import { ReactNode, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Group,
  MantineColor,
  MantineSize,
  Modal,
} from "@mantine/core";

type ConfirmationModalProps = {
  onConfirm: (event?: React.MouseEvent<HTMLElement>) => void;
  onCancel: (event?: React.MouseEvent<HTMLElement>) => void;
  opened: boolean;
  title?: string;
  confirmButtonColor?: MantineColor;
  confirmButtonText?: string;
  cancelButtonText?: string;
  size?: number | MantineSize | (string & {});
  icon?: IconProp;
  iconColor?: string;
  children?: ReactNode;
};

type DeleteModalProps = {
  onDelete: (event?: React.MouseEvent<HTMLElement>) => void;
  onCancel: (event?: React.MouseEvent<HTMLElement>) => void;
  opened: boolean;
  title?: string;
  text: ReactNode;
  size?: number | MantineSize | (string & {});
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onConfirm,
  onCancel,
  opened,
  title,
  confirmButtonColor = "blue",
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  size = "xl",
  icon,
  iconColor,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const onClickConfirm = (event: React.MouseEvent<HTMLElement>) => {
    setIsLoading(true);
    onConfirm(event);
  };

  return (
    <Modal
      centered
      onClose={onCancel}
      opened={opened}
      size={size}
      withCloseButton={false}
      title={title}
    >
      <Group align="flex-start" wrap="nowrap">
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            size="3x"
            color={iconColor}
            style={{ marginTop: "8px" }}
          />
        )}
        <div>{children}</div>
      </Group>
      <Group mt="md" justify="right">
        <Button color="gray" onClick={onCancel} variant="light">
          {cancelButtonText}
        </Button>
        <Button
          color={confirmButtonColor}
          onClick={onClickConfirm}
          loading={isLoading}
        >
          {confirmButtonText}
        </Button>
      </Group>
    </Modal>
  );
};

export const DeleteModal: React.FC<DeleteModalProps> = ({
  onDelete,
  onCancel,
  opened,
  title,
  text,
  size = "xl",
}) => {
  return (
    <ConfirmationModal
      onConfirm={onDelete}
      onCancel={onCancel}
      opened={opened}
      title={title}
      confirmButtonColor="red"
      confirmButtonText="Remove"
      size={size}
      icon={faTrashCan}
      iconColor="var(--mantine-color-red-7)"
    >
      <Box display="inline-block" ml={16}>
        {text}
      </Box>
    </ConfirmationModal>
  );
};
