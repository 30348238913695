import { memo, ReactNode, useCallback, useMemo, useState } from "react";
import intersectionBy from "lodash/intersectionBy";
import pluralize from "pluralize";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faCodeFork, faSearch, faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  Group,
  Skeleton,
  Stack,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { CloseButton, Input } from "@mantine/core";

import { GithubRepository } from "@protos/external/v1/github_repository";
import { TargetRepository } from "@protos/search/v1/search_service";
import { ExternalLink } from "@shared/components";

import styles from "./EditorProjectSelect.module.css";

interface RepoCardProps {
  repo: GithubRepository;
  selected: boolean;
  onToggle: () => void;
}

const RepoCard = ({ repo, selected, onToggle }: RepoCardProps) => {
  return (
    <UnstyledButton
      className={styles.projectSelectCard}
      component={Group}
      align="center"
      wrap="nowrap"
      onClick={onToggle}
    >
      <Checkbox
        checked={selected}
        onChange={onToggle}
        styles={{ input: { cursor: "pointer" } }}
        aria-label={repo.fullName}
      />
      <Group justify="space-between" w="100%" wrap="nowrap">
        <Stack gap={0}>
          <Text truncate="end">
            <FontAwesomeIcon icon={faGithub} />
            <ExternalLink
              className={styles.repoLink}
              href={repo.htmlUrl}
              onClick={(event) => event.stopPropagation()}
            >
              {repo.fullName.split("/")[0]}/
              <strong>{repo.fullName.split("/")[1]}</strong>
            </ExternalLink>
          </Text>
          <Text c="dimmed" fz="xs">
            {repo.language}
          </Text>
        </Stack>
        <Stack gap={0} justify="end">
          <Text fz="xs" c="dimmed" ta="right">
            <FontAwesomeIcon icon={faStar} /> {repo.stargazersCount}
          </Text>
          <Text fz="xs" c="dimmed" ta="right">
            <FontAwesomeIcon icon={faCodeFork} /> {repo.forksCount}
          </Text>
        </Stack>
      </Group>
    </UnstyledButton>
  );
};

interface ProjectsSelectProps {
  options: GithubRepository[];
  value: TargetRepository[];
  onChange: (value: TargetRepository[]) => void;
  onSearchChange?: (value: string) => void;
  // Set this to `false` if options being passed are pre-filtered. Defaults to `true`.
  filterResults?: boolean;
  isLoadingOptions?: boolean;
  help?: ReactNode;
}

export const ProjectsSelect = memo(
  ({
    value,
    onChange,
    options,
    onSearchChange,
    filterResults = true,
    isLoadingOptions = false,
    help,
  }: ProjectsSelectProps) => {
    const [searchValue, setSearchValue] = useState("");
    const handleSearchChange = useCallback(
      (evt: React.ChangeEvent<HTMLInputElement> | string) => {
        const val = typeof evt === "string" ? evt : evt.target.value;
        setSearchValue(val);
        if (onSearchChange) {
          onSearchChange(val);
        }
      },
      [onSearchChange]
    );

    const filteredValues = useMemo(
      () =>
        filterResults
          ? options
              .filter((r) =>
                r.fullName.toLowerCase().includes(searchValue.toLowerCase())
              )
              .sort((a, b) => a.fullName.localeCompare(b.fullName))
          : options,
      [filterResults, options, searchValue]
    );
    const selectedFilteredValues = intersectionBy(
      value,
      filteredValues,
      (v) => v.fullName
    );

    const handleSelectAll = useCallback(() => {
      if (selectedFilteredValues.length === filteredValues.length) {
        onChange(
          value.filter(
            (v) =>
              !selectedFilteredValues.find((s) => s.fullName === v.fullName)
          )
        );
      } else {
        onChange(
          filteredValues.map((r) => ({
            fullName: r.fullName,
            cloneUrl: r.cloneUrl,
            private: r.private,
          }))
        );
      }
    }, [value, filteredValues, selectedFilteredValues, onChange]);

    const toggleRepo = (repo: GithubRepository) => () => {
      if (value.find((v) => v.fullName === repo.fullName)) {
        onChange(value.filter((v) => v.fullName !== repo.fullName));
      } else {
        onChange([
          ...value,
          {
            fullName: repo.fullName,
            cloneUrl: repo.cloneUrl,
            private: repo.private,
          },
        ]);
      }
    };

    return (
      <>
        <Input
          m="sm"
          placeholder="Search repositories"
          value={searchValue}
          onChange={handleSearchChange}
          leftSection={<FontAwesomeIcon icon={faSearch} />}
          rightSectionPointerEvents="all"
          rightSection={
            searchValue && (
              <CloseButton
                aria-label="Clear input"
                onClick={() => handleSearchChange("")}
              />
            )
          }
        />
        {isLoadingOptions ? (
          <Stack mx="sm">
            <Skeleton w="100%" h={40} />
            <Skeleton w="100%" h={40} />
            <Skeleton w="100%" h={40} />
          </Stack>
        ) : (
          <>
            <Group justify="space-between" align="start">
              {filteredValues.length ? (
                <Checkbox
                  ml="sm"
                  mb="sm"
                  label={
                    <Text inherit ml="xs">
                      Select {filteredValues.length}{" "}
                      {pluralize("repo", filteredValues.length)}
                    </Text>
                  }
                  checked={
                    selectedFilteredValues.length === filteredValues.length
                  }
                  indeterminate={
                    selectedFilteredValues.length > 0 &&
                    selectedFilteredValues.length < filteredValues.length
                  }
                  onChange={handleSelectAll}
                  styles={{ input: { cursor: "pointer" } }}
                />
              ) : (
                <div />
              )}
              {help}
            </Group>
            {filteredValues.map((repo) => (
              <RepoCard
                key={repo.fullName}
                repo={repo}
                selected={!!value.find((v) => v.fullName === repo.fullName)}
                onToggle={toggleRepo(repo)}
              />
            ))}
            {!!searchValue.length &&
              !isLoadingOptions &&
              !filteredValues.length && (
                <Text ta="center" c="dimmed">
                  No repositories found
                </Text>
              )}
          </>
        )}
      </>
    );
  }
);
