import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { ORGANIZATION_KEY, RETURN_PATH_KEY } from "@shared/constants";
import { AuthType } from "@shared/types";
import { recoverBrowserSecurityError } from "@shared/utils";
import { clearTokens } from "@shared/utils/lib/authUtils";

const addReturnPathToUrl = (
  targetUrlString: string,
  preferredReturnPath?: string
): string => {
  const currentUrl = new URL(window.location.toString());
  const targetUrl = new URL(targetUrlString, window.location.origin);
  const newReturnPath =
    // reuse existing return path if it exists
    currentUrl.searchParams.get(RETURN_PATH_KEY) ??
    // use requested return path if it exists
    preferredReturnPath ??
    // use current path otherwise
    `${currentUrl.pathname}${currentUrl.search}`;
  targetUrl.searchParams.set(RETURN_PATH_KEY, newReturnPath);
  return `${targetUrl.pathname}${targetUrl.search}`;
};

export const useAuth = () => {
  const history = useHistory();
  const login = useCallback(
    async (
      authProviderName: string,
      authProviderType: AuthType,
      preferredReturnPath?: string
    ) => {
      const targetUrl =
        authProviderType === "saml2"
          ? `/api/auth/saml/login/${authProviderName}`
          : `/api/auth/oauth/login/${authProviderName}`;
      const targetPath = addReturnPathToUrl(targetUrl, preferredReturnPath);
      //should-redirect-with-react-router because the redirect URL doesn't exist in the frontend app
      // nosemgrep
      window.location.href = targetPath; // nosemgrep
    },
    []
  );

  const scmInstallRedirect = useCallback(
    async (authProviderType: AuthType, preferredReturnPath?: string) => {
      // NOTE (zz): The following line is no longer true as we currently don't set the organization data in localStorage:
      // Allows us to fall back to using the newest org when this function returns
      recoverBrowserSecurityError(() =>
        localStorage.removeItem(ORGANIZATION_KEY)
      );
      // NOTE (zz): Coupling with `Onboarding.tsx` router to redirect to the correct page
      const targetPath =
        authProviderType && authProviderType === "gitlab"
          ? "/gitlab/organizations"
          : "/onboarding/scm/github/install";
      const targetUrl = addReturnPathToUrl(targetPath, preferredReturnPath);
      history.push(targetUrl);
    },
    [history]
  );

  const logout = () => {
    // Allows us to fall back to using the newest org when this function returns
    clearTokens();
    // refresh page so user changes are reflected
    window.location.reload();
  };

  return { login, logout, scmInstallRedirect };
};
