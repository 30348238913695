import {
  Location,
  MatchCallTrace,
  MatchDataflowTrace,
  MatchIntermediateVar,
} from "@semgrep_output_types";

export interface TaintVisualizerRow {
  label: string;
  location?: Location;
  content?: string[];
}

const match_to_visualizer_row = (
  label: string,
  location: Location,
  content: string[]
): TaintVisualizerRow => {
  return { label, location, content };
};

const call_trace_to_lines = (
  call_trace: MatchCallTrace,
  get_lines: (path: string, start_line: number, end_line: number) => string[],
  prefix_label?: string
): TaintVisualizerRow[] => {
  const trace = call_trace.value;
  switch (call_trace.kind) {
    case "CliLoc": {
      const trace_inner: [Location, string] = call_trace.value;
      return [
        match_to_visualizer_row(prefix_label || "", trace_inner[0], [
          trace_inner[1],
        ]),
      ];
    }
    case "CliCall": {
      // TODO: typescript compiler should be able to figure out .kind --> .value type restriction?
      const data: [Location, string] = trace[0] as any;
      const intermediate_vars: MatchIntermediateVar[] = trace[1] as any;
      const subcall_trace: MatchCallTrace | undefined = trace[2];
      let output: TaintVisualizerRow[] = [];
      output = output.concat([
        match_to_visualizer_row(prefix_label + " through call", data[0], [
          data[1],
        ]),
      ]);

      if (intermediate_vars && intermediate_vars.length > 0) {
        for (const i in intermediate_vars) {
          const loc = intermediate_vars[i].location;
          //const lines = get_lines(loc.path, loc.start.line, loc.end.line);
          output = output.concat([
            match_to_visualizer_row(prefix_label + " through variable", loc, [
              intermediate_vars[i].content,
            ]),
          ]);
        }
      }

      if (subcall_trace) {
        let subcall_prefix = "";
        switch (subcall_trace.kind) {
          case "CliCall":
            subcall_prefix = "then calls";
            break;
          case "CliLoc":
            subcall_prefix = "then reaches";
            break;
        }
        output = output.concat(
          call_trace_to_lines(subcall_trace, get_lines, subcall_prefix)
        );
      }
      return output;
    }
  }
};

export const dataflow_trace_to_lines = (
  dataflow_trace: MatchDataflowTrace | undefined,
  get_lines: (path: string, start_line: number, end_line: number) => string[]
): TaintVisualizerRow[] => {
  let output: TaintVisualizerRow[] = [];
  if (dataflow_trace) {
    const source = dataflow_trace.taint_source;
    const intermediate_vars = dataflow_trace.intermediate_vars;
    const sink = dataflow_trace.taint_sink;

    if (source) {
      output = output.concat(
        call_trace_to_lines(
          source,
          get_lines,
          "⨀ taint comes from this source: "
        )
      );
    }

    if (intermediate_vars && intermediate_vars.length > 0) {
      for (const i in intermediate_vars) {
        const loc = intermediate_vars[i].location;
        const content = intermediate_vars[i].content;
        output = output.concat(
          match_to_visualizer_row(
            "→ taint flows through this intermediate variable",
            loc,
            [content]
          )
        );
      }
    }

    if (sink) {
      output = output.concat(
        call_trace_to_lines(sink, get_lines, "◉ taint flows to this sink: ")
      );
    }
  }
  return output;
};
