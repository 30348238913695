import { RunResponse } from "@shared/types";

export const EMPTY_RUN_RESPONSE: RunResponse = {
  semgrep_result: {
    status_code: -1,
    output: {
      errors: [],
      results: [],
      paths: { scanned: [] },
      skipped_rules: [],
    },
    run_time: 0.0,
    environment: "browser",
  },
  test_result: [],
  ai_autofixes: [],
};
