import { OrgData } from "@shared/types";

import { getOrgSlug } from "../getOrgSlug";
/**
 * @param orgName name of org (or name of current namespace)
 * @param ruleId id from the rule text
 * @returns orgName:ruleId
 * slugifies orgName and lowercases snippet name
 */
export const getSnippetAliasName = (org: OrgData, ruleId: string) => {
  return `${getOrgSlug(org)}:${ruleId}`.toLowerCase();
};
