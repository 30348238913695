import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mantine/core";

import { Billboard } from "../../Billboard";

export const LoginPrompt: React.FC<{ returnPath: string }> = ({
  returnPath,
}) => {
  const history = useHistory();
  return (
    <Billboard
      title="✨ Welcome to Semgrep! ✨"
      primaryAction={
        <Button
          onClick={() => history.push(`/login?return_path=${returnPath}`)}
        >
          Continue
        </Button>
      }
    >
      Sign in with GitHub, GitLab, or SSO. <br /> It's free.
    </Billboard>
  );
};
