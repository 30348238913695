// disabling eslint, these monaco imports need to happen in a specific order
/* eslint-disable */
// @ts-nocheck
import MonacoEditor, { DiffEditor, loader } from "@monaco-editor/react";
import * as monaco from "monaco-editor";

import { setDiagnosticsOptions } from "monaco-yaml";
import yaml from "yaml";
import { getSchemaUrl } from "@shared/api/lib/editor/getSchemaUrl";

import monacoJsonWorker from "monaco-editor/esm/vs/language/json/json.worker?worker";
import monacoCssWorker from "monaco-editor/esm/vs/language/css/css.worker?worker";
import monacoHtmlWorker from "monaco-editor/esm/vs/language/html/html.worker?worker";
import monacoTsWorker from "monaco-editor/esm/vs/language/typescript/ts.worker?worker";
import monacoEditorWorker from "monaco-editor/esm/vs/editor/editor.worker?worker";
import YamlWorker from "./yaml.worker.js?worker";

self.MonacoEnvironment = {
  getWorker: function (_, label) {
    switch (label) {
      case "json":
        return new monacoJsonWorker();
      case "css":
      case "scss":
      case "less":
        return new monacoCssWorker();
      case "html":
      case "handlebars":
      case "razor":
        return new monacoHtmlWorker();
      case "typescript":
      case "javascript":
        return new monacoTsWorker();
      case "yaml":
        return new YamlWorker();
      default:
        return new monacoEditorWorker();
    }
  },
};

// Load monaco services to support LSP and register language servers
const initialize = async () => {
  // Get the schema url and load it into the editor
  const { schema_url: url } = await getSchemaUrl();
  // fetch the schema yaml
  if (!url) return;
  const schema = await fetch(url).then((res) => res.text());
  // Parse the schema into a JSON object
  const schemaObj = yaml.parse(schema);
  setDiagnosticsOptions({
    enableSchemaRequest: true,
    hover: true,
    completion: false,
    validate: true,
    format: true,
    schemas: [
      {
        uri: url,
        fileMatch: ["rule.yaml"],
        schema: {
          id: url,
          ...schemaObj,
        },
      },
    ],
  });
};

initialize();
// Load the editor w/modified services into react/monaco
loader.config({
  monaco,
});

export { MonacoEditor, DiffEditor };
