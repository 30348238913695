import { observer } from "mobx-react-lite";
import { faHyphen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MatchingExplanation } from "@semgrep_output_types";

import styles from "../StructureMode.module.css";

interface MatchNumberBoxProps {
  explanation: MatchingExplanation;
}

export const MatchNumberBox: React.FC<MatchNumberBoxProps> = observer(
  ({ explanation }) => {
    const isNotZero = explanation && explanation.matches.length !== 0;

    return (
      <div
        style={{
          backgroundColor: isNotZero
            ? "var(--mantine-color-green-1)"
            : "var(--mantine-color-red-1)",
        }}
        className={styles.matchNumberBox}
        aria-label="MatchNumberBox"
      >
        {isNotZero ? (
          <div
            className={styles.matchNumberText}
            style={{
              color: isNotZero
                ? "var(--mantine-color-green-7)"
                : "var(--mantine-color-red-5)",
            }}
          >
            {explanation?.matches.length ?? 0}
          </div>
        ) : (
          <FontAwesomeIcon
            icon={faHyphen}
            size="lg"
            color="var(--mantine-color-red-5)"
          />
        )}
      </div>
    );
  }
);
