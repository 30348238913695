const hostname = window.location.hostname;

export const appEnv =
  hostname === "localhost"
    ? undefined
    : hostname === "semgrep.dev"
    ? "prod"
    : hostname === "staging.semgrep.dev"
    ? "staging"
    : hostname.endsWith("dev.semgrep.dev")
    ? "dev"
    : hostname.endsWith("dev2.semgrep.dev")
    ? "dev2"
    : hostname.endsWith(".semgrep.dev") // Tenant
    ? "prod"
    : undefined;
