import { useContext } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Group, Tooltip } from "@mantine/core";

import { MatchingExplanation } from "@semgrep_output_types";

import { WorkbenchContext } from "../../providers";

import { MenuDropdown, MenuDropdownItem } from "./components/MenuDropdown";
import {
  defaultRawPatternInnerOfKind,
  InArray,
  Pattern,
  PatternKind,
} from "./types/rule";
import { MatchNumberBox } from "./utils/MatchNumberBox";
import { OperatorIcon } from "./utils/OperatorIcon";
import { PatternButtons } from "./Buttons";

function PATTERN_SELECT_OPTIONS(
  inArray: InArray<Pattern> | null
): MenuDropdownItem[] {
  return [
    {
      name: "pattern",
      info: "Produce findings on code matching this pattern",
      enabled: true,
    },
    {
      name: "any",
      info: "Match when any children patterns match",
      enabled: true,
    },
    {
      name: "all",
      info: "Match when all children patterns match",
      enabled: true,
    },
    {
      name: "regex",
      info: "Produce findings on text matching this regex",
      enabled: true,
    },
    inArray
      ? {
          name: "inside",
          info: "Return only matches within this pattern",
          enabled: true,
        }
      : {
          name: "inside",
          info: "(not allowed at top level of pattern)",
          enabled: false,
        },
    inArray?.parentKind === "all"
      ? {
          name: "not",
          info: "Remove findings matching this pattern",
          enabled: true,
        }
      : {
          name: "not",
          info: "(only allowed underneath an `all`)",
          enabled: false,
        },
  ];
}

function extractPatString(pattern: Pattern): string | null {
  const p = pattern.value.rawPattern.value;
  switch (p.kind) {
    case "pattern":
      return p.pattern;
    case "regex":
      return p.regex;
    case "all":
    case "any":
    case "inside":
    case "not":
      return null;
  }
}

interface OperatorSelectProps {
  pattern: Pattern;
  inArray: InArray<Pattern> | null;
  explanation: MatchingExplanation | null;
  isFocused: boolean;
}

/**
 * The component which dictates a drop-down menu for selecting kinds of patterns.
 *
 * @param pattern - the pattern this menu corresponds to
 * @param inArray - the array of patterns this one is contained in (if any)
 * @param explanation - the matching explanations corresponding to this node
 * @param isFocused - whether this pattern is being hovered
 */
export const PatternOperatorSelect: React.FC<OperatorSelectProps> = observer(
  ({ pattern, inArray, explanation, isFocused }) => {
    const { workbench } = useContext(WorkbenchContext);
    const { bundle } = workbench;

    const p = pattern.value.rawPattern.value;
    let singlePattern: string | null = null;
    switch (p.kind) {
      case "pattern":
      case "regex":
        singlePattern = extractPatString(pattern);
        break;
      case "all":
      case "any":
        singlePattern = extractPatString(p.patterns[0]);
        break;
      case "inside":
      case "not":
        singlePattern = extractPatString(p.pattern);
        break;
      default:
    }

    const onChange = (new_kind: string | null) => {
      // TODO(brandon): why is this here?
      if (new_kind === pattern.value.rawPattern.value.kind) {
        return;
      }
      runInAction(() => {
        switch (new_kind) {
          case null:
            // TODO: delete this rule segment when null?
            // this will not run unless we remove `allowDeselect={false}` from the Select component
            break;
          case "pattern":
          case "regex":
          case "inside":
          case "not":
            if (singlePattern) {
              pattern.value.rawPattern.value = defaultRawPatternInnerOfKind(
                new_kind,
                singlePattern
              );
            } else {
              pattern.value.rawPattern.value =
                defaultRawPatternInnerOfKind(new_kind);
            }
            break;
          case "any":
          case "all": {
            const current_kind = pattern.value.rawPattern.value.kind;
            // If we are coming from an `any` or `all`, preserve the existing
            // sub-patterns
            if (
              (current_kind === "any" || current_kind === "all") &&
              pattern.value.rawPattern.value.patterns.length > 0
            ) {
              pattern.value.rawPattern.value = {
                kind: new_kind,
                patterns: pattern.value.rawPattern.value.patterns,
              };
            } else if (singlePattern) {
              pattern.value.rawPattern.value = defaultRawPatternInnerOfKind(
                new_kind,
                singlePattern
              );
            } else {
              pattern.value.rawPattern.value =
                defaultRawPatternInnerOfKind(new_kind);
            }
            break;
          }
        }
        bundle?.onStructureRuleChange();
        return;
      });
    };

    const selectElem = (
      // We elect to place the matching explanation div next to the select, as
      // opposed to within its leftSection, as this allows a consistent spacing from
      // the edge of the explanation to the select box's contents.
      <Group gap={0}>
        {explanation && <MatchNumberBox explanation={explanation} />}
        <MenuDropdown
          items={PATTERN_SELECT_OPTIONS(inArray)}
          onChange={(new_kind: string | null) => onChange(new_kind)}
          renderLeftIcon={(kind: string) => {
            const item = PATTERN_SELECT_OPTIONS(inArray).find(
              ({ name }) => name === kind
            );
            return (
              <Tooltip label={item?.info}>
                <Group w={20} justify="center">
                  <OperatorIcon kind={kind as PatternKind} />
                </Group>
              </Tooltip>
            );
          }}
          value={p.kind}
        />
      </Group>
    );

    // buttons go on the bottom for pattern or regex, so return early
    if (p.kind === "pattern" || p.kind === "regex") {
      return selectElem;
    }

    return (
      <Group
        // so operator select buttons don't wrap
        wrap={"nowrap"}
        gap={"var(--button-spacing)"}
      >
        {selectElem}
        <PatternButtons
          bundle={bundle}
          pattern={pattern}
          inArray={inArray}
          isFocused={isFocused}
        />
      </Group>
    );
  }
);
