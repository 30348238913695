import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Button, Stack, Text } from "@mantine/core";

import { emptyPattern, RuleWithKind } from "./types/rule";
import { PatternTree } from "./PatternTree";

import styles from "./StructureMode.module.css";

const LEFT_PADDING = "10px";

interface TaintStructureProps {
  rule: RuleWithKind<"taint">;
}
const TaintStructureComponent: React.FC<TaintStructureProps> = ({ rule }) => {
  const r = rule.value;
  return (
    <Stack className={styles["formula-list"]}>
      <Stack gap={"var(--formula-spacing)"}>
        <Text style={{ paddingLeft: LEFT_PADDING }} fw={700}>
          Sources
        </Text>
        {r.sources.map((source, index) => (
          <PatternTree
            key={source.uuid}
            pattern={source}
            topArray={{
              index,
              parentArray: r.sources,
              parentKind: "any",
            }}
          />
        ))}
      </Stack>
      <Stack gap={"var(--formula-spacing)"}>
        <Text style={{ paddingLeft: LEFT_PADDING }} fw={700}>
          Sinks
        </Text>
        {r.sinks.map((sink, index) => (
          <PatternTree
            key={sink.uuid}
            pattern={sink}
            topArray={{
              index,
              parentArray: r.sinks,
              parentKind: "any",
            }}
          />
        ))}
      </Stack>
      <Stack gap={"var(--formula-spacing)"}>
        <Text style={{ paddingLeft: LEFT_PADDING }} fw={700}>
          Sanitizers
        </Text>
        {r.sanitizers.length === 0 && (
          <Button
            className={styles.sanitizersButton}
            onMouseDown={(_) => {
              runInAction(() => {
                r.sanitizers.push(emptyPattern());
              });
            }}
          >
            Add Sanitizers
          </Button>
        )}
        {r.sanitizers.map((source, index) => (
          <PatternTree
            key={source.uuid}
            pattern={source}
            topArray={{
              index,
              parentArray: r.sanitizers,
              parentKind: "sanitizer",
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export const TaintStructure = observer(TaintStructureComponent);
