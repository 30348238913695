import { observer } from "mobx-react-lite";
import styled from "styled-components";
import {
  faCircleX as errorIcon,
  faExclamationCircle as infoIcon,
  faExclamationTriangle as warningIcon,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ErrorResult } from "../../stores";
import { RuleValidationError } from "../../types";
import { SectionHeader } from "../SectionHeader";
import { SectionList } from "../SectionList";

const ErrorBox = styled.div`
  white-space: pre-wrap;
  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr;
  column-gap: var(--global-spacing-xxs);
`;

interface ErrorProps {
  error: RuleValidationError;
}

const ErrorElement = ({ error }: ErrorProps) => {
  return (
    <ErrorBox>
      <FontAwesomeIcon
        icon={
          error.severity === "Error"
            ? errorIcon
            : error.severity === "Warning"
            ? warningIcon
            : infoIcon
        }
        style={{
          fontSize: "16px",
          alignSelf: "start",
          marginTop: "2px",
          color: `var(--global-${
            error.severity === "Error"
              ? "red"
              : error.severity === "Warning"
              ? "yellow"
              : "blue"
          })`,
        }}
      />
      <span style={{ fontSize: "13px" }}>
        <code>{error.message}</code>
        <code
          style={{
            marginLeft: "var(--mantine-spacing-sm)",
            color: "var(--mantine-color-gray-6)",
          }}
        >
          {error.source}({error.code})
        </code>
        <code
          style={{
            color: "var(--mantine-color-gray-6)",
            fontSize: "13px",
            marginLeft: "var(--mantine-spacing-sm)",
          }}
        >
          {error.startPos
            ? `[Ln ${error.startPos?.lineno ?? 0}, Col ${
                error.startPos?.col ?? 0
              }]`
            : ""}
        </code>
      </span>
    </ErrorBox>
  );
};

interface ErrorsSectionProps {
  result: ErrorResult;
}

const Section = ({ result }: ErrorsSectionProps) => {
  return (
    <>
      <SectionHeader>Errors</SectionHeader>
      <SectionList style={{ padding: "var(--mantine-spacing-sm)" }}>
        {result.errors.map((error, index) => (
          <ErrorElement error={error} key={index} />
        ))}
      </SectionList>
    </>
  );
};

export const ErrorsSection = observer(Section);
