import { useTimeLookbackRange } from "../useTimeLookbackRange";
import { useTimeLookbackType } from "../useTimeLookbackType";

export const useTimeLookback = () => {
  const [tlr, setTlr] = useTimeLookbackRange();
  const [tlrType, setTlrType] = useTimeLookbackType();

  return {
    tlr,
    setTlr,
    tlrType,
    setTlrType,
  };
};
