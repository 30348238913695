import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { SemgrepCheatsheet } from "@shared/components";
import { SEMGREP_REPO } from "@shared/constants";
import { ALL_VALID_LANGUAGE_KEYS, makeGhIssueUrl } from "@shared/utils";

import { Bundle } from "../stores";

import { LanguagePanel } from "./LanguagePanel";

const Container = styled.div`
  padding: var(--mantine-spacing-lg);
  font-size: 0.9em;
`;

const HelpContainer = styled.div`
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 20px;
`;

interface Props {
  bundle: Bundle;
}

const SemgrepDocsEditor = ({ bundle }: Props) => {
  const { rule } = bundle;
  if (rule === null) return null;
  if (rule.languages.length === 0) return null;
  const selectedLanguages = rule.languages;
  const selectedLanguage = rule.languages[0];

  return (
    <Container>
      <HelpContainer>
        See{" "}
        <a href="https://semgrep.dev/docs/writing-rules/overview/">
          all documentation at semgrep.dev/docs
        </a>{" "}
        or <a href="/learn">try the tutorial</a>
        .<br />
        You can also{" "}
        <a
          href={makeGhIssueUrl(
            SEMGREP_REPO,
            "semgrep.dev bug report from editor",
            ""
          )}
        >
          file a bug
        </a>{" "}
        or{" "}
        <a href="https://go.semgrep.dev/slack">
          chat with the Semgrep developers
        </a>
        ! We're friendly and would be happy to help you.
        <br />
      </HelpContainer>
      {!ALL_VALID_LANGUAGE_KEYS.includes(selectedLanguage) ? (
        <div>
          Language <code>{selectedLanguage}</code> isn't supported, see below
          for valid languages:
          <br />
          <br />
          <LanguagePanel selectedLanguages={selectedLanguages} />
        </div>
      ) : (
        <div>
          <br />
          <h2>Cheatsheet for {selectedLanguage}</h2>
          <SemgrepCheatsheet hideIndex forLanguage={selectedLanguage} />
        </div>
      )}
    </Container>
  );
};

export const SemgrepDocsEditorPanel = observer(SemgrepDocsEditor);
