import { RefObject } from "react";
import debounce from "lodash/debounce";
import useResizeObserver, {
  UseResizeObserverCallback,
} from "@react-hook/resize-observer";

import { RESIZE_DEBOUNCE_MILLIS } from "@shared/constants";

/** Returns a debounced resize observer */
export const useDebouncedResize = (
  element: HTMLElement | RefObject<HTMLElement> | null,
  callback: UseResizeObserverCallback
) => {
  useResizeObserver(element, debounce(callback, RESIZE_DEBOUNCE_MILLIS));
};
