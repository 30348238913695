import { useCallback, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router";
import styled from "styled-components";
import { faGem } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Group, SegmentedControl, Tabs, Tooltip } from "@mantine/core";

import { useAnnouncement, useUrlSearch } from "@shared/hooks";

import { WorkbenchContext } from "../providers";

import { ResultsPanel } from "./ResultsPanel/ResultsPanel";
import { TargetEditor } from "./TargetEditor/TargetEditor";
import { EditorQueryConsole } from "./EditorQueryConsole";
import { MetadataPanel } from "./MetadataPanel";
import { SemgrepDocsEditorPanel } from "./SemgrepDocsEditorPanel";

const TestPanel = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr) min-content;
`;

// 94px is the height of the tab bar
const SecondaryTestPanel = styled.div<{ maxHeight: string }>`
  max-height: ${(props) => props.maxHeight};
  overflow-y: auto;
`;

const TestPanelContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr);
  max-height: 100%;
  min-width: 300px;
`;

type TAB_ID = "code" | "livecode" | "metadata" | "languages" | "docs";

const PRO_TURBO_TOOLTIP = (
  <div style={{ whiteSpace: "break-spaces" }}>
    <p>
      The <b>Pro</b> option runs your rule with the Semgrep Pro Engine.
    </p>
    <p>
      The <b>Turbo</b> option runs your rule against the Semgrep OSS Engine
      after every keystroke.
    </p>
  </div>
);

const Panel = () => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;

  const { search, setSearch } = useUrlSearch();
  const activeTab = (search.get("view") || "code") as TAB_ID;
  const setActiveTab = useCallback(
    (tab: TAB_ID) => {
      search.set("view", tab);
      setSearch(search);
    },
    [search, setSearch]
  );

  // Very stupid hack to override the max height if there is an announcement banner or the main nav (in the playground)
  // Fixed in the layout rewrite of the Nx editor
  const location = useLocation();
  const announcement = useAnnouncement();

  let headerHeight = 94;
  if (location.pathname.includes("playground")) {
    headerHeight += 50;
  } else if (!announcement.dismissed) {
    // Banner does not display on the playground, hence the exclusivity
    headerHeight += 53;
  }
  const secondaryPanelHeight = `calc(100vh - ${headerHeight}px)`;

  if (bundle === null) return null;
  const controlValue = workbench.turboModeEnabled
    ? "turbo"
    : bundle.isDeep
    ? "pro"
    : "neither";

  const onChangeActiveTab = (value: string | null) => {
    if (value) {
      setActiveTab(value as TAB_ID);
    }
  };

  const metadataTabElem =
    bundle.structureRule === undefined ? (
      <Tabs.Tab value="metadata">metadata</Tabs.Tab>
    ) : (
      // Structure mode has its own metadata tab, so let's disable this one, and show a
      // tooltip.
      <Tooltip
        label={
          'Located in the "Rule Info" menu at the bottom of the Structure Mode editor!'
        }
      >
        <Tabs.Tab disabled={true} value="metadata">
          metadata
        </Tabs.Tab>
      </Tooltip>
    );

  return (
    <TestPanelContainer>
      <Tabs defaultValue="code" value={activeTab} onChange={onChangeActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="code">test code</Tabs.Tab>
          <Tabs.Tab value="livecode">
            <Group gap="5">
              <div>live code</div>
              <Badge color="blue" size="xs" variant="light">
                NEW
              </Badge>
            </Group>
          </Tabs.Tab>
          {metadataTabElem}
          <Tabs.Tab value="docs">docs</Tabs.Tab>
          {activeTab === "code" && (
            <Tooltip
              label={PRO_TURBO_TOOLTIP}
              w={250}
              withinPortal
              withArrow
              openDelay={750}
            >
              <SegmentedControl
                radius="xl"
                size="xs"
                h={30}
                styles={{
                  label: {
                    lineHeight: 1.3,
                  },
                }}
                m="auto"
                mr="xs"
                data={[
                  {
                    label: (
                      <span>
                        <FontAwesomeIcon icon={faGem} /> Pro
                      </span>
                    ),
                    value: "pro",
                  },
                  {
                    label: "Turbo",
                    value: "turbo",
                  },
                  ...(controlValue === "neither"
                    ? [{ label: "OSS", value: "neither" }]
                    : []),
                ]}
                value={controlValue}
                onChange={workbench.toggleTurboMode}
              />
            </Tooltip>
          )}
        </Tabs.List>
      </Tabs>
      {activeTab === "code" && (
        <TestPanel>
          <TargetEditor />
          <ResultsPanel />
        </TestPanel>
      )}
      {activeTab === "livecode" && (
        <SecondaryTestPanel maxHeight={secondaryPanelHeight}>
          <EditorQueryConsole />
        </SecondaryTestPanel>
      )}
      {activeTab === "metadata" && (
        <SecondaryTestPanel maxHeight={secondaryPanelHeight}>
          <MetadataPanel workbench={workbench} />
        </SecondaryTestPanel>
      )}
      {activeTab === "docs" && (
        <SecondaryTestPanel maxHeight={secondaryPanelHeight}>
          <SemgrepDocsEditorPanel bundle={bundle} />
        </SecondaryTestPanel>
      )}
    </TestPanelContainer>
  );
};

export const TargetEditorPanel = observer(Panel);
