import React, { useState } from "react";
import styled, { css } from "styled-components";
import {
  faCheckCircle,
  faQuestionCircle,
  faUserShield,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Center } from "@mantine/core";

import {
  GITHUB_WORKFLOW_FILE_PATH,
  SEMGREP_IGNORE_FILE_PATH,
} from "@shared/constants";

type Item = {
  title: string;
  description: React.ReactNode;
};

type Content = {
  [key: string]: Item[];
};

const PERMISSIONS: Content = {
  login: [
    {
      title: "Reading your email addresses",
      description: "So that you can receive operational notices.",
    },
  ],
  install: [
    {
      title: "Reading metadata of the repositories you select",
      description: "Lets Semgrep list their names on the project setup page.",
    },
    {
      title: "Reading the list of organization members",
      description:
        "Lets Semgrep determine who can manage your Semgrep organization based on your GitHub organization's members list.",
    },
    {
      title: "Writing (and reading) pull requests",
      description: "Lets Semgrep comment about findings on pull requests",
    },
    {
      title: "Writing (and reading) actions",
      description:
        "Allows Semgrep AppSec Platform to cancel stuck jobs, rerun jobs, pull logs from jobs, and perform on-demand scanning",
    },
    {
      title: "Reading checks",
      description:
        "Facilitates debugging of Semgrep AppSec Platform when configured out of GitHub Actions",
    },
    {
      title: "Writing (and reading) security events",
      description:
        "Enables integration with GitHub Advanced Security (for example, to show Semgrep results)",
    },
    {
      title: "Writing (and reading) secrets",
      description:
        "Enables automatic adding of the Semgrep AppSec Platform Token to your repository secrets when onboarding projects. Note: We cannot read the values of your existing or future secrets (only the names).",
    },
    {
      title: "Writing (and reading) 2 files",
      description: (
        <span>
          Lets Semgrep configure itself to run in CI by writing to{" "}
          <code>{GITHUB_WORKFLOW_FILE_PATH}</code> and{" "}
          <code>{SEMGREP_IGNORE_FILE_PATH}</code>.
        </span>
      ),
    },
    {
      title: "Writing (and reading) workflows",
      description: (
        <span>
          Lets Semgrep configure itself to run in CI by writing to{" "}
          <code>{GITHUB_WORKFLOW_FILE_PATH}</code>. GitHub allows writing to
          files within <code>.github/workflows/</code> only if this permission
          is granted along with "Writing a single file".
        </span>
      ),
    },
  ],
  lite: [],
  code_access: [
    {
      title: "Read source code of the repositories you select",
      description:
        "Allows Semgrep Assistant to fetch source code files on-demand to construct AI prompts.",
    },
  ],
};

const FEATURES: Content = {
  login: [
    {
      title: "Save named rules",
      description:
        "Keep an organized list of rules you’ve written in the playground.",
    },
    {
      title: "Add GitHub organizations",
      description:
        "To manage policies and track Semgrep findings across repositories.",
    },
  ],
  install: [
    {
      title: "Set up Semgrep on your projects",
      description: "Scan new commits for issues automatically.",
    },
    {
      title: "See findings across your organization",
      description: "See the issues Semgrep found across all your repositories.",
    },
    {
      title: "Collaborate with other GitHub organization members",
      description:
        "Any member of your GitHub organization can join your Semgrep organization.",
    },
    {
      title: "Configure policies",
      description: "Fine-tune what rules should block merges or alert you.",
    },
    {
      title: "Set notification channels",
      description: "Get alerts via Slack or email.",
    },
  ],
  lite: [],
  code_access: [
    {
      title: "Use Semgrep Assistant",
      description: "Get AI-powered finding triage and fix suggestions.",
    },
  ],
};

interface Props {
  requestedFeature?: string;
}

const List = styled.ul`
  font-size: var(--mantine-font-size-md);
  list-style: none;
  padding: 0 1em;

  ${(props: CollapsingListProps) =>
    props.collapsed &&
    css`
      overflow-y: hidden;
      max-height: 10rem;
      mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
    `}

  li:not(:first-child) {
    margin-top: var(--mantine-spacing-sm);
  }
`;

interface CollapsingListProps {
  collapsed?: boolean;
}

const NO_WRITE_ONLY_PERMISSIONS_INFO_STICKER = (
  <Alert
    variant="outline"
    color="gray.6"
    title="Reading PRs, a single file, and workflows"
    icon={<FontAwesomeIcon icon={faQuestionCircle} />}
    mt="md"
  >
    Semgrep only uses write permissions on these resources, but those can only
    be requested along with read permissions. Semgrep never accesses your pull
    request data.
  </Alert>
);

export const PermissionsList: React.FC<Props> = ({ requestedFeature }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const permissions = [
    ...PERMISSIONS["install"],
    ...(requestedFeature && requestedFeature in PERMISSIONS
      ? PERMISSIONS[requestedFeature]
      : []),
  ];
  return (
    <div>
      <h2 style={{ fontSize: "var(--global-font-size-l)" }}>
        <FontAwesomeIcon fixedWidth icon={faUserShield} /> Semgrep asks for{" "}
        {permissions.length === 1
          ? "this permission"
          : `${permissions.length} permissions`}
        :
      </h2>
      <List collapsed={collapsed}>
        {permissions.map((item, key) => (
          <li key={key}>
            <div style={{ color: "var(--mantine-color-gray-9)" }}>
              {item.title}
            </div>
            <div style={{ color: "var(--mantine-color-gray-6)" }}>
              {item.description}
            </div>
          </li>
        ))}
        {NO_WRITE_ONLY_PERMISSIONS_INFO_STICKER}
      </List>
      {collapsed && (
        <Center>
          <Button
            color="gray"
            onClick={() => setCollapsed(false)}
            mx="auto"
            variant="outline"
          >
            Show more
          </Button>
        </Center>
      )}
    </div>
  );
};

export const FeaturesList: React.FC<Props> = ({ requestedFeature }) => {
  return (
    <div>
      <h2 style={{ fontSize: "var(--global-font-size-l)" }}>
        <FontAwesomeIcon fixedWidth icon={faCheckCircle} /> After installation,
        you'll be able to:
      </h2>
      <List>
        {FEATURES["install"].map((item, key) => (
          <li key={key}>
            <div style={{ color: "var(--mantine-color-gray-9)" }}>
              {item.title}
            </div>
            <div style={{ color: "var(--mantine-color-gray-6)" }}>
              {item.description}
            </div>
          </li>
        ))}
        {requestedFeature &&
          requestedFeature in FEATURES &&
          FEATURES[requestedFeature].map((item, key) => (
            <li key={key}>
              <div style={{ color: "var(--mantine-color-gray-9)" }}>
                {item.title}
              </div>
              <div style={{ color: "var(--mantine-color-gray-6)" }}>
                {item.description}
              </div>
            </li>
          ))}
      </List>
    </div>
  );
};
