import { useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { authGet } from "@shared/api";
import { DEPLOYMENT_URL } from "@shared/constants";
import { FetchLicensesResponse } from "@shared/types";

import { useOrg } from "../useOrg";

const fetchOrgLicenses = (
  deploymentId: number
): Promise<FetchLicensesResponse> => {
  const path = `${DEPLOYMENT_URL}/${deploymentId}/licenses`;
  return authGet<FetchLicensesResponse>(path);
};

/**
 * hook to fetch licenses for the current org. Org may be undefined if the user
 * is not logged in.
 * @return custom useQuery hook that fetches licenses and is enabled only when
 * the org is defined.
 */
export const useLicenses = () => {
  const [org] = useOrg();

  return useQuery({
    queryKey: ["licenses", { org }],

    queryFn: () =>
      fetchOrgLicenses(org!.id).then((response) => {
        return response.licenses;
      }),

    enabled: org !== undefined,
  });
};

export const useInvalidateLicenses = () => {
  const queryClient = useQueryClient();
  const [org] = useOrg();

  const invalidateLicenses = useCallback(() => {
    if (org === undefined) return;
    queryClient.invalidateQueries({
      queryKey: ["licenses", { org }],
    });
  }, [queryClient, org]);

  return { invalidateLicenses };
};
