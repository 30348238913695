import { Center } from "@mantine/core";
import { Spotlight } from "@mantine/spotlight";

import { useSpotlightActions } from "@shared/hooks";

/**
 * @returns Spotlight component for the logged-in app. Requires that user is
 * logged in and has an organization (not available during org onboarding).
 * @requires org is not undefined
 */
export const AppSpotlight = () => {
  const spotlightActions = useSpotlightActions();

  return (
    <Spotlight
      actions={spotlightActions}
      shortcut={["mod + k"]}
      searchProps={{
        placeholder: "Navigate to...",
      }}
      nothingFound={<Center>Nothing found...</Center>}
      disabled={spotlightActions.length === 0}
    />
  );
};
