import { RuleBoardPolicyName } from "@shared/types";

export const RULE_BOARD_TABS = [
  "monitor",
  "comment",
  "block",
  "disabled",
] as RuleBoardPolicyName[];

export const POLICY_TABS_TO_DISPLAY_NAME_MAPPING: Record<string, string> = {
  monitor: "Monitor mode",
  comment: "Comment mode",
  block: "Blocking mode",
};
