import {
  faBan,
  faExclamationCircle,
  faSearch,
  faTools,
} from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle, faMinus } from "@fortawesome/pro-solid-svg-icons";

import { FindingTriageReason } from "@protos/common/v1/common";

import { Finding } from "../findings";
import { Policy } from "../policy/policy";
import { ProductIgnoreMap, Repo } from "../repo/repo";
import { RbacRole } from "../settings/rbac";

import { AuthProvider } from "./authProvider";
import { SsoProviderType } from "./ssoProviderType";

export type TriageAction = "ignored" | "reviewing" | "fixing" | "reopened";
export type TriageToOptions = TriageAction | null;
export type TriageState =
  | "untriaged"
  | "ignored"
  | "reopened"
  | "reviewing"
  | "fixing";
export type AggregateState =
  | "open"
  | "ignored_app"
  | "ignored_code"
  | "reviewing"
  | "fixing"
  | "fixed"
  | "removed";
export type TriageReason = "false_positive" | "acceptable_risk" | "no_time";
export const TRIAGE_REASONS = {
  false_positive: "False positive",
  acceptable_risk: "Acceptable risk",
  no_time: "No time to fix",
};
export const TriageReasonsProto = [
  [FindingTriageReason.ACCEPTABLE_RISK, "Acceptable risk"],
  [FindingTriageReason.FALSE_POSITIVE, "False positive"],
  [FindingTriageReason.NO_TIME, "No time"],
];
export const TriageStateColorMapping = {
  ignored: "cyan.5",
  reopened: "blue.7",
  reviewing: "cyan.8",
  fixing: "green.7",
  fixed: "#2F9E44", // green.8 doesn't work properly
  removed: "dark.3",
};

export const TriageStateButtonIconMapping = {
  ignored: faBan,
  reopened: faExclamationCircle,
  reviewing: faSearch,
  fixing: faTools,
  fixed: faCheckCircle,
  removed: faMinus,
};

export interface UpdateFindingBody {
  triageState: TriageState | null;
  triageReason?: TriageReason;
  note?: string;
  ids?: number[];
}

export interface putDemoFindingsResponse {
  findings: Finding[];
}

export interface RepoChanges {
  name?: string | null;
  policies?: Policy[];
  ignored_files?: string[] | null;
  product_ignored_files?: Partial<ProductIgnoreMap>;
  inventory?: boolean;
  autofix?: boolean;
  tags?: string[];
  sca_parity_rules?: boolean;
  managed_scans?: { diff_scan: boolean };
  primary_ref_id?: number;
}

export interface DeploymentRepoResponse {
  repo: Repo;
}

export interface DeploymentRepoListResponse {
  repos: Repo[];
}

export interface DeploymentRepoPaginatedListResponse
  extends DeploymentRepoListResponse {
  cursor?: string;
}

export interface DeploymentReposRefreshResponse {
  new_repos: string[];
}

export interface DeploymentReposRefreshAsyncResponse {
  task_token_jwt: string; // NOTE: This can be `null` see (see FS-414)
}

export interface SubmitDeploymentRepoResponse extends Repo {}

export interface BulkPatchDeploymentRepoResponse {
  updated: Repo[];
}

export interface GitlabGroup {
  id: number;
  name: string;
  is_enabled: boolean;
}

export interface DeploymentRepoCIConfigResponse {
  config: string;
}

export interface FetchGitlabGroupsSelectionsResponse {
  gitlab_organizations: GitlabGroup[];
}

export interface UpdateGitlabGroupsSelectionsRequest {
  organizations: GitlabGroup[];
}

export interface UserPermissionsResponse {
  authorized_actions: string[];
}

export interface DeploymentTagsResponse {
  deployment_id: number;
  repository_tags: string[];
}

export interface Organization {
  id: number;
  name?: string;
  slug: string;
  deployments?: { id: number; name: string }[];
  default_user_role: RbacRole;
}

export interface SamlProviderSecurity {
  requestedAuthnContext?: string[];
}

export interface SamlProviderData {
  x509cert: string;
  idp_sso_url: string;
  idp_issuer: string;
  unspecifiedAuthnContext?: boolean;
}

export interface OpenIdProviderData {
  client_id: string;
  client_secret?: string;
  well_known_url?: string;
  auth_uri?: string;
  token_uri?: string;
  userinfo_uri?: string;
}
export interface SsoProviderSettings extends AuthProvider {
  deployment_id: number | null;
  provider_type: SsoProviderType;
  provider_data: OpenIdProviderData | SamlProviderData;
}

type SsoBaseFormData = {
  display_name: string;
  email_domain: string;
};
export type OpenIdFormData = OpenIdProviderData &
  SsoBaseFormData & { base_url: string };
export type SamlFormData = SamlProviderData & SsoBaseFormData;

export type ProviderData = SamlProviderData | OpenIdProviderData;

export interface GetSsoTokenEmailResponse {
  email_domain: string;
}

export interface PostSsoDeploymentSettings extends SsoProviderSettings {
  deployment_name: string;
  token: string;
}

export type RunScanRequest = {
  runs: { repo_id: number; branch?: string }[];
  type: string;
};

export type AutoScanTask = {
  repo_id: number;
  task_id: string;
};

export type AutoScanError = {
  repo_id: number;
  message: string;
};

export type StartAutoScanResponse = {
  tasks: AutoScanTask[];
  errors: AutoScanError[];
};

export type SbomExportAsyncResponse = {
  task_token_jwt: string;
};

export type FeedbackRequest = {
  feedback: string;
};
