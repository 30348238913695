import { PropsWithChildren } from "react";
import { Box, Group, Image, Stack, Title } from "@mantine/core";

import CodeBackground from "@assets/code-background.svg";
import Logo from "@assets/SemgrepLogoSmall.svg";

import styles from "./EditorQueryConsole.module.css";

export const EditorQueryConsoleEmptyState = ({
  children,
}: PropsWithChildren) => {
  return (
    <Stack align="center" gap="xs">
      <div className={styles.header}>
        <Image className={styles.headerBg} src={CodeBackground} />
        <Group className={styles.headerTitle} justify="center">
          <Image w={100} src={Logo} />
          <Title>Code search</Title>
        </Group>
      </div>
      <Box ta="center">
        <Title order={3}>Scan code from GitHub</Title>
        <p>
          Select from connected repositories with Code Access enabled or public
          repositories.
        </p>
      </Box>
      {children}
    </Stack>
  );
};
