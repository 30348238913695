import { useQuery } from "@tanstack/react-query";

import { authGet } from "@shared/api/lib/auth/auth";
import { loadPermissions } from "@shared/types";
import { UserPermissionsResponse } from "@shared/types";

import { useOrg } from "../useOrg";

const fetchUserPermissions = (
  deploymentId: number
): Promise<UserPermissionsResponse> => {
  return authGet<UserPermissionsResponse>(
    `/api/agent/deployments/${deploymentId}/authorized_actions`
  );
};

export const useFetchPermissions = () => {
  // may be called from a logged out state, so we need to handle the case where
  // org is undefined
  const [org] = useOrg();
  const deploymentId = org?.id;

  return useQuery({
    queryKey: ["permissions", deploymentId],
    queryFn: () =>
      fetchUserPermissions(deploymentId!).then(({ authorized_actions }) =>
        loadPermissions(authorized_actions)
      ),
    enabled: !!org,
  });
};
