import { useQuery } from "@tanstack/react-query";

import {
  GetSearchableReposRequest,
  GetSearchableReposResponse,
} from "@protos/search/v1/search_service";
import { authPost } from "@shared/api";
import { ApiError } from "@shared/types";

import { useOrg } from "../useOrg/useOrg";

const fetchSearchableRepos = (body: GetSearchableReposRequest) => {
  return authPost<GetSearchableReposRequest, GetSearchableReposResponse>(
    `/api/console/repos`,
    body
  );
};

export const useSearchableRepos = () => {
  const [org] = useOrg();
  return useQuery<GetSearchableReposResponse, ApiError>({
    queryKey: ["searchableRepos", org?.id, org],
    queryFn: () =>
      org
        ? fetchSearchableRepos({ deploymentId: org.id })
        : Promise.reject("No org present"),
    enabled: !!org?.id,
  });
};
