export const mobileMq = `max-width: 768px`;

type UnreachableExample = "axios" | "node-fetch" | "blitz";
type ReachableExample = "lodash" | "minimist";
export type Example = UnreachableExample | ReachableExample;

export type Screen = Example | "intro";

export const newGreen = "#17b97f";
export const lightGreen = "#04c17f";
export const scBackground = "#131a2c";
export const lightRed = "#E39494";
