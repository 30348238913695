import React, { useEffect, useMemo, useRef, useState } from "react";
import * as monaco from "monaco-editor";
import { Monaco } from "@monaco-editor/react";

import { MonacoEditor } from "@vendors/monaco";

import { useDebouncedResize } from "@shared/hooks";

import styles from "./WidgetPatternEditor.module.css";

interface Props {
  pattern: string;
  error?: boolean;
  language?: string;
  readOnly?: boolean;
  onPatternChange: (pattern: string) => void;
  minLines?: number;
}

const MAX_EDITOR_LINES = 10;
const PLACEHOLDER_TEXT = "Your pattern here...";

const getEditorHeight = (
  monacoInstance: Monaco,
  mountedEditor: monaco.editor.IStandaloneCodeEditor,
  minLines?: number
) => {
  const editorElement = mountedEditor.getDomNode();

  if (!editorElement) {
    return;
  }

  const lineHeight = mountedEditor.getOption(
    monacoInstance.editor.EditorOption.lineHeight
  );
  let lineCount = mountedEditor.getModel()?.getLineCount() || 1;
  lineCount = Math.min(lineCount, MAX_EDITOR_LINES);
  if (minLines) {
    lineCount = Math.max(lineCount, minLines);
  }
  const newHeight = lineCount * lineHeight + 7;
  return newHeight;
};

const PatternEditor: React.FC<Props> = ({
  pattern,
  onPatternChange,
  error,
  readOnly,
  language,
  minLines,
}) => {
  const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor>();
  const [monacoInstance, setMonacoInstance] = useState<Monaco>();
  const [height, setHeight] = useState<number>(0);

  const editorDidMount = useMemo(
    () =>
      (
        mountedEditor: monaco.editor.IStandaloneCodeEditor,
        monacoInstance: Monaco
      ) => {
        setEditor(mountedEditor);
        setMonacoInstance(monacoInstance);
        // from https://github.com/microsoft/monaco-editor/issues/794#issuecomment-583367666
        const updateEditorHeight = () => {
          const newHeight = getEditorHeight(
            monacoInstance,
            mountedEditor,
            minLines
          );
          if (newHeight) {
            setHeight(newHeight);
          }
        };
        mountedEditor.onDidChangeModelDecorations(updateEditorHeight);

        const model = mountedEditor.getModel();
        model?.updateOptions({ tabSize: 2 });
        updateEditorHeight();
      },
    [minLines, setEditor, setHeight]
  );

  const container = useRef<HTMLDivElement>(null!);
  useDebouncedResize(container, () => {
    if (editor === undefined) return;
    if (monacoInstance === undefined) return;
    // recalculate the height here as well
    editor.layout();
    const newHeight = getEditorHeight(monacoInstance, editor, minLines);
    if (newHeight) {
      setHeight(newHeight);
    }
  });
  useEffect(() => {
    editor?.layout();
  }, [editor, height]);

  return (
    <>
      {pattern === "" ? (
        <div className={styles.placeholder}>{PLACEHOLDER_TEXT}</div>
      ) : undefined}
      <MonacoEditor
        value={pattern}
        onChange={(value: string | undefined) => {
          value !== undefined && onPatternChange(value);
        }}
        onMount={editorDidMount}
        language={language ?? "yaml"}
        options={{
          automaticLayout: true,
          renderLineHighlight: "none",
          minimap: { enabled: false },
          wordWrap: "on",
          fontFamily:
            '"Jetbrains MonoVariable", "Jetbrains Mono", "Menlo", "Courier New", monospace',
          lineDecorationsWidth: 0,
          readOnly: readOnly,
          contextmenu: true,
          scrollBeyondLastLine: false,
          hideCursorInOverviewRuler: true,
          overviewRulerBorder: false,
          overviewRulerLanes: 1,
          scrollbar: { horizontal: "hidden", verticalScrollbarSize: 5 },
          wrappingIndent: "same",
          renderWhitespace: error ? "all" : "none",
          lineNumbers: "off",
        }}
      />
    </>
  );
};
export const WidgetPatternEditor = React.memo(PatternEditor);
