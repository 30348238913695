import { TimeFilter } from "@protos/issues/v1/issues";

export const TIME_LOOKBACK_TYPES_OPTIONS = {
  "Last opened": TimeFilter.RELEVANT_SINCE,
  "Last triaged": TimeFilter.TRIAGED_AT,
  "Last fixed": TimeFilter.FIXED_AT,
} as const;

export type TimeLookbackType = keyof typeof TIME_LOOKBACK_TYPES_OPTIONS;

export const TIME_LOOKBACK_TYPES = Object.keys(
  TIME_LOOKBACK_TYPES_OPTIONS
) as TimeLookbackType[];
