import { ReactNode, useCallback } from "react";
import { faFacePensive } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/react";

import { SESSION_CONTENTS } from "@shared/constants";
import { useAuth } from "@shared/hooks";
import { recoverBrowserSecurityError } from "@shared/utils";

import { RobotsNoindex } from "../RobotsNoIndex";

import Squares from "@assets/squares.svg?react";

import styles from "./AppCrashFallback.module.css";

// No Mantine components in this file because we want to render it outside the MantineProvider as a final fallback
export const AppCrashFallback: Sentry.FallbackRender = ({
  resetError,
  ...rest
}) => {
  const refresh = useCallback(
    () => (resetError ? resetError() : window.location.reload()),
    [resetError]
  );

  return (
    <div className={styles.shell}>
      <RobotsNoindex />
      <Squares className={styles.squares} />
      <div className={styles.card}>
        <ComponentFallback {...rest} resetError={refresh} />
      </div>
    </div>
  );
};

export const ComponentFallback: Sentry.FallbackRender = ({ resetError }) => {
  const { logout } = useAuth();

  return (
    <div style={{ padding: 30 }}>
      <div className={styles.header}>
        <FontAwesomeIcon icon={faFacePensive} size="6x" fixedWidth />
        <h1>Semgrep has crashed</h1>
      </div>

      <p>
        Sorry about this! We've been notified and will get to work on fixing the
        root cause. Please try refreshing the page.
      </p>
      <button
        className={`${styles.button} ${styles.primary}`}
        onClick={resetError}
      >
        Refresh
      </button>

      <p>Logging out may also fix it.</p>
      <button className={styles.button} onClick={logout}>
        Log out
      </button>

      <p>
        Wiping your editor session will lose your work from the editor, but it
        can fix some crashes that keep coming back when reloading the page.
      </p>
      <button
        className={styles.button}
        onClick={() => {
          recoverBrowserSecurityError(() =>
            localStorage.removeItem(SESSION_CONTENTS)
          );
          resetError && resetError();
        }}
      >
        Wipe editor session
      </button>

      <hr className={styles.hr} />

      <small>
        If you need immediate help, we're probably online! Let us know on{" "}
        <a href="https://go.semgrep.dev/slack" target="_blank" rel="noreferrer">
          Slack
        </a>
        .
      </small>
    </div>
  );
};

export const ErrorBoundary = ({
  children,
  Fallback = ComponentFallback,
}: {
  children: ReactNode;
  Fallback?: Sentry.FallbackRender;
}) => (
  <Sentry.ErrorBoundary fallback={(props) => <Fallback {...props} />}>
    {children}
  </Sentry.ErrorBoundary>
);
