import styled from "styled-components";

interface Props {
  columns?: number;
  minWidth?: number;
}

export const Grid = styled.div<Props>`
  display: grid;
  overflow-x: auto;
  grid-gap: 12px;
  grid-auto-rows: repeat(auto-fit);
  grid-template-columns: ${(props: Props) => {
    if (props.columns !== undefined) {
      return `repeat(${props.columns}, 1fr);`;
    } else if (props.minWidth !== undefined) {
      return `repeat(auto-fit, minmax(${props.minWidth}px, 1fr));`;
    } else {
      return `repeat(auto-fit, 1fr);`;
    }
  }};
`;
