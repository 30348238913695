import React from "react";
import styled from "styled-components";

import SemgrepLogo from "@assets/SemgrepLogoSmall.svg?react";
import SemgrepText from "@assets/SemgrepText.svg?react";

const Logo = styled.div`
  display: flex;
  align-items: center;
`;

export const BrandIcon = styled(SemgrepLogo)`
  height: 17px;
  width: 44px;
  transition: fill 0.3s ease, transform 0.3s ease;
  @media (prefers-reduced-motion) {
    transform: rotate(0deg) !important;
  }
  ${Logo}:hover & {
    transform: rotate(-180deg);
  }
`;

export const NavbarLogo: React.FC<{}> = () => (
  <Logo style={{ marginTop: -4 }}>
    <BrandIcon />
    <SemgrepText
      width="90px"
      height="20px"
      style={{ marginTop: 5, marginLeft: 5 }}
    />
  </Logo>
);
