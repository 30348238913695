import styled from "styled-components";

// Just a button that looks like a link!
export const LinkButton = styled.button`
  border: none;
  color: var(--mantine-color-blue-6);
  background: none;
  :hover {
    color: var(--mantine-color-blue-8);
    text-decoration: underline;
    cursor: pointer;
  }
`;
