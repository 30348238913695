import React, { useMemo, useRef } from "react";
import styled from "styled-components";

import { CopyButton } from "../CopyButton";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  copyable?: boolean;
  inline?: boolean;
  multiline?: boolean;
  command?: boolean;
  light?: boolean;
  dark?: boolean;
  hideOverflow?: boolean;
  title?: React.ReactNode;
  copyStyle?: React.CSSProperties;
}

const Container = styled.div`
  text-align: left;
  font-size: var(--mantine-font-size-xs);
  @media screen and (max-width: 415px) {
    font-size: 8px;
  }
  line-height: 16px;
  border-radius: 3px;
  background: ${(props: Props) =>
    props.light ? "#fefeff" : props.dark ? "#1a1a1a" : "#29343d"};
  color: ${(props: Props) => (props.light ? "#29343d" : "#f6f8fa")};
  box-shadow: 0 0 0 1px rgb(16 22 26 / 15%), 0 0 0 rgb(16 22 26 / 0%);

  & pre {
    margin: 0;
    padding: 9px 10px 7px;
    // there is no <code> in multiline, so we need to set padding-right
    // for the copy icon
    ${(props: Props) =>
      props.multiline &&
      props.copyable &&
      `
        padding-right: 24px;
      `};
    white-space: pre-wrap;
  }

  & pre code {
    display: inline-block;
    overflow-x: ${(props: Props) => (props.hideOverflow ? "hidden" : "auto")};
    white-space: nowrap;
    width: 100%;
    padding-right: ${(props: Props) => (props.copyable ? "15px" : "0")};
  }

  & code::before {
    content: "${(props: Props) => (props.command && "$ ") || ""}";
  }

  ${(props: Props) =>
    props.inline &&
    `
      display: inline;
      padding: 4px;
    `};
`;

const Title = styled.header`
  color: ${(props: Props) => (props.light ? "#2f383b" : "#d5d5d9")};
  background: ${(props: Props) =>
    props.light ? "#f4f4f5" : props.dark ? "black" : "#383f49"};
  font-size: var(--mantine-font-size-md);
  padding: 8px;
  border-bottom: 1px solid;
  border-bottom-color: ${(props: Props) =>
    props.light ? "#eaeaea" : "transparent"};
  border-radius: 3px 3px 0 0;
  margin-bottom: 4px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  color: ${(props: Props) => (props.light ? "#2f383b" : "#d5d5d9")};

  &:hover {
    color: ${(props: Props) => (props.light ? "#38353a" : "#e6e8ea")};
  }
`;

export const Code: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  copyable = false,
  inline = false,
  multiline = false,
  command = false,
  light = false,
  dark = false,
  hideOverflow = false,
  title = undefined,
  className = undefined,
  style = undefined,
  copyStyle = undefined,
}) => {
  const codeRef = useRef(null);

  const copyElement = useMemo<React.ReactNode | undefined>(
    () =>
      copyable && (
        <ButtonContainer light={light}>
          <CopyButton style={copyStyle} getTarget={() => codeRef.current} />
        </ButtonContainer>
      ),
    [copyable, light, copyStyle]
  );

  const codeElement = useMemo<React.ReactNode>(() => {
    const contents = (
      <>
        {copyElement}
        {children}
      </>
    );
    return multiline ? (
      <pre style={{ position: "relative" }} ref={codeRef}>
        {contents}
      </pre>
    ) : inline ? (
      <code style={{ position: "relative" }} ref={codeRef}>
        {contents}
      </code>
    ) : (
      <pre style={{ position: "relative" }}>
        <code ref={codeRef}>{contents}</code>
      </pre>
    );
  }, [children, copyElement, inline, multiline]);

  return (
    <Container
      as={inline ? "span" : "div"}
      className={className}
      style={style}
      copyable={copyable}
      multiline={multiline}
      inline={inline}
      command={command}
      light={light}
      dark={dark}
      hideOverflow={hideOverflow}
    >
      {title && (
        <Title light={light} dark={dark}>
          {title}
        </Title>
      )}
      {codeElement}
    </Container>
  );
};
