import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { useBasePath } from "@shared/hooks";

/* Query params provided through url */
type RedirectParams = {
  sections?: string;
};

/*
This component exists for the sole purposes of backwards-compatability.
Previously, the dashboard was not deep-linked to an org, and all of
the links at /orgs/<orgname>/:section were located at /manage/:section.
This component forwards links to /manage to go to the corresponding
screen but with deep-linking for orgs and the NavSidebar
*/
export const SmartRedirect: React.FC<RouteComponentProps<RedirectParams>> = ({
  match,
}) => {
  const sections = match.params.sections;
  const basePath = useBasePath();
  return <Redirect to={`${basePath}/${sections ?? ""}`} />;
};
