import { authPost } from "../auth";

import { PublishParams, PublishResponse } from "./types";

export const publishRule = (ruleName: string) =>
  // ruleName is the name of the rule in the registry, not the hashid.
  // example: python.flask.my_new_rule
  authPost<PublishParams, PublishResponse>(`/api/registry/rules/publish`, {
    ruleName,
  });
