import { Rule } from "@shared/types";

import { authGet } from "../auth/auth";

/**
 * if you're fetching the whole registry (startsWith is undefined),
 * use the useRegistryRules hook instead
 */
export const fetchRules = (startsWith?: string): Promise<Rule[]> => {
  const params: Record<string, string> = {
    definition: "1",
    test_cases: "1",
  };

  if (startsWith !== undefined) {
    params["starts_with"] = startsWith;
  }

  return authGet<Rule[]>(
    `/api/registry/rules?${new URLSearchParams(params).toString()}`
  );
};
