import sortBy from "lodash/sortBy";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { VISIBLE_LANGUAGES } from "@shared/utils";

const Cell = styled.td`
  padding: 0 var(--mantine-spacing-sm);
`;

const Container = styled.div``;

interface Props {
  selectedLanguages: string[];
}

const LanguagePanelComponent = ({ selectedLanguages }: Props) => {
  return (
    <Container>
      <h2>
        Supported Languages{" "}
        <sup>
          <a href="https://semgrep.dev/docs/language-support">docs</a>
        </sup>
      </h2>
      <div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Key</th>
              <th>Maturity</th>
            </tr>
          </thead>
          <tbody>
            {sortBy(Object.values(VISIBLE_LANGUAGES), "maturity")
              .reverse()
              .map((lang, i) => (
                <tr
                  key={i}
                  style={{
                    fontWeight:
                      lang.keys.filter(
                        (key) => selectedLanguages.indexOf(key) > -1
                      ).length > 0
                        ? "600"
                        : undefined,
                  }}
                >
                  <Cell>{lang.name}</Cell>
                  <Cell>
                    <code>{lang.keys[0]}</code>
                  </Cell>
                  <Cell>{lang.maturity}</Cell>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Container>
  );
};

export const LanguagePanel = observer(LanguagePanelComponent);
