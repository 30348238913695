import { useCallback } from "react";
import { useContext } from "react";
import React from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Group, Stack } from "@mantine/core";

import { WorkbenchContext } from "../../providers";

import { MenuDropdown, MenuDropdownItem } from "./components/MenuDropdown";
import {
  defaultMetavariableConstraintOfKind,
  InArray,
  MetavariableConstraint,
  MetavariableConstraintWithKind,
} from "./types/rule";
import { AnalyzerSelect } from "./utils/AnalyzerSelect";
import { StructureModeTextInput } from "./utils/StructureModeTextInput";
import { addIntoArray, removeFromInArray } from "./utils/utils";
import { PlusDeleteButtons } from "./Buttons";

const METAVARIABLE_CONSTRAINT_OPTIONS: MenuDropdownItem[] = [
  {
    name: "pattern",
    info: "Filter for when metavariable contains this pattern",
    enabled: true,
  },
  {
    name: "regex",
    info: "Filter for when metavariable matches this regex",
    enabled: true,
  },
  {
    name: "analyzer",
    info: "Filter for when metavariable fulfills this analyzer",
    enabled: true,
  },
  {
    name: "type",
    info: "Filter for when metavariable has a particular type",
    enabled: true,
  },
];
/******************************************************************************/
/* Metavariable constraints */
/******************************************************************************/

interface MetavariableConstraintSelectProps {
  value: string;
  metavariableConstraint: MetavariableConstraint;
}

const MetavariableConstraintSelectComponent: React.FC<
  MetavariableConstraintSelectProps
> = ({ value, metavariableConstraint }) => {
  const {
    workbench: { bundle },
  } = useContext(WorkbenchContext);

  const onChange = useCallback(
    (newKind: string | null) => {
      if (newKind === value) {
        return;
      }
      runInAction(() => {
        switch (newKind) {
          case null:
            // TODO: delete this rule segment when null?
            // this will not run unless we remove `allowDeselect={false}` from the Select component
            break;
          case "regex":
          case "analyzer":
          case "pattern":
          case "type":
            metavariableConstraint.value =
              defaultMetavariableConstraintOfKind(newKind);
        }
        bundle?.onStructureRuleChange();
        return;
      });
    },
    [bundle, value, metavariableConstraint]
  );

  return (
    <Group>
      <MenuDropdown
        items={METAVARIABLE_CONSTRAINT_OPTIONS}
        onChange={onChange}
        value={value}
      />
    </Group>
  );
};
const MetavariableConstraintSelect = observer(
  MetavariableConstraintSelectComponent
);

interface MetavariableConstraintNodeProps {
  metavariableConstraint: MetavariableConstraint;
  inArray: InArray<MetavariableConstraint> | null;
  isFocused: boolean;
}
const MetavariableConstraintNodeComponent: React.FC<
  MetavariableConstraintNodeProps
> = ({ metavariableConstraint, inArray, isFocused }) => {
  const {
    workbench: { bundle },
  } = useContext(WorkbenchContext);

  const mc = metavariableConstraint.value;

  const buttonsElem = (
    <PlusDeleteButtons
      onPlus={() =>
        addIntoArray(
          bundle,
          inArray,
          new MetavariableConstraint(
            defaultMetavariableConstraintOfKind("pattern")
          )
        )
      }
      onDelete={() => removeFromInArray(bundle, inArray)}
      isFocused={isFocused}
      containingArray={inArray?.parentArray}
      thingToDelete={"metavariable constraint"}
    />
  );

  switch (mc.kind) {
    case "analyzer":
      return (
        <Group gap={"var(--button-spacing)"}>
          <MetavariableConstraintSelect
            metavariableConstraint={metavariableConstraint}
            value={mc.kind}
          />
          <AnalyzerSelect
            value={mc.analyzer}
            metavariableConstraint={
              metavariableConstraint as MetavariableConstraintWithKind<"analyzer">
            }
          />
          {buttonsElem}
        </Group>
      );
    case "pattern":
      return (
        <Group>
          <MetavariableConstraintSelect
            metavariableConstraint={metavariableConstraint}
            value={mc.kind}
          />
          {buttonsElem}
        </Group>
      );
    case "regex": {
      const onChangeRegex = (s: string | undefined) => {
        if (s) {
          runInAction(() => {
            mc.regex = s;
          });
        }
      };

      return (
        <Stack
          style={{
            gap: "var(--intra-node-vertical-spacing)",
            // flexWrap needed so metavariable editor box doesn't wrap
            flexWrap: "nowrap",
          }}
        >
          <Group>
            <MetavariableConstraintSelect
              metavariableConstraint={metavariableConstraint}
              value={mc.kind}
            />
            {buttonsElem}
          </Group>
          <StructureModeTextInput
            value={mc.regex}
            onChange={onChangeRegex}
            isCode={false}
            placeholder="Enter a regex here..."
          />
        </Stack>
      );
    }
    case "type": {
      const onChangeType = (s: string | undefined) => {
        if (s) {
          runInAction(() => {
            mc.type = s;
          });
        }
      };

      return (
        <Stack
          style={{
            gap: "var(--intra-node-vertical-spacing)",
            // flexWrap needed so metavariable editor box doesn't wrap
            flexWrap: "nowrap",
          }}
        >
          <Group>
            <MetavariableConstraintSelect
              metavariableConstraint={metavariableConstraint}
              value={mc.kind}
            />
            {buttonsElem}
          </Group>
          <StructureModeTextInput
            value={mc.type}
            onChange={onChangeType}
            isCode={false}
            placeholder={"int"}
          />
        </Stack>
      );
    }
  }
};
export const MetavariableConstraintNode = observer(
  MetavariableConstraintNodeComponent
);
