// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/auth/common/v1/common.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "protos.auth.common.v1";

export enum Role {
  UNSPECIFIED = "ROLE_UNSPECIFIED",
  ADMINISTRATOR = "ROLE_ADMINISTRATOR",
  MANAGER = "ROLE_MANAGER",
  DEVELOPER = "ROLE_DEVELOPER",
  READ_ONLY = "ROLE_READ_ONLY",
  NO_ACCESS = "ROLE_NO_ACCESS",
}

export function roleFromJSON(object: any): Role {
  switch (object) {
    case 0:
    case "ROLE_UNSPECIFIED":
      return Role.UNSPECIFIED;
    case 1:
    case "ROLE_ADMINISTRATOR":
      return Role.ADMINISTRATOR;
    case 10:
    case "ROLE_MANAGER":
      return Role.MANAGER;
    case 20:
    case "ROLE_DEVELOPER":
      return Role.DEVELOPER;
    case 99:
    case "ROLE_READ_ONLY":
      return Role.READ_ONLY;
    case 100:
    case "ROLE_NO_ACCESS":
      return Role.NO_ACCESS;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Role");
  }
}

export function roleToJSON(object: Role): string {
  switch (object) {
    case Role.UNSPECIFIED:
      return "ROLE_UNSPECIFIED";
    case Role.ADMINISTRATOR:
      return "ROLE_ADMINISTRATOR";
    case Role.MANAGER:
      return "ROLE_MANAGER";
    case Role.DEVELOPER:
      return "ROLE_DEVELOPER";
    case Role.READ_ONLY:
      return "ROLE_READ_ONLY";
    case Role.NO_ACCESS:
      return "ROLE_NO_ACCESS";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Role");
  }
}

export function roleToNumber(object: Role): number {
  switch (object) {
    case Role.UNSPECIFIED:
      return 0;
    case Role.ADMINISTRATOR:
      return 1;
    case Role.MANAGER:
      return 10;
    case Role.DEVELOPER:
      return 20;
    case Role.READ_ONLY:
      return 99;
    case Role.NO_ACCESS:
      return 100;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Role");
  }
}

export enum OrgScope {
  UNSPECIFIED = "ORG_SCOPE_UNSPECIFIED",
  MEMBER = "ORG_SCOPE_MEMBER",
  DEVELOP = "ORG_SCOPE_DEVELOP",
  MANAGE = "ORG_SCOPE_MANAGE",
  ADMINISTRATE = "ORG_SCOPE_ADMINISTRATE",
  MANAGE_ORGANIZATION_MEMBERSHIP = "ORG_SCOPE_MANAGE_ORGANIZATION_MEMBERSHIP",
  MANAGE_ORGANIZATION_RESOURCES = "ORG_SCOPE_MANAGE_ORGANIZATION_RESOURCES",
  MANAGE_ORGANIZATION_ADMINS = "ORG_SCOPE_MANAGE_ORGANIZATION_ADMINS",
  MANAGE_ORGANIZATION_SETTINGS = "ORG_SCOPE_MANAGE_ORGANIZATION_SETTINGS",
  MANAGE_ORGANIZATION_POLICIES = "ORG_SCOPE_MANAGE_ORGANIZATION_POLICIES",
  MANAGE_GROUP = "ORG_SCOPE_MANAGE_GROUP",
  READ_ORGANIZATION_SETTINGS = "ORG_SCOPE_READ_ORGANIZATION_SETTINGS",
  READ_POLICY = "ORG_SCOPE_READ_POLICY",
}

export function orgScopeFromJSON(object: any): OrgScope {
  switch (object) {
    case 0:
    case "ORG_SCOPE_UNSPECIFIED":
      return OrgScope.UNSPECIFIED;
    case 1:
    case "ORG_SCOPE_MEMBER":
      return OrgScope.MEMBER;
    case 2:
    case "ORG_SCOPE_DEVELOP":
      return OrgScope.DEVELOP;
    case 3:
    case "ORG_SCOPE_MANAGE":
      return OrgScope.MANAGE;
    case 4:
    case "ORG_SCOPE_ADMINISTRATE":
      return OrgScope.ADMINISTRATE;
    case 10:
    case "ORG_SCOPE_MANAGE_ORGANIZATION_MEMBERSHIP":
      return OrgScope.MANAGE_ORGANIZATION_MEMBERSHIP;
    case 11:
    case "ORG_SCOPE_MANAGE_ORGANIZATION_RESOURCES":
      return OrgScope.MANAGE_ORGANIZATION_RESOURCES;
    case 12:
    case "ORG_SCOPE_MANAGE_ORGANIZATION_ADMINS":
      return OrgScope.MANAGE_ORGANIZATION_ADMINS;
    case 13:
    case "ORG_SCOPE_MANAGE_ORGANIZATION_SETTINGS":
      return OrgScope.MANAGE_ORGANIZATION_SETTINGS;
    case 14:
    case "ORG_SCOPE_MANAGE_ORGANIZATION_POLICIES":
      return OrgScope.MANAGE_ORGANIZATION_POLICIES;
    case 20:
    case "ORG_SCOPE_MANAGE_GROUP":
      return OrgScope.MANAGE_GROUP;
    case 30:
    case "ORG_SCOPE_READ_ORGANIZATION_SETTINGS":
      return OrgScope.READ_ORGANIZATION_SETTINGS;
    case 50:
    case "ORG_SCOPE_READ_POLICY":
      return OrgScope.READ_POLICY;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OrgScope");
  }
}

export function orgScopeToJSON(object: OrgScope): string {
  switch (object) {
    case OrgScope.UNSPECIFIED:
      return "ORG_SCOPE_UNSPECIFIED";
    case OrgScope.MEMBER:
      return "ORG_SCOPE_MEMBER";
    case OrgScope.DEVELOP:
      return "ORG_SCOPE_DEVELOP";
    case OrgScope.MANAGE:
      return "ORG_SCOPE_MANAGE";
    case OrgScope.ADMINISTRATE:
      return "ORG_SCOPE_ADMINISTRATE";
    case OrgScope.MANAGE_ORGANIZATION_MEMBERSHIP:
      return "ORG_SCOPE_MANAGE_ORGANIZATION_MEMBERSHIP";
    case OrgScope.MANAGE_ORGANIZATION_RESOURCES:
      return "ORG_SCOPE_MANAGE_ORGANIZATION_RESOURCES";
    case OrgScope.MANAGE_ORGANIZATION_ADMINS:
      return "ORG_SCOPE_MANAGE_ORGANIZATION_ADMINS";
    case OrgScope.MANAGE_ORGANIZATION_SETTINGS:
      return "ORG_SCOPE_MANAGE_ORGANIZATION_SETTINGS";
    case OrgScope.MANAGE_ORGANIZATION_POLICIES:
      return "ORG_SCOPE_MANAGE_ORGANIZATION_POLICIES";
    case OrgScope.MANAGE_GROUP:
      return "ORG_SCOPE_MANAGE_GROUP";
    case OrgScope.READ_ORGANIZATION_SETTINGS:
      return "ORG_SCOPE_READ_ORGANIZATION_SETTINGS";
    case OrgScope.READ_POLICY:
      return "ORG_SCOPE_READ_POLICY";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OrgScope");
  }
}

export function orgScopeToNumber(object: OrgScope): number {
  switch (object) {
    case OrgScope.UNSPECIFIED:
      return 0;
    case OrgScope.MEMBER:
      return 1;
    case OrgScope.DEVELOP:
      return 2;
    case OrgScope.MANAGE:
      return 3;
    case OrgScope.ADMINISTRATE:
      return 4;
    case OrgScope.MANAGE_ORGANIZATION_MEMBERSHIP:
      return 10;
    case OrgScope.MANAGE_ORGANIZATION_RESOURCES:
      return 11;
    case OrgScope.MANAGE_ORGANIZATION_ADMINS:
      return 12;
    case OrgScope.MANAGE_ORGANIZATION_SETTINGS:
      return 13;
    case OrgScope.MANAGE_ORGANIZATION_POLICIES:
      return 14;
    case OrgScope.MANAGE_GROUP:
      return 20;
    case OrgScope.READ_ORGANIZATION_SETTINGS:
      return 30;
    case OrgScope.READ_POLICY:
      return 50;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OrgScope");
  }
}

export enum GroupScope {
  UNSPECIFIED = "GROUP_SCOPE_UNSPECIFIED",
  MEMBER = "GROUP_SCOPE_MEMBER",
  DEVELOP = "GROUP_SCOPE_DEVELOP",
  MANAGE = "GROUP_SCOPE_MANAGE",
  ADMINISTRATE = "GROUP_SCOPE_ADMINISTRATE",
  MANAGE_GROUP_MEMBERSHIP = "GROUP_SCOPE_MANAGE_GROUP_MEMBERSHIP",
  MANAGE_GROUP_RESOURCES = "GROUP_SCOPE_MANAGE_GROUP_RESOURCES",
}

export function groupScopeFromJSON(object: any): GroupScope {
  switch (object) {
    case 0:
    case "GROUP_SCOPE_UNSPECIFIED":
      return GroupScope.UNSPECIFIED;
    case 1:
    case "GROUP_SCOPE_MEMBER":
      return GroupScope.MEMBER;
    case 2:
    case "GROUP_SCOPE_DEVELOP":
      return GroupScope.DEVELOP;
    case 3:
    case "GROUP_SCOPE_MANAGE":
      return GroupScope.MANAGE;
    case 4:
    case "GROUP_SCOPE_ADMINISTRATE":
      return GroupScope.ADMINISTRATE;
    case 10:
    case "GROUP_SCOPE_MANAGE_GROUP_MEMBERSHIP":
      return GroupScope.MANAGE_GROUP_MEMBERSHIP;
    case 11:
    case "GROUP_SCOPE_MANAGE_GROUP_RESOURCES":
      return GroupScope.MANAGE_GROUP_RESOURCES;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum GroupScope");
  }
}

export function groupScopeToJSON(object: GroupScope): string {
  switch (object) {
    case GroupScope.UNSPECIFIED:
      return "GROUP_SCOPE_UNSPECIFIED";
    case GroupScope.MEMBER:
      return "GROUP_SCOPE_MEMBER";
    case GroupScope.DEVELOP:
      return "GROUP_SCOPE_DEVELOP";
    case GroupScope.MANAGE:
      return "GROUP_SCOPE_MANAGE";
    case GroupScope.ADMINISTRATE:
      return "GROUP_SCOPE_ADMINISTRATE";
    case GroupScope.MANAGE_GROUP_MEMBERSHIP:
      return "GROUP_SCOPE_MANAGE_GROUP_MEMBERSHIP";
    case GroupScope.MANAGE_GROUP_RESOURCES:
      return "GROUP_SCOPE_MANAGE_GROUP_RESOURCES";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum GroupScope");
  }
}

export function groupScopeToNumber(object: GroupScope): number {
  switch (object) {
    case GroupScope.UNSPECIFIED:
      return 0;
    case GroupScope.MEMBER:
      return 1;
    case GroupScope.DEVELOP:
      return 2;
    case GroupScope.MANAGE:
      return 3;
    case GroupScope.ADMINISTRATE:
      return 4;
    case GroupScope.MANAGE_GROUP_MEMBERSHIP:
      return 10;
    case GroupScope.MANAGE_GROUP_RESOURCES:
      return 11;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum GroupScope");
  }
}

export enum ResourceScope {
  UNSPECIFIED = "RESOURCE_SCOPE_UNSPECIFIED",
  READ_FINDING = "RESOURCE_SCOPE_READ_FINDING",
  CREATE_FINDING = "RESOURCE_SCOPE_CREATE_FINDING",
  TRIAGE_FINDING = "RESOURCE_SCOPE_TRIAGE_FINDING",
  DELETE_FINDING = "RESOURCE_SCOPE_DELETE_FINDING",
  MANAGE_PROJECT_SETTINGS = "RESOURCE_SCOPE_MANAGE_PROJECT_SETTINGS",
  DELETE_PROJECT = "RESOURCE_SCOPE_DELETE_PROJECT",
  ASSIGN_GROUP = "RESOURCE_SCOPE_ASSIGN_GROUP",
}

export function resourceScopeFromJSON(object: any): ResourceScope {
  switch (object) {
    case 0:
    case "RESOURCE_SCOPE_UNSPECIFIED":
      return ResourceScope.UNSPECIFIED;
    case 10:
    case "RESOURCE_SCOPE_READ_FINDING":
      return ResourceScope.READ_FINDING;
    case 11:
    case "RESOURCE_SCOPE_CREATE_FINDING":
      return ResourceScope.CREATE_FINDING;
    case 12:
    case "RESOURCE_SCOPE_TRIAGE_FINDING":
      return ResourceScope.TRIAGE_FINDING;
    case 13:
    case "RESOURCE_SCOPE_DELETE_FINDING":
      return ResourceScope.DELETE_FINDING;
    case 50:
    case "RESOURCE_SCOPE_MANAGE_PROJECT_SETTINGS":
      return ResourceScope.MANAGE_PROJECT_SETTINGS;
    case 100:
    case "RESOURCE_SCOPE_DELETE_PROJECT":
      return ResourceScope.DELETE_PROJECT;
    case 101:
    case "RESOURCE_SCOPE_ASSIGN_GROUP":
      return ResourceScope.ASSIGN_GROUP;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ResourceScope");
  }
}

export function resourceScopeToJSON(object: ResourceScope): string {
  switch (object) {
    case ResourceScope.UNSPECIFIED:
      return "RESOURCE_SCOPE_UNSPECIFIED";
    case ResourceScope.READ_FINDING:
      return "RESOURCE_SCOPE_READ_FINDING";
    case ResourceScope.CREATE_FINDING:
      return "RESOURCE_SCOPE_CREATE_FINDING";
    case ResourceScope.TRIAGE_FINDING:
      return "RESOURCE_SCOPE_TRIAGE_FINDING";
    case ResourceScope.DELETE_FINDING:
      return "RESOURCE_SCOPE_DELETE_FINDING";
    case ResourceScope.MANAGE_PROJECT_SETTINGS:
      return "RESOURCE_SCOPE_MANAGE_PROJECT_SETTINGS";
    case ResourceScope.DELETE_PROJECT:
      return "RESOURCE_SCOPE_DELETE_PROJECT";
    case ResourceScope.ASSIGN_GROUP:
      return "RESOURCE_SCOPE_ASSIGN_GROUP";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ResourceScope");
  }
}

export function resourceScopeToNumber(object: ResourceScope): number {
  switch (object) {
    case ResourceScope.UNSPECIFIED:
      return 0;
    case ResourceScope.READ_FINDING:
      return 10;
    case ResourceScope.CREATE_FINDING:
      return 11;
    case ResourceScope.TRIAGE_FINDING:
      return 12;
    case ResourceScope.DELETE_FINDING:
      return 13;
    case ResourceScope.MANAGE_PROJECT_SETTINGS:
      return 50;
    case ResourceScope.DELETE_PROJECT:
      return 100;
    case ResourceScope.ASSIGN_GROUP:
      return 101;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ResourceScope");
  }
}

export interface UserId {
  /** tenant is the namespace of the calling service (e.g. "default") */
  tenant: string;
  /** user_id is a unique identifier of the user (e.g. "123") */
  userId: string;
}

export interface OrganizationId {
  /** tenant is the namespace of the calling service (e.g. "default") */
  tenant: string;
  /** organization_id is a unique identifier of the organization (e.g. "123") */
  organizationId: string;
}

export interface GroupId {
  organizationId:
    | OrganizationId
    | undefined;
  /** group_id is a unique identifier of the group (e.g. "123") */
  groupId: string;
}

export interface Scope {
  /** A permission that a user requests on a specific resource */
  scope: string;
  value?: { $case: "organization"; organization: OrgScope } | { $case: "group"; group: GroupScope } | {
    $case: "resource";
    resource: ResourceScope;
  } | undefined;
}

export interface ResourceId {
  organizationId:
    | OrganizationId
    | undefined;
  /** resource_id is a unique identifier of the resource (e.g. "567") */
  resourceId: string;
}

export interface Resource {
  id: ResourceId | undefined;
  authorizedScopes: ResourceScope[];
}

export interface GroupUser {
  groupId: GroupId | undefined;
  userId: UserId | undefined;
  role: Role;
}

export interface GroupResource {
  groupId: GroupId | undefined;
  resourceId: ResourceId | undefined;
}

export interface User {
  id:
    | UserId
    | undefined;
  /** Optional fields, only set if explicitly requested */
  organizationIds: OrganizationId[];
  groups: GroupUser[];
  organizationScopes: OrganizationScopes[];
}

export interface UserIdWithRole {
  id: UserId | undefined;
  role: Role;
}

export interface Group {
  id:
    | GroupId
    | undefined;
  /** Optional fields, only set if explicitly requested */
  parentGroups: GroupId[];
  resources: GroupResource[];
  groups: GroupId[];
  members: GroupUser[];
  authorizedScopes: GroupScope[];
}

export interface Organization {
  id:
    | OrganizationId
    | undefined;
  /** Optional fields, only set if explicitly requested */
  administrators: UserIdWithRole[];
  groups: GroupId[];
  authorizedScopes: OrgScope[];
}

export interface OrganizationScopes {
  id: OrganizationId | undefined;
  scopes: OrgScope[];
}

export interface EntityId {
  id?:
    | { $case: "resource"; resource: ResourceId }
    | { $case: "group"; group: GroupId }
    | { $case: "organization"; organization: OrganizationId }
    | { $case: "user"; user: UserId }
    | undefined;
}

export interface TransactionId {
  id: string;
}

function createBaseUserId(): UserId {
  return { tenant: "", userId: "" };
}

export const UserId = {
  encode(message: UserId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== "") {
      writer.uint32(10).string(message.tenant);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenant = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserId {
    return {
      tenant: isSet(object.tenant) ? globalThis.String(object.tenant) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
    };
  },

  toJSON(message: UserId): unknown {
    const obj: any = {};
    if (message.tenant !== "") {
      obj.tenant = message.tenant;
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserId>, I>>(base?: I): UserId {
    return UserId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserId>, I>>(object: I): UserId {
    const message = createBaseUserId();
    message.tenant = object.tenant ?? "";
    message.userId = object.userId ?? "";
    return message;
  },
};

function createBaseOrganizationId(): OrganizationId {
  return { tenant: "", organizationId: "" };
}

export const OrganizationId = {
  encode(message: OrganizationId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== "") {
      writer.uint32(10).string(message.tenant);
    }
    if (message.organizationId !== "") {
      writer.uint32(18).string(message.organizationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganizationId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenant = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organizationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrganizationId {
    return {
      tenant: isSet(object.tenant) ? globalThis.String(object.tenant) : "",
      organizationId: isSet(object.organizationId) ? globalThis.String(object.organizationId) : "",
    };
  },

  toJSON(message: OrganizationId): unknown {
    const obj: any = {};
    if (message.tenant !== "") {
      obj.tenant = message.tenant;
    }
    if (message.organizationId !== "") {
      obj.organizationId = message.organizationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrganizationId>, I>>(base?: I): OrganizationId {
    return OrganizationId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrganizationId>, I>>(object: I): OrganizationId {
    const message = createBaseOrganizationId();
    message.tenant = object.tenant ?? "";
    message.organizationId = object.organizationId ?? "";
    return message;
  },
};

function createBaseGroupId(): GroupId {
  return { organizationId: undefined, groupId: "" };
}

export const GroupId = {
  encode(message: GroupId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organizationId !== undefined) {
      OrganizationId.encode(message.organizationId, writer.uint32(18).fork()).ldelim();
    }
    if (message.groupId !== "") {
      writer.uint32(26).string(message.groupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GroupId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroupId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organizationId = OrganizationId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.groupId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GroupId {
    return {
      organizationId: isSet(object.organizationId) ? OrganizationId.fromJSON(object.organizationId) : undefined,
      groupId: isSet(object.groupId) ? globalThis.String(object.groupId) : "",
    };
  },

  toJSON(message: GroupId): unknown {
    const obj: any = {};
    if (message.organizationId !== undefined) {
      obj.organizationId = OrganizationId.toJSON(message.organizationId);
    }
    if (message.groupId !== "") {
      obj.groupId = message.groupId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GroupId>, I>>(base?: I): GroupId {
    return GroupId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GroupId>, I>>(object: I): GroupId {
    const message = createBaseGroupId();
    message.organizationId = (object.organizationId !== undefined && object.organizationId !== null)
      ? OrganizationId.fromPartial(object.organizationId)
      : undefined;
    message.groupId = object.groupId ?? "";
    return message;
  },
};

function createBaseScope(): Scope {
  return { scope: "", value: undefined };
}

export const Scope = {
  encode(message: Scope, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scope !== "") {
      writer.uint32(10).string(message.scope);
    }
    switch (message.value?.$case) {
      case "organization":
        writer.uint32(16).int32(orgScopeToNumber(message.value.organization));
        break;
      case "group":
        writer.uint32(24).int32(groupScopeToNumber(message.value.group));
        break;
      case "resource":
        writer.uint32(32).int32(resourceScopeToNumber(message.value.resource));
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Scope {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScope();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scope = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = { $case: "organization", organization: orgScopeFromJSON(reader.int32()) };
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.value = { $case: "group", group: groupScopeFromJSON(reader.int32()) };
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.value = { $case: "resource", resource: resourceScopeFromJSON(reader.int32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Scope {
    return {
      scope: isSet(object.scope) ? globalThis.String(object.scope) : "",
      value: isSet(object.organization)
        ? { $case: "organization", organization: orgScopeFromJSON(object.organization) }
        : isSet(object.group)
        ? { $case: "group", group: groupScopeFromJSON(object.group) }
        : isSet(object.resource)
        ? { $case: "resource", resource: resourceScopeFromJSON(object.resource) }
        : undefined,
    };
  },

  toJSON(message: Scope): unknown {
    const obj: any = {};
    if (message.scope !== "") {
      obj.scope = message.scope;
    }
    if (message.value?.$case === "organization") {
      obj.organization = orgScopeToJSON(message.value.organization);
    }
    if (message.value?.$case === "group") {
      obj.group = groupScopeToJSON(message.value.group);
    }
    if (message.value?.$case === "resource") {
      obj.resource = resourceScopeToJSON(message.value.resource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Scope>, I>>(base?: I): Scope {
    return Scope.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Scope>, I>>(object: I): Scope {
    const message = createBaseScope();
    message.scope = object.scope ?? "";
    if (
      object.value?.$case === "organization" &&
      object.value?.organization !== undefined &&
      object.value?.organization !== null
    ) {
      message.value = { $case: "organization", organization: object.value.organization };
    }
    if (object.value?.$case === "group" && object.value?.group !== undefined && object.value?.group !== null) {
      message.value = { $case: "group", group: object.value.group };
    }
    if (object.value?.$case === "resource" && object.value?.resource !== undefined && object.value?.resource !== null) {
      message.value = { $case: "resource", resource: object.value.resource };
    }
    return message;
  },
};

function createBaseResourceId(): ResourceId {
  return { organizationId: undefined, resourceId: "" };
}

export const ResourceId = {
  encode(message: ResourceId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organizationId !== undefined) {
      OrganizationId.encode(message.organizationId, writer.uint32(10).fork()).ldelim();
    }
    if (message.resourceId !== "") {
      writer.uint32(18).string(message.resourceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResourceId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResourceId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organizationId = OrganizationId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.resourceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResourceId {
    return {
      organizationId: isSet(object.organizationId) ? OrganizationId.fromJSON(object.organizationId) : undefined,
      resourceId: isSet(object.resourceId) ? globalThis.String(object.resourceId) : "",
    };
  },

  toJSON(message: ResourceId): unknown {
    const obj: any = {};
    if (message.organizationId !== undefined) {
      obj.organizationId = OrganizationId.toJSON(message.organizationId);
    }
    if (message.resourceId !== "") {
      obj.resourceId = message.resourceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ResourceId>, I>>(base?: I): ResourceId {
    return ResourceId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResourceId>, I>>(object: I): ResourceId {
    const message = createBaseResourceId();
    message.organizationId = (object.organizationId !== undefined && object.organizationId !== null)
      ? OrganizationId.fromPartial(object.organizationId)
      : undefined;
    message.resourceId = object.resourceId ?? "";
    return message;
  },
};

function createBaseResource(): Resource {
  return { id: undefined, authorizedScopes: [] };
}

export const Resource = {
  encode(message: Resource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      ResourceId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(162).fork();
    for (const v of message.authorizedScopes) {
      writer.int32(resourceScopeToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Resource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = ResourceId.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag === 160) {
            message.authorizedScopes.push(resourceScopeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 162) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.authorizedScopes.push(resourceScopeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Resource {
    return {
      id: isSet(object.id) ? ResourceId.fromJSON(object.id) : undefined,
      authorizedScopes: globalThis.Array.isArray(object?.authorizedScopes)
        ? object.authorizedScopes.map((e: any) => resourceScopeFromJSON(e))
        : [],
    };
  },

  toJSON(message: Resource): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = ResourceId.toJSON(message.id);
    }
    if (message.authorizedScopes?.length) {
      obj.authorizedScopes = message.authorizedScopes.map((e) => resourceScopeToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Resource>, I>>(base?: I): Resource {
    return Resource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Resource>, I>>(object: I): Resource {
    const message = createBaseResource();
    message.id = (object.id !== undefined && object.id !== null) ? ResourceId.fromPartial(object.id) : undefined;
    message.authorizedScopes = object.authorizedScopes?.map((e) => e) || [];
    return message;
  },
};

function createBaseGroupUser(): GroupUser {
  return { groupId: undefined, userId: undefined, role: Role.UNSPECIFIED };
}

export const GroupUser = {
  encode(message: GroupUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== undefined) {
      GroupId.encode(message.groupId, writer.uint32(10).fork()).ldelim();
    }
    if (message.userId !== undefined) {
      UserId.encode(message.userId, writer.uint32(18).fork()).ldelim();
    }
    if (message.role !== Role.UNSPECIFIED) {
      writer.uint32(24).int32(roleToNumber(message.role));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GroupUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroupUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = GroupId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = UserId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.role = roleFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GroupUser {
    return {
      groupId: isSet(object.groupId) ? GroupId.fromJSON(object.groupId) : undefined,
      userId: isSet(object.userId) ? UserId.fromJSON(object.userId) : undefined,
      role: isSet(object.role) ? roleFromJSON(object.role) : Role.UNSPECIFIED,
    };
  },

  toJSON(message: GroupUser): unknown {
    const obj: any = {};
    if (message.groupId !== undefined) {
      obj.groupId = GroupId.toJSON(message.groupId);
    }
    if (message.userId !== undefined) {
      obj.userId = UserId.toJSON(message.userId);
    }
    if (message.role !== Role.UNSPECIFIED) {
      obj.role = roleToJSON(message.role);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GroupUser>, I>>(base?: I): GroupUser {
    return GroupUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GroupUser>, I>>(object: I): GroupUser {
    const message = createBaseGroupUser();
    message.groupId = (object.groupId !== undefined && object.groupId !== null)
      ? GroupId.fromPartial(object.groupId)
      : undefined;
    message.userId = (object.userId !== undefined && object.userId !== null)
      ? UserId.fromPartial(object.userId)
      : undefined;
    message.role = object.role ?? Role.UNSPECIFIED;
    return message;
  },
};

function createBaseGroupResource(): GroupResource {
  return { groupId: undefined, resourceId: undefined };
}

export const GroupResource = {
  encode(message: GroupResource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== undefined) {
      GroupId.encode(message.groupId, writer.uint32(10).fork()).ldelim();
    }
    if (message.resourceId !== undefined) {
      ResourceId.encode(message.resourceId, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GroupResource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroupResource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = GroupId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.resourceId = ResourceId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GroupResource {
    return {
      groupId: isSet(object.groupId) ? GroupId.fromJSON(object.groupId) : undefined,
      resourceId: isSet(object.resourceId) ? ResourceId.fromJSON(object.resourceId) : undefined,
    };
  },

  toJSON(message: GroupResource): unknown {
    const obj: any = {};
    if (message.groupId !== undefined) {
      obj.groupId = GroupId.toJSON(message.groupId);
    }
    if (message.resourceId !== undefined) {
      obj.resourceId = ResourceId.toJSON(message.resourceId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GroupResource>, I>>(base?: I): GroupResource {
    return GroupResource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GroupResource>, I>>(object: I): GroupResource {
    const message = createBaseGroupResource();
    message.groupId = (object.groupId !== undefined && object.groupId !== null)
      ? GroupId.fromPartial(object.groupId)
      : undefined;
    message.resourceId = (object.resourceId !== undefined && object.resourceId !== null)
      ? ResourceId.fromPartial(object.resourceId)
      : undefined;
    return message;
  },
};

function createBaseUser(): User {
  return { id: undefined, organizationIds: [], groups: [], organizationScopes: [] };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      UserId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.organizationIds) {
      OrganizationId.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.groups) {
      GroupUser.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.organizationScopes) {
      OrganizationScopes.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = UserId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organizationIds.push(OrganizationId.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.groups.push(GroupUser.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.organizationScopes.push(OrganizationScopes.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      id: isSet(object.id) ? UserId.fromJSON(object.id) : undefined,
      organizationIds: globalThis.Array.isArray(object?.organizationIds)
        ? object.organizationIds.map((e: any) => OrganizationId.fromJSON(e))
        : [],
      groups: globalThis.Array.isArray(object?.groups) ? object.groups.map((e: any) => GroupUser.fromJSON(e)) : [],
      organizationScopes: globalThis.Array.isArray(object?.organizationScopes)
        ? object.organizationScopes.map((e: any) => OrganizationScopes.fromJSON(e))
        : [],
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = UserId.toJSON(message.id);
    }
    if (message.organizationIds?.length) {
      obj.organizationIds = message.organizationIds.map((e) => OrganizationId.toJSON(e));
    }
    if (message.groups?.length) {
      obj.groups = message.groups.map((e) => GroupUser.toJSON(e));
    }
    if (message.organizationScopes?.length) {
      obj.organizationScopes = message.organizationScopes.map((e) => OrganizationScopes.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.id = (object.id !== undefined && object.id !== null) ? UserId.fromPartial(object.id) : undefined;
    message.organizationIds = object.organizationIds?.map((e) => OrganizationId.fromPartial(e)) || [];
    message.groups = object.groups?.map((e) => GroupUser.fromPartial(e)) || [];
    message.organizationScopes = object.organizationScopes?.map((e) => OrganizationScopes.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserIdWithRole(): UserIdWithRole {
  return { id: undefined, role: Role.UNSPECIFIED };
}

export const UserIdWithRole = {
  encode(message: UserIdWithRole, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      UserId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.role !== Role.UNSPECIFIED) {
      writer.uint32(16).int32(roleToNumber(message.role));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserIdWithRole {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserIdWithRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = UserId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.role = roleFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserIdWithRole {
    return {
      id: isSet(object.id) ? UserId.fromJSON(object.id) : undefined,
      role: isSet(object.role) ? roleFromJSON(object.role) : Role.UNSPECIFIED,
    };
  },

  toJSON(message: UserIdWithRole): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = UserId.toJSON(message.id);
    }
    if (message.role !== Role.UNSPECIFIED) {
      obj.role = roleToJSON(message.role);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserIdWithRole>, I>>(base?: I): UserIdWithRole {
    return UserIdWithRole.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserIdWithRole>, I>>(object: I): UserIdWithRole {
    const message = createBaseUserIdWithRole();
    message.id = (object.id !== undefined && object.id !== null) ? UserId.fromPartial(object.id) : undefined;
    message.role = object.role ?? Role.UNSPECIFIED;
    return message;
  },
};

function createBaseGroup(): Group {
  return { id: undefined, parentGroups: [], resources: [], groups: [], members: [], authorizedScopes: [] };
}

export const Group = {
  encode(message: Group, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      GroupId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.parentGroups) {
      GroupId.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.resources) {
      GroupResource.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.groups) {
      GroupId.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.members) {
      GroupUser.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    writer.uint32(162).fork();
    for (const v of message.authorizedScopes) {
      writer.int32(groupScopeToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Group {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = GroupId.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.parentGroups.push(GroupId.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.resources.push(GroupResource.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.groups.push(GroupId.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.members.push(GroupUser.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag === 160) {
            message.authorizedScopes.push(groupScopeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 162) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.authorizedScopes.push(groupScopeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Group {
    return {
      id: isSet(object.id) ? GroupId.fromJSON(object.id) : undefined,
      parentGroups: globalThis.Array.isArray(object?.parentGroups)
        ? object.parentGroups.map((e: any) => GroupId.fromJSON(e))
        : [],
      resources: globalThis.Array.isArray(object?.resources)
        ? object.resources.map((e: any) => GroupResource.fromJSON(e))
        : [],
      groups: globalThis.Array.isArray(object?.groups) ? object.groups.map((e: any) => GroupId.fromJSON(e)) : [],
      members: globalThis.Array.isArray(object?.members) ? object.members.map((e: any) => GroupUser.fromJSON(e)) : [],
      authorizedScopes: globalThis.Array.isArray(object?.authorizedScopes)
        ? object.authorizedScopes.map((e: any) => groupScopeFromJSON(e))
        : [],
    };
  },

  toJSON(message: Group): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = GroupId.toJSON(message.id);
    }
    if (message.parentGroups?.length) {
      obj.parentGroups = message.parentGroups.map((e) => GroupId.toJSON(e));
    }
    if (message.resources?.length) {
      obj.resources = message.resources.map((e) => GroupResource.toJSON(e));
    }
    if (message.groups?.length) {
      obj.groups = message.groups.map((e) => GroupId.toJSON(e));
    }
    if (message.members?.length) {
      obj.members = message.members.map((e) => GroupUser.toJSON(e));
    }
    if (message.authorizedScopes?.length) {
      obj.authorizedScopes = message.authorizedScopes.map((e) => groupScopeToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Group>, I>>(base?: I): Group {
    return Group.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Group>, I>>(object: I): Group {
    const message = createBaseGroup();
    message.id = (object.id !== undefined && object.id !== null) ? GroupId.fromPartial(object.id) : undefined;
    message.parentGroups = object.parentGroups?.map((e) => GroupId.fromPartial(e)) || [];
    message.resources = object.resources?.map((e) => GroupResource.fromPartial(e)) || [];
    message.groups = object.groups?.map((e) => GroupId.fromPartial(e)) || [];
    message.members = object.members?.map((e) => GroupUser.fromPartial(e)) || [];
    message.authorizedScopes = object.authorizedScopes?.map((e) => e) || [];
    return message;
  },
};

function createBaseOrganization(): Organization {
  return { id: undefined, administrators: [], groups: [], authorizedScopes: [] };
}

export const Organization = {
  encode(message: Organization, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      OrganizationId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.administrators) {
      UserIdWithRole.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.groups) {
      GroupId.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    writer.uint32(162).fork();
    for (const v of message.authorizedScopes) {
      writer.int32(orgScopeToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Organization {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = OrganizationId.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.administrators.push(UserIdWithRole.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.groups.push(GroupId.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag === 160) {
            message.authorizedScopes.push(orgScopeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 162) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.authorizedScopes.push(orgScopeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Organization {
    return {
      id: isSet(object.id) ? OrganizationId.fromJSON(object.id) : undefined,
      administrators: globalThis.Array.isArray(object?.administrators)
        ? object.administrators.map((e: any) => UserIdWithRole.fromJSON(e))
        : [],
      groups: globalThis.Array.isArray(object?.groups) ? object.groups.map((e: any) => GroupId.fromJSON(e)) : [],
      authorizedScopes: globalThis.Array.isArray(object?.authorizedScopes)
        ? object.authorizedScopes.map((e: any) => orgScopeFromJSON(e))
        : [],
    };
  },

  toJSON(message: Organization): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = OrganizationId.toJSON(message.id);
    }
    if (message.administrators?.length) {
      obj.administrators = message.administrators.map((e) => UserIdWithRole.toJSON(e));
    }
    if (message.groups?.length) {
      obj.groups = message.groups.map((e) => GroupId.toJSON(e));
    }
    if (message.authorizedScopes?.length) {
      obj.authorizedScopes = message.authorizedScopes.map((e) => orgScopeToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Organization>, I>>(base?: I): Organization {
    return Organization.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Organization>, I>>(object: I): Organization {
    const message = createBaseOrganization();
    message.id = (object.id !== undefined && object.id !== null) ? OrganizationId.fromPartial(object.id) : undefined;
    message.administrators = object.administrators?.map((e) => UserIdWithRole.fromPartial(e)) || [];
    message.groups = object.groups?.map((e) => GroupId.fromPartial(e)) || [];
    message.authorizedScopes = object.authorizedScopes?.map((e) => e) || [];
    return message;
  },
};

function createBaseOrganizationScopes(): OrganizationScopes {
  return { id: undefined, scopes: [] };
}

export const OrganizationScopes = {
  encode(message: OrganizationScopes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      OrganizationId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).fork();
    for (const v of message.scopes) {
      writer.int32(orgScopeToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganizationScopes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationScopes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = OrganizationId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag === 16) {
            message.scopes.push(orgScopeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.scopes.push(orgScopeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrganizationScopes {
    return {
      id: isSet(object.id) ? OrganizationId.fromJSON(object.id) : undefined,
      scopes: globalThis.Array.isArray(object?.scopes) ? object.scopes.map((e: any) => orgScopeFromJSON(e)) : [],
    };
  },

  toJSON(message: OrganizationScopes): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = OrganizationId.toJSON(message.id);
    }
    if (message.scopes?.length) {
      obj.scopes = message.scopes.map((e) => orgScopeToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrganizationScopes>, I>>(base?: I): OrganizationScopes {
    return OrganizationScopes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrganizationScopes>, I>>(object: I): OrganizationScopes {
    const message = createBaseOrganizationScopes();
    message.id = (object.id !== undefined && object.id !== null) ? OrganizationId.fromPartial(object.id) : undefined;
    message.scopes = object.scopes?.map((e) => e) || [];
    return message;
  },
};

function createBaseEntityId(): EntityId {
  return { id: undefined };
}

export const EntityId = {
  encode(message: EntityId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.id?.$case) {
      case "resource":
        ResourceId.encode(message.id.resource, writer.uint32(10).fork()).ldelim();
        break;
      case "group":
        GroupId.encode(message.id.group, writer.uint32(18).fork()).ldelim();
        break;
      case "organization":
        OrganizationId.encode(message.id.organization, writer.uint32(26).fork()).ldelim();
        break;
      case "user":
        UserId.encode(message.id.user, writer.uint32(34).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntityId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntityId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = { $case: "resource", resource: ResourceId.decode(reader, reader.uint32()) };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = { $case: "group", group: GroupId.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = { $case: "organization", organization: OrganizationId.decode(reader, reader.uint32()) };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.id = { $case: "user", user: UserId.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntityId {
    return {
      id: isSet(object.resource)
        ? { $case: "resource", resource: ResourceId.fromJSON(object.resource) }
        : isSet(object.group)
        ? { $case: "group", group: GroupId.fromJSON(object.group) }
        : isSet(object.organization)
        ? { $case: "organization", organization: OrganizationId.fromJSON(object.organization) }
        : isSet(object.user)
        ? { $case: "user", user: UserId.fromJSON(object.user) }
        : undefined,
    };
  },

  toJSON(message: EntityId): unknown {
    const obj: any = {};
    if (message.id?.$case === "resource") {
      obj.resource = ResourceId.toJSON(message.id.resource);
    }
    if (message.id?.$case === "group") {
      obj.group = GroupId.toJSON(message.id.group);
    }
    if (message.id?.$case === "organization") {
      obj.organization = OrganizationId.toJSON(message.id.organization);
    }
    if (message.id?.$case === "user") {
      obj.user = UserId.toJSON(message.id.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntityId>, I>>(base?: I): EntityId {
    return EntityId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntityId>, I>>(object: I): EntityId {
    const message = createBaseEntityId();
    if (object.id?.$case === "resource" && object.id?.resource !== undefined && object.id?.resource !== null) {
      message.id = { $case: "resource", resource: ResourceId.fromPartial(object.id.resource) };
    }
    if (object.id?.$case === "group" && object.id?.group !== undefined && object.id?.group !== null) {
      message.id = { $case: "group", group: GroupId.fromPartial(object.id.group) };
    }
    if (
      object.id?.$case === "organization" && object.id?.organization !== undefined && object.id?.organization !== null
    ) {
      message.id = { $case: "organization", organization: OrganizationId.fromPartial(object.id.organization) };
    }
    if (object.id?.$case === "user" && object.id?.user !== undefined && object.id?.user !== null) {
      message.id = { $case: "user", user: UserId.fromPartial(object.id.user) };
    }
    return message;
  },
};

function createBaseTransactionId(): TransactionId {
  return { id: "" };
}

export const TransactionId = {
  encode(message: TransactionId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransactionId {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: TransactionId): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TransactionId>, I>>(base?: I): TransactionId {
    return TransactionId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransactionId>, I>>(object: I): TransactionId {
    const message = createBaseTransactionId();
    message.id = object.id ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
