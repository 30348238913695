import * as H from "history";
import { makeAutoObservable, runInAction } from "mobx";
import yaml from "yaml";
import { datadogRum } from "@datadog/browser-rum";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";

import { fetchCheckVersion } from "@shared/api/lib/cli/fetchCheckVersion";
import { DEFAULT_TARGET, DEFAULT_WORKBENCH_LANGUAGE } from "@shared/constants";
import { Address, CombinedRegistryAddress } from "@shared/editorCore";
import { ApiError, OrgData, Permission, Rule, UserData } from "@shared/types";
import { getOrgSlug, getSnippetAliasName } from "@shared/utils";
import { DEFAULT_RULE } from "@shared/utils/lib/defaultRule";

import { SwitchToStructureAlertError } from "../components/StructureMode/utils/SwitchToStructureAlert";
import { toYAML } from "../components/StructureMode/utils/toYAML";
import { startTurboMode, TurboMode } from "../TurboMode";
import { BundleAddressString } from "../types";
import { addressStringToAddress } from "../utils/addressStringToAddress";
import { addRuleToRecentlyViewed } from "../utils/addRuleToRecentlyViewed";
import {
  clearLocalStorageBundle,
  getLocalStorageBundleContent,
} from "../utils/localStorageBundle";
import { yamlFromRule } from "../utils/serializeYaml";

import { Bundle, ReadonlyBundle } from "./Bundle";
import { Editors } from "./Editors";
import { FileRoot } from "./FileRoot";
import { ErrorResult, Result } from "./Result";
import { WorkbenchUI } from "./WorkbenchUI";

export type POSSIBLE_ERROR_STATES = null | "notFound" | "unknownError";

const DEFAULT_PYTHON_RULE = yamlFromRule(
  DEFAULT_RULE(DEFAULT_WORKBENCH_LANGUAGE),
  true
);

export type NewBundleContent = {
  newPattern: string;
  newTarget: string;
};

export class Workbench {
  fileRoot: FileRoot;
  ui: WorkbenchUI;
  /**
   * The currently open bundle's address as text
   * Initialized in constructor by url parameters
   * After being set, Workbench controls the addressString
   * @example
   *
   * When URL is https://semgrep.dev/orgs/acme/editor/
   *
   * ```
   * console.log(workbench.addressString) === undefined
   * ```
   *
   * When URL is https://semgrep.dev/orgs/acme/editor/s/acme:rule-one
   *
   * ```
   * console.log(workbench.addressString) === "s/acme:rule-one"
   * ```
   *
   * When URL is https://semgrep.dev/orgs/acme/editor/new
   *
   * ```
   * console.log(workbench.addressString) === "new"
   * ```
   */
  address?: Address = undefined;
  addressString?: BundleAddressString = undefined;

  /**
   * The bundle as it looked when we loaded it from the backend.
   */
  remoteBundle: ReadonlyBundle | null = null;
  /**
   * The bundle as it looks after any edits the user might have made.
   */
  bundle: Bundle | null = null;
  /**
   * All run results the user received in this session, in chronological order.
   *
   * @remarks - to access the most recent result, use `workbench.result`
   */

  editors: Editors;

  turboMode: TurboMode | null = null;
  turboModeEnabled: boolean;

  resultsHistory: Result[] = [];
  /**
   * Reference to the global react-router history.
   * While using the Workbench, other components should NOT directly modify
   * the history because those changes (e.g. calling history.push("/new") to
   * create a new rule) will not be reflected.
   *
   * Instead, use the provided functions to create new, load, and fork a bundle
   */
  history: H.History;
  /**
   * List of permissions the workbench can count on from the backend.
   */
  permissions: Permission[];
  /**
   * Reference to the currently authenticated user.
   */
  user?: UserData;
  /**
   * Reference to the currently selected organization.
   */
  org?: OrgData;
  /**
   * True when a package is being loaded.
   */
  isLoading: boolean = false;
  /**
   * True when a snippet is being saved.
   */
  isSaving: boolean = false;
  /**
   * true if the user is in the playground
   * This affects the base url and the visibility of some features
   */
  isPlayground: boolean;
  /**
   * Non-null only when errors have happened during loading.
   * Should be checked similar to loading, should be reset on new loads.
   */
  errorState: POSSIBLE_ERROR_STATES = null;
  /**
   * False when we don't want the unsaved changes warning to be shown.
   * We manually control this sometimes when we change the url but don't discard changes
   * (i.e. when forking a rule)
   */
  showUnsavedChangesWarning: boolean = true;

  constructor(
    history: H.History,
    permissions: Permission[],
    addressString?: BundleAddressString,
    user?: UserData,
    org?: OrgData,
    isPlayground: boolean = false,
    turboModeEnabled: boolean = true,
    isDeep: boolean = false
  ) {
    this.history = history;
    this.permissions = permissions;
    this.isPlayground = isPlayground;
    this.turboModeEnabled = turboModeEnabled;
    this.user = user;
    this.org = org;
    this.fileRoot = new FileRoot(this);
    this.editors = new Editors(this);
    this.ui = new WorkbenchUI();

    makeAutoObservable(
      this,
      {
        history: false,
        fileRoot: false,
      },
      { autoBind: true }
    );

    this.setAddressString(addressString, isDeep);
  }

  async initTurboMode() {
    try {
      const url = new URLSearchParams(this.history.location.search);
      let version = url.get("turboVersion");
      if (!version) {
        try {
          const checkVersion = await fetchCheckVersion();
          version = `release-${checkVersion.latest}`;
        } catch {
          // worst case scenario, we use the develop branch
          version = "develop";
        }
      }

      const turboMode = await startTurboMode(version);
      runInAction(() => {
        this.turboMode = turboMode;
      });
    } catch (err: any) {
      runInAction(() => {
        this.turboModeEnabled = false;
      });
      Sentry.captureException(err, {
        tags: {
          turboMode: true,
          initialized: false,
        },
      });
      throw err.message; // TODO: make withToaster handle Error objects
    }
  }

  toggleTurboMode() {
    const url = new URLSearchParams(this.history.location.search);
    url.set("turbo", this.turboModeEnabled ? "0" : "1");
    url.set("pro", this.turboModeEnabled ? "1" : "0");
    this.history.push({ search: url.toString() });
    this.turboModeEnabled = !this.turboModeEnabled;
    if (this.bundle) {
      if (this.turboModeEnabled) {
        this.bundle.isDeep = false;
      } else {
        this.bundle.isDeep = true;
      }
    }
  }

  setContext(user?: UserData, org?: OrgData) {
    this.user = user;
    this.org = org;
  }

  setPermissions(permissions: Permission[]) {
    this.permissions = permissions;
  }
  /**
   * Sets address string and loads bundle accordingly
   */
  setAddressString(addressString?: BundleAddressString, isDeep?: boolean) {
    this.addressString = addressString;
    this.address = this.parseAddress();
    // isDeep and turboModeEnabled can't both be true
    const checkedIsDeep = this.turboModeEnabled ? false : isDeep;

    // depending on address string, load bundle
    if (addressString === "new") {
      let newBundleContent: NewBundleContent | undefined;

      if (this.isPlayground) {
        const storedBundle = getLocalStorageBundleContent();
        if (storedBundle) {
          newBundleContent = {
            newPattern: storedBundle.rule,
            newTarget: storedBundle.target,
          };
        }
      }

      this.newBundle(newBundleContent, false, checkedIsDeep);
    } else if (addressString !== undefined) {
      this.fetchBundle(addressString, checkedIsDeep);
    }
  }

  setAddressStringAndHistory(addressString: BundleAddressString) {
    this.addressString = addressString;
    this.address = this.parseAddress();

    // do nothing if we're already at the desired url
    // so that the back button doesn't ever need double clicks to work
    if (this.history.location.pathname === `${this.baseUrl}/${addressString}`)
      return;

    // keep query parameters
    const queryParams = new URLSearchParams(this.history.location.search);
    // Get rid of any query console search as it's not relevant to the new rule
    queryParams.delete("search_id");
    const desiredHref = `${
      this.baseUrl
    }/${addressString}?${queryParams.toString()}`;

    // set location state to control if unsaved changes prompt is shown
    this.history.push(desiredHref, {
      showUnsavedChangesWarning: this.showUnsavedChangesWarning,
    });
  }

  parseAddress(): Address | undefined {
    if (this.addressString === undefined || this.isNewBundle) {
      return undefined;
    }
    let addressTo: Address;
    try {
      addressTo = addressStringToAddress(this.addressString);
    } catch {
      this.history.push("/orgs/-/not-found");
      return undefined;
    }
    return addressTo;
  }

  setShowUnsavedChangesWarning(showWarning: boolean) {
    this.showUnsavedChangesWarning = showWarning;
  }

  handleAcceptFix(reject: boolean) {
    if (this.bundle && this.bundle.showingAutofixDiff) {
      const newText = this.bundle.showingAutofixDiff;
      this.bundle.setShowingAutofixDiff(undefined);
      if (!reject) {
        this.bundle.setTargetText(newText);
      }
    }
  }

  /**
   * If the bundle is new (addressString is "new")
   */
  get isNewBundle(): boolean {
    return this.addressString === "new";
  }

  get isSavedInLocalStorage(): boolean {
    if (!this.isPlayground) {
      return false;
    }
    const storedBundle = getLocalStorageBundleContent();
    if (storedBundle) {
      return (
        this.bundle?.ruleText === storedBundle.rule &&
        this.bundle?.targetText === storedBundle.target
      );
    }
    return false;
  }

  async reloadAllData() {
    await this.fileRoot.reloadSnippetsAndRulesets();
  }

  async handleApiError(err: ApiError) {
    if (err.statusCode === 404) {
      runInAction(() => {
        this.errorState = "notFound";
      });
      return;
    }
    runInAction(() => {
      this.errorState = "unknownError";
    });
    return;
  }

  /**
   * If there are unsaved changes and the warning is allowed, show to use.
   * Return true to continue, false if user does not want to continue
   */
  confirmUserContinuesIfUnsavedChanges() {
    if (!this.hasUnsavedChanges || !this.showUnsavedChangesWarning) {
      return true;
    }

    const leaveUnsavedMessage = `You have unsaved changes. Do you want to continue without saving these changes?`;
    return window.confirm(leaveUnsavedMessage);
  }

  // We move this here to take advantage of the workbench's history logic, which is particular
  // about the way we manipulate the URL structure.
  // If we do not push to the history with `showUnsavedChangesWarning: false`, we will get an
  // alert upon switching rule editor tabs, which is unideal.
  // So we move this here and set the flag accordingly.
  setSelectedRuleEditorTabIndex(
    tab: string,
    newTab: string | null,
    search: URLSearchParams,
    onStructureSwitchFailure: (kind: SwitchToStructureAlertError) => void
  ) {
    if (tab === newTab) {
      return;
    }

    const bundle = this.bundle;
    if (bundle && newTab === "structure" && bundle?.ruleText) {
      // if we can't switch, display a notif!
      const res = bundle?.getTextToStructureRuleErrors;
      if (res !== null) {
        onStructureSwitchFailure(res);
      }
    }
    // When switching to and from Structure Mode, we need to convert our
    // structureRule and ruleText fields to be compatible with either.
    else if (bundle && newTab === "advanced" && bundle?.structureRule) {
      const ruleText = toYAML(bundle?.structureRule, true);
      runInAction(() => {
        bundle.ruleText = ruleText;
        bundle.structureRule = undefined;
      });
    }

    if (newTab) {
      const newSearch = new URLSearchParams(search);
      if (newTab === tab) {
        newSearch.delete("editorMode");
      } else {
        newSearch.set("editorMode", newTab);
      }
      /* WRONG:
      this.history.push(`${this.baseUrl}/new?${newSearch.toString()}`, {
        showUnsavedChangesWarning: false,
      });
      */
      // This is wrong because if we are on the URL of a saved rule, we will make a
      // new rule indiscriminately, when by switching tabs we really just want to
      // see the current rule.
      // So let's just switch the query parameter, and keep all else the same.
      const url = `${window.location.pathname}?${newSearch.toString()}`;
      // This will prevent a pop-up from switching modes!
      this.history.push(url, {
        showUnsavedChangesWarning: false,
      });
    }
  }

  /**
   * Fetches and sets the package for the current workbench path.
   */
  async fetchBundle(addressString: BundleAddressString, isDeep?: boolean) {
    if (!this.confirmUserContinuesIfUnsavedChanges()) return;

    runInAction(() => {
      this.isLoading = true;
      this.clearResults();
    });

    let address: Address;
    try {
      address = addressStringToAddress(addressString);
    } catch {
      this.history.push("/orgs/-/not-found");
      return;
    }
    const rule = await address.fetch().catch(this.handleApiError);
    if (rule === undefined) return;

    // there are sometimes multiple test cases for rules that work with different languages
    // let's just take the first one
    const testCase = rule.test_cases ? rule.test_cases[0].target : "";
    // editor is only compatible with rules with one id in them
    const firstRule = rule.definition.rules[0] ?? {};
    const firstRuleFull = {
      rules: [firstRule],
    };
    if (rule.definition.rules.length > 1) {
      // user action in alert will determine what happens
      this.ui.openMultipleRuleIdAlert(rule);
      // continue with assumption that user will just view the first rule
    } else if (this.org) {
      // avoid adding improperly-formatted rules to recently viewed
      addRuleToRecentlyViewed(this.org.name, address.fullName, addressString);
    }

    runInAction(() => {
      this.isLoading = false;
      this.setAddressStringAndHistory(addressString);
      this.bundle = new Bundle(
        this,
        yaml.stringify(firstRuleFull),
        testCase,
        rule.visibility,
        isDeep !== undefined ? isDeep : !this.turboModeEnabled,
        rule.meta.rule.rule_id,
        rule.deployment_name,
        {
          lastChangeAt: rule.last_change_at || undefined,
          lastChangeBy: rule.last_change_by || undefined,
          sourceUri: rule.source_uri,
        }
      );

      this.remoteBundle = this.bundle.clone();
    });
  }

  /**
   * Creates a new bundle. If a pattern and target aren't provided,
   * bundle is created with existing Semgrep defaults.
   */
  async newBundle(
    newBundleContent?: {
      newPattern: string;
      newTarget: string;
    },
    clearLocalStorage = true,
    isDeep?: boolean
  ) {
    if (!this.confirmUserContinuesIfUnsavedChanges()) return;

    if (clearLocalStorage) {
      clearLocalStorageBundle();
    }

    runInAction(() => {
      this.isLoading = true;
      this.clearResults();
      this.remoteBundle = null;
      this.bundle = null;
    });

    const pattern = newBundleContent?.newPattern ?? DEFAULT_PYTHON_RULE;
    const target = newBundleContent?.newTarget ?? DEFAULT_TARGET;

    runInAction(() => {
      this.setAddressStringAndHistory("new");
      this.isLoading = false;
      this.bundle = new Bundle(
        this,
        pattern,
        target,
        "org_private",
        isDeep !== undefined ? isDeep : !this.turboModeEnabled
      );
    });
  }

  /**
   * Forks rule at addressString, if provided, else makes copy of current bundle.
   *
   * Forked rule id is `[old-id]-copy`
   */
  async forkRule(addressString?: string) {
    if (addressString === undefined && this.bundle === undefined) {
      throw new Error("No address or bundle to fork from");
    }

    // only confirm unsaved changes if we're forking from an address (not the current bundle)
    // we don't show the warning if there are unsaved changes in the current bundle because we
    // won't be discarding those changes
    if (addressString !== undefined) {
      if (!this.confirmUserContinuesIfUnsavedChanges()) return;
    }

    runInAction(() => {
      this.isLoading = true;
      this.errorState = null;
    });

    let newPattern: string;
    let newTarget: string;

    if (addressString) {
      let address: Address;
      try {
        address = addressStringToAddress(addressString);
      } catch {
        this.history.push("/orgs/-/not-found");
        return;
      }
      const rule = await address.fetch().catch(this.handleApiError);
      if (rule === undefined) return;

      // there are sometimes multiple test cases for rules that work with different languages
      // let's just take the first one
      newTarget = rule.test_cases ? rule.test_cases[0].target : "";
      // editor is only compatible with rules with one id in them
      const firstRule = rule.definition.rules[0] ?? {};

      const forkedRule = { ...firstRule, id: firstRule.id + "-copy" };
      newPattern = yaml.stringify({ rules: [forkedRule] });
    } else {
      runInAction(() => {
        // we're not going to discard any changes, so turn off the warning
        this.showUnsavedChangesWarning = false;
      });

      newPattern = this.bundle!.getForkedBundle(this.remoteBundle?.rule?.id);
      newTarget = this.bundle!.targetText;
    }

    // newBundle will handle setting loading state to false
    await this.newBundle({ newPattern, newTarget });
    runInAction(() => {
      this.showUnsavedChangesWarning = true;
    });

    showNotification({
      message: "Fork successful",
      color: "green",
    });

    return;
  }

  /**
   * Saves bundle, pops up errors as notifications
   * After saving, sets history to saved rule id
   * The `canOverwrite` manages whether it behaves as an upsert in case of
   * conflicts (only when no remote resource is defined).
   */
  async saveBundle(canOverwrite: boolean = false) {
    if (this.org === undefined || this.bundle === null) {
      throw new Error(
        "Cannot save rule without an organization and valid rule"
      );
    }

    if (!this.doesUserHaveEditPermission) {
      // save button shouldn't even appear
      throw new Error("Cannot save read-only rule");
    }

    runInAction(() => {
      this.isSaving = true;
    });

    if (this.address instanceof CombinedRegistryAddress) {
      this.saveBundleById(this.address.id);
    } else {
      this.saveBundleNew(canOverwrite);
    }
  }

  async saveBundleById(id: string) {
    try {
      const errors = await this.bundle?.saveById(id);
      if (errors) {
        this.resultsHistory.push(errors);
        throw new Error("Rules with errors cannot be saved.");
      }
    } catch (err) {
      showNotification({
        message: `${err instanceof Error ? err.message : err}`,
        color: "red",
        autoClose: 4000,
      });
      runInAction(() => {
        this.isSaving = false;
      });
      return;
    }

    // successfully saved, so reload snippet and change history
    runInAction(() => {
      this.isSaving = false;
      this.remoteBundle = this.bundle!.clone();
    });
  }

  async saveBundleAsNamedSnippet(canOverwrite: boolean) {
    if (this.org === undefined || this.bundle === null) {
      throw new Error(
        "Cannot save rule without an organization and valid rule"
      );
    }

    // check if rule would be overwritten
    const snippetFullName = getSnippetAliasName(this.org, this.bundle.rule!.id);
    if (
      !canOverwrite &&
      this.remoteBundle === null &&
      this.fileRoot.getSnippet(snippetFullName)
    ) {
      runInAction(() => {
        this.isSaving = false;
        this.ui.openConfirmOverwriteAlert();
      });

      datadogRum.addAction("Save Rule", {
        definition: this.bundle.rule,
        language: this.bundle.language!,
        test_target: this.bundle.targetText,
        visibility: this.bundle.visibility,
        rule: this.bundle.rule,
        snippetName: snippetFullName,
        message: "Rule save failed due to existing rule.",
      });

      return;
    }

    try {
      const errors = await this.bundle?.saveBySnippetName(snippetFullName);
      if (errors) {
        this.resultsHistory.push(errors);
        throw new Error("Rules with errors cannot be saved.");
      }
    } catch (err) {
      showNotification({
        message: `${err instanceof Error ? err.message : err}`,
        color: "red",
        autoClose: 4000,
      });
      runInAction(() => {
        this.isSaving = false;
      });
      return;
    }

    // successfully saved, so reload snippet and change history
    await this.fileRoot.reloadSnippet(snippetFullName);
    this.setAddressStringAndHistory(`s/${snippetFullName}`);
    runInAction(() => {
      this.isSaving = false;
      this.remoteBundle = this.bundle!.clone();
    });
  }

  async saveBundleNew(canOverwrite: boolean) {
    if (this.org === undefined || this.bundle === null) {
      throw new Error(
        "Cannot save rule without an organization and valid rule"
      );
    }

    // check if rule would be overwritten
    const existingRule = this.fileRoot.getCustomRule(this.bundle.rule!.id);

    if (!canOverwrite && this.remoteBundle === null && existingRule) {
      runInAction(() => {
        this.isSaving = false;
        this.ui.openConfirmOverwriteAlert();
      });

      datadogRum.addAction("Save Rule", {
        definition: this.bundle.rule,
        language: this.bundle.language!,
        test_target: this.bundle.targetText,
        visibility: this.bundle.visibility,
        rule: this.bundle.rule,
        rule_id: this.bundle.rule!.id,
        message: "Rule save failed due to existing rule.",
      });

      return;
    }

    try {
      const { id, path } = await this.bundle.saveNew();

      // successfully saved, so reload item and change history
      await this.fileRoot.reloadCustomRule(id, path);
      this.bundle.deploymentName = this.org.name;
      this.bundle.hashId = id;

      this.setAddressStringAndHistory(`r/${id}/${path}`);
      runInAction(() => {
        this.isSaving = false;
        this.remoteBundle = this.bundle!.clone();
      });
    } catch (err) {
      if (err instanceof ErrorResult) {
        this.resultsHistory.push(err);
      }
      showNotification({
        message: `${err instanceof Error ? err.message : err}`,
        color: "red",
        autoClose: 4000,
      });
      runInAction(() => {
        this.isSaving = false;
      });
      return;
    }
  }

  async saveOrFork(): Promise<{ showSaveLegacy: boolean } | undefined> {
    if (this.needsForkingToSave) {
      this.setShowUnsavedChangesWarning(false);
      this.forkRule();
      return;
    }

    // if there are no unsaved changes, nothing to be saved
    if (!this.hasUnsavedChanges) return;

    if (!this.isLegacyRuleIdFormat) {
      this.saveBundle();
      return;
    }

    return { showSaveLegacy: true };
  }

  get hasRuleNameChanged(): boolean {
    return !!(
      this.remoteBundle?.rule?.id &&
      this.bundle?.rule?.id !== this.remoteBundle.rule.id
    );
  }

  get needsForkingToSave(): boolean {
    return this.hasRuleNameChanged || !this.doesRuleBelongToUser;
  }
  /**
   * Returns true if the current rule is a snippet in the old format where
   * rule id is not in the rule name
   * i.e.: rule id = 'my_pattern_id'
   *       rule address = 'returntocorp:not_my_pattern_id'
   */
  get isLegacyRuleIdFormat(): boolean {
    const parts = this.address?.fullName.split(".") ?? [];
    const withoutPrefix = parts.slice(1).join(".");
    const last = parts[parts.length - 1];

    const ruleId = this.remoteBundle?.rule?.id;

    return !(ruleId === withoutPrefix || ruleId === last);
  }

  clearResults() {
    this.errorState = null;
    this.resultsHistory = [];
  }

  get isDefaultNewRule(): boolean {
    return (
      this.bundle?.ruleText === DEFAULT_PYTHON_RULE &&
      this.bundle?.targetText === DEFAULT_TARGET
    );
  }

  get hasUnsavedChanges(): boolean {
    if (this.isDefaultNewRule) {
      return false;
    }
    return !!this.bundle && !this.bundle.isEqualTo(this.remoteBundle);
  }

  /**
   * The base URL of the workbench.
   */
  get baseUrl(): string {
    if (this.isPlayground) {
      return "/playground";
    } else {
      return `/orgs/${this.org ? getOrgSlug(this.org) : "-"}/editor`;
    }
  }

  /**
   * Does this rule belong to the current user
   * False if
   * - Rule is in the registry
   * - Rule is in someone else's namespace
   * - Rule is a snippet - the snippet id is created by hashing the rule
   *   contents, so changes can't be saved. Snippets belong to no one.
   */
  get doesRuleBelongToUser(): boolean {
    if (!this.org) return false;

    if (!this.user) {
      // rules can only belong to a user if they're logged in
      return false;
    }

    if (!this.bundle) {
      return false;
    }

    if (!this.remoteBundle) {
      // rules that haven't been saved yet belong to the user
      return true;
    }

    if (this.bundle.deploymentName === this.org.name) {
      return true;
    }

    return false;
  }

  /**
   * Does the user have permission to edit this rule
   */
  get doesUserHaveEditPermission(): boolean {
    // if a user is not logged in, they don't have any permissions for us to check
    const canCreate =
      this.permissions.includes(Permission.editor_create) || !this.user;
    const canEdit =
      this.permissions.includes(Permission.editor_update) || !this.user;

    if (this.isNewBundle) {
      return canCreate;
    }

    return canEdit;
  }

  /**
   * The most recent run result
   */
  get result(): Result | null {
    return this.resultsHistory.slice(-1)[0] ?? null;
  }

  /**
   * Split a rule with multiple ids into multiple rules, saved in the
   * current namespace.
   * Send the user to the first rule right after its creation, so they
   * don't have to wait for the rest of the rules to be split.
   *
   * Because this is a rare case, we're just going to ignore errors for
   * every rule except the first. These rules should be valid since they've
   * already been added to the registry, so the possible errors are API
   * errors and name collision errors.
   */
  async splitRules(rule: Rule) {
    if (rule.definition.rules.length < 2)
      throw new Error("Trying to split rule that has less than 2 ids");

    if (this.org === undefined)
      throw new Error("Cannot split rule when org is undefined");

    // Set loading to true so the user can't edit the rule while this
    // first rule is being saved
    runInAction(() => {
      this.isLoading = true;
    });

    // save first rule
    const firstRule = rule.definition.rules[0];
    const firstRuleFull = { rules: [firstRule] };
    const testCase = rule.test_cases ? rule.test_cases[0].target : "";
    this.bundle = new Bundle(
      this,
      yaml.stringify(firstRuleFull),
      testCase,
      rule.visibility,
      !this.turboModeEnabled,
      rule.meta.rule.rule_id,
      rule.deployment_name,
      {
        lastChangeAt: rule.last_change_at || undefined,
        lastChangeBy: rule.last_change_by || undefined,
        sourceUri: rule.source_uri,
      }
    );
    this.remoteBundle = this.bundle.clone();

    // saves rule and changes history, bringing user to the page for the rule
    this.saveBundle(true).then(() => {
      runInAction(() => {
        this.isLoading = false;
      });
    });

    // save the rest of the rules
    const rulesToSave = rule.definition.rules.slice(1);
    rulesToSave.forEach(async (r) => {
      const bundleToSave = new Bundle(
        this,
        yaml.stringify(r),
        testCase,
        rule.visibility,
        !this.turboModeEnabled,
        undefined, // needs to be saved first
        rule.deployment_name,
        {
          lastChangeAt: rule.last_change_at || undefined,
          lastChangeBy: rule.last_change_by || undefined,
          sourceUri: rule.source_uri,
        }
      );

      // check if we would overwrite a rule
      if (this.fileRoot.getCustomRule(r.id)) {
        datadogRum.addAction("Split Rule", {
          definition: { rules: [bundleToSave.rule] },
          language: bundleToSave.language!,
          test_target: bundleToSave.targetText,
          visibility: bundleToSave.visibility,
          rule: bundleToSave.rule,
          rule_id: r.id,
          message: "Split rule save failed due to existing rule.",
        });
        return;
      }

      try {
        await bundleToSave.saveNew();
      } catch (err) {
        // We're going to ignore these errors, but let's log them
        // bundle.save logs more specific errors as well
        datadogRum.addAction("Split Rule", {
          message: "Split rule save failed. See Save Rule errors.",
        });
      }
    });

    // reload now that all are saved
    this.fileRoot.reloadSnippets();
  }

  get shouldShowRunButton() {
    return !this.turboModeEnabled;
  }
}
