import { runInAction } from "mobx";

import { Bundle } from "../../../stores/Bundle";
import { InArray, Pattern, PatternConstraint } from "../types/rule";

/******************************************************************************/
/* Pattern manipulation */
/******************************************************************************/

export const disablePattern = (
  bundle: Bundle | null,
  pattern: Pattern,
  b: boolean
) =>
  runInAction(() => {
    pattern.isDisabled = b;
    pattern.isExpanded = !b;
    bundle?.onStructureRuleChange();
  });

/******************************************************************************/
/* Constraint manipulation */
/******************************************************************************/

export const newConstraint = (bundle: Bundle | null, pattern: Pattern) =>
  runInAction(() => {
    if (pattern.value.constraint && !(pattern.value.constraint.length === 0)) {
      return;
    } else {
      pattern.value.constraint = [
        new PatternConstraint({ kind: "focus", metavariables: [""] }),
      ];
      bundle?.onStructureRuleChange();
    }
  });

/******************************************************************************/
/* Helpers */
/******************************************************************************/

export const removeFromInArray = (
  bundle: Bundle | null,
  inArray: InArray<any> | null
) =>
  runInAction(() => {
    inArray?.parentArray.splice(inArray.index, 1);
    bundle?.onStructureRuleChange();
    return;
  });

export function addIntoArray<T>(
  bundle: Bundle | null,
  inArray: InArray<T> | null,
  element: T
) {
  runInAction(() => {
    inArray?.parentArray.splice(inArray.index + 1, 0, element);
    bundle?.onStructureRuleChange();
    return;
  });
}
