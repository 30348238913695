import { useContext } from "react";
import { CodeHighlight } from "@mantine/code-highlight";
import { Anchor, Box } from "@mantine/core";

import { useQueryConsoleRepo } from "@shared/hooks";
import { dedent } from "@shared/utils";

import { WorkbenchContext } from "../../providers";

interface EditorQueryConsoleFindingProps {
  searchId: string;
  repoName: string;
  visible: boolean;
}

export const EditorQueryConsoleFinding = ({
  searchId,
  repoName,
  visible,
}: EditorQueryConsoleFindingProps) => {
  const { workbench } = useContext(WorkbenchContext);

  const results = useQueryConsoleRepo(
    { searchId, repoName },
    { enabled: visible }
  );

  if (results.isFetching) {
    return <Box>Loading...</Box>;
  }

  if (results.isError) {
    return <Box>Error loading results.</Box>;
  }

  if (!results.data) {
    return null;
  }

  return (
    <div>
      {results.data.status?.results.map((result, index) => (
        <Box key={index} mb="md">
          <Anchor href={result.url} target="_blank">
            <strong>
              <small>{result.filename}</small>
            </strong>
            <small> Line {result.line}</small>
          </Anchor>
          <CodeHighlight
            code={dedent(result.snippet.split("\n")).join("\n")}
            language={workbench.bundle?.language || "plaintext"}
          />
        </Box>
      ))}
    </div>
  );
};
