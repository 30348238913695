import Xarrow from "react-xarrows";
import styled from "styled-components";
import { Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { GreenArrow, RedArrow } from "./Illustrations";
import { lightGreen, lightRed, mobileMq, newGreen, Screen } from "./utils";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const IllustrationWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8d8d8d;
  font-size: 11px;
  padding: 24px;
`;

const Point = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  background-color: ${(props) => props.color};
  border-radius: 7px;
`;

const PointSurface = styled.div<{ color: string; clickable?: boolean }>`
  padding: 8px;
  border: 1px solid ${(props) => props.color};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
  /* number of rows times number of columns plus gaps and paddings */
  width: ${14 * 25 + 4 * 24 + 8 * 2 + 2}px;
  height: ${14 * 4 + 4 * 3 + 8 * 2 + 2}px;
  ${({ clickable }) =>
    clickable
      ? `
  cursor: pointer;
  :hover {
    background-color: #20283e;
  }

  `
      : ""}

  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-direction: row;
  }
`;

export function IntroSupplyChain({ goTo }: { goTo: (screen: Screen) => void }) {
  const isMobile = useMediaQuery(`(${mobileMq})`);
  const isSmallMobile = useMediaQuery(`(max-width: 520px`);
  return (
    <Layout>
      <Xarrow
        start="unsorted_point_37"
        end="reachable_point_0"
        color={lightRed}
        headShape="arrow1"
        headSize={4}
        startAnchor="left"
        endAnchor={isMobile ? "top" : "right"}
      />
      <Xarrow
        start="unsorted_point_63"
        end="reachable_point_1"
        color={lightRed}
        headShape="arrow1"
        headSize={4}
        startAnchor="left"
        endAnchor={isMobile ? "top" : "right"}
      />
      <Title
        size="h3"
        style={{
          color: "white",
          fontSize: "30px",
        }}
      >
        98% of dependency vulnerabilities are{" "}
        <span
          style={{
            color: newGreen,
          }}
        >
          not reachable
        </span>
      </Title>
      <Text
        size="lg"
        style={{
          color: "#D0D5DD",
          fontSize: "16px",
        }}
      >
        Only 2% of vulnerabilities affect your codebase. Fix these first.
      </Text>
      <IllustrationWrapper>
        <div>
          <PointSurface color="#c1c1c1">
            {[...Array(100)].map((_, i) => (
              <Point
                key={i}
                id={"unsorted_point_" + i}
                color={
                  i === 37 || i === 63
                    ? "var(--mantine-color-red-6)"
                    : "#646464"
                }
              />
            ))}
          </PointSurface>
          100 vulnerabilities in your lockfile
        </div>

        <div
          style={{
            display: "flex",
            gap: "16px",
            marginTop: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
            }}
          >
            <PointSurface
              color="var(--mantine-color-red-6)"
              style={{ width: "140px", flexGrow: 1 }}
              onClick={() => {
                goTo("lodash");
              }}
              clickable
            >
              {[...Array(2)].map((_, i) => (
                <Point
                  key={i}
                  id={"reachable_point_" + i}
                  color="var(--mantine-color-red-6)"
                />
              ))}
            </PointSurface>
            2 reachable vulnerabilities
          </div>
          <div>
            <PointSurface
              color={lightGreen}
              onClick={() => {
                goTo("axios");
              }}
              clickable
            >
              {[...Array(98)].map((_, i) => (
                <Point key={i} id={"unreachable_point_" + i} color={newGreen} />
              ))}
            </PointSurface>
            98 unreachable vulnerabilities
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 30px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <RedArrow />
            <div
              style={{
                position: "absolute",
                top: "53px",
                left: "100px",
                whiteSpace: "nowrap",
                fontSize: "20px",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Fix these immediately!
            </div>
          </div>
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                marginTop: "-12px",
              }}
            >
              <GreenArrow />
            </div>

            <div
              style={{
                position: "absolute",
                top: "24px",
                right: "42px",
                whiteSpace: "nowrap",
                fontSize: "16px",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Ignore these {!isSmallMobile ? "unreachable vulnerabilities" : ""}
            </div>
          </div>
        </div>
      </IllustrationWrapper>
    </Layout>
  );
}
