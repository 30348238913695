/**
 * Maybe if we don't import anything in this file then the nice loader will
 * appear faster. :thinking_face:
 */
import "./LoadingScreen.css";

interface LoadingScreenProps {
  text?: string;
}

export const Spinner = ({ size = 100 }: { size?: number }) => (
  <span
    // from https://cssloaders.github.io/
    style={{
      flexShrink: 0,
      width: size,
      height: size,
      border: "5px solid rgb(34, 139, 230)", // mantine blue
      borderBottomColor: "transparent",
      borderRadius: "50%",
      display: "inline-block",
      boxSizing: "border-box",
      animation: "rotation 1s linear infinite",
    }}
  />
);

export const LoadingScreen = ({
  text = "Semgrep AppSec Platform is loading…",
}: LoadingScreenProps) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "55vh",
    }}
  >
    <Spinner />
    <div
      style={{
        fontSize: "var(--global-font-size-l)",
        marginTop: "var(--mantine-spacing-lg)",
        marginBottom: "var(--mantine-spacing-lg)",
      }}
    >
      {text}
    </div>
  </div>
);
