export enum Permission {
  panel_overview = "panel_overview",
  panel_projects = "panel_projects",
  panel_policy = "panel_policy",
  panel_findings = "panel_findings",
  panel_analytics = "panel_analytics",
  panel_settings = "panel_settings",
  editor_create = "editor_create",
  editor_update = "editor_update",
  editor_delete = "editor_delete",
  rbac_update = "rbac_update",
  team_manage = "team_manage",
  projects_read_all = "projects_read_all",
  scm_initiate_sync = "scm_initiate_sync",
  scm_autoinstall = "scm_autoinstall",
}

export const loadPermissions = (untypedPermissions: string[]): Permission[] => {
  return untypedPermissions.map(
    (x) => Permission[x as keyof typeof Permission]
  );
};
