import { matchPath } from "react-router";

export const getOrgSlugFromPath = (path: string) => {
  const match = matchPath<{ orgName?: string }>(path, {
    path: "/orgs/:orgName",
  });

  if (match?.params.orgName === "-") {
    return undefined;
  }

  return match?.params.orgName;
};
