import * as Sentry from "@sentry/react";

import { useOrg } from "../useOrg";

/**
 * @returns the current selected org. Do not use this hook from a component that
 * may be accessed by a user that is not logged in or has not created an org
 * yet, because it will throw an error and crash the app.
 * @throws an error if the org is not defined.
 */
export const useOrgAssertDefined = () => {
  const [org] = useOrg();
  if (!org) {
    Sentry.captureMessage("Org is undefined.");
    throw new Error("Org is undefined.");
  }
  return org;
};
