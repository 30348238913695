import { useEffect } from "react";
import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import {
  faBookBookmark,
  faCheckCircle,
  faCodePullRequest,
  faExclamationCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Group, Modal, Progress, Text } from "@mantine/core";

import { Workbench } from "../../stores";

import { PublishMetadata } from "./PublishMetadata";

const Actions = styled.div`
  gap: 16px;
  display: flex;
  justify-content: flex-end;
`;

export const PublicRuleChecklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  font-size: var(--mantine-font-size-md);
`;

const IntervalStep = 100;
const IntervalDuration = 8000;

const DOCS_LINK_CONTRIBUTING_RULES =
  "https://semgrep.dev/docs/contributing/contributing-to-semgrep-rules-repository/#semgrep-registry-rule-requirements";

function PublicRuleChecklistComponent({
  onCreatePRClick,
  workbench,
  onClose,
}: {
  onCreatePRClick: () => void;
  workbench: Workbench;
  onClose: () => void;
}) {
  const [state, setState] = useState<"meta-data" | "confirmation" | "loading">(
    "meta-data"
  );
  const [timer, setTimer] = useState<number>(0);
  const timerRef = useRef<number>(0);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  function startLoader() {
    setTimer(0);
    timerRef.current = 0;
    function nextInterval() {
      timeoutRef.current = setTimeout(() => {
        if (timerRef.current > IntervalDuration) {
          onCreatePRClick();
        } else {
          const newTime = timerRef.current + IntervalStep;
          timerRef.current = newTime;
          setTimer(newTime * 100);
          nextInterval();
        }
      }, IntervalStep);
    }
    setState("loading");
    nextInterval();
  }

  useEffect(() => {
    return () => {
      // On unmount, cancel the timer
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  function cancelLoader() {
    setTimer(0);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setState("meta-data");
  }

  const expectedMatches = workbench.bundle?.expectedMatches.length || 0;
  const expectedNotMatches = workbench.bundle?.expectedNotMatches.length || 0;

  const isSecretsRule = workbench.bundle?.isSecretsRule ?? false;

  return (
    <Modal
      opened={true}
      onClose={onClose}
      title={state === "meta-data" ? "Publish rule" : "Creating Pull Request"}
      size={800}
    >
      <PublicRuleChecklistContainer>
        {state === "meta-data" && (
          <>
            <Alert title="Ready for prime time?">
              In the Semgrep Registry anyone can find what you've created. To
              publish, let's make sure that your metadata is ready for prime
              time! See the{" "}
              <a
                href={DOCS_LINK_CONTRIBUTING_RULES}
                target="_blank"
                rel="noopener noreferrer"
              >
                Semgrep registry rule requirements
              </a>{" "}
              for more information on what's required in the rule metadata.
            </Alert>
            <PublishMetadata workbench={workbench} />
            <Group>
              <Text mr={24}>Test cases</Text>
              {expectedMatches && expectedNotMatches ? (
                <div>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="var(--mantine-color-green-5)"
                  />{" "}
                  Detected
                </div>
              ) : expectedMatches ? (
                <div>
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color="var(--mantine-color-yellow-9)"
                  />{" "}
                  Only postive test case is detected, add a negative case
                </div>
              ) : expectedNotMatches ? (
                <div>
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color="var(--mantine-color-yellow-9)"
                  />{" "}
                  Only negative test case is detected, add a positive case
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    color="var(--mantine-color-red-6)"
                  />{" "}
                  Not found{" "}
                  <a href="https://github.com/semgrep/semgrep-rules/blob/develop/CONTRIBUTING.md#tests">
                    (how to add?)
                  </a>
                </div>
              )}
            </Group>

            <Actions>
              <Button
                component="a"
                href="https://github.com/semgrep/semgrep-rules#contributing"
                variant="outline"
                leftSection={<FontAwesomeIcon icon={faBookBookmark} />}
              >
                Contributing guide
              </Button>
              <Button
                leftSection={<FontAwesomeIcon icon={faCheckCircle} />}
                onClick={() => {
                  if (workbench.hasUnsavedChanges) {
                    workbench.saveBundle();
                  }
                  setState("confirmation");
                }}
                disabled={
                  !workbench.bundle?.hasRequiredFieldsForPublish ||
                  isSecretsRule
                }
              >
                {workbench.hasUnsavedChanges ? "Save and continue" : "Continue"}
              </Button>
            </Actions>
          </>
        )}
        {state === "confirmation" && (
          <>
            <div>
              <Alert title="Beware, public repository ahead" color="orange">
                We'll create a PR in a public repo. This means that your rule
                will be public too and can not ever be deleted.
              </Alert>
              <div
                style={{
                  marginTop: "16px",
                }}
              >
                Next, our Semgrep PR bot will create a pull request on GitHub in
                the{" "}
                <a href="https://github.com/semgrep/semgrep-rules">
                  semgrep-rules repo.{" "}
                </a>
                If you publish, your rule will be open sourced under{" "}
                <a href="https://github.com/semgrep/semgrep-rules/blob/develop/LICENSE">
                  LGPL 2.1
                </a>
                .
              </div>
            </div>
            <Actions>
              <Button
                leftSection={<FontAwesomeIcon icon={faCodePullRequest} />}
                onClick={() => {
                  startLoader();
                }}
              >
                Create PR
              </Button>
            </Actions>
          </>
        )}
        {state === "loading" && (
          <>
            <Progress
              value={(timer || 0) / IntervalDuration}
              striped
              animated
            />
            <Actions>
              <Button variant="outline" onClick={cancelLoader}>
                Cancel
              </Button>
            </Actions>
          </>
        )}
      </PublicRuleChecklistContainer>
    </Modal>
  );
}

export const PublicRuleChecklist = observer(PublicRuleChecklistComponent);
