import {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import * as monaco from "monaco-editor";
import { datadogRum } from "@datadog/browser-rum";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InlineCodeHighlight } from "@mantine/code-highlight";
import { Button, Group, Paper, Stack, Text } from "@mantine/core";

import { MonacoEditor } from "@vendors/monaco";

import { PlaceholderContentWidget } from "@shared/utils";

import { WorkbenchContext } from "../../../providers";

import styles from "./StructureModeTextInput.module.css";

const MONACO_LINE_HEIGHT = 18;

// TODO: https://linear.app/semgrep/issue/SAF-1561/re-enable-structure-mode-pattern-parsing
// function toSemgrepLanguageID(language: string) {
//   switch (language) {
//     case "typescript":
//       return "ts";
//     case "hcl":
//       return "terraform";
//   }
//   return language;
// }

interface StructureModeTextInputProps {
  value: string;
  onChange: (value: string | undefined) => void;
  isCode: boolean;
  placeholder: string;
  width?: number | string;
}

export const StructureModeTextInput: React.FC<StructureModeTextInputProps> = ({
  value,
  onChange,
  placeholder,
  width = 350,
  // TODO: placeholder
  // monaco editor doesn't support a placeholder like Textarea does
  // if we want this we'll have to roll our own functionality
}) => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;
  const [lines, setLines] = useState(getNumLines(value));
  // TODO: https://linear.app/semgrep/issue/SAF-1561/re-enable-structure-mode-pattern-parsing
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [parses, _setParses] = useState<boolean>(true);
  const editorRef: MutableRefObject<monaco.editor.IStandaloneCodeEditor | null> =
    useRef(null);
  const [aiFix, setAiFix] = useState<string | null>(null);
  // NOTE: All this typing timer and typing stopped stuff has been commented out,
  // because we're having trouble with the pattern fixing chain.
  // We don't have bandwidth to address this right now, so let's comment it out.
  // const [typingTimer, setTypingTimer] = useState<NodeJS.Timeout | null>(null);
  // coupling(stopped-typing):
  // we represent whether typing stopped using some state, as opposed to just
  // spawning the timeout at the time that the user hits the key, because we
  // want to check whether the pattern parses, to send the task
  // If we spawn the timeout on key press, it will only have access to the
  // value of whether the component parses for the value _before_ the key press
  // So instead, we set a flag and trigger the AI request on the next render
  // const [_typingStopped, setTypingStopped] = useState<boolean>(false);
  // const typingInterval = 1000; // 1 second

  // Here, we register the undo and redo commands for each Monaco editor
  // This is so that we don't default to the Monaco editor's undo and redo,
  // which has a separate buffer, which can get desynced from our custom one.
  // Essentially, we'd prefer to defer to our custom undo and redo.
  useEffect(() => {
    const undoAction = editorRef.current?.addAction({
      id: "StructureModeTextInputUndo",
      label: "Structure Mode undo",
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyZ],
      run: () => {
        bundle?.onRuleUndo();
      },
    });
    const redoAction = editorRef.current?.addAction({
      id: "StructureModeTextInputRedo",
      label: "Structure Mode redo",
      keybindings: [
        monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyZ,
      ],
      run: () => {
        bundle?.onRuleRedo();
      },
    });
    // We have to clean up after ourselves, though. We don't want to have the
    // undo and redo commands still active if we switch to Advanced Mode, for
    // instance.
    // We previously observed that without this cleanup and useEffect, switching
    // from Structure Mode to Advanced Mode would bork Advanced Mode's undo and
    // redo.
    return function cleanup() {
      undoAction?.dispose();
      redoAction?.dispose();
    };
  }, [bundle, value]);

  const language = bundle?.structureRule?.language;

  function triggerAiTelemetry(reason: string) {
    datadogRum.addAction("AI pattern fix", {
      pattern: value,
      language: language ?? "unknown",
      message: "AI pattern fix requested",
      fix: aiFix,
      reason: reason,
    });
  }

  function getNumLines(s: string) {
    return s.split("\n").length;
  }

  // TODO: https://linear.app/semgrep/issue/SAF-1561/re-enable-structure-mode-pattern-parsing
  // TODO: Bring this back once I figure out why it's always red when loading into a language
  // which normally pattern parses fine.
  // This code allows responsive validation of each pattern on key press
  // useEffect(() => {
  //   // only do it if this text input is for code, though
  //   if (
  //     isCode &&
  //     value !== "" &&
  //     language
  //     // TODO: remove this once we find out why the pattern parsing isn't working
  //     // for some languages
  //   ) {
  //     const fetchData = async () => {
  //       const parses = await workbench.turboMode?.parsePattern(
  //         toSemgrepLanguageID(language),
  //         value
  //       );
  //       return parses;
  //     };

  //     fetchData().then((result) => setParses(result ?? true));
  //   }
  // });

  // This is the task for sending a request for AI pattern fixing.
  // This will only trigger on two conditions:
  // 1. the user stops typing for a second
  // 2. the current pattern does not parse
  // const taskResult = usePostPatternFixing({
  //   request: { pattern: value, language: language ?? "unknown" },
  //   onSuccess: (data: TaskResultResponse) => {
  //     if (!parses) {
  //       setAiFix(data.value);
  //     }
  //   },
  // });

  // See coupling(stopped-typing):
  // if (typingStopped) {
  //   // Your code to execute after the user stops typing goes here
  //   if (!parses) {
  //     taskResult.startTask(null);
  //   }
  //   setTypingStopped(false);
  // }

  // When the user stops typing, if the pattern doesn't parse, we are going to
  // try to generate an AI fix for it.
  // function onTypingStopped() {
  //   setTypingStopped(true);
  // }

  async function onChange2(s: string | undefined) {
    if (s) {
      setLines(getNumLines(s));
      onChange(s);

      if (aiFix) {
        triggerAiTelemetry("rejected on type");
      }
      // when we type something new, get rid of the old AI fix
      setAiFix(null);

      // start a timer for each key press, so that we know when
      // the user stops typing
      // if (typingTimer) {
      //   clearTimeout(typingTimer);
      // }
      // setTypingTimer(setTimeout(onTypingStopped, typingInterval));

      bundle?.onStructureRuleChange();
    }
  }
  const editor = (
    <MonacoEditor
      onMount={(editor) => {
        editorRef.current = editor;
        // side-effecting, we don't need to use this value
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _placeholderWidget = new PlaceholderContentWidget(
          placeholder,
          editor
        );
      }}
      value={value}
      onChange={onChange2}
      height={lines * MONACO_LINE_HEIGHT + 4}
      language={language}
      options={{
        // to make things a little less claustrophobic
        padding: { top: 2, bottom: 2 },
        glyphMargin: false,
        minimap: { enabled: false },
        folding: false,
        lineNumbersMinChars: 0,
        lineNumbers: "off",
        renderLineHighlight: "none",
        // gets rid of the annoying line thing to the right
        overviewRulerLanes: 0,
        overviewRulerBorder: false,
        scrollbar: { vertical: "hidden", horizontal: "hidden" },
        // need this or the editor will scroll on a newline, meaning that
        // although the editor will resize to have height to accommodate the
        // entire text, we will start on the next line
        scrollBeyondLastLine: false,
        // this stops the red squiggles which we don't care about in a pattern
        renderValidationDecorations: "off",
      }}
    />
  );

  return (
    <Stack gap="5px">
      {!parses && (
        <Group gap="5px">
          <FontAwesomeIcon color="red" size="xs" icon={faX} />
          <Text c="red" size="xs" style={{ fontSize: 11 }}>
            Pattern does not parse!
          </Text>
        </Group>
      )}
      <div
        style={{
          width: width,
          // if the pattern doesn't parse, signal that with the box border!
          border: parses ? undefined : "1px solid red",
        }}
        className={styles["structure-mode-text-input"]}
        aria-label="StructureModeTextInput"
      >
        {editor}
      </div>
      {aiFix && (
        <Paper style={{ backgroundColor: "var(--mantine-color-blue-1)" }}>
          <Group
            style={{
              padding: "var(--mantine-spacing-xs)",
              justifyContent: "space-between",
            }}
          >
            <Stack>
              <Group gap="var(--mantine-spacing-xs)">
                <FontAwesomeIcon size="xs" icon={faSparkles} />
                <Text size="xs" style={{ fontSize: 12 }}>
                  AI suggested fix:
                </Text>
              </Group>
              <InlineCodeHighlight code={aiFix} language={language} />
            </Stack>
            <Stack gap="var(--mantine-spacing-xs)">
              <Button
                size="xs"
                onClick={() => {
                  triggerAiTelemetry("rejected");
                  setAiFix(null);
                }}
                className={styles["reject-ai-fix"]}
              >
                reject
              </Button>
              <Button
                onClick={() => {
                  triggerAiTelemetry("accepted");
                  // if we don't set the lines here, the box won't resize properly
                  setLines(getNumLines(aiFix));
                  onChange(aiFix);
                  setAiFix(null);
                }}
                size="xs"
                className={styles["accept-ai-fix"]}
              >
                accept
              </Button>
              {/* TODO: add a button to generate another fix */}
              {/* <Button
                size="xs"
                onClick={() => {
                  setAiFix(null);
                  taskResult.startTask(null);
                }}
                color="purple"
              >
                another
              </Button> */}
            </Stack>
          </Group>
        </Paper>
      )}
    </Stack>
  );
};
