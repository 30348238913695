import { OWASP_2017, OWASP_2021 } from "../owasp";

type InternalOwaspId = `${number}:${"2017" | "2021"}`;

export function owaspKeyToString(id: InternalOwaspId | string): string | null {
  const [number, year] = id.split(":");
  if (year === "2017") {
    return `A${number}:2017 ${OWASP_2017[Number(number) - 1]}`;
  }
  if (year === "2021") {
    return `A${number}:2021 ${OWASP_2021[Number(number) - 1]}`;
  }
  return null;
}

export function owaspStringToKey(str: string): InternalOwaspId | null {
  const parts = str.split(":");
  if (parts[0] && parts[1]) {
    const index = parseInt(parts[0].slice(1));
    const owasp_year = parts[1].slice(0, 4) as "2017" | "2021";
    if (index <= 10 && (owasp_year === "2017" || owasp_year === "2021")) {
      return `${index}:${owasp_year}`;
    }
  }
  return null;
}

export function owaspStringToId(str: string): string | null {
  const parts = str.split(":");
  if (parts[0] && parts[1]) {
    const index = parseInt(parts[0].slice(1));
    const owasp_year = parts[1].slice(0, 4);
    if (owasp_year === "2017") {
      return OWASP_2017[index - 1] || null;
    }
    if (owasp_year === "2021") {
      return OWASP_2021[index - 1] || null;
    }
  }
  return null;
}
