import {
  GLOBAL_POLICY_SLUG,
  GLOBAL_SECRETS_POLICY_SLUG,
  GLOBAL_SUPPLY_CHAIN_POLICY_SLUG,
} from "@shared/constants";
import { PolicyProduct } from "@shared/types";

export const productToSlug = (product: PolicyProduct) => {
  switch (product) {
    case "sast":
      return GLOBAL_POLICY_SLUG;
    case "secrets":
      return GLOBAL_SECRETS_POLICY_SLUG;
    case "sca":
      return GLOBAL_SUPPLY_CHAIN_POLICY_SLUG;
  }
};

export const productToUrl = (product: PolicyProduct) => {
  switch (product) {
    case "sast":
      return "policies";
    case "secrets":
      return "policies/secrets";
    case "sca":
      return "policies/supply-chain";
  }
};
