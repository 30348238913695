import { useLocation } from "react-router";

import { getOrgSlug, getOrgSlugFromPath } from "@shared/utils";

import { useOrg } from "../useOrg";

/**
 * @returns the app base path for the current organization, or "/manage" if
 * there is no organization.
 */
export const useBasePath = () => {
  const { pathname } = useLocation();
  const [org] = useOrg();
  const orgPath = org ? `/orgs/${getOrgSlug(org)}` : null;

  const orgName = getOrgSlugFromPath(pathname);

  return orgName !== undefined
    ? `/orgs/${orgName}`
    : orgPath
    ? orgPath
    : "/manage";
};
