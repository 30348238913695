import { useEffect } from "react";
import { useAtom } from "jotai";

import { orgAtom } from "@shared/state";
import { getOrgSlug, loadOrg } from "@shared/utils";

import { useChangeOrg } from "../useChangeOrg";
import { useUser } from "../useUser";
import { useUserOrgs } from "../useUserOrgs";

/**
 * This hook ensures that the correct current organization is loaded, and that
 * the URL reflects the current organization. It should be used at the top level
 * of the app.
 */
export const useSyncOrg = () => {
  const [org] = useAtom(orgAtom);
  const [user] = useUser();
  const { data: userOrgs, isLoading: isUserOrgsLoading } = useUserOrgs();
  const changeOrg = useChangeOrg();

  useEffect(() => {
    if (!user || isUserOrgsLoading || !userOrgs) {
      return;
    }
    const loadedOrg = loadOrg(user, userOrgs);
    const orgIsDifferent = loadedOrg && org && loadedOrg.id !== org.id;
    if ((!org || orgIsDifferent) && loadedOrg) {
      changeOrg(loadedOrg);
    }
  }, [org, changeOrg, user, userOrgs, isUserOrgsLoading]);

  useEffect(() => {
    if (org && window.location.pathname.startsWith("/orgs/-")) {
      const newPath = window.location.pathname.replace(
        "/-",
        `/${getOrgSlug(org)}`
      );
      // this is used outside of the router, so we need to change the URL using
      // the window object
      window.history.replaceState(
        {},
        "",
        `${newPath}${window.location.search}`
      );
    }
  });
};
