import React from "react";

import { Language } from "@shared/types";
import { ID_BY_KEY, VISIBLE_LANGUAGES } from "@shared/utils";

import CheatsheetRaw from "@assets/cheatsheet.json";

import "./SemgrepCheatsheetStyles.scss";

interface Props {
  forLanguage: keyof CheatsheetJson;
  hideIndex?: boolean;
}

interface EntryProps {
  index: number;
  pattern: string;
  code: string;
  highlights: Highlight[];
  footer?: string;
}

const Cheatsheet: CheatsheetJson = CheatsheetRaw;

type CheatsheetMap = {
  [category: string]: { [subcategory: string]: CheatsheetMapEntry[] };
};
type CheatsheetJson = {
  [lang in Language]: CheatsheetMap;
};

interface CheatsheetMapEntry {
  pattern: string | null;
  code: string | null;
  code_path: string | null;
  pattern_path: string | null;
  highlights: Highlight[];
}

interface Highlight {
  start: { line: number; col: number };
  end: { line: number; col: number };
}

interface CodeMatchProps {
  code_lines: string[];
  highlights: Highlight[];
}

const CodeMatch: React.FC<CodeMatchProps> = (props) => {
  const rows = props.code_lines.map((line_text, line) => {
    const skipLine = line_text.match(/ERROR/) !== null;
    const highlightWholeLine =
      props.highlights.filter(
        (h) => h.start.line < line + 1 && h.end.line > line + 1
      ).length > 0;
    const startHighlightOnThisLine = props.highlights.filter(
      (h) => h.start.line === line + 1
    );
    const endHighlightOnThisLine = props.highlights.filter(
      (h) => h.end.line === line + 1
    );

    const startHighlight =
      startHighlightOnThisLine.length > 0
        ? startHighlightOnThisLine[0].start.col
        : highlightWholeLine || endHighlightOnThisLine.length > 0
        ? 0
        : line_text.length + 1;
    const endHighlight =
      endHighlightOnThisLine.length > 0
        ? endHighlightOnThisLine[0].end.col
        : line_text.length + 1;

    if (skipLine) {
      return undefined;
    }

    line_text = line_text + "\n";

    return (
      <span key={`cheatsheet-match-${line}`}>
        <span>{line_text.substring(0, startHighlight - 1)}</span>
        <span className="cheatsheet-match-text">
          {line_text.substring(startHighlight - 1, endHighlight - 1)}
        </span>
        <span>{line_text.substring(endHighlight - 1)}</span>
      </span>
    );
  });
  return <div>{rows}</div>;
};

const CheatsheetEntry: React.FC<EntryProps> = (props) => {
  return (
    <div className="CheatsheetEntry">
      <div className="pair" key={props.index}>
        <div className="pattern">{props.pattern}</div>
        <div className="match">
          <CodeMatch
            code_lines={props.code.split("\n")}
            highlights={props.highlights}
          />
        </div>
      </div>
      {props.footer ? (
        <div className="cheatsheet-entry-footer">
          <div>{props.footer}</div>
        </div>
      ) : null}
    </div>
  );
};

export const SemgrepCheatsheet: React.FC<Props> = ({
  forLanguage,
  hideIndex,
}) => {
  const normalizedLanguageName = ID_BY_KEY[forLanguage];
  if (normalizedLanguageName === undefined) {
    return null;
  }
  const languageFriendlyName =
    VISIBLE_LANGUAGES[normalizedLanguageName]?.name || "unknown";

  const cheatSheetForLang = Cheatsheet[normalizedLanguageName];
  if (cheatSheetForLang === undefined) {
    return null;
  }

  return (
    <div className="cheatsheet">
      {!hideIndex && (
        <ul>
          {Object.entries(cheatSheetForLang).map(([category, _], index) => (
            <li key={index}>
              <a href={`#category-${index}`}>{category}</a>
            </li>
          ))}
        </ul>
      )}

      {Object.entries(cheatSheetForLang).map(
        ([category, subs], category_index) => (
          <div className="example-category" key={category_index}>
            <h3>
              <span id={`category-${category_index}`}>{category}</span>
            </h3>
            <div className="examples">
              {Object.entries(subs).map(([subcategory, entries], index) => (
                <div className="example" key={index}>
                  <h4>{subcategory}</h4>
                  <div>
                    {entries
                      .filter((entry) => entry !== null)
                      .map((entry, index) =>
                        entry.pattern && entry.code ? (
                          <CheatsheetEntry
                            key={`cheatsheet-item-${index}`}
                            index={index}
                            pattern={entry.pattern}
                            code={entry.code}
                            highlights={entry.highlights}
                          />
                        ) : (
                          <div key={index}>
                            This example is missing, or this feature isn't
                            supported yet for {languageFriendlyName} in Semgrep.
                            File an issue or make a PR
                            {(entry.pattern_path || entry.code_path) &&
                              ` for ${
                                entry.pattern_path
                                  ? entry.code_path
                                  : entry.pattern_path
                              }`}
                            !
                          </div>
                        )
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
};
