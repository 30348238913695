import { forwardRef } from "react";
import { Group, Text } from "@mantine/core";

import { SelectItemData } from "@shared/types";

interface ItemProps
  extends SelectItemData,
    React.ComponentPropsWithoutRef<"div"> {}

export const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  (
    { value, label, description, count, leftSection, ...others }: ItemProps,
    ref
  ) => (
    <div key={value} ref={ref} {...others} data-testid={`select-item-${value}`}>
      <Group gap="xs" wrap="nowrap">
        {leftSection}
        <Text inherit>
          {label} {count && <Text span>({count})</Text>}
        </Text>
      </Group>
      {description && (
        <Text fz="xs" opacity={0.65} inherit>
          {description}
        </Text>
      )}
    </div>
  )
);
