import {
  RuleBoardGetResponse,
  RuleBoardPutRequest,
  RuleBoardUpdate,
} from "@shared/types";

import { authPut } from "../auth/auth";

export const updateRuleBoard = async (
  board_slug: string,
  changes: RuleBoardUpdate[],
  deploymentId: number
): Promise<RuleBoardGetResponse> => {
  return await authPut<RuleBoardPutRequest, RuleBoardGetResponse>(
    `/api/agent/deployments/${deploymentId}/ruleboards/${board_slug}`,
    {
      changes,
    }
  );
};
