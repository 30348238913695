import React from "react";
import { observer } from "mobx-react-lite";
import { faChevronDown, faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Group, Menu, Text, Tooltip } from "@mantine/core";

import styles from "./MenuDropdown.module.css";

export type MenuDropdownItem = {
  name: string;
  info: string | JSX.Element;
  enabled: boolean;
};

interface MenuDropdownProps {
  items: MenuDropdownItem[];
  onChange: (new_kind: string | null) => void;
  renderLeftIcon?: (kind: string) => JSX.Element;
  value: string;
  width?: string;
}
export const MenuDropdown: React.FC<MenuDropdownProps> = observer(
  ({ items, onChange, renderLeftIcon, value, width = "150px" }) => {
    const options = items.map(({ name, info, enabled }, index) => (
      <Menu.Item
        key={index}
        value={name}
        disabled={!enabled}
        leftSection={
          renderLeftIcon && (
            <div className={styles["entry-left-icon"]}>
              {renderLeftIcon(name)}
            </div>
          )
        }
        rightSection={
          <Tooltip label={info}>
            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
          </Tooltip>
        }
        onClick={() => onChange(name)}
      >
        <Group style={{ justifyContent: "space-between" }}>
          <Group gap="5px" style={{ fontSize: 13 }}>
            {name}
          </Group>
        </Group>
      </Menu.Item>
    ));
    return (
      <Menu openDelay={0}>
        <Menu.Target>
          <Card
            bg="var(--mantine-color-gray-1)"
            padding="5"
            w={width}
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            className={styles["card"]}
          >
            <Group style={{ justifyContent: "space-between" }}>
              <Group
                gap="5px"
                // only put left padding if there's no icon, or it looks weird
                style={{ paddingLeft: renderLeftIcon ? undefined : "5px" }}
              >
                {renderLeftIcon && renderLeftIcon(value)}
                <Text
                  size="xs"
                  // for some reason putting this into the css doesn't work
                  style={{ fontSize: 12 }}
                  className={styles["entry-text"]}
                >
                  {value}
                </Text>
              </Group>
              <div className={styles["down-chevron"]}>
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              </div>
            </Group>
          </Card>
        </Menu.Target>

        <Menu.Dropdown>{...options}</Menu.Dropdown>
      </Menu>
    );
  }
);
