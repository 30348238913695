import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import styled from "styled-components";

// Nx doesn't like that these libraries are both statically imported and dynamically imported in the same file.
// It's probably correct and we should address this at some point, but for now we'll disable the rule for the offending imports.
import { AppNavbar } from "@shared/components";
import { ReactLazyPreloadable } from "@shared/utils";

const EditorScreen = ReactLazyPreloadable(() =>
  import("@modules/editor").then((module) => ({
    default: module.EditorScreen,
  }))
);

const AppContainer = styled.main`
  padding-top: var(--global-spacing-navbar-height);
`;
const PlaygroundContainer = styled(AppContainer)`
  height: 100vh;
  width: 100vw;
`;

export const PlaygroundRouter: React.FC = () => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const currentPath = history.location.pathname;
  const isDevelopFromParams = params.get("develop") === "true";

  // for redirecting from old to new playground - registry and blank rules
  const ruleIdFromParams = params.get("registry");
  let newPlaygroundRegistryUrl =
    ruleIdFromParams === null ? "new" : `r/${ruleIdFromParams}`;

  // for redirecting from old to new playground - snippets
  let newPlaygroundSnippetUrl = `/playground${currentPath}`;

  // add version parameter if necessary
  if (isDevelopFromParams) {
    const developParam = "develop=true";
    newPlaygroundRegistryUrl += `?${developParam}`;
    newPlaygroundSnippetUrl += `?${developParam}`;
  }

  return (
    <Switch>
      <Route path="/playground" exact>
        <Redirect to={`/playground/${newPlaygroundRegistryUrl}`} />
      </Route>
      <Route path={`/playground/:bundleAddress*`} exact>
        <AppNavbar />
        <PlaygroundContainer>
          <EditorScreen />
        </PlaygroundContainer>
      </Route>
      <Route path="/s/:snippetFqname" exact>
        <Redirect to={newPlaygroundSnippetUrl} />
      </Route>
    </Switch>
  );
};
