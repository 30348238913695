import { useCallback, useMemo } from "react";
import { Text } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";

import { GithubRepository } from "@protos/external/v1/github_repository";
import { TargetRepository } from "@protos/search/v1/search_service";
import { ExternalLink } from "@shared/components";
import { usePublicGithubRepos } from "@shared/hooks";

import { ProjectsSelect } from "./ProjectsSelect";

const targetToGithubRepo = (repo: TargetRepository) => {
  return GithubRepository.fromJSON(repo);
};

interface PublicReposSelectProps {
  value: TargetRepository[];
  onChange: (value: TargetRepository[]) => void;
}

export const PublicReposSelect = ({
  value,
  onChange,
}: PublicReposSelectProps) => {
  const [debouncedSearch, setDebouncedSearch] = useDebouncedState("", 750);
  const { data: searchResults, isLoading } = usePublicGithubRepos({
    query: debouncedSearch,
  });

  const handleSearchChange = useCallback(
    (value: string) => setDebouncedSearch(value),
    [setDebouncedSearch]
  );

  const options = useMemo(() => {
    const transformedValues = value.map(targetToGithubRepo);
    return debouncedSearch.length
      ? searchResults?.repositories ?? []
      : transformedValues;
  }, [debouncedSearch, searchResults, value]);

  return (
    <>
      <ProjectsSelect
        options={options}
        value={value}
        onChange={onChange}
        onSearchChange={handleSearchChange}
        filterResults={false}
        isLoadingOptions={!!debouncedSearch.length && isLoading}
        help={
          <Text ta="right" fz="xs" mb="sm">
            Supports Github{" "}
            <ExternalLink href="https://docs.github.com/en/search-github/github-code-search/understanding-github-code-search-syntax">
              search syntax
            </ExternalLink>
          </Text>
        }
      />
      {!debouncedSearch.length && (
        <Text ta="center" c="dimmed">
          Start searching to select public repos
        </Text>
      )}
    </>
  );
};
