import React from "react";
import { useContextMenu } from "mantine-contextmenu";
import styled from "styled-components";
import { faFolder as faFolderOpen } from "@fortawesome/pro-regular-svg-icons";
import {
  faChevronDown,
  faChevronRight,
  faFile,
  faFolder,
  faHourglass,
  faLock,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Text, Tooltip, UnstyledButton } from "@mantine/core";

import { HighlightedText } from "../FileBrowser/HighlightedText";

import styles from "./NodeLabel.module.css";

const ActionIcons = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  gap: 8px;
  align-items: center;
  height: 100%;
  padding: 0 8px;
`;

const ClickableIcon = styled.div`
  cursor: pointer;
  color: var(--mantine-color-gray-6);
  &:hover {
    color: black;
  }
`;

const LockedFileIcon = (
  <span className="fa-layers fa-fw" style={{ display: "inline-block" }}>
    <FontAwesomeIcon
      fixedWidth
      icon={faFile}
      color="var(--mantine-color-gray-9)"
    />
    <FontAwesomeIcon
      fixedWidth
      icon={faLock}
      transform={"shrink-9 right-1 down-3"}
      color="var(--mantine-color-gray-0)"
    />
  </span>
);

interface ContextAction {
  actionName: string;
  action: (event: React.MouseEvent) => void;
  icon: React.ReactNode;
}

interface Props {
  type: "file" | "private-file" | "folder-open" | "folder-closed" | "loading";
  title: string;
  onClick?: () => void;
  contextMenuActions?: ContextAction[];
  childCount?: number;
  searchQuery?: string;
  isLoading?: boolean;
  isSelected: boolean;
}

const generateIcon = (type: string) => {
  if (type === "private-file") return LockedFileIcon;

  const icon =
    type === "file"
      ? faFile
      : type === "folder-open"
      ? faFolderOpen
      : type === "folder-closed"
      ? faFolder
      : type === "loading"
      ? faHourglass
      : null;

  return (
    icon && (
      <FontAwesomeIcon
        fixedWidth
        icon={icon}
        color="var(--mantine-color-gray-9)"
        size="sm"
        style={{ flexShrink: 0 }}
      />
    )
  );
};

export const NodeLabel = ({
  type,
  title,
  onClick,
  contextMenuActions = [],
  childCount,
  searchQuery = "",
  isLoading = false,
  isSelected,
}: Props) => {
  const actionIconsItems = contextMenuActions.filter(({ icon }) => !!icon);
  const { showContextMenu } = useContextMenu();

  const actions = contextMenuActions.map((action) => ({
    key: action.actionName,
    icon: action.icon,
    title: action.actionName,
    onClick: (e: React.MouseEvent) => action.action(e),
  }));
  return (
    <UnstyledButton
      onClick={onClick}
      disabled={onClick === undefined}
      aria-selected={isSelected}
      aria-label={title}
      pos="relative"
      data-selected={isSelected}
      className={styles.button}
      w="100%"
      onContextMenu={showContextMenu(actions)}
    >
      <Grid align="center" justify="left" m={0} p={8}>
        <Grid.Col span="content" style={{ overflow: "hidden" }} py={0} pl={0}>
          {childCount !== undefined ? (
            <FontAwesomeIcon
              fixedWidth
              icon={type === "folder-open" ? faChevronDown : faChevronRight}
              color="var(--mantine-color-dark-2)"
              size="xs"
            />
          ) : null}
        </Grid.Col>
        <Grid.Col span="content" style={{ overflow: "hidden" }} p={0}>
          {generateIcon(type)}
        </Grid.Col>
        <Grid.Col span="auto" style={{ overflow: "hidden" }} py={0}>
          <Text truncate size="sm">
            <HighlightedText fullText={title} highlightedText={searchQuery} />
          </Text>
        </Grid.Col>
        <Grid.Col span="content" style={{ overflow: "hidden" }} py={0}>
          {isLoading || (childCount && childCount === -1) ? (
            <FontAwesomeIcon
              icon={faSpinner}
              pulse
              color="gray"
              title="loading contents"
              style={{ marginRight: 8 }}
              size="sm"
            />
          ) : childCount !== undefined ? (
            <Text size="xs" style={{ whiteSpace: "nowrap" }}>
              {childCount}
            </Text>
          ) : null}
        </Grid.Col>
      </Grid>
      {actionIconsItems.length > 0 && (
        <ActionIcons className="action-items">
          {actionIconsItems.map(({ icon, action, actionName }) => {
            return (
              <Tooltip
                label={actionName}
                position="top"
                key={actionName}
                withArrow
                withinPortal
              >
                <ClickableIcon
                  role="button"
                  aria-label={actionName}
                  onClick={(e) => {
                    action(e);
                    e.stopPropagation();
                  }}
                >
                  {icon}
                </ClickableIcon>
              </Tooltip>
            );
          })}
        </ActionIcons>
      )}
    </UnstyledButton>
  );
};
