import { RunBody, RunResponse, Snippet } from "@shared/types";
// TODO: Remove this when semgrep_output_types is moved to a lib
// eslint-disable-next-line
import { readCliOutput } from "@semgrep_output_types";

import { authPost } from "../auth/auth";

const RUN_URL = `/api/run`;

// atd is wonky about union types in TS, so we must explicitly call readCliOutput
const fixRunResponse = (data: RunResponse): RunResponse => {
  const parsedOutput = readCliOutput(data.semgrep_result.output);
  data.semgrep_result.output = parsedOutput;
  return data;
};

export const run = async (
  snippet: Snippet,
  yaml: boolean,
  options: {
    is_develop?: boolean;
    is_deep?: boolean;
    is_debug?: boolean;
    with_ai_autofixes?: boolean;
    deployment_id?: number;
  }
): Promise<RunResponse> => {
  const value = await authPost<RunBody, RunResponse>(RUN_URL, {
    ...snippet,
    yaml,
    ...options,
  });
  return fixRunResponse(value);
};
