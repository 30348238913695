import { useContext, useState } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Divider, SegmentedControl } from "@mantine/core";
import { useHotkeys } from "@mantine/hooks";

import { WorkbenchContext } from "../../providers";

import { emptyPattern, Rule, RuleWithKind } from "./types/rule";
import { ModeToggleAlert } from "./utils/ModeToggleAlert";
import { StructureRuleMetadataEditor } from "./utils/StructureRuleMetadataEditor";
import { PatternTree } from "./PatternTree";
import { TaintStructure } from "./TaintStructure";

import styles from "./StructureMode.module.css";

type modeToggle = "off" | "on-search" | "on-taint";
function isSwitch(toggleState: modeToggle) {
  return toggleState !== "off";
}

const StructureModeComponent = () => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;

  // should ideally not call structureRuleFromText because we guarded
  // rendering the structure mode component in RulePanelEditor, but
  // let's keep it to be safe
  const rule = bundle?.structureRule ?? bundle?.syncStructureRuleFromText();

  // Structure Mode compatible undo and redo.
  useHotkeys([
    [
      "mod+shift+z",
      (event) => {
        event.preventDefault();
        bundle?.onRuleRedo();
      },
    ],
    [
      "mod+z",
      (event) => {
        event.preventDefault();
        bundle?.onRuleUndo();
      },
    ],
  ]);

  const renderRule = (rule: Rule) => {
    const r = rule.value;
    switch (r.kind) {
      case "search":
        return <PatternTree pattern={r.pattern} topArray={null} />;
      case "taint": {
        return <TaintStructure rule={rule as RuleWithKind<"taint">} />;
      }
    }
  };

  // state we need for displaying the switching mode warning, as well as
  // keeping track of what mode we are in currently
  const [isSearch, setIsSearch] = useState(rule?.value.kind === "search");
  const [modeToggleState, setModeToggleState] = useState<modeToggle>("off");

  if (rule === undefined) {
    return (
      <Box p="md">
        <Alert
          color="red"
          icon={<FontAwesomeIcon icon={faCircleExclamation} />}
          title="Error converting rule"
        >
          No Structure Rule?
        </Alert>
      </Box>
    );
  }

  const onConfirm = () => {
    if (isSwitch(modeToggleState)) {
      runInAction(() => {
        if (modeToggleState === "on-taint" && bundle) {
          setModeToggleState("off");
          setIsSearch(false);
          const a = [emptyPattern()];
          const b = [emptyPattern()];
          bundle.structureRule = new Rule(
            {
              kind: "taint",
              sources: a,
              sinks: b,
              sanitizers: [],
            },
            rule.language,
            rule.message,
            rule.ruleID
          );
        } else if (modeToggleState === "on-search" && bundle) {
          setModeToggleState("off");
          setIsSearch(true);
          bundle.structureRule = new Rule(
            {
              kind: "search",
              pattern: emptyPattern(),
            },
            rule.language,
            rule.message,
            rule.ruleID
          );
        }
      });
    }
  };
  const onCancel = () => {
    setModeToggleState("off");
  };
  const onModeToggle = (value: string) => {
    if (value === "search") {
      setModeToggleState("on-search");
    } else if (value === "taint") {
      setModeToggleState("on-taint");
    }
  };

  return (
    <>
      <div style={{ position: "relative", backgroundColor: "white" }}>
        <div
          style={{
            overflow: "scroll",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          {/* TODO:  This is currently buggy, the segmented control is not
            sticky, so the user will need to scroll to the top if they want
            to switch modes.
            I cannot for the life of me figure out how to make it work properly.
            This has caused me excruciating pain and suffering thus far and so
            I am choosing to leave it in this state for now. Godspeed.
            */}
          <ModeToggleAlert
            opened={isSwitch(modeToggleState)}
            onConfirm={onConfirm}
            onCancel={onCancel}
          />
          <div>
            <SegmentedControl
              color="blue"
              data={["search", "taint"]}
              className={styles.ruleModeControl}
              style={{ width: "var(--rule-control-width)" }}
              value={isSearch ? "search" : "taint"}
              onChange={onModeToggle}
            />
          </div>
          <Divider />
          {/* this stuff is needed to make the rule play nice with the metadata
          editor below
          without it, everything starts displaying super out of whack
          for instance, without this outer relative position, we can't scroll
          vertically in the rule anymore
       */}
          <div className={styles.metadataInfo}>{renderRule(rule)}</div>
        </div>
      </div>
      <StructureRuleMetadataEditor rule={rule} />
    </>
  );
};

export const StructureMode = observer(StructureModeComponent);
