import {
  faGithub,
  faGitlab,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons";

import { AuthProvider, PolicyWithRules } from "@shared/types";

// Contributor logos
import Auth0LogoPng from "@assets/contributors/Auth0Logo.png";
import ChefLogoPng from "@assets/contributors/ChefLogo.png";
import CheggLogoPng from "@assets/contributors/CheggLogo.png";
import DropboxLogoPng from "@assets/contributors/DropboxLogo.png";
import DropboxMonochromeLogoSvg from "@assets/contributors/DropboxMonochromeLogo.svg";
import FigmaLogoPng from "@assets/contributors/FigmaLogo.png";
import FigmaMonochromeLogoSvg from "@assets/contributors/FigmaMonochromeLogo.svg";
import GitLabLogoPng from "@assets/contributors/GitLabLogo.png";
import HashiCorpLogoPng from "@assets/contributors/HashiCorpLogo.png";
import HashiCorpMonochromeLogoSvg from "@assets/contributors/HashiCorpMonochromeLogo.svg";
import PearsonLogoPng from "@assets/contributors/PearsonLogo.png";
import Rapid7LogoPng from "@assets/contributors/Rapid7Logo.png";
import RazorpayLogoPng from "@assets/contributors/RazorpayLogo.png";
import ShopifyLogoPng from "@assets/contributors/ShopifyLogo.png";
import ShopifyLogoNoTextPng from "@assets/contributors/ShopifyLogoNoText.png";
import ShopifyMonochromeLogoSvg from "@assets/contributors/ShopifyMonochromeLogo.svg";
import SlackLogoPng from "@assets/contributors/SlackLogo.png";
import SlackMonochromeLogoSvg from "@assets/contributors/SlackMonochromeLogo.svg";
import SnowflakeLogoPng from "@assets/contributors/SnowflakeLogo.png";
import SnowflakeMonochromeLogoSvg from "@assets/contributors/SnowflakeMonochromeLogo.svg";
import TrailOfBitsLogoPng from "@assets/contributors/TrailOfBitsLogo.png";
import UpvestLogoPng from "@assets/contributors/UpvestLogo.png";
import ZulipLogoPng from "@assets/contributors/ZulipLogo.png";
// Inventory icons
import AllIcon from "@assets/inventory-icons/AllIcon.png";
import AngularIcon from "@assets/inventory-icons/AngularIcon.png";
import ChefIcon from "@assets/inventory-icons/ChefIcon.png";
import CircleCIIcon from "@assets/inventory-icons/CircleCIIcon.png";
import CodecovIcon from "@assets/inventory-icons/CodecovIcon.png";
import DjangoIcon from "@assets/inventory-icons/DjangoIcon.png";
import DockerComposeIcon from "@assets/inventory-icons/DockerComposeIcon.png";
import DockerIcon from "@assets/inventory-icons/DockerIcon.png";
import EmberIcon from "@assets/inventory-icons/EmberIcon.png";
import ExpressIcon from "@assets/inventory-icons/ExpressJSIcon.png";
import FlaskIcon from "@assets/inventory-icons/FlaskIcon.png";
import GitHubActionsIcon from "@assets/inventory-icons/GitHubActionsIcon.png";
import GitLabIcon from "@assets/inventory-icons/GitLabIcon.png";
import JenkinsIcon from "@assets/inventory-icons/JenkinsIcon.png";
import JQueryIcon from "@assets/inventory-icons/JQueryIcon.png";
import JWTIcon from "@assets/inventory-icons/JWTIcon.png";
import KubernetesIcon from "@assets/inventory-icons/KubernetesIcon.png";
import NginxIcon from "@assets/inventory-icons/NginxIcon.png";
import NodeJSIcon from "@assets/inventory-icons/NodeJSIcon.png";
import PreCommitIcon from "@assets/inventory-icons/PreCommitIcon.png";
import PuppetIcon from "@assets/inventory-icons/PuppetIcon.png";
import RailsIcon from "@assets/inventory-icons/RailsIcon.png";
import ReactIcon from "@assets/inventory-icons/ReactIcon.png";
import SpringIcon from "@assets/inventory-icons/SpringIcon.png";
import TerraformIcon from "@assets/inventory-icons/TerraformIcon.png";
import TravisIcon from "@assets/inventory-icons/TravisIcon.png";
// Language icons
import BashIcon from "@assets/language-icons/BashIcon.png";
import CLangIcon from "@assets/language-icons/CLangIcon.png";
import CppIcon from "@assets/language-icons/CppIcon.png";
import CSharpIcon from "@assets/language-icons/csharp.png";
import GenericIcon from "@assets/language-icons/generic.png";
import GoLangIcon from "@assets/language-icons/GoLangIcon.png";
import JavaIcon from "@assets/language-icons/JavaIcon.png";
import JavaScriptIcon from "@assets/language-icons/JSLangIcon.png";
import JSONIcon from "@assets/language-icons/JSONLangIcon.png";
import JuliaIcon from "@assets/language-icons/JuliaIcon.png";
import OCamlIcon from "@assets/language-icons/OcamlIcon.png";
import PhpIcon from "@assets/language-icons/phpIcon.png";
import PythonIcon from "@assets/language-icons/PythonIcon.png";
import RubyIcon from "@assets/language-icons/RubyIcon.png";
import RustIcon from "@assets/language-icons/RustIcon.png";
import ShellIcon from "@assets/language-icons/ShellIcon.png";
import SolidityIcon from "@assets/language-icons/Solidity.png";
import SwiftIcon from "@assets/language-icons/SwiftIcon.png";
import TypeScriptIcon from "@assets/language-icons/TypeScriptIcon.png";
import VueIcon from "@assets/language-icons/VueIcon.png";
import YAMLIcon from "@assets/language-icons/YAMLIcon.png";

export const DEFAULT_SEMGREP_CONFIG_ID = "p/ci";
export const LOCAL_INSTALL_URL = "/docs/getting-started/#run-semgrep-locally";
export const RESIZE_DEBOUNCE_MILLIS = 49; // Just under 3 frames
export const LOGIN_STATE_SENTINEL = "github_auth_sentinel";
export const ci_users = [
  ChefLogoPng,
  GitLabLogoPng,
  SnowflakeLogoPng,
  Rapid7LogoPng,
  UpvestLogoPng,
];

interface ContributorsWithAltText {
  imageSrc: string;
  text: string;
}

export const HOME_SCREEN_CONTRIBUTORS: ContributorsWithAltText[] = [
  { imageSrc: DropboxLogoPng, text: "Dropbox" },
  { imageSrc: SlackLogoPng, text: "Slack" },
  { imageSrc: FigmaLogoPng, text: "Figma" },
  { imageSrc: ChefLogoPng, text: "Chef" },
  { imageSrc: ZulipLogoPng, text: "Zulip" },
  { imageSrc: TrailOfBitsLogoPng, text: "Trail of Bits" },
  { imageSrc: SnowflakeLogoPng, text: "Snowflake" },
  { imageSrc: GitLabLogoPng, text: "GitLab" },
  { imageSrc: ShopifyLogoPng, text: "Shopify" },
  { imageSrc: HashiCorpLogoPng, text: "HashiCorp" },
  { imageSrc: CheggLogoPng, text: "Chegg" },
];

// Note that these logos are light purple
export const CONTACT_US_CONTRIBUTORS: ContributorsWithAltText[] = [
  { imageSrc: SnowflakeMonochromeLogoSvg, text: "Snowflake" },
  { imageSrc: FigmaMonochromeLogoSvg, text: "Figma" },
  { imageSrc: ShopifyMonochromeLogoSvg, text: "Shopify" },
  { imageSrc: SlackMonochromeLogoSvg, text: "Slack" },
  { imageSrc: HashiCorpMonochromeLogoSvg, text: "HashiCorp" },
  { imageSrc: DropboxMonochromeLogoSvg, text: "Dropbox" },
];

interface UserWithAltText {
  imageSrc: string;
  text: string;
}

export const appUsersNoText: UserWithAltText[] = [
  { imageSrc: FigmaLogoPng, text: "Figma" },
  { imageSrc: Auth0LogoPng, text: "Auth0" },
  { imageSrc: RazorpayLogoPng, text: "Razorpay" },
  { imageSrc: ShopifyLogoNoTextPng, text: "Shopify" },
  { imageSrc: PearsonLogoPng, text: "Pearson" },
];

export const RETURN_PATH_KEY = "return_path";

interface AuthProviderWithIcon extends AuthProvider {
  icon: IconDefinition;
}

export const AUTH_PROVIDERS: AuthProviderWithIcon[] = [
  {
    id: null,
    provider_name: "github",
    provider_type: "github",
    display_name: "GitHub",
    base_url: "https://github.com/login/oauth",
    icon: faGithub,
    promoted: true,
  },
  {
    id: null,
    provider_name: "gitlab",
    provider_type: "openid",
    display_name: "GitLab",
    base_url: "https://gitlab.com/oauth",
    icon: faGitlab,
    promoted: false,
  },
];

export const GITHUB_WORKFLOW_FILE_PATH = ".github/workflows/semgrep.yml";
export const SEMGREP_IGNORE_FILE_PATH = ".semgrepignore";

export const DEFAULT_POLICY_TEXT =
  "Use this field to describe your policy. What does it contain? When should it be used?";
// default values for policies when created
export const DEFAULT_POLICY_OPTIONS: Omit<PolicyWithRules, "name" | "slug"> = {
  rules: [],
  notifications: [],
  pr_comments: false,
  blocking: false,
  is_default: false,
  repos: [],
  description: DEFAULT_POLICY_TEXT,
  overrides: [],
};

export const RULE_SEVERITIES = ["INFO", "WARNING", "ERROR"];

// this array is to convert rule visibilities to friendly names for frontend display
export const RULE_VISIBILITY_ALIASES: { [abbr: string]: string } = {
  public: "Community (Public)",
  org_private: "Private",
  logged_in: "Logged in",
  team_tier: "Pro (Login)",
};

export const techToImage: { [lang: string]: string } = {
  python: PythonIcon,
  javascript: JavaScriptIcon,
  js: JavaScriptIcon,
  go: GoLangIcon,
  golang: GoLangIcon,
  java: JavaIcon,
  c: CLangIcon,
  cpp: CppIcon,
  "c#": CSharpIcon,
  csharp: CSharpIcon,
  ocaml: OCamlIcon,
  ruby: RubyIcon,
  typescript: TypeScriptIcon,
  ts: TypeScriptIcon,
  php: PhpIcon,
  json: JSONIcon,
  julia: JuliaIcon,
  rust: RustIcon,
  yaml: YAMLIcon,
  shell: ShellIcon,
  bash: BashIcon,
  generic: GenericIcon,
  none: GenericIcon,
  regex: GenericIcon,
  // other
  all: AllIcon,
  angular: AngularIcon,
  "angular yarn": AngularIcon,
  "angular core": AngularIcon,
  "angular core yarn": AngularIcon,
  chef: ChefIcon,
  "codecov file": CodecovIcon,
  "codecov url": CodecovIcon,
  circleci: CircleCIIcon,
  django: DjangoIcon,
  docker: DockerIcon,
  dockerfile: DockerIcon,
  "docker compose": DockerComposeIcon,
  ember: EmberIcon,
  "ember yarn": EmberIcon,
  express: ExpressIcon,
  expressjs: ExpressIcon,
  flask: FlaskIcon,
  "github actions": GitHubActionsIcon,
  gitlab: GitLabIcon,
  jenkins: JenkinsIcon,
  jquery: JQueryIcon,
  jwt: JWTIcon,
  kubernetes: KubernetesIcon,
  nginx: NginxIcon,
  nodejs: NodeJSIcon,
  "pre commit": PreCommitIcon,
  puppet: PuppetIcon,
  rails: RailsIcon,
  react: ReactIcon,
  "react yarn": ReactIcon,
  solidity: SolidityIcon,
  spring: SpringIcon,
  swift: SwiftIcon,
  terraform: TerraformIcon,
  travis: TravisIcon,
  vue: VueIcon,
  "vue yarn": VueIcon,
};

//TODO: we should generate this code fron semgrep-interfaces/lang.json
export const techToCapitalization: { [lang: string]: string } = {
  python: "Python",
  javascript: "JavaScript",
  js: "JavaScript",
  go: "Go",
  golang: "Go",
  java: "Java",
  bash: "Bash",
  c: "C",
  cpp: "C++",
  ocaml: "OCaml",
  ruby: "Ruby",
  typescript: "TypeScript",
  ts: "TypeScript",
  php: "PHP",
  json: "JSON",
  julia: "Julia",
  rust: "Rust",
  yaml: "YAML",
  generic: "Generic",
  none: "Generic",
  regex: "Generic",
  // other
  all: "Generic",
  chef: "Chef",
  "codecov file": "Codecov",
  "codecov url": "Codecov",
  django: "Django",
  docker: "Docker",
  dockerfile: "Dockerfile",
  "docker compose": "Docker Compose",
  express: "Express",
  flask: "Flask",
  jquery: "jQuery",
  jwt: "JWT",
  kubernetes: "Kubernetes",
  nginx: "NGINX",
  nodejs: "Node.js",
  puppet: "Puppet",
  rails: "Rails",
  react: "React",
  spring: "Spring",
  terraform: "Terraform",
  travis: "Travis",
};

export const SNIPPET_ALIAS_REGEX = new RegExp(/^s\/[\w.@-]+:[\w.-]+$/);
export const SNIPPET_ID_REGEX = new RegExp(/^s\/[\w-]+$/);
export const RULE_NAME_REGEX = new RegExp(/^[A-Za-z0-9_-]+$/);
export const RULE_NAME_ERROR_TEXT =
  "Please use only the following characters: [A-Z],[a-z],[0-9],-,_";

// this array is to severity (number) to friendly names for frontend display
export const SEVERITIES = ["Low", "Medium", "High"];

export const CREATE_SSO_ROUTE = "/create-sso-deployment";

export const LS_CLI_TOKEN = "cli-token";
export const LS_CLI_USED = "cli-login-used-on";
export const CLI_TOKEN_PARAM = "cli-token";

export const REF_FILTER_PARAM = "ref";
export const REPO_FILTER_PARAM = "repo";
export const REPO_REF_FILTER_PARAM = "repo_ref";
export const PRIMARY_FILTER_PARAM = "primary";
export const TAB_FILTER_PARAM = "tab";
export const TRIAGE_REASON_FILTER_PARAM = "triage_reason";

export const DEFAULT_BRANCH_REFS = [
  "develop",
  "development",
  "main",
  "master",
  "trunk",
];

export const DEFAULT_BRANCHES_GROUP = "_default";
export const OTHER_BRANCHES_GROUP = "_other";
export const ALL_BRANCHES_GROUP = "_all";

export const HIGH_RISK_SENSITIVITIES = [
  "user authentication",
  "user authorization",
  "infrastructure",
  "superuser",
  "payments",
  "user data",
  "PII",
];

export const LOW_RISK_SENSITIVITIES = [
  "tests",
  "developer scripts",
  "migrations",
  "documentation scripts",
  "build process",
  "generated code",
  "vendored code",
];

export const SAST_FINDINGS_PAGE = "SAST Findings";
export const SCA_FINDINGS_PAGE = "SCA Findings";
export const SECRETS_FINDINGS_PAGE = "Secrets Findings";
