import { Pill as MantinePill } from "@mantine/core";

import classes from "./Pill.module.css";

export const Pill = MantinePill.extend({
  defaultProps: {
    classNames: {
      root: classes.root,
      label: classes.label,
      remove: classes.remove,
    },
  },
});
