import { useEffect, useRef } from "react";
import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Prompt, useLocation } from "react-router-dom";
import Split from "react-split-grid";
import styled from "styled-components";

import { SplitGutter } from "@shared/editorCore";

import { WorkbenchContext } from "../providers";

import { EditorHeader } from "./EditorHeader/EditorHeader";
import { RuleEditorPanel } from "./RuleEditorPanel/RuleEditorPanel";
import { EditorErrorStates } from "./TargetEditor/EditorErrorStates";
import { EditorLoading } from "./TargetEditor/EditorLoading";
import { ConfirmOverwriteAlert } from "./ConfirmOverwriteAlert";
import { MultipleRuleIdAlert } from "./MultipleRuleIdAlert";
import { TargetEditorPanel } from "./TargetEditorPanel";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 59px minmax(0, 1fr);
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 4px 1fr;
`;

const leaveUnsavedMessage = `You have unsaved changes. Do you want to continue without saving these changes?`;

const Panel = () => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle, isLoading, errorState } = workbench;
  const location = useLocation();
  const hasStructureRuleError = useRef(false);

  // This is in a useEffect here so that we only do it once, on first render.
  // If we dynamically recalculate whether there are structure rule errors on each
  // render, our defaultTab to RuleEditorPanel will change, which could potentially
  // cause an unexpected tab change during rule editing.
  // This way, we ensure that defaultTab should be set to only one value over the
  // course of an editor session.
  useEffect(() => {
    hasStructureRuleError.current =
      workbench.bundle?.getTextToStructureRuleErrors === null;
  }, [workbench.bundle]);

  useEffect(() => {
    function unloadHandler(event: BeforeUnloadEvent) {
      if (!workbench.hasUnsavedChanges || workbench.isSavedInLocalStorage) {
        return;
      }
      event.preventDefault();
      return (event.returnValue = leaveUnsavedMessage);
    }

    window.addEventListener("beforeunload", unloadHandler);
    return () => {
      window.removeEventListener("beforeunload", unloadHandler);
    };
    // eslint-disable-next-line
  }, []);

  if (errorState) return <EditorErrorStates errorState={errorState} />;
  if (isLoading) return <EditorLoading />;
  if (bundle === null) return null;

  return (
    <Container>
      <Prompt
        // `any` is used as the type here because location has type `H.Location`
        // and `location.state` has type `unknown`
        message={(nextLocation: any | undefined) => {
          // with type `any`, we can't be certain what is in location
          // only check showUnsavedChangesWarning if defined
          if (
            (nextLocation?.state &&
              !nextLocation.state.showUnsavedChangesWarning) ||
            // Check again hasUnsavedChanges in case it changed since the time the prompt was shown
            !workbench.hasUnsavedChanges ||
            // If saved in local storage, no need to show the prompt
            workbench.isSavedInLocalStorage ||
            workbench.isSaving ||
            // Pathname is the same (only query params changed)
            (nextLocation && nextLocation.pathname === location.pathname)
          ) {
            // don't prompt
            return true;
          }
          return leaveUnsavedMessage;
        }}
      />
      <ConfirmOverwriteAlert />
      <MultipleRuleIdAlert />
      <EditorHeader />
      <Split
        minSize={200}
        render={({ getGridProps, getGutterProps }: any) => (
          <Grid {...getGridProps()}>
            <RuleEditorPanel
              defaultTab={
                workbench.isPlayground && hasStructureRuleError.current
                  ? "structure"
                  : "advanced"
              }
            />
            <SplitGutter
              between={"left-right"}
              {...getGutterProps("column", 1)}
            />
            <TargetEditorPanel />
          </Grid>
        )}
      />
    </Container>
  );
};

export const EditorsPanel = observer(Panel);
