import { createGlobalStyle } from "styled-components";

import "./Global.scss";
import "./EditorHome.scss";

export const GlobalStyles = createGlobalStyle`
  html {
    /* Global styles */
    --global-content-width: 1200px;

    /* Mantine only supports till 1.2rem, bigger sizes are defined here */
    --global-font-size-l: 1.5rem;
    --global-font-size-xl: 1.75rem;

    /* Additional spacings next to Mantine's */
    --global-spacing-xxs: 0.125rem; // 2px
    --global-spacing-xl: 2.5rem; // 40px
    --global-spacing-xxl: 3.75rem; // 60px

    --global-spacing-navbar-height: 50px;
  }

  em, i {
    font-style: oblique 7.5deg;
  }

  strong, b {
    font-weight: 600;
  }

  body {
    background-color: var(--mantine-color-white);
    font-family: var(--mantine-font-family);
    font-weight: 400;
    line-height: 1.625;
    font-size: var(--mantine-font-size-md);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

    @media
    (-webkit-max-device-pixel-ratio: 1.5),
    (max-resolution: 128dpi) {
      /* Get font-smoothing back to normal in the editor only on non-retina screens */
      .monaco-editor {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
      }
    }

  a:where(:not([class*="mantine-"])) {
    color: var(--mantine-color-blue-6);

    :hover {
      color: var(--mantine-color-blue-8);
    }

    :active {
      color: var(--mantine-color-blue-9);
    }
  }

  pre,
  code {
    font-family: var(--mantine-font-family-monospace);
  }
`;
