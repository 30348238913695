import React from "react";
import { NotificationData, showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";

import { ApiError } from "@shared/types";

import { isApiError } from "../isApiError";

/**
 * @deprecated this function is deprecated. Please put the toast logic in the
 * react-query hook that calls the API function instead.
 * @example
 * ```ts
 * const usePostData = () => {
 *   return useMutation({
 *     mutationFn: (data: { name: string }) => postData(data),
 *     onError: (err: ApiError) => {
 *       showNotification({
 *         message: err.message || "There was an issue creating the data.",
 *     });
 * };
 * ```
 */
export function withToaster<A, B>(
  f: (...args: A[]) => Promise<B>,
  errorToMessage: (err: ApiError) => React.ReactNode,
  toastProps?: Omit<NotificationData, "message" | "color">
) {
  return (...args: A[]) => {
    const promise = f(...args);
    promise.catch((err) => {
      const message = isApiError(err)
        ? errorToMessage(err)
        : err.statusCode
        ? `Operation failed with response code: ${err.statusCode}`
        : JSON.stringify(err);
      console.error(err);
      Sentry.captureException(err);
      showNotification({
        ...(toastProps ?? {}),
        message,
        color: "red",
      });
    });
    return promise;
  };
}
