import { Badge, useMantineTheme } from "@mantine/core";

import { Advisory_Severity } from "@protos/sca/v1/sca";

export interface SeverityBadgeProps {
  severity: Advisory_Severity | undefined;
}

export const SeverityBadge = ({ severity }: SeverityBadgeProps) => {
  const { colors } = useMantineTheme();
  switch (severity) {
    case Advisory_Severity.CRITICAL:
      return (
        <Badge variant="filled" color="red.8">
          Critical
        </Badge>
      );
    case Advisory_Severity.HIGH:
      return (
        <Badge
          variant="filled"
          color="red.0"
          style={{
            color: colors["red"][8],
            border: "1px solid ",
            borderColor: colors["red"][8],
          }}
        >
          High
        </Badge>
      );
    case Advisory_Severity.MEDIUM:
      return (
        <Badge variant="filled" color="orange.6">
          Medium
        </Badge>
      );
    case Advisory_Severity.LOW:
      return (
        <Badge variant="filled" color="cyan.5">
          Low
        </Badge>
      );
    default:
      return null;
  }
};
