import { useCallback, useState } from "react";
import { faComment } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonProps,
  Modal,
  Stack,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";

import { FeedbackRequest } from "@protos/search/v1/search_service";
import { useOrg } from "@shared/hooks";

import { useQueryConsoleFeedback } from "../hooks/useQueryConsoleFeedback";

export const QueryConsoleFeedbackButton = (
  props: Omit<ButtonProps, "onClick">
) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useInputState("");

  const [org] = useOrg();

  const {
    mutate: submitFeedback,
    isPending,
    error,
  } = useQueryConsoleFeedback({
    onSuccess: () => {
      setModalOpen(false);
      setMessage("");
      showNotification({
        color: "green",
        title: "Your feedback was submitted",
        message: "Thanks for helping make Semgrep better!",
      });
    },
  });

  const onSubmitFeedback = useCallback(() => {
    submitFeedback(FeedbackRequest.create({ deploymentId: org?.id, message }));
  }, [message, submitFeedback, org?.id]);

  return (
    <>
      <Button
        size="compact-sm"
        color="green"
        leftSection={<FontAwesomeIcon icon={faComment} />}
        onClick={() => setModalOpen(true)}
        {...props}
      >
        Send feedback
      </Button>

      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title={<Title order={4}>How can we improve?</Title>}
      >
        <Stack>
          <Textarea
            placeholder="Code Search is useful for... I wish it could..."
            label="Message"
            withAsterisk
            value={message}
            onChange={setMessage}
          />
          {error && (
            <Text c="red" fz="sm">
              {error.body?.error}
            </Text>
          )}
          <Button
            fullWidth
            onClick={onSubmitFeedback}
            disabled={message.length === 0}
            loading={isPending}
          >
            Send Feedback
          </Button>
        </Stack>
      </Modal>
    </>
  );
};
