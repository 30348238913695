import { Link, useLocation } from "react-router-dom";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faInfoCircle, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button } from "@mantine/core";

import { ScmType } from "@protos/common/v1/common";
import { TargetRepository } from "@protos/search/v1/search_service";
import {
  useBasePath,
  useScmConfigList,
  useSearchableRepos,
} from "@shared/hooks";

import { ProjectsSelect } from "./ProjectsSelect";

interface PrivateReposSelectProps {
  value: TargetRepository[];
  onChange: (value: TargetRepository[]) => void;
}

export const PrivateReposSelect = ({
  value,
  onChange,
}: PrivateReposSelectProps) => {
  const basePath = useBasePath();
  const location = useLocation();
  const { data: searchableRepos, isLoading, error } = useSearchableRepos();

  const { data: scmConfigs, isFetched: scmConfigsFetched } = useScmConfigList();
  const githubScm = scmConfigs?.find(
    (config) => config.type === ScmType.GITHUB
  );

  if (searchableRepos?.needsAppInstall) {
    return (
      <Alert
        m="md"
        variant="light"
        color="blue"
        title="Private GitHub app required"
        icon={<FontAwesomeIcon icon={faInfoCircle} />}
      >
        To grant code access, you must install a private instance of the Semgrep
        app on your GitHub organization.
        <br />
        {scmConfigsFetched &&
          (githubScm ? (
            <Button
              mt="sm"
              size="xs"
              component={Link}
              to={`${basePath}/setup/scms/${githubScm.id}/create-app?prev=${location.pathname}`}
              leftSection={<FontAwesomeIcon icon={faGithub} />}
            >
              Register app
            </Button>
          ) : (
            <Button
              mt="sm"
              size="xs"
              component={Link}
              to={`${basePath}/settings/source-code`}
              leftSection={<FontAwesomeIcon icon={faGithub} />}
            >
              Connect to GitHub
            </Button>
          ))}
      </Alert>
    );
  }
  if (error) {
    if (error.statusCode === 402) {
      return (
        <Alert
          m="md"
          variant="light"
          color="red"
          title="This feature requires an active SAST subscription."
          icon={<FontAwesomeIcon icon={faWarning} />}
        >
          Please contact{" "}
          <a href="mailto:sales@semgrep.com">sales@semgrep.com</a>.
        </Alert>
      );
    } else {
      return (
        <Alert
          m="md"
          variant="light"
          color="red"
          title="There was an error loading your repositories"
          icon={<FontAwesomeIcon icon={faWarning} />}
        >
          {error.message}
        </Alert>
      );
    }
  }

  return (
    <ProjectsSelect
      options={searchableRepos?.repositories ?? []}
      value={value}
      onChange={onChange}
      isLoadingOptions={isLoading}
    />
  );
};
