// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/external/v1/github_repository.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "protos.external.v1";

/** Generated from https://docs.github.com/en/rest/repos/repos?apiVersion=2022-11-28#list-organization-repositories */

export interface GithubRepository {
  owner: GithubRepository_Owner | undefined;
  permissions: GithubRepository_Permissions | undefined;
  codeOfConduct: GithubRepository_CodeOfConduct | undefined;
  id: number;
  nodeId: string;
  name: string;
  fullName: string;
  private: boolean;
  htmlUrl: string;
  description: string;
  fork: boolean;
  url: string;
  archiveUrl: string;
  assigneesUrl: string;
  blobsUrl: string;
  branchesUrl: string;
  collaboratorsUrl: string;
  commentsUrl: string;
  commitsUrl: string;
  compareUrl: string;
  contentsUrl: string;
  contributorsUrl: string;
  deploymentsUrl: string;
  downloadsUrl: string;
  eventsUrl: string;
  forksUrl: string;
  gitCommitsUrl: string;
  gitRefsUrl: string;
  gitTagsUrl: string;
  gitUrl: string;
  issueCommentUrl: string;
  issueEventsUrl: string;
  issuesUrl: string;
  keysUrl: string;
  labelsUrl: string;
  languagesUrl: string;
  mergesUrl: string;
  milestonesUrl: string;
  notificationsUrl: string;
  pullsUrl: string;
  releasesUrl: string;
  sshUrl: string;
  stargazersUrl: string;
  statusesUrl: string;
  subscribersUrl: string;
  subscriptionUrl: string;
  tagsUrl: string;
  teamsUrl: string;
  treesUrl: string;
  cloneUrl: string;
  mirrorUrl: string;
  hooksUrl: string;
  svnUrl: string;
  homepage: string;
  language: string;
  forksCount: number;
  stargazersCount: number;
  watchersCount: number;
  size: number;
  defaultBranch: string;
  openIssuesCount: number;
  isTemplate: boolean;
  topics: string[];
  hasIssues: boolean;
  hasProjects: boolean;
  hasWiki: boolean;
  hasPages: boolean;
  hasDownloads: boolean;
  hasDiscussions: boolean;
  archived: boolean;
  disabled: boolean;
  visibility: string;
  pushedAt: string;
  createdAt: string;
  updatedAt: string;
  roleName: string;
  tempCloneToken: string;
  deleteBranchOnMerge: boolean;
  subscribersCount: number;
  networkCount: number;
  license: GithubRepository_License | undefined;
  forks: number;
  openIssues: number;
  watchers: number;
  allowForking: boolean;
  webCommitSignoffRequired: boolean;
  securityAndAnalysis: GithubRepository_SecurityAndAnalysis | undefined;
}

export interface GithubRepository_Owner {
  name: string;
  email: string;
  login: string;
  id: number;
  nodeId: string;
  avatarUrl: string;
  gravatarId: string;
  url: string;
  htmlUrl: string;
  followersUrl: string;
  followingUrl: string;
  gistsUrl: string;
  starredUrl: string;
  subscriptionsUrl: string;
  organizationsUrl: string;
  reposUrl: string;
  eventsUrl: string;
  receivedEventsUrl: string;
  type: string;
  siteAdmin: boolean;
  starredAt: string;
}

export interface GithubRepository_Permissions {
  admin: boolean;
  maintain: boolean;
  push: boolean;
  triage: boolean;
  pull: boolean;
}

export interface GithubRepository_CodeOfConduct {
  key: string;
  name: string;
  url: string;
  body: string;
  htmlUrl: string;
}

export interface GithubRepository_License {
  key: string;
  name: string;
  spdxId: string;
  url: string;
  nodeId: string;
}

export interface GithubRepository_Status {
  status: GithubRepository_Status_EnabledOrDisabled;
}

export enum GithubRepository_Status_EnabledOrDisabled {
  unspecified = "unspecified",
  enabled = "enabled",
  disabled = "disabled",
}

export function githubRepository_Status_EnabledOrDisabledFromJSON(
  object: any,
): GithubRepository_Status_EnabledOrDisabled {
  switch (object) {
    case 0:
    case "unspecified":
      return GithubRepository_Status_EnabledOrDisabled.unspecified;
    case 1:
    case "enabled":
      return GithubRepository_Status_EnabledOrDisabled.enabled;
    case 2:
    case "disabled":
      return GithubRepository_Status_EnabledOrDisabled.disabled;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum GithubRepository_Status_EnabledOrDisabled",
      );
  }
}

export function githubRepository_Status_EnabledOrDisabledToJSON(
  object: GithubRepository_Status_EnabledOrDisabled,
): string {
  switch (object) {
    case GithubRepository_Status_EnabledOrDisabled.unspecified:
      return "unspecified";
    case GithubRepository_Status_EnabledOrDisabled.enabled:
      return "enabled";
    case GithubRepository_Status_EnabledOrDisabled.disabled:
      return "disabled";
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum GithubRepository_Status_EnabledOrDisabled",
      );
  }
}

export function githubRepository_Status_EnabledOrDisabledToNumber(
  object: GithubRepository_Status_EnabledOrDisabled,
): number {
  switch (object) {
    case GithubRepository_Status_EnabledOrDisabled.unspecified:
      return 0;
    case GithubRepository_Status_EnabledOrDisabled.enabled:
      return 1;
    case GithubRepository_Status_EnabledOrDisabled.disabled:
      return 2;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum GithubRepository_Status_EnabledOrDisabled",
      );
  }
}

export interface GithubRepository_SecurityAndAnalysis {
  advancedSecurity: GithubRepository_Status | undefined;
  secretScanning: GithubRepository_Status | undefined;
  secretScanningPushProtection: GithubRepository_Status | undefined;
}

function createBaseGithubRepository(): GithubRepository {
  return {
    owner: undefined,
    permissions: undefined,
    codeOfConduct: undefined,
    id: 0,
    nodeId: "",
    name: "",
    fullName: "",
    private: false,
    htmlUrl: "",
    description: "",
    fork: false,
    url: "",
    archiveUrl: "",
    assigneesUrl: "",
    blobsUrl: "",
    branchesUrl: "",
    collaboratorsUrl: "",
    commentsUrl: "",
    commitsUrl: "",
    compareUrl: "",
    contentsUrl: "",
    contributorsUrl: "",
    deploymentsUrl: "",
    downloadsUrl: "",
    eventsUrl: "",
    forksUrl: "",
    gitCommitsUrl: "",
    gitRefsUrl: "",
    gitTagsUrl: "",
    gitUrl: "",
    issueCommentUrl: "",
    issueEventsUrl: "",
    issuesUrl: "",
    keysUrl: "",
    labelsUrl: "",
    languagesUrl: "",
    mergesUrl: "",
    milestonesUrl: "",
    notificationsUrl: "",
    pullsUrl: "",
    releasesUrl: "",
    sshUrl: "",
    stargazersUrl: "",
    statusesUrl: "",
    subscribersUrl: "",
    subscriptionUrl: "",
    tagsUrl: "",
    teamsUrl: "",
    treesUrl: "",
    cloneUrl: "",
    mirrorUrl: "",
    hooksUrl: "",
    svnUrl: "",
    homepage: "",
    language: "",
    forksCount: 0,
    stargazersCount: 0,
    watchersCount: 0,
    size: 0,
    defaultBranch: "",
    openIssuesCount: 0,
    isTemplate: false,
    topics: [],
    hasIssues: false,
    hasProjects: false,
    hasWiki: false,
    hasPages: false,
    hasDownloads: false,
    hasDiscussions: false,
    archived: false,
    disabled: false,
    visibility: "",
    pushedAt: "",
    createdAt: "",
    updatedAt: "",
    roleName: "",
    tempCloneToken: "",
    deleteBranchOnMerge: false,
    subscribersCount: 0,
    networkCount: 0,
    license: undefined,
    forks: 0,
    openIssues: 0,
    watchers: 0,
    allowForking: false,
    webCommitSignoffRequired: false,
    securityAndAnalysis: undefined,
  };
}

export const GithubRepository = {
  encode(message: GithubRepository, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.owner !== undefined) {
      GithubRepository_Owner.encode(message.owner, writer.uint32(682).fork()).ldelim();
    }
    if (message.permissions !== undefined) {
      GithubRepository_Permissions.encode(message.permissions, writer.uint32(690).fork()).ldelim();
    }
    if (message.codeOfConduct !== undefined) {
      GithubRepository_CodeOfConduct.encode(message.codeOfConduct, writer.uint32(698).fork()).ldelim();
    }
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.fullName !== "") {
      writer.uint32(34).string(message.fullName);
    }
    if (message.private !== false) {
      writer.uint32(40).bool(message.private);
    }
    if (message.htmlUrl !== "") {
      writer.uint32(50).string(message.htmlUrl);
    }
    if (message.description !== "") {
      writer.uint32(58).string(message.description);
    }
    if (message.fork !== false) {
      writer.uint32(64).bool(message.fork);
    }
    if (message.url !== "") {
      writer.uint32(74).string(message.url);
    }
    if (message.archiveUrl !== "") {
      writer.uint32(82).string(message.archiveUrl);
    }
    if (message.assigneesUrl !== "") {
      writer.uint32(90).string(message.assigneesUrl);
    }
    if (message.blobsUrl !== "") {
      writer.uint32(98).string(message.blobsUrl);
    }
    if (message.branchesUrl !== "") {
      writer.uint32(106).string(message.branchesUrl);
    }
    if (message.collaboratorsUrl !== "") {
      writer.uint32(114).string(message.collaboratorsUrl);
    }
    if (message.commentsUrl !== "") {
      writer.uint32(122).string(message.commentsUrl);
    }
    if (message.commitsUrl !== "") {
      writer.uint32(130).string(message.commitsUrl);
    }
    if (message.compareUrl !== "") {
      writer.uint32(138).string(message.compareUrl);
    }
    if (message.contentsUrl !== "") {
      writer.uint32(146).string(message.contentsUrl);
    }
    if (message.contributorsUrl !== "") {
      writer.uint32(154).string(message.contributorsUrl);
    }
    if (message.deploymentsUrl !== "") {
      writer.uint32(162).string(message.deploymentsUrl);
    }
    if (message.downloadsUrl !== "") {
      writer.uint32(170).string(message.downloadsUrl);
    }
    if (message.eventsUrl !== "") {
      writer.uint32(178).string(message.eventsUrl);
    }
    if (message.forksUrl !== "") {
      writer.uint32(186).string(message.forksUrl);
    }
    if (message.gitCommitsUrl !== "") {
      writer.uint32(194).string(message.gitCommitsUrl);
    }
    if (message.gitRefsUrl !== "") {
      writer.uint32(202).string(message.gitRefsUrl);
    }
    if (message.gitTagsUrl !== "") {
      writer.uint32(210).string(message.gitTagsUrl);
    }
    if (message.gitUrl !== "") {
      writer.uint32(218).string(message.gitUrl);
    }
    if (message.issueCommentUrl !== "") {
      writer.uint32(226).string(message.issueCommentUrl);
    }
    if (message.issueEventsUrl !== "") {
      writer.uint32(234).string(message.issueEventsUrl);
    }
    if (message.issuesUrl !== "") {
      writer.uint32(242).string(message.issuesUrl);
    }
    if (message.keysUrl !== "") {
      writer.uint32(250).string(message.keysUrl);
    }
    if (message.labelsUrl !== "") {
      writer.uint32(258).string(message.labelsUrl);
    }
    if (message.languagesUrl !== "") {
      writer.uint32(266).string(message.languagesUrl);
    }
    if (message.mergesUrl !== "") {
      writer.uint32(274).string(message.mergesUrl);
    }
    if (message.milestonesUrl !== "") {
      writer.uint32(282).string(message.milestonesUrl);
    }
    if (message.notificationsUrl !== "") {
      writer.uint32(290).string(message.notificationsUrl);
    }
    if (message.pullsUrl !== "") {
      writer.uint32(298).string(message.pullsUrl);
    }
    if (message.releasesUrl !== "") {
      writer.uint32(306).string(message.releasesUrl);
    }
    if (message.sshUrl !== "") {
      writer.uint32(314).string(message.sshUrl);
    }
    if (message.stargazersUrl !== "") {
      writer.uint32(322).string(message.stargazersUrl);
    }
    if (message.statusesUrl !== "") {
      writer.uint32(330).string(message.statusesUrl);
    }
    if (message.subscribersUrl !== "") {
      writer.uint32(338).string(message.subscribersUrl);
    }
    if (message.subscriptionUrl !== "") {
      writer.uint32(346).string(message.subscriptionUrl);
    }
    if (message.tagsUrl !== "") {
      writer.uint32(354).string(message.tagsUrl);
    }
    if (message.teamsUrl !== "") {
      writer.uint32(362).string(message.teamsUrl);
    }
    if (message.treesUrl !== "") {
      writer.uint32(370).string(message.treesUrl);
    }
    if (message.cloneUrl !== "") {
      writer.uint32(378).string(message.cloneUrl);
    }
    if (message.mirrorUrl !== "") {
      writer.uint32(386).string(message.mirrorUrl);
    }
    if (message.hooksUrl !== "") {
      writer.uint32(394).string(message.hooksUrl);
    }
    if (message.svnUrl !== "") {
      writer.uint32(402).string(message.svnUrl);
    }
    if (message.homepage !== "") {
      writer.uint32(410).string(message.homepage);
    }
    if (message.language !== "") {
      writer.uint32(418).string(message.language);
    }
    if (message.forksCount !== 0) {
      writer.uint32(424).int32(message.forksCount);
    }
    if (message.stargazersCount !== 0) {
      writer.uint32(432).int32(message.stargazersCount);
    }
    if (message.watchersCount !== 0) {
      writer.uint32(440).int32(message.watchersCount);
    }
    if (message.size !== 0) {
      writer.uint32(448).int32(message.size);
    }
    if (message.defaultBranch !== "") {
      writer.uint32(458).string(message.defaultBranch);
    }
    if (message.openIssuesCount !== 0) {
      writer.uint32(464).int32(message.openIssuesCount);
    }
    if (message.isTemplate !== false) {
      writer.uint32(472).bool(message.isTemplate);
    }
    for (const v of message.topics) {
      writer.uint32(482).string(v!);
    }
    if (message.hasIssues !== false) {
      writer.uint32(488).bool(message.hasIssues);
    }
    if (message.hasProjects !== false) {
      writer.uint32(496).bool(message.hasProjects);
    }
    if (message.hasWiki !== false) {
      writer.uint32(504).bool(message.hasWiki);
    }
    if (message.hasPages !== false) {
      writer.uint32(512).bool(message.hasPages);
    }
    if (message.hasDownloads !== false) {
      writer.uint32(520).bool(message.hasDownloads);
    }
    if (message.hasDiscussions !== false) {
      writer.uint32(528).bool(message.hasDiscussions);
    }
    if (message.archived !== false) {
      writer.uint32(536).bool(message.archived);
    }
    if (message.disabled !== false) {
      writer.uint32(544).bool(message.disabled);
    }
    if (message.visibility !== "") {
      writer.uint32(554).string(message.visibility);
    }
    if (message.pushedAt !== "") {
      writer.uint32(562).string(message.pushedAt);
    }
    if (message.createdAt !== "") {
      writer.uint32(570).string(message.createdAt);
    }
    if (message.updatedAt !== "") {
      writer.uint32(578).string(message.updatedAt);
    }
    if (message.roleName !== "") {
      writer.uint32(586).string(message.roleName);
    }
    if (message.tempCloneToken !== "") {
      writer.uint32(594).string(message.tempCloneToken);
    }
    if (message.deleteBranchOnMerge !== false) {
      writer.uint32(600).bool(message.deleteBranchOnMerge);
    }
    if (message.subscribersCount !== 0) {
      writer.uint32(608).int32(message.subscribersCount);
    }
    if (message.networkCount !== 0) {
      writer.uint32(616).int32(message.networkCount);
    }
    if (message.license !== undefined) {
      GithubRepository_License.encode(message.license, writer.uint32(626).fork()).ldelim();
    }
    if (message.forks !== 0) {
      writer.uint32(632).int32(message.forks);
    }
    if (message.openIssues !== 0) {
      writer.uint32(640).int32(message.openIssues);
    }
    if (message.watchers !== 0) {
      writer.uint32(648).int32(message.watchers);
    }
    if (message.allowForking !== false) {
      writer.uint32(656).bool(message.allowForking);
    }
    if (message.webCommitSignoffRequired !== false) {
      writer.uint32(664).bool(message.webCommitSignoffRequired);
    }
    if (message.securityAndAnalysis !== undefined) {
      GithubRepository_SecurityAndAnalysis.encode(message.securityAndAnalysis, writer.uint32(674).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GithubRepository {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGithubRepository();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 85:
          if (tag !== 682) {
            break;
          }

          message.owner = GithubRepository_Owner.decode(reader, reader.uint32());
          continue;
        case 86:
          if (tag !== 690) {
            break;
          }

          message.permissions = GithubRepository_Permissions.decode(reader, reader.uint32());
          continue;
        case 87:
          if (tag !== 698) {
            break;
          }

          message.codeOfConduct = GithubRepository_CodeOfConduct.decode(reader, reader.uint32());
          continue;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fullName = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.private = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.htmlUrl = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.description = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.fork = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.url = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.archiveUrl = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.assigneesUrl = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.blobsUrl = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.branchesUrl = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.collaboratorsUrl = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.commentsUrl = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.commitsUrl = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.compareUrl = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.contentsUrl = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.contributorsUrl = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.deploymentsUrl = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.downloadsUrl = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.eventsUrl = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.forksUrl = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.gitCommitsUrl = reader.string();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.gitRefsUrl = reader.string();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.gitTagsUrl = reader.string();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.gitUrl = reader.string();
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.issueCommentUrl = reader.string();
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.issueEventsUrl = reader.string();
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.issuesUrl = reader.string();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.keysUrl = reader.string();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.labelsUrl = reader.string();
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.languagesUrl = reader.string();
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.mergesUrl = reader.string();
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.milestonesUrl = reader.string();
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.notificationsUrl = reader.string();
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.pullsUrl = reader.string();
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.releasesUrl = reader.string();
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.sshUrl = reader.string();
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.stargazersUrl = reader.string();
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.statusesUrl = reader.string();
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.subscribersUrl = reader.string();
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.subscriptionUrl = reader.string();
          continue;
        case 44:
          if (tag !== 354) {
            break;
          }

          message.tagsUrl = reader.string();
          continue;
        case 45:
          if (tag !== 362) {
            break;
          }

          message.teamsUrl = reader.string();
          continue;
        case 46:
          if (tag !== 370) {
            break;
          }

          message.treesUrl = reader.string();
          continue;
        case 47:
          if (tag !== 378) {
            break;
          }

          message.cloneUrl = reader.string();
          continue;
        case 48:
          if (tag !== 386) {
            break;
          }

          message.mirrorUrl = reader.string();
          continue;
        case 49:
          if (tag !== 394) {
            break;
          }

          message.hooksUrl = reader.string();
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.svnUrl = reader.string();
          continue;
        case 51:
          if (tag !== 410) {
            break;
          }

          message.homepage = reader.string();
          continue;
        case 52:
          if (tag !== 418) {
            break;
          }

          message.language = reader.string();
          continue;
        case 53:
          if (tag !== 424) {
            break;
          }

          message.forksCount = reader.int32();
          continue;
        case 54:
          if (tag !== 432) {
            break;
          }

          message.stargazersCount = reader.int32();
          continue;
        case 55:
          if (tag !== 440) {
            break;
          }

          message.watchersCount = reader.int32();
          continue;
        case 56:
          if (tag !== 448) {
            break;
          }

          message.size = reader.int32();
          continue;
        case 57:
          if (tag !== 458) {
            break;
          }

          message.defaultBranch = reader.string();
          continue;
        case 58:
          if (tag !== 464) {
            break;
          }

          message.openIssuesCount = reader.int32();
          continue;
        case 59:
          if (tag !== 472) {
            break;
          }

          message.isTemplate = reader.bool();
          continue;
        case 60:
          if (tag !== 482) {
            break;
          }

          message.topics.push(reader.string());
          continue;
        case 61:
          if (tag !== 488) {
            break;
          }

          message.hasIssues = reader.bool();
          continue;
        case 62:
          if (tag !== 496) {
            break;
          }

          message.hasProjects = reader.bool();
          continue;
        case 63:
          if (tag !== 504) {
            break;
          }

          message.hasWiki = reader.bool();
          continue;
        case 64:
          if (tag !== 512) {
            break;
          }

          message.hasPages = reader.bool();
          continue;
        case 65:
          if (tag !== 520) {
            break;
          }

          message.hasDownloads = reader.bool();
          continue;
        case 66:
          if (tag !== 528) {
            break;
          }

          message.hasDiscussions = reader.bool();
          continue;
        case 67:
          if (tag !== 536) {
            break;
          }

          message.archived = reader.bool();
          continue;
        case 68:
          if (tag !== 544) {
            break;
          }

          message.disabled = reader.bool();
          continue;
        case 69:
          if (tag !== 554) {
            break;
          }

          message.visibility = reader.string();
          continue;
        case 70:
          if (tag !== 562) {
            break;
          }

          message.pushedAt = reader.string();
          continue;
        case 71:
          if (tag !== 570) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 72:
          if (tag !== 578) {
            break;
          }

          message.updatedAt = reader.string();
          continue;
        case 73:
          if (tag !== 586) {
            break;
          }

          message.roleName = reader.string();
          continue;
        case 74:
          if (tag !== 594) {
            break;
          }

          message.tempCloneToken = reader.string();
          continue;
        case 75:
          if (tag !== 600) {
            break;
          }

          message.deleteBranchOnMerge = reader.bool();
          continue;
        case 76:
          if (tag !== 608) {
            break;
          }

          message.subscribersCount = reader.int32();
          continue;
        case 77:
          if (tag !== 616) {
            break;
          }

          message.networkCount = reader.int32();
          continue;
        case 78:
          if (tag !== 626) {
            break;
          }

          message.license = GithubRepository_License.decode(reader, reader.uint32());
          continue;
        case 79:
          if (tag !== 632) {
            break;
          }

          message.forks = reader.int32();
          continue;
        case 80:
          if (tag !== 640) {
            break;
          }

          message.openIssues = reader.int32();
          continue;
        case 81:
          if (tag !== 648) {
            break;
          }

          message.watchers = reader.int32();
          continue;
        case 82:
          if (tag !== 656) {
            break;
          }

          message.allowForking = reader.bool();
          continue;
        case 83:
          if (tag !== 664) {
            break;
          }

          message.webCommitSignoffRequired = reader.bool();
          continue;
        case 84:
          if (tag !== 674) {
            break;
          }

          message.securityAndAnalysis = GithubRepository_SecurityAndAnalysis.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GithubRepository {
    return {
      owner: isSet(object.owner) ? GithubRepository_Owner.fromJSON(object.owner) : undefined,
      permissions: isSet(object.permissions) ? GithubRepository_Permissions.fromJSON(object.permissions) : undefined,
      codeOfConduct: isSet(object.codeOfConduct)
        ? GithubRepository_CodeOfConduct.fromJSON(object.codeOfConduct)
        : undefined,
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      fullName: isSet(object.fullName) ? globalThis.String(object.fullName) : "",
      private: isSet(object.private) ? globalThis.Boolean(object.private) : false,
      htmlUrl: isSet(object.htmlUrl) ? globalThis.String(object.htmlUrl) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      fork: isSet(object.fork) ? globalThis.Boolean(object.fork) : false,
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      archiveUrl: isSet(object.archiveUrl) ? globalThis.String(object.archiveUrl) : "",
      assigneesUrl: isSet(object.assigneesUrl) ? globalThis.String(object.assigneesUrl) : "",
      blobsUrl: isSet(object.blobsUrl) ? globalThis.String(object.blobsUrl) : "",
      branchesUrl: isSet(object.branchesUrl) ? globalThis.String(object.branchesUrl) : "",
      collaboratorsUrl: isSet(object.collaboratorsUrl) ? globalThis.String(object.collaboratorsUrl) : "",
      commentsUrl: isSet(object.commentsUrl) ? globalThis.String(object.commentsUrl) : "",
      commitsUrl: isSet(object.commitsUrl) ? globalThis.String(object.commitsUrl) : "",
      compareUrl: isSet(object.compareUrl) ? globalThis.String(object.compareUrl) : "",
      contentsUrl: isSet(object.contentsUrl) ? globalThis.String(object.contentsUrl) : "",
      contributorsUrl: isSet(object.contributorsUrl) ? globalThis.String(object.contributorsUrl) : "",
      deploymentsUrl: isSet(object.deploymentsUrl) ? globalThis.String(object.deploymentsUrl) : "",
      downloadsUrl: isSet(object.downloadsUrl) ? globalThis.String(object.downloadsUrl) : "",
      eventsUrl: isSet(object.eventsUrl) ? globalThis.String(object.eventsUrl) : "",
      forksUrl: isSet(object.forksUrl) ? globalThis.String(object.forksUrl) : "",
      gitCommitsUrl: isSet(object.gitCommitsUrl) ? globalThis.String(object.gitCommitsUrl) : "",
      gitRefsUrl: isSet(object.gitRefsUrl) ? globalThis.String(object.gitRefsUrl) : "",
      gitTagsUrl: isSet(object.gitTagsUrl) ? globalThis.String(object.gitTagsUrl) : "",
      gitUrl: isSet(object.gitUrl) ? globalThis.String(object.gitUrl) : "",
      issueCommentUrl: isSet(object.issueCommentUrl) ? globalThis.String(object.issueCommentUrl) : "",
      issueEventsUrl: isSet(object.issueEventsUrl) ? globalThis.String(object.issueEventsUrl) : "",
      issuesUrl: isSet(object.issuesUrl) ? globalThis.String(object.issuesUrl) : "",
      keysUrl: isSet(object.keysUrl) ? globalThis.String(object.keysUrl) : "",
      labelsUrl: isSet(object.labelsUrl) ? globalThis.String(object.labelsUrl) : "",
      languagesUrl: isSet(object.languagesUrl) ? globalThis.String(object.languagesUrl) : "",
      mergesUrl: isSet(object.mergesUrl) ? globalThis.String(object.mergesUrl) : "",
      milestonesUrl: isSet(object.milestonesUrl) ? globalThis.String(object.milestonesUrl) : "",
      notificationsUrl: isSet(object.notificationsUrl) ? globalThis.String(object.notificationsUrl) : "",
      pullsUrl: isSet(object.pullsUrl) ? globalThis.String(object.pullsUrl) : "",
      releasesUrl: isSet(object.releasesUrl) ? globalThis.String(object.releasesUrl) : "",
      sshUrl: isSet(object.sshUrl) ? globalThis.String(object.sshUrl) : "",
      stargazersUrl: isSet(object.stargazersUrl) ? globalThis.String(object.stargazersUrl) : "",
      statusesUrl: isSet(object.statusesUrl) ? globalThis.String(object.statusesUrl) : "",
      subscribersUrl: isSet(object.subscribersUrl) ? globalThis.String(object.subscribersUrl) : "",
      subscriptionUrl: isSet(object.subscriptionUrl) ? globalThis.String(object.subscriptionUrl) : "",
      tagsUrl: isSet(object.tagsUrl) ? globalThis.String(object.tagsUrl) : "",
      teamsUrl: isSet(object.teamsUrl) ? globalThis.String(object.teamsUrl) : "",
      treesUrl: isSet(object.treesUrl) ? globalThis.String(object.treesUrl) : "",
      cloneUrl: isSet(object.cloneUrl) ? globalThis.String(object.cloneUrl) : "",
      mirrorUrl: isSet(object.mirrorUrl) ? globalThis.String(object.mirrorUrl) : "",
      hooksUrl: isSet(object.hooksUrl) ? globalThis.String(object.hooksUrl) : "",
      svnUrl: isSet(object.svnUrl) ? globalThis.String(object.svnUrl) : "",
      homepage: isSet(object.homepage) ? globalThis.String(object.homepage) : "",
      language: isSet(object.language) ? globalThis.String(object.language) : "",
      forksCount: isSet(object.forksCount) ? globalThis.Number(object.forksCount) : 0,
      stargazersCount: isSet(object.stargazersCount) ? globalThis.Number(object.stargazersCount) : 0,
      watchersCount: isSet(object.watchersCount) ? globalThis.Number(object.watchersCount) : 0,
      size: isSet(object.size) ? globalThis.Number(object.size) : 0,
      defaultBranch: isSet(object.defaultBranch) ? globalThis.String(object.defaultBranch) : "",
      openIssuesCount: isSet(object.openIssuesCount) ? globalThis.Number(object.openIssuesCount) : 0,
      isTemplate: isSet(object.isTemplate) ? globalThis.Boolean(object.isTemplate) : false,
      topics: globalThis.Array.isArray(object?.topics) ? object.topics.map((e: any) => globalThis.String(e)) : [],
      hasIssues: isSet(object.hasIssues) ? globalThis.Boolean(object.hasIssues) : false,
      hasProjects: isSet(object.hasProjects) ? globalThis.Boolean(object.hasProjects) : false,
      hasWiki: isSet(object.hasWiki) ? globalThis.Boolean(object.hasWiki) : false,
      hasPages: isSet(object.hasPages) ? globalThis.Boolean(object.hasPages) : false,
      hasDownloads: isSet(object.hasDownloads) ? globalThis.Boolean(object.hasDownloads) : false,
      hasDiscussions: isSet(object.hasDiscussions) ? globalThis.Boolean(object.hasDiscussions) : false,
      archived: isSet(object.archived) ? globalThis.Boolean(object.archived) : false,
      disabled: isSet(object.disabled) ? globalThis.Boolean(object.disabled) : false,
      visibility: isSet(object.visibility) ? globalThis.String(object.visibility) : "",
      pushedAt: isSet(object.pushedAt) ? globalThis.String(object.pushedAt) : "",
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
      updatedAt: isSet(object.updatedAt) ? globalThis.String(object.updatedAt) : "",
      roleName: isSet(object.roleName) ? globalThis.String(object.roleName) : "",
      tempCloneToken: isSet(object.tempCloneToken) ? globalThis.String(object.tempCloneToken) : "",
      deleteBranchOnMerge: isSet(object.deleteBranchOnMerge) ? globalThis.Boolean(object.deleteBranchOnMerge) : false,
      subscribersCount: isSet(object.subscribersCount) ? globalThis.Number(object.subscribersCount) : 0,
      networkCount: isSet(object.networkCount) ? globalThis.Number(object.networkCount) : 0,
      license: isSet(object.license) ? GithubRepository_License.fromJSON(object.license) : undefined,
      forks: isSet(object.forks) ? globalThis.Number(object.forks) : 0,
      openIssues: isSet(object.openIssues) ? globalThis.Number(object.openIssues) : 0,
      watchers: isSet(object.watchers) ? globalThis.Number(object.watchers) : 0,
      allowForking: isSet(object.allowForking) ? globalThis.Boolean(object.allowForking) : false,
      webCommitSignoffRequired: isSet(object.webCommitSignoffRequired)
        ? globalThis.Boolean(object.webCommitSignoffRequired)
        : false,
      securityAndAnalysis: isSet(object.securityAndAnalysis)
        ? GithubRepository_SecurityAndAnalysis.fromJSON(object.securityAndAnalysis)
        : undefined,
    };
  },

  toJSON(message: GithubRepository): unknown {
    const obj: any = {};
    if (message.owner !== undefined) {
      obj.owner = GithubRepository_Owner.toJSON(message.owner);
    }
    if (message.permissions !== undefined) {
      obj.permissions = GithubRepository_Permissions.toJSON(message.permissions);
    }
    if (message.codeOfConduct !== undefined) {
      obj.codeOfConduct = GithubRepository_CodeOfConduct.toJSON(message.codeOfConduct);
    }
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.fullName !== "") {
      obj.fullName = message.fullName;
    }
    if (message.private !== false) {
      obj.private = message.private;
    }
    if (message.htmlUrl !== "") {
      obj.htmlUrl = message.htmlUrl;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.fork !== false) {
      obj.fork = message.fork;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.archiveUrl !== "") {
      obj.archiveUrl = message.archiveUrl;
    }
    if (message.assigneesUrl !== "") {
      obj.assigneesUrl = message.assigneesUrl;
    }
    if (message.blobsUrl !== "") {
      obj.blobsUrl = message.blobsUrl;
    }
    if (message.branchesUrl !== "") {
      obj.branchesUrl = message.branchesUrl;
    }
    if (message.collaboratorsUrl !== "") {
      obj.collaboratorsUrl = message.collaboratorsUrl;
    }
    if (message.commentsUrl !== "") {
      obj.commentsUrl = message.commentsUrl;
    }
    if (message.commitsUrl !== "") {
      obj.commitsUrl = message.commitsUrl;
    }
    if (message.compareUrl !== "") {
      obj.compareUrl = message.compareUrl;
    }
    if (message.contentsUrl !== "") {
      obj.contentsUrl = message.contentsUrl;
    }
    if (message.contributorsUrl !== "") {
      obj.contributorsUrl = message.contributorsUrl;
    }
    if (message.deploymentsUrl !== "") {
      obj.deploymentsUrl = message.deploymentsUrl;
    }
    if (message.downloadsUrl !== "") {
      obj.downloadsUrl = message.downloadsUrl;
    }
    if (message.eventsUrl !== "") {
      obj.eventsUrl = message.eventsUrl;
    }
    if (message.forksUrl !== "") {
      obj.forksUrl = message.forksUrl;
    }
    if (message.gitCommitsUrl !== "") {
      obj.gitCommitsUrl = message.gitCommitsUrl;
    }
    if (message.gitRefsUrl !== "") {
      obj.gitRefsUrl = message.gitRefsUrl;
    }
    if (message.gitTagsUrl !== "") {
      obj.gitTagsUrl = message.gitTagsUrl;
    }
    if (message.gitUrl !== "") {
      obj.gitUrl = message.gitUrl;
    }
    if (message.issueCommentUrl !== "") {
      obj.issueCommentUrl = message.issueCommentUrl;
    }
    if (message.issueEventsUrl !== "") {
      obj.issueEventsUrl = message.issueEventsUrl;
    }
    if (message.issuesUrl !== "") {
      obj.issuesUrl = message.issuesUrl;
    }
    if (message.keysUrl !== "") {
      obj.keysUrl = message.keysUrl;
    }
    if (message.labelsUrl !== "") {
      obj.labelsUrl = message.labelsUrl;
    }
    if (message.languagesUrl !== "") {
      obj.languagesUrl = message.languagesUrl;
    }
    if (message.mergesUrl !== "") {
      obj.mergesUrl = message.mergesUrl;
    }
    if (message.milestonesUrl !== "") {
      obj.milestonesUrl = message.milestonesUrl;
    }
    if (message.notificationsUrl !== "") {
      obj.notificationsUrl = message.notificationsUrl;
    }
    if (message.pullsUrl !== "") {
      obj.pullsUrl = message.pullsUrl;
    }
    if (message.releasesUrl !== "") {
      obj.releasesUrl = message.releasesUrl;
    }
    if (message.sshUrl !== "") {
      obj.sshUrl = message.sshUrl;
    }
    if (message.stargazersUrl !== "") {
      obj.stargazersUrl = message.stargazersUrl;
    }
    if (message.statusesUrl !== "") {
      obj.statusesUrl = message.statusesUrl;
    }
    if (message.subscribersUrl !== "") {
      obj.subscribersUrl = message.subscribersUrl;
    }
    if (message.subscriptionUrl !== "") {
      obj.subscriptionUrl = message.subscriptionUrl;
    }
    if (message.tagsUrl !== "") {
      obj.tagsUrl = message.tagsUrl;
    }
    if (message.teamsUrl !== "") {
      obj.teamsUrl = message.teamsUrl;
    }
    if (message.treesUrl !== "") {
      obj.treesUrl = message.treesUrl;
    }
    if (message.cloneUrl !== "") {
      obj.cloneUrl = message.cloneUrl;
    }
    if (message.mirrorUrl !== "") {
      obj.mirrorUrl = message.mirrorUrl;
    }
    if (message.hooksUrl !== "") {
      obj.hooksUrl = message.hooksUrl;
    }
    if (message.svnUrl !== "") {
      obj.svnUrl = message.svnUrl;
    }
    if (message.homepage !== "") {
      obj.homepage = message.homepage;
    }
    if (message.language !== "") {
      obj.language = message.language;
    }
    if (message.forksCount !== 0) {
      obj.forksCount = Math.round(message.forksCount);
    }
    if (message.stargazersCount !== 0) {
      obj.stargazersCount = Math.round(message.stargazersCount);
    }
    if (message.watchersCount !== 0) {
      obj.watchersCount = Math.round(message.watchersCount);
    }
    if (message.size !== 0) {
      obj.size = Math.round(message.size);
    }
    if (message.defaultBranch !== "") {
      obj.defaultBranch = message.defaultBranch;
    }
    if (message.openIssuesCount !== 0) {
      obj.openIssuesCount = Math.round(message.openIssuesCount);
    }
    if (message.isTemplate !== false) {
      obj.isTemplate = message.isTemplate;
    }
    if (message.topics?.length) {
      obj.topics = message.topics;
    }
    if (message.hasIssues !== false) {
      obj.hasIssues = message.hasIssues;
    }
    if (message.hasProjects !== false) {
      obj.hasProjects = message.hasProjects;
    }
    if (message.hasWiki !== false) {
      obj.hasWiki = message.hasWiki;
    }
    if (message.hasPages !== false) {
      obj.hasPages = message.hasPages;
    }
    if (message.hasDownloads !== false) {
      obj.hasDownloads = message.hasDownloads;
    }
    if (message.hasDiscussions !== false) {
      obj.hasDiscussions = message.hasDiscussions;
    }
    if (message.archived !== false) {
      obj.archived = message.archived;
    }
    if (message.disabled !== false) {
      obj.disabled = message.disabled;
    }
    if (message.visibility !== "") {
      obj.visibility = message.visibility;
    }
    if (message.pushedAt !== "") {
      obj.pushedAt = message.pushedAt;
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    if (message.updatedAt !== "") {
      obj.updatedAt = message.updatedAt;
    }
    if (message.roleName !== "") {
      obj.roleName = message.roleName;
    }
    if (message.tempCloneToken !== "") {
      obj.tempCloneToken = message.tempCloneToken;
    }
    if (message.deleteBranchOnMerge !== false) {
      obj.deleteBranchOnMerge = message.deleteBranchOnMerge;
    }
    if (message.subscribersCount !== 0) {
      obj.subscribersCount = Math.round(message.subscribersCount);
    }
    if (message.networkCount !== 0) {
      obj.networkCount = Math.round(message.networkCount);
    }
    if (message.license !== undefined) {
      obj.license = GithubRepository_License.toJSON(message.license);
    }
    if (message.forks !== 0) {
      obj.forks = Math.round(message.forks);
    }
    if (message.openIssues !== 0) {
      obj.openIssues = Math.round(message.openIssues);
    }
    if (message.watchers !== 0) {
      obj.watchers = Math.round(message.watchers);
    }
    if (message.allowForking !== false) {
      obj.allowForking = message.allowForking;
    }
    if (message.webCommitSignoffRequired !== false) {
      obj.webCommitSignoffRequired = message.webCommitSignoffRequired;
    }
    if (message.securityAndAnalysis !== undefined) {
      obj.securityAndAnalysis = GithubRepository_SecurityAndAnalysis.toJSON(message.securityAndAnalysis);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GithubRepository>, I>>(base?: I): GithubRepository {
    return GithubRepository.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GithubRepository>, I>>(object: I): GithubRepository {
    const message = createBaseGithubRepository();
    message.owner = (object.owner !== undefined && object.owner !== null)
      ? GithubRepository_Owner.fromPartial(object.owner)
      : undefined;
    message.permissions = (object.permissions !== undefined && object.permissions !== null)
      ? GithubRepository_Permissions.fromPartial(object.permissions)
      : undefined;
    message.codeOfConduct = (object.codeOfConduct !== undefined && object.codeOfConduct !== null)
      ? GithubRepository_CodeOfConduct.fromPartial(object.codeOfConduct)
      : undefined;
    message.id = object.id ?? 0;
    message.nodeId = object.nodeId ?? "";
    message.name = object.name ?? "";
    message.fullName = object.fullName ?? "";
    message.private = object.private ?? false;
    message.htmlUrl = object.htmlUrl ?? "";
    message.description = object.description ?? "";
    message.fork = object.fork ?? false;
    message.url = object.url ?? "";
    message.archiveUrl = object.archiveUrl ?? "";
    message.assigneesUrl = object.assigneesUrl ?? "";
    message.blobsUrl = object.blobsUrl ?? "";
    message.branchesUrl = object.branchesUrl ?? "";
    message.collaboratorsUrl = object.collaboratorsUrl ?? "";
    message.commentsUrl = object.commentsUrl ?? "";
    message.commitsUrl = object.commitsUrl ?? "";
    message.compareUrl = object.compareUrl ?? "";
    message.contentsUrl = object.contentsUrl ?? "";
    message.contributorsUrl = object.contributorsUrl ?? "";
    message.deploymentsUrl = object.deploymentsUrl ?? "";
    message.downloadsUrl = object.downloadsUrl ?? "";
    message.eventsUrl = object.eventsUrl ?? "";
    message.forksUrl = object.forksUrl ?? "";
    message.gitCommitsUrl = object.gitCommitsUrl ?? "";
    message.gitRefsUrl = object.gitRefsUrl ?? "";
    message.gitTagsUrl = object.gitTagsUrl ?? "";
    message.gitUrl = object.gitUrl ?? "";
    message.issueCommentUrl = object.issueCommentUrl ?? "";
    message.issueEventsUrl = object.issueEventsUrl ?? "";
    message.issuesUrl = object.issuesUrl ?? "";
    message.keysUrl = object.keysUrl ?? "";
    message.labelsUrl = object.labelsUrl ?? "";
    message.languagesUrl = object.languagesUrl ?? "";
    message.mergesUrl = object.mergesUrl ?? "";
    message.milestonesUrl = object.milestonesUrl ?? "";
    message.notificationsUrl = object.notificationsUrl ?? "";
    message.pullsUrl = object.pullsUrl ?? "";
    message.releasesUrl = object.releasesUrl ?? "";
    message.sshUrl = object.sshUrl ?? "";
    message.stargazersUrl = object.stargazersUrl ?? "";
    message.statusesUrl = object.statusesUrl ?? "";
    message.subscribersUrl = object.subscribersUrl ?? "";
    message.subscriptionUrl = object.subscriptionUrl ?? "";
    message.tagsUrl = object.tagsUrl ?? "";
    message.teamsUrl = object.teamsUrl ?? "";
    message.treesUrl = object.treesUrl ?? "";
    message.cloneUrl = object.cloneUrl ?? "";
    message.mirrorUrl = object.mirrorUrl ?? "";
    message.hooksUrl = object.hooksUrl ?? "";
    message.svnUrl = object.svnUrl ?? "";
    message.homepage = object.homepage ?? "";
    message.language = object.language ?? "";
    message.forksCount = object.forksCount ?? 0;
    message.stargazersCount = object.stargazersCount ?? 0;
    message.watchersCount = object.watchersCount ?? 0;
    message.size = object.size ?? 0;
    message.defaultBranch = object.defaultBranch ?? "";
    message.openIssuesCount = object.openIssuesCount ?? 0;
    message.isTemplate = object.isTemplate ?? false;
    message.topics = object.topics?.map((e) => e) || [];
    message.hasIssues = object.hasIssues ?? false;
    message.hasProjects = object.hasProjects ?? false;
    message.hasWiki = object.hasWiki ?? false;
    message.hasPages = object.hasPages ?? false;
    message.hasDownloads = object.hasDownloads ?? false;
    message.hasDiscussions = object.hasDiscussions ?? false;
    message.archived = object.archived ?? false;
    message.disabled = object.disabled ?? false;
    message.visibility = object.visibility ?? "";
    message.pushedAt = object.pushedAt ?? "";
    message.createdAt = object.createdAt ?? "";
    message.updatedAt = object.updatedAt ?? "";
    message.roleName = object.roleName ?? "";
    message.tempCloneToken = object.tempCloneToken ?? "";
    message.deleteBranchOnMerge = object.deleteBranchOnMerge ?? false;
    message.subscribersCount = object.subscribersCount ?? 0;
    message.networkCount = object.networkCount ?? 0;
    message.license = (object.license !== undefined && object.license !== null)
      ? GithubRepository_License.fromPartial(object.license)
      : undefined;
    message.forks = object.forks ?? 0;
    message.openIssues = object.openIssues ?? 0;
    message.watchers = object.watchers ?? 0;
    message.allowForking = object.allowForking ?? false;
    message.webCommitSignoffRequired = object.webCommitSignoffRequired ?? false;
    message.securityAndAnalysis = (object.securityAndAnalysis !== undefined && object.securityAndAnalysis !== null)
      ? GithubRepository_SecurityAndAnalysis.fromPartial(object.securityAndAnalysis)
      : undefined;
    return message;
  },
};

function createBaseGithubRepository_Owner(): GithubRepository_Owner {
  return {
    name: "",
    email: "",
    login: "",
    id: 0,
    nodeId: "",
    avatarUrl: "",
    gravatarId: "",
    url: "",
    htmlUrl: "",
    followersUrl: "",
    followingUrl: "",
    gistsUrl: "",
    starredUrl: "",
    subscriptionsUrl: "",
    organizationsUrl: "",
    reposUrl: "",
    eventsUrl: "",
    receivedEventsUrl: "",
    type: "",
    siteAdmin: false,
    starredAt: "",
  };
}

export const GithubRepository_Owner = {
  encode(message: GithubRepository_Owner, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.login !== "") {
      writer.uint32(26).string(message.login);
    }
    if (message.id !== 0) {
      writer.uint32(32).int32(message.id);
    }
    if (message.nodeId !== "") {
      writer.uint32(42).string(message.nodeId);
    }
    if (message.avatarUrl !== "") {
      writer.uint32(50).string(message.avatarUrl);
    }
    if (message.gravatarId !== "") {
      writer.uint32(58).string(message.gravatarId);
    }
    if (message.url !== "") {
      writer.uint32(66).string(message.url);
    }
    if (message.htmlUrl !== "") {
      writer.uint32(74).string(message.htmlUrl);
    }
    if (message.followersUrl !== "") {
      writer.uint32(82).string(message.followersUrl);
    }
    if (message.followingUrl !== "") {
      writer.uint32(90).string(message.followingUrl);
    }
    if (message.gistsUrl !== "") {
      writer.uint32(98).string(message.gistsUrl);
    }
    if (message.starredUrl !== "") {
      writer.uint32(106).string(message.starredUrl);
    }
    if (message.subscriptionsUrl !== "") {
      writer.uint32(114).string(message.subscriptionsUrl);
    }
    if (message.organizationsUrl !== "") {
      writer.uint32(122).string(message.organizationsUrl);
    }
    if (message.reposUrl !== "") {
      writer.uint32(130).string(message.reposUrl);
    }
    if (message.eventsUrl !== "") {
      writer.uint32(138).string(message.eventsUrl);
    }
    if (message.receivedEventsUrl !== "") {
      writer.uint32(146).string(message.receivedEventsUrl);
    }
    if (message.type !== "") {
      writer.uint32(154).string(message.type);
    }
    if (message.siteAdmin !== false) {
      writer.uint32(160).bool(message.siteAdmin);
    }
    if (message.starredAt !== "") {
      writer.uint32(170).string(message.starredAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GithubRepository_Owner {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGithubRepository_Owner();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.login = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.avatarUrl = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.gravatarId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.url = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.htmlUrl = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.followersUrl = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.followingUrl = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.gistsUrl = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.starredUrl = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.subscriptionsUrl = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.organizationsUrl = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.reposUrl = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.eventsUrl = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.receivedEventsUrl = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.type = reader.string();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.siteAdmin = reader.bool();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.starredAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GithubRepository_Owner {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      login: isSet(object.login) ? globalThis.String(object.login) : "",
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      avatarUrl: isSet(object.avatarUrl) ? globalThis.String(object.avatarUrl) : "",
      gravatarId: isSet(object.gravatarId) ? globalThis.String(object.gravatarId) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      htmlUrl: isSet(object.htmlUrl) ? globalThis.String(object.htmlUrl) : "",
      followersUrl: isSet(object.followersUrl) ? globalThis.String(object.followersUrl) : "",
      followingUrl: isSet(object.followingUrl) ? globalThis.String(object.followingUrl) : "",
      gistsUrl: isSet(object.gistsUrl) ? globalThis.String(object.gistsUrl) : "",
      starredUrl: isSet(object.starredUrl) ? globalThis.String(object.starredUrl) : "",
      subscriptionsUrl: isSet(object.subscriptionsUrl) ? globalThis.String(object.subscriptionsUrl) : "",
      organizationsUrl: isSet(object.organizationsUrl) ? globalThis.String(object.organizationsUrl) : "",
      reposUrl: isSet(object.reposUrl) ? globalThis.String(object.reposUrl) : "",
      eventsUrl: isSet(object.eventsUrl) ? globalThis.String(object.eventsUrl) : "",
      receivedEventsUrl: isSet(object.receivedEventsUrl) ? globalThis.String(object.receivedEventsUrl) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      siteAdmin: isSet(object.siteAdmin) ? globalThis.Boolean(object.siteAdmin) : false,
      starredAt: isSet(object.starredAt) ? globalThis.String(object.starredAt) : "",
    };
  },

  toJSON(message: GithubRepository_Owner): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.login !== "") {
      obj.login = message.login;
    }
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.avatarUrl !== "") {
      obj.avatarUrl = message.avatarUrl;
    }
    if (message.gravatarId !== "") {
      obj.gravatarId = message.gravatarId;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.htmlUrl !== "") {
      obj.htmlUrl = message.htmlUrl;
    }
    if (message.followersUrl !== "") {
      obj.followersUrl = message.followersUrl;
    }
    if (message.followingUrl !== "") {
      obj.followingUrl = message.followingUrl;
    }
    if (message.gistsUrl !== "") {
      obj.gistsUrl = message.gistsUrl;
    }
    if (message.starredUrl !== "") {
      obj.starredUrl = message.starredUrl;
    }
    if (message.subscriptionsUrl !== "") {
      obj.subscriptionsUrl = message.subscriptionsUrl;
    }
    if (message.organizationsUrl !== "") {
      obj.organizationsUrl = message.organizationsUrl;
    }
    if (message.reposUrl !== "") {
      obj.reposUrl = message.reposUrl;
    }
    if (message.eventsUrl !== "") {
      obj.eventsUrl = message.eventsUrl;
    }
    if (message.receivedEventsUrl !== "") {
      obj.receivedEventsUrl = message.receivedEventsUrl;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.siteAdmin !== false) {
      obj.siteAdmin = message.siteAdmin;
    }
    if (message.starredAt !== "") {
      obj.starredAt = message.starredAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GithubRepository_Owner>, I>>(base?: I): GithubRepository_Owner {
    return GithubRepository_Owner.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GithubRepository_Owner>, I>>(object: I): GithubRepository_Owner {
    const message = createBaseGithubRepository_Owner();
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.login = object.login ?? "";
    message.id = object.id ?? 0;
    message.nodeId = object.nodeId ?? "";
    message.avatarUrl = object.avatarUrl ?? "";
    message.gravatarId = object.gravatarId ?? "";
    message.url = object.url ?? "";
    message.htmlUrl = object.htmlUrl ?? "";
    message.followersUrl = object.followersUrl ?? "";
    message.followingUrl = object.followingUrl ?? "";
    message.gistsUrl = object.gistsUrl ?? "";
    message.starredUrl = object.starredUrl ?? "";
    message.subscriptionsUrl = object.subscriptionsUrl ?? "";
    message.organizationsUrl = object.organizationsUrl ?? "";
    message.reposUrl = object.reposUrl ?? "";
    message.eventsUrl = object.eventsUrl ?? "";
    message.receivedEventsUrl = object.receivedEventsUrl ?? "";
    message.type = object.type ?? "";
    message.siteAdmin = object.siteAdmin ?? false;
    message.starredAt = object.starredAt ?? "";
    return message;
  },
};

function createBaseGithubRepository_Permissions(): GithubRepository_Permissions {
  return { admin: false, maintain: false, push: false, triage: false, pull: false };
}

export const GithubRepository_Permissions = {
  encode(message: GithubRepository_Permissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.admin !== false) {
      writer.uint32(8).bool(message.admin);
    }
    if (message.maintain !== false) {
      writer.uint32(16).bool(message.maintain);
    }
    if (message.push !== false) {
      writer.uint32(24).bool(message.push);
    }
    if (message.triage !== false) {
      writer.uint32(32).bool(message.triage);
    }
    if (message.pull !== false) {
      writer.uint32(40).bool(message.pull);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GithubRepository_Permissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGithubRepository_Permissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.admin = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.maintain = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.push = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.triage = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pull = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GithubRepository_Permissions {
    return {
      admin: isSet(object.admin) ? globalThis.Boolean(object.admin) : false,
      maintain: isSet(object.maintain) ? globalThis.Boolean(object.maintain) : false,
      push: isSet(object.push) ? globalThis.Boolean(object.push) : false,
      triage: isSet(object.triage) ? globalThis.Boolean(object.triage) : false,
      pull: isSet(object.pull) ? globalThis.Boolean(object.pull) : false,
    };
  },

  toJSON(message: GithubRepository_Permissions): unknown {
    const obj: any = {};
    if (message.admin !== false) {
      obj.admin = message.admin;
    }
    if (message.maintain !== false) {
      obj.maintain = message.maintain;
    }
    if (message.push !== false) {
      obj.push = message.push;
    }
    if (message.triage !== false) {
      obj.triage = message.triage;
    }
    if (message.pull !== false) {
      obj.pull = message.pull;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GithubRepository_Permissions>, I>>(base?: I): GithubRepository_Permissions {
    return GithubRepository_Permissions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GithubRepository_Permissions>, I>>(object: I): GithubRepository_Permissions {
    const message = createBaseGithubRepository_Permissions();
    message.admin = object.admin ?? false;
    message.maintain = object.maintain ?? false;
    message.push = object.push ?? false;
    message.triage = object.triage ?? false;
    message.pull = object.pull ?? false;
    return message;
  },
};

function createBaseGithubRepository_CodeOfConduct(): GithubRepository_CodeOfConduct {
  return { key: "", name: "", url: "", body: "", htmlUrl: "" };
}

export const GithubRepository_CodeOfConduct = {
  encode(message: GithubRepository_CodeOfConduct, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.url !== "") {
      writer.uint32(26).string(message.url);
    }
    if (message.body !== "") {
      writer.uint32(34).string(message.body);
    }
    if (message.htmlUrl !== "") {
      writer.uint32(42).string(message.htmlUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GithubRepository_CodeOfConduct {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGithubRepository_CodeOfConduct();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.url = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.body = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.htmlUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GithubRepository_CodeOfConduct {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      body: isSet(object.body) ? globalThis.String(object.body) : "",
      htmlUrl: isSet(object.htmlUrl) ? globalThis.String(object.htmlUrl) : "",
    };
  },

  toJSON(message: GithubRepository_CodeOfConduct): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.body !== "") {
      obj.body = message.body;
    }
    if (message.htmlUrl !== "") {
      obj.htmlUrl = message.htmlUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GithubRepository_CodeOfConduct>, I>>(base?: I): GithubRepository_CodeOfConduct {
    return GithubRepository_CodeOfConduct.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GithubRepository_CodeOfConduct>, I>>(
    object: I,
  ): GithubRepository_CodeOfConduct {
    const message = createBaseGithubRepository_CodeOfConduct();
    message.key = object.key ?? "";
    message.name = object.name ?? "";
    message.url = object.url ?? "";
    message.body = object.body ?? "";
    message.htmlUrl = object.htmlUrl ?? "";
    return message;
  },
};

function createBaseGithubRepository_License(): GithubRepository_License {
  return { key: "", name: "", spdxId: "", url: "", nodeId: "" };
}

export const GithubRepository_License = {
  encode(message: GithubRepository_License, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.spdxId !== "") {
      writer.uint32(26).string(message.spdxId);
    }
    if (message.url !== "") {
      writer.uint32(34).string(message.url);
    }
    if (message.nodeId !== "") {
      writer.uint32(42).string(message.nodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GithubRepository_License {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGithubRepository_License();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.spdxId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.url = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GithubRepository_License {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      spdxId: isSet(object.spdxId) ? globalThis.String(object.spdxId) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
    };
  },

  toJSON(message: GithubRepository_License): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.spdxId !== "") {
      obj.spdxId = message.spdxId;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GithubRepository_License>, I>>(base?: I): GithubRepository_License {
    return GithubRepository_License.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GithubRepository_License>, I>>(object: I): GithubRepository_License {
    const message = createBaseGithubRepository_License();
    message.key = object.key ?? "";
    message.name = object.name ?? "";
    message.spdxId = object.spdxId ?? "";
    message.url = object.url ?? "";
    message.nodeId = object.nodeId ?? "";
    return message;
  },
};

function createBaseGithubRepository_Status(): GithubRepository_Status {
  return { status: GithubRepository_Status_EnabledOrDisabled.unspecified };
}

export const GithubRepository_Status = {
  encode(message: GithubRepository_Status, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== GithubRepository_Status_EnabledOrDisabled.unspecified) {
      writer.uint32(8).int32(githubRepository_Status_EnabledOrDisabledToNumber(message.status));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GithubRepository_Status {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGithubRepository_Status();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = githubRepository_Status_EnabledOrDisabledFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GithubRepository_Status {
    return {
      status: isSet(object.status)
        ? githubRepository_Status_EnabledOrDisabledFromJSON(object.status)
        : GithubRepository_Status_EnabledOrDisabled.unspecified,
    };
  },

  toJSON(message: GithubRepository_Status): unknown {
    const obj: any = {};
    if (message.status !== GithubRepository_Status_EnabledOrDisabled.unspecified) {
      obj.status = githubRepository_Status_EnabledOrDisabledToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GithubRepository_Status>, I>>(base?: I): GithubRepository_Status {
    return GithubRepository_Status.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GithubRepository_Status>, I>>(object: I): GithubRepository_Status {
    const message = createBaseGithubRepository_Status();
    message.status = object.status ?? GithubRepository_Status_EnabledOrDisabled.unspecified;
    return message;
  },
};

function createBaseGithubRepository_SecurityAndAnalysis(): GithubRepository_SecurityAndAnalysis {
  return { advancedSecurity: undefined, secretScanning: undefined, secretScanningPushProtection: undefined };
}

export const GithubRepository_SecurityAndAnalysis = {
  encode(message: GithubRepository_SecurityAndAnalysis, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.advancedSecurity !== undefined) {
      GithubRepository_Status.encode(message.advancedSecurity, writer.uint32(10).fork()).ldelim();
    }
    if (message.secretScanning !== undefined) {
      GithubRepository_Status.encode(message.secretScanning, writer.uint32(18).fork()).ldelim();
    }
    if (message.secretScanningPushProtection !== undefined) {
      GithubRepository_Status.encode(message.secretScanningPushProtection, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GithubRepository_SecurityAndAnalysis {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGithubRepository_SecurityAndAnalysis();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.advancedSecurity = GithubRepository_Status.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.secretScanning = GithubRepository_Status.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.secretScanningPushProtection = GithubRepository_Status.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GithubRepository_SecurityAndAnalysis {
    return {
      advancedSecurity: isSet(object.advancedSecurity)
        ? GithubRepository_Status.fromJSON(object.advancedSecurity)
        : undefined,
      secretScanning: isSet(object.secretScanning)
        ? GithubRepository_Status.fromJSON(object.secretScanning)
        : undefined,
      secretScanningPushProtection: isSet(object.secretScanningPushProtection)
        ? GithubRepository_Status.fromJSON(object.secretScanningPushProtection)
        : undefined,
    };
  },

  toJSON(message: GithubRepository_SecurityAndAnalysis): unknown {
    const obj: any = {};
    if (message.advancedSecurity !== undefined) {
      obj.advancedSecurity = GithubRepository_Status.toJSON(message.advancedSecurity);
    }
    if (message.secretScanning !== undefined) {
      obj.secretScanning = GithubRepository_Status.toJSON(message.secretScanning);
    }
    if (message.secretScanningPushProtection !== undefined) {
      obj.secretScanningPushProtection = GithubRepository_Status.toJSON(message.secretScanningPushProtection);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GithubRepository_SecurityAndAnalysis>, I>>(
    base?: I,
  ): GithubRepository_SecurityAndAnalysis {
    return GithubRepository_SecurityAndAnalysis.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GithubRepository_SecurityAndAnalysis>, I>>(
    object: I,
  ): GithubRepository_SecurityAndAnalysis {
    const message = createBaseGithubRepository_SecurityAndAnalysis();
    message.advancedSecurity = (object.advancedSecurity !== undefined && object.advancedSecurity !== null)
      ? GithubRepository_Status.fromPartial(object.advancedSecurity)
      : undefined;
    message.secretScanning = (object.secretScanning !== undefined && object.secretScanning !== null)
      ? GithubRepository_Status.fromPartial(object.secretScanning)
      : undefined;
    message.secretScanningPushProtection =
      (object.secretScanningPushProtection !== undefined && object.secretScanningPushProtection !== null)
        ? GithubRepository_Status.fromPartial(object.secretScanningPushProtection)
        : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
