import { useContext } from "react";

import { LinkButton } from "@shared/components";

import { WorkbenchContext } from "../../providers";

interface Props {
  line: number;
  label?: "pro-only" | "pro-removed" | "normal";
}

export const LineNumber = ({ line, label }: Props) => {
  const { workbench } = useContext(WorkbenchContext);
  return (
    <LinkButton
      style={{
        padding: 0,
        textDecoration: label === "pro-removed" ? "line-through" : undefined,
      }}
      onClick={() => workbench.editors.focusTargetLine(line)}
    >
      ⬆Line {line}
    </LinkButton>
  );
};
