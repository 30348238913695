import { useContext } from "react";
import cloneDeep from "lodash/cloneDeep";
import { observer } from "mobx-react-lite";
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon } from "@mantine/core";

import {
  ALLOWED_VISUAL_PATTERN_KEYS,
  SearchPatternComponent,
} from "@shared/types";

import { WorkbenchContext } from "../../providers";

import { PatternEditor } from "./PatternEditor";
import { PatternSelect } from "./PatternSelect";

interface Props {
  patternComponent: SearchPatternComponent;
  // index of pattern in bundle.simpleRule.patterns
  index: number;
}

const PatternRowComponent: React.FC<Props> = ({ patternComponent, index }) => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;

  if (bundle === null || bundle.simpleRule === undefined) {
    // Should never get here because you can't switch to Simple Mode
    return null;
  }

  const onChangePatternType = (newType: ALLOWED_VISUAL_PATTERN_KEYS) => {
    // this only works with SearchPatternComponents
    // no support for MetavariableConstraintPatternComponents
    const newRule = cloneDeep(bundle.simpleRule!);
    if (newType === "pattern-fix") {
      newRule.fix = patternComponent.pattern;
      if (newRule.fix === "") {
        // TODO hack if autofix is being added for the first time,
        // we need to actually populate it with text so it's not ignored
        newRule.fix = "TODO";
      }
      newRule.patterns.splice(index, 1);
    } else {
      const spattern = patternComponent as SearchPatternComponent;
      const newComponent = {
        pattern: spattern.pattern,
        type: newType,
      };
      newRule.patterns.splice(index, 1, newComponent);
    }
    bundle.onSimpleRuleChange(newRule);
  };

  const onChangePattern = (newPattern: string) => {
    const newRule = cloneDeep(bundle.simpleRule!);
    const newComponent = {
      pattern: newPattern,
      type: patternComponent.type,
    };
    newRule.patterns.splice(index, 1, newComponent);
    bundle.onSimpleRuleChange(newRule);
  };

  const onAddPattern = () => {
    const newRule = cloneDeep(bundle.simpleRule!);
    // need valid pattern or legacy rule isn't correctly converted
    newRule.patterns = newRule.patterns.concat({
      pattern: 'print("...")',
      type: "pattern",
    });
    bundle.onSimpleRuleChange(newRule);
  };

  const onRemovePattern = () => {
    const newRule = cloneDeep(bundle.simpleRule!);
    newRule.patterns.splice(index, 1);
    bundle.onSimpleRuleChange(newRule);
  };

  return (
    <>
      {/* needs to start in the first column */}
      <div style={{ gridColumnStart: 1 }}>
        <PatternSelect
          value={patternComponent.type}
          onChange={onChangePatternType}
          disabled={index === 0}
        />
      </div>
      <PatternEditor
        pattern={patternComponent.pattern}
        onPatternChange={onChangePattern}
        index={index}
      />
      <ActionIcon variant="default" size="sm" onClick={onAddPattern}>
        <FontAwesomeIcon icon={faPlus} size="sm" />
      </ActionIcon>
      {index !== 0 && (
        <ActionIcon variant="default" size="sm" onClick={onRemovePattern}>
          <FontAwesomeIcon icon={faMinus} size="sm" />
        </ActionIcon>
      )}
    </>
  );
};

export const PatternRow = observer(PatternRowComponent);
