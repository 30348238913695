export const notify = (message: string, options?: NotificationOptions) => {
  if (Notification.permission === "granted") {
    return new Notification(message, options);
  }
  return null;
};

export const requestNotificationPermission = () => {
  if (Notification.permission !== "denied") {
    Notification.requestPermission();
  }
};
