// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/auth/v1/common.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "protos.auth.v1";

export enum TourState {
  UNSPECIFIED = "TOUR_STATE_UNSPECIFIED",
  INCOMPLETE = "TOUR_STATE_INCOMPLETE",
  COMPLETE = "TOUR_STATE_COMPLETE",
  SKIPPED = "TOUR_STATE_SKIPPED",
}

export function tourStateFromJSON(object: any): TourState {
  switch (object) {
    case 0:
    case "TOUR_STATE_UNSPECIFIED":
      return TourState.UNSPECIFIED;
    case 1:
    case "TOUR_STATE_INCOMPLETE":
      return TourState.INCOMPLETE;
    case 2:
    case "TOUR_STATE_COMPLETE":
      return TourState.COMPLETE;
    case 3:
    case "TOUR_STATE_SKIPPED":
      return TourState.SKIPPED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TourState");
  }
}

export function tourStateToJSON(object: TourState): string {
  switch (object) {
    case TourState.UNSPECIFIED:
      return "TOUR_STATE_UNSPECIFIED";
    case TourState.INCOMPLETE:
      return "TOUR_STATE_INCOMPLETE";
    case TourState.COMPLETE:
      return "TOUR_STATE_COMPLETE";
    case TourState.SKIPPED:
      return "TOUR_STATE_SKIPPED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TourState");
  }
}

export function tourStateToNumber(object: TourState): number {
  switch (object) {
    case TourState.UNSPECIFIED:
      return 0;
    case TourState.INCOMPLETE:
      return 1;
    case TourState.COMPLETE:
      return 2;
    case TourState.SKIPPED:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TourState");
  }
}

export interface UserEmail {
  email: string;
  primary: boolean;
  verified: boolean;
}

export interface UserSettings {
  /** the user's currently selected email address */
  primaryEmail: string;
  /** if the user has accepted the terms of service */
  hasAcceptedToc: boolean;
  /** if the user is subscribed to emails */
  subscribe: boolean;
  /** if the user would like to be contacted about product experiments */
  contactAboutExperiments: boolean;
  /** other emails that the user has */
  availableEmails: UserEmail[];
  /** the user's profile photo url */
  profilePhotoUrl?:
    | string
    | undefined;
  /**
   * if the user has completed the findings product tour, deprecated (see findings_tour_state)
   *
   * @deprecated
   */
  hasCompletedFindingsTour: boolean;
  /**
   * if the user has completed the supply chain product tour, deprecated (see supply_chain_tour_state)
   *
   * @deprecated
   */
  hasCompletedSupplyChainTour: boolean;
  /** the completion state of the findings product tour */
  findingsTourState: TourState;
  /** the completion state of the supply chain product tour */
  supplyChainTourState: TourState;
  /** the completion state of the supply chain product tour (using the new findings UI) */
  supplyChainFindingsTourState: TourState;
}

export interface UserAuthDetails {
  providerId: string;
  providerType: string;
  sourceId: string;
  current: boolean;
}

export interface User {
  id: number;
  name: string;
  email: string;
  login: string;
  authDetails: UserAuthDetails[];
  orgRole?: string | undefined;
  deletedAt?: Date | undefined;
}

function createBaseUserEmail(): UserEmail {
  return { email: "", primary: false, verified: false };
}

export const UserEmail = {
  encode(message: UserEmail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.primary !== false) {
      writer.uint32(16).bool(message.primary);
    }
    if (message.verified !== false) {
      writer.uint32(24).bool(message.verified);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserEmail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserEmail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.primary = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.verified = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserEmail {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      primary: isSet(object.primary) ? globalThis.Boolean(object.primary) : false,
      verified: isSet(object.verified) ? globalThis.Boolean(object.verified) : false,
    };
  },

  toJSON(message: UserEmail): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.primary !== false) {
      obj.primary = message.primary;
    }
    if (message.verified !== false) {
      obj.verified = message.verified;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserEmail>, I>>(base?: I): UserEmail {
    return UserEmail.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserEmail>, I>>(object: I): UserEmail {
    const message = createBaseUserEmail();
    message.email = object.email ?? "";
    message.primary = object.primary ?? false;
    message.verified = object.verified ?? false;
    return message;
  },
};

function createBaseUserSettings(): UserSettings {
  return {
    primaryEmail: "",
    hasAcceptedToc: false,
    subscribe: false,
    contactAboutExperiments: false,
    availableEmails: [],
    profilePhotoUrl: undefined,
    hasCompletedFindingsTour: false,
    hasCompletedSupplyChainTour: false,
    findingsTourState: TourState.UNSPECIFIED,
    supplyChainTourState: TourState.UNSPECIFIED,
    supplyChainFindingsTourState: TourState.UNSPECIFIED,
  };
}

export const UserSettings = {
  encode(message: UserSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.primaryEmail !== "") {
      writer.uint32(10).string(message.primaryEmail);
    }
    if (message.hasAcceptedToc !== false) {
      writer.uint32(16).bool(message.hasAcceptedToc);
    }
    if (message.subscribe !== false) {
      writer.uint32(24).bool(message.subscribe);
    }
    if (message.contactAboutExperiments !== false) {
      writer.uint32(32).bool(message.contactAboutExperiments);
    }
    for (const v of message.availableEmails) {
      UserEmail.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.profilePhotoUrl !== undefined) {
      writer.uint32(50).string(message.profilePhotoUrl);
    }
    if (message.hasCompletedFindingsTour !== false) {
      writer.uint32(56).bool(message.hasCompletedFindingsTour);
    }
    if (message.hasCompletedSupplyChainTour !== false) {
      writer.uint32(64).bool(message.hasCompletedSupplyChainTour);
    }
    if (message.findingsTourState !== TourState.UNSPECIFIED) {
      writer.uint32(72).int32(tourStateToNumber(message.findingsTourState));
    }
    if (message.supplyChainTourState !== TourState.UNSPECIFIED) {
      writer.uint32(80).int32(tourStateToNumber(message.supplyChainTourState));
    }
    if (message.supplyChainFindingsTourState !== TourState.UNSPECIFIED) {
      writer.uint32(88).int32(tourStateToNumber(message.supplyChainFindingsTourState));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.primaryEmail = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasAcceptedToc = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.subscribe = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.contactAboutExperiments = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.availableEmails.push(UserEmail.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.profilePhotoUrl = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.hasCompletedFindingsTour = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.hasCompletedSupplyChainTour = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.findingsTourState = tourStateFromJSON(reader.int32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.supplyChainTourState = tourStateFromJSON(reader.int32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.supplyChainFindingsTourState = tourStateFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserSettings {
    return {
      primaryEmail: isSet(object.primaryEmail) ? globalThis.String(object.primaryEmail) : "",
      hasAcceptedToc: isSet(object.hasAcceptedToc) ? globalThis.Boolean(object.hasAcceptedToc) : false,
      subscribe: isSet(object.subscribe) ? globalThis.Boolean(object.subscribe) : false,
      contactAboutExperiments: isSet(object.contactAboutExperiments)
        ? globalThis.Boolean(object.contactAboutExperiments)
        : false,
      availableEmails: globalThis.Array.isArray(object?.availableEmails)
        ? object.availableEmails.map((e: any) => UserEmail.fromJSON(e))
        : [],
      profilePhotoUrl: isSet(object.profilePhotoUrl) ? globalThis.String(object.profilePhotoUrl) : undefined,
      hasCompletedFindingsTour: isSet(object.hasCompletedFindingsTour)
        ? globalThis.Boolean(object.hasCompletedFindingsTour)
        : false,
      hasCompletedSupplyChainTour: isSet(object.hasCompletedSupplyChainTour)
        ? globalThis.Boolean(object.hasCompletedSupplyChainTour)
        : false,
      findingsTourState: isSet(object.findingsTourState)
        ? tourStateFromJSON(object.findingsTourState)
        : TourState.UNSPECIFIED,
      supplyChainTourState: isSet(object.supplyChainTourState)
        ? tourStateFromJSON(object.supplyChainTourState)
        : TourState.UNSPECIFIED,
      supplyChainFindingsTourState: isSet(object.supplyChainFindingsTourState)
        ? tourStateFromJSON(object.supplyChainFindingsTourState)
        : TourState.UNSPECIFIED,
    };
  },

  toJSON(message: UserSettings): unknown {
    const obj: any = {};
    if (message.primaryEmail !== "") {
      obj.primaryEmail = message.primaryEmail;
    }
    if (message.hasAcceptedToc !== false) {
      obj.hasAcceptedToc = message.hasAcceptedToc;
    }
    if (message.subscribe !== false) {
      obj.subscribe = message.subscribe;
    }
    if (message.contactAboutExperiments !== false) {
      obj.contactAboutExperiments = message.contactAboutExperiments;
    }
    if (message.availableEmails?.length) {
      obj.availableEmails = message.availableEmails.map((e) => UserEmail.toJSON(e));
    }
    if (message.profilePhotoUrl !== undefined) {
      obj.profilePhotoUrl = message.profilePhotoUrl;
    }
    if (message.hasCompletedFindingsTour !== false) {
      obj.hasCompletedFindingsTour = message.hasCompletedFindingsTour;
    }
    if (message.hasCompletedSupplyChainTour !== false) {
      obj.hasCompletedSupplyChainTour = message.hasCompletedSupplyChainTour;
    }
    if (message.findingsTourState !== TourState.UNSPECIFIED) {
      obj.findingsTourState = tourStateToJSON(message.findingsTourState);
    }
    if (message.supplyChainTourState !== TourState.UNSPECIFIED) {
      obj.supplyChainTourState = tourStateToJSON(message.supplyChainTourState);
    }
    if (message.supplyChainFindingsTourState !== TourState.UNSPECIFIED) {
      obj.supplyChainFindingsTourState = tourStateToJSON(message.supplyChainFindingsTourState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserSettings>, I>>(base?: I): UserSettings {
    return UserSettings.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserSettings>, I>>(object: I): UserSettings {
    const message = createBaseUserSettings();
    message.primaryEmail = object.primaryEmail ?? "";
    message.hasAcceptedToc = object.hasAcceptedToc ?? false;
    message.subscribe = object.subscribe ?? false;
    message.contactAboutExperiments = object.contactAboutExperiments ?? false;
    message.availableEmails = object.availableEmails?.map((e) => UserEmail.fromPartial(e)) || [];
    message.profilePhotoUrl = object.profilePhotoUrl ?? undefined;
    message.hasCompletedFindingsTour = object.hasCompletedFindingsTour ?? false;
    message.hasCompletedSupplyChainTour = object.hasCompletedSupplyChainTour ?? false;
    message.findingsTourState = object.findingsTourState ?? TourState.UNSPECIFIED;
    message.supplyChainTourState = object.supplyChainTourState ?? TourState.UNSPECIFIED;
    message.supplyChainFindingsTourState = object.supplyChainFindingsTourState ?? TourState.UNSPECIFIED;
    return message;
  },
};

function createBaseUserAuthDetails(): UserAuthDetails {
  return { providerId: "", providerType: "", sourceId: "", current: false };
}

export const UserAuthDetails = {
  encode(message: UserAuthDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.providerId !== "") {
      writer.uint32(10).string(message.providerId);
    }
    if (message.providerType !== "") {
      writer.uint32(18).string(message.providerType);
    }
    if (message.sourceId !== "") {
      writer.uint32(26).string(message.sourceId);
    }
    if (message.current !== false) {
      writer.uint32(32).bool(message.current);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserAuthDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserAuthDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.providerId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.providerType = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sourceId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.current = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserAuthDetails {
    return {
      providerId: isSet(object.providerId) ? globalThis.String(object.providerId) : "",
      providerType: isSet(object.providerType) ? globalThis.String(object.providerType) : "",
      sourceId: isSet(object.sourceId) ? globalThis.String(object.sourceId) : "",
      current: isSet(object.current) ? globalThis.Boolean(object.current) : false,
    };
  },

  toJSON(message: UserAuthDetails): unknown {
    const obj: any = {};
    if (message.providerId !== "") {
      obj.providerId = message.providerId;
    }
    if (message.providerType !== "") {
      obj.providerType = message.providerType;
    }
    if (message.sourceId !== "") {
      obj.sourceId = message.sourceId;
    }
    if (message.current !== false) {
      obj.current = message.current;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserAuthDetails>, I>>(base?: I): UserAuthDetails {
    return UserAuthDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserAuthDetails>, I>>(object: I): UserAuthDetails {
    const message = createBaseUserAuthDetails();
    message.providerId = object.providerId ?? "";
    message.providerType = object.providerType ?? "";
    message.sourceId = object.sourceId ?? "";
    message.current = object.current ?? false;
    return message;
  },
};

function createBaseUser(): User {
  return { id: 0, name: "", email: "", login: "", authDetails: [], orgRole: undefined, deletedAt: undefined };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    if (message.login !== "") {
      writer.uint32(34).string(message.login);
    }
    for (const v of message.authDetails) {
      UserAuthDetails.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.orgRole !== undefined) {
      writer.uint32(50).string(message.orgRole);
    }
    if (message.deletedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.deletedAt), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.login = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.authDetails.push(UserAuthDetails.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orgRole = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.deletedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      login: isSet(object.login) ? globalThis.String(object.login) : "",
      authDetails: globalThis.Array.isArray(object?.authDetails)
        ? object.authDetails.map((e: any) => UserAuthDetails.fromJSON(e))
        : [],
      orgRole: isSet(object.orgRole) ? globalThis.String(object.orgRole) : undefined,
      deletedAt: isSet(object.deletedAt) ? fromJsonTimestamp(object.deletedAt) : undefined,
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.login !== "") {
      obj.login = message.login;
    }
    if (message.authDetails?.length) {
      obj.authDetails = message.authDetails.map((e) => UserAuthDetails.toJSON(e));
    }
    if (message.orgRole !== undefined) {
      obj.orgRole = message.orgRole;
    }
    if (message.deletedAt !== undefined) {
      obj.deletedAt = message.deletedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.login = object.login ?? "";
    message.authDetails = object.authDetails?.map((e) => UserAuthDetails.fromPartial(e)) || [];
    message.orgRole = object.orgRole ?? undefined;
    message.deletedAt = object.deletedAt ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
