import { TimeFilter } from "@protos/issues/v1/issues";
import {
  TIME_LOOKBACK_TYPES_OPTIONS,
  TimeLookbackRange,
  TimeLookbackType,
} from "@shared/types";

import { calculateLookbackSeconds } from "../calculateLookbackSeconds";

export const timeLookbackRangeToSince = (
  timeLookbackRange: TimeLookbackRange,
  until?: Date
): number => {
  const trueSince = calculateLookbackSeconds(timeLookbackRange, until);
  // To prevent duplicated loading, decrease precision to be 100 seconds
  const roundedSince = Math.floor(trueSince / 100) * 100;

  return roundedSince;
};

export const timeLookbackRangeToSinceDate = (
  timeLookbackRange: TimeLookbackRange,
  until?: Date
): Date => {
  return new Date(timeLookbackRangeToSince(timeLookbackRange, until) * 1000);
};

export const timeLookbackTypeToProto = (
  timeLookbackType: TimeLookbackType
): TimeFilter => {
  return TIME_LOOKBACK_TYPES_OPTIONS[timeLookbackType];
};

export const getTimeLookbackFilters = (
  timeLookbackRange: TimeLookbackRange,
  timeLookbackType: TimeLookbackType
) => {
  return {
    since: timeLookbackRangeToSinceDate(timeLookbackRange),
    timeFilter: timeLookbackTypeToProto(timeLookbackType),
  };
};

export function tlrFromQueryParam(
  lastTriaged: string | undefined
): TimeLookbackRange | undefined {
  return lastTriaged
    ? (decodeURIComponent(lastTriaged) as TimeLookbackRange)
    : undefined;
}

export function tlrToQueryParam(
  lastTriaged: TimeLookbackRange | undefined
): string {
  return lastTriaged ?? "";
}
