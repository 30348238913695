import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { useAtom } from "jotai";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import {
  ContextAttributes,
  PrefabProvider,
} from "@prefab-cloud/prefab-cloud-react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { AppSpotlight, LoadingScreen } from "@shared/components";
import {
  useFetchPermissions,
  useOrg,
  useSetPermissions,
  useTenant,
  useUser,
} from "@shared/hooks";
import { orgLoadingAtom } from "@shared/state";
import { MantineProvider } from "@shared/theme";
import { fullstory, getPrefabClientKey, queryClient } from "@shared/utils";

dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);

interface AppProviderProps {
  children?: React.ReactNode;
}

const AppProviderLoading: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [org] = useOrg();
  const [orgLoading] = useAtom(orgLoadingAtom);
  const { data: fetchedPermissions } = useFetchPermissions();
  // query isn't enabled if there's no org
  const isLoadingPermissions = org && fetchedPermissions === undefined;
  const setPermissions = useSetPermissions();

  useEffect(() => {
    if (fetchedPermissions) {
      setPermissions(fetchedPermissions);
    }
  }, [fetchedPermissions, setPermissions]);

  if (orgLoading || isLoadingPermissions) {
    return <LoadingScreen />;
  }
  return children;
};

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const [showQueryDevtools, setShowQueryDevtools] = useState(true);
  const [user] = useUser();
  const [org] = useOrg();
  const tenant = useTenant();
  const contextAttributes: ContextAttributes = {
    user: omitBy(
      { id: user?.user_id, username: user?.username, email: user?.email },
      isUndefined
    ),
    deployment: omitBy(
      { id: org?.id, key: org?.id, name: org?.name },
      isUndefined
    ),
    tenant: { key: tenant.tenantName },
  } as ContextAttributes;

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      // @ts-ignore
      window.setShowQueryDevtools = (show: bool) => setShowQueryDevtools(show);
    }
  }, []);

  return (
    <PrefabProvider
      apiKey={getPrefabClientKey()}
      contextAttributes={contextAttributes}
      collectEvaluationSummaries={true}
      afterEvaluationCallback={(key, value) => {
        if (key.startsWith("onboarding.")) {
          // NOTE(zz): Only log onboarding exposure events for now.
          fullstory.event("prefab-evaluation", { key, value });
        }
      }}
    >
      <QueryClientProvider client={queryClient}>
        <MantineProvider>
          {org && <AppSpotlight />}
          <AppProviderLoading>{children}</AppProviderLoading>
          {process.env.NODE_ENV === "development" && showQueryDevtools && (
            <ReactQueryDevtools initialIsOpen={false} position="bottom" />
          )}
        </MantineProvider>
      </QueryClientProvider>
    </PrefabProvider>
  );
};
