import { Tooltip as MantineTooltip } from "@mantine/core";

export const Tooltip = MantineTooltip.extend({
  defaultProps: {
    transitionProps: {
      transition: "pop",
      duration: 350,
    },
  },
});
