// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/onboarding/v1/checklist.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "protos.onboarding.v1";

export interface OnboardingTask {
  type: OnboardingTask_TaskType;
  state: OnboardingTask_TaskState;
  level: OnboardingTask_TaskLevel;
}

export enum OnboardingTask_TaskType {
  UNSPECIFIED = "TASK_TYPE_UNSPECIFIED",
  COMPLETE_TOUR = "TASK_TYPE_COMPLETE_TOUR",
  SCAN_CODE = "TASK_TYPE_SCAN_CODE",
  TRIAGE_FIRST_PARTY = "TASK_TYPE_TRIAGE_FIRST_PARTY",
  TRIAGE_DEPENDENCY = "TASK_TYPE_TRIAGE_DEPENDENCY",
  SET_UP_RECURRING_SCAN = "TASK_TYPE_SET_UP_RECURRING_SCAN",
  CONFIGURE_IGNORES = "TASK_TYPE_CONFIGURE_IGNORES",
  JOIN_SLACK = "TASK_TYPE_JOIN_SLACK",
  CUSTOMIZE_RULES = "TASK_TYPE_CUSTOMIZE_RULES",
  ENABLE_INTEGRATION = "TASK_TYPE_ENABLE_INTEGRATION",
  ADD_TEAM_MEMBER = "TASK_TYPE_ADD_TEAM_MEMBER",
  WRITE_CUSTOM_RULE = "TASK_TYPE_WRITE_CUSTOM_RULE",
  SETUP_SCM_PROVIDER = "TASK_TYPE_SETUP_SCM_PROVIDER",
  ENABLE_ASSISTANT = "TASK_TYPE_ENABLE_ASSISTANT",
}

export function onboardingTask_TaskTypeFromJSON(object: any): OnboardingTask_TaskType {
  switch (object) {
    case 0:
    case "TASK_TYPE_UNSPECIFIED":
      return OnboardingTask_TaskType.UNSPECIFIED;
    case 1:
    case "TASK_TYPE_COMPLETE_TOUR":
      return OnboardingTask_TaskType.COMPLETE_TOUR;
    case 2:
    case "TASK_TYPE_SCAN_CODE":
      return OnboardingTask_TaskType.SCAN_CODE;
    case 3:
    case "TASK_TYPE_TRIAGE_FIRST_PARTY":
      return OnboardingTask_TaskType.TRIAGE_FIRST_PARTY;
    case 4:
    case "TASK_TYPE_TRIAGE_DEPENDENCY":
      return OnboardingTask_TaskType.TRIAGE_DEPENDENCY;
    case 5:
    case "TASK_TYPE_SET_UP_RECURRING_SCAN":
      return OnboardingTask_TaskType.SET_UP_RECURRING_SCAN;
    case 6:
    case "TASK_TYPE_CONFIGURE_IGNORES":
      return OnboardingTask_TaskType.CONFIGURE_IGNORES;
    case 7:
    case "TASK_TYPE_JOIN_SLACK":
      return OnboardingTask_TaskType.JOIN_SLACK;
    case 8:
    case "TASK_TYPE_CUSTOMIZE_RULES":
      return OnboardingTask_TaskType.CUSTOMIZE_RULES;
    case 9:
    case "TASK_TYPE_ENABLE_INTEGRATION":
      return OnboardingTask_TaskType.ENABLE_INTEGRATION;
    case 10:
    case "TASK_TYPE_ADD_TEAM_MEMBER":
      return OnboardingTask_TaskType.ADD_TEAM_MEMBER;
    case 11:
    case "TASK_TYPE_WRITE_CUSTOM_RULE":
      return OnboardingTask_TaskType.WRITE_CUSTOM_RULE;
    case 12:
    case "TASK_TYPE_SETUP_SCM_PROVIDER":
      return OnboardingTask_TaskType.SETUP_SCM_PROVIDER;
    case 13:
    case "TASK_TYPE_ENABLE_ASSISTANT":
      return OnboardingTask_TaskType.ENABLE_ASSISTANT;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OnboardingTask_TaskType");
  }
}

export function onboardingTask_TaskTypeToJSON(object: OnboardingTask_TaskType): string {
  switch (object) {
    case OnboardingTask_TaskType.UNSPECIFIED:
      return "TASK_TYPE_UNSPECIFIED";
    case OnboardingTask_TaskType.COMPLETE_TOUR:
      return "TASK_TYPE_COMPLETE_TOUR";
    case OnboardingTask_TaskType.SCAN_CODE:
      return "TASK_TYPE_SCAN_CODE";
    case OnboardingTask_TaskType.TRIAGE_FIRST_PARTY:
      return "TASK_TYPE_TRIAGE_FIRST_PARTY";
    case OnboardingTask_TaskType.TRIAGE_DEPENDENCY:
      return "TASK_TYPE_TRIAGE_DEPENDENCY";
    case OnboardingTask_TaskType.SET_UP_RECURRING_SCAN:
      return "TASK_TYPE_SET_UP_RECURRING_SCAN";
    case OnboardingTask_TaskType.CONFIGURE_IGNORES:
      return "TASK_TYPE_CONFIGURE_IGNORES";
    case OnboardingTask_TaskType.JOIN_SLACK:
      return "TASK_TYPE_JOIN_SLACK";
    case OnboardingTask_TaskType.CUSTOMIZE_RULES:
      return "TASK_TYPE_CUSTOMIZE_RULES";
    case OnboardingTask_TaskType.ENABLE_INTEGRATION:
      return "TASK_TYPE_ENABLE_INTEGRATION";
    case OnboardingTask_TaskType.ADD_TEAM_MEMBER:
      return "TASK_TYPE_ADD_TEAM_MEMBER";
    case OnboardingTask_TaskType.WRITE_CUSTOM_RULE:
      return "TASK_TYPE_WRITE_CUSTOM_RULE";
    case OnboardingTask_TaskType.SETUP_SCM_PROVIDER:
      return "TASK_TYPE_SETUP_SCM_PROVIDER";
    case OnboardingTask_TaskType.ENABLE_ASSISTANT:
      return "TASK_TYPE_ENABLE_ASSISTANT";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OnboardingTask_TaskType");
  }
}

export function onboardingTask_TaskTypeToNumber(object: OnboardingTask_TaskType): number {
  switch (object) {
    case OnboardingTask_TaskType.UNSPECIFIED:
      return 0;
    case OnboardingTask_TaskType.COMPLETE_TOUR:
      return 1;
    case OnboardingTask_TaskType.SCAN_CODE:
      return 2;
    case OnboardingTask_TaskType.TRIAGE_FIRST_PARTY:
      return 3;
    case OnboardingTask_TaskType.TRIAGE_DEPENDENCY:
      return 4;
    case OnboardingTask_TaskType.SET_UP_RECURRING_SCAN:
      return 5;
    case OnboardingTask_TaskType.CONFIGURE_IGNORES:
      return 6;
    case OnboardingTask_TaskType.JOIN_SLACK:
      return 7;
    case OnboardingTask_TaskType.CUSTOMIZE_RULES:
      return 8;
    case OnboardingTask_TaskType.ENABLE_INTEGRATION:
      return 9;
    case OnboardingTask_TaskType.ADD_TEAM_MEMBER:
      return 10;
    case OnboardingTask_TaskType.WRITE_CUSTOM_RULE:
      return 11;
    case OnboardingTask_TaskType.SETUP_SCM_PROVIDER:
      return 12;
    case OnboardingTask_TaskType.ENABLE_ASSISTANT:
      return 13;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OnboardingTask_TaskType");
  }
}

export enum OnboardingTask_TaskState {
  UNSPECIFIED = "TASK_STATE_UNSPECIFIED",
  COMPLETE = "TASK_STATE_COMPLETE",
  /** INCOMPLETE - not done yet */
  INCOMPLETE = "TASK_STATE_INCOMPLETE",
  /** LOCKED - cannot be completed until other items are completed */
  LOCKED = "TASK_STATE_LOCKED",
}

export function onboardingTask_TaskStateFromJSON(object: any): OnboardingTask_TaskState {
  switch (object) {
    case 0:
    case "TASK_STATE_UNSPECIFIED":
      return OnboardingTask_TaskState.UNSPECIFIED;
    case 1:
    case "TASK_STATE_COMPLETE":
      return OnboardingTask_TaskState.COMPLETE;
    case 2:
    case "TASK_STATE_INCOMPLETE":
      return OnboardingTask_TaskState.INCOMPLETE;
    case 3:
    case "TASK_STATE_LOCKED":
      return OnboardingTask_TaskState.LOCKED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OnboardingTask_TaskState");
  }
}

export function onboardingTask_TaskStateToJSON(object: OnboardingTask_TaskState): string {
  switch (object) {
    case OnboardingTask_TaskState.UNSPECIFIED:
      return "TASK_STATE_UNSPECIFIED";
    case OnboardingTask_TaskState.COMPLETE:
      return "TASK_STATE_COMPLETE";
    case OnboardingTask_TaskState.INCOMPLETE:
      return "TASK_STATE_INCOMPLETE";
    case OnboardingTask_TaskState.LOCKED:
      return "TASK_STATE_LOCKED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OnboardingTask_TaskState");
  }
}

export function onboardingTask_TaskStateToNumber(object: OnboardingTask_TaskState): number {
  switch (object) {
    case OnboardingTask_TaskState.UNSPECIFIED:
      return 0;
    case OnboardingTask_TaskState.COMPLETE:
      return 1;
    case OnboardingTask_TaskState.INCOMPLETE:
      return 2;
    case OnboardingTask_TaskState.LOCKED:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OnboardingTask_TaskState");
  }
}

export enum OnboardingTask_TaskLevel {
  UNSPECIFIED = "TASK_LEVEL_UNSPECIFIED",
  ORG = "TASK_LEVEL_ORG",
  USER = "TASK_LEVEL_USER",
}

export function onboardingTask_TaskLevelFromJSON(object: any): OnboardingTask_TaskLevel {
  switch (object) {
    case 0:
    case "TASK_LEVEL_UNSPECIFIED":
      return OnboardingTask_TaskLevel.UNSPECIFIED;
    case 1:
    case "TASK_LEVEL_ORG":
      return OnboardingTask_TaskLevel.ORG;
    case 2:
    case "TASK_LEVEL_USER":
      return OnboardingTask_TaskLevel.USER;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OnboardingTask_TaskLevel");
  }
}

export function onboardingTask_TaskLevelToJSON(object: OnboardingTask_TaskLevel): string {
  switch (object) {
    case OnboardingTask_TaskLevel.UNSPECIFIED:
      return "TASK_LEVEL_UNSPECIFIED";
    case OnboardingTask_TaskLevel.ORG:
      return "TASK_LEVEL_ORG";
    case OnboardingTask_TaskLevel.USER:
      return "TASK_LEVEL_USER";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OnboardingTask_TaskLevel");
  }
}

export function onboardingTask_TaskLevelToNumber(object: OnboardingTask_TaskLevel): number {
  switch (object) {
    case OnboardingTask_TaskLevel.UNSPECIFIED:
      return 0;
    case OnboardingTask_TaskLevel.ORG:
      return 1;
    case OnboardingTask_TaskLevel.USER:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OnboardingTask_TaskLevel");
  }
}

export interface ChecklistStatus {
  tasks: OnboardingTask[];
}

export interface GetChecklistStatusRequest {
  deploymentId: number;
}

export interface GetChecklistStatusResponse {
  taskStatus: OnboardingTask[];
}

export interface PatchChecklistRequest {
  deploymentId: number;
  taskStatus: OnboardingTask[];
}

export interface PatchChecklistResponse {
  taskStatus: OnboardingTask[];
}

function createBaseOnboardingTask(): OnboardingTask {
  return {
    type: OnboardingTask_TaskType.UNSPECIFIED,
    state: OnboardingTask_TaskState.UNSPECIFIED,
    level: OnboardingTask_TaskLevel.UNSPECIFIED,
  };
}

export const OnboardingTask = {
  encode(message: OnboardingTask, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== OnboardingTask_TaskType.UNSPECIFIED) {
      writer.uint32(8).int32(onboardingTask_TaskTypeToNumber(message.type));
    }
    if (message.state !== OnboardingTask_TaskState.UNSPECIFIED) {
      writer.uint32(16).int32(onboardingTask_TaskStateToNumber(message.state));
    }
    if (message.level !== OnboardingTask_TaskLevel.UNSPECIFIED) {
      writer.uint32(24).int32(onboardingTask_TaskLevelToNumber(message.level));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OnboardingTask {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOnboardingTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = onboardingTask_TaskTypeFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.state = onboardingTask_TaskStateFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.level = onboardingTask_TaskLevelFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OnboardingTask {
    return {
      type: isSet(object.type) ? onboardingTask_TaskTypeFromJSON(object.type) : OnboardingTask_TaskType.UNSPECIFIED,
      state: isSet(object.state)
        ? onboardingTask_TaskStateFromJSON(object.state)
        : OnboardingTask_TaskState.UNSPECIFIED,
      level: isSet(object.level)
        ? onboardingTask_TaskLevelFromJSON(object.level)
        : OnboardingTask_TaskLevel.UNSPECIFIED,
    };
  },

  toJSON(message: OnboardingTask): unknown {
    const obj: any = {};
    if (message.type !== OnboardingTask_TaskType.UNSPECIFIED) {
      obj.type = onboardingTask_TaskTypeToJSON(message.type);
    }
    if (message.state !== OnboardingTask_TaskState.UNSPECIFIED) {
      obj.state = onboardingTask_TaskStateToJSON(message.state);
    }
    if (message.level !== OnboardingTask_TaskLevel.UNSPECIFIED) {
      obj.level = onboardingTask_TaskLevelToJSON(message.level);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OnboardingTask>, I>>(base?: I): OnboardingTask {
    return OnboardingTask.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OnboardingTask>, I>>(object: I): OnboardingTask {
    const message = createBaseOnboardingTask();
    message.type = object.type ?? OnboardingTask_TaskType.UNSPECIFIED;
    message.state = object.state ?? OnboardingTask_TaskState.UNSPECIFIED;
    message.level = object.level ?? OnboardingTask_TaskLevel.UNSPECIFIED;
    return message;
  },
};

function createBaseChecklistStatus(): ChecklistStatus {
  return { tasks: [] };
}

export const ChecklistStatus = {
  encode(message: ChecklistStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tasks) {
      OnboardingTask.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChecklistStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChecklistStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tasks.push(OnboardingTask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChecklistStatus {
    return {
      tasks: globalThis.Array.isArray(object?.tasks) ? object.tasks.map((e: any) => OnboardingTask.fromJSON(e)) : [],
    };
  },

  toJSON(message: ChecklistStatus): unknown {
    const obj: any = {};
    if (message.tasks?.length) {
      obj.tasks = message.tasks.map((e) => OnboardingTask.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChecklistStatus>, I>>(base?: I): ChecklistStatus {
    return ChecklistStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChecklistStatus>, I>>(object: I): ChecklistStatus {
    const message = createBaseChecklistStatus();
    message.tasks = object.tasks?.map((e) => OnboardingTask.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetChecklistStatusRequest(): GetChecklistStatusRequest {
  return { deploymentId: 0 };
}

export const GetChecklistStatusRequest = {
  encode(message: GetChecklistStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetChecklistStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetChecklistStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetChecklistStatusRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0 };
  },

  toJSON(message: GetChecklistStatusRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetChecklistStatusRequest>, I>>(base?: I): GetChecklistStatusRequest {
    return GetChecklistStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetChecklistStatusRequest>, I>>(object: I): GetChecklistStatusRequest {
    const message = createBaseGetChecklistStatusRequest();
    message.deploymentId = object.deploymentId ?? 0;
    return message;
  },
};

function createBaseGetChecklistStatusResponse(): GetChecklistStatusResponse {
  return { taskStatus: [] };
}

export const GetChecklistStatusResponse = {
  encode(message: GetChecklistStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.taskStatus) {
      OnboardingTask.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetChecklistStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetChecklistStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.taskStatus.push(OnboardingTask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetChecklistStatusResponse {
    return {
      taskStatus: globalThis.Array.isArray(object?.taskStatus)
        ? object.taskStatus.map((e: any) => OnboardingTask.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetChecklistStatusResponse): unknown {
    const obj: any = {};
    if (message.taskStatus?.length) {
      obj.taskStatus = message.taskStatus.map((e) => OnboardingTask.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetChecklistStatusResponse>, I>>(base?: I): GetChecklistStatusResponse {
    return GetChecklistStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetChecklistStatusResponse>, I>>(object: I): GetChecklistStatusResponse {
    const message = createBaseGetChecklistStatusResponse();
    message.taskStatus = object.taskStatus?.map((e) => OnboardingTask.fromPartial(e)) || [];
    return message;
  },
};

function createBasePatchChecklistRequest(): PatchChecklistRequest {
  return { deploymentId: 0, taskStatus: [] };
}

export const PatchChecklistRequest = {
  encode(message: PatchChecklistRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    for (const v of message.taskStatus) {
      OnboardingTask.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatchChecklistRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatchChecklistRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.taskStatus.push(OnboardingTask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PatchChecklistRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      taskStatus: globalThis.Array.isArray(object?.taskStatus)
        ? object.taskStatus.map((e: any) => OnboardingTask.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PatchChecklistRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.taskStatus?.length) {
      obj.taskStatus = message.taskStatus.map((e) => OnboardingTask.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PatchChecklistRequest>, I>>(base?: I): PatchChecklistRequest {
    return PatchChecklistRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PatchChecklistRequest>, I>>(object: I): PatchChecklistRequest {
    const message = createBasePatchChecklistRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.taskStatus = object.taskStatus?.map((e) => OnboardingTask.fromPartial(e)) || [];
    return message;
  },
};

function createBasePatchChecklistResponse(): PatchChecklistResponse {
  return { taskStatus: [] };
}

export const PatchChecklistResponse = {
  encode(message: PatchChecklistResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.taskStatus) {
      OnboardingTask.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatchChecklistResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatchChecklistResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.taskStatus.push(OnboardingTask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PatchChecklistResponse {
    return {
      taskStatus: globalThis.Array.isArray(object?.taskStatus)
        ? object.taskStatus.map((e: any) => OnboardingTask.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PatchChecklistResponse): unknown {
    const obj: any = {};
    if (message.taskStatus?.length) {
      obj.taskStatus = message.taskStatus.map((e) => OnboardingTask.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PatchChecklistResponse>, I>>(base?: I): PatchChecklistResponse {
    return PatchChecklistResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PatchChecklistResponse>, I>>(object: I): PatchChecklistResponse {
    const message = createBasePatchChecklistResponse();
    message.taskStatus = object.taskStatus?.map((e) => OnboardingTask.fromPartial(e)) || [];
    return message;
  },
};

export interface ChecklistService {
  getChecklistStatus(request: GetChecklistStatusRequest): Promise<GetChecklistStatusResponse>;
  patchChecklist(request: PatchChecklistRequest): Promise<PatchChecklistResponse>;
}

export const ChecklistServiceServiceName = "protos.onboarding.v1.ChecklistService";
export class ChecklistServiceClientImpl implements ChecklistService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || ChecklistServiceServiceName;
    this.rpc = rpc;
    this.getChecklistStatus = this.getChecklistStatus.bind(this);
    this.patchChecklist = this.patchChecklist.bind(this);
  }
  getChecklistStatus(request: GetChecklistStatusRequest): Promise<GetChecklistStatusResponse> {
    const data = GetChecklistStatusRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetChecklistStatus", data);
    return promise.then((data) => GetChecklistStatusResponse.decode(_m0.Reader.create(data)));
  }

  patchChecklist(request: PatchChecklistRequest): Promise<PatchChecklistResponse> {
    const data = PatchChecklistRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "PatchChecklist", data);
    return promise.then((data) => PatchChecklistResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
