import {
  Address,
  CombinedRegistryAddress,
  RegistryAddress,
  SnippetAddress,
} from "@shared/editorCore";

/**
 * Converts addressString to address
 * @param addressString is string in format s/{snippet name or id} or r/{registry name}
 */
export const addressStringToAddress = (addressString: string): Address => {
  const [prefix, id, friendlyName] = addressString.split("/");

  switch (prefix) {
    case "s":
      return new SnippetAddress(id);
    case "r":
      return friendlyName
        ? new CombinedRegistryAddress(id, friendlyName)
        : new RegistryAddress(id);
    default:
      throw new Error(`Unknown URL prefix: ${prefix}`);
  }
};
