/**
 * Downloads file with name `fileName` and contents `fileContents`
 * @param fileName name of file, should include extension
 * @param fileContents contents of file to be downloaded
 */
export const downloadFile = (fileName: string, fileContents: string) => {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(fileContents)
  );
  element.setAttribute("download", fileName);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
