import { toJS } from "mobx";
import YAML from "yaml";

import { writeMatchSeverity } from "@semgrep_output_types";

import {
  isPatternWithKind,
  MetavariableConstraint,
  Pattern,
  PatternConstraint,
  Rule,
} from "../types/rule";

export type OutPattern =
  | { pattern: string }
  | { patterns: (OutPattern | OutConstraint)[] }
  | { "pattern-either": OutPattern[] }
  | { "pattern-regex": string }
  | { "pattern-inside": string | OutPattern }
  | { "pattern-not": string | OutPattern }
  | { "pattern-not-inside": string | OutPattern }
  | { "pattern-not-regex": string | OutPattern };

type OutConstraint =
  | { "focus-metavariable": string | string[] }
  | { "metavariable-regex": { metavariable: string; regex: string } }
  | { "metavariable-analysis": { metavariable: string; analyzer: string } }
  | { "metavariable-type": { metavariable: string; type: string } }
  | {
      "metavariable-pattern": {
        metavariable: string;
        // This could be `pattern`, `patterns`, etc.
        // Lots of possibilities, so we just say we map from any string to `any`
        // here.
        [key: string]: unknown;
      };
    }
  | { "metavariable-comparison": { comparison: string } };

/******************************************************************************/
/* Rule to YAML */
/******************************************************************************/

function hasEmptyConstraint(p: Pattern): boolean {
  return p.value.constraint === null || p.value.constraint.length === 0;
}

function mapMetavariableConstraint(
  metavariable: string,
  c: MetavariableConstraint,
  onAdvancedSwitch: boolean
): OutConstraint {
  switch (c.value.kind) {
    case "regex":
      return {
        "metavariable-regex": { metavariable, regex: c.value.regex },
      };
    case "pattern": {
      // This logic is a bit more complex because it's unclear what
      // fields should be under the metavariable-pattern, because
      // there's lots of different possibilities.
      // To reflect this in the type system properly, we just let there
      // be a mapping from any string literal to the various fields within
      // an OutPattern, which is only four things as of now.
      const innerPattern = mapPatternToStringMaybe(
        c.value.pattern,
        onAdvancedSwitch
      );
      let metavariablePatternInner: {
        metavariable: string;
        [
          key: string
        ]: // may need to extend this if OutPattern gets more variants!
        string | OutPattern | (OutPattern | OutConstraint)[] | OutPattern[];
      };
      if (typeof innerPattern === "string") {
        metavariablePatternInner = {
          metavariable,
          pattern: innerPattern,
        };
      } else {
        // If the inner thing is actually an object, let's just takes its fields.
        metavariablePatternInner = {
          metavariable,
          ...innerPattern,
        };
      }
      return {
        "metavariable-pattern": metavariablePatternInner,
      };
    }
    case "analyzer":
      return {
        "metavariable-analysis": {
          metavariable,
          analyzer: c.value.analyzer,
        },
      };
    case "type":
      return {
        "metavariable-type": {
          metavariable,
          type: c.value.type,
        },
      };
  }
}

function mapConstraint(
  c: PatternConstraint,
  onAdvancedSwitch: boolean
): OutConstraint[] {
  const pc = c.value;
  switch (pc.kind) {
    case "focus":
      if (pc.metavariables.length === 1) {
        return [{ "focus-metavariable": pc.metavariables[0] }];
      }
      return [{ "focus-metavariable": pc.metavariables }];
    case "comparison":
      return [
        {
          "metavariable-comparison": {
            comparison: pc.comparison,
          },
        },
      ];
    case "metavariable":
      return pc.metavariableConstraints.map((mc) =>
        mapMetavariableConstraint(pc.metavariable, mc, onAdvancedSwitch)
      );
  }
}

const mapPatternToStringMaybe = (
  pattern: Pattern,
  onAdvancedSwitch: boolean
): OutPattern | string => {
  const rawPattern = pattern.value.rawPattern.value;
  if (rawPattern.kind === "pattern") {
    return rawPattern.pattern;
  }
  return mapPattern(pattern, onAdvancedSwitch);
};

const mapPattern = (
  pattern: Pattern,
  onAdvancedSwitch: boolean
): OutPattern => {
  const rawPattern = pattern.value.rawPattern.value;

  const constraints = pattern.value.constraint;

  let basePattern: OutPattern;
  const kind = rawPattern.kind;
  switch (kind) {
    case "pattern": {
      const pattern: string = rawPattern.pattern;
      basePattern = { pattern: pattern };
      break;
    }
    case "regex": {
      const regex: string = rawPattern.regex;
      basePattern = { "pattern-regex": regex };
      break;
    }
    case "any": {
      basePattern = {
        "pattern-either": rawPattern.patterns.flatMap((p) =>
          p.isDisabled && !onAdvancedSwitch
            ? []
            : [mapPattern(p, onAdvancedSwitch)]
        ),
      };
      break;
    }
    case "all": {
      basePattern = {
        patterns: rawPattern.patterns.flatMap((p) =>
          p.isDisabled && !onAdvancedSwitch
            ? []
            : [mapPattern(p, onAdvancedSwitch)]
        ),
      };
      break;
    }
    case "inside": {
      basePattern = {
        "pattern-inside": mapPatternToStringMaybe(
          rawPattern.pattern,
          onAdvancedSwitch
        ),
      };
      break;
    }
    case "not": {
      if (isPatternWithKind("pattern", rawPattern.pattern)) {
        basePattern = {
          "pattern-not": rawPattern.pattern.value.rawPattern.value.pattern,
        };
        // We must check for the constraints being empty here, because it would be invalid
        // to translate
        // not:
        //  inside: C
        //  where:
        //  - B
        // where:
        // - A
        // because the constraints happen interleaved with the operators
      } else if (
        isPatternWithKind("inside", rawPattern.pattern) &&
        hasEmptyConstraint(rawPattern.pattern)
      ) {
        basePattern = {
          "pattern-not-inside": mapPatternToStringMaybe(
            rawPattern.pattern.value.rawPattern.value.pattern,
            onAdvancedSwitch
          ),
        };
      } else if (
        isPatternWithKind("regex", rawPattern.pattern) &&
        hasEmptyConstraint(rawPattern.pattern)
      ) {
        basePattern = {
          "pattern-not-regex": rawPattern.pattern.value.rawPattern.value.regex,
        };
      } else {
        basePattern = {
          "pattern-not": mapPatternToStringMaybe(
            rawPattern.pattern,
            onAdvancedSwitch
          ),
        };
      }
      break;
    }
  }

  if (constraints && constraints.length > 0) {
    const newConstraints = constraints.flatMap((c) =>
      mapConstraint(c, onAdvancedSwitch)
    );
    if ("patterns" in basePattern) {
      basePattern.patterns.push(...newConstraints);
    } else {
      basePattern = { patterns: [basePattern, ...newConstraints] };
    }
  }

  return basePattern;
};

export const toYAML = (
  rule: Rule,
  onAdvancedSwitch: boolean = false
): string => {
  const prepStringify = (rule: Rule) => {
    const kind = rule.value.kind;
    switch (kind) {
      case "search":
        return {
          id: rule.ruleID,
          languages: [rule.language],
          severity: writeMatchSeverity(rule.severity),
          message: rule.message,
          ...mapPattern(rule.value.pattern, onAdvancedSwitch),
        };
      case "taint": {
        const taint: { [key: string]: any } = {};
        if (rule.value.sources?.length > 0) {
          taint["pattern-sources"] = rule.value.sources.map((p) =>
            mapPattern(p, onAdvancedSwitch)
          );
        }
        if (rule.value.sinks?.length > 0) {
          taint["pattern-sinks"] = rule.value.sinks.map((p) =>
            mapPattern(p, onAdvancedSwitch)
          );
        }
        if (rule.value.sanitizers?.length > 0) {
          taint["pattern-sanitizers"] = rule.value.sanitizers.map((p) =>
            mapPattern(p, onAdvancedSwitch)
          );
        }
        taint["mode"] = "taint";
        // const propagators = rule.value.propagators.map((p) => yamlify(p));
        return {
          id: rule.ruleID,
          languages: [rule.language],
          severity: writeMatchSeverity(rule.severity),
          message: rule.message,
          ...taint,
          // propagators: propagators,
        };
      }
      default:
        throw new Error("Invalid rule kind");
    }
  };

  const ruleObj: { [key: string]: any } = prepStringify(rule);
  // we have to do this kind of contrived set-up because we don't want to set
  // the fix or options fields if it is empty
  if (rule.fix !== null) {
    ruleObj["fix"] = rule.fix;
  }

  // Here, we restore all the fields in `extras`, which are the fields
  // of the original rule that we didn't know how to interpret.
  if (rule.extra !== null) {
    for (const [key, value] of rule.extra.entries()) {
      // This needs to be toJS, because these values are MobX observables,
      // which are not quite objects.
      // YAML.stringify behaves erratically on those, so we switch them
      // back to normal JS values.l
      ruleObj[key] = toJS(value);
    }
  }

  const ruleString = YAML.stringify({ rules: [ruleObj] });
  return ruleString;
};

// only for testing purposes
export const toOutPattern = (pattern: Pattern): OutPattern => {
  return mapPattern(pattern, false);
};
