import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GradientTransparencyWrapper } from "./GradientTransparencyWrapper";
import { SupplyChainCard } from "./SupplyChainCard";

export function LockFileCard({
  name,
  version,
  vulnerable,
  direction,
  onClick,
  isActive,
}: {
  name: string;
  version: string;
  vulnerable?: boolean;
  direction?: "top" | "bottom";
  onClick: () => void;
  isActive: boolean;
}) {
  return (
    <GradientTransparencyWrapper
      id={name}
      direction={direction}
      onClick={onClick}
    >
      <SupplyChainCard clickable={!isActive}>
        <div>{name}</div>
        <div>
          {vulnerable ? (
            <FontAwesomeIcon
              fixedWidth
              icon={faWarning}
              color="#aaa"
              style={{ marginRight: "4px" }}
            />
          ) : null}
          {version}
        </div>
      </SupplyChainCard>
    </GradientTransparencyWrapper>
  );
}
