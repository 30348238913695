export const TECHNOLOGIES = [
  "django",
  "docker",
  "dockerfile",
  "flask",
  "gorilla",
  "kubernetes",
  "nginx",
  "react",
  "terraform",
];
export const NO_TECH = "--no-technology--";
export const ALL_TECHNOLOGIES = [NO_TECH, ...TECHNOLOGIES];
export const CATEGORIES = [
  "best-practice",
  "correctness",
  "maintainability",
  "performance",
  "portability",
  "security",
]; // + dependencies (once we have rules for dependencies)

export const NO_CATEGORY = "--no-category--";
export const ALL_CATEGORIES = [NO_CATEGORY, ...CATEGORIES];

export const SECURITY_SUBCATEGORIES = ["vuln", "audit", "secure default"];
