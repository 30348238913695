import { FC } from "react";

import { Confidence } from "@shared/types";

import styles from "./ConfidenceIcon.module.css";

interface Props {
  confidence: Confidence;
  variant: "light" | "dark" | "active";
}

export const ConfidenceIcon: FC<Props> = ({ confidence, variant }) => {
  type Color = "primary" | "secondary";

  const generateClasses = () => {
    let colors: Color[];

    if (confidence === "High") {
      colors = ["primary", "primary", "primary"];
    } else if (confidence === "Medium") {
      colors = ["primary", "primary", "secondary"];
    } else {
      colors = ["primary", "secondary", "secondary"];
    }

    return colors.map((color: Color) => {
      return styles[`${variant}-${color}`];
    });
  };

  const barClasses = generateClasses();

  // This is a custom SVG made in Figma, not a Font Awesome icon
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 210">
        <path
          id="Line 52 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 5.5C2.82843 5.5 3.5 6.17157 3.5 7L3.5 10C3.5 10.8284 2.82843 11.5 2 11.5C1.17157 11.5 0.5 10.8284 0.5 10L0.5 7C0.5 6.17157 1.17157 5.5 2 5.5Z"
          className={barClasses[0]}
        />
        <path
          id="Line 53 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 3.5C6.82843 3.5 7.5 4.17157 7.5 5V10C7.5 10.8284 6.82843 11.5 6 11.5C5.17157 11.5 4.5 10.8284 4.5 10V5C4.5 4.17157 5.17157 3.5 6 3.5Z"
          fill="#364FC7"
          className={barClasses[1]}
        />
        <path
          id="Line 54 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0.5C10.8284 0.5 11.5 1.17157 11.5 2V10C11.5 10.8284 10.8284 11.5 10 11.5C9.17157 11.5 8.5 10.8284 8.5 10V2C8.5 1.17157 9.17157 0.5 10 0.5Z"
          className={barClasses[2]}
        />
      </g>
    </svg>
  );
};
