import React, { ChangeEvent, FormEvent, useState } from "react";
import uniq from "lodash/uniq";
import { createGlobalStyle } from "styled-components";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Center,
  CloseButton,
  Flex,
  Group,
  MultiSelect,
  Select,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";

import { FilterMultiselect } from "@shared/components";
import {
  ALL_CATEGORIES,
  NO_CATEGORY,
  SECURITY_SUBCATEGORIES,
  TECHNOLOGIES,
} from "@shared/constants";
import { RegistryRuleMetadata } from "@shared/types";
import {
  ALL_LANGUAGES,
  OWASP_2017,
  OWASP_2021,
  owaspKeyToString,
} from "@shared/utils";

import { CWE_NAMES } from "../../constants";
import CWE_DESCRIPTIONS from "../../constants/cwe-descriptions.json";

/*
example output:

metadata:
    owasp: 'A1: Injection'
    cwe: "CWE-22: Improper Limitation of a Pathname to a Restricted Directory ('Path Traversal')"
    source-rule-url: https://find-sec-bugs.github.io/bugs.htm#PATH_TRAVERSAL_IN
    references:
    - https://www.owasp.org/index.php/Path_Traversal
    category: security
    technology:
    - jax-rs
*/

// ~/semgrep-rules (develop)> rg --no-line-number --no-filename category: | sort | uniq
/* const rule_categories: string[] = [
   "correctness",
   "security",
 "best-practice",
 "caching",
 "compatibility",
 "correctness",
 "maintainability",
 "performance",
 "portability",
] */

const MetadataPopoversOverrideStyle = createGlobalStyle`
  .mantine-ScrollArea-viewport>div {
    display: block !important;
  }
`;

const ellipsize = (s: string | undefined, charCount: number): string => {
  /**
   * Return a string with at most charCount characters, where the last three
   * chars are `...` if any of the original string was trimmed.
   */
  if (s === undefined) return "";
  if (s.length >= charCount - 3) {
    return s.slice(0, charCount - 3) + "...";
  } else {
    return s.slice(0, charCount);
  }
};

const dropUndefinedKeys = (o: Object) => {
  return Object.fromEntries(
    Object.entries(o).filter(([_, v]) => v !== undefined)
  );
};

const hackyCweLabelToId = (label: string): string => {
  // Look for the CWE id in the label and fallback to using the full label if
  // we can't find the id.
  const numStr = label.match(/CWE (\d+): /);
  return numStr && numStr.length >= 2 && numStr[1] ? numStr[1] : label;
};

const cweSortFunction = (a: string, b: string) => {
  const intA = parseInt(hackyCweLabelToId(a));
  const intB = parseInt(hackyCweLabelToId(b));

  return intA && intB ? intA - intB : intA || a > b ? 1 : -1;
};

function createCweLabel(id: string) {
  const description = CWE_DESCRIPTIONS[id as keyof typeof CWE_DESCRIPTIONS];
  // If we can't find the name, use an ellipsized version of the description.
  const name = CWE_NAMES[parseInt(id)] || ellipsize(description, 200);
  return parseInt(id) ? `CWE-${parseInt(id)}: ${name || id}` : id;
}

interface IRuleMetadataEditorProps {
  metadata: RegistryRuleMetadata;
  setMetadata: (_: RegistryRuleMetadata) => void;
  message: string;
  setMessage: (s: string) => void;
  ruleId: string;
  setRuleId: (s: string) => void;
  languages: string[];
  setLanguages: (s: string[]) => void;
  showOptional: boolean;
}

// for confidence, likelihood, impact
const HIGH_MED_LOW = ["HIGH", "MEDIUM", "LOW"];

export const RuleMetadataEditor: React.FC<IRuleMetadataEditorProps> = ({
  metadata,
  setMetadata,
  message,
  setMessage,
  ruleId,
  setRuleId,
  languages,
  setLanguages,
}) => {
  // we have to add runtime type checks and be conservative, because the user may not have conformed to
  // our expected schema while editing metadata in the YAML
  const selectedCategoryIsSecurity: boolean = metadata?.category === "security";

  const setMetadataSafe = (meta: RegistryRuleMetadata) => {
    /**
     * In this component, we use undefined exclusively to represent "no value."
     * But when the `metadata` is converted to YAML, it will add the keys that
     * have undefined value in the output YAML as key: null. So we want to drop
     * undefined keys entirely from the object, rather than setting them to
     * `undefined`.
     */
    setMetadata(dropUndefinedKeys(meta));
  };

  const setCategory = (category: string | null) => {
    if (category) {
      if (category === NO_CATEGORY) {
        setMetadataSafe({ ...metadata, category: undefined });
      } else {
        setMetadata({ ...metadata, category });
      }
    }
  };
  const setCWE = (cwe: string[]) => {
    setMetadata({ ...metadata, cwe });
  };
  const setOwasp = (owasp: string[]) => {
    setMetadata({ ...metadata, owasp });
  };
  const setTechnology = (technology: string[]) => {
    if (technology[0] === undefined) {
      setMetadataSafe({ ...metadata, technology: undefined });
    } else {
      setMetadata({ ...metadata, technology });
    }
  };
  const setSourceRuleUrl = (url: string) => {
    if (url === "") {
      setMetadataSafe({
        ...metadata,
        source_rule_url: undefined,
      });
    } else {
      setMetadata({
        ...metadata,
        source_rule_url: url,
      });
    }
  };
  const setReferences = (refs: string[]) => {
    if (refs.length === 0) {
      setMetadataSafe({ ...metadata, references: undefined });
    } else {
      setMetadata({
        ...metadata,
        references: refs,
      });
    }
  };

  const category =
    metadata.category && typeof metadata.category === "string"
      ? metadata.category
      : undefined;
  const cwes =
    typeof metadata.cwe === "string"
      ? [metadata.cwe]
      : Array.isArray(metadata.cwe)
      ? metadata.cwe
      : [];
  const owasps =
    typeof metadata.owasp === "string"
      ? [metadata.owasp]
      : Array.isArray(metadata.owasp)
      ? metadata.owasp
      : [];
  const technologies =
    metadata.technology && Array.isArray(metadata.technology)
      ? metadata.technology
      : [];
  const references: string[] =
    metadata.references !== undefined && Array.isArray(metadata.references)
      ? metadata.references
      : [];

  // Merge the list of ALL_LANGUAGES with the list of selected languages,
  // deduplicate it, and then sort it.
  const [languageData, setLanguageData] = useState(
    uniq([
      ...Object.values(ALL_LANGUAGES).map((lang) => lang.id),
      ...languages,
    ]).sort()
  );
  const [cweData, setCweData] = useState(
    uniq([...Object.keys(CWE_DESCRIPTIONS).map(createCweLabel), ...cwes]).sort(
      cweSortFunction
    )
  );
  const [owaspData, setOwaspData] = useState(
    uniq([
      ...OWASP_2021.map((key, i) => owaspKeyToString(`${i + 1}:2021`) || ""),
      ...OWASP_2017.map((key, i) => owaspKeyToString(`${i + 1}:2017`) || ""),
      ...owasps,
    ])
  );
  const [technologyData, setTechnologyData] = useState(
    uniq([...TECHNOLOGIES, ...technologies]).sort()
  );

  return (
    <>
      <MetadataPopoversOverrideStyle />
      <TextInput
        error={ruleId ? "" : "Required"}
        label="Rule ID"
        onChange={(e: FormEvent<HTMLInputElement>) =>
          setRuleId(e.currentTarget.value)
        }
        value={ruleId}
      />
      <FilterMultiselect
        clearable
        creatable
        data={languageData.map((lang) => ({ value: lang, label: lang }))}
        error={languages?.length ? "" : "Required"}
        label="Languages"
        style={{ marginTop: 8 }}
        onChange={setLanguages}
        onCreate={(query) => {
          if (query === "") return;
          setLanguageData((current) => [...current, query].sort());
          return query;
        }}
        placeholder="Search..."
        searchable
        value={languages}
        defaultPillStyles
      />
      <Select
        data={ALL_CATEGORIES}
        error={category ? "" : "Required"}
        label="Category"
        mt="xs"
        nothingFoundMessage="No options"
        allowDeselect={false}
        onChange={setCategory}
        placeholder="Select category"
        searchable
        value={category}
        data-fs-element="Rule category select"
      />
      {selectedCategoryIsSecurity && (
        <MultiSelect
          data={SECURITY_SUBCATEGORIES}
          // dropdownPosition="bottom"
          label="Subcategory"
          error={metadata.subcategory?.length ? "" : "Required"}
          mt="xs"
          onChange={(subcategory: string[]) =>
            setMetadata({ ...metadata, subcategory })
          }
          placeholder="Select subcategory"
          value={metadata.subcategory}
        />
      )}
      <Textarea
        error={message?.length ? "" : "Required"}
        label={
          <span>
            Message{" "}
            <Tooltip
              withArrow
              withinPortal
              label="You can use metavariables (like $FOO) in the message"
            >
              <FontAwesomeIcon icon={faQuestionCircle} size="xs" />
            </Tooltip>
          </span>
        }
        mt="xs"
        onChange={(e: FormEvent<HTMLTextAreaElement>) =>
          setMessage(e.currentTarget.value)
        }
        value={message}
        data-testid="RuleMessage"
      />
      {selectedCategoryIsSecurity && (
        <>
          <FilterMultiselect
            clearable
            creatable
            data={cweData.map((cwe) => ({ value: cwe, label: cwe }))}
            error={cwes?.length ? "" : "Required"}
            label="CWE"
            style={{
              marginTop: 8,
            }}
            onChange={setCWE}
            onCreate={(query) => {
              if (query === "") return;

              setCweData((current) =>
                [...current, query].sort(cweSortFunction)
              );
              return query;
            }}
            placeholder="Search..."
            searchable
            defaultPillStyles
            value={cwes}
          />
          <Group mt="xs" wrap="nowrap" align="flex-start">
            <Select
              data={HIGH_MED_LOW}
              // dropdownPosition="bottom"
              error={metadata.confidence ? "" : "Required"}
              label="Confidence"
              mt="xs"
              allowDeselect={false}
              onChange={(confidence: string | null) =>
                confidence && setMetadata({ ...metadata, confidence })
              }
              placeholder="Select confidence"
              value={metadata.confidence}
              data-fs-element="Rule confidence select"
            />
            <Select
              data={HIGH_MED_LOW}
              // dropdownPosition="bottom"
              error={metadata.likelihood ? "" : "Required"}
              label="Likelihood"
              mt="xs"
              allowDeselect={false}
              onChange={(likelihood: string | null) =>
                likelihood && setMetadata({ ...metadata, likelihood })
              }
              placeholder="Select likelihood"
              value={metadata.likelihood}
              data-fs-element="Rule likelihood select"
            />
            <Select
              data={HIGH_MED_LOW}
              // dropdownPosition="bottom"
              error={metadata.impact ? "" : "Required"}
              label="Impact"
              mt="xs"
              allowDeselect={false}
              onChange={(impact: string | null) =>
                impact && setMetadata({ ...metadata, impact })
              }
              placeholder="Select impact"
              value={metadata.impact}
              data-fs-element="Rule impact select"
            />
          </Group>
          <FilterMultiselect
            clearable
            creatable
            data={owaspData.map((owasp) => ({ value: owasp, label: owasp }))}
            label="OWASP"
            style={{
              marginTop: 8,
            }}
            onChange={setOwasp}
            onCreate={(query) => {
              if (query === "") return;
              setOwaspData((current) => [...current, query].sort());
              return query;
            }}
            placeholder="Search..."
            searchable
            value={owasps}
            defaultPillStyles
          />
        </>
      )}

      <FilterMultiselect
        clearable
        creatable
        data={technologyData.map((tech) => ({ value: tech, label: tech }))}
        error={technologies?.length ? "" : "Required"}
        label="Technologies"
        style={{ marginTop: 8 }}
        onChange={setTechnology}
        onCreate={(query) => {
          if (query === "") return;
          setTechnologyData((current) => [...current, query].sort());
          return query;
        }}
        placeholder="Search..."
        searchable
        value={technologies}
        defaultPillStyles
      />
      <TextInput
        label="Rule source URL"
        onChange={(e) => setSourceRuleUrl(e.currentTarget.value)}
        mt="xs"
        placeholder="https://example.com"
        value={metadata.source_rule_url ?? ""}
      />
      <label
        style={{
          display: "block",
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1.55,
          marginTop: 10,
        }}
      >
        Reference URLs
        {references.length === 0 && (
          <Text c="red" size="xs">
            Add reference URLs to help others understand the context of this
            rule
          </Text>
        )}
        {references.map((ref, i) => {
          return (
            <Flex key={i}>
              <TextInput
                key={i}
                mt={2}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setReferences([
                    ...references.slice(0, i),
                    e.currentTarget.value,
                    ...references.slice(i + 1),
                  ])
                }
                placeholder="https://example.com"
                style={{ flexGrow: 1 }}
                value={ref}
              />
              <Center inline>
                <CloseButton
                  ml={2}
                  onClick={() =>
                    setReferences([
                      ...references.slice(0, i),
                      ...references.slice(i + 1),
                    ])
                  }
                />
              </Center>
            </Flex>
          );
        })}
      </label>

      <div>
        <Button
          c="blue"
          leftSection={<FontAwesomeIcon icon={faPlus} />}
          mt={4}
          onClick={() => {
            setReferences([...references, "https://example.com"]);
          }}
          variant="subtle"
        >
          Add URL
        </Button>
      </div>
    </>
  );
};
