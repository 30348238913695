import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text } from "@mantine/core";

import { Code, ConfirmationModal } from "@shared/components";

import { WorkbenchContext } from "../providers";

const ConfirmOverwrite = () => {
  const { workbench } = useContext(WorkbenchContext);

  const overwriteAndClose = () => {
    // Trigger the save method with explicit acknowledgment of overwrite
    workbench.saveBundle(true);
    workbench.ui.closeConfirmOverwriteAlert();
  };

  return (
    <ConfirmationModal
      onConfirm={overwriteAndClose}
      onCancel={workbench.ui.closeConfirmOverwriteAlert}
      opened={workbench.ui.isConfirmOverwriteAlertOpen}
      confirmButtonText={"Overwrite"}
      confirmButtonColor={"yellow"}
    >
      <FontAwesomeIcon
        display="inline-block"
        icon={faArrowsRotate}
        size="3x"
        style={{ verticalAlign: "top" }}
      />
      <Text display="inline-block" ml={16}>
        <p>
          Are you sure you want to overwrite{" "}
          <Code inline light>
            {`${workbench?.org?.name}:${workbench.bundle?.rule?.id}`}
          </Code>{" "}
          with your current rule?
        </p>
        <p>
          This is a destructive operation and will remove your previous rule.
        </p>
      </Text>
    </ConfirmationModal>
  );
};

export const ConfirmOverwriteAlert = observer(ConfirmOverwrite);
