import React, { FC } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";

// Nx doesn't like that these libraries are both statically imported and dynamically imported in the same file.
// It's probably correct and we should address this at some point, but for now we'll disable the rule for the offending imports.
// TODO: Remove this eslint disable once we've addressed the static import issue
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  AppCrashFallback,
  GlobalHelmet,
  GlobalStyles,
  LoadingScreen,
  Tracking,
} from "@shared/components";
import { useSyncOrg } from "@shared/hooks";

import { AppProvider } from "./providers/AppProvider";
import { AppRoutes } from "./routes/AppRoutes";

import "@mantine/core/styles.layer.css";
import "@mantine/spotlight/styles.layer.css";
import "@mantine/code-highlight/styles.layer.css";
import "@mantine/notifications/styles.layer.css";
import "@mantine/dates/styles.layer.css";
import "@mantine/charts/styles.layer.css";
import "mantine-contextmenu/styles.layer.css";

if (process.env.NODE_ENV === "development") {
  import("@locator/runtime").then(({ setup }) => {
    setup({});
  });
}

/**
 * We add the react-router outside the App element, so that calling
 * history.go(0) re-renders the whole <App />.
 *
 */
const App: FC = () => {
  useSyncOrg();

  return (
    <Router>
      <GlobalStyles />
      <GlobalHelmet />
      <AppProvider>
        <Route path="/">
          <Tracking />
        </Route>
        <React.Suspense fallback={<LoadingScreen />}>
          <AppRoutes />
        </React.Suspense>
      </AppProvider>
    </Router>
  );
};

export default Sentry.withErrorBoundary(App, {
  showDialog: true,
  fallback: (props) => <AppCrashFallback {...props} />,
});
