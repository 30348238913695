import { useContext } from "react";
import { action } from "mobx";
import { observer } from "mobx-react-lite";

import { DEFAULT_MONACO_OPTIONS, MonacoEditor } from "@vendors/monaco";

import { DEFAULT_RULE_FILE_PATH } from "../../constants";
import { WorkbenchContext } from "../../providers/workbenchContext";
import { EditorWrapper } from "../EditorWrapper";
import { toYAML } from "../StructureMode/utils/toYAML";

const Editor = () => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle, editors } = workbench;

  if (bundle === null) return null;

  const { ruleText, setRuleText } = bundle;
  const text = bundle.structureRule
    ? toYAML(bundle.structureRule, true)
    : ruleText;

  return (
    <EditorWrapper id="rule-editor">
      <MonacoEditor
        value={text}
        onChange={action((value) => {
          if (value !== undefined) {
            setRuleText(value);
            editors.onRuleEditorChange(value);
          }
        })}
        onMount={editors.onRuleEditorMount}
        language="yaml"
        path={DEFAULT_RULE_FILE_PATH}
        options={{
          ...DEFAULT_MONACO_OPTIONS,
          readOnly: !workbench.doesUserHaveEditPermission,
        }}
      />
    </EditorWrapper>
  );
};

export const RuleEditor = observer(Editor);
