import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import {
  faCabinetFiling,
  faCodeBranch,
  faExternalLinkSquare,
  faFolders,
  faLink,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/react";

import {
  deleteOrgPrivateRule,
  deleteUserRule,
  fetchCustomRules,
  fetchMultitypeRulesets,
  fetchRule,
  fetchRulesetRulePaths,
  fetchRulesetWithDefinitions,
} from "@shared/api/lib/registry";
import { Permission } from "@shared/types";
import { Rule, Ruleset, RulesetRulePath, ShallowRule } from "@shared/types";
import { getOrgSlug, notNull } from "@shared/utils";

import { NodeLabel } from "../components/FileBrowser/NodeLabel";
import { BundleAddressString } from "../types";

import { TreeNode } from "./Tree";
import { Workbench } from "./Workbench";

const OFFICIAL_REGISTRY_URL = "https://github.com/semgrep/semgrep-rules/";

/**
 * A node in the file tree. Each node maps to one line in the file browser UI. This line can be a folder or a file.
 */
abstract class Node {
  /**
   * A reference to the workbench object.
   * You can always access it for instance to get the current user.
   */
  abstract workbench: Workbench;

  /**
   * A reference to the root of the tree.
   * You can always access it for instance to get global info such as the base URL.
   */
  abstract root: FileRoot;

  /**
   * A reference to the folder this node is in. This is null if the node is in the root.
   */
  abstract parent: Folder | null;

  /**
   * The number of files contained in this folder or in subfolders. If -1,
   * the contents are still loading or otherwise invalid.
   */
  abstract get numContainedFiles(): number;

  /**
   * React to a click on this node.
   */
  abstract onClick(): void;

  /**
   * A representation of this node that Mantine's <List /> component understands.
   *
   * @example
   * ```
   * const folder = {
   *   ...,
   *   childNodes: [file.node()]
   * }
   * ```
   */
  abstract get node(): TreeNode;

  /**
   * Whether the current node matches the root's search query.
   */
  abstract get matchesSearchQuery(): boolean;

  constructor() {
    makeObservable(this, {
      depth: computed,
    });
  }

  /**
   * Return the amount of parents this node has.
   */
  get depth(): number {
    let result = 0;
    let { parent } = this;
    while (parent) {
      result++;
      parent = parent.parent;
    }
    return result;
  }
}

abstract class File extends Node {
  onClick() {
    this.workbench.fetchBundle(this.bundleAddress);
  }

  get numContainedFiles(): number {
    return 1;
  }

  /**
   * Bundle address url parameter
   *
   * @example
   * For `/orgs/-/editor/r/full.path.to.your.rule`:
   * ```
   * r/full.path.to.your.rule
   * ```
   *
   * For `/orgs/-/editor/s/namespace:ruleName`:
   * ```
   * s/namespace:ruleName
   * ```
   * @example
   * Here's how you'd use this to navigate to this rule in the workbench:
   * ```
   * this.workbench.fetchBundle(this.bundleAddress);
   * ```
   */
  abstract get bundleAddress(): BundleAddressString;

  /**
   * The relative url of this file.
   *
   * For use when copying url or opening rule in new tab. Do not use to
   * navigate to new rule (see `bundleAddress` above)
   */
  abstract get url(): string;

  /**
   * Whether this is the currently selected file. This is used to highlight the file in the file browser.
   */
  get isSelected(): boolean {
    return this.bundleAddress === this.root.workbench.addressString;
  }

  constructor() {
    super();
    makeObservable(this, {
      isSelected: computed,
      onClick: action.bound,
    });
  }
}

abstract class Folder extends Node {
  /**
   * Whether this folder was expanded by the user in the file browser.
   */
  isUserExpanded: boolean = false;

  constructor() {
    super();
    makeObservable(this, {
      isUserExpanded: observable,
      onClick: action.bound,
      childrenNodes: computed,
      isExpanded: computed,
    });
  }

  /**
   * All child nodes of this folder. These can be files or subfolders.
   */
  abstract get children(): Node[];

  /**
   * Whether child nodes should be visible.
   * They are visible if the user expanded it, or if this folder matches a search.
   */
  get isExpanded(): boolean {
    return (
      this.isUserExpanded ||
      (this.root.isSearching &&
        this.matchesSearchQuery &&
        (this.numContainedFiles < 5 || this.depth < 1))
    );
  }

  onClick() {
    this.isUserExpanded = !this.isUserExpanded;
  }

  /**
   * Get child nodes in a format that Mantine's <List /> component understands.
   */
  get childrenNodes(): TreeNode[] {
    if (!this.isExpanded) {
      // Interactions can take multiple seconds if Mantine gets all children all
      // the time. A good test case for this is to clear the search query.
      return [];
    }
    return this.children
      .filter((node) => node.matchesSearchQuery)
      .map((node) => node.node);
  }

  get matchesSearchQuery(): boolean {
    return this.children.some((c) => c.matchesSearchQuery);
  }
}

export class SnippetFile extends File {
  workbench: Workbench;
  root: FileRoot;
  parent: SnippetNamespaceFolder;

  /**
   * The editor snippet this file represents.
   */
  snippet: Ruleset;

  constructor(parent: SnippetNamespaceFolder, snippet: Ruleset) {
    super();
    makeObservable(this, {
      bundleAddress: computed,
      url: computed,
      node: computed,
    });
    this.snippet = snippet;
    this.parent = parent;
    this.root = this.parent.root;
    this.workbench = this.root.workbench;
  }

  get bundleAddress(): BundleAddressString {
    return `s/${this.snippet.name}`;
  }

  get title(): string {
    return this.snippet.name.replace(`${this.parent.namespace}:`, "");
  }

  get url(): string {
    return `${this.root.workbench.baseUrl}/${this.bundleAddress}`;
  }

  delete(): void {
    deleteUserRule(this.snippet.name)
      .then(() => {
        this.root.snippets = this.root.snippets!.filter(
          (rootSnippet) => rootSnippet.id !== this.snippet.id
        );
      })
      .catch(this.workbench.handleApiError);
  }

  get node(): TreeNode {
    const labelType: "private-file" | "file" =
      this.snippet.visibility === "org_private" ? "private-file" : "file";
    return {
      id: this.snippet.id,
      label: (
        <NodeLabel
          title={this.title}
          type={labelType}
          searchQuery={this.root.searchQuery}
          onClick={this.onClick}
          isSelected={this.isSelected}
          contextMenuActions={[
            {
              actionName: "Open rule in new tab",
              action: (_) =>
                window.open(window.location.origin + this.url, "_blank"),
              icon: <FontAwesomeIcon fixedWidth icon={faExternalLinkSquare} />,
            },
            {
              actionName: "Copy link to rule",
              action: (_) =>
                navigator.clipboard.writeText(
                  window.location.origin + this.url
                ),
              icon: <FontAwesomeIcon fixedWidth icon={faLink} />,
            },
            ...(this.workbench.permissions.includes(Permission.editor_create)
              ? [
                  {
                    actionName: "Fork rule",
                    action: (_: React.MouseEvent) =>
                      this.root.workbench.forkRule(this.bundleAddress),
                    icon: <FontAwesomeIcon fixedWidth icon={faCodeBranch} />,
                  },
                ]
              : []),
            ...(this.workbench.permissions.includes(Permission.editor_delete)
              ? [
                  {
                    actionName: "Delete rule",
                    action: (_: React.MouseEvent) =>
                      this.workbench.ui.openConfirmDeleteAlert(this),
                    icon: <FontAwesomeIcon fixedWidth icon={faTrashAlt} />,
                  },
                ]
              : []),
          ]}
        />
      ),
      isSelected: this.isSelected,
    };
  }

  get matchesSearchQuery(): boolean {
    return this.snippet.name.includes(this.root.searchQuery);
  }
}

export class CustomRuleFile extends File {
  /*
   * Represents a custom rule added by users in their org.
   * Snippets are also custom rules, but they are represented by SnippetFile since they don't have a path and are exposed via rulesets.
   */

  workbench: Workbench;
  root: FileRoot;
  parent: SnippetNamespaceFolder;

  /**
   * The editor rule (without snippet) this file represents.
   */
  rule: ShallowRule;

  constructor(parent: SnippetNamespaceFolder, rule: ShallowRule) {
    super();
    makeObservable(this, {
      bundleAddress: computed,
      url: computed,
      node: computed,
    });
    this.rule = rule;
    this.parent = parent;
    this.root = this.parent.root;
    this.workbench = this.root.workbench;
  }

  get bundleAddress(): BundleAddressString {
    if (this.rule.meta.rule) {
      return `r/${this.rule.meta.rule.rule_id}/${this.rule.path ?? "-"}`;
    } else {
      return `r/${this.rulePath}`;
    }
  }

  get title(): string {
    return this.rulePath.split(".").slice(1).join(".");
  }

  get url(): string {
    return `${this.root.workbench.baseUrl}/${this.bundleAddress}`;
  }

  delete(): void {
    this.rule.meta.rule?.rule_id &&
      deleteOrgPrivateRule(this.rule.meta.rule.rule_id)
        .then(() => {
          this.root.customRules = this.root.customRules!.filter((rule) =>
            rule.path ? rule.path !== this.rule.path : true
          );
        })
        .catch(this.workbench.handleApiError);
  }

  /**
   * The registry rule path this file represents.
   */
  get rulePath(): string {
    // Path should always be defined, but it's not a verified fact
    return this.rule.path || this.rule.id;
  }

  get node(): TreeNode {
    const labelType: "private-file" | "file" =
      this.rule.visibility === "org_private" ? "private-file" : "file";
    return {
      id: this.rulePath,
      label: (
        <NodeLabel
          title={this.title}
          type={labelType}
          searchQuery={this.root.searchQuery}
          onClick={this.onClick}
          isSelected={this.isSelected}
          contextMenuActions={[
            {
              actionName: "Copy link to rule",
              action: () =>
                navigator.clipboard.writeText(
                  window.location.origin + this.url
                ),
              icon: <FontAwesomeIcon fixedWidth icon={faLink} />,
            },
            ...(this.workbench.permissions.includes(Permission.editor_create)
              ? [
                  {
                    actionName: "Fork rule",
                    action: (_: React.MouseEvent) =>
                      this.root.workbench.forkRule(this.bundleAddress),
                    icon: <FontAwesomeIcon fixedWidth icon={faCodeBranch} />,
                  },
                ]
              : []),
            ...(this.workbench.permissions.includes(Permission.editor_delete)
              ? [
                  {
                    actionName: "Delete rule",
                    action: (_: React.MouseEvent) =>
                      this.workbench.ui.openConfirmDeleteAlert(this),
                    icon: <FontAwesomeIcon fixedWidth icon={faTrashAlt} />,
                  },
                ]
              : []),
          ]}
        />
      ),
      isSelected: this.isSelected,
    };
  }

  get matchesSearchQuery(): boolean {
    return this.rulePath!.split(".")
      .slice(1)
      .join(".")
      .includes(this.root.searchQuery);
  }
}

class RegistryFile extends File {
  workbench: Workbench;
  root: FileRoot;
  parent: Folder;

  /**
   * The registry rule path this file represents.
   */
  rulePath: string;

  /**
   * Should the file render with full path as the name, or only the final section.
   */
  showFullPathLabel: boolean;

  constructor(parent: Folder, rulePath: string, showFullPathLabel = true) {
    super();
    makeObservable(this, {
      bundleAddress: computed,
      url: computed,
      node: computed,
    });
    this.rulePath = rulePath;
    this.parent = parent;
    this.root = this.parent.root;
    this.workbench = this.root.workbench;
    this.showFullPathLabel = showFullPathLabel;
  }

  get bundleAddress(): BundleAddressString {
    return `r/${this.rulePath}`;
  }

  get url(): string {
    return `${this.root.workbench.baseUrl}/${this.bundleAddress}`;
  }

  get node(): TreeNode {
    return {
      id: this.rulePath,
      label: (
        <NodeLabel
          title={
            this.showFullPathLabel
              ? this.rulePath
              : this.rulePath.split(".").pop()!
          }
          type="file"
          onClick={this.onClick}
          searchQuery={this.root.searchQuery}
          isSelected={this.isSelected}
          contextMenuActions={[
            {
              actionName: "Copy link to rule",
              action: (_) =>
                navigator.clipboard.writeText(
                  window.location.origin + this.url
                ),
              icon: <FontAwesomeIcon fixedWidth icon={faLink} />,
            },
            ...(this.workbench.permissions.includes(Permission.editor_create)
              ? [
                  {
                    actionName: "Fork rule",
                    action: (_: React.MouseEvent) =>
                      this.root.workbench.forkRule(this.bundleAddress),
                    icon: <FontAwesomeIcon fixedWidth icon={faCodeBranch} />,
                  },
                ]
              : []),
          ]}
        />
      ),
      isSelected: this.isSelected,
    };
  }

  get matchesSearchQuery(): boolean {
    return this.rulePath.includes(this.root.searchQuery);
  }
}

class LoadingPlaceholderFile extends File {
  workbench: Workbench;
  root: FileRoot;
  parent: Folder;

  constructor(parent: Folder) {
    super();
    makeObservable(this, {
      node: computed,
    });
    this.parent = parent;
    this.root = this.parent.root;
    this.workbench = this.root.workbench;
  }

  get node(): TreeNode {
    return {
      id: "loading",
      label: (
        <NodeLabel
          title={this.root.isSearching ? "Searching…" : "Loading…"}
          type="loading"
          isSelected={false}
        />
      ),
      disabled: true,
    };
  }

  get matchesSearchQuery(): boolean {
    return true;
  }

  get bundleAddress(): BundleAddressString {
    throw new Error("Cannot navigate to loading placeholder file");
  }

  get url(): string {
    throw new Error("Cannot navigate to loading placeholder file");
  }
}

class SnippetNamespaceFolder extends Folder {
  workbench: Workbench;
  root: FileRoot;
  parent = null;

  /**
   * The snippet namespace this folder displays.
   *
   * @example
   * To display all returntocorp:* snippets, set to `returntocorp`.
   * It will display all returntocorp.* private rules, as well.
   */
  namespace: string;

  constructor(root: FileRoot, namespace: string) {
    super();
    makeObservable(this, {
      children: computed,
      node: computed,
    });
    this.namespace = namespace;
    this.root = root;
    this.workbench = this.root.workbench;
  }

  get children(): Node[] {
    if (this.root.snippets === null || this.root.customRules === null)
      return [new LoadingPlaceholderFile(this)];

    const snippets = this.root.snippets
      .filter(
        (s) =>
          this.workbench.org !== undefined &&
          s.name.startsWith(`${this.namespace}:`)
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((s) => new SnippetFile(this, s));

    const customRules = this.root.customRules
      .slice()
      .sort((a, b) => a.id.localeCompare(b.id))
      .map((rule) => new CustomRuleFile(this, rule));

    return ([] as (CustomRuleFile | SnippetFile)[]).concat(
      snippets,
      customRules
    );
  }

  get numContainedFiles(): number {
    if (this.root.snippets === null || this.root.customRules === null)
      return -1;

    return this.children.filter((node) => node.matchesSearchQuery).length;
  }

  get node(): TreeNode {
    return {
      id: this.namespace,
      label: (
        <NodeLabel
          title={this.namespace}
          type={this.isExpanded ? "folder-open" : "folder-closed"}
          onClick={this.onClick}
          childCount={this.numContainedFiles}
          isLoading={this.root.snippets === null}
          isSelected={false}
        />
      ),
      hasCaret: false,
      isSelected: false,
      isExpanded: this.isExpanded,
      childNodes: this.childrenNodes,
    };
  }
}

class OfficialRulesetsFolder extends Folder {
  workbench: Workbench;
  root: FileRoot;
  parent = null;

  constructor(root: FileRoot) {
    super();
    makeObservable(this, {
      children: computed,
      node: computed,
    });
    this.root = root;
    this.workbench = this.root.workbench;
  }

  get children(): Node[] {
    if (this.root.rulesets === null) return [new LoadingPlaceholderFile(this)];
    return [...this.root.rulesets] // Shallow clone before sorting to avoid mutation
      .sort((a, b) => a.ruleset_name.localeCompare(b.ruleset_name))
      .map((ruleset) => new RulesetFolder(this, ruleset));
  }

  get numContainedFiles(): number {
    // since a single rule can be included in multiple ruleset "folders", summing up the rules
    // contained in the rulesets can double-count rules. As a workaround, this just returns from
    // the total number of rules that exist (even if a given rule is not in any ruleset, it's counted).
    if (this.root.rules === null) return -1;

    return this.root.rules?.filter(
      (rule) =>
        rule.path?.includes(this.root.searchQuery) &&
        rule.source_uri?.startsWith(OFFICIAL_REGISTRY_URL)
    ).length;
  }

  get node(): TreeNode {
    return {
      id: "official",
      label: (
        <NodeLabel
          title="official rulesets"
          type={this.isExpanded ? "folder-open" : "folder-closed"}
          childCount={this.numContainedFiles}
          onClick={this.onClick}
          isLoading={this.root.rulesets === null}
          isSelected={false}
          contextMenuActions={[
            {
              actionName: "Group by directory",
              action: (_) => this.root.setRulesView("repository"),
              icon: <FontAwesomeIcon fixedWidth icon={faFolders} />,
            },
          ]}
        />
      ),
      hasCaret: false,
      isExpanded: this.isExpanded,
      childNodes: this.childrenNodes,
    };
  }
}

class RulesetFolder extends Folder {
  workbench: Workbench;
  root: FileRoot;
  parent: Folder;

  /**
   * The ruleset that this folder represents.
   */
  ruleset: RulesetRulePath;

  constructor(parent: Folder, ruleset: RulesetRulePath) {
    super();
    makeObservable(this, {
      children: computed,
      node: computed,
    });
    this.parent = parent;
    this.root = this.parent.root;
    this.workbench = this.root.workbench;
    this.ruleset = ruleset;
  }

  get children(): Node[] {
    return [...this.ruleset.rule_paths] // Shallow clone before sorting to avoid mutation
      .sort((a, b) => a.localeCompare(b))
      .map((r) => new RegistryFile(this, r));
  }

  get numContainedFiles(): number {
    return this.ruleset.rule_paths.filter((path) =>
      path.includes(this.root.searchQuery)
    ).length;
  }

  get node(): TreeNode {
    return {
      id: this.ruleset.ruleset_name,
      label: (
        <NodeLabel
          title={this.ruleset.ruleset_name}
          type={this.isExpanded ? "folder-open" : "folder-closed"}
          onClick={this.onClick}
          childCount={this.numContainedFiles}
          isLoading={this.root.rulesets === null}
          isSelected={false}
        />
      ),
      hasCaret: false,
      isExpanded: this.isExpanded,
      childNodes: this.childrenNodes,
    };
  }
}

class RegistryRootFolder extends Folder {
  workbench: Workbench;
  root: FileRoot;
  parent = null;

  constructor(root: FileRoot) {
    super();
    makeObservable(this, {
      children: computed,
      node: computed,
    });
    this.root = root;
    this.workbench = this.root.workbench;
  }

  get children(): Node[] {
    if (this.root.rules === null) return [new LoadingPlaceholderFile(this)];

    const children = this.root.rules.filter(
      (r) =>
        r.source_uri?.startsWith(OFFICIAL_REGISTRY_URL) && r.path !== undefined
    );

    // Gather and deduplicate folder names out of all rules
    const childrenFolders = children
      .reduce((prev, current) => {
        const pathSegment = current.path!.split(".")[0]; // Root Index 0
        return prev.includes(pathSegment) ? prev : prev.concat(pathSegment);
      }, [] as string[])
      .map((pathSegment) => new RegistryFolder(this, pathSegment, 0))
      .sort((a, b) => a.name.localeCompare(b.name));

    // Assume that there are no individual rules at the root level
    return childrenFolders;
  }

  get numContainedFiles(): number {
    if (this.root.rules === null) return -1;

    return this.root.rules?.filter(
      (rule) =>
        rule.path?.includes(this.root.searchQuery) &&
        rule.source_uri?.startsWith(OFFICIAL_REGISTRY_URL)
    ).length;
  }

  get node(): TreeNode {
    return {
      id: "official_registry",
      label: (
        <NodeLabel
          title="Semgrep Registry"
          type={this.isExpanded ? "folder-open" : "folder-closed"}
          onClick={this.onClick}
          childCount={this.numContainedFiles}
          isLoading={this.root.rules === null}
          contextMenuActions={[
            {
              actionName: "Group by ruleset",
              action: (_) => this.root.setRulesView("ruleset"),
              icon: <FontAwesomeIcon fixedWidth icon={faCabinetFiling} />,
            },
          ]}
          isSelected={false}
        />
      ),
      hasCaret: false,
      childNodes: this.childrenNodes,
      isExpanded: this.isExpanded,
    };
  }
}

class RegistryFolder extends Folder {
  workbench: Workbench;
  root: FileRoot;

  /**
   * The registry folder path one segment "higher" in hierarchy.
   */
  parent: Folder;

  /**
   * The registry folder path, and the current index (for utility).
   * To preserve the unique property, pathSegment is the whole path
   * until the folder.
   */
  pathSegment: string;
  pathIndex: number;

  constructor(parent: Folder, pathSegment: string, pathIndex: number) {
    super();
    makeObservable(this, {
      children: computed,
      node: computed,
      name: computed,
    });
    this.parent = parent;
    this.root = this.parent.root;
    this.workbench = this.root.workbench;
    this.pathSegment = pathSegment;
    this.pathIndex = pathIndex;
  }

  get name(): string {
    return this.pathSegment.split(".")[this.pathIndex];
  }

  get eligibleRules(): Rule[] {
    if (this.root.rules === null) return [];
    return this.root.rules.filter(
      (r) =>
        r.source_uri?.startsWith(OFFICIAL_REGISTRY_URL) &&
        r.path !== undefined &&
        r.path.startsWith(this.pathSegment + ".") // add '.' to ensure we match full name
    );
  }

  get children(): Node[] {
    if (this.root.rules === null) return [];

    // Fairly complex children() function is here due to
    // the recursive nature of rules and their folders.

    // Some intense reducing incoming...
    const mappedRulePaths = this.eligibleRules.reduce(
      (container, rule) => {
        const pathSegments = rule.path!.split(".");
        // last 2 segments define a rule file, therefore we test:
        if (pathSegments[this.pathIndex + 3] === undefined) {
          // Might as well cast into files right here, to save a loop later
          container.files.push(new RegistryFile(this, rule.path!, false));
          return container;
        }

        // More than 2 segments mean there's a folder here, de-duplication:
        const pathSegment = pathSegments[this.pathIndex + 1];
        if (container.folders.includes(pathSegment)) return container;
        container.folders.push(pathSegment);
        return container;
      },
      { folders: [], files: [] } as {
        folders: string[];
        files: RegistryFile[];
      }
    );

    // Turn folder names into full folder segments:
    const mappedFolders =
      // Shallow clone before sorting to avoid mutation:
      [...mappedRulePaths.folders]
        .sort((a, b) => a.localeCompare(b))
        .map(
          (pathSegment) =>
            new RegistryFolder(
              this,
              this.pathSegment + "." + pathSegment,
              this.pathIndex + 1
            )
        );

    const mappedFiles = [...mappedRulePaths.files].sort((a, b) =>
      a.rulePath.localeCompare(b.rulePath)
    );

    return [...mappedFolders, ...mappedFiles];
  }

  get numContainedFiles(): number {
    // this mimics the above children() function, but does not
    // filter out rules that are in subfolders (and ignores subfolders)
    if (this.root.rules == null) return -1;
    return this.eligibleRules.filter((rule) =>
      rule.path!.includes(this.root.searchQuery)
    ).length;
  }

  get node(): TreeNode {
    return {
      id: this.pathSegment,
      label: (
        <NodeLabel
          title={this.name}
          searchQuery={this.root.searchQuery}
          type={this.isExpanded ? "folder-open" : "folder-closed"}
          onClick={this.onClick}
          childCount={this.numContainedFiles}
          isLoading={this.root.rules === null}
          isSelected={false}
        />
      ),
      hasCaret: false,
      isExpanded: this.isExpanded,
      childNodes: this.childrenNodes,
    };
  }
}

/**
 * A file tree that contains all synthetic folders and files
 * based off of the registry, rulesets, and user snippets.
 * The tree knows how to translate itself to a format
 * that Mantine's <List /> component understands.
 *
 * @remarks - When the tree is translated into a Mantine list,
 *            we track which nodes are expanded ourselves,
 *            and tell Mantine only about visible nodes,
 *            so that it doesn't block rendering for seconds.
 */
export class FileRoot {
  /**
   * Reference to the main Workbench instance.
   */
  workbench: Workbench;
  /**
   * All available rules from all registries, in a flat list.
   * It is the folders' responsibility to filter to the rules that are relevant to them.
   */
  rules: Rule[] | null = null;
  /**
   * Custom rules
   */
  customRules: Rule[] | null = null;
  /**
   * All snippets accessible to the current user, in a flat list.
   * It is the folders' responsibility to filter to the snippets that are relevant to them.
   */
  snippets: Ruleset[] | null = null;
  /**
   * All public rulesets, in a flat list.
   */
  rulesets: RulesetRulePath[] | null = null;
  /**
   * Search is implemented by removing all files that don't match the search string, and
   * by removing all non-root folders that are child-less after search (therefore would
   * be empty).
   * It is the folders'/childrens' responsibility to filter according to the search string.
   */
  searchQuery: string = "";
  /**
   * Determines what kind of root rule list to show:
   *     false - shows rules according to the registry
   *     true - shows rules according to official rulesets
   * The implementation is simplistic, due to no need of this kind of "config" otherwise.
   */
  isRulesetView: boolean = false;
  /**
   * The SnippetNamespaceFolder that is linked to the organization
   */
  namespaceFolder: SnippetNamespaceFolder | undefined = undefined;

  constructor(workbench: Workbench) {
    this.workbench = workbench;
    this.namespaceFolder = new SnippetNamespaceFolder(
      this,
      this.workbench.org ? getOrgSlug(this.workbench.org) : "my_organization"
    );

    reaction(
      () => this.workbench.org,
      (_) => {
        this.namespaceFolder = new SnippetNamespaceFolder(
          this,
          this.workbench.org
            ? getOrgSlug(this.workbench.org)
            : "my_organization"
        );
      }
    );

    makeObservable(this, {
      rules: observable,
      customRules: observable,
      snippets: observable,
      rulesets: observable,
      searchQuery: observable,
      isRulesetView: observable,
      children: computed,
      childrenNodes: computed,
      isSearching: computed,
      updateRules: action.bound,
      reloadSnippets: action.bound,
      reloadRulesets: action.bound,
      updateSearchQuery: action.bound,
      setRulesView: action.bound,
      namespaceFolder: observable,
    });
  }

  get children(): Node[] {
    return [
      this.namespaceFolder,
      this.isRulesetView
        ? new OfficialRulesetsFolder(this)
        : new RegistryRootFolder(this),
    ].filter(notNull);
  }

  /**
   * Get child nodes in a format that Mantine's <List /> component understands.
   */
  get childrenNodes(): TreeNode[] {
    return this.children.map((node) => node.node);
  }

  /**
   * Return true if anything anywhere in the tree matches the current search query.
   * This lets us know when there are zero results.
   */
  get matchesSearchQuery(): boolean {
    return this.children.some((c) => c.matchesSearchQuery);
  }

  /**
   * The base URL of the workbench.
   */
  get baseUrl(): string {
    return `/orgs/${
      this.workbench.org ? getOrgSlug(this.workbench.org) : "-"
    }/editor`;
  }

  /**
   * True if the user is currently searching via the UI.
   */
  get isSearching(): boolean {
    return this.searchQuery !== "";
  }

  async reloadSnippetsAndRulesets() {
    let transaction, span;
    try {
      transaction = Sentry.startTransaction({ name: "editor" });
      span = transaction.startChild({ op: "loadSnippetsAndRulesets" });
    } catch {
      transaction = null;
      span = null;
    }
    await Promise.all([
      this.reloadSnippets(),
      this.reloadRulesets(),
      this.reloadCustomRules(),
    ]);
    if (transaction && span) {
      span.finish();
      transaction.finish();
    }
  }

  updateRules(rules: Rule[] | null) {
    runInAction(() => {
      this.rules = rules;
    });
  }

  async reloadCustomRules() {
    if (!this.workbench.user) return;

    runInAction(() => {
      this.customRules = null;
    });
    const response = await fetchCustomRules(this.workbench.org!.id);
    runInAction(() => {
      this.customRules = response;
    });
  }

  async reloadSnippets() {
    if (!this.workbench.user) return;

    runInAction(() => {
      this.snippets = null;
    });
    const response = await fetchMultitypeRulesets(["unlisted", "org_private"]);
    runInAction(() => {
      this.snippets = response;
    });
  }

  async reloadSnippet(name: string) {
    runInAction(() => {
      if (!this.snippets) return;
      // Remove snippet-to-reload from cache
      this.snippets = this.snippets.filter((snippet) => snippet.name !== name);
    });
    const response = await fetchRulesetWithDefinitions(name);
    runInAction(() => {
      // Add reloaded snippet back to cache
      this.snippets = [...(this.snippets || []), response];
    });
  }

  async reloadRulesets() {
    runInAction(() => {
      this.rulesets = null;
    });
    const response = await fetchRulesetRulePaths();
    runInAction(() => {
      this.rulesets = response;
    });
  }

  getSnippet(name: string): Ruleset | null | undefined {
    if (this.snippets === null) return null;
    const [snippet] = this.snippets.filter((s) => s.name === name);
    return snippet;
  }

  async reloadCustomRule(id: string, path: string) {
    runInAction(() => {
      if (!this.customRules) return;
      // Remove snippet-to-reload from cache
      this.customRules = this.customRules.filter((rule) => rule.path !== path);
    });
    const response = await fetchRule(id);
    runInAction(() => {
      // Add reloaded snippet back to cache
      this.customRules = [...(this.customRules || []), response];
    });
  }

  getCustomRule(id_str: string): Rule | null | undefined {
    if (this.customRules === null) return null;
    const [rule] = this.customRules.filter(
      (r) => r.path === `${this.workbench.org?.name}.${id_str}`
    );
    return rule;
  }

  /**
   * Set search query for searching via the UI.
   */
  updateSearchQuery(newSearch: string) {
    this.searchQuery = newSearch;
  }

  setRulesView(rulesView: "repository" | "ruleset") {
    this.isRulesetView = rulesView === "ruleset";
  }
}
