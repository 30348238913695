import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCabinetFiling } from "@fortawesome/pro-regular-svg-icons";
import {
  faBook,
  faFileCode,
  faFileMagnifyingGlass,
  faFolderOpen,
  faGear,
  faKey,
  faLinkSimple,
  faMessageQuestion,
  faTableTree,
  faTelescope,
  faUserSecret,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartArea, faCode } from "@fortawesome/sharp-solid-svg-icons";

import {
  IssuesFilter as IssuesFilterProto,
  IssueTab as IssueTabProto,
  IssueType as IssueTypeProto,
} from "@protos/issues/v1/issues";
import { TimeLookbackRange, TimeLookbackType } from "@shared/types";
import { getTimeLookbackFilters, tlrToQueryParam } from "@shared/utils";

import { useBasePath } from "../useBasePath";
import { useFindingCounts } from "../useFindingCounts";
import { useOrg } from "../useOrg";
import { useRoutePermissionMap } from "../useRoutePermissionMap";
import { useSecretsPageLink } from "../useSecretsPageLink";
import { useTimeLookback } from "../useTimeLookback";

interface NavigationLinkProps {
  to?: string;
  search?: string;
  label: string;
  betaLabel?: boolean;
  active?: boolean;
  icon?: React.ReactNode;
  count?: number;
  routes?: NavigationLinkProps[];
  isChild?: boolean;
  menuOpen?: boolean;
  secondaryLink?: boolean;
  rightSection?: React.ReactNode;
  isExternalLink?: boolean;
  isDropdownMenuItem?: boolean;
  visible?: boolean;
  isHoverCardItem?: boolean;
  hideIcon?: boolean;
  children?: React.ReactNode;
}

const Icon = ({ icon }: { icon: IconProp }) => {
  return <FontAwesomeIcon icon={icon} fixedWidth />;
};

export const generateDefaultScaFindingsSearchParams = (
  tlr: TimeLookbackRange,
  tlrType: TimeLookbackType
) => {
  const scaSearchParams: {
    [key: string]: string;
  } = {
    primary: "true",
    tab: "open",
  };
  if (tlrType === "Last opened") {
    scaSearchParams["last_opened"] = tlrToQueryParam(tlr);
  } else if (tlrType === "Last triaged") {
    scaSearchParams["last_triaged"] = tlrToQueryParam(tlr);
  } else if (tlrType === "Last fixed") {
    scaSearchParams["last_fixed"] = tlrToQueryParam(tlr);
  }

  return new URLSearchParams(scaSearchParams).toString();
};

const getTlrTypeQueryParamName = (tlrType: TimeLookbackType) => {
  return tlrType.toLowerCase().replace(/ /g, "_");
};

export const useNavigationLinks = () => {
  const [org] = useOrg();
  const { tlr, tlrType } = useTimeLookback();
  const permissionsMap = useRoutePermissionMap();
  const secretsPageLink = useSecretsPageLink();
  const secretsPageUrl = new URL(secretsPageLink, window.location.origin);

  const branchQueryParam = { onPrimaryBranch: true };

  // show issue counts for all open SAST issues on the default branch group
  const { data: sastFindingCounts } = useFindingCounts({
    filter: IssuesFilterProto.create({
      ...getTimeLookbackFilters(tlr, tlrType),
      tab: IssueTabProto.OPEN,
      ...branchQueryParam,
    }),
    issueType: IssueTypeProto.SAST,
  });
  const { data: scaFindingCounts } = useFindingCounts({
    filter: IssuesFilterProto.create({
      ...getTimeLookbackFilters(tlr, tlrType),
      tab: IssueTabProto.OPEN,
      ...branchQueryParam,
    }),
    issueType: IssueTypeProto.SCA,
  });
  const { data: secretsFindingCounts } = useFindingCounts({
    filter: IssuesFilterProto.create({
      ...getTimeLookbackFilters(tlr, tlrType),
      tab: IssueTabProto.OPEN,
    }),
    issueType: IssueTypeProto.SECRETS,
  });

  const location = useLocation();
  const basePath = useBasePath();

  const links: NavigationLinkProps[] = useMemo(
    () => [
      {
        label: "Dashboard",
        icon: <Icon icon={faChartArea} />,
        to: basePath,
        active:
          location.pathname === "/orgs/-" ||
          (org && location.pathname === basePath),
        visible: permissionsMap.overview,
      },
      {
        label: "Projects",
        icon: <Icon icon={faFolderOpen} />,
        to: `${basePath}/projects/scanning`,
        active: location.pathname.includes("/projects"),
        visible: permissionsMap.projects,
      },
      {
        label: "Code",
        icon: <Icon icon={faCode} />,
        to: `${basePath}/findings`,
        search: `?tab=open&primary=true&${getTlrTypeQueryParamName(
          tlrType
        )}=${tlrToQueryParam(tlr)}`,
        count: sastFindingCounts?.open,
        active:
          location.pathname.includes("/findings") &&
          !location.pathname.includes("/supply-chain"), // distinct from the findings url to ensure that the Code item is highlighted when viewing a Code finding, and the Supply Chain item is highlighted when viewing a supply chain finding
        visible: permissionsMap.findings,
      },
      {
        label: "Secrets",
        icon: <Icon icon={faKey} />,
        active:
          location.pathname.includes("/secrets") &&
          !location.pathname.includes("policies"),
        to: secretsPageUrl.pathname,
        search: `${secretsPageUrl.search}&${getTlrTypeQueryParamName(
          tlrType
        )}=${tlrToQueryParam(tlr)}`,
        count: secretsFindingCounts?.open,
        visible: permissionsMap.secrets,
      },
      {
        label: "Supply Chain",
        icon: <Icon icon={faLinkSimple} />,
        visible: permissionsMap.supplyChain,
        active:
          location.pathname.includes("/supply-chain") &&
          !location.pathname.includes("policies"),
        to: `${basePath}/supply-chain/vulnerabilities`,
        search: `?${generateDefaultScaFindingsSearchParams(
          tlr,
          tlrType
        ).toString()}`,
        count: scaFindingCounts?.open,
      },
      {
        label: "Rules",
        icon: <Icon icon={faFileMagnifyingGlass} />,
        active:
          location.pathname.includes("/board") ||
          location.pathname.includes("/policies") ||
          location.pathname.includes("/editor"),
        visible:
          permissionsMap.ruleBoard &&
          permissionsMap.editor &&
          permissionsMap.registry,
        routes: [
          {
            label: "Policies",
            to: `${basePath}/policies`,
            visible: permissionsMap.ruleBoard,
            active: location.pathname.includes("/policies"),
            icon: <Icon icon={faTableTree} />,
          },
          {
            label: "Editor",
            to: `${basePath}/editor`,
            active: location.pathname.includes("/editor"),
            visible: permissionsMap.editor,
            icon: <Icon icon={faFileCode} />,
          },
          {
            label: "Code Search",
            icon: <Icon icon={faTelescope} />,
            visible: permissionsMap.codeSearch,
            active: location.pathname.includes("/code-search"),
            to: `${basePath}/code-search`,
            betaLabel: true,
          },
          {
            label: "Registry",
            to: "/explore",
            isExternalLink: true,
            visible: permissionsMap.registry,
            icon: <Icon icon={faCabinetFiling} />,
          },
        ],
      },
      {
        label: "Settings",
        to: `${basePath}/settings`,
        active: location.pathname.includes("/settings"),
        secondaryLink: true,
        icon: <Icon icon={faGear} />,
        visible: permissionsMap.settings,
      },
      {
        label: "Docs",
        to: "https://semgrep.dev/docs/",
        secondaryLink: true,
        icon: <Icon icon={faBook} />,
        isExternalLink: true,
        visible: permissionsMap.docs,
      },
      {
        label: "Help",
        active: location.pathname.includes("/support"),
        to: `${basePath}/support`,
        secondaryLink: true,
        icon: <Icon icon={faMessageQuestion} />,
        visible: permissionsMap.support,
      },
      {
        label: "Admin",
        active: location.pathname.includes("/admin"),
        to: `/admin`,
        secondaryLink: true,
        visible: permissionsMap.admin,
        icon: <Icon icon={faUserSecret} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      basePath,
      location.pathname,
      org,
      sastFindingCounts?.open,
      scaFindingCounts?.open,
      secretsFindingCounts?.open,
      permissionsMap,
    ]
  );

  return { links };
};
