import type { SemgrepExample } from "@shared/types";

export const EDITOR_EXAMPLES: SemgrepExample[] = [
  {
    description:
      "See how Semgrep’s semantic matching differs from grep’s text matching",
    title: "Semgrep vs. grep",
    hash_id: "QB0x",
    language: "javascript",
  },
  {
    description:
      "Find where we forgot to check the return code of a subprocess call",
    title: "Unchecked subprocess return code",
    hash_id: "nELb",
    language: "python",
  },
  {
    description:
      "Warn about likely copy-paste errors where both sides of an == operator have the same code",
    title: "Using Semgrep's metavariables",
    hash_id: "EbLo",
    language: "python",
  },
  {
    description: "Find various ways credentials could be hard-coded",
    title: "Constant propagation",
    hash_id: "Zo2L",
    language: "python",
  },
  {
    description:
      "Write a private rule for misuse of your project's business logic",
    title: "Custom rules",
    hash_id: "3Djl",
    language: "java",
  },
  {
    description: "Automatically patch identified issues",
    title: "Autofix",
    hash_id: "7qr4",
    language: "python",
  },
  {
    description: "Gather an inventory of all routes in your web application",
    title: "API routes",
    hash_id: "LJR7",
    language: "java",
  },
  {
    description:
      "Find hard-coded IP addresses by filtering Semgrep's matches with a regular expression",
    title: "Combining Semgrep and regex",
    hash_id: "8l34",
    language: "regex",
  },
  {
    description: "Scan AWS IAM policies for misconfiguration",
    title: "Bugs in config files",
    hash_id: "gwey",
    language: "json",
  },
];
