import { useQuery } from "@tanstack/react-query";

import {
  GetIssueCountsRequest,
  GetIssueCountsResponse,
} from "@protos/issues/v1/issues";
import { authPost } from "@shared/api";
import { DEPLOYMENT_URL } from "@shared/constants";

import { useOrgAssertDefined } from "../useOrgAssertDefined";

interface UseFindingCountsOpts {
  enabled: boolean;
}

/**
 * This hook returns the counts of findings for the current org.
 */
export const useFindingCounts = (
  req: Omit<GetIssueCountsRequest, "deploymentId">,
  opts: UseFindingCountsOpts = {
    enabled: true,
  }
) => {
  const org = useOrgAssertDefined();

  return useQuery({
    queryKey: ["FindingCounts", org.id, JSON.stringify(req)],
    queryFn: () => fetchFindingCounts({ ...req, deploymentId: org.id }),
    enabled: opts.enabled,
  });
};

const fetchFindingCounts = async (
  req: GetIssueCountsRequest
): Promise<GetIssueCountsResponse> => {
  const url = `${DEPLOYMENT_URL}/${req.deploymentId}/issue_counts`;
  const res = await authPost(url, GetIssueCountsRequest.toJSON(req));
  return GetIssueCountsResponse.fromJSON(res);
};
