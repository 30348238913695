import React, { FormEvent } from "react";
import { observer } from "mobx-react-lite";
import { createGlobalStyle } from "styled-components";
import {
  faAngleDoubleUp,
  faPlus,
  faQuestionCircle,
  faX,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  Button,
  ScrollArea,
  Select,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";

import { readMatchSeverity } from "@semgrep_output_types";

import { Rule } from "../types/rule";

import { LanguageSelectComponent } from "./LanguageSelectComponent";

import styles from "./../StructureMode.module.css";

const MetadataPopoversOverrideStyle = createGlobalStyle`
  .mantine-ScrollArea-viewport>div {
    display: block !important;
  }
`;

interface StructureRuleMetadataEditorProps {
  rule: Rule;
}

const StructureRuleMetadataEditorComponent: React.FC<
  StructureRuleMetadataEditorProps
> = ({ rule }) => {
  const setRuleID = (ruleID: string) => {
    rule.ruleID = ruleID;
  };

  const setFix = (fix: string | null) => {
    rule.fix = fix;
  };

  const setMessage = (message: string) => {
    rule.message = message;
  };

  // really, the type is "ERROR", "INFO", or "WARNING"
  const setSeverity = (severity: string | null) => {
    if (severity) {
      rule.severity = readMatchSeverity(severity);
    }
  };

  return (
    // this is mostly copied from the Inspect Rule panel
    <Accordion
      chevron={<FontAwesomeIcon icon={faAngleDoubleUp} size="xs" />}
      chevronPosition="left"
      classNames={{
        root: styles.ruleInfoRoot,
        label: styles.ruleInfoLabel,
        item: styles.ruleInfoItem,
      }}
      multiple
      variant="contained"
      radius="xs"
      defaultValue={["inspector"]}
    >
      <ScrollArea.Autosize mah={300}>
        <Accordion.Item value={"inspector"}>
          <Accordion.Control>
            <Text inherit size="md" fw={500}>
              Rule info
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <MetadataPopoversOverrideStyle />
            <TextInput
              error={rule.ruleID ? "" : "Required"}
              label="Rule ID"
              onChange={(e: FormEvent<HTMLInputElement>) =>
                setRuleID(e.currentTarget.value)
              }
              aria-label="StructureModeMetadataRuleIDEditor"
              value={rule.ruleID}
            />
            <LanguageSelectComponent
              rule={rule}
              aria-label="StructureModeMetadataLanguageEditor"
            />
            <Select
              data={["INFO", "WARNING", "ERROR"]}
              error={rule.severity ? "" : "Required"}
              label="Severity"
              mt="xs"
              nothingFoundMessage="No options"
              onChange={setSeverity}
              placeholder="Select severity"
              searchable
              aria-label="StructureModeMetadataSeverityEditor"
              value={rule.severity.kind.toUpperCase()}
              data-fs-element="Structure severity select"
            />
            <Textarea
              error={rule.message?.length ? "" : "Required"}
              label={
                <span>
                  Message{" "}
                  <Tooltip
                    withArrow
                    withinPortal
                    label="You can use metavariables (like $FOO) in the message"
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} size="xs" />
                  </Tooltip>
                </span>
              }
              mt="xs"
              onChange={(e: FormEvent<HTMLTextAreaElement>) =>
                setMessage(e.currentTarget.value)
              }
              value={rule.message}
              data-testid="StructureModeMetadataMessageEditor"
            />
            {rule.fix !== null ? (
              <TextInput
                label="Fix"
                onChange={(e: FormEvent<HTMLInputElement>) =>
                  setFix(e.currentTarget.value)
                }
                data-testid="StructureModeMetadataFixEditor"
                value={rule.fix}
                rightSection={
                  <FontAwesomeIcon
                    icon={faX}
                    color="var(--mantine-color-red-8)"
                    onClick={() => setFix(null)}
                    className={styles["metadataInfoDeletionButton"]}
                  />
                }
              />
            ) : (
              <Button
                c="blue"
                leftSection={<FontAwesomeIcon icon={faPlus} />}
                mt={4}
                onClick={() => {
                  setFix("");
                }}
                variant="subtle"
              >
                Add fix
              </Button>
            )}
          </Accordion.Panel>
        </Accordion.Item>
      </ScrollArea.Autosize>
    </Accordion>
  );
};

export const StructureRuleMetadataEditor = observer(
  StructureRuleMetadataEditorComponent
);
