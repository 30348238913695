import deburr from "lodash/deburr";

import { OrgData } from "@shared/types";

/**
 * @param name the name to slugify
 * @returns a name slugified for an org. This is a lowercased, deburred, and
 * trimmed. If you have an OrgData object, you should use getOrgSlug instead.
 * @deprecated you probably want to use getOrgSlug instead
 */
export const slugifyNameForOrg = (name: string): string =>
  deburr(name.toLowerCase()).trim().replace(/\W+/g, "-");

/**
 *
 * @param org the org to get the slug for
 * @returns the slug for the org. Either org.slug or a slugified version of the
 * org name, if the slug is not present in the org data.
 */
export const getOrgSlug = (org: OrgData): string => {
  return org.slug ?? slugifyNameForOrg(org.name);
};
