import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import {
  CreateSearchRequest,
  CreateSearchResponse,
} from "@protos/search/v1/search_service";
import { createSearch } from "@shared/api";
import { ApiError } from "@shared/types";

export const useStartSearch = (
  options: Omit<
    UseMutationOptions<CreateSearchResponse, ApiError, CreateSearchRequest>,
    "mutationFn"
  > = {}
) => {
  return useMutation({
    mutationFn: createSearch,
    ...options,
  });
};
