// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/search/v1/search_service.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { CliMatch } from "../../cli/semgrep_output";
import { GithubRepository } from "../../external/v1/github_repository";

export const protobufPackage = "protos.search.v1";

export enum SearchEngine {
  UNSPECIFIED = "SEARCH_ENGINE_UNSPECIFIED",
  ARGO = "SEARCH_ENGINE_ARGO",
  SQS = "SEARCH_ENGINE_SQS",
}

export function searchEngineFromJSON(object: any): SearchEngine {
  switch (object) {
    case 0:
    case "SEARCH_ENGINE_UNSPECIFIED":
      return SearchEngine.UNSPECIFIED;
    case 1:
    case "SEARCH_ENGINE_ARGO":
      return SearchEngine.ARGO;
    case 2:
    case "SEARCH_ENGINE_SQS":
      return SearchEngine.SQS;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SearchEngine");
  }
}

export function searchEngineToJSON(object: SearchEngine): string {
  switch (object) {
    case SearchEngine.UNSPECIFIED:
      return "SEARCH_ENGINE_UNSPECIFIED";
    case SearchEngine.ARGO:
      return "SEARCH_ENGINE_ARGO";
    case SearchEngine.SQS:
      return "SEARCH_ENGINE_SQS";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SearchEngine");
  }
}

export function searchEngineToNumber(object: SearchEngine): number {
  switch (object) {
    case SearchEngine.UNSPECIFIED:
      return 0;
    case SearchEngine.ARGO:
      return 1;
    case SearchEngine.SQS:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SearchEngine");
  }
}

export enum SearchState {
  UNSPECIFIED = "SEARCH_STATE_UNSPECIFIED",
  PENDING = "SEARCH_STATE_PENDING",
  RUNNING = "SEARCH_STATE_RUNNING",
  COMPLETE = "SEARCH_STATE_COMPLETE",
  ERROR = "SEARCH_STATE_ERROR",
  TIMEOUT = "SEARCH_STATE_TIMEOUT",
}

export function searchStateFromJSON(object: any): SearchState {
  switch (object) {
    case 0:
    case "SEARCH_STATE_UNSPECIFIED":
      return SearchState.UNSPECIFIED;
    case 1:
    case "SEARCH_STATE_PENDING":
      return SearchState.PENDING;
    case 2:
    case "SEARCH_STATE_RUNNING":
      return SearchState.RUNNING;
    case 3:
    case "SEARCH_STATE_COMPLETE":
      return SearchState.COMPLETE;
    case 4:
    case "SEARCH_STATE_ERROR":
      return SearchState.ERROR;
    case 5:
    case "SEARCH_STATE_TIMEOUT":
      return SearchState.TIMEOUT;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SearchState");
  }
}

export function searchStateToJSON(object: SearchState): string {
  switch (object) {
    case SearchState.UNSPECIFIED:
      return "SEARCH_STATE_UNSPECIFIED";
    case SearchState.PENDING:
      return "SEARCH_STATE_PENDING";
    case SearchState.RUNNING:
      return "SEARCH_STATE_RUNNING";
    case SearchState.COMPLETE:
      return "SEARCH_STATE_COMPLETE";
    case SearchState.ERROR:
      return "SEARCH_STATE_ERROR";
    case SearchState.TIMEOUT:
      return "SEARCH_STATE_TIMEOUT";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SearchState");
  }
}

export function searchStateToNumber(object: SearchState): number {
  switch (object) {
    case SearchState.UNSPECIFIED:
      return 0;
    case SearchState.PENDING:
      return 1;
    case SearchState.RUNNING:
      return 2;
    case SearchState.COMPLETE:
      return 3;
    case SearchState.ERROR:
      return 4;
    case SearchState.TIMEOUT:
      return 5;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SearchState");
  }
}

export interface CreateSearchRequest {
  deploymentId: number;
  rules: string;
  repositories: TargetRepository[];
  workflowTemplateNameOverride?: string | undefined;
  basedOnRuleVersionId?: number | undefined;
  ruleEdited: boolean;
  /** @deprecated */
  engine: SearchEngine;
  limit?: number | undefined;
  parentSavedSearchId?: number | undefined;
}

export interface SearchStatusUpdate {
  state: SearchState;
  startedAt: Date | undefined;
  message: string;
}

export interface SearchStatus {
  searchId: string;
  deploymentId: number;
  state: SearchState;
  updates: SearchStatusUpdate[];
  itemsProcessed: number;
  itemsTotal: number;
}

export interface SearchResultCursor {
  stateUpdatedAt: Date | undefined;
  searchRepoId: number;
}

export interface CreateSearchResponse {
  /** @deprecated */
  repositoryResults: RepositorySearchResults[];
  searchId: string;
}

/** @deprecated */
export interface RepositorySearchResults {
  repository: string;
  results: SearchResult[];
  commit: string;
}

export interface GetSearchRepoRequest {
  searchId: string;
  repoName: string;
}

export interface GetSearchRepoResponse {
  searchId: string;
  repoName: string;
  status: RepositorySearchStatus | undefined;
}

export interface GetSearchRequest {
  searchId: string;
  limit: number;
  cursor?: SearchResultCursor | undefined;
}

export interface GetSearchResponse {
  searchId: string;
  deploymentId: number;
  rules: string;
  repositories: RepositorySearchStatus[];
  state: SearchState;
  createdAt: Date | undefined;
  limit?: number | undefined;
  nextCursor: SearchResultCursor | undefined;
  hasMore: boolean;
  terminated: boolean;
  totalRepositories: number;
  terminatedRepositories: number;
}

export interface RepositorySearchStatus {
  /** @deprecated */
  fullName: string;
  commit: string;
  results: SearchResult[];
  url: string;
  state: SearchState;
  repository: TargetRepository | undefined;
  durationMs?: number | undefined;
  resultCount: number;
  exitState?: string | undefined;
}

export interface SearchResult {
  filename: string;
  line: number;
  snippet: string;
  url: string;
  /** @deprecated */
  match: CliMatch | undefined;
}

export interface GetSearchableReposRequest {
  deploymentId: number;
}

export interface TargetRepository {
  fullName: string;
  cloneUrl: string;
  private: boolean;
}

export interface GetSearchableReposResponse {
  repositories: GithubRepository[];
  needsAppInstall: boolean;
}

export interface SearchPublicReposRequest {
  deploymentId: number;
  query: string;
}

export interface GithubRepositorySearchResponse {
  items: GithubRepository[];
  incompleteResults: boolean;
  totalCount: number;
}

export interface SearchPublicReposResponse {
  repositories: GithubRepository[];
}

export interface SavedSearchRule {
  id: number;
  savedSearchId: string;
  rule: string;
}

export interface SavedSearch {
  id: number;
  name: string;
  deploymentId: number;
  createdBy: string;
  /** @deprecated */
  createdAtMs: number;
  searchIds: string[];
  createdAt: Date | undefined;
}

export interface CreateSavedSearchRequest {
  name: string;
  /** @deprecated */
  deploymentId: number;
  searchId: string;
}

export interface CreateSavedSearchResponse {
  savedSearch: SavedSearch | undefined;
}

export interface GetSavedSearchRequest {
  savedSearchId: number;
}

export interface GetSavedSearchResponse {
  savedSearch: SavedSearch | undefined;
}

export interface UpdateSavedSearchRequest {
  name: string;
  /** @deprecated */
  deploymentId: number;
  savedSearchId: number;
}

export interface UpdateSavedSearchResponse {
  savedSearch: SavedSearch | undefined;
}

export interface DeleteSavedSearchRequest {
  savedSearchId: number;
}

export interface DeleteSavedSearchResponse {
  savedSearch: SavedSearch | undefined;
}

export interface GetSavedSearchesRequest {
  deploymentId: number;
}

export interface GetSavedSearchesResponse {
  savedSearches: SavedSearch[];
}

export interface RerunSavedSearchRequest {
  savedSearchId: number;
}

export interface RerunSavedSearchResponse {
  savedSearch: SavedSearch | undefined;
  createSearchResponse: CreateSearchResponse | undefined;
}

export interface FeedbackRequest {
  deploymentId: number;
  message: string;
  referrer: string;
}

export interface FeedbackResponse {
  message: string;
}

export interface SearchRepoProgressUpdate {
  searchId: string;
  repoName: string;
  state: SearchState;
  resultCount: number;
  timestamp: Date | undefined;
}

function createBaseCreateSearchRequest(): CreateSearchRequest {
  return {
    deploymentId: 0,
    rules: "",
    repositories: [],
    workflowTemplateNameOverride: undefined,
    basedOnRuleVersionId: undefined,
    ruleEdited: false,
    engine: SearchEngine.UNSPECIFIED,
    limit: undefined,
    parentSavedSearchId: undefined,
  };
}

export const CreateSearchRequest = {
  encode(message: CreateSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint32(message.deploymentId);
    }
    if (message.rules !== "") {
      writer.uint32(18).string(message.rules);
    }
    for (const v of message.repositories) {
      TargetRepository.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.workflowTemplateNameOverride !== undefined) {
      writer.uint32(34).string(message.workflowTemplateNameOverride);
    }
    if (message.basedOnRuleVersionId !== undefined) {
      writer.uint32(40).uint32(message.basedOnRuleVersionId);
    }
    if (message.ruleEdited !== false) {
      writer.uint32(48).bool(message.ruleEdited);
    }
    if (message.engine !== SearchEngine.UNSPECIFIED) {
      writer.uint32(56).int32(searchEngineToNumber(message.engine));
    }
    if (message.limit !== undefined) {
      writer.uint32(64).uint32(message.limit);
    }
    if (message.parentSavedSearchId !== undefined) {
      writer.uint32(72).uint32(message.parentSavedSearchId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSearchRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.rules = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.repositories.push(TargetRepository.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.workflowTemplateNameOverride = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.basedOnRuleVersionId = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.ruleEdited = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.engine = searchEngineFromJSON(reader.int32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.limit = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.parentSavedSearchId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSearchRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      rules: isSet(object.rules) ? globalThis.String(object.rules) : "",
      repositories: globalThis.Array.isArray(object?.repositories)
        ? object.repositories.map((e: any) => TargetRepository.fromJSON(e))
        : [],
      workflowTemplateNameOverride: isSet(object.workflowTemplateNameOverride)
        ? globalThis.String(object.workflowTemplateNameOverride)
        : undefined,
      basedOnRuleVersionId: isSet(object.basedOnRuleVersionId)
        ? globalThis.Number(object.basedOnRuleVersionId)
        : undefined,
      ruleEdited: isSet(object.ruleEdited) ? globalThis.Boolean(object.ruleEdited) : false,
      engine: isSet(object.engine) ? searchEngineFromJSON(object.engine) : SearchEngine.UNSPECIFIED,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : undefined,
      parentSavedSearchId: isSet(object.parentSavedSearchId)
        ? globalThis.Number(object.parentSavedSearchId)
        : undefined,
    };
  },

  toJSON(message: CreateSearchRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.rules !== "") {
      obj.rules = message.rules;
    }
    if (message.repositories?.length) {
      obj.repositories = message.repositories.map((e) => TargetRepository.toJSON(e));
    }
    if (message.workflowTemplateNameOverride !== undefined) {
      obj.workflowTemplateNameOverride = message.workflowTemplateNameOverride;
    }
    if (message.basedOnRuleVersionId !== undefined) {
      obj.basedOnRuleVersionId = Math.round(message.basedOnRuleVersionId);
    }
    if (message.ruleEdited !== false) {
      obj.ruleEdited = message.ruleEdited;
    }
    if (message.engine !== SearchEngine.UNSPECIFIED) {
      obj.engine = searchEngineToJSON(message.engine);
    }
    if (message.limit !== undefined) {
      obj.limit = Math.round(message.limit);
    }
    if (message.parentSavedSearchId !== undefined) {
      obj.parentSavedSearchId = Math.round(message.parentSavedSearchId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateSearchRequest>, I>>(base?: I): CreateSearchRequest {
    return CreateSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateSearchRequest>, I>>(object: I): CreateSearchRequest {
    const message = createBaseCreateSearchRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.rules = object.rules ?? "";
    message.repositories = object.repositories?.map((e) => TargetRepository.fromPartial(e)) || [];
    message.workflowTemplateNameOverride = object.workflowTemplateNameOverride ?? undefined;
    message.basedOnRuleVersionId = object.basedOnRuleVersionId ?? undefined;
    message.ruleEdited = object.ruleEdited ?? false;
    message.engine = object.engine ?? SearchEngine.UNSPECIFIED;
    message.limit = object.limit ?? undefined;
    message.parentSavedSearchId = object.parentSavedSearchId ?? undefined;
    return message;
  },
};

function createBaseSearchStatusUpdate(): SearchStatusUpdate {
  return { state: SearchState.UNSPECIFIED, startedAt: undefined, message: "" };
}

export const SearchStatusUpdate = {
  encode(message: SearchStatusUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.state !== SearchState.UNSPECIFIED) {
      writer.uint32(8).int32(searchStateToNumber(message.state));
    }
    if (message.startedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.startedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.message !== "") {
      writer.uint32(26).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchStatusUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchStatusUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.state = searchStateFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchStatusUpdate {
    return {
      state: isSet(object.state) ? searchStateFromJSON(object.state) : SearchState.UNSPECIFIED,
      startedAt: isSet(object.startedAt) ? fromJsonTimestamp(object.startedAt) : undefined,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: SearchStatusUpdate): unknown {
    const obj: any = {};
    if (message.state !== SearchState.UNSPECIFIED) {
      obj.state = searchStateToJSON(message.state);
    }
    if (message.startedAt !== undefined) {
      obj.startedAt = message.startedAt.toISOString();
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchStatusUpdate>, I>>(base?: I): SearchStatusUpdate {
    return SearchStatusUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchStatusUpdate>, I>>(object: I): SearchStatusUpdate {
    const message = createBaseSearchStatusUpdate();
    message.state = object.state ?? SearchState.UNSPECIFIED;
    message.startedAt = object.startedAt ?? undefined;
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseSearchStatus(): SearchStatus {
  return {
    searchId: "",
    deploymentId: 0,
    state: SearchState.UNSPECIFIED,
    updates: [],
    itemsProcessed: 0,
    itemsTotal: 0,
  };
}

export const SearchStatus = {
  encode(message: SearchStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchId !== "") {
      writer.uint32(10).string(message.searchId);
    }
    if (message.deploymentId !== 0) {
      writer.uint32(16).uint32(message.deploymentId);
    }
    if (message.state !== SearchState.UNSPECIFIED) {
      writer.uint32(24).int32(searchStateToNumber(message.state));
    }
    for (const v of message.updates) {
      SearchStatusUpdate.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.itemsProcessed !== 0) {
      writer.uint32(40).uint32(message.itemsProcessed);
    }
    if (message.itemsTotal !== 0) {
      writer.uint32(48).uint32(message.itemsTotal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deploymentId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.state = searchStateFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updates.push(SearchStatusUpdate.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.itemsProcessed = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.itemsTotal = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchStatus {
    return {
      searchId: isSet(object.searchId) ? globalThis.String(object.searchId) : "",
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      state: isSet(object.state) ? searchStateFromJSON(object.state) : SearchState.UNSPECIFIED,
      updates: globalThis.Array.isArray(object?.updates)
        ? object.updates.map((e: any) => SearchStatusUpdate.fromJSON(e))
        : [],
      itemsProcessed: isSet(object.itemsProcessed) ? globalThis.Number(object.itemsProcessed) : 0,
      itemsTotal: isSet(object.itemsTotal) ? globalThis.Number(object.itemsTotal) : 0,
    };
  },

  toJSON(message: SearchStatus): unknown {
    const obj: any = {};
    if (message.searchId !== "") {
      obj.searchId = message.searchId;
    }
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.state !== SearchState.UNSPECIFIED) {
      obj.state = searchStateToJSON(message.state);
    }
    if (message.updates?.length) {
      obj.updates = message.updates.map((e) => SearchStatusUpdate.toJSON(e));
    }
    if (message.itemsProcessed !== 0) {
      obj.itemsProcessed = Math.round(message.itemsProcessed);
    }
    if (message.itemsTotal !== 0) {
      obj.itemsTotal = Math.round(message.itemsTotal);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchStatus>, I>>(base?: I): SearchStatus {
    return SearchStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchStatus>, I>>(object: I): SearchStatus {
    const message = createBaseSearchStatus();
    message.searchId = object.searchId ?? "";
    message.deploymentId = object.deploymentId ?? 0;
    message.state = object.state ?? SearchState.UNSPECIFIED;
    message.updates = object.updates?.map((e) => SearchStatusUpdate.fromPartial(e)) || [];
    message.itemsProcessed = object.itemsProcessed ?? 0;
    message.itemsTotal = object.itemsTotal ?? 0;
    return message;
  },
};

function createBaseSearchResultCursor(): SearchResultCursor {
  return { stateUpdatedAt: undefined, searchRepoId: 0 };
}

export const SearchResultCursor = {
  encode(message: SearchResultCursor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.stateUpdatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.stateUpdatedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.searchRepoId !== 0) {
      writer.uint32(16).uint32(message.searchRepoId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchResultCursor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchResultCursor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.stateUpdatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.searchRepoId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchResultCursor {
    return {
      stateUpdatedAt: isSet(object.stateUpdatedAt) ? fromJsonTimestamp(object.stateUpdatedAt) : undefined,
      searchRepoId: isSet(object.searchRepoId) ? globalThis.Number(object.searchRepoId) : 0,
    };
  },

  toJSON(message: SearchResultCursor): unknown {
    const obj: any = {};
    if (message.stateUpdatedAt !== undefined) {
      obj.stateUpdatedAt = message.stateUpdatedAt.toISOString();
    }
    if (message.searchRepoId !== 0) {
      obj.searchRepoId = Math.round(message.searchRepoId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchResultCursor>, I>>(base?: I): SearchResultCursor {
    return SearchResultCursor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchResultCursor>, I>>(object: I): SearchResultCursor {
    const message = createBaseSearchResultCursor();
    message.stateUpdatedAt = object.stateUpdatedAt ?? undefined;
    message.searchRepoId = object.searchRepoId ?? 0;
    return message;
  },
};

function createBaseCreateSearchResponse(): CreateSearchResponse {
  return { repositoryResults: [], searchId: "" };
}

export const CreateSearchResponse = {
  encode(message: CreateSearchResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.repositoryResults) {
      RepositorySearchResults.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.searchId !== "") {
      writer.uint32(18).string(message.searchId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSearchResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSearchResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.repositoryResults.push(RepositorySearchResults.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.searchId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSearchResponse {
    return {
      repositoryResults: globalThis.Array.isArray(object?.repositoryResults)
        ? object.repositoryResults.map((e: any) => RepositorySearchResults.fromJSON(e))
        : [],
      searchId: isSet(object.searchId) ? globalThis.String(object.searchId) : "",
    };
  },

  toJSON(message: CreateSearchResponse): unknown {
    const obj: any = {};
    if (message.repositoryResults?.length) {
      obj.repositoryResults = message.repositoryResults.map((e) => RepositorySearchResults.toJSON(e));
    }
    if (message.searchId !== "") {
      obj.searchId = message.searchId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateSearchResponse>, I>>(base?: I): CreateSearchResponse {
    return CreateSearchResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateSearchResponse>, I>>(object: I): CreateSearchResponse {
    const message = createBaseCreateSearchResponse();
    message.repositoryResults = object.repositoryResults?.map((e) => RepositorySearchResults.fromPartial(e)) || [];
    message.searchId = object.searchId ?? "";
    return message;
  },
};

function createBaseRepositorySearchResults(): RepositorySearchResults {
  return { repository: "", results: [], commit: "" };
}

export const RepositorySearchResults = {
  encode(message: RepositorySearchResults, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.repository !== "") {
      writer.uint32(10).string(message.repository);
    }
    for (const v of message.results) {
      SearchResult.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.commit !== "") {
      writer.uint32(26).string(message.commit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RepositorySearchResults {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRepositorySearchResults();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.repository = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.results.push(SearchResult.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.commit = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RepositorySearchResults {
    return {
      repository: isSet(object.repository) ? globalThis.String(object.repository) : "",
      results: globalThis.Array.isArray(object?.results)
        ? object.results.map((e: any) => SearchResult.fromJSON(e))
        : [],
      commit: isSet(object.commit) ? globalThis.String(object.commit) : "",
    };
  },

  toJSON(message: RepositorySearchResults): unknown {
    const obj: any = {};
    if (message.repository !== "") {
      obj.repository = message.repository;
    }
    if (message.results?.length) {
      obj.results = message.results.map((e) => SearchResult.toJSON(e));
    }
    if (message.commit !== "") {
      obj.commit = message.commit;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RepositorySearchResults>, I>>(base?: I): RepositorySearchResults {
    return RepositorySearchResults.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RepositorySearchResults>, I>>(object: I): RepositorySearchResults {
    const message = createBaseRepositorySearchResults();
    message.repository = object.repository ?? "";
    message.results = object.results?.map((e) => SearchResult.fromPartial(e)) || [];
    message.commit = object.commit ?? "";
    return message;
  },
};

function createBaseGetSearchRepoRequest(): GetSearchRepoRequest {
  return { searchId: "", repoName: "" };
}

export const GetSearchRepoRequest = {
  encode(message: GetSearchRepoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchId !== "") {
      writer.uint32(10).string(message.searchId);
    }
    if (message.repoName !== "") {
      writer.uint32(18).string(message.repoName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSearchRepoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSearchRepoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.repoName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSearchRepoRequest {
    return {
      searchId: isSet(object.searchId) ? globalThis.String(object.searchId) : "",
      repoName: isSet(object.repoName) ? globalThis.String(object.repoName) : "",
    };
  },

  toJSON(message: GetSearchRepoRequest): unknown {
    const obj: any = {};
    if (message.searchId !== "") {
      obj.searchId = message.searchId;
    }
    if (message.repoName !== "") {
      obj.repoName = message.repoName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSearchRepoRequest>, I>>(base?: I): GetSearchRepoRequest {
    return GetSearchRepoRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSearchRepoRequest>, I>>(object: I): GetSearchRepoRequest {
    const message = createBaseGetSearchRepoRequest();
    message.searchId = object.searchId ?? "";
    message.repoName = object.repoName ?? "";
    return message;
  },
};

function createBaseGetSearchRepoResponse(): GetSearchRepoResponse {
  return { searchId: "", repoName: "", status: undefined };
}

export const GetSearchRepoResponse = {
  encode(message: GetSearchRepoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchId !== "") {
      writer.uint32(10).string(message.searchId);
    }
    if (message.repoName !== "") {
      writer.uint32(18).string(message.repoName);
    }
    if (message.status !== undefined) {
      RepositorySearchStatus.encode(message.status, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSearchRepoResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSearchRepoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.repoName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.status = RepositorySearchStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSearchRepoResponse {
    return {
      searchId: isSet(object.searchId) ? globalThis.String(object.searchId) : "",
      repoName: isSet(object.repoName) ? globalThis.String(object.repoName) : "",
      status: isSet(object.status) ? RepositorySearchStatus.fromJSON(object.status) : undefined,
    };
  },

  toJSON(message: GetSearchRepoResponse): unknown {
    const obj: any = {};
    if (message.searchId !== "") {
      obj.searchId = message.searchId;
    }
    if (message.repoName !== "") {
      obj.repoName = message.repoName;
    }
    if (message.status !== undefined) {
      obj.status = RepositorySearchStatus.toJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSearchRepoResponse>, I>>(base?: I): GetSearchRepoResponse {
    return GetSearchRepoResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSearchRepoResponse>, I>>(object: I): GetSearchRepoResponse {
    const message = createBaseGetSearchRepoResponse();
    message.searchId = object.searchId ?? "";
    message.repoName = object.repoName ?? "";
    message.status = (object.status !== undefined && object.status !== null)
      ? RepositorySearchStatus.fromPartial(object.status)
      : undefined;
    return message;
  },
};

function createBaseGetSearchRequest(): GetSearchRequest {
  return { searchId: "", limit: 0, cursor: undefined };
}

export const GetSearchRequest = {
  encode(message: GetSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchId !== "") {
      writer.uint32(10).string(message.searchId);
    }
    if (message.limit !== 0) {
      writer.uint32(24).uint32(message.limit);
    }
    if (message.cursor !== undefined) {
      SearchResultCursor.encode(message.cursor, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSearchRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.limit = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cursor = SearchResultCursor.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSearchRequest {
    return {
      searchId: isSet(object.searchId) ? globalThis.String(object.searchId) : "",
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : 0,
      cursor: isSet(object.cursor) ? SearchResultCursor.fromJSON(object.cursor) : undefined,
    };
  },

  toJSON(message: GetSearchRequest): unknown {
    const obj: any = {};
    if (message.searchId !== "") {
      obj.searchId = message.searchId;
    }
    if (message.limit !== 0) {
      obj.limit = Math.round(message.limit);
    }
    if (message.cursor !== undefined) {
      obj.cursor = SearchResultCursor.toJSON(message.cursor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSearchRequest>, I>>(base?: I): GetSearchRequest {
    return GetSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSearchRequest>, I>>(object: I): GetSearchRequest {
    const message = createBaseGetSearchRequest();
    message.searchId = object.searchId ?? "";
    message.limit = object.limit ?? 0;
    message.cursor = (object.cursor !== undefined && object.cursor !== null)
      ? SearchResultCursor.fromPartial(object.cursor)
      : undefined;
    return message;
  },
};

function createBaseGetSearchResponse(): GetSearchResponse {
  return {
    searchId: "",
    deploymentId: 0,
    rules: "",
    repositories: [],
    state: SearchState.UNSPECIFIED,
    createdAt: undefined,
    limit: undefined,
    nextCursor: undefined,
    hasMore: false,
    terminated: false,
    totalRepositories: 0,
    terminatedRepositories: 0,
  };
}

export const GetSearchResponse = {
  encode(message: GetSearchResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchId !== "") {
      writer.uint32(10).string(message.searchId);
    }
    if (message.deploymentId !== 0) {
      writer.uint32(16).uint32(message.deploymentId);
    }
    if (message.rules !== "") {
      writer.uint32(26).string(message.rules);
    }
    for (const v of message.repositories) {
      RepositorySearchStatus.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.state !== SearchState.UNSPECIFIED) {
      writer.uint32(40).int32(searchStateToNumber(message.state));
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(50).fork()).ldelim();
    }
    if (message.limit !== undefined) {
      writer.uint32(56).uint32(message.limit);
    }
    if (message.nextCursor !== undefined) {
      SearchResultCursor.encode(message.nextCursor, writer.uint32(66).fork()).ldelim();
    }
    if (message.hasMore !== false) {
      writer.uint32(72).bool(message.hasMore);
    }
    if (message.terminated !== false) {
      writer.uint32(80).bool(message.terminated);
    }
    if (message.totalRepositories !== 0) {
      writer.uint32(88).uint32(message.totalRepositories);
    }
    if (message.terminatedRepositories !== 0) {
      writer.uint32(96).uint32(message.terminatedRepositories);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSearchResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSearchResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deploymentId = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.rules = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.repositories.push(RepositorySearchStatus.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.state = searchStateFromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.limit = reader.uint32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.nextCursor = SearchResultCursor.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.hasMore = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.terminated = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.totalRepositories = reader.uint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.terminatedRepositories = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSearchResponse {
    return {
      searchId: isSet(object.searchId) ? globalThis.String(object.searchId) : "",
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      rules: isSet(object.rules) ? globalThis.String(object.rules) : "",
      repositories: globalThis.Array.isArray(object?.repositories)
        ? object.repositories.map((e: any) => RepositorySearchStatus.fromJSON(e))
        : [],
      state: isSet(object.state) ? searchStateFromJSON(object.state) : SearchState.UNSPECIFIED,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : undefined,
      nextCursor: isSet(object.nextCursor) ? SearchResultCursor.fromJSON(object.nextCursor) : undefined,
      hasMore: isSet(object.hasMore) ? globalThis.Boolean(object.hasMore) : false,
      terminated: isSet(object.terminated) ? globalThis.Boolean(object.terminated) : false,
      totalRepositories: isSet(object.totalRepositories) ? globalThis.Number(object.totalRepositories) : 0,
      terminatedRepositories: isSet(object.terminatedRepositories)
        ? globalThis.Number(object.terminatedRepositories)
        : 0,
    };
  },

  toJSON(message: GetSearchResponse): unknown {
    const obj: any = {};
    if (message.searchId !== "") {
      obj.searchId = message.searchId;
    }
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.rules !== "") {
      obj.rules = message.rules;
    }
    if (message.repositories?.length) {
      obj.repositories = message.repositories.map((e) => RepositorySearchStatus.toJSON(e));
    }
    if (message.state !== SearchState.UNSPECIFIED) {
      obj.state = searchStateToJSON(message.state);
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.limit !== undefined) {
      obj.limit = Math.round(message.limit);
    }
    if (message.nextCursor !== undefined) {
      obj.nextCursor = SearchResultCursor.toJSON(message.nextCursor);
    }
    if (message.hasMore !== false) {
      obj.hasMore = message.hasMore;
    }
    if (message.terminated !== false) {
      obj.terminated = message.terminated;
    }
    if (message.totalRepositories !== 0) {
      obj.totalRepositories = Math.round(message.totalRepositories);
    }
    if (message.terminatedRepositories !== 0) {
      obj.terminatedRepositories = Math.round(message.terminatedRepositories);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSearchResponse>, I>>(base?: I): GetSearchResponse {
    return GetSearchResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSearchResponse>, I>>(object: I): GetSearchResponse {
    const message = createBaseGetSearchResponse();
    message.searchId = object.searchId ?? "";
    message.deploymentId = object.deploymentId ?? 0;
    message.rules = object.rules ?? "";
    message.repositories = object.repositories?.map((e) => RepositorySearchStatus.fromPartial(e)) || [];
    message.state = object.state ?? SearchState.UNSPECIFIED;
    message.createdAt = object.createdAt ?? undefined;
    message.limit = object.limit ?? undefined;
    message.nextCursor = (object.nextCursor !== undefined && object.nextCursor !== null)
      ? SearchResultCursor.fromPartial(object.nextCursor)
      : undefined;
    message.hasMore = object.hasMore ?? false;
    message.terminated = object.terminated ?? false;
    message.totalRepositories = object.totalRepositories ?? 0;
    message.terminatedRepositories = object.terminatedRepositories ?? 0;
    return message;
  },
};

function createBaseRepositorySearchStatus(): RepositorySearchStatus {
  return {
    fullName: "",
    commit: "",
    results: [],
    url: "",
    state: SearchState.UNSPECIFIED,
    repository: undefined,
    durationMs: undefined,
    resultCount: 0,
    exitState: undefined,
  };
}

export const RepositorySearchStatus = {
  encode(message: RepositorySearchStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fullName !== "") {
      writer.uint32(10).string(message.fullName);
    }
    if (message.commit !== "") {
      writer.uint32(18).string(message.commit);
    }
    for (const v of message.results) {
      SearchResult.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.url !== "") {
      writer.uint32(34).string(message.url);
    }
    if (message.state !== SearchState.UNSPECIFIED) {
      writer.uint32(40).int32(searchStateToNumber(message.state));
    }
    if (message.repository !== undefined) {
      TargetRepository.encode(message.repository, writer.uint32(50).fork()).ldelim();
    }
    if (message.durationMs !== undefined) {
      writer.uint32(56).uint64(message.durationMs);
    }
    if (message.resultCount !== 0) {
      writer.uint32(64).uint32(message.resultCount);
    }
    if (message.exitState !== undefined) {
      writer.uint32(74).string(message.exitState);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RepositorySearchStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRepositorySearchStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fullName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.commit = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.results.push(SearchResult.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.url = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.state = searchStateFromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.repository = TargetRepository.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.durationMs = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.resultCount = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.exitState = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RepositorySearchStatus {
    return {
      fullName: isSet(object.fullName) ? globalThis.String(object.fullName) : "",
      commit: isSet(object.commit) ? globalThis.String(object.commit) : "",
      results: globalThis.Array.isArray(object?.results)
        ? object.results.map((e: any) => SearchResult.fromJSON(e))
        : [],
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      state: isSet(object.state) ? searchStateFromJSON(object.state) : SearchState.UNSPECIFIED,
      repository: isSet(object.repository) ? TargetRepository.fromJSON(object.repository) : undefined,
      durationMs: isSet(object.durationMs) ? globalThis.Number(object.durationMs) : undefined,
      resultCount: isSet(object.resultCount) ? globalThis.Number(object.resultCount) : 0,
      exitState: isSet(object.exitState) ? globalThis.String(object.exitState) : undefined,
    };
  },

  toJSON(message: RepositorySearchStatus): unknown {
    const obj: any = {};
    if (message.fullName !== "") {
      obj.fullName = message.fullName;
    }
    if (message.commit !== "") {
      obj.commit = message.commit;
    }
    if (message.results?.length) {
      obj.results = message.results.map((e) => SearchResult.toJSON(e));
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.state !== SearchState.UNSPECIFIED) {
      obj.state = searchStateToJSON(message.state);
    }
    if (message.repository !== undefined) {
      obj.repository = TargetRepository.toJSON(message.repository);
    }
    if (message.durationMs !== undefined) {
      obj.durationMs = Math.round(message.durationMs);
    }
    if (message.resultCount !== 0) {
      obj.resultCount = Math.round(message.resultCount);
    }
    if (message.exitState !== undefined) {
      obj.exitState = message.exitState;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RepositorySearchStatus>, I>>(base?: I): RepositorySearchStatus {
    return RepositorySearchStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RepositorySearchStatus>, I>>(object: I): RepositorySearchStatus {
    const message = createBaseRepositorySearchStatus();
    message.fullName = object.fullName ?? "";
    message.commit = object.commit ?? "";
    message.results = object.results?.map((e) => SearchResult.fromPartial(e)) || [];
    message.url = object.url ?? "";
    message.state = object.state ?? SearchState.UNSPECIFIED;
    message.repository = (object.repository !== undefined && object.repository !== null)
      ? TargetRepository.fromPartial(object.repository)
      : undefined;
    message.durationMs = object.durationMs ?? undefined;
    message.resultCount = object.resultCount ?? 0;
    message.exitState = object.exitState ?? undefined;
    return message;
  },
};

function createBaseSearchResult(): SearchResult {
  return { filename: "", line: 0, snippet: "", url: "", match: undefined };
}

export const SearchResult = {
  encode(message: SearchResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.filename !== "") {
      writer.uint32(10).string(message.filename);
    }
    if (message.line !== 0) {
      writer.uint32(16).uint32(message.line);
    }
    if (message.snippet !== "") {
      writer.uint32(26).string(message.snippet);
    }
    if (message.url !== "") {
      writer.uint32(34).string(message.url);
    }
    if (message.match !== undefined) {
      CliMatch.encode(message.match, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.filename = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.line = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.snippet = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.url = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.match = CliMatch.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchResult {
    return {
      filename: isSet(object.filename) ? globalThis.String(object.filename) : "",
      line: isSet(object.line) ? globalThis.Number(object.line) : 0,
      snippet: isSet(object.snippet) ? globalThis.String(object.snippet) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      match: isSet(object.match) ? CliMatch.fromJSON(object.match) : undefined,
    };
  },

  toJSON(message: SearchResult): unknown {
    const obj: any = {};
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    if (message.line !== 0) {
      obj.line = Math.round(message.line);
    }
    if (message.snippet !== "") {
      obj.snippet = message.snippet;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.match !== undefined) {
      obj.match = CliMatch.toJSON(message.match);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchResult>, I>>(base?: I): SearchResult {
    return SearchResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchResult>, I>>(object: I): SearchResult {
    const message = createBaseSearchResult();
    message.filename = object.filename ?? "";
    message.line = object.line ?? 0;
    message.snippet = object.snippet ?? "";
    message.url = object.url ?? "";
    message.match = (object.match !== undefined && object.match !== null)
      ? CliMatch.fromPartial(object.match)
      : undefined;
    return message;
  },
};

function createBaseGetSearchableReposRequest(): GetSearchableReposRequest {
  return { deploymentId: 0 };
}

export const GetSearchableReposRequest = {
  encode(message: GetSearchableReposRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint32(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSearchableReposRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSearchableReposRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSearchableReposRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0 };
  },

  toJSON(message: GetSearchableReposRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSearchableReposRequest>, I>>(base?: I): GetSearchableReposRequest {
    return GetSearchableReposRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSearchableReposRequest>, I>>(object: I): GetSearchableReposRequest {
    const message = createBaseGetSearchableReposRequest();
    message.deploymentId = object.deploymentId ?? 0;
    return message;
  },
};

function createBaseTargetRepository(): TargetRepository {
  return { fullName: "", cloneUrl: "", private: false };
}

export const TargetRepository = {
  encode(message: TargetRepository, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fullName !== "") {
      writer.uint32(10).string(message.fullName);
    }
    if (message.cloneUrl !== "") {
      writer.uint32(18).string(message.cloneUrl);
    }
    if (message.private !== false) {
      writer.uint32(24).bool(message.private);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TargetRepository {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTargetRepository();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fullName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cloneUrl = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.private = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TargetRepository {
    return {
      fullName: isSet(object.fullName) ? globalThis.String(object.fullName) : "",
      cloneUrl: isSet(object.cloneUrl) ? globalThis.String(object.cloneUrl) : "",
      private: isSet(object.private) ? globalThis.Boolean(object.private) : false,
    };
  },

  toJSON(message: TargetRepository): unknown {
    const obj: any = {};
    if (message.fullName !== "") {
      obj.fullName = message.fullName;
    }
    if (message.cloneUrl !== "") {
      obj.cloneUrl = message.cloneUrl;
    }
    if (message.private !== false) {
      obj.private = message.private;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TargetRepository>, I>>(base?: I): TargetRepository {
    return TargetRepository.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TargetRepository>, I>>(object: I): TargetRepository {
    const message = createBaseTargetRepository();
    message.fullName = object.fullName ?? "";
    message.cloneUrl = object.cloneUrl ?? "";
    message.private = object.private ?? false;
    return message;
  },
};

function createBaseGetSearchableReposResponse(): GetSearchableReposResponse {
  return { repositories: [], needsAppInstall: false };
}

export const GetSearchableReposResponse = {
  encode(message: GetSearchableReposResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.repositories) {
      GithubRepository.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.needsAppInstall !== false) {
      writer.uint32(16).bool(message.needsAppInstall);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSearchableReposResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSearchableReposResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.repositories.push(GithubRepository.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.needsAppInstall = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSearchableReposResponse {
    return {
      repositories: globalThis.Array.isArray(object?.repositories)
        ? object.repositories.map((e: any) => GithubRepository.fromJSON(e))
        : [],
      needsAppInstall: isSet(object.needsAppInstall) ? globalThis.Boolean(object.needsAppInstall) : false,
    };
  },

  toJSON(message: GetSearchableReposResponse): unknown {
    const obj: any = {};
    if (message.repositories?.length) {
      obj.repositories = message.repositories.map((e) => GithubRepository.toJSON(e));
    }
    if (message.needsAppInstall !== false) {
      obj.needsAppInstall = message.needsAppInstall;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSearchableReposResponse>, I>>(base?: I): GetSearchableReposResponse {
    return GetSearchableReposResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSearchableReposResponse>, I>>(object: I): GetSearchableReposResponse {
    const message = createBaseGetSearchableReposResponse();
    message.repositories = object.repositories?.map((e) => GithubRepository.fromPartial(e)) || [];
    message.needsAppInstall = object.needsAppInstall ?? false;
    return message;
  },
};

function createBaseSearchPublicReposRequest(): SearchPublicReposRequest {
  return { deploymentId: 0, query: "" };
}

export const SearchPublicReposRequest = {
  encode(message: SearchPublicReposRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint32(message.deploymentId);
    }
    if (message.query !== "") {
      writer.uint32(18).string(message.query);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchPublicReposRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchPublicReposRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.query = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchPublicReposRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      query: isSet(object.query) ? globalThis.String(object.query) : "",
    };
  },

  toJSON(message: SearchPublicReposRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.query !== "") {
      obj.query = message.query;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchPublicReposRequest>, I>>(base?: I): SearchPublicReposRequest {
    return SearchPublicReposRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchPublicReposRequest>, I>>(object: I): SearchPublicReposRequest {
    const message = createBaseSearchPublicReposRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.query = object.query ?? "";
    return message;
  },
};

function createBaseGithubRepositorySearchResponse(): GithubRepositorySearchResponse {
  return { items: [], incompleteResults: false, totalCount: 0 };
}

export const GithubRepositorySearchResponse = {
  encode(message: GithubRepositorySearchResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.items) {
      GithubRepository.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.incompleteResults !== false) {
      writer.uint32(16).bool(message.incompleteResults);
    }
    if (message.totalCount !== 0) {
      writer.uint32(24).uint32(message.totalCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GithubRepositorySearchResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGithubRepositorySearchResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(GithubRepository.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.incompleteResults = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.totalCount = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GithubRepositorySearchResponse {
    return {
      items: globalThis.Array.isArray(object?.items) ? object.items.map((e: any) => GithubRepository.fromJSON(e)) : [],
      incompleteResults: isSet(object.incompleteResults) ? globalThis.Boolean(object.incompleteResults) : false,
      totalCount: isSet(object.totalCount) ? globalThis.Number(object.totalCount) : 0,
    };
  },

  toJSON(message: GithubRepositorySearchResponse): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => GithubRepository.toJSON(e));
    }
    if (message.incompleteResults !== false) {
      obj.incompleteResults = message.incompleteResults;
    }
    if (message.totalCount !== 0) {
      obj.totalCount = Math.round(message.totalCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GithubRepositorySearchResponse>, I>>(base?: I): GithubRepositorySearchResponse {
    return GithubRepositorySearchResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GithubRepositorySearchResponse>, I>>(
    object: I,
  ): GithubRepositorySearchResponse {
    const message = createBaseGithubRepositorySearchResponse();
    message.items = object.items?.map((e) => GithubRepository.fromPartial(e)) || [];
    message.incompleteResults = object.incompleteResults ?? false;
    message.totalCount = object.totalCount ?? 0;
    return message;
  },
};

function createBaseSearchPublicReposResponse(): SearchPublicReposResponse {
  return { repositories: [] };
}

export const SearchPublicReposResponse = {
  encode(message: SearchPublicReposResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.repositories) {
      GithubRepository.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchPublicReposResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchPublicReposResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.repositories.push(GithubRepository.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchPublicReposResponse {
    return {
      repositories: globalThis.Array.isArray(object?.repositories)
        ? object.repositories.map((e: any) => GithubRepository.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SearchPublicReposResponse): unknown {
    const obj: any = {};
    if (message.repositories?.length) {
      obj.repositories = message.repositories.map((e) => GithubRepository.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchPublicReposResponse>, I>>(base?: I): SearchPublicReposResponse {
    return SearchPublicReposResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchPublicReposResponse>, I>>(object: I): SearchPublicReposResponse {
    const message = createBaseSearchPublicReposResponse();
    message.repositories = object.repositories?.map((e) => GithubRepository.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSavedSearchRule(): SavedSearchRule {
  return { id: 0, savedSearchId: "", rule: "" };
}

export const SavedSearchRule = {
  encode(message: SavedSearchRule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.savedSearchId !== "") {
      writer.uint32(18).string(message.savedSearchId);
    }
    if (message.rule !== "") {
      writer.uint32(26).string(message.rule);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SavedSearchRule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSavedSearchRule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.savedSearchId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.rule = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SavedSearchRule {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      savedSearchId: isSet(object.savedSearchId) ? globalThis.String(object.savedSearchId) : "",
      rule: isSet(object.rule) ? globalThis.String(object.rule) : "",
    };
  },

  toJSON(message: SavedSearchRule): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.savedSearchId !== "") {
      obj.savedSearchId = message.savedSearchId;
    }
    if (message.rule !== "") {
      obj.rule = message.rule;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SavedSearchRule>, I>>(base?: I): SavedSearchRule {
    return SavedSearchRule.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SavedSearchRule>, I>>(object: I): SavedSearchRule {
    const message = createBaseSavedSearchRule();
    message.id = object.id ?? 0;
    message.savedSearchId = object.savedSearchId ?? "";
    message.rule = object.rule ?? "";
    return message;
  },
};

function createBaseSavedSearch(): SavedSearch {
  return { id: 0, name: "", deploymentId: 0, createdBy: "", createdAtMs: 0, searchIds: [], createdAt: undefined };
}

export const SavedSearch = {
  encode(message: SavedSearch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.deploymentId !== 0) {
      writer.uint32(24).uint32(message.deploymentId);
    }
    if (message.createdBy !== "") {
      writer.uint32(34).string(message.createdBy);
    }
    if (message.createdAtMs !== 0) {
      writer.uint32(40).uint32(message.createdAtMs);
    }
    for (const v of message.searchIds) {
      writer.uint32(50).string(v!);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SavedSearch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSavedSearch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.deploymentId = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.createdAtMs = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.searchIds.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SavedSearch {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      createdBy: isSet(object.createdBy) ? globalThis.String(object.createdBy) : "",
      createdAtMs: isSet(object.createdAtMs) ? globalThis.Number(object.createdAtMs) : 0,
      searchIds: globalThis.Array.isArray(object?.searchIds)
        ? object.searchIds.map((e: any) => globalThis.String(e))
        : [],
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
    };
  },

  toJSON(message: SavedSearch): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    if (message.createdAtMs !== 0) {
      obj.createdAtMs = Math.round(message.createdAtMs);
    }
    if (message.searchIds?.length) {
      obj.searchIds = message.searchIds;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SavedSearch>, I>>(base?: I): SavedSearch {
    return SavedSearch.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SavedSearch>, I>>(object: I): SavedSearch {
    const message = createBaseSavedSearch();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.deploymentId = object.deploymentId ?? 0;
    message.createdBy = object.createdBy ?? "";
    message.createdAtMs = object.createdAtMs ?? 0;
    message.searchIds = object.searchIds?.map((e) => e) || [];
    message.createdAt = object.createdAt ?? undefined;
    return message;
  },
};

function createBaseCreateSavedSearchRequest(): CreateSavedSearchRequest {
  return { name: "", deploymentId: 0, searchId: "" };
}

export const CreateSavedSearchRequest = {
  encode(message: CreateSavedSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.deploymentId !== 0) {
      writer.uint32(16).uint32(message.deploymentId);
    }
    if (message.searchId !== "") {
      writer.uint32(26).string(message.searchId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSavedSearchRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSavedSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deploymentId = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.searchId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSavedSearchRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      searchId: isSet(object.searchId) ? globalThis.String(object.searchId) : "",
    };
  },

  toJSON(message: CreateSavedSearchRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.searchId !== "") {
      obj.searchId = message.searchId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateSavedSearchRequest>, I>>(base?: I): CreateSavedSearchRequest {
    return CreateSavedSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateSavedSearchRequest>, I>>(object: I): CreateSavedSearchRequest {
    const message = createBaseCreateSavedSearchRequest();
    message.name = object.name ?? "";
    message.deploymentId = object.deploymentId ?? 0;
    message.searchId = object.searchId ?? "";
    return message;
  },
};

function createBaseCreateSavedSearchResponse(): CreateSavedSearchResponse {
  return { savedSearch: undefined };
}

export const CreateSavedSearchResponse = {
  encode(message: CreateSavedSearchResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.savedSearch !== undefined) {
      SavedSearch.encode(message.savedSearch, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSavedSearchResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSavedSearchResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.savedSearch = SavedSearch.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSavedSearchResponse {
    return { savedSearch: isSet(object.savedSearch) ? SavedSearch.fromJSON(object.savedSearch) : undefined };
  },

  toJSON(message: CreateSavedSearchResponse): unknown {
    const obj: any = {};
    if (message.savedSearch !== undefined) {
      obj.savedSearch = SavedSearch.toJSON(message.savedSearch);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateSavedSearchResponse>, I>>(base?: I): CreateSavedSearchResponse {
    return CreateSavedSearchResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateSavedSearchResponse>, I>>(object: I): CreateSavedSearchResponse {
    const message = createBaseCreateSavedSearchResponse();
    message.savedSearch = (object.savedSearch !== undefined && object.savedSearch !== null)
      ? SavedSearch.fromPartial(object.savedSearch)
      : undefined;
    return message;
  },
};

function createBaseGetSavedSearchRequest(): GetSavedSearchRequest {
  return { savedSearchId: 0 };
}

export const GetSavedSearchRequest = {
  encode(message: GetSavedSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.savedSearchId !== 0) {
      writer.uint32(8).uint32(message.savedSearchId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSavedSearchRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSavedSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.savedSearchId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSavedSearchRequest {
    return { savedSearchId: isSet(object.savedSearchId) ? globalThis.Number(object.savedSearchId) : 0 };
  },

  toJSON(message: GetSavedSearchRequest): unknown {
    const obj: any = {};
    if (message.savedSearchId !== 0) {
      obj.savedSearchId = Math.round(message.savedSearchId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSavedSearchRequest>, I>>(base?: I): GetSavedSearchRequest {
    return GetSavedSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSavedSearchRequest>, I>>(object: I): GetSavedSearchRequest {
    const message = createBaseGetSavedSearchRequest();
    message.savedSearchId = object.savedSearchId ?? 0;
    return message;
  },
};

function createBaseGetSavedSearchResponse(): GetSavedSearchResponse {
  return { savedSearch: undefined };
}

export const GetSavedSearchResponse = {
  encode(message: GetSavedSearchResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.savedSearch !== undefined) {
      SavedSearch.encode(message.savedSearch, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSavedSearchResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSavedSearchResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.savedSearch = SavedSearch.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSavedSearchResponse {
    return { savedSearch: isSet(object.savedSearch) ? SavedSearch.fromJSON(object.savedSearch) : undefined };
  },

  toJSON(message: GetSavedSearchResponse): unknown {
    const obj: any = {};
    if (message.savedSearch !== undefined) {
      obj.savedSearch = SavedSearch.toJSON(message.savedSearch);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSavedSearchResponse>, I>>(base?: I): GetSavedSearchResponse {
    return GetSavedSearchResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSavedSearchResponse>, I>>(object: I): GetSavedSearchResponse {
    const message = createBaseGetSavedSearchResponse();
    message.savedSearch = (object.savedSearch !== undefined && object.savedSearch !== null)
      ? SavedSearch.fromPartial(object.savedSearch)
      : undefined;
    return message;
  },
};

function createBaseUpdateSavedSearchRequest(): UpdateSavedSearchRequest {
  return { name: "", deploymentId: 0, savedSearchId: 0 };
}

export const UpdateSavedSearchRequest = {
  encode(message: UpdateSavedSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.deploymentId !== 0) {
      writer.uint32(16).uint32(message.deploymentId);
    }
    if (message.savedSearchId !== 0) {
      writer.uint32(24).uint32(message.savedSearchId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSavedSearchRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSavedSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deploymentId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.savedSearchId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateSavedSearchRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      savedSearchId: isSet(object.savedSearchId) ? globalThis.Number(object.savedSearchId) : 0,
    };
  },

  toJSON(message: UpdateSavedSearchRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.savedSearchId !== 0) {
      obj.savedSearchId = Math.round(message.savedSearchId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateSavedSearchRequest>, I>>(base?: I): UpdateSavedSearchRequest {
    return UpdateSavedSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateSavedSearchRequest>, I>>(object: I): UpdateSavedSearchRequest {
    const message = createBaseUpdateSavedSearchRequest();
    message.name = object.name ?? "";
    message.deploymentId = object.deploymentId ?? 0;
    message.savedSearchId = object.savedSearchId ?? 0;
    return message;
  },
};

function createBaseUpdateSavedSearchResponse(): UpdateSavedSearchResponse {
  return { savedSearch: undefined };
}

export const UpdateSavedSearchResponse = {
  encode(message: UpdateSavedSearchResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.savedSearch !== undefined) {
      SavedSearch.encode(message.savedSearch, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSavedSearchResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSavedSearchResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.savedSearch = SavedSearch.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateSavedSearchResponse {
    return { savedSearch: isSet(object.savedSearch) ? SavedSearch.fromJSON(object.savedSearch) : undefined };
  },

  toJSON(message: UpdateSavedSearchResponse): unknown {
    const obj: any = {};
    if (message.savedSearch !== undefined) {
      obj.savedSearch = SavedSearch.toJSON(message.savedSearch);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateSavedSearchResponse>, I>>(base?: I): UpdateSavedSearchResponse {
    return UpdateSavedSearchResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateSavedSearchResponse>, I>>(object: I): UpdateSavedSearchResponse {
    const message = createBaseUpdateSavedSearchResponse();
    message.savedSearch = (object.savedSearch !== undefined && object.savedSearch !== null)
      ? SavedSearch.fromPartial(object.savedSearch)
      : undefined;
    return message;
  },
};

function createBaseDeleteSavedSearchRequest(): DeleteSavedSearchRequest {
  return { savedSearchId: 0 };
}

export const DeleteSavedSearchRequest = {
  encode(message: DeleteSavedSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.savedSearchId !== 0) {
      writer.uint32(8).uint32(message.savedSearchId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteSavedSearchRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteSavedSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.savedSearchId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteSavedSearchRequest {
    return { savedSearchId: isSet(object.savedSearchId) ? globalThis.Number(object.savedSearchId) : 0 };
  },

  toJSON(message: DeleteSavedSearchRequest): unknown {
    const obj: any = {};
    if (message.savedSearchId !== 0) {
      obj.savedSearchId = Math.round(message.savedSearchId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteSavedSearchRequest>, I>>(base?: I): DeleteSavedSearchRequest {
    return DeleteSavedSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteSavedSearchRequest>, I>>(object: I): DeleteSavedSearchRequest {
    const message = createBaseDeleteSavedSearchRequest();
    message.savedSearchId = object.savedSearchId ?? 0;
    return message;
  },
};

function createBaseDeleteSavedSearchResponse(): DeleteSavedSearchResponse {
  return { savedSearch: undefined };
}

export const DeleteSavedSearchResponse = {
  encode(message: DeleteSavedSearchResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.savedSearch !== undefined) {
      SavedSearch.encode(message.savedSearch, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteSavedSearchResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteSavedSearchResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.savedSearch = SavedSearch.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteSavedSearchResponse {
    return { savedSearch: isSet(object.savedSearch) ? SavedSearch.fromJSON(object.savedSearch) : undefined };
  },

  toJSON(message: DeleteSavedSearchResponse): unknown {
    const obj: any = {};
    if (message.savedSearch !== undefined) {
      obj.savedSearch = SavedSearch.toJSON(message.savedSearch);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteSavedSearchResponse>, I>>(base?: I): DeleteSavedSearchResponse {
    return DeleteSavedSearchResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteSavedSearchResponse>, I>>(object: I): DeleteSavedSearchResponse {
    const message = createBaseDeleteSavedSearchResponse();
    message.savedSearch = (object.savedSearch !== undefined && object.savedSearch !== null)
      ? SavedSearch.fromPartial(object.savedSearch)
      : undefined;
    return message;
  },
};

function createBaseGetSavedSearchesRequest(): GetSavedSearchesRequest {
  return { deploymentId: 0 };
}

export const GetSavedSearchesRequest = {
  encode(message: GetSavedSearchesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint32(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSavedSearchesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSavedSearchesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSavedSearchesRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0 };
  },

  toJSON(message: GetSavedSearchesRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSavedSearchesRequest>, I>>(base?: I): GetSavedSearchesRequest {
    return GetSavedSearchesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSavedSearchesRequest>, I>>(object: I): GetSavedSearchesRequest {
    const message = createBaseGetSavedSearchesRequest();
    message.deploymentId = object.deploymentId ?? 0;
    return message;
  },
};

function createBaseGetSavedSearchesResponse(): GetSavedSearchesResponse {
  return { savedSearches: [] };
}

export const GetSavedSearchesResponse = {
  encode(message: GetSavedSearchesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.savedSearches) {
      SavedSearch.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSavedSearchesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSavedSearchesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.savedSearches.push(SavedSearch.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSavedSearchesResponse {
    return {
      savedSearches: globalThis.Array.isArray(object?.savedSearches)
        ? object.savedSearches.map((e: any) => SavedSearch.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetSavedSearchesResponse): unknown {
    const obj: any = {};
    if (message.savedSearches?.length) {
      obj.savedSearches = message.savedSearches.map((e) => SavedSearch.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSavedSearchesResponse>, I>>(base?: I): GetSavedSearchesResponse {
    return GetSavedSearchesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSavedSearchesResponse>, I>>(object: I): GetSavedSearchesResponse {
    const message = createBaseGetSavedSearchesResponse();
    message.savedSearches = object.savedSearches?.map((e) => SavedSearch.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRerunSavedSearchRequest(): RerunSavedSearchRequest {
  return { savedSearchId: 0 };
}

export const RerunSavedSearchRequest = {
  encode(message: RerunSavedSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.savedSearchId !== 0) {
      writer.uint32(8).uint32(message.savedSearchId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RerunSavedSearchRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRerunSavedSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.savedSearchId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RerunSavedSearchRequest {
    return { savedSearchId: isSet(object.savedSearchId) ? globalThis.Number(object.savedSearchId) : 0 };
  },

  toJSON(message: RerunSavedSearchRequest): unknown {
    const obj: any = {};
    if (message.savedSearchId !== 0) {
      obj.savedSearchId = Math.round(message.savedSearchId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RerunSavedSearchRequest>, I>>(base?: I): RerunSavedSearchRequest {
    return RerunSavedSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RerunSavedSearchRequest>, I>>(object: I): RerunSavedSearchRequest {
    const message = createBaseRerunSavedSearchRequest();
    message.savedSearchId = object.savedSearchId ?? 0;
    return message;
  },
};

function createBaseRerunSavedSearchResponse(): RerunSavedSearchResponse {
  return { savedSearch: undefined, createSearchResponse: undefined };
}

export const RerunSavedSearchResponse = {
  encode(message: RerunSavedSearchResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.savedSearch !== undefined) {
      SavedSearch.encode(message.savedSearch, writer.uint32(10).fork()).ldelim();
    }
    if (message.createSearchResponse !== undefined) {
      CreateSearchResponse.encode(message.createSearchResponse, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RerunSavedSearchResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRerunSavedSearchResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.savedSearch = SavedSearch.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createSearchResponse = CreateSearchResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RerunSavedSearchResponse {
    return {
      savedSearch: isSet(object.savedSearch) ? SavedSearch.fromJSON(object.savedSearch) : undefined,
      createSearchResponse: isSet(object.createSearchResponse)
        ? CreateSearchResponse.fromJSON(object.createSearchResponse)
        : undefined,
    };
  },

  toJSON(message: RerunSavedSearchResponse): unknown {
    const obj: any = {};
    if (message.savedSearch !== undefined) {
      obj.savedSearch = SavedSearch.toJSON(message.savedSearch);
    }
    if (message.createSearchResponse !== undefined) {
      obj.createSearchResponse = CreateSearchResponse.toJSON(message.createSearchResponse);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RerunSavedSearchResponse>, I>>(base?: I): RerunSavedSearchResponse {
    return RerunSavedSearchResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RerunSavedSearchResponse>, I>>(object: I): RerunSavedSearchResponse {
    const message = createBaseRerunSavedSearchResponse();
    message.savedSearch = (object.savedSearch !== undefined && object.savedSearch !== null)
      ? SavedSearch.fromPartial(object.savedSearch)
      : undefined;
    message.createSearchResponse = (object.createSearchResponse !== undefined && object.createSearchResponse !== null)
      ? CreateSearchResponse.fromPartial(object.createSearchResponse)
      : undefined;
    return message;
  },
};

function createBaseFeedbackRequest(): FeedbackRequest {
  return { deploymentId: 0, message: "", referrer: "" };
}

export const FeedbackRequest = {
  encode(message: FeedbackRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint32(message.deploymentId);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    if (message.referrer !== "") {
      writer.uint32(26).string(message.referrer);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeedbackRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeedbackRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.referrer = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeedbackRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      referrer: isSet(object.referrer) ? globalThis.String(object.referrer) : "",
    };
  },

  toJSON(message: FeedbackRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.referrer !== "") {
      obj.referrer = message.referrer;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FeedbackRequest>, I>>(base?: I): FeedbackRequest {
    return FeedbackRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FeedbackRequest>, I>>(object: I): FeedbackRequest {
    const message = createBaseFeedbackRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.message = object.message ?? "";
    message.referrer = object.referrer ?? "";
    return message;
  },
};

function createBaseFeedbackResponse(): FeedbackResponse {
  return { message: "" };
}

export const FeedbackResponse = {
  encode(message: FeedbackResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeedbackResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeedbackResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeedbackResponse {
    return { message: isSet(object.message) ? globalThis.String(object.message) : "" };
  },

  toJSON(message: FeedbackResponse): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FeedbackResponse>, I>>(base?: I): FeedbackResponse {
    return FeedbackResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FeedbackResponse>, I>>(object: I): FeedbackResponse {
    const message = createBaseFeedbackResponse();
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseSearchRepoProgressUpdate(): SearchRepoProgressUpdate {
  return { searchId: "", repoName: "", state: SearchState.UNSPECIFIED, resultCount: 0, timestamp: undefined };
}

export const SearchRepoProgressUpdate = {
  encode(message: SearchRepoProgressUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchId !== "") {
      writer.uint32(10).string(message.searchId);
    }
    if (message.repoName !== "") {
      writer.uint32(18).string(message.repoName);
    }
    if (message.state !== SearchState.UNSPECIFIED) {
      writer.uint32(24).int32(searchStateToNumber(message.state));
    }
    if (message.resultCount !== 0) {
      writer.uint32(32).uint32(message.resultCount);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchRepoProgressUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchRepoProgressUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.repoName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.state = searchStateFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.resultCount = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchRepoProgressUpdate {
    return {
      searchId: isSet(object.searchId) ? globalThis.String(object.searchId) : "",
      repoName: isSet(object.repoName) ? globalThis.String(object.repoName) : "",
      state: isSet(object.state) ? searchStateFromJSON(object.state) : SearchState.UNSPECIFIED,
      resultCount: isSet(object.resultCount) ? globalThis.Number(object.resultCount) : 0,
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
    };
  },

  toJSON(message: SearchRepoProgressUpdate): unknown {
    const obj: any = {};
    if (message.searchId !== "") {
      obj.searchId = message.searchId;
    }
    if (message.repoName !== "") {
      obj.repoName = message.repoName;
    }
    if (message.state !== SearchState.UNSPECIFIED) {
      obj.state = searchStateToJSON(message.state);
    }
    if (message.resultCount !== 0) {
      obj.resultCount = Math.round(message.resultCount);
    }
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchRepoProgressUpdate>, I>>(base?: I): SearchRepoProgressUpdate {
    return SearchRepoProgressUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchRepoProgressUpdate>, I>>(object: I): SearchRepoProgressUpdate {
    const message = createBaseSearchRepoProgressUpdate();
    message.searchId = object.searchId ?? "";
    message.repoName = object.repoName ?? "";
    message.state = object.state ?? SearchState.UNSPECIFIED;
    message.resultCount = object.resultCount ?? 0;
    message.timestamp = object.timestamp ?? undefined;
    return message;
  },
};

export interface SearchService {
  createSearch(request: CreateSearchRequest): Promise<CreateSearchResponse>;
  getSearch(request: GetSearchRequest): Promise<GetSearchResponse>;
  getSearchRepo(request: GetSearchRepoRequest): Promise<GetSearchRepoResponse>;
  getSearchableRepos(request: GetSearchableReposRequest): Promise<GetSearchableReposResponse>;
  searchPublicRepos(request: SearchPublicReposRequest): Promise<SearchPublicReposResponse>;
  getSavedSearches(request: GetSavedSearchesRequest): Promise<GetSavedSearchesResponse>;
  createSavedSearch(request: CreateSavedSearchRequest): Promise<CreateSavedSearchResponse>;
  rerunSavedSearch(request: RerunSavedSearchRequest): Promise<RerunSavedSearchResponse>;
  getSavedSearch(request: GetSavedSearchRequest): Promise<GetSavedSearchResponse>;
  updateSavedSearch(request: UpdateSavedSearchRequest): Promise<UpdateSavedSearchResponse>;
  deleteSavedSearch(request: DeleteSavedSearchRequest): Promise<DeleteSavedSearchResponse>;
  feedback(request: FeedbackRequest): Promise<FeedbackResponse>;
}

export const SearchServiceServiceName = "protos.search.v1.SearchService";
export class SearchServiceClientImpl implements SearchService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || SearchServiceServiceName;
    this.rpc = rpc;
    this.createSearch = this.createSearch.bind(this);
    this.getSearch = this.getSearch.bind(this);
    this.getSearchRepo = this.getSearchRepo.bind(this);
    this.getSearchableRepos = this.getSearchableRepos.bind(this);
    this.searchPublicRepos = this.searchPublicRepos.bind(this);
    this.getSavedSearches = this.getSavedSearches.bind(this);
    this.createSavedSearch = this.createSavedSearch.bind(this);
    this.rerunSavedSearch = this.rerunSavedSearch.bind(this);
    this.getSavedSearch = this.getSavedSearch.bind(this);
    this.updateSavedSearch = this.updateSavedSearch.bind(this);
    this.deleteSavedSearch = this.deleteSavedSearch.bind(this);
    this.feedback = this.feedback.bind(this);
  }
  createSearch(request: CreateSearchRequest): Promise<CreateSearchResponse> {
    const data = CreateSearchRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateSearch", data);
    return promise.then((data) => CreateSearchResponse.decode(_m0.Reader.create(data)));
  }

  getSearch(request: GetSearchRequest): Promise<GetSearchResponse> {
    const data = GetSearchRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetSearch", data);
    return promise.then((data) => GetSearchResponse.decode(_m0.Reader.create(data)));
  }

  getSearchRepo(request: GetSearchRepoRequest): Promise<GetSearchRepoResponse> {
    const data = GetSearchRepoRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetSearchRepo", data);
    return promise.then((data) => GetSearchRepoResponse.decode(_m0.Reader.create(data)));
  }

  getSearchableRepos(request: GetSearchableReposRequest): Promise<GetSearchableReposResponse> {
    const data = GetSearchableReposRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetSearchableRepos", data);
    return promise.then((data) => GetSearchableReposResponse.decode(_m0.Reader.create(data)));
  }

  searchPublicRepos(request: SearchPublicReposRequest): Promise<SearchPublicReposResponse> {
    const data = SearchPublicReposRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "SearchPublicRepos", data);
    return promise.then((data) => SearchPublicReposResponse.decode(_m0.Reader.create(data)));
  }

  getSavedSearches(request: GetSavedSearchesRequest): Promise<GetSavedSearchesResponse> {
    const data = GetSavedSearchesRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetSavedSearches", data);
    return promise.then((data) => GetSavedSearchesResponse.decode(_m0.Reader.create(data)));
  }

  createSavedSearch(request: CreateSavedSearchRequest): Promise<CreateSavedSearchResponse> {
    const data = CreateSavedSearchRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateSavedSearch", data);
    return promise.then((data) => CreateSavedSearchResponse.decode(_m0.Reader.create(data)));
  }

  rerunSavedSearch(request: RerunSavedSearchRequest): Promise<RerunSavedSearchResponse> {
    const data = RerunSavedSearchRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "RerunSavedSearch", data);
    return promise.then((data) => RerunSavedSearchResponse.decode(_m0.Reader.create(data)));
  }

  getSavedSearch(request: GetSavedSearchRequest): Promise<GetSavedSearchResponse> {
    const data = GetSavedSearchRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetSavedSearch", data);
    return promise.then((data) => GetSavedSearchResponse.decode(_m0.Reader.create(data)));
  }

  updateSavedSearch(request: UpdateSavedSearchRequest): Promise<UpdateSavedSearchResponse> {
    const data = UpdateSavedSearchRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "UpdateSavedSearch", data);
    return promise.then((data) => UpdateSavedSearchResponse.decode(_m0.Reader.create(data)));
  }

  deleteSavedSearch(request: DeleteSavedSearchRequest): Promise<DeleteSavedSearchResponse> {
    const data = DeleteSavedSearchRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "DeleteSavedSearch", data);
    return promise.then((data) => DeleteSavedSearchResponse.decode(_m0.Reader.create(data)));
  }

  feedback(request: FeedbackRequest): Promise<FeedbackResponse> {
    const data = FeedbackRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "Feedback", data);
    return promise.then((data) => FeedbackResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
