import { Modal as MantineModal } from "@mantine/core";

import classes from "./Modal.module.css";

export const Modal = MantineModal.extend({
  defaultProps: {
    centered: true,
    overlayProps: {
      opacity: 0.55,
      blur: 3,
      color: "#909296", // dark-2
    },
    keepMounted: false,
  },
  classNames: {
    title: classes.title,
  },
});
