import styled from "styled-components";

export const SectionList = styled.ul`
  max-height: 25vh;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;

  & > li:not(:first-child) {
    border-top: 1px solid #ccc;
  }
`;
