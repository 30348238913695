import { JsonValue } from "@bufbuild/protobuf";
import { useQuery } from "@tanstack/react-query";

import { ListUserOrgsResponse } from "@protos/auth/v1/user";
import { Deployment } from "@protos/deployment/v1/deployment";
import { authGet } from "@shared/api/lib/auth/auth";
import { deploymentProtoToOrg, queryClient } from "@shared/utils";

import { useUser } from "../useUser";

const fetchUserOrgs = async (
  userId: number | string
): Promise<Deployment[]> => {
  const result = await authGet<JsonValue>(
    `/api/auth/users/${userId}/deployments`
  );
  return ListUserOrgsResponse.fromJSON(result).orgs;
};

/**
 * Get the list of authorized deployments for the user.
 *
 * @returns a query list of authorized deployments for the user
 */
export const useUserOrgs = () => {
  const [user] = useUser();
  const userId = user?.user_id;
  return useQuery(
    {
      queryKey: ["userDeployments", userId],
      queryFn: async () =>
        (await fetchUserOrgs(userId!)).map((d) => deploymentProtoToOrg(d)),
      enabled: !!userId,
      staleTime: 60000, // 60s until stale (defaults to 0 otherwise)
    },
    // Explicitly pass in queryClient because we need to call this hook in App.tsx before the QueryClientProvider is defined
    queryClient
  );
};
