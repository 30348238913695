type BundleContent = {
  rule: string;
  target: string;
};

export function getLocalStorageBundleContent(): BundleContent | null {
  try {
    const wipRuleString = localStorage.getItem("WORK_IN_PROGRESS_RULE");

    if (wipRuleString) {
      const wipRule = JSON.parse(wipRuleString);
      if (
        wipRule &&
        // Being rather safe when getting data from localStorage
        typeof wipRule.rule === "string" &&
        typeof wipRule.target === "string"
      ) {
        return wipRule;
      }
    }
  } catch (e) {
    console.error("Could not retrieve last rule from local storage: ", e);
  }
  return null;
}

export function setLocalStorageBundle(value: BundleContent) {
  try {
    localStorage.setItem("WORK_IN_PROGRESS_RULE", JSON.stringify(value));
  } catch (e) {
    console.error("Could not set last rule in local storage: ", e);
  }
}

export function clearLocalStorageBundle() {
  try {
    localStorage.removeItem("WORK_IN_PROGRESS_RULE");
  } catch (e) {
    console.error("Could not remove last rule from local storage: ", e);
  }
}
