// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/ticketing/v1/ticketing.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "protos.ticketing.v1";

export interface ExternalTicket {
  /** URL of the external ticket. */
  url: string;
  /** Identifier of the external ticket (e.g. for Jira, something like OPS-158). */
  externalSlug: string;
}

export interface TicketAttempt {
  /** Date that the ticket was last attempted */
  attemptedAt:
    | Date
    | undefined;
  /** The (error) message returned when the attempt was made */
  responseMessage: string;
}

function createBaseExternalTicket(): ExternalTicket {
  return { url: "", externalSlug: "" };
}

export const ExternalTicket = {
  encode(message: ExternalTicket, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.externalSlug !== "") {
      writer.uint32(18).string(message.externalSlug);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExternalTicket {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExternalTicket();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.externalSlug = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExternalTicket {
    return {
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      externalSlug: isSet(object.externalSlug) ? globalThis.String(object.externalSlug) : "",
    };
  },

  toJSON(message: ExternalTicket): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.externalSlug !== "") {
      obj.externalSlug = message.externalSlug;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExternalTicket>, I>>(base?: I): ExternalTicket {
    return ExternalTicket.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExternalTicket>, I>>(object: I): ExternalTicket {
    const message = createBaseExternalTicket();
    message.url = object.url ?? "";
    message.externalSlug = object.externalSlug ?? "";
    return message;
  },
};

function createBaseTicketAttempt(): TicketAttempt {
  return { attemptedAt: undefined, responseMessage: "" };
}

export const TicketAttempt = {
  encode(message: TicketAttempt, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.attemptedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.attemptedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.responseMessage !== "") {
      writer.uint32(18).string(message.responseMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TicketAttempt {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTicketAttempt();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.attemptedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.responseMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TicketAttempt {
    return {
      attemptedAt: isSet(object.attemptedAt) ? fromJsonTimestamp(object.attemptedAt) : undefined,
      responseMessage: isSet(object.responseMessage) ? globalThis.String(object.responseMessage) : "",
    };
  },

  toJSON(message: TicketAttempt): unknown {
    const obj: any = {};
    if (message.attemptedAt !== undefined) {
      obj.attemptedAt = message.attemptedAt.toISOString();
    }
    if (message.responseMessage !== "") {
      obj.responseMessage = message.responseMessage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TicketAttempt>, I>>(base?: I): TicketAttempt {
    return TicketAttempt.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TicketAttempt>, I>>(object: I): TicketAttempt {
    const message = createBaseTicketAttempt();
    message.attemptedAt = object.attemptedAt ?? undefined;
    message.responseMessage = object.responseMessage ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
