import { CheckVersionResponse } from "@protos/cli/v1/check_version";

import { checkStatus } from "../fetch";

export const fetchCheckVersion = (): Promise<CheckVersionResponse> => {
  return fetch("/api/check-version", {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then(checkStatus)
    .then((r) => r.json());
};
