import { Button } from "@mantine/core";

interface AcceptFixButtonProps {
  reject?: boolean;
  handleAccept: (reject: boolean) => void;
}

export const AcceptFixButton = ({
  reject,
  handleAccept,
}: AcceptFixButtonProps) => {
  return (
    <Button
      onClick={() => {
        handleAccept(reject || false);
      }}
      size="compact-lg"
      variant="light"
    >
      {reject ? "Reject" : "Accept"} Fix
    </Button>
  );
};
