import React, { useMemo } from "react";
import { faCirclePlus, faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import { SimpleGrid, Skeleton } from "@mantine/core";

import { Deployment } from "@protos/deployment/v1/deployment";
import {
  useJoinableOrgs,
  useJoinDeploymentMutation,
  useUser,
} from "@shared/hooks";
import { DeploymentAction } from "@shared/types";
import { scmEntityName } from "@shared/utils";

import { SetupCard } from "../SetupCard";

interface OrgActionSelectorProps {
  setAction: (action: DeploymentAction) => void;
}

export const OrgActionSelector: React.FC<OrgActionSelectorProps> = ({
  setAction,
}) => {
  const [user] = useUser();
  const { data: joinableOrgs, isLoading } = useJoinableOrgs();
  const { mutate: joinDeployment } = useJoinDeploymentMutation();

  const onJoin = async (deployment: Deployment) => {
    joinDeployment({ deploymentId: deployment.id, redirectOnSuccess: true });
  };

  const providerDescription = useMemo(
    () =>
      joinableOrgs?.length === 0
        ? `No organizations to join.`
        : joinableOrgs?.length === 1
        ? `Join the ${
            joinableOrgs[0].name
          } organization linked to your ${scmEntityName(
            user?.auth_provider_type
          )}`
        : `You can join any existing Semgrep organizations if you are a member of its associated ${scmEntityName(
            user?.auth_provider_type
          )}`,
    [joinableOrgs, user?.auth_provider_type]
  );

  return (
    <SimpleGrid cols={2}>
      <SetupCard
        title="Create an organization"
        description="Once your Semgrep organization is created, you can link your organization to an SCM or add an SSO provider from the Settings page."
        icon={faCirclePlus}
        onClick={() => setAction("create")}
      />
      {isLoading ? (
        <Skeleton height="100%" />
      ) : (
        <SetupCard
          title="Join an organization"
          description={providerDescription}
          isLoading={isLoading}
          icon={faUserPlus}
          disabled={joinableOrgs?.length === 0}
          onClick={() =>
            joinableOrgs?.length === 1
              ? onJoin(joinableOrgs[0])
              : setAction("join")
          }
        />
      )}
    </SimpleGrid>
  );
};
