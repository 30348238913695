// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/projects/v1/projects.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import {
  BulkEndpointError,
  ScmType,
  scmTypeFromJSON,
  scmTypeToJSON,
  scmTypeToNumber,
  SortDirection,
  sortDirectionFromJSON,
  sortDirectionToJSON,
  sortDirectionToNumber,
} from "../../common/v1/common";
import { RepoScope, repoScopeFromJSON, repoScopeToJSON, repoScopeToNumber } from "../../permissions/v2/scopes";
import { DependencyParserErrors } from "../../sca/v1/sca";
import { ScanSummary } from "../../scan/v1/scan";

export const protobufPackage = "protos.projects.v1";

export enum Provider {
  UNSPECIFIED = "PROVIDER_UNSPECIFIED",
  GITHUB = "PROVIDER_GITHUB",
}

export function providerFromJSON(object: any): Provider {
  switch (object) {
    case 0:
    case "PROVIDER_UNSPECIFIED":
      return Provider.UNSPECIFIED;
    case 1:
    case "PROVIDER_GITHUB":
      return Provider.GITHUB;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Provider");
  }
}

export function providerToJSON(object: Provider): string {
  switch (object) {
    case Provider.UNSPECIFIED:
      return "PROVIDER_UNSPECIFIED";
    case Provider.GITHUB:
      return "PROVIDER_GITHUB";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Provider");
  }
}

export function providerToNumber(object: Provider): number {
  switch (object) {
    case Provider.UNSPECIFIED:
      return 0;
    case Provider.GITHUB:
      return 1;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Provider");
  }
}

export enum RepoSortBy {
  UNSPECIFIED = "REPO_SORT_BY_UNSPECIFIED",
  NAME = "REPO_SORT_BY_NAME",
  LATEST_SCAN_AT = "REPO_SORT_BY_LATEST_SCAN_AT",
}

export function repoSortByFromJSON(object: any): RepoSortBy {
  switch (object) {
    case 0:
    case "REPO_SORT_BY_UNSPECIFIED":
      return RepoSortBy.UNSPECIFIED;
    case 1:
    case "REPO_SORT_BY_NAME":
      return RepoSortBy.NAME;
    case 2:
    case "REPO_SORT_BY_LATEST_SCAN_AT":
      return RepoSortBy.LATEST_SCAN_AT;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RepoSortBy");
  }
}

export function repoSortByToJSON(object: RepoSortBy): string {
  switch (object) {
    case RepoSortBy.UNSPECIFIED:
      return "REPO_SORT_BY_UNSPECIFIED";
    case RepoSortBy.NAME:
      return "REPO_SORT_BY_NAME";
    case RepoSortBy.LATEST_SCAN_AT:
      return "REPO_SORT_BY_LATEST_SCAN_AT";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RepoSortBy");
  }
}

export function repoSortByToNumber(object: RepoSortBy): number {
  switch (object) {
    case RepoSortBy.UNSPECIFIED:
      return 0;
    case RepoSortBy.NAME:
      return 1;
    case RepoSortBy.LATEST_SCAN_AT:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RepoSortBy");
  }
}

export interface PolicySummary {
  slug: string;
  name: string;
}

export interface ProductIgnoredFilesMap {
  sast: string[];
  sca: string[];
  secrets: string[];
}

export interface ManagedScanSettings {
  diffScan: boolean;
  numConfigs?: number | undefined;
  fullScan: boolean;
}

export interface RepositoryRefItem {
  repoRefId: number;
  ref: string;
  isDefault: boolean;
  isPrimary: boolean;
}

/** TODO: reuse RepositoryRefItem for this */
export interface PrimaryRefItem {
  id?: number | undefined;
  ref?: string | undefined;
  isOverride: boolean;
}

/** TODO: reuse RepositoryRefItem for this */
export interface SimpleRefItem {
  id?: number | undefined;
  ref?: string | undefined;
}

export interface ScaInfo {
  /** a map from file name to the number of dependencies */
  dependencyCounts: { [key: string]: number };
}

export interface ScaInfo_DependencyCountsEntry {
  key: string;
  value: number;
}

export interface Repository {
  /** The unique identifier for the repository in the database */
  id: number;
  /** The name of this repository in Semgrep */
  name: string;
  /** The tags that have been applied to this repository */
  tags: string[];
  /** When this repository was first set up */
  provisionedAt?:
    | Date
    | undefined;
  /** The most-recent scan to complete while scanning this repository */
  latestScan?:
    | ScanSummary
    | undefined;
  /** The deployment_id that owns this repository and its scans */
  deploymentId: number;
  /** The URL for this repository (in an SCM like GitHub) */
  url?:
    | string
    | undefined;
  /** The list of files NOT to scan in this repository, if not the default */
  ignoredFiles: string[];
  /** The list of files NOT to scan just for particular products */
  productIgnoredFiles?:
    | ProductIgnoredFilesMap
    | undefined;
  /** The unique identifier of the first scan of this repository, if it exists */
  firstScanId?:
    | number
    | undefined;
  /** Whether or not this repository has been archived in the SCM */
  isArchived: boolean;
  /** Whether or not this repository has been disconnected from the SCM */
  isDisconnected: boolean;
  /** Whether or not code access has been granted for this repository */
  hasCodeAccess: boolean;
  /** A summary of the repository-wide SMS settings */
  managedScans?:
    | ManagedScanSettings
    | undefined;
  /** Whether or not the repository seems set up for Semgrep scanning of some kind */
  isSetup: boolean;
  /** When the repository most-recently synced with the SCM (in milliseconds!) */
  scmLastSyncedAt?:
    | number
    | undefined;
  /** For users with RBAC, the permissions available to this user on this repo */
  authorizedScopes: RepoScope[];
  /** The SCM for this user (TODO: there must be a better version of this supporting more than GitHub) */
  provider?:
    | Provider
    | undefined;
  /**
   * The name of the default branch (Deprecated: this has been replaced with default_ref_info)
   *
   * @deprecated
   */
  defaultBranch?:
    | string
    | undefined;
  /** All of the scanned refs for this project */
  refs: RepositoryRefItem[];
  /** The information about the primary ref, if it is set and requested */
  primaryRef?:
    | PrimaryRefItem
    | undefined;
  /** The information about the default ref, if it is known and requested */
  defaultRef?:
    | SimpleRefItem
    | undefined;
  /** TODO: possibly move this inside of the latest_scan object? */
  lastScannedRef?:
    | SimpleRefItem
    | undefined;
  /** parser errors from the latest full SCA scan */
  latestFullScaScanParserErrors?:
    | DependencyParserErrors
    | undefined;
  /** when the last full SCA scan was completed */
  latestFullScaScanAt?:
    | Date
    | undefined;
  /** information specific to Supply Chain */
  scaInfo:
    | ScaInfo
    | undefined;
  /** ids of possible duplicate repos, if is is requested */
  linkedRepos: number[];
  /** The SCM type */
  scmType: ScmType;
}

export interface RepoFilters {
  /** filter repos by name */
  search?:
    | string
    | undefined;
  /** filter repos by if they have code access */
  codeAccess?:
    | boolean
    | undefined;
  /** filter repos by when they were last scanned */
  lastScanSince?:
    | Date
    | undefined;
  /** filter repos by if they have been set up */
  setup?:
    | boolean
    | undefined;
  /** filter repos by if they are archived */
  archived?:
    | boolean
    | undefined;
  /** filter repos by id */
  ids: number[];
  /** filter repos by if they have SMS set up, whether or not it is currently enabled */
  hasSmsSetup?: boolean | undefined;
}

export interface SingleBulkRepoChange {
  /** if the tags should be updated */
  updateTags: boolean;
  /** what the tags should be updated to, will be ignored if update_tags is false */
  tags: string[];
  /** managed scans settings to update */
  managedScans: ManagedScanSettings | undefined;
}

export interface BulkEditReposItem {
  repoId: number;
  change: SingleBulkRepoChange | undefined;
}

export interface BulkEditReposRequest {
  deploymentId: number;
  changes: BulkEditReposItem[];
}

export interface BulkEditReposResponse {
  /** once we make protos for Repository, this should be updated to return whole Repo objects */
  updatedRepoNames: string[];
}

export interface EditRepoRequest {
  deploymentId: number;
  idOrName: string;
  name?: string | undefined;
  managedScans?: ManagedScanSettings | undefined;
}

export interface EditRepoResponse {
  repo: Repository | undefined;
}

export interface GetRepoRequest {
  deploymentId: number;
  idOrName: string;
}

export interface GetRepoResponse {
  repo: Repository | undefined;
}

export interface SearchReposRequest {
  deploymentId: number;
  cursor?: string | undefined;
  limit?:
    | number
    | undefined;
  /** what field the repos should be sorted by */
  sortBy?:
    | RepoSortBy
    | undefined;
  /** what direction the repos should be sorted in */
  sortDirection?:
    | SortDirection
    | undefined;
  /**
   * filter repos by name, deprecated in favor of filters
   *
   * @deprecated
   */
  search?:
    | string
    | undefined;
  /**
   * filter repos by if they have code access, deprecated in favor of filters
   *
   * @deprecated
   */
  codeAccess?:
    | boolean
    | undefined;
  /**
   * filter repos by when they were last scanned, deprecated in favor of
   * filters
   *
   * @deprecated
   */
  lastScanSince?:
    | Date
    | undefined;
  /**
   * filter repos by if they have been set up, deprecated in favor of filters
   *
   * @deprecated
   */
  setup?:
    | boolean
    | undefined;
  /**
   * filter repos by if they are archived, deprecated in favor of filters
   *
   * @deprecated
   */
  archived?:
    | boolean
    | undefined;
  /** filters */
  filters?: RepoFilters | undefined;
}

export interface SearchReposResponse {
  /** List of repositories */
  repos: Repository[];
  /** Cursor to paginate through the results */
  cursor?: string | undefined;
}

export interface BulkApplyRepoUpdateRequest {
  /** id of the deployment */
  deploymentId: number;
  /** filters to specify which repos to provision */
  filters:
    | RepoFilters
    | undefined;
  /** repos to exclude from the changes */
  excludeIds: number[];
  /** change to apply */
  change: SingleBulkRepoChange | undefined;
}

export interface BulkApplyRepoUpdateResponse {
  /** list of ids of the repos that were updated */
  updatedRepoIds: number[];
  /** Error messages */
  errors: BulkEndpointError[];
}

export interface BulkAddRepoSecretRequest {
  /** id of the deployment */
  deploymentId: number;
  /** filters to specify which repos to add the secret to */
  filters:
    | RepoFilters
    | undefined;
  /** repos to exclude from the changes */
  excludeIds: number[];
}

export interface BulkAddRepoSecretResponse {
  /** map of repo ids to the token ids */
  repoTokenIds: { [key: number]: number };
  /** errors and the ids of the impacted repositories */
  errors: BulkEndpointError[];
}

export interface BulkAddRepoSecretResponse_RepoTokenIdsEntry {
  key: number;
  value: number;
}

export interface GhaOptions {
  publishUrl: string;
  prEnabled: boolean;
  prSendComments: boolean;
  pushEnabled: boolean;
  pushBranches: string[];
  pushPaths: string[];
  cronEnabled: boolean;
  useSelfHostedRunners?: boolean | undefined;
  useCheckoutV2?: boolean | undefined;
}

export interface BulkProvisionReposRequest {
  /** id of the deployment */
  deploymentId: number;
  /** filters to specify which repos to provision */
  filters:
    | RepoFilters
    | undefined;
  /** repos to exclude from the changes */
  excludeIds: number[];
  /** GitHub action options to apply to the repos */
  ghaOptions: GhaOptions | undefined;
}

export interface BulkProvisionReposResponse {
  /** map of repo ids to the files that were provisioned */
  repoFileUrls: { [key: number]: string };
  /** errors and the ids of the impacted repositories */
  errors: BulkEndpointError[];
}

export interface BulkProvisionReposResponse_RepoFileUrlsEntry {
  key: number;
  value: string;
}

export interface GetReposByTagRequest {
  deploymentId: number;
}

export interface GetReposByTagResponse {
  tagIdToRepoIds: { [key: number]: GetReposByTagResponse_RepoIdList };
}

export interface GetReposByTagResponse_RepoIdList {
  repoIds: number[];
}

export interface GetReposByTagResponse_TagIdToRepoIdsEntry {
  key: number;
  value: GetReposByTagResponse_RepoIdList | undefined;
}

export interface GetReposCountRequest {
  deploymentId: number;
  filters: RepoFilters | undefined;
}

export interface GetReposCountResponse {
  count: number;
}

function createBasePolicySummary(): PolicySummary {
  return { slug: "", name: "" };
}

export const PolicySummary = {
  encode(message: PolicySummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.slug !== "") {
      writer.uint32(10).string(message.slug);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PolicySummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolicySummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.slug = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PolicySummary {
    return {
      slug: isSet(object.slug) ? globalThis.String(object.slug) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: PolicySummary): unknown {
    const obj: any = {};
    if (message.slug !== "") {
      obj.slug = message.slug;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PolicySummary>, I>>(base?: I): PolicySummary {
    return PolicySummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PolicySummary>, I>>(object: I): PolicySummary {
    const message = createBasePolicySummary();
    message.slug = object.slug ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseProductIgnoredFilesMap(): ProductIgnoredFilesMap {
  return { sast: [], sca: [], secrets: [] };
}

export const ProductIgnoredFilesMap = {
  encode(message: ProductIgnoredFilesMap, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.sast) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.sca) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.secrets) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProductIgnoredFilesMap {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProductIgnoredFilesMap();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sast.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sca.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.secrets.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProductIgnoredFilesMap {
    return {
      sast: globalThis.Array.isArray(object?.sast) ? object.sast.map((e: any) => globalThis.String(e)) : [],
      sca: globalThis.Array.isArray(object?.sca) ? object.sca.map((e: any) => globalThis.String(e)) : [],
      secrets: globalThis.Array.isArray(object?.secrets) ? object.secrets.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ProductIgnoredFilesMap): unknown {
    const obj: any = {};
    if (message.sast?.length) {
      obj.sast = message.sast;
    }
    if (message.sca?.length) {
      obj.sca = message.sca;
    }
    if (message.secrets?.length) {
      obj.secrets = message.secrets;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIgnoredFilesMap>, I>>(base?: I): ProductIgnoredFilesMap {
    return ProductIgnoredFilesMap.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProductIgnoredFilesMap>, I>>(object: I): ProductIgnoredFilesMap {
    const message = createBaseProductIgnoredFilesMap();
    message.sast = object.sast?.map((e) => e) || [];
    message.sca = object.sca?.map((e) => e) || [];
    message.secrets = object.secrets?.map((e) => e) || [];
    return message;
  },
};

function createBaseManagedScanSettings(): ManagedScanSettings {
  return { diffScan: false, numConfigs: undefined, fullScan: false };
}

export const ManagedScanSettings = {
  encode(message: ManagedScanSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.diffScan !== false) {
      writer.uint32(8).bool(message.diffScan);
    }
    if (message.numConfigs !== undefined) {
      writer.uint32(16).int32(message.numConfigs);
    }
    if (message.fullScan !== false) {
      writer.uint32(24).bool(message.fullScan);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManagedScanSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManagedScanSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.diffScan = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.numConfigs = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.fullScan = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManagedScanSettings {
    return {
      diffScan: isSet(object.diffScan) ? globalThis.Boolean(object.diffScan) : false,
      numConfigs: isSet(object.numConfigs) ? globalThis.Number(object.numConfigs) : undefined,
      fullScan: isSet(object.fullScan) ? globalThis.Boolean(object.fullScan) : false,
    };
  },

  toJSON(message: ManagedScanSettings): unknown {
    const obj: any = {};
    if (message.diffScan !== false) {
      obj.diffScan = message.diffScan;
    }
    if (message.numConfigs !== undefined) {
      obj.numConfigs = Math.round(message.numConfigs);
    }
    if (message.fullScan !== false) {
      obj.fullScan = message.fullScan;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManagedScanSettings>, I>>(base?: I): ManagedScanSettings {
    return ManagedScanSettings.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManagedScanSettings>, I>>(object: I): ManagedScanSettings {
    const message = createBaseManagedScanSettings();
    message.diffScan = object.diffScan ?? false;
    message.numConfigs = object.numConfigs ?? undefined;
    message.fullScan = object.fullScan ?? false;
    return message;
  },
};

function createBaseRepositoryRefItem(): RepositoryRefItem {
  return { repoRefId: 0, ref: "", isDefault: false, isPrimary: false };
}

export const RepositoryRefItem = {
  encode(message: RepositoryRefItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.repoRefId !== 0) {
      writer.uint32(8).uint64(message.repoRefId);
    }
    if (message.ref !== "") {
      writer.uint32(18).string(message.ref);
    }
    if (message.isDefault !== false) {
      writer.uint32(24).bool(message.isDefault);
    }
    if (message.isPrimary !== false) {
      writer.uint32(32).bool(message.isPrimary);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RepositoryRefItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRepositoryRefItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.repoRefId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ref = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isDefault = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isPrimary = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RepositoryRefItem {
    return {
      repoRefId: isSet(object.repoRefId) ? globalThis.Number(object.repoRefId) : 0,
      ref: isSet(object.ref) ? globalThis.String(object.ref) : "",
      isDefault: isSet(object.isDefault) ? globalThis.Boolean(object.isDefault) : false,
      isPrimary: isSet(object.isPrimary) ? globalThis.Boolean(object.isPrimary) : false,
    };
  },

  toJSON(message: RepositoryRefItem): unknown {
    const obj: any = {};
    if (message.repoRefId !== 0) {
      obj.repoRefId = Math.round(message.repoRefId);
    }
    if (message.ref !== "") {
      obj.ref = message.ref;
    }
    if (message.isDefault !== false) {
      obj.isDefault = message.isDefault;
    }
    if (message.isPrimary !== false) {
      obj.isPrimary = message.isPrimary;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RepositoryRefItem>, I>>(base?: I): RepositoryRefItem {
    return RepositoryRefItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RepositoryRefItem>, I>>(object: I): RepositoryRefItem {
    const message = createBaseRepositoryRefItem();
    message.repoRefId = object.repoRefId ?? 0;
    message.ref = object.ref ?? "";
    message.isDefault = object.isDefault ?? false;
    message.isPrimary = object.isPrimary ?? false;
    return message;
  },
};

function createBasePrimaryRefItem(): PrimaryRefItem {
  return { id: undefined, ref: undefined, isOverride: false };
}

export const PrimaryRefItem = {
  encode(message: PrimaryRefItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.ref !== undefined) {
      writer.uint32(18).string(message.ref);
    }
    if (message.isOverride !== false) {
      writer.uint32(24).bool(message.isOverride);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrimaryRefItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrimaryRefItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ref = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isOverride = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrimaryRefItem {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : undefined,
      ref: isSet(object.ref) ? globalThis.String(object.ref) : undefined,
      isOverride: isSet(object.isOverride) ? globalThis.Boolean(object.isOverride) : false,
    };
  },

  toJSON(message: PrimaryRefItem): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = Math.round(message.id);
    }
    if (message.ref !== undefined) {
      obj.ref = message.ref;
    }
    if (message.isOverride !== false) {
      obj.isOverride = message.isOverride;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PrimaryRefItem>, I>>(base?: I): PrimaryRefItem {
    return PrimaryRefItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PrimaryRefItem>, I>>(object: I): PrimaryRefItem {
    const message = createBasePrimaryRefItem();
    message.id = object.id ?? undefined;
    message.ref = object.ref ?? undefined;
    message.isOverride = object.isOverride ?? false;
    return message;
  },
};

function createBaseSimpleRefItem(): SimpleRefItem {
  return { id: undefined, ref: undefined };
}

export const SimpleRefItem = {
  encode(message: SimpleRefItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.ref !== undefined) {
      writer.uint32(18).string(message.ref);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimpleRefItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimpleRefItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ref = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SimpleRefItem {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : undefined,
      ref: isSet(object.ref) ? globalThis.String(object.ref) : undefined,
    };
  },

  toJSON(message: SimpleRefItem): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = Math.round(message.id);
    }
    if (message.ref !== undefined) {
      obj.ref = message.ref;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SimpleRefItem>, I>>(base?: I): SimpleRefItem {
    return SimpleRefItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SimpleRefItem>, I>>(object: I): SimpleRefItem {
    const message = createBaseSimpleRefItem();
    message.id = object.id ?? undefined;
    message.ref = object.ref ?? undefined;
    return message;
  },
};

function createBaseScaInfo(): ScaInfo {
  return { dependencyCounts: {} };
}

export const ScaInfo = {
  encode(message: ScaInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.dependencyCounts).forEach(([key, value]) => {
      ScaInfo_DependencyCountsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScaInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScaInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = ScaInfo_DependencyCountsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.dependencyCounts[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScaInfo {
    return {
      dependencyCounts: isObject(object.dependencyCounts)
        ? Object.entries(object.dependencyCounts).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ScaInfo): unknown {
    const obj: any = {};
    if (message.dependencyCounts) {
      const entries = Object.entries(message.dependencyCounts);
      if (entries.length > 0) {
        obj.dependencyCounts = {};
        entries.forEach(([k, v]) => {
          obj.dependencyCounts[k] = Math.round(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScaInfo>, I>>(base?: I): ScaInfo {
    return ScaInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScaInfo>, I>>(object: I): ScaInfo {
    const message = createBaseScaInfo();
    message.dependencyCounts = Object.entries(object.dependencyCounts ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseScaInfo_DependencyCountsEntry(): ScaInfo_DependencyCountsEntry {
  return { key: "", value: 0 };
}

export const ScaInfo_DependencyCountsEntry = {
  encode(message: ScaInfo_DependencyCountsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScaInfo_DependencyCountsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScaInfo_DependencyCountsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScaInfo_DependencyCountsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: ScaInfo_DependencyCountsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScaInfo_DependencyCountsEntry>, I>>(base?: I): ScaInfo_DependencyCountsEntry {
    return ScaInfo_DependencyCountsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScaInfo_DependencyCountsEntry>, I>>(
    object: I,
  ): ScaInfo_DependencyCountsEntry {
    const message = createBaseScaInfo_DependencyCountsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseRepository(): Repository {
  return {
    id: 0,
    name: "",
    tags: [],
    provisionedAt: undefined,
    latestScan: undefined,
    deploymentId: 0,
    url: undefined,
    ignoredFiles: [],
    productIgnoredFiles: undefined,
    firstScanId: undefined,
    isArchived: false,
    isDisconnected: false,
    hasCodeAccess: false,
    managedScans: undefined,
    isSetup: false,
    scmLastSyncedAt: undefined,
    authorizedScopes: [],
    provider: undefined,
    defaultBranch: undefined,
    refs: [],
    primaryRef: undefined,
    defaultRef: undefined,
    lastScannedRef: undefined,
    latestFullScaScanParserErrors: undefined,
    latestFullScaScanAt: undefined,
    scaInfo: undefined,
    linkedRepos: [],
    scmType: ScmType.UNSPECIFIED,
  };
}

export const Repository = {
  encode(message: Repository, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.tags) {
      writer.uint32(26).string(v!);
    }
    if (message.provisionedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.provisionedAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.latestScan !== undefined) {
      ScanSummary.encode(message.latestScan, writer.uint32(42).fork()).ldelim();
    }
    if (message.deploymentId !== 0) {
      writer.uint32(48).int64(message.deploymentId);
    }
    if (message.url !== undefined) {
      writer.uint32(58).string(message.url);
    }
    for (const v of message.ignoredFiles) {
      writer.uint32(66).string(v!);
    }
    if (message.productIgnoredFiles !== undefined) {
      ProductIgnoredFilesMap.encode(message.productIgnoredFiles, writer.uint32(74).fork()).ldelim();
    }
    if (message.firstScanId !== undefined) {
      writer.uint32(80).uint64(message.firstScanId);
    }
    if (message.isArchived !== false) {
      writer.uint32(88).bool(message.isArchived);
    }
    if (message.isDisconnected !== false) {
      writer.uint32(96).bool(message.isDisconnected);
    }
    if (message.hasCodeAccess !== false) {
      writer.uint32(104).bool(message.hasCodeAccess);
    }
    if (message.managedScans !== undefined) {
      ManagedScanSettings.encode(message.managedScans, writer.uint32(114).fork()).ldelim();
    }
    if (message.isSetup !== false) {
      writer.uint32(120).bool(message.isSetup);
    }
    if (message.scmLastSyncedAt !== undefined) {
      writer.uint32(133).float(message.scmLastSyncedAt);
    }
    writer.uint32(138).fork();
    for (const v of message.authorizedScopes) {
      writer.int32(repoScopeToNumber(v));
    }
    writer.ldelim();
    if (message.provider !== undefined) {
      writer.uint32(144).int32(providerToNumber(message.provider));
    }
    if (message.defaultBranch !== undefined) {
      writer.uint32(154).string(message.defaultBranch);
    }
    for (const v of message.refs) {
      RepositoryRefItem.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    if (message.primaryRef !== undefined) {
      PrimaryRefItem.encode(message.primaryRef, writer.uint32(170).fork()).ldelim();
    }
    if (message.defaultRef !== undefined) {
      SimpleRefItem.encode(message.defaultRef, writer.uint32(178).fork()).ldelim();
    }
    if (message.lastScannedRef !== undefined) {
      SimpleRefItem.encode(message.lastScannedRef, writer.uint32(186).fork()).ldelim();
    }
    if (message.latestFullScaScanParserErrors !== undefined) {
      DependencyParserErrors.encode(message.latestFullScaScanParserErrors, writer.uint32(194).fork()).ldelim();
    }
    if (message.latestFullScaScanAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latestFullScaScanAt), writer.uint32(202).fork()).ldelim();
    }
    if (message.scaInfo !== undefined) {
      ScaInfo.encode(message.scaInfo, writer.uint32(210).fork()).ldelim();
    }
    writer.uint32(218).fork();
    for (const v of message.linkedRepos) {
      writer.uint64(v);
    }
    writer.ldelim();
    if (message.scmType !== ScmType.UNSPECIFIED) {
      writer.uint32(224).int32(scmTypeToNumber(message.scmType));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Repository {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRepository();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.provisionedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.latestScan = ScanSummary.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.url = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.ignoredFiles.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.productIgnoredFiles = ProductIgnoredFilesMap.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.firstScanId = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.isArchived = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.isDisconnected = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.hasCodeAccess = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.managedScans = ManagedScanSettings.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.isSetup = reader.bool();
          continue;
        case 16:
          if (tag !== 133) {
            break;
          }

          message.scmLastSyncedAt = reader.float();
          continue;
        case 17:
          if (tag === 136) {
            message.authorizedScopes.push(repoScopeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 138) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.authorizedScopes.push(repoScopeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.provider = providerFromJSON(reader.int32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.defaultBranch = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.refs.push(RepositoryRefItem.decode(reader, reader.uint32()));
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.primaryRef = PrimaryRefItem.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.defaultRef = SimpleRefItem.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.lastScannedRef = SimpleRefItem.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.latestFullScaScanParserErrors = DependencyParserErrors.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.latestFullScaScanAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.scaInfo = ScaInfo.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag === 216) {
            message.linkedRepos.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 218) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.linkedRepos.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.scmType = scmTypeFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Repository {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      provisionedAt: isSet(object.provisionedAt) ? fromJsonTimestamp(object.provisionedAt) : undefined,
      latestScan: isSet(object.latestScan) ? ScanSummary.fromJSON(object.latestScan) : undefined,
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      url: isSet(object.url) ? globalThis.String(object.url) : undefined,
      ignoredFiles: globalThis.Array.isArray(object?.ignoredFiles)
        ? object.ignoredFiles.map((e: any) => globalThis.String(e))
        : [],
      productIgnoredFiles: isSet(object.productIgnoredFiles)
        ? ProductIgnoredFilesMap.fromJSON(object.productIgnoredFiles)
        : undefined,
      firstScanId: isSet(object.firstScanId) ? globalThis.Number(object.firstScanId) : undefined,
      isArchived: isSet(object.isArchived) ? globalThis.Boolean(object.isArchived) : false,
      isDisconnected: isSet(object.isDisconnected) ? globalThis.Boolean(object.isDisconnected) : false,
      hasCodeAccess: isSet(object.hasCodeAccess) ? globalThis.Boolean(object.hasCodeAccess) : false,
      managedScans: isSet(object.managedScans) ? ManagedScanSettings.fromJSON(object.managedScans) : undefined,
      isSetup: isSet(object.isSetup) ? globalThis.Boolean(object.isSetup) : false,
      scmLastSyncedAt: isSet(object.scmLastSyncedAt) ? globalThis.Number(object.scmLastSyncedAt) : undefined,
      authorizedScopes: globalThis.Array.isArray(object?.authorizedScopes)
        ? object.authorizedScopes.map((e: any) => repoScopeFromJSON(e))
        : [],
      provider: isSet(object.provider) ? providerFromJSON(object.provider) : undefined,
      defaultBranch: isSet(object.defaultBranch) ? globalThis.String(object.defaultBranch) : undefined,
      refs: globalThis.Array.isArray(object?.refs) ? object.refs.map((e: any) => RepositoryRefItem.fromJSON(e)) : [],
      primaryRef: isSet(object.primaryRef) ? PrimaryRefItem.fromJSON(object.primaryRef) : undefined,
      defaultRef: isSet(object.defaultRef) ? SimpleRefItem.fromJSON(object.defaultRef) : undefined,
      lastScannedRef: isSet(object.lastScannedRef) ? SimpleRefItem.fromJSON(object.lastScannedRef) : undefined,
      latestFullScaScanParserErrors: isSet(object.latestFullScaScanParserErrors)
        ? DependencyParserErrors.fromJSON(object.latestFullScaScanParserErrors)
        : undefined,
      latestFullScaScanAt: isSet(object.latestFullScaScanAt)
        ? fromJsonTimestamp(object.latestFullScaScanAt)
        : undefined,
      scaInfo: isSet(object.scaInfo) ? ScaInfo.fromJSON(object.scaInfo) : undefined,
      linkedRepos: globalThis.Array.isArray(object?.linkedRepos)
        ? object.linkedRepos.map((e: any) => globalThis.Number(e))
        : [],
      scmType: isSet(object.scmType) ? scmTypeFromJSON(object.scmType) : ScmType.UNSPECIFIED,
    };
  },

  toJSON(message: Repository): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.provisionedAt !== undefined) {
      obj.provisionedAt = message.provisionedAt.toISOString();
    }
    if (message.latestScan !== undefined) {
      obj.latestScan = ScanSummary.toJSON(message.latestScan);
    }
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.url !== undefined) {
      obj.url = message.url;
    }
    if (message.ignoredFiles?.length) {
      obj.ignoredFiles = message.ignoredFiles;
    }
    if (message.productIgnoredFiles !== undefined) {
      obj.productIgnoredFiles = ProductIgnoredFilesMap.toJSON(message.productIgnoredFiles);
    }
    if (message.firstScanId !== undefined) {
      obj.firstScanId = Math.round(message.firstScanId);
    }
    if (message.isArchived !== false) {
      obj.isArchived = message.isArchived;
    }
    if (message.isDisconnected !== false) {
      obj.isDisconnected = message.isDisconnected;
    }
    if (message.hasCodeAccess !== false) {
      obj.hasCodeAccess = message.hasCodeAccess;
    }
    if (message.managedScans !== undefined) {
      obj.managedScans = ManagedScanSettings.toJSON(message.managedScans);
    }
    if (message.isSetup !== false) {
      obj.isSetup = message.isSetup;
    }
    if (message.scmLastSyncedAt !== undefined) {
      obj.scmLastSyncedAt = message.scmLastSyncedAt;
    }
    if (message.authorizedScopes?.length) {
      obj.authorizedScopes = message.authorizedScopes.map((e) => repoScopeToJSON(e));
    }
    if (message.provider !== undefined) {
      obj.provider = providerToJSON(message.provider);
    }
    if (message.defaultBranch !== undefined) {
      obj.defaultBranch = message.defaultBranch;
    }
    if (message.refs?.length) {
      obj.refs = message.refs.map((e) => RepositoryRefItem.toJSON(e));
    }
    if (message.primaryRef !== undefined) {
      obj.primaryRef = PrimaryRefItem.toJSON(message.primaryRef);
    }
    if (message.defaultRef !== undefined) {
      obj.defaultRef = SimpleRefItem.toJSON(message.defaultRef);
    }
    if (message.lastScannedRef !== undefined) {
      obj.lastScannedRef = SimpleRefItem.toJSON(message.lastScannedRef);
    }
    if (message.latestFullScaScanParserErrors !== undefined) {
      obj.latestFullScaScanParserErrors = DependencyParserErrors.toJSON(message.latestFullScaScanParserErrors);
    }
    if (message.latestFullScaScanAt !== undefined) {
      obj.latestFullScaScanAt = message.latestFullScaScanAt.toISOString();
    }
    if (message.scaInfo !== undefined) {
      obj.scaInfo = ScaInfo.toJSON(message.scaInfo);
    }
    if (message.linkedRepos?.length) {
      obj.linkedRepos = message.linkedRepos.map((e) => Math.round(e));
    }
    if (message.scmType !== ScmType.UNSPECIFIED) {
      obj.scmType = scmTypeToJSON(message.scmType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Repository>, I>>(base?: I): Repository {
    return Repository.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Repository>, I>>(object: I): Repository {
    const message = createBaseRepository();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    message.provisionedAt = object.provisionedAt ?? undefined;
    message.latestScan = (object.latestScan !== undefined && object.latestScan !== null)
      ? ScanSummary.fromPartial(object.latestScan)
      : undefined;
    message.deploymentId = object.deploymentId ?? 0;
    message.url = object.url ?? undefined;
    message.ignoredFiles = object.ignoredFiles?.map((e) => e) || [];
    message.productIgnoredFiles = (object.productIgnoredFiles !== undefined && object.productIgnoredFiles !== null)
      ? ProductIgnoredFilesMap.fromPartial(object.productIgnoredFiles)
      : undefined;
    message.firstScanId = object.firstScanId ?? undefined;
    message.isArchived = object.isArchived ?? false;
    message.isDisconnected = object.isDisconnected ?? false;
    message.hasCodeAccess = object.hasCodeAccess ?? false;
    message.managedScans = (object.managedScans !== undefined && object.managedScans !== null)
      ? ManagedScanSettings.fromPartial(object.managedScans)
      : undefined;
    message.isSetup = object.isSetup ?? false;
    message.scmLastSyncedAt = object.scmLastSyncedAt ?? undefined;
    message.authorizedScopes = object.authorizedScopes?.map((e) => e) || [];
    message.provider = object.provider ?? undefined;
    message.defaultBranch = object.defaultBranch ?? undefined;
    message.refs = object.refs?.map((e) => RepositoryRefItem.fromPartial(e)) || [];
    message.primaryRef = (object.primaryRef !== undefined && object.primaryRef !== null)
      ? PrimaryRefItem.fromPartial(object.primaryRef)
      : undefined;
    message.defaultRef = (object.defaultRef !== undefined && object.defaultRef !== null)
      ? SimpleRefItem.fromPartial(object.defaultRef)
      : undefined;
    message.lastScannedRef = (object.lastScannedRef !== undefined && object.lastScannedRef !== null)
      ? SimpleRefItem.fromPartial(object.lastScannedRef)
      : undefined;
    message.latestFullScaScanParserErrors =
      (object.latestFullScaScanParserErrors !== undefined && object.latestFullScaScanParserErrors !== null)
        ? DependencyParserErrors.fromPartial(object.latestFullScaScanParserErrors)
        : undefined;
    message.latestFullScaScanAt = object.latestFullScaScanAt ?? undefined;
    message.scaInfo = (object.scaInfo !== undefined && object.scaInfo !== null)
      ? ScaInfo.fromPartial(object.scaInfo)
      : undefined;
    message.linkedRepos = object.linkedRepos?.map((e) => e) || [];
    message.scmType = object.scmType ?? ScmType.UNSPECIFIED;
    return message;
  },
};

function createBaseRepoFilters(): RepoFilters {
  return {
    search: undefined,
    codeAccess: undefined,
    lastScanSince: undefined,
    setup: undefined,
    archived: undefined,
    ids: [],
    hasSmsSetup: undefined,
  };
}

export const RepoFilters = {
  encode(message: RepoFilters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.search !== undefined) {
      writer.uint32(10).string(message.search);
    }
    if (message.codeAccess !== undefined) {
      writer.uint32(16).bool(message.codeAccess);
    }
    if (message.lastScanSince !== undefined) {
      Timestamp.encode(toTimestamp(message.lastScanSince), writer.uint32(26).fork()).ldelim();
    }
    if (message.setup !== undefined) {
      writer.uint32(32).bool(message.setup);
    }
    if (message.archived !== undefined) {
      writer.uint32(40).bool(message.archived);
    }
    writer.uint32(50).fork();
    for (const v of message.ids) {
      writer.uint64(v);
    }
    writer.ldelim();
    if (message.hasSmsSetup !== undefined) {
      writer.uint32(56).bool(message.hasSmsSetup);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RepoFilters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRepoFilters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.search = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.codeAccess = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastScanSince = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.setup = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.archived = reader.bool();
          continue;
        case 6:
          if (tag === 48) {
            message.ids.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.ids.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.hasSmsSetup = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RepoFilters {
    return {
      search: isSet(object.search) ? globalThis.String(object.search) : undefined,
      codeAccess: isSet(object.codeAccess) ? globalThis.Boolean(object.codeAccess) : undefined,
      lastScanSince: isSet(object.lastScanSince) ? fromJsonTimestamp(object.lastScanSince) : undefined,
      setup: isSet(object.setup) ? globalThis.Boolean(object.setup) : undefined,
      archived: isSet(object.archived) ? globalThis.Boolean(object.archived) : undefined,
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.Number(e)) : [],
      hasSmsSetup: isSet(object.hasSmsSetup) ? globalThis.Boolean(object.hasSmsSetup) : undefined,
    };
  },

  toJSON(message: RepoFilters): unknown {
    const obj: any = {};
    if (message.search !== undefined) {
      obj.search = message.search;
    }
    if (message.codeAccess !== undefined) {
      obj.codeAccess = message.codeAccess;
    }
    if (message.lastScanSince !== undefined) {
      obj.lastScanSince = message.lastScanSince.toISOString();
    }
    if (message.setup !== undefined) {
      obj.setup = message.setup;
    }
    if (message.archived !== undefined) {
      obj.archived = message.archived;
    }
    if (message.ids?.length) {
      obj.ids = message.ids.map((e) => Math.round(e));
    }
    if (message.hasSmsSetup !== undefined) {
      obj.hasSmsSetup = message.hasSmsSetup;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RepoFilters>, I>>(base?: I): RepoFilters {
    return RepoFilters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RepoFilters>, I>>(object: I): RepoFilters {
    const message = createBaseRepoFilters();
    message.search = object.search ?? undefined;
    message.codeAccess = object.codeAccess ?? undefined;
    message.lastScanSince = object.lastScanSince ?? undefined;
    message.setup = object.setup ?? undefined;
    message.archived = object.archived ?? undefined;
    message.ids = object.ids?.map((e) => e) || [];
    message.hasSmsSetup = object.hasSmsSetup ?? undefined;
    return message;
  },
};

function createBaseSingleBulkRepoChange(): SingleBulkRepoChange {
  return { updateTags: false, tags: [], managedScans: undefined };
}

export const SingleBulkRepoChange = {
  encode(message: SingleBulkRepoChange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updateTags !== false) {
      writer.uint32(8).bool(message.updateTags);
    }
    for (const v of message.tags) {
      writer.uint32(18).string(v!);
    }
    if (message.managedScans !== undefined) {
      ManagedScanSettings.encode(message.managedScans, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SingleBulkRepoChange {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSingleBulkRepoChange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updateTags = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.managedScans = ManagedScanSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SingleBulkRepoChange {
    return {
      updateTags: isSet(object.updateTags) ? globalThis.Boolean(object.updateTags) : false,
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      managedScans: isSet(object.managedScans) ? ManagedScanSettings.fromJSON(object.managedScans) : undefined,
    };
  },

  toJSON(message: SingleBulkRepoChange): unknown {
    const obj: any = {};
    if (message.updateTags !== false) {
      obj.updateTags = message.updateTags;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.managedScans !== undefined) {
      obj.managedScans = ManagedScanSettings.toJSON(message.managedScans);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SingleBulkRepoChange>, I>>(base?: I): SingleBulkRepoChange {
    return SingleBulkRepoChange.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SingleBulkRepoChange>, I>>(object: I): SingleBulkRepoChange {
    const message = createBaseSingleBulkRepoChange();
    message.updateTags = object.updateTags ?? false;
    message.tags = object.tags?.map((e) => e) || [];
    message.managedScans = (object.managedScans !== undefined && object.managedScans !== null)
      ? ManagedScanSettings.fromPartial(object.managedScans)
      : undefined;
    return message;
  },
};

function createBaseBulkEditReposItem(): BulkEditReposItem {
  return { repoId: 0, change: undefined };
}

export const BulkEditReposItem = {
  encode(message: BulkEditReposItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.repoId !== 0) {
      writer.uint32(8).int64(message.repoId);
    }
    if (message.change !== undefined) {
      SingleBulkRepoChange.encode(message.change, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkEditReposItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkEditReposItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.repoId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.change = SingleBulkRepoChange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkEditReposItem {
    return {
      repoId: isSet(object.repoId) ? globalThis.Number(object.repoId) : 0,
      change: isSet(object.change) ? SingleBulkRepoChange.fromJSON(object.change) : undefined,
    };
  },

  toJSON(message: BulkEditReposItem): unknown {
    const obj: any = {};
    if (message.repoId !== 0) {
      obj.repoId = Math.round(message.repoId);
    }
    if (message.change !== undefined) {
      obj.change = SingleBulkRepoChange.toJSON(message.change);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkEditReposItem>, I>>(base?: I): BulkEditReposItem {
    return BulkEditReposItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkEditReposItem>, I>>(object: I): BulkEditReposItem {
    const message = createBaseBulkEditReposItem();
    message.repoId = object.repoId ?? 0;
    message.change = (object.change !== undefined && object.change !== null)
      ? SingleBulkRepoChange.fromPartial(object.change)
      : undefined;
    return message;
  },
};

function createBaseBulkEditReposRequest(): BulkEditReposRequest {
  return { deploymentId: 0, changes: [] };
}

export const BulkEditReposRequest = {
  encode(message: BulkEditReposRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    for (const v of message.changes) {
      BulkEditReposItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkEditReposRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkEditReposRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.changes.push(BulkEditReposItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkEditReposRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      changes: globalThis.Array.isArray(object?.changes)
        ? object.changes.map((e: any) => BulkEditReposItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BulkEditReposRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.changes?.length) {
      obj.changes = message.changes.map((e) => BulkEditReposItem.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkEditReposRequest>, I>>(base?: I): BulkEditReposRequest {
    return BulkEditReposRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkEditReposRequest>, I>>(object: I): BulkEditReposRequest {
    const message = createBaseBulkEditReposRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.changes = object.changes?.map((e) => BulkEditReposItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBulkEditReposResponse(): BulkEditReposResponse {
  return { updatedRepoNames: [] };
}

export const BulkEditReposResponse = {
  encode(message: BulkEditReposResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.updatedRepoNames) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkEditReposResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkEditReposResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updatedRepoNames.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkEditReposResponse {
    return {
      updatedRepoNames: globalThis.Array.isArray(object?.updatedRepoNames)
        ? object.updatedRepoNames.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: BulkEditReposResponse): unknown {
    const obj: any = {};
    if (message.updatedRepoNames?.length) {
      obj.updatedRepoNames = message.updatedRepoNames;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkEditReposResponse>, I>>(base?: I): BulkEditReposResponse {
    return BulkEditReposResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkEditReposResponse>, I>>(object: I): BulkEditReposResponse {
    const message = createBaseBulkEditReposResponse();
    message.updatedRepoNames = object.updatedRepoNames?.map((e) => e) || [];
    return message;
  },
};

function createBaseEditRepoRequest(): EditRepoRequest {
  return { deploymentId: 0, idOrName: "", name: undefined, managedScans: undefined };
}

export const EditRepoRequest = {
  encode(message: EditRepoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    if (message.idOrName !== "") {
      writer.uint32(18).string(message.idOrName);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.managedScans !== undefined) {
      ManagedScanSettings.encode(message.managedScans, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditRepoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditRepoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.idOrName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.managedScans = ManagedScanSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditRepoRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      idOrName: isSet(object.idOrName) ? globalThis.String(object.idOrName) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
      managedScans: isSet(object.managedScans) ? ManagedScanSettings.fromJSON(object.managedScans) : undefined,
    };
  },

  toJSON(message: EditRepoRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.idOrName !== "") {
      obj.idOrName = message.idOrName;
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.managedScans !== undefined) {
      obj.managedScans = ManagedScanSettings.toJSON(message.managedScans);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EditRepoRequest>, I>>(base?: I): EditRepoRequest {
    return EditRepoRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EditRepoRequest>, I>>(object: I): EditRepoRequest {
    const message = createBaseEditRepoRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.idOrName = object.idOrName ?? "";
    message.name = object.name ?? undefined;
    message.managedScans = (object.managedScans !== undefined && object.managedScans !== null)
      ? ManagedScanSettings.fromPartial(object.managedScans)
      : undefined;
    return message;
  },
};

function createBaseEditRepoResponse(): EditRepoResponse {
  return { repo: undefined };
}

export const EditRepoResponse = {
  encode(message: EditRepoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.repo !== undefined) {
      Repository.encode(message.repo, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditRepoResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditRepoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.repo = Repository.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditRepoResponse {
    return { repo: isSet(object.repo) ? Repository.fromJSON(object.repo) : undefined };
  },

  toJSON(message: EditRepoResponse): unknown {
    const obj: any = {};
    if (message.repo !== undefined) {
      obj.repo = Repository.toJSON(message.repo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EditRepoResponse>, I>>(base?: I): EditRepoResponse {
    return EditRepoResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EditRepoResponse>, I>>(object: I): EditRepoResponse {
    const message = createBaseEditRepoResponse();
    message.repo = (object.repo !== undefined && object.repo !== null)
      ? Repository.fromPartial(object.repo)
      : undefined;
    return message;
  },
};

function createBaseGetRepoRequest(): GetRepoRequest {
  return { deploymentId: 0, idOrName: "" };
}

export const GetRepoRequest = {
  encode(message: GetRepoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    if (message.idOrName !== "") {
      writer.uint32(18).string(message.idOrName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRepoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRepoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.idOrName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRepoRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      idOrName: isSet(object.idOrName) ? globalThis.String(object.idOrName) : "",
    };
  },

  toJSON(message: GetRepoRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.idOrName !== "") {
      obj.idOrName = message.idOrName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetRepoRequest>, I>>(base?: I): GetRepoRequest {
    return GetRepoRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRepoRequest>, I>>(object: I): GetRepoRequest {
    const message = createBaseGetRepoRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.idOrName = object.idOrName ?? "";
    return message;
  },
};

function createBaseGetRepoResponse(): GetRepoResponse {
  return { repo: undefined };
}

export const GetRepoResponse = {
  encode(message: GetRepoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.repo !== undefined) {
      Repository.encode(message.repo, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRepoResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRepoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.repo = Repository.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRepoResponse {
    return { repo: isSet(object.repo) ? Repository.fromJSON(object.repo) : undefined };
  },

  toJSON(message: GetRepoResponse): unknown {
    const obj: any = {};
    if (message.repo !== undefined) {
      obj.repo = Repository.toJSON(message.repo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetRepoResponse>, I>>(base?: I): GetRepoResponse {
    return GetRepoResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRepoResponse>, I>>(object: I): GetRepoResponse {
    const message = createBaseGetRepoResponse();
    message.repo = (object.repo !== undefined && object.repo !== null)
      ? Repository.fromPartial(object.repo)
      : undefined;
    return message;
  },
};

function createBaseSearchReposRequest(): SearchReposRequest {
  return {
    deploymentId: 0,
    cursor: undefined,
    limit: undefined,
    sortBy: undefined,
    sortDirection: undefined,
    search: undefined,
    codeAccess: undefined,
    lastScanSince: undefined,
    setup: undefined,
    archived: undefined,
    filters: undefined,
  };
}

export const SearchReposRequest = {
  encode(message: SearchReposRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    if (message.cursor !== undefined) {
      writer.uint32(18).string(message.cursor);
    }
    if (message.limit !== undefined) {
      writer.uint32(24).uint64(message.limit);
    }
    if (message.sortBy !== undefined) {
      writer.uint32(32).int32(repoSortByToNumber(message.sortBy));
    }
    if (message.sortDirection !== undefined) {
      writer.uint32(40).int32(sortDirectionToNumber(message.sortDirection));
    }
    if (message.search !== undefined) {
      writer.uint32(50).string(message.search);
    }
    if (message.codeAccess !== undefined) {
      writer.uint32(56).bool(message.codeAccess);
    }
    if (message.lastScanSince !== undefined) {
      Timestamp.encode(toTimestamp(message.lastScanSince), writer.uint32(66).fork()).ldelim();
    }
    if (message.setup !== undefined) {
      writer.uint32(72).bool(message.setup);
    }
    if (message.archived !== undefined) {
      writer.uint32(80).bool(message.archived);
    }
    if (message.filters !== undefined) {
      RepoFilters.encode(message.filters, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchReposRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchReposRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cursor = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.limit = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sortBy = repoSortByFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.sortDirection = sortDirectionFromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.search = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.codeAccess = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.lastScanSince = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.setup = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.archived = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.filters = RepoFilters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchReposRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : undefined,
      sortBy: isSet(object.sortBy) ? repoSortByFromJSON(object.sortBy) : undefined,
      sortDirection: isSet(object.sortDirection) ? sortDirectionFromJSON(object.sortDirection) : undefined,
      search: isSet(object.search) ? globalThis.String(object.search) : undefined,
      codeAccess: isSet(object.codeAccess) ? globalThis.Boolean(object.codeAccess) : undefined,
      lastScanSince: isSet(object.lastScanSince) ? fromJsonTimestamp(object.lastScanSince) : undefined,
      setup: isSet(object.setup) ? globalThis.Boolean(object.setup) : undefined,
      archived: isSet(object.archived) ? globalThis.Boolean(object.archived) : undefined,
      filters: isSet(object.filters) ? RepoFilters.fromJSON(object.filters) : undefined,
    };
  },

  toJSON(message: SearchReposRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    if (message.limit !== undefined) {
      obj.limit = Math.round(message.limit);
    }
    if (message.sortBy !== undefined) {
      obj.sortBy = repoSortByToJSON(message.sortBy);
    }
    if (message.sortDirection !== undefined) {
      obj.sortDirection = sortDirectionToJSON(message.sortDirection);
    }
    if (message.search !== undefined) {
      obj.search = message.search;
    }
    if (message.codeAccess !== undefined) {
      obj.codeAccess = message.codeAccess;
    }
    if (message.lastScanSince !== undefined) {
      obj.lastScanSince = message.lastScanSince.toISOString();
    }
    if (message.setup !== undefined) {
      obj.setup = message.setup;
    }
    if (message.archived !== undefined) {
      obj.archived = message.archived;
    }
    if (message.filters !== undefined) {
      obj.filters = RepoFilters.toJSON(message.filters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchReposRequest>, I>>(base?: I): SearchReposRequest {
    return SearchReposRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchReposRequest>, I>>(object: I): SearchReposRequest {
    const message = createBaseSearchReposRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.cursor = object.cursor ?? undefined;
    message.limit = object.limit ?? undefined;
    message.sortBy = object.sortBy ?? undefined;
    message.sortDirection = object.sortDirection ?? undefined;
    message.search = object.search ?? undefined;
    message.codeAccess = object.codeAccess ?? undefined;
    message.lastScanSince = object.lastScanSince ?? undefined;
    message.setup = object.setup ?? undefined;
    message.archived = object.archived ?? undefined;
    message.filters = (object.filters !== undefined && object.filters !== null)
      ? RepoFilters.fromPartial(object.filters)
      : undefined;
    return message;
  },
};

function createBaseSearchReposResponse(): SearchReposResponse {
  return { repos: [], cursor: undefined };
}

export const SearchReposResponse = {
  encode(message: SearchReposResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.repos) {
      Repository.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.cursor !== undefined) {
      writer.uint32(18).string(message.cursor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchReposResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchReposResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.repos.push(Repository.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cursor = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchReposResponse {
    return {
      repos: globalThis.Array.isArray(object?.repos) ? object.repos.map((e: any) => Repository.fromJSON(e)) : [],
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
    };
  },

  toJSON(message: SearchReposResponse): unknown {
    const obj: any = {};
    if (message.repos?.length) {
      obj.repos = message.repos.map((e) => Repository.toJSON(e));
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchReposResponse>, I>>(base?: I): SearchReposResponse {
    return SearchReposResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchReposResponse>, I>>(object: I): SearchReposResponse {
    const message = createBaseSearchReposResponse();
    message.repos = object.repos?.map((e) => Repository.fromPartial(e)) || [];
    message.cursor = object.cursor ?? undefined;
    return message;
  },
};

function createBaseBulkApplyRepoUpdateRequest(): BulkApplyRepoUpdateRequest {
  return { deploymentId: 0, filters: undefined, excludeIds: [], change: undefined };
}

export const BulkApplyRepoUpdateRequest = {
  encode(message: BulkApplyRepoUpdateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.filters !== undefined) {
      RepoFilters.encode(message.filters, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.excludeIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    if (message.change !== undefined) {
      SingleBulkRepoChange.encode(message.change, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkApplyRepoUpdateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkApplyRepoUpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filters = RepoFilters.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag === 24) {
            message.excludeIds.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.excludeIds.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.change = SingleBulkRepoChange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkApplyRepoUpdateRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      filters: isSet(object.filters) ? RepoFilters.fromJSON(object.filters) : undefined,
      excludeIds: globalThis.Array.isArray(object?.excludeIds)
        ? object.excludeIds.map((e: any) => globalThis.Number(e))
        : [],
      change: isSet(object.change) ? SingleBulkRepoChange.fromJSON(object.change) : undefined,
    };
  },

  toJSON(message: BulkApplyRepoUpdateRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.filters !== undefined) {
      obj.filters = RepoFilters.toJSON(message.filters);
    }
    if (message.excludeIds?.length) {
      obj.excludeIds = message.excludeIds.map((e) => Math.round(e));
    }
    if (message.change !== undefined) {
      obj.change = SingleBulkRepoChange.toJSON(message.change);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkApplyRepoUpdateRequest>, I>>(base?: I): BulkApplyRepoUpdateRequest {
    return BulkApplyRepoUpdateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkApplyRepoUpdateRequest>, I>>(object: I): BulkApplyRepoUpdateRequest {
    const message = createBaseBulkApplyRepoUpdateRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.filters = (object.filters !== undefined && object.filters !== null)
      ? RepoFilters.fromPartial(object.filters)
      : undefined;
    message.excludeIds = object.excludeIds?.map((e) => e) || [];
    message.change = (object.change !== undefined && object.change !== null)
      ? SingleBulkRepoChange.fromPartial(object.change)
      : undefined;
    return message;
  },
};

function createBaseBulkApplyRepoUpdateResponse(): BulkApplyRepoUpdateResponse {
  return { updatedRepoIds: [], errors: [] };
}

export const BulkApplyRepoUpdateResponse = {
  encode(message: BulkApplyRepoUpdateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.updatedRepoIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    for (const v of message.errors) {
      BulkEndpointError.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkApplyRepoUpdateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkApplyRepoUpdateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.updatedRepoIds.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.updatedRepoIds.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.errors.push(BulkEndpointError.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkApplyRepoUpdateResponse {
    return {
      updatedRepoIds: globalThis.Array.isArray(object?.updatedRepoIds)
        ? object.updatedRepoIds.map((e: any) => globalThis.Number(e))
        : [],
      errors: globalThis.Array.isArray(object?.errors)
        ? object.errors.map((e: any) => BulkEndpointError.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BulkApplyRepoUpdateResponse): unknown {
    const obj: any = {};
    if (message.updatedRepoIds?.length) {
      obj.updatedRepoIds = message.updatedRepoIds.map((e) => Math.round(e));
    }
    if (message.errors?.length) {
      obj.errors = message.errors.map((e) => BulkEndpointError.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkApplyRepoUpdateResponse>, I>>(base?: I): BulkApplyRepoUpdateResponse {
    return BulkApplyRepoUpdateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkApplyRepoUpdateResponse>, I>>(object: I): BulkApplyRepoUpdateResponse {
    const message = createBaseBulkApplyRepoUpdateResponse();
    message.updatedRepoIds = object.updatedRepoIds?.map((e) => e) || [];
    message.errors = object.errors?.map((e) => BulkEndpointError.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBulkAddRepoSecretRequest(): BulkAddRepoSecretRequest {
  return { deploymentId: 0, filters: undefined, excludeIds: [] };
}

export const BulkAddRepoSecretRequest = {
  encode(message: BulkAddRepoSecretRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.filters !== undefined) {
      RepoFilters.encode(message.filters, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.excludeIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkAddRepoSecretRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkAddRepoSecretRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filters = RepoFilters.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag === 24) {
            message.excludeIds.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.excludeIds.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkAddRepoSecretRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      filters: isSet(object.filters) ? RepoFilters.fromJSON(object.filters) : undefined,
      excludeIds: globalThis.Array.isArray(object?.excludeIds)
        ? object.excludeIds.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: BulkAddRepoSecretRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.filters !== undefined) {
      obj.filters = RepoFilters.toJSON(message.filters);
    }
    if (message.excludeIds?.length) {
      obj.excludeIds = message.excludeIds.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkAddRepoSecretRequest>, I>>(base?: I): BulkAddRepoSecretRequest {
    return BulkAddRepoSecretRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkAddRepoSecretRequest>, I>>(object: I): BulkAddRepoSecretRequest {
    const message = createBaseBulkAddRepoSecretRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.filters = (object.filters !== undefined && object.filters !== null)
      ? RepoFilters.fromPartial(object.filters)
      : undefined;
    message.excludeIds = object.excludeIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseBulkAddRepoSecretResponse(): BulkAddRepoSecretResponse {
  return { repoTokenIds: {}, errors: [] };
}

export const BulkAddRepoSecretResponse = {
  encode(message: BulkAddRepoSecretResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.repoTokenIds).forEach(([key, value]) => {
      BulkAddRepoSecretResponse_RepoTokenIdsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    for (const v of message.errors) {
      BulkEndpointError.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkAddRepoSecretResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkAddRepoSecretResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = BulkAddRepoSecretResponse_RepoTokenIdsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.repoTokenIds[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.errors.push(BulkEndpointError.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkAddRepoSecretResponse {
    return {
      repoTokenIds: isObject(object.repoTokenIds)
        ? Object.entries(object.repoTokenIds).reduce<{ [key: number]: number }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = Number(value);
          return acc;
        }, {})
        : {},
      errors: globalThis.Array.isArray(object?.errors)
        ? object.errors.map((e: any) => BulkEndpointError.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BulkAddRepoSecretResponse): unknown {
    const obj: any = {};
    if (message.repoTokenIds) {
      const entries = Object.entries(message.repoTokenIds);
      if (entries.length > 0) {
        obj.repoTokenIds = {};
        entries.forEach(([k, v]) => {
          obj.repoTokenIds[k] = Math.round(v);
        });
      }
    }
    if (message.errors?.length) {
      obj.errors = message.errors.map((e) => BulkEndpointError.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkAddRepoSecretResponse>, I>>(base?: I): BulkAddRepoSecretResponse {
    return BulkAddRepoSecretResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkAddRepoSecretResponse>, I>>(object: I): BulkAddRepoSecretResponse {
    const message = createBaseBulkAddRepoSecretResponse();
    message.repoTokenIds = Object.entries(object.repoTokenIds ?? {}).reduce<{ [key: number]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.errors = object.errors?.map((e) => BulkEndpointError.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBulkAddRepoSecretResponse_RepoTokenIdsEntry(): BulkAddRepoSecretResponse_RepoTokenIdsEntry {
  return { key: 0, value: 0 };
}

export const BulkAddRepoSecretResponse_RepoTokenIdsEntry = {
  encode(message: BulkAddRepoSecretResponse_RepoTokenIdsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).uint64(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).uint64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkAddRepoSecretResponse_RepoTokenIdsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkAddRepoSecretResponse_RepoTokenIdsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkAddRepoSecretResponse_RepoTokenIdsEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: BulkAddRepoSecretResponse_RepoTokenIdsEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkAddRepoSecretResponse_RepoTokenIdsEntry>, I>>(
    base?: I,
  ): BulkAddRepoSecretResponse_RepoTokenIdsEntry {
    return BulkAddRepoSecretResponse_RepoTokenIdsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkAddRepoSecretResponse_RepoTokenIdsEntry>, I>>(
    object: I,
  ): BulkAddRepoSecretResponse_RepoTokenIdsEntry {
    const message = createBaseBulkAddRepoSecretResponse_RepoTokenIdsEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseGhaOptions(): GhaOptions {
  return {
    publishUrl: "",
    prEnabled: false,
    prSendComments: false,
    pushEnabled: false,
    pushBranches: [],
    pushPaths: [],
    cronEnabled: false,
    useSelfHostedRunners: undefined,
    useCheckoutV2: undefined,
  };
}

export const GhaOptions = {
  encode(message: GhaOptions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.publishUrl !== "") {
      writer.uint32(10).string(message.publishUrl);
    }
    if (message.prEnabled !== false) {
      writer.uint32(16).bool(message.prEnabled);
    }
    if (message.prSendComments !== false) {
      writer.uint32(24).bool(message.prSendComments);
    }
    if (message.pushEnabled !== false) {
      writer.uint32(32).bool(message.pushEnabled);
    }
    for (const v of message.pushBranches) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.pushPaths) {
      writer.uint32(50).string(v!);
    }
    if (message.cronEnabled !== false) {
      writer.uint32(56).bool(message.cronEnabled);
    }
    if (message.useSelfHostedRunners !== undefined) {
      writer.uint32(64).bool(message.useSelfHostedRunners);
    }
    if (message.useCheckoutV2 !== undefined) {
      writer.uint32(72).bool(message.useCheckoutV2);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GhaOptions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGhaOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publishUrl = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.prEnabled = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.prSendComments = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.pushEnabled = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.pushBranches.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.pushPaths.push(reader.string());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.cronEnabled = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.useSelfHostedRunners = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.useCheckoutV2 = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GhaOptions {
    return {
      publishUrl: isSet(object.publishUrl) ? globalThis.String(object.publishUrl) : "",
      prEnabled: isSet(object.prEnabled) ? globalThis.Boolean(object.prEnabled) : false,
      prSendComments: isSet(object.prSendComments) ? globalThis.Boolean(object.prSendComments) : false,
      pushEnabled: isSet(object.pushEnabled) ? globalThis.Boolean(object.pushEnabled) : false,
      pushBranches: globalThis.Array.isArray(object?.pushBranches)
        ? object.pushBranches.map((e: any) => globalThis.String(e))
        : [],
      pushPaths: globalThis.Array.isArray(object?.pushPaths)
        ? object.pushPaths.map((e: any) => globalThis.String(e))
        : [],
      cronEnabled: isSet(object.cronEnabled) ? globalThis.Boolean(object.cronEnabled) : false,
      useSelfHostedRunners: isSet(object.useSelfHostedRunners)
        ? globalThis.Boolean(object.useSelfHostedRunners)
        : undefined,
      useCheckoutV2: isSet(object.useCheckoutV2) ? globalThis.Boolean(object.useCheckoutV2) : undefined,
    };
  },

  toJSON(message: GhaOptions): unknown {
    const obj: any = {};
    if (message.publishUrl !== "") {
      obj.publishUrl = message.publishUrl;
    }
    if (message.prEnabled !== false) {
      obj.prEnabled = message.prEnabled;
    }
    if (message.prSendComments !== false) {
      obj.prSendComments = message.prSendComments;
    }
    if (message.pushEnabled !== false) {
      obj.pushEnabled = message.pushEnabled;
    }
    if (message.pushBranches?.length) {
      obj.pushBranches = message.pushBranches;
    }
    if (message.pushPaths?.length) {
      obj.pushPaths = message.pushPaths;
    }
    if (message.cronEnabled !== false) {
      obj.cronEnabled = message.cronEnabled;
    }
    if (message.useSelfHostedRunners !== undefined) {
      obj.useSelfHostedRunners = message.useSelfHostedRunners;
    }
    if (message.useCheckoutV2 !== undefined) {
      obj.useCheckoutV2 = message.useCheckoutV2;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GhaOptions>, I>>(base?: I): GhaOptions {
    return GhaOptions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GhaOptions>, I>>(object: I): GhaOptions {
    const message = createBaseGhaOptions();
    message.publishUrl = object.publishUrl ?? "";
    message.prEnabled = object.prEnabled ?? false;
    message.prSendComments = object.prSendComments ?? false;
    message.pushEnabled = object.pushEnabled ?? false;
    message.pushBranches = object.pushBranches?.map((e) => e) || [];
    message.pushPaths = object.pushPaths?.map((e) => e) || [];
    message.cronEnabled = object.cronEnabled ?? false;
    message.useSelfHostedRunners = object.useSelfHostedRunners ?? undefined;
    message.useCheckoutV2 = object.useCheckoutV2 ?? undefined;
    return message;
  },
};

function createBaseBulkProvisionReposRequest(): BulkProvisionReposRequest {
  return { deploymentId: 0, filters: undefined, excludeIds: [], ghaOptions: undefined };
}

export const BulkProvisionReposRequest = {
  encode(message: BulkProvisionReposRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.filters !== undefined) {
      RepoFilters.encode(message.filters, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.excludeIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    if (message.ghaOptions !== undefined) {
      GhaOptions.encode(message.ghaOptions, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkProvisionReposRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkProvisionReposRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filters = RepoFilters.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag === 24) {
            message.excludeIds.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.excludeIds.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ghaOptions = GhaOptions.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkProvisionReposRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      filters: isSet(object.filters) ? RepoFilters.fromJSON(object.filters) : undefined,
      excludeIds: globalThis.Array.isArray(object?.excludeIds)
        ? object.excludeIds.map((e: any) => globalThis.Number(e))
        : [],
      ghaOptions: isSet(object.ghaOptions) ? GhaOptions.fromJSON(object.ghaOptions) : undefined,
    };
  },

  toJSON(message: BulkProvisionReposRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.filters !== undefined) {
      obj.filters = RepoFilters.toJSON(message.filters);
    }
    if (message.excludeIds?.length) {
      obj.excludeIds = message.excludeIds.map((e) => Math.round(e));
    }
    if (message.ghaOptions !== undefined) {
      obj.ghaOptions = GhaOptions.toJSON(message.ghaOptions);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkProvisionReposRequest>, I>>(base?: I): BulkProvisionReposRequest {
    return BulkProvisionReposRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkProvisionReposRequest>, I>>(object: I): BulkProvisionReposRequest {
    const message = createBaseBulkProvisionReposRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.filters = (object.filters !== undefined && object.filters !== null)
      ? RepoFilters.fromPartial(object.filters)
      : undefined;
    message.excludeIds = object.excludeIds?.map((e) => e) || [];
    message.ghaOptions = (object.ghaOptions !== undefined && object.ghaOptions !== null)
      ? GhaOptions.fromPartial(object.ghaOptions)
      : undefined;
    return message;
  },
};

function createBaseBulkProvisionReposResponse(): BulkProvisionReposResponse {
  return { repoFileUrls: {}, errors: [] };
}

export const BulkProvisionReposResponse = {
  encode(message: BulkProvisionReposResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.repoFileUrls).forEach(([key, value]) => {
      BulkProvisionReposResponse_RepoFileUrlsEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .ldelim();
    });
    for (const v of message.errors) {
      BulkEndpointError.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkProvisionReposResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkProvisionReposResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = BulkProvisionReposResponse_RepoFileUrlsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.repoFileUrls[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.errors.push(BulkEndpointError.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkProvisionReposResponse {
    return {
      repoFileUrls: isObject(object.repoFileUrls)
        ? Object.entries(object.repoFileUrls).reduce<{ [key: number]: string }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = String(value);
          return acc;
        }, {})
        : {},
      errors: globalThis.Array.isArray(object?.errors)
        ? object.errors.map((e: any) => BulkEndpointError.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BulkProvisionReposResponse): unknown {
    const obj: any = {};
    if (message.repoFileUrls) {
      const entries = Object.entries(message.repoFileUrls);
      if (entries.length > 0) {
        obj.repoFileUrls = {};
        entries.forEach(([k, v]) => {
          obj.repoFileUrls[k] = v;
        });
      }
    }
    if (message.errors?.length) {
      obj.errors = message.errors.map((e) => BulkEndpointError.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkProvisionReposResponse>, I>>(base?: I): BulkProvisionReposResponse {
    return BulkProvisionReposResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkProvisionReposResponse>, I>>(object: I): BulkProvisionReposResponse {
    const message = createBaseBulkProvisionReposResponse();
    message.repoFileUrls = Object.entries(object.repoFileUrls ?? {}).reduce<{ [key: number]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.errors = object.errors?.map((e) => BulkEndpointError.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBulkProvisionReposResponse_RepoFileUrlsEntry(): BulkProvisionReposResponse_RepoFileUrlsEntry {
  return { key: 0, value: "" };
}

export const BulkProvisionReposResponse_RepoFileUrlsEntry = {
  encode(message: BulkProvisionReposResponse_RepoFileUrlsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).uint64(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkProvisionReposResponse_RepoFileUrlsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkProvisionReposResponse_RepoFileUrlsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkProvisionReposResponse_RepoFileUrlsEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: BulkProvisionReposResponse_RepoFileUrlsEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkProvisionReposResponse_RepoFileUrlsEntry>, I>>(
    base?: I,
  ): BulkProvisionReposResponse_RepoFileUrlsEntry {
    return BulkProvisionReposResponse_RepoFileUrlsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkProvisionReposResponse_RepoFileUrlsEntry>, I>>(
    object: I,
  ): BulkProvisionReposResponse_RepoFileUrlsEntry {
    const message = createBaseBulkProvisionReposResponse_RepoFileUrlsEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseGetReposByTagRequest(): GetReposByTagRequest {
  return { deploymentId: 0 };
}

export const GetReposByTagRequest = {
  encode(message: GetReposByTagRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetReposByTagRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReposByTagRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetReposByTagRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0 };
  },

  toJSON(message: GetReposByTagRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetReposByTagRequest>, I>>(base?: I): GetReposByTagRequest {
    return GetReposByTagRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetReposByTagRequest>, I>>(object: I): GetReposByTagRequest {
    const message = createBaseGetReposByTagRequest();
    message.deploymentId = object.deploymentId ?? 0;
    return message;
  },
};

function createBaseGetReposByTagResponse(): GetReposByTagResponse {
  return { tagIdToRepoIds: {} };
}

export const GetReposByTagResponse = {
  encode(message: GetReposByTagResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.tagIdToRepoIds).forEach(([key, value]) => {
      GetReposByTagResponse_TagIdToRepoIdsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetReposByTagResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReposByTagResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = GetReposByTagResponse_TagIdToRepoIdsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.tagIdToRepoIds[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetReposByTagResponse {
    return {
      tagIdToRepoIds: isObject(object.tagIdToRepoIds)
        ? Object.entries(object.tagIdToRepoIds).reduce<{ [key: number]: GetReposByTagResponse_RepoIdList }>(
          (acc, [key, value]) => {
            acc[globalThis.Number(key)] = GetReposByTagResponse_RepoIdList.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: GetReposByTagResponse): unknown {
    const obj: any = {};
    if (message.tagIdToRepoIds) {
      const entries = Object.entries(message.tagIdToRepoIds);
      if (entries.length > 0) {
        obj.tagIdToRepoIds = {};
        entries.forEach(([k, v]) => {
          obj.tagIdToRepoIds[k] = GetReposByTagResponse_RepoIdList.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetReposByTagResponse>, I>>(base?: I): GetReposByTagResponse {
    return GetReposByTagResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetReposByTagResponse>, I>>(object: I): GetReposByTagResponse {
    const message = createBaseGetReposByTagResponse();
    message.tagIdToRepoIds = Object.entries(object.tagIdToRepoIds ?? {}).reduce<
      { [key: number]: GetReposByTagResponse_RepoIdList }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[globalThis.Number(key)] = GetReposByTagResponse_RepoIdList.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseGetReposByTagResponse_RepoIdList(): GetReposByTagResponse_RepoIdList {
  return { repoIds: [] };
}

export const GetReposByTagResponse_RepoIdList = {
  encode(message: GetReposByTagResponse_RepoIdList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.repoIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetReposByTagResponse_RepoIdList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReposByTagResponse_RepoIdList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.repoIds.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.repoIds.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetReposByTagResponse_RepoIdList {
    return {
      repoIds: globalThis.Array.isArray(object?.repoIds) ? object.repoIds.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: GetReposByTagResponse_RepoIdList): unknown {
    const obj: any = {};
    if (message.repoIds?.length) {
      obj.repoIds = message.repoIds.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetReposByTagResponse_RepoIdList>, I>>(
    base?: I,
  ): GetReposByTagResponse_RepoIdList {
    return GetReposByTagResponse_RepoIdList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetReposByTagResponse_RepoIdList>, I>>(
    object: I,
  ): GetReposByTagResponse_RepoIdList {
    const message = createBaseGetReposByTagResponse_RepoIdList();
    message.repoIds = object.repoIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetReposByTagResponse_TagIdToRepoIdsEntry(): GetReposByTagResponse_TagIdToRepoIdsEntry {
  return { key: 0, value: undefined };
}

export const GetReposByTagResponse_TagIdToRepoIdsEntry = {
  encode(message: GetReposByTagResponse_TagIdToRepoIdsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).uint64(message.key);
    }
    if (message.value !== undefined) {
      GetReposByTagResponse_RepoIdList.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetReposByTagResponse_TagIdToRepoIdsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReposByTagResponse_TagIdToRepoIdsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = GetReposByTagResponse_RepoIdList.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetReposByTagResponse_TagIdToRepoIdsEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? GetReposByTagResponse_RepoIdList.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: GetReposByTagResponse_TagIdToRepoIdsEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== undefined) {
      obj.value = GetReposByTagResponse_RepoIdList.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetReposByTagResponse_TagIdToRepoIdsEntry>, I>>(
    base?: I,
  ): GetReposByTagResponse_TagIdToRepoIdsEntry {
    return GetReposByTagResponse_TagIdToRepoIdsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetReposByTagResponse_TagIdToRepoIdsEntry>, I>>(
    object: I,
  ): GetReposByTagResponse_TagIdToRepoIdsEntry {
    const message = createBaseGetReposByTagResponse_TagIdToRepoIdsEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? GetReposByTagResponse_RepoIdList.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseGetReposCountRequest(): GetReposCountRequest {
  return { deploymentId: 0, filters: undefined };
}

export const GetReposCountRequest = {
  encode(message: GetReposCountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.filters !== undefined) {
      RepoFilters.encode(message.filters, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetReposCountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReposCountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filters = RepoFilters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetReposCountRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      filters: isSet(object.filters) ? RepoFilters.fromJSON(object.filters) : undefined,
    };
  },

  toJSON(message: GetReposCountRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.filters !== undefined) {
      obj.filters = RepoFilters.toJSON(message.filters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetReposCountRequest>, I>>(base?: I): GetReposCountRequest {
    return GetReposCountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetReposCountRequest>, I>>(object: I): GetReposCountRequest {
    const message = createBaseGetReposCountRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.filters = (object.filters !== undefined && object.filters !== null)
      ? RepoFilters.fromPartial(object.filters)
      : undefined;
    return message;
  },
};

function createBaseGetReposCountResponse(): GetReposCountResponse {
  return { count: 0 };
}

export const GetReposCountResponse = {
  encode(message: GetReposCountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.count !== 0) {
      writer.uint32(8).uint64(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetReposCountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReposCountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.count = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetReposCountResponse {
    return { count: isSet(object.count) ? globalThis.Number(object.count) : 0 };
  },

  toJSON(message: GetReposCountResponse): unknown {
    const obj: any = {};
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetReposCountResponse>, I>>(base?: I): GetReposCountResponse {
    return GetReposCountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetReposCountResponse>, I>>(object: I): GetReposCountResponse {
    const message = createBaseGetReposCountResponse();
    message.count = object.count ?? 0;
    return message;
  },
};

export interface ProjectService {
  bulkEditRepos(request: BulkEditReposRequest): Promise<BulkEditReposResponse>;
  editRepo(request: EditRepoRequest): Promise<EditRepoResponse>;
  getRepo(request: GetRepoRequest): Promise<GetRepoResponse>;
  searchRepos(request: SearchReposRequest): Promise<SearchReposResponse>;
  bulkApplyRepoUpdate(request: BulkApplyRepoUpdateRequest): Promise<BulkApplyRepoUpdateResponse>;
  bulkAddRepoSecret(request: BulkAddRepoSecretRequest): Promise<BulkAddRepoSecretResponse>;
  bulkProvisionRepos(request: BulkProvisionReposRequest): Promise<BulkProvisionReposResponse>;
  getReposByTag(request: GetReposByTagRequest): Promise<GetReposByTagResponse>;
  getReposCount(request: GetReposCountRequest): Promise<GetReposCountResponse>;
}

export const ProjectServiceServiceName = "protos.projects.v1.ProjectService";
export class ProjectServiceClientImpl implements ProjectService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || ProjectServiceServiceName;
    this.rpc = rpc;
    this.bulkEditRepos = this.bulkEditRepos.bind(this);
    this.editRepo = this.editRepo.bind(this);
    this.getRepo = this.getRepo.bind(this);
    this.searchRepos = this.searchRepos.bind(this);
    this.bulkApplyRepoUpdate = this.bulkApplyRepoUpdate.bind(this);
    this.bulkAddRepoSecret = this.bulkAddRepoSecret.bind(this);
    this.bulkProvisionRepos = this.bulkProvisionRepos.bind(this);
    this.getReposByTag = this.getReposByTag.bind(this);
    this.getReposCount = this.getReposCount.bind(this);
  }
  bulkEditRepos(request: BulkEditReposRequest): Promise<BulkEditReposResponse> {
    const data = BulkEditReposRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "BulkEditRepos", data);
    return promise.then((data) => BulkEditReposResponse.decode(_m0.Reader.create(data)));
  }

  editRepo(request: EditRepoRequest): Promise<EditRepoResponse> {
    const data = EditRepoRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "EditRepo", data);
    return promise.then((data) => EditRepoResponse.decode(_m0.Reader.create(data)));
  }

  getRepo(request: GetRepoRequest): Promise<GetRepoResponse> {
    const data = GetRepoRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetRepo", data);
    return promise.then((data) => GetRepoResponse.decode(_m0.Reader.create(data)));
  }

  searchRepos(request: SearchReposRequest): Promise<SearchReposResponse> {
    const data = SearchReposRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "SearchRepos", data);
    return promise.then((data) => SearchReposResponse.decode(_m0.Reader.create(data)));
  }

  bulkApplyRepoUpdate(request: BulkApplyRepoUpdateRequest): Promise<BulkApplyRepoUpdateResponse> {
    const data = BulkApplyRepoUpdateRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "BulkApplyRepoUpdate", data);
    return promise.then((data) => BulkApplyRepoUpdateResponse.decode(_m0.Reader.create(data)));
  }

  bulkAddRepoSecret(request: BulkAddRepoSecretRequest): Promise<BulkAddRepoSecretResponse> {
    const data = BulkAddRepoSecretRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "BulkAddRepoSecret", data);
    return promise.then((data) => BulkAddRepoSecretResponse.decode(_m0.Reader.create(data)));
  }

  bulkProvisionRepos(request: BulkProvisionReposRequest): Promise<BulkProvisionReposResponse> {
    const data = BulkProvisionReposRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "BulkProvisionRepos", data);
    return promise.then((data) => BulkProvisionReposResponse.decode(_m0.Reader.create(data)));
  }

  getReposByTag(request: GetReposByTagRequest): Promise<GetReposByTagResponse> {
    const data = GetReposByTagRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetReposByTag", data);
    return promise.then((data) => GetReposByTagResponse.decode(_m0.Reader.create(data)));
  }

  getReposCount(request: GetReposCountRequest): Promise<GetReposCountResponse> {
    const data = GetReposCountRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetReposCount", data);
    return promise.then((data) => GetReposCountResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
