import { Literal, Static, Union } from "runtypes";

export const ALLOWED_VISUAL_PATTERN_KEYS = Union(
  Literal("pattern"),
  Literal("pattern-inside"),
  Literal("pattern-not"),
  Literal("pattern-not-inside"),
  Literal("pattern-fix"),
  Literal("pattern-regex"),
  Literal("metavariable-regex")
);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ALLOWED_VISUAL_PATTERN_KEYS = Static<
  typeof ALLOWED_VISUAL_PATTERN_KEYS
>;
