import { useHistory } from "react-router";
import { showNotification } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";

import { authPost } from "@shared/api";
import { ApiError, OrgData } from "@shared/types";
import { queryClient } from "@shared/utils";

import { useChangeOrg } from "../useChangeOrg";
import { useRefreshUserAuth } from "../useRefreshUserAuth";

const createDeployment = async (deployment_name: string): Promise<OrgData> => {
  return authPost<
    { deployment_name: string; source_type: string; source_id?: string },
    { deployment: OrgData }
  >("/api/agent/deployments", {
    deployment_name,
    source_type: "simple",
    source_id: "",
  }).then((response) => {
    return response.deployment;
  });
};

/**
 * @param deploymentName name of the deployment to create
 * @param redirectToOnSuccess path to redirect to on success. Will be done after
 * changing the org to the newly created org.
 * @returns mutation that creates a deployment. will refresh user auth on
 * success
 */
export const useCreateDeploymentMutation = () => {
  const refreshUserAuth = useRefreshUserAuth();
  const changeOrg = useChangeOrg();
  const history = useHistory();

  return useMutation({
    mutationFn: (options: {
      deploymentName: string;
      redirectToOnSuccess?: string;
    }) => createDeployment(options.deploymentName),
    onError: (err: ApiError, options) => {
      showNotification({
        message:
          err.message ||
          `There was an issue creating the organization "${options.deploymentName}".`,
      });
    },
    onSuccess: async (resp, args) => {
      // NOTE: Coupling with the `useUserOrgs` hook, we invalidate the current user's orgs
      queryClient.invalidateQueries({ queryKey: ["userDeployments"] });
      await refreshUserAuth();

      if (args.redirectToOnSuccess) {
        changeOrg(resp);
        history.push(args.redirectToOnSuccess);
      }
    },
  });
};
