// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/ai/v1/triage.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "protos.ai.v1";

export interface Autotriage {
  id: number;
  issueId: number;
  verdict: Autotriage_Verdict;
  /** The reasoning for a false positive verdict, explaining why you might want to ignore the finding. Empty string if verdict is true positive. */
  reason: string;
  feedback: AutotriageFeedback | undefined;
  matchBasedId: string;
}

export enum Autotriage_Verdict {
  /** UNSPECIFIED - No information about Autotriage verdict. */
  UNSPECIFIED = "VERDICT_UNSPECIFIED",
  /** TRUE_POSITIVE - Assistant recommends fixing the finding. AI generated decision, review carefully. */
  TRUE_POSITIVE = "VERDICT_TRUE_POSITIVE",
  /** FALSE_POSITIVE - Assistant recommends ignoring the finding. AI generated decision, review carefully. */
  FALSE_POSITIVE = "VERDICT_FALSE_POSITIVE",
  /** NO_VERDICT - Issues that do not have an Autotriage verdict associated with them. */
  NO_VERDICT = "VERDICT_NO_VERDICT",
}

export function autotriage_VerdictFromJSON(object: any): Autotriage_Verdict {
  switch (object) {
    case 0:
    case "VERDICT_UNSPECIFIED":
      return Autotriage_Verdict.UNSPECIFIED;
    case 10:
    case "VERDICT_TRUE_POSITIVE":
      return Autotriage_Verdict.TRUE_POSITIVE;
    case 20:
    case "VERDICT_FALSE_POSITIVE":
      return Autotriage_Verdict.FALSE_POSITIVE;
    case 30:
    case "VERDICT_NO_VERDICT":
      return Autotriage_Verdict.NO_VERDICT;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Autotriage_Verdict");
  }
}

export function autotriage_VerdictToJSON(object: Autotriage_Verdict): string {
  switch (object) {
    case Autotriage_Verdict.UNSPECIFIED:
      return "VERDICT_UNSPECIFIED";
    case Autotriage_Verdict.TRUE_POSITIVE:
      return "VERDICT_TRUE_POSITIVE";
    case Autotriage_Verdict.FALSE_POSITIVE:
      return "VERDICT_FALSE_POSITIVE";
    case Autotriage_Verdict.NO_VERDICT:
      return "VERDICT_NO_VERDICT";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Autotriage_Verdict");
  }
}

export function autotriage_VerdictToNumber(object: Autotriage_Verdict): number {
  switch (object) {
    case Autotriage_Verdict.UNSPECIFIED:
      return 0;
    case Autotriage_Verdict.TRUE_POSITIVE:
      return 10;
    case Autotriage_Verdict.FALSE_POSITIVE:
      return 20;
    case Autotriage_Verdict.NO_VERDICT:
      return 30;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Autotriage_Verdict");
  }
}

export interface AutotriageFeedback {
  autotriageId: number;
  rating: AutotriageFeedback_Rating;
}

export enum AutotriageFeedback_Rating {
  UNSPECIFIED = "RATING_UNSPECIFIED",
  /** GOOD - Autotriage rated positively by a user. */
  GOOD = "RATING_GOOD",
  /** BAD - Autotriage rated negatively by a user. */
  BAD = "RATING_BAD",
}

export function autotriageFeedback_RatingFromJSON(object: any): AutotriageFeedback_Rating {
  switch (object) {
    case 0:
    case "RATING_UNSPECIFIED":
      return AutotriageFeedback_Rating.UNSPECIFIED;
    case 10:
    case "RATING_GOOD":
      return AutotriageFeedback_Rating.GOOD;
    case 20:
    case "RATING_BAD":
      return AutotriageFeedback_Rating.BAD;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AutotriageFeedback_Rating");
  }
}

export function autotriageFeedback_RatingToJSON(object: AutotriageFeedback_Rating): string {
  switch (object) {
    case AutotriageFeedback_Rating.UNSPECIFIED:
      return "RATING_UNSPECIFIED";
    case AutotriageFeedback_Rating.GOOD:
      return "RATING_GOOD";
    case AutotriageFeedback_Rating.BAD:
      return "RATING_BAD";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AutotriageFeedback_Rating");
  }
}

export function autotriageFeedback_RatingToNumber(object: AutotriageFeedback_Rating): number {
  switch (object) {
    case AutotriageFeedback_Rating.UNSPECIFIED:
      return 0;
    case AutotriageFeedback_Rating.GOOD:
      return 10;
    case AutotriageFeedback_Rating.BAD:
      return 20;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AutotriageFeedback_Rating");
  }
}

export interface ListAutotriagesResponse {
  autotriages: Autotriage[];
}

export interface PutAutotriageFeedbackRequest {
  feedback: AutotriageFeedback | undefined;
}

export interface PutAutotriageFeedbackResponse {
  newFeedback: AutotriageFeedback | undefined;
}

function createBaseAutotriage(): Autotriage {
  return {
    id: 0,
    issueId: 0,
    verdict: Autotriage_Verdict.UNSPECIFIED,
    reason: "",
    feedback: undefined,
    matchBasedId: "",
  };
}

export const Autotriage = {
  encode(message: Autotriage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.issueId !== 0) {
      writer.uint32(16).uint64(message.issueId);
    }
    if (message.verdict !== Autotriage_Verdict.UNSPECIFIED) {
      writer.uint32(24).int32(autotriage_VerdictToNumber(message.verdict));
    }
    if (message.reason !== "") {
      writer.uint32(34).string(message.reason);
    }
    if (message.feedback !== undefined) {
      AutotriageFeedback.encode(message.feedback, writer.uint32(42).fork()).ldelim();
    }
    if (message.matchBasedId !== "") {
      writer.uint32(50).string(message.matchBasedId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Autotriage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAutotriage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.issueId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.verdict = autotriage_VerdictFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.reason = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.feedback = AutotriageFeedback.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.matchBasedId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Autotriage {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      issueId: isSet(object.issueId) ? globalThis.Number(object.issueId) : 0,
      verdict: isSet(object.verdict) ? autotriage_VerdictFromJSON(object.verdict) : Autotriage_Verdict.UNSPECIFIED,
      reason: isSet(object.reason) ? globalThis.String(object.reason) : "",
      feedback: isSet(object.feedback) ? AutotriageFeedback.fromJSON(object.feedback) : undefined,
      matchBasedId: isSet(object.matchBasedId) ? globalThis.String(object.matchBasedId) : "",
    };
  },

  toJSON(message: Autotriage): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.issueId !== 0) {
      obj.issueId = Math.round(message.issueId);
    }
    if (message.verdict !== Autotriage_Verdict.UNSPECIFIED) {
      obj.verdict = autotriage_VerdictToJSON(message.verdict);
    }
    if (message.reason !== "") {
      obj.reason = message.reason;
    }
    if (message.feedback !== undefined) {
      obj.feedback = AutotriageFeedback.toJSON(message.feedback);
    }
    if (message.matchBasedId !== "") {
      obj.matchBasedId = message.matchBasedId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Autotriage>, I>>(base?: I): Autotriage {
    return Autotriage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Autotriage>, I>>(object: I): Autotriage {
    const message = createBaseAutotriage();
    message.id = object.id ?? 0;
    message.issueId = object.issueId ?? 0;
    message.verdict = object.verdict ?? Autotriage_Verdict.UNSPECIFIED;
    message.reason = object.reason ?? "";
    message.feedback = (object.feedback !== undefined && object.feedback !== null)
      ? AutotriageFeedback.fromPartial(object.feedback)
      : undefined;
    message.matchBasedId = object.matchBasedId ?? "";
    return message;
  },
};

function createBaseAutotriageFeedback(): AutotriageFeedback {
  return { autotriageId: 0, rating: AutotriageFeedback_Rating.UNSPECIFIED };
}

export const AutotriageFeedback = {
  encode(message: AutotriageFeedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.autotriageId !== 0) {
      writer.uint32(8).uint64(message.autotriageId);
    }
    if (message.rating !== AutotriageFeedback_Rating.UNSPECIFIED) {
      writer.uint32(16).int32(autotriageFeedback_RatingToNumber(message.rating));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AutotriageFeedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAutotriageFeedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.autotriageId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rating = autotriageFeedback_RatingFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AutotriageFeedback {
    return {
      autotriageId: isSet(object.autotriageId) ? globalThis.Number(object.autotriageId) : 0,
      rating: isSet(object.rating)
        ? autotriageFeedback_RatingFromJSON(object.rating)
        : AutotriageFeedback_Rating.UNSPECIFIED,
    };
  },

  toJSON(message: AutotriageFeedback): unknown {
    const obj: any = {};
    if (message.autotriageId !== 0) {
      obj.autotriageId = Math.round(message.autotriageId);
    }
    if (message.rating !== AutotriageFeedback_Rating.UNSPECIFIED) {
      obj.rating = autotriageFeedback_RatingToJSON(message.rating);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AutotriageFeedback>, I>>(base?: I): AutotriageFeedback {
    return AutotriageFeedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AutotriageFeedback>, I>>(object: I): AutotriageFeedback {
    const message = createBaseAutotriageFeedback();
    message.autotriageId = object.autotriageId ?? 0;
    message.rating = object.rating ?? AutotriageFeedback_Rating.UNSPECIFIED;
    return message;
  },
};

function createBaseListAutotriagesResponse(): ListAutotriagesResponse {
  return { autotriages: [] };
}

export const ListAutotriagesResponse = {
  encode(message: ListAutotriagesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.autotriages) {
      Autotriage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAutotriagesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAutotriagesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.autotriages.push(Autotriage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAutotriagesResponse {
    return {
      autotriages: globalThis.Array.isArray(object?.autotriages)
        ? object.autotriages.map((e: any) => Autotriage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListAutotriagesResponse): unknown {
    const obj: any = {};
    if (message.autotriages?.length) {
      obj.autotriages = message.autotriages.map((e) => Autotriage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAutotriagesResponse>, I>>(base?: I): ListAutotriagesResponse {
    return ListAutotriagesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAutotriagesResponse>, I>>(object: I): ListAutotriagesResponse {
    const message = createBaseListAutotriagesResponse();
    message.autotriages = object.autotriages?.map((e) => Autotriage.fromPartial(e)) || [];
    return message;
  },
};

function createBasePutAutotriageFeedbackRequest(): PutAutotriageFeedbackRequest {
  return { feedback: undefined };
}

export const PutAutotriageFeedbackRequest = {
  encode(message: PutAutotriageFeedbackRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.feedback !== undefined) {
      AutotriageFeedback.encode(message.feedback, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PutAutotriageFeedbackRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePutAutotriageFeedbackRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.feedback = AutotriageFeedback.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PutAutotriageFeedbackRequest {
    return { feedback: isSet(object.feedback) ? AutotriageFeedback.fromJSON(object.feedback) : undefined };
  },

  toJSON(message: PutAutotriageFeedbackRequest): unknown {
    const obj: any = {};
    if (message.feedback !== undefined) {
      obj.feedback = AutotriageFeedback.toJSON(message.feedback);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PutAutotriageFeedbackRequest>, I>>(base?: I): PutAutotriageFeedbackRequest {
    return PutAutotriageFeedbackRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PutAutotriageFeedbackRequest>, I>>(object: I): PutAutotriageFeedbackRequest {
    const message = createBasePutAutotriageFeedbackRequest();
    message.feedback = (object.feedback !== undefined && object.feedback !== null)
      ? AutotriageFeedback.fromPartial(object.feedback)
      : undefined;
    return message;
  },
};

function createBasePutAutotriageFeedbackResponse(): PutAutotriageFeedbackResponse {
  return { newFeedback: undefined };
}

export const PutAutotriageFeedbackResponse = {
  encode(message: PutAutotriageFeedbackResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.newFeedback !== undefined) {
      AutotriageFeedback.encode(message.newFeedback, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PutAutotriageFeedbackResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePutAutotriageFeedbackResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.newFeedback = AutotriageFeedback.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PutAutotriageFeedbackResponse {
    return { newFeedback: isSet(object.newFeedback) ? AutotriageFeedback.fromJSON(object.newFeedback) : undefined };
  },

  toJSON(message: PutAutotriageFeedbackResponse): unknown {
    const obj: any = {};
    if (message.newFeedback !== undefined) {
      obj.newFeedback = AutotriageFeedback.toJSON(message.newFeedback);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PutAutotriageFeedbackResponse>, I>>(base?: I): PutAutotriageFeedbackResponse {
    return PutAutotriageFeedbackResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PutAutotriageFeedbackResponse>, I>>(
    object: I,
  ): PutAutotriageFeedbackResponse {
    const message = createBasePutAutotriageFeedbackResponse();
    message.newFeedback = (object.newFeedback !== undefined && object.newFeedback !== null)
      ? AutotriageFeedback.fromPartial(object.newFeedback)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
