import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { faGithubSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Group, Skeleton, Title, Tooltip } from "@mantine/core";

import { SimpleExternalLink } from "@shared/components";

import { WorkbenchContext } from "../../providers";

const sourceUriToHistory = (sourceUri: string | undefined) => {
  if (
    !sourceUri ||
    !sourceUri.startsWith("https://github.com/semgrep/semgrep-rules/")
  )
    return undefined;
  return sourceUri.replace("blob", "commits");
};

const EditorTitleNotObserving = () => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;

  const gitHistoryLink = sourceUriToHistory(
    workbench.remoteBundle?.displayProps.sourceUri
  );

  return (
    <Group
      wrap="nowrap"
      data-testid="EditorHeader"
      style={{ flexShrink: 4, flexGrow: 1 }}
      h="100%"
      miw={40}
    >
      {bundle === null ? (
        <Skeleton height={24} width={144} />
      ) : (
        <div style={{ position: "relative", flexGrow: 1, height: "100%" }}>
          <Tooltip
            position="right-start"
            withArrow
            withinPortal
            label={
              <>
                To change the name, edit the <code>id</code> key in the editor:
                <br />
                <code>id: {bundle?.rule?.id ?? "<add rule id here>"}</code>
              </>
            }
          >
            <Title
              order={3}
              c="white"
              data-testid="EditorTitle"
              style={{
                textOverflow: "ellipsis",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
              }}
            >
              {/* using a template literal here to keep the editor from crashing if the rule id is wrapped in curly braces */}
              {`${bundle.lastTitle}`}
            </Title>
          </Tooltip>
        </div>
      )}
      {gitHistoryLink && (
        <SimpleExternalLink
          // align icon to text
          style={{ marginTop: "7px" }}
          href={gitHistoryLink}
        >
          <FontAwesomeIcon icon={faGithubSquare} style={{ fontSize: "41px" }} />
        </SimpleExternalLink>
      )}
    </Group>
  );
};

export const EditorTitle = observer(EditorTitleNotObserving);
