import { Menu } from "@mantine/core";

import { Workbench } from "../../stores";
import { RuleExample } from "../../utils/ruleExamples";

interface Props {
  example: RuleExample;
  workbench: Workbench;
}

export const ExamplesMenuItem = ({ example, workbench }: Props) => (
  <Menu.Item
    onClick={() =>
      workbench.newBundle({
        newPattern: example.pattern,
        newTarget: example.target,
      })
    }
  >
    {example.description}
  </Menu.Item>
);
