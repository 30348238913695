import { datadogRum } from "@datadog/browser-rum";

import { ApiError } from "@shared/types";

export const checkStatus = async (res: Response): Promise<Response> => {
  if (res.status >= 200 && res.status < 300) {
    return res;
  } else {
    const errorTitle = `Error fetching '${res.url}'`;
    let errorDetail = `Got ${res.status} - ${res.statusText}`;
    let errorBody;

    // let's try to get more context from the backend
    try {
      errorBody = await res.json();
      if (errorBody?.error !== "") {
        errorDetail = errorBody.error;
      }
    } catch (e) {
      // handle case where API is down and bad gateway from the load balancer isn't json
      console.error("unable to process error from backend", e);
    }

    const err = new ApiError(
      `${errorTitle} ${errorDetail}`,
      res.status,
      errorBody
    );

    // Using a console log here to avoid duplicate error reports with sentry and datadog
    // The css makes it look like a console error
    // Adding nosem here to ignore the no-console logs error
    // nosemgrep
    // eslint-disable-next-line no-console
    console.log(
      // nosemgrep
      `%c ${String.fromCodePoint(0x1f6ab)} ${err.message} ${errorBody}`,
      "color: #ff8080; background: hsl(0deg 100% 8%);"
    );

    datadogRum.addError(err, {
      url: res.url,
      statusCode: err.statusCode,
    });
    throw err;
  }
};
