import { useCallback, useEffect } from "react";
import { useDocumentVisibility } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/react";

import {
  CreateSearchRequest,
  SearchEngine,
  SearchState,
  TargetRepository,
} from "@protos/search/v1/search_service";
import { ApiError } from "@shared/types";
import { notify, requestNotificationPermission } from "@shared/utils";

import { useOrg } from "../useOrg";
import { useQueryConsoleResult } from "../useQueryConsoleResult";
import { useStartSearch } from "../useStartSearch";
import { useUrlSearch } from "../useUrlSearch";

export const TERMINAL_SEARCH_STATES = [
  SearchState.ERROR,
  SearchState.COMPLETE,
  SearchState.TIMEOUT,
];

export const RUNNING_SEARCH_STATES = [SearchState.RUNNING, SearchState.PENDING];

interface QueryConsoleOptions {
  rules: string;
  reposToSearch: TargetRepository[];
  // Current search id state is externalized so consumer can decide where it goes (e.g. URL or component state)
  currentSearchId: string | null;
  setCurrentSearchId: (id: string | null) => void;
  limit?: number;
}

export const useQueryConsole = ({
  rules,
  reposToSearch,
  currentSearchId,
  setCurrentSearchId,
  limit,
}: QueryConsoleOptions) => {
  const [org] = useOrg();
  const { search } = useUrlSearch();
  const documentState = useDocumentVisibility();

  // Send a notification if the user is not on the page
  const maybeAlertCompletion = useCallback(() => {
    if (documentState === "hidden") {
      notify(`Your search is complete!`);
    }
  }, [documentState]);

  const handleAPIError = useCallback(
    (err: ApiError) => {
      Sentry.captureException(err);
      maybeAlertCompletion();
      showNotification({
        message:
          err.message ||
          "There was an error running your search. Please try again.",
        color: "red",
      });
    },
    [maybeAlertCompletion]
  );

  const {
    mutate: postSearch,
    isPending: searchStarting,
    error: startSearchError,
  } = useStartSearch({
    onSuccess: (response) => setCurrentSearchId(response.searchId),
    onError: handleAPIError,
  });

  const startSearch = useCallback(() => {
    // Debug options taken from query params
    const workflowTemplateNameOverride =
      search.get("workflowName") ?? undefined;
    const engine =
      search.get("search_engine") === "argo"
        ? SearchEngine.ARGO
        : SearchEngine.SQS;

    postSearch(
      CreateSearchRequest.create({
        rules,
        deploymentId: org?.id,
        repositories: reposToSearch,
        workflowTemplateNameOverride,
        engine,
        limit,
      })
    );

    requestNotificationPermission();
  }, [search, postSearch, rules, org?.id, reposToSearch, limit]);

  const { data: searchResults } = useQueryConsoleResult(currentSearchId);
  const isRunning = !!searchResults && !searchResults.terminated;

  useEffect(() => {
    if (searchResults?.terminated) {
      maybeAlertCompletion();
    }
  }, [maybeAlertCompletion, searchResults?.terminated]);

  const cancel = useCallback(() => {
    setCurrentSearchId(null);
  }, [setCurrentSearchId]);

  return {
    startSearch,
    searchStarting,
    startSearchError,
    searchResults,
    isRunning,
    cancel,
  };
};
