import { HoverCard as MantineHoverCard } from "@mantine/core";

import classes from "./HoverCard.module.css";

export const HoverCard = MantineHoverCard.extend({
  classNames: classes,
  defaultProps: {
    transitionProps: {
      transition: "pop",
      duration: 350,
    },
  },
});
