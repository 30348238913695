import React from "react";
import styled from "styled-components";
import {
  faGithub,
  faSlack,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TWITTER_URL } from "@shared/constants";
import { useSupportsFeature } from "@shared/hooks";

import { Grid } from "../Grid";

const Container = styled.footer`
  background: linear-gradient(
    245deg,
    #165b98 35%,
    #091d2f 100%
  ); // TODO replace gradient for something recent
  &,
  & a {
    color: white;
  }

  &,
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-weight: 400;
  }

  border-top: 12px solid var(--mantine-color-green-5);
`;

const FooterGrid = styled(Grid)`
  margin: auto;
  max-width: 1160px;
  padding: 80px 20px 68px;
  text-align: left;
`;

const Panel = styled.section`
  padding-left: 20px;
  border-left: 1px solid #f2f8ff;
`;

const LinksPanel = styled(Panel)`
  display: flex;
  justify-content: space-between;
`;

const SocialLinks = styled.section`
  margin-right: 12px;
`;

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 28px;
  }
`;

const LinkList = styled.ul`
  margin: 0;
  padding-left: 0;
  list-style-type: none;
`;

const LinkListItem = styled.li`
  font-size: var(--mantine-font-size-md);
  line-height: 36px;
  text-decoration: none;
`;

const LinkListHiddenItem = styled.li`
  display: none;
`;

const LinkHeading = styled.h4`
  margin: 0;
  font-size: var(--mantine-font-size-md);
  line-height: 36px;
  color: #c6c8cc;
`;

export const Footer: React.FC = () => {
  const hasNonStandardTOC = useSupportsFeature("hasNonStandardTOC");
  return (
    <Container>
      <FooterGrid minWidth={340}>
        <LinksPanel>
          <SocialLinks>
            <SocialLink href="https://go.semgrep.dev/slack">
              <FontAwesomeIcon icon={faSlack} size="2x" fixedWidth />
              <span>Slack</span>
            </SocialLink>
            <SocialLink href="https://github.com/semgrep/semgrep">
              <FontAwesomeIcon icon={faGithub} size="2x" fixedWidth />
              <span>GitHub</span>
            </SocialLink>
            <SocialLink href={TWITTER_URL}>
              <FontAwesomeIcon icon={faTwitter} size="2x" fixedWidth />
              <span>Twitter</span>
            </SocialLink>
          </SocialLinks>
          <Grid columns={3}>
            <LinkList>
              <LinkHeading>Learn</LinkHeading>
              <LinkListItem>
                <a href="/docs/">Docs</a>
              </LinkListItem>
              <LinkListItem>
                <a href="/docs/writing-rules/rule-ideas/">Examples</a>
              </LinkListItem>
              <LinkListItem>
                <a href="/learn">Tour</a>
              </LinkListItem>
            </LinkList>
            <LinkList>
              <LinkHeading>Product</LinkHeading>
              <LinkListItem>
                <a href="/privacy">Privacy</a>
              </LinkListItem>
              <LinkListItem>
                <a href="https://github.com/semgrep/semgrep/issues">Issues</a>
              </LinkListItem>
              {!hasNonStandardTOC && (
                <LinkListItem>
                  <a href="/terms">Terms of service</a>
                </LinkListItem>
              )}
            </LinkList>
            <LinkList>
              <LinkHeading>About</LinkHeading>
              <LinkListItem>
                <a href="https://semgrep.dev/blog">Blog</a>
              </LinkListItem>
              <LinkListItem>
                <a href="https://semgrep.dev/about/">About us</a>
              </LinkListItem>
              <LinkListItem>
                <a href="/contact-us">Contact us</a>
              </LinkListItem>
              <LinkListHiddenItem>
                <a href="https://semgrep.dev/api/supersecret">Secret</a>
              </LinkListHiddenItem>
            </LinkList>
          </Grid>
        </LinksPanel>
        <Panel>
          <p>
            &copy; {new Date().getFullYear()}{" "}
            <a href="https://semgrep.dev">Semgrep Inc</a>. Semgrep is a
            registered trademark of Semgrep Inc.
          </p>
        </Panel>
      </FooterGrid>
    </Container>
  );
};
