import { OrgRole } from "@protos/deployment/v1/deployment";
import { RbacRole } from "@shared/types";

export const convertOrgRole = (role: OrgRole): RbacRole | null => {
  switch (role) {
    case OrgRole.ADMIN:
      return RbacRole.Admin;
    case OrgRole.MEMBER:
      return RbacRole.Member;
    case OrgRole.READONLY:
      return RbacRole.Readonly;
  }
  return null;
};

export const convertRbacRole = (role: RbacRole): OrgRole => {
  switch (role) {
    case RbacRole.Admin:
      return OrgRole.ADMIN;
    case RbacRole.Member:
      return OrgRole.MEMBER;
    case RbacRole.Readonly:
      return OrgRole.READONLY;
  }
};
