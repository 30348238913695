import React from "react";
import styled from "styled-components";
import { Tooltip } from "@mantine/core";

import { techToImage } from "@shared/constants";

const LangImg = styled.img`
  max-height: 20px;
  max-width: 20px;
  vertical-align: middle;
`;

export const LanguagesCell: React.FC<{
  languages: string[];
  large?: boolean;
}> = ({ languages, large = false }) => {
  return (
    <div>
      {languages.map((lang, idx) => (
        <Tooltip key={idx} label={lang} withArrow withinPortal>
          <span style={{ marginRight: large ? "16px" : "8px" }}>
            {techToImage[lang.toLowerCase()] ? (
              <LangImg // nosemgrep: typescript.react.security.audit.react-http-leak.react-http-leak
                src={techToImage[lang.toLowerCase()]}
                alt={lang}
              />
            ) : (
              <span>{lang}</span>
            )}
          </span>
        </Tooltip>
      ))}
    </div>
  );
};
