// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/permissions/common/v1/common.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { BoolValue } from "../../../../google/protobuf/wrappers";
import { Role, roleFromJSON, roleToJSON, roleToNumber } from "../../../auth/common/v1/common";

export const protobufPackage = "protos.permissions.common.v1";

export enum ProjectManagementType {
  UNSPECIFIED = "PROJECT_MANAGEMENT_TYPE_UNSPECIFIED",
  UNMANAGED = "PROJECT_MANAGEMENT_TYPE_UNMANAGED",
  ALL_PROJECTS = "PROJECT_MANAGEMENT_TYPE_ALL_PROJECTS",
}

export function projectManagementTypeFromJSON(object: any): ProjectManagementType {
  switch (object) {
    case 0:
    case "PROJECT_MANAGEMENT_TYPE_UNSPECIFIED":
      return ProjectManagementType.UNSPECIFIED;
    case 1:
    case "PROJECT_MANAGEMENT_TYPE_UNMANAGED":
      return ProjectManagementType.UNMANAGED;
    case 2:
    case "PROJECT_MANAGEMENT_TYPE_ALL_PROJECTS":
      return ProjectManagementType.ALL_PROJECTS;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ProjectManagementType");
  }
}

export function projectManagementTypeToJSON(object: ProjectManagementType): string {
  switch (object) {
    case ProjectManagementType.UNSPECIFIED:
      return "PROJECT_MANAGEMENT_TYPE_UNSPECIFIED";
    case ProjectManagementType.UNMANAGED:
      return "PROJECT_MANAGEMENT_TYPE_UNMANAGED";
    case ProjectManagementType.ALL_PROJECTS:
      return "PROJECT_MANAGEMENT_TYPE_ALL_PROJECTS";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ProjectManagementType");
  }
}

export function projectManagementTypeToNumber(object: ProjectManagementType): number {
  switch (object) {
    case ProjectManagementType.UNSPECIFIED:
      return 0;
    case ProjectManagementType.UNMANAGED:
      return 1;
    case ProjectManagementType.ALL_PROJECTS:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ProjectManagementType");
  }
}

export interface GroupUser {
  userId: number;
  role: Role;
}

export interface Group {
  id: number;
  deploymentId: number;
  name: string;
  slug: string;
  description?: string | undefined;
  repositoryIds: number[];
  users: GroupUser[];
  isDefault: boolean | undefined;
  projectManagementType: ProjectManagementType;
  /** Auth services allow for multiple parents, but the app allows only one */
  parentGroupId?: number | undefined;
  childGroupIds: number[];
  repositoryCount?: number | undefined;
  userCount?: number | undefined;
  childGroupCount?: number | undefined;
}

function createBaseGroupUser(): GroupUser {
  return { userId: 0, role: Role.UNSPECIFIED };
}

export const GroupUser = {
  encode(message: GroupUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int64(message.userId);
    }
    if (message.role !== Role.UNSPECIFIED) {
      writer.uint32(16).int32(roleToNumber(message.role));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GroupUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroupUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.role = roleFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GroupUser {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      role: isSet(object.role) ? roleFromJSON(object.role) : Role.UNSPECIFIED,
    };
  },

  toJSON(message: GroupUser): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.role !== Role.UNSPECIFIED) {
      obj.role = roleToJSON(message.role);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GroupUser>, I>>(base?: I): GroupUser {
    return GroupUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GroupUser>, I>>(object: I): GroupUser {
    const message = createBaseGroupUser();
    message.userId = object.userId ?? 0;
    message.role = object.role ?? Role.UNSPECIFIED;
    return message;
  },
};

function createBaseGroup(): Group {
  return {
    id: 0,
    deploymentId: 0,
    name: "",
    slug: "",
    description: undefined,
    repositoryIds: [],
    users: [],
    isDefault: undefined,
    projectManagementType: ProjectManagementType.UNSPECIFIED,
    parentGroupId: undefined,
    childGroupIds: [],
    repositoryCount: undefined,
    userCount: undefined,
    childGroupCount: undefined,
  };
}

export const Group = {
  encode(message: Group, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.deploymentId !== 0) {
      writer.uint32(16).int64(message.deploymentId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.slug !== "") {
      writer.uint32(34).string(message.slug);
    }
    if (message.description !== undefined) {
      writer.uint32(42).string(message.description);
    }
    writer.uint32(50).fork();
    for (const v of message.repositoryIds) {
      writer.int64(v);
    }
    writer.ldelim();
    for (const v of message.users) {
      GroupUser.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.isDefault !== undefined) {
      BoolValue.encode({ value: message.isDefault! }, writer.uint32(66).fork()).ldelim();
    }
    if (message.projectManagementType !== ProjectManagementType.UNSPECIFIED) {
      writer.uint32(72).int32(projectManagementTypeToNumber(message.projectManagementType));
    }
    if (message.parentGroupId !== undefined) {
      writer.uint32(80).int64(message.parentGroupId);
    }
    writer.uint32(90).fork();
    for (const v of message.childGroupIds) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.repositoryCount !== undefined) {
      writer.uint32(96).int64(message.repositoryCount);
    }
    if (message.userCount !== undefined) {
      writer.uint32(104).int64(message.userCount);
    }
    if (message.childGroupCount !== undefined) {
      writer.uint32(112).int64(message.childGroupCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Group {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.slug = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag === 48) {
            message.repositoryIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.repositoryIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.users.push(GroupUser.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.isDefault = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.projectManagementType = projectManagementTypeFromJSON(reader.int32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.parentGroupId = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag === 88) {
            message.childGroupIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 90) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.childGroupIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.repositoryCount = longToNumber(reader.int64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.userCount = longToNumber(reader.int64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.childGroupCount = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Group {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      slug: isSet(object.slug) ? globalThis.String(object.slug) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : undefined,
      repositoryIds: globalThis.Array.isArray(object?.repositoryIds)
        ? object.repositoryIds.map((e: any) => globalThis.Number(e))
        : [],
      users: globalThis.Array.isArray(object?.users) ? object.users.map((e: any) => GroupUser.fromJSON(e)) : [],
      isDefault: isSet(object.isDefault) ? Boolean(object.isDefault) : undefined,
      projectManagementType: isSet(object.projectManagementType)
        ? projectManagementTypeFromJSON(object.projectManagementType)
        : ProjectManagementType.UNSPECIFIED,
      parentGroupId: isSet(object.parentGroupId) ? globalThis.Number(object.parentGroupId) : undefined,
      childGroupIds: globalThis.Array.isArray(object?.childGroupIds)
        ? object.childGroupIds.map((e: any) => globalThis.Number(e))
        : [],
      repositoryCount: isSet(object.repositoryCount) ? globalThis.Number(object.repositoryCount) : undefined,
      userCount: isSet(object.userCount) ? globalThis.Number(object.userCount) : undefined,
      childGroupCount: isSet(object.childGroupCount) ? globalThis.Number(object.childGroupCount) : undefined,
    };
  },

  toJSON(message: Group): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.slug !== "") {
      obj.slug = message.slug;
    }
    if (message.description !== undefined) {
      obj.description = message.description;
    }
    if (message.repositoryIds?.length) {
      obj.repositoryIds = message.repositoryIds.map((e) => Math.round(e));
    }
    if (message.users?.length) {
      obj.users = message.users.map((e) => GroupUser.toJSON(e));
    }
    if (message.isDefault !== undefined) {
      obj.isDefault = message.isDefault;
    }
    if (message.projectManagementType !== ProjectManagementType.UNSPECIFIED) {
      obj.projectManagementType = projectManagementTypeToJSON(message.projectManagementType);
    }
    if (message.parentGroupId !== undefined) {
      obj.parentGroupId = Math.round(message.parentGroupId);
    }
    if (message.childGroupIds?.length) {
      obj.childGroupIds = message.childGroupIds.map((e) => Math.round(e));
    }
    if (message.repositoryCount !== undefined) {
      obj.repositoryCount = Math.round(message.repositoryCount);
    }
    if (message.userCount !== undefined) {
      obj.userCount = Math.round(message.userCount);
    }
    if (message.childGroupCount !== undefined) {
      obj.childGroupCount = Math.round(message.childGroupCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Group>, I>>(base?: I): Group {
    return Group.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Group>, I>>(object: I): Group {
    const message = createBaseGroup();
    message.id = object.id ?? 0;
    message.deploymentId = object.deploymentId ?? 0;
    message.name = object.name ?? "";
    message.slug = object.slug ?? "";
    message.description = object.description ?? undefined;
    message.repositoryIds = object.repositoryIds?.map((e) => e) || [];
    message.users = object.users?.map((e) => GroupUser.fromPartial(e)) || [];
    message.isDefault = object.isDefault ?? undefined;
    message.projectManagementType = object.projectManagementType ?? ProjectManagementType.UNSPECIFIED;
    message.parentGroupId = object.parentGroupId ?? undefined;
    message.childGroupIds = object.childGroupIds?.map((e) => e) || [];
    message.repositoryCount = object.repositoryCount ?? undefined;
    message.userCount = object.userCount ?? undefined;
    message.childGroupCount = object.childGroupCount ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
