// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/scan/v1/scan.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { FloatRange, ScmType, scmTypeFromJSON, scmTypeToJSON, scmTypeToNumber } from "../../common/v1/common";

export const protobufPackage = "protos.scan.v1";

export enum ScanStatus {
  UNSPECIFIED = "SCAN_STATUS_UNSPECIFIED",
  /** RUNNING - a scan that has been started but not yet completed */
  RUNNING = "SCAN_STATUS_RUNNING",
  /** COMPLETED - a scan that has completed with a success exit code (0 or 1) */
  COMPLETED = "SCAN_STATUS_COMPLETED",
  /** ERROR - a scan that has completed with a failing exit code */
  ERROR = "SCAN_STATUS_ERROR",
  /**
   * NEVER_FINISHED - a scan that has started but never completed, distinguished from running by
   * a timeout threshold
   */
  NEVER_FINISHED = "SCAN_STATUS_NEVER_FINISHED",
}

export function scanStatusFromJSON(object: any): ScanStatus {
  switch (object) {
    case 0:
    case "SCAN_STATUS_UNSPECIFIED":
      return ScanStatus.UNSPECIFIED;
    case 1:
    case "SCAN_STATUS_RUNNING":
      return ScanStatus.RUNNING;
    case 2:
    case "SCAN_STATUS_COMPLETED":
      return ScanStatus.COMPLETED;
    case 5:
    case "SCAN_STATUS_ERROR":
      return ScanStatus.ERROR;
    case 6:
    case "SCAN_STATUS_NEVER_FINISHED":
      return ScanStatus.NEVER_FINISHED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ScanStatus");
  }
}

export function scanStatusToJSON(object: ScanStatus): string {
  switch (object) {
    case ScanStatus.UNSPECIFIED:
      return "SCAN_STATUS_UNSPECIFIED";
    case ScanStatus.RUNNING:
      return "SCAN_STATUS_RUNNING";
    case ScanStatus.COMPLETED:
      return "SCAN_STATUS_COMPLETED";
    case ScanStatus.ERROR:
      return "SCAN_STATUS_ERROR";
    case ScanStatus.NEVER_FINISHED:
      return "SCAN_STATUS_NEVER_FINISHED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ScanStatus");
  }
}

export function scanStatusToNumber(object: ScanStatus): number {
  switch (object) {
    case ScanStatus.UNSPECIFIED:
      return 0;
    case ScanStatus.RUNNING:
      return 1;
    case ScanStatus.COMPLETED:
      return 2;
    case ScanStatus.ERROR:
      return 5;
    case ScanStatus.NEVER_FINISHED:
      return 6;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ScanStatus");
  }
}

export enum ScanEnvironment {
  UNSPECIFIED = "SCAN_ENVIRONMENT_UNSPECIFIED",
  LOCAL = "SCAN_ENVIRONMENT_LOCAL",
  MANAGED_SCANS = "SCAN_ENVIRONMENT_MANAGED_SCANS",
  GITHUB_ACTIONS = "SCAN_ENVIRONMENT_GITHUB_ACTIONS",
  GITLAB_CI = "SCAN_ENVIRONMENT_GITLAB_CI",
  BITBUCKET = "SCAN_ENVIRONMENT_BITBUCKET",
  JENKINS = "SCAN_ENVIRONMENT_JENKINS",
  CIRCLECI = "SCAN_ENVIRONMENT_CIRCLECI",
  BUILDKITE = "SCAN_ENVIRONMENT_BUILDKITE",
  AZURE_PIPELINES = "SCAN_ENVIRONMENT_AZURE_PIPELINES",
}

export function scanEnvironmentFromJSON(object: any): ScanEnvironment {
  switch (object) {
    case 0:
    case "SCAN_ENVIRONMENT_UNSPECIFIED":
      return ScanEnvironment.UNSPECIFIED;
    case 1:
    case "SCAN_ENVIRONMENT_LOCAL":
      return ScanEnvironment.LOCAL;
    case 2:
    case "SCAN_ENVIRONMENT_MANAGED_SCANS":
      return ScanEnvironment.MANAGED_SCANS;
    case 3:
    case "SCAN_ENVIRONMENT_GITHUB_ACTIONS":
      return ScanEnvironment.GITHUB_ACTIONS;
    case 4:
    case "SCAN_ENVIRONMENT_GITLAB_CI":
      return ScanEnvironment.GITLAB_CI;
    case 5:
    case "SCAN_ENVIRONMENT_BITBUCKET":
      return ScanEnvironment.BITBUCKET;
    case 6:
    case "SCAN_ENVIRONMENT_JENKINS":
      return ScanEnvironment.JENKINS;
    case 7:
    case "SCAN_ENVIRONMENT_CIRCLECI":
      return ScanEnvironment.CIRCLECI;
    case 8:
    case "SCAN_ENVIRONMENT_BUILDKITE":
      return ScanEnvironment.BUILDKITE;
    case 9:
    case "SCAN_ENVIRONMENT_AZURE_PIPELINES":
      return ScanEnvironment.AZURE_PIPELINES;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ScanEnvironment");
  }
}

export function scanEnvironmentToJSON(object: ScanEnvironment): string {
  switch (object) {
    case ScanEnvironment.UNSPECIFIED:
      return "SCAN_ENVIRONMENT_UNSPECIFIED";
    case ScanEnvironment.LOCAL:
      return "SCAN_ENVIRONMENT_LOCAL";
    case ScanEnvironment.MANAGED_SCANS:
      return "SCAN_ENVIRONMENT_MANAGED_SCANS";
    case ScanEnvironment.GITHUB_ACTIONS:
      return "SCAN_ENVIRONMENT_GITHUB_ACTIONS";
    case ScanEnvironment.GITLAB_CI:
      return "SCAN_ENVIRONMENT_GITLAB_CI";
    case ScanEnvironment.BITBUCKET:
      return "SCAN_ENVIRONMENT_BITBUCKET";
    case ScanEnvironment.JENKINS:
      return "SCAN_ENVIRONMENT_JENKINS";
    case ScanEnvironment.CIRCLECI:
      return "SCAN_ENVIRONMENT_CIRCLECI";
    case ScanEnvironment.BUILDKITE:
      return "SCAN_ENVIRONMENT_BUILDKITE";
    case ScanEnvironment.AZURE_PIPELINES:
      return "SCAN_ENVIRONMENT_AZURE_PIPELINES";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ScanEnvironment");
  }
}

export function scanEnvironmentToNumber(object: ScanEnvironment): number {
  switch (object) {
    case ScanEnvironment.UNSPECIFIED:
      return 0;
    case ScanEnvironment.LOCAL:
      return 1;
    case ScanEnvironment.MANAGED_SCANS:
      return 2;
    case ScanEnvironment.GITHUB_ACTIONS:
      return 3;
    case ScanEnvironment.GITLAB_CI:
      return 4;
    case ScanEnvironment.BITBUCKET:
      return 5;
    case ScanEnvironment.JENKINS:
      return 6;
    case ScanEnvironment.CIRCLECI:
      return 7;
    case ScanEnvironment.BUILDKITE:
      return 8;
    case ScanEnvironment.AZURE_PIPELINES:
      return 9;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ScanEnvironment");
  }
}

export interface ScanSummary {
  /** ID of the scan */
  id: number;
  /** when the scan was started */
  startedAt:
    | Date
    | undefined;
  /** when the scan was completed */
  completedAt?:
    | Date
    | undefined;
  /** scan exit code, can be negative */
  exitCode?:
    | number
    | undefined;
  /** if the scan has logs that are stored by Semgrep (SMS only) */
  hasLogs: boolean;
  /** scan status */
  status: ScanStatus;
}

export interface S3LogLink {
  bucket: string;
  key: string;
}

export interface CreateLogLinkRequest {
  scanId: number;
  logLink?: { $case: "s3LogLink"; s3LogLink: S3LogLink } | undefined;
}

export interface CreateLogLinkResponse {
}

export interface GetLogRequest {
  scanId: number;
}

export interface GetLogResponse {
  scanId: number;
  log: string;
}

export interface GetScanRequest {
  scanId: number;
}

export interface GetScanResponse {
  scan: Scan | undefined;
}

export interface SearchScansRequest {
  deploymentId: number;
  /**
   * Cursor to paginate through the results (replaced with cursor_enc)
   *
   * @deprecated
   */
  cursor?:
    | number
    | undefined;
  /** Page size to paginate through the results (default is 100, max is 500) */
  limit?:
    | number
    | undefined;
  /** Only get scans created after this time */
  since?:
    | Date
    | undefined;
  /** Only get scans for this repo */
  repositoryId?:
    | number
    | undefined;
  /** Only get scans that are full scans (if false, only get diff scans) */
  isFullScan?:
    | boolean
    | undefined;
  /** Only get scans that have one of these statuses */
  statuses: ScanStatus[];
  /** Only get scans that have a total running time within this range */
  totalTime?:
    | FloatRange
    | undefined;
  /** Use an encrypted cursor to paginate through the results */
  cursorEnc?: string | undefined;
}

export interface ScanFindingsCounts {
  /** total number of findings */
  total?: number | undefined;
  code?: number | undefined;
  supplyChain?: number | undefined;
  secrets?: number | undefined;
}

export interface LanguageParseRate {
  /** the total number of bytes */
  numBytes?:
    | number
    | undefined;
  /** the number of bytes parsed */
  bytesParsed?:
    | number
    | undefined;
  /** the total number of targets */
  numTargets?:
    | number
    | undefined;
  /** the number of targets parsed */
  targetsParsed?: number | undefined;
}

export interface Scan {
  /** ID of the scan */
  id: number;
  /** ID of the repository the scan is for */
  repositoryId: number;
  /** ID of the deployment the scan is for */
  deploymentId: number;
  /** when the scan was started */
  startedAt:
    | Date
    | undefined;
  /** when the scan was completed */
  completedAt?:
    | Date
    | undefined;
  /** scan exit code, can be negative */
  exitCode?:
    | number
    | undefined;
  /** if the scan has logs that are stored by Semgrep (SMS only) */
  hasLogs: boolean;
  /** link to the CI job */
  ciJobUrl?:
    | string
    | undefined;
  /** commit hash the scan was run on */
  commit?:
    | string
    | undefined;
  /** if the scan is a full scan */
  isFullScan?:
    | boolean
    | undefined;
  /** scan status */
  status: ScanStatus;
  /** findings counts */
  findingsCounts?:
    | ScanFindingsCounts
    | undefined;
  /** total time the scan took to run */
  totalTime?:
    | number
    | undefined;
  /** branch the scan was run on */
  branch?:
    | string
    | undefined;
  /** pull (or merge) request ID the scan was run on */
  pullRequestId?:
    | number
    | undefined;
  /** the environment where the scan was run */
  environment: ScanEnvironment;
  /** the type of source control management system the scan was run on */
  scmType: ScmType;
  /** the parse rate of different languages the scan was run on */
  parseRate: { [key: string]: LanguageParseRate };
  /** the ref that the scan was run on */
  repositoryRefId: number;
  /** the url to the repository where the scan was run */
  repositoryUrl?:
    | string
    | undefined;
  /** the url to the ref where the scan was run */
  refUrl?:
    | string
    | undefined;
  /** the cli version from the scan metadata */
  cliVersion?: string | undefined;
}

export interface Scan_ParseRateEntry {
  key: string;
  value: LanguageParseRate | undefined;
}

export interface SearchScansResponse {
  /** List of scans */
  scans: Scan[];
  /**
   * Cursor to paginate through the results (replaced with cursor_enc)
   *
   * @deprecated
   */
  cursor?:
    | number
    | undefined;
  /** Use an encrypted cursor to paginate through the results */
  cursorEnc?: string | undefined;
}

export interface ScanPublic {
  /** ID of the scan */
  id: number;
  /** ID of the deployment the scan is for */
  deploymentId: number;
  /** ID of the repository the scan is for */
  repositoryId: number;
  /** branch the scan was run on */
  branch?:
    | string
    | undefined;
  /** scanned commit hash */
  commit?:
    | string
    | undefined;
  /** when the scan was started */
  startedAt:
    | Date
    | undefined;
  /** when the scan was completed */
  completedAt?:
    | Date
    | undefined;
  /** scan exit code, can be negative */
  exitCode?:
    | number
    | undefined;
  /** if the scan is a full scan */
  isFullScan?:
    | boolean
    | undefined;
  /** total time the scan took to run */
  totalTime?:
    | number
    | undefined;
  /** findings counts */
  findingsCounts?:
    | ScanFindingsCounts
    | undefined;
  /** status of the scan */
  status?: ScanStatus | undefined;
}

export interface ListScansPublicRequest {
  deploymentId: number;
  /** Cursor to paginate through the results */
  cursor?:
    | string
    | undefined;
  /** Page size to paginate through the results (default is 100, max is 500) */
  limit?:
    | number
    | undefined;
  /** Only get scans created after this time */
  since?:
    | Date
    | undefined;
  /** Only get scans for this repo */
  repositoryId?:
    | number
    | undefined;
  /** Only get scans that are full scans (if false, only get diff scans) */
  isFullScan?:
    | boolean
    | undefined;
  /** Only get scans that have one of these statuses */
  statuses: ScanStatus[];
  /** Only get scans that have a total running time within this range */
  totalTime?:
    | FloatRange
    | undefined;
  /** Only get scans from the specified branch */
  branch?: string | undefined;
}

export interface ListScansPublicResponse {
  /** List of scans */
  scans: ScanPublic[];
  /** Cursor to paginate through the results */
  cursor?: string | undefined;
}

function createBaseScanSummary(): ScanSummary {
  return {
    id: 0,
    startedAt: undefined,
    completedAt: undefined,
    exitCode: undefined,
    hasLogs: false,
    status: ScanStatus.UNSPECIFIED,
  };
}

export const ScanSummary = {
  encode(message: ScanSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.startedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.startedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.completedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.completedAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.exitCode !== undefined) {
      writer.uint32(32).int64(message.exitCode);
    }
    if (message.hasLogs !== false) {
      writer.uint32(40).bool(message.hasLogs);
    }
    if (message.status !== ScanStatus.UNSPECIFIED) {
      writer.uint32(48).int32(scanStatusToNumber(message.status));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.completedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.exitCode = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.hasLogs = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.status = scanStatusFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanSummary {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      startedAt: isSet(object.startedAt) ? fromJsonTimestamp(object.startedAt) : undefined,
      completedAt: isSet(object.completedAt) ? fromJsonTimestamp(object.completedAt) : undefined,
      exitCode: isSet(object.exitCode) ? globalThis.Number(object.exitCode) : undefined,
      hasLogs: isSet(object.hasLogs) ? globalThis.Boolean(object.hasLogs) : false,
      status: isSet(object.status) ? scanStatusFromJSON(object.status) : ScanStatus.UNSPECIFIED,
    };
  },

  toJSON(message: ScanSummary): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.startedAt !== undefined) {
      obj.startedAt = message.startedAt.toISOString();
    }
    if (message.completedAt !== undefined) {
      obj.completedAt = message.completedAt.toISOString();
    }
    if (message.exitCode !== undefined) {
      obj.exitCode = Math.round(message.exitCode);
    }
    if (message.hasLogs !== false) {
      obj.hasLogs = message.hasLogs;
    }
    if (message.status !== ScanStatus.UNSPECIFIED) {
      obj.status = scanStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanSummary>, I>>(base?: I): ScanSummary {
    return ScanSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScanSummary>, I>>(object: I): ScanSummary {
    const message = createBaseScanSummary();
    message.id = object.id ?? 0;
    message.startedAt = object.startedAt ?? undefined;
    message.completedAt = object.completedAt ?? undefined;
    message.exitCode = object.exitCode ?? undefined;
    message.hasLogs = object.hasLogs ?? false;
    message.status = object.status ?? ScanStatus.UNSPECIFIED;
    return message;
  },
};

function createBaseS3LogLink(): S3LogLink {
  return { bucket: "", key: "" };
}

export const S3LogLink = {
  encode(message: S3LogLink, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bucket !== "") {
      writer.uint32(10).string(message.bucket);
    }
    if (message.key !== "") {
      writer.uint32(18).string(message.key);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): S3LogLink {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseS3LogLink();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bucket = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.key = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): S3LogLink {
    return {
      bucket: isSet(object.bucket) ? globalThis.String(object.bucket) : "",
      key: isSet(object.key) ? globalThis.String(object.key) : "",
    };
  },

  toJSON(message: S3LogLink): unknown {
    const obj: any = {};
    if (message.bucket !== "") {
      obj.bucket = message.bucket;
    }
    if (message.key !== "") {
      obj.key = message.key;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<S3LogLink>, I>>(base?: I): S3LogLink {
    return S3LogLink.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<S3LogLink>, I>>(object: I): S3LogLink {
    const message = createBaseS3LogLink();
    message.bucket = object.bucket ?? "";
    message.key = object.key ?? "";
    return message;
  },
};

function createBaseCreateLogLinkRequest(): CreateLogLinkRequest {
  return { scanId: 0, logLink: undefined };
}

export const CreateLogLinkRequest = {
  encode(message: CreateLogLinkRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== 0) {
      writer.uint32(8).uint64(message.scanId);
    }
    switch (message.logLink?.$case) {
      case "s3LogLink":
        S3LogLink.encode(message.logLink.s3LogLink, writer.uint32(18).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateLogLinkRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateLogLinkRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scanId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.logLink = { $case: "s3LogLink", s3LogLink: S3LogLink.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateLogLinkRequest {
    return {
      scanId: isSet(object.scanId) ? globalThis.Number(object.scanId) : 0,
      logLink: isSet(object.s3LogLink)
        ? { $case: "s3LogLink", s3LogLink: S3LogLink.fromJSON(object.s3LogLink) }
        : undefined,
    };
  },

  toJSON(message: CreateLogLinkRequest): unknown {
    const obj: any = {};
    if (message.scanId !== 0) {
      obj.scanId = Math.round(message.scanId);
    }
    if (message.logLink?.$case === "s3LogLink") {
      obj.s3LogLink = S3LogLink.toJSON(message.logLink.s3LogLink);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateLogLinkRequest>, I>>(base?: I): CreateLogLinkRequest {
    return CreateLogLinkRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateLogLinkRequest>, I>>(object: I): CreateLogLinkRequest {
    const message = createBaseCreateLogLinkRequest();
    message.scanId = object.scanId ?? 0;
    if (
      object.logLink?.$case === "s3LogLink" &&
      object.logLink?.s3LogLink !== undefined &&
      object.logLink?.s3LogLink !== null
    ) {
      message.logLink = { $case: "s3LogLink", s3LogLink: S3LogLink.fromPartial(object.logLink.s3LogLink) };
    }
    return message;
  },
};

function createBaseCreateLogLinkResponse(): CreateLogLinkResponse {
  return {};
}

export const CreateLogLinkResponse = {
  encode(_: CreateLogLinkResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateLogLinkResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateLogLinkResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CreateLogLinkResponse {
    return {};
  },

  toJSON(_: CreateLogLinkResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateLogLinkResponse>, I>>(base?: I): CreateLogLinkResponse {
    return CreateLogLinkResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateLogLinkResponse>, I>>(_: I): CreateLogLinkResponse {
    const message = createBaseCreateLogLinkResponse();
    return message;
  },
};

function createBaseGetLogRequest(): GetLogRequest {
  return { scanId: 0 };
}

export const GetLogRequest = {
  encode(message: GetLogRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== 0) {
      writer.uint32(8).uint64(message.scanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLogRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLogRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scanId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLogRequest {
    return { scanId: isSet(object.scanId) ? globalThis.Number(object.scanId) : 0 };
  },

  toJSON(message: GetLogRequest): unknown {
    const obj: any = {};
    if (message.scanId !== 0) {
      obj.scanId = Math.round(message.scanId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLogRequest>, I>>(base?: I): GetLogRequest {
    return GetLogRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLogRequest>, I>>(object: I): GetLogRequest {
    const message = createBaseGetLogRequest();
    message.scanId = object.scanId ?? 0;
    return message;
  },
};

function createBaseGetLogResponse(): GetLogResponse {
  return { scanId: 0, log: "" };
}

export const GetLogResponse = {
  encode(message: GetLogResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== 0) {
      writer.uint32(8).uint64(message.scanId);
    }
    if (message.log !== "") {
      writer.uint32(18).string(message.log);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLogResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLogResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scanId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.log = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLogResponse {
    return {
      scanId: isSet(object.scanId) ? globalThis.Number(object.scanId) : 0,
      log: isSet(object.log) ? globalThis.String(object.log) : "",
    };
  },

  toJSON(message: GetLogResponse): unknown {
    const obj: any = {};
    if (message.scanId !== 0) {
      obj.scanId = Math.round(message.scanId);
    }
    if (message.log !== "") {
      obj.log = message.log;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLogResponse>, I>>(base?: I): GetLogResponse {
    return GetLogResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLogResponse>, I>>(object: I): GetLogResponse {
    const message = createBaseGetLogResponse();
    message.scanId = object.scanId ?? 0;
    message.log = object.log ?? "";
    return message;
  },
};

function createBaseGetScanRequest(): GetScanRequest {
  return { scanId: 0 };
}

export const GetScanRequest = {
  encode(message: GetScanRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== 0) {
      writer.uint32(8).uint64(message.scanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetScanRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetScanRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scanId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetScanRequest {
    return { scanId: isSet(object.scanId) ? globalThis.Number(object.scanId) : 0 };
  },

  toJSON(message: GetScanRequest): unknown {
    const obj: any = {};
    if (message.scanId !== 0) {
      obj.scanId = Math.round(message.scanId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetScanRequest>, I>>(base?: I): GetScanRequest {
    return GetScanRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetScanRequest>, I>>(object: I): GetScanRequest {
    const message = createBaseGetScanRequest();
    message.scanId = object.scanId ?? 0;
    return message;
  },
};

function createBaseGetScanResponse(): GetScanResponse {
  return { scan: undefined };
}

export const GetScanResponse = {
  encode(message: GetScanResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scan !== undefined) {
      Scan.encode(message.scan, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetScanResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetScanResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scan = Scan.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetScanResponse {
    return { scan: isSet(object.scan) ? Scan.fromJSON(object.scan) : undefined };
  },

  toJSON(message: GetScanResponse): unknown {
    const obj: any = {};
    if (message.scan !== undefined) {
      obj.scan = Scan.toJSON(message.scan);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetScanResponse>, I>>(base?: I): GetScanResponse {
    return GetScanResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetScanResponse>, I>>(object: I): GetScanResponse {
    const message = createBaseGetScanResponse();
    message.scan = (object.scan !== undefined && object.scan !== null) ? Scan.fromPartial(object.scan) : undefined;
    return message;
  },
};

function createBaseSearchScansRequest(): SearchScansRequest {
  return {
    deploymentId: 0,
    cursor: undefined,
    limit: undefined,
    since: undefined,
    repositoryId: undefined,
    isFullScan: undefined,
    statuses: [],
    totalTime: undefined,
    cursorEnc: undefined,
  };
}

export const SearchScansRequest = {
  encode(message: SearchScansRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.cursor !== undefined) {
      writer.uint32(16).uint64(message.cursor);
    }
    if (message.limit !== undefined) {
      writer.uint32(24).uint64(message.limit);
    }
    if (message.since !== undefined) {
      Timestamp.encode(toTimestamp(message.since), writer.uint32(34).fork()).ldelim();
    }
    if (message.repositoryId !== undefined) {
      writer.uint32(40).uint64(message.repositoryId);
    }
    if (message.isFullScan !== undefined) {
      writer.uint32(48).bool(message.isFullScan);
    }
    writer.uint32(58).fork();
    for (const v of message.statuses) {
      writer.int32(scanStatusToNumber(v));
    }
    writer.ldelim();
    if (message.totalTime !== undefined) {
      FloatRange.encode(message.totalTime, writer.uint32(66).fork()).ldelim();
    }
    if (message.cursorEnc !== undefined) {
      writer.uint32(74).string(message.cursorEnc);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchScansRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchScansRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.cursor = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.limit = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.since = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.repositoryId = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isFullScan = reader.bool();
          continue;
        case 7:
          if (tag === 56) {
            message.statuses.push(scanStatusFromJSON(reader.int32()));

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.statuses.push(scanStatusFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.totalTime = FloatRange.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.cursorEnc = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchScansRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      cursor: isSet(object.cursor) ? globalThis.Number(object.cursor) : undefined,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : undefined,
      since: isSet(object.since) ? fromJsonTimestamp(object.since) : undefined,
      repositoryId: isSet(object.repositoryId) ? globalThis.Number(object.repositoryId) : undefined,
      isFullScan: isSet(object.isFullScan) ? globalThis.Boolean(object.isFullScan) : undefined,
      statuses: globalThis.Array.isArray(object?.statuses)
        ? object.statuses.map((e: any) => scanStatusFromJSON(e))
        : [],
      totalTime: isSet(object.totalTime) ? FloatRange.fromJSON(object.totalTime) : undefined,
      cursorEnc: isSet(object.cursorEnc) ? globalThis.String(object.cursorEnc) : undefined,
    };
  },

  toJSON(message: SearchScansRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.cursor !== undefined) {
      obj.cursor = Math.round(message.cursor);
    }
    if (message.limit !== undefined) {
      obj.limit = Math.round(message.limit);
    }
    if (message.since !== undefined) {
      obj.since = message.since.toISOString();
    }
    if (message.repositoryId !== undefined) {
      obj.repositoryId = Math.round(message.repositoryId);
    }
    if (message.isFullScan !== undefined) {
      obj.isFullScan = message.isFullScan;
    }
    if (message.statuses?.length) {
      obj.statuses = message.statuses.map((e) => scanStatusToJSON(e));
    }
    if (message.totalTime !== undefined) {
      obj.totalTime = FloatRange.toJSON(message.totalTime);
    }
    if (message.cursorEnc !== undefined) {
      obj.cursorEnc = message.cursorEnc;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchScansRequest>, I>>(base?: I): SearchScansRequest {
    return SearchScansRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchScansRequest>, I>>(object: I): SearchScansRequest {
    const message = createBaseSearchScansRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.cursor = object.cursor ?? undefined;
    message.limit = object.limit ?? undefined;
    message.since = object.since ?? undefined;
    message.repositoryId = object.repositoryId ?? undefined;
    message.isFullScan = object.isFullScan ?? undefined;
    message.statuses = object.statuses?.map((e) => e) || [];
    message.totalTime = (object.totalTime !== undefined && object.totalTime !== null)
      ? FloatRange.fromPartial(object.totalTime)
      : undefined;
    message.cursorEnc = object.cursorEnc ?? undefined;
    return message;
  },
};

function createBaseScanFindingsCounts(): ScanFindingsCounts {
  return { total: undefined, code: undefined, supplyChain: undefined, secrets: undefined };
}

export const ScanFindingsCounts = {
  encode(message: ScanFindingsCounts, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.total !== undefined) {
      writer.uint32(8).uint64(message.total);
    }
    if (message.code !== undefined) {
      writer.uint32(16).uint64(message.code);
    }
    if (message.supplyChain !== undefined) {
      writer.uint32(24).uint64(message.supplyChain);
    }
    if (message.secrets !== undefined) {
      writer.uint32(32).uint64(message.secrets);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanFindingsCounts {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanFindingsCounts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.total = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.code = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.supplyChain = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.secrets = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanFindingsCounts {
    return {
      total: isSet(object.total) ? globalThis.Number(object.total) : undefined,
      code: isSet(object.code) ? globalThis.Number(object.code) : undefined,
      supplyChain: isSet(object.supplyChain) ? globalThis.Number(object.supplyChain) : undefined,
      secrets: isSet(object.secrets) ? globalThis.Number(object.secrets) : undefined,
    };
  },

  toJSON(message: ScanFindingsCounts): unknown {
    const obj: any = {};
    if (message.total !== undefined) {
      obj.total = Math.round(message.total);
    }
    if (message.code !== undefined) {
      obj.code = Math.round(message.code);
    }
    if (message.supplyChain !== undefined) {
      obj.supplyChain = Math.round(message.supplyChain);
    }
    if (message.secrets !== undefined) {
      obj.secrets = Math.round(message.secrets);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanFindingsCounts>, I>>(base?: I): ScanFindingsCounts {
    return ScanFindingsCounts.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScanFindingsCounts>, I>>(object: I): ScanFindingsCounts {
    const message = createBaseScanFindingsCounts();
    message.total = object.total ?? undefined;
    message.code = object.code ?? undefined;
    message.supplyChain = object.supplyChain ?? undefined;
    message.secrets = object.secrets ?? undefined;
    return message;
  },
};

function createBaseLanguageParseRate(): LanguageParseRate {
  return { numBytes: undefined, bytesParsed: undefined, numTargets: undefined, targetsParsed: undefined };
}

export const LanguageParseRate = {
  encode(message: LanguageParseRate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.numBytes !== undefined) {
      writer.uint32(8).int64(message.numBytes);
    }
    if (message.bytesParsed !== undefined) {
      writer.uint32(16).int64(message.bytesParsed);
    }
    if (message.numTargets !== undefined) {
      writer.uint32(24).int64(message.numTargets);
    }
    if (message.targetsParsed !== undefined) {
      writer.uint32(32).int64(message.targetsParsed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LanguageParseRate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLanguageParseRate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.numBytes = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.bytesParsed = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.numTargets = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.targetsParsed = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LanguageParseRate {
    return {
      numBytes: isSet(object.numBytes) ? globalThis.Number(object.numBytes) : undefined,
      bytesParsed: isSet(object.bytesParsed) ? globalThis.Number(object.bytesParsed) : undefined,
      numTargets: isSet(object.numTargets) ? globalThis.Number(object.numTargets) : undefined,
      targetsParsed: isSet(object.targetsParsed) ? globalThis.Number(object.targetsParsed) : undefined,
    };
  },

  toJSON(message: LanguageParseRate): unknown {
    const obj: any = {};
    if (message.numBytes !== undefined) {
      obj.numBytes = Math.round(message.numBytes);
    }
    if (message.bytesParsed !== undefined) {
      obj.bytesParsed = Math.round(message.bytesParsed);
    }
    if (message.numTargets !== undefined) {
      obj.numTargets = Math.round(message.numTargets);
    }
    if (message.targetsParsed !== undefined) {
      obj.targetsParsed = Math.round(message.targetsParsed);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LanguageParseRate>, I>>(base?: I): LanguageParseRate {
    return LanguageParseRate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LanguageParseRate>, I>>(object: I): LanguageParseRate {
    const message = createBaseLanguageParseRate();
    message.numBytes = object.numBytes ?? undefined;
    message.bytesParsed = object.bytesParsed ?? undefined;
    message.numTargets = object.numTargets ?? undefined;
    message.targetsParsed = object.targetsParsed ?? undefined;
    return message;
  },
};

function createBaseScan(): Scan {
  return {
    id: 0,
    repositoryId: 0,
    deploymentId: 0,
    startedAt: undefined,
    completedAt: undefined,
    exitCode: undefined,
    hasLogs: false,
    ciJobUrl: undefined,
    commit: undefined,
    isFullScan: undefined,
    status: ScanStatus.UNSPECIFIED,
    findingsCounts: undefined,
    totalTime: undefined,
    branch: undefined,
    pullRequestId: undefined,
    environment: ScanEnvironment.UNSPECIFIED,
    scmType: ScmType.UNSPECIFIED,
    parseRate: {},
    repositoryRefId: 0,
    repositoryUrl: undefined,
    refUrl: undefined,
    cliVersion: undefined,
  };
}

export const Scan = {
  encode(message: Scan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.repositoryId !== 0) {
      writer.uint32(16).uint64(message.repositoryId);
    }
    if (message.deploymentId !== 0) {
      writer.uint32(24).uint64(message.deploymentId);
    }
    if (message.startedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.startedAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.completedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.completedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.exitCode !== undefined) {
      writer.uint32(48).int64(message.exitCode);
    }
    if (message.hasLogs !== false) {
      writer.uint32(56).bool(message.hasLogs);
    }
    if (message.ciJobUrl !== undefined) {
      writer.uint32(66).string(message.ciJobUrl);
    }
    if (message.commit !== undefined) {
      writer.uint32(74).string(message.commit);
    }
    if (message.isFullScan !== undefined) {
      writer.uint32(80).bool(message.isFullScan);
    }
    if (message.status !== ScanStatus.UNSPECIFIED) {
      writer.uint32(88).int32(scanStatusToNumber(message.status));
    }
    if (message.findingsCounts !== undefined) {
      ScanFindingsCounts.encode(message.findingsCounts, writer.uint32(98).fork()).ldelim();
    }
    if (message.totalTime !== undefined) {
      writer.uint32(109).float(message.totalTime);
    }
    if (message.branch !== undefined) {
      writer.uint32(114).string(message.branch);
    }
    if (message.pullRequestId !== undefined) {
      writer.uint32(120).uint64(message.pullRequestId);
    }
    if (message.environment !== ScanEnvironment.UNSPECIFIED) {
      writer.uint32(128).int32(scanEnvironmentToNumber(message.environment));
    }
    if (message.scmType !== ScmType.UNSPECIFIED) {
      writer.uint32(136).int32(scmTypeToNumber(message.scmType));
    }
    Object.entries(message.parseRate).forEach(([key, value]) => {
      Scan_ParseRateEntry.encode({ key: key as any, value }, writer.uint32(146).fork()).ldelim();
    });
    if (message.repositoryRefId !== 0) {
      writer.uint32(152).uint64(message.repositoryRefId);
    }
    if (message.repositoryUrl !== undefined) {
      writer.uint32(162).string(message.repositoryUrl);
    }
    if (message.refUrl !== undefined) {
      writer.uint32(170).string(message.refUrl);
    }
    if (message.cliVersion !== undefined) {
      writer.uint32(178).string(message.cliVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Scan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.repositoryId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.startedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.completedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.exitCode = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.hasLogs = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.ciJobUrl = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.commit = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isFullScan = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.status = scanStatusFromJSON(reader.int32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.findingsCounts = ScanFindingsCounts.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 109) {
            break;
          }

          message.totalTime = reader.float();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.branch = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.pullRequestId = longToNumber(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.environment = scanEnvironmentFromJSON(reader.int32());
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.scmType = scmTypeFromJSON(reader.int32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          const entry18 = Scan_ParseRateEntry.decode(reader, reader.uint32());
          if (entry18.value !== undefined) {
            message.parseRate[entry18.key] = entry18.value;
          }
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.repositoryRefId = longToNumber(reader.uint64() as Long);
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.repositoryUrl = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.refUrl = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.cliVersion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Scan {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      repositoryId: isSet(object.repositoryId) ? globalThis.Number(object.repositoryId) : 0,
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      startedAt: isSet(object.startedAt) ? fromJsonTimestamp(object.startedAt) : undefined,
      completedAt: isSet(object.completedAt) ? fromJsonTimestamp(object.completedAt) : undefined,
      exitCode: isSet(object.exitCode) ? globalThis.Number(object.exitCode) : undefined,
      hasLogs: isSet(object.hasLogs) ? globalThis.Boolean(object.hasLogs) : false,
      ciJobUrl: isSet(object.ciJobUrl) ? globalThis.String(object.ciJobUrl) : undefined,
      commit: isSet(object.commit) ? globalThis.String(object.commit) : undefined,
      isFullScan: isSet(object.isFullScan) ? globalThis.Boolean(object.isFullScan) : undefined,
      status: isSet(object.status) ? scanStatusFromJSON(object.status) : ScanStatus.UNSPECIFIED,
      findingsCounts: isSet(object.findingsCounts) ? ScanFindingsCounts.fromJSON(object.findingsCounts) : undefined,
      totalTime: isSet(object.totalTime) ? globalThis.Number(object.totalTime) : undefined,
      branch: isSet(object.branch) ? globalThis.String(object.branch) : undefined,
      pullRequestId: isSet(object.pullRequestId) ? globalThis.Number(object.pullRequestId) : undefined,
      environment: isSet(object.environment)
        ? scanEnvironmentFromJSON(object.environment)
        : ScanEnvironment.UNSPECIFIED,
      scmType: isSet(object.scmType) ? scmTypeFromJSON(object.scmType) : ScmType.UNSPECIFIED,
      parseRate: isObject(object.parseRate)
        ? Object.entries(object.parseRate).reduce<{ [key: string]: LanguageParseRate }>((acc, [key, value]) => {
          acc[key] = LanguageParseRate.fromJSON(value);
          return acc;
        }, {})
        : {},
      repositoryRefId: isSet(object.repositoryRefId) ? globalThis.Number(object.repositoryRefId) : 0,
      repositoryUrl: isSet(object.repositoryUrl) ? globalThis.String(object.repositoryUrl) : undefined,
      refUrl: isSet(object.refUrl) ? globalThis.String(object.refUrl) : undefined,
      cliVersion: isSet(object.cliVersion) ? globalThis.String(object.cliVersion) : undefined,
    };
  },

  toJSON(message: Scan): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.repositoryId !== 0) {
      obj.repositoryId = Math.round(message.repositoryId);
    }
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.startedAt !== undefined) {
      obj.startedAt = message.startedAt.toISOString();
    }
    if (message.completedAt !== undefined) {
      obj.completedAt = message.completedAt.toISOString();
    }
    if (message.exitCode !== undefined) {
      obj.exitCode = Math.round(message.exitCode);
    }
    if (message.hasLogs !== false) {
      obj.hasLogs = message.hasLogs;
    }
    if (message.ciJobUrl !== undefined) {
      obj.ciJobUrl = message.ciJobUrl;
    }
    if (message.commit !== undefined) {
      obj.commit = message.commit;
    }
    if (message.isFullScan !== undefined) {
      obj.isFullScan = message.isFullScan;
    }
    if (message.status !== ScanStatus.UNSPECIFIED) {
      obj.status = scanStatusToJSON(message.status);
    }
    if (message.findingsCounts !== undefined) {
      obj.findingsCounts = ScanFindingsCounts.toJSON(message.findingsCounts);
    }
    if (message.totalTime !== undefined) {
      obj.totalTime = message.totalTime;
    }
    if (message.branch !== undefined) {
      obj.branch = message.branch;
    }
    if (message.pullRequestId !== undefined) {
      obj.pullRequestId = Math.round(message.pullRequestId);
    }
    if (message.environment !== ScanEnvironment.UNSPECIFIED) {
      obj.environment = scanEnvironmentToJSON(message.environment);
    }
    if (message.scmType !== ScmType.UNSPECIFIED) {
      obj.scmType = scmTypeToJSON(message.scmType);
    }
    if (message.parseRate) {
      const entries = Object.entries(message.parseRate);
      if (entries.length > 0) {
        obj.parseRate = {};
        entries.forEach(([k, v]) => {
          obj.parseRate[k] = LanguageParseRate.toJSON(v);
        });
      }
    }
    if (message.repositoryRefId !== 0) {
      obj.repositoryRefId = Math.round(message.repositoryRefId);
    }
    if (message.repositoryUrl !== undefined) {
      obj.repositoryUrl = message.repositoryUrl;
    }
    if (message.refUrl !== undefined) {
      obj.refUrl = message.refUrl;
    }
    if (message.cliVersion !== undefined) {
      obj.cliVersion = message.cliVersion;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Scan>, I>>(base?: I): Scan {
    return Scan.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Scan>, I>>(object: I): Scan {
    const message = createBaseScan();
    message.id = object.id ?? 0;
    message.repositoryId = object.repositoryId ?? 0;
    message.deploymentId = object.deploymentId ?? 0;
    message.startedAt = object.startedAt ?? undefined;
    message.completedAt = object.completedAt ?? undefined;
    message.exitCode = object.exitCode ?? undefined;
    message.hasLogs = object.hasLogs ?? false;
    message.ciJobUrl = object.ciJobUrl ?? undefined;
    message.commit = object.commit ?? undefined;
    message.isFullScan = object.isFullScan ?? undefined;
    message.status = object.status ?? ScanStatus.UNSPECIFIED;
    message.findingsCounts = (object.findingsCounts !== undefined && object.findingsCounts !== null)
      ? ScanFindingsCounts.fromPartial(object.findingsCounts)
      : undefined;
    message.totalTime = object.totalTime ?? undefined;
    message.branch = object.branch ?? undefined;
    message.pullRequestId = object.pullRequestId ?? undefined;
    message.environment = object.environment ?? ScanEnvironment.UNSPECIFIED;
    message.scmType = object.scmType ?? ScmType.UNSPECIFIED;
    message.parseRate = Object.entries(object.parseRate ?? {}).reduce<{ [key: string]: LanguageParseRate }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = LanguageParseRate.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.repositoryRefId = object.repositoryRefId ?? 0;
    message.repositoryUrl = object.repositoryUrl ?? undefined;
    message.refUrl = object.refUrl ?? undefined;
    message.cliVersion = object.cliVersion ?? undefined;
    return message;
  },
};

function createBaseScan_ParseRateEntry(): Scan_ParseRateEntry {
  return { key: "", value: undefined };
}

export const Scan_ParseRateEntry = {
  encode(message: Scan_ParseRateEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      LanguageParseRate.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Scan_ParseRateEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScan_ParseRateEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = LanguageParseRate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Scan_ParseRateEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? LanguageParseRate.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Scan_ParseRateEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = LanguageParseRate.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Scan_ParseRateEntry>, I>>(base?: I): Scan_ParseRateEntry {
    return Scan_ParseRateEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Scan_ParseRateEntry>, I>>(object: I): Scan_ParseRateEntry {
    const message = createBaseScan_ParseRateEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? LanguageParseRate.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseSearchScansResponse(): SearchScansResponse {
  return { scans: [], cursor: undefined, cursorEnc: undefined };
}

export const SearchScansResponse = {
  encode(message: SearchScansResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.scans) {
      Scan.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.cursor !== undefined) {
      writer.uint32(16).uint64(message.cursor);
    }
    if (message.cursorEnc !== undefined) {
      writer.uint32(26).string(message.cursorEnc);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchScansResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchScansResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scans.push(Scan.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.cursor = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cursorEnc = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchScansResponse {
    return {
      scans: globalThis.Array.isArray(object?.scans) ? object.scans.map((e: any) => Scan.fromJSON(e)) : [],
      cursor: isSet(object.cursor) ? globalThis.Number(object.cursor) : undefined,
      cursorEnc: isSet(object.cursorEnc) ? globalThis.String(object.cursorEnc) : undefined,
    };
  },

  toJSON(message: SearchScansResponse): unknown {
    const obj: any = {};
    if (message.scans?.length) {
      obj.scans = message.scans.map((e) => Scan.toJSON(e));
    }
    if (message.cursor !== undefined) {
      obj.cursor = Math.round(message.cursor);
    }
    if (message.cursorEnc !== undefined) {
      obj.cursorEnc = message.cursorEnc;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchScansResponse>, I>>(base?: I): SearchScansResponse {
    return SearchScansResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchScansResponse>, I>>(object: I): SearchScansResponse {
    const message = createBaseSearchScansResponse();
    message.scans = object.scans?.map((e) => Scan.fromPartial(e)) || [];
    message.cursor = object.cursor ?? undefined;
    message.cursorEnc = object.cursorEnc ?? undefined;
    return message;
  },
};

function createBaseScanPublic(): ScanPublic {
  return {
    id: 0,
    deploymentId: 0,
    repositoryId: 0,
    branch: undefined,
    commit: undefined,
    startedAt: undefined,
    completedAt: undefined,
    exitCode: undefined,
    isFullScan: undefined,
    totalTime: undefined,
    findingsCounts: undefined,
    status: undefined,
  };
}

export const ScanPublic = {
  encode(message: ScanPublic, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.deploymentId !== 0) {
      writer.uint32(16).uint64(message.deploymentId);
    }
    if (message.repositoryId !== 0) {
      writer.uint32(24).uint64(message.repositoryId);
    }
    if (message.branch !== undefined) {
      writer.uint32(34).string(message.branch);
    }
    if (message.commit !== undefined) {
      writer.uint32(42).string(message.commit);
    }
    if (message.startedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.startedAt), writer.uint32(50).fork()).ldelim();
    }
    if (message.completedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.completedAt), writer.uint32(58).fork()).ldelim();
    }
    if (message.exitCode !== undefined) {
      writer.uint32(64).int64(message.exitCode);
    }
    if (message.isFullScan !== undefined) {
      writer.uint32(72).bool(message.isFullScan);
    }
    if (message.totalTime !== undefined) {
      writer.uint32(85).float(message.totalTime);
    }
    if (message.findingsCounts !== undefined) {
      ScanFindingsCounts.encode(message.findingsCounts, writer.uint32(90).fork()).ldelim();
    }
    if (message.status !== undefined) {
      writer.uint32(96).int32(scanStatusToNumber(message.status));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanPublic {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanPublic();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.repositoryId = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.branch = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.commit = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.startedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.completedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.exitCode = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isFullScan = reader.bool();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.totalTime = reader.float();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.findingsCounts = ScanFindingsCounts.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.status = scanStatusFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanPublic {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      repositoryId: isSet(object.repositoryId) ? globalThis.Number(object.repositoryId) : 0,
      branch: isSet(object.branch) ? globalThis.String(object.branch) : undefined,
      commit: isSet(object.commit) ? globalThis.String(object.commit) : undefined,
      startedAt: isSet(object.startedAt) ? fromJsonTimestamp(object.startedAt) : undefined,
      completedAt: isSet(object.completedAt) ? fromJsonTimestamp(object.completedAt) : undefined,
      exitCode: isSet(object.exitCode) ? globalThis.Number(object.exitCode) : undefined,
      isFullScan: isSet(object.isFullScan) ? globalThis.Boolean(object.isFullScan) : undefined,
      totalTime: isSet(object.totalTime) ? globalThis.Number(object.totalTime) : undefined,
      findingsCounts: isSet(object.findingsCounts) ? ScanFindingsCounts.fromJSON(object.findingsCounts) : undefined,
      status: isSet(object.status) ? scanStatusFromJSON(object.status) : undefined,
    };
  },

  toJSON(message: ScanPublic): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.repositoryId !== 0) {
      obj.repositoryId = Math.round(message.repositoryId);
    }
    if (message.branch !== undefined) {
      obj.branch = message.branch;
    }
    if (message.commit !== undefined) {
      obj.commit = message.commit;
    }
    if (message.startedAt !== undefined) {
      obj.startedAt = message.startedAt.toISOString();
    }
    if (message.completedAt !== undefined) {
      obj.completedAt = message.completedAt.toISOString();
    }
    if (message.exitCode !== undefined) {
      obj.exitCode = Math.round(message.exitCode);
    }
    if (message.isFullScan !== undefined) {
      obj.isFullScan = message.isFullScan;
    }
    if (message.totalTime !== undefined) {
      obj.totalTime = message.totalTime;
    }
    if (message.findingsCounts !== undefined) {
      obj.findingsCounts = ScanFindingsCounts.toJSON(message.findingsCounts);
    }
    if (message.status !== undefined) {
      obj.status = scanStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanPublic>, I>>(base?: I): ScanPublic {
    return ScanPublic.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScanPublic>, I>>(object: I): ScanPublic {
    const message = createBaseScanPublic();
    message.id = object.id ?? 0;
    message.deploymentId = object.deploymentId ?? 0;
    message.repositoryId = object.repositoryId ?? 0;
    message.branch = object.branch ?? undefined;
    message.commit = object.commit ?? undefined;
    message.startedAt = object.startedAt ?? undefined;
    message.completedAt = object.completedAt ?? undefined;
    message.exitCode = object.exitCode ?? undefined;
    message.isFullScan = object.isFullScan ?? undefined;
    message.totalTime = object.totalTime ?? undefined;
    message.findingsCounts = (object.findingsCounts !== undefined && object.findingsCounts !== null)
      ? ScanFindingsCounts.fromPartial(object.findingsCounts)
      : undefined;
    message.status = object.status ?? undefined;
    return message;
  },
};

function createBaseListScansPublicRequest(): ListScansPublicRequest {
  return {
    deploymentId: 0,
    cursor: undefined,
    limit: undefined,
    since: undefined,
    repositoryId: undefined,
    isFullScan: undefined,
    statuses: [],
    totalTime: undefined,
    branch: undefined,
  };
}

export const ListScansPublicRequest = {
  encode(message: ListScansPublicRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.cursor !== undefined) {
      writer.uint32(18).string(message.cursor);
    }
    if (message.limit !== undefined) {
      writer.uint32(24).uint64(message.limit);
    }
    if (message.since !== undefined) {
      Timestamp.encode(toTimestamp(message.since), writer.uint32(34).fork()).ldelim();
    }
    if (message.repositoryId !== undefined) {
      writer.uint32(40).uint64(message.repositoryId);
    }
    if (message.isFullScan !== undefined) {
      writer.uint32(48).bool(message.isFullScan);
    }
    writer.uint32(58).fork();
    for (const v of message.statuses) {
      writer.int32(scanStatusToNumber(v));
    }
    writer.ldelim();
    if (message.totalTime !== undefined) {
      FloatRange.encode(message.totalTime, writer.uint32(66).fork()).ldelim();
    }
    if (message.branch !== undefined) {
      writer.uint32(74).string(message.branch);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListScansPublicRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListScansPublicRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cursor = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.limit = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.since = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.repositoryId = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isFullScan = reader.bool();
          continue;
        case 7:
          if (tag === 56) {
            message.statuses.push(scanStatusFromJSON(reader.int32()));

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.statuses.push(scanStatusFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.totalTime = FloatRange.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.branch = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListScansPublicRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : undefined,
      since: isSet(object.since) ? fromJsonTimestamp(object.since) : undefined,
      repositoryId: isSet(object.repositoryId) ? globalThis.Number(object.repositoryId) : undefined,
      isFullScan: isSet(object.isFullScan) ? globalThis.Boolean(object.isFullScan) : undefined,
      statuses: globalThis.Array.isArray(object?.statuses)
        ? object.statuses.map((e: any) => scanStatusFromJSON(e))
        : [],
      totalTime: isSet(object.totalTime) ? FloatRange.fromJSON(object.totalTime) : undefined,
      branch: isSet(object.branch) ? globalThis.String(object.branch) : undefined,
    };
  },

  toJSON(message: ListScansPublicRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    if (message.limit !== undefined) {
      obj.limit = Math.round(message.limit);
    }
    if (message.since !== undefined) {
      obj.since = message.since.toISOString();
    }
    if (message.repositoryId !== undefined) {
      obj.repositoryId = Math.round(message.repositoryId);
    }
    if (message.isFullScan !== undefined) {
      obj.isFullScan = message.isFullScan;
    }
    if (message.statuses?.length) {
      obj.statuses = message.statuses.map((e) => scanStatusToJSON(e));
    }
    if (message.totalTime !== undefined) {
      obj.totalTime = FloatRange.toJSON(message.totalTime);
    }
    if (message.branch !== undefined) {
      obj.branch = message.branch;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListScansPublicRequest>, I>>(base?: I): ListScansPublicRequest {
    return ListScansPublicRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListScansPublicRequest>, I>>(object: I): ListScansPublicRequest {
    const message = createBaseListScansPublicRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.cursor = object.cursor ?? undefined;
    message.limit = object.limit ?? undefined;
    message.since = object.since ?? undefined;
    message.repositoryId = object.repositoryId ?? undefined;
    message.isFullScan = object.isFullScan ?? undefined;
    message.statuses = object.statuses?.map((e) => e) || [];
    message.totalTime = (object.totalTime !== undefined && object.totalTime !== null)
      ? FloatRange.fromPartial(object.totalTime)
      : undefined;
    message.branch = object.branch ?? undefined;
    return message;
  },
};

function createBaseListScansPublicResponse(): ListScansPublicResponse {
  return { scans: [], cursor: undefined };
}

export const ListScansPublicResponse = {
  encode(message: ListScansPublicResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.scans) {
      ScanPublic.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.cursor !== undefined) {
      writer.uint32(18).string(message.cursor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListScansPublicResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListScansPublicResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scans.push(ScanPublic.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cursor = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListScansPublicResponse {
    return {
      scans: globalThis.Array.isArray(object?.scans) ? object.scans.map((e: any) => ScanPublic.fromJSON(e)) : [],
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
    };
  },

  toJSON(message: ListScansPublicResponse): unknown {
    const obj: any = {};
    if (message.scans?.length) {
      obj.scans = message.scans.map((e) => ScanPublic.toJSON(e));
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListScansPublicResponse>, I>>(base?: I): ListScansPublicResponse {
    return ListScansPublicResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListScansPublicResponse>, I>>(object: I): ListScansPublicResponse {
    const message = createBaseListScansPublicResponse();
    message.scans = object.scans?.map((e) => ScanPublic.fromPartial(e)) || [];
    message.cursor = object.cursor ?? undefined;
    return message;
  },
};

export interface ScanService {
  createLogLink(request: CreateLogLinkRequest): Promise<CreateLogLinkResponse>;
  getLog(request: GetLogRequest): Promise<GetLogResponse>;
  getScan(request: GetScanRequest): Promise<GetScanResponse>;
  searchScans(request: SearchScansRequest): Promise<SearchScansResponse>;
  listScansPublic(request: ListScansPublicRequest): Promise<ListScansPublicResponse>;
}

export const ScanServiceServiceName = "protos.scan.v1.ScanService";
export class ScanServiceClientImpl implements ScanService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || ScanServiceServiceName;
    this.rpc = rpc;
    this.createLogLink = this.createLogLink.bind(this);
    this.getLog = this.getLog.bind(this);
    this.getScan = this.getScan.bind(this);
    this.searchScans = this.searchScans.bind(this);
    this.listScansPublic = this.listScansPublic.bind(this);
  }
  createLogLink(request: CreateLogLinkRequest): Promise<CreateLogLinkResponse> {
    const data = CreateLogLinkRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateLogLink", data);
    return promise.then((data) => CreateLogLinkResponse.decode(_m0.Reader.create(data)));
  }

  getLog(request: GetLogRequest): Promise<GetLogResponse> {
    const data = GetLogRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetLog", data);
    return promise.then((data) => GetLogResponse.decode(_m0.Reader.create(data)));
  }

  getScan(request: GetScanRequest): Promise<GetScanResponse> {
    const data = GetScanRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetScan", data);
    return promise.then((data) => GetScanResponse.decode(_m0.Reader.create(data)));
  }

  searchScans(request: SearchScansRequest): Promise<SearchScansResponse> {
    const data = SearchScansRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "SearchScans", data);
    return promise.then((data) => SearchScansResponse.decode(_m0.Reader.create(data)));
  }

  listScansPublic(request: ListScansPublicRequest): Promise<ListScansPublicResponse> {
    const data = ListScansPublicRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListScansPublic", data);
    return promise.then((data) => ListScansPublicResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
