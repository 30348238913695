import { FC, useState } from "react";
import pluralize from "pluralize";
import {
  Accordion,
  Button,
  Checkbox,
  Group,
  Radio,
  ScrollArea,
  Stack,
  Title,
  Tooltip,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";

import { updateRuleBoard } from "@shared/api";
import { GLOBAL_POLICY_SLUG } from "@shared/constants";
import { useOrg, useRuleBoards, useUser } from "@shared/hooks";
import { ProductRuleItem, RuleBoardUpdate } from "@shared/types";

import { LOADING_STATE, LOGGED_OUT_STATE, NO_ORG_STATE } from "../MenuStates";

import styles from "./PolicyModesMenu.module.css";

export interface PolicyModesMenuProps {
  ruleItemsToAdd: ProductRuleItem[];
  handleClose: () => void;
}

/**
 * @ruleItemsToAdd the rule items to add to the policy
 * @handleClose function to close the menu
 * @returns An accordion dropdown of policies.
 */
export const PolicyModesMenu: FC<PolicyModesMenuProps> = ({
  ruleItemsToAdd,
  handleClose,
}) => {
  const [user] = useUser();
  const [org] = useOrg();
  const { data } = useRuleBoards();
  const ruleBoards = data?.ruleboards;

  const [selectedRuleBoards, setSelectedRuleBoards] = useState<string[]>([
    GLOBAL_POLICY_SLUG,
  ]);
  const [selectedPolicies, setSelectedPolicies] = useState<{
    [slug: string]: string;
  }>({});

  const [isLoading, setIsLoading] = useState(false);

  const handlePolicyChange = (ruleBoardSlug: string, policy: string) => {
    setSelectedPolicies((prevPolicies) => ({
      ...prevPolicies,
      [ruleBoardSlug]: policy,
    }));
  };

  const handleRadioChange = (ruleBoardSlug: string, policy: string) => {
    if (ruleBoardSlug in selectedPolicies) {
      if (selectedPolicies[ruleBoardSlug] === policy) {
        return;
      }
    }
    handlePolicyChange(ruleBoardSlug, policy);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    // TODO: modify backend endpoint to allow updates to multiple rule boards
    const promises = Object.keys(selectedPolicies).map(
      async (ruleBoardSlug) => {
        const policy = selectedPolicies[ruleBoardSlug];

        // Create the change to send to the API
        const changes = ruleItemsToAdd.map(({ ruleItem }) => ({
          ...ruleItem,
          policy,
          update_type: "add",
        })) as RuleBoardUpdate[];

        try {
          await updateRuleBoard(ruleBoardSlug, changes, org!.id);
        } catch (error) {
          console.error(`Error updating policy)`);
          console.error(error);
        }
      }
    );

    Promise.all(promises)
      .then(() => {
        showNotification({
          title: "Success!",
          message: `Successfully added to ${promises.length} ${pluralize(
            "policy",
            promises.length
          )}`,
          color: "green",
        });
      })
      .catch(() =>
        showNotification({
          title: "Error",
          message: `Error adding rules to ${pluralize(
            "policy",
            promises.length
          )}`,
          color: "red",
        })
      )
      .finally(() => {
        setSelectedRuleBoards([GLOBAL_POLICY_SLUG]);
        setSelectedPolicies({});
        handleClose();
        setIsLoading(false);
      });
  };

  // Zero states and error states
  if (user === undefined) return LOGGED_OUT_STATE;
  if (org === undefined) return NO_ORG_STATE;
  if (ruleBoards === undefined) return LOADING_STATE;

  return (
    <Stack>
      <ScrollArea style={{ maxHeight: 400, overflow: "auto" }}>
        <Accordion
          multiple
          value={selectedRuleBoards}
          onChange={setSelectedRuleBoards}
          classNames={{
            label: styles.accordionLabel,
            control: styles.accordionControl,
          }}
        >
          {ruleBoards?.map((ruleBoard) => (
            <Accordion.Item key={ruleBoard.slug} value={ruleBoard.slug}>
              <Accordion.Control>
                <Group gap="xs" wrap="nowrap">
                  <Tooltip
                    label={`Select a rule mode below to add this to ${ruleBoard.name}`}
                    disabled={ruleBoard.slug in selectedPolicies}
                    withinPortal
                  >
                    <div>
                      <Checkbox
                        size="sm"
                        value={ruleBoard.slug}
                        disabled={!(ruleBoard.slug in selectedPolicies)}
                        checked={ruleBoard.slug in selectedPolicies}
                        onChange={(checked) => {
                          if (checked) {
                            setSelectedPolicies((prevPolicies) => {
                              const updatedPolicies = { ...prevPolicies };
                              delete updatedPolicies[ruleBoard.slug];
                              return updatedPolicies;
                            });
                          }
                          return !checked;
                        }}
                      />
                    </div>
                  </Tooltip>
                  <Title order={6}>{ruleBoard.name}</Title>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <Radio.Group
                  pl={20}
                  value={
                    ruleBoard.slug in selectedPolicies
                      ? selectedPolicies[ruleBoard.slug]
                      : ""
                  }
                  onChange={(policy) =>
                    handleRadioChange(ruleBoard.slug, policy)
                  }
                >
                  <Stack gap="xs">
                    <Radio value="monitor" label="Monitor" />
                    <Radio value="comment" label="Comment" />
                    <Radio value="block" label="Block" />
                  </Stack>
                </Radio.Group>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </ScrollArea>
      <Button
        onClick={handleSubmit}
        disabled={Object.keys(selectedPolicies).length === 0}
        loading={isLoading}
      >
        Submit
      </Button>
    </Stack>
  );
};
