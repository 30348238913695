import { RecentlyVisitedRuleData } from "../types";

import { parseRecentlyVisitedDataFromLocalStorage } from "./parseRecentlyVisitedDataFromLocalStorage";

export const getRecentlyViewedRules = (
  orgName: string
): RecentlyVisitedRuleData[] => {
  const recentlyViewed = parseRecentlyVisitedDataFromLocalStorage();
  const orgRecentlyViewed = recentlyViewed.find(
    (orgDatum) => orgDatum.orgName === orgName
  );
  return orgRecentlyViewed ? orgRecentlyViewed.rules : [];
};
