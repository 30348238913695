import YAML from "yaml";

import { Code, Link } from "@shared/components";
import { useBasePath } from "@shared/hooks";

export function CLIPublishCommand({
  visibility,
  name,
}: {
  visibility: "unlisted" | "org_private" | "public";
  name: string;
}) {
  return (
    <>
      semgrep publish --visibility={visibility}
      {visibility === "public" && ` --registry-id=${name}`}
      {" <"}path_to_your_folder{">"}
    </>
  );
}

export default function GitHubImportRulesCode({
  visibility,
  name,
}: {
  visibility: "unlisted" | "org_private" | "public";
  name: string;
}) {
  const basePath = useBasePath();
  const workflowYaml = {
    name: "SemgrepPublish",
    jobs: {
      semgrep: {
        name: "Publish",
        "runs-on": "ubuntu-20.04",
        env: {
          // eslint-disable-next-line no-template-curly-in-string
          SEMGREP_APP_TOKEN: "${{ secrets.SEMGREP_APP_TOKEN }}",
        },
        container: {
          image: "returntocorp/semgrep",
        },
        steps: [{ run: "__CLI__" }],
      },
    },
  };

  const yamlString = YAML.stringify(workflowYaml)
    .replace("'\"", "'")
    .replace("\"'", "'");

  // Split it so I can insert Component
  const [start, end] = yamlString.split("__CLI__");

  return (
    <>
      <div style={{ marginBottom: "4px" }}>
        Commit this configuration to your project workflows in GitHub:
      </div>
      <div style={{ display: "flex", gap: "12px" }}>
        <div style={{ flexGrow: 1, flexBasis: "70%" }}>
          <Code
            multiline
            copyable
            dark
            title={<code>.github/workflows/semgrep.yml</code>}
          >
            {start}
            <CLIPublishCommand visibility={visibility} name={name} />
            {end}
          </Code>
        </div>
      </div>
      <div style={{ marginTop: "12px" }}>
        Add <Link to={`${basePath}/settings/tokens`}>your token</Link> as a
        secret CI/CD variable named{" "}
        <Code inline light>
          SEMGREP_APP_TOKEN
        </Code>
        .
      </div>
    </>
  );
}
