import React, { FC, useMemo } from "react";
import {
  faGithub,
  faGitlab,
  faMicrosoft,
} from "@fortawesome/free-brands-svg-icons";

import { ScmType } from "@protos/common/v1/common";
import { useBasePath, usePrefabValue, useSupportsFeature } from "@shared/hooks";

import {
  SetupProjectChooseType,
  SMSProjectTypeOption,
} from "./SetupProjectChooseType";

const OPTIONS: SMSProjectTypeOption[] = [
  {
    scmType: ScmType.GITHUB,
    title: "GitHub",
    faIcon: faGithub,
  },
  {
    scmType: ScmType.GITLAB,
    title: "GitLab",
    faIcon: faGitlab,
  },
];

const EXTRA_OPTIONS: SMSProjectTypeOption[] = [
  {
    scmType: ScmType.AZURE_DEVOPS,
    title: "Azure Devops",
    faIcon: faMicrosoft,
  },
];

interface SetupProjectChooseSMSTypeProps {
  onClose?: () => void;
}

export const SetupProjectChooseSMSType: FC<SetupProjectChooseSMSTypeProps> = ({
  onClose,
}) => {
  const basePath = useBasePath();
  const hasUpdatedSCMOnboarding = useSupportsFeature("hasUpdatedSCMOnboarding");
  const { data: supportsAdditionalSmsScms } = usePrefabValue(
    "sms.additional_scms_supported",
    "boolean"
  );
  const smsOptions = useMemo(
    () => [...OPTIONS, ...(supportsAdditionalSmsScms ? EXTRA_OPTIONS : [])],
    [supportsAdditionalSmsScms]
  );

  const getNextLink = (option: SMSProjectTypeOption) => {
    // If the feature flag is on, we should redirect to the managed setup page
    // TODO: Remove the feature flag and the code in the else block once the feature is fully rolled out
    if (hasUpdatedSCMOnboarding) {
      let path;
      if (option.scmType === ScmType.GITHUB) {
        path = `${basePath}/setup/managed/github`;
      } else if (option.scmType === ScmType.AZURE_DEVOPS) {
        path = `${basePath}/setup/managed/azure_devops`;
      } else {
        path = `${basePath}/setup/managed/gitlab`;
      }
      onClose?.();
      return path;
    } else {
      if (option.scmType === ScmType.GITHUB) {
        return `${basePath}/setup/managed`;
      } else {
        return `${basePath}/settings/source-code`;
      }
    }
  };

  return (
    <SetupProjectChooseType
      title="Scan new project with Semgrep Managed Scans"
      options={smsOptions}
      getNextLink={getNextLink}
    />
  );
};
