import React from "react";
import { Code, Text } from "@mantine/core";

import { ConfirmationModal } from "@shared/components";

interface SwitchToStructureAlertProps {
  error: SwitchToStructureAlertError | null;
  onConfirm: () => void;
  onCancel: () => void;
}

export type SwitchToStructureAlertError =
  // the rule is invalid YAML
  | { kind: "invalid"; reason: string }
  // the rule has features we do not support in Structure Mode
  | { kind: "unsupported"; reason: string };

export const SwitchToStructureAlert: React.FC<SwitchToStructureAlertProps> = ({
  error,
  onConfirm,
  onCancel,
}) => {
  if (error === null) return;

  let reason;
  switch (error.kind) {
    case "invalid": {
      reason = (
        <>
          <Text>
            This rule is invalid because it fails to parse as proper YAML, due
            to reason:
          </Text>
          <Code>{error.reason}</Code>
          <Text>
            You can try to fix it by addressing the in-editor diagnostics in the
            rule.
          </Text>
        </>
      );
      break;
    }
    case "unsupported": {
      reason = (
        <>
          <Text>
            This rule is invalid because it has features we do not support in
            Structure Mode:
          </Text>
          <Code>{error.reason}</Code>
        </>
      );
      break;
    }
  }
  return (
    <ConfirmationModal
      onConfirm={onConfirm}
      onCancel={onCancel}
      opened={true}
      confirmButtonText="Switch to Structure Mode"
      confirmButtonColor="yellow"
    >
      <Text display="inline-block" ml={16}>
        <p>Switch to Structure Mode and start over?</p>
        {reason}
        <p>
          If you switch to Structure Mode, you'll lose the rule and will have to
          start over.
        </p>
      </Text>
    </ConfirmationModal>
  );
};
