import React, { useEffect, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { fetchRule, fetchRulesetWithDefinitions } from "@shared/api";
import { NotFound } from "@shared/components";

/* Query params provided through url */
type RedirectParams = {
  snippetFqname: string;
};

const Main = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  padding: 20px;
  font-size: var(--mantine-font-size-xl);
`;

/*
This component exists for the sole purposes of backwards-compatability.
Previously, the editor linked to snippets using urls like
https://semgrep.dev/xKyz rather than https://semgrep.dev/s/xKyz

Some links may still exist (in emails, Tweets, etc.) to this form
of outdated link. This SmartRedirect will redirect such urls to
the editor (e.g. https://semgrep.dev/xKyz -> https://semgrep.dev/s/xKyz)
but will not redirect other typos or broken links to the editor
(e.g. https://semgrep.dev/managge -> https://semgrep.dev/s/managge)
*/
export const SmartRedirect: React.FC<RouteComponentProps<RedirectParams>> = ({
  /* match comes from RouteComponentProps wrapper */
  match,
}) => {
  const [exists, setExists] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    snippetExists(match.params.snippetFqname);
  }, [match.params.snippetFqname]);

  const snippetExists = async (id: string) => {
    /* sets 'exists' to true if snippetFqname is either a ruleset or rule */
    const promise = id.includes(":")
      ? // e.g. username:ruleid
        fetchRulesetWithDefinitions(id).then(() => setExists(true))
      : // e.g. kMps
        fetchRule(id).then(() => setExists(true));
    // e.g. maanage
    promise
      .catch(() => setExists(false))
      // Whether or not the snippet exists, set loading to false
      .finally(() => setIsLoading(false));
  };

  if (exists) return <Redirect to={`/s/${match.params.snippetFqname}`} />;
  if (isLoading) return <Main> Loading... </Main>;
  return (
    <Main>
      <NotFound />
    </Main>
  );
};
