export function RedArrow() {
  return (
    <svg
      width="92"
      height="71"
      viewBox="0 0 92 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3861 -0.00055693L0.588211 20.912L23.598 18.9409L10.3861 -0.00055693ZM91.029 67.0002C75.8052 66.7796 58.461 64.7894 43.9561 57.6561C29.5581 50.5755 17.9008 38.4097 13.8858 17.5491L9.95786 18.3051C14.2339 40.5217 26.7869 53.6702 42.1909 61.2456C57.488 68.7684 75.5373 70.7761 90.971 70.9998L91.029 67.0002Z"
        fill="#E05D5B"
      />
    </svg>
  );
}

export function GreenArrow() {
  return (
    <svg
      width="36"
      height="52"
      viewBox="0 0 36 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.495 6.65133e-06L35.9499 18.0108L13.1247 21.5237L21.495 6.65133e-06ZM0.97088 48.0002C5.81421 47.93 9.51595 46.8403 12.3562 45.1313C15.1942 43.4236 17.2822 41.0314 18.7991 38.185C21.8688 32.4248 22.5563 24.88 22.2349 17.8802L26.2306 17.6967C26.5635 24.9447 25.9045 33.3569 22.3292 40.0662C20.5236 43.4544 17.9644 46.425 14.4185 48.5587C10.8746 50.691 6.45549 51.9212 1.02884 51.9998L0.97088 48.0002Z"
        fill="#17b97f"
      />
    </svg>
  );
}
