import jwt_decode from "jwt-decode";

import { Deployment, FeatureFlag } from "@protos/deployment/v1/deployment";
import {
  DeploymentFeature,
  JwtV1,
  OrgData,
  RbacRole,
  SourceType,
  UserData,
} from "@shared/types";

import { convertOrgRole } from "../convertOrgRole";

const featureFlagProtoToDeploymentFeature = (
  feature: FeatureFlag
): DeploymentFeature => ({
  name: feature.name,
  expires_at: feature.expiresAt?.toUTCString() || "",
});

export const deploymentProtoToOrg = (deployment: Deployment): OrgData => ({
  id: deployment.id,
  name: deployment.name,
  display_name: deployment.displayName,
  slug: deployment.slug,
  has_autofix: deployment.features?.hasAutofix || false,
  has_deepsemgrep: deployment.features?.hasDeepsemgrep || false,
  has_triage_via_comment: deployment.features?.hasTriageViaComment || false,
  has_dependency_query: deployment.features?.hasDependencyQuery || false,
  editor_semgrep_version: deployment.features?.editorSemgrepVersion,
  features: deployment.features?.featureFlags.map(
    featureFlagProtoToDeploymentFeature
  ),
  source_type: deployment.sourceType as SourceType,
  organization_id: deployment.organizationId,
  default_user_role:
    convertOrgRole(deployment.features?.defaultUserRole!) || RbacRole.Member,
});

export const decodeJwt = (token: string): JwtV1 => {
  return jwt_decode<JwtV1>(token);
};

export const decodeJwtIdentity = (token: string): UserData => {
  return decodeJwt(token).identity;
};

export const extractUserCreatedDate = (
  userData: UserData
): string | undefined => {
  try {
    const rawDateString = userData?.user_created_at;
    if (!rawDateString) {
      return;
    }
    return new Date(rawDateString).toISOString();
  } catch (e) {
    return;
  }
};

export const daysSinceUserCreated = (
  userData: UserData
): number | undefined => {
  try {
    const createdDate = extractUserCreatedDate(userData);
    if (!createdDate) {
      return;
    }
    const created = new Date(createdDate);
    const now = new Date();
    return Math.max(
      0,
      Math.floor((now.getTime() - created.getTime()) / (1000 * 60 * 60 * 24))
    );
  } catch (e) {
    return;
  }
};

export const extractUserCreatedQuarter = (
  userData: UserData
): string | undefined => {
  try {
    const createdDate = extractUserCreatedDate(userData);
    if (!createdDate) {
      return;
    }
    const created = new Date(createdDate);
    // NOTE: getMonth() is 0-indexed (0 = January, 11 = December)
    const month = created.getMonth();
    // Q1 at Semgrep starts in February and thus Jan needs to be mapped to Q4 of previous year
    const adjustedYear =
      month === 0 ? created.getFullYear() - 1 : created.getFullYear();
    const adjustedMonth = (month - 1 + 12) % 12;
    const quarter = Math.floor(adjustedMonth / 3) + 1;
    return `${adjustedYear}-Q${quarter}`;
  } catch (e) {
    return;
  }
};
