import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { faMinus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon } from "@mantine/core";

import { PatternEditor } from "@shared/editorCore";

import { WorkbenchContext } from "../../providers";

import { PatternSelect } from "./PatternSelect";

const FixRowComponent: React.FC = () => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;

  if (bundle === null || bundle.simpleRule === undefined) {
    // Should never get here because you can't switch to Simple Mode
    return null;
  }

  const onChangeFix = (newPattern: string) => {
    bundle.onSimpleRuleChange({ ...bundle.simpleRule!, fix: newPattern });
  };

  const onRemoveFix = () => {
    bundle.onSimpleRuleChange({ ...bundle.simpleRule!, fix: "" });
  };

  return (
    <>
      <div style={{ gridColumnStart: 1 }}>
        <PatternSelect
          value={"pattern-fix"}
          // no onChange because you can't change from autofix
          onChange={(_) => {}}
          disabled={true}
        />
      </div>
      <PatternEditor
        pattern={bundle.simpleRule.fix}
        onPatternChange={onChangeFix}
        language={bundle.language!}
        keyComboRunAction={bundle.run}
      />
      <ActionIcon
        variant="default"
        size="sm"
        onClick={onRemoveFix}
        style={{ gridColumnStart: 4 }}
      >
        <FontAwesomeIcon icon={faMinus} size="sm" />
      </ActionIcon>
    </>
  );
};

export const FixRow = observer(FixRowComponent);
