import {
  faBitbucket,
  faGithub,
  faGitlab,
  faMicrosoft,
} from "@fortawesome/free-brands-svg-icons";
import {
  faExclamationTriangle,
  faMinus,
  faQuestion,
  faUnlink,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColorSwatch } from "@mantine/core";

import { Confidence, ScmType, Severity } from "@protos/common/v1/common";
import { ValidationState } from "@protos/secrets/v1/secrets";

import { ConfidenceIcon } from "../ConfidenceIcon";
/**
 * ProtoIcons is a mapping of proto enum values to an Icon component.
 * In enums.ts, we use this to dynamically render a Mantine Data object for each enum
 * value and use this object as a reference for the Icon.
 */
export const ProtoIcons: Record<string, React.ReactNode> = {
  // severity
  [Severity.HIGH]: <ColorSwatch color="#F03E3E" size={8} />,
  [Severity.MEDIUM]: <ColorSwatch color="#FFA94D" size={8} />,
  [Severity.LOW]: <ColorSwatch color="#C5F6FA" size={8} />,
  // confidence
  [Confidence.HIGH]: <ConfidenceIcon confidence="High" variant="dark" />,
  [Confidence.MEDIUM]: <ConfidenceIcon confidence="Medium" variant="dark" />,
  [Confidence.LOW]: <ConfidenceIcon confidence="Low" variant="dark" />,
  // SCM Type
  [ScmType.GITHUB]: <FontAwesomeIcon icon={faGithub} />,
  [ScmType.GITHUB_ENTERPRISE]: <FontAwesomeIcon icon={faGithub} />,
  [ScmType.GITLAB]: <FontAwesomeIcon icon={faGitlab} />,
  [ScmType.GITLAB_SELFMANAGED]: <FontAwesomeIcon icon={faGitlab} />,
  [ScmType.AZURE_DEVOPS]: <FontAwesomeIcon icon={faMicrosoft} />,
  [ScmType.BITBUCKET]: <FontAwesomeIcon icon={faBitbucket} />,
  // Secrets Validation
  [ValidationState.CONFIRMED_VALID]: (
    <FontAwesomeIcon icon={faExclamationTriangle} />
  ),
  [ValidationState.CONFIRMED_INVALID]: <FontAwesomeIcon icon={faUnlink} />,
  [ValidationState.NO_VALIDATOR]: <FontAwesomeIcon icon={faMinus} />,
  [ValidationState.VALIDATION_ERROR]: <FontAwesomeIcon icon={faQuestion} />,
};
