import { FC } from "react";
import { ContextMenuProvider } from "mantine-contextmenu";
import { MantineProvider as MantineCoreProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";

import { theme } from "./theme";

interface MantineProviderProps {
  children: React.ReactNode;
}

export const MantineProvider: FC<MantineProviderProps> = ({ children }) => {
  return (
    <MantineCoreProvider withCssVariables theme={theme}>
      <ContextMenuProvider>
        <ModalsProvider>
          <Notifications />
          {children}
        </ModalsProvider>
      </ContextMenuProvider>
    </MantineCoreProvider>
  );
};
