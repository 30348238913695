import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { RegistryRuleMetadata } from "@shared/types";

import { Workbench } from "../stores";

import { RuleMetadataEditor } from "./RuleMetadataEditor";

const Container = styled.div`
  padding: var(--mantine-spacing-lg);
  overflow-y: auto;
  font-size: 0.9em;
`;

const TipContainer = styled.div`
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 20px;
`;

interface Props {
  workbench: Workbench;
}

const MetadataPanelComponent = ({ workbench }: Props) => {
  const { bundle } = workbench;
  if (bundle === null) return null;
  const { rule } = bundle;
  if (rule === null) return null;

  return (
    <Container>
      <RuleMetadataEditor
        metadata={(rule?.metadata || {}) as RegistryRuleMetadata}
        setMetadata={(m) => bundle.setRuleMetadata(m)}
        message={rule?.message ?? ""}
        setMessage={(m) => bundle.setRuleMessage(m)}
        ruleId={rule?.id ?? ""}
        setRuleId={(m) => bundle.setRuleId(m)}
        languages={rule?.languages ?? []}
        setLanguages={(m) => bundle.setRuleLanguages(m)}
        showOptional={true}
      />
      <TipContainer>
        <h2 style={{ marginTop: "var(--mantine-spacing-lg)" }}>
          Message Writing Tips
        </h2>
        <p>
          You can reference metavariables (like $FOO) in your message and they
          will be replaced with the matched content.
        </p>
        <p>Use Markdown syntax to format your rule message:</p>
        <table>
          <thead>
            <tr>
              <th>Markdown</th>
              <th>Output</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>*Italic text*</code>
              </td>
              <td>
                <i>Italic text</i>
              </td>
            </tr>
            <tr>
              <td>
                <code>**Bold text**</code>
              </td>
              <td>
                <b>Bold text</b>
              </td>
            </tr>
            <tr>
              <td>
                <code>`Inline code`</code>
              </td>
              <td>
                <code>Inline code</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>[Link](https://sg.run)</code>
              </td>
              <td>
                <a href="https://sg.run">Link text</a>
              </td>
            </tr>
          </tbody>
        </table>
      </TipContainer>
    </Container>
  );
};

export const MetadataPanel = observer(MetadataPanelComponent);
