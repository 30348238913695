import { Product, ProductRuleItem, Rule, Ruleset } from "@shared/types";
import { isSecretsRuleset } from "@shared/utils";

import {
  Address,
  CombinedRegistryAddress,
  RegistryAddress,
} from "../../state/Address";

interface Args {
  rulesFromEditor?: { address?: Address[]; product: Product };
  rulesets?: Ruleset[];
  registryRules?: Rule[];
}

/**
 * Removes duplicates from an array of ProductRuleItems
 */
const uniq = (array: ProductRuleItem[]) => {
  const seen = new Map();

  return array.filter((item) => {
    const key = `${item.ruleItem.item.type}-${item.ruleItem.item.id}`;
    if (seen.has(key)) {
      return false;
    }

    seen.set(key, true);
    return true;
  });
};

/**
 * Takes a variety of rule formats and outputs ProductRuleItems, which is the
 * format of rules items used to update the rule board's rules
 * @returns a list of ProductRuleItem[] (duplicates removed)
 */
export const convertToProductRuleItem = ({
  rulesFromEditor,
  rulesets,
  registryRules,
}: Args) => {
  let ruleItems: ProductRuleItem[] = [];

  if (rulesFromEditor && rulesFromEditor.address) {
    ruleItems = rulesFromEditor.address.map(
      (address) =>
        ({
          ruleItem: {
            item: {
              type:
                address instanceof RegistryAddress ||
                address instanceof CombinedRegistryAddress
                  ? "rule"
                  : "snippet",
              id: address.fullName,
            },
          },
          product: rulesFromEditor.product,
        } as ProductRuleItem)
    );
  }

  if (rulesets) {
    const toAdd: ProductRuleItem[] = rulesets.map((ruleset) => ({
      ruleItem: {
        item: {
          type: "pack",
          id: ruleset.name.toLowerCase(),
        },
      },
      product: isSecretsRuleset(ruleset) ? "secrets" : "sast",
    }));

    ruleItems = ruleItems.concat(toAdd);
  }

  if (registryRules) {
    const toAdd: ProductRuleItem[] = registryRules.map((rule) => ({
      ruleItem: {
        item: {
          type: "rule",
          id: (rule.path ?? rule.id).toLowerCase(),
        },
      },
      product: rule.rule_type ?? "sast",
    }));

    ruleItems = ruleItems.concat(toAdd);
  }

  return uniq(ruleItems);
};
