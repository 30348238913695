import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Skeleton, Stack, Text } from "@mantine/core";

import { Deployment } from "@protos/deployment/v1/deployment";
import {
  useJoinableOrgs,
  useJoinDeploymentMutation,
  useUser,
} from "@shared/hooks";
import { getScmIcon, scmEntityName } from "@shared/utils";

interface JoinableOrgsFormProps {
  onBack: () => void;
}

export const JoinableOrgsForm: React.FC<JoinableOrgsFormProps> = ({
  onBack,
}) => {
  const [user] = useUser();
  const { data: joinableOrgs, isLoading } = useJoinableOrgs();

  const { mutate: joinDeployment, isPending } = useJoinDeploymentMutation();

  const onJoin = async (deployment: Deployment) => {
    joinDeployment({ deploymentId: deployment.id, redirectOnSuccess: true });
  };

  return isLoading || joinableOrgs === undefined ? (
    <Skeleton h={50} />
  ) : (
    <Stack>
      <Text>
        Join an existing Semgrep organization from one of your{" "}
        {scmEntityName(user?.auth_provider_type)}s.
      </Text>
      <Stack align="center">
        {joinableOrgs.map((org, idx) => (
          <Button
            key={idx}
            variant="outline"
            disabled={isPending}
            onClick={() => onJoin(org)}
            leftSection={<FontAwesomeIcon icon={getScmIcon(org.sourceType)} />}
          >
            {org.name}
          </Button>
        ))}
        <Button onClick={() => onBack()}>Back</Button>
      </Stack>
    </Stack>
  );
};
