// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/ai/v1/patch.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import {
  AggregateIssueState,
  aggregateIssueStateFromJSON,
  aggregateIssueStateToJSON,
  aggregateIssueStateToNumber,
} from "../../issues/v1/issues";

export const protobufPackage = "protos.ai.v1";

export interface LookupKey {
  issueId?: number | undefined;
  ruleId?: number | undefined;
}

export interface AiPatch {
  id: number;
  status: AiPatch_Status;
  type: AiPatch_Type;
  tab: AiPatch_Tab;
  title: string;
  link: string;
  description: string;
  project: string;
  state: AggregateIssueState;
  selectedAt?: Date | undefined;
  lookupKey: LookupKey | undefined;
}

export enum AiPatch_Type {
  UNSPECIFIED = "TYPE_UNSPECIFIED",
  RULE_DEFINITION = "TYPE_RULE_DEFINITION",
  CODE_FIX = "TYPE_CODE_FIX",
  SCA_UPGRADE = "TYPE_SCA_UPGRADE",
  SECRETS_REVOKE = "TYPE_SECRETS_REVOKE",
  PROMOTE_RULE = "TYPE_PROMOTE_RULE",
  DEMOTE_RULE = "TYPE_DEMOTE_RULE",
  IGNORE_PATH = "TYPE_IGNORE_PATH",
}

export function aiPatch_TypeFromJSON(object: any): AiPatch_Type {
  switch (object) {
    case 0:
    case "TYPE_UNSPECIFIED":
      return AiPatch_Type.UNSPECIFIED;
    case 1:
    case "TYPE_RULE_DEFINITION":
      return AiPatch_Type.RULE_DEFINITION;
    case 3:
    case "TYPE_CODE_FIX":
      return AiPatch_Type.CODE_FIX;
    case 4:
    case "TYPE_SCA_UPGRADE":
      return AiPatch_Type.SCA_UPGRADE;
    case 5:
    case "TYPE_SECRETS_REVOKE":
      return AiPatch_Type.SECRETS_REVOKE;
    case 6:
    case "TYPE_PROMOTE_RULE":
      return AiPatch_Type.PROMOTE_RULE;
    case 7:
    case "TYPE_DEMOTE_RULE":
      return AiPatch_Type.DEMOTE_RULE;
    case 8:
    case "TYPE_IGNORE_PATH":
      return AiPatch_Type.IGNORE_PATH;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AiPatch_Type");
  }
}

export function aiPatch_TypeToJSON(object: AiPatch_Type): string {
  switch (object) {
    case AiPatch_Type.UNSPECIFIED:
      return "TYPE_UNSPECIFIED";
    case AiPatch_Type.RULE_DEFINITION:
      return "TYPE_RULE_DEFINITION";
    case AiPatch_Type.CODE_FIX:
      return "TYPE_CODE_FIX";
    case AiPatch_Type.SCA_UPGRADE:
      return "TYPE_SCA_UPGRADE";
    case AiPatch_Type.SECRETS_REVOKE:
      return "TYPE_SECRETS_REVOKE";
    case AiPatch_Type.PROMOTE_RULE:
      return "TYPE_PROMOTE_RULE";
    case AiPatch_Type.DEMOTE_RULE:
      return "TYPE_DEMOTE_RULE";
    case AiPatch_Type.IGNORE_PATH:
      return "TYPE_IGNORE_PATH";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AiPatch_Type");
  }
}

export function aiPatch_TypeToNumber(object: AiPatch_Type): number {
  switch (object) {
    case AiPatch_Type.UNSPECIFIED:
      return 0;
    case AiPatch_Type.RULE_DEFINITION:
      return 1;
    case AiPatch_Type.CODE_FIX:
      return 3;
    case AiPatch_Type.SCA_UPGRADE:
      return 4;
    case AiPatch_Type.SECRETS_REVOKE:
      return 5;
    case AiPatch_Type.PROMOTE_RULE:
      return 6;
    case AiPatch_Type.DEMOTE_RULE:
      return 7;
    case AiPatch_Type.IGNORE_PATH:
      return 8;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AiPatch_Type");
  }
}

export enum AiPatch_Tab {
  UNSPECIFIED = "TAB_UNSPECIFIED",
  CONFIG = "TAB_CONFIG",
  SECURITY = "TAB_SECURITY",
}

export function aiPatch_TabFromJSON(object: any): AiPatch_Tab {
  switch (object) {
    case 0:
    case "TAB_UNSPECIFIED":
      return AiPatch_Tab.UNSPECIFIED;
    case 1:
    case "TAB_CONFIG":
      return AiPatch_Tab.CONFIG;
    case 2:
    case "TAB_SECURITY":
      return AiPatch_Tab.SECURITY;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AiPatch_Tab");
  }
}

export function aiPatch_TabToJSON(object: AiPatch_Tab): string {
  switch (object) {
    case AiPatch_Tab.UNSPECIFIED:
      return "TAB_UNSPECIFIED";
    case AiPatch_Tab.CONFIG:
      return "TAB_CONFIG";
    case AiPatch_Tab.SECURITY:
      return "TAB_SECURITY";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AiPatch_Tab");
  }
}

export function aiPatch_TabToNumber(object: AiPatch_Tab): number {
  switch (object) {
    case AiPatch_Tab.UNSPECIFIED:
      return 0;
    case AiPatch_Tab.CONFIG:
      return 1;
    case AiPatch_Tab.SECURITY:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AiPatch_Tab");
  }
}

export enum AiPatch_Status {
  UNSPECIFIED = "STATUS_UNSPECIFIED",
  NEW = "STATUS_NEW",
  ACCEPTED = "STATUS_ACCEPTED",
  REJECTED = "STATUS_REJECTED",
  EXPIRED = "STATUS_EXPIRED",
}

export function aiPatch_StatusFromJSON(object: any): AiPatch_Status {
  switch (object) {
    case 0:
    case "STATUS_UNSPECIFIED":
      return AiPatch_Status.UNSPECIFIED;
    case 10:
    case "STATUS_NEW":
      return AiPatch_Status.NEW;
    case 20:
    case "STATUS_ACCEPTED":
      return AiPatch_Status.ACCEPTED;
    case 30:
    case "STATUS_REJECTED":
      return AiPatch_Status.REJECTED;
    case 40:
    case "STATUS_EXPIRED":
      return AiPatch_Status.EXPIRED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AiPatch_Status");
  }
}

export function aiPatch_StatusToJSON(object: AiPatch_Status): string {
  switch (object) {
    case AiPatch_Status.UNSPECIFIED:
      return "STATUS_UNSPECIFIED";
    case AiPatch_Status.NEW:
      return "STATUS_NEW";
    case AiPatch_Status.ACCEPTED:
      return "STATUS_ACCEPTED";
    case AiPatch_Status.REJECTED:
      return "STATUS_REJECTED";
    case AiPatch_Status.EXPIRED:
      return "STATUS_EXPIRED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AiPatch_Status");
  }
}

export function aiPatch_StatusToNumber(object: AiPatch_Status): number {
  switch (object) {
    case AiPatch_Status.UNSPECIFIED:
      return 0;
    case AiPatch_Status.NEW:
      return 10;
    case AiPatch_Status.ACCEPTED:
      return 20;
    case AiPatch_Status.REJECTED:
      return 30;
    case AiPatch_Status.EXPIRED:
      return 40;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AiPatch_Status");
  }
}

export interface GetAiPatchesResponse {
  aiPatches: AiPatch[];
}

export interface PutAiPatchStatusRequest {
  /** experimental idea: validate the status the user had visible is still correct */
  fromStatus: AiPatch_Status;
  toStatus: AiPatch_Status;
}

export interface PutAiPatchStatusResponse {
  newAiPatch: AiPatch | undefined;
}

function createBaseLookupKey(): LookupKey {
  return { issueId: undefined, ruleId: undefined };
}

export const LookupKey = {
  encode(message: LookupKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.issueId !== undefined) {
      writer.uint32(8).uint64(message.issueId);
    }
    if (message.ruleId !== undefined) {
      writer.uint32(16).uint64(message.ruleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LookupKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLookupKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.issueId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.ruleId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LookupKey {
    return {
      issueId: isSet(object.issueId) ? globalThis.Number(object.issueId) : undefined,
      ruleId: isSet(object.ruleId) ? globalThis.Number(object.ruleId) : undefined,
    };
  },

  toJSON(message: LookupKey): unknown {
    const obj: any = {};
    if (message.issueId !== undefined) {
      obj.issueId = Math.round(message.issueId);
    }
    if (message.ruleId !== undefined) {
      obj.ruleId = Math.round(message.ruleId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LookupKey>, I>>(base?: I): LookupKey {
    return LookupKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LookupKey>, I>>(object: I): LookupKey {
    const message = createBaseLookupKey();
    message.issueId = object.issueId ?? undefined;
    message.ruleId = object.ruleId ?? undefined;
    return message;
  },
};

function createBaseAiPatch(): AiPatch {
  return {
    id: 0,
    status: AiPatch_Status.UNSPECIFIED,
    type: AiPatch_Type.UNSPECIFIED,
    tab: AiPatch_Tab.UNSPECIFIED,
    title: "",
    link: "",
    description: "",
    project: "",
    state: AggregateIssueState.UNSPECIFIED,
    selectedAt: undefined,
    lookupKey: undefined,
  };
}

export const AiPatch = {
  encode(message: AiPatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.status !== AiPatch_Status.UNSPECIFIED) {
      writer.uint32(16).int32(aiPatch_StatusToNumber(message.status));
    }
    if (message.type !== AiPatch_Type.UNSPECIFIED) {
      writer.uint32(24).int32(aiPatch_TypeToNumber(message.type));
    }
    if (message.tab !== AiPatch_Tab.UNSPECIFIED) {
      writer.uint32(80).int32(aiPatch_TabToNumber(message.tab));
    }
    if (message.title !== "") {
      writer.uint32(34).string(message.title);
    }
    if (message.link !== "") {
      writer.uint32(42).string(message.link);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    if (message.project !== "") {
      writer.uint32(58).string(message.project);
    }
    if (message.state !== AggregateIssueState.UNSPECIFIED) {
      writer.uint32(64).int32(aggregateIssueStateToNumber(message.state));
    }
    if (message.selectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.selectedAt), writer.uint32(74).fork()).ldelim();
    }
    if (message.lookupKey !== undefined) {
      LookupKey.encode(message.lookupKey, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AiPatch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAiPatch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = aiPatch_StatusFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = aiPatch_TypeFromJSON(reader.int32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.tab = aiPatch_TabFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.title = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.link = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.project = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.state = aggregateIssueStateFromJSON(reader.int32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.selectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.lookupKey = LookupKey.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AiPatch {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      status: isSet(object.status) ? aiPatch_StatusFromJSON(object.status) : AiPatch_Status.UNSPECIFIED,
      type: isSet(object.type) ? aiPatch_TypeFromJSON(object.type) : AiPatch_Type.UNSPECIFIED,
      tab: isSet(object.tab) ? aiPatch_TabFromJSON(object.tab) : AiPatch_Tab.UNSPECIFIED,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      link: isSet(object.link) ? globalThis.String(object.link) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      state: isSet(object.state) ? aggregateIssueStateFromJSON(object.state) : AggregateIssueState.UNSPECIFIED,
      selectedAt: isSet(object.selectedAt) ? fromJsonTimestamp(object.selectedAt) : undefined,
      lookupKey: isSet(object.lookupKey) ? LookupKey.fromJSON(object.lookupKey) : undefined,
    };
  },

  toJSON(message: AiPatch): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.status !== AiPatch_Status.UNSPECIFIED) {
      obj.status = aiPatch_StatusToJSON(message.status);
    }
    if (message.type !== AiPatch_Type.UNSPECIFIED) {
      obj.type = aiPatch_TypeToJSON(message.type);
    }
    if (message.tab !== AiPatch_Tab.UNSPECIFIED) {
      obj.tab = aiPatch_TabToJSON(message.tab);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.link !== "") {
      obj.link = message.link;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.state !== AggregateIssueState.UNSPECIFIED) {
      obj.state = aggregateIssueStateToJSON(message.state);
    }
    if (message.selectedAt !== undefined) {
      obj.selectedAt = message.selectedAt.toISOString();
    }
    if (message.lookupKey !== undefined) {
      obj.lookupKey = LookupKey.toJSON(message.lookupKey);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AiPatch>, I>>(base?: I): AiPatch {
    return AiPatch.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AiPatch>, I>>(object: I): AiPatch {
    const message = createBaseAiPatch();
    message.id = object.id ?? 0;
    message.status = object.status ?? AiPatch_Status.UNSPECIFIED;
    message.type = object.type ?? AiPatch_Type.UNSPECIFIED;
    message.tab = object.tab ?? AiPatch_Tab.UNSPECIFIED;
    message.title = object.title ?? "";
    message.link = object.link ?? "";
    message.description = object.description ?? "";
    message.project = object.project ?? "";
    message.state = object.state ?? AggregateIssueState.UNSPECIFIED;
    message.selectedAt = object.selectedAt ?? undefined;
    message.lookupKey = (object.lookupKey !== undefined && object.lookupKey !== null)
      ? LookupKey.fromPartial(object.lookupKey)
      : undefined;
    return message;
  },
};

function createBaseGetAiPatchesResponse(): GetAiPatchesResponse {
  return { aiPatches: [] };
}

export const GetAiPatchesResponse = {
  encode(message: GetAiPatchesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.aiPatches) {
      AiPatch.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAiPatchesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAiPatchesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.aiPatches.push(AiPatch.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAiPatchesResponse {
    return {
      aiPatches: globalThis.Array.isArray(object?.aiPatches)
        ? object.aiPatches.map((e: any) => AiPatch.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetAiPatchesResponse): unknown {
    const obj: any = {};
    if (message.aiPatches?.length) {
      obj.aiPatches = message.aiPatches.map((e) => AiPatch.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAiPatchesResponse>, I>>(base?: I): GetAiPatchesResponse {
    return GetAiPatchesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAiPatchesResponse>, I>>(object: I): GetAiPatchesResponse {
    const message = createBaseGetAiPatchesResponse();
    message.aiPatches = object.aiPatches?.map((e) => AiPatch.fromPartial(e)) || [];
    return message;
  },
};

function createBasePutAiPatchStatusRequest(): PutAiPatchStatusRequest {
  return { fromStatus: AiPatch_Status.UNSPECIFIED, toStatus: AiPatch_Status.UNSPECIFIED };
}

export const PutAiPatchStatusRequest = {
  encode(message: PutAiPatchStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fromStatus !== AiPatch_Status.UNSPECIFIED) {
      writer.uint32(8).int32(aiPatch_StatusToNumber(message.fromStatus));
    }
    if (message.toStatus !== AiPatch_Status.UNSPECIFIED) {
      writer.uint32(16).int32(aiPatch_StatusToNumber(message.toStatus));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PutAiPatchStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePutAiPatchStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.fromStatus = aiPatch_StatusFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.toStatus = aiPatch_StatusFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PutAiPatchStatusRequest {
    return {
      fromStatus: isSet(object.fromStatus) ? aiPatch_StatusFromJSON(object.fromStatus) : AiPatch_Status.UNSPECIFIED,
      toStatus: isSet(object.toStatus) ? aiPatch_StatusFromJSON(object.toStatus) : AiPatch_Status.UNSPECIFIED,
    };
  },

  toJSON(message: PutAiPatchStatusRequest): unknown {
    const obj: any = {};
    if (message.fromStatus !== AiPatch_Status.UNSPECIFIED) {
      obj.fromStatus = aiPatch_StatusToJSON(message.fromStatus);
    }
    if (message.toStatus !== AiPatch_Status.UNSPECIFIED) {
      obj.toStatus = aiPatch_StatusToJSON(message.toStatus);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PutAiPatchStatusRequest>, I>>(base?: I): PutAiPatchStatusRequest {
    return PutAiPatchStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PutAiPatchStatusRequest>, I>>(object: I): PutAiPatchStatusRequest {
    const message = createBasePutAiPatchStatusRequest();
    message.fromStatus = object.fromStatus ?? AiPatch_Status.UNSPECIFIED;
    message.toStatus = object.toStatus ?? AiPatch_Status.UNSPECIFIED;
    return message;
  },
};

function createBasePutAiPatchStatusResponse(): PutAiPatchStatusResponse {
  return { newAiPatch: undefined };
}

export const PutAiPatchStatusResponse = {
  encode(message: PutAiPatchStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.newAiPatch !== undefined) {
      AiPatch.encode(message.newAiPatch, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PutAiPatchStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePutAiPatchStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.newAiPatch = AiPatch.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PutAiPatchStatusResponse {
    return { newAiPatch: isSet(object.newAiPatch) ? AiPatch.fromJSON(object.newAiPatch) : undefined };
  },

  toJSON(message: PutAiPatchStatusResponse): unknown {
    const obj: any = {};
    if (message.newAiPatch !== undefined) {
      obj.newAiPatch = AiPatch.toJSON(message.newAiPatch);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PutAiPatchStatusResponse>, I>>(base?: I): PutAiPatchStatusResponse {
    return PutAiPatchStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PutAiPatchStatusResponse>, I>>(object: I): PutAiPatchStatusResponse {
    const message = createBasePutAiPatchStatusResponse();
    message.newAiPatch = (object.newAiPatch !== undefined && object.newAiPatch !== null)
      ? AiPatch.fromPartial(object.newAiPatch)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
