export const copyTextToClipboard = (text: string): Promise<void> => {
  return navigator.clipboard.writeText(text);
};

export const copyElementToClipboard = (target: HTMLElement): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (
      target instanceof HTMLInputElement ||
      target instanceof HTMLTextAreaElement
    ) {
      target.select();
    } else {
      const selection = window.getSelection();
      if (selection === null) {
        return reject("failed to access browser selection");
      }
      selection.selectAllChildren(target);
    }
    const didSucceed = document.execCommand("copy");
    didSucceed ? resolve() : reject("failed to run copy command");
  });
};
