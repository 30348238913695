import { useContext } from "react";
import dayjs from "dayjs";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faClock,
  faCloudArrowUp,
  faCodeBranch as faCodeBranchLight,
  faFolderArrowUp,
  faPlusCircle as faPlusCircleLight,
  faShareFromSquare,
  faTrashAlt,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCodeCommit,
  faEllipsisVertical,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Menu } from "@mantine/core";

import { deleteOrgPrivateRule } from "@shared/api";
import { Permission } from "@shared/types";

import { WorkbenchContext } from "../../providers";

const CustomMenuItem: React.FC<{
  text: string;
  icon: IconProp;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
}> = ({ text, icon, onClick, disabled = false }) => (
  <Menu.Item
    leftSection={<FontAwesomeIcon fixedWidth icon={icon} />}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </Menu.Item>
);

const DAYJS_FORMAT_STRING = "MMM D, hh:mm A";

const EditorEllipsisMenu: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const { workbench } = useContext(WorkbenchContext);
  const isUserLoggedIn = workbench.user !== undefined;
  const { remoteBundle } = workbench;
  const canCreateNewRule =
    workbench.permissions.includes(Permission.editor_create) || !isUserLoggedIn;
  const canForkRule = canCreateNewRule && workbench.bundle;
  const clickForkRule = () => {
    workbench.setShowUnsavedChangesWarning(false);
    workbench.forkRule();
    return;
  };
  const canDeleteRule = isUserLoggedIn && workbench.doesRuleBelongToUser;
  const clickDeleteRule = () => {
    if (!canDeleteRule) return;
    // doubtful you can delete a rule if the bundle isnt loaded, but lets appease the typechecker
    if (!workbench.bundle) return;
    // can't delete rule that doesn't have an ID
    if (!workbench.bundle.hashId) return;
    deleteOrgPrivateRule(workbench.bundle.hashId)
      .catch(workbench.handleApiError)
      .then(() => workbench.newBundle())
      .then(() => workbench.reloadAllData());
  };

  const isSecretsRule = workbench.bundle?.isSecretsRule ?? false;
  const canPublishRule = isUserLoggedIn && !isSecretsRule;

  const clickShareRule = async (isPublic: boolean) => {
    if (
      workbench.hasUnsavedChanges &&
      workbench.org &&
      workbench.bundle &&
      workbench.doesUserHaveEditPermission
    ) {
      await workbench.saveBundle();
    }
    workbench.ui.openRuleShareDialog(isPublic);
  };

  const urlParams = new URLSearchParams(workbench.history.location.search);
  const isDevelop = urlParams.get("develop") === "true";

  const lastEditorName = remoteBundle?.displayProps.lastChangeBy;
  const tooltipTime = dayjs(remoteBundle?.displayProps.lastChangeAt);
  return (
    <Menu withArrow withinPortal position="bottom-end">
      {/* // TODO disabled? */}
      <Menu.Dropdown>
        <CustomMenuItem
          icon={faPlusCircleLight}
          onClick={() => workbench.newBundle()}
          text="New"
          disabled={!canCreateNewRule}
        />
        <CustomMenuItem
          icon={faCodeBranchLight}
          onClick={clickForkRule}
          text="Fork"
          disabled={!canForkRule}
        />
        <Menu.Divider />
        <CustomMenuItem
          icon={faShareFromSquare}
          onClick={() => clickShareRule(false)}
          text="Share..."
        />
        <CustomMenuItem
          icon={faFolderArrowUp}
          onClick={() => workbench.ui.openImportRulesDialog()}
          disabled={!isUserLoggedIn}
          text="Import..."
        />
        <CustomMenuItem
          icon={faCloudArrowUp}
          onClick={() => clickShareRule(true)}
          disabled={!canPublishRule}
          text="Publish..."
        />
        <Menu.Divider />
        <CustomMenuItem
          icon={faTrashAlt}
          onClick={clickDeleteRule}
          text="Delete"
          disabled={!canDeleteRule}
        />
        <Menu.Divider />
        <CustomMenuItem
          icon={faCodeCommit}
          onClick={() => {}}
          text={`Semgrep v${
            isDevelop
              ? "Dev"
              : workbench?.org?.editor_semgrep_version || "Latest"
          }`}
          disabled={true}
        />
        <CustomMenuItem
          icon={faClock}
          text={`Last edit: ${tooltipTime.format(DAYJS_FORMAT_STRING)}`}
          onClick={() => {}}
          disabled={true}
        />
        {lastEditorName && (
          <CustomMenuItem
            icon={faUser}
            text={`Last editor: ${lastEditorName}`}
            onClick={() => {}}
            disabled={true}
          />
        )}
      </Menu.Dropdown>
      <Menu.Target>
        <div>
          <ActionIcon
            disabled={disabled}
            color="blue.0"
            variant="outline"
            size={36} // matches other buttons
            aria-label="More options"
          >
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </ActionIcon>
        </div>
      </Menu.Target>
    </Menu>
  );
};
export default EditorEllipsisMenu;
