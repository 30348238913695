import { observer } from "mobx-react-lite";
import { faRoute } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Group } from "@mantine/core";

import { InlineMarkdownCode } from "@shared/components";
import { AISuggestion } from "@shared/types";
import { CliMatch } from "@semgrep_output_types";

import { SuccessResult } from "../../stores";
import { isPro } from "../../utils";
import { AutofixMatchPanel } from "../AutofixMatchPanel";
import { SectionHeader } from "../SectionHeader";
import { SectionList } from "../SectionList";

import { LineNumber } from "./LineNumber";
import { ProBadge } from "./ProBadge";

import styles from "./ResultsPanel.module.css";

function compare(n1: number, n2: number) {
  if (n1 < n2) return -1;
  if (n1 > n2) return 1;
  return 0;
}

interface Props {
  result: SuccessResult;
  aiAutofixes: AISuggestion[];
  dirty: boolean;
  onShowTrace?: (r: CliMatch) => () => void;
  onApplyFix?: (r: CliMatch) => () => void;
  onApplyAIFix: (fix: AISuggestion) => void;
}
const Matches = ({
  result,
  aiAutofixes,
  dirty,
  onApplyFix,
  onShowTrace,
  onApplyAIFix,
}: Props) => {
  let labeledMatches: {
    label: "normal" | "pro-only" | "pro-removed";
    match: CliMatch;
  }[] = [];
  if (result.differentialResults) {
    result.differentialResults.proOnlyFindings.forEach((m) =>
      labeledMatches.push({
        label: "pro-only",
        match: m,
      })
    );
    result.differentialResults.proRemovedFindings.forEach((m) =>
      labeledMatches.push({
        label: "pro-removed",
        match: m,
      })
    );
    result.differentialResults.bothFindings.forEach((m) =>
      labeledMatches.push({
        label: "normal",
        match: m,
      })
    );

    labeledMatches.sort((m1, m2) => {
      const startCmp = compare(m1.match.start.offset, m2.match.start.offset);
      if (startCmp === 0) {
        return compare(m1.match.end.offset, m2.match.end.offset);
      }
      return startCmp;
    });
  } else {
    labeledMatches = result.response.semgrep_result.output.results.map((m) => ({
      label: "normal",
      match: m,
    }));
  }

  return (
    <>
      <SectionHeader>Matches</SectionHeader>
      <SectionList>
        {labeledMatches.map(({ match, label }, i) => (
          <li
            className={styles.matchesSection}
            key={i}
            style={{
              backgroundColor: isPro(label)
                ? "var(--mantine-color-violet-0)"
                : undefined,
            }}
          >
            <Group>
              <LineNumber line={match.start.line} label={label} />
              <ProBadge label={label} />
            </Group>
            {label === "pro-removed" ? (
              <span style={{ textDecoration: "line-through" }}>
                <InlineMarkdownCode text={match.extra.message} />
              </span>
            ) : (
              <InlineMarkdownCode text={match.extra.message} />
            )}
            {label !== "pro-removed" &&
              match.extra.dataflow_trace &&
              onShowTrace && (
                <Button
                  className={styles.dataflowTracesButton}
                  size="compact-xs"
                  onClick={onShowTrace(match)}
                  variant="outline"
                  disabled={dirty}
                  leftSection={<FontAwesomeIcon icon={faRoute} />}
                >
                  dataflow
                </Button>
              )}
            <AutofixMatchPanel
              item={match}
              onApplyAIFix={onApplyAIFix}
              aiAutofix={aiAutofixes[i]}
              fixes={match.extra.fixed_lines}
              dirty={dirty}
              onApplyFix={onApplyFix}
            />
          </li>
        ))}
      </SectionList>
    </>
  );
};

export const MatchesSection = observer(Matches);
