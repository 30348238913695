import { LAST_SELECTED_ORG_KEY, ORGANIZATION_KEY } from "@shared/constants";
import { OrgData, UserData } from "@shared/types";

import { findOrgBySlug } from "../findOrgBySlug";
import { getOrgSlugFromPath } from "../getOrgSlugFromPath";
import { recoverBrowserSecurityError } from "../storage/storage";

const getUserFirstOrg = (userOrgs: OrgData[]): OrgData => {
  const sortedOrgs = [...userOrgs].sort(
    (a: OrgData, b: OrgData) => b.id - a.id
  );
  return sortedOrgs[0];
};

/**
 * Get org that should be selected based on the URL, last selected org, and
 * current user orgs.
 * @param userOrgs the orgs that the current user has access to
 * @returns the org that should be selected, in order of priority:
 *    - The org from the URL
 *    - The org that is saved in local storage
 *    - The last selected org
 *    - The first org in the user orgs list
 */
export const loadOrg = (
  user: UserData | undefined,
  userOrgs: OrgData[] | undefined
) => {
  if (!user || !userOrgs) return undefined;

  const slugFromPath = getOrgSlugFromPath(window.location.pathname);

  /**
   * NOTE: To help with fast hydration of the UI for future page loads, we will once
   * again store org data in local storage (GROW-286). While we previously did save
   * org data to local storage, the prior implementation coupled org data with auth
   * via inclusion in the JWT. This design would cause forced user logouts when the
   * org data changed such as updating SCM provider configuration.
   * See https://github.com/semgrep/semgrep-app/pull/14117
   */
  const savedOrgData = recoverBrowserSecurityError(() =>
    localStorage.getItem(ORGANIZATION_KEY)
  );

  const lastSelectedSlug = recoverBrowserSecurityError(() =>
    localStorage.getItem(LAST_SELECTED_ORG_KEY)
  );

  // if there is an org in the URL, always use that org
  const orgFromPath = slugFromPath
    ? findOrgBySlug(slugFromPath, userOrgs)
    : undefined;
  if (orgFromPath) return orgFromPath;

  // Try to load from local storage for previously selected org
  let savedOrg: OrgData | undefined;
  if (savedOrgData) {
    try {
      savedOrg = JSON.parse(savedOrgData);
    } catch {} // eslint-disable-line no-empty
  }

  if (userOrgs.some((o) => o.id === savedOrg?.id || 0)) {
    // If user has a saved org, load that org
    return savedOrg;
  }

  const lastSelectedOrg = lastSelectedSlug
    ? findOrgBySlug(lastSelectedSlug, userOrgs)
    : undefined;

  if (lastSelectedOrg) return lastSelectedOrg;

  // return most recently created org
  return getUserFirstOrg(userOrgs);
};
