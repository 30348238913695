import isEqual from "lodash/isEqual";
import { computed, makeAutoObservable, makeObservable, observable } from "mobx";

import { ApiError, Highlight, RunResponse } from "@shared/types";
import { makeMatchHighlight } from "@shared/utils";
import {
  CliMatch,
  MatchDataflowTrace,
  MatchingExplanation,
} from "@semgrep_output_types";

import { RuleValidationError } from "../types/ruleValidationError";

import type { Bundle } from "./Bundle";

function findingsSame(f1: CliMatch, f2: CliMatch) {
  return isEqual(f1.start, f2.start) && isEqual(f1.end, f2.end);
}

interface CommonResult {
  bundle: Bundle;
  errorsCount: number;
  matchesCount: number;
  testFailuresCount: number;
  // Target text for which is this result valid for
  targetText: string;
}

export class RunResult implements CommonResult {
  bundle: Bundle;
  response: RunResponse;
  findings: CliMatch[];
  numTestFailures: number;
  showingTaintTraces: MatchDataflowTrace[] = [];
  errorsCount = 0;
  targetText: string;
  explanations?: MatchingExplanation[];

  constructor(bundle: Bundle, response: RunResponse) {
    // These are all manually tagged as being observable.
    // As much as we'd like to just call makeAutoObservable and be done with
    // it, we can't do that because SuccessResult extends this class with
    // extra information, and mobx doesn't like that.
    makeObservable(this, {
      bundle: observable,
      response: observable,
      findings: observable,
      numTestFailures: observable,
      showingTaintTraces: observable,
      errorsCount: observable,
      targetText: observable,
      explanations: observable,
      highlights: computed,
      taintTraces: computed,
      isDirty: computed,
      matchesCount: computed,
      testFailuresCount: computed,
      matchLines: computed,
      unexpectedMatchLines: computed,
      runtimeSeconds: computed,
      semgrepVersion: computed,
      errors: computed,
    });

    this.bundle = bundle;
    this.targetText = bundle.targetText;
    this.response = response;
    this.findings = response.semgrep_result.output.results;
    this.numTestFailures = response.test_result.reduce(
      (memo, tr) => memo + (tr.status === "FAILURE" ? 1 : 0),
      0
    );
    this.errorsCount = this.errors.length;
    this.explanations = response.semgrep_result.output.explanations;
  }

  get highlights(): Highlight[] {
    if (this.isDirty) {
      return [];
    }
    return this.findings.map((result) => makeMatchHighlight(result));
  }

  get taintTraces(): MatchDataflowTrace[] {
    if (this.isDirty) {
      return [];
    }
    return this.showingTaintTraces;
  }

  get isDirty(): boolean {
    return this.bundle.targetText !== this.targetText;
  }

  get matchesCount(): number {
    return this.findings.length;
  }

  get testFailuresCount(): number {
    return this.numTestFailures ?? 0;
  }

  get matchLines(): number[] {
    return this.response.semgrep_result.output.results.map(
      (match) => match.start.line
    );
  }

  get unexpectedMatchLines(): number[] {
    const { expectedMatches, expectedNotMatches } = this.bundle;
    const actualMatches = this.matchLines;
    return Array.from(
      new Set(
        actualMatches.filter(
          (lineno) =>
            expectedMatches.indexOf(lineno) === -1 &&
            expectedNotMatches.indexOf(lineno) === -1
        )
      )
    );
  }

  get runtimeSeconds(): number {
    return this.response.semgrep_result.run_time;
  }

  get semgrepVersion(): string | undefined {
    return this.response.semgrep_result.output.version;
  }

  get errors(): RuleValidationError[] {
    return this.response.semgrep_result.output.errors.map((err) => ({
      message: err.message ?? "unknown",
      severity: "Error",
      source: "Engine",
      code: err.type_.kind,
    }));
  }
}

// A SuccessResult is essentially a RunResult, but it might be two of them.
// The reason for this is that, in order to compare and contrast OSS and Pro
// results, we need to run the engine twice.
// By default, in Pro mode we run the result twice, and the second result is
// included here. The OSS result is the `secondResult`, the main one is Pro.
export class SuccessResult extends RunResult {
  secondResult?: RunResult;

  // These are pre-computed so we can use them to display the Pro-different
  // information
  differentialResults?: {
    bothFindings: CliMatch[];
    proOnlyFindings: CliMatch[];
    proRemovedFindings: CliMatch[];
  };

  constructor(
    bundle: Bundle,
    response: RunResponse,
    secondResponse?: RunResponse
  ) {
    super(bundle, response);
    if (secondResponse) {
      this.secondResult = new RunResult(bundle, secondResponse);

      const ossFindings = this.secondResult.findings;
      const proFindings = this.findings;

      this.differentialResults = {
        proOnlyFindings: proFindings.filter(
          (f1) => !ossFindings.some((f2) => findingsSame(f1, f2))
        ),
        bothFindings: proFindings.filter((f1) =>
          ossFindings.some((f2) => findingsSame(f1, f2))
        ),
        proRemovedFindings: ossFindings.filter(
          (f1) => !proFindings.some((f2) => findingsSame(f1, f2))
        ),
      };
    }
  }
}

export class ErrorResult implements CommonResult {
  bundle: Bundle;
  errors: RuleValidationError[];
  targetText: string;

  // Errors and matches aren't usually exclusive, but for this class
  // they are in the vast majority of cases. Subclass if otherwise.
  matchesCount = 0;
  testFailuresCount = 0;

  constructor(bundle: Bundle, errors: RuleValidationError[]) {
    makeAutoObservable(this);
    this.bundle = bundle;
    this.errors = errors;
    this.targetText = bundle.targetText;
  }

  get highlights(): Highlight[] {
    return [];
  }

  get taintTraces(): MatchDataflowTrace[] {
    return [];
  }

  get isDirty(): boolean {
    return this.bundle.targetText !== this.targetText;
  }

  get errorsCount(): number {
    return this.errors.length;
  }

  static fromApiResult(bundle: Bundle, error: ApiError) {
    const error_: RuleValidationError =
      error.statusCode === 400
        ? typeof error.body?.error === "string"
          ? { message: error.body?.error, severity: "Error" }
          : { message: "API ERROR - INVALID REQUEST", severity: "Error" }
        : { message: "UNKNOWN API ERROR", severity: "Error" };
    return new ErrorResult(bundle, [error_]);
  }
}

export type Result = SuccessResult | ErrorResult;
