import React from "react";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Menu, ScrollArea, Text } from "@mantine/core";

import { Language, LanguageDefinition } from "@shared/types";
import {
  ALL_LANGUAGES,
  languageMaturityTag,
  VISIBLE_LANGUAGES,
} from "@shared/utils";

interface Props {
  onChange: (language: Language) => void;
  value: Language;
}

const friendlySelectLanguage = (langDef: LanguageDefinition) => (
  <span>
    {langDef.name}
    <Text fz="sm" c="gray" span>
      {languageMaturityTag[langDef.maturity]}
    </Text>
  </span>
);

export const LanguageSelect: React.FC<Props> = ({ onChange, value }) => {
  const langDef = ALL_LANGUAGES[value] || { id: value, name: value };

  return (
    <Menu position="bottom-end" withinPortal>
      <Menu.Dropdown>
        <ScrollArea style={{ height: 400 }}>
          {Object.values(VISIBLE_LANGUAGES).map((lang) => (
            <Menu.Item key={lang.id} onClick={() => onChange(lang.id)}>
              {friendlySelectLanguage(lang)}
            </Menu.Item>
          ))}
        </ScrollArea>
      </Menu.Dropdown>
      <Menu.Target>
        <Button
          rightSection={<FontAwesomeIcon icon={faCaretDown} />}
          variant="default"
        >
          {friendlySelectLanguage(langDef)}
        </Button>
      </Menu.Target>
    </Menu>
  );
};
