import { useContext } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Box, Group, Stack } from "@mantine/core";

import { MatchingExplanation } from "@semgrep_output_types";

import { WorkbenchContext } from "../../providers";

import { InArray, Pattern, PatternWithKind } from "./types/rule";
import { StructureModeTextInput } from "./utils/StructureModeTextInput";
import { PatternButtons } from "./Buttons";
import { PatternOperatorSelect } from "./PatternOperatorSelect";

interface CodePatternEditorProps {
  pattern: PatternWithKind<"pattern" | "regex">;
  inArray: InArray<Pattern> | null;
  explanation: MatchingExplanation | null;
  isFocused: boolean;
}

/**
 * The component which dictates a basic pattern editor, which comes in the
 * form of either a box for a pattern or a regex.
 * @param pattern - the pattern this box corresponds to. It should have kind
 * `pattern` or `regex`
 * @param inArray - the array of patterns this one is contained in (if any)
 * @param explanation - the matching explanation corresponding to this node
 * @param isFocused - whether this pattern is being hovered
 */
export const CodePatternEditor: React.FC<CodePatternEditorProps> = observer(
  ({ pattern, inArray, explanation, isFocused }) => {
    const { workbench } = useContext(WorkbenchContext);
    const { bundle } = workbench;

    let text = null;
    switch (pattern.value.rawPattern.value.kind) {
      case "pattern":
        text = pattern.value.rawPattern.value.pattern;
        break;
      case "regex":
        text = pattern.value.rawPattern.value.regex;
        break;
    }

    const onChange = (s: string | undefined) => {
      if (s) {
        runInAction(() => {
          switch (pattern.value.rawPattern.value.kind) {
            case "pattern":
              pattern.value.rawPattern.value.pattern = s;
              return;
            case "regex":
              pattern.value.rawPattern.value.regex = s;
              return;
          }
        });
      }
    };

    return (
      // The width of the textarea is hard-coded here.
      // Ideally, it would be as big as possible within the space, but this
      // doesn't work properly, probably because the parent's width is bounded.
      <Stack gap="6px">
        <Group gap={"var(--button-spacing)"}>
          <PatternOperatorSelect
            pattern={pattern}
            inArray={inArray}
            explanation={explanation}
            isFocused={isFocused}
          />
          <PatternButtons
            bundle={bundle}
            pattern={pattern}
            inArray={inArray}
            isFocused={isFocused}
          />
        </Group>
        {pattern.isExpanded && (
          <Box w="350px">
            <StructureModeTextInput
              value={text}
              onChange={onChange}
              isCode={pattern.value.rawPattern.value.kind === "pattern"}
              placeholder="Enter a pattern here..."
            />
          </Box>
        )}
      </Stack>
    );
  }
);
