import { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  faCheckCircle,
  faCodeBranch,
  faSave,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, HoverCard, Tooltip } from "@mantine/core";

import { useInvalidateChecklistProgress } from "@shared/hooks";
import { Permission } from "@shared/types";

import { WorkbenchContext } from "../providers";

import { LegacyRuleSaveDialog } from "./LegacyRuleSaveDialog";
import { SaveRuleUpsellPopover } from "./SaveRuleUpsellPopover";

/**
 * Button for saving or forking a rule
 * If the user is able to save changes to a rule, the button says "Save"
 * If the user cannot save changes or the user has changed the rule id
 * (thus saving would create a fork), the button says "Fork"
 */
const ActionButton = () => {
  const { workbench } = useContext(WorkbenchContext);
  const {
    bundle,
    remoteBundle,
    isSaving,
    hasUnsavedChanges,
    doesRuleBelongToUser,
    hasRuleNameChanged,
    needsForkingToSave,
  } = workbench;
  const [isLegacyRuleSaveDialogOpen, setIsLegacyRuleSaveDialogOpen] =
    useState(false);

  const { invalidateChecklistProgress } = useInvalidateChecklistProgress();

  // can't save without a rule ID
  if (!bundle?.rule?.id) return null;
  // address string has to be valid because that's what we're currently looking at
  if (workbench.addressString === undefined) return null;

  const hasCreatePermission = workbench.permissions.includes(
    Permission.editor_create
  );

  // if saving the saving the current rule would not save changes to the original

  const isLoggedOut = workbench.user === undefined;

  // to be enabled, a user needs to have changes to save, have permission to save,
  // and be logged in
  const isEnabled =
    !isLoggedOut &&
    hasCreatePermission &&
    !isSaving &&
    (hasUnsavedChanges || needsForkingToSave);

  // tooltip content that shows if the user is logged in
  let tooltipContent: JSX.Element | undefined = undefined;
  if (!isSaving && hasRuleNameChanged) {
    tooltipContent = (
      <span>
        Changing the rule name creates a fork - the original rule will be
        unchanged at <code>{remoteBundle?.rule?.id}</code>
      </span>
    );
  } else if (!isSaving && !doesRuleBelongToUser) {
    tooltipContent = (
      <span>
        You cannot modify the original rule at{" "}
        <code>{remoteBundle?.rule?.id}</code>
      </span>
    );
  }

  const getIconAndButtonText = () => {
    if (isSaving) {
      return {
        icon: undefined, // will use the button's loading state
        text: "Saving",
      };
    } else if (needsForkingToSave && !isLoggedOut) {
      return {
        icon: <FontAwesomeIcon icon={faCodeBranch} />,
        text: "Fork",
      };
    } else if (hasUnsavedChanges) {
      return {
        icon: <FontAwesomeIcon icon={faSave} />,
        text: "Save",
      };
    } else if (workbench.isNewBundle) {
      return {
        icon: <FontAwesomeIcon icon={faSave} />,
        text: "Edit to save",
      };
    } else {
      return {
        icon: <FontAwesomeIcon icon={faCheckCircle} />,
        text: "Saved",
      };
    }
  };

  const onSaveRule = async () => {
    const res = await workbench.saveOrFork();
    if (res?.showSaveLegacy) {
      setIsLegacyRuleSaveDialogOpen(true);
    }
    invalidateChecklistProgress();
  };

  return (
    <>
      <LegacyRuleSaveDialog
        opened={isLegacyRuleSaveDialogOpen}
        closeDialog={() => setIsLegacyRuleSaveDialogOpen(false)}
      />
      {workbench.user ? (
        <Tooltip
          label={tooltipContent}
          position="right"
          withArrow
          disabled={tooltipContent === undefined}
        >
          <div>
            <Button
              onClick={onSaveRule}
              variant="outline"
              color="blue.0"
              disabled={!isEnabled}
              leftSection={getIconAndButtonText().icon}
              loading={isSaving}
            >
              {getIconAndButtonText().text}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <HoverCard withArrow>
          <HoverCard.Target>
            <div>
              <Button variant="outline" color="blue.0" disabled>
                <FontAwesomeIcon fixedWidth icon={faSave} /> Save
              </Button>
            </div>
          </HoverCard.Target>
          <HoverCard.Dropdown p="md">
            <SaveRuleUpsellPopover
              returnPath={
                workbench.hasUnsavedChanges ? "/playground/new" : undefined
              }
            />
          </HoverCard.Dropdown>
        </HoverCard>
      )}
    </>
  );
};
export const HeaderActionButton = observer(ActionButton);
