import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { faAngleDoubleUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, ScrollArea, Text } from "@mantine/core";

import { MatchingExplanation } from "@semgrep_output_types";

import { WorkbenchContext } from "../../providers/workbenchContext";
import { SuccessResult } from "../../stores";

import OperationItem from "./OperationItem";

import styles from "./RuleInspectorPanel.module.css";

const RuleInspectorPanel = () => {
  const { workbench } = useContext(WorkbenchContext);
  const { result } = workbench;

  let explanations: MatchingExplanation[] = [];
  if (result instanceof SuccessResult) {
    explanations = result?.explanations || [];
  }
  return (
    <Accordion
      chevron={<FontAwesomeIcon icon={faAngleDoubleUp} size="xs" />}
      chevronPosition="left"
      data-hasexplanations={Boolean(explanations.length > 0)}
      classNames={{
        root: styles.root,
        control:
          explanations.length > 0 ? styles.hasExplanations : styles.control,
        label: styles.label,
        item: styles.item,
      }}
      multiple
      variant="contained"
      radius="xs"
      defaultValue={["inspector"]}
    >
      <ScrollArea.Autosize mah={300}>
        <Accordion.Item
          value={explanations.length === 0 ? "not-inspector" : "inspector"}
        >
          <Accordion.Control disabled={explanations.length === 0}>
            <Text inherit size="md" fw={500}>
              Inspect Rule
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            {explanations.map((e, i) => (
              <OperationItem explanation={e} key={i} topLevel={true} />
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </ScrollArea.Autosize>
    </Accordion>
  );
};
export default observer(RuleInspectorPanel);
