import { useRef } from "react";
import styled from "styled-components";
import { TextInput, Tooltip } from "@mantine/core";

import { CopyButton } from "@shared/components";

export const CopyIconWrapper = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 16px;
`;

export function RuleSharePreview({
  input,
  style,
  leftElement,
}: {
  input: string;
  style?: React.CSSProperties;
  leftElement?: JSX.Element;
}) {
  const inputRef = useRef(null);
  return (
    <TextInput
      ref={inputRef}
      value={input}
      onChange={() => {}}
      onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
      readOnly
      leftSection={leftElement}
      style={{
        textOverflow: "ellipsis",
        ...style,
      }}
      styles={{ input: { backgroundColor: "var(--mantine-color-gray-3)" } }}
      rightSection={
        <CopyIconWrapper>
          <Tooltip label="Copy" withArrow withinPortal position="left">
            <div>
              <CopyButton getTarget={() => inputRef.current} />
            </div>
          </Tooltip>
        </CopyIconWrapper>
      }
    />
  );
}
