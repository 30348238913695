// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/secrets/v1/secrets.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { Autotriage } from "../../ai/v1/triage";
import {
  Confidence,
  confidenceFromJSON,
  confidenceToJSON,
  confidenceToNumber,
  FilterItem,
  FindingStatus,
  findingStatusFromJSON,
  findingStatusToJSON,
  findingStatusToNumber,
  FindingTriageReason,
  findingTriageReasonFromJSON,
  findingTriageReasonToJSON,
  findingTriageReasonToNumber,
  FindingTriageState,
  findingTriageStateFromJSON,
  findingTriageStateToJSON,
  findingTriageStateToNumber,
  Mode,
  modeFromJSON,
  modeToJSON,
  modeToNumber,
  RepositoryVisibility,
  repositoryVisibilityFromJSON,
  repositoryVisibilityToJSON,
  repositoryVisibilityToNumber,
  ScmType,
  scmTypeFromJSON,
  scmTypeToJSON,
  scmTypeToNumber,
  Severity,
  severityFromJSON,
  severityToJSON,
  severityToNumber,
} from "../../common/v1/common";
import { ExternalTicket } from "../../ticketing/v1/ticketing";

export const protobufPackage = "protos.secrets.v1";

export enum ValidationState {
  /** UNSPECIFIED - Return results for all validation states (can also omit this parameter). */
  UNSPECIFIED = "VALIDATION_STATE_UNSPECIFIED",
  /** CONFIRMED_VALID - Secret has been tested and is confirmed valid. */
  CONFIRMED_VALID = "VALIDATION_STATE_CONFIRMED_VALID",
  /** CONFIRMED_INVALID - Secret has been tested and is confirmed invalid. */
  CONFIRMED_INVALID = "VALIDATION_STATE_CONFIRMED_INVALID",
  /** VALIDATION_ERROR - Secret test was attempted and there was an error. */
  VALIDATION_ERROR = "VALIDATION_STATE_VALIDATION_ERROR",
  /** NO_VALIDATOR - There is no validator for this secret. */
  NO_VALIDATOR = "VALIDATION_STATE_NO_VALIDATOR",
}

export function validationStateFromJSON(object: any): ValidationState {
  switch (object) {
    case 0:
    case "VALIDATION_STATE_UNSPECIFIED":
      return ValidationState.UNSPECIFIED;
    case 1:
    case "VALIDATION_STATE_CONFIRMED_VALID":
      return ValidationState.CONFIRMED_VALID;
    case 2:
    case "VALIDATION_STATE_CONFIRMED_INVALID":
      return ValidationState.CONFIRMED_INVALID;
    case 3:
    case "VALIDATION_STATE_VALIDATION_ERROR":
      return ValidationState.VALIDATION_ERROR;
    case 4:
    case "VALIDATION_STATE_NO_VALIDATOR":
      return ValidationState.NO_VALIDATOR;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ValidationState");
  }
}

export function validationStateToJSON(object: ValidationState): string {
  switch (object) {
    case ValidationState.UNSPECIFIED:
      return "VALIDATION_STATE_UNSPECIFIED";
    case ValidationState.CONFIRMED_VALID:
      return "VALIDATION_STATE_CONFIRMED_VALID";
    case ValidationState.CONFIRMED_INVALID:
      return "VALIDATION_STATE_CONFIRMED_INVALID";
    case ValidationState.VALIDATION_ERROR:
      return "VALIDATION_STATE_VALIDATION_ERROR";
    case ValidationState.NO_VALIDATOR:
      return "VALIDATION_STATE_NO_VALIDATOR";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ValidationState");
  }
}

export function validationStateToNumber(object: ValidationState): number {
  switch (object) {
    case ValidationState.UNSPECIFIED:
      return 0;
    case ValidationState.CONFIRMED_VALID:
      return 1;
    case ValidationState.CONFIRMED_INVALID:
      return 2;
    case ValidationState.VALIDATION_ERROR:
      return 3;
    case ValidationState.NO_VALIDATOR:
      return 4;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ValidationState");
  }
}

export interface HistoricalInfo {
  /**
   * Git commit at which the finding is present. Used by "historical" scans,
   * which scan non-HEAD commits in the git history. Relevant for finding, e.g.,
   * secrets which are buried in the git history which we wouldn't find at HEAD
   */
  gitCommit: string;
  gitCommitTimestamp:
    | Date
    | undefined;
  /**
   * Git blob at which the finding is present. Sent in addition to the commit
   * since some SCMs have permalinks which use the blob sha, so this information
   * is useful when generating links back to the SCM.
   */
  gitBlob?: string | undefined;
}

/** A Finding represents a single secret finding. */
export interface SecretsFinding {
  /** Creation timestamp. */
  createdAt:
    | Date
    | undefined;
  /** Update timestamp. */
  updatedAt:
    | Date
    | undefined;
  /** ID of the finding. */
  id: number;
  /** Service type for the secrets finding (e.g. AWS, GitHub, GitLab, etc). */
  type: string;
  /** File path where the finding was detected. */
  findingPath: string;
  /** URL to the file where the finding was detected. */
  findingPathUrl?:
    | string
    | undefined;
  /** Repository where the finding was detected. */
  repository:
    | SecretsFinding_Repository
    | undefined;
  /** Branch where the finding was detected. */
  ref: string;
  /** URL to the branch where the finding was detected. */
  refUrl?:
    | string
    | undefined;
  /** Severity of the finding. */
  severity: Severity;
  /** Confidence of the finding. */
  confidence: Confidence;
  /** Whether the finding was validated or not. */
  validationState: ValidationState;
  /** The behavior of the finding reporting: Monitor / Comment / Block. */
  mode: Mode;
  /** Status of the finding. */
  status: FindingStatus;
  /** ID of the rule that triggered the finding. */
  ruleHashId: string;
  /** The external ticket reference */
  externalTicket?:
    | ExternalTicket
    | undefined;
  /** Historical scanning info for the finding. */
  historicalInfo?:
    | HistoricalInfo
    | undefined;
  /**
   * Autotriage info for the finding.
   * This is used for the Generic Secrets Detection project, for
   * autotriaging secrets findings with LLMs
   */
  autotriage?: Autotriage | undefined;
}

/** Repository where the finding was detected. */
export interface SecretsFinding_Repository {
  /** Repository name */
  name: string;
  /** URL to the repository where the finding was detected. */
  url: string;
  /** Repository visbility (e.g. public, private, unknown). */
  visibility: RepositoryVisibility;
  /** Provider for the finding (e.g. GitHub, GitLab, GHE, etc). */
  scmType: ScmType;
}

/** Query param filters for the Secrets page. */
export interface SecretsQueryParamFilters {
  /** Status of the finding. */
  status: string;
  /** Whether the finding was validated or not. */
  validationState: string;
  /** Severity of the finding. */
  severity: string;
  /** Confidence of the finding. */
  confidence: string;
  /** Service type for the secrets finding (e.g. AWS, GitHub, GitLab, etc). */
  type: string;
  /** Repository where the finding was detected. */
  repo: string;
  /** Branch where the finding was detected. */
  ref: string;
  /** The behavior of the finding reporting: Monitor / Comment / Block. */
  mode: string;
  /** Cursor to paginate through the results. Initializes as undefined. */
  cursor: string;
  /** Page size to paginate through the results. */
  limit: string;
  /** Is the repo public, private, or unknown. */
  repositoryVisibility: string;
}

/** GET request for the Secrets page. */
export interface ListSecretsRequest {
  /** Deployment ID (numeric). Example: `123`. Can be found at `/deployments`, or in your Settings in the web UI. */
  deploymentId: number;
  /** Cursor to paginate through the results. Provide a cursor value from the response to retrieve the next or previous page. */
  cursor?:
    | string
    | undefined;
  /** Page size to paginate through the results. */
  limit?:
    | number
    | undefined;
  /** Only get secrets created after this time. Format time as `YYYY-MM-DDThh:mm:ss.sssZ`. */
  since?:
    | Date
    | undefined;
  /** Whether the finding was validated or not. */
  validationState: ValidationState[];
  /** Status of the finding. */
  status?:
    | FindingStatus
    | undefined;
  /** Severity of the finding. */
  severity: Severity[];
  /** Repositories to view results for. If not specified, returns all. */
  repo: string[];
}

/** GET request for the Secrets count. */
export interface SecretsCountRequest {
  /** ID of the deployment. */
  deploymentId: number;
}

/** GET response for the Secrets page list view */
export interface ListSecretsResponse {
  /** List of findings. */
  findings: SecretsFinding[];
  /** Cursor to paginate through the results. */
  cursor?:
    | string
    | undefined;
  /** Cursor to paginate backwards through the results. */
  previous?: string | undefined;
}

/** In the future this could also include the valid secrets */
export interface SecretsCount {
  /** Total number of secrets findings in a given repository. */
  count: number;
}

/** GET response for the Secrets count */
export interface SecretsCountResponse {
  /** Number of secrets findings matching the given filters */
  count: number;
  /** Number of secrets by repository. */
  secretsByRepository: { [key: number]: SecretsCount };
}

export interface SecretsCountResponse_SecretsByRepositoryEntry {
  key: number;
  value: SecretsCount | undefined;
}

/** PUT request body for the Secrets update. */
export interface SecretsFindingUpdateBody {
  /** Optional triage state of the finding. */
  triageState?:
    | FindingTriageState
    | undefined;
  /** Optional triage reason */
  triageReason?:
    | FindingTriageReason
    | undefined;
  /** Optional note */
  note?: string | undefined;
}

/** PUT request for the Secrets update. */
export interface UpdateSecretsRequest {
  /** ID of the deployment. */
  deploymentId: number;
  /** PUT request body for the Secrets update. */
  body: SecretsFindingUpdateBody | undefined;
}

/** PUT response for the Secrets update. */
export interface UpdateSecretsResponse {
  /** total issues updated */
  numUpdated: number;
  /** unique issues updated */
  numUniqueUpdated: number;
}

/** GET request for a single Secrets finding. */
export interface SecretsFindingRequest {
  /** ID of the deployment. */
  deploymentId: number;
  /** ID of the finding. */
  id: number;
}

/** GET response for a single Secrets finding. */
export interface SecretsFindingResponse {
  /** The secrets finding */
  finding: SecretsFinding | undefined;
}

export interface SecretsFilters {
  repos: FilterItem[];
  refs: FilterItem[];
  providers: FilterItem[];
}

export interface SecretsFiltersRequest {
  deploymentId: number;
}

export interface SecretsFiltersResponse {
  filters: SecretsFilters | undefined;
}

function createBaseHistoricalInfo(): HistoricalInfo {
  return { gitCommit: "", gitCommitTimestamp: undefined, gitBlob: undefined };
}

export const HistoricalInfo = {
  encode(message: HistoricalInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.gitCommit !== "") {
      writer.uint32(10).string(message.gitCommit);
    }
    if (message.gitCommitTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.gitCommitTimestamp), writer.uint32(18).fork()).ldelim();
    }
    if (message.gitBlob !== undefined) {
      writer.uint32(26).string(message.gitBlob);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HistoricalInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistoricalInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.gitCommit = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.gitCommitTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.gitBlob = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HistoricalInfo {
    return {
      gitCommit: isSet(object.gitCommit) ? globalThis.String(object.gitCommit) : "",
      gitCommitTimestamp: isSet(object.gitCommitTimestamp) ? fromJsonTimestamp(object.gitCommitTimestamp) : undefined,
      gitBlob: isSet(object.gitBlob) ? globalThis.String(object.gitBlob) : undefined,
    };
  },

  toJSON(message: HistoricalInfo): unknown {
    const obj: any = {};
    if (message.gitCommit !== "") {
      obj.gitCommit = message.gitCommit;
    }
    if (message.gitCommitTimestamp !== undefined) {
      obj.gitCommitTimestamp = message.gitCommitTimestamp.toISOString();
    }
    if (message.gitBlob !== undefined) {
      obj.gitBlob = message.gitBlob;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HistoricalInfo>, I>>(base?: I): HistoricalInfo {
    return HistoricalInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HistoricalInfo>, I>>(object: I): HistoricalInfo {
    const message = createBaseHistoricalInfo();
    message.gitCommit = object.gitCommit ?? "";
    message.gitCommitTimestamp = object.gitCommitTimestamp ?? undefined;
    message.gitBlob = object.gitBlob ?? undefined;
    return message;
  },
};

function createBaseSecretsFinding(): SecretsFinding {
  return {
    createdAt: undefined,
    updatedAt: undefined,
    id: 0,
    type: "",
    findingPath: "",
    findingPathUrl: undefined,
    repository: undefined,
    ref: "",
    refUrl: undefined,
    severity: Severity.UNSPECIFIED,
    confidence: Confidence.UNSPECIFIED,
    validationState: ValidationState.UNSPECIFIED,
    mode: Mode.UNSPECIFIED,
    status: FindingStatus.UNSPECIFIED,
    ruleHashId: "",
    externalTicket: undefined,
    historicalInfo: undefined,
    autotriage: undefined,
  };
}

export const SecretsFinding = {
  encode(message: SecretsFinding, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.id !== 0) {
      writer.uint32(24).uint64(message.id);
    }
    if (message.type !== "") {
      writer.uint32(34).string(message.type);
    }
    if (message.findingPath !== "") {
      writer.uint32(42).string(message.findingPath);
    }
    if (message.findingPathUrl !== undefined) {
      writer.uint32(50).string(message.findingPathUrl);
    }
    if (message.repository !== undefined) {
      SecretsFinding_Repository.encode(message.repository, writer.uint32(58).fork()).ldelim();
    }
    if (message.ref !== "") {
      writer.uint32(66).string(message.ref);
    }
    if (message.refUrl !== undefined) {
      writer.uint32(74).string(message.refUrl);
    }
    if (message.severity !== Severity.UNSPECIFIED) {
      writer.uint32(80).int32(severityToNumber(message.severity));
    }
    if (message.confidence !== Confidence.UNSPECIFIED) {
      writer.uint32(88).int32(confidenceToNumber(message.confidence));
    }
    if (message.validationState !== ValidationState.UNSPECIFIED) {
      writer.uint32(96).int32(validationStateToNumber(message.validationState));
    }
    if (message.mode !== Mode.UNSPECIFIED) {
      writer.uint32(104).int32(modeToNumber(message.mode));
    }
    if (message.status !== FindingStatus.UNSPECIFIED) {
      writer.uint32(112).int32(findingStatusToNumber(message.status));
    }
    if (message.ruleHashId !== "") {
      writer.uint32(122).string(message.ruleHashId);
    }
    if (message.externalTicket !== undefined) {
      ExternalTicket.encode(message.externalTicket, writer.uint32(130).fork()).ldelim();
    }
    if (message.historicalInfo !== undefined) {
      HistoricalInfo.encode(message.historicalInfo, writer.uint32(138).fork()).ldelim();
    }
    if (message.autotriage !== undefined) {
      Autotriage.encode(message.autotriage, writer.uint32(146).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretsFinding {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretsFinding();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.type = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.findingPath = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.findingPathUrl = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.repository = SecretsFinding_Repository.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.ref = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.refUrl = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.severity = severityFromJSON(reader.int32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.confidence = confidenceFromJSON(reader.int32());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.validationState = validationStateFromJSON(reader.int32());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.mode = modeFromJSON(reader.int32());
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.status = findingStatusFromJSON(reader.int32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.ruleHashId = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.externalTicket = ExternalTicket.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.historicalInfo = HistoricalInfo.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.autotriage = Autotriage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretsFinding {
    return {
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      findingPath: isSet(object.findingPath) ? globalThis.String(object.findingPath) : "",
      findingPathUrl: isSet(object.findingPathUrl) ? globalThis.String(object.findingPathUrl) : undefined,
      repository: isSet(object.repository) ? SecretsFinding_Repository.fromJSON(object.repository) : undefined,
      ref: isSet(object.ref) ? globalThis.String(object.ref) : "",
      refUrl: isSet(object.refUrl) ? globalThis.String(object.refUrl) : undefined,
      severity: isSet(object.severity) ? severityFromJSON(object.severity) : Severity.UNSPECIFIED,
      confidence: isSet(object.confidence) ? confidenceFromJSON(object.confidence) : Confidence.UNSPECIFIED,
      validationState: isSet(object.validationState)
        ? validationStateFromJSON(object.validationState)
        : ValidationState.UNSPECIFIED,
      mode: isSet(object.mode) ? modeFromJSON(object.mode) : Mode.UNSPECIFIED,
      status: isSet(object.status) ? findingStatusFromJSON(object.status) : FindingStatus.UNSPECIFIED,
      ruleHashId: isSet(object.ruleHashId) ? globalThis.String(object.ruleHashId) : "",
      externalTicket: isSet(object.externalTicket) ? ExternalTicket.fromJSON(object.externalTicket) : undefined,
      historicalInfo: isSet(object.historicalInfo) ? HistoricalInfo.fromJSON(object.historicalInfo) : undefined,
      autotriage: isSet(object.autotriage) ? Autotriage.fromJSON(object.autotriage) : undefined,
    };
  },

  toJSON(message: SecretsFinding): unknown {
    const obj: any = {};
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt.toISOString();
    }
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.findingPath !== "") {
      obj.findingPath = message.findingPath;
    }
    if (message.findingPathUrl !== undefined) {
      obj.findingPathUrl = message.findingPathUrl;
    }
    if (message.repository !== undefined) {
      obj.repository = SecretsFinding_Repository.toJSON(message.repository);
    }
    if (message.ref !== "") {
      obj.ref = message.ref;
    }
    if (message.refUrl !== undefined) {
      obj.refUrl = message.refUrl;
    }
    if (message.severity !== Severity.UNSPECIFIED) {
      obj.severity = severityToJSON(message.severity);
    }
    if (message.confidence !== Confidence.UNSPECIFIED) {
      obj.confidence = confidenceToJSON(message.confidence);
    }
    if (message.validationState !== ValidationState.UNSPECIFIED) {
      obj.validationState = validationStateToJSON(message.validationState);
    }
    if (message.mode !== Mode.UNSPECIFIED) {
      obj.mode = modeToJSON(message.mode);
    }
    if (message.status !== FindingStatus.UNSPECIFIED) {
      obj.status = findingStatusToJSON(message.status);
    }
    if (message.ruleHashId !== "") {
      obj.ruleHashId = message.ruleHashId;
    }
    if (message.externalTicket !== undefined) {
      obj.externalTicket = ExternalTicket.toJSON(message.externalTicket);
    }
    if (message.historicalInfo !== undefined) {
      obj.historicalInfo = HistoricalInfo.toJSON(message.historicalInfo);
    }
    if (message.autotriage !== undefined) {
      obj.autotriage = Autotriage.toJSON(message.autotriage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretsFinding>, I>>(base?: I): SecretsFinding {
    return SecretsFinding.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretsFinding>, I>>(object: I): SecretsFinding {
    const message = createBaseSecretsFinding();
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.id = object.id ?? 0;
    message.type = object.type ?? "";
    message.findingPath = object.findingPath ?? "";
    message.findingPathUrl = object.findingPathUrl ?? undefined;
    message.repository = (object.repository !== undefined && object.repository !== null)
      ? SecretsFinding_Repository.fromPartial(object.repository)
      : undefined;
    message.ref = object.ref ?? "";
    message.refUrl = object.refUrl ?? undefined;
    message.severity = object.severity ?? Severity.UNSPECIFIED;
    message.confidence = object.confidence ?? Confidence.UNSPECIFIED;
    message.validationState = object.validationState ?? ValidationState.UNSPECIFIED;
    message.mode = object.mode ?? Mode.UNSPECIFIED;
    message.status = object.status ?? FindingStatus.UNSPECIFIED;
    message.ruleHashId = object.ruleHashId ?? "";
    message.externalTicket = (object.externalTicket !== undefined && object.externalTicket !== null)
      ? ExternalTicket.fromPartial(object.externalTicket)
      : undefined;
    message.historicalInfo = (object.historicalInfo !== undefined && object.historicalInfo !== null)
      ? HistoricalInfo.fromPartial(object.historicalInfo)
      : undefined;
    message.autotriage = (object.autotriage !== undefined && object.autotriage !== null)
      ? Autotriage.fromPartial(object.autotriage)
      : undefined;
    return message;
  },
};

function createBaseSecretsFinding_Repository(): SecretsFinding_Repository {
  return { name: "", url: "", visibility: RepositoryVisibility.UNSPECIFIED, scmType: ScmType.UNSPECIFIED };
}

export const SecretsFinding_Repository = {
  encode(message: SecretsFinding_Repository, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    if (message.visibility !== RepositoryVisibility.UNSPECIFIED) {
      writer.uint32(24).int32(repositoryVisibilityToNumber(message.visibility));
    }
    if (message.scmType !== ScmType.UNSPECIFIED) {
      writer.uint32(32).int32(scmTypeToNumber(message.scmType));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretsFinding_Repository {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretsFinding_Repository();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.visibility = repositoryVisibilityFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.scmType = scmTypeFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretsFinding_Repository {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      visibility: isSet(object.visibility)
        ? repositoryVisibilityFromJSON(object.visibility)
        : RepositoryVisibility.UNSPECIFIED,
      scmType: isSet(object.scmType) ? scmTypeFromJSON(object.scmType) : ScmType.UNSPECIFIED,
    };
  },

  toJSON(message: SecretsFinding_Repository): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.visibility !== RepositoryVisibility.UNSPECIFIED) {
      obj.visibility = repositoryVisibilityToJSON(message.visibility);
    }
    if (message.scmType !== ScmType.UNSPECIFIED) {
      obj.scmType = scmTypeToJSON(message.scmType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretsFinding_Repository>, I>>(base?: I): SecretsFinding_Repository {
    return SecretsFinding_Repository.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretsFinding_Repository>, I>>(object: I): SecretsFinding_Repository {
    const message = createBaseSecretsFinding_Repository();
    message.name = object.name ?? "";
    message.url = object.url ?? "";
    message.visibility = object.visibility ?? RepositoryVisibility.UNSPECIFIED;
    message.scmType = object.scmType ?? ScmType.UNSPECIFIED;
    return message;
  },
};

function createBaseSecretsQueryParamFilters(): SecretsQueryParamFilters {
  return {
    status: "",
    validationState: "",
    severity: "",
    confidence: "",
    type: "",
    repo: "",
    ref: "",
    mode: "",
    cursor: "",
    limit: "",
    repositoryVisibility: "",
  };
}

export const SecretsQueryParamFilters = {
  encode(message: SecretsQueryParamFilters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    if (message.validationState !== "") {
      writer.uint32(18).string(message.validationState);
    }
    if (message.severity !== "") {
      writer.uint32(26).string(message.severity);
    }
    if (message.confidence !== "") {
      writer.uint32(34).string(message.confidence);
    }
    if (message.type !== "") {
      writer.uint32(42).string(message.type);
    }
    if (message.repo !== "") {
      writer.uint32(50).string(message.repo);
    }
    if (message.ref !== "") {
      writer.uint32(58).string(message.ref);
    }
    if (message.mode !== "") {
      writer.uint32(66).string(message.mode);
    }
    if (message.cursor !== "") {
      writer.uint32(74).string(message.cursor);
    }
    if (message.limit !== "") {
      writer.uint32(82).string(message.limit);
    }
    if (message.repositoryVisibility !== "") {
      writer.uint32(90).string(message.repositoryVisibility);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretsQueryParamFilters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretsQueryParamFilters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.validationState = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.severity = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.confidence = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.type = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.repo = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ref = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.mode = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.cursor = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.limit = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.repositoryVisibility = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretsQueryParamFilters {
    return {
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      validationState: isSet(object.validationState) ? globalThis.String(object.validationState) : "",
      severity: isSet(object.severity) ? globalThis.String(object.severity) : "",
      confidence: isSet(object.confidence) ? globalThis.String(object.confidence) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      repo: isSet(object.repo) ? globalThis.String(object.repo) : "",
      ref: isSet(object.ref) ? globalThis.String(object.ref) : "",
      mode: isSet(object.mode) ? globalThis.String(object.mode) : "",
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : "",
      limit: isSet(object.limit) ? globalThis.String(object.limit) : "",
      repositoryVisibility: isSet(object.repositoryVisibility) ? globalThis.String(object.repositoryVisibility) : "",
    };
  },

  toJSON(message: SecretsQueryParamFilters): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.validationState !== "") {
      obj.validationState = message.validationState;
    }
    if (message.severity !== "") {
      obj.severity = message.severity;
    }
    if (message.confidence !== "") {
      obj.confidence = message.confidence;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.repo !== "") {
      obj.repo = message.repo;
    }
    if (message.ref !== "") {
      obj.ref = message.ref;
    }
    if (message.mode !== "") {
      obj.mode = message.mode;
    }
    if (message.cursor !== "") {
      obj.cursor = message.cursor;
    }
    if (message.limit !== "") {
      obj.limit = message.limit;
    }
    if (message.repositoryVisibility !== "") {
      obj.repositoryVisibility = message.repositoryVisibility;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretsQueryParamFilters>, I>>(base?: I): SecretsQueryParamFilters {
    return SecretsQueryParamFilters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretsQueryParamFilters>, I>>(object: I): SecretsQueryParamFilters {
    const message = createBaseSecretsQueryParamFilters();
    message.status = object.status ?? "";
    message.validationState = object.validationState ?? "";
    message.severity = object.severity ?? "";
    message.confidence = object.confidence ?? "";
    message.type = object.type ?? "";
    message.repo = object.repo ?? "";
    message.ref = object.ref ?? "";
    message.mode = object.mode ?? "";
    message.cursor = object.cursor ?? "";
    message.limit = object.limit ?? "";
    message.repositoryVisibility = object.repositoryVisibility ?? "";
    return message;
  },
};

function createBaseListSecretsRequest(): ListSecretsRequest {
  return {
    deploymentId: 0,
    cursor: undefined,
    limit: undefined,
    since: undefined,
    validationState: [],
    status: undefined,
    severity: [],
    repo: [],
  };
}

export const ListSecretsRequest = {
  encode(message: ListSecretsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.cursor !== undefined) {
      writer.uint32(18).string(message.cursor);
    }
    if (message.limit !== undefined) {
      writer.uint32(24).uint32(message.limit);
    }
    if (message.since !== undefined) {
      Timestamp.encode(toTimestamp(message.since), writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(42).fork();
    for (const v of message.validationState) {
      writer.int32(validationStateToNumber(v));
    }
    writer.ldelim();
    if (message.status !== undefined) {
      writer.uint32(48).int32(findingStatusToNumber(message.status));
    }
    writer.uint32(58).fork();
    for (const v of message.severity) {
      writer.int32(severityToNumber(v));
    }
    writer.ldelim();
    for (const v of message.repo) {
      writer.uint32(66).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListSecretsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListSecretsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cursor = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.limit = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.since = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag === 40) {
            message.validationState.push(validationStateFromJSON(reader.int32()));

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.validationState.push(validationStateFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.status = findingStatusFromJSON(reader.int32());
          continue;
        case 7:
          if (tag === 56) {
            message.severity.push(severityFromJSON(reader.int32()));

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.severity.push(severityFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.repo.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListSecretsRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : undefined,
      since: isSet(object.since) ? fromJsonTimestamp(object.since) : undefined,
      validationState: globalThis.Array.isArray(object?.validationState)
        ? object.validationState.map((e: any) => validationStateFromJSON(e))
        : [],
      status: isSet(object.status) ? findingStatusFromJSON(object.status) : undefined,
      severity: globalThis.Array.isArray(object?.severity) ? object.severity.map((e: any) => severityFromJSON(e)) : [],
      repo: globalThis.Array.isArray(object?.repo) ? object.repo.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ListSecretsRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    if (message.limit !== undefined) {
      obj.limit = Math.round(message.limit);
    }
    if (message.since !== undefined) {
      obj.since = message.since.toISOString();
    }
    if (message.validationState?.length) {
      obj.validationState = message.validationState.map((e) => validationStateToJSON(e));
    }
    if (message.status !== undefined) {
      obj.status = findingStatusToJSON(message.status);
    }
    if (message.severity?.length) {
      obj.severity = message.severity.map((e) => severityToJSON(e));
    }
    if (message.repo?.length) {
      obj.repo = message.repo;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListSecretsRequest>, I>>(base?: I): ListSecretsRequest {
    return ListSecretsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListSecretsRequest>, I>>(object: I): ListSecretsRequest {
    const message = createBaseListSecretsRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.cursor = object.cursor ?? undefined;
    message.limit = object.limit ?? undefined;
    message.since = object.since ?? undefined;
    message.validationState = object.validationState?.map((e) => e) || [];
    message.status = object.status ?? undefined;
    message.severity = object.severity?.map((e) => e) || [];
    message.repo = object.repo?.map((e) => e) || [];
    return message;
  },
};

function createBaseSecretsCountRequest(): SecretsCountRequest {
  return { deploymentId: 0 };
}

export const SecretsCountRequest = {
  encode(message: SecretsCountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretsCountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretsCountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretsCountRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0 };
  },

  toJSON(message: SecretsCountRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretsCountRequest>, I>>(base?: I): SecretsCountRequest {
    return SecretsCountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretsCountRequest>, I>>(object: I): SecretsCountRequest {
    const message = createBaseSecretsCountRequest();
    message.deploymentId = object.deploymentId ?? 0;
    return message;
  },
};

function createBaseListSecretsResponse(): ListSecretsResponse {
  return { findings: [], cursor: undefined, previous: undefined };
}

export const ListSecretsResponse = {
  encode(message: ListSecretsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.findings) {
      SecretsFinding.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.cursor !== undefined) {
      writer.uint32(18).string(message.cursor);
    }
    if (message.previous !== undefined) {
      writer.uint32(26).string(message.previous);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListSecretsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListSecretsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.findings.push(SecretsFinding.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cursor = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.previous = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListSecretsResponse {
    return {
      findings: globalThis.Array.isArray(object?.findings)
        ? object.findings.map((e: any) => SecretsFinding.fromJSON(e))
        : [],
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
      previous: isSet(object.previous) ? globalThis.String(object.previous) : undefined,
    };
  },

  toJSON(message: ListSecretsResponse): unknown {
    const obj: any = {};
    if (message.findings?.length) {
      obj.findings = message.findings.map((e) => SecretsFinding.toJSON(e));
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    if (message.previous !== undefined) {
      obj.previous = message.previous;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListSecretsResponse>, I>>(base?: I): ListSecretsResponse {
    return ListSecretsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListSecretsResponse>, I>>(object: I): ListSecretsResponse {
    const message = createBaseListSecretsResponse();
    message.findings = object.findings?.map((e) => SecretsFinding.fromPartial(e)) || [];
    message.cursor = object.cursor ?? undefined;
    message.previous = object.previous ?? undefined;
    return message;
  },
};

function createBaseSecretsCount(): SecretsCount {
  return { count: 0 };
}

export const SecretsCount = {
  encode(message: SecretsCount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.count !== 0) {
      writer.uint32(8).uint32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretsCount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretsCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.count = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretsCount {
    return { count: isSet(object.count) ? globalThis.Number(object.count) : 0 };
  },

  toJSON(message: SecretsCount): unknown {
    const obj: any = {};
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretsCount>, I>>(base?: I): SecretsCount {
    return SecretsCount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretsCount>, I>>(object: I): SecretsCount {
    const message = createBaseSecretsCount();
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseSecretsCountResponse(): SecretsCountResponse {
  return { count: 0, secretsByRepository: {} };
}

export const SecretsCountResponse = {
  encode(message: SecretsCountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.count !== 0) {
      writer.uint32(8).uint32(message.count);
    }
    Object.entries(message.secretsByRepository).forEach(([key, value]) => {
      SecretsCountResponse_SecretsByRepositoryEntry.encode({ key: key as any, value }, writer.uint32(18).fork())
        .ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretsCountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretsCountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.count = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = SecretsCountResponse_SecretsByRepositoryEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.secretsByRepository[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretsCountResponse {
    return {
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
      secretsByRepository: isObject(object.secretsByRepository)
        ? Object.entries(object.secretsByRepository).reduce<{ [key: number]: SecretsCount }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = SecretsCount.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: SecretsCountResponse): unknown {
    const obj: any = {};
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    if (message.secretsByRepository) {
      const entries = Object.entries(message.secretsByRepository);
      if (entries.length > 0) {
        obj.secretsByRepository = {};
        entries.forEach(([k, v]) => {
          obj.secretsByRepository[k] = SecretsCount.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretsCountResponse>, I>>(base?: I): SecretsCountResponse {
    return SecretsCountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretsCountResponse>, I>>(object: I): SecretsCountResponse {
    const message = createBaseSecretsCountResponse();
    message.count = object.count ?? 0;
    message.secretsByRepository = Object.entries(object.secretsByRepository ?? {}).reduce<
      { [key: number]: SecretsCount }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[globalThis.Number(key)] = SecretsCount.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseSecretsCountResponse_SecretsByRepositoryEntry(): SecretsCountResponse_SecretsByRepositoryEntry {
  return { key: 0, value: undefined };
}

export const SecretsCountResponse_SecretsByRepositoryEntry = {
  encode(message: SecretsCountResponse_SecretsByRepositoryEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).uint64(message.key);
    }
    if (message.value !== undefined) {
      SecretsCount.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretsCountResponse_SecretsByRepositoryEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretsCountResponse_SecretsByRepositoryEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = SecretsCount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretsCountResponse_SecretsByRepositoryEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? SecretsCount.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: SecretsCountResponse_SecretsByRepositoryEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== undefined) {
      obj.value = SecretsCount.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretsCountResponse_SecretsByRepositoryEntry>, I>>(
    base?: I,
  ): SecretsCountResponse_SecretsByRepositoryEntry {
    return SecretsCountResponse_SecretsByRepositoryEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretsCountResponse_SecretsByRepositoryEntry>, I>>(
    object: I,
  ): SecretsCountResponse_SecretsByRepositoryEntry {
    const message = createBaseSecretsCountResponse_SecretsByRepositoryEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? SecretsCount.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseSecretsFindingUpdateBody(): SecretsFindingUpdateBody {
  return { triageState: undefined, triageReason: undefined, note: undefined };
}

export const SecretsFindingUpdateBody = {
  encode(message: SecretsFindingUpdateBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.triageState !== undefined) {
      writer.uint32(8).int32(findingTriageStateToNumber(message.triageState));
    }
    if (message.triageReason !== undefined) {
      writer.uint32(16).int32(findingTriageReasonToNumber(message.triageReason));
    }
    if (message.note !== undefined) {
      writer.uint32(26).string(message.note);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretsFindingUpdateBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretsFindingUpdateBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.triageState = findingTriageStateFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.triageReason = findingTriageReasonFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.note = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretsFindingUpdateBody {
    return {
      triageState: isSet(object.triageState) ? findingTriageStateFromJSON(object.triageState) : undefined,
      triageReason: isSet(object.triageReason) ? findingTriageReasonFromJSON(object.triageReason) : undefined,
      note: isSet(object.note) ? globalThis.String(object.note) : undefined,
    };
  },

  toJSON(message: SecretsFindingUpdateBody): unknown {
    const obj: any = {};
    if (message.triageState !== undefined) {
      obj.triageState = findingTriageStateToJSON(message.triageState);
    }
    if (message.triageReason !== undefined) {
      obj.triageReason = findingTriageReasonToJSON(message.triageReason);
    }
    if (message.note !== undefined) {
      obj.note = message.note;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretsFindingUpdateBody>, I>>(base?: I): SecretsFindingUpdateBody {
    return SecretsFindingUpdateBody.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretsFindingUpdateBody>, I>>(object: I): SecretsFindingUpdateBody {
    const message = createBaseSecretsFindingUpdateBody();
    message.triageState = object.triageState ?? undefined;
    message.triageReason = object.triageReason ?? undefined;
    message.note = object.note ?? undefined;
    return message;
  },
};

function createBaseUpdateSecretsRequest(): UpdateSecretsRequest {
  return { deploymentId: 0, body: undefined };
}

export const UpdateSecretsRequest = {
  encode(message: UpdateSecretsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.body !== undefined) {
      SecretsFindingUpdateBody.encode(message.body, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSecretsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSecretsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.body = SecretsFindingUpdateBody.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateSecretsRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      body: isSet(object.body) ? SecretsFindingUpdateBody.fromJSON(object.body) : undefined,
    };
  },

  toJSON(message: UpdateSecretsRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.body !== undefined) {
      obj.body = SecretsFindingUpdateBody.toJSON(message.body);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateSecretsRequest>, I>>(base?: I): UpdateSecretsRequest {
    return UpdateSecretsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateSecretsRequest>, I>>(object: I): UpdateSecretsRequest {
    const message = createBaseUpdateSecretsRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.body = (object.body !== undefined && object.body !== null)
      ? SecretsFindingUpdateBody.fromPartial(object.body)
      : undefined;
    return message;
  },
};

function createBaseUpdateSecretsResponse(): UpdateSecretsResponse {
  return { numUpdated: 0, numUniqueUpdated: 0 };
}

export const UpdateSecretsResponse = {
  encode(message: UpdateSecretsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.numUpdated !== 0) {
      writer.uint32(8).uint32(message.numUpdated);
    }
    if (message.numUniqueUpdated !== 0) {
      writer.uint32(16).uint32(message.numUniqueUpdated);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSecretsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSecretsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.numUpdated = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.numUniqueUpdated = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateSecretsResponse {
    return {
      numUpdated: isSet(object.numUpdated) ? globalThis.Number(object.numUpdated) : 0,
      numUniqueUpdated: isSet(object.numUniqueUpdated) ? globalThis.Number(object.numUniqueUpdated) : 0,
    };
  },

  toJSON(message: UpdateSecretsResponse): unknown {
    const obj: any = {};
    if (message.numUpdated !== 0) {
      obj.numUpdated = Math.round(message.numUpdated);
    }
    if (message.numUniqueUpdated !== 0) {
      obj.numUniqueUpdated = Math.round(message.numUniqueUpdated);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateSecretsResponse>, I>>(base?: I): UpdateSecretsResponse {
    return UpdateSecretsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateSecretsResponse>, I>>(object: I): UpdateSecretsResponse {
    const message = createBaseUpdateSecretsResponse();
    message.numUpdated = object.numUpdated ?? 0;
    message.numUniqueUpdated = object.numUniqueUpdated ?? 0;
    return message;
  },
};

function createBaseSecretsFindingRequest(): SecretsFindingRequest {
  return { deploymentId: 0, id: 0 };
}

export const SecretsFindingRequest = {
  encode(message: SecretsFindingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.id !== 0) {
      writer.uint32(16).uint64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretsFindingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretsFindingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretsFindingRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
    };
  },

  toJSON(message: SecretsFindingRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretsFindingRequest>, I>>(base?: I): SecretsFindingRequest {
    return SecretsFindingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretsFindingRequest>, I>>(object: I): SecretsFindingRequest {
    const message = createBaseSecretsFindingRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseSecretsFindingResponse(): SecretsFindingResponse {
  return { finding: undefined };
}

export const SecretsFindingResponse = {
  encode(message: SecretsFindingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.finding !== undefined) {
      SecretsFinding.encode(message.finding, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretsFindingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretsFindingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.finding = SecretsFinding.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretsFindingResponse {
    return { finding: isSet(object.finding) ? SecretsFinding.fromJSON(object.finding) : undefined };
  },

  toJSON(message: SecretsFindingResponse): unknown {
    const obj: any = {};
    if (message.finding !== undefined) {
      obj.finding = SecretsFinding.toJSON(message.finding);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretsFindingResponse>, I>>(base?: I): SecretsFindingResponse {
    return SecretsFindingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretsFindingResponse>, I>>(object: I): SecretsFindingResponse {
    const message = createBaseSecretsFindingResponse();
    message.finding = (object.finding !== undefined && object.finding !== null)
      ? SecretsFinding.fromPartial(object.finding)
      : undefined;
    return message;
  },
};

function createBaseSecretsFilters(): SecretsFilters {
  return { repos: [], refs: [], providers: [] };
}

export const SecretsFilters = {
  encode(message: SecretsFilters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.repos) {
      FilterItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.refs) {
      FilterItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.providers) {
      FilterItem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretsFilters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretsFilters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.repos.push(FilterItem.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.refs.push(FilterItem.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.providers.push(FilterItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretsFilters {
    return {
      repos: globalThis.Array.isArray(object?.repos) ? object.repos.map((e: any) => FilterItem.fromJSON(e)) : [],
      refs: globalThis.Array.isArray(object?.refs) ? object.refs.map((e: any) => FilterItem.fromJSON(e)) : [],
      providers: globalThis.Array.isArray(object?.providers)
        ? object.providers.map((e: any) => FilterItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SecretsFilters): unknown {
    const obj: any = {};
    if (message.repos?.length) {
      obj.repos = message.repos.map((e) => FilterItem.toJSON(e));
    }
    if (message.refs?.length) {
      obj.refs = message.refs.map((e) => FilterItem.toJSON(e));
    }
    if (message.providers?.length) {
      obj.providers = message.providers.map((e) => FilterItem.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretsFilters>, I>>(base?: I): SecretsFilters {
    return SecretsFilters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretsFilters>, I>>(object: I): SecretsFilters {
    const message = createBaseSecretsFilters();
    message.repos = object.repos?.map((e) => FilterItem.fromPartial(e)) || [];
    message.refs = object.refs?.map((e) => FilterItem.fromPartial(e)) || [];
    message.providers = object.providers?.map((e) => FilterItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSecretsFiltersRequest(): SecretsFiltersRequest {
  return { deploymentId: 0 };
}

export const SecretsFiltersRequest = {
  encode(message: SecretsFiltersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretsFiltersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretsFiltersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretsFiltersRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0 };
  },

  toJSON(message: SecretsFiltersRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretsFiltersRequest>, I>>(base?: I): SecretsFiltersRequest {
    return SecretsFiltersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretsFiltersRequest>, I>>(object: I): SecretsFiltersRequest {
    const message = createBaseSecretsFiltersRequest();
    message.deploymentId = object.deploymentId ?? 0;
    return message;
  },
};

function createBaseSecretsFiltersResponse(): SecretsFiltersResponse {
  return { filters: undefined };
}

export const SecretsFiltersResponse = {
  encode(message: SecretsFiltersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.filters !== undefined) {
      SecretsFilters.encode(message.filters, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretsFiltersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretsFiltersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.filters = SecretsFilters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretsFiltersResponse {
    return { filters: isSet(object.filters) ? SecretsFilters.fromJSON(object.filters) : undefined };
  },

  toJSON(message: SecretsFiltersResponse): unknown {
    const obj: any = {};
    if (message.filters !== undefined) {
      obj.filters = SecretsFilters.toJSON(message.filters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretsFiltersResponse>, I>>(base?: I): SecretsFiltersResponse {
    return SecretsFiltersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretsFiltersResponse>, I>>(object: I): SecretsFiltersResponse {
    const message = createBaseSecretsFiltersResponse();
    message.filters = (object.filters !== undefined && object.filters !== null)
      ? SecretsFilters.fromPartial(object.filters)
      : undefined;
    return message;
  },
};

export interface SecretsService {
  listSecrets(request: ListSecretsRequest): Promise<ListSecretsResponse>;
  secretsCount(request: SecretsCountRequest): Promise<SecretsCountResponse>;
  secretsFilters(request: SecretsFiltersRequest): Promise<SecretsFiltersResponse>;
  updateSecrets(request: UpdateSecretsRequest): Promise<UpdateSecretsResponse>;
  secretsFinding(request: SecretsFindingRequest): Promise<SecretsFindingResponse>;
}

export const SecretsServiceServiceName = "protos.secrets.v1.SecretsService";
export class SecretsServiceClientImpl implements SecretsService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || SecretsServiceServiceName;
    this.rpc = rpc;
    this.listSecrets = this.listSecrets.bind(this);
    this.secretsCount = this.secretsCount.bind(this);
    this.secretsFilters = this.secretsFilters.bind(this);
    this.updateSecrets = this.updateSecrets.bind(this);
    this.secretsFinding = this.secretsFinding.bind(this);
  }
  listSecrets(request: ListSecretsRequest): Promise<ListSecretsResponse> {
    const data = ListSecretsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListSecrets", data);
    return promise.then((data) => ListSecretsResponse.decode(_m0.Reader.create(data)));
  }

  secretsCount(request: SecretsCountRequest): Promise<SecretsCountResponse> {
    const data = SecretsCountRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "SecretsCount", data);
    return promise.then((data) => SecretsCountResponse.decode(_m0.Reader.create(data)));
  }

  secretsFilters(request: SecretsFiltersRequest): Promise<SecretsFiltersResponse> {
    const data = SecretsFiltersRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "SecretsFilters", data);
    return promise.then((data) => SecretsFiltersResponse.decode(_m0.Reader.create(data)));
  }

  updateSecrets(request: UpdateSecretsRequest): Promise<UpdateSecretsResponse> {
    const data = UpdateSecretsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "UpdateSecrets", data);
    return promise.then((data) => UpdateSecretsResponse.decode(_m0.Reader.create(data)));
  }

  secretsFinding(request: SecretsFindingRequest): Promise<SecretsFindingResponse> {
    const data = SecretsFindingRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "SecretsFinding", data);
    return promise.then((data) => SecretsFindingResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
