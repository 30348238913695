import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import yaml from "yaml";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";

import { run as postRun } from "@shared/api/lib/editor";
import {
  postRule,
  postRuleset,
  putRule,
  RulePostParams,
  RulesetPostParams,
} from "@shared/api/lib/registry";
import { DEFAULT_LANG } from "@shared/constants";
import {
  ApiError,
  Language,
  RegistryRule,
  RegistryRuleMetadata,
  RegistryTaintRule,
  RunResponse,
  SimpleRule,
  Visibility,
} from "@shared/types";
import { DEFAULT_RULE, ID_BY_KEY } from "@shared/utils";

import { Rule } from "../components/StructureMode/types/rule";
import {
  fromYAML,
  InvalidYAML,
  UnsupportedRule,
} from "../components/StructureMode/utils/fromYAML";
import { SwitchToStructureAlertError } from "../components/StructureMode/utils/SwitchToStructureAlert";
import { toYAML } from "../components/StructureMode/utils/toYAML";
import { RuleValidationError } from "../types/ruleValidationError";
import { setLocalStorageBundle } from "../utils/localStorageBundle";
import { ruleFromYAML } from "../utils/parseYaml";
import { yamlFromRule } from "../utils/serializeYaml";

import { ErrorResult, SuccessResult } from "./Result";
import { Workbench } from "./Workbench";

type DisplayProps = {
  lastChangeAt?: string;
  lastChangeBy?: string;
  sourceUri?: string;
};

export class Bundle {
  workbench: Workbench;
  ruleText: string;
  targetText: string;
  showingAutofixDiff?: string;
  visibility: Visibility;
  deploymentName?: string;
  isRunning: boolean = false;
  displayProps: DisplayProps;
  isDeep: boolean;
  hashId?: string;
  structureRule?: Rule;
  // Buffers which hold "snapshots" of each past and future state of the rule text.
  // This enables us to Ctrl-Z and Ctrl-Shift-Z to go forward and backward in the
  // history of the Structure Rule.
  // Since Structure Mode is really just a view into the rule text, we can use these
  // in a 1-1 correspondence to the state of the structure rule over time, for the
  // most part.
  // INVARIANT: undoBuffer.length >= 1
  // INVARIANT: undoBuffer[undoBuffer.length - 1] === ruleText
  undoBuffer: string[];
  redoBuffer: string[];

  /**
   * Last title is used to keep track of the last valid rule_id for the UI.
   *
   * Note: This parameter is necessary because `rule` is null anytime the
   * current `ruleText` is unparseable. In that sense, we keep track of the last
   * _valid_ ID so that we can display that as the editor title.
   */
  lastTitle: string = "Unknown Rule";

  constructor(
    workbench: Workbench,
    ruleText: string,
    targetText: string,
    visibility: Visibility,
    isDeep: boolean,
    hashId?: string,
    deploymentName?: string,
    displayProps?: DisplayProps
  ) {
    makeAutoObservable(this, { workbench: false }, { autoBind: true });

    /**
     * Anytime the ruletext is updated, update the last valid title for the rule
     * with a debounce due to expensive YAML parsing.
     */
    reaction(() => this.ruleText, debounce(this.updateLastTitle, 100));

    this.workbench = workbench;
    this.ruleText = ruleText;
    this.undoBuffer = [ruleText];
    this.redoBuffer = [];
    this.targetText = targetText;
    this.visibility = visibility;
    this.isDeep = isDeep;
    this.hashId = hashId;
    this.deploymentName = deploymentName;
    this.displayProps = displayProps || {};
    this.updateLastTitle();
  }

  setRuleText(value: string) {
    if (this.workbench.isPlayground) {
      setLocalStorageBundle({
        rule: value,
        target: this.targetText,
      });
    }

    this.ruleText = value;
  }

  updateLastTitle() {
    if (this.rule) {
      this.lastTitle = this.rule.id;
    }
  }

  setTargetText(value: string) {
    if (this.workbench.isPlayground) {
      setLocalStorageBundle({
        rule: this.ruleText,
        target: value,
      });
    }
    this.targetText = value;
  }

  setShowingAutofixDiff(value: string | undefined) {
    this.showingAutofixDiff = value;
    this.workbench.editors.focusDiffTargetLine(value);
  }

  setRuleId(value: string) {
    if (this.rule) {
      const ruleCopy = cloneDeep(this.rule);
      ruleCopy.id = value;
      this.ruleText = yaml.stringify({ rules: [ruleCopy] });
    }
  }

  setRuleMetadata(value: RegistryRuleMetadata) {
    if (this.rule) {
      const ruleCopy = cloneDeep(this.rule);
      ruleCopy.metadata = value;
      this.ruleText = yaml.stringify({ rules: [ruleCopy] });
    }
  }

  setRuleMessage(value: string) {
    if (this.rule) {
      const ruleCopy = cloneDeep(this.rule);
      ruleCopy.message = value;
      this.ruleText = yaml.stringify({ rules: [ruleCopy] });
    }
  }

  setRuleLanguages(value: string[]) {
    if (this.rule) {
      const ruleCopy = cloneDeep(this.rule);
      ruleCopy.languages = value;
      this.ruleText = yaml.stringify({ rules: [ruleCopy] });
    }
  }

  get rule(): RegistryRule | RegistryTaintRule | null {
    const ruleText = this.structureRule
      ? toYAML(this.structureRule)
      : this.ruleText;
    try {
      const rule = yaml.parse(ruleText);
      if (
        typeof rule === "object" &&
        "rules" in rule &&
        Array.isArray(rule.rules)
      ) {
        return rule.rules[0] as RegistryRule | RegistryTaintRule;
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  /**
   * Returns true if the rule has a single rule ID (or 0), false if it has multiple.
   */
  get ruleHasOneId(): boolean | null {
    try {
      const rule = yaml.parse(this.ruleText);
      return rule.rules.length <= 1;
    } catch (e) {
      return null;
    }
  }

  /*
   * @param originalId - the original rule id, to check if the user changed the id
   * @returns the rule text as a string, for use when forking a bundle.
   * If the rule can be parsed and the current hasn't been edited, the rule id will
   * be changed to end in `-copy.
   * Otherwise, the rule text will be returned as-is.
   * Will include multiple rule ids (which is invalid, but we don't want to
   * erase the user's work).
   */
  getForkedBundle(originalId?: string): string {
    // we can only set the new id if the rule parses
    if (!this.rule) return this.ruleText;

    const forkedRuleId =
      this.rule.id === originalId ? `${this.rule.id}-copy` : this.rule.id;
    const forkedRule = {
      ...this.rule,
      id: forkedRuleId,
    };
    // if the user wrote another rule in the editor, we shouldn't discard
    // those changes. the user will see a warning if they try to save or run
    const fullRule = yaml.parse(this.ruleText);
    const allRules = fullRule.rules ?? [{}];

    const forkedRules = [forkedRule, ...allRules.slice(1)];
    return yaml.stringify({ rules: forkedRules });
  }

  getSimpleRuleInfo():
    | { simpleRule: SimpleRule | undefined; lossless: boolean }
    | undefined {
    if (this.validate().length > 0) {
      return undefined;
    }

    const [simpleRule, lossless] = ruleFromYAML(
      this.ruleText,
      DEFAULT_RULE(this.language ?? DEFAULT_LANG)
    );
    if (simpleRule) {
      return { simpleRule, lossless };
    } else {
      return undefined;
    }
  }

  get simpleRule(): SimpleRule | undefined {
    try {
      return this.getSimpleRuleInfo()?.simpleRule;
    } catch (e) {
      return undefined;
    }
  }

  // returns `null` in the case that you _can_ get a structure rule from the text
  // otherwise, returns an enum describing the reason why you cannot
  get getTextToStructureRuleErrors(): SwitchToStructureAlertError | null {
    try {
      // fromYAML and not structureRuleFromText, which triggers a rerender
      // and could possibly make rendering loop
      fromYAML(this.ruleText);
      return null;
    } catch (e) {
      if (e instanceof UnsupportedRule) {
        return { kind: "unsupported", reason: e.reason };
      } else if (e instanceof InvalidYAML) {
        return { kind: "invalid", reason: e.reason };
      } else {
        return { kind: "invalid", reason: "general error" };
      }
    }
  }

  // This function will side-effectively sync the structure rule and
  // actual rule text to the provided rule text, or the currently
  // saved rule text if one is not provided.
  syncStructureRuleFromText(ruleText: string | null = null): Rule {
    const text = ruleText ?? this.ruleText;
    const newRule = fromYAML(text);
    runInAction(() => {
      this.ruleText = text;
      this.structureRule = newRule;
      setLocalStorageBundle({
        rule: this.ruleText,
        target: this.targetText,
      });
    });
    return newRule;
  }

  onStructureRuleChange() {
    runInAction(() => {
      if (!this.structureRule) return;
      this.ruleText = toYAML(this.structureRule);
      // when the user makes a new change, we push the new frame
      // to our saved history, and clear the future frames
      this.undoBuffer.push(this.ruleText);
      this.redoBuffer = [];
      // save in local storage
      setLocalStorageBundle({
        rule: this.ruleText,
        target: this.targetText,
      });
    });
  }

  onRuleUndo(): void {
    runInAction(() => {
      // on an undo, we must reset to the second-to-last frame, if existent
      if (this.undoBuffer.length <= 1) return;
      const currentRule = this.undoBuffer.pop();
      if (currentRule) {
        this.redoBuffer.push(currentRule);
        const lastRule = this.undoBuffer[this.undoBuffer.length - 1];
        this.ruleText = lastRule;
        // for side effect
        this.syncStructureRuleFromText();
      }
    });
  }

  onRuleRedo(): void {
    runInAction(() => {
      const futureRule = this.redoBuffer.pop();
      if (futureRule !== undefined) {
        this.undoBuffer.push(futureRule);
        this.ruleText = futureRule;
        // for side effect
        this.syncStructureRuleFromText();
      }
    });
  }

  onSimpleRuleChange(newRule: SimpleRule) {
    runInAction(() => {
      this.ruleText = yamlFromRule(newRule);
      setLocalStorageBundle({
        rule: this.ruleText,
        target: this.targetText,
      });
    });
  }

  get language(): Language | null {
    try {
      if (this.rule === null) return null;
      return ID_BY_KEY[this.rule.languages[0].toLowerCase()];
    } catch (e) {
      return null;
    }
  }

  get isSecretsRule(): boolean | null {
    if (this.rule === null) return null;
    return (
      this.rule.metadata?.product === "secrets" ||
      this.rule.metadata?.secret_type !== undefined ||
      this.rule.validators !== undefined
    );
  }

  /**
   * @returns true if the rule has all the required fields to be published
   * see https://semgrep.dev/docs/contributing/contributing-to-semgrep-rules-repository/#semgrep-registry-rule-requirements
   */
  get hasRequiredFieldsForPublish(): boolean {
    if (this.rule === null) return false;

    const hasSecurityRequiredFields =
      !!this.rule.metadata?.cwe &&
      this.rule.metadata.impact !== undefined &&
      this.rule.metadata.confidence !== undefined &&
      this.rule.metadata.likelihood !== undefined &&
      !!this.rule.metadata.subcategory?.length;

    return (
      !!this.rule.metadata?.technology?.length &&
      this.rule.metadata.category !== undefined &&
      !!this.rule.metadata.references?.length &&
      // if the category is security, there are further requirements
      (this.rule.metadata.category !== "security" || hasSecurityRequiredFields)
    );
  }

  setLanguage(language: Language) {
    if (this.rule === null) return;
    const ruleCopy = cloneDeep(this.rule);
    ruleCopy.languages = [language];
    this.ruleText = yaml.stringify({ rules: [ruleCopy] });
  }

  clone(): Bundle {
    return new Bundle(
      this.workbench,
      this.ruleText,
      this.targetText,
      this.visibility,
      this.isDeep,
      undefined, // hashId from backend on save
      this.deploymentName,
      {
        lastChangeAt: this.displayProps.lastChangeAt || undefined,
        lastChangeBy: this.displayProps.lastChangeBy || undefined,
        sourceUri: this.displayProps.sourceUri,
      }
    );
  }

  isEqualTo(other: Bundle | null): boolean {
    return (
      this.ruleText === other?.ruleText && this.targetText === other?.targetText
    );
  }

  /**
   * The line numbers tagged by the match string (found in comments preceding the line).
   */
  findTaggedLines(matchRegExp: string): number[] {
    const tuples: [string, number][] = this.targetText
      .split("\n")
      // we use `index + 2` because:
      // a) line numbering starts from 1, not 0
      // b) a match is expected on the following line compared to where the ruleid comment is
      .map((line, index) => [line, index + 2]);

    return (
      tuples
        // nosemgrep: javascript.lang.security.audit.detect-non-literal-regexp.detect-non-literal-regexp
        .filter(([line, _]) => (line as string).match(new RegExp(matchRegExp)))
        .map(([_, lineno]) => lineno)
    );
  }

  /**
   * The line numbers on which the rule should match according to test comments.
   */
  get expectedMatches(): number[] {
    return this.rule
      ? this.findTaggedLines(`[^a-zA-Z]ruleid: ?${this.rule.id}`)
      : [];
  }

  /**
   * The line numbers on which the rule should not match according to test comments.
   */
  get expectedNotMatches(): number[] {
    return this.rule
      ? this.findTaggedLines(`[^a-zA-Z]ok: ?${this.rule.id}`)
      : [];
  }

  /**
   * Saves an unnamed snippet (ie permalink) with visibility "unlisted"
   * Returns validation errors, if found, or snippetId (on success)
   * Throws error on API error.
   */
  async saveSnippet(): Promise<string> {
    const errors = this.validate();
    const saveMetadata: RulePostParams = {
      definition: { rules: [this.rule] },
      language: this.language!,
      test_target: this.targetText,
      visibility: "unlisted",
      // no deployment_id (see COD-742)
    };
    if (errors && errors.length > 0) {
      datadogRum.addAction("Save Snippet", {
        ...saveMetadata,
        rule: this.rule,
        message: "Snippet save failed due to validation errors.",
        errors: errors,
      });

      throw new ErrorResult(this, errors);
    }

    const handleSaveAPIError = (err: ApiError) => {
      const message =
        err.statusCode >= 400 && err.statusCode < 500
          ? "Saving failed: validation error (try running the rule)"
          : "Saving failed: critical error (try later)";

      datadogRum.addAction("Save Snippet", {
        ...saveMetadata,
        rule: this.rule,
        errors: [err],
        message: "Snippet save failed due to API errors.",
      });

      throw new Error(message);
    };

    const { id: savedId } = await postRule(saveMetadata).catch(
      handleSaveAPIError
    );

    datadogRum.addAction("Save Snippet", {
      ...saveMetadata,
      rule: this.rule,
      savedId: savedId,
      message: "Snippet save succeeded.",
    });

    return savedId;
  }

  /**
   * Legacy save method for named snippets, should only be used for updating existing snippets.
   * Overwrites existing snippets if they exist.
   * Returns validation errors, if found.
   * Throws error on API error.
   */
  async saveBySnippetName(
    snippetFullName: string
  ): Promise<ErrorResult | undefined> {
    const errors = this.validate();
    const saveMetadata: RulePostParams = {
      definition: { rules: [this.rule] },
      language: this.language!,
      test_target: this.targetText,
      visibility: this.visibility,
      deployment_id: this.workbench.org?.id,
    };

    if (errors && errors.length > 0) {
      datadogRum.addAction("Save Rule", {
        ...saveMetadata,
        rule: this.rule,
        snippetName: snippetFullName,
        message: "Rule save failed due to validation errors.",
        errors: errors,
      });

      return new ErrorResult(this, errors);
    }

    const handleSaveAPIError = (err: ApiError) => {
      const serverMessage = err.body;
      const message =
        "Saving failed: " +
        (typeof serverMessage?.error === "string"
          ? serverMessage?.error
          : err.statusCode >= 400 && err.statusCode < 500
          ? "validation error (try running the rule)"
          : "critical error (try later)");

      datadogRum.addAction("Save Rule", {
        ...saveMetadata,
        rule: this.rule,
        snippetName: snippetFullName,
        errors: [err],
        message: "Rule save failed due to API errors.",
      });

      throw new Error(message);
    };

    // TODO: Fix this disco coding. There are many assumptions here that
    // don't always hold up. Like the snippet formatting/always saving rulesets.
    const { id: savedId } = await postRule(saveMetadata).catch(
      handleSaveAPIError
    );

    let rulesetData: RulesetPostParams = {
      rules: [{ id: savedId }],
    };
    if (saveMetadata.deployment_id) {
      rulesetData = {
        ...rulesetData,
        deployment_id: saveMetadata.deployment_id,
      };
    }

    await postRuleset(snippetFullName, rulesetData).catch(handleSaveAPIError);

    datadogRum.addAction("Save Rule", {
      ...saveMetadata,
      rule: this.rule,
      snippetName: snippetFullName,
      savedId: savedId,
      message: "Rule save succeeded.",
    });

    return undefined;
  }

  /**
   * Save the rule to the backend, and create a fitting data store locally.
   * If a rule with this id already exists, it will be overwritten.
   * Returns validation errors, if found.
   * Throws error on API error.
   */

  async saveNew(): Promise<{ id: string; path: string }> {
    const errors = this.validate();
    const saveMetadata: RulePostParams = {
      definition: { rules: [this.rule] },
      language: this.language!,
      test_target: this.targetText,
      visibility: this.visibility,
      deployment_id: this.workbench.org?.id,
    };

    if (errors && errors.length > 0) {
      datadogRum.addAction("Save Rule", {
        ...saveMetadata,
        rule: this.rule,
        rule_id: this.rule?.id,
        message: "Rule save failed due to validation errors.",
        errors: errors,
      });

      throw new ErrorResult(this, errors);
    }

    const handleSaveAPIError = (err: ApiError) => {
      const serverMessage = err.body;
      const message =
        "Saving failed: " +
        (typeof serverMessage?.error === "string"
          ? serverMessage?.error
          : err.statusCode >= 400 && err.statusCode < 500
          ? "validation error (try running the rule)"
          : "critical error (try later)");

      datadogRum.addAction("Save Rule", {
        ...saveMetadata,
        rule: this.rule,
        rule_id: this.rule?.id,
        errors: [err],
        message: "Rule save failed due to API errors.",
      });

      throw new Error(message);
    };

    const {
      meta: {
        rule: { rule_id: savedId },
      },
      path: savedPath,
    } = await postRule(saveMetadata).catch(handleSaveAPIError);

    datadogRum.addAction("Save Rule", {
      ...saveMetadata,
      rule: this.rule,
      savedPath: savedPath,
      savedId: savedId,
      message: "Rule save succeeded.",
    });

    return { id: savedId, path: savedPath || this.rule?.id || "-" };
  }

  /**
   * Save the rule to the backend, and create a fitting data store locally.
   * If a rule with this id already exists, it will be overwritten.
   * Returns validation errors, if found.
   * Throws error on API error.
   */
  async saveById(id: string): Promise<ErrorResult | undefined> {
    const errors = this.validate();
    const saveMetadata: RulePostParams = {
      definition: { rules: [this.rule] },
      language: this.language!,
      test_target: this.targetText,
      visibility: this.visibility,
      deployment_id: this.workbench.org?.id,
    };

    if (errors && errors.length > 0) {
      datadogRum.addAction("Save Rule", {
        ...saveMetadata,
        rule: this.rule,
        id: id,
        message: "Rule save failed due to validation errors.",
        errors: errors,
      });

      return new ErrorResult(this, errors);
    }

    const handleSaveAPIError = (err: ApiError) => {
      const serverMessage = err.body;
      const message =
        "Saving failed: " +
        (typeof serverMessage?.error === "string"
          ? serverMessage?.error
          : err.statusCode >= 400 && err.statusCode < 500
          ? "validation error (try running the rule)"
          : "critical error (try later)");

      datadogRum.addAction("Save Rule", {
        ...saveMetadata,
        rule: this.rule,
        id: id,
        errors: [err],
        message: "Rule save failed due to API errors.",
      });

      throw new Error(message);
    };

    const { id: savedId } = await putRule(id, saveMetadata).catch(
      handleSaveAPIError
    );

    datadogRum.addAction("Save Rule", {
      ...saveMetadata,
      rule: this.rule,
      id: savedId,
      message: "Rule save succeeded.",
    });

    return undefined;
  }

  validate(): RuleValidationError[] {
    let errors: RuleValidationError[] = [];
    try {
      errors = this.workbench.editors.validateRule();
    } catch (err) {
      console.error(err);
    }

    if (!this.ruleHasOneId) {
      errors.push({
        message: "Rule must have only one rule ID",
        severity: "Error",
      });
    }
    return errors;
  }

  async run() {
    if (!this.rule) {
      return;
    }
    runInAction(() => {
      this.isRunning = true;
    });

    // If we are in structure mode, we need to sync the rule, or we will
    // run an outdated rule's text!
    if (this.structureRule) {
      this.onStructureRuleChange();
    }

    const { org, history } = this.workbench;
    const urlParams = new URLSearchParams(history.location.search);
    const isDevelop = urlParams.get("develop") === "true";
    // org is undefined if user is logged out
    const snippetFullName = org ? `${org.name}:${this.rule.id}` : this.rule.id;

    const actionMetadata = {
      snippetName: snippetFullName,
      definition: { rules: [this.rule] },
      rule: this.rule,
      language: this.language!,
      test_target: this.targetText,
      visibility: this.visibility,
      deep: this.isDeep,
      turbo: this.workbench.turboModeEnabled,
      mode: this.workbench.editors.ruleEditorMode,
    };

    const errors = this.validate();
    if (errors && errors.length > 0) {
      runInAction(() => {
        this.isRunning = false;
        this.workbench.resultsHistory.push(new ErrorResult(this, errors));
      });

      datadogRum.addAction("Run Rule", {
        ...actionMetadata,
        message: "Rule run failed due to validation errors.",
        errors: errors,
      });

      return;
    }

    let successResult: SuccessResult;
    const sentryTags = {
      turboMode: !!(
        this.workbench.turboModeEnabled && this.workbench.turboMode
      ),
      initialized: true,
      language: this.rule.languages[0],
    };

    if (this.workbench.turboModeEnabled && this.workbench.turboMode) {
      let response: RunResponse;
      try {
        response = await this.workbench.turboMode.run();
      } catch (err: any) {
        runInAction(() => {
          this.isRunning = false;
          this.workbench.resultsHistory.push(
            new ErrorResult(this, [{ message: err.message, severity: "Error" }])
          );
        });
        Sentry.captureException(err, {
          tags: sentryTags,
        });

        datadogRum.addAction("Run Rule", {
          ...actionMetadata,
          turboMode: true,
          message: "Rule run failed due to unknown errors.",
          errors: [err],
        });
        return;
      }

      successResult = new SuccessResult(this, response);
    } else {
      // record run time in sentry
      const transaction = Sentry.startTransaction({ name: "editor" });
      let span;
      if (transaction) {
        span = transaction.startChild({ op: "postRun" });
      } else {
        Sentry.captureException("Failed to record transaction for editor", {
          tags: sentryTags,
        });
      }
      let ossResponse: RunResponse;
      let proResponse: RunResponse;
      try {
        const ossRunPromise = postRun(
          {
            pattern: yaml.stringify(yaml.parse(this.ruleText)),
            // language will not be null because validate() has been run
            language: ID_BY_KEY[this.language!.toLowerCase()],
            target: this.targetText,
          },
          true,
          {
            is_develop: isDevelop,
            is_deep: false,
            is_debug: true,
            deployment_id: org?.id,
          }
        );
        const proRunPromise = postRun(
          {
            pattern: yaml.stringify(yaml.parse(this.ruleText)),
            // language will not be null because validate() has been run
            language: ID_BY_KEY[this.language!.toLowerCase()],
            target: this.targetText,
          },
          true,
          {
            is_develop: isDevelop,
            is_deep: true,
            is_debug: true,
            deployment_id: org?.id,
          }
        );
        [ossResponse, proResponse] = await Promise.all([
          ossRunPromise,
          proRunPromise,
        ]);
      } catch (err) {
        if (err instanceof ApiError) {
          runInAction(() => {
            this.isRunning = false;
            this.workbench.resultsHistory.push(
              ErrorResult.fromApiResult(this, err as ApiError)
            );
          });

          datadogRum.addAction("Run Rule", {
            ...actionMetadata,
            turboMode: false,
            message: "Rule run failed due to API errors.",
            errors: [err],
          });
        } else {
          Sentry.captureException(err, { tags: sentryTags });

          datadogRum.addAction("Run Rule", {
            ...actionMetadata,
            turboMode: false,
            message: "Rule run failed due to unknown errors.",
            errors: [err],
          });

          console.error(err);
        }
        return;
      } finally {
        if (span) span.finish();
        if (transaction) transaction.finish();
      }

      successResult = new SuccessResult(this, proResponse, ossResponse);
    }

    if (successResult.errors.length > 0) {
      datadogRum.addAction("Run Rule", {
        ...actionMetadata,
        message: "Rule run failed due to runtime errors.",
        errors: successResult.response.semgrep_result.output.errors,
      });
    } else {
      datadogRum.addAction("Run Rule", {
        ...actionMetadata,
        message: "Rule run succeeded.",
      });
    }

    runInAction(() => {
      this.isRunning = false;
      this.workbench.resultsHistory.push(successResult);
      if (
        successResult.response.ai_autofixes.length > 0 &&
        this.workbench.bundle
      ) {
        this.workbench.bundle.setShowingAutofixDiff(
          successResult.response.ai_autofixes[0].fix_code
        );
      }
    });
  }
}

export type ReadonlyBundle = Bundle & {
  ruleText: Readonly<string>;
  targetText: Readonly<string>;
};
