import { MouseEventHandler } from "react";
import { observer } from "mobx-react-lite";
import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { faPlus, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Group, Switch, Tooltip } from "@mantine/core";
import { useHover } from "@mantine/hooks";

import { Bundle } from "../../stores/Bundle";

import { emptyPattern, InArray, Pattern } from "./types/rule";
import { FadedComponent } from "./utils/FadedComponent";
import {
  addIntoArray,
  disablePattern,
  newConstraint,
  removeFromInArray,
} from "./utils/utils";

interface FilterButtonProps {
  onFilter: MouseEventHandler<HTMLButtonElement>;
  isFocused: boolean;
  pattern: Pattern;
}

/**
 * A filter button that is used to add a filter constraint to a given component of
 * the structure mode rule.
 *
 * @param onFilter - the action to take when the button is clicked
 * @param isFocused - whether the button is in a button array that is being focused
 * @param pattern - the pattern to apply the constraint to
 */
const FilterButton: React.FC<FilterButtonProps> = observer(
  ({ onFilter, isFocused, pattern }) => {
    const { ref } = useHover();

    const isDisabled =
      pattern.value.constraint !== null && pattern.value.constraint.length > 0;
    const addButtonText = isDisabled
      ? "Pattern already has a constraint"
      : "Add constraint to pattern";

    return (
      <Tooltip label={addButtonText} openDelay={350}>
        <div ref={ref}>
          <FadedComponent isFocused={isFocused}>
            <ActionIcon
              variant="subtle"
              color="black"
              onClick={onFilter}
              disabled={isDisabled}
            >
              <FontAwesomeIcon icon={faFilter} />
            </ActionIcon>
          </FadedComponent>
        </div>
      </Tooltip>
    );
  }
);

interface PlusButtonProps {
  onPlus: MouseEventHandler<HTMLButtonElement>;
  isFocused: boolean;
}

/**
 * A "+" button that is used to add a sibling to a given component of a structure mode
 * rule.
 *
 * @param onAdd - the action to take when the button is clicked
 * @param isFocused - whether the button is in a button array that is being focused
 */
const PlusButton: React.FC<PlusButtonProps> = observer(
  ({ onPlus, isFocused }) => {
    const { ref } = useHover();

    const addButtonText = "Add sibling";

    return (
      <Tooltip label={addButtonText} openDelay={350}>
        <div ref={ref}>
          <FadedComponent isFocused={isFocused}>
            <ActionIcon variant="subtle" color="black" onMouseDown={onPlus}>
              <FontAwesomeIcon icon={faPlus} />
            </ActionIcon>
          </FadedComponent>
        </div>
      </Tooltip>
    );
  }
);

interface DeleteButtonProps {
  onDelete: MouseEventHandler<HTMLButtonElement>;
  isFocused: boolean;
  disabled: boolean;
  thingToDelete: string;
}

/**
 * An "X" button that is used to remove a given component of a structure mode
 * rule.
 * It is capable of being disabled under certain conditions, and glows red
 * when hovered.
 *
 * @param onDelete - the action to take when the button is clicked
 * @param isFocused - whether the button is in a button array that is being focused
 * @param disabled - whether the button should be disabled
 * @param thingToDelete - the name of the thing that this delete button would delete
 */
const DeleteButton: React.FC<DeleteButtonProps> = observer(
  ({ onDelete, isFocused, disabled, thingToDelete }) => {
    const { hovered, ref } = useHover();

    const deleteButtonText = disabled
      ? `Cannot delete ${thingToDelete} (parent must have at least one child)`
      : `Delete ${thingToDelete}`;

    return (
      <Tooltip label={deleteButtonText} openDelay={350}>
        <div ref={ref}>
          {
            <FadedComponent isFocused={isFocused}>
              <ActionIcon
                variant="subtle"
                color="black"
                disabled={disabled}
                onMouseDown={onDelete}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  color={!disabled && hovered ? "red" : undefined}
                />
              </ActionIcon>
            </FadedComponent>
          }
        </div>
      </Tooltip>
    );
  }
);

/**
 * A pair of plus and minus buttons, which indicate whether to add or remove
 * an item from an array of items, in a structure mode rule.
 *
 * @param onPlus - the action to take when an item is added
 * @param onDelete - the action to take when an item is removed
 * @param isFocused - whether the button is in a button array that is being focused
 * @param containingArray - the array of items that these buttons are in
 * @param thingToDelete - the name of the thing that this delete button would delete
 * @param allowZero - whether the array should be allowed to have zero items in it
 */
export const PlusDeleteButtons: React.FC<{
  onPlus: () => void | null;
  onDelete: () => void | null;
  isFocused: boolean;
  containingArray: any[] | undefined;
  thingToDelete: string;
  allowZero?: boolean;
}> = ({
  onPlus,
  onDelete,
  isFocused,
  containingArray,
  thingToDelete,
  allowZero = false,
}) => {
  const allowDeletion =
    (containingArray ? containingArray.length > 1 : false) || allowZero;

  return (
    <Group gap={"var(--button-spacing)"}>
      {onPlus && containingArray && (
        <PlusButton onPlus={onPlus} isFocused={isFocused} />
      )}
      {onDelete && containingArray && (
        <DeleteButton
          onDelete={onDelete}
          isFocused={isFocused}
          disabled={!allowDeletion}
          thingToDelete={thingToDelete}
        />
      )}
    </Group>
  );
};

/**
 * The buttons which a pattern can have, in a structure mode rule.
 *
 * @param bundle - the current bundle
 * @param pattern - the pattern object
 * @param inArray - the containing array of patterns of this one, if there is one
 * @param isFocused - whether this pattern is currently focused
 */
export const PatternButtons: React.FC<{
  bundle: Bundle | null;
  pattern: Pattern;
  inArray: InArray<Pattern> | null;
  isFocused: boolean;
}> = ({ bundle, inArray, pattern, isFocused }) => {
  const containingArray = inArray?.parentArray;

  const onPlus = () => addIntoArray(bundle, inArray, emptyPattern(""));
  const onDelete = () => removeFromInArray(bundle, inArray);
  const onFilter = () => newConstraint(bundle, pattern);
  const allowDeletion = containingArray
    ? containingArray.filter((p) => !p.isDisabled).length > 1
    : false;
  // A disabled pattern should always be able to be re-enabled
  const allowSwitching = allowDeletion || pattern.isDisabled;

  // Patterns shouldn't ever allow themselves to be deleted,
  // unless we're talking about a standalone sanitizer.
  const allowZero = inArray?.parentKind === "sanitizer";

  const disableButtonText = pattern.isDisabled
    ? "Enable pattern"
    : !allowDeletion
    ? `Cannot disable (parent must have at least one child)`
    : "Disable pattern";

  return (
    <Group gap={"var(--button-spacing)"}>
      <FilterButton
        onFilter={onFilter}
        isFocused={isFocused}
        pattern={pattern}
      />
      <PlusDeleteButtons
        onPlus={onPlus}
        onDelete={onDelete}
        isFocused={isFocused}
        containingArray={containingArray}
        thingToDelete={"pattern"}
        allowZero={allowZero}
      />
      <FadedComponent isFocused={isFocused || pattern.isDisabled} opacity={0}>
        <Tooltip label={disableButtonText} refProp="rootRef" openDelay={350}>
          <Switch
            aria-label="PatternDisableSwitch"
            defaultChecked={false}
            disabled={!allowSwitching}
            checked={!pattern.isDisabled}
            color="var(--mantine-color-blue-4)"
            onChange={(event) => {
              disablePattern(bundle, pattern, !event.currentTarget.checked);
            }}
          />
        </Tooltip>
      </FadedComponent>
    </Group>
  );
};
