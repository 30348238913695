import { DependencyList, EffectCallback, useEffect, useRef } from "react";

// adapted from https://github.com/samanmohamadi/use-debounced-effect
export const useDebouncedEffect = (
  callback: EffectCallback,
  delay: number,
  deps?: DependencyList
): void => {
  const firstUpdate = useRef(true);
  const depsArg = deps !== undefined ? deps.map((i) => i) : undefined;
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay, depsArg]);
};
