import React, { useEffect } from "react";
import { useMemo, useRef, useState } from "react";
import * as monaco from "monaco-editor";
import { Box } from "@mantine/core";
import { Monaco } from "@monaco-editor/react";

import { MonacoEditor } from "@vendors/monaco";

import { LANG_MONACO_MAP } from "@shared/constants";
import { useDebouncedResize, useHighlights } from "@shared/hooks";
import { Highlight } from "@shared/types";

import { RunButton } from "../RunButton";

interface Props {
  target: string;
  language: string;
  hideLineNumbers?: boolean;
  error?: string;
  onTargetChange?: (target: string) => void;
  onRun?: () => Promise<any>;
  isRunning: boolean;
  keyComboRunAction: () => void;
  highlighted: readonly Highlight[];
  offsetHighlight?: [number, number];
  centeredLine: number | undefined;
  readOnly?: boolean;
}

const Editor: React.FC<Props> = ({
  target,
  error,
  onTargetChange,
  onRun,
  isRunning,
  readOnly,
  language,
  hideLineNumbers,
  centeredLine,
  highlighted,
  offsetHighlight,
  keyComboRunAction,
}) => {
  const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor>();
  const [monacoInstance, setMonacoInstance] = useState<Monaco>();

  useEffect(() => {
    if (editor === undefined || centeredLine === undefined) {
      return;
    }
    editor.revealLineInCenter(centeredLine);
  }, [editor, centeredLine]);

  const registerHovers = useHighlights(
    monacoInstance,
    editor,
    language,
    highlighted,
    offsetHighlight
  );

  const editorEl = useRef<HTMLDivElement>(null!);
  useDebouncedResize(editorEl, () => editor?.layout());

  useEffect(() => {
    if (editor === undefined) return;
    if (monacoInstance === undefined) return;
    editor.addCommand(
      monacoInstance.KeyMod.CtrlCmd | monacoInstance.KeyCode.Enter,
      keyComboRunAction
    );
  }, [editor, monacoInstance, keyComboRunAction]);

  const editorDidMount = useMemo(
    () =>
      async (
        mountedEditor: monaco.editor.IStandaloneCodeEditor,
        monacoInstance: Monaco
      ) => {
        if (monacoInstance === undefined) return;
        setEditor(mountedEditor);
        setMonacoInstance(monacoInstance);
        registerHovers(monacoInstance);
      },
    [setEditor, registerHovers]
  );
  const lang = language.toLowerCase();
  const editorLang = LANG_MONACO_MAP[language] || lang;

  return (
    <>
      <div ref={editorEl} className={`target-editor${error ? " invalid" : ""}`}>
        <MonacoEditor
          value={target}
          onChange={
            onTargetChange
              ? (value: string | undefined) => {
                  value && onTargetChange(value);
                }
              : undefined
          }
          onMount={editorDidMount}
          language={editorLang}
          options={{
            minimap: { enabled: false },
            readOnly: readOnly || false,
            wordWrap: "on",
            glyphMargin: true,
            fontFamily:
              '"Jetbrains MonoVariable", "Jetbrains Mono", "Menlo", "Courier New", monospace',
            lineNumbers: hideLineNumbers ? "off" : "on",
            contextmenu: true,
            hideCursorInOverviewRuler: true,
            scrollBeyondLastLine: false,
            overviewRulerBorder: false,
            overviewRulerLanes: 1,
            lineNumbersMinChars: 3,
            scrollbar: {
              horizontal: "hidden",
              verticalScrollbarSize: 5,
            },
            hover: { delay: 200 },
            wrappingIndent: "same",
            lightbulb: { enabled: false },
            showUnused: false,
          }}
        />
        {onRun && (
          <Box pos="absolute" top={10} right={10}>
            <RunButton onClick={onRun} isRunning={isRunning} />
          </Box>
        )}
      </div>
      {error && (
        <div className="editor-error">
          <div className="alert">
            <div className="alert-message">{error}</div>
          </div>
        </div>
      )}
    </>
  );
};
export const CodeEditor = React.memo(Editor);
