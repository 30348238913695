import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  faBook,
  faChevronDown,
  faDatabase,
  faLock,
  faPage,
  faPencilAlt,
  faSignInAlt,
  faTags,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppShell,
  Box,
  Burger,
  Button,
  Center,
  Collapse,
  Divider,
  Drawer,
  Group,
  Menu,
  rem,
  ScrollArea,
  Stack,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import {
  useAuth,
  useBasePath,
  useIsDesktop,
  useOrg,
  useUser,
} from "@shared/hooks";
import { getOrgSlug } from "@shared/utils";

import { NavbarLogo } from "../NavbarLogo";
import { UserMenu } from "../UserMenu";

const SignInLink = styled.a`
  color: var(--mantine-color-gray-8);
`;

interface Props {
  minimal?: boolean;
  showLogin?: boolean;
}

export const AppNavbar: React.FC<Props> = ({
  minimal = false,
  showLogin = true,
}) => {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const [user] = useUser();
  const [org] = useOrg();
  // NOTE(zz): Don't think the install redirect is needed moving forwards
  const { scmInstallRedirect: install, logout } = useAuth();
  const isDesktop = useIsDesktop();
  const basePath = useBasePath();
  const location = useLocation();

  const registryPages = ["/explore", "/r"];
  // TODO update when we get rid of the old playground
  // we will no longer need "/s" because snippets will be at "/playground/s"
  const editorPages = ["/playground", "/s"];

  // can't use basePath because it gets the path for the current org, and we may
  // not be logged in
  const orgLandingPage = org ? `/orgs/${getOrgSlug(org)}/` : "/";

  const orgDisplayName = org?.display_name || org?.name;

  const links = [
    {
      icon: faDatabase,
      label: "Registry",
      href: "/explore",
      checkPath: () =>
        registryPages.indexOf(location.pathname) > -1 ||
        location.pathname.includes("/p/") ||
        location.pathname.includes("/explore"),
      internal: true,
    },
    {
      icon: faPencilAlt,
      label: "Playground",
      href: "/playground/new",
      checkPath: () =>
        editorPages.indexOf(location.pathname) > -1 ||
        location.pathname.includes("/s/") ||
        location.pathname.includes("/playground"),
      internal: true,
    },
    {
      icon: faPage,
      label: "Products",
      href: "/products",
      checkPath: () => location.pathname.includes("/products"),
      menuItems: [
        {
          label: "Semgrep Code",
          href: "/products/semgrep-code",
        },
        {
          label: "Semgrep Supply Chain",
          href: "/products/semgrep-supply-chain",
        },
        {
          label: "Semgrep Secrets",
          href: "/products/semgrep-secrets",
        },
      ],
      menu: true,
    },
    {
      icon: faTags,
      label: "Pricing",
      href: "/pricing",
      checkPath: () => location.pathname.includes("/pricing"),
    },
    {
      icon: faBook,
      label: "Docs",
      href: "/docs/",
      checkPath: () => location.pathname.includes("/docs"),
      menuItems: [
        ...(isDesktop
          ? [
              {
                label: "Docs",
                href: "/docs/",
              },
              {
                label: "Tutorial",
                href: "/learn/",
              },
            ]
          : []),
      ],
      menu: true,
    },
  ];

  return (
    <div className={minimal ? "minimal" : "normal"}>
      {/* height: "auto" will expand the navbar vertically when its contents wrap on mobile  */}
      <AppShell>
        <AppShell.Header h="50px" py={6} style={{ position: "fixed" }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "0 8px",
            }}
          >
            <Group justify="flex-start" gap="xs" style={{ columnGap: 0 }}>
              {!minimal && (
                <Button
                  color="gray"
                  component="a" // <a> instead of <Link> to do a hard-ish refresh when clicked
                  href={orgLandingPage}
                  variant="subtle"
                >
                  {<NavbarLogo />}
                </Button>
              )}
              <Group h="100%" gap={0} visibleFrom="md">
                {links.map((link) => (
                  <React.Fragment key={link.href}>
                    {link.menu ? (
                      <Menu trigger="hover" closeDelay={200} openDelay={200}>
                        <Menu.Target>
                          <Button
                            color={link.checkPath() ? "blue" : "gray"}
                            variant="subtle"
                          >
                            {link.label}
                          </Button>
                        </Menu.Target>
                        <Menu.Dropdown>
                          {link.menuItems &&
                            link.menuItems.map((menuItem) => (
                              <Menu.Item
                                key={menuItem.href}
                                component="a"
                                href={menuItem.href}
                              >
                                {menuItem.label}
                              </Menu.Item>
                            ))}
                        </Menu.Dropdown>
                      </Menu>
                    ) : link.internal ? (
                      <Button
                        component={Link}
                        to={link.href}
                        color={link.checkPath() ? "blue" : "gray"}
                        variant="subtle"
                      >
                        {link.label}
                      </Button>
                    ) : (
                      <Button
                        component="a"
                        href={link.href}
                        color={link.checkPath() ? "blue" : "gray"}
                        variant="subtle"
                      >
                        {link.label}
                      </Button>
                    )}
                  </React.Fragment>
                ))}

                {user?.roles?.admin === true && (
                  <Button
                    color={
                      location.pathname.includes("/admin") ? "blue" : "gray"
                    }
                    component={Link}
                    to="/admin"
                    variant="subtle"
                  >
                    {"Admin"}
                  </Button>
                )}
              </Group>
            </Group>
            <Group justify="flex-end" gap="xs" visibleFrom="md">
              {user !== undefined ? (
                // NOTE(zz): We should likely remove `install` from the UserMenu
                <UserMenu
                  user={user}
                  orgDisplayName={orgDisplayName}
                  basePath={basePath}
                  install={install}
                  logout={logout}
                />
              ) : showLogin ? (
                <Tooltip
                  label="Sign in to save custom rules and manage Semgrep policies in CI!"
                  position="bottom"
                  multiline
                  w={240}
                  withArrow
                >
                  <SignInLink
                    href="/login"
                    style={{ color: "--global-purple-highlight" }}
                  >
                    {isDesktop ? (
                      "Sign in / Sign up free"
                    ) : (
                      <FontAwesomeIcon icon={faSignInAlt} />
                    )}
                  </SignInLink>
                </Tooltip>
              ) : undefined}
            </Group>
            <Burger
              opened={drawerOpened}
              onClick={toggleDrawer}
              hiddenFrom="md"
            />
          </div>
        </AppShell.Header>
      </AppShell>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title={<NavbarLogo />}
        hiddenFrom="md"
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
          <Divider style={{ marginTop: 0 }} my="sm" color={"gray.1"} />

          {links.map((link) => (
            <Group
              wrap="nowrap"
              align="flex-start"
              px="md"
              py="xs"
              key={link.label}
            >
              <ThemeIcon size={34} variant="default" radius="md">
                <FontAwesomeIcon icon={link.icon} />
              </ThemeIcon>
              {link.label === "Products" ? (
                <div>
                  <Button
                    color={"gray"}
                    onClick={toggleLinks}
                    style={{ textAlign: "left" }}
                    variant="subtle"
                  >
                    <Center inline>
                      <Box component="span" mr={4}>
                        {link.label}
                      </Box>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        style={{ paddingLeft: "8px" }}
                      />
                    </Center>
                  </Button>
                  {link.menuItems && (
                    <Collapse in={linksOpened}>
                      <Stack align="flex-start" mt={12}>
                        {link.menuItems.map((menuItem) => (
                          <Button
                            component="a"
                            href={menuItem.href}
                            color={"gray"}
                            variant="subtle"
                            key={menuItem.label}
                          >
                            {menuItem.label}
                          </Button>
                        ))}
                      </Stack>
                    </Collapse>
                  )}
                </div>
              ) : link.internal ? (
                <Button
                  style={{ textAlign: "left" }}
                  color={link.checkPath() ? "blue" : "gray"}
                  component={Link}
                  to={link.href}
                  variant="subtle"
                >
                  {link.label}
                </Button>
              ) : (
                <Button
                  component="a"
                  href={link.href}
                  style={{ textAlign: "left" }}
                  color={link.checkPath() ? "blue" : "gray"}
                  variant="subtle"
                >
                  {link.label}
                </Button>
              )}
            </Group>
          ))}

          {user?.roles?.admin === true ? (
            <Group wrap="nowrap" align="flex-start" px="md" py="xs">
              <ThemeIcon size={34} variant="default" radius="md">
                <FontAwesomeIcon icon={faLock} />
              </ThemeIcon>
              <Button
                style={{ textAlign: "left" }}
                color={location.pathname.includes("/admin") ? "blue" : "gray"}
                component={Link}
                to="/admin"
                variant="subtle"
              >
                Admin
              </Button>{" "}
            </Group>
          ) : null}

          <Divider my="sm" color={"gray.1"} />

          <Group justify="center" grow pb="xl" px="md">
            {user !== undefined ? (
              <UserMenu
                user={user}
                orgDisplayName={orgDisplayName}
                basePath={basePath}
                install={install}
                logout={logout}
              />
            ) : showLogin ? (
              <SignInLink
                href="/login"
                style={{ color: "--global-purple-highlight" }}
              >
                {"Sign in / Sign up free"}
              </SignInLink>
            ) : undefined}
          </Group>
        </ScrollArea>
      </Drawer>
    </div>
  );
};
