import * as monaco from "monaco-editor";

import { MatchingExplanation } from "@semgrep_output_types";

import { opToSyntax } from "./opToSyntax";

export const makeExplanationsHoverProvider = (
  explanations: MatchingExplanation[],
  ranges: monaco.Range[]
): monaco.languages.HoverProvider => {
  // Yes, this is recursive, no, this is no tail optimized or memoized but,
  // I'm calling it O(1), because I'm assuming no one will ever write a rule
  // with THAT many patterns, and THAT many targets
  // Also, tested w/ ~100 match and ~10 pattern rules, and it was fine
  const matchingOps = (
    location: monaco.Range,
    explanation: MatchingExplanation,
    depth: number
  ): string => {
    const matched = explanation.matches.find((m) => {
      const mRange = new monaco.Range(
        m.start.line,
        m.start.col,
        m.end.line,
        m.end.col
      );
      return location.containsRange(mRange);
    });
    const operation = opToSyntax(explanation.op);
    const opString = `${operation}: `;
    const opStringWithDepth = `${"   ".repeat(depth)}${
      depth > 0 ? "-" : ""
    } ${opString}`.replace(/^\n+|\n+$/g, "");
    const rest = explanation.children
      .map((e) => matchingOps(location, e, depth + 1))
      .filter((s) => s.length > 0)
      .join("\n");
    return matched ? `\n${opStringWithDepth}${rest}` : rest;
  };

  const rangeHovers = ranges.map((r) => {
    const opContents = explanations
      .map((e) => matchingOps(r, e, 0))
      .filter((s) => s.length > 0)
      .join("\n");

    const contents = {
      value: `\`\`\`yaml${opContents}\n\`\`\``,
    };
    return [r, contents] as [monaco.Range, monaco.IMarkdownString];
  });
  return {
    provideHover: async (_model, position) => {
      const range = new monaco.Range(
        position.lineNumber,
        position.column,
        position.lineNumber,
        position.column
      );

      const explContents = rangeHovers
        .filter(([r, _]) => r.containsPosition(position))
        .map(([_, c]) => c);
      const contents = [
        {
          value: "**Finding**",
        },
        {
          value: "This finding matched the following parts of the rule",
        },
        ...explContents,
      ];

      return {
        range,
        contents,
      };
    },
  };
};
