// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/ai/v1/tags.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "protos.ai.v1";

export enum Sensitivity {
  UNSPECIFIED = "SENSITIVITY_UNSPECIFIED",
  HIGH_SENSITIVITY = "SENSITIVITY_HIGH_SENSITIVITY",
  LOW_SENSITIVITY = "SENSITIVITY_LOW_SENSITIVITY",
  NEUTRAL_SENSITIVITY = "SENSITIVITY_NEUTRAL_SENSITIVITY",
}

export function sensitivityFromJSON(object: any): Sensitivity {
  switch (object) {
    case 0:
    case "SENSITIVITY_UNSPECIFIED":
      return Sensitivity.UNSPECIFIED;
    case 10:
    case "SENSITIVITY_HIGH_SENSITIVITY":
      return Sensitivity.HIGH_SENSITIVITY;
    case 20:
    case "SENSITIVITY_LOW_SENSITIVITY":
      return Sensitivity.LOW_SENSITIVITY;
    case 30:
    case "SENSITIVITY_NEUTRAL_SENSITIVITY":
      return Sensitivity.NEUTRAL_SENSITIVITY;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Sensitivity");
  }
}

export function sensitivityToJSON(object: Sensitivity): string {
  switch (object) {
    case Sensitivity.UNSPECIFIED:
      return "SENSITIVITY_UNSPECIFIED";
    case Sensitivity.HIGH_SENSITIVITY:
      return "SENSITIVITY_HIGH_SENSITIVITY";
    case Sensitivity.LOW_SENSITIVITY:
      return "SENSITIVITY_LOW_SENSITIVITY";
    case Sensitivity.NEUTRAL_SENSITIVITY:
      return "SENSITIVITY_NEUTRAL_SENSITIVITY";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Sensitivity");
  }
}

export function sensitivityToNumber(object: Sensitivity): number {
  switch (object) {
    case Sensitivity.UNSPECIFIED:
      return 0;
    case Sensitivity.HIGH_SENSITIVITY:
      return 10;
    case Sensitivity.LOW_SENSITIVITY:
      return 20;
    case Sensitivity.NEUTRAL_SENSITIVITY:
      return 30;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Sensitivity");
  }
}

export interface SensitivityTags {
  id: number;
  path: string;
  tags: string;
  sensitivity: Sensitivity;
}

function createBaseSensitivityTags(): SensitivityTags {
  return { id: 0, path: "", tags: "", sensitivity: Sensitivity.UNSPECIFIED };
}

export const SensitivityTags = {
  encode(message: SensitivityTags, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.path !== "") {
      writer.uint32(18).string(message.path);
    }
    if (message.tags !== "") {
      writer.uint32(26).string(message.tags);
    }
    if (message.sensitivity !== Sensitivity.UNSPECIFIED) {
      writer.uint32(32).int32(sensitivityToNumber(message.sensitivity));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SensitivityTags {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSensitivityTags();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.path = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tags = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sensitivity = sensitivityFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SensitivityTags {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      tags: isSet(object.tags) ? globalThis.String(object.tags) : "",
      sensitivity: isSet(object.sensitivity) ? sensitivityFromJSON(object.sensitivity) : Sensitivity.UNSPECIFIED,
    };
  },

  toJSON(message: SensitivityTags): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.tags !== "") {
      obj.tags = message.tags;
    }
    if (message.sensitivity !== Sensitivity.UNSPECIFIED) {
      obj.sensitivity = sensitivityToJSON(message.sensitivity);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SensitivityTags>, I>>(base?: I): SensitivityTags {
    return SensitivityTags.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SensitivityTags>, I>>(object: I): SensitivityTags {
    const message = createBaseSensitivityTags();
    message.id = object.id ?? 0;
    message.path = object.path ?? "";
    message.tags = object.tags ?? "";
    message.sensitivity = object.sensitivity ?? Sensitivity.UNSPECIFIED;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
