import { useQuery } from "@tanstack/react-query";

import { authGet } from "@shared/api";
import { RuleBoardsResponse } from "@shared/types";

import { useOrgAssertDefined } from "../useOrgAssertDefined";

export const fetchRuleBoards = async (
  deploymentId: number
): Promise<RuleBoardsResponse> => {
  return await authGet<RuleBoardsResponse>(
    `/api/agent/deployments/${deploymentId}/ruleboards`
  );
};

export const useRuleBoards = () => {
  const org = useOrgAssertDefined();

  return useQuery({
    queryKey: ["ruleBoards", org.id, org],
    queryFn: () => fetchRuleBoards(org.id),
  });
};
