import { IRange } from "monaco-editor";

import { CodeLoc } from "@shared/types";
import { Position } from "@semgrep_output_types";

const spanToHighlightRange = (start: CodeLoc, end: CodeLoc): IRange => ({
  startLineNumber: start.line,
  startColumn: start.col ?? 0,
  endLineNumber: end.line,
  endColumn: end.col ?? Infinity,
});

export const makeHighlight = (
  start: Position,
  end?: Position,
  newEnd?: CodeLoc
) => {
  const endLine = end?.line ?? start.line; // if no end.line, assume one line
  const range = spanToHighlightRange(start, {
    col: newEnd?.col ?? end?.col,
    line: newEnd?.line ?? endLine,
  });
  return {
    range,
    startLine: start.line,
    endLine: newEnd ? newEnd.line : endLine,
  };
};
