import React from "react";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor, Button, SimpleGrid, Stack, Text, Title } from "@mantine/core";

import { ScmType } from "@protos/common/v1/common";
import { CIProvider } from "@shared/types";

import { Link } from "../Link";

type BaseProjectTypeOption = {
  title: string;
  faIcon?: IconDefinition;
  imgSrc?: string;
  imgDesc?: string;
};

export type CIProjectTypeOption = BaseProjectTypeOption & {
  ciProvider: CIProvider;
};

export type SMSProjectTypeOption = BaseProjectTypeOption & {
  scmType: ScmType;
};

type Props<T extends CIProjectTypeOption | SMSProjectTypeOption> = {
  title: string;
  options: T[];
  getNextLink: (option: T) => string;
};

export function SetupProjectChooseType<
  T extends CIProjectTypeOption | SMSProjectTypeOption
>({ title, options, getNextLink }: Props<T>) {
  return (
    <Stack px={24} pb="lg" align="center" gap={24}>
      <Title c="dark" order={3} fw="bold">
        {title}
      </Title>
      <SimpleGrid spacing="md" cols={2} style={{ width: "85%" }}>
        {options.map((option, i) => (
          <Button
            key={i}
            variant="default"
            styles={{ inner: { justifyContent: "flex-start" } }}
            fw="normal"
            leftSection={
              option.faIcon ? (
                <FontAwesomeIcon
                  icon={option.faIcon}
                  style={{ fontSize: "var(--global-font-size-lg)" }}
                />
              ) : (
                option.imgSrc &&
                option.imgDesc && (
                  <img
                    src={option.imgSrc}
                    alt={option.imgDesc}
                    style={{ width: "20px" }}
                  />
                )
              )
            }
            size="xl"
            component={Link}
            to={getNextLink(option)}
          >
            {option.title}
          </Button>
        ))}
      </SimpleGrid>
      <Text size="xs">
        Your source code will never be sent anywhere.{" "}
        <Anchor
          inherit
          href="https://semgrep.dev/docs/faq/#is-private-source-code-shared-with-semgrep-inc"
          target="_blank"
          rel="noopener noreferrer"
          c="black"
          style={{ textDecoration: "underline" }}
        >
          See what data will be uploaded.
        </Anchor>
      </Text>
    </Stack>
  );
}
