import React, { useEffect, useMemo, useRef, useState } from "react";
import { editor as monacoEditor } from "monaco-editor";
import styled from "styled-components";
import { Box, noop } from "@mantine/core";
import { Monaco } from "@monaco-editor/react";

import { MonacoEditor } from "@vendors/monaco";

import { ErrorBoundary } from "@shared/components";
import { useDebouncedResize, useHighlights } from "@shared/hooks";
import { Highlight } from "@shared/types";

import { RunButton } from "../RunButton";

interface Props {
  pattern: string;
  error?: boolean;
  language: string;
  onPatternChange: (pattern: string) => void;
  keyComboRunAction?: () => void;
  runButton?: boolean;
  onRun?: () => Promise<void>;
  minLines?: number;
  highlighted?: readonly Highlight[];
  readOnly?: boolean;
}

const MAX_EDITOR_LINES = 10;
const PLACEHOLDER_TEXT = "Your pattern here...";

const Container = styled.div<{ hasMinLines: boolean }>`
  flex-basis: ${(props) => (props.hasMinLines ? "unset" : "250px")};
  flex-grow: ${(props) => (props.hasMinLines ? "unset" : 4)};
  padding-top: 5px;
  min-height: 30px;
  max-height: 200px;
  background-color: white;
  border-radius: var(--mantine-radius-default);
  overflow: hidden;
`; // min-height and esp. max-height are for YAML tab

const Placeholder = styled.div`
  position: absolute;
  z-index: 3;
  pointer-events: none;
  color: #c7c7c7;
  /* Copied from .monaco-editor .view-lines */
  font-family: var(--mantine-font-family-monospace);
  font-weight: 400;
  font-size: var(--mantine-font-size-xs);
  font-feature-settings: "liga" 0, "calt" 0;
  line-height: 18px;
  letter-spacing: 0px;
  margin-left: 16px;
`;

const getEditorHeight = (
  monacoInstance: Monaco,
  mountedEditor: monacoEditor.IStandaloneCodeEditor,
  minLines?: number
) => {
  const editorElement = mountedEditor.getDomNode();

  if (!editorElement) {
    return;
  }

  const lineHeight = mountedEditor.getOption(
    monacoInstance.editor.EditorOption.lineHeight
  );
  let lineCount = mountedEditor.getModel()?.getLineCount() || 1;
  lineCount = Math.min(lineCount, MAX_EDITOR_LINES);
  if (minLines) {
    lineCount = Math.max(lineCount, minLines);
  }
  const newHeight = lineCount * lineHeight + 7;
  return newHeight;
};

const Editor: React.FC<Props> = ({
  pattern,
  onPatternChange,
  error,
  keyComboRunAction = noop,
  language,
  runButton,
  onRun,
  minLines,
  highlighted,
  readOnly,
}) => {
  const [editor, setEditor] = useState<monacoEditor.IStandaloneCodeEditor>();
  const [monacoInstance, setMonacoInstance] = useState<Monaco>();
  const [_, setModel] = useState<monacoEditor.ITextModel>();
  const [height, setHeight] = useState<number>(0);

  const registerHovers = useHighlights(
    monacoInstance,
    editor,
    language,
    highlighted ?? [],
    undefined
  );

  const editorDidMount = useMemo(
    () =>
      (
        mountedEditor: monacoEditor.IStandaloneCodeEditor,
        monacoInstance: Monaco
      ) => {
        setEditor(mountedEditor);
        setMonacoInstance(monacoInstance);
        // from https://github.com/microsoft/monaco-editor/issues/794#issuecomment-583367666
        const updateEditorHeight = () => {
          const newHeight = getEditorHeight(
            monacoInstance,
            mountedEditor,
            minLines
          );
          if (newHeight) {
            setHeight(newHeight);
          }
        };
        mountedEditor.onDidChangeModelDecorations(updateEditorHeight);

        const model = mountedEditor.getModel()!;
        setModel(model);
        model.updateOptions({ tabSize: 2 });
        updateEditorHeight();

        registerHovers(monacoInstance);
      },
    [minLines, registerHovers, setEditor, setHeight, setModel]
  );

  const container = useRef<HTMLDivElement>(null!);
  useDebouncedResize(container, () => {
    if (editor === undefined) return;
    if (monacoInstance === undefined) return;
    // recalculate the height here as well
    editor.layout();
    const newHeight = getEditorHeight(monacoInstance, editor, minLines);
    if (newHeight) {
      setHeight(newHeight);
    }
  });

  useEffect(() => {
    editor?.layout();
  }, [editor, height]);

  useEffect(() => {
    if (monacoInstance === undefined) return;
    editor?.addCommand(
      monacoInstance.KeyMod.CtrlCmd | monacoInstance.KeyCode.Enter,
      keyComboRunAction
    );
  }, [monacoInstance, editor, keyComboRunAction]);

  return (
    <Container
      ref={container}
      style={{ height: height }}
      className={`pattern-editor${error ? " invalid" : ""}`}
      hasMinLines={minLines !== undefined}
    >
      {pattern === "" ? (
        <Placeholder>{PLACEHOLDER_TEXT}</Placeholder>
      ) : undefined}
      <ErrorBoundary>
        <MonacoEditor
          value={pattern}
          onChange={(value: string | undefined) => {
            if (value !== undefined) onPatternChange(value);
          }}
          onMount={editorDidMount}
          language={language ?? "yaml"}
          options={{
            automaticLayout: true,
            renderLineHighlight: "none",
            minimap: { enabled: false },
            wordWrap: "on",
            fontFamily:
              '"Jetbrains MonoVariable", "Jetbrains Mono", "Menlo", "Courier New", monospace',
            lineNumbers: "off",
            lineDecorationsWidth: 0,
            contextmenu: false,
            scrollBeyondLastLine: false,
            hideCursorInOverviewRuler: true,
            overviewRulerBorder: false,
            overviewRulerLanes: 1,
            scrollbar: { horizontal: "hidden", verticalScrollbarSize: 5 },
            wrappingIndent: "same",
            renderWhitespace: error ? "all" : "none",
            readOnly: readOnly ?? false,
          }}
        />
      </ErrorBoundary>
      {runButton && (
        <Box pos="absolute" top={10} right={10}>
          <RunButton
            onClick={onRun!}
            isRunning={false}
            customRunText={"Edit"}
          />
        </Box>
      )}
    </Container>
  );
};
export const PatternEditor = React.memo(Editor);
