import { CliMatch } from "@semgrep_output_types";

export const applyFix = (
  r: CliMatch,
  target: string
): { newTarget: string } => {
  // get the start and end points
  const lines = target.split("\n");
  if (r.extra.fixed_lines && lines.length > 0) {
    const startLine = (r.start.line || 1) - 1; // start.line is 1 indexed
    const endLine = (r.end.line || 1) - 1; // end.line is 1 indexed
    // break into before, to modify, after
    const beforeLines = lines.slice(0, startLine);
    const afterLines = lines.slice(endLine + 1); // next line after end of match
    const modifiedLines = r.extra.fixed_lines;
    // build new string
    const contentsAfterfix = [...beforeLines, ...modifiedLines, ...afterLines];
    const newTarget = contentsAfterfix.join("\n");
    return { newTarget };
  } else {
    console.warn("problem applying fixed lines", r.extra.fixed_lines, target);
    return { newTarget: target };
  }
};
