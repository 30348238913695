import { FC } from "react";
import styled from "styled-components";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Paper,
  Skeleton,
  Stack,
  Text,
  Title,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";

import styles from "./SetupCard.module.css";

export interface SetupCardProps {
  title: string;
  description: string;
  icon: IconDefinition;
  datatestid?: string;
  primary?: boolean;
  secondary?: boolean;
  ribbonText?: string;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}

const Ribbon = styled.div`
  position: absolute;
  font-size: var(--mantine-font-size-xs);
  font-weight: 500;
  top: 20px;
  left: -4px;
  padding: 6px 16px 6px 10px;
  clip-path: polygon(0 0, 100% 0, 95% 50%, 100% 100%, 0% 100%);
`;

// text --> white, icon --> white, background: blue, ribbon: light blue, ribbon text: blue

export const SetupCard: FC<SetupCardProps> = ({
  title,
  description,
  icon,
  datatestid,
  primary,
  secondary,
  ribbonText,
  onClick,
  isLoading,
  disabled,
}) => {
  const { colors } = useMantineTheme();
  const textColor = primary ? "white" : undefined;
  const { ref, width } = useElementSize();

  const isWrapWidth = width < 340;

  return (
    <UnstyledButton
      className={styles.button}
      onClick={onClick}
      ref={ref}
      disabled={disabled}
      data-testid={datatestid}
      aria-label={title}
    >
      <Paper
        radius="md"
        px={isWrapWidth ? 24 : 40}
        py={64}
        bg={
          disabled ? colors["gray"][1] : primary ? colors["blue"][8] : "white"
        }
        pos="relative"
        h="100%"
        withBorder
      >
        {ribbonText && (
          <Ribbon
            style={
              primary
                ? {
                    backgroundColor: colors["blue"][1],
                    color: colors["blue"][8],
                  }
                : secondary
                ? {
                    backgroundColor: colors["orange"][8],
                    color: "white",
                  }
                : {
                    backgroundColor: colors["blue"][8],
                    color: "white",
                  }
            }
          >
            {ribbonText}
          </Ribbon>
        )}
        <Stack
          align="center"
          gap={isWrapWidth ? "xs" : "md"}
          style={{ maxWidth: 300 }}
        >
          <FontAwesomeIcon
            icon={icon}
            size={isWrapWidth ? "3x" : "4x"}
            color={primary ? textColor : colors["blue"][8]}
          />
          <Title
            ta="center"
            fz={isWrapWidth ? 20 : 24}
            c={textColor}
            pt={isWrapWidth ? "xs" : "lg"}
          >
            {title}
          </Title>
          {isLoading && <Skeleton h={50} />}
          <Text ta="center" fz={isWrapWidth ? "sm" : "md"} c={textColor}>
            {description}
          </Text>
        </Stack>
      </Paper>
    </UnstyledButton>
  );
};
