import { useMemo } from "react";

import { Permission } from "@shared/types";

import { usePermissions } from "../usePermissions";
import { useSupportsFeature } from "../useSupportsFeature";
import { useUser } from "../useUser";

/**
 * A type by which we can look up routes and navlinks. Routes are defined in
 * `useDashboardRoutes`, and navlinks are defined in `useNavigationLinks`.
 */
type RoutesAndNavLinks =
  | "overview"
  | "finding"
  | "findings"
  | "ruleBoard"
  | "projects"
  | "supplyChain"
  | "supplyChainFinding"
  | "settings"
  | "editor"
  | "accountSettings"
  | "support"
  | "setup"
  | "secret"
  | "secrets"
  | "registry"
  | "docs"
  | "admin"
  | "codeSearch"
  | "reporting";

/**
 *
 * @returns map from name of dashboard route or navigation link to a boolean
 * representing if the user has access.
 */
export const useRoutePermissionMap = () => {
  const [user] = useUser();
  const [permissions] = usePermissions();
  const hasRbac = useSupportsFeature("hasProjectLevelRBACEnforced");
  const hasCodeSearch = useSupportsFeature("hasQueryConsoleSavedSearch");

  return useMemo<Record<RoutesAndNavLinks, boolean>>(
    () => ({
      overview: permissions.includes(Permission.panel_overview),
      projects:
        permissions.includes(Permission.panel_projects) ||
        (hasRbac && permissions.includes(Permission.panel_overview)), // show projects page with RBAC only to users that have access to the org
      setup: permissions.includes(Permission.panel_projects),
      ruleBoard: permissions.includes(Permission.panel_policy),
      supplyChain: permissions.includes(Permission.panel_findings),
      supplyChainFinding: permissions.includes(Permission.panel_findings),
      findings: permissions.includes(Permission.panel_findings),
      finding: permissions.includes(Permission.panel_findings),
      secrets: permissions.includes(Permission.panel_findings),
      secret: permissions.includes(Permission.panel_findings),
      editor: true,
      settings:
        permissions.includes(Permission.panel_settings) ||
        permissions.includes(Permission.team_manage),
      accountSettings: true,
      support: true,
      registry: true,
      docs: true,
      admin: !!user?.roles?.admin,
      codeSearch: hasCodeSearch,
      reporting: permissions.includes(Permission.panel_findings),
    }),
    [permissions, hasRbac, user?.roles?.admin, hasCodeSearch]
  );
};
