import styled from "styled-components";

import { scBackground } from "./utils";

const Code = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 4px 0px;
`;

const Line = styled.div<{ highlight: boolean }>`
  padding: 1px 8px;
  font-family: monospace;
  font-size: 13px;
  text-align: left;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ highlight }) => (highlight ? `background-color: #780c1b;` : ``)}
`;

export const GradientCard = styled.div`
  position: relative;
  z-index: 100;
  background-color: ${scBackground};
  color: white;
  max-width: 400px;
  &:before {
    content: "";
    inset: 0px;
    position: absolute;
    border-radius: 8px;
    padding: 1px;
    background: linear-gradient(
      102.89deg,
      rgb(42, 207, 205) 13.72%,
      rgb(106, 67, 194) 86%
    );
    -webkit-mask: linear-gradient(
          rgb(255, 255, 255) 0px,
          rgb(255, 255, 255) 0px
        )
        content-box content-box,
      linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
    -webkit-mask-composite: xor;
  }
`;

const Name = styled.div`
  font-size: 13px;
  display: flex;
  font-weight: 500;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  border-bottom: 1px solid #546075;
`;

export function CodeCard({
  name,
  lines,
  highlightedLine,
}: {
  name: string;
  lines: string[];
  highlightedLine: number;
}) {
  return (
    <GradientCard id={name}>
      <Header>
        <Name>{name}</Name>
      </Header>
      <Code>
        <Line highlight={false}>...</Line>
        {lines.map((line, i) => {
          return (
            <Line key={i} highlight={i + 1 === highlightedLine}>
              {line}
            </Line>
          );
        })}
        <Line highlight={false}>...</Line>
      </Code>
    </GradientCard>
  );
}
