import { ItemId } from "@atlaskit/tree";

import { MatchingExplanation } from "@semgrep_output_types";

import {
  ConstraintNodeData,
  InArray,
  isConstraintNodeData,
  isMetavariableConstraintNodeData,
  isPatternNodeData,
  MetavariableConstraint,
  MetavariableConstraintNodeData,
  Pattern,
  PatternConstraint,
  PatternNodeData,
  PatternTreeData,
  PatternTreeItem,
} from "../types/rule";

/******************************************************************************/
/* Tree functions (drag and drop) */
/******************************************************************************/

// Why is this here?
// We want to make sure that the pattern tree is the source of truth for generating our
// UI, which includes things like matching explanation numbers.
// To do this, we store the explanations from running the rule into the pattern tree
// itself.
// However, when we mutate the pattern tree without re-running it (such as by using the
// delete button on a sub-pattern), this doesn't generate a new explanation, but does
// generate a new pattern tree.
// We want to avoid using the explanations that were generated from an out-of-date structure.
// As such, we explicitly refresh the explanation fields of the pattern tree when the
// rule is re-run, as opposed to when the tree is mutated.
export function augmentPatternWithExplanations(
  pattern: Pattern,
  explanation: MatchingExplanation | null
) {
  pattern.explanation = explanation;
  const p = pattern.value.rawPattern.value;
  switch (p.kind) {
    case "any":
    case "all": {
      const disabledPatterns = p.patterns.filter((p) => p.isDisabled);
      const realPatterns = p.patterns.filter((p) => !p.isDisabled);
      realPatterns.map((p, index) =>
        augmentPatternWithExplanations(p, explanation?.children[index] ?? null)
      );
      disabledPatterns.map((p) => augmentPatternWithExplanations(p, null));
      break;
    }
    case "pattern":
    case "regex":
      break;
    case "not":
    case "inside": {
      augmentPatternWithExplanations(
        p.pattern,
        explanation?.children[0] ?? null
      );
      break;
    }
  }
}

export function metavariableConstraintMove(
  fromParent: PatternTreeItem,
  fromIndex: number,
  fromItemData: MetavariableConstraintNodeData,
  toParent: PatternTreeItem,
  toIndex: number
) {
  // We can only move an mvar constraint to underneath a constraint.
  if (
    !(
      isConstraintNodeData(toParent.data) &&
      isConstraintNodeData(fromParent.data)
    )
  )
    return;

  // we can only move a metavariable constraint to under a "metavariable"
  if (toParent.data.constraint.value.kind !== "metavariable") {
    return;
  }

  // remove from list of metavariable constraints it was in
  fromItemData.inArray.parentArray.splice(fromIndex, 1);

  // add to constraint list of parent
  // invariant: mvar constraint lists are never empty, so this is OK
  toParent.data.constraint.value.metavariableConstraints.splice(
    toIndex,
    0,
    fromItemData.metavariableConstraint
  );
}

export function constraintMove(
  fromParent: PatternTreeItem,
  fromIndex: number,
  fromItemData: ConstraintNodeData,
  toParent: PatternTreeItem,
  toIndex: number
) {
  // We can only move a constraint to underneath a pattern.
  if (!(isPatternNodeData(toParent.data) && isPatternNodeData(fromParent.data)))
    return;

  function numberOfPatternsInItem(item: PatternTreeItem) {
    if (isPatternNodeData(item.data)) {
      const p = item.data.pattern.value.rawPattern.value;
      switch (p.kind) {
        case "all":
        case "any":
          return p.patterns.length;
        case "inside":
        case "not":
          return 1;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  }

  // We must adjust our index here.
  // So far, we have been keeping an invariant that, when moving to the
  // tree representation, constraints are children of the nodes they modify.
  // However, we also have our constraint inArrays, which tell us the index
  // of the parent's constraint array (in the Pattern) which a given constraint
  // is at.
  // This allows us to adjust from index _in the tree, included patterns_ to
  // index _in the constraint array, not including patterns_.
  // For instance, for the pattern:
  // all:
  //  pattern: A
  // where:
  //  focus: $A
  // the `focus` has tree index 2, but constraint index 0.
  const toIndexAdjusted = toIndex - numberOfPatternsInItem(toParent);
  const fromIndexAdjusted = fromIndex - numberOfPatternsInItem(fromParent);

  // It's invalid to move a constraint to a place where patterns are.
  if (toIndex < numberOfPatternsInItem(toParent)) {
    return;
  }

  // remove from list of constraints it was in
  fromItemData.inArray.parentArray.splice(fromIndexAdjusted, 1);

  // If the pattern we are moving it to does not have a constraint, we must
  // make it.
  if (toParent.data.pattern.value.constraint === null) {
    toParent.data.pattern.value.constraint = [fromItemData.constraint];
  } else {
    // add to constraint list of parent
    toParent.data.pattern.value.constraint.splice(
      toIndexAdjusted,
      0,
      fromItemData.constraint
    );
  }
}

export function patternMove(
  fromParent: PatternTreeItem,
  fromIndex: number,
  fromItemData: PatternNodeData,
  toParent: PatternTreeItem,
  toIndex: number | undefined
) {
  // We can only move a pattern to underneath a pattern.
  if (!(isPatternNodeData(toParent.data) && isPatternNodeData(fromParent.data)))
    return;

  const pc = toParent.data.pattern.value.rawPattern.value;

  switch (pc.kind) {
    case "all":
    case "any": {
      // Pattern to pattern moves are only allowed under an `all` or `any`
      if (toIndex !== undefined) {
        // not clear to me why this is safe if the parent is the
        // same for both
        fromItemData.inArray?.parentArray.splice(fromIndex, 1);
        pc.patterns.splice(toIndex, 0, fromItemData.pattern);
      } else {
        throw new Error("pattern to pattern index undefined");
      }
      break;
    }
  }
}

export function moveTreeItem(
  fromParent: PatternTreeItem,
  fromIndex: number,
  fromItem: PatternTreeItem,
  toParent: PatternTreeItem,
  toIndex: number | undefined
) {
  // THINK: why would this be undefined?
  if (toIndex === undefined) {
    return;
  }

  // In all the branches below, note that we only ever conduct a move by altering the
  // "original" source of truth -- the Pattern AST itself.
  // We don't need to touch the tree (which is a mess of pointers and other nonsense)
  // because the tree is generated from the pattern AST afresh every time.
  // This makes the code a lot simpler, and also makes debugging easier. If there is
  // a problem, it must be with the original Pattern object.

  if (isMetavariableConstraintNodeData(fromItem.data)) {
    metavariableConstraintMove(
      fromParent,
      fromIndex,
      fromItem.data,
      toParent,
      toIndex
    );
  }
  if (isConstraintNodeData(fromItem.data)) {
    constraintMove(fromParent, fromIndex, fromItem.data, toParent, toIndex);
  }
  if (isPatternNodeData(fromItem.data)) {
    patternMove(fromParent, fromIndex, fromItem.data, toParent, toIndex);
  }
}

/******************************************************************************/
/* Pattern -> tree */
/******************************************************************************/

// This function generates an Atlaskit Tree structure from our `Pattern`s.
// This is because the Tree API is the one we use to display the nodes, and to have them exhibit the
// proper drag and drop behavior.
// We could have just circumvented Pattern and had a Tree all along, but a Tree is a pointer-laden
// structure which is very easy to mess up.
// Instead, we will have a Pattern, which is a simple recursive type which is the "source of truth"
// for the edited pattern. We will make only edits to that, which will trigger a rerender of the tree
// (and thus the UI).
export function treeOfPattern(pattern: Pattern): PatternTreeData {
  const table = new Map<string, any>();
  function auxMetavariableConstraint(
    metavariableConstraint: MetavariableConstraint,
    inArray: InArray<MetavariableConstraint> | null
  ): string {
    const mc = metavariableConstraint.value;

    function mkItem(children: Array<ItemId>) {
      return {
        id: metavariableConstraint.uuid,
        children: children,
        isExpanded: metavariableConstraint.isExpanded,
        data: {
          kind: "MetavariableConstraintNode",
          metavariableConstraint: metavariableConstraint,
          inArray: inArray,
        },
      };
    }

    let item;
    switch (mc.kind) {
      case "regex":
      case "analyzer":
        item = mkItem([]);
        break;
      case "type":
        item = mkItem([]);
        break;
      case "pattern":
        item = mkItem([aux(mc.pattern, null)]);
        break;
    }

    table.set(metavariableConstraint.uuid, item);
    return metavariableConstraint.uuid;
  }

  function auxConstraint(
    constraint: PatternConstraint,
    inArray: InArray<PatternConstraint>
  ): string {
    const c = constraint.value;

    function mkItem(children: Array<ItemId>): PatternTreeItem {
      return {
        id: constraint.uuid,
        children: children,
        isExpanded: constraint.isExpanded,
        data: {
          kind: "ConstraintNode",
          constraint: constraint,
          inArray: inArray,
        },
      };
    }

    let item: PatternTreeItem;
    switch (c.kind) {
      case "focus":
      case "comparison":
        item = mkItem([]);
        break;
      case "metavariable": {
        const items = c.metavariableConstraints.map((mc, index) => {
          return auxMetavariableConstraint(mc, {
            index,
            parentArray: c.metavariableConstraints,
            parentKind: "metavariableConstraint",
          });
        });

        item = mkItem(items);
        break;
      }
    }

    table.set(constraint.uuid, item);
    return constraint.uuid;
  }

  function aux(pattern: Pattern, inArray: InArray<Pattern> | null): string {
    let item;
    const p = pattern.value;
    const rp = p.rawPattern.value;

    const constraint = pattern.value.constraint;
    const constraintChildren =
      constraint?.map((c, index) =>
        auxConstraint(c, {
          index: index,
          parentArray: constraint,
          parentKind: "constraint",
        })
      ) ?? [];

    function mkItem(patternChildren: Array<ItemId>): PatternTreeItem {
      return {
        id: pattern.uuid,
        children: patternChildren.concat(constraintChildren),
        isExpanded: pattern.isExpanded,
        data: {
          kind: rp.kind,
          pattern: pattern,
          inArray: inArray,
          explanation: pattern.explanation,
        },
      };
    }
    switch (rp.kind) {
      case "pattern":
        item = mkItem([]);
        break;
      case "regex":
        item = mkItem([]);
        break;
      case "any":
      case "all": {
        const origchildren = rp.patterns;
        const children = rp.patterns.map((p, index) =>
          aux(p, {
            index,
            parentArray: origchildren,
            parentKind: rp.kind,
          })
        );
        item = mkItem(children);
        break;
      }
      case "inside":
      case "not": {
        const children = [aux(rp.pattern, null)].concat(constraintChildren);
        item = mkItem(children);
        break;
      }
    }
    table.set(pattern.uuid, item);
    return pattern.uuid;
  }

  // let's go!
  const rootId = aux(pattern, null);

  return { rootId: rootId, items: Object.fromEntries(table) };
}
