import { datadogRum } from "@datadog/browser-rum";

import { RegistryRule, RegistryTaintRule, RunResponse } from "@shared/types";
import { ID_BY_KEY } from "@shared/utils";
import { CliOutput, readCliOutput } from "@semgrep_output_types";

const TARGET_FILE = "/target";
const RULES_FILE = "/rules.json";

const PARSER_OVERRIDES: { [id: string]: string } = {
  xml: "html",
  clojure: "lisp",
  scheme: "lisp",
  js: "typescript",
  ts: "typescript",
};

const STATIC_ORIGIN =
  window.location.hostname === "semgrep.dev" ||
  window.location.hostname.endsWith(".semgrep.dev")
    ? "https://static.semgrep.dev"
    : window.location.origin;

const buildEngineUrl = (version: string, filename = "index.mjs") =>
  `${STATIC_ORIGIN}/static/turbo/${encodeURIComponent(
    encodeURIComponent(version)
  )}/engine/dist/${filename}`;
const buildParserUrl = (
  version: string,
  language: string,
  filename = "index.mjs"
) =>
  `${STATIC_ORIGIN}/static/turbo/${encodeURIComponent(
    encodeURIComponent(version)
  )}/languages/${language}/dist/${filename}`;

const getParserName = (lang: string) => {
  const id = ID_BY_KEY[lang] || lang;
  return PARSER_OVERRIDES[id] || id;
};

interface Parser {
  getLangs: () => string[];
  setMountpoints: (mountpoints: object[]) => void;
  parseTarget: (lang: string, filename: string) => any;
  parsePattern: (lang: string, pattern: string) => any;
}
interface Engine {
  lookupLang: (name: string) => string | null;
  addParser: (parser: Parser) => void;
  hasParser: (lang: string) => boolean;
  execute: (
    language: string,
    rulesFilename: string,
    root: string,
    targetFilenames: string[]
  ) => string;
  parsePattern: (lang: string, pattern: string) => any;
  writeFile: (filename: string, content: string) => void;
  deleteFile: (filename: string) => void;
  isMissingLanguages: () => boolean;
  getMissingLanguages: () => string[];
  clearMissingLanguages: () => void;
}

export const startTurboMode = async (version: string) => {
  const { EngineFactory } = await import(
    /* @vite-ignore */ buildEngineUrl(version)
  );
  const engine = await EngineFactory(
    buildEngineUrl(version, "semgrep-engine.wasm")
  );
  return new TurboMode(engine, version);
};

export class TurboMode {
  version: string;
  displayVersion: string;
  engine: Engine;

  language = "";
  dirty = false;
  running = false;

  constructor(engine: Engine, version: string) {
    this.version = version;
    this.displayVersion = version.replace("release-", "");
    this.engine = engine;
  }

  onTargetChange(value: string) {
    this.engine.writeFile("/target", value);
    this.dirty = true;
  }

  onRuleChange(rule: RegistryRule | RegistryTaintRule) {
    this.engine.writeFile(RULES_FILE, JSON.stringify({ rules: [rule] }));
    this.language = rule.languages[0];
    this.dirty = true;
  }

  // This is for guarding some computation which requires a language to be loaded.
  // We basically just try it until all languages that may be missing are loaded.
  async guardWithLoadLanguage<T>(inner_function: () => T): Promise<T> {
    let missingLangs: string[] = [];

    // this is hacky but lets guard against an infinite loop here
    for (let i = 0; i < 10; i++) {
      try {
        const result = inner_function();

        // eagerly return if semgrep isnt missing any languages
        if (!this.engine.isMissingLanguages()) {
          return result;
        }
      } catch (err) {
        if (!this.engine.isMissingLanguages()) {
          // a missing language parser could cause an exception
          // so lets only bubble up exceptions if all languages are satisfied
          throw err;
        }
      }

      // copy and reset the missing languages set, so we dont accumulate bogus langs
      missingLangs = this.engine.getMissingLanguages();
      // try loading all the missing langs
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const turboThis = this;
      await Promise.all(
        missingLangs.map((lang) => turboThis.loadLanguage(lang))
      );
      this.engine.clearMissingLanguages();
    }

    throw new Error(`Couldnt find parser for language(s): ${missingLangs}`);
  }

  async parsePattern(lang: string, pattern: string): Promise<boolean> {
    try {
      // We have to check for whether parsePattern exists, because it was only added
      // to the API in Turbo Mode versions past https://github.com/semgrep/semgrep/pull/10158
      if (this.engine.parsePattern) {
        await this.guardWithLoadLanguage(() => {
          const res = this.engine.parsePattern(lang, pattern);
          return res;
        });
      }
      // if we do not have the parsePattern, though, assume it parses
      return true;
      // I don't know what type the raised exception is, it's coming from the
      // compiled semgrep.js code
    } catch (e: any) {
      datadogRum.addAction("Pattern parsing failure", {
        pattern: pattern,
        lang: lang,
        exn: e,
        message: "Got exception on attempting to parse pattern.",
      });
      if (e && e[1] && e[1][1] === "Assert_failure") {
        // I have legitimately no idea why this is occurring.
        // I can't reproduce this assertion failure by using the pattern myself in the CLI.
        // It seems to originate from the corresponding tree-sitter Parse.ml

        return true;
      }
      return false;
    }
  }

  async loadLanguage(newLanguage: string) {
    const lang = this.engine.lookupLang(newLanguage);
    if (lang && !this.engine.hasParser(lang)) {
      const parserName = getParserName(lang);
      const { ParserFactory } = await import(
        /* @vite-ignore */ buildParserUrl(this.version, parserName)
      );
      const parser: Parser = await ParserFactory(
        buildParserUrl(this.version, parserName, "semgrep-parser.wasm")
      );
      this.engine.addParser(parser);
      this.dirty = true;
    }
  }

  shouldRun(): boolean {
    return this.dirty && !this.running;
  }

  execute(): CliOutput {
    // The signature of engine.execute() changed in https://github.com/returntocorp/semgrep/pull/8337 (v1.37.0)
    // In order to support both new and old semgrep.js builds, we first call engine.execute() the "old" way and examine its return type.
    // If a function is returned, we've encountered the "new" signature and we call the method again with different args.

    // TODO: remove this logic once versions before 1.37.0 are no longer supported.

    // @ts-ignore
    let rawResult = this.engine.execute(this.language, RULES_FILE, TARGET_FILE);

    if (typeof rawResult == "function") {
      rawResult = this.engine.execute(this.language, RULES_FILE, "/", [
        TARGET_FILE,
      ]);
    }

    const result = JSON.parse(rawResult);

    // TODO: figure out why this wasn't getting set
    result.paths = { scanned: [], skipped: [] };

    return readCliOutput(result);
  }

  async tryRun(): Promise<CliOutput> {
    return this.guardWithLoadLanguage(() => this.execute());
  }

  cliOutputToRunResponse(
    start: number,
    end: number,
    resp: CliOutput
  ): RunResponse {
    return {
      semgrep_result: {
        status_code: 0,
        output: resp,
        run_time: (end - start) / 1000.0,
        environment: "semgrep.js",
      },
      test_result: [],
      ai_autofixes: [],
    };
  }

  async run(): Promise<RunResponse> {
    try {
      this.running = true;
      const start = performance.now();
      const resp = await this.tryRun();
      this.dirty = false;
      const end = performance.now();

      return this.cliOutputToRunResponse(start, end, resp);
    } catch (err: any) {
      if (typeof err === "object" && err[0] === 0 && err[1][0] === 248) {
        throw new Error(err[2]);
      } else {
        throw err;
      }
    } finally {
      this.running = false;
    }
  }
}
