import * as monaco from "monaco-editor";

import { CliMatch } from "@semgrep_output_types";

import styles from "./utils.module.css";

export const makeProFindingGlyphs = (
  positiveFindings: CliMatch[],
  negativeFindings: CliMatch[]
) => {
  const positiveGlyphs = positiveFindings.map((m) => ({
    range: new monaco.Range(m.start.line, 1, m.end.line, 1),
    options: {
      glyphMarginClassName: `${styles.highlightPro} ${styles.filledStar}`,
      isWholeLine: true,
      glyphMarginHoverMessage: {
        supportHtml: true,
        value:
          'Finding found with the <a href="https://semgrep.dev/products/pro-engine/">Semgrep Pro Engine</a>',
      },
    },
  }));

  const negativeGlyphs = negativeFindings.map((m) => ({
    range: new monaco.Range(m.start.line, 1, m.end.line, 1),
    options: {
      glyphMarginClassName: `${styles.highlightPro} ${styles.unfilledStar}`,
      className: `${styles.highlight} ${styles.backdropProRemoved}`,
      isWholeLine: true,
      glyphMarginHoverMessage: {
        supportHtml: true,
        value:
          'Finding removed with the <a href="https://semgrep.dev/products/pro-engine/">Semgrep Pro Engine</a>',
      },
    },
  }));

  return [...positiveGlyphs, ...negativeGlyphs];
};
