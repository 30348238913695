import { useContext } from "react";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Group, Modal } from "@mantine/core";

import { WorkbenchContext } from "../../providers";

interface Props {
  // user is either switching from unlisted to private or vice versa
  isSwitchToPrivate: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const SwitchToUnlistedWarning = (
  <Alert
    color="yellow"
    title="Confirm to make this rule public"
    icon={<FontAwesomeIcon icon={faExclamationCircle} />}
  >
    <p>Anyone can access this rule if they have the link.</p>
  </Alert>
);

const SwitchToPrivateWarning = (
  <Alert
    color="yellow"
    title="Confirm to make this rule private"
    icon={<FontAwesomeIcon icon={faExclamationCircle} />}
  >
    <p>Users outside your organization will no longer access this rule.</p>
  </Alert>
);

export const ConfirmVisibilitySwitchDialog: React.FC<Props> = ({
  isSwitchToPrivate,
  onConfirm,
  onCancel,
}) => {
  const { workbench } = useContext(WorkbenchContext);
  return (
    <Modal
      opened={true}
      onClose={workbench.ui.closeRuleShareDialog}
      title={`Switch to ${isSwitchToPrivate ? "private" : "public"}`}
    >
      {isSwitchToPrivate ? SwitchToPrivateWarning : SwitchToUnlistedWarning}
      <Group justify="right" mt={16}>
        <Button variant="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          leftSection={<FontAwesomeIcon icon={faCheckCircle} />}
        >
          Confirm
        </Button>
      </Group>
    </Modal>
  );
};
