import {
  faAmpersand,
  faCode,
  faGripLinesVertical,
  faMinus,
  faPersonShelter,
  faText,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PatternKind } from "../types/rule";

export const OperatorIcon: React.FC<{ kind: PatternKind }> = ({ kind }) => {
  const iconColor = "var(--mantine-color-gray-6)";
  switch (kind) {
    case "all":
      return <FontAwesomeIcon color={iconColor} icon={faAmpersand} size="sm" />;
    case "any":
      return <FontAwesomeIcon color={iconColor} icon={faGripLinesVertical} />;
    case "pattern":
      return <FontAwesomeIcon color={iconColor} icon={faCode} size="sm" />;
    case "regex":
      return <FontAwesomeIcon color={iconColor} icon={faText} size="sm" />;
    case "inside":
      return (
        <FontAwesomeIcon color={iconColor} icon={faPersonShelter} size="sm" />
      );
    case "not":
      return <FontAwesomeIcon color={iconColor} icon={faMinus} size="sm" />;
  }
};
