import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { ItemId, RenderItemParams, TreeData } from "@atlaskit/tree";

import { MatchingExplanation, MatchSeverity } from "@semgrep_output_types";

/******************************************************************************/
/* Rules */
/******************************************************************************/

export class Rule {
  value: RuleInner;
  uuid: string;
  language: string;
  message: string;
  ruleID: string;
  severity: MatchSeverity;
  fix: string | null;
  // We keep this `extra` field, which just stores all the non-supported
  // top-level rule fields that are not specifically useful to Structure Mode
  // We store this so that when we go back to YAML, we can reconstruct
  // the original rule faithfully.
  extra: Map<string, any> | null;

  constructor(
    value: RuleInner,
    language: string,
    message: string,
    ruleID: string,
    severity: MatchSeverity = { kind: "Error" },
    fix: string | null = null,
    extra: Map<string, any> | null = null
  ) {
    this.value = value;
    this.language = language;
    this.message = message;
    this.uuid = uuidv4();
    this.ruleID = ruleID;
    this.fix = fix;
    this.severity = severity;
    this.extra = extra;
    // this didn't work with a default argument
    makeAutoObservable(this);
  }
}

export type RuleWithKind<K extends "search" | "taint"> = Rule & {
  value: {
    kind: K;
  };
};

export type RuleInner =
  | { kind: "search"; pattern: Pattern }
  | {
      kind: "taint";
      sources: Pattern[];
      sinks: Pattern[];
      sanitizers: Pattern[];
      // TODO: propagators
    };

/******************************************************************************/
/* Kinds */
/******************************************************************************/

export type PatternKind =
  | "any"
  | "all"
  | "inside"
  | "not"
  | "regex"
  | "pattern";
export type ConstraintKind = "focus" | "comparison" | "metavariable";
export type MetavariableConstraintKind =
  | "pattern"
  | "regex"
  | "analyzer"
  | "type";

export type PatternWithKind<K extends PatternKind> = Pattern & {
  value: {
    rawPattern: { value: { kind: K; patterns: Pattern[] } };
    constraint: PatternConstraint | null;
  };
};

export const isPatternWithKind = <K extends PatternKind>(
  kind: K,
  pattern: Pattern
): pattern is PatternWithKind<K> => {
  return pattern.value.rawPattern.value.kind === kind;
};

export type ConstraintWithKind<K extends ConstraintKind> = PatternConstraint & {
  value: { kind: K };
};

export const isConstraintWithKind = <K extends ConstraintKind>(
  kind: K,
  constraint: PatternConstraint
): constraint is ConstraintWithKind<K> => {
  return constraint.value.kind === kind;
};

export type MetavariableConstraintWithKind<
  K extends MetavariableConstraintKind
> = MetavariableConstraint & {
  value: { kind: K };
};

export const isMetavariableConstraintWithKind = <
  K extends MetavariableConstraintKind
>(
  kind: K,
  constraint: MetavariableConstraint
): constraint is MetavariableConstraintWithKind<K> => {
  return constraint.value.kind === kind;
};

/******************************************************************************/
/* Patterns types */
/******************************************************************************/

export class Pattern {
  value: PatternInner;
  isExpanded: boolean;
  isDisabled: boolean;
  uuid: string;
  explanation: MatchingExplanation | null;

  constructor(value: PatternInner) {
    this.value = value;
    this.uuid = uuidv4();
    this.isExpanded = true;
    this.isDisabled = false;
    this.explanation = null;
    makeAutoObservable(this);
  }
}

type PatternInner = {
  rawPattern: RawPattern;
  constraint: PatternConstraint[] | null;
};

export class RawPattern {
  value: RawPatternInner;
  uuid: string;

  constructor(value: RawPatternInner) {
    // in YAML, technically blocks like this:
    // pattern: |
    //   foo
    // have a newline at the very end
    // this is annoying in the UI, though, so let's get rid of them here.
    // This should only matter when we initially construct the pattern, though.
    if (value.kind === "pattern") {
      this.value = { kind: "pattern", pattern: value.pattern.trim() };
    } else {
      this.value = value;
    }
    this.uuid = uuidv4();
    makeAutoObservable(this);
  }
}

export type RawPatternInner =
  | { kind: "pattern"; pattern: string }
  | { kind: "any"; patterns: Pattern[] }
  | { kind: "all"; patterns: Pattern[] }
  | { kind: "inside"; pattern: Pattern }
  | { kind: "not"; pattern: Pattern }
  | { kind: "regex"; regex: string };

export class PatternConstraint {
  value: PatternConstraintInner;
  uuid: string;
  isExpanded: boolean;

  constructor(value: PatternConstraintInner) {
    this.value = value;
    this.uuid = uuidv4();
    this.isExpanded = true;
    makeAutoObservable(this);
  }
}

export type PatternConstraintInner =
  | { kind: "focus"; metavariables: string[] }
  | { kind: "comparison"; comparison: string }
  | {
      kind: "metavariable";
      metavariable: string;
      metavariableConstraints: MetavariableConstraint[];
    };

export class MetavariableConstraint {
  value: MetavariableConstraintInner;
  isExpanded: boolean;
  uuid: string;

  constructor(value: MetavariableConstraintInner) {
    this.value = value;
    this.uuid = uuidv4();
    this.isExpanded = true;
    makeAutoObservable(this);
  }
}

export type MetavariableConstraintInner =
  | { kind: "regex"; regex: string }
  | { kind: "pattern"; pattern: Pattern }
  | { kind: "analyzer"; analyzer: string }
  | { kind: "type"; type: string };

/******************************************************************************/
/* Drag and drop tree and items types */
/******************************************************************************/

export type InArray<T> = {
  parentKind: string;
  parentArray: T[];
  index: number;
};

export type PatternNodeData = {
  kind: PatternKind;
  pattern: Pattern;
  inArray: InArray<Pattern> | null;
  explanation: MatchingExplanation | null;
};

export type ConstraintNodeData = {
  kind: "ConstraintNode";
  constraint: PatternConstraint;
  inArray: InArray<PatternConstraint>;
};

export type MetavariableConstraintNodeData = {
  kind: "MetavariableConstraintNode";
  metavariableConstraint: MetavariableConstraint;
  inArray: InArray<MetavariableConstraint>;
};

export function isPatternNodeData(
  data: PatternNodeData | ConstraintNodeData | MetavariableConstraintNodeData
): data is PatternNodeData {
  switch (data.kind) {
    case "pattern":
    case "any":
    case "all":
    case "not":
    case "inside":
    case "regex":
      return true;
  }
  return false;
}

export function isConstraintNodeData(
  data: PatternNodeData | ConstraintNodeData | MetavariableConstraintNodeData
): data is ConstraintNodeData {
  switch (data.kind) {
    case "ConstraintNode":
      return true;
  }
  return false;
}

export function isMetavariableConstraintNodeData(
  data: PatternNodeData | ConstraintNodeData | MetavariableConstraintNodeData
): data is MetavariableConstraintNodeData {
  switch (data.kind) {
    case "MetavariableConstraintNode":
      return true;
  }
  return false;
}
export type PatternTreeItemWithKind<
  K extends PatternKind | "ConstraintNode" | "MetavariableConstraintNode"
> = PatternTreeItem & {
  data: { kind: K };
};

export const isPatternTreeItemWithKind = <
  K extends PatternKind | "ConstraintNode" | "MetavariableConstraintNode"
>(
  kind: K,
  pattern: PatternTreeItem
): pattern is PatternTreeItemWithKind<K> => {
  return pattern.data.kind === kind;
};

// We inline the `PatternTreeItem` type here, because doing an intersection of
// PatternTreeItem = TreeItem & { data: PatternNodeData | ... }
// was causing us to lose the type of `data`, and degenerate it to `any`.
export type PatternTreeItem = {
  id: ItemId;
  children: ItemId[];
  hasChildren?: boolean;
  isExpanded?: boolean;
  isChildrenLoading?: boolean;
  data: PatternNodeData | ConstraintNodeData | MetavariableConstraintNodeData;
};

export type PatternRenderItemParams = RenderItemParams & {
  item: PatternTreeItem;
};

export type PatternTreeData = TreeData & {
  items: Record<ItemId, PatternTreeItem>;
};

/******************************************************************************/
/* Pattern helpers */
/******************************************************************************/

export const emptyPattern = (text: string = "") =>
  new Pattern({
    rawPattern: new RawPattern({ kind: "pattern", pattern: text }),
    constraint: null,
  });

export const emptyConstraint = () =>
  new PatternConstraint(defaultConstraintOfKind("focus"));

export const emptyRule = () =>
  new Rule(
    {
      kind: "search",
      pattern: emptyPattern(),
    },
    "python",
    "foo",
    "empty-rule"
  );

export function defaultRawPatternInnerOfKind(
  kind: PatternKind,
  patstring: string = ""
): RawPatternInner {
  switch (kind) {
    case "pattern":
      return { kind: kind, pattern: patstring };
    case "regex":
      return { kind: kind, regex: patstring };
    case "any":
    case "all":
      return {
        kind: kind,
        patterns: [emptyPattern(patstring)],
      };
    case "inside":
    case "not":
      return {
        kind: kind,
        pattern: emptyPattern(patstring),
      };
  }
}

export function defaultMetavariableConstraintOfKind(
  kind: MetavariableConstraintKind
): MetavariableConstraintInner {
  switch (kind) {
    case "pattern":
      return { kind: kind, pattern: emptyPattern("") };
    case "regex":
      return { kind: kind, regex: "" };
    case "analyzer":
      return { kind: kind, analyzer: "" };
    case "type":
      return { kind: kind, type: "" };
  }
}

export function defaultConstraintOfKind(
  kind: ConstraintKind
): PatternConstraintInner {
  switch (kind) {
    case "focus":
      return { kind: kind, metavariables: [""] };
    case "comparison":
      return { kind: kind, comparison: "" };
    case "metavariable":
      return {
        kind: kind,
        metavariable: "",
        metavariableConstraints: [
          new MetavariableConstraint(
            defaultMetavariableConstraintOfKind("pattern")
          ),
        ],
      };
  }
}

/******************************************************************************/
/* Example rules */
/******************************************************************************/

export const exampleStructureRule = new Rule(
  {
    kind: "search",
    pattern: new Pattern({
      rawPattern: new RawPattern({
        kind: "any",
        patterns: [
          new Pattern({
            rawPattern: new RawPattern({
              kind: "pattern",
              pattern: "foo",
            }),
            constraint: null,
          }),
          new Pattern({
            rawPattern: new RawPattern({
              kind: "pattern",
              pattern: "bar",
            }),
            constraint: null,
          }),
        ],
      }),
      constraint: null,
    }),
  },
  "python",
  "message",
  "example-rule"
);

export const exampleTaintStructureRule = new Rule(
  {
    kind: "taint",
    sources: [
      new Pattern({
        rawPattern: new RawPattern({
          kind: "any",
          patterns: [
            new Pattern({
              rawPattern: new RawPattern({
                kind: "pattern",
                pattern: "source 1",
              }),
              constraint: null,
            }),
            new Pattern({
              rawPattern: new RawPattern({
                kind: "pattern",
                pattern: "source 2",
              }),
              constraint: null,
            }),
          ],
        }),
        constraint: null,
      }),
    ],
    sinks: [
      new Pattern({
        rawPattern: new RawPattern({
          kind: "any",
          patterns: [
            new Pattern({
              rawPattern: new RawPattern({
                kind: "pattern",
                pattern: "sink 1",
              }),
              constraint: null,
            }),
            new Pattern({
              rawPattern: new RawPattern({
                kind: "pattern",
                pattern: "sink 2",
              }),
              constraint: null,
            }),
          ],
        }),
        constraint: null,
      }),
    ],
    sanitizers: [
      new Pattern({
        rawPattern: new RawPattern({
          kind: "any",
          patterns: [
            new Pattern({
              rawPattern: new RawPattern({
                kind: "pattern",
                pattern: "sanitizer 1",
              }),
              constraint: null,
            }),
            new Pattern({
              rawPattern: new RawPattern({
                kind: "pattern",
                pattern: "sanitizer 2",
              }),
              constraint: null,
            }),
          ],
        }),
        constraint: null,
      }),
    ],
  },
  "python",
  "message",
  "example-taint-rule"
);
