import { useHistory } from "react-router";
import { showNotification } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";

import { authPost } from "@shared/api";
import { ApiError, OrgData } from "@shared/types";
import { queryClient } from "@shared/utils";

import { useChangeOrg } from "../useChangeOrg";
import { useRefreshUserAuth } from "../useRefreshUserAuth";

const joinDeployment = async (deploymentId: number): Promise<OrgData> => {
  return authPost<Record<string, never>, { deployment: OrgData }>(
    `/api/agent/deployments/${deploymentId}/users`,
    {}
  ).then((response) => {
    return response.deployment;
  });
};

interface MutationArgs {
  deploymentId: number;
  redirectOnSuccess: boolean;
}

/**
 * @param deploymentId the id of the deployment to join
 * @param redirectOnSuccess whether to redirect the user to the new org after
 * joining
 * @returns mutation to join a deployment. Will refresh the user's auth after
 * the deployment is successfully joined.
 */
export const useJoinDeploymentMutation = () => {
  const refreshUserAuth = useRefreshUserAuth();
  const changeOrg = useChangeOrg();
  const history = useHistory();

  return useMutation({
    mutationFn: ({ deploymentId }: MutationArgs) =>
      joinDeployment(deploymentId),
    onError: (err: ApiError) => {
      showNotification({
        message:
          err.message ||
          "There was an issue joining an organization with the provided name.",
      });
    },
    onSuccess: async (resp, args) => {
      // NOTE: Coupling with the `useUserOrgs` hook, we invalidate the current user's orgs
      queryClient.invalidateQueries({ queryKey: ["userDeployments"] });
      queryClient.invalidateQueries({ queryKey: ["userJoinableDeployments"] });
      await refreshUserAuth();

      // redirect user to the new org after joining
      if (args.redirectOnSuccess) {
        changeOrg(resp);
        history.push(`/orgs/${resp.slug}`);
      }
    },
  });
};
