import { useAtom } from "jotai";

import { changeOrgAtom } from "@shared/state";

/**
 * Used for switching to another org.
 * If you need to update the org object, use the setOrg function in useOrg.
 */
export const useChangeOrg = () => {
  const [, setChangeOrg] = useAtom(changeOrgAtom);

  return setChangeOrg;
};
