import { makeAutoObservable, reaction, toJS } from "mobx";
import * as monaco from "monaco-editor";
import { Monaco } from "@monaco-editor/react";

import {
  firstDifferentLine,
  fullstory,
  makeHighlightDec,
  makeHighlightLines,
} from "@shared/utils";
import { Location, MetavarValue } from "@semgrep_output_types";

import { augmentPatternWithExplanations } from "../components/StructureMode/utils/treeOfPattern";
import { codeSnippetsProvider } from "../providers/codeSnippetsProvider";
import { TAB_ID as RuleEditorMode } from "../types";
import { RuleValidationError } from "../types/ruleValidationError";
import { makeExplanationHighlight } from "../utils/makeExplanationHighlight";
import { makeExplanationsHoverProvider } from "../utils/makeExplanationsHoverProvider";
import { makeProFindingGlyphs } from "../utils/makeProFindingGlyphs";
import { makeTestAssertionGlyphs } from "../utils/makeTestAssertionGlyphs";
import { makeTaintViewZones } from "../utils/taintHovers";

import { ErrorResult, SuccessResult } from "./Result";
import { Workbench } from "./Workbench";

export class Editors {
  workbench: Workbench;
  ruleEditor?: monaco.editor.IStandaloneCodeEditor;
  ruleEditorValue?: string;
  ruleEditorMode?: RuleEditorMode;
  ruleMonaco?: Monaco;
  targetEditor?: monaco.editor.IStandaloneCodeEditor;
  targetDiffEditor?: monaco.editor.IStandaloneDiffEditor;
  targetDiffMonaco?: Monaco;
  targetEditorValue?: string;
  targetMonaco?: Monaco;
  activeExplanationHighlight?: monaco.editor.IEditorDecorationsCollection;
  // we associate the "name" of the operator to its decorations
  explanationHighlights: [string, monaco.editor.IModelDeltaDecoration[]][] = [];
  matchHighlights?: monaco.editor.IEditorDecorationsCollection;
  activeInlays?: monaco.IDisposable;
  activeExplanationHovers?: monaco.IDisposable;
  latestHighlightIds: string[] = [];
  latestTestAssertionGlyphs?: monaco.editor.IEditorDecorationsCollection;
  latestProFindingGlyphs?: monaco.editor.IEditorDecorationsCollection;
  resetHovers: monaco.IDisposable | undefined;
  viewZoneIds: string[] = [];

  constructor(workbench: Workbench) {
    this.workbench = workbench;
    makeAutoObservable(this, { workbench: false }, { autoBind: true });

    // whenever the ruleEditor or targetEditor change (like when a new rule is selected from
    // the sidebar) register the keyboard shortcuts
    reaction(
      () => [this.ruleEditor, this.targetEditor, this.workbench.bundle],
      this.registerKeyboardShortcuts
    );

    // whenever turbo mode is (de)activated, the rule changes, or the target
    // changes, update turbo mode
    // we also now wipe the explanation glyphs. the reason is that if you're
    // typing, you're unlikely to want to see the explanation glyphs, and this
    // fixes a bug where due to the changes in removeExplanationHighlights, you
    // could get "stuck" and have a highlight on the glyph that you could no
    // longer remove
    reaction(
      () => [
        this.workbench.turboModeEnabled,
        this.workbench.turboMode,
        this.workbench.bundle,
        this.workbench.bundle?.rule,
        this.workbench.bundle?.targetText,
        this.workbench.bundle?.structureRule,
      ],
      () => {
        this.turboModeUpdate();
        this.clearExplanationHighlights();
      }
    );

    // whenever the result changes, render the new highlights
    reaction(
      () => [
        this.workbench.result,
        this.workbench.result?.highlights,
        this.workbench.result?.taintTraces,
        this.workbench.result?.bundle.showingAutofixDiff,
      ],
      () => {
        this.renderTargetHighlights();
        this.renderTestAssertionGlyphs();
        this.renderProFindingGlyphs();
      }
    );

    // whenever just the result changes, render the matching explanation
    // badges for structure mode specifically
    // this would go in the above reaction, but that includes highlights, which
    // is dependent on the target changing. this means if a user edits the target,
    // we can get into an inconsistent state.
    reaction(
      () => [this.workbench.result],
      () => {
        this.renderStructureMatchingExplanationBadges();
      }
    );
  }

  turboModeUpdate() {
    if (
      !this.workbench.turboModeEnabled ||
      !this.workbench.turboMode ||
      !this.workbench.bundle ||
      !this.workbench.bundle.rule ||
      !this.workbench.bundle.targetText
    ) {
      return;
    }
    this.workbench.turboMode.onRuleChange(this.workbench.bundle.rule);
    this.workbench.turboMode.onTargetChange(this.workbench.bundle.targetText);
    if (this.workbench.turboMode.shouldRun()) {
      this.workbench.bundle.run();
    }
  }

  // onMount

  onRuleEditorMount(
    editorInstance: monaco.editor.IStandaloneCodeEditor,
    monacoInstance: Monaco
  ) {
    this.ruleEditor = editorInstance;
    this.ruleMonaco = monacoInstance;
    this.registerCodeSnippets(); // This just needs to be called once
  }

  onTargetEditorMount(
    editorInstance: monaco.editor.IStandaloneCodeEditor,
    monacoInstance: Monaco
  ) {
    this.targetEditor = editorInstance;
    this.targetMonaco = monacoInstance;

    this.renderTargetHighlights();
    this.renderTestAssertionGlyphs();
    this.renderProFindingGlyphs();
    this.focusFirstResult();
  }

  onTargetDiffEditorMount(
    diffEditorInstance: monaco.editor.IStandaloneDiffEditor,
    monacoInstance: Monaco
  ) {
    this.targetDiffEditor = diffEditorInstance;
    this.targetDiffMonaco = monacoInstance;

    const modified = this.targetDiffEditor.getModel()?.modified?.getValue();
    this.focusDiffTargetLine(modified);
  }

  // onChange

  onRuleEditorChange(value: string) {
    this.ruleEditorValue = value;
  }

  onTargetEditorChange(value: string) {
    this.targetEditorValue = value;
  }

  // N.B.: diff editor cannot change

  // actions

  registerKeyboardShortcuts() {
    if (this.ruleEditor && this.targetEditor && this.workbench.bundle) {
      this.ruleEditor.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
        this.workbench.bundle.run
      );
      this.targetEditor.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
        this.workbench.bundle.run
      );

      this.ruleEditor.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS,
        this.workbench.saveOrFork
      );
      this.targetEditor.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS,
        this.workbench.saveOrFork
      );
      const descriptor = {
        id: "fullstoryLog",
        run: (...args: any[]) => {
          const label = args[1];
          fullstory.event("snippet-selected", { label: label });
        },
      };
      monaco.editor.addCommand(descriptor);
    }
  }

  registerCodeSnippets() {
    if (!this.ruleMonaco) return;
    this.ruleMonaco.languages.registerCompletionItemProvider("yaml", {
      provideCompletionItems: codeSnippetsProvider,
    });
  }

  // For Structure Mode, specifically.
  // This updates the Structure Mode pattern tree with the matching explanations
  // generated by running the rule.
  // We must do this here, because we can mutate the structure of the rule without
  // running it, such as by using the delete button.
  // When this occurs, we do not want to reapply the changes from the explanations
  // generated from the structure of the rule when it was run, as they are now
  // out-of-date.
  // So only do this when the result has truly changed.
  renderStructureMatchingExplanationBadges() {
    const result = this.workbench.result;
    if (
      this.workbench.bundle?.structureRule &&
      result instanceof SuccessResult &&
      result?.explanations
    ) {
      const explanations = result.explanations;
      const rule = this.workbench.bundle?.structureRule;
      if (rule?.value.kind === "search") {
        augmentPatternWithExplanations(
          rule?.value.pattern,
          explanations.length > 0 ? explanations[0] : null
        );
      } else if (this.workbench.bundle?.structureRule?.value.kind === "taint") {
        const sourceExplanations =
          explanations[0]?.children.find((exp) => exp.op.kind === "TaintSource")
            ?.children ?? [];

        const sinkExplanations =
          explanations[0]?.children.find((exp) => exp.op.kind === "TaintSink")
            ?.children ?? [];

        const sanitizerExplanations =
          explanations[0]?.children.find(
            (exp) => exp.op.kind === "TaintSanitizer"
          )?.children ?? [];

        rule?.value.sources.map((source, index) =>
          augmentPatternWithExplanations(source, sourceExplanations[index])
        );

        rule?.value.sinks.map((sink, index) =>
          augmentPatternWithExplanations(sink, sinkExplanations[index])
        );

        rule?.value.sanitizers.map((sanitizer, index) =>
          augmentPatternWithExplanations(
            sanitizer,
            sanitizerExplanations[index]
          )
        );
      }
    }
  }

  renderTargetHighlights() {
    const result = this.workbench.result;
    if (this.workbench.bundle === null) return;
    if (this.workbench.bundle.language === null) return;

    if (this.targetEditor === undefined) return;
    if (this.targetMonaco === undefined) return;

    const highlights = result?.highlights.filter((hl) => !hl.isError) || [];

    const highlightedWords = makeHighlightDec(highlights);
    const highlightedLines = makeHighlightLines(highlights, this.targetMonaco);

    this.matchHighlights?.clear();
    this.matchHighlights = this.targetEditor.createDecorationsCollection([
      ...highlightedLines,
      ...highlightedWords,
    ]);

    this.activeExplanationHovers?.dispose();
    if (
      result instanceof SuccessResult &&
      result?.explanations &&
      result.explanations.length > 0
    ) {
      const lang = this.targetEditor.getModel()?.getLanguageId() || "";
      const ranges = result.findings.map(
        (f) =>
          new monaco.Range(f.start.line, f.start.col, f.end.line, f.end.col)
      );
      const hoverProvider = makeExplanationsHoverProvider(
        result.explanations,
        ranges
      );
      this.activeExplanationHovers =
        this.targetMonaco.languages.registerHoverProvider(lang, hoverProvider);
    }
    this.latestHighlightIds = this.targetEditor.deltaDecorations(
      this.latestHighlightIds,
      [...highlightedLines, ...highlightedWords]
    );

    if (result?.taintTraces) {
      this.targetEditor.changeViewZones((changeAccessor) => {
        this.viewZoneIds.forEach(changeAccessor.removeZone);
        this.viewZoneIds = makeTaintViewZones(toJS(result?.taintTraces)).map(
          changeAccessor.addZone
        );
      });
    }
  }

  addExplanationHighlights(name: string, highlights: [string, Location][]) {
    if (this.targetMonaco === undefined) return;

    const targetMonaco = this.targetMonaco;
    const highlightedAreas = highlights.flatMap(([key, span]) => {
      const highlight = makeExplanationHighlight(key, span);
      const highlightedWords = makeHighlightDec([highlight]);
      const highlightedLines = makeHighlightLines([highlight], targetMonaco);
      return [...highlightedLines, ...highlightedWords];
    });

    this.explanationHighlights.push([name, highlightedAreas]);
  }

  // we take in the "name" of the operator so we know we are deleting the right entry
  // if we just naively pop, it is possible that we can do the following sequence:
  // HOVER over pattern1
  // HOVER over pattern2
  // NO LONGER HOVER over pattern1
  // which would result in us adding the glyphs for pattern2, and then immediately
  // popping them when we no longer hover over pattern1
  // by keeping the name, we search for pattern1's explanation so we can actually
  // pop that instead
  removeExplanationHighlights(name: string) {
    for (let i = this.explanationHighlights.length - 1; i >= 0; --i) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [name2, _highlights] = this.explanationHighlights[i];
      if (name === name2) {
        this.explanationHighlights.splice(i, 1);
        return;
      }
    }
  }

  clearExplanationHighlights() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [name, _highlights] of this.explanationHighlights) {
      this.removeExplanationHighlights(name);
    }
  }

  renderExplanationHighlights() {
    if (this.targetEditor === undefined) return;
    if (this.targetMonaco === undefined) return;

    this.activeExplanationHighlight?.clear();
    if (this.explanationHighlights.length > 0) {
      this.activeExplanationHighlight =
        this.targetEditor.createDecorationsCollection(
          this.explanationHighlights[this.explanationHighlights.length - 1][1]
        );
    }
  }

  renderInlayHints(metavars: [string, MetavarValue][]) {
    if (this.targetEditor === undefined) return;
    if (this.workbench.bundle?.language === null) return;

    const language = this.workbench.bundle?.language || "";
    const targetEditor = this.targetEditor;
    this.activeInlays?.dispose();
    this.activeInlays = monaco.languages.registerInlayHintsProvider(language, {
      provideInlayHints(model, _range, _token) {
        if (targetEditor?.getModel() !== model)
          return { hints: [], dispose: () => {} };
        const hints = metavars.map(([label, value]) => {
          return {
            label: label + ":",
            position: {
              lineNumber: value.start.line,
              column: value.start.col,
            },
            kind: monaco.languages.InlayHintKind.Parameter,
            paddingRight: true,
          };
        });
        return { hints: hints, dispose: () => {} };
      },
    });
  }

  // "findings", but it also includes removed findings
  renderProFindingGlyphs() {
    const result = this.workbench.result;
    if (this.workbench.bundle === null) return;

    if (this.targetEditor === undefined) return;

    if (!result || result instanceof ErrorResult) return;

    // There are no pro findings glyphs if this is unset.
    if (!result.differentialResults) return;

    this.latestProFindingGlyphs?.clear();
    if (
      result.differentialResults.proOnlyFindings.length === 0 &&
      result.differentialResults.proRemovedFindings.length === 0
    )
      return;
    const glyphs = makeProFindingGlyphs(
      result.differentialResults.proOnlyFindings,
      result.differentialResults.proRemovedFindings
    );
    if (glyphs.length === 0) return;
    this.latestProFindingGlyphs =
      this.targetEditor.createDecorationsCollection(glyphs);
  }

  renderTestAssertionGlyphs() {
    const result = this.workbench.result;
    if (this.workbench.bundle === null) return;

    if (this.targetEditor === undefined) return;

    if (!result || result instanceof ErrorResult) return;

    const actualMatches = result.matchLines;
    const extraneousMatches = result.unexpectedMatchLines;
    const { expectedMatches, expectedNotMatches } = this.workbench.bundle;

    this.latestTestAssertionGlyphs?.clear();
    if (expectedMatches.length === 0 && expectedNotMatches.length === 0) return;
    const glyphs = makeTestAssertionGlyphs(
      expectedMatches,
      expectedNotMatches,
      actualMatches,
      extraneousMatches
    );
    if (glyphs.length === 0) return;
    this.latestTestAssertionGlyphs =
      this.targetEditor.createDecorationsCollection(glyphs);
  }

  focusTargetLine(line: number) {
    this.targetEditor?.revealLineInCenter(line);
  }

  focusFirstResult() {
    const highlights = this.workbench.result?.highlights;
    if (highlights && highlights.length > 0) {
      const line = highlights[0].startLine;
      if (line) {
        this.targetEditor?.revealLineInCenter(line);
      }
    }
  }

  focusDiffTargetLine(newText: string | undefined) {
    if (this.targetDiffEditor) {
      const original = this.targetDiffEditor.getModel()?.original?.getValue();
      if (original && newText) {
        const firstDiffLine = firstDifferentLine(original, newText);
        this.targetDiffEditor.revealLineInCenter(firstDiffLine);
      }
    }
  }

  // Use yaml language server to validate yaml
  validateRule(): RuleValidationError[] {
    if (!this.ruleMonaco || !this.ruleEditor) return [];
    const uri = this.ruleEditor?.getModel()?.uri;
    if (!uri) return [];
    // Gets any and all error markers from the rule editor
    const markers = this.ruleMonaco.editor.getModelMarkers({
      // Only get errors from the rule file
      resource: uri,
    });
    // Map language server errors to our error type
    const errors = markers?.map((marker) => {
      const severity: "Error" | "Warning" =
        marker.severity === monaco.MarkerSeverity.Error ? "Error" : "Warning";
      return {
        message: marker.message,
        severity: severity,
        source: marker.source,
        startPos: {
          lineno: marker.startLineNumber,
          col: marker.startColumn,
        },
        endPos: {
          lineno: marker.endLineNumber,
          col: marker.endColumn,
        },
      };
    });
    return errors || [];
  }
}
