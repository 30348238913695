import { Rule } from "@shared/types";

import { authGet } from "../auth";

export const fetchCustomRules = (deployment_id: number): Promise<Rule[]> => {
  interface CustomRulesResponse {
    rules: Rule[];
  }

  return authGet<CustomRulesResponse>(
    `/api/registry/orgs/${deployment_id}/rules`
  ).then((res) => res.rules);
};

export const fetchRule = (id: string): Promise<Rule> => {
  const data: Record<string, string> = { definition: "1", test_cases: "1" };

  return authGet<Rule>(
    `/api/registry/rules/${id}?${new URLSearchParams(data).toString()}`
  );
};
