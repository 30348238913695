import { useContext } from "react";
import { Link } from "react-router-dom";
import { faCabinetFiling } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Group, Modal, Text } from "@mantine/core";

import { WorkbenchContext } from "../../providers";

import { RuleSharePreview } from "./RuleSharePreview";

/**
 * Share dialog for existing public rules (registry rules)
 */
export const RegistryRuleShareDialog = () => {
  const { workbench } = useContext(WorkbenchContext);
  return (
    <Modal
      opened={true}
      onClose={workbench.ui.closeRuleShareDialog}
      title="Share public rule"
    >
      <RuleSharePreview
        input={"https://semgrep.dev/r/" + workbench.address!.fullName}
      />
      <Text size="xs" mt={4} ml={4}>
        Visible in registry.
      </Text>
      <Group justify="flex-end" mt={16}>
        <Button component={Link} to={"/r/" + workbench.address!.fullName}>
          <FontAwesomeIcon
            icon={faCabinetFiling}
            style={{ marginRight: "8px" }}
          />
          See in Registry
        </Button>
      </Group>
    </Modal>
  );
};
