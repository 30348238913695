import { Link } from "react-router-dom";
import {
  Anchor,
  Box,
  Button,
  Container,
  Group,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";

import { useBasePath } from "@shared/hooks";

import { NotFoundBackgroundImage } from "./NotFoundBackgroundImage";

import styles from "./NotFound.module.css";

// Component based on: https://ui.mantine.dev/component/nothing-found-background
export const NotFound = () => {
  const basePath = useBasePath();
  const theme = useMantineTheme();

  return (
    <Container pt={80} pb={80}>
      <Box style={{ position: "relative" }}>
        <NotFoundBackgroundImage
          style={{ position: "absolute", top: 80, opacity: 0.75, zIndex: 0 }}
        />
        <Box
          className={styles.content}
          style={{ position: "relative", zIndex: 1 }}
        >
          <Title fw="bold" fz={40} ta="center">
            Nothing to see here
          </Title>
          <Text
            c="dimmed"
            size="lg"
            ta="center"
            maw={540}
            m="auto"
            mt={theme.spacing.xl}
            mb={`calc(${theme.spacing?.xl} * 1.5)`}
          >
            Page you are trying to open does not exist. You may have mistyped
            the address, or do not have permission to view it. If you think this
            is an error,{" "}
            <Anchor inherit href={`${basePath}/support`}>
              contact support
            </Anchor>
            .
          </Text>

          <Group justify="center">
            <Button component={Link} size="md" to={`${basePath}`}>
              Take me back to dashboard
            </Button>
          </Group>
        </Box>
      </Box>
    </Container>
  );
};
