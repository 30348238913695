import { Highlight } from "@shared/types";
import { makeHighlight } from "@shared/utils";
import { Location } from "@semgrep_output_types";

export const makeExplanationHighlight = (
  key: string,
  span: Location,
  type?: string
): Highlight => {
  const start = span?.start ?? { line: 1, col: 0 };
  return {
    ...makeHighlight(start, span?.end),
    key: `${key}-${start.line}-${start.col}`,
    isError: false,
    isDebug: true,
    type: type ?? "debug-range",
    group: "test",
    message: "test message",
  };
};
