import { PolicyWithRules } from "@shared/types";

export interface PolicyOverrides {
  block?: PolicyWithRules;
  audit?: PolicyWithRules;
  comment?: PolicyWithRules;
}

// TODO: These constants aren't really used anywhere except to check against a slug,
// but we can use these in the future for writing tests
export const BLOCKING_POLICY_NAME: string = "Rule Board - Block column";
export const AUDIT_POLICY_NAME: string = "Rule Board - Audit column";
export const PR_COMMENTS_POLICY_NAME: string =
  "Rule Board - PR Comments column";
export const MONITOR_POLICY_NAME: string = "Rule Board - Monitor column";

export const SECRETS_BLOCKING_POLICY_NAME = "Secrets Rule Board - Block column";
export const SECRETS_AUDIT_POLICY_NAME = "Secrets Rule Board - Audit column";
export const SECRETS_PR_COMMENTS_POLICY_NAME =
  "Secrets Rule Board - PR Comments column";

export const BLOCKING_POLICY: PolicyWithRules = {
  name: BLOCKING_POLICY_NAME,
  slug: "rule-board-block",
  rules: [],
  is_default: false,
  notifications: [],
  blocking: true,
  description: "",
  repos: [],
  pr_comments: true,
  overrides: [],
};

export const SECRETS_BLOCKING_POLICY: PolicyWithRules = {
  name: SECRETS_BLOCKING_POLICY_NAME,
  slug: "rule-board-secrets-block",
  rules: [],
  is_default: false,
  notifications: [],
  blocking: true,
  description: "",
  repos: [],
  pr_comments: true,
  overrides: [],
};

export const AUDIT_POLICY: PolicyWithRules = {
  name: AUDIT_POLICY_NAME,
  slug: "rule-board-audit",
  rules: [
    // If you update these, also update them in backend!
    { type: "pack", id: "default", overrides: [] },
    { type: "pack", id: "comment", overrides: [] },
  ],
  is_default: false,
  notifications: [],
  blocking: false,
  description: "",
  repos: [],
  pr_comments: false,
  overrides: [],
};

export const SECRETS_AUDIT_POLICY: PolicyWithRules = {
  name: SECRETS_AUDIT_POLICY_NAME,
  slug: "rule-board-secrets-audit",
  rules: [
    // If you update these, also update them in backend!
    { type: "pack", id: "semgrep-secrets", overrides: [] },
  ],
  is_default: false,
  notifications: [],
  blocking: false,
  description: "",
  repos: [],
  pr_comments: false,
  overrides: [],
};

export const PR_COMMENTS_POLICY: PolicyWithRules = {
  name: PR_COMMENTS_POLICY_NAME,
  slug: "rule-board-pr-comments",
  rules: [],
  is_default: false,
  notifications: [],
  blocking: false,
  description: "",
  repos: [],
  pr_comments: true,
  overrides: [],
};

export const SECRETS_PR_COMMENTS_POLICY: PolicyWithRules = {
  name: SECRETS_PR_COMMENTS_POLICY_NAME,
  slug: "rule-board-secrets-pr-comments",
  rules: [],
  is_default: false,
  notifications: [],
  blocking: false,
  description: "",
  repos: [],
  pr_comments: true,
  overrides: [],
};

export const DEFAULT_POLICY_MODES = [
  // in the order that they should be displayed
  AUDIT_POLICY,
  PR_COMMENTS_POLICY,
  BLOCKING_POLICY,
];

export const NO_GROUPING = "list";
export const GROUP_BY_VULN_CATEGORY = "group";
export const DEFAULT_VIEW = GROUP_BY_VULN_CATEGORY;

export const GLOBAL_POLICY_SLUG = "global-policy";
export const GLOBAL_SECRETS_POLICY_SLUG = "secrets-global-policy";
// Not used; added for completeness
export const GLOBAL_SUPPLY_CHAIN_POLICY_SLUG = "ssc-global-policy";
