import { useContext, useEffect, useState } from "react";
import {
  faCloudUpload,
  faDownload,
  faExclamationTriangle,
  faQuestionCircle,
  faSave,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Center,
  Divider,
  Flex,
  Loader,
  Modal,
  Switch,
  Text,
  Tooltip,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";

import { publishRule, updateRuleVisibility } from "@shared/api";
import { Code, Subheading } from "@shared/components";
import { SnippetAddress } from "@shared/editorCore";
import { useOrg } from "@shared/hooks";
import { downloadFile, withToaster } from "@shared/utils";

import { WorkbenchContext } from "../../providers";

import { ConfirmVisibilitySwitchDialog } from "./ConfirmVisibilitySwitchDialog";
import { PrCreatedDialog } from "./PrCreatedDialog";
import { PublicRuleChecklist } from "./PublicRuleChecklist";
import { RegistryRuleShareDialog } from "./RegistryRuleShareDialog";
import { RuleSharePreview } from "./RuleSharePreview";
import { VisibilityToggle } from "./VisibilityToggle";

export type ChangeVisibilityAction =
  | "unlisted"
  | "org_private"
  | "public" // repo, goes to publish flow
  | undefined;

/**
 * Component that encapsulates all the rule share dialog states
 * This is a complicated component with many uses. You can
 * - change a rule's visibility
 * - publish a rule to the registry (happens when you set visibility to public)
 * - get a permalink (creates a snippet)
 */
export const RuleShareDialog = () => {
  const [org] = useOrg();
  const { workbench } = useContext(WorkbenchContext);

  const visibility = workbench.bundle?.visibility;
  const address = workbench.address;

  const [action, setAction] = useState<ChangeVisibilityAction>(
    workbench.ui.ruleShareDialogIsPublic ? "public" : undefined
  );
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [prURL, setPrURL] = useState<string | undefined>(undefined);
  const [addressFragment, setAddressFragment] = useState<string | undefined>(
    undefined
  );

  const mustBeSnippetLink =
    workbench.user === undefined || address instanceof SnippetAddress;
  const [isSnippetLink, setIsSnippetLink] =
    useState<boolean>(mustBeSnippetLink);
  const [isLoadingLink, setIsLoadingLink] = useState(false);
  const isSecretsRule = workbench.bundle?.isSecretsRule ?? false;

  // Required to block out non-team-tier permissions:

  // content for the tooltip that appears if the publish to registry button is disabled
  let publishToRegistryTooltipContent = undefined;
  if (workbench.user === undefined) {
    publishToRegistryTooltipContent =
      "Log in to contribute to the Semgrep Registry";
  } else if (workbench.hasUnsavedChanges) {
    publishToRegistryTooltipContent =
      "Save your changes first before publishing";
  } else if (workbench.isDefaultNewRule) {
    publishToRegistryTooltipContent = "Write a rule to be able to publish it";
  } else if (isSecretsRule) {
    publishToRegistryTooltipContent =
      "Secrets rules cannot be published to the registry";
  }

  useEffect(() => {
    if (isSnippetLink) {
      // generate snippet link
      setIsLoadingLink(true);
      workbench.bundle
        ?.saveSnippet()
        .then((resp) => {
          const newAddress = `s/${resp}`;
          if (addressFragment !== newAddress) {
            setAddressFragment(newAddress);
          }
        })
        .catch((err) => {
          setAddressFragment(undefined);
          showNotification({
            message: `Failed to save rule: ${err}`,
            color: "red",
          });
        })
        .finally(() => setIsLoadingLink(false));
    } else if (address) {
      setAddressFragment(address.addressFragment());
    } else {
      setAddressFragment(undefined);
    }
    // disablign exhaustive deps because we don't want this to run every time bundle changes
    // eslint-disable-next-line
  }, [isSnippetLink, address]);

  /**
   * Creates PR using publish api
   */
  const onCreatePRClick = () => {
    // todo check for unsaved changes
    // cannot create a PR if there are unsaved changes, or if the address or rule
    // don't exist.
    if (
      workbench.hasUnsavedChanges ||
      !address ||
      !workbench.bundle?.rule ||
      isSecretsRule
    )
      return;
    setIsPublishing(true);
    withToaster(
      () => publishRule(address.fullName),
      (err) => {
        setIsPublishing(false);
        return `Error publishing: ${err.message}`;
      }
    )().then(({ url }) => {
      setIsPublishing(false);
      setPrURL(url);
    });
  };

  const onSaveChangeClick = (newVisibility: "unlisted" | "org_private") => {
    // should not get here if there is no valid address to save changes to or the rule has a validator
    if (!address || isSecretsRule) return;

    setIsPublishing(true);
    withToaster(
      () => {
        if (!workbench.bundle) return Promise.reject("No bundle to save");
        return updateRuleVisibility(workbench.bundle.hashId!, newVisibility);
      },
      (err) => {
        setIsPublishing(false);
        setAction(undefined);
        return `Error updating: ${err.message}`;
      }
    )().then(() => {
      if (!workbench.addressString) return;

      Promise.all([
        workbench.fetchBundle(workbench.addressString),
        workbench.fileRoot.reloadSnippetsAndRulesets(),
      ]).then(() => {
        setIsPublishing(false);
      });
    });
  };

  if (prURL) {
    return (
      <PrCreatedDialog
        onClose={workbench.ui.closeRuleShareDialog}
        prURL={prURL}
      />
    );
  } else if (isPublishing) {
    // publishing loading state
    return (
      <Modal
        opened={true}
        onClose={workbench.ui.closeRuleShareDialog}
        title="Processing"
      >
        <Center>
          <Loader size="xl" my={8} />
        </Center>
      </Modal>
    );
  } else if (action === "org_private" || action === "unlisted") {
    return (
      <ConfirmVisibilitySwitchDialog
        isSwitchToPrivate={action === "org_private"}
        onCancel={() => setAction(undefined)}
        onConfirm={() => {
          onSaveChangeClick(action);
          setAction(undefined);
        }}
      />
    );
  } else if (visibility === "public") {
    return <RegistryRuleShareDialog />;
  } else if (action === "public") {
    return (
      <PublicRuleChecklist
        onCreatePRClick={onCreatePRClick}
        workbench={workbench}
        onClose={workbench.ui.closeRuleShareDialog}
      />
    );
  }

  // will get here if action === undefined (user is not trying to change the visibility)
  // and the rule is not a public registry rule
  return (
    <Modal
      opened={true}
      size="auto"
      onClose={workbench.ui.closeRuleShareDialog}
      title="Share your rule"
    >
      <div style={{ marginBottom: "16px" }}>
        {isLoadingLink ? (
          <RuleSharePreview input={"Generating link..."} />
        ) : addressFragment ? (
          <RuleSharePreview
            input={`https://semgrep.dev/playground/${addressFragment}`}
          />
        ) : address === undefined ? (
          // no saved version of the rule to show the address of
          <RuleSharePreview
            input={"Save your rule first!"}
            leftElement={
              <FontAwesomeIcon icon={faSave} style={{ margin: "0 4px" }} />
            }
          />
        ) : (
          <RuleSharePreview
            input={"Invalid rule error, try running your rule!"}
            leftElement={
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{ margin: "0 4px" }}
              />
            }
          />
        )}
        <Text size="xs" mt={4} ml={4}>
          {visibility === "unlisted" || isSnippetLink
            ? "Visible to your organization and to anyone with the link"
            : `Visible only to logged in users of ${
                org?.display_name || org?.name
              }.`}
        </Text>
      </div>
      <VisibilityToggle
        allowPrivate={!isSnippetLink}
        allowPublic={!isSecretsRule}
        visibility={visibility!}
        setAction={setAction}
      />
      <Switch
        checked={isSnippetLink}
        onChange={() => setIsSnippetLink(!isSnippetLink)}
        disabled={mustBeSnippetLink || isSecretsRule}
        style={{ margin: "0 0 0 16px" }}
        display={"inline-block"}
        label={
          <span>
            Permalink{" "}
            <Tooltip
              withArrow
              withinPortal
              multiline
              w={300}
              label={
                <>
                  <div>
                    Permalinks go to this particular version of the rule.
                    Changes to the rule won't be reflected at this link.
                  </div>
                  {workbench.user === undefined && (
                    <div>
                      To create share links that include updates to rules, log
                      in.
                    </div>
                  )}
                </>
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} size={"xs"} />
            </Tooltip>
          </span>
        }
      />
      {isSnippetLink && (
        <Text size="xs" mt={4} ml={4} c="red">
          Permalinks are viewable by anyone with a link.
        </Text>
      )}
      <Divider mt={16} />
      <Subheading>To run this rule locally:</Subheading>
      <p>Step 1: install the CLI tool</p>
      <Code copyable>pip install semgrep</Code>
      <p>Step 2: login to the CLI tool</p>
      <Code copyable>semgrep login</Code>
      <p style={{ marginTop: "12px" }}>Step 3: Scan a code directory</p>
      <Code copyable>{`semgrep --config ${workbench.addressString}`}</Code>
      <Divider mt={16} />
      <Flex
        gap={8}
        p={16}
        mt={16}
        style={{ backgroundColor: "var(--mantine-color-gray-4)" }}
        align="center"
      >
        <Text mr={16}>Share your rule with the Semgrep community</Text>
        <Tooltip
          label={publishToRegistryTooltipContent}
          disabled={publishToRegistryTooltipContent === undefined}
          position="left"
          withArrow
          withinPortal
        >
          <div>
            <Button
              onClick={() => {
                setAction("public");
              }}
              variant="outline"
              disabled={
                workbench.isDefaultNewRule ||
                workbench.hasUnsavedChanges ||
                isSecretsRule
              }
              leftSection={<FontAwesomeIcon icon={faCloudUpload} />}
            >
              Publish to Registry
            </Button>
          </div>
        </Tooltip>
        <Button
          variant="outline"
          aria-label="Download rule"
          onClick={() =>
            downloadFile(
              workbench.bundle?.rule?.id + ".yaml",
              workbench.bundle?.ruleText ?? ""
            )
          }
        >
          <FontAwesomeIcon icon={faDownload} />
        </Button>
      </Flex>
    </Modal>
  );
};
