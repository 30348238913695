import { List } from "@mantine/core";

export interface TreeNode<T = {}> {
  /** A unique identifier for the node. */
  id: string | number;
  /** The main label for the node. */
  label: string | JSX.Element;
  /** Child tree nodes of this node. */
  childNodes?: Array<TreeNode<T>>;
  /**
   * Whether this tree node is non-interactive. Enabling this prop will ignore
   * mouse event handlers (in particular click, down, enter, leave).
   */
  disabled?: boolean;
  /**
   * Whether the caret to expand/collapse a node should be shown.
   * If not specified, this will be true if the node has children and false
   * otherwise.
   */
  hasCaret?: boolean;
  /** Whether this node is expanded. */
  isExpanded?: boolean;
  /** Whether this node is selected. */
  isSelected?: boolean;
}

interface Props {
  /** The data specifying the contents and appearance of the tree. */
  contents: ReadonlyArray<TreeNode>;
}

export const Tree: React.FC<Props> = ({ contents }) => {
  return (
    <List
      listStyleType="none"
      size="md"
      pl={12}
      styles={{ itemWrapper: { width: "100%" }, itemLabel: { width: "100%" } }}
    >
      {contents.map((node) => (
        <List.Item key={node.id}>
          {node.label}
          {node.isExpanded && node.childNodes ? (
            <Tree contents={node.childNodes} />
          ) : (
            ""
          )}
        </List.Item>
      ))}
    </List>
  );
};

export const TreeContainer: React.FC<Props> = ({ contents }) => {
  return (
    <div
      style={{
        paddingRight: 4,
        width: "100%",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Tree contents={contents} />
    </div>
  );
};
