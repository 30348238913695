import { Ruleset, Visibility } from "@shared/types";

import { authGet } from "../auth";

export function fetchMultitypeRulesets(
  types: Visibility[]
): Promise<Ruleset[]> {
  return authGet<Ruleset[]>(
    `/api/registry/rulesets?types=${types.join(",")}&rule_ids=1&definition=1`
  );
}
