import { useContext, useEffect } from "react";
import { toJS } from "mobx";
import { faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InlineCodeHighlight } from "@mantine/code-highlight";
import { Accordion, Badge, Group } from "@mantine/core";
import { useHover } from "@mantine/hooks";

import {
  Location,
  MatchingExplanation,
  MetavarValue,
} from "@semgrep_output_types";

import { WorkbenchContext } from "../../providers";
import { Editors } from "../../stores";
import { opToRemaining, opToSyntax } from "../../utils/opToSyntax";

import styles from "./RuleInspectorPanel.module.css";

function getExplanationName(explanation: MatchingExplanation) {
  return `${explanation.op}-${explanation.loc.start.line}.${explanation.loc.start.col}-${explanation.loc.end.line}.${explanation.loc.end.col}`;
}

// const MONACO_THEME = {
//   plain: {
//     color: "#0451A5",
//   },
//   styles: [
//     {
//       types: ["atrule"],
//       style: {
//         color: "#008080",
//       },
//     },
//   ],
// };

export function renderExplanationHighlights(
  hovered: boolean,
  explanation: MatchingExplanation | null,
  editors: Editors
) {
  if (explanation) {
    const name = getExplanationName(explanation);
    if (hovered) {
      const matchHighlights: [string, Location][] = explanation.matches.map(
        (match) => {
          const name = `${match.check_id}-${match.start.line}.${match.start.col}-${match.end.line}.${match.end.col}`;
          return [
            name,
            {
              path: match.start.line.toString(),
              start: match.start,
              end: match.end,
            },
          ];
        }
      );
      editors.addExplanationHighlights(name, matchHighlights);
      // toJS needed because this is a Mobx proxy
      const metavars: [string, MetavarValue][] = toJS(
        explanation.matches
      ).flatMap((m) => {
        // Convert from Map to Array
        return Array.from(m.extra.metavars.entries());
      });
      editors.renderInlayHints(metavars);
    } else {
      editors.removeExplanationHighlights(name);
      editors.renderInlayHints([]);
    }
    editors.renderExplanationHighlights();
  }
}

interface OperationItemProps {
  explanation: MatchingExplanation;
  topLevel?: boolean;
}
const OperationItem = ({ explanation, topLevel }: OperationItemProps) => {
  const { workbench } = useContext(WorkbenchContext);
  const { hovered, ref } = useHover();

  const name = getExplanationName(explanation);
  useEffect(() => {
    if (hovered) {
      const matchHighlights: [string, Location][] = explanation.matches.map(
        (match) => {
          const name = `${match.check_id}-${match.start.line}.${match.start.col}-${match.end.line}.${match.end.col}`;
          return [
            name,
            {
              path: match.start.line.toString(),
              start: match.start,
              end: match.end,
            },
          ];
        }
      );
      workbench.editors.addExplanationHighlights(name, matchHighlights);
      // toJS needed because this is a Mobx proxy
      const metavars: [string, MetavarValue][] = toJS(
        explanation.matches
      ).flatMap((m) => {
        // Convert from Map to Array
        return Array.from(m.extra.metavars.entries());
      });
      workbench.editors.renderInlayHints(metavars);
    } else {
      workbench.editors.removeExplanationHighlights(name);
      workbench.editors.renderInlayHints([]);
    }
    workbench.editors.renderExplanationHighlights();
  }, [hovered, explanation, workbench.editors, name]);

  const op = explanation.op;
  const operation = (topLevel ? "" : "- ") + opToSyntax(op);
  const remainingOps = opToRemaining(op);
  const children = explanation.children;
  return (
    <Accordion.Item value={name} ref={ref}>
      <Accordion.Control
        chevron={
          children.length > 0 ? (
            <FontAwesomeIcon icon={faAngleUp} size="xs" />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          ) // Needed, as undefined will use default chevron, and we want none
        }
        disabled={children.length === 0}
      >
        <Group gap="xs" wrap="nowrap">
          <Badge
            variant="light"
            size="xs"
            color={explanation.matches.length > 0 ? "green" : "red"}
          >
            {explanation.matches.length}
          </Badge>
          <InlineCodeHighlight
            language="yaml"
            code={`${operation}: ${remainingOps}`}
            classNames={{
              code: styles.code,
            }}
            //noCopy
            //getPrismTheme...
          />
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        {children.map((child, i) => (
          <OperationItem explanation={child} key={i} />
        ))}
      </Accordion.Panel>
    </Accordion.Item>
  );
};

export default OperationItem;
