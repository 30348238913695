import { FC, useCallback, useState } from "react";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faCopy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Box, CopyButton, Group, Tooltip } from "@mantine/core";

export interface HoverCopyButtonProps {
  enabled?: boolean;
  copyText?: string;
  tooltipText?: string;
  iconSize?: SizeProp;
  children?: React.ReactNode;
}

/**
 * A copy button that only appears when you hover over its children.
 * @param enabled - set to false to render the children directly without the copy button
 * @param copyText - the text to be copied
 * @param tooltipText - the text appearing in the tooltip that appears when hovering over the copy icon
 * @param iconSize - size of the copy icon
 */
export const HoverCopyButton: FC<HoverCopyButtonProps> = ({
  copyText,
  tooltipText = "Copy",
  iconSize,
  children,
}) => {
  const [displayCopyButton, setDisplayCopyButton] = useState<boolean>(false);
  const [closeTimeout, setCloseTimeout] =
    useState<ReturnType<typeof setTimeout>>();

  // using a delay here with a timeout to remove the glitch that happens when
  // the user hovers from the target to the popover
  const delayedSetOpen = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        if (closeTimeout) {
          clearTimeout(closeTimeout);
          setCloseTimeout(undefined);
        }
        setDisplayCopyButton(true);
      } else {
        setCloseTimeout(
          setTimeout(() => {
            setDisplayCopyButton(false);
          }, 300)
        );
      }
    },
    [closeTimeout]
  );

  return (
    <Group gap={6}>
      <Box
        onMouseEnter={() => delayedSetOpen(true)}
        onMouseLeave={() => delayedSetOpen(false)}
      >
        {children}
      </Box>
      {copyText && tooltipText && (
        <CopyButton value={copyText} timeout={1200}>
          {({ copied, copy }) => (
            <Tooltip label={copied ? "Copied" : tooltipText} position="right">
              <ActionIcon
                size="xs"
                color={copied ? "teal" : "gray"}
                onMouseEnter={() => delayedSetOpen(true)}
                onMouseLeave={() => delayedSetOpen(false)}
                onClick={copy}
                style={{
                  visibility: displayCopyButton ? undefined : "hidden",
                }}
                variant="transparent"
              >
                <FontAwesomeIcon
                  icon={copied ? faCheck : faCopy}
                  size={iconSize}
                />
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      )}
    </Group>
  );
};
