import styled from "styled-components";

import { mobileMq } from "./utils";

export const GradientTransparencyWrapper = styled.div<{
  direction: "top" | "bottom" | undefined;
}>`
  ${({ direction }) =>
    direction &&
    `
      -webkit-mask-image: -webkit-gradient(
        linear,
        left ${direction === "bottom" ? "top" : "bottom"},
        left ${direction === "bottom" ? "bottom" : "top"},
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
      );
      @media (${mobileMq}) {
        -webkit-mask-image: -webkit-gradient(
          linear,
          ${direction === "bottom" ? "left" : "right"} top,
          ${direction === "bottom" ? "right" : "left"} top,
          from(rgba(0, 0, 0, 1)),
          to(rgba(0, 0, 0, 0))
        );
      }
    `}
`;
