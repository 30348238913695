import { useCallback, useMemo } from "react";
import { useLocalStorage } from "@mantine/hooks";
import { usePrefab } from "@prefab-cloud/prefab-cloud-react";

// Update the values in Prefab.
// Instructions: https://www.notion.so/semgrep/How-to-update-the-announcement-banner-b729290b24f24e12ac9c51dc1f5d833b
export const useAnnouncement = () => {
  const { get, loading } = usePrefab();
  const key = get("announcement.key") as string;
  const text = get("announcement.text") as string;
  const linkText = get("announcement.link.text") as string;
  const linkUrl = get("announcement.link.url") as string;

  const [_dismissed, _setDismissed] = useLocalStorage({
    key: key,
    defaultValue: "false",
  });

  const dismissed = _dismissed === "true";
  const setDismissed = useCallback(
    (value: boolean) => _setDismissed(String(value)),
    [_setDismissed]
  );

  return useMemo(
    () => ({
      loading,
      key,
      text,
      link: {
        text: linkText,
        url: linkUrl,
      },
      dismissed,
      setDismissed,
    }),
    [loading, key, text, linkText, linkUrl, dismissed, setDismissed]
  );
};
