// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/scm/v1/scm.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import {
  GithubEntityType,
  githubEntityTypeFromJSON,
  githubEntityTypeToJSON,
  githubEntityTypeToNumber,
  ScmType,
  scmTypeFromJSON,
  scmTypeToJSON,
  scmTypeToNumber,
} from "../../common/v1/common";

export const protobufPackage = "protos.scm.v1";

export interface ScmApp {
  id: number;
  slug: string;
  appId: number;
  scmType: ScmType;
  baseUrl: string;
  requestId: string;
  scmName: string;
  codeAccess: boolean;
}

export interface ScmStatus {
  checked: Date | undefined;
  ok: boolean;
  error?: string | undefined;
}

export interface ScmTokenScopes {
  readMetadata: boolean;
  readPullRequest: boolean;
  writePullRequestComment: boolean;
  readContents: boolean;
  readMembers: boolean;
  manageWebhooks: boolean;
}

export interface ScmConfig {
  /** the unique id of the config */
  id: number;
  /** the type of the source code manager that this config is for */
  type: ScmType;
  /** the namespace of the config (e.g. GitHub org, GitLab group, etc.) */
  namespace: string;
  sourceId: string;
  baseUrl: string;
  /** for the private app only */
  app?:
    | ScmApp
    | undefined;
  /** most recent status written to the db */
  status?: ScmStatus | undefined;
  installed: boolean;
  suspended: boolean;
  /** the type of GitHub entity (org or personal) */
  githubEntityType: GithubEntityType;
  autoScan?: boolean | undefined;
  useNetworkBroker: boolean;
  tokenScopes?: ScmTokenScopes | undefined;
  subscriptions: ScmWebhookSubscription[];
  lastSuccessfulSyncAt?: Date | undefined;
  scmId?: number | undefined;
}

export interface GetScmAppRequest {
  deploymentId: number;
  requestId: string;
}

export interface GetScmAppResponse {
  app: ScmApp | undefined;
}

export interface ListScmAppsRequest {
  deploymentId: number;
}

export interface ListScmAppsResponse {
  apps: ScmApp[];
}

export interface ListScmConfigRequest {
  deploymentId: number;
  limit?: number | undefined;
  cursor?: string | undefined;
}

export interface ListScmConfigResponse {
  /** a list of SCM configs for this deployment */
  configs: ScmConfig[];
  cursor?: string | undefined;
}

export interface CreateScmConfigRequest {
  deploymentId: number;
  type: ScmType;
  namespace: string;
  sourceId?: string | undefined;
  baseUrl: string;
  accessToken?: string | undefined;
  scmConfigId?: number | undefined;
  subscribe?: boolean | undefined;
  autoScan?: boolean | undefined;
}

export interface CreateScmConfigResponse {
  config: ScmConfig | undefined;
}

export interface PatchScmConfigRequest {
  deploymentId: number;
  configId: number;
  accessToken?: string | undefined;
  autoScan?: boolean | undefined;
  useNetworkBroker?: boolean | undefined;
  namespace?: string | undefined;
  subscribe?: boolean | undefined;
}

export interface PatchScmConfigResponse {
  config: ScmConfig | undefined;
}

export interface DeleteScmConfigRequest {
  deploymentId: number;
  configId: number;
}

export interface DeleteScmConfigResponse {
}

export interface CheckScmConfigRequest {
  deploymentId: number;
  configId: number;
  checkScopes?: boolean | undefined;
}

export interface CheckScmConfigResponse {
  status: ScmStatus | undefined;
  tokenScopes?: ScmTokenScopes | undefined;
}

export interface SyncScmConfigRequest {
  deploymentId: number;
  configId: number;
}

export interface SyncScmConfigResponse {
  /** Encoded TaskToken */
  taskTokenJwt: string;
}

export interface CreateScmAppRequestRequest {
  deploymentId: number;
  deploymentScmConfigId: number;
  baseUrl: string;
  namespace: string;
  githubEntityType: GithubEntityType;
}

export interface CreateScmAppRequestResponse {
  requestId: string;
}

export interface CompleteScmAppRequestRequest {
  requestId: string;
  code: string;
}

export interface CompleteScmAppRequestResponse {
  app: ScmApp | undefined;
}

export interface PatchScmConfigWebhookRequest {
  deploymentId: number;
  configId: number;
}

export interface PatchScmConfigWebhookResponse {
  success: boolean;
}

export interface ScmWebhookSubscription {
  scmUrl: string;
  events: string[];
}

export interface ScmWebhookSubscriptionSubscribeRequest {
  deploymentId: number;
  configId: number;
}

export interface ScmWebhookSubscriptionSubscribeResponse {
  deploymentId: number;
  configId: number;
  subscription: ScmWebhookSubscription | undefined;
}

export interface ScmWebhookSubscriptionUnsubscribeRequest {
  deploymentId: number;
  configId: number;
}

export interface ScmWebhookSubscriptionUnsubscribeResponse {
  deploymentId: number;
  configId: number;
  subscription: ScmWebhookSubscription | undefined;
}

export interface ListScmWebhookSubscriptionRequest {
  deploymentId: number;
  configId: number;
}

export interface ListScmWebhookSubscriptionResponse {
  deploymentId: number;
  configId: number;
  subscriptions: ScmWebhookSubscription[];
}

function createBaseScmApp(): ScmApp {
  return {
    id: 0,
    slug: "",
    appId: 0,
    scmType: ScmType.UNSPECIFIED,
    baseUrl: "",
    requestId: "",
    scmName: "",
    codeAccess: false,
  };
}

export const ScmApp = {
  encode(message: ScmApp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.slug !== "") {
      writer.uint32(18).string(message.slug);
    }
    if (message.appId !== 0) {
      writer.uint32(24).uint64(message.appId);
    }
    if (message.scmType !== ScmType.UNSPECIFIED) {
      writer.uint32(32).int32(scmTypeToNumber(message.scmType));
    }
    if (message.baseUrl !== "") {
      writer.uint32(42).string(message.baseUrl);
    }
    if (message.requestId !== "") {
      writer.uint32(58).string(message.requestId);
    }
    if (message.scmName !== "") {
      writer.uint32(66).string(message.scmName);
    }
    if (message.codeAccess !== false) {
      writer.uint32(72).bool(message.codeAccess);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScmApp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScmApp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.slug = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.appId = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.scmType = scmTypeFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.baseUrl = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.requestId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.scmName = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.codeAccess = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScmApp {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      slug: isSet(object.slug) ? globalThis.String(object.slug) : "",
      appId: isSet(object.appId) ? globalThis.Number(object.appId) : 0,
      scmType: isSet(object.scmType) ? scmTypeFromJSON(object.scmType) : ScmType.UNSPECIFIED,
      baseUrl: isSet(object.baseUrl) ? globalThis.String(object.baseUrl) : "",
      requestId: isSet(object.requestId) ? globalThis.String(object.requestId) : "",
      scmName: isSet(object.scmName) ? globalThis.String(object.scmName) : "",
      codeAccess: isSet(object.codeAccess) ? globalThis.Boolean(object.codeAccess) : false,
    };
  },

  toJSON(message: ScmApp): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.slug !== "") {
      obj.slug = message.slug;
    }
    if (message.appId !== 0) {
      obj.appId = Math.round(message.appId);
    }
    if (message.scmType !== ScmType.UNSPECIFIED) {
      obj.scmType = scmTypeToJSON(message.scmType);
    }
    if (message.baseUrl !== "") {
      obj.baseUrl = message.baseUrl;
    }
    if (message.requestId !== "") {
      obj.requestId = message.requestId;
    }
    if (message.scmName !== "") {
      obj.scmName = message.scmName;
    }
    if (message.codeAccess !== false) {
      obj.codeAccess = message.codeAccess;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScmApp>, I>>(base?: I): ScmApp {
    return ScmApp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScmApp>, I>>(object: I): ScmApp {
    const message = createBaseScmApp();
    message.id = object.id ?? 0;
    message.slug = object.slug ?? "";
    message.appId = object.appId ?? 0;
    message.scmType = object.scmType ?? ScmType.UNSPECIFIED;
    message.baseUrl = object.baseUrl ?? "";
    message.requestId = object.requestId ?? "";
    message.scmName = object.scmName ?? "";
    message.codeAccess = object.codeAccess ?? false;
    return message;
  },
};

function createBaseScmStatus(): ScmStatus {
  return { checked: undefined, ok: false, error: undefined };
}

export const ScmStatus = {
  encode(message: ScmStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.checked !== undefined) {
      Timestamp.encode(toTimestamp(message.checked), writer.uint32(10).fork()).ldelim();
    }
    if (message.ok !== false) {
      writer.uint32(16).bool(message.ok);
    }
    if (message.error !== undefined) {
      writer.uint32(26).string(message.error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScmStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScmStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.checked = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.ok = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.error = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScmStatus {
    return {
      checked: isSet(object.checked) ? fromJsonTimestamp(object.checked) : undefined,
      ok: isSet(object.ok) ? globalThis.Boolean(object.ok) : false,
      error: isSet(object.error) ? globalThis.String(object.error) : undefined,
    };
  },

  toJSON(message: ScmStatus): unknown {
    const obj: any = {};
    if (message.checked !== undefined) {
      obj.checked = message.checked.toISOString();
    }
    if (message.ok !== false) {
      obj.ok = message.ok;
    }
    if (message.error !== undefined) {
      obj.error = message.error;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScmStatus>, I>>(base?: I): ScmStatus {
    return ScmStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScmStatus>, I>>(object: I): ScmStatus {
    const message = createBaseScmStatus();
    message.checked = object.checked ?? undefined;
    message.ok = object.ok ?? false;
    message.error = object.error ?? undefined;
    return message;
  },
};

function createBaseScmTokenScopes(): ScmTokenScopes {
  return {
    readMetadata: false,
    readPullRequest: false,
    writePullRequestComment: false,
    readContents: false,
    readMembers: false,
    manageWebhooks: false,
  };
}

export const ScmTokenScopes = {
  encode(message: ScmTokenScopes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.readMetadata !== false) {
      writer.uint32(8).bool(message.readMetadata);
    }
    if (message.readPullRequest !== false) {
      writer.uint32(16).bool(message.readPullRequest);
    }
    if (message.writePullRequestComment !== false) {
      writer.uint32(24).bool(message.writePullRequestComment);
    }
    if (message.readContents !== false) {
      writer.uint32(32).bool(message.readContents);
    }
    if (message.readMembers !== false) {
      writer.uint32(40).bool(message.readMembers);
    }
    if (message.manageWebhooks !== false) {
      writer.uint32(48).bool(message.manageWebhooks);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScmTokenScopes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScmTokenScopes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.readMetadata = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.readPullRequest = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.writePullRequestComment = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.readContents = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.readMembers = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.manageWebhooks = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScmTokenScopes {
    return {
      readMetadata: isSet(object.readMetadata) ? globalThis.Boolean(object.readMetadata) : false,
      readPullRequest: isSet(object.readPullRequest) ? globalThis.Boolean(object.readPullRequest) : false,
      writePullRequestComment: isSet(object.writePullRequestComment)
        ? globalThis.Boolean(object.writePullRequestComment)
        : false,
      readContents: isSet(object.readContents) ? globalThis.Boolean(object.readContents) : false,
      readMembers: isSet(object.readMembers) ? globalThis.Boolean(object.readMembers) : false,
      manageWebhooks: isSet(object.manageWebhooks) ? globalThis.Boolean(object.manageWebhooks) : false,
    };
  },

  toJSON(message: ScmTokenScopes): unknown {
    const obj: any = {};
    if (message.readMetadata !== false) {
      obj.readMetadata = message.readMetadata;
    }
    if (message.readPullRequest !== false) {
      obj.readPullRequest = message.readPullRequest;
    }
    if (message.writePullRequestComment !== false) {
      obj.writePullRequestComment = message.writePullRequestComment;
    }
    if (message.readContents !== false) {
      obj.readContents = message.readContents;
    }
    if (message.readMembers !== false) {
      obj.readMembers = message.readMembers;
    }
    if (message.manageWebhooks !== false) {
      obj.manageWebhooks = message.manageWebhooks;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScmTokenScopes>, I>>(base?: I): ScmTokenScopes {
    return ScmTokenScopes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScmTokenScopes>, I>>(object: I): ScmTokenScopes {
    const message = createBaseScmTokenScopes();
    message.readMetadata = object.readMetadata ?? false;
    message.readPullRequest = object.readPullRequest ?? false;
    message.writePullRequestComment = object.writePullRequestComment ?? false;
    message.readContents = object.readContents ?? false;
    message.readMembers = object.readMembers ?? false;
    message.manageWebhooks = object.manageWebhooks ?? false;
    return message;
  },
};

function createBaseScmConfig(): ScmConfig {
  return {
    id: 0,
    type: ScmType.UNSPECIFIED,
    namespace: "",
    sourceId: "",
    baseUrl: "",
    app: undefined,
    status: undefined,
    installed: false,
    suspended: false,
    githubEntityType: GithubEntityType.UNSPECIFIED,
    autoScan: undefined,
    useNetworkBroker: false,
    tokenScopes: undefined,
    subscriptions: [],
    lastSuccessfulSyncAt: undefined,
    scmId: undefined,
  };
}

export const ScmConfig = {
  encode(message: ScmConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.type !== ScmType.UNSPECIFIED) {
      writer.uint32(16).int32(scmTypeToNumber(message.type));
    }
    if (message.namespace !== "") {
      writer.uint32(26).string(message.namespace);
    }
    if (message.sourceId !== "") {
      writer.uint32(34).string(message.sourceId);
    }
    if (message.baseUrl !== "") {
      writer.uint32(42).string(message.baseUrl);
    }
    if (message.app !== undefined) {
      ScmApp.encode(message.app, writer.uint32(50).fork()).ldelim();
    }
    if (message.status !== undefined) {
      ScmStatus.encode(message.status, writer.uint32(58).fork()).ldelim();
    }
    if (message.installed !== false) {
      writer.uint32(64).bool(message.installed);
    }
    if (message.suspended !== false) {
      writer.uint32(72).bool(message.suspended);
    }
    if (message.githubEntityType !== GithubEntityType.UNSPECIFIED) {
      writer.uint32(80).int32(githubEntityTypeToNumber(message.githubEntityType));
    }
    if (message.autoScan !== undefined) {
      writer.uint32(88).bool(message.autoScan);
    }
    if (message.useNetworkBroker !== false) {
      writer.uint32(96).bool(message.useNetworkBroker);
    }
    if (message.tokenScopes !== undefined) {
      ScmTokenScopes.encode(message.tokenScopes, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.subscriptions) {
      ScmWebhookSubscription.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    if (message.lastSuccessfulSyncAt !== undefined) {
      Timestamp.encode(toTimestamp(message.lastSuccessfulSyncAt), writer.uint32(122).fork()).ldelim();
    }
    if (message.scmId !== undefined) {
      writer.uint32(792).uint64(message.scmId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScmConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScmConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = scmTypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.namespace = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sourceId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.baseUrl = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.app = ScmApp.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.status = ScmStatus.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.installed = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.suspended = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.githubEntityType = githubEntityTypeFromJSON(reader.int32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.autoScan = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.useNetworkBroker = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.tokenScopes = ScmTokenScopes.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.subscriptions.push(ScmWebhookSubscription.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.lastSuccessfulSyncAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 99:
          if (tag !== 792) {
            break;
          }

          message.scmId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScmConfig {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      type: isSet(object.type) ? scmTypeFromJSON(object.type) : ScmType.UNSPECIFIED,
      namespace: isSet(object.namespace) ? globalThis.String(object.namespace) : "",
      sourceId: isSet(object.sourceId) ? globalThis.String(object.sourceId) : "",
      baseUrl: isSet(object.baseUrl) ? globalThis.String(object.baseUrl) : "",
      app: isSet(object.app) ? ScmApp.fromJSON(object.app) : undefined,
      status: isSet(object.status) ? ScmStatus.fromJSON(object.status) : undefined,
      installed: isSet(object.installed) ? globalThis.Boolean(object.installed) : false,
      suspended: isSet(object.suspended) ? globalThis.Boolean(object.suspended) : false,
      githubEntityType: isSet(object.githubEntityType)
        ? githubEntityTypeFromJSON(object.githubEntityType)
        : GithubEntityType.UNSPECIFIED,
      autoScan: isSet(object.autoScan) ? globalThis.Boolean(object.autoScan) : undefined,
      useNetworkBroker: isSet(object.useNetworkBroker) ? globalThis.Boolean(object.useNetworkBroker) : false,
      tokenScopes: isSet(object.tokenScopes) ? ScmTokenScopes.fromJSON(object.tokenScopes) : undefined,
      subscriptions: globalThis.Array.isArray(object?.subscriptions)
        ? object.subscriptions.map((e: any) => ScmWebhookSubscription.fromJSON(e))
        : [],
      lastSuccessfulSyncAt: isSet(object.lastSuccessfulSyncAt)
        ? fromJsonTimestamp(object.lastSuccessfulSyncAt)
        : undefined,
      scmId: isSet(object.scmId) ? globalThis.Number(object.scmId) : undefined,
    };
  },

  toJSON(message: ScmConfig): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.type !== ScmType.UNSPECIFIED) {
      obj.type = scmTypeToJSON(message.type);
    }
    if (message.namespace !== "") {
      obj.namespace = message.namespace;
    }
    if (message.sourceId !== "") {
      obj.sourceId = message.sourceId;
    }
    if (message.baseUrl !== "") {
      obj.baseUrl = message.baseUrl;
    }
    if (message.app !== undefined) {
      obj.app = ScmApp.toJSON(message.app);
    }
    if (message.status !== undefined) {
      obj.status = ScmStatus.toJSON(message.status);
    }
    if (message.installed !== false) {
      obj.installed = message.installed;
    }
    if (message.suspended !== false) {
      obj.suspended = message.suspended;
    }
    if (message.githubEntityType !== GithubEntityType.UNSPECIFIED) {
      obj.githubEntityType = githubEntityTypeToJSON(message.githubEntityType);
    }
    if (message.autoScan !== undefined) {
      obj.autoScan = message.autoScan;
    }
    if (message.useNetworkBroker !== false) {
      obj.useNetworkBroker = message.useNetworkBroker;
    }
    if (message.tokenScopes !== undefined) {
      obj.tokenScopes = ScmTokenScopes.toJSON(message.tokenScopes);
    }
    if (message.subscriptions?.length) {
      obj.subscriptions = message.subscriptions.map((e) => ScmWebhookSubscription.toJSON(e));
    }
    if (message.lastSuccessfulSyncAt !== undefined) {
      obj.lastSuccessfulSyncAt = message.lastSuccessfulSyncAt.toISOString();
    }
    if (message.scmId !== undefined) {
      obj.scmId = Math.round(message.scmId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScmConfig>, I>>(base?: I): ScmConfig {
    return ScmConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScmConfig>, I>>(object: I): ScmConfig {
    const message = createBaseScmConfig();
    message.id = object.id ?? 0;
    message.type = object.type ?? ScmType.UNSPECIFIED;
    message.namespace = object.namespace ?? "";
    message.sourceId = object.sourceId ?? "";
    message.baseUrl = object.baseUrl ?? "";
    message.app = (object.app !== undefined && object.app !== null) ? ScmApp.fromPartial(object.app) : undefined;
    message.status = (object.status !== undefined && object.status !== null)
      ? ScmStatus.fromPartial(object.status)
      : undefined;
    message.installed = object.installed ?? false;
    message.suspended = object.suspended ?? false;
    message.githubEntityType = object.githubEntityType ?? GithubEntityType.UNSPECIFIED;
    message.autoScan = object.autoScan ?? undefined;
    message.useNetworkBroker = object.useNetworkBroker ?? false;
    message.tokenScopes = (object.tokenScopes !== undefined && object.tokenScopes !== null)
      ? ScmTokenScopes.fromPartial(object.tokenScopes)
      : undefined;
    message.subscriptions = object.subscriptions?.map((e) => ScmWebhookSubscription.fromPartial(e)) || [];
    message.lastSuccessfulSyncAt = object.lastSuccessfulSyncAt ?? undefined;
    message.scmId = object.scmId ?? undefined;
    return message;
  },
};

function createBaseGetScmAppRequest(): GetScmAppRequest {
  return { deploymentId: 0, requestId: "" };
}

export const GetScmAppRequest = {
  encode(message: GetScmAppRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.requestId !== "") {
      writer.uint32(18).string(message.requestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetScmAppRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetScmAppRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.requestId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetScmAppRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      requestId: isSet(object.requestId) ? globalThis.String(object.requestId) : "",
    };
  },

  toJSON(message: GetScmAppRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.requestId !== "") {
      obj.requestId = message.requestId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetScmAppRequest>, I>>(base?: I): GetScmAppRequest {
    return GetScmAppRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetScmAppRequest>, I>>(object: I): GetScmAppRequest {
    const message = createBaseGetScmAppRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.requestId = object.requestId ?? "";
    return message;
  },
};

function createBaseGetScmAppResponse(): GetScmAppResponse {
  return { app: undefined };
}

export const GetScmAppResponse = {
  encode(message: GetScmAppResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.app !== undefined) {
      ScmApp.encode(message.app, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetScmAppResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetScmAppResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.app = ScmApp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetScmAppResponse {
    return { app: isSet(object.app) ? ScmApp.fromJSON(object.app) : undefined };
  },

  toJSON(message: GetScmAppResponse): unknown {
    const obj: any = {};
    if (message.app !== undefined) {
      obj.app = ScmApp.toJSON(message.app);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetScmAppResponse>, I>>(base?: I): GetScmAppResponse {
    return GetScmAppResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetScmAppResponse>, I>>(object: I): GetScmAppResponse {
    const message = createBaseGetScmAppResponse();
    message.app = (object.app !== undefined && object.app !== null) ? ScmApp.fromPartial(object.app) : undefined;
    return message;
  },
};

function createBaseListScmAppsRequest(): ListScmAppsRequest {
  return { deploymentId: 0 };
}

export const ListScmAppsRequest = {
  encode(message: ListScmAppsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListScmAppsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListScmAppsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListScmAppsRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0 };
  },

  toJSON(message: ListScmAppsRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListScmAppsRequest>, I>>(base?: I): ListScmAppsRequest {
    return ListScmAppsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListScmAppsRequest>, I>>(object: I): ListScmAppsRequest {
    const message = createBaseListScmAppsRequest();
    message.deploymentId = object.deploymentId ?? 0;
    return message;
  },
};

function createBaseListScmAppsResponse(): ListScmAppsResponse {
  return { apps: [] };
}

export const ListScmAppsResponse = {
  encode(message: ListScmAppsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.apps) {
      ScmApp.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListScmAppsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListScmAppsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.apps.push(ScmApp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListScmAppsResponse {
    return { apps: globalThis.Array.isArray(object?.apps) ? object.apps.map((e: any) => ScmApp.fromJSON(e)) : [] };
  },

  toJSON(message: ListScmAppsResponse): unknown {
    const obj: any = {};
    if (message.apps?.length) {
      obj.apps = message.apps.map((e) => ScmApp.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListScmAppsResponse>, I>>(base?: I): ListScmAppsResponse {
    return ListScmAppsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListScmAppsResponse>, I>>(object: I): ListScmAppsResponse {
    const message = createBaseListScmAppsResponse();
    message.apps = object.apps?.map((e) => ScmApp.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListScmConfigRequest(): ListScmConfigRequest {
  return { deploymentId: 0, limit: undefined, cursor: undefined };
}

export const ListScmConfigRequest = {
  encode(message: ListScmConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.limit !== undefined) {
      writer.uint32(16).uint64(message.limit);
    }
    if (message.cursor !== undefined) {
      writer.uint32(26).string(message.cursor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListScmConfigRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListScmConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.limit = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cursor = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListScmConfigRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : undefined,
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
    };
  },

  toJSON(message: ListScmConfigRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.limit !== undefined) {
      obj.limit = Math.round(message.limit);
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListScmConfigRequest>, I>>(base?: I): ListScmConfigRequest {
    return ListScmConfigRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListScmConfigRequest>, I>>(object: I): ListScmConfigRequest {
    const message = createBaseListScmConfigRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.limit = object.limit ?? undefined;
    message.cursor = object.cursor ?? undefined;
    return message;
  },
};

function createBaseListScmConfigResponse(): ListScmConfigResponse {
  return { configs: [], cursor: undefined };
}

export const ListScmConfigResponse = {
  encode(message: ListScmConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.configs) {
      ScmConfig.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.cursor !== undefined) {
      writer.uint32(26).string(message.cursor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListScmConfigResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListScmConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.configs.push(ScmConfig.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cursor = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListScmConfigResponse {
    return {
      configs: globalThis.Array.isArray(object?.configs) ? object.configs.map((e: any) => ScmConfig.fromJSON(e)) : [],
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
    };
  },

  toJSON(message: ListScmConfigResponse): unknown {
    const obj: any = {};
    if (message.configs?.length) {
      obj.configs = message.configs.map((e) => ScmConfig.toJSON(e));
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListScmConfigResponse>, I>>(base?: I): ListScmConfigResponse {
    return ListScmConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListScmConfigResponse>, I>>(object: I): ListScmConfigResponse {
    const message = createBaseListScmConfigResponse();
    message.configs = object.configs?.map((e) => ScmConfig.fromPartial(e)) || [];
    message.cursor = object.cursor ?? undefined;
    return message;
  },
};

function createBaseCreateScmConfigRequest(): CreateScmConfigRequest {
  return {
    deploymentId: 0,
    type: ScmType.UNSPECIFIED,
    namespace: "",
    sourceId: undefined,
    baseUrl: "",
    accessToken: undefined,
    scmConfigId: undefined,
    subscribe: undefined,
    autoScan: undefined,
  };
}

export const CreateScmConfigRequest = {
  encode(message: CreateScmConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.type !== ScmType.UNSPECIFIED) {
      writer.uint32(16).int32(scmTypeToNumber(message.type));
    }
    if (message.namespace !== "") {
      writer.uint32(26).string(message.namespace);
    }
    if (message.sourceId !== undefined) {
      writer.uint32(34).string(message.sourceId);
    }
    if (message.baseUrl !== "") {
      writer.uint32(42).string(message.baseUrl);
    }
    if (message.accessToken !== undefined) {
      writer.uint32(50).string(message.accessToken);
    }
    if (message.scmConfigId !== undefined) {
      writer.uint32(56).uint64(message.scmConfigId);
    }
    if (message.subscribe !== undefined) {
      writer.uint32(64).bool(message.subscribe);
    }
    if (message.autoScan !== undefined) {
      writer.uint32(72).bool(message.autoScan);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateScmConfigRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateScmConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = scmTypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.namespace = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sourceId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.baseUrl = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.accessToken = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.scmConfigId = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.subscribe = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.autoScan = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateScmConfigRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      type: isSet(object.type) ? scmTypeFromJSON(object.type) : ScmType.UNSPECIFIED,
      namespace: isSet(object.namespace) ? globalThis.String(object.namespace) : "",
      sourceId: isSet(object.sourceId) ? globalThis.String(object.sourceId) : undefined,
      baseUrl: isSet(object.baseUrl) ? globalThis.String(object.baseUrl) : "",
      accessToken: isSet(object.accessToken) ? globalThis.String(object.accessToken) : undefined,
      scmConfigId: isSet(object.scmConfigId) ? globalThis.Number(object.scmConfigId) : undefined,
      subscribe: isSet(object.subscribe) ? globalThis.Boolean(object.subscribe) : undefined,
      autoScan: isSet(object.autoScan) ? globalThis.Boolean(object.autoScan) : undefined,
    };
  },

  toJSON(message: CreateScmConfigRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.type !== ScmType.UNSPECIFIED) {
      obj.type = scmTypeToJSON(message.type);
    }
    if (message.namespace !== "") {
      obj.namespace = message.namespace;
    }
    if (message.sourceId !== undefined) {
      obj.sourceId = message.sourceId;
    }
    if (message.baseUrl !== "") {
      obj.baseUrl = message.baseUrl;
    }
    if (message.accessToken !== undefined) {
      obj.accessToken = message.accessToken;
    }
    if (message.scmConfigId !== undefined) {
      obj.scmConfigId = Math.round(message.scmConfigId);
    }
    if (message.subscribe !== undefined) {
      obj.subscribe = message.subscribe;
    }
    if (message.autoScan !== undefined) {
      obj.autoScan = message.autoScan;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateScmConfigRequest>, I>>(base?: I): CreateScmConfigRequest {
    return CreateScmConfigRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateScmConfigRequest>, I>>(object: I): CreateScmConfigRequest {
    const message = createBaseCreateScmConfigRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.type = object.type ?? ScmType.UNSPECIFIED;
    message.namespace = object.namespace ?? "";
    message.sourceId = object.sourceId ?? undefined;
    message.baseUrl = object.baseUrl ?? "";
    message.accessToken = object.accessToken ?? undefined;
    message.scmConfigId = object.scmConfigId ?? undefined;
    message.subscribe = object.subscribe ?? undefined;
    message.autoScan = object.autoScan ?? undefined;
    return message;
  },
};

function createBaseCreateScmConfigResponse(): CreateScmConfigResponse {
  return { config: undefined };
}

export const CreateScmConfigResponse = {
  encode(message: CreateScmConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.config !== undefined) {
      ScmConfig.encode(message.config, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateScmConfigResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateScmConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.config = ScmConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateScmConfigResponse {
    return { config: isSet(object.config) ? ScmConfig.fromJSON(object.config) : undefined };
  },

  toJSON(message: CreateScmConfigResponse): unknown {
    const obj: any = {};
    if (message.config !== undefined) {
      obj.config = ScmConfig.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateScmConfigResponse>, I>>(base?: I): CreateScmConfigResponse {
    return CreateScmConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateScmConfigResponse>, I>>(object: I): CreateScmConfigResponse {
    const message = createBaseCreateScmConfigResponse();
    message.config = (object.config !== undefined && object.config !== null)
      ? ScmConfig.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBasePatchScmConfigRequest(): PatchScmConfigRequest {
  return {
    deploymentId: 0,
    configId: 0,
    accessToken: undefined,
    autoScan: undefined,
    useNetworkBroker: undefined,
    namespace: undefined,
    subscribe: undefined,
  };
}

export const PatchScmConfigRequest = {
  encode(message: PatchScmConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.configId !== 0) {
      writer.uint32(16).uint64(message.configId);
    }
    if (message.accessToken !== undefined) {
      writer.uint32(26).string(message.accessToken);
    }
    if (message.autoScan !== undefined) {
      writer.uint32(32).bool(message.autoScan);
    }
    if (message.useNetworkBroker !== undefined) {
      writer.uint32(40).bool(message.useNetworkBroker);
    }
    if (message.namespace !== undefined) {
      writer.uint32(50).string(message.namespace);
    }
    if (message.subscribe !== undefined) {
      writer.uint32(56).bool(message.subscribe);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatchScmConfigRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatchScmConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.configId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accessToken = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.autoScan = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.useNetworkBroker = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.namespace = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.subscribe = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PatchScmConfigRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      configId: isSet(object.configId) ? globalThis.Number(object.configId) : 0,
      accessToken: isSet(object.accessToken) ? globalThis.String(object.accessToken) : undefined,
      autoScan: isSet(object.autoScan) ? globalThis.Boolean(object.autoScan) : undefined,
      useNetworkBroker: isSet(object.useNetworkBroker) ? globalThis.Boolean(object.useNetworkBroker) : undefined,
      namespace: isSet(object.namespace) ? globalThis.String(object.namespace) : undefined,
      subscribe: isSet(object.subscribe) ? globalThis.Boolean(object.subscribe) : undefined,
    };
  },

  toJSON(message: PatchScmConfigRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.configId !== 0) {
      obj.configId = Math.round(message.configId);
    }
    if (message.accessToken !== undefined) {
      obj.accessToken = message.accessToken;
    }
    if (message.autoScan !== undefined) {
      obj.autoScan = message.autoScan;
    }
    if (message.useNetworkBroker !== undefined) {
      obj.useNetworkBroker = message.useNetworkBroker;
    }
    if (message.namespace !== undefined) {
      obj.namespace = message.namespace;
    }
    if (message.subscribe !== undefined) {
      obj.subscribe = message.subscribe;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PatchScmConfigRequest>, I>>(base?: I): PatchScmConfigRequest {
    return PatchScmConfigRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PatchScmConfigRequest>, I>>(object: I): PatchScmConfigRequest {
    const message = createBasePatchScmConfigRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.configId = object.configId ?? 0;
    message.accessToken = object.accessToken ?? undefined;
    message.autoScan = object.autoScan ?? undefined;
    message.useNetworkBroker = object.useNetworkBroker ?? undefined;
    message.namespace = object.namespace ?? undefined;
    message.subscribe = object.subscribe ?? undefined;
    return message;
  },
};

function createBasePatchScmConfigResponse(): PatchScmConfigResponse {
  return { config: undefined };
}

export const PatchScmConfigResponse = {
  encode(message: PatchScmConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.config !== undefined) {
      ScmConfig.encode(message.config, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatchScmConfigResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatchScmConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.config = ScmConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PatchScmConfigResponse {
    return { config: isSet(object.config) ? ScmConfig.fromJSON(object.config) : undefined };
  },

  toJSON(message: PatchScmConfigResponse): unknown {
    const obj: any = {};
    if (message.config !== undefined) {
      obj.config = ScmConfig.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PatchScmConfigResponse>, I>>(base?: I): PatchScmConfigResponse {
    return PatchScmConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PatchScmConfigResponse>, I>>(object: I): PatchScmConfigResponse {
    const message = createBasePatchScmConfigResponse();
    message.config = (object.config !== undefined && object.config !== null)
      ? ScmConfig.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseDeleteScmConfigRequest(): DeleteScmConfigRequest {
  return { deploymentId: 0, configId: 0 };
}

export const DeleteScmConfigRequest = {
  encode(message: DeleteScmConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.configId !== 0) {
      writer.uint32(16).uint64(message.configId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteScmConfigRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteScmConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.configId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteScmConfigRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      configId: isSet(object.configId) ? globalThis.Number(object.configId) : 0,
    };
  },

  toJSON(message: DeleteScmConfigRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.configId !== 0) {
      obj.configId = Math.round(message.configId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteScmConfigRequest>, I>>(base?: I): DeleteScmConfigRequest {
    return DeleteScmConfigRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteScmConfigRequest>, I>>(object: I): DeleteScmConfigRequest {
    const message = createBaseDeleteScmConfigRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.configId = object.configId ?? 0;
    return message;
  },
};

function createBaseDeleteScmConfigResponse(): DeleteScmConfigResponse {
  return {};
}

export const DeleteScmConfigResponse = {
  encode(_: DeleteScmConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteScmConfigResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteScmConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeleteScmConfigResponse {
    return {};
  },

  toJSON(_: DeleteScmConfigResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteScmConfigResponse>, I>>(base?: I): DeleteScmConfigResponse {
    return DeleteScmConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteScmConfigResponse>, I>>(_: I): DeleteScmConfigResponse {
    const message = createBaseDeleteScmConfigResponse();
    return message;
  },
};

function createBaseCheckScmConfigRequest(): CheckScmConfigRequest {
  return { deploymentId: 0, configId: 0, checkScopes: undefined };
}

export const CheckScmConfigRequest = {
  encode(message: CheckScmConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.configId !== 0) {
      writer.uint32(16).uint64(message.configId);
    }
    if (message.checkScopes !== undefined) {
      writer.uint32(24).bool(message.checkScopes);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckScmConfigRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckScmConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.configId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.checkScopes = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CheckScmConfigRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      configId: isSet(object.configId) ? globalThis.Number(object.configId) : 0,
      checkScopes: isSet(object.checkScopes) ? globalThis.Boolean(object.checkScopes) : undefined,
    };
  },

  toJSON(message: CheckScmConfigRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.configId !== 0) {
      obj.configId = Math.round(message.configId);
    }
    if (message.checkScopes !== undefined) {
      obj.checkScopes = message.checkScopes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CheckScmConfigRequest>, I>>(base?: I): CheckScmConfigRequest {
    return CheckScmConfigRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CheckScmConfigRequest>, I>>(object: I): CheckScmConfigRequest {
    const message = createBaseCheckScmConfigRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.configId = object.configId ?? 0;
    message.checkScopes = object.checkScopes ?? undefined;
    return message;
  },
};

function createBaseCheckScmConfigResponse(): CheckScmConfigResponse {
  return { status: undefined, tokenScopes: undefined };
}

export const CheckScmConfigResponse = {
  encode(message: CheckScmConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== undefined) {
      ScmStatus.encode(message.status, writer.uint32(10).fork()).ldelim();
    }
    if (message.tokenScopes !== undefined) {
      ScmTokenScopes.encode(message.tokenScopes, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckScmConfigResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckScmConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = ScmStatus.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tokenScopes = ScmTokenScopes.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CheckScmConfigResponse {
    return {
      status: isSet(object.status) ? ScmStatus.fromJSON(object.status) : undefined,
      tokenScopes: isSet(object.tokenScopes) ? ScmTokenScopes.fromJSON(object.tokenScopes) : undefined,
    };
  },

  toJSON(message: CheckScmConfigResponse): unknown {
    const obj: any = {};
    if (message.status !== undefined) {
      obj.status = ScmStatus.toJSON(message.status);
    }
    if (message.tokenScopes !== undefined) {
      obj.tokenScopes = ScmTokenScopes.toJSON(message.tokenScopes);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CheckScmConfigResponse>, I>>(base?: I): CheckScmConfigResponse {
    return CheckScmConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CheckScmConfigResponse>, I>>(object: I): CheckScmConfigResponse {
    const message = createBaseCheckScmConfigResponse();
    message.status = (object.status !== undefined && object.status !== null)
      ? ScmStatus.fromPartial(object.status)
      : undefined;
    message.tokenScopes = (object.tokenScopes !== undefined && object.tokenScopes !== null)
      ? ScmTokenScopes.fromPartial(object.tokenScopes)
      : undefined;
    return message;
  },
};

function createBaseSyncScmConfigRequest(): SyncScmConfigRequest {
  return { deploymentId: 0, configId: 0 };
}

export const SyncScmConfigRequest = {
  encode(message: SyncScmConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.configId !== 0) {
      writer.uint32(16).uint64(message.configId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyncScmConfigRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSyncScmConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.configId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SyncScmConfigRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      configId: isSet(object.configId) ? globalThis.Number(object.configId) : 0,
    };
  },

  toJSON(message: SyncScmConfigRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.configId !== 0) {
      obj.configId = Math.round(message.configId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SyncScmConfigRequest>, I>>(base?: I): SyncScmConfigRequest {
    return SyncScmConfigRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SyncScmConfigRequest>, I>>(object: I): SyncScmConfigRequest {
    const message = createBaseSyncScmConfigRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.configId = object.configId ?? 0;
    return message;
  },
};

function createBaseSyncScmConfigResponse(): SyncScmConfigResponse {
  return { taskTokenJwt: "" };
}

export const SyncScmConfigResponse = {
  encode(message: SyncScmConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.taskTokenJwt !== "") {
      writer.uint32(10).string(message.taskTokenJwt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyncScmConfigResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSyncScmConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.taskTokenJwt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SyncScmConfigResponse {
    return { taskTokenJwt: isSet(object.taskTokenJwt) ? globalThis.String(object.taskTokenJwt) : "" };
  },

  toJSON(message: SyncScmConfigResponse): unknown {
    const obj: any = {};
    if (message.taskTokenJwt !== "") {
      obj.taskTokenJwt = message.taskTokenJwt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SyncScmConfigResponse>, I>>(base?: I): SyncScmConfigResponse {
    return SyncScmConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SyncScmConfigResponse>, I>>(object: I): SyncScmConfigResponse {
    const message = createBaseSyncScmConfigResponse();
    message.taskTokenJwt = object.taskTokenJwt ?? "";
    return message;
  },
};

function createBaseCreateScmAppRequestRequest(): CreateScmAppRequestRequest {
  return {
    deploymentId: 0,
    deploymentScmConfigId: 0,
    baseUrl: "",
    namespace: "",
    githubEntityType: GithubEntityType.UNSPECIFIED,
  };
}

export const CreateScmAppRequestRequest = {
  encode(message: CreateScmAppRequestRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.deploymentScmConfigId !== 0) {
      writer.uint32(16).uint64(message.deploymentScmConfigId);
    }
    if (message.baseUrl !== "") {
      writer.uint32(26).string(message.baseUrl);
    }
    if (message.namespace !== "") {
      writer.uint32(34).string(message.namespace);
    }
    if (message.githubEntityType !== GithubEntityType.UNSPECIFIED) {
      writer.uint32(40).int32(githubEntityTypeToNumber(message.githubEntityType));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateScmAppRequestRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateScmAppRequestRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deploymentScmConfigId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.baseUrl = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.namespace = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.githubEntityType = githubEntityTypeFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateScmAppRequestRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      deploymentScmConfigId: isSet(object.deploymentScmConfigId) ? globalThis.Number(object.deploymentScmConfigId) : 0,
      baseUrl: isSet(object.baseUrl) ? globalThis.String(object.baseUrl) : "",
      namespace: isSet(object.namespace) ? globalThis.String(object.namespace) : "",
      githubEntityType: isSet(object.githubEntityType)
        ? githubEntityTypeFromJSON(object.githubEntityType)
        : GithubEntityType.UNSPECIFIED,
    };
  },

  toJSON(message: CreateScmAppRequestRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.deploymentScmConfigId !== 0) {
      obj.deploymentScmConfigId = Math.round(message.deploymentScmConfigId);
    }
    if (message.baseUrl !== "") {
      obj.baseUrl = message.baseUrl;
    }
    if (message.namespace !== "") {
      obj.namespace = message.namespace;
    }
    if (message.githubEntityType !== GithubEntityType.UNSPECIFIED) {
      obj.githubEntityType = githubEntityTypeToJSON(message.githubEntityType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateScmAppRequestRequest>, I>>(base?: I): CreateScmAppRequestRequest {
    return CreateScmAppRequestRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateScmAppRequestRequest>, I>>(object: I): CreateScmAppRequestRequest {
    const message = createBaseCreateScmAppRequestRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.deploymentScmConfigId = object.deploymentScmConfigId ?? 0;
    message.baseUrl = object.baseUrl ?? "";
    message.namespace = object.namespace ?? "";
    message.githubEntityType = object.githubEntityType ?? GithubEntityType.UNSPECIFIED;
    return message;
  },
};

function createBaseCreateScmAppRequestResponse(): CreateScmAppRequestResponse {
  return { requestId: "" };
}

export const CreateScmAppRequestResponse = {
  encode(message: CreateScmAppRequestResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== "") {
      writer.uint32(10).string(message.requestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateScmAppRequestResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateScmAppRequestResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.requestId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateScmAppRequestResponse {
    return { requestId: isSet(object.requestId) ? globalThis.String(object.requestId) : "" };
  },

  toJSON(message: CreateScmAppRequestResponse): unknown {
    const obj: any = {};
    if (message.requestId !== "") {
      obj.requestId = message.requestId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateScmAppRequestResponse>, I>>(base?: I): CreateScmAppRequestResponse {
    return CreateScmAppRequestResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateScmAppRequestResponse>, I>>(object: I): CreateScmAppRequestResponse {
    const message = createBaseCreateScmAppRequestResponse();
    message.requestId = object.requestId ?? "";
    return message;
  },
};

function createBaseCompleteScmAppRequestRequest(): CompleteScmAppRequestRequest {
  return { requestId: "", code: "" };
}

export const CompleteScmAppRequestRequest = {
  encode(message: CompleteScmAppRequestRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== "") {
      writer.uint32(10).string(message.requestId);
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompleteScmAppRequestRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompleteScmAppRequestRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.requestId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompleteScmAppRequestRequest {
    return {
      requestId: isSet(object.requestId) ? globalThis.String(object.requestId) : "",
      code: isSet(object.code) ? globalThis.String(object.code) : "",
    };
  },

  toJSON(message: CompleteScmAppRequestRequest): unknown {
    const obj: any = {};
    if (message.requestId !== "") {
      obj.requestId = message.requestId;
    }
    if (message.code !== "") {
      obj.code = message.code;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompleteScmAppRequestRequest>, I>>(base?: I): CompleteScmAppRequestRequest {
    return CompleteScmAppRequestRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompleteScmAppRequestRequest>, I>>(object: I): CompleteScmAppRequestRequest {
    const message = createBaseCompleteScmAppRequestRequest();
    message.requestId = object.requestId ?? "";
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseCompleteScmAppRequestResponse(): CompleteScmAppRequestResponse {
  return { app: undefined };
}

export const CompleteScmAppRequestResponse = {
  encode(message: CompleteScmAppRequestResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.app !== undefined) {
      ScmApp.encode(message.app, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompleteScmAppRequestResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompleteScmAppRequestResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.app = ScmApp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompleteScmAppRequestResponse {
    return { app: isSet(object.app) ? ScmApp.fromJSON(object.app) : undefined };
  },

  toJSON(message: CompleteScmAppRequestResponse): unknown {
    const obj: any = {};
    if (message.app !== undefined) {
      obj.app = ScmApp.toJSON(message.app);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompleteScmAppRequestResponse>, I>>(base?: I): CompleteScmAppRequestResponse {
    return CompleteScmAppRequestResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompleteScmAppRequestResponse>, I>>(
    object: I,
  ): CompleteScmAppRequestResponse {
    const message = createBaseCompleteScmAppRequestResponse();
    message.app = (object.app !== undefined && object.app !== null) ? ScmApp.fromPartial(object.app) : undefined;
    return message;
  },
};

function createBasePatchScmConfigWebhookRequest(): PatchScmConfigWebhookRequest {
  return { deploymentId: 0, configId: 0 };
}

export const PatchScmConfigWebhookRequest = {
  encode(message: PatchScmConfigWebhookRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.configId !== 0) {
      writer.uint32(16).uint64(message.configId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatchScmConfigWebhookRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatchScmConfigWebhookRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.configId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PatchScmConfigWebhookRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      configId: isSet(object.configId) ? globalThis.Number(object.configId) : 0,
    };
  },

  toJSON(message: PatchScmConfigWebhookRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.configId !== 0) {
      obj.configId = Math.round(message.configId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PatchScmConfigWebhookRequest>, I>>(base?: I): PatchScmConfigWebhookRequest {
    return PatchScmConfigWebhookRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PatchScmConfigWebhookRequest>, I>>(object: I): PatchScmConfigWebhookRequest {
    const message = createBasePatchScmConfigWebhookRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.configId = object.configId ?? 0;
    return message;
  },
};

function createBasePatchScmConfigWebhookResponse(): PatchScmConfigWebhookResponse {
  return { success: false };
}

export const PatchScmConfigWebhookResponse = {
  encode(message: PatchScmConfigWebhookResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.success !== false) {
      writer.uint32(8).bool(message.success);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatchScmConfigWebhookResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatchScmConfigWebhookResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.success = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PatchScmConfigWebhookResponse {
    return { success: isSet(object.success) ? globalThis.Boolean(object.success) : false };
  },

  toJSON(message: PatchScmConfigWebhookResponse): unknown {
    const obj: any = {};
    if (message.success !== false) {
      obj.success = message.success;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PatchScmConfigWebhookResponse>, I>>(base?: I): PatchScmConfigWebhookResponse {
    return PatchScmConfigWebhookResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PatchScmConfigWebhookResponse>, I>>(
    object: I,
  ): PatchScmConfigWebhookResponse {
    const message = createBasePatchScmConfigWebhookResponse();
    message.success = object.success ?? false;
    return message;
  },
};

function createBaseScmWebhookSubscription(): ScmWebhookSubscription {
  return { scmUrl: "", events: [] };
}

export const ScmWebhookSubscription = {
  encode(message: ScmWebhookSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scmUrl !== "") {
      writer.uint32(10).string(message.scmUrl);
    }
    for (const v of message.events) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScmWebhookSubscription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScmWebhookSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scmUrl = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.events.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScmWebhookSubscription {
    return {
      scmUrl: isSet(object.scmUrl) ? globalThis.String(object.scmUrl) : "",
      events: globalThis.Array.isArray(object?.events) ? object.events.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ScmWebhookSubscription): unknown {
    const obj: any = {};
    if (message.scmUrl !== "") {
      obj.scmUrl = message.scmUrl;
    }
    if (message.events?.length) {
      obj.events = message.events;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScmWebhookSubscription>, I>>(base?: I): ScmWebhookSubscription {
    return ScmWebhookSubscription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScmWebhookSubscription>, I>>(object: I): ScmWebhookSubscription {
    const message = createBaseScmWebhookSubscription();
    message.scmUrl = object.scmUrl ?? "";
    message.events = object.events?.map((e) => e) || [];
    return message;
  },
};

function createBaseScmWebhookSubscriptionSubscribeRequest(): ScmWebhookSubscriptionSubscribeRequest {
  return { deploymentId: 0, configId: 0 };
}

export const ScmWebhookSubscriptionSubscribeRequest = {
  encode(message: ScmWebhookSubscriptionSubscribeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.configId !== 0) {
      writer.uint32(16).uint64(message.configId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScmWebhookSubscriptionSubscribeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScmWebhookSubscriptionSubscribeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.configId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScmWebhookSubscriptionSubscribeRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      configId: isSet(object.configId) ? globalThis.Number(object.configId) : 0,
    };
  },

  toJSON(message: ScmWebhookSubscriptionSubscribeRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.configId !== 0) {
      obj.configId = Math.round(message.configId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScmWebhookSubscriptionSubscribeRequest>, I>>(
    base?: I,
  ): ScmWebhookSubscriptionSubscribeRequest {
    return ScmWebhookSubscriptionSubscribeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScmWebhookSubscriptionSubscribeRequest>, I>>(
    object: I,
  ): ScmWebhookSubscriptionSubscribeRequest {
    const message = createBaseScmWebhookSubscriptionSubscribeRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.configId = object.configId ?? 0;
    return message;
  },
};

function createBaseScmWebhookSubscriptionSubscribeResponse(): ScmWebhookSubscriptionSubscribeResponse {
  return { deploymentId: 0, configId: 0, subscription: undefined };
}

export const ScmWebhookSubscriptionSubscribeResponse = {
  encode(message: ScmWebhookSubscriptionSubscribeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.configId !== 0) {
      writer.uint32(16).uint64(message.configId);
    }
    if (message.subscription !== undefined) {
      ScmWebhookSubscription.encode(message.subscription, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScmWebhookSubscriptionSubscribeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScmWebhookSubscriptionSubscribeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.configId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subscription = ScmWebhookSubscription.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScmWebhookSubscriptionSubscribeResponse {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      configId: isSet(object.configId) ? globalThis.Number(object.configId) : 0,
      subscription: isSet(object.subscription) ? ScmWebhookSubscription.fromJSON(object.subscription) : undefined,
    };
  },

  toJSON(message: ScmWebhookSubscriptionSubscribeResponse): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.configId !== 0) {
      obj.configId = Math.round(message.configId);
    }
    if (message.subscription !== undefined) {
      obj.subscription = ScmWebhookSubscription.toJSON(message.subscription);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScmWebhookSubscriptionSubscribeResponse>, I>>(
    base?: I,
  ): ScmWebhookSubscriptionSubscribeResponse {
    return ScmWebhookSubscriptionSubscribeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScmWebhookSubscriptionSubscribeResponse>, I>>(
    object: I,
  ): ScmWebhookSubscriptionSubscribeResponse {
    const message = createBaseScmWebhookSubscriptionSubscribeResponse();
    message.deploymentId = object.deploymentId ?? 0;
    message.configId = object.configId ?? 0;
    message.subscription = (object.subscription !== undefined && object.subscription !== null)
      ? ScmWebhookSubscription.fromPartial(object.subscription)
      : undefined;
    return message;
  },
};

function createBaseScmWebhookSubscriptionUnsubscribeRequest(): ScmWebhookSubscriptionUnsubscribeRequest {
  return { deploymentId: 0, configId: 0 };
}

export const ScmWebhookSubscriptionUnsubscribeRequest = {
  encode(message: ScmWebhookSubscriptionUnsubscribeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.configId !== 0) {
      writer.uint32(16).uint64(message.configId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScmWebhookSubscriptionUnsubscribeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScmWebhookSubscriptionUnsubscribeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.configId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScmWebhookSubscriptionUnsubscribeRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      configId: isSet(object.configId) ? globalThis.Number(object.configId) : 0,
    };
  },

  toJSON(message: ScmWebhookSubscriptionUnsubscribeRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.configId !== 0) {
      obj.configId = Math.round(message.configId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScmWebhookSubscriptionUnsubscribeRequest>, I>>(
    base?: I,
  ): ScmWebhookSubscriptionUnsubscribeRequest {
    return ScmWebhookSubscriptionUnsubscribeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScmWebhookSubscriptionUnsubscribeRequest>, I>>(
    object: I,
  ): ScmWebhookSubscriptionUnsubscribeRequest {
    const message = createBaseScmWebhookSubscriptionUnsubscribeRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.configId = object.configId ?? 0;
    return message;
  },
};

function createBaseScmWebhookSubscriptionUnsubscribeResponse(): ScmWebhookSubscriptionUnsubscribeResponse {
  return { deploymentId: 0, configId: 0, subscription: undefined };
}

export const ScmWebhookSubscriptionUnsubscribeResponse = {
  encode(message: ScmWebhookSubscriptionUnsubscribeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.configId !== 0) {
      writer.uint32(16).uint64(message.configId);
    }
    if (message.subscription !== undefined) {
      ScmWebhookSubscription.encode(message.subscription, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScmWebhookSubscriptionUnsubscribeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScmWebhookSubscriptionUnsubscribeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.configId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subscription = ScmWebhookSubscription.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScmWebhookSubscriptionUnsubscribeResponse {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      configId: isSet(object.configId) ? globalThis.Number(object.configId) : 0,
      subscription: isSet(object.subscription) ? ScmWebhookSubscription.fromJSON(object.subscription) : undefined,
    };
  },

  toJSON(message: ScmWebhookSubscriptionUnsubscribeResponse): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.configId !== 0) {
      obj.configId = Math.round(message.configId);
    }
    if (message.subscription !== undefined) {
      obj.subscription = ScmWebhookSubscription.toJSON(message.subscription);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScmWebhookSubscriptionUnsubscribeResponse>, I>>(
    base?: I,
  ): ScmWebhookSubscriptionUnsubscribeResponse {
    return ScmWebhookSubscriptionUnsubscribeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScmWebhookSubscriptionUnsubscribeResponse>, I>>(
    object: I,
  ): ScmWebhookSubscriptionUnsubscribeResponse {
    const message = createBaseScmWebhookSubscriptionUnsubscribeResponse();
    message.deploymentId = object.deploymentId ?? 0;
    message.configId = object.configId ?? 0;
    message.subscription = (object.subscription !== undefined && object.subscription !== null)
      ? ScmWebhookSubscription.fromPartial(object.subscription)
      : undefined;
    return message;
  },
};

function createBaseListScmWebhookSubscriptionRequest(): ListScmWebhookSubscriptionRequest {
  return { deploymentId: 0, configId: 0 };
}

export const ListScmWebhookSubscriptionRequest = {
  encode(message: ListScmWebhookSubscriptionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.configId !== 0) {
      writer.uint32(16).uint64(message.configId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListScmWebhookSubscriptionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListScmWebhookSubscriptionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.configId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListScmWebhookSubscriptionRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      configId: isSet(object.configId) ? globalThis.Number(object.configId) : 0,
    };
  },

  toJSON(message: ListScmWebhookSubscriptionRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.configId !== 0) {
      obj.configId = Math.round(message.configId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListScmWebhookSubscriptionRequest>, I>>(
    base?: I,
  ): ListScmWebhookSubscriptionRequest {
    return ListScmWebhookSubscriptionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListScmWebhookSubscriptionRequest>, I>>(
    object: I,
  ): ListScmWebhookSubscriptionRequest {
    const message = createBaseListScmWebhookSubscriptionRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.configId = object.configId ?? 0;
    return message;
  },
};

function createBaseListScmWebhookSubscriptionResponse(): ListScmWebhookSubscriptionResponse {
  return { deploymentId: 0, configId: 0, subscriptions: [] };
}

export const ListScmWebhookSubscriptionResponse = {
  encode(message: ListScmWebhookSubscriptionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.configId !== 0) {
      writer.uint32(16).uint64(message.configId);
    }
    for (const v of message.subscriptions) {
      ScmWebhookSubscription.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListScmWebhookSubscriptionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListScmWebhookSubscriptionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.configId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subscriptions.push(ScmWebhookSubscription.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListScmWebhookSubscriptionResponse {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      configId: isSet(object.configId) ? globalThis.Number(object.configId) : 0,
      subscriptions: globalThis.Array.isArray(object?.subscriptions)
        ? object.subscriptions.map((e: any) => ScmWebhookSubscription.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListScmWebhookSubscriptionResponse): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.configId !== 0) {
      obj.configId = Math.round(message.configId);
    }
    if (message.subscriptions?.length) {
      obj.subscriptions = message.subscriptions.map((e) => ScmWebhookSubscription.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListScmWebhookSubscriptionResponse>, I>>(
    base?: I,
  ): ListScmWebhookSubscriptionResponse {
    return ListScmWebhookSubscriptionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListScmWebhookSubscriptionResponse>, I>>(
    object: I,
  ): ListScmWebhookSubscriptionResponse {
    const message = createBaseListScmWebhookSubscriptionResponse();
    message.deploymentId = object.deploymentId ?? 0;
    message.configId = object.configId ?? 0;
    message.subscriptions = object.subscriptions?.map((e) => ScmWebhookSubscription.fromPartial(e)) || [];
    return message;
  },
};

export interface ScmService {
  listScmConfig(request: ListScmConfigRequest): Promise<ListScmConfigResponse>;
  createScmConfig(request: CreateScmConfigRequest): Promise<CreateScmConfigResponse>;
  patchScmConfig(request: PatchScmConfigRequest): Promise<PatchScmConfigResponse>;
  deleteScmConfig(request: DeleteScmConfigRequest): Promise<DeleteScmConfigResponse>;
  checkScmConfig(request: CheckScmConfigRequest): Promise<CheckScmConfigResponse>;
  syncScmConfig(request: SyncScmConfigRequest): Promise<SyncScmConfigResponse>;
  listScmApps(request: ListScmAppsRequest): Promise<ListScmAppsResponse>;
  getScmApp(request: GetScmAppRequest): Promise<GetScmAppResponse>;
  createScmAppRequest(request: CreateScmAppRequestRequest): Promise<CreateScmAppRequestResponse>;
  completeScmAppRequest(request: CompleteScmAppRequestRequest): Promise<CompleteScmAppRequestResponse>;
  scmWebhookSubscriptionSubscribe(
    request: ScmWebhookSubscriptionSubscribeRequest,
  ): Promise<ScmWebhookSubscriptionSubscribeResponse>;
  scmWebhookSubscriptionUnsubscribe(
    request: ScmWebhookSubscriptionUnsubscribeRequest,
  ): Promise<ScmWebhookSubscriptionUnsubscribeResponse>;
  listScmWebhookSubscription(request: ListScmWebhookSubscriptionRequest): Promise<ListScmWebhookSubscriptionResponse>;
}

export const ScmServiceServiceName = "protos.scm.v1.ScmService";
export class ScmServiceClientImpl implements ScmService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || ScmServiceServiceName;
    this.rpc = rpc;
    this.listScmConfig = this.listScmConfig.bind(this);
    this.createScmConfig = this.createScmConfig.bind(this);
    this.patchScmConfig = this.patchScmConfig.bind(this);
    this.deleteScmConfig = this.deleteScmConfig.bind(this);
    this.checkScmConfig = this.checkScmConfig.bind(this);
    this.syncScmConfig = this.syncScmConfig.bind(this);
    this.listScmApps = this.listScmApps.bind(this);
    this.getScmApp = this.getScmApp.bind(this);
    this.createScmAppRequest = this.createScmAppRequest.bind(this);
    this.completeScmAppRequest = this.completeScmAppRequest.bind(this);
    this.scmWebhookSubscriptionSubscribe = this.scmWebhookSubscriptionSubscribe.bind(this);
    this.scmWebhookSubscriptionUnsubscribe = this.scmWebhookSubscriptionUnsubscribe.bind(this);
    this.listScmWebhookSubscription = this.listScmWebhookSubscription.bind(this);
  }
  listScmConfig(request: ListScmConfigRequest): Promise<ListScmConfigResponse> {
    const data = ListScmConfigRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListScmConfig", data);
    return promise.then((data) => ListScmConfigResponse.decode(_m0.Reader.create(data)));
  }

  createScmConfig(request: CreateScmConfigRequest): Promise<CreateScmConfigResponse> {
    const data = CreateScmConfigRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateScmConfig", data);
    return promise.then((data) => CreateScmConfigResponse.decode(_m0.Reader.create(data)));
  }

  patchScmConfig(request: PatchScmConfigRequest): Promise<PatchScmConfigResponse> {
    const data = PatchScmConfigRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "PatchScmConfig", data);
    return promise.then((data) => PatchScmConfigResponse.decode(_m0.Reader.create(data)));
  }

  deleteScmConfig(request: DeleteScmConfigRequest): Promise<DeleteScmConfigResponse> {
    const data = DeleteScmConfigRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "DeleteScmConfig", data);
    return promise.then((data) => DeleteScmConfigResponse.decode(_m0.Reader.create(data)));
  }

  checkScmConfig(request: CheckScmConfigRequest): Promise<CheckScmConfigResponse> {
    const data = CheckScmConfigRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CheckScmConfig", data);
    return promise.then((data) => CheckScmConfigResponse.decode(_m0.Reader.create(data)));
  }

  syncScmConfig(request: SyncScmConfigRequest): Promise<SyncScmConfigResponse> {
    const data = SyncScmConfigRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "SyncScmConfig", data);
    return promise.then((data) => SyncScmConfigResponse.decode(_m0.Reader.create(data)));
  }

  listScmApps(request: ListScmAppsRequest): Promise<ListScmAppsResponse> {
    const data = ListScmAppsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListScmApps", data);
    return promise.then((data) => ListScmAppsResponse.decode(_m0.Reader.create(data)));
  }

  getScmApp(request: GetScmAppRequest): Promise<GetScmAppResponse> {
    const data = GetScmAppRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetScmApp", data);
    return promise.then((data) => GetScmAppResponse.decode(_m0.Reader.create(data)));
  }

  createScmAppRequest(request: CreateScmAppRequestRequest): Promise<CreateScmAppRequestResponse> {
    const data = CreateScmAppRequestRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateScmAppRequest", data);
    return promise.then((data) => CreateScmAppRequestResponse.decode(_m0.Reader.create(data)));
  }

  completeScmAppRequest(request: CompleteScmAppRequestRequest): Promise<CompleteScmAppRequestResponse> {
    const data = CompleteScmAppRequestRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CompleteScmAppRequest", data);
    return promise.then((data) => CompleteScmAppRequestResponse.decode(_m0.Reader.create(data)));
  }

  scmWebhookSubscriptionSubscribe(
    request: ScmWebhookSubscriptionSubscribeRequest,
  ): Promise<ScmWebhookSubscriptionSubscribeResponse> {
    const data = ScmWebhookSubscriptionSubscribeRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ScmWebhookSubscriptionSubscribe", data);
    return promise.then((data) => ScmWebhookSubscriptionSubscribeResponse.decode(_m0.Reader.create(data)));
  }

  scmWebhookSubscriptionUnsubscribe(
    request: ScmWebhookSubscriptionUnsubscribeRequest,
  ): Promise<ScmWebhookSubscriptionUnsubscribeResponse> {
    const data = ScmWebhookSubscriptionUnsubscribeRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ScmWebhookSubscriptionUnsubscribe", data);
    return promise.then((data) => ScmWebhookSubscriptionUnsubscribeResponse.decode(_m0.Reader.create(data)));
  }

  listScmWebhookSubscription(request: ListScmWebhookSubscriptionRequest): Promise<ListScmWebhookSubscriptionResponse> {
    const data = ListScmWebhookSubscriptionRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListScmWebhookSubscription", data);
    return promise.then((data) => ListScmWebhookSubscriptionResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
