/* eslint-disable react/jsx-no-useless-fragment */
import { showNotification } from "@mantine/notifications";
import { QueryCache, QueryClient } from "@tanstack/react-query";

import { ApiError } from "@shared/types";

import { appEnv } from "../appEnv";
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // retrying in dev just gets annoying due to error log spam
      retry: appEnv === undefined ? false : 3,
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    // will send error notification if query.meta.errorMessage is set
    onError: (error, query) => {
      if (query.meta?.errorMessage) {
        showNotification({
          title: <>{String(query.meta.errorMessage)}</>,
          message: <>{(error as ApiError).message}</>,
          color: "red",
          autoClose: false,
        });
      }
    },
  }),
});
