import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";

// Nx doesn't like that these libraries are both statically imported and dynamically imported in the same file.
// It's probably correct and we should address this at some point, but for now we'll disable the rule for the offending imports.
// TODO: Remove this eslint disable once we've addressed the static import issue
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AppNavbar, ErrorScreen, Footer, NotFound } from "@shared/components";
import { useUser } from "@shared/hooks";
import { ReactLazyPreloadable } from "@shared/utils";

// eslint-disable-next-line @nx/enforce-module-boundaries
import { SmartRedirect } from "@modules/editor";

import { SmartRedirect as ManageRedirect } from "../pages/dashboard/routes/ManageRedirect";

import { AuthedRoute } from "./AuthedRoute";
import { PlaygroundRouter } from "./PlaygroundRouter";

const SlackOAuthCallbackScreen = ReactLazyPreloadable(() =>
  import("@modules/settings").then((module) => ({
    default: module.SlackOAuthCallbackScreen,
  }))
);
const SlackOAuthErrorScreen = ReactLazyPreloadable(() =>
  import("@modules/settings").then((module) => ({
    default: module.SlackOAuthErrorScreen,
  }))
);
const GithubAppAdminManifestForm = ReactLazyPreloadable(() =>
  import("@modules/setup").then((module) => ({
    default: module.GitHubAppAdminManifestForm,
  }))
);
const GithubAppRedirect = ReactLazyPreloadable(() =>
  import("@modules/setup").then((module) => ({
    default: module.GitHubAppRedirect,
  }))
);
const LearnHome = ReactLazyPreloadable(() =>
  import("../pages/learn").then((module) => ({
    default: module.LearnHome,
  }))
);

const PublicCreateSSODeployment = ReactLazyPreloadable(() =>
  import("../pages/ssoSetup/PublicCreateSSODeployment").then((module) => ({
    default: module.PublicCreateSSODeployment,
  }))
);

const CheatsheetEmbed = ReactLazyPreloadable(() =>
  import("../pages/embed/CheatsheetEmbed").then((module) => ({
    default: module.CheatsheetEmbed,
  }))
);
const LoginScreen = ReactLazyPreloadable(() =>
  import("@modules/login").then((module) => ({
    default: module.Login,
  }))
);

// Semgrep Cloud

const DashboardApp = ReactLazyPreloadable(
  () => import("../pages/dashboard/DashboardApp")
);

// End Semgrep Cloud

const EditorWidget = React.lazy(() =>
  import("@shared/editorCore").then((module) => ({
    default: module.EditorWidget,
  }))
);
const SupplyChainWidget = ReactLazyPreloadable(() =>
  import("@shared/components").then((module) => ({
    default: module.SupplyChainWidget,
  }))
);

const GitlabOrganizationsScreen = ReactLazyPreloadable(() =>
  import("../pages/gitLabOrganizations/GitlabOrganizations").then((module) => ({
    default: module.GitlabOrganizations,
  }))
);
const OAuthCallbackScreen = ReactLazyPreloadable(() =>
  import("@modules/login").then((module) => ({
    default: module.OAuthCallbackScreen,
  }))
);
const SamlFinishScreen = ReactLazyPreloadable(() =>
  import("@modules/login").then((module) => ({
    default: module.SamlFinishScreen,
  }))
);
const LoginOAuthRedirect = ReactLazyPreloadable(() =>
  import("@modules/login").then((module) => ({
    default: module.LoginOAuthRedirect,
  }))
);
const SsoErrorScreen = ReactLazyPreloadable(() =>
  import("@modules/login").then((module) => ({
    default: module.SsoErrorScreen,
  }))
);

const Admin = ReactLazyPreloadable(() =>
  import("@modules/admin").then((module) => ({
    default: module.Admin,
  }))
);

const Learn = ReactLazyPreloadable(() =>
  import("../pages/learn").then((module) => ({ default: module.Learn }))
);

const RegistryScreen = ReactLazyPreloadable(() =>
  import("@modules/registry").then((module) => ({
    default: module.RegistryScreen,
  }))
);

const Onboarding = ReactLazyPreloadable(() =>
  import("@modules/onboarding").then((module) => ({
    default: module.Onboarding,
  }))
);

const AppContainer = styled.main`
  padding-top: var(--global-spacing-navbar-height);
`;

const RegistryAppContainer = styled(AppContainer)`
  background: #ffffff;
  min-height: 100vh;
`;

export const AppRoutes: React.FC = () => {
  const [user] = useUser();
  const queryString = window.location.search;

  return (
    <Switch>
      <Route path={["/", "/login"]} exact>
        <AppNavbar showLogin={false} />
        <LoginScreen />
      </Route>
      <Route
        path="/deep-semgrep-beta"
        exact
        component={() => {
          // cannot use <Redirect /> because this path is outside react-router
          window.location.href = "/products/pro-engine";
          return null;
        }}
      />
      <Route
        path="/oauth/login/:authProviderName"
        component={LoginOAuthRedirect}
        exact
      />
      <Route
        exact
        path="/oauth/temp/slack"
        component={SlackOAuthCallbackScreen}
      />
      <Route
        exact
        path="/oauth/slack-error"
        component={SlackOAuthErrorScreen}
      />
      <Route
        path="/oauth/callback/:authProviderName"
        component={OAuthCallbackScreen}
        exact
      />
      <Route path="/auth/saml/finish" component={SamlFinishScreen} exact />
      <Route path="/github/login" exact>
        <Redirect to="/oauth/login/github" />
      </Route>
      <Route path="/github/install" exact>
        {/* NOTE(zz): This is a deprecated route with a sane fallback */}
        <Redirect to="/onboarding/scm/github/install" />
      </Route>
      <AuthedRoute path="/gitlab/organizations" exact>
        <GitlabOrganizationsScreen />
      </AuthedRoute>
      <Route path="/callback" exact>
        <Redirect to="/oauth/callback/github" />
      </Route>
      <Route path="/github/admin/app_install">
        <GithubAppAdminManifestForm />
      </Route>
      <Route
        path="/github/admin/redirect"
        exact
        component={GithubAppRedirect}
      />
      <Route path="/embed/cheatsheet" exact component={CheatsheetEmbed} />
      <Route path="/embed/editor" exact component={EditorWidget} />
      <Route path="/embed/supply-chain" exact component={SupplyChainWidget} />
      <Route path="/registry">
        <Redirect to="/explore" />
      </Route>
      <Redirect from="/packs" to="/explore" exact />
      <Redirect from="/rulesets" to="/explore" exact />
      <Route path="/r" exact>
        <RegistryAppContainer>
          <AppNavbar />
          <RegistryScreen defaultTabId="rules" />
        </RegistryAppContainer>
      </Route>
      <Route path="/r/:registryId">
        <RegistryAppContainer>
          <AppNavbar />
          <RegistryScreen defaultTabId="rules" />
        </RegistryAppContainer>
      </Route>
      <Route path="/explore" exact>
        <RegistryAppContainer>
          <AppNavbar />
          <RegistryScreen defaultTabId="explore" />
        </RegistryAppContainer>
      </Route>
      <Route path="/p/:packId" exact>
        <RegistryAppContainer>
          <AppNavbar />
          <RegistryScreen defaultTabId="one-pack" />
        </RegistryAppContainer>
      </Route>
      <Route path="/welcome/survey">
        <Redirect to="/orgs/:orgName" />
      </Route>
      <AuthedRoute path="/onboarding" component={Onboarding} />
      <AuthedRoute path="/orgs/:orgName">
        <DashboardApp />
      </AuthedRoute>
      <Route path={["/playground", "/s"]}>
        <PlaygroundRouter />
      </Route>
      <Route path="/create-sso-deployment" exact>
        <AppNavbar />
        <PublicCreateSSODeployment />
      </Route>
      <Route path="/survey" exact>
        <Redirect to="/orgs/:orgName" />
      </Route>
      <Route path="/:path?">
        <AppContainer>
          <AppNavbar />
          <Switch>
            <AuthedRoute path="/manage/:sections*" component={ManageRedirect} />
            {user?.roles?.admin === true ? (
              <Route path="/admin" exact component={Admin} />
            ) : null}
            <Route path="/fatal-error" component={ErrorScreen} exact />
            <Route path="/sso-error" component={SsoErrorScreen} exact />
            <Redirect from="/editor" to={`/playground${queryString}`} exact />
            <Route path="/learn" exact component={LearnHome} />
            {/* Legacy support for links to e.g. /learn/3 */}
            <Route path="/learn/:step" exact component={LearnHome} />
            <Route path="/learn/:tutorial/:step" component={Learn} />
            <Route path="/:snippetFqname" component={SmartRedirect} exact />
            {/* If no other route matches, the page is not found */}
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </AppContainer>
      </Route>
    </Switch>
  );
};
