import { Helmet } from "react-helmet";

/**
 * Disable indexing of the page by Google and search engines.
 *
 * This is useful for example for OAuth callback pages.
 */
export const RobotsNoindex = () => (
  <Helmet>
    <meta name="robots" content="noindex, follow" />
  </Helmet>
);
