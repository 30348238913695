import { recoverBrowserSecurityError } from "@shared/utils";

import { RecentlyVisitedOrgData } from "../types";

// only exported for testing
export const RECENTLY_VIEWED_RULES_EDITOR = "recently_viewed_rules_editor";

export const parseRecentlyVisitedDataFromLocalStorage =
  (): RecentlyVisitedOrgData[] => {
    const currentRecentlyViewedString = recoverBrowserSecurityError(() =>
      localStorage.getItem(RECENTLY_VIEWED_RULES_EDITOR)
    );

    return currentRecentlyViewedString
      ? JSON.parse(currentRecentlyViewedString)
      : [];
  };
