import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Skeleton } from "@mantine/core";

import { DEFAULT_PATTERN, DEFAULT_TARGET } from "@shared/constants";
import { SplitGutter } from "@shared/editorCore";

import { EditorHeader } from "../EditorHeader";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--global-spacing-xxl) minmax(0, 1fr);
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 4px 1fr;
`;

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--global-spacing-xxs);
  gap: var(--global-spacing-xxs);
  min-width: 0;
  overflow-x: hidden;
`;

interface SkeletonPanelProps {
  text: string;
}

const SkeletonPanel = ({ text }: SkeletonPanelProps) => {
  const lines = text.split("\n");
  return (
    <SkeletonContainer>
      {[...lines, ...lines.reverse(), ...lines]
        .filter((line) => line.length > 1)
        .map((line, index) => (
          <Skeleton key={index} height={16} mt={6} width="auto">
            {line}
          </Skeleton>
        ))}
    </SkeletonContainer>
  );
};

const Loading = () => {
  return (
    <Container>
      <EditorHeader />
      <Grid>
        <SkeletonPanel text={DEFAULT_PATTERN} />
        <SplitGutter between="left-right" />
        <SkeletonPanel text={DEFAULT_TARGET} />
      </Grid>
    </Container>
  );
};

export const EditorLoading = observer(Loading);
