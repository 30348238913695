// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/ai/v1/memories.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "protos.ai.v1";

export interface Memory {
  id?: number | undefined;
  content: string;
  ruleId?: number | undefined;
  ruleName?: string | undefined;
  repositoryId?: number | undefined;
  repositoryName?: string | undefined;
  createdAt: Date | undefined;
  createdBy?: string | undefined;
  context: string[];
}

export interface CreateMemoryRequest {
  memory: Memory | undefined;
}

export interface CreateMemoryResponse {
  memory: Memory | undefined;
}

export interface ListMemoriesResponse {
  memories: Memory[];
}

export interface FindRelevantIssuesRequest {
  ruleId?: number | undefined;
  repositoryId?: number | undefined;
}

export interface FindRelevantIssuesResponse {
  issueIds: number[];
}

function createBaseMemory(): Memory {
  return {
    id: undefined,
    content: "",
    ruleId: undefined,
    ruleName: undefined,
    repositoryId: undefined,
    repositoryName: undefined,
    createdAt: undefined,
    createdBy: undefined,
    context: [],
  };
}

export const Memory = {
  encode(message: Memory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.content !== "") {
      writer.uint32(18).string(message.content);
    }
    if (message.ruleId !== undefined) {
      writer.uint32(24).uint64(message.ruleId);
    }
    if (message.ruleName !== undefined) {
      writer.uint32(34).string(message.ruleName);
    }
    if (message.repositoryId !== undefined) {
      writer.uint32(40).uint64(message.repositoryId);
    }
    if (message.repositoryName !== undefined) {
      writer.uint32(50).string(message.repositoryName);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(58).fork()).ldelim();
    }
    if (message.createdBy !== undefined) {
      writer.uint32(66).string(message.createdBy);
    }
    for (const v of message.context) {
      writer.uint32(74).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Memory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.content = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.ruleId = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ruleName = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.repositoryId = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.repositoryName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.context.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Memory {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : undefined,
      content: isSet(object.content) ? globalThis.String(object.content) : "",
      ruleId: isSet(object.ruleId) ? globalThis.Number(object.ruleId) : undefined,
      ruleName: isSet(object.ruleName) ? globalThis.String(object.ruleName) : undefined,
      repositoryId: isSet(object.repositoryId) ? globalThis.Number(object.repositoryId) : undefined,
      repositoryName: isSet(object.repositoryName) ? globalThis.String(object.repositoryName) : undefined,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      createdBy: isSet(object.createdBy) ? globalThis.String(object.createdBy) : undefined,
      context: globalThis.Array.isArray(object?.context) ? object.context.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: Memory): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = Math.round(message.id);
    }
    if (message.content !== "") {
      obj.content = message.content;
    }
    if (message.ruleId !== undefined) {
      obj.ruleId = Math.round(message.ruleId);
    }
    if (message.ruleName !== undefined) {
      obj.ruleName = message.ruleName;
    }
    if (message.repositoryId !== undefined) {
      obj.repositoryId = Math.round(message.repositoryId);
    }
    if (message.repositoryName !== undefined) {
      obj.repositoryName = message.repositoryName;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.createdBy !== undefined) {
      obj.createdBy = message.createdBy;
    }
    if (message.context?.length) {
      obj.context = message.context;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Memory>, I>>(base?: I): Memory {
    return Memory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Memory>, I>>(object: I): Memory {
    const message = createBaseMemory();
    message.id = object.id ?? undefined;
    message.content = object.content ?? "";
    message.ruleId = object.ruleId ?? undefined;
    message.ruleName = object.ruleName ?? undefined;
    message.repositoryId = object.repositoryId ?? undefined;
    message.repositoryName = object.repositoryName ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.createdBy = object.createdBy ?? undefined;
    message.context = object.context?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreateMemoryRequest(): CreateMemoryRequest {
  return { memory: undefined };
}

export const CreateMemoryRequest = {
  encode(message: CreateMemoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.memory !== undefined) {
      Memory.encode(message.memory, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateMemoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateMemoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.memory = Memory.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateMemoryRequest {
    return { memory: isSet(object.memory) ? Memory.fromJSON(object.memory) : undefined };
  },

  toJSON(message: CreateMemoryRequest): unknown {
    const obj: any = {};
    if (message.memory !== undefined) {
      obj.memory = Memory.toJSON(message.memory);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateMemoryRequest>, I>>(base?: I): CreateMemoryRequest {
    return CreateMemoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateMemoryRequest>, I>>(object: I): CreateMemoryRequest {
    const message = createBaseCreateMemoryRequest();
    message.memory = (object.memory !== undefined && object.memory !== null)
      ? Memory.fromPartial(object.memory)
      : undefined;
    return message;
  },
};

function createBaseCreateMemoryResponse(): CreateMemoryResponse {
  return { memory: undefined };
}

export const CreateMemoryResponse = {
  encode(message: CreateMemoryResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.memory !== undefined) {
      Memory.encode(message.memory, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateMemoryResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateMemoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.memory = Memory.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateMemoryResponse {
    return { memory: isSet(object.memory) ? Memory.fromJSON(object.memory) : undefined };
  },

  toJSON(message: CreateMemoryResponse): unknown {
    const obj: any = {};
    if (message.memory !== undefined) {
      obj.memory = Memory.toJSON(message.memory);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateMemoryResponse>, I>>(base?: I): CreateMemoryResponse {
    return CreateMemoryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateMemoryResponse>, I>>(object: I): CreateMemoryResponse {
    const message = createBaseCreateMemoryResponse();
    message.memory = (object.memory !== undefined && object.memory !== null)
      ? Memory.fromPartial(object.memory)
      : undefined;
    return message;
  },
};

function createBaseListMemoriesResponse(): ListMemoriesResponse {
  return { memories: [] };
}

export const ListMemoriesResponse = {
  encode(message: ListMemoriesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.memories) {
      Memory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMemoriesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMemoriesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.memories.push(Memory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMemoriesResponse {
    return {
      memories: globalThis.Array.isArray(object?.memories) ? object.memories.map((e: any) => Memory.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListMemoriesResponse): unknown {
    const obj: any = {};
    if (message.memories?.length) {
      obj.memories = message.memories.map((e) => Memory.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListMemoriesResponse>, I>>(base?: I): ListMemoriesResponse {
    return ListMemoriesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListMemoriesResponse>, I>>(object: I): ListMemoriesResponse {
    const message = createBaseListMemoriesResponse();
    message.memories = object.memories?.map((e) => Memory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFindRelevantIssuesRequest(): FindRelevantIssuesRequest {
  return { ruleId: undefined, repositoryId: undefined };
}

export const FindRelevantIssuesRequest = {
  encode(message: FindRelevantIssuesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruleId !== undefined) {
      writer.uint32(8).uint64(message.ruleId);
    }
    if (message.repositoryId !== undefined) {
      writer.uint32(16).uint64(message.repositoryId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FindRelevantIssuesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFindRelevantIssuesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ruleId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.repositoryId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FindRelevantIssuesRequest {
    return {
      ruleId: isSet(object.ruleId) ? globalThis.Number(object.ruleId) : undefined,
      repositoryId: isSet(object.repositoryId) ? globalThis.Number(object.repositoryId) : undefined,
    };
  },

  toJSON(message: FindRelevantIssuesRequest): unknown {
    const obj: any = {};
    if (message.ruleId !== undefined) {
      obj.ruleId = Math.round(message.ruleId);
    }
    if (message.repositoryId !== undefined) {
      obj.repositoryId = Math.round(message.repositoryId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FindRelevantIssuesRequest>, I>>(base?: I): FindRelevantIssuesRequest {
    return FindRelevantIssuesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FindRelevantIssuesRequest>, I>>(object: I): FindRelevantIssuesRequest {
    const message = createBaseFindRelevantIssuesRequest();
    message.ruleId = object.ruleId ?? undefined;
    message.repositoryId = object.repositoryId ?? undefined;
    return message;
  },
};

function createBaseFindRelevantIssuesResponse(): FindRelevantIssuesResponse {
  return { issueIds: [] };
}

export const FindRelevantIssuesResponse = {
  encode(message: FindRelevantIssuesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.issueIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FindRelevantIssuesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFindRelevantIssuesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.issueIds.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.issueIds.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FindRelevantIssuesResponse {
    return {
      issueIds: globalThis.Array.isArray(object?.issueIds) ? object.issueIds.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: FindRelevantIssuesResponse): unknown {
    const obj: any = {};
    if (message.issueIds?.length) {
      obj.issueIds = message.issueIds.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FindRelevantIssuesResponse>, I>>(base?: I): FindRelevantIssuesResponse {
    return FindRelevantIssuesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FindRelevantIssuesResponse>, I>>(object: I): FindRelevantIssuesResponse {
    const message = createBaseFindRelevantIssuesResponse();
    message.issueIds = object.issueIds?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
