import { Ruleset } from "@shared/types";

import { authPost } from "../auth";

import { RulesetPostParams } from "./types";

export const postRuleset = (name: string, data: RulesetPostParams) => {
  return authPost<RulesetPostParams, Ruleset>(
    `/api/registry/rulesets/${name}`,
    data
  );
};
