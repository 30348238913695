import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  faClipboard,
  faClipboardCheck,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mantine/core";

import { useDebouncedEffect } from "@shared/hooks";
import { copyElementToClipboard } from "@shared/utils";

interface Props {
  getTarget: () => HTMLElement | null;
  style?: React.CSSProperties;
}

const ActionIcon = styled(FontAwesomeIcon)`
  font-size: var(--mantine-font-size-md);
  cursor: pointer;
`;

export const CopyButton: React.FC<Props> = ({
  getTarget,
  style = undefined,
}) => {
  const [copySucceeded, setCopySucceeded] = useState<boolean>();
  const [copyContent, setCopyContent] = useState<string>();
  const [copyIntent, setCopyIntent] = useState<string>();

  // Auto-close some time after most recent open
  useDebouncedEffect(
    () => {
      copySucceeded !== undefined && setCopySucceeded(undefined);
    },
    2000,
    [copySucceeded]
  );

  // Set tooltip content but leave it when it's running the closing animation
  useEffect(() => {
    if (copySucceeded === undefined) {
      return;
    }
    setCopyContent(copySucceeded ? "Copied!" : "Failed to copy");
    setCopyIntent(copySucceeded ? "green" : "red");
  }, [copySucceeded]);

  return (
    <Tooltip
      label={copyContent}
      disabled={copyContent === undefined}
      color={copyIntent}
      opened={copySucceeded !== undefined}
      withArrow
      withinPortal
    >
      <ActionIcon
        color="gray"
        style={style}
        icon={copySucceeded ? faClipboardCheck : faClipboard}
        aria-label="Copy to clipboard"
        onClick={() => {
          setCopySucceeded(undefined);
          const target = getTarget();
          target &&
            copyElementToClipboard(target)
              .then(() => setCopySucceeded(true))
              .catch(() => setCopySucceeded(false));
        }}
      />
    </Tooltip>
  );
};
