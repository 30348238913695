import { useContext } from "react";
import { faFilePlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Group, Stack, Text, Title, UnstyledButton } from "@mantine/core";

import { Permission } from "@shared/types";

import { WorkbenchContext } from "../../providers";

import { SplashExamples } from "./SplashExamples";
import { SplashRecents } from "./SplashRecents";
import { SplashTutorials } from "./SplashTutorials";

export const SplashPanel = () => {
  const { workbench } = useContext(WorkbenchContext);

  return (
    <div>
      {/* height is taken from navbar orgpicker height */}
      <Group h={59} bg="dark.5" p="sm" align="center">
        <Title order={3} c="white">
          Editor
        </Title>
      </Group>
      <Group wrap="nowrap" bg="gray.2" p="lg" align="flex-end">
        {workbench.permissions.includes(Permission.editor_create) && (
          <UnstyledButton
            variant="button-card"
            onClick={() => workbench.newBundle()}
          >
            <Text c="blue.8">
              <FontAwesomeIcon icon={faFilePlus} size="6x" />
            </Text>
            <Text fz="xl">Create new rule</Text>
          </UnstyledButton>
        )}
        <Stack style={{ flexGrow: 1 }}>
          <Title order={4}>Recent</Title>
          <SplashRecents />
        </Stack>
      </Group>
      <Stack gap="lg" p="lg">
        <Stack>
          <Title order={4}>Examples</Title>
          <SplashExamples />
        </Stack>
        <Stack>
          <Title order={4}>Learn</Title>
          <SplashTutorials />
        </Stack>
      </Stack>
    </div>
  );
};
