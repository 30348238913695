import { useMemo } from "react";

import { ERROR_TYPE_CODE, ERROR_TYPE_PATTERN } from "@shared/constants";
import { RunResponse, SemgrepRunException } from "@shared/types";
// TODO: Remove disabled when semgrep ouptput types are moved to a lib
// eslint-disable-next-line
import { CliError, CliMatch, MatchingExplanation } from "@semgrep_output_types";

export interface RunResponseStructure {
  findings: CliMatch[];
  cliErrors: CliError[];
  patternErrors: CliError[];
  targetErrors: CliError[];
  fatalErrors: CliError[];
  runError?: SemgrepRunException;
  allErrors: (CliError | SemgrepRunException)[];
  explanations?: MatchingExplanation[];
}

export const useRunResponse = (
  runResult: RunResponse
): RunResponseStructure => {
  const findings = runResult.semgrep_result.output.results;
  const explanations = runResult.semgrep_result.output.explanations;
  const cliErrors = runResult.semgrep_result.output.errors;
  const patternErrors = useMemo(
    () => cliErrors.filter((err) => err.type_.kind === ERROR_TYPE_PATTERN),
    [cliErrors]
  );
  const targetErrors = useMemo(
    () => cliErrors.filter((err) => err.type_.kind === ERROR_TYPE_CODE),
    [cliErrors]
  );
  const fatalErrors = useMemo(
    () =>
      cliErrors.filter(
        (err) =>
          err.type_.kind !== ERROR_TYPE_CODE &&
          err.type_.kind !== ERROR_TYPE_PATTERN
      ),
    [cliErrors]
  );
  const runError = runResult.semgrep_result.run_error;
  const allErrors = useMemo(() => {
    const errors: (CliError | SemgrepRunException)[] = [...cliErrors];
    if (runError) {
      errors.push(runError);
    }
    return errors;
  }, [cliErrors, runError]);
  return {
    findings,
    cliErrors,
    patternErrors,
    targetErrors,
    runError,
    fatalErrors,
    allErrors,
    explanations,
  };
};
