import { makeAutoObservable } from "mobx";

import { fetchRule } from "@shared/api";
import { Rule } from "@shared/types";

interface AddressInterface {
  fullName: string;
  addressFragment: () => string;
  fetch: () => Promise<Rule>;
}

// Address of a rule that's coming from the registry
export class RegistryAddress implements AddressInterface {
  path: string;

  constructor(path: string) {
    makeAutoObservable(this);
    this.path = path;
  }

  get fullName(): string {
    return `${this.path}`;
  }

  addressFragment(): string {
    return `r/${this.path}`;
  }

  async fetch() {
    const response = await fetchRule(this.fullName);
    return response;
  }
}

// Address of a snippet
// Id is random characters
export class SnippetAddress implements AddressInterface {
  id: string;

  constructor(id: string) {
    makeAutoObservable(this);
    this.id = id;
  }

  get fullName(): string {
    return this.id;
  }

  addressFragment() {
    return `s/${this.id}`;
  }

  async fetch() {
    const response = await fetchRule(this.fullName);
    return response;
  }
}

// This combines the immutable hashid for a Rule with a human friendly string that
// isn't important to the loading of the rule (and if it's renamed will still work)
// eg /r/1DzJ/the.path.of.the.rule
export class CombinedRegistryAddress implements AddressInterface {
  id: string;
  fullName: string;

  constructor(id: string, friendlyName: string) {
    makeAutoObservable(this);
    this.id = id;
    this.fullName = friendlyName;
  }

  addressFragment() {
    return `r/${this.id}/${this.fullName}`;
  }

  async fetch() {
    const response = await fetchRule(this.id);
    return response;
  }
}

export type Address =
  | CombinedRegistryAddress
  | RegistryAddress
  | SnippetAddress;
