import { MatchingOperation } from "@semgrep_output_types";

import { opDictSyntaxOriginal } from "../constants/opDictSyntaxOriginal";

export const opToSyntax = (op: MatchingOperation): string => {
  const opKind = op.kind;
  if (opKind in opDictSyntaxOriginal) {
    return opDictSyntaxOriginal[opKind as keyof typeof opDictSyntaxOriginal];
  }
  return opKind;
};

export const opToRemaining = (op: MatchingOperation): string => {
  if ("value" in op) {
    return op.value;
  } else {
    return "";
  }
};
