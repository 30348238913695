import { useContext } from "react";
import styled from "styled-components";
import { faHammer, faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CodeHighlight } from "@mantine/code-highlight";
import { Button } from "@mantine/core";

import { AISuggestion } from "@shared/types";
import { dedent, isRealLanguageName } from "@shared/utils";
import { CliMatch } from "@semgrep_output_types";

import { WorkbenchContext } from "../../providers";

interface Props {
  onResultClick?: (line?: number) => () => void;
  message?: string;
  line?: number;
}
export interface MatchProps extends Props {
  item: CliMatch;
  fixes?: string[];
  dirty: boolean;
  fixesApplied?: { [key: string]: boolean };
  onApplyFix?: (r: CliMatch) => () => void;
  onApplyAIFix: (fix: AISuggestion) => void;
  aiAutofix?: AISuggestion;
}

const ResultFix = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export function AutofixMatchPanel(props: MatchProps) {
  const { item, fixes, dirty, onApplyFix, aiAutofix, onApplyAIFix } = props;
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;

  let languageString = bundle?.language;

  if (languageString == null || !isRealLanguageName(languageString)) {
    languageString = undefined;
  }

  return (
    onApplyFix &&
    fixes && (
      <ResultFix>
        {aiAutofix && (
          <Button
            disabled={dirty || aiAutofix.status !== "success"}
            onClick={() => onApplyAIFix(aiAutofix)}
            variant="outline"
            size="xs"
            leftSection={<FontAwesomeIcon icon={faSparkles} />}
          >
            {aiAutofix.status !== "success" ? "AI autofix" : "AI autofix"}
          </Button>
        )}
        <Button
          style={{
            flexShrink: 0,
          }}
          size="xs"
          onClick={onApplyFix(item)}
          variant="outline"
          disabled={dirty}
          leftSection={<FontAwesomeIcon icon={faHammer} />}
        >
          autofix
        </Button>
        <CodeHighlight
          //noCopy
          language={languageString}
          // highlightLines={dedent(fixes).reduce(
          //   (acc, cur, i) => ({ ...acc, [i + 1]: { color: "yellow" } }),
          //   {}
          // )}
          className="suggested-fix"
          style={{
            overflowX: "scroll",
            flexGrow: 1,
          }}
          code={dedent(fixes).join("\n")}
        />
      </ResultFix>
    )
  );
}
