import { useState } from "react";
import pluralize from "pluralize";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import {
  faClock,
  faInfoCircle,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  Alert,
  Checkbox,
  Group,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";

import {
  GetSearchResponse,
  RepositorySearchStatus,
  SearchState,
} from "@protos/search/v1/search_service";
import { Spinner } from "@shared/components";
import { TERMINAL_SEARCH_STATES } from "@shared/hooks";

import { EditorQueryConsoleFinding } from "./EditorQueryConsoleFinding";
import { QueryConsoleFeedbackButton } from "./QueryConsoleFeedbackButton";

interface EditorQueryConsoleResultsProps {
  searchResult: GetSearchResponse;
}

const getRepoName = (repo: RepositorySearchStatus) =>
  repo.repository?.fullName || repo.fullName;

export const EditorQueryConsoleResults = ({
  searchResult,
}: EditorQueryConsoleResultsProps) => {
  const theme = useMantineTheme();
  const [hideEmptyResults, setHideEmptyResults] = useLocalStorage({
    key: "qc_hide_empty_results",
    defaultValue: false,
  });

  const reposToRender = hideEmptyResults
    ? searchResult.repositories.filter(
        (repo) =>
          !TERMINAL_SEARCH_STATES.includes(repo.state) ||
          (TERMINAL_SEARCH_STATES.includes(repo.state) && repo.resultCount > 0)
      )
    : searchResult.repositories;

  const [viewingRepoName, setViewingRepoName] = useState<string | null>(null);

  const terminatedReposWithNoResults =
    searchResult.repositories.filter(
      (repo) =>
        repo.resultCount === 0 && TERMINAL_SEARCH_STATES.includes(repo.state)
    ).length || 0;

  return (
    <div>
      <Group justify="space-between">
        <Title order={3} p="md">
          {!searchResult.terminated ? "Searching" : "Searched"}{" "}
          {searchResult.totalRepositories}{" "}
          {pluralize("repository", searchResult.totalRepositories)}
          {!searchResult.terminated && "..."}
        </Title>
        <QueryConsoleFeedbackButton
          variant="white"
          color={theme.primaryColor}
        />
      </Group>
      {terminatedReposWithNoResults > 0 && (
        <Checkbox
          label={
            <span>
              Hide {terminatedReposWithNoResults}{" "}
              {pluralize("repo", terminatedReposWithNoResults)} with no results
            </span>
          }
          size="sm"
          ml="md"
          mb="md"
          checked={hideEmptyResults}
          onChange={(evt) => setHideEmptyResults(evt.target.checked)}
        />
      )}
      {searchResult.limit && (
        <Alert m="md" icon={<FontAwesomeIcon icon={faInfoCircle} />}>
          Results limited to {searchResult.limit} per repository
        </Alert>
      )}
      {reposToRender.length > 0 && (
        <Accordion onChange={setViewingRepoName}>
          {reposToRender
            .sort((a, b) => getRepoName(a).localeCompare(getRepoName(b)))
            .map((repoResults) => {
              const repoName = getRepoName(repoResults);
              return (
                <Accordion.Item key={repoName} value={repoName}>
                  <Accordion.Control
                    icon={<FontAwesomeIcon icon={faGithub} />}
                    disabled={!repoResults.resultCount}
                    chevron={
                      !TERMINAL_SEARCH_STATES.includes(repoResults.state) ? (
                        <Spinner size={20} />
                      ) : undefined
                    }
                  >
                    <Group justify="space-between">
                      <span>{repoName}</span>

                      {TERMINAL_SEARCH_STATES.includes(repoResults.state) ? (
                        <Group>
                          {repoResults.state === SearchState.ERROR ? (
                            <Tooltip label="Search failed with an error">
                              <FontAwesomeIcon icon={faTriangleExclamation} />
                            </Tooltip>
                          ) : null}
                          {repoResults.state === SearchState.TIMEOUT ? (
                            <Tooltip label="Search took too long to finish">
                              <FontAwesomeIcon icon={faClock} />
                            </Tooltip>
                          ) : null}
                          <span>
                            {repoResults.resultCount}{" "}
                            {pluralize("result", repoResults.resultCount)}
                          </span>
                        </Group>
                      ) : null}
                    </Group>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <EditorQueryConsoleFinding
                      key={repoName}
                      searchId={searchResult.searchId}
                      repoName={repoName}
                      visible={viewingRepoName === repoName}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              );
            })}
        </Accordion>
      )}
      {!reposToRender.length && searchResult.terminated && (
        <Text p="md">No results found</Text>
      )}
    </div>
  );
};
