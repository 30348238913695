import { useContext, useState } from "react";
import styled from "styled-components";
import { Badge, Group, Tabs } from "@mantine/core";

import { DEFAULT_PATTERN } from "@shared/constants";
import { useUrlSearch } from "@shared/hooks";

import { WorkbenchContext } from "../../providers/workbenchContext";
import { TAB_ID } from "../../types";
import RuleInspectorPanel from "../RuleInspector/RuleInspectorPanel";
import { SimpleMode } from "../SimpleMode";
import { StructureMode } from "../StructureMode";
import {
  SwitchToStructureAlert,
  SwitchToStructureAlertError,
} from "../StructureMode/utils/SwitchToStructureAlert";

import { RuleEditor } from "./RuleEditor";

const TestPanel = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr) min-content;
`;

const SecondaryTestPanel = styled(TestPanel)`
  background-color: #ebf1f5;
`;

const TestPanelContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr);
`;

/**
 * Rule editor panel with simple and advanced tabs
 */
export const RuleEditorPanel = ({ defaultTab }: { defaultTab: TAB_ID }) => {
  const { search } = useUrlSearch();
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;

  const tab = (search.get("editorMode") as TAB_ID) || defaultTab;
  const [structureSwitchFailure, setStructureSwitchFailure] =
    useState<SwitchToStructureAlertError | null>(null);

  // This sets the current tab so that we can accurately log this information
  // to datadog!
  // We do it out here instead of inside setSelectedTabIndex because we want to
  // set the tab initially, even if we don't click anything.
  if (bundle) {
    bundle.workbench.editors.ruleEditorMode = tab;
  }

  function onStructureSwitchFailure(kind: SwitchToStructureAlertError) {
    setStructureSwitchFailure(kind);
  }

  // We need to validate whether the advanced rule can be represented in Structure Mode before
  // we render the component.
  if (tab === "structure") {
    if (bundle?.getTextToStructureRuleErrors !== null) {
      workbench.setSelectedRuleEditorTabIndex(
        tab,
        "advanced",
        search,
        onStructureSwitchFailure
      );
    }
  }

  return (
    <>
      {/* This code manages the logic for when a user tries to switch to Structure
      Mode while they have a rule which does not parse. */}
      <SwitchToStructureAlert
        error={structureSwitchFailure}
        onConfirm={() => {
          if (bundle) {
            bundle.ruleText = DEFAULT_PATTERN;
            workbench.setSelectedRuleEditorTabIndex(
              tab,
              "structure",
              search,
              onStructureSwitchFailure
            );
            setStructureSwitchFailure(null);
          }
        }}
        onCancel={() => {
          setStructureSwitchFailure(null);
        }}
      />
      <TestPanelContainer>
        <Tabs
          defaultValue="structure"
          value={tab}
          onChange={(newTab) => {
            workbench.setSelectedRuleEditorTabIndex(
              tab,
              newTab,
              search,
              onStructureSwitchFailure
            );
          }}
        >
          <Tabs.List>
            {/* Tentatively killed in favor of Structure Mode */}
            {/* <Tabs.Tab value="simple">simple</Tabs.Tab> */}
            {
              <Tabs.Tab value="structure">
                {/* This gap is hardcoded, but it won't be here long. */}
                <Group gap={5}>
                  <div>structure</div>
                  <Badge color="blue" size="xs" variant="light">
                    NEW
                  </Badge>
                </Group>
              </Tabs.Tab>
            }
            <Tabs.Tab value="advanced">advanced</Tabs.Tab>
          </Tabs.List>
        </Tabs>

        {tab === "advanced" && (
          <TestPanel>
            <RuleEditor />
          </TestPanel>
        )}
        {tab === "structure" && (
          <TestPanel>
            <StructureMode />
          </TestPanel>
        )}
        {tab === "simple" && (
          <SecondaryTestPanel>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  overflow: "scroll",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
              >
                <SimpleMode />
              </div>
            </div>
          </SecondaryTestPanel>
        )}
        {(tab === "advanced" || tab === "simple") && <RuleInspectorPanel />}
      </TestPanelContainer>
    </>
  );
};
