import { useHistory } from "react-router-dom";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { SpotlightActionData } from "@mantine/spotlight";

import { useNavigationLinks } from "../useNavigationLinks";
import { useUser } from "../useUser";

interface NavigationLinkProps {
  to?: string;
  search?: string;
  label: string;
  betaLabel?: boolean;
  active?: boolean;
  icon?: React.ReactNode;
  count?: number;
  routes?: NavigationLinkProps[];
  isChild?: boolean;
  menuOpen?: boolean;
  secondaryLink?: boolean;
  rightSection?: React.ReactNode;
  isExternalLink?: boolean;
  isDropdownMenuItem?: boolean;
  visible?: boolean;
  isHoverCardItem?: boolean;
  hideIcon?: boolean;
  children?: React.ReactNode;
}

export const useSpotlightActions = () => {
  const { links } = useNavigationLinks();
  const history = useHistory();
  const [user] = useUser();

  const goToPath = (path: string, external: boolean) => {
    if (external) {
      window.open(path, "_blank");
    } else {
      history.push(`${path}`);
    }
  };

  const pushLink = (link: NavigationLinkProps) => {
    actions.push({
      id: link.label,
      label: link.label,
      onClick: () => goToPath(link.to || "", Boolean(link.isExternalLink)),
      leftSection: link.icon,
    });
  };

  const actions: SpotlightActionData[] = [];
  for (const link of links) {
    if (link.visible) {
      if (link.to) {
        pushLink(link);
      }
      if (link.routes) {
        for (const childRoute of link.routes) {
          if (childRoute.visible) {
            pushLink(childRoute);
          }
        }
      }
    }
  }

  if (!user) {
    return [
      {
        id: "login",
        title: "Log in",
        onTrigger: () => goToPath("/login", false),
        icon: <FontAwesomeIcon icon={faUser} fixedWidth />,
      },
    ];
  }
  return actions;
};
