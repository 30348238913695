// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/sca/v1/sca.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { ExternalTicket } from "../../ticketing/v1/ticketing";

export const protobufPackage = "protos.sca.v1";

export enum Ecosystem {
  no_package_manager = "no_package_manager",
  npm = "npm",
  pypi = "pypi",
  gomod = "gomod",
  cargo = "cargo",
  maven = "maven",
  gem = "gem",
  composer = "composer",
  nuget = "nuget",
  pub = "pub",
  swiftpm = "swiftpm",
  hex = "hex",
}

export function ecosystemFromJSON(object: any): Ecosystem {
  switch (object) {
    case 0:
    case "no_package_manager":
      return Ecosystem.no_package_manager;
    case 10:
    case "npm":
      return Ecosystem.npm;
    case 20:
    case "pypi":
      return Ecosystem.pypi;
    case 30:
    case "gomod":
      return Ecosystem.gomod;
    case 40:
    case "cargo":
      return Ecosystem.cargo;
    case 50:
    case "maven":
      return Ecosystem.maven;
    case 60:
    case "gem":
      return Ecosystem.gem;
    case 70:
    case "composer":
      return Ecosystem.composer;
    case 80:
    case "nuget":
      return Ecosystem.nuget;
    case 90:
    case "pub":
      return Ecosystem.pub;
    case 100:
    case "swiftpm":
      return Ecosystem.swiftpm;
    case 110:
    case "hex":
      return Ecosystem.hex;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Ecosystem");
  }
}

export function ecosystemToJSON(object: Ecosystem): string {
  switch (object) {
    case Ecosystem.no_package_manager:
      return "no_package_manager";
    case Ecosystem.npm:
      return "npm";
    case Ecosystem.pypi:
      return "pypi";
    case Ecosystem.gomod:
      return "gomod";
    case Ecosystem.cargo:
      return "cargo";
    case Ecosystem.maven:
      return "maven";
    case Ecosystem.gem:
      return "gem";
    case Ecosystem.composer:
      return "composer";
    case Ecosystem.nuget:
      return "nuget";
    case Ecosystem.pub:
      return "pub";
    case Ecosystem.swiftpm:
      return "swiftpm";
    case Ecosystem.hex:
      return "hex";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Ecosystem");
  }
}

export function ecosystemToNumber(object: Ecosystem): number {
  switch (object) {
    case Ecosystem.no_package_manager:
      return 0;
    case Ecosystem.npm:
      return 10;
    case Ecosystem.pypi:
      return 20;
    case Ecosystem.gomod:
      return 30;
    case Ecosystem.cargo:
      return 40;
    case Ecosystem.maven:
      return 50;
    case Ecosystem.gem:
      return 60;
    case Ecosystem.composer:
      return 70;
    case Ecosystem.nuget:
      return 80;
    case Ecosystem.pub:
      return 90;
    case Ecosystem.swiftpm:
      return 100;
    case Ecosystem.hex:
      return 110;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Ecosystem");
  }
}

export enum Transitivity {
  UNKNOWN_TRANSITIVITY = "UNKNOWN_TRANSITIVITY",
  TRANSITIVE = "TRANSITIVE",
  DIRECT = "DIRECT",
}

export function transitivityFromJSON(object: any): Transitivity {
  switch (object) {
    case 0:
    case "UNKNOWN_TRANSITIVITY":
      return Transitivity.UNKNOWN_TRANSITIVITY;
    case 10:
    case "TRANSITIVE":
      return Transitivity.TRANSITIVE;
    case 20:
    case "DIRECT":
      return Transitivity.DIRECT;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Transitivity");
  }
}

export function transitivityToJSON(object: Transitivity): string {
  switch (object) {
    case Transitivity.UNKNOWN_TRANSITIVITY:
      return "UNKNOWN_TRANSITIVITY";
    case Transitivity.TRANSITIVE:
      return "TRANSITIVE";
    case Transitivity.DIRECT:
      return "DIRECT";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Transitivity");
  }
}

export function transitivityToNumber(object: Transitivity): number {
  switch (object) {
    case Transitivity.UNKNOWN_TRANSITIVITY:
      return 0;
    case Transitivity.TRANSITIVE:
      return 10;
    case Transitivity.DIRECT:
      return 20;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Transitivity");
  }
}

export enum Reachability {
  UNSPECIFIED = "REACHABILITY_UNSPECIFIED",
  /** CONDITIONALLY_REACHABLE - signifies that a supply chain issue is reachable from the client's code if certain conditions are met, and requires manual verification (semgrep's scan could not verify it automatically) */
  CONDITIONALLY_REACHABLE = "REACHABILITY_CONDITIONALLY_REACHABLE",
  /** ALWAYS_REACHABLE - signifies that the very presence of a dependency IS the issue, no matter how the dependency is used -- fixing requires upgrading or replacing the dependency */
  ALWAYS_REACHABLE = "REACHABILITY_ALWAYS_REACHABLE",
  /** REACHABLE - signifies that a supply chain issue is reachable from the client's code (semgrep's scan found it by matching code against a pattern) */
  REACHABLE = "REACHABILITY_REACHABLE",
  /** UNREACHABLE - signifies that a supply chain issue is NOT reachable from the client's code */
  UNREACHABLE = "REACHABILITY_UNREACHABLE",
  /** UNKNOWN - signifies that a supply chain issue's reachability is unknown */
  UNKNOWN = "REACHABILITY_UNKNOWN",
}

export function reachabilityFromJSON(object: any): Reachability {
  switch (object) {
    case 0:
    case "REACHABILITY_UNSPECIFIED":
      return Reachability.UNSPECIFIED;
    case 1:
    case "REACHABILITY_CONDITIONALLY_REACHABLE":
      return Reachability.CONDITIONALLY_REACHABLE;
    case 2:
    case "REACHABILITY_ALWAYS_REACHABLE":
      return Reachability.ALWAYS_REACHABLE;
    case 3:
    case "REACHABILITY_REACHABLE":
      return Reachability.REACHABLE;
    case 4:
    case "REACHABILITY_UNREACHABLE":
      return Reachability.UNREACHABLE;
    case 5:
    case "REACHABILITY_UNKNOWN":
      return Reachability.UNKNOWN;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Reachability");
  }
}

export function reachabilityToJSON(object: Reachability): string {
  switch (object) {
    case Reachability.UNSPECIFIED:
      return "REACHABILITY_UNSPECIFIED";
    case Reachability.CONDITIONALLY_REACHABLE:
      return "REACHABILITY_CONDITIONALLY_REACHABLE";
    case Reachability.ALWAYS_REACHABLE:
      return "REACHABILITY_ALWAYS_REACHABLE";
    case Reachability.REACHABLE:
      return "REACHABILITY_REACHABLE";
    case Reachability.UNREACHABLE:
      return "REACHABILITY_UNREACHABLE";
    case Reachability.UNKNOWN:
      return "REACHABILITY_UNKNOWN";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Reachability");
  }
}

export function reachabilityToNumber(object: Reachability): number {
  switch (object) {
    case Reachability.UNSPECIFIED:
      return 0;
    case Reachability.CONDITIONALLY_REACHABLE:
      return 1;
    case Reachability.ALWAYS_REACHABLE:
      return 2;
    case Reachability.REACHABLE:
      return 3;
    case Reachability.UNREACHABLE:
      return 4;
    case Reachability.UNKNOWN:
      return 5;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Reachability");
  }
}

export enum ScaRuleKind {
  UNSPECIFIED = "SCA_RULE_KIND_UNSPECIFIED",
  REACHABLE = "SCA_RULE_KIND_REACHABLE",
  UPGRADE_ONLY = "SCA_RULE_KIND_UPGRADE_ONLY",
  MALICIOUS_DEPENDENCY = "SCA_RULE_KIND_MALICIOUS_DEPENDENCY",
  LEGACY = "SCA_RULE_KIND_LEGACY",
}

export function scaRuleKindFromJSON(object: any): ScaRuleKind {
  switch (object) {
    case 0:
    case "SCA_RULE_KIND_UNSPECIFIED":
      return ScaRuleKind.UNSPECIFIED;
    case 1:
    case "SCA_RULE_KIND_REACHABLE":
      return ScaRuleKind.REACHABLE;
    case 2:
    case "SCA_RULE_KIND_UPGRADE_ONLY":
      return ScaRuleKind.UPGRADE_ONLY;
    case 3:
    case "SCA_RULE_KIND_MALICIOUS_DEPENDENCY":
      return ScaRuleKind.MALICIOUS_DEPENDENCY;
    case 4:
    case "SCA_RULE_KIND_LEGACY":
      return ScaRuleKind.LEGACY;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ScaRuleKind");
  }
}

export function scaRuleKindToJSON(object: ScaRuleKind): string {
  switch (object) {
    case ScaRuleKind.UNSPECIFIED:
      return "SCA_RULE_KIND_UNSPECIFIED";
    case ScaRuleKind.REACHABLE:
      return "SCA_RULE_KIND_REACHABLE";
    case ScaRuleKind.UPGRADE_ONLY:
      return "SCA_RULE_KIND_UPGRADE_ONLY";
    case ScaRuleKind.MALICIOUS_DEPENDENCY:
      return "SCA_RULE_KIND_MALICIOUS_DEPENDENCY";
    case ScaRuleKind.LEGACY:
      return "SCA_RULE_KIND_LEGACY";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ScaRuleKind");
  }
}

export function scaRuleKindToNumber(object: ScaRuleKind): number {
  switch (object) {
    case ScaRuleKind.UNSPECIFIED:
      return 0;
    case ScaRuleKind.REACHABLE:
      return 1;
    case ScaRuleKind.UPGRADE_ONLY:
      return 2;
    case ScaRuleKind.MALICIOUS_DEPENDENCY:
      return 3;
    case ScaRuleKind.LEGACY:
      return 4;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ScaRuleKind");
  }
}

export enum EpssProbability {
  UNSPECIFIED = "EPSS_PROBABILITY_UNSPECIFIED",
  /** LOW - represents a score between 0 and 0.3 */
  LOW = "EPSS_PROBABILITY_LOW",
  /** MEDIUM - represents a score between 0.4 and 0.7 */
  MEDIUM = "EPSS_PROBABILITY_MEDIUM",
  /** HIGH - represents a score between 0.7 and 1 */
  HIGH = "EPSS_PROBABILITY_HIGH",
  /** NONE - represents a missing score */
  NONE = "EPSS_PROBABILITY_NONE",
}

export function epssProbabilityFromJSON(object: any): EpssProbability {
  switch (object) {
    case 0:
    case "EPSS_PROBABILITY_UNSPECIFIED":
      return EpssProbability.UNSPECIFIED;
    case 1:
    case "EPSS_PROBABILITY_LOW":
      return EpssProbability.LOW;
    case 2:
    case "EPSS_PROBABILITY_MEDIUM":
      return EpssProbability.MEDIUM;
    case 3:
    case "EPSS_PROBABILITY_HIGH":
      return EpssProbability.HIGH;
    case 10:
    case "EPSS_PROBABILITY_NONE":
      return EpssProbability.NONE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum EpssProbability");
  }
}

export function epssProbabilityToJSON(object: EpssProbability): string {
  switch (object) {
    case EpssProbability.UNSPECIFIED:
      return "EPSS_PROBABILITY_UNSPECIFIED";
    case EpssProbability.LOW:
      return "EPSS_PROBABILITY_LOW";
    case EpssProbability.MEDIUM:
      return "EPSS_PROBABILITY_MEDIUM";
    case EpssProbability.HIGH:
      return "EPSS_PROBABILITY_HIGH";
    case EpssProbability.NONE:
      return "EPSS_PROBABILITY_NONE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum EpssProbability");
  }
}

export function epssProbabilityToNumber(object: EpssProbability): number {
  switch (object) {
    case EpssProbability.UNSPECIFIED:
      return 0;
    case EpssProbability.LOW:
      return 1;
    case EpssProbability.MEDIUM:
      return 2;
    case EpssProbability.HIGH:
      return 3;
    case EpssProbability.NONE:
      return 10;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum EpssProbability");
  }
}

export enum LicensePolicySetting {
  UNSPECIFIED = "LICENSE_POLICY_SETTING_UNSPECIFIED",
  ALLOW = "LICENSE_POLICY_SETTING_ALLOW",
  COMMENT = "LICENSE_POLICY_SETTING_COMMENT",
  BLOCK = "LICENSE_POLICY_SETTING_BLOCK",
}

export function licensePolicySettingFromJSON(object: any): LicensePolicySetting {
  switch (object) {
    case 0:
    case "LICENSE_POLICY_SETTING_UNSPECIFIED":
      return LicensePolicySetting.UNSPECIFIED;
    case 10:
    case "LICENSE_POLICY_SETTING_ALLOW":
      return LicensePolicySetting.ALLOW;
    case 20:
    case "LICENSE_POLICY_SETTING_COMMENT":
      return LicensePolicySetting.COMMENT;
    case 30:
    case "LICENSE_POLICY_SETTING_BLOCK":
      return LicensePolicySetting.BLOCK;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum LicensePolicySetting");
  }
}

export function licensePolicySettingToJSON(object: LicensePolicySetting): string {
  switch (object) {
    case LicensePolicySetting.UNSPECIFIED:
      return "LICENSE_POLICY_SETTING_UNSPECIFIED";
    case LicensePolicySetting.ALLOW:
      return "LICENSE_POLICY_SETTING_ALLOW";
    case LicensePolicySetting.COMMENT:
      return "LICENSE_POLICY_SETTING_COMMENT";
    case LicensePolicySetting.BLOCK:
      return "LICENSE_POLICY_SETTING_BLOCK";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum LicensePolicySetting");
  }
}

export function licensePolicySettingToNumber(object: LicensePolicySetting): number {
  switch (object) {
    case LicensePolicySetting.UNSPECIFIED:
      return 0;
    case LicensePolicySetting.ALLOW:
      return 10;
    case LicensePolicySetting.COMMENT:
      return 20;
    case LicensePolicySetting.BLOCK:
      return 30;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum LicensePolicySetting");
  }
}

export enum DevSemgrepAction {
  UNSPECIFIED = "DEV_SEMGREP_ACTION_UNSPECIFIED",
  MONITOR = "DEV_SEMGREP_ACTION_MONITOR",
  COMMENT = "DEV_SEMGREP_ACTION_COMMENT",
  BLOCK = "DEV_SEMGREP_ACTION_BLOCK",
}

export function devSemgrepActionFromJSON(object: any): DevSemgrepAction {
  switch (object) {
    case 0:
    case "DEV_SEMGREP_ACTION_UNSPECIFIED":
      return DevSemgrepAction.UNSPECIFIED;
    case 10:
    case "DEV_SEMGREP_ACTION_MONITOR":
      return DevSemgrepAction.MONITOR;
    case 20:
    case "DEV_SEMGREP_ACTION_COMMENT":
      return DevSemgrepAction.COMMENT;
    case 30:
    case "DEV_SEMGREP_ACTION_BLOCK":
      return DevSemgrepAction.BLOCK;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DevSemgrepAction");
  }
}

export function devSemgrepActionToJSON(object: DevSemgrepAction): string {
  switch (object) {
    case DevSemgrepAction.UNSPECIFIED:
      return "DEV_SEMGREP_ACTION_UNSPECIFIED";
    case DevSemgrepAction.MONITOR:
      return "DEV_SEMGREP_ACTION_MONITOR";
    case DevSemgrepAction.COMMENT:
      return "DEV_SEMGREP_ACTION_COMMENT";
    case DevSemgrepAction.BLOCK:
      return "DEV_SEMGREP_ACTION_BLOCK";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DevSemgrepAction");
  }
}

export function devSemgrepActionToNumber(object: DevSemgrepAction): number {
  switch (object) {
    case DevSemgrepAction.UNSPECIFIED:
      return 0;
    case DevSemgrepAction.MONITOR:
      return 10;
    case DevSemgrepAction.COMMENT:
      return 20;
    case DevSemgrepAction.BLOCK:
      return 30;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DevSemgrepAction");
  }
}

/** SBOM */
export enum SbomFormat {
  UNSPECIFIED = "SBOM_FORMAT_UNSPECIFIED",
  /** CYCLONEDX - Future formats can be added here */
  CYCLONEDX = "SBOM_FORMAT_CYCLONEDX",
}

export function sbomFormatFromJSON(object: any): SbomFormat {
  switch (object) {
    case 0:
    case "SBOM_FORMAT_UNSPECIFIED":
      return SbomFormat.UNSPECIFIED;
    case 1:
    case "SBOM_FORMAT_CYCLONEDX":
      return SbomFormat.CYCLONEDX;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SbomFormat");
  }
}

export function sbomFormatToJSON(object: SbomFormat): string {
  switch (object) {
    case SbomFormat.UNSPECIFIED:
      return "SBOM_FORMAT_UNSPECIFIED";
    case SbomFormat.CYCLONEDX:
      return "SBOM_FORMAT_CYCLONEDX";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SbomFormat");
  }
}

export function sbomFormatToNumber(object: SbomFormat): number {
  switch (object) {
    case SbomFormat.UNSPECIFIED:
      return 0;
    case SbomFormat.CYCLONEDX:
      return 1;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SbomFormat");
  }
}

export enum SbomOutputFormat {
  UNSPECIFIED = "SBOM_OUTPUT_FORMAT_UNSPECIFIED",
  XML = "SBOM_OUTPUT_FORMAT_XML",
  /** JSON - Future formats can be added here */
  JSON = "SBOM_OUTPUT_FORMAT_JSON",
}

export function sbomOutputFormatFromJSON(object: any): SbomOutputFormat {
  switch (object) {
    case 0:
    case "SBOM_OUTPUT_FORMAT_UNSPECIFIED":
      return SbomOutputFormat.UNSPECIFIED;
    case 1:
    case "SBOM_OUTPUT_FORMAT_XML":
      return SbomOutputFormat.XML;
    case 2:
    case "SBOM_OUTPUT_FORMAT_JSON":
      return SbomOutputFormat.JSON;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SbomOutputFormat");
  }
}

export function sbomOutputFormatToJSON(object: SbomOutputFormat): string {
  switch (object) {
    case SbomOutputFormat.UNSPECIFIED:
      return "SBOM_OUTPUT_FORMAT_UNSPECIFIED";
    case SbomOutputFormat.XML:
      return "SBOM_OUTPUT_FORMAT_XML";
    case SbomOutputFormat.JSON:
      return "SBOM_OUTPUT_FORMAT_JSON";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SbomOutputFormat");
  }
}

export function sbomOutputFormatToNumber(object: SbomOutputFormat): number {
  switch (object) {
    case SbomOutputFormat.UNSPECIFIED:
      return 0;
    case SbomOutputFormat.XML:
      return 1;
    case SbomOutputFormat.JSON:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SbomOutputFormat");
  }
}

/** Metadata-level component type enum for CycloneDX 1.5, superset of 1.4 */
export enum SbomMetadataComponentTypeCycloneDxV15 {
  UNSPECIFIED = "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_UNSPECIFIED",
  APPLICATION = "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_APPLICATION",
  FRAMEWORK = "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_FRAMEWORK",
  LIBRARY = "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_LIBRARY",
  CONTAINER = "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_CONTAINER",
  PLATFORM = "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_PLATFORM",
  OPERATING_SYSTEM = "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_OPERATING_SYSTEM",
  DEVICE = "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_DEVICE",
  DEVICE_DRIVER = "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_DEVICE_DRIVER",
  FIRMWARE = "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_FIRMWARE",
  FILE = "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_FILE",
  MACHINE_LEARNING_MODEL = "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_MACHINE_LEARNING_MODEL",
  DATA = "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_DATA",
}

export function sbomMetadataComponentTypeCycloneDxV15FromJSON(object: any): SbomMetadataComponentTypeCycloneDxV15 {
  switch (object) {
    case 0:
    case "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_UNSPECIFIED":
      return SbomMetadataComponentTypeCycloneDxV15.UNSPECIFIED;
    case 1:
    case "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_APPLICATION":
      return SbomMetadataComponentTypeCycloneDxV15.APPLICATION;
    case 2:
    case "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_FRAMEWORK":
      return SbomMetadataComponentTypeCycloneDxV15.FRAMEWORK;
    case 3:
    case "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_LIBRARY":
      return SbomMetadataComponentTypeCycloneDxV15.LIBRARY;
    case 4:
    case "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_CONTAINER":
      return SbomMetadataComponentTypeCycloneDxV15.CONTAINER;
    case 5:
    case "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_PLATFORM":
      return SbomMetadataComponentTypeCycloneDxV15.PLATFORM;
    case 6:
    case "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_OPERATING_SYSTEM":
      return SbomMetadataComponentTypeCycloneDxV15.OPERATING_SYSTEM;
    case 7:
    case "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_DEVICE":
      return SbomMetadataComponentTypeCycloneDxV15.DEVICE;
    case 8:
    case "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_DEVICE_DRIVER":
      return SbomMetadataComponentTypeCycloneDxV15.DEVICE_DRIVER;
    case 9:
    case "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_FIRMWARE":
      return SbomMetadataComponentTypeCycloneDxV15.FIRMWARE;
    case 10:
    case "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_FILE":
      return SbomMetadataComponentTypeCycloneDxV15.FILE;
    case 11:
    case "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_MACHINE_LEARNING_MODEL":
      return SbomMetadataComponentTypeCycloneDxV15.MACHINE_LEARNING_MODEL;
    case 12:
    case "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_DATA":
      return SbomMetadataComponentTypeCycloneDxV15.DATA;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum SbomMetadataComponentTypeCycloneDxV15",
      );
  }
}

export function sbomMetadataComponentTypeCycloneDxV15ToJSON(object: SbomMetadataComponentTypeCycloneDxV15): string {
  switch (object) {
    case SbomMetadataComponentTypeCycloneDxV15.UNSPECIFIED:
      return "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_UNSPECIFIED";
    case SbomMetadataComponentTypeCycloneDxV15.APPLICATION:
      return "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_APPLICATION";
    case SbomMetadataComponentTypeCycloneDxV15.FRAMEWORK:
      return "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_FRAMEWORK";
    case SbomMetadataComponentTypeCycloneDxV15.LIBRARY:
      return "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_LIBRARY";
    case SbomMetadataComponentTypeCycloneDxV15.CONTAINER:
      return "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_CONTAINER";
    case SbomMetadataComponentTypeCycloneDxV15.PLATFORM:
      return "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_PLATFORM";
    case SbomMetadataComponentTypeCycloneDxV15.OPERATING_SYSTEM:
      return "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_OPERATING_SYSTEM";
    case SbomMetadataComponentTypeCycloneDxV15.DEVICE:
      return "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_DEVICE";
    case SbomMetadataComponentTypeCycloneDxV15.DEVICE_DRIVER:
      return "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_DEVICE_DRIVER";
    case SbomMetadataComponentTypeCycloneDxV15.FIRMWARE:
      return "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_FIRMWARE";
    case SbomMetadataComponentTypeCycloneDxV15.FILE:
      return "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_FILE";
    case SbomMetadataComponentTypeCycloneDxV15.MACHINE_LEARNING_MODEL:
      return "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_MACHINE_LEARNING_MODEL";
    case SbomMetadataComponentTypeCycloneDxV15.DATA:
      return "SBOM_METADATA_COMPONENT_TYPE_CYCLONE_DX_V15_DATA";
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum SbomMetadataComponentTypeCycloneDxV15",
      );
  }
}

export function sbomMetadataComponentTypeCycloneDxV15ToNumber(object: SbomMetadataComponentTypeCycloneDxV15): number {
  switch (object) {
    case SbomMetadataComponentTypeCycloneDxV15.UNSPECIFIED:
      return 0;
    case SbomMetadataComponentTypeCycloneDxV15.APPLICATION:
      return 1;
    case SbomMetadataComponentTypeCycloneDxV15.FRAMEWORK:
      return 2;
    case SbomMetadataComponentTypeCycloneDxV15.LIBRARY:
      return 3;
    case SbomMetadataComponentTypeCycloneDxV15.CONTAINER:
      return 4;
    case SbomMetadataComponentTypeCycloneDxV15.PLATFORM:
      return 5;
    case SbomMetadataComponentTypeCycloneDxV15.OPERATING_SYSTEM:
      return 6;
    case SbomMetadataComponentTypeCycloneDxV15.DEVICE:
      return 7;
    case SbomMetadataComponentTypeCycloneDxV15.DEVICE_DRIVER:
      return 8;
    case SbomMetadataComponentTypeCycloneDxV15.FIRMWARE:
      return 9;
    case SbomMetadataComponentTypeCycloneDxV15.FILE:
      return 10;
    case SbomMetadataComponentTypeCycloneDxV15.MACHINE_LEARNING_MODEL:
      return 11;
    case SbomMetadataComponentTypeCycloneDxV15.DATA:
      return 12;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum SbomMetadataComponentTypeCycloneDxV15",
      );
  }
}

/** Specific location in a file. */
export interface CodeLocation {
  /** Path to a file. */
  path: string;
  /** Starting line number (1 indexed). */
  startLine: number;
  /** Starting column number (1 indexed). */
  startCol: number;
  /** Ending line number (1 indexed). */
  endLine: number;
  /** Ending column number (1 indexed). */
  endCol: number;
  /** URL to code location if available, otherwise empty. */
  url: string;
  /** Timestamp when code file was last modified, if available. */
  committedAt: Date | undefined;
}

/** A specific dependency. */
export interface Dependency {
  /** String identifier of dependency */
  name: string;
  /** Version specifier of dependency. */
  versionSpecifier: string;
}

export interface Advisory {
  /** Unique identifier for the rule. */
  ruleId: string;
  /** Human-readable title for the vulnerability. */
  title: string;
  /** Description for the vulnerability. */
  description: string;
  /** The ecosystem the advisory is for. */
  ecosystem: Ecosystem;
  /** The severity of any findings the advisory would generate. */
  severity: Advisory_Severity;
  /** References for the advisory (CVE, CWE, OWASP, etc). */
  references:
    | Advisory_References
    | undefined;
  /** Date the advisory was announced. */
  announcedAt:
    | Date
    | undefined;
  /** The text of the rule. */
  ruleText: string;
  /** The type of rule. */
  reachability: Advisory_RuleType;
  /** Conditions that if true would mean vulnerability applies. */
  reachableIf: string;
  /** Version specifiers of vulnerable dependencies. */
  vulnerableDependencies: Dependency[];
  /** Version specifiers of safe dependencies */
  safeDependencies: Dependency[];
  /** CVE ID */
  cve: string;
  /** GHSA ID */
  ghsa: string;
}

export enum Advisory_Severity {
  UNKNOWN_SEVERITY = "UNKNOWN_SEVERITY",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  CRITICAL = "CRITICAL",
}

export function advisory_SeverityFromJSON(object: any): Advisory_Severity {
  switch (object) {
    case 0:
    case "UNKNOWN_SEVERITY":
      return Advisory_Severity.UNKNOWN_SEVERITY;
    case 10:
    case "LOW":
      return Advisory_Severity.LOW;
    case 20:
    case "MEDIUM":
      return Advisory_Severity.MEDIUM;
    case 30:
    case "HIGH":
      return Advisory_Severity.HIGH;
    case 40:
    case "CRITICAL":
      return Advisory_Severity.CRITICAL;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Advisory_Severity");
  }
}

export function advisory_SeverityToJSON(object: Advisory_Severity): string {
  switch (object) {
    case Advisory_Severity.UNKNOWN_SEVERITY:
      return "UNKNOWN_SEVERITY";
    case Advisory_Severity.LOW:
      return "LOW";
    case Advisory_Severity.MEDIUM:
      return "MEDIUM";
    case Advisory_Severity.HIGH:
      return "HIGH";
    case Advisory_Severity.CRITICAL:
      return "CRITICAL";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Advisory_Severity");
  }
}

export function advisory_SeverityToNumber(object: Advisory_Severity): number {
  switch (object) {
    case Advisory_Severity.UNKNOWN_SEVERITY:
      return 0;
    case Advisory_Severity.LOW:
      return 10;
    case Advisory_Severity.MEDIUM:
      return 20;
    case Advisory_Severity.HIGH:
      return 30;
    case Advisory_Severity.CRITICAL:
      return 40;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Advisory_Severity");
  }
}

export enum Advisory_RuleType {
  UNKNOWN_RULE_TYPE = "UNKNOWN_RULE_TYPE",
  VERSION_SPECIFIER = "VERSION_SPECIFIER",
  REACHABILITY = "REACHABILITY",
  ALWAYS_REACHABLE = "ALWAYS_REACHABLE",
  MANUAL_REVIEW_REACHABLE = "MANUAL_REVIEW_REACHABLE",
}

export function advisory_RuleTypeFromJSON(object: any): Advisory_RuleType {
  switch (object) {
    case 0:
    case "UNKNOWN_RULE_TYPE":
      return Advisory_RuleType.UNKNOWN_RULE_TYPE;
    case 1:
    case "VERSION_SPECIFIER":
      return Advisory_RuleType.VERSION_SPECIFIER;
    case 2:
    case "REACHABILITY":
      return Advisory_RuleType.REACHABILITY;
    case 3:
    case "ALWAYS_REACHABLE":
      return Advisory_RuleType.ALWAYS_REACHABLE;
    case 4:
    case "MANUAL_REVIEW_REACHABLE":
      return Advisory_RuleType.MANUAL_REVIEW_REACHABLE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Advisory_RuleType");
  }
}

export function advisory_RuleTypeToJSON(object: Advisory_RuleType): string {
  switch (object) {
    case Advisory_RuleType.UNKNOWN_RULE_TYPE:
      return "UNKNOWN_RULE_TYPE";
    case Advisory_RuleType.VERSION_SPECIFIER:
      return "VERSION_SPECIFIER";
    case Advisory_RuleType.REACHABILITY:
      return "REACHABILITY";
    case Advisory_RuleType.ALWAYS_REACHABLE:
      return "ALWAYS_REACHABLE";
    case Advisory_RuleType.MANUAL_REVIEW_REACHABLE:
      return "MANUAL_REVIEW_REACHABLE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Advisory_RuleType");
  }
}

export function advisory_RuleTypeToNumber(object: Advisory_RuleType): number {
  switch (object) {
    case Advisory_RuleType.UNKNOWN_RULE_TYPE:
      return 0;
    case Advisory_RuleType.VERSION_SPECIFIER:
      return 1;
    case Advisory_RuleType.REACHABILITY:
      return 2;
    case Advisory_RuleType.ALWAYS_REACHABLE:
      return 3;
    case Advisory_RuleType.MANUAL_REVIEW_REACHABLE:
      return 4;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Advisory_RuleType");
  }
}

export interface Advisory_References {
  cveIds: string[];
  cweIds: string[];
  owaspIds: string[];
  urls: string[];
}

export interface VulnTriage {
  status: VulnTriage_Status;
  dismissReason: VulnTriage_DismissReason;
  issueUrl: string;
  prUrl: string;
}

export enum VulnTriage_Status {
  UNKNOWN_STATUS = "UNKNOWN_STATUS",
  NEW = "NEW",
  IN_PROGRESS = "IN_PROGRESS",
  IGNORED = "IGNORED",
  CLOSED = "CLOSED",
}

export function vulnTriage_StatusFromJSON(object: any): VulnTriage_Status {
  switch (object) {
    case 0:
    case "UNKNOWN_STATUS":
      return VulnTriage_Status.UNKNOWN_STATUS;
    case 10:
    case "NEW":
      return VulnTriage_Status.NEW;
    case 20:
    case "IN_PROGRESS":
      return VulnTriage_Status.IN_PROGRESS;
    case 30:
    case "IGNORED":
      return VulnTriage_Status.IGNORED;
    case 40:
    case "CLOSED":
      return VulnTriage_Status.CLOSED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum VulnTriage_Status");
  }
}

export function vulnTriage_StatusToJSON(object: VulnTriage_Status): string {
  switch (object) {
    case VulnTriage_Status.UNKNOWN_STATUS:
      return "UNKNOWN_STATUS";
    case VulnTriage_Status.NEW:
      return "NEW";
    case VulnTriage_Status.IN_PROGRESS:
      return "IN_PROGRESS";
    case VulnTriage_Status.IGNORED:
      return "IGNORED";
    case VulnTriage_Status.CLOSED:
      return "CLOSED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum VulnTriage_Status");
  }
}

export function vulnTriage_StatusToNumber(object: VulnTriage_Status): number {
  switch (object) {
    case VulnTriage_Status.UNKNOWN_STATUS:
      return 0;
    case VulnTriage_Status.NEW:
      return 10;
    case VulnTriage_Status.IN_PROGRESS:
      return 20;
    case VulnTriage_Status.IGNORED:
      return 30;
    case VulnTriage_Status.CLOSED:
      return 40;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum VulnTriage_Status");
  }
}

export enum VulnTriage_DismissReason {
  UNKNOWN_REASON = "UNKNOWN_REASON",
  FALSE_POSITIVE = "FALSE_POSITIVE",
  ACCEPTABLE_RISK = "ACCEPTABLE_RISK",
  NO_TIME_TO_FIX = "NO_TIME_TO_FIX",
}

export function vulnTriage_DismissReasonFromJSON(object: any): VulnTriage_DismissReason {
  switch (object) {
    case 0:
    case "UNKNOWN_REASON":
      return VulnTriage_DismissReason.UNKNOWN_REASON;
    case 10:
    case "FALSE_POSITIVE":
      return VulnTriage_DismissReason.FALSE_POSITIVE;
    case 20:
    case "ACCEPTABLE_RISK":
      return VulnTriage_DismissReason.ACCEPTABLE_RISK;
    case 30:
    case "NO_TIME_TO_FIX":
      return VulnTriage_DismissReason.NO_TIME_TO_FIX;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum VulnTriage_DismissReason");
  }
}

export function vulnTriage_DismissReasonToJSON(object: VulnTriage_DismissReason): string {
  switch (object) {
    case VulnTriage_DismissReason.UNKNOWN_REASON:
      return "UNKNOWN_REASON";
    case VulnTriage_DismissReason.FALSE_POSITIVE:
      return "FALSE_POSITIVE";
    case VulnTriage_DismissReason.ACCEPTABLE_RISK:
      return "ACCEPTABLE_RISK";
    case VulnTriage_DismissReason.NO_TIME_TO_FIX:
      return "NO_TIME_TO_FIX";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum VulnTriage_DismissReason");
  }
}

export function vulnTriage_DismissReasonToNumber(object: VulnTriage_DismissReason): number {
  switch (object) {
    case VulnTriage_DismissReason.UNKNOWN_REASON:
      return 0;
    case VulnTriage_DismissReason.FALSE_POSITIVE:
      return 10;
    case VulnTriage_DismissReason.ACCEPTABLE_RISK:
      return 20;
    case VulnTriage_DismissReason.NO_TIME_TO_FIX:
      return 30;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum VulnTriage_DismissReason");
  }
}

/** A vulnerability is an instance of a vulnerable dependency:version in a lockfile. */
export interface Vuln {
  /** Human-readable title for the vulnerability. */
  title: string;
  /** The advisory which triggered this vulnerability. */
  advisory:
    | Advisory
    | undefined;
  /** Whether the vulnerability is reachable or not. */
  exposureType: Vuln_ExposureType;
  /** The ID of the repository where the vulnerability was found. */
  repositoryId: number;
  /** Subdirectory where the vulnerability was found. */
  subdirectory: string;
  /** The dependency which matched the advisory. */
  matchedDependency:
    | Dependency
    | undefined;
  /** The location of the dependency in the lockfile. */
  dependencyFileLocation:
    | CodeLocation
    | undefined;
  /** Direct usages of the dependency in code. */
  usages: Usage[];
  /** The triage status of the vulnerability. */
  triage:
    | VulnTriage
    | undefined;
  /** generated from repository_id, subdirectory, and advisory.rule_id */
  groupKey: string;
  /** The package manager used to install the dependency. */
  packageManager: Vuln_PackageManager;
  /** The closest safe dependency to the vulnerable dependency. */
  closestSafeDependency:
    | Dependency
    | undefined;
  /** Plaintext name of repository this vulnerability belongs to. */
  repositoryName: string;
  /** Date the vulnerability was first found. */
  openedAt:
    | Date
    | undefined;
  /** Date the vulnerability was first triaged. */
  firstTriagedAt:
    | Date
    | undefined;
  /** Whether the vulnerability is transitive or direct. */
  transitivity: Transitivity;
}

export enum Vuln_ExposureType {
  UNKNOWN_EXPOSURE = "UNKNOWN_EXPOSURE",
  UNREACHABLE = "UNREACHABLE",
  REACHABLE = "REACHABLE",
}

export function vuln_ExposureTypeFromJSON(object: any): Vuln_ExposureType {
  switch (object) {
    case 0:
    case "UNKNOWN_EXPOSURE":
      return Vuln_ExposureType.UNKNOWN_EXPOSURE;
    case 10:
    case "UNREACHABLE":
      return Vuln_ExposureType.UNREACHABLE;
    case 20:
    case "REACHABLE":
      return Vuln_ExposureType.REACHABLE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Vuln_ExposureType");
  }
}

export function vuln_ExposureTypeToJSON(object: Vuln_ExposureType): string {
  switch (object) {
    case Vuln_ExposureType.UNKNOWN_EXPOSURE:
      return "UNKNOWN_EXPOSURE";
    case Vuln_ExposureType.UNREACHABLE:
      return "UNREACHABLE";
    case Vuln_ExposureType.REACHABLE:
      return "REACHABLE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Vuln_ExposureType");
  }
}

export function vuln_ExposureTypeToNumber(object: Vuln_ExposureType): number {
  switch (object) {
    case Vuln_ExposureType.UNKNOWN_EXPOSURE:
      return 0;
    case Vuln_ExposureType.UNREACHABLE:
      return 10;
    case Vuln_ExposureType.REACHABLE:
      return 20;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Vuln_ExposureType");
  }
}

export enum Vuln_PackageManager {
  no_package_manager = "no_package_manager",
  npm = "npm",
  yarn = "yarn",
  pipenv = "pipenv",
  poetry = "poetry",
  go = "go",
  cargo = "cargo",
  maven = "maven",
  gem = "gem",
  bundler = "bundler",
  composer = "composer",
  nuget = "nuget",
  pub = "pub",
  swiftpm = "swiftpm",
  hex = "hex",
}

export function vuln_PackageManagerFromJSON(object: any): Vuln_PackageManager {
  switch (object) {
    case 0:
    case "no_package_manager":
      return Vuln_PackageManager.no_package_manager;
    case 10:
    case "npm":
      return Vuln_PackageManager.npm;
    case 11:
    case "yarn":
      return Vuln_PackageManager.yarn;
    case 20:
    case "pipenv":
      return Vuln_PackageManager.pipenv;
    case 21:
    case "poetry":
      return Vuln_PackageManager.poetry;
    case 30:
    case "go":
      return Vuln_PackageManager.go;
    case 40:
    case "cargo":
      return Vuln_PackageManager.cargo;
    case 50:
    case "maven":
      return Vuln_PackageManager.maven;
    case 60:
    case "gem":
      return Vuln_PackageManager.gem;
    case 61:
    case "bundler":
      return Vuln_PackageManager.bundler;
    case 70:
    case "composer":
      return Vuln_PackageManager.composer;
    case 80:
    case "nuget":
      return Vuln_PackageManager.nuget;
    case 90:
    case "pub":
      return Vuln_PackageManager.pub;
    case 100:
    case "swiftpm":
      return Vuln_PackageManager.swiftpm;
    case 110:
    case "hex":
      return Vuln_PackageManager.hex;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Vuln_PackageManager");
  }
}

export function vuln_PackageManagerToJSON(object: Vuln_PackageManager): string {
  switch (object) {
    case Vuln_PackageManager.no_package_manager:
      return "no_package_manager";
    case Vuln_PackageManager.npm:
      return "npm";
    case Vuln_PackageManager.yarn:
      return "yarn";
    case Vuln_PackageManager.pipenv:
      return "pipenv";
    case Vuln_PackageManager.poetry:
      return "poetry";
    case Vuln_PackageManager.go:
      return "go";
    case Vuln_PackageManager.cargo:
      return "cargo";
    case Vuln_PackageManager.maven:
      return "maven";
    case Vuln_PackageManager.gem:
      return "gem";
    case Vuln_PackageManager.bundler:
      return "bundler";
    case Vuln_PackageManager.composer:
      return "composer";
    case Vuln_PackageManager.nuget:
      return "nuget";
    case Vuln_PackageManager.pub:
      return "pub";
    case Vuln_PackageManager.swiftpm:
      return "swiftpm";
    case Vuln_PackageManager.hex:
      return "hex";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Vuln_PackageManager");
  }
}

export function vuln_PackageManagerToNumber(object: Vuln_PackageManager): number {
  switch (object) {
    case Vuln_PackageManager.no_package_manager:
      return 0;
    case Vuln_PackageManager.npm:
      return 10;
    case Vuln_PackageManager.yarn:
      return 11;
    case Vuln_PackageManager.pipenv:
      return 20;
    case Vuln_PackageManager.poetry:
      return 21;
    case Vuln_PackageManager.go:
      return 30;
    case Vuln_PackageManager.cargo:
      return 40;
    case Vuln_PackageManager.maven:
      return 50;
    case Vuln_PackageManager.gem:
      return 60;
    case Vuln_PackageManager.bundler:
      return 61;
    case Vuln_PackageManager.composer:
      return 70;
    case Vuln_PackageManager.nuget:
      return 80;
    case Vuln_PackageManager.pub:
      return 90;
    case Vuln_PackageManager.swiftpm:
      return 100;
    case Vuln_PackageManager.hex:
      return 110;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Vuln_PackageManager");
  }
}

export interface Usage {
  findingId: number;
  /** location of the usage in the code */
  location: CodeLocation | undefined;
  externalTicket: ExternalTicket | undefined;
}

/** Represents a single "Exploit Prediction Scoring System" score (https://www.first.org/epss/) */
export interface EpssScore {
  score: number;
  percentile: number;
  updatedAt: Date | undefined;
  categorization: EpssProbability;
}

export interface CountVulnsRequest {
  /** @deprecated */
  since: number;
  /**
   * comma separated list of repository ids
   *
   * @deprecated
   */
  repositoryIds: string;
  repoIds: number[];
  refs: string[];
  severities: Advisory_Severity[];
  transitivities: Transitivity[];
  exposure: Vuln_ExposureType[];
  relevantSince?: Date | undefined;
  query: string;
}

export interface VulnCount {
  reachable: number;
  unknown: number;
  unreachable: number;
}

export interface CountVulnsResponse {
  reachable: number;
  unknown: number;
  unreachable: number;
  vulnsByRepository: { [key: number]: VulnCount };
}

export interface CountVulnsResponse_VulnsByRepositoryEntry {
  key: number;
  value: VulnCount | undefined;
}

export interface ListVulnsRequest {
  deploymentId: number;
  pageSize: number;
  cursor: number;
  refs?: string | undefined;
}

export interface ListVulnsResponse {
  vulns: Vuln[];
  hasMore: boolean;
  cursor: number;
}

/** Cursor object for pagination, with a `vulnOffset` and `issueOffset`. Provided by response and used as-is in request. */
export interface ListVulns2Cursor {
  vulnOffset: number;
  issueOffset?: number | undefined;
}

export interface ListVulns2Request {
  /** Deployment ID (numeric). Example: `123`. Can be found at `/deployments`, or in your Settings in the web UI. */
  deploymentId: number;
  /** Number of vulnerabilities per page. Default: 10, min: 1, max: 100. */
  pageSize: number;
  /** Cursor for the next page. */
  cursor?:
    | ListVulns2Cursor
    | undefined;
  /** Only get vulnerabilities in these branches. */
  refs: string[];
  /** Only get vulnerabilities created after this time. Format time as `YYYY-MM-DDThh:mm:ss.sssZ`, as shown in the example payload. */
  createdAfter?:
    | Date
    | undefined;
  /** Statuses to filter by. */
  statuses: VulnTriage_Status[];
  /** Severities to filter by. */
  severities: Advisory_Severity[];
  /** Transitivities to filter by. */
  transitivities: Transitivity[];
  /** Exposure types to filter by. */
  exposure: Vuln_ExposureType[];
  /** Repository ID to filter by. Use Projects endpoints to retrieve repository IDs. */
  repositoryId?:
    | number
    | undefined;
  /** Term to filter by. */
  query?: string | undefined;
}

export interface ListVulns2Response {
  vulns: Vuln[];
  /** True if there are more vulnerabilities to get. */
  hasMore: boolean;
  /** Pass to next request to get next page of results. */
  cursor: ListVulns2Cursor | undefined;
}

export interface PutVulnTriageRequest {
  deploymentId: number;
  groupKey: string;
  dismissReason?: VulnTriage_DismissReason | undefined;
  issueUrl?: string | undefined;
  prUrl?: string | undefined;
}

export interface PutVulnTriageResponse {
  newTriage: VulnTriage | undefined;
}

export interface GetVulnsFiltersResponse {
  refs: string[];
}

export interface ListAdvisoriesRequest {
}

export interface AdvisoryFilter {
  /** Filters advisories to those whose title, CVE, GHSA, or rule ID (path) contain the given query as a substring. */
  query?:
    | string
    | undefined;
  /** Filters advisories to those of the specified language. */
  language: string[];
  /** Filters advisories to those of the specified rule type. */
  ruleType: Advisory_RuleType[];
  /** Filters advisories to those with the specified severity. */
  severity: Advisory_Severity[];
}

export interface ListAdvisoriesV2Request {
  /** Cursor used for pagination. Passing no value effectively requests the first page. */
  cursor?:
    | number
    | undefined;
  /** Sets the page size of the paginated list of advisories. */
  limit?:
    | number
    | undefined;
  /** Filters the advisories to list. */
  filter?: AdvisoryFilter | undefined;
}

export interface ListAdvisoriesResponse {
  advisories: Advisory[];
}

export interface ListAdvisoriesV2Response {
  advisories: Advisory[];
  cursor?: number | undefined;
  totalCount: number;
}

export interface PostAdvisoryFeedbackRequest {
  deploymentId: number;
  ruleId: string;
  summary: string;
  codeSample: string;
  suggestedChange: string;
}

export interface PostAdvisoryFeedbackResponse {
}

export interface FoundDependency {
  /** ID of repository dependency is found in. */
  repositoryId: number;
  /** Path and line number dependency is declared in. */
  definedAt:
    | CodeLocation
    | undefined;
  /** Whether dependency is direct or transitive. */
  transitivity: Transitivity;
  /** What the dependency is. */
  package:
    | Dependency
    | undefined;
  /** The ecosystem the dependency is in (e.g. pypi, npm, etc). */
  ecosystem: Ecosystem;
  /** Licenses the dependency is using. */
  licenses: string[];
  /** WIP: chain of dependencies that brought in this transitive dependency. */
  pathToTransitivity: Dependency[];
  /** Path to the manifest file that defines the subproject containing this dependency */
  manifestDefinition: CodeLocation | undefined;
}

/** Object to provide dependency details to filter by. */
export interface DependencyFilter {
  /** Filter by dependency name (e.g. lodash). */
  name: string;
  /** Filter by dependency version (e.g. 1.0.1). */
  version: string;
  /**
   * Repository IDs (numeric) to filter by. Omit if the endpoint has Repository ID as a path parameter.
   * Use Projects endpoints to retrieve Repository IDs.
   */
  repositoryId: number[];
  /** Filter by transitivity. */
  transitivity: Transitivity[];
  /** Filter by ecosystem (e.g. npm, pypi, etc). */
  ecosystem: Ecosystem[];
  /** Filter by path to the lockfile (e.g. `foo/bar/package-lock.json`). */
  lockfilePath: string;
  /** Filter by license policy setting outcome. */
  licensePolicySetting: LicensePolicySetting;
  /** Filter by license (e.g. MIT). */
  license: string[];
}

export interface ListDependenciesRequest {
  /** Deployment ID (numeric). Example: `123`. Can be found at `/deployments`, or in your Settings in the web UI. */
  deploymentId: number;
  /** Request filters. */
  dependencyFilter:
    | DependencyFilter
    | undefined;
  /** Use cursor in response to get next page of results. */
  cursor: number;
  /** Number of dependencies per page. Default: 1000, min: 1, max: 10000. */
  pageSize: number;
}

export interface ListDependenciesResponse {
  dependencies: FoundDependency[];
  /** True if there are more pages of dependencies to get. */
  hasMore: boolean;
  /** Pass to next request to get next page of results. */
  cursor: number;
}

export interface ListDependenciesMultiRequest {
  deploymentId: number;
  requests: ListDependenciesRequest[];
}

export interface ListDependenciesMultiResponse {
  responses: ListDependenciesResponse[];
}

export interface RepositoryDependencySummary {
  /** ID of repository. */
  id: number;
  /** Name of repository. */
  name: string;
  /** Total number of dependencies in the repository. */
  numDependencies: number;
}

export interface ListRepositoriesForDependenciesRequest {
  /** Deployment ID (numeric). Example: `123`. Can be found at `/deployments`, or in your Settings in the web UI. */
  deploymentId: number;
  /** Request filters. */
  dependencyFilter:
    | DependencyFilter
    | undefined;
  /** Use cursor in response to get next page of results. */
  cursor: number;
  /** Number of repositories per page. Default: 5, min: 1, max: 100. */
  pageSize: number;
}

export interface ListRepositoriesForDependenciesResponse {
  repositorySummaries: RepositoryDependencySummary[];
  /** True if there are more repositories to get. */
  hasMore: boolean;
  /** Pass to next request to get next page of results. */
  cursor?: number | undefined;
}

export interface ListLockfilesForDependenciesRequest {
  /** Deployment ID (numeric). Example: `123`. Can be found at `/deployments`, or in your Settings in the web UI. */
  deploymentId: number;
  /** Repository ID to filter by. Use Projects endpoints to retrieve repository IDs. */
  repositoryId: number;
  dependencyFilter:
    | DependencyFilter
    | undefined;
  /** Use cursor in response to get next page of results. */
  cursor: string;
  /** Number of repositories per page. Default: 5, min: 1, max: 100. */
  pageSize: number;
}

export interface LockfileDependencySummary {
  /** Path to lockfile (e.g. foo/bar/package-lock.json). */
  lockfilePath: string;
  /** Total number of dependencies in the lockfile. */
  numDependencies: number;
}

export interface ListLockfilesForDependenciesResponse {
  lockfileSummaries: LockfileDependencySummary[];
  /** True if there are more lockfiles to get. */
  hasMore: boolean;
  /** Pass to next request to get next page of results. */
  cursor?: string | undefined;
}

export interface RepositoryLockfile {
  /** ID of repository. */
  repositoryId: number;
  /** Path to lockfile (e.g. foo/bar/package-lock.json). */
  lockfilePath: string;
}

export interface DependencyFacetCount {
  count: number;
  facet?:
    | { $case: "repositoryLockfile"; repositoryLockfile: RepositoryLockfile }
    | { $case: "transitivity"; transitivity: Transitivity }
    | { $case: "ecosystem"; ecosystem: Ecosystem }
    | { $case: "license"; license: string }
    | { $case: "licensePolicySetting"; licensePolicySetting: LicensePolicySetting }
    | undefined;
}

export interface DependencyParserError {
  location: CodeLocation | undefined;
  reason: string;
  parser: string;
  text: string;
}

export interface DependencyParserErrors {
  errors: DependencyParserError[];
}

export interface DependencyException {
  ecosystem: Ecosystem;
  name: string;
  version: string;
}

export interface LicensePolicy {
  settings: { [key: string]: LicensePolicySetting };
  exceptions: DependencyException[];
}

export interface LicensePolicy_SettingsEntry {
  key: string;
  value: LicensePolicySetting;
}

export interface GetLicensePolicyRequest {
  deploymentId: number;
}

export interface GetLicensePolicyResponse {
  policy: LicensePolicy | undefined;
}

export interface PostLicensePolicyRequest {
  policy: LicensePolicy | undefined;
}

export interface PostLicensePolicyResponse {
  policy: LicensePolicy | undefined;
}

export interface PostHasDependencyQueryRequest {
  hasDependencyQuery: boolean;
}

export interface PostHasDependencyQueryResponse {
  hasDependencyQuery: boolean;
}

export interface PutHasSCAPRBlockingRequest {
  hasScaPrBlocking: boolean;
}

export interface PutHasSCAPRBlockingResponse {
  hasScaPrBlocking: boolean;
}

export interface LicensePolicyFinding {
  dependency: FoundDependency | undefined;
  triggeredByLicense: string;
  setting: LicensePolicySetting;
  scanId: number;
}

export interface Reachable {
  low: DevSemgrepAction;
  medium: DevSemgrepAction;
  high: DevSemgrepAction;
  critical: DevSemgrepAction;
}

export interface AlwaysUpgrade {
  low: DevSemgrepAction;
  medium: DevSemgrepAction;
  high: DevSemgrepAction;
  critical: DevSemgrepAction;
}

export interface ManualReview {
  low: DevSemgrepAction;
  medium: DevSemgrepAction;
  high: DevSemgrepAction;
  critical: DevSemgrepAction;
}

export interface SCAPRBlockingConfig {
  reachable: Reachable | undefined;
  alwaysUpgrade: AlwaysUpgrade | undefined;
  manualReview: ManualReview | undefined;
}

export interface SbomFormatVersion {
  format: SbomFormat;
  version?: string | undefined;
}

export interface SbomMetadataContact {
  name?: string | undefined;
  email?: string | undefined;
  phone?: string | undefined;
}

export interface SbomMetadataSupplier {
  name?: string | undefined;
  url?: string | undefined;
  contact?: SbomMetadataContact | undefined;
}

export interface GenerateSbomRequest {
  repositoryId: number;
  ref?: string | undefined;
  formatVersion: SbomFormatVersion | undefined;
  sbomOutputFormat: SbomOutputFormat;
  metadataComponentType?: SbomMetadataComponentTypeCycloneDxV15 | undefined;
  metadataSupplier?: SbomMetadataSupplier | undefined;
}

/** Diff-aware scan dependency data storage and retrieval */
export interface PostDiffScanDependencyDataRequest {
  repositoryId: number;
  ref: string;
  prIdentifier?: string | undefined;
  commitSha?: string | undefined;
  licensePolicyThreshold?: LicensePolicySetting | undefined;
}

function createBaseCodeLocation(): CodeLocation {
  return { path: "", startLine: 0, startCol: 0, endLine: 0, endCol: 0, url: "", committedAt: undefined };
}

export const CodeLocation = {
  encode(message: CodeLocation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.path !== "") {
      writer.uint32(10).string(message.path);
    }
    if (message.startLine !== 0) {
      writer.uint32(16).uint64(message.startLine);
    }
    if (message.startCol !== 0) {
      writer.uint32(24).uint64(message.startCol);
    }
    if (message.endLine !== 0) {
      writer.uint32(32).uint64(message.endLine);
    }
    if (message.endCol !== 0) {
      writer.uint32(40).uint64(message.endCol);
    }
    if (message.url !== "") {
      writer.uint32(50).string(message.url);
    }
    if (message.committedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.committedAt), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CodeLocation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCodeLocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.path = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.startLine = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.startCol = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.endLine = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.endCol = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.url = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.committedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CodeLocation {
    return {
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      startLine: isSet(object.startLine) ? globalThis.Number(object.startLine) : 0,
      startCol: isSet(object.startCol) ? globalThis.Number(object.startCol) : 0,
      endLine: isSet(object.endLine) ? globalThis.Number(object.endLine) : 0,
      endCol: isSet(object.endCol) ? globalThis.Number(object.endCol) : 0,
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      committedAt: isSet(object.committedAt) ? fromJsonTimestamp(object.committedAt) : undefined,
    };
  },

  toJSON(message: CodeLocation): unknown {
    const obj: any = {};
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.startLine !== 0) {
      obj.startLine = Math.round(message.startLine);
    }
    if (message.startCol !== 0) {
      obj.startCol = Math.round(message.startCol);
    }
    if (message.endLine !== 0) {
      obj.endLine = Math.round(message.endLine);
    }
    if (message.endCol !== 0) {
      obj.endCol = Math.round(message.endCol);
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.committedAt !== undefined) {
      obj.committedAt = message.committedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CodeLocation>, I>>(base?: I): CodeLocation {
    return CodeLocation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CodeLocation>, I>>(object: I): CodeLocation {
    const message = createBaseCodeLocation();
    message.path = object.path ?? "";
    message.startLine = object.startLine ?? 0;
    message.startCol = object.startCol ?? 0;
    message.endLine = object.endLine ?? 0;
    message.endCol = object.endCol ?? 0;
    message.url = object.url ?? "";
    message.committedAt = object.committedAt ?? undefined;
    return message;
  },
};

function createBaseDependency(): Dependency {
  return { name: "", versionSpecifier: "" };
}

export const Dependency = {
  encode(message: Dependency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.versionSpecifier !== "") {
      writer.uint32(18).string(message.versionSpecifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Dependency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDependency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.versionSpecifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Dependency {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      versionSpecifier: isSet(object.versionSpecifier) ? globalThis.String(object.versionSpecifier) : "",
    };
  },

  toJSON(message: Dependency): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.versionSpecifier !== "") {
      obj.versionSpecifier = message.versionSpecifier;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Dependency>, I>>(base?: I): Dependency {
    return Dependency.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Dependency>, I>>(object: I): Dependency {
    const message = createBaseDependency();
    message.name = object.name ?? "";
    message.versionSpecifier = object.versionSpecifier ?? "";
    return message;
  },
};

function createBaseAdvisory(): Advisory {
  return {
    ruleId: "",
    title: "",
    description: "",
    ecosystem: Ecosystem.no_package_manager,
    severity: Advisory_Severity.UNKNOWN_SEVERITY,
    references: undefined,
    announcedAt: undefined,
    ruleText: "",
    reachability: Advisory_RuleType.UNKNOWN_RULE_TYPE,
    reachableIf: "",
    vulnerableDependencies: [],
    safeDependencies: [],
    cve: "",
    ghsa: "",
  };
}

export const Advisory = {
  encode(message: Advisory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruleId !== "") {
      writer.uint32(10).string(message.ruleId);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      writer.uint32(32).int32(ecosystemToNumber(message.ecosystem));
    }
    if (message.severity !== Advisory_Severity.UNKNOWN_SEVERITY) {
      writer.uint32(48).int32(advisory_SeverityToNumber(message.severity));
    }
    if (message.references !== undefined) {
      Advisory_References.encode(message.references, writer.uint32(58).fork()).ldelim();
    }
    if (message.announcedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.announcedAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.ruleText !== "") {
      writer.uint32(74).string(message.ruleText);
    }
    if (message.reachability !== Advisory_RuleType.UNKNOWN_RULE_TYPE) {
      writer.uint32(80).int32(advisory_RuleTypeToNumber(message.reachability));
    }
    if (message.reachableIf !== "") {
      writer.uint32(106).string(message.reachableIf);
    }
    for (const v of message.vulnerableDependencies) {
      Dependency.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.safeDependencies) {
      Dependency.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    if (message.cve !== "") {
      writer.uint32(114).string(message.cve);
    }
    if (message.ghsa !== "") {
      writer.uint32(122).string(message.ghsa);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Advisory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdvisory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ruleId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.ecosystem = ecosystemFromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.severity = advisory_SeverityFromJSON(reader.int32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.references = Advisory_References.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.announcedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.ruleText = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.reachability = advisory_RuleTypeFromJSON(reader.int32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.reachableIf = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.vulnerableDependencies.push(Dependency.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.safeDependencies.push(Dependency.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.cve = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.ghsa = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Advisory {
    return {
      ruleId: isSet(object.ruleId) ? globalThis.String(object.ruleId) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      ecosystem: isSet(object.ecosystem) ? ecosystemFromJSON(object.ecosystem) : Ecosystem.no_package_manager,
      severity: isSet(object.severity)
        ? advisory_SeverityFromJSON(object.severity)
        : Advisory_Severity.UNKNOWN_SEVERITY,
      references: isSet(object.references) ? Advisory_References.fromJSON(object.references) : undefined,
      announcedAt: isSet(object.announcedAt) ? fromJsonTimestamp(object.announcedAt) : undefined,
      ruleText: isSet(object.ruleText) ? globalThis.String(object.ruleText) : "",
      reachability: isSet(object.reachability)
        ? advisory_RuleTypeFromJSON(object.reachability)
        : Advisory_RuleType.UNKNOWN_RULE_TYPE,
      reachableIf: isSet(object.reachableIf) ? globalThis.String(object.reachableIf) : "",
      vulnerableDependencies: globalThis.Array.isArray(object?.vulnerableDependencies)
        ? object.vulnerableDependencies.map((e: any) => Dependency.fromJSON(e))
        : [],
      safeDependencies: globalThis.Array.isArray(object?.safeDependencies)
        ? object.safeDependencies.map((e: any) => Dependency.fromJSON(e))
        : [],
      cve: isSet(object.cve) ? globalThis.String(object.cve) : "",
      ghsa: isSet(object.ghsa) ? globalThis.String(object.ghsa) : "",
    };
  },

  toJSON(message: Advisory): unknown {
    const obj: any = {};
    if (message.ruleId !== "") {
      obj.ruleId = message.ruleId;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      obj.ecosystem = ecosystemToJSON(message.ecosystem);
    }
    if (message.severity !== Advisory_Severity.UNKNOWN_SEVERITY) {
      obj.severity = advisory_SeverityToJSON(message.severity);
    }
    if (message.references !== undefined) {
      obj.references = Advisory_References.toJSON(message.references);
    }
    if (message.announcedAt !== undefined) {
      obj.announcedAt = message.announcedAt.toISOString();
    }
    if (message.ruleText !== "") {
      obj.ruleText = message.ruleText;
    }
    if (message.reachability !== Advisory_RuleType.UNKNOWN_RULE_TYPE) {
      obj.reachability = advisory_RuleTypeToJSON(message.reachability);
    }
    if (message.reachableIf !== "") {
      obj.reachableIf = message.reachableIf;
    }
    if (message.vulnerableDependencies?.length) {
      obj.vulnerableDependencies = message.vulnerableDependencies.map((e) => Dependency.toJSON(e));
    }
    if (message.safeDependencies?.length) {
      obj.safeDependencies = message.safeDependencies.map((e) => Dependency.toJSON(e));
    }
    if (message.cve !== "") {
      obj.cve = message.cve;
    }
    if (message.ghsa !== "") {
      obj.ghsa = message.ghsa;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Advisory>, I>>(base?: I): Advisory {
    return Advisory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Advisory>, I>>(object: I): Advisory {
    const message = createBaseAdvisory();
    message.ruleId = object.ruleId ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.ecosystem = object.ecosystem ?? Ecosystem.no_package_manager;
    message.severity = object.severity ?? Advisory_Severity.UNKNOWN_SEVERITY;
    message.references = (object.references !== undefined && object.references !== null)
      ? Advisory_References.fromPartial(object.references)
      : undefined;
    message.announcedAt = object.announcedAt ?? undefined;
    message.ruleText = object.ruleText ?? "";
    message.reachability = object.reachability ?? Advisory_RuleType.UNKNOWN_RULE_TYPE;
    message.reachableIf = object.reachableIf ?? "";
    message.vulnerableDependencies = object.vulnerableDependencies?.map((e) => Dependency.fromPartial(e)) || [];
    message.safeDependencies = object.safeDependencies?.map((e) => Dependency.fromPartial(e)) || [];
    message.cve = object.cve ?? "";
    message.ghsa = object.ghsa ?? "";
    return message;
  },
};

function createBaseAdvisory_References(): Advisory_References {
  return { cveIds: [], cweIds: [], owaspIds: [], urls: [] };
}

export const Advisory_References = {
  encode(message: Advisory_References, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.cveIds) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.cweIds) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.owaspIds) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.urls) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Advisory_References {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdvisory_References();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cveIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cweIds.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.owaspIds.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.urls.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Advisory_References {
    return {
      cveIds: globalThis.Array.isArray(object?.cveIds) ? object.cveIds.map((e: any) => globalThis.String(e)) : [],
      cweIds: globalThis.Array.isArray(object?.cweIds) ? object.cweIds.map((e: any) => globalThis.String(e)) : [],
      owaspIds: globalThis.Array.isArray(object?.owaspIds) ? object.owaspIds.map((e: any) => globalThis.String(e)) : [],
      urls: globalThis.Array.isArray(object?.urls) ? object.urls.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: Advisory_References): unknown {
    const obj: any = {};
    if (message.cveIds?.length) {
      obj.cveIds = message.cveIds;
    }
    if (message.cweIds?.length) {
      obj.cweIds = message.cweIds;
    }
    if (message.owaspIds?.length) {
      obj.owaspIds = message.owaspIds;
    }
    if (message.urls?.length) {
      obj.urls = message.urls;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Advisory_References>, I>>(base?: I): Advisory_References {
    return Advisory_References.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Advisory_References>, I>>(object: I): Advisory_References {
    const message = createBaseAdvisory_References();
    message.cveIds = object.cveIds?.map((e) => e) || [];
    message.cweIds = object.cweIds?.map((e) => e) || [];
    message.owaspIds = object.owaspIds?.map((e) => e) || [];
    message.urls = object.urls?.map((e) => e) || [];
    return message;
  },
};

function createBaseVulnTriage(): VulnTriage {
  return {
    status: VulnTriage_Status.UNKNOWN_STATUS,
    dismissReason: VulnTriage_DismissReason.UNKNOWN_REASON,
    issueUrl: "",
    prUrl: "",
  };
}

export const VulnTriage = {
  encode(message: VulnTriage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== VulnTriage_Status.UNKNOWN_STATUS) {
      writer.uint32(8).int32(vulnTriage_StatusToNumber(message.status));
    }
    if (message.dismissReason !== VulnTriage_DismissReason.UNKNOWN_REASON) {
      writer.uint32(16).int32(vulnTriage_DismissReasonToNumber(message.dismissReason));
    }
    if (message.issueUrl !== "") {
      writer.uint32(26).string(message.issueUrl);
    }
    if (message.prUrl !== "") {
      writer.uint32(34).string(message.prUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VulnTriage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVulnTriage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = vulnTriage_StatusFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.dismissReason = vulnTriage_DismissReasonFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.issueUrl = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.prUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VulnTriage {
    return {
      status: isSet(object.status) ? vulnTriage_StatusFromJSON(object.status) : VulnTriage_Status.UNKNOWN_STATUS,
      dismissReason: isSet(object.dismissReason)
        ? vulnTriage_DismissReasonFromJSON(object.dismissReason)
        : VulnTriage_DismissReason.UNKNOWN_REASON,
      issueUrl: isSet(object.issueUrl) ? globalThis.String(object.issueUrl) : "",
      prUrl: isSet(object.prUrl) ? globalThis.String(object.prUrl) : "",
    };
  },

  toJSON(message: VulnTriage): unknown {
    const obj: any = {};
    if (message.status !== VulnTriage_Status.UNKNOWN_STATUS) {
      obj.status = vulnTriage_StatusToJSON(message.status);
    }
    if (message.dismissReason !== VulnTriage_DismissReason.UNKNOWN_REASON) {
      obj.dismissReason = vulnTriage_DismissReasonToJSON(message.dismissReason);
    }
    if (message.issueUrl !== "") {
      obj.issueUrl = message.issueUrl;
    }
    if (message.prUrl !== "") {
      obj.prUrl = message.prUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VulnTriage>, I>>(base?: I): VulnTriage {
    return VulnTriage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VulnTriage>, I>>(object: I): VulnTriage {
    const message = createBaseVulnTriage();
    message.status = object.status ?? VulnTriage_Status.UNKNOWN_STATUS;
    message.dismissReason = object.dismissReason ?? VulnTriage_DismissReason.UNKNOWN_REASON;
    message.issueUrl = object.issueUrl ?? "";
    message.prUrl = object.prUrl ?? "";
    return message;
  },
};

function createBaseVuln(): Vuln {
  return {
    title: "",
    advisory: undefined,
    exposureType: Vuln_ExposureType.UNKNOWN_EXPOSURE,
    repositoryId: 0,
    subdirectory: "",
    matchedDependency: undefined,
    dependencyFileLocation: undefined,
    usages: [],
    triage: undefined,
    groupKey: "",
    packageManager: Vuln_PackageManager.no_package_manager,
    closestSafeDependency: undefined,
    repositoryName: "",
    openedAt: undefined,
    firstTriagedAt: undefined,
    transitivity: Transitivity.UNKNOWN_TRANSITIVITY,
  };
}

export const Vuln = {
  encode(message: Vuln, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    if (message.advisory !== undefined) {
      Advisory.encode(message.advisory, writer.uint32(18).fork()).ldelim();
    }
    if (message.exposureType !== Vuln_ExposureType.UNKNOWN_EXPOSURE) {
      writer.uint32(24).int32(vuln_ExposureTypeToNumber(message.exposureType));
    }
    if (message.repositoryId !== 0) {
      writer.uint32(32).uint64(message.repositoryId);
    }
    if (message.subdirectory !== "") {
      writer.uint32(42).string(message.subdirectory);
    }
    if (message.matchedDependency !== undefined) {
      Dependency.encode(message.matchedDependency, writer.uint32(50).fork()).ldelim();
    }
    if (message.dependencyFileLocation !== undefined) {
      CodeLocation.encode(message.dependencyFileLocation, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.usages) {
      Usage.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.triage !== undefined) {
      VulnTriage.encode(message.triage, writer.uint32(74).fork()).ldelim();
    }
    if (message.groupKey !== "") {
      writer.uint32(82).string(message.groupKey);
    }
    if (message.packageManager !== Vuln_PackageManager.no_package_manager) {
      writer.uint32(88).int32(vuln_PackageManagerToNumber(message.packageManager));
    }
    if (message.closestSafeDependency !== undefined) {
      Dependency.encode(message.closestSafeDependency, writer.uint32(98).fork()).ldelim();
    }
    if (message.repositoryName !== "") {
      writer.uint32(106).string(message.repositoryName);
    }
    if (message.openedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.openedAt), writer.uint32(114).fork()).ldelim();
    }
    if (message.firstTriagedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.firstTriagedAt), writer.uint32(122).fork()).ldelim();
    }
    if (message.transitivity !== Transitivity.UNKNOWN_TRANSITIVITY) {
      writer.uint32(136).int32(transitivityToNumber(message.transitivity));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Vuln {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVuln();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.advisory = Advisory.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.exposureType = vuln_ExposureTypeFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.repositoryId = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.subdirectory = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.matchedDependency = Dependency.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.dependencyFileLocation = CodeLocation.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.usages.push(Usage.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.triage = VulnTriage.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.groupKey = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.packageManager = vuln_PackageManagerFromJSON(reader.int32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.closestSafeDependency = Dependency.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.repositoryName = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.openedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.firstTriagedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.transitivity = transitivityFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Vuln {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      advisory: isSet(object.advisory) ? Advisory.fromJSON(object.advisory) : undefined,
      exposureType: isSet(object.exposureType)
        ? vuln_ExposureTypeFromJSON(object.exposureType)
        : Vuln_ExposureType.UNKNOWN_EXPOSURE,
      repositoryId: isSet(object.repositoryId) ? globalThis.Number(object.repositoryId) : 0,
      subdirectory: isSet(object.subdirectory) ? globalThis.String(object.subdirectory) : "",
      matchedDependency: isSet(object.matchedDependency) ? Dependency.fromJSON(object.matchedDependency) : undefined,
      dependencyFileLocation: isSet(object.dependencyFileLocation)
        ? CodeLocation.fromJSON(object.dependencyFileLocation)
        : undefined,
      usages: globalThis.Array.isArray(object?.usages) ? object.usages.map((e: any) => Usage.fromJSON(e)) : [],
      triage: isSet(object.triage) ? VulnTriage.fromJSON(object.triage) : undefined,
      groupKey: isSet(object.groupKey) ? globalThis.String(object.groupKey) : "",
      packageManager: isSet(object.packageManager)
        ? vuln_PackageManagerFromJSON(object.packageManager)
        : Vuln_PackageManager.no_package_manager,
      closestSafeDependency: isSet(object.closestSafeDependency)
        ? Dependency.fromJSON(object.closestSafeDependency)
        : undefined,
      repositoryName: isSet(object.repositoryName) ? globalThis.String(object.repositoryName) : "",
      openedAt: isSet(object.openedAt) ? fromJsonTimestamp(object.openedAt) : undefined,
      firstTriagedAt: isSet(object.firstTriagedAt) ? fromJsonTimestamp(object.firstTriagedAt) : undefined,
      transitivity: isSet(object.transitivity)
        ? transitivityFromJSON(object.transitivity)
        : Transitivity.UNKNOWN_TRANSITIVITY,
    };
  },

  toJSON(message: Vuln): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.advisory !== undefined) {
      obj.advisory = Advisory.toJSON(message.advisory);
    }
    if (message.exposureType !== Vuln_ExposureType.UNKNOWN_EXPOSURE) {
      obj.exposureType = vuln_ExposureTypeToJSON(message.exposureType);
    }
    if (message.repositoryId !== 0) {
      obj.repositoryId = Math.round(message.repositoryId);
    }
    if (message.subdirectory !== "") {
      obj.subdirectory = message.subdirectory;
    }
    if (message.matchedDependency !== undefined) {
      obj.matchedDependency = Dependency.toJSON(message.matchedDependency);
    }
    if (message.dependencyFileLocation !== undefined) {
      obj.dependencyFileLocation = CodeLocation.toJSON(message.dependencyFileLocation);
    }
    if (message.usages?.length) {
      obj.usages = message.usages.map((e) => Usage.toJSON(e));
    }
    if (message.triage !== undefined) {
      obj.triage = VulnTriage.toJSON(message.triage);
    }
    if (message.groupKey !== "") {
      obj.groupKey = message.groupKey;
    }
    if (message.packageManager !== Vuln_PackageManager.no_package_manager) {
      obj.packageManager = vuln_PackageManagerToJSON(message.packageManager);
    }
    if (message.closestSafeDependency !== undefined) {
      obj.closestSafeDependency = Dependency.toJSON(message.closestSafeDependency);
    }
    if (message.repositoryName !== "") {
      obj.repositoryName = message.repositoryName;
    }
    if (message.openedAt !== undefined) {
      obj.openedAt = message.openedAt.toISOString();
    }
    if (message.firstTriagedAt !== undefined) {
      obj.firstTriagedAt = message.firstTriagedAt.toISOString();
    }
    if (message.transitivity !== Transitivity.UNKNOWN_TRANSITIVITY) {
      obj.transitivity = transitivityToJSON(message.transitivity);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Vuln>, I>>(base?: I): Vuln {
    return Vuln.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Vuln>, I>>(object: I): Vuln {
    const message = createBaseVuln();
    message.title = object.title ?? "";
    message.advisory = (object.advisory !== undefined && object.advisory !== null)
      ? Advisory.fromPartial(object.advisory)
      : undefined;
    message.exposureType = object.exposureType ?? Vuln_ExposureType.UNKNOWN_EXPOSURE;
    message.repositoryId = object.repositoryId ?? 0;
    message.subdirectory = object.subdirectory ?? "";
    message.matchedDependency = (object.matchedDependency !== undefined && object.matchedDependency !== null)
      ? Dependency.fromPartial(object.matchedDependency)
      : undefined;
    message.dependencyFileLocation =
      (object.dependencyFileLocation !== undefined && object.dependencyFileLocation !== null)
        ? CodeLocation.fromPartial(object.dependencyFileLocation)
        : undefined;
    message.usages = object.usages?.map((e) => Usage.fromPartial(e)) || [];
    message.triage = (object.triage !== undefined && object.triage !== null)
      ? VulnTriage.fromPartial(object.triage)
      : undefined;
    message.groupKey = object.groupKey ?? "";
    message.packageManager = object.packageManager ?? Vuln_PackageManager.no_package_manager;
    message.closestSafeDependency =
      (object.closestSafeDependency !== undefined && object.closestSafeDependency !== null)
        ? Dependency.fromPartial(object.closestSafeDependency)
        : undefined;
    message.repositoryName = object.repositoryName ?? "";
    message.openedAt = object.openedAt ?? undefined;
    message.firstTriagedAt = object.firstTriagedAt ?? undefined;
    message.transitivity = object.transitivity ?? Transitivity.UNKNOWN_TRANSITIVITY;
    return message;
  },
};

function createBaseUsage(): Usage {
  return { findingId: 0, location: undefined, externalTicket: undefined };
}

export const Usage = {
  encode(message: Usage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.findingId !== 0) {
      writer.uint32(8).uint64(message.findingId);
    }
    if (message.location !== undefined) {
      CodeLocation.encode(message.location, writer.uint32(18).fork()).ldelim();
    }
    if (message.externalTicket !== undefined) {
      ExternalTicket.encode(message.externalTicket, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Usage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUsage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.findingId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.location = CodeLocation.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.externalTicket = ExternalTicket.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Usage {
    return {
      findingId: isSet(object.findingId) ? globalThis.Number(object.findingId) : 0,
      location: isSet(object.location) ? CodeLocation.fromJSON(object.location) : undefined,
      externalTicket: isSet(object.externalTicket) ? ExternalTicket.fromJSON(object.externalTicket) : undefined,
    };
  },

  toJSON(message: Usage): unknown {
    const obj: any = {};
    if (message.findingId !== 0) {
      obj.findingId = Math.round(message.findingId);
    }
    if (message.location !== undefined) {
      obj.location = CodeLocation.toJSON(message.location);
    }
    if (message.externalTicket !== undefined) {
      obj.externalTicket = ExternalTicket.toJSON(message.externalTicket);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Usage>, I>>(base?: I): Usage {
    return Usage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Usage>, I>>(object: I): Usage {
    const message = createBaseUsage();
    message.findingId = object.findingId ?? 0;
    message.location = (object.location !== undefined && object.location !== null)
      ? CodeLocation.fromPartial(object.location)
      : undefined;
    message.externalTicket = (object.externalTicket !== undefined && object.externalTicket !== null)
      ? ExternalTicket.fromPartial(object.externalTicket)
      : undefined;
    return message;
  },
};

function createBaseEpssScore(): EpssScore {
  return { score: 0, percentile: 0, updatedAt: undefined, categorization: EpssProbability.UNSPECIFIED };
}

export const EpssScore = {
  encode(message: EpssScore, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.score !== 0) {
      writer.uint32(13).float(message.score);
    }
    if (message.percentile !== 0) {
      writer.uint32(21).float(message.percentile);
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.categorization !== EpssProbability.UNSPECIFIED) {
      writer.uint32(32).int32(epssProbabilityToNumber(message.categorization));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EpssScore {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEpssScore();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.score = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.percentile = reader.float();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.categorization = epssProbabilityFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EpssScore {
    return {
      score: isSet(object.score) ? globalThis.Number(object.score) : 0,
      percentile: isSet(object.percentile) ? globalThis.Number(object.percentile) : 0,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
      categorization: isSet(object.categorization)
        ? epssProbabilityFromJSON(object.categorization)
        : EpssProbability.UNSPECIFIED,
    };
  },

  toJSON(message: EpssScore): unknown {
    const obj: any = {};
    if (message.score !== 0) {
      obj.score = message.score;
    }
    if (message.percentile !== 0) {
      obj.percentile = message.percentile;
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt.toISOString();
    }
    if (message.categorization !== EpssProbability.UNSPECIFIED) {
      obj.categorization = epssProbabilityToJSON(message.categorization);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EpssScore>, I>>(base?: I): EpssScore {
    return EpssScore.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EpssScore>, I>>(object: I): EpssScore {
    const message = createBaseEpssScore();
    message.score = object.score ?? 0;
    message.percentile = object.percentile ?? 0;
    message.updatedAt = object.updatedAt ?? undefined;
    message.categorization = object.categorization ?? EpssProbability.UNSPECIFIED;
    return message;
  },
};

function createBaseCountVulnsRequest(): CountVulnsRequest {
  return {
    since: 0,
    repositoryIds: "",
    repoIds: [],
    refs: [],
    severities: [],
    transitivities: [],
    exposure: [],
    relevantSince: undefined,
    query: "",
  };
}

export const CountVulnsRequest = {
  encode(message: CountVulnsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.since !== 0) {
      writer.uint32(8).uint64(message.since);
    }
    if (message.repositoryIds !== "") {
      writer.uint32(18).string(message.repositoryIds);
    }
    writer.uint32(82).fork();
    for (const v of message.repoIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    for (const v of message.refs) {
      writer.uint32(26).string(v!);
    }
    writer.uint32(50).fork();
    for (const v of message.severities) {
      writer.int32(advisory_SeverityToNumber(v));
    }
    writer.ldelim();
    writer.uint32(58).fork();
    for (const v of message.transitivities) {
      writer.int32(transitivityToNumber(v));
    }
    writer.ldelim();
    writer.uint32(66).fork();
    for (const v of message.exposure) {
      writer.int32(vuln_ExposureTypeToNumber(v));
    }
    writer.ldelim();
    if (message.relevantSince !== undefined) {
      Timestamp.encode(toTimestamp(message.relevantSince), writer.uint32(74).fork()).ldelim();
    }
    if (message.query !== "") {
      writer.uint32(90).string(message.query);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountVulnsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountVulnsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.since = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.repositoryIds = reader.string();
          continue;
        case 10:
          if (tag === 80) {
            message.repoIds.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.repoIds.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.refs.push(reader.string());
          continue;
        case 6:
          if (tag === 48) {
            message.severities.push(advisory_SeverityFromJSON(reader.int32()));

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.severities.push(advisory_SeverityFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 7:
          if (tag === 56) {
            message.transitivities.push(transitivityFromJSON(reader.int32()));

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.transitivities.push(transitivityFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 8:
          if (tag === 64) {
            message.exposure.push(vuln_ExposureTypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 66) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exposure.push(vuln_ExposureTypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.relevantSince = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.query = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountVulnsRequest {
    return {
      since: isSet(object.since) ? globalThis.Number(object.since) : 0,
      repositoryIds: isSet(object.repositoryIds) ? globalThis.String(object.repositoryIds) : "",
      repoIds: globalThis.Array.isArray(object?.repoIds) ? object.repoIds.map((e: any) => globalThis.Number(e)) : [],
      refs: globalThis.Array.isArray(object?.refs) ? object.refs.map((e: any) => globalThis.String(e)) : [],
      severities: globalThis.Array.isArray(object?.severities)
        ? object.severities.map((e: any) => advisory_SeverityFromJSON(e))
        : [],
      transitivities: globalThis.Array.isArray(object?.transitivities)
        ? object.transitivities.map((e: any) => transitivityFromJSON(e))
        : [],
      exposure: globalThis.Array.isArray(object?.exposure)
        ? object.exposure.map((e: any) => vuln_ExposureTypeFromJSON(e))
        : [],
      relevantSince: isSet(object.relevantSince) ? fromJsonTimestamp(object.relevantSince) : undefined,
      query: isSet(object.query) ? globalThis.String(object.query) : "",
    };
  },

  toJSON(message: CountVulnsRequest): unknown {
    const obj: any = {};
    if (message.since !== 0) {
      obj.since = Math.round(message.since);
    }
    if (message.repositoryIds !== "") {
      obj.repositoryIds = message.repositoryIds;
    }
    if (message.repoIds?.length) {
      obj.repoIds = message.repoIds.map((e) => Math.round(e));
    }
    if (message.refs?.length) {
      obj.refs = message.refs;
    }
    if (message.severities?.length) {
      obj.severities = message.severities.map((e) => advisory_SeverityToJSON(e));
    }
    if (message.transitivities?.length) {
      obj.transitivities = message.transitivities.map((e) => transitivityToJSON(e));
    }
    if (message.exposure?.length) {
      obj.exposure = message.exposure.map((e) => vuln_ExposureTypeToJSON(e));
    }
    if (message.relevantSince !== undefined) {
      obj.relevantSince = message.relevantSince.toISOString();
    }
    if (message.query !== "") {
      obj.query = message.query;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CountVulnsRequest>, I>>(base?: I): CountVulnsRequest {
    return CountVulnsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CountVulnsRequest>, I>>(object: I): CountVulnsRequest {
    const message = createBaseCountVulnsRequest();
    message.since = object.since ?? 0;
    message.repositoryIds = object.repositoryIds ?? "";
    message.repoIds = object.repoIds?.map((e) => e) || [];
    message.refs = object.refs?.map((e) => e) || [];
    message.severities = object.severities?.map((e) => e) || [];
    message.transitivities = object.transitivities?.map((e) => e) || [];
    message.exposure = object.exposure?.map((e) => e) || [];
    message.relevantSince = object.relevantSince ?? undefined;
    message.query = object.query ?? "";
    return message;
  },
};

function createBaseVulnCount(): VulnCount {
  return { reachable: 0, unknown: 0, unreachable: 0 };
}

export const VulnCount = {
  encode(message: VulnCount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.reachable !== 0) {
      writer.uint32(8).uint64(message.reachable);
    }
    if (message.unknown !== 0) {
      writer.uint32(16).uint64(message.unknown);
    }
    if (message.unreachable !== 0) {
      writer.uint32(24).uint64(message.unreachable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VulnCount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVulnCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.reachable = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unknown = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.unreachable = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VulnCount {
    return {
      reachable: isSet(object.reachable) ? globalThis.Number(object.reachable) : 0,
      unknown: isSet(object.unknown) ? globalThis.Number(object.unknown) : 0,
      unreachable: isSet(object.unreachable) ? globalThis.Number(object.unreachable) : 0,
    };
  },

  toJSON(message: VulnCount): unknown {
    const obj: any = {};
    if (message.reachable !== 0) {
      obj.reachable = Math.round(message.reachable);
    }
    if (message.unknown !== 0) {
      obj.unknown = Math.round(message.unknown);
    }
    if (message.unreachable !== 0) {
      obj.unreachable = Math.round(message.unreachable);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VulnCount>, I>>(base?: I): VulnCount {
    return VulnCount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VulnCount>, I>>(object: I): VulnCount {
    const message = createBaseVulnCount();
    message.reachable = object.reachable ?? 0;
    message.unknown = object.unknown ?? 0;
    message.unreachable = object.unreachable ?? 0;
    return message;
  },
};

function createBaseCountVulnsResponse(): CountVulnsResponse {
  return { reachable: 0, unknown: 0, unreachable: 0, vulnsByRepository: {} };
}

export const CountVulnsResponse = {
  encode(message: CountVulnsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.reachable !== 0) {
      writer.uint32(8).uint64(message.reachable);
    }
    if (message.unknown !== 0) {
      writer.uint32(16).uint64(message.unknown);
    }
    if (message.unreachable !== 0) {
      writer.uint32(24).uint64(message.unreachable);
    }
    Object.entries(message.vulnsByRepository).forEach(([key, value]) => {
      CountVulnsResponse_VulnsByRepositoryEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountVulnsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountVulnsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.reachable = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unknown = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.unreachable = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = CountVulnsResponse_VulnsByRepositoryEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.vulnsByRepository[entry4.key] = entry4.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountVulnsResponse {
    return {
      reachable: isSet(object.reachable) ? globalThis.Number(object.reachable) : 0,
      unknown: isSet(object.unknown) ? globalThis.Number(object.unknown) : 0,
      unreachable: isSet(object.unreachable) ? globalThis.Number(object.unreachable) : 0,
      vulnsByRepository: isObject(object.vulnsByRepository)
        ? Object.entries(object.vulnsByRepository).reduce<{ [key: number]: VulnCount }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = VulnCount.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: CountVulnsResponse): unknown {
    const obj: any = {};
    if (message.reachable !== 0) {
      obj.reachable = Math.round(message.reachable);
    }
    if (message.unknown !== 0) {
      obj.unknown = Math.round(message.unknown);
    }
    if (message.unreachable !== 0) {
      obj.unreachable = Math.round(message.unreachable);
    }
    if (message.vulnsByRepository) {
      const entries = Object.entries(message.vulnsByRepository);
      if (entries.length > 0) {
        obj.vulnsByRepository = {};
        entries.forEach(([k, v]) => {
          obj.vulnsByRepository[k] = VulnCount.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CountVulnsResponse>, I>>(base?: I): CountVulnsResponse {
    return CountVulnsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CountVulnsResponse>, I>>(object: I): CountVulnsResponse {
    const message = createBaseCountVulnsResponse();
    message.reachable = object.reachable ?? 0;
    message.unknown = object.unknown ?? 0;
    message.unreachable = object.unreachable ?? 0;
    message.vulnsByRepository = Object.entries(object.vulnsByRepository ?? {}).reduce<{ [key: number]: VulnCount }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = VulnCount.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseCountVulnsResponse_VulnsByRepositoryEntry(): CountVulnsResponse_VulnsByRepositoryEntry {
  return { key: 0, value: undefined };
}

export const CountVulnsResponse_VulnsByRepositoryEntry = {
  encode(message: CountVulnsResponse_VulnsByRepositoryEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).uint64(message.key);
    }
    if (message.value !== undefined) {
      VulnCount.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountVulnsResponse_VulnsByRepositoryEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountVulnsResponse_VulnsByRepositoryEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = VulnCount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountVulnsResponse_VulnsByRepositoryEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? VulnCount.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: CountVulnsResponse_VulnsByRepositoryEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== undefined) {
      obj.value = VulnCount.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CountVulnsResponse_VulnsByRepositoryEntry>, I>>(
    base?: I,
  ): CountVulnsResponse_VulnsByRepositoryEntry {
    return CountVulnsResponse_VulnsByRepositoryEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CountVulnsResponse_VulnsByRepositoryEntry>, I>>(
    object: I,
  ): CountVulnsResponse_VulnsByRepositoryEntry {
    const message = createBaseCountVulnsResponse_VulnsByRepositoryEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? VulnCount.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseListVulnsRequest(): ListVulnsRequest {
  return { deploymentId: 0, pageSize: 0, cursor: 0, refs: undefined };
}

export const ListVulnsRequest = {
  encode(message: ListVulnsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(32).int64(message.deploymentId);
    }
    if (message.pageSize !== 0) {
      writer.uint32(8).uint32(message.pageSize);
    }
    if (message.cursor !== 0) {
      writer.uint32(16).uint64(message.cursor);
    }
    if (message.refs !== undefined) {
      writer.uint32(26).string(message.refs);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListVulnsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListVulnsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 32) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.cursor = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.refs = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListVulnsRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      cursor: isSet(object.cursor) ? globalThis.Number(object.cursor) : 0,
      refs: isSet(object.refs) ? globalThis.String(object.refs) : undefined,
    };
  },

  toJSON(message: ListVulnsRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.cursor !== 0) {
      obj.cursor = Math.round(message.cursor);
    }
    if (message.refs !== undefined) {
      obj.refs = message.refs;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListVulnsRequest>, I>>(base?: I): ListVulnsRequest {
    return ListVulnsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListVulnsRequest>, I>>(object: I): ListVulnsRequest {
    const message = createBaseListVulnsRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.pageSize = object.pageSize ?? 0;
    message.cursor = object.cursor ?? 0;
    message.refs = object.refs ?? undefined;
    return message;
  },
};

function createBaseListVulnsResponse(): ListVulnsResponse {
  return { vulns: [], hasMore: false, cursor: 0 };
}

export const ListVulnsResponse = {
  encode(message: ListVulnsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.vulns) {
      Vuln.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.hasMore !== false) {
      writer.uint32(16).bool(message.hasMore);
    }
    if (message.cursor !== 0) {
      writer.uint32(24).uint64(message.cursor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListVulnsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListVulnsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vulns.push(Vuln.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasMore = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.cursor = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListVulnsResponse {
    return {
      vulns: globalThis.Array.isArray(object?.vulns) ? object.vulns.map((e: any) => Vuln.fromJSON(e)) : [],
      hasMore: isSet(object.hasMore) ? globalThis.Boolean(object.hasMore) : false,
      cursor: isSet(object.cursor) ? globalThis.Number(object.cursor) : 0,
    };
  },

  toJSON(message: ListVulnsResponse): unknown {
    const obj: any = {};
    if (message.vulns?.length) {
      obj.vulns = message.vulns.map((e) => Vuln.toJSON(e));
    }
    if (message.hasMore !== false) {
      obj.hasMore = message.hasMore;
    }
    if (message.cursor !== 0) {
      obj.cursor = Math.round(message.cursor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListVulnsResponse>, I>>(base?: I): ListVulnsResponse {
    return ListVulnsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListVulnsResponse>, I>>(object: I): ListVulnsResponse {
    const message = createBaseListVulnsResponse();
    message.vulns = object.vulns?.map((e) => Vuln.fromPartial(e)) || [];
    message.hasMore = object.hasMore ?? false;
    message.cursor = object.cursor ?? 0;
    return message;
  },
};

function createBaseListVulns2Cursor(): ListVulns2Cursor {
  return { vulnOffset: 0, issueOffset: undefined };
}

export const ListVulns2Cursor = {
  encode(message: ListVulns2Cursor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vulnOffset !== 0) {
      writer.uint32(8).uint64(message.vulnOffset);
    }
    if (message.issueOffset !== undefined) {
      writer.uint32(16).uint64(message.issueOffset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListVulns2Cursor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListVulns2Cursor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.vulnOffset = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.issueOffset = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListVulns2Cursor {
    return {
      vulnOffset: isSet(object.vulnOffset) ? globalThis.Number(object.vulnOffset) : 0,
      issueOffset: isSet(object.issueOffset) ? globalThis.Number(object.issueOffset) : undefined,
    };
  },

  toJSON(message: ListVulns2Cursor): unknown {
    const obj: any = {};
    if (message.vulnOffset !== 0) {
      obj.vulnOffset = Math.round(message.vulnOffset);
    }
    if (message.issueOffset !== undefined) {
      obj.issueOffset = Math.round(message.issueOffset);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListVulns2Cursor>, I>>(base?: I): ListVulns2Cursor {
    return ListVulns2Cursor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListVulns2Cursor>, I>>(object: I): ListVulns2Cursor {
    const message = createBaseListVulns2Cursor();
    message.vulnOffset = object.vulnOffset ?? 0;
    message.issueOffset = object.issueOffset ?? undefined;
    return message;
  },
};

function createBaseListVulns2Request(): ListVulns2Request {
  return {
    deploymentId: 0,
    pageSize: 0,
    cursor: undefined,
    refs: [],
    createdAfter: undefined,
    statuses: [],
    severities: [],
    transitivities: [],
    exposure: [],
    repositoryId: undefined,
    query: undefined,
  };
}

export const ListVulns2Request = {
  encode(message: ListVulns2Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(32).int64(message.deploymentId);
    }
    if (message.pageSize !== 0) {
      writer.uint32(8).uint32(message.pageSize);
    }
    if (message.cursor !== undefined) {
      ListVulns2Cursor.encode(message.cursor, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.refs) {
      writer.uint32(26).string(v!);
    }
    if (message.createdAfter !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAfter), writer.uint32(42).fork()).ldelim();
    }
    writer.uint32(50).fork();
    for (const v of message.statuses) {
      writer.int32(vulnTriage_StatusToNumber(v));
    }
    writer.ldelim();
    writer.uint32(58).fork();
    for (const v of message.severities) {
      writer.int32(advisory_SeverityToNumber(v));
    }
    writer.ldelim();
    writer.uint32(66).fork();
    for (const v of message.transitivities) {
      writer.int32(transitivityToNumber(v));
    }
    writer.ldelim();
    writer.uint32(74).fork();
    for (const v of message.exposure) {
      writer.int32(vuln_ExposureTypeToNumber(v));
    }
    writer.ldelim();
    if (message.repositoryId !== undefined) {
      writer.uint32(80).uint64(message.repositoryId);
    }
    if (message.query !== undefined) {
      writer.uint32(90).string(message.query);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListVulns2Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListVulns2Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 32) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cursor = ListVulns2Cursor.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.refs.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAfter = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag === 48) {
            message.statuses.push(vulnTriage_StatusFromJSON(reader.int32()));

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.statuses.push(vulnTriage_StatusFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 7:
          if (tag === 56) {
            message.severities.push(advisory_SeverityFromJSON(reader.int32()));

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.severities.push(advisory_SeverityFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 8:
          if (tag === 64) {
            message.transitivities.push(transitivityFromJSON(reader.int32()));

            continue;
          }

          if (tag === 66) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.transitivities.push(transitivityFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 9:
          if (tag === 72) {
            message.exposure.push(vuln_ExposureTypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 74) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exposure.push(vuln_ExposureTypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.repositoryId = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.query = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListVulns2Request {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      cursor: isSet(object.cursor) ? ListVulns2Cursor.fromJSON(object.cursor) : undefined,
      refs: globalThis.Array.isArray(object?.refs) ? object.refs.map((e: any) => globalThis.String(e)) : [],
      createdAfter: isSet(object.createdAfter) ? fromJsonTimestamp(object.createdAfter) : undefined,
      statuses: globalThis.Array.isArray(object?.statuses)
        ? object.statuses.map((e: any) => vulnTriage_StatusFromJSON(e))
        : [],
      severities: globalThis.Array.isArray(object?.severities)
        ? object.severities.map((e: any) => advisory_SeverityFromJSON(e))
        : [],
      transitivities: globalThis.Array.isArray(object?.transitivities)
        ? object.transitivities.map((e: any) => transitivityFromJSON(e))
        : [],
      exposure: globalThis.Array.isArray(object?.exposure)
        ? object.exposure.map((e: any) => vuln_ExposureTypeFromJSON(e))
        : [],
      repositoryId: isSet(object.repositoryId) ? globalThis.Number(object.repositoryId) : undefined,
      query: isSet(object.query) ? globalThis.String(object.query) : undefined,
    };
  },

  toJSON(message: ListVulns2Request): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.cursor !== undefined) {
      obj.cursor = ListVulns2Cursor.toJSON(message.cursor);
    }
    if (message.refs?.length) {
      obj.refs = message.refs;
    }
    if (message.createdAfter !== undefined) {
      obj.createdAfter = message.createdAfter.toISOString();
    }
    if (message.statuses?.length) {
      obj.statuses = message.statuses.map((e) => vulnTriage_StatusToJSON(e));
    }
    if (message.severities?.length) {
      obj.severities = message.severities.map((e) => advisory_SeverityToJSON(e));
    }
    if (message.transitivities?.length) {
      obj.transitivities = message.transitivities.map((e) => transitivityToJSON(e));
    }
    if (message.exposure?.length) {
      obj.exposure = message.exposure.map((e) => vuln_ExposureTypeToJSON(e));
    }
    if (message.repositoryId !== undefined) {
      obj.repositoryId = Math.round(message.repositoryId);
    }
    if (message.query !== undefined) {
      obj.query = message.query;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListVulns2Request>, I>>(base?: I): ListVulns2Request {
    return ListVulns2Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListVulns2Request>, I>>(object: I): ListVulns2Request {
    const message = createBaseListVulns2Request();
    message.deploymentId = object.deploymentId ?? 0;
    message.pageSize = object.pageSize ?? 0;
    message.cursor = (object.cursor !== undefined && object.cursor !== null)
      ? ListVulns2Cursor.fromPartial(object.cursor)
      : undefined;
    message.refs = object.refs?.map((e) => e) || [];
    message.createdAfter = object.createdAfter ?? undefined;
    message.statuses = object.statuses?.map((e) => e) || [];
    message.severities = object.severities?.map((e) => e) || [];
    message.transitivities = object.transitivities?.map((e) => e) || [];
    message.exposure = object.exposure?.map((e) => e) || [];
    message.repositoryId = object.repositoryId ?? undefined;
    message.query = object.query ?? undefined;
    return message;
  },
};

function createBaseListVulns2Response(): ListVulns2Response {
  return { vulns: [], hasMore: false, cursor: undefined };
}

export const ListVulns2Response = {
  encode(message: ListVulns2Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.vulns) {
      Vuln.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.hasMore !== false) {
      writer.uint32(16).bool(message.hasMore);
    }
    if (message.cursor !== undefined) {
      ListVulns2Cursor.encode(message.cursor, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListVulns2Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListVulns2Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vulns.push(Vuln.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasMore = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cursor = ListVulns2Cursor.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListVulns2Response {
    return {
      vulns: globalThis.Array.isArray(object?.vulns) ? object.vulns.map((e: any) => Vuln.fromJSON(e)) : [],
      hasMore: isSet(object.hasMore) ? globalThis.Boolean(object.hasMore) : false,
      cursor: isSet(object.cursor) ? ListVulns2Cursor.fromJSON(object.cursor) : undefined,
    };
  },

  toJSON(message: ListVulns2Response): unknown {
    const obj: any = {};
    if (message.vulns?.length) {
      obj.vulns = message.vulns.map((e) => Vuln.toJSON(e));
    }
    if (message.hasMore !== false) {
      obj.hasMore = message.hasMore;
    }
    if (message.cursor !== undefined) {
      obj.cursor = ListVulns2Cursor.toJSON(message.cursor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListVulns2Response>, I>>(base?: I): ListVulns2Response {
    return ListVulns2Response.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListVulns2Response>, I>>(object: I): ListVulns2Response {
    const message = createBaseListVulns2Response();
    message.vulns = object.vulns?.map((e) => Vuln.fromPartial(e)) || [];
    message.hasMore = object.hasMore ?? false;
    message.cursor = (object.cursor !== undefined && object.cursor !== null)
      ? ListVulns2Cursor.fromPartial(object.cursor)
      : undefined;
    return message;
  },
};

function createBasePutVulnTriageRequest(): PutVulnTriageRequest {
  return { deploymentId: 0, groupKey: "", dismissReason: undefined, issueUrl: undefined, prUrl: undefined };
}

export const PutVulnTriageRequest = {
  encode(message: PutVulnTriageRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(32).int64(message.deploymentId);
    }
    if (message.groupKey !== "") {
      writer.uint32(42).string(message.groupKey);
    }
    if (message.dismissReason !== undefined) {
      writer.uint32(8).int32(vulnTriage_DismissReasonToNumber(message.dismissReason));
    }
    if (message.issueUrl !== undefined) {
      writer.uint32(18).string(message.issueUrl);
    }
    if (message.prUrl !== undefined) {
      writer.uint32(26).string(message.prUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PutVulnTriageRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePutVulnTriageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 32) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.groupKey = reader.string();
          continue;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.dismissReason = vulnTriage_DismissReasonFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.issueUrl = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.prUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PutVulnTriageRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      groupKey: isSet(object.groupKey) ? globalThis.String(object.groupKey) : "",
      dismissReason: isSet(object.dismissReason) ? vulnTriage_DismissReasonFromJSON(object.dismissReason) : undefined,
      issueUrl: isSet(object.issueUrl) ? globalThis.String(object.issueUrl) : undefined,
      prUrl: isSet(object.prUrl) ? globalThis.String(object.prUrl) : undefined,
    };
  },

  toJSON(message: PutVulnTriageRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.groupKey !== "") {
      obj.groupKey = message.groupKey;
    }
    if (message.dismissReason !== undefined) {
      obj.dismissReason = vulnTriage_DismissReasonToJSON(message.dismissReason);
    }
    if (message.issueUrl !== undefined) {
      obj.issueUrl = message.issueUrl;
    }
    if (message.prUrl !== undefined) {
      obj.prUrl = message.prUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PutVulnTriageRequest>, I>>(base?: I): PutVulnTriageRequest {
    return PutVulnTriageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PutVulnTriageRequest>, I>>(object: I): PutVulnTriageRequest {
    const message = createBasePutVulnTriageRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.groupKey = object.groupKey ?? "";
    message.dismissReason = object.dismissReason ?? undefined;
    message.issueUrl = object.issueUrl ?? undefined;
    message.prUrl = object.prUrl ?? undefined;
    return message;
  },
};

function createBasePutVulnTriageResponse(): PutVulnTriageResponse {
  return { newTriage: undefined };
}

export const PutVulnTriageResponse = {
  encode(message: PutVulnTriageResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.newTriage !== undefined) {
      VulnTriage.encode(message.newTriage, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PutVulnTriageResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePutVulnTriageResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.newTriage = VulnTriage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PutVulnTriageResponse {
    return { newTriage: isSet(object.newTriage) ? VulnTriage.fromJSON(object.newTriage) : undefined };
  },

  toJSON(message: PutVulnTriageResponse): unknown {
    const obj: any = {};
    if (message.newTriage !== undefined) {
      obj.newTriage = VulnTriage.toJSON(message.newTriage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PutVulnTriageResponse>, I>>(base?: I): PutVulnTriageResponse {
    return PutVulnTriageResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PutVulnTriageResponse>, I>>(object: I): PutVulnTriageResponse {
    const message = createBasePutVulnTriageResponse();
    message.newTriage = (object.newTriage !== undefined && object.newTriage !== null)
      ? VulnTriage.fromPartial(object.newTriage)
      : undefined;
    return message;
  },
};

function createBaseGetVulnsFiltersResponse(): GetVulnsFiltersResponse {
  return { refs: [] };
}

export const GetVulnsFiltersResponse = {
  encode(message: GetVulnsFiltersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.refs) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVulnsFiltersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVulnsFiltersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.refs.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVulnsFiltersResponse {
    return { refs: globalThis.Array.isArray(object?.refs) ? object.refs.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: GetVulnsFiltersResponse): unknown {
    const obj: any = {};
    if (message.refs?.length) {
      obj.refs = message.refs;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVulnsFiltersResponse>, I>>(base?: I): GetVulnsFiltersResponse {
    return GetVulnsFiltersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVulnsFiltersResponse>, I>>(object: I): GetVulnsFiltersResponse {
    const message = createBaseGetVulnsFiltersResponse();
    message.refs = object.refs?.map((e) => e) || [];
    return message;
  },
};

function createBaseListAdvisoriesRequest(): ListAdvisoriesRequest {
  return {};
}

export const ListAdvisoriesRequest = {
  encode(_: ListAdvisoriesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAdvisoriesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAdvisoriesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListAdvisoriesRequest {
    return {};
  },

  toJSON(_: ListAdvisoriesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAdvisoriesRequest>, I>>(base?: I): ListAdvisoriesRequest {
    return ListAdvisoriesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAdvisoriesRequest>, I>>(_: I): ListAdvisoriesRequest {
    const message = createBaseListAdvisoriesRequest();
    return message;
  },
};

function createBaseAdvisoryFilter(): AdvisoryFilter {
  return { query: undefined, language: [], ruleType: [], severity: [] };
}

export const AdvisoryFilter = {
  encode(message: AdvisoryFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.query !== undefined) {
      writer.uint32(10).string(message.query);
    }
    for (const v of message.language) {
      writer.uint32(18).string(v!);
    }
    writer.uint32(26).fork();
    for (const v of message.ruleType) {
      writer.int32(advisory_RuleTypeToNumber(v));
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.severity) {
      writer.int32(advisory_SeverityToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdvisoryFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdvisoryFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.query = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.language.push(reader.string());
          continue;
        case 3:
          if (tag === 24) {
            message.ruleType.push(advisory_RuleTypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.ruleType.push(advisory_RuleTypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 4:
          if (tag === 32) {
            message.severity.push(advisory_SeverityFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.severity.push(advisory_SeverityFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdvisoryFilter {
    return {
      query: isSet(object.query) ? globalThis.String(object.query) : undefined,
      language: globalThis.Array.isArray(object?.language) ? object.language.map((e: any) => globalThis.String(e)) : [],
      ruleType: globalThis.Array.isArray(object?.ruleType)
        ? object.ruleType.map((e: any) => advisory_RuleTypeFromJSON(e))
        : [],
      severity: globalThis.Array.isArray(object?.severity)
        ? object.severity.map((e: any) => advisory_SeverityFromJSON(e))
        : [],
    };
  },

  toJSON(message: AdvisoryFilter): unknown {
    const obj: any = {};
    if (message.query !== undefined) {
      obj.query = message.query;
    }
    if (message.language?.length) {
      obj.language = message.language;
    }
    if (message.ruleType?.length) {
      obj.ruleType = message.ruleType.map((e) => advisory_RuleTypeToJSON(e));
    }
    if (message.severity?.length) {
      obj.severity = message.severity.map((e) => advisory_SeverityToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdvisoryFilter>, I>>(base?: I): AdvisoryFilter {
    return AdvisoryFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdvisoryFilter>, I>>(object: I): AdvisoryFilter {
    const message = createBaseAdvisoryFilter();
    message.query = object.query ?? undefined;
    message.language = object.language?.map((e) => e) || [];
    message.ruleType = object.ruleType?.map((e) => e) || [];
    message.severity = object.severity?.map((e) => e) || [];
    return message;
  },
};

function createBaseListAdvisoriesV2Request(): ListAdvisoriesV2Request {
  return { cursor: undefined, limit: undefined, filter: undefined };
}

export const ListAdvisoriesV2Request = {
  encode(message: ListAdvisoriesV2Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cursor !== undefined) {
      writer.uint32(8).uint64(message.cursor);
    }
    if (message.limit !== undefined) {
      writer.uint32(16).uint64(message.limit);
    }
    if (message.filter !== undefined) {
      AdvisoryFilter.encode(message.filter, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAdvisoriesV2Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAdvisoriesV2Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.cursor = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.limit = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filter = AdvisoryFilter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAdvisoriesV2Request {
    return {
      cursor: isSet(object.cursor) ? globalThis.Number(object.cursor) : undefined,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : undefined,
      filter: isSet(object.filter) ? AdvisoryFilter.fromJSON(object.filter) : undefined,
    };
  },

  toJSON(message: ListAdvisoriesV2Request): unknown {
    const obj: any = {};
    if (message.cursor !== undefined) {
      obj.cursor = Math.round(message.cursor);
    }
    if (message.limit !== undefined) {
      obj.limit = Math.round(message.limit);
    }
    if (message.filter !== undefined) {
      obj.filter = AdvisoryFilter.toJSON(message.filter);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAdvisoriesV2Request>, I>>(base?: I): ListAdvisoriesV2Request {
    return ListAdvisoriesV2Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAdvisoriesV2Request>, I>>(object: I): ListAdvisoriesV2Request {
    const message = createBaseListAdvisoriesV2Request();
    message.cursor = object.cursor ?? undefined;
    message.limit = object.limit ?? undefined;
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? AdvisoryFilter.fromPartial(object.filter)
      : undefined;
    return message;
  },
};

function createBaseListAdvisoriesResponse(): ListAdvisoriesResponse {
  return { advisories: [] };
}

export const ListAdvisoriesResponse = {
  encode(message: ListAdvisoriesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.advisories) {
      Advisory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAdvisoriesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAdvisoriesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.advisories.push(Advisory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAdvisoriesResponse {
    return {
      advisories: globalThis.Array.isArray(object?.advisories)
        ? object.advisories.map((e: any) => Advisory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListAdvisoriesResponse): unknown {
    const obj: any = {};
    if (message.advisories?.length) {
      obj.advisories = message.advisories.map((e) => Advisory.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAdvisoriesResponse>, I>>(base?: I): ListAdvisoriesResponse {
    return ListAdvisoriesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAdvisoriesResponse>, I>>(object: I): ListAdvisoriesResponse {
    const message = createBaseListAdvisoriesResponse();
    message.advisories = object.advisories?.map((e) => Advisory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListAdvisoriesV2Response(): ListAdvisoriesV2Response {
  return { advisories: [], cursor: undefined, totalCount: 0 };
}

export const ListAdvisoriesV2Response = {
  encode(message: ListAdvisoriesV2Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.advisories) {
      Advisory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.cursor !== undefined) {
      writer.uint32(16).uint64(message.cursor);
    }
    if (message.totalCount !== 0) {
      writer.uint32(24).uint64(message.totalCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAdvisoriesV2Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAdvisoriesV2Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.advisories.push(Advisory.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.cursor = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.totalCount = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAdvisoriesV2Response {
    return {
      advisories: globalThis.Array.isArray(object?.advisories)
        ? object.advisories.map((e: any) => Advisory.fromJSON(e))
        : [],
      cursor: isSet(object.cursor) ? globalThis.Number(object.cursor) : undefined,
      totalCount: isSet(object.totalCount) ? globalThis.Number(object.totalCount) : 0,
    };
  },

  toJSON(message: ListAdvisoriesV2Response): unknown {
    const obj: any = {};
    if (message.advisories?.length) {
      obj.advisories = message.advisories.map((e) => Advisory.toJSON(e));
    }
    if (message.cursor !== undefined) {
      obj.cursor = Math.round(message.cursor);
    }
    if (message.totalCount !== 0) {
      obj.totalCount = Math.round(message.totalCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAdvisoriesV2Response>, I>>(base?: I): ListAdvisoriesV2Response {
    return ListAdvisoriesV2Response.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAdvisoriesV2Response>, I>>(object: I): ListAdvisoriesV2Response {
    const message = createBaseListAdvisoriesV2Response();
    message.advisories = object.advisories?.map((e) => Advisory.fromPartial(e)) || [];
    message.cursor = object.cursor ?? undefined;
    message.totalCount = object.totalCount ?? 0;
    return message;
  },
};

function createBasePostAdvisoryFeedbackRequest(): PostAdvisoryFeedbackRequest {
  return { deploymentId: 0, ruleId: "", summary: "", codeSample: "", suggestedChange: "" };
}

export const PostAdvisoryFeedbackRequest = {
  encode(message: PostAdvisoryFeedbackRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(32).int64(message.deploymentId);
    }
    if (message.ruleId !== "") {
      writer.uint32(42).string(message.ruleId);
    }
    if (message.summary !== "") {
      writer.uint32(10).string(message.summary);
    }
    if (message.codeSample !== "") {
      writer.uint32(18).string(message.codeSample);
    }
    if (message.suggestedChange !== "") {
      writer.uint32(26).string(message.suggestedChange);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostAdvisoryFeedbackRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostAdvisoryFeedbackRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 32) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ruleId = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.summary = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.codeSample = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.suggestedChange = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostAdvisoryFeedbackRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      ruleId: isSet(object.ruleId) ? globalThis.String(object.ruleId) : "",
      summary: isSet(object.summary) ? globalThis.String(object.summary) : "",
      codeSample: isSet(object.codeSample) ? globalThis.String(object.codeSample) : "",
      suggestedChange: isSet(object.suggestedChange) ? globalThis.String(object.suggestedChange) : "",
    };
  },

  toJSON(message: PostAdvisoryFeedbackRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.ruleId !== "") {
      obj.ruleId = message.ruleId;
    }
    if (message.summary !== "") {
      obj.summary = message.summary;
    }
    if (message.codeSample !== "") {
      obj.codeSample = message.codeSample;
    }
    if (message.suggestedChange !== "") {
      obj.suggestedChange = message.suggestedChange;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PostAdvisoryFeedbackRequest>, I>>(base?: I): PostAdvisoryFeedbackRequest {
    return PostAdvisoryFeedbackRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostAdvisoryFeedbackRequest>, I>>(object: I): PostAdvisoryFeedbackRequest {
    const message = createBasePostAdvisoryFeedbackRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.ruleId = object.ruleId ?? "";
    message.summary = object.summary ?? "";
    message.codeSample = object.codeSample ?? "";
    message.suggestedChange = object.suggestedChange ?? "";
    return message;
  },
};

function createBasePostAdvisoryFeedbackResponse(): PostAdvisoryFeedbackResponse {
  return {};
}

export const PostAdvisoryFeedbackResponse = {
  encode(_: PostAdvisoryFeedbackResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostAdvisoryFeedbackResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostAdvisoryFeedbackResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PostAdvisoryFeedbackResponse {
    return {};
  },

  toJSON(_: PostAdvisoryFeedbackResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<PostAdvisoryFeedbackResponse>, I>>(base?: I): PostAdvisoryFeedbackResponse {
    return PostAdvisoryFeedbackResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostAdvisoryFeedbackResponse>, I>>(_: I): PostAdvisoryFeedbackResponse {
    const message = createBasePostAdvisoryFeedbackResponse();
    return message;
  },
};

function createBaseFoundDependency(): FoundDependency {
  return {
    repositoryId: 0,
    definedAt: undefined,
    transitivity: Transitivity.UNKNOWN_TRANSITIVITY,
    package: undefined,
    ecosystem: Ecosystem.no_package_manager,
    licenses: [],
    pathToTransitivity: [],
    manifestDefinition: undefined,
  };
}

export const FoundDependency = {
  encode(message: FoundDependency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.repositoryId !== 0) {
      writer.uint32(8).uint64(message.repositoryId);
    }
    if (message.definedAt !== undefined) {
      CodeLocation.encode(message.definedAt, writer.uint32(18).fork()).ldelim();
    }
    if (message.transitivity !== Transitivity.UNKNOWN_TRANSITIVITY) {
      writer.uint32(24).int32(transitivityToNumber(message.transitivity));
    }
    if (message.package !== undefined) {
      Dependency.encode(message.package, writer.uint32(34).fork()).ldelim();
    }
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      writer.uint32(40).int32(ecosystemToNumber(message.ecosystem));
    }
    for (const v of message.licenses) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.pathToTransitivity) {
      Dependency.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.manifestDefinition !== undefined) {
      CodeLocation.encode(message.manifestDefinition, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FoundDependency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFoundDependency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.repositoryId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.definedAt = CodeLocation.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.transitivity = transitivityFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.package = Dependency.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.ecosystem = ecosystemFromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.licenses.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.pathToTransitivity.push(Dependency.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.manifestDefinition = CodeLocation.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FoundDependency {
    return {
      repositoryId: isSet(object.repositoryId) ? globalThis.Number(object.repositoryId) : 0,
      definedAt: isSet(object.definedAt) ? CodeLocation.fromJSON(object.definedAt) : undefined,
      transitivity: isSet(object.transitivity)
        ? transitivityFromJSON(object.transitivity)
        : Transitivity.UNKNOWN_TRANSITIVITY,
      package: isSet(object.package) ? Dependency.fromJSON(object.package) : undefined,
      ecosystem: isSet(object.ecosystem) ? ecosystemFromJSON(object.ecosystem) : Ecosystem.no_package_manager,
      licenses: globalThis.Array.isArray(object?.licenses) ? object.licenses.map((e: any) => globalThis.String(e)) : [],
      pathToTransitivity: globalThis.Array.isArray(object?.pathToTransitivity)
        ? object.pathToTransitivity.map((e: any) => Dependency.fromJSON(e))
        : [],
      manifestDefinition: isSet(object.manifestDefinition)
        ? CodeLocation.fromJSON(object.manifestDefinition)
        : undefined,
    };
  },

  toJSON(message: FoundDependency): unknown {
    const obj: any = {};
    if (message.repositoryId !== 0) {
      obj.repositoryId = Math.round(message.repositoryId);
    }
    if (message.definedAt !== undefined) {
      obj.definedAt = CodeLocation.toJSON(message.definedAt);
    }
    if (message.transitivity !== Transitivity.UNKNOWN_TRANSITIVITY) {
      obj.transitivity = transitivityToJSON(message.transitivity);
    }
    if (message.package !== undefined) {
      obj.package = Dependency.toJSON(message.package);
    }
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      obj.ecosystem = ecosystemToJSON(message.ecosystem);
    }
    if (message.licenses?.length) {
      obj.licenses = message.licenses;
    }
    if (message.pathToTransitivity?.length) {
      obj.pathToTransitivity = message.pathToTransitivity.map((e) => Dependency.toJSON(e));
    }
    if (message.manifestDefinition !== undefined) {
      obj.manifestDefinition = CodeLocation.toJSON(message.manifestDefinition);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FoundDependency>, I>>(base?: I): FoundDependency {
    return FoundDependency.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FoundDependency>, I>>(object: I): FoundDependency {
    const message = createBaseFoundDependency();
    message.repositoryId = object.repositoryId ?? 0;
    message.definedAt = (object.definedAt !== undefined && object.definedAt !== null)
      ? CodeLocation.fromPartial(object.definedAt)
      : undefined;
    message.transitivity = object.transitivity ?? Transitivity.UNKNOWN_TRANSITIVITY;
    message.package = (object.package !== undefined && object.package !== null)
      ? Dependency.fromPartial(object.package)
      : undefined;
    message.ecosystem = object.ecosystem ?? Ecosystem.no_package_manager;
    message.licenses = object.licenses?.map((e) => e) || [];
    message.pathToTransitivity = object.pathToTransitivity?.map((e) => Dependency.fromPartial(e)) || [];
    message.manifestDefinition = (object.manifestDefinition !== undefined && object.manifestDefinition !== null)
      ? CodeLocation.fromPartial(object.manifestDefinition)
      : undefined;
    return message;
  },
};

function createBaseDependencyFilter(): DependencyFilter {
  return {
    name: "",
    version: "",
    repositoryId: [],
    transitivity: [],
    ecosystem: [],
    lockfilePath: "",
    licensePolicySetting: LicensePolicySetting.UNSPECIFIED,
    license: [],
  };
}

export const DependencyFilter = {
  encode(message: DependencyFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.version !== "") {
      writer.uint32(18).string(message.version);
    }
    writer.uint32(26).fork();
    for (const v of message.repositoryId) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.transitivity) {
      writer.int32(transitivityToNumber(v));
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.ecosystem) {
      writer.int32(ecosystemToNumber(v));
    }
    writer.ldelim();
    if (message.lockfilePath !== "") {
      writer.uint32(50).string(message.lockfilePath);
    }
    if (message.licensePolicySetting !== LicensePolicySetting.UNSPECIFIED) {
      writer.uint32(56).int32(licensePolicySettingToNumber(message.licensePolicySetting));
    }
    for (const v of message.license) {
      writer.uint32(66).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DependencyFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDependencyFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.version = reader.string();
          continue;
        case 3:
          if (tag === 24) {
            message.repositoryId.push(reader.uint32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.repositoryId.push(reader.uint32());
            }

            continue;
          }

          break;
        case 4:
          if (tag === 32) {
            message.transitivity.push(transitivityFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.transitivity.push(transitivityFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 5:
          if (tag === 40) {
            message.ecosystem.push(ecosystemFromJSON(reader.int32()));

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.ecosystem.push(ecosystemFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lockfilePath = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.licensePolicySetting = licensePolicySettingFromJSON(reader.int32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.license.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DependencyFilter {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      repositoryId: globalThis.Array.isArray(object?.repositoryId)
        ? object.repositoryId.map((e: any) => globalThis.Number(e))
        : [],
      transitivity: globalThis.Array.isArray(object?.transitivity)
        ? object.transitivity.map((e: any) => transitivityFromJSON(e))
        : [],
      ecosystem: globalThis.Array.isArray(object?.ecosystem)
        ? object.ecosystem.map((e: any) => ecosystemFromJSON(e))
        : [],
      lockfilePath: isSet(object.lockfilePath) ? globalThis.String(object.lockfilePath) : "",
      licensePolicySetting: isSet(object.licensePolicySetting)
        ? licensePolicySettingFromJSON(object.licensePolicySetting)
        : LicensePolicySetting.UNSPECIFIED,
      license: globalThis.Array.isArray(object?.license) ? object.license.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: DependencyFilter): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.repositoryId?.length) {
      obj.repositoryId = message.repositoryId.map((e) => Math.round(e));
    }
    if (message.transitivity?.length) {
      obj.transitivity = message.transitivity.map((e) => transitivityToJSON(e));
    }
    if (message.ecosystem?.length) {
      obj.ecosystem = message.ecosystem.map((e) => ecosystemToJSON(e));
    }
    if (message.lockfilePath !== "") {
      obj.lockfilePath = message.lockfilePath;
    }
    if (message.licensePolicySetting !== LicensePolicySetting.UNSPECIFIED) {
      obj.licensePolicySetting = licensePolicySettingToJSON(message.licensePolicySetting);
    }
    if (message.license?.length) {
      obj.license = message.license;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DependencyFilter>, I>>(base?: I): DependencyFilter {
    return DependencyFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DependencyFilter>, I>>(object: I): DependencyFilter {
    const message = createBaseDependencyFilter();
    message.name = object.name ?? "";
    message.version = object.version ?? "";
    message.repositoryId = object.repositoryId?.map((e) => e) || [];
    message.transitivity = object.transitivity?.map((e) => e) || [];
    message.ecosystem = object.ecosystem?.map((e) => e) || [];
    message.lockfilePath = object.lockfilePath ?? "";
    message.licensePolicySetting = object.licensePolicySetting ?? LicensePolicySetting.UNSPECIFIED;
    message.license = object.license?.map((e) => e) || [];
    return message;
  },
};

function createBaseListDependenciesRequest(): ListDependenciesRequest {
  return { deploymentId: 0, dependencyFilter: undefined, cursor: 0, pageSize: 0 };
}

export const ListDependenciesRequest = {
  encode(message: ListDependenciesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    if (message.dependencyFilter !== undefined) {
      DependencyFilter.encode(message.dependencyFilter, writer.uint32(18).fork()).ldelim();
    }
    if (message.cursor !== 0) {
      writer.uint32(24).uint64(message.cursor);
    }
    if (message.pageSize !== 0) {
      writer.uint32(32).uint32(message.pageSize);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDependenciesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDependenciesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dependencyFilter = DependencyFilter.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.cursor = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.pageSize = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDependenciesRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      dependencyFilter: isSet(object.dependencyFilter) ? DependencyFilter.fromJSON(object.dependencyFilter) : undefined,
      cursor: isSet(object.cursor) ? globalThis.Number(object.cursor) : 0,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
    };
  },

  toJSON(message: ListDependenciesRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.dependencyFilter !== undefined) {
      obj.dependencyFilter = DependencyFilter.toJSON(message.dependencyFilter);
    }
    if (message.cursor !== 0) {
      obj.cursor = Math.round(message.cursor);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListDependenciesRequest>, I>>(base?: I): ListDependenciesRequest {
    return ListDependenciesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListDependenciesRequest>, I>>(object: I): ListDependenciesRequest {
    const message = createBaseListDependenciesRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.dependencyFilter = (object.dependencyFilter !== undefined && object.dependencyFilter !== null)
      ? DependencyFilter.fromPartial(object.dependencyFilter)
      : undefined;
    message.cursor = object.cursor ?? 0;
    message.pageSize = object.pageSize ?? 0;
    return message;
  },
};

function createBaseListDependenciesResponse(): ListDependenciesResponse {
  return { dependencies: [], hasMore: false, cursor: 0 };
}

export const ListDependenciesResponse = {
  encode(message: ListDependenciesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dependencies) {
      FoundDependency.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.hasMore !== false) {
      writer.uint32(16).bool(message.hasMore);
    }
    if (message.cursor !== 0) {
      writer.uint32(24).uint64(message.cursor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDependenciesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDependenciesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dependencies.push(FoundDependency.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasMore = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.cursor = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDependenciesResponse {
    return {
      dependencies: globalThis.Array.isArray(object?.dependencies)
        ? object.dependencies.map((e: any) => FoundDependency.fromJSON(e))
        : [],
      hasMore: isSet(object.hasMore) ? globalThis.Boolean(object.hasMore) : false,
      cursor: isSet(object.cursor) ? globalThis.Number(object.cursor) : 0,
    };
  },

  toJSON(message: ListDependenciesResponse): unknown {
    const obj: any = {};
    if (message.dependencies?.length) {
      obj.dependencies = message.dependencies.map((e) => FoundDependency.toJSON(e));
    }
    if (message.hasMore !== false) {
      obj.hasMore = message.hasMore;
    }
    if (message.cursor !== 0) {
      obj.cursor = Math.round(message.cursor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListDependenciesResponse>, I>>(base?: I): ListDependenciesResponse {
    return ListDependenciesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListDependenciesResponse>, I>>(object: I): ListDependenciesResponse {
    const message = createBaseListDependenciesResponse();
    message.dependencies = object.dependencies?.map((e) => FoundDependency.fromPartial(e)) || [];
    message.hasMore = object.hasMore ?? false;
    message.cursor = object.cursor ?? 0;
    return message;
  },
};

function createBaseListDependenciesMultiRequest(): ListDependenciesMultiRequest {
  return { deploymentId: 0, requests: [] };
}

export const ListDependenciesMultiRequest = {
  encode(message: ListDependenciesMultiRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    for (const v of message.requests) {
      ListDependenciesRequest.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDependenciesMultiRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDependenciesMultiRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.requests.push(ListDependenciesRequest.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDependenciesMultiRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      requests: globalThis.Array.isArray(object?.requests)
        ? object.requests.map((e: any) => ListDependenciesRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListDependenciesMultiRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.requests?.length) {
      obj.requests = message.requests.map((e) => ListDependenciesRequest.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListDependenciesMultiRequest>, I>>(base?: I): ListDependenciesMultiRequest {
    return ListDependenciesMultiRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListDependenciesMultiRequest>, I>>(object: I): ListDependenciesMultiRequest {
    const message = createBaseListDependenciesMultiRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.requests = object.requests?.map((e) => ListDependenciesRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListDependenciesMultiResponse(): ListDependenciesMultiResponse {
  return { responses: [] };
}

export const ListDependenciesMultiResponse = {
  encode(message: ListDependenciesMultiResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.responses) {
      ListDependenciesResponse.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDependenciesMultiResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDependenciesMultiResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.responses.push(ListDependenciesResponse.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDependenciesMultiResponse {
    return {
      responses: globalThis.Array.isArray(object?.responses)
        ? object.responses.map((e: any) => ListDependenciesResponse.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListDependenciesMultiResponse): unknown {
    const obj: any = {};
    if (message.responses?.length) {
      obj.responses = message.responses.map((e) => ListDependenciesResponse.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListDependenciesMultiResponse>, I>>(base?: I): ListDependenciesMultiResponse {
    return ListDependenciesMultiResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListDependenciesMultiResponse>, I>>(
    object: I,
  ): ListDependenciesMultiResponse {
    const message = createBaseListDependenciesMultiResponse();
    message.responses = object.responses?.map((e) => ListDependenciesResponse.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRepositoryDependencySummary(): RepositoryDependencySummary {
  return { id: 0, name: "", numDependencies: 0 };
}

export const RepositoryDependencySummary = {
  encode(message: RepositoryDependencySummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.numDependencies !== 0) {
      writer.uint32(24).uint32(message.numDependencies);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RepositoryDependencySummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRepositoryDependencySummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.numDependencies = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RepositoryDependencySummary {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      numDependencies: isSet(object.numDependencies) ? globalThis.Number(object.numDependencies) : 0,
    };
  },

  toJSON(message: RepositoryDependencySummary): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.numDependencies !== 0) {
      obj.numDependencies = Math.round(message.numDependencies);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RepositoryDependencySummary>, I>>(base?: I): RepositoryDependencySummary {
    return RepositoryDependencySummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RepositoryDependencySummary>, I>>(object: I): RepositoryDependencySummary {
    const message = createBaseRepositoryDependencySummary();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.numDependencies = object.numDependencies ?? 0;
    return message;
  },
};

function createBaseListRepositoriesForDependenciesRequest(): ListRepositoriesForDependenciesRequest {
  return { deploymentId: 0, dependencyFilter: undefined, cursor: 0, pageSize: 0 };
}

export const ListRepositoriesForDependenciesRequest = {
  encode(message: ListRepositoriesForDependenciesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.dependencyFilter !== undefined) {
      DependencyFilter.encode(message.dependencyFilter, writer.uint32(18).fork()).ldelim();
    }
    if (message.cursor !== 0) {
      writer.uint32(24).uint32(message.cursor);
    }
    if (message.pageSize !== 0) {
      writer.uint32(32).uint32(message.pageSize);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRepositoriesForDependenciesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRepositoriesForDependenciesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dependencyFilter = DependencyFilter.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.cursor = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.pageSize = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRepositoriesForDependenciesRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      dependencyFilter: isSet(object.dependencyFilter) ? DependencyFilter.fromJSON(object.dependencyFilter) : undefined,
      cursor: isSet(object.cursor) ? globalThis.Number(object.cursor) : 0,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
    };
  },

  toJSON(message: ListRepositoriesForDependenciesRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.dependencyFilter !== undefined) {
      obj.dependencyFilter = DependencyFilter.toJSON(message.dependencyFilter);
    }
    if (message.cursor !== 0) {
      obj.cursor = Math.round(message.cursor);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListRepositoriesForDependenciesRequest>, I>>(
    base?: I,
  ): ListRepositoriesForDependenciesRequest {
    return ListRepositoriesForDependenciesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListRepositoriesForDependenciesRequest>, I>>(
    object: I,
  ): ListRepositoriesForDependenciesRequest {
    const message = createBaseListRepositoriesForDependenciesRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.dependencyFilter = (object.dependencyFilter !== undefined && object.dependencyFilter !== null)
      ? DependencyFilter.fromPartial(object.dependencyFilter)
      : undefined;
    message.cursor = object.cursor ?? 0;
    message.pageSize = object.pageSize ?? 0;
    return message;
  },
};

function createBaseListRepositoriesForDependenciesResponse(): ListRepositoriesForDependenciesResponse {
  return { repositorySummaries: [], hasMore: false, cursor: undefined };
}

export const ListRepositoriesForDependenciesResponse = {
  encode(message: ListRepositoriesForDependenciesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.repositorySummaries) {
      RepositoryDependencySummary.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.hasMore !== false) {
      writer.uint32(16).bool(message.hasMore);
    }
    if (message.cursor !== undefined) {
      writer.uint32(24).uint32(message.cursor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRepositoriesForDependenciesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRepositoriesForDependenciesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.repositorySummaries.push(RepositoryDependencySummary.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasMore = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.cursor = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRepositoriesForDependenciesResponse {
    return {
      repositorySummaries: globalThis.Array.isArray(object?.repositorySummaries)
        ? object.repositorySummaries.map((e: any) => RepositoryDependencySummary.fromJSON(e))
        : [],
      hasMore: isSet(object.hasMore) ? globalThis.Boolean(object.hasMore) : false,
      cursor: isSet(object.cursor) ? globalThis.Number(object.cursor) : undefined,
    };
  },

  toJSON(message: ListRepositoriesForDependenciesResponse): unknown {
    const obj: any = {};
    if (message.repositorySummaries?.length) {
      obj.repositorySummaries = message.repositorySummaries.map((e) => RepositoryDependencySummary.toJSON(e));
    }
    if (message.hasMore !== false) {
      obj.hasMore = message.hasMore;
    }
    if (message.cursor !== undefined) {
      obj.cursor = Math.round(message.cursor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListRepositoriesForDependenciesResponse>, I>>(
    base?: I,
  ): ListRepositoriesForDependenciesResponse {
    return ListRepositoriesForDependenciesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListRepositoriesForDependenciesResponse>, I>>(
    object: I,
  ): ListRepositoriesForDependenciesResponse {
    const message = createBaseListRepositoriesForDependenciesResponse();
    message.repositorySummaries = object.repositorySummaries?.map((e) => RepositoryDependencySummary.fromPartial(e)) ||
      [];
    message.hasMore = object.hasMore ?? false;
    message.cursor = object.cursor ?? undefined;
    return message;
  },
};

function createBaseListLockfilesForDependenciesRequest(): ListLockfilesForDependenciesRequest {
  return { deploymentId: 0, repositoryId: 0, dependencyFilter: undefined, cursor: "", pageSize: 0 };
}

export const ListLockfilesForDependenciesRequest = {
  encode(message: ListLockfilesForDependenciesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.repositoryId !== 0) {
      writer.uint32(16).uint64(message.repositoryId);
    }
    if (message.dependencyFilter !== undefined) {
      DependencyFilter.encode(message.dependencyFilter, writer.uint32(26).fork()).ldelim();
    }
    if (message.cursor !== "") {
      writer.uint32(34).string(message.cursor);
    }
    if (message.pageSize !== 0) {
      writer.uint32(40).uint32(message.pageSize);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListLockfilesForDependenciesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListLockfilesForDependenciesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.repositoryId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.dependencyFilter = DependencyFilter.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cursor = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pageSize = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListLockfilesForDependenciesRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      repositoryId: isSet(object.repositoryId) ? globalThis.Number(object.repositoryId) : 0,
      dependencyFilter: isSet(object.dependencyFilter) ? DependencyFilter.fromJSON(object.dependencyFilter) : undefined,
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : "",
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
    };
  },

  toJSON(message: ListLockfilesForDependenciesRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.repositoryId !== 0) {
      obj.repositoryId = Math.round(message.repositoryId);
    }
    if (message.dependencyFilter !== undefined) {
      obj.dependencyFilter = DependencyFilter.toJSON(message.dependencyFilter);
    }
    if (message.cursor !== "") {
      obj.cursor = message.cursor;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListLockfilesForDependenciesRequest>, I>>(
    base?: I,
  ): ListLockfilesForDependenciesRequest {
    return ListLockfilesForDependenciesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListLockfilesForDependenciesRequest>, I>>(
    object: I,
  ): ListLockfilesForDependenciesRequest {
    const message = createBaseListLockfilesForDependenciesRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.repositoryId = object.repositoryId ?? 0;
    message.dependencyFilter = (object.dependencyFilter !== undefined && object.dependencyFilter !== null)
      ? DependencyFilter.fromPartial(object.dependencyFilter)
      : undefined;
    message.cursor = object.cursor ?? "";
    message.pageSize = object.pageSize ?? 0;
    return message;
  },
};

function createBaseLockfileDependencySummary(): LockfileDependencySummary {
  return { lockfilePath: "", numDependencies: 0 };
}

export const LockfileDependencySummary = {
  encode(message: LockfileDependencySummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lockfilePath !== "") {
      writer.uint32(10).string(message.lockfilePath);
    }
    if (message.numDependencies !== 0) {
      writer.uint32(16).uint32(message.numDependencies);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LockfileDependencySummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLockfileDependencySummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lockfilePath = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.numDependencies = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LockfileDependencySummary {
    return {
      lockfilePath: isSet(object.lockfilePath) ? globalThis.String(object.lockfilePath) : "",
      numDependencies: isSet(object.numDependencies) ? globalThis.Number(object.numDependencies) : 0,
    };
  },

  toJSON(message: LockfileDependencySummary): unknown {
    const obj: any = {};
    if (message.lockfilePath !== "") {
      obj.lockfilePath = message.lockfilePath;
    }
    if (message.numDependencies !== 0) {
      obj.numDependencies = Math.round(message.numDependencies);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LockfileDependencySummary>, I>>(base?: I): LockfileDependencySummary {
    return LockfileDependencySummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LockfileDependencySummary>, I>>(object: I): LockfileDependencySummary {
    const message = createBaseLockfileDependencySummary();
    message.lockfilePath = object.lockfilePath ?? "";
    message.numDependencies = object.numDependencies ?? 0;
    return message;
  },
};

function createBaseListLockfilesForDependenciesResponse(): ListLockfilesForDependenciesResponse {
  return { lockfileSummaries: [], hasMore: false, cursor: undefined };
}

export const ListLockfilesForDependenciesResponse = {
  encode(message: ListLockfilesForDependenciesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.lockfileSummaries) {
      LockfileDependencySummary.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.hasMore !== false) {
      writer.uint32(16).bool(message.hasMore);
    }
    if (message.cursor !== undefined) {
      writer.uint32(26).string(message.cursor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListLockfilesForDependenciesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListLockfilesForDependenciesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lockfileSummaries.push(LockfileDependencySummary.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasMore = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cursor = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListLockfilesForDependenciesResponse {
    return {
      lockfileSummaries: globalThis.Array.isArray(object?.lockfileSummaries)
        ? object.lockfileSummaries.map((e: any) => LockfileDependencySummary.fromJSON(e))
        : [],
      hasMore: isSet(object.hasMore) ? globalThis.Boolean(object.hasMore) : false,
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
    };
  },

  toJSON(message: ListLockfilesForDependenciesResponse): unknown {
    const obj: any = {};
    if (message.lockfileSummaries?.length) {
      obj.lockfileSummaries = message.lockfileSummaries.map((e) => LockfileDependencySummary.toJSON(e));
    }
    if (message.hasMore !== false) {
      obj.hasMore = message.hasMore;
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListLockfilesForDependenciesResponse>, I>>(
    base?: I,
  ): ListLockfilesForDependenciesResponse {
    return ListLockfilesForDependenciesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListLockfilesForDependenciesResponse>, I>>(
    object: I,
  ): ListLockfilesForDependenciesResponse {
    const message = createBaseListLockfilesForDependenciesResponse();
    message.lockfileSummaries = object.lockfileSummaries?.map((e) => LockfileDependencySummary.fromPartial(e)) || [];
    message.hasMore = object.hasMore ?? false;
    message.cursor = object.cursor ?? undefined;
    return message;
  },
};

function createBaseRepositoryLockfile(): RepositoryLockfile {
  return { repositoryId: 0, lockfilePath: "" };
}

export const RepositoryLockfile = {
  encode(message: RepositoryLockfile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.repositoryId !== 0) {
      writer.uint32(8).uint32(message.repositoryId);
    }
    if (message.lockfilePath !== "") {
      writer.uint32(18).string(message.lockfilePath);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RepositoryLockfile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRepositoryLockfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.repositoryId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lockfilePath = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RepositoryLockfile {
    return {
      repositoryId: isSet(object.repositoryId) ? globalThis.Number(object.repositoryId) : 0,
      lockfilePath: isSet(object.lockfilePath) ? globalThis.String(object.lockfilePath) : "",
    };
  },

  toJSON(message: RepositoryLockfile): unknown {
    const obj: any = {};
    if (message.repositoryId !== 0) {
      obj.repositoryId = Math.round(message.repositoryId);
    }
    if (message.lockfilePath !== "") {
      obj.lockfilePath = message.lockfilePath;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RepositoryLockfile>, I>>(base?: I): RepositoryLockfile {
    return RepositoryLockfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RepositoryLockfile>, I>>(object: I): RepositoryLockfile {
    const message = createBaseRepositoryLockfile();
    message.repositoryId = object.repositoryId ?? 0;
    message.lockfilePath = object.lockfilePath ?? "";
    return message;
  },
};

function createBaseDependencyFacetCount(): DependencyFacetCount {
  return { count: 0, facet: undefined };
}

export const DependencyFacetCount = {
  encode(message: DependencyFacetCount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.count !== 0) {
      writer.uint32(8).uint32(message.count);
    }
    switch (message.facet?.$case) {
      case "repositoryLockfile":
        RepositoryLockfile.encode(message.facet.repositoryLockfile, writer.uint32(18).fork()).ldelim();
        break;
      case "transitivity":
        writer.uint32(24).int32(transitivityToNumber(message.facet.transitivity));
        break;
      case "ecosystem":
        writer.uint32(32).int32(ecosystemToNumber(message.facet.ecosystem));
        break;
      case "license":
        writer.uint32(42).string(message.facet.license);
        break;
      case "licensePolicySetting":
        writer.uint32(48).int32(licensePolicySettingToNumber(message.facet.licensePolicySetting));
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DependencyFacetCount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDependencyFacetCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.count = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.facet = {
            $case: "repositoryLockfile",
            repositoryLockfile: RepositoryLockfile.decode(reader, reader.uint32()),
          };
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.facet = { $case: "transitivity", transitivity: transitivityFromJSON(reader.int32()) };
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.facet = { $case: "ecosystem", ecosystem: ecosystemFromJSON(reader.int32()) };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.facet = { $case: "license", license: reader.string() };
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.facet = {
            $case: "licensePolicySetting",
            licensePolicySetting: licensePolicySettingFromJSON(reader.int32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DependencyFacetCount {
    return {
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
      facet: isSet(object.repositoryLockfile)
        ? { $case: "repositoryLockfile", repositoryLockfile: RepositoryLockfile.fromJSON(object.repositoryLockfile) }
        : isSet(object.transitivity)
        ? { $case: "transitivity", transitivity: transitivityFromJSON(object.transitivity) }
        : isSet(object.ecosystem)
        ? { $case: "ecosystem", ecosystem: ecosystemFromJSON(object.ecosystem) }
        : isSet(object.license)
        ? { $case: "license", license: globalThis.String(object.license) }
        : isSet(object.licensePolicySetting)
        ? {
          $case: "licensePolicySetting",
          licensePolicySetting: licensePolicySettingFromJSON(object.licensePolicySetting),
        }
        : undefined,
    };
  },

  toJSON(message: DependencyFacetCount): unknown {
    const obj: any = {};
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    if (message.facet?.$case === "repositoryLockfile") {
      obj.repositoryLockfile = RepositoryLockfile.toJSON(message.facet.repositoryLockfile);
    }
    if (message.facet?.$case === "transitivity") {
      obj.transitivity = transitivityToJSON(message.facet.transitivity);
    }
    if (message.facet?.$case === "ecosystem") {
      obj.ecosystem = ecosystemToJSON(message.facet.ecosystem);
    }
    if (message.facet?.$case === "license") {
      obj.license = message.facet.license;
    }
    if (message.facet?.$case === "licensePolicySetting") {
      obj.licensePolicySetting = licensePolicySettingToJSON(message.facet.licensePolicySetting);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DependencyFacetCount>, I>>(base?: I): DependencyFacetCount {
    return DependencyFacetCount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DependencyFacetCount>, I>>(object: I): DependencyFacetCount {
    const message = createBaseDependencyFacetCount();
    message.count = object.count ?? 0;
    if (
      object.facet?.$case === "repositoryLockfile" &&
      object.facet?.repositoryLockfile !== undefined &&
      object.facet?.repositoryLockfile !== null
    ) {
      message.facet = {
        $case: "repositoryLockfile",
        repositoryLockfile: RepositoryLockfile.fromPartial(object.facet.repositoryLockfile),
      };
    }
    if (
      object.facet?.$case === "transitivity" &&
      object.facet?.transitivity !== undefined &&
      object.facet?.transitivity !== null
    ) {
      message.facet = { $case: "transitivity", transitivity: object.facet.transitivity };
    }
    if (
      object.facet?.$case === "ecosystem" && object.facet?.ecosystem !== undefined && object.facet?.ecosystem !== null
    ) {
      message.facet = { $case: "ecosystem", ecosystem: object.facet.ecosystem };
    }
    if (object.facet?.$case === "license" && object.facet?.license !== undefined && object.facet?.license !== null) {
      message.facet = { $case: "license", license: object.facet.license };
    }
    if (
      object.facet?.$case === "licensePolicySetting" &&
      object.facet?.licensePolicySetting !== undefined &&
      object.facet?.licensePolicySetting !== null
    ) {
      message.facet = { $case: "licensePolicySetting", licensePolicySetting: object.facet.licensePolicySetting };
    }
    return message;
  },
};

function createBaseDependencyParserError(): DependencyParserError {
  return { location: undefined, reason: "", parser: "", text: "" };
}

export const DependencyParserError = {
  encode(message: DependencyParserError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.location !== undefined) {
      CodeLocation.encode(message.location, writer.uint32(10).fork()).ldelim();
    }
    if (message.reason !== "") {
      writer.uint32(18).string(message.reason);
    }
    if (message.parser !== "") {
      writer.uint32(26).string(message.parser);
    }
    if (message.text !== "") {
      writer.uint32(34).string(message.text);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DependencyParserError {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDependencyParserError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.location = CodeLocation.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.reason = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.parser = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.text = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DependencyParserError {
    return {
      location: isSet(object.location) ? CodeLocation.fromJSON(object.location) : undefined,
      reason: isSet(object.reason) ? globalThis.String(object.reason) : "",
      parser: isSet(object.parser) ? globalThis.String(object.parser) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
    };
  },

  toJSON(message: DependencyParserError): unknown {
    const obj: any = {};
    if (message.location !== undefined) {
      obj.location = CodeLocation.toJSON(message.location);
    }
    if (message.reason !== "") {
      obj.reason = message.reason;
    }
    if (message.parser !== "") {
      obj.parser = message.parser;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DependencyParserError>, I>>(base?: I): DependencyParserError {
    return DependencyParserError.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DependencyParserError>, I>>(object: I): DependencyParserError {
    const message = createBaseDependencyParserError();
    message.location = (object.location !== undefined && object.location !== null)
      ? CodeLocation.fromPartial(object.location)
      : undefined;
    message.reason = object.reason ?? "";
    message.parser = object.parser ?? "";
    message.text = object.text ?? "";
    return message;
  },
};

function createBaseDependencyParserErrors(): DependencyParserErrors {
  return { errors: [] };
}

export const DependencyParserErrors = {
  encode(message: DependencyParserErrors, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.errors) {
      DependencyParserError.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DependencyParserErrors {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDependencyParserErrors();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.errors.push(DependencyParserError.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DependencyParserErrors {
    return {
      errors: globalThis.Array.isArray(object?.errors)
        ? object.errors.map((e: any) => DependencyParserError.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DependencyParserErrors): unknown {
    const obj: any = {};
    if (message.errors?.length) {
      obj.errors = message.errors.map((e) => DependencyParserError.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DependencyParserErrors>, I>>(base?: I): DependencyParserErrors {
    return DependencyParserErrors.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DependencyParserErrors>, I>>(object: I): DependencyParserErrors {
    const message = createBaseDependencyParserErrors();
    message.errors = object.errors?.map((e) => DependencyParserError.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDependencyException(): DependencyException {
  return { ecosystem: Ecosystem.no_package_manager, name: "", version: "" };
}

export const DependencyException = {
  encode(message: DependencyException, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      writer.uint32(8).int32(ecosystemToNumber(message.ecosystem));
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.version !== "") {
      writer.uint32(26).string(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DependencyException {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDependencyException();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ecosystem = ecosystemFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.version = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DependencyException {
    return {
      ecosystem: isSet(object.ecosystem) ? ecosystemFromJSON(object.ecosystem) : Ecosystem.no_package_manager,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
    };
  },

  toJSON(message: DependencyException): unknown {
    const obj: any = {};
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      obj.ecosystem = ecosystemToJSON(message.ecosystem);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DependencyException>, I>>(base?: I): DependencyException {
    return DependencyException.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DependencyException>, I>>(object: I): DependencyException {
    const message = createBaseDependencyException();
    message.ecosystem = object.ecosystem ?? Ecosystem.no_package_manager;
    message.name = object.name ?? "";
    message.version = object.version ?? "";
    return message;
  },
};

function createBaseLicensePolicy(): LicensePolicy {
  return { settings: {}, exceptions: [] };
}

export const LicensePolicy = {
  encode(message: LicensePolicy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.settings).forEach(([key, value]) => {
      LicensePolicy_SettingsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    for (const v of message.exceptions) {
      DependencyException.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LicensePolicy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLicensePolicy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = LicensePolicy_SettingsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.settings[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.exceptions.push(DependencyException.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LicensePolicy {
    return {
      settings: isObject(object.settings)
        ? Object.entries(object.settings).reduce<{ [key: string]: LicensePolicySetting }>((acc, [key, value]) => {
          acc[key] = licensePolicySettingFromJSON(value);
          return acc;
        }, {})
        : {},
      exceptions: globalThis.Array.isArray(object?.exceptions)
        ? object.exceptions.map((e: any) => DependencyException.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LicensePolicy): unknown {
    const obj: any = {};
    if (message.settings) {
      const entries = Object.entries(message.settings);
      if (entries.length > 0) {
        obj.settings = {};
        entries.forEach(([k, v]) => {
          obj.settings[k] = licensePolicySettingToJSON(v);
        });
      }
    }
    if (message.exceptions?.length) {
      obj.exceptions = message.exceptions.map((e) => DependencyException.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LicensePolicy>, I>>(base?: I): LicensePolicy {
    return LicensePolicy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LicensePolicy>, I>>(object: I): LicensePolicy {
    const message = createBaseLicensePolicy();
    message.settings = Object.entries(object.settings ?? {}).reduce<{ [key: string]: LicensePolicySetting }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value as LicensePolicySetting;
        }
        return acc;
      },
      {},
    );
    message.exceptions = object.exceptions?.map((e) => DependencyException.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLicensePolicy_SettingsEntry(): LicensePolicy_SettingsEntry {
  return { key: "", value: LicensePolicySetting.UNSPECIFIED };
}

export const LicensePolicy_SettingsEntry = {
  encode(message: LicensePolicy_SettingsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== LicensePolicySetting.UNSPECIFIED) {
      writer.uint32(16).int32(licensePolicySettingToNumber(message.value));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LicensePolicy_SettingsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLicensePolicy_SettingsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = licensePolicySettingFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LicensePolicy_SettingsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? licensePolicySettingFromJSON(object.value) : LicensePolicySetting.UNSPECIFIED,
    };
  },

  toJSON(message: LicensePolicy_SettingsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== LicensePolicySetting.UNSPECIFIED) {
      obj.value = licensePolicySettingToJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LicensePolicy_SettingsEntry>, I>>(base?: I): LicensePolicy_SettingsEntry {
    return LicensePolicy_SettingsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LicensePolicy_SettingsEntry>, I>>(object: I): LicensePolicy_SettingsEntry {
    const message = createBaseLicensePolicy_SettingsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? LicensePolicySetting.UNSPECIFIED;
    return message;
  },
};

function createBaseGetLicensePolicyRequest(): GetLicensePolicyRequest {
  return { deploymentId: 0 };
}

export const GetLicensePolicyRequest = {
  encode(message: GetLicensePolicyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLicensePolicyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLicensePolicyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLicensePolicyRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0 };
  },

  toJSON(message: GetLicensePolicyRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLicensePolicyRequest>, I>>(base?: I): GetLicensePolicyRequest {
    return GetLicensePolicyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLicensePolicyRequest>, I>>(object: I): GetLicensePolicyRequest {
    const message = createBaseGetLicensePolicyRequest();
    message.deploymentId = object.deploymentId ?? 0;
    return message;
  },
};

function createBaseGetLicensePolicyResponse(): GetLicensePolicyResponse {
  return { policy: undefined };
}

export const GetLicensePolicyResponse = {
  encode(message: GetLicensePolicyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.policy !== undefined) {
      LicensePolicy.encode(message.policy, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLicensePolicyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLicensePolicyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.policy = LicensePolicy.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLicensePolicyResponse {
    return { policy: isSet(object.policy) ? LicensePolicy.fromJSON(object.policy) : undefined };
  },

  toJSON(message: GetLicensePolicyResponse): unknown {
    const obj: any = {};
    if (message.policy !== undefined) {
      obj.policy = LicensePolicy.toJSON(message.policy);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLicensePolicyResponse>, I>>(base?: I): GetLicensePolicyResponse {
    return GetLicensePolicyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLicensePolicyResponse>, I>>(object: I): GetLicensePolicyResponse {
    const message = createBaseGetLicensePolicyResponse();
    message.policy = (object.policy !== undefined && object.policy !== null)
      ? LicensePolicy.fromPartial(object.policy)
      : undefined;
    return message;
  },
};

function createBasePostLicensePolicyRequest(): PostLicensePolicyRequest {
  return { policy: undefined };
}

export const PostLicensePolicyRequest = {
  encode(message: PostLicensePolicyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.policy !== undefined) {
      LicensePolicy.encode(message.policy, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostLicensePolicyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostLicensePolicyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.policy = LicensePolicy.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostLicensePolicyRequest {
    return { policy: isSet(object.policy) ? LicensePolicy.fromJSON(object.policy) : undefined };
  },

  toJSON(message: PostLicensePolicyRequest): unknown {
    const obj: any = {};
    if (message.policy !== undefined) {
      obj.policy = LicensePolicy.toJSON(message.policy);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PostLicensePolicyRequest>, I>>(base?: I): PostLicensePolicyRequest {
    return PostLicensePolicyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostLicensePolicyRequest>, I>>(object: I): PostLicensePolicyRequest {
    const message = createBasePostLicensePolicyRequest();
    message.policy = (object.policy !== undefined && object.policy !== null)
      ? LicensePolicy.fromPartial(object.policy)
      : undefined;
    return message;
  },
};

function createBasePostLicensePolicyResponse(): PostLicensePolicyResponse {
  return { policy: undefined };
}

export const PostLicensePolicyResponse = {
  encode(message: PostLicensePolicyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.policy !== undefined) {
      LicensePolicy.encode(message.policy, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostLicensePolicyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostLicensePolicyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.policy = LicensePolicy.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostLicensePolicyResponse {
    return { policy: isSet(object.policy) ? LicensePolicy.fromJSON(object.policy) : undefined };
  },

  toJSON(message: PostLicensePolicyResponse): unknown {
    const obj: any = {};
    if (message.policy !== undefined) {
      obj.policy = LicensePolicy.toJSON(message.policy);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PostLicensePolicyResponse>, I>>(base?: I): PostLicensePolicyResponse {
    return PostLicensePolicyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostLicensePolicyResponse>, I>>(object: I): PostLicensePolicyResponse {
    const message = createBasePostLicensePolicyResponse();
    message.policy = (object.policy !== undefined && object.policy !== null)
      ? LicensePolicy.fromPartial(object.policy)
      : undefined;
    return message;
  },
};

function createBasePostHasDependencyQueryRequest(): PostHasDependencyQueryRequest {
  return { hasDependencyQuery: false };
}

export const PostHasDependencyQueryRequest = {
  encode(message: PostHasDependencyQueryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.hasDependencyQuery !== false) {
      writer.uint32(8).bool(message.hasDependencyQuery);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostHasDependencyQueryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostHasDependencyQueryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.hasDependencyQuery = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostHasDependencyQueryRequest {
    return {
      hasDependencyQuery: isSet(object.hasDependencyQuery) ? globalThis.Boolean(object.hasDependencyQuery) : false,
    };
  },

  toJSON(message: PostHasDependencyQueryRequest): unknown {
    const obj: any = {};
    if (message.hasDependencyQuery !== false) {
      obj.hasDependencyQuery = message.hasDependencyQuery;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PostHasDependencyQueryRequest>, I>>(base?: I): PostHasDependencyQueryRequest {
    return PostHasDependencyQueryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostHasDependencyQueryRequest>, I>>(
    object: I,
  ): PostHasDependencyQueryRequest {
    const message = createBasePostHasDependencyQueryRequest();
    message.hasDependencyQuery = object.hasDependencyQuery ?? false;
    return message;
  },
};

function createBasePostHasDependencyQueryResponse(): PostHasDependencyQueryResponse {
  return { hasDependencyQuery: false };
}

export const PostHasDependencyQueryResponse = {
  encode(message: PostHasDependencyQueryResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.hasDependencyQuery !== false) {
      writer.uint32(8).bool(message.hasDependencyQuery);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostHasDependencyQueryResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostHasDependencyQueryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.hasDependencyQuery = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostHasDependencyQueryResponse {
    return {
      hasDependencyQuery: isSet(object.hasDependencyQuery) ? globalThis.Boolean(object.hasDependencyQuery) : false,
    };
  },

  toJSON(message: PostHasDependencyQueryResponse): unknown {
    const obj: any = {};
    if (message.hasDependencyQuery !== false) {
      obj.hasDependencyQuery = message.hasDependencyQuery;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PostHasDependencyQueryResponse>, I>>(base?: I): PostHasDependencyQueryResponse {
    return PostHasDependencyQueryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostHasDependencyQueryResponse>, I>>(
    object: I,
  ): PostHasDependencyQueryResponse {
    const message = createBasePostHasDependencyQueryResponse();
    message.hasDependencyQuery = object.hasDependencyQuery ?? false;
    return message;
  },
};

function createBasePutHasSCAPRBlockingRequest(): PutHasSCAPRBlockingRequest {
  return { hasScaPrBlocking: false };
}

export const PutHasSCAPRBlockingRequest = {
  encode(message: PutHasSCAPRBlockingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.hasScaPrBlocking !== false) {
      writer.uint32(8).bool(message.hasScaPrBlocking);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PutHasSCAPRBlockingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePutHasSCAPRBlockingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.hasScaPrBlocking = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PutHasSCAPRBlockingRequest {
    return { hasScaPrBlocking: isSet(object.hasScaPrBlocking) ? globalThis.Boolean(object.hasScaPrBlocking) : false };
  },

  toJSON(message: PutHasSCAPRBlockingRequest): unknown {
    const obj: any = {};
    if (message.hasScaPrBlocking !== false) {
      obj.hasScaPrBlocking = message.hasScaPrBlocking;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PutHasSCAPRBlockingRequest>, I>>(base?: I): PutHasSCAPRBlockingRequest {
    return PutHasSCAPRBlockingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PutHasSCAPRBlockingRequest>, I>>(object: I): PutHasSCAPRBlockingRequest {
    const message = createBasePutHasSCAPRBlockingRequest();
    message.hasScaPrBlocking = object.hasScaPrBlocking ?? false;
    return message;
  },
};

function createBasePutHasSCAPRBlockingResponse(): PutHasSCAPRBlockingResponse {
  return { hasScaPrBlocking: false };
}

export const PutHasSCAPRBlockingResponse = {
  encode(message: PutHasSCAPRBlockingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.hasScaPrBlocking !== false) {
      writer.uint32(8).bool(message.hasScaPrBlocking);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PutHasSCAPRBlockingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePutHasSCAPRBlockingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.hasScaPrBlocking = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PutHasSCAPRBlockingResponse {
    return { hasScaPrBlocking: isSet(object.hasScaPrBlocking) ? globalThis.Boolean(object.hasScaPrBlocking) : false };
  },

  toJSON(message: PutHasSCAPRBlockingResponse): unknown {
    const obj: any = {};
    if (message.hasScaPrBlocking !== false) {
      obj.hasScaPrBlocking = message.hasScaPrBlocking;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PutHasSCAPRBlockingResponse>, I>>(base?: I): PutHasSCAPRBlockingResponse {
    return PutHasSCAPRBlockingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PutHasSCAPRBlockingResponse>, I>>(object: I): PutHasSCAPRBlockingResponse {
    const message = createBasePutHasSCAPRBlockingResponse();
    message.hasScaPrBlocking = object.hasScaPrBlocking ?? false;
    return message;
  },
};

function createBaseLicensePolicyFinding(): LicensePolicyFinding {
  return { dependency: undefined, triggeredByLicense: "", setting: LicensePolicySetting.UNSPECIFIED, scanId: 0 };
}

export const LicensePolicyFinding = {
  encode(message: LicensePolicyFinding, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dependency !== undefined) {
      FoundDependency.encode(message.dependency, writer.uint32(10).fork()).ldelim();
    }
    if (message.triggeredByLicense !== "") {
      writer.uint32(18).string(message.triggeredByLicense);
    }
    if (message.setting !== LicensePolicySetting.UNSPECIFIED) {
      writer.uint32(24).int32(licensePolicySettingToNumber(message.setting));
    }
    if (message.scanId !== 0) {
      writer.uint32(32).uint64(message.scanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LicensePolicyFinding {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLicensePolicyFinding();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dependency = FoundDependency.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.triggeredByLicense = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.setting = licensePolicySettingFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.scanId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LicensePolicyFinding {
    return {
      dependency: isSet(object.dependency) ? FoundDependency.fromJSON(object.dependency) : undefined,
      triggeredByLicense: isSet(object.triggeredByLicense) ? globalThis.String(object.triggeredByLicense) : "",
      setting: isSet(object.setting) ? licensePolicySettingFromJSON(object.setting) : LicensePolicySetting.UNSPECIFIED,
      scanId: isSet(object.scanId) ? globalThis.Number(object.scanId) : 0,
    };
  },

  toJSON(message: LicensePolicyFinding): unknown {
    const obj: any = {};
    if (message.dependency !== undefined) {
      obj.dependency = FoundDependency.toJSON(message.dependency);
    }
    if (message.triggeredByLicense !== "") {
      obj.triggeredByLicense = message.triggeredByLicense;
    }
    if (message.setting !== LicensePolicySetting.UNSPECIFIED) {
      obj.setting = licensePolicySettingToJSON(message.setting);
    }
    if (message.scanId !== 0) {
      obj.scanId = Math.round(message.scanId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LicensePolicyFinding>, I>>(base?: I): LicensePolicyFinding {
    return LicensePolicyFinding.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LicensePolicyFinding>, I>>(object: I): LicensePolicyFinding {
    const message = createBaseLicensePolicyFinding();
    message.dependency = (object.dependency !== undefined && object.dependency !== null)
      ? FoundDependency.fromPartial(object.dependency)
      : undefined;
    message.triggeredByLicense = object.triggeredByLicense ?? "";
    message.setting = object.setting ?? LicensePolicySetting.UNSPECIFIED;
    message.scanId = object.scanId ?? 0;
    return message;
  },
};

function createBaseReachable(): Reachable {
  return {
    low: DevSemgrepAction.UNSPECIFIED,
    medium: DevSemgrepAction.UNSPECIFIED,
    high: DevSemgrepAction.UNSPECIFIED,
    critical: DevSemgrepAction.UNSPECIFIED,
  };
}

export const Reachable = {
  encode(message: Reachable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.low !== DevSemgrepAction.UNSPECIFIED) {
      writer.uint32(8).int32(devSemgrepActionToNumber(message.low));
    }
    if (message.medium !== DevSemgrepAction.UNSPECIFIED) {
      writer.uint32(16).int32(devSemgrepActionToNumber(message.medium));
    }
    if (message.high !== DevSemgrepAction.UNSPECIFIED) {
      writer.uint32(24).int32(devSemgrepActionToNumber(message.high));
    }
    if (message.critical !== DevSemgrepAction.UNSPECIFIED) {
      writer.uint32(32).int32(devSemgrepActionToNumber(message.critical));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Reachable {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReachable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.low = devSemgrepActionFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.medium = devSemgrepActionFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.high = devSemgrepActionFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.critical = devSemgrepActionFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Reachable {
    return {
      low: isSet(object.low) ? devSemgrepActionFromJSON(object.low) : DevSemgrepAction.UNSPECIFIED,
      medium: isSet(object.medium) ? devSemgrepActionFromJSON(object.medium) : DevSemgrepAction.UNSPECIFIED,
      high: isSet(object.high) ? devSemgrepActionFromJSON(object.high) : DevSemgrepAction.UNSPECIFIED,
      critical: isSet(object.critical) ? devSemgrepActionFromJSON(object.critical) : DevSemgrepAction.UNSPECIFIED,
    };
  },

  toJSON(message: Reachable): unknown {
    const obj: any = {};
    if (message.low !== DevSemgrepAction.UNSPECIFIED) {
      obj.low = devSemgrepActionToJSON(message.low);
    }
    if (message.medium !== DevSemgrepAction.UNSPECIFIED) {
      obj.medium = devSemgrepActionToJSON(message.medium);
    }
    if (message.high !== DevSemgrepAction.UNSPECIFIED) {
      obj.high = devSemgrepActionToJSON(message.high);
    }
    if (message.critical !== DevSemgrepAction.UNSPECIFIED) {
      obj.critical = devSemgrepActionToJSON(message.critical);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Reachable>, I>>(base?: I): Reachable {
    return Reachable.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Reachable>, I>>(object: I): Reachable {
    const message = createBaseReachable();
    message.low = object.low ?? DevSemgrepAction.UNSPECIFIED;
    message.medium = object.medium ?? DevSemgrepAction.UNSPECIFIED;
    message.high = object.high ?? DevSemgrepAction.UNSPECIFIED;
    message.critical = object.critical ?? DevSemgrepAction.UNSPECIFIED;
    return message;
  },
};

function createBaseAlwaysUpgrade(): AlwaysUpgrade {
  return {
    low: DevSemgrepAction.UNSPECIFIED,
    medium: DevSemgrepAction.UNSPECIFIED,
    high: DevSemgrepAction.UNSPECIFIED,
    critical: DevSemgrepAction.UNSPECIFIED,
  };
}

export const AlwaysUpgrade = {
  encode(message: AlwaysUpgrade, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.low !== DevSemgrepAction.UNSPECIFIED) {
      writer.uint32(8).int32(devSemgrepActionToNumber(message.low));
    }
    if (message.medium !== DevSemgrepAction.UNSPECIFIED) {
      writer.uint32(16).int32(devSemgrepActionToNumber(message.medium));
    }
    if (message.high !== DevSemgrepAction.UNSPECIFIED) {
      writer.uint32(24).int32(devSemgrepActionToNumber(message.high));
    }
    if (message.critical !== DevSemgrepAction.UNSPECIFIED) {
      writer.uint32(32).int32(devSemgrepActionToNumber(message.critical));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AlwaysUpgrade {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlwaysUpgrade();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.low = devSemgrepActionFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.medium = devSemgrepActionFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.high = devSemgrepActionFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.critical = devSemgrepActionFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AlwaysUpgrade {
    return {
      low: isSet(object.low) ? devSemgrepActionFromJSON(object.low) : DevSemgrepAction.UNSPECIFIED,
      medium: isSet(object.medium) ? devSemgrepActionFromJSON(object.medium) : DevSemgrepAction.UNSPECIFIED,
      high: isSet(object.high) ? devSemgrepActionFromJSON(object.high) : DevSemgrepAction.UNSPECIFIED,
      critical: isSet(object.critical) ? devSemgrepActionFromJSON(object.critical) : DevSemgrepAction.UNSPECIFIED,
    };
  },

  toJSON(message: AlwaysUpgrade): unknown {
    const obj: any = {};
    if (message.low !== DevSemgrepAction.UNSPECIFIED) {
      obj.low = devSemgrepActionToJSON(message.low);
    }
    if (message.medium !== DevSemgrepAction.UNSPECIFIED) {
      obj.medium = devSemgrepActionToJSON(message.medium);
    }
    if (message.high !== DevSemgrepAction.UNSPECIFIED) {
      obj.high = devSemgrepActionToJSON(message.high);
    }
    if (message.critical !== DevSemgrepAction.UNSPECIFIED) {
      obj.critical = devSemgrepActionToJSON(message.critical);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlwaysUpgrade>, I>>(base?: I): AlwaysUpgrade {
    return AlwaysUpgrade.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AlwaysUpgrade>, I>>(object: I): AlwaysUpgrade {
    const message = createBaseAlwaysUpgrade();
    message.low = object.low ?? DevSemgrepAction.UNSPECIFIED;
    message.medium = object.medium ?? DevSemgrepAction.UNSPECIFIED;
    message.high = object.high ?? DevSemgrepAction.UNSPECIFIED;
    message.critical = object.critical ?? DevSemgrepAction.UNSPECIFIED;
    return message;
  },
};

function createBaseManualReview(): ManualReview {
  return {
    low: DevSemgrepAction.UNSPECIFIED,
    medium: DevSemgrepAction.UNSPECIFIED,
    high: DevSemgrepAction.UNSPECIFIED,
    critical: DevSemgrepAction.UNSPECIFIED,
  };
}

export const ManualReview = {
  encode(message: ManualReview, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.low !== DevSemgrepAction.UNSPECIFIED) {
      writer.uint32(8).int32(devSemgrepActionToNumber(message.low));
    }
    if (message.medium !== DevSemgrepAction.UNSPECIFIED) {
      writer.uint32(16).int32(devSemgrepActionToNumber(message.medium));
    }
    if (message.high !== DevSemgrepAction.UNSPECIFIED) {
      writer.uint32(24).int32(devSemgrepActionToNumber(message.high));
    }
    if (message.critical !== DevSemgrepAction.UNSPECIFIED) {
      writer.uint32(32).int32(devSemgrepActionToNumber(message.critical));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManualReview {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManualReview();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.low = devSemgrepActionFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.medium = devSemgrepActionFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.high = devSemgrepActionFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.critical = devSemgrepActionFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManualReview {
    return {
      low: isSet(object.low) ? devSemgrepActionFromJSON(object.low) : DevSemgrepAction.UNSPECIFIED,
      medium: isSet(object.medium) ? devSemgrepActionFromJSON(object.medium) : DevSemgrepAction.UNSPECIFIED,
      high: isSet(object.high) ? devSemgrepActionFromJSON(object.high) : DevSemgrepAction.UNSPECIFIED,
      critical: isSet(object.critical) ? devSemgrepActionFromJSON(object.critical) : DevSemgrepAction.UNSPECIFIED,
    };
  },

  toJSON(message: ManualReview): unknown {
    const obj: any = {};
    if (message.low !== DevSemgrepAction.UNSPECIFIED) {
      obj.low = devSemgrepActionToJSON(message.low);
    }
    if (message.medium !== DevSemgrepAction.UNSPECIFIED) {
      obj.medium = devSemgrepActionToJSON(message.medium);
    }
    if (message.high !== DevSemgrepAction.UNSPECIFIED) {
      obj.high = devSemgrepActionToJSON(message.high);
    }
    if (message.critical !== DevSemgrepAction.UNSPECIFIED) {
      obj.critical = devSemgrepActionToJSON(message.critical);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManualReview>, I>>(base?: I): ManualReview {
    return ManualReview.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManualReview>, I>>(object: I): ManualReview {
    const message = createBaseManualReview();
    message.low = object.low ?? DevSemgrepAction.UNSPECIFIED;
    message.medium = object.medium ?? DevSemgrepAction.UNSPECIFIED;
    message.high = object.high ?? DevSemgrepAction.UNSPECIFIED;
    message.critical = object.critical ?? DevSemgrepAction.UNSPECIFIED;
    return message;
  },
};

function createBaseSCAPRBlockingConfig(): SCAPRBlockingConfig {
  return { reachable: undefined, alwaysUpgrade: undefined, manualReview: undefined };
}

export const SCAPRBlockingConfig = {
  encode(message: SCAPRBlockingConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.reachable !== undefined) {
      Reachable.encode(message.reachable, writer.uint32(10).fork()).ldelim();
    }
    if (message.alwaysUpgrade !== undefined) {
      AlwaysUpgrade.encode(message.alwaysUpgrade, writer.uint32(18).fork()).ldelim();
    }
    if (message.manualReview !== undefined) {
      ManualReview.encode(message.manualReview, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SCAPRBlockingConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSCAPRBlockingConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.reachable = Reachable.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alwaysUpgrade = AlwaysUpgrade.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.manualReview = ManualReview.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SCAPRBlockingConfig {
    return {
      reachable: isSet(object.reachable) ? Reachable.fromJSON(object.reachable) : undefined,
      alwaysUpgrade: isSet(object.alwaysUpgrade) ? AlwaysUpgrade.fromJSON(object.alwaysUpgrade) : undefined,
      manualReview: isSet(object.manualReview) ? ManualReview.fromJSON(object.manualReview) : undefined,
    };
  },

  toJSON(message: SCAPRBlockingConfig): unknown {
    const obj: any = {};
    if (message.reachable !== undefined) {
      obj.reachable = Reachable.toJSON(message.reachable);
    }
    if (message.alwaysUpgrade !== undefined) {
      obj.alwaysUpgrade = AlwaysUpgrade.toJSON(message.alwaysUpgrade);
    }
    if (message.manualReview !== undefined) {
      obj.manualReview = ManualReview.toJSON(message.manualReview);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SCAPRBlockingConfig>, I>>(base?: I): SCAPRBlockingConfig {
    return SCAPRBlockingConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SCAPRBlockingConfig>, I>>(object: I): SCAPRBlockingConfig {
    const message = createBaseSCAPRBlockingConfig();
    message.reachable = (object.reachable !== undefined && object.reachable !== null)
      ? Reachable.fromPartial(object.reachable)
      : undefined;
    message.alwaysUpgrade = (object.alwaysUpgrade !== undefined && object.alwaysUpgrade !== null)
      ? AlwaysUpgrade.fromPartial(object.alwaysUpgrade)
      : undefined;
    message.manualReview = (object.manualReview !== undefined && object.manualReview !== null)
      ? ManualReview.fromPartial(object.manualReview)
      : undefined;
    return message;
  },
};

function createBaseSbomFormatVersion(): SbomFormatVersion {
  return { format: SbomFormat.UNSPECIFIED, version: undefined };
}

export const SbomFormatVersion = {
  encode(message: SbomFormatVersion, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.format !== SbomFormat.UNSPECIFIED) {
      writer.uint32(8).int32(sbomFormatToNumber(message.format));
    }
    if (message.version !== undefined) {
      writer.uint32(18).string(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SbomFormatVersion {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSbomFormatVersion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.format = sbomFormatFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.version = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SbomFormatVersion {
    return {
      format: isSet(object.format) ? sbomFormatFromJSON(object.format) : SbomFormat.UNSPECIFIED,
      version: isSet(object.version) ? globalThis.String(object.version) : undefined,
    };
  },

  toJSON(message: SbomFormatVersion): unknown {
    const obj: any = {};
    if (message.format !== SbomFormat.UNSPECIFIED) {
      obj.format = sbomFormatToJSON(message.format);
    }
    if (message.version !== undefined) {
      obj.version = message.version;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SbomFormatVersion>, I>>(base?: I): SbomFormatVersion {
    return SbomFormatVersion.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SbomFormatVersion>, I>>(object: I): SbomFormatVersion {
    const message = createBaseSbomFormatVersion();
    message.format = object.format ?? SbomFormat.UNSPECIFIED;
    message.version = object.version ?? undefined;
    return message;
  },
};

function createBaseSbomMetadataContact(): SbomMetadataContact {
  return { name: undefined, email: undefined, phone: undefined };
}

export const SbomMetadataContact = {
  encode(message: SbomMetadataContact, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.email !== undefined) {
      writer.uint32(18).string(message.email);
    }
    if (message.phone !== undefined) {
      writer.uint32(26).string(message.phone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SbomMetadataContact {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSbomMetadataContact();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.phone = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SbomMetadataContact {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
      email: isSet(object.email) ? globalThis.String(object.email) : undefined,
      phone: isSet(object.phone) ? globalThis.String(object.phone) : undefined,
    };
  },

  toJSON(message: SbomMetadataContact): unknown {
    const obj: any = {};
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.email !== undefined) {
      obj.email = message.email;
    }
    if (message.phone !== undefined) {
      obj.phone = message.phone;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SbomMetadataContact>, I>>(base?: I): SbomMetadataContact {
    return SbomMetadataContact.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SbomMetadataContact>, I>>(object: I): SbomMetadataContact {
    const message = createBaseSbomMetadataContact();
    message.name = object.name ?? undefined;
    message.email = object.email ?? undefined;
    message.phone = object.phone ?? undefined;
    return message;
  },
};

function createBaseSbomMetadataSupplier(): SbomMetadataSupplier {
  return { name: undefined, url: undefined, contact: undefined };
}

export const SbomMetadataSupplier = {
  encode(message: SbomMetadataSupplier, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.url !== undefined) {
      writer.uint32(18).string(message.url);
    }
    if (message.contact !== undefined) {
      SbomMetadataContact.encode(message.contact, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SbomMetadataSupplier {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSbomMetadataSupplier();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.contact = SbomMetadataContact.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SbomMetadataSupplier {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
      url: isSet(object.url) ? globalThis.String(object.url) : undefined,
      contact: isSet(object.contact) ? SbomMetadataContact.fromJSON(object.contact) : undefined,
    };
  },

  toJSON(message: SbomMetadataSupplier): unknown {
    const obj: any = {};
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.url !== undefined) {
      obj.url = message.url;
    }
    if (message.contact !== undefined) {
      obj.contact = SbomMetadataContact.toJSON(message.contact);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SbomMetadataSupplier>, I>>(base?: I): SbomMetadataSupplier {
    return SbomMetadataSupplier.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SbomMetadataSupplier>, I>>(object: I): SbomMetadataSupplier {
    const message = createBaseSbomMetadataSupplier();
    message.name = object.name ?? undefined;
    message.url = object.url ?? undefined;
    message.contact = (object.contact !== undefined && object.contact !== null)
      ? SbomMetadataContact.fromPartial(object.contact)
      : undefined;
    return message;
  },
};

function createBaseGenerateSbomRequest(): GenerateSbomRequest {
  return {
    repositoryId: 0,
    ref: undefined,
    formatVersion: undefined,
    sbomOutputFormat: SbomOutputFormat.UNSPECIFIED,
    metadataComponentType: undefined,
    metadataSupplier: undefined,
  };
}

export const GenerateSbomRequest = {
  encode(message: GenerateSbomRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.repositoryId !== 0) {
      writer.uint32(8).int64(message.repositoryId);
    }
    if (message.ref !== undefined) {
      writer.uint32(18).string(message.ref);
    }
    if (message.formatVersion !== undefined) {
      SbomFormatVersion.encode(message.formatVersion, writer.uint32(26).fork()).ldelim();
    }
    if (message.sbomOutputFormat !== SbomOutputFormat.UNSPECIFIED) {
      writer.uint32(32).int32(sbomOutputFormatToNumber(message.sbomOutputFormat));
    }
    if (message.metadataComponentType !== undefined) {
      writer.uint32(40).int32(sbomMetadataComponentTypeCycloneDxV15ToNumber(message.metadataComponentType));
    }
    if (message.metadataSupplier !== undefined) {
      SbomMetadataSupplier.encode(message.metadataSupplier, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateSbomRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateSbomRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.repositoryId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ref = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.formatVersion = SbomFormatVersion.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sbomOutputFormat = sbomOutputFormatFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.metadataComponentType = sbomMetadataComponentTypeCycloneDxV15FromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.metadataSupplier = SbomMetadataSupplier.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateSbomRequest {
    return {
      repositoryId: isSet(object.repositoryId) ? globalThis.Number(object.repositoryId) : 0,
      ref: isSet(object.ref) ? globalThis.String(object.ref) : undefined,
      formatVersion: isSet(object.formatVersion) ? SbomFormatVersion.fromJSON(object.formatVersion) : undefined,
      sbomOutputFormat: isSet(object.sbomOutputFormat)
        ? sbomOutputFormatFromJSON(object.sbomOutputFormat)
        : SbomOutputFormat.UNSPECIFIED,
      metadataComponentType: isSet(object.metadataComponentType)
        ? sbomMetadataComponentTypeCycloneDxV15FromJSON(object.metadataComponentType)
        : undefined,
      metadataSupplier: isSet(object.metadataSupplier)
        ? SbomMetadataSupplier.fromJSON(object.metadataSupplier)
        : undefined,
    };
  },

  toJSON(message: GenerateSbomRequest): unknown {
    const obj: any = {};
    if (message.repositoryId !== 0) {
      obj.repositoryId = Math.round(message.repositoryId);
    }
    if (message.ref !== undefined) {
      obj.ref = message.ref;
    }
    if (message.formatVersion !== undefined) {
      obj.formatVersion = SbomFormatVersion.toJSON(message.formatVersion);
    }
    if (message.sbomOutputFormat !== SbomOutputFormat.UNSPECIFIED) {
      obj.sbomOutputFormat = sbomOutputFormatToJSON(message.sbomOutputFormat);
    }
    if (message.metadataComponentType !== undefined) {
      obj.metadataComponentType = sbomMetadataComponentTypeCycloneDxV15ToJSON(message.metadataComponentType);
    }
    if (message.metadataSupplier !== undefined) {
      obj.metadataSupplier = SbomMetadataSupplier.toJSON(message.metadataSupplier);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateSbomRequest>, I>>(base?: I): GenerateSbomRequest {
    return GenerateSbomRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateSbomRequest>, I>>(object: I): GenerateSbomRequest {
    const message = createBaseGenerateSbomRequest();
    message.repositoryId = object.repositoryId ?? 0;
    message.ref = object.ref ?? undefined;
    message.formatVersion = (object.formatVersion !== undefined && object.formatVersion !== null)
      ? SbomFormatVersion.fromPartial(object.formatVersion)
      : undefined;
    message.sbomOutputFormat = object.sbomOutputFormat ?? SbomOutputFormat.UNSPECIFIED;
    message.metadataComponentType = object.metadataComponentType ?? undefined;
    message.metadataSupplier = (object.metadataSupplier !== undefined && object.metadataSupplier !== null)
      ? SbomMetadataSupplier.fromPartial(object.metadataSupplier)
      : undefined;
    return message;
  },
};

function createBasePostDiffScanDependencyDataRequest(): PostDiffScanDependencyDataRequest {
  return { repositoryId: 0, ref: "", prIdentifier: undefined, commitSha: undefined, licensePolicyThreshold: undefined };
}

export const PostDiffScanDependencyDataRequest = {
  encode(message: PostDiffScanDependencyDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.repositoryId !== 0) {
      writer.uint32(16).int64(message.repositoryId);
    }
    if (message.ref !== "") {
      writer.uint32(26).string(message.ref);
    }
    if (message.prIdentifier !== undefined) {
      writer.uint32(34).string(message.prIdentifier);
    }
    if (message.commitSha !== undefined) {
      writer.uint32(42).string(message.commitSha);
    }
    if (message.licensePolicyThreshold !== undefined) {
      writer.uint32(48).int32(licensePolicySettingToNumber(message.licensePolicyThreshold));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostDiffScanDependencyDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostDiffScanDependencyDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.repositoryId = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ref = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.prIdentifier = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.commitSha = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.licensePolicyThreshold = licensePolicySettingFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostDiffScanDependencyDataRequest {
    return {
      repositoryId: isSet(object.repositoryId) ? globalThis.Number(object.repositoryId) : 0,
      ref: isSet(object.ref) ? globalThis.String(object.ref) : "",
      prIdentifier: isSet(object.prIdentifier) ? globalThis.String(object.prIdentifier) : undefined,
      commitSha: isSet(object.commitSha) ? globalThis.String(object.commitSha) : undefined,
      licensePolicyThreshold: isSet(object.licensePolicyThreshold)
        ? licensePolicySettingFromJSON(object.licensePolicyThreshold)
        : undefined,
    };
  },

  toJSON(message: PostDiffScanDependencyDataRequest): unknown {
    const obj: any = {};
    if (message.repositoryId !== 0) {
      obj.repositoryId = Math.round(message.repositoryId);
    }
    if (message.ref !== "") {
      obj.ref = message.ref;
    }
    if (message.prIdentifier !== undefined) {
      obj.prIdentifier = message.prIdentifier;
    }
    if (message.commitSha !== undefined) {
      obj.commitSha = message.commitSha;
    }
    if (message.licensePolicyThreshold !== undefined) {
      obj.licensePolicyThreshold = licensePolicySettingToJSON(message.licensePolicyThreshold);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PostDiffScanDependencyDataRequest>, I>>(
    base?: I,
  ): PostDiffScanDependencyDataRequest {
    return PostDiffScanDependencyDataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostDiffScanDependencyDataRequest>, I>>(
    object: I,
  ): PostDiffScanDependencyDataRequest {
    const message = createBasePostDiffScanDependencyDataRequest();
    message.repositoryId = object.repositoryId ?? 0;
    message.ref = object.ref ?? "";
    message.prIdentifier = object.prIdentifier ?? undefined;
    message.commitSha = object.commitSha ?? undefined;
    message.licensePolicyThreshold = object.licensePolicyThreshold ?? undefined;
    return message;
  },
};

export interface SupplyChainService {
  listVulns(request: ListVulnsRequest): Promise<ListVulnsResponse>;
  listVulns2(request: ListVulns2Request): Promise<ListVulns2Response>;
  putVulnTriage(request: PutVulnTriageRequest): Promise<PutVulnTriageResponse>;
  getLicensePolicy(request: GetLicensePolicyRequest): Promise<GetLicensePolicyResponse>;
  listDependencies(request: ListDependenciesRequest): Promise<ListDependenciesResponse>;
  listLockfilesForDependencies(
    request: ListLockfilesForDependenciesRequest,
  ): Promise<ListLockfilesForDependenciesResponse>;
  listRepositoriesForDependencies(
    request: ListRepositoriesForDependenciesRequest,
  ): Promise<ListRepositoriesForDependenciesResponse>;
  listAdvisories(request: ListAdvisoriesRequest): Promise<ListAdvisoriesResponse>;
  listAdvisoriesV2(request: ListAdvisoriesV2Request): Promise<ListAdvisoriesV2Response>;
  postAdvisoryFeedback(request: PostAdvisoryFeedbackRequest): Promise<PostAdvisoryFeedbackResponse>;
}

export const SupplyChainServiceServiceName = "protos.sca.v1.SupplyChainService";
export class SupplyChainServiceClientImpl implements SupplyChainService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || SupplyChainServiceServiceName;
    this.rpc = rpc;
    this.listVulns = this.listVulns.bind(this);
    this.listVulns2 = this.listVulns2.bind(this);
    this.putVulnTriage = this.putVulnTriage.bind(this);
    this.getLicensePolicy = this.getLicensePolicy.bind(this);
    this.listDependencies = this.listDependencies.bind(this);
    this.listLockfilesForDependencies = this.listLockfilesForDependencies.bind(this);
    this.listRepositoriesForDependencies = this.listRepositoriesForDependencies.bind(this);
    this.listAdvisories = this.listAdvisories.bind(this);
    this.listAdvisoriesV2 = this.listAdvisoriesV2.bind(this);
    this.postAdvisoryFeedback = this.postAdvisoryFeedback.bind(this);
  }
  listVulns(request: ListVulnsRequest): Promise<ListVulnsResponse> {
    const data = ListVulnsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListVulns", data);
    return promise.then((data) => ListVulnsResponse.decode(_m0.Reader.create(data)));
  }

  listVulns2(request: ListVulns2Request): Promise<ListVulns2Response> {
    const data = ListVulns2Request.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListVulns2", data);
    return promise.then((data) => ListVulns2Response.decode(_m0.Reader.create(data)));
  }

  putVulnTriage(request: PutVulnTriageRequest): Promise<PutVulnTriageResponse> {
    const data = PutVulnTriageRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "PutVulnTriage", data);
    return promise.then((data) => PutVulnTriageResponse.decode(_m0.Reader.create(data)));
  }

  getLicensePolicy(request: GetLicensePolicyRequest): Promise<GetLicensePolicyResponse> {
    const data = GetLicensePolicyRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetLicensePolicy", data);
    return promise.then((data) => GetLicensePolicyResponse.decode(_m0.Reader.create(data)));
  }

  listDependencies(request: ListDependenciesRequest): Promise<ListDependenciesResponse> {
    const data = ListDependenciesRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListDependencies", data);
    return promise.then((data) => ListDependenciesResponse.decode(_m0.Reader.create(data)));
  }

  listLockfilesForDependencies(
    request: ListLockfilesForDependenciesRequest,
  ): Promise<ListLockfilesForDependenciesResponse> {
    const data = ListLockfilesForDependenciesRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListLockfilesForDependencies", data);
    return promise.then((data) => ListLockfilesForDependenciesResponse.decode(_m0.Reader.create(data)));
  }

  listRepositoriesForDependencies(
    request: ListRepositoriesForDependenciesRequest,
  ): Promise<ListRepositoriesForDependenciesResponse> {
    const data = ListRepositoriesForDependenciesRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListRepositoriesForDependencies", data);
    return promise.then((data) => ListRepositoriesForDependenciesResponse.decode(_m0.Reader.create(data)));
  }

  listAdvisories(request: ListAdvisoriesRequest): Promise<ListAdvisoriesResponse> {
    const data = ListAdvisoriesRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListAdvisories", data);
    return promise.then((data) => ListAdvisoriesResponse.decode(_m0.Reader.create(data)));
  }

  listAdvisoriesV2(request: ListAdvisoriesV2Request): Promise<ListAdvisoriesV2Response> {
    const data = ListAdvisoriesV2Request.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListAdvisoriesV2", data);
    return promise.then((data) => ListAdvisoriesV2Response.decode(_m0.Reader.create(data)));
  }

  postAdvisoryFeedback(request: PostAdvisoryFeedbackRequest): Promise<PostAdvisoryFeedbackResponse> {
    const data = PostAdvisoryFeedbackRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "PostAdvisoryFeedback", data);
    return promise.then((data) => PostAdvisoryFeedbackResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
