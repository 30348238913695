// @generated by protoc-gen-es v1.2.0 with parameter "target=ts"
// @generated from file protos/billing/v1/billing.proto (package protos.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum protos.billing.v1.Product
 */
export enum Product {
  /**
   * @generated from enum value: PRODUCT_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PRODUCT_SAST = 1;
   */
  SAST = 1,

  /**
   * @generated from enum value: PRODUCT_SCA = 2;
   */
  SCA = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Product)
proto3.util.setEnumType(Product, "protos.billing.v1.Product", [
  { no: 0, name: "PRODUCT_UNSPECIFIED" },
  { no: 1, name: "PRODUCT_SAST" },
  { no: 2, name: "PRODUCT_SCA" },
]);

/**
 * @generated from message protos.billing.v1.CreateCheckoutSessionRequest
 */
export class CreateCheckoutSessionRequest extends Message<CreateCheckoutSessionRequest> {
  /**
   * @generated from field: int32 deployment_id = 1;
   */
  deploymentId = 0;

  /**
   * @generated from field: repeated protos.billing.v1.Product products = 2;
   */
  products: Product[] = [];

  constructor(data?: PartialMessage<CreateCheckoutSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protos.billing.v1.CreateCheckoutSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "deployment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "products", kind: "enum", T: proto3.getEnumType(Product), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCheckoutSessionRequest {
    return new CreateCheckoutSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCheckoutSessionRequest {
    return new CreateCheckoutSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCheckoutSessionRequest {
    return new CreateCheckoutSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCheckoutSessionRequest | PlainMessage<CreateCheckoutSessionRequest> | undefined, b: CreateCheckoutSessionRequest | PlainMessage<CreateCheckoutSessionRequest> | undefined): boolean {
    return proto3.util.equals(CreateCheckoutSessionRequest, a, b);
  }
}

/**
 * @generated from message protos.billing.v1.CreateCheckoutSessionResponse
 */
export class CreateCheckoutSessionResponse extends Message<CreateCheckoutSessionResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<CreateCheckoutSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protos.billing.v1.CreateCheckoutSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCheckoutSessionResponse {
    return new CreateCheckoutSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCheckoutSessionResponse {
    return new CreateCheckoutSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCheckoutSessionResponse {
    return new CreateCheckoutSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCheckoutSessionResponse | PlainMessage<CreateCheckoutSessionResponse> | undefined, b: CreateCheckoutSessionResponse | PlainMessage<CreateCheckoutSessionResponse> | undefined): boolean {
    return proto3.util.equals(CreateCheckoutSessionResponse, a, b);
  }
}

/**
 * @generated from message protos.billing.v1.GetContributorsRequest
 */
export class GetContributorsRequest extends Message<GetContributorsRequest> {
  /**
   * @generated from field: int32 deployment_id = 1;
   */
  deploymentId = 0;

  constructor(data?: PartialMessage<GetContributorsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protos.billing.v1.GetContributorsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "deployment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetContributorsRequest {
    return new GetContributorsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetContributorsRequest {
    return new GetContributorsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetContributorsRequest {
    return new GetContributorsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetContributorsRequest | PlainMessage<GetContributorsRequest> | undefined, b: GetContributorsRequest | PlainMessage<GetContributorsRequest> | undefined): boolean {
    return proto3.util.equals(GetContributorsRequest, a, b);
  }
}

/**
 * @generated from message protos.billing.v1.FilteredContributor
 */
export class FilteredContributor extends Message<FilteredContributor> {
  /**
   * @generated from field: string contributor = 1;
   */
  contributor = "";

  /**
   * @generated from field: repeated string names = 2;
   */
  names: string[] = [];

  /**
   * @generated from field: repeated string emails = 3;
   */
  emails: string[] = [];

  /**
   * @generated from field: repeated string logins = 4;
   */
  logins: string[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp last_contributed_at = 5;
   */
  lastContributedAt?: Timestamp;

  constructor(data?: PartialMessage<FilteredContributor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protos.billing.v1.FilteredContributor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contributor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "logins", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "last_contributed_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FilteredContributor {
    return new FilteredContributor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FilteredContributor {
    return new FilteredContributor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FilteredContributor {
    return new FilteredContributor().fromJsonString(jsonString, options);
  }

  static equals(a: FilteredContributor | PlainMessage<FilteredContributor> | undefined, b: FilteredContributor | PlainMessage<FilteredContributor> | undefined): boolean {
    return proto3.util.equals(FilteredContributor, a, b);
  }
}

/**
 * @generated from message protos.billing.v1.GetContributorsResponse
 */
export class GetContributorsResponse extends Message<GetContributorsResponse> {
  /**
   * @generated from field: int32 count = 1;
   */
  count = 0;

  /**
   * @generated from field: repeated protos.billing.v1.FilteredContributor contributors = 2;
   */
  contributors: FilteredContributor[] = [];

  constructor(data?: PartialMessage<GetContributorsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protos.billing.v1.GetContributorsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "contributors", kind: "message", T: FilteredContributor, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetContributorsResponse {
    return new GetContributorsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetContributorsResponse {
    return new GetContributorsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetContributorsResponse {
    return new GetContributorsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetContributorsResponse | PlainMessage<GetContributorsResponse> | undefined, b: GetContributorsResponse | PlainMessage<GetContributorsResponse> | undefined): boolean {
    return proto3.util.equals(GetContributorsResponse, a, b);
  }
}

/**
 * @generated from message protos.billing.v1.GetUsageRequest
 */
export class GetUsageRequest extends Message<GetUsageRequest> {
  /**
   * @generated from field: int32 deployment_id = 1;
   */
  deploymentId = 0;

  constructor(data?: PartialMessage<GetUsageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protos.billing.v1.GetUsageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "deployment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUsageRequest | PlainMessage<GetUsageRequest> | undefined, b: GetUsageRequest | PlainMessage<GetUsageRequest> | undefined): boolean {
    return proto3.util.equals(GetUsageRequest, a, b);
  }
}

/**
 * @generated from message protos.billing.v1.ProductUsage
 */
export class ProductUsage extends Message<ProductUsage> {
  /**
   * @generated from field: string product = 1;
   */
  product = "";

  /**
   * @generated from field: int32 used_seats = 2;
   */
  usedSeats = 0;

  /**
   * @generated from field: int32 trial_seats = 3;
   */
  trialSeats = 0;

  /**
   * @generated from field: int32 licensed_seats = 4;
   */
  licensedSeats = 0;

  /**
   * @generated from field: int32 community_seats = 5;
   */
  communitySeats = 0;

  /**
   * @generated from field: int32 available_seats = 6;
   */
  availableSeats = 0;

  constructor(data?: PartialMessage<ProductUsage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protos.billing.v1.ProductUsage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "used_seats", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "trial_seats", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "licensed_seats", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "community_seats", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "available_seats", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductUsage {
    return new ProductUsage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductUsage {
    return new ProductUsage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductUsage {
    return new ProductUsage().fromJsonString(jsonString, options);
  }

  static equals(a: ProductUsage | PlainMessage<ProductUsage> | undefined, b: ProductUsage | PlainMessage<ProductUsage> | undefined): boolean {
    return proto3.util.equals(ProductUsage, a, b);
  }
}

/**
 * @generated from message protos.billing.v1.GetUsageResponse
 */
export class GetUsageResponse extends Message<GetUsageResponse> {
  /**
   * @generated from field: int32 used_seats = 1;
   */
  usedSeats = 0;

  /**
   * @generated from field: int32 available_seats = 2;
   */
  availableSeats = 0;

  /**
   * @generated from field: repeated protos.billing.v1.ProductUsage products = 3;
   */
  products: ProductUsage[] = [];

  constructor(data?: PartialMessage<GetUsageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protos.billing.v1.GetUsageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "used_seats", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "available_seats", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "products", kind: "message", T: ProductUsage, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUsageResponse | PlainMessage<GetUsageResponse> | undefined, b: GetUsageResponse | PlainMessage<GetUsageResponse> | undefined): boolean {
    return proto3.util.equals(GetUsageResponse, a, b);
  }
}

