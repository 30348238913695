import { useMemo } from "react";
import groupBy from "lodash/groupBy";

import {
  RepositorySearchStatus,
  TargetRepository,
} from "@protos/search/v1/search_service";
import { useQueryConsoleResult, useSearchableRepos } from "@shared/hooks";

// Needed while there are deprecated protobuf fields and repository is optional
const createTargetRepo = (resultRepo: RepositorySearchStatus) => {
  return (
    resultRepo.repository ||
    TargetRepository.create({ fullName: resultRepo.fullName })
  );
};

export const useReposFromQcSearch = (searchId: string | null) => {
  const { data: searchablePrivateRepos } = useSearchableRepos();
  const { data: searchResult } = useQueryConsoleResult(searchId);

  return useMemo(() => {
    if (!searchId) {
      return null;
    }

    const groups = groupBy(searchResult?.repositories, (resultRepo) =>
      searchablePrivateRepos?.repositories.find(
        (r) => r.fullName === resultRepo.repository?.fullName
      )
        ? "privateRepos"
        : "publicRepos"
    );
    return {
      privateRepos: groups.privateRepos?.map(createTargetRepo) ?? [],
      publicRepos: groups.publicRepos?.map(createTargetRepo) ?? [],
    };
  }, [searchId, searchResult, searchablePrivateRepos]);
};
