import React from "react";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Group, Stack, Text, TextInput, Tooltip } from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";

import { useBasePath, useCreateDeploymentMutation } from "@shared/hooks";
import { slugifyNameForOrg } from "@shared/utils";

interface NewOrgFormProps {
  onBack: () => void;
}

export const NewOrgForm: React.FC<NewOrgFormProps> = ({ onBack }) => {
  const basePath = useBasePath();
  const { mutate: createDeployment, isPending } = useCreateDeploymentMutation();

  const onCreate = async (values: { name: string }) => {
    createDeployment({
      deploymentName: values.name,
      redirectToOnSuccess: `${basePath}/projects/scanning`,
    });
  };

  const form = useForm({
    initialValues: {
      name: "",
    },
    validate: {
      name: hasLength({ min: 1 }, "Enter a Semgrep Organization name"),
    },
    transformValues: (values) => ({
      name: values.name,
    }),
    validateInputOnChange: true,
  });

  return (
    <form onSubmit={form.onSubmit((values) => onCreate(values))}>
      <Stack w="100%">
        <TextInput
          label="Organization display name"
          required
          {...form.getInputProps("name")}
        />
        <TextInput
          label={
            <Tooltip
              multiline
              withinPortal
              w={250}
              label="The slug is a URL-compatible format of your display name. It will be used for your organization home page URL and for invoking the Semgrep API."
            >
              <Text inherit>
                Organization slug{" "}
                <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
              </Text>
            </Tooltip>
          }
          disabled
          value={slugifyNameForOrg(form.values.name || "")}
        />
        <Group justify="space-between" grow>
          <Button onClick={onBack}>Back</Button>
          <Button type="submit" disabled={isPending}>
            Create
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
