import { useState } from "react";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import styled from "styled-components";
import { faCaretDown, faCaretRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "@mantine/core";

import { ExternalLink } from "@shared/components";

import { SuccessResult } from "../../stores";
import { SectionHeader } from "../SectionHeader";
import { SectionList } from "../SectionList";

import { LineNumber } from "./LineNumber";

const LineNumberSerial = styled(LineNumber)`
  :not(:first-child)::before {
    content: ", ";
  }
`;

interface Props {
  result: SuccessResult;
}

const TestsSectionHeader = styled.h4`
  display: flex;
  align-items: center;
  gap: var(--mantine-spacing-sm);
  cursor: pointer;
  border-top: 1px var(--mantine-color-gray-7) solid;

  :hover {
    background-color: var(--mantine-color-blue-0);
  }
`;

const PASS_TAG = (
  <Badge
    color="green"
    variant="filled"
    radius="xs"
    style={{ width: 54, textTransform: "uppercase" }}
  >
    pass
  </Badge>
);
const FAIL_TAG = (
  <Badge
    color="red"
    variant="filled"
    radius="xs"
    style={{ width: 54, textTransform: "uppercase" }}
  >
    fail
  </Badge>
);

const TestCase = styled.li`
  display: flex;
  align-items: center;
  gap: var(--mantine-spacing-sm);
`;

const Code = styled.span`
  font-family: var(--mantine-font-family-monospace);
`;

const Tests = ({ result }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { bundle } = result;
  const { expectedMatches, expectedNotMatches } = bundle;
  const actualMatches = result.matchLines;
  const extraneousMatches = result.unexpectedMatchLines;
  if (expectedMatches.length === 0 && expectedNotMatches.length === 0)
    return (
      <>
        <SectionHeader>No test assertions</SectionHeader>
        <SectionList>
          <p
            style={{
              marginBottom: "4px",
              padding: "0 var(--mantine-spacing-sm)",
            }}
          >
            Add <Code>ruleid: {"<rule-id>"}</Code> above lines your rule should
            catch to signify a test.{" "}
            <ExternalLink
              stayVisible={true}
              href="https://semgrep.dev/docs/writing-rules/testing-rules/"
            >
              More info on test assertions.
            </ExternalLink>
          </p>
        </SectionList>
      </>
    );

  const expandIcon = (
    <FontAwesomeIcon icon={isExpanded ? faCaretDown : faCaretRight} size="xs" />
  );

  const countPassing =
    expectedMatches
      .map((lineno) => actualMatches.includes(lineno))
      .filter((x) => x).length +
    expectedNotMatches
      .map((lineno) => !actualMatches.includes(lineno))
      .filter((x) => x).length +
    (extraneousMatches.length === 0 ? 1 : 0);
  return (
    <>
      <TestsSectionHeader onClick={() => setIsExpanded(!isExpanded)}>
        {expandIcon}
        {countPassing}/{expectedMatches.length + expectedNotMatches.length + 1}{" "}
        Tests Passed
      </TestsSectionHeader>
      <SectionList
        style={{
          display: isExpanded ? undefined : "none",
        }}
      >
        {expectedMatches.map((lineno, i) => (
          <TestCase key={i}>
            {actualMatches.includes(lineno) ? PASS_TAG : FAIL_TAG}
            <div>
              <LineNumber line={lineno} /> must match
            </div>
          </TestCase>
        ))}
        {expectedNotMatches.map((lineno, i) => (
          <TestCase key={i}>
            {!actualMatches.includes(lineno) ? PASS_TAG : FAIL_TAG}
            <div>
              <LineNumber line={lineno} /> must not match
            </div>
          </TestCase>
        ))}
        <TestCase>
          {extraneousMatches.length === 0 ? PASS_TAG : FAIL_TAG}
          <div>
            Other lines must not match.
            {extraneousMatches.length === 0 ? (
              " None match."
            ) : (
              <>
                {" "}
                Unexpected {pluralize(
                  "match",
                  extraneousMatches.length
                )} on{" "}
                {[...extraneousMatches].map((lineno) => (
                  <LineNumberSerial key={lineno} line={lineno} />
                ))}
              </>
            )}
          </div>
        </TestCase>
      </SectionList>
    </>
  );
};

export const TestsSection = observer(Tests);
