export interface RuleExample {
  description: string;
  helpUrl?: string;
  pattern: string;
  target: string;
}

const DEFAULT_TAINT_RULE = `rules:
  - id: simple-taint-rule
    languages:
      - python
    message: >-
      This example leverages dataflow analysis through \`pattern-sources\`, \`pattern-sinks\`, and \`pattern-sanitizers\`.
      The source \`get_user_input\` will be tracked to either \`html_output\` or \`eval\`,
      we remove any findings if they are sanitized with \`sanitize_input\`.
      For more information visit https://semgrep.dev/docs/writing-rules/data-flow/taint-mode
    mode: taint
    pattern-sources:
      - pattern: get_user_input(...)
    pattern-sanitizers:
      - pattern: sanitize_input(...)
    pattern-sinks:
      - pattern: html_output(...)
      - pattern: eval(...)
    severity: WARNING
`;

const DEFAULT_TAINT_TARGET = `def route1():
    data = get_user_input()
    data = sanitize_input(data)
    # ok: simple-taint-rule
    return html_output(data)

  def route2():
    data = get_user_input()
    # ruleid: simple-taint-rule
    return html_output(data)`;

const DEFAULT_TAINTLABEL_RULE = `rules:
- id: taint-labels
  message: >-
    Taint labels allow you to granularly define what sources you want to track and how they must flow.
    In this example we want to produce a finding only if \`user_input\` flows into \`evil(...)\`.
    If these conditions are met, the finding will occur if it reaches the \`sink(...)\`.
    For more information visit https://semgrep.dev/docs/writing-rules/data-flow/taint-mode/#taint-labels-pro-
  mode: taint
  pattern-sources:
    - pattern: user_input
      label: INPUT
    - pattern: evil(...)
      label: EVIL
  pattern-sinks:
    - pattern: sink(...)
      requires: EVIL and INPUT
  languages:
    - python
  severity: WARNING
`;

const DEFAULT_TAINTLABEL_TARGET = `def bad(user_input):
  x = user_input
  y = evil(x)
  # ruleid: taint-labels
  sink(y)

def bad(user_input):
  x = user_input
  # ok: taint-labels (no evil!)
  sink(x)`;

const DEFAULT_METAVARIABLE_COMPARISON_RULE = `rules:
- id: less-than-2048-key-size
  message: >-
    This example leverages \`metavariable-comparison\` to identify key sizes less than 2048 from the first argument of \`generate_private_key\`.
    For more information visit https://semgrep.dev/docs/writing-rules/rule-syntax/#metavariable-comparison
  patterns:
    - pattern: cryptography.hazmat.primitives.asymmetric.dsa.generate_private_key($SIZE, ...)
    - focus-metavariable: $SIZE
    - metavariable-comparison:
        metavariable: $SIZE
        comparison: $SIZE < 2048
  languages:
    - python
  severity: ERROR
`;

const DEFAULT_METAVARIABLE_COMPARISON_TARGET = `from cryptography.hazmat.primitives.asymmetric import dsa

# ruleid: less-than-2048-private-key
dsa.generate_private_key(1024,
  backend=backends.default_backend())
# ok: less-than-2048-private-key
dsa.generate_private_key(2048,
  backend=backends.default_backend())
`;

const DEFAULT_METAVARIABLE_PATTERN_RULE = `rules:
- id: version-should-not-be-less-than-249
  message: >-
    In this example we take the contents of a string, and use \`metavariable-pattern\` with our \`generic\` language to be able to manipulate the string to look for version numbers that should be less than \`249\` we can use \`metavariable-comparison\` to verify this.
    For more information visit https://semgrep.dev/docs/writing-rules/rule-syntax/#metavariable-pattern
  patterns:
  - pattern: |
      "$VALUE"
  - metavariable-pattern:
      metavariable: $VALUE
      language: generic
      patterns:
        - pattern: |
            1.0.$THREE
        - metavariable-comparison:
            comparison: $THREE < 249
  languages: [python]
  severity: WARNING
`;

const DEFAULT_METAVARIABLE_PATTERN_TARGET = `# ruleid: version-should-not-be-less-than-249
somevalue = "1.0.247"
# ok: version-should-not-be-less-than-249
someothervalue = "1.0.249"
`;

const DEFAULT_SECRET_PATTERN_TARGET = `// ruleid: example-github-validation-rule
ghp_h_Bo4EZV2blMzuSgUOFvwMHwaKKYWtMmIQIzs_
`;

const DEFAULT_SECRET_PATTERN_RULE = `rules:
- id: example-github-validation-rule
  message: >-
    In this example, we look for github tokens with our \`pattern-regex\` syntax, and bind the value to the SECRET metavariable.
    We then use the contents in our \`validators\` feature which includes it as part of the Authorization request to https://api.github.com/user.
    If the HTTP response returns 200, the finding will be marked as \`Valid\`.
    If the HTTP response returns 401, the finding will be marked as \`Invalid\`.
    If the HTTP response returns anything else, the finding will be marked as \`Network Error\`, which should be investigated further.
    For more information visit https://semgrep.dev/docs/semgrep-secrets/validators/ and https://semgrep.dev/docs/writing-rules/pattern-syntax/#metavariables
  metadata:
    product: secrets
    secret_type: GitHub
  languages:
    - regex
  patterns:
    - pattern-regex: (?<SECRET>\\b((ghp|gho|ghu|ghs|ghr|github_pat)_[a-zA-Z0-9_]{36,255})\\b)
  severity: ERROR
  validators:
  - http:
      request:
        headers:
            Authorization: Bearer $SECRET
            Host: api.github.com
            User-Agent: Semgrep
        method: GET
        url: https://api.github.com/user
      response:
        - match:
            - status-code: 200
          result:
            validity: valid
        - match:
            - status-code: 401
          result:
            validity: invalid

`;

export const codeRuleExamples: RuleExample[] = [
  {
    description: "Metavariable-comparison",
    pattern: DEFAULT_METAVARIABLE_COMPARISON_RULE,
    target: DEFAULT_METAVARIABLE_COMPARISON_TARGET,
  },
  {
    description: "Metavariable-pattern",
    pattern: DEFAULT_METAVARIABLE_PATTERN_RULE,
    target: DEFAULT_METAVARIABLE_PATTERN_TARGET,
  },
];

export const taintRuleExamples: RuleExample[] = [
  {
    description: "Dataflow analysis",
    pattern: DEFAULT_TAINT_RULE,
    target: DEFAULT_TAINT_TARGET,
  },
  {
    description: "Dataflow analysis with taint labels",
    pattern: DEFAULT_TAINTLABEL_RULE,
    target: DEFAULT_TAINTLABEL_TARGET,
  },
];

export const secretRuleExamples: RuleExample[] = [
  {
    description: "HTTP validators",
    pattern: DEFAULT_SECRET_PATTERN_RULE,
    target: DEFAULT_SECRET_PATTERN_TARGET,
  },
];
