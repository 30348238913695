import jwt_decode from "jwt-decode";

import {
  ACCESS_TOKEN_KEY,
  EXPIRES_AT_KEY,
  LAST_LOGIN_AT_KEY,
  LOGIN_STATE_SENTINEL,
  ORGANIZATION_KEY,
  SESSION_CONTENTS,
} from "@shared/constants";
import { UserData } from "@shared/types";

import { recoverBrowserSecurityError } from "../storage/storage";
import { decodeJwtIdentity } from "../userUtils";

export enum AuthStates {
  Unauthorized = "UNAUTHORIZED",
  Authorized = "AUTHORIZED",
}

// NOTE(zz): This function is a bit misleading as we only check the value of a timestamp
// which we assume we derived from the actual token and the value has not been tampered with.
// This is a weak assumption and should be revisited.
export function isAuthenticated(): boolean {
  const expiresAt = JSON.parse(
    recoverBrowserSecurityError(() => localStorage.getItem(EXPIRES_AT_KEY)) ||
      "0"
  );
  return new Date().getTime() < expiresAt;
}

export function everAuthenticated(): boolean {
  return (
    recoverBrowserSecurityError(() =>
      localStorage.getItem(LAST_LOGIN_AT_KEY)
    ) !== null
  );
}

export function getAccessToken(): string {
  const accessToken = recoverBrowserSecurityError(() =>
    localStorage.getItem(ACCESS_TOKEN_KEY)
  );
  if (!accessToken) {
    throw new Error("No access token found");
  }
  return accessToken;
}

export const getUserData = (): UserData => {
  const token = getAccessToken();
  return decodeJwtIdentity(token);
};

export const getAuthenticationState = (): AuthStates => {
  // All users must be properly authenticated and have a valid user id.
  if (isAuthenticated()) {
    return AuthStates.Authorized;
  } else {
    return AuthStates.Unauthorized;
  }
};

export function clearTokens(all?: boolean) {
  recoverBrowserSecurityError(() => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(EXPIRES_AT_KEY);
    localStorage.removeItem(ORGANIZATION_KEY);
    if (all) {
      localStorage.removeItem(LAST_LOGIN_AT_KEY);
      localStorage.removeItem(LOGIN_STATE_SENTINEL);
      localStorage.removeItem(SESSION_CONTENTS);
    }
  });
}

/**
 * Sets access token and relevant data to localStorage. Also clears the
 * organization data, since the user may have changed organizations and the new
 * token may not be valid for the old organization.
 * @param token access token to save in localStorage
 */
export const setAccessToken = (token: string) => {
  // nosemgrep: react-jwt-in-localstorage
  const decoded = jwt_decode<any>(token);
  recoverBrowserSecurityError(() => {
    localStorage.removeItem(ORGANIZATION_KEY);
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    localStorage.setItem(LAST_LOGIN_AT_KEY, new Date().getTime().toString());
    localStorage.setItem(EXPIRES_AT_KEY, JSON.stringify(decoded.exp * 1000)); // nosemgrep: react-jwt-decoded-property
  });
};
