import React from "react";
import { Link } from "react-router-dom";
import {
  faSignOut,
  faSortDown,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Button, Divider, Loader, Menu } from "@mantine/core";

import { useChangeOrg, useOrg, useUserOrgs } from "@shared/hooks";
import { AuthType, UserData } from "@shared/types";
import { getUserPhotoUrl } from "@shared/utils";

interface UserMenuProps {
  user: UserData;
  orgDisplayName: string | undefined;
  basePath: string;
  install: (
    authProviderType: AuthType,
    preferredReturnPath?: string
  ) => Promise<void>;
  logout: () => void;
}

export const UserMenu: React.FC<UserMenuProps> = ({
  user,
  orgDisplayName,
  basePath,
  install,
  logout,
}) => {
  const [org] = useOrg();
  const { data: userOrgs, isLoading: isUserOrgsLoading } = useUserOrgs();
  const changeOrg = useChangeOrg();
  return (
    <>
      <Menu shadow="md" width="max-content">
        <Menu.Target>
          <Button color="gray" variant="subtle" ml={8}>
            {orgDisplayName ?? "(add organization)"}{" "}
            <FontAwesomeIcon
              icon={faSortDown}
              color="var(--mantine-color-gray-6)"
              pull="right"
              style={{ marginTop: "-4px" }}
            />
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {isUserOrgsLoading ? (
            <Menu.Item>
              <Loader />
            </Menu.Item>
          ) : (
            (userOrgs || []).map((availableOrg) => {
              if (availableOrg.id === org?.id) return null;
              return (
                <Menu.Item
                  p="8px"
                  key={availableOrg.id}
                  onClick={() => {
                    changeOrg(availableOrg);
                  }}
                >
                  Switch to{" "}
                  <strong>
                    {availableOrg.display_name || availableOrg.name}
                  </strong>
                </Menu.Item>
              );
            })
          )}
          <Menu.Item
            onClick={async () => {
              await install(user.auth_provider_type);
            }}
          >
            Add organization
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Divider orientation="vertical" />
      <Menu position="bottom-end">
        <Menu.Target>
          <div>
            <Avatar src={getUserPhotoUrl(user)} radius="xl" size={30} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            leftSection={<FontAwesomeIcon icon={faSignOut} />}
            onClick={async () => {
              await logout();
            }}
          >
            sign out
          </Menu.Item>
          <Menu.Item
            component={Link}
            leftSection={<FontAwesomeIcon icon={faUser} />}
            to={`${basePath}/user/account`}
          >
            account
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
