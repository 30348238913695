import { Helmet } from "react-helmet";

export const GlobalHelmet = () => (
  <Helmet>
    <title>Semgrep</title>
    <meta name="title" content="Semgrep" />
    <meta
      name="description"
      content="Semgrep is a fast, open-source, static analysis tool for finding bugs and enforcing code standards at editor, commit, and CI time."
    />
    <meta name="viewport" content="width=device-width,initial-scale=1" />

    <meta property="og:type" content="website" />
    <meta property="og:title" content="Semgrep" />
    <meta
      property="og:description"
      content="Semgrep is a fast, open-source, static analysis tool for finding bugs and enforcing code standards at editor, commit, and CI time."
    />
    <meta property="og:image" content="/thumbnail.png" />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:title" content="Semgrep" />
    <meta
      property="twitter:description"
      content="Semgrep is a fast, open-source, static analysis tool for finding bugs and enforcing code standards at editor, commit, and CI time."
    />
    <meta property="twitter:image" content="/thumbnail.png" />
  </Helmet>
);
