interface FadedComponentProps {
  isFocused: boolean;
  children: React.ReactNode;
  opacity?: number;
}

export const FadedComponent: React.FC<FadedComponentProps> = (props) => {
  const opacity = props.opacity ?? 0.3;

  if (props.isFocused) {
    return props.children;
  } else {
    // if we don't set the line height here, the buttons will inexplicably be
    // several pixels taller due to inheriting the property from body
    return (
      <div style={{ opacity: opacity, lineHeight: 0 }}>{props.children}</div>
    );
  }
};
