import { useContext } from "react";
import { observer } from "mobx-react-lite";
import {
  faCabinetFiling,
  faGlobeAmericas,
  faLock,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, Group, Tooltip } from "@mantine/core";

import { AddToPolicyButton } from "@shared/components";
import { convertToProductRuleItem, SnippetAddress } from "@shared/editorCore";

import { WorkbenchContext } from "../../providers";
import { HeaderActionButton } from "../HeaderActionButton";
import { SignInButton } from "../SignInButton";

import EditorEllipsisMenu from "./EditorEllipsisMenu";
import { EditorTitle } from "./EditorTitle";

const Header = () => {
  const { workbench } = useContext(WorkbenchContext);

  const visibility = workbench.bundle?.visibility;

  const isSecretsRule = workbench.bundle?.isSecretsRule ?? false;

  const itemsToAdd = {
    rulesFromEditor: {
      address: workbench.address ? [workbench.address] : undefined,
      product: isSecretsRule ? ("secrets" as const) : ("sast" as const),
    },
  };

  return (
    <Group
      align="center"
      gap="xs"
      bg="dark.5"
      p="sm"
      pr="md"
      wrap="nowrap"
      justify="space-between"
    >
      <EditorTitle />
      <Group justify="xs" wrap="nowrap">
        {workbench.hasUnsavedChanges && (
          // Remove all caps, which is default for mantine badges
          <Badge color="orange" variant="filled">
            Unsaved changes
          </Badge>
        )}
        {!workbench.doesUserHaveEditPermission && (
          <Tooltip label="You do not have permission to edit this rule">
            <Badge color="gray" variant="filled">
              Read only
            </Badge>
          </Tooltip>
        )}
        <HeaderActionButton />
        <Button
          disabled={workbench.isLoading}
          variant="outline"
          color="blue.0"
          onClick={async () => {
            if (
              workbench.hasUnsavedChanges &&
              workbench.org &&
              workbench.bundle &&
              workbench.doesUserHaveEditPermission &&
              !(workbench.address instanceof SnippetAddress) // Snippets autosave differently
            ) {
              await workbench.saveBundle();
            }
            workbench.ui.openRuleShareDialog();
          }}
          leftSection={
            <FontAwesomeIcon
              icon={
                visibility === "unlisted"
                  ? faGlobeAmericas
                  : visibility === "org_private"
                  ? faLock
                  : faCabinetFiling
              }
            />
          }
        >
          Share
        </Button>
        {!workbench.user ? (
          <SignInButton
            returnPath={
              workbench.hasUnsavedChanges ? "/playground/new" : undefined
            }
          />
        ) : (
          <Tooltip
            multiline
            w={300}
            label={
              workbench.address instanceof SnippetAddress
                ? "Fork rule to configure policies"
                : "Save rule to configure policies"
            }
            disabled={
              !(
                workbench.hasUnsavedChanges ||
                workbench.isNewBundle ||
                workbench.address instanceof SnippetAddress
              )
            }
            position="bottom"
          >
            <div>
              <AddToPolicyButton
                ruleItemsToAdd={convertToProductRuleItem(itemsToAdd)}
                showOpenInAppCTA={workbench.isPlayground}
                buttonProps={{
                  disabled:
                    workbench.hasUnsavedChanges ||
                    workbench.isNewBundle ||
                    workbench.address instanceof SnippetAddress,
                }}
              />
            </div>
          </Tooltip>
        )}
        <EditorEllipsisMenu disabled={workbench.isLoading} />
      </Group>
    </Group>
  );
};

export const EditorHeader = observer(Header);
