import { observer } from "mobx-react-lite";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import { Billboard } from "@shared/components";

import { POSSIBLE_ERROR_STATES } from "../../stores";

interface Props {
  errorState: POSSIBLE_ERROR_STATES;
}

const ErrorStates = ({ errorState }: Props) => {
  let errorName, message;
  if (errorState === "notFound") {
    errorName = "RULE NOT FOUND";
    message = (
      <p>
        The currently selected rule cannot be found. <br /> It might have been
        deleted. <br />
        Try searching for a different rule.
      </p>
    );
  } else if (errorState === "unknownError") {
    errorName = "SOMETHING WENT WRONG";
    message = (
      <p>
        The editor reached an unrecoverable error. <br /> Try refreshing the
        page.
      </p>
    );
  }
  return (
    <Billboard icon={faExclamationTriangle} title={errorName}>
      {message}
    </Billboard>
  );
};

export const EditorErrorStates = observer(ErrorStates);
