import * as Sentry from "@sentry/react";

import { authGet } from "../auth";

export interface SchemaUrlResponse {
  schema_url: string;
}

export const getSchemaUrl = async () => {
  try {
    return await authGet<SchemaUrlResponse>("/api/schema_url");
  } catch (e) {
    Sentry.captureException(`Error fetching schema url: ${e}`);
    return {
      schema_url: "",
    };
  }
};
