import { EditorProps } from "@monaco-editor/react";

export const DEFAULT_MONACO_OPTIONS: EditorProps["options"] = {
  // Needed so we get autosuggest in the rules editor
  quickSuggestions: {
    other: true,
    comments: true,
    strings: true,
  },
  renderLineHighlight: "none",
  minimap: { enabled: false },
  wordWrap: "on",
  fontFamily: "var(--mantine-font-family-monospace)",
  lineNumbers: "on",
  lineDecorationsWidth: 0,
  contextmenu: false,
  scrollBeyondLastLine: false,
  hideCursorInOverviewRuler: true,
  overviewRulerBorder: false,
  overviewRulerLanes: 1,
  scrollbar: {
    horizontal: "hidden",
    verticalScrollbarSize: 5,
  },
  padding: { top: 12 },
  wrappingIndent: "same",
  selectionHighlight: false,
  occurrencesHighlight: "off",
  // Ugly and useless in the rules editor
  lightbulb: {
    enabled: false,
  },
  codeLens: false,
  glyphMargin: true,
};
