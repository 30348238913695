import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

/** Returns the current URL search parameters and a function to update them */
export const useUrlSearch = () => {
  const history = useHistory();

  // Use location from separate hook, because useHistory doesn't re-render the page on url change
  useLocation();

  // useLocation returns an empty search on first render, while window.location is always correct
  // so we have useLocation re-run this on URL change but grab the value from here
  // see https://stackoverflow.com/a/72457886
  const searchLocation = window.location.search;

  const search = useMemo(
    () => new URLSearchParams(searchLocation),
    [searchLocation]
  );

  const setSearch = useCallback(
    (params: URLSearchParams) => {
      if (params.toString() !== searchLocation) {
        history.replace({ search: params.toString() });
      }
    },
    [history, searchLocation]
  );

  return { search, setSearch };
};
