export const opDictSyntaxOriginal = {
  And: "patterns",
  Or: "pattern-either",
  Inside: "pattern-inside",
  XPat: "pattern",
  Negation: "pattern-not",
  Filter: "filter", // Can be metavariable-* or pattern-regex etc...
  Taint: "taint",
  TaintSource: "pattern-sources",
  TaintSink: "pattern-sinks",
  TaintSanitizer: "pattern-sanitizers",
  EllipsisAndStmts: "EllipsisAndStmts",
  ClassHeaderAndElems: "ClassHeaderAndElems",
};
