// @ts-ignore
import { useState } from "react";
import Xarrow from "react-xarrows";
import styled from "styled-components";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CodeHighlight } from "@mantine/code-highlight";
import { Modal, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { Advisory_Severity } from "@protos/sca/v1/sca";

import { CodeCard, GradientCard } from "./CodeCard";
import { FindingCard } from "./FindingCard";
import { LockFileCard } from "./LockFileCard";
import {
  Example,
  lightGreen,
  lightRed,
  mobileMq,
  newGreen,
  Screen,
} from "./utils";

const SplitLayout = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr;

  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 0 24px;
  text-align: left;
  box-sizing: border-box;
  @media (${mobileMq}) {
    grid-template-columns: auto;
    padding: 0px;
  }
`;

const LockFileSection = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  max-width: 100vw;
  @media (${mobileMq}) {
    align-items: center;
    text-align: center;
  }
`;

const CenterSection = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin: 24px 0px;
  @media (${mobileMq}) {
    margin: 64px 0px;
    align-items: center;
    max-width: 100vw;
  }
`;

const FindingsSection = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  max-width: 100vw;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const LockFile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (${mobileMq}) {
    flex-direction: row;
    max-width: 100%;
    overflow: hidden;
    justify-content: center;
  }
`;

const FindingCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (${mobileMq}) {
    flex-direction: row;
    max-width: 100vw;
    overflow: hidden;
    justify-content: center;
    -webkit-mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0)
    );
  }
`;

const FindingsCategory = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (${mobileMq}) {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
`;

const CheckRulePattern = styled.button`
  padding: 4px 10px;
  border-radius: 10px;
  background-color: rgb(40 50 75 / 80%);
  border: none;
  cursor: pointer;
  font-size: 14px;
  z-index: 10;
  :hover {
    background-color: rgb(40 50 75);
  }
  color: #eee;
  font-weight: 600;
`;

const Point = styled.div<{ active: boolean }>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${({ active }) => (active ? "#C6CBD6" : "#475467")};
  cursor: pointer;
`;

const CarouselControls = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

const CarouselArrow = styled.div`
  padding: 0px 16px;
  color: ${newGreen};
  cursor: pointer;
  &:hover {
    color: ${lightGreen};
  }
`;

const preferredExamples: Example[] = ["lodash", "minimist", "axios"];
const reachableExamples: Example[] = ["lodash", "minimist"];
const unreachableExamples: Example[] = ["axios", "node-fetch", "blitz"];

const libsVersions = {
  lodash: "4.6.0",
  minimist: "1.2.1",
  axios: "0.20.0",
  "node-fetch": "2.6.1",
  blitz: "0.41.1",
};

const exampleRules: { [example: string]: string } = {
  lodash: `patterns:
  - pattern-either:
      - patterns:
          - pattern-either:
              - pattern: $LODASH.set(...)
              - pattern: $LODASH.set.call(...)
              - pattern: $F(...,$LODASH.set,...)
          - pattern-either:
              - pattern-inside: |
                  $LODASH = require("lodash");
                  ...
              - pattern-inside: |
                  import("lodash").then(...)
              - patterns:
                  - pattern-inside: |
                      $LD = require("lodash");
                      ...
                  - pattern-inside: |
                      global.$LODASH = $LD
                      ...
              - pattern-inside: |
                  import $LODASH from "lodash";
                  ...
              - pattern-inside: |
                  $LODASH = await import("lodash")
                  ...
              - pattern-inside: |
                  $LODASH = (await import("lodash")).$Y
                  ...
              - pattern-inside: |
                  import * as $LODASH from "lodash";
                  ...
      - patterns:
          - pattern-either:
              - pattern: set(...)
              - pattern: set.call(...)
              - pattern: pipe(...,set,...)
          - pattern-either:
              - pattern-inside: |
                  import { set } from "lodash";
                  ...
              - pattern-inside: |
                  import set from "lodash/set";
                  ...
              - pattern-inside: |
                  import set from "lodash.set";
                  ...
              - pattern-inside: >
                  require({'packages':[...,{'name':'lodash',...}]},[...,'lodash/set'],function(set){...});
                  ...
      - patterns:
          - pattern: $X(...)
          - pattern-inside: |
              import { set as $X } from "lodash";
              ...`,
  minimist: `patterns:
  - pattern-either:
    - pattern: |
        function (..., $STR, ...) {
          ...
          $MINIMIST($STR)
          ...
        }
    - pattern: |
        function $FUNC (..., $STR, ...) {
          ...
          $MINIMIST($STR)
          ...
        }
    - pattern: |
        $X = function $FUNC(..., $STR, ...) {
          ...
          $MINIMIST($STR)
          ...
        }
    - pattern: |
        $APP.$METHOD(..., function $FUNC(..., $STR, ...) {
          ...
          $MINIMIST($STR)
          ...
        }, ...)
  - pattern-either:
    - pattern-inside: |
        const $MINIMIST = require("minimist");
        ...
    - pattern-inside: |
        var $MINIMIST = require("minimist");
        ...
    - pattern-inside: |
        import { $MINIMIST } from "minimist";
        ...
    - pattern-inside: |
        import $MINIMIST from "minimist";
        ...`,

  axios: `patterns:
  - pattern-either:
    - pattern: $PATH.trim(..., $STR, ...)
    - pattern: trim(..., $STR, ...)
  - pattern-either:
    - pattern-inside: function (..., $STR, ...) {...}
    - pattern-inside: function $FUNC (..., $STR, ...) {...}
    - pattern-inside: $X = function $FUNC(..., $STR, ...) {...}
    - pattern-inside: $APP.$METHOD(..., function $FUNC(..., $STR, ...) {...}, ...)`,
};

export function ExplainSupplyChain({
  goTo,
  example,
}: {
  goTo: (screen: Screen) => void;
  example: Example;
}) {
  const [showExampleModal, setShowExampleModal] = useState<null | Example>(
    null
  );
  let reachablePairs: [string, string][] = [];
  let unreachablePairs: [string, string][] = [];
  if (example === "lodash") {
    reachablePairs = [
      ["lodash", "index.js"],
      ["index.js", "finding-lodash"],
    ];
  }
  if (example === "minimist") {
    reachablePairs = [
      ["minimist", "utils.js"],
      ["utils.js", "finding-minimist"],
    ];
  }
  const isMobile = useMediaQuery(`(${mobileMq})`);

  if (example === "axios") {
    unreachablePairs = [["axios", "finding-axios"]];
  }

  const otherExamples = preferredExamples.filter((key) => key !== example);
  const sortedLibs = [otherExamples[0], example, otherExamples[1]] as Example[];

  const findings = {
    fetch: {
      key: "fetch",
      lib: "node-fetch" as Example,
      name: "Open Redirect in node-fetch",
      version: "2.6.1",
      upgradeTo: "2.6.7",
      severity: Advisory_Severity.HIGH,
    },
    lodash: {
      key: "lodash",
      lib: "lodash" as Example,
      name: "Code Injection in lodash",
      version: "4.6.0",
      upgradeTo: "4.17.19",
      severity: Advisory_Severity.CRITICAL,
    },
    minimist: {
      key: "minimist",
      lib: "minimist" as Example,
      name: "Prototype Pollution in minimist",
      version: "1.2.1",
      upgradeTo: "1.2.6",
      severity: Advisory_Severity.HIGH,
    },
    axios: {
      key: "axios",
      lib: "axios" as Example,
      name: "Inefficient Regular Expression Complexity in axios",
      version: "0.20.0",
      upgradeTo: "0.21.2",
      severity: Advisory_Severity.HIGH,
    },
    blitz: {
      key: "blitz",
      lib: "blitz" as Example,
      name: "Code Injection in blitz",
      version: "0.41.1",
      upgradeTo: "0.45.3",
      severity: Advisory_Severity.HIGH,
    },
  };

  return (
    <Wrapper>
      <div
        style={{
          padding: "24px",
          marginBottom: "16px",
        }}
      >
        <Modal
          opened={!!showExampleModal}
          onClose={() => setShowExampleModal(null)}
          size="large"
          fullScreen={isMobile}
        >
          <Title size="h3">Example rule</Title>
          <Text>
            Semgrep rule for {example} example. It is used to match if the
            vulnerability is reachable or unreachable.
          </Text>
          <CodeHighlight
            language="yaml"
            code={
              exampleRules[example] ? exampleRules[example] : "rule not found"
            }
            //noCopy
            style={{ margin: "8px -20px" }}
          />
        </Modal>
        <Title size="30px" c="white" fw="700">
          {example === "axios" ? (
            <>
              Filter out{" "}
              <span
                style={{
                  color: newGreen,
                }}
              >
                unreachable
              </span>{" "}
              vulnerabilities
            </>
          ) : (
            <>
              Find{" "}
              <span
                style={{
                  color: "var(--mantine-color-red-6)",
                }}
              >
                reachable
              </span>{" "}
              vulnerabilities
            </>
          )}
        </Title>
        <Text c="#D0D5DD">
          Semgrep static code analysis detects if you use the vulnerable package
          in a way that can be exploited.
        </Text>
      </div>
      <SplitLayout>
        {reachablePairs.map(([a, b]) => {
          return (
            <Xarrow
              key={a + "_" + b}
              start={a}
              end={b}
              color={lightRed}
              headShape="arrow1"
              headSize={5}
            />
          );
        })}
        {unreachablePairs.map(([a, b]) => {
          return (
            <Xarrow
              key={a + "_" + b}
              start={a}
              end={b}
              color={newGreen}
              headShape="arrow1"
              headSize={5}
            />
          );
        })}

        <LockFileSection>
          <div>
            <Text
              style={{
                fontSize: "12px",
                color: "#aaa",
              }}
            >
              Unsorted vulnerabilities
            </Text>
            <Text
              style={{
                fontSize: "12px",
                color: "#aaa",
              }}
            >
              found in <b>package-lock.json</b>
            </Text>
          </div>
          <LockFile>
            {sortedLibs.map((key, i) => {
              return (
                <LockFileCard
                  key={i}
                  name={key}
                  version={libsVersions[key]}
                  direction={
                    i === 0
                      ? "top"
                      : i === sortedLibs.length - 1
                      ? "bottom"
                      : undefined
                  }
                  onClick={() => goTo(key)}
                  isActive={key === example}
                />
              );
            })}
          </LockFile>
        </LockFileSection>
        <div></div>
        <CenterSection>
          {example === "axios" ? (
            <GradientCard>
              <div
                style={{
                  padding: "8px 12px",
                  fontSize: "12px",
                }}
              >
                <b>No match</b>
                <div>
                  Semgrep will analyze your code, and if it does not find any
                  usage of the vulnerable dependency that could lead to
                  exploitation, it marks the vulnerability as Unreachable.
                </div>
                In this case there is <b>no match for {example}</b>
              </div>
            </GradientCard>
          ) : null}
          {example === "lodash" ? (
            <CodeCard
              name="index.js"
              lines={[`import _ from "lodash";`, `...`, `_.set();`]}
              highlightedLine={3}
            />
          ) : null}

          {example === "minimist" ? (
            <CodeCard
              name="utils.js"
              lines={[
                `import minimist from "minimist";`,
                `...`,
                `function parseArgs(args) {`,
                `  return minimist(args);`,
                `}`,
              ]}
              highlightedLine={4}
            />
          ) : null}
          <CheckRulePattern
            onClick={() => {
              setShowExampleModal(example);
            }}
          >
            Check Rule Pattern
          </CheckRulePattern>
        </CenterSection>
        <div></div>
        <FindingsSection>
          {!isMobile || reachableExamples.includes(example) ? (
            <FindingsCategory>
              <div>
                <Text c="#aaa" fz={12}>
                  Reachable vulnerabilities
                </Text>
              </div>
              <FindingCards>
                {isMobile ? (
                  example === "minimist" ? (
                    // just different order when different example is selected
                    <>
                      <FindingCard
                        {...findings.fetch}
                        activeExample={example}
                      />
                      <FindingCard
                        {...findings.minimist}
                        activeExample={example}
                        goTo={goTo}
                      />
                      <FindingCard
                        {...findings.lodash}
                        activeExample={example}
                        goTo={goTo}
                      />
                    </>
                  ) : (
                    <>
                      <FindingCard
                        {...findings.fetch}
                        activeExample={example}
                      />
                      <FindingCard
                        {...findings.lodash}
                        activeExample={example}
                        goTo={goTo}
                      />
                      <FindingCard
                        {...findings.minimist}
                        activeExample={example}
                        goTo={goTo}
                      />
                    </>
                  )
                ) : (
                  <>
                    <FindingCard
                      {...findings.lodash}
                      activeExample={example}
                      goTo={goTo}
                    />
                    <FindingCard
                      {...findings.minimist}
                      activeExample={example}
                      faded
                      goTo={goTo}
                    />
                  </>
                )}
              </FindingCards>
            </FindingsCategory>
          ) : null}
          {!isMobile || unreachableExamples.includes(example) ? (
            <FindingsCategory>
              <div>
                <Text c="#aaa" fz={12}>
                  Unreachable vulnerabilities
                </Text>
              </div>
              <FindingCards>
                {isMobile ? (
                  <>
                    <FindingCard {...findings.blitz} activeExample={example} />
                    <FindingCard
                      {...findings.axios}
                      activeExample={example}
                      goTo={goTo}
                    />
                    <FindingCard {...findings.fetch} activeExample={example} />
                  </>
                ) : (
                  <FindingCard
                    {...findings.axios}
                    activeExample={example}
                    goTo={goTo}
                    faded
                  />
                )}
              </FindingCards>
            </FindingsCategory>
          ) : null}
        </FindingsSection>
      </SplitLayout>
      <CarouselControls>
        <CarouselArrow>
          <FontAwesomeIcon
            icon={faChevronLeft}
            size="xl"
            onClick={() => {
              goTo(
                preferredExamples[
                  (preferredExamples.indexOf(example) -
                    1 +
                    preferredExamples.length) %
                    preferredExamples.length
                ]
              );
            }}
          />
        </CarouselArrow>
        {preferredExamples.map((key) => {
          return (
            <Point
              key={key}
              active={key === example}
              onClick={() => {
                goTo(key as Example);
              }}
            />
          );
        })}
        <CarouselArrow>
          <FontAwesomeIcon
            icon={faChevronRight}
            size="xl"
            onClick={() => {
              goTo(
                preferredExamples[
                  (preferredExamples.indexOf(example) + 1) %
                    preferredExamples.length
                ]
              );
            }}
          />
        </CarouselArrow>
      </CarouselControls>
    </Wrapper>
  );
}
