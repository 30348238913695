import { FC } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styled from "styled-components";
import { Code } from "@mantine/core";

// This is for overwriting the default paragraph styles in CSS
const MarkDownWrapper = styled.div`
  & p {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  ul,
  ol {
    margin-top: 0;
  }
`;

export interface InlineMarkdownCodeProps {
  text: string;
  className?: string;
}

export const InlineMarkdownCode: FC<InlineMarkdownCodeProps> = ({
  text,
  className,
}) => {
  return (
    <MarkDownWrapper className={className}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          code: ({ children }) => (
            <Code
              c="blue"
              p="0px 5px"
              fz="1em" // by default the code font size is smaller than the rest of the text
              lh="1em"
            >
              {children}
            </Code>
          ),
        }}
      >
        {text}
      </ReactMarkdown>
    </MarkDownWrapper>
  );
};
