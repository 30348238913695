import {
  lang as doc,
  Language,
  LanguageDefinition,
  LanguageMaturity,
} from "@shared/types";

export function isRealLanguageName(lang: Language): boolean {
  return !["regex", "generic"].includes(lang);
}
/** A human-readable suffix to display language maturities to the user */
export const languageMaturityTag: Record<LanguageMaturity, string> = {
  ga: "",
  beta: " (Beta)",
  alpha: " (Experimental)",
  develop: "",
};

/** The data in lang.json; keyed by identifier */
export const ALL_LANGUAGES: Record<Language, LanguageDefinition> =
  Object.fromEntries(
    (doc as LanguageDefinition[]).map((d) => [d.id as Language, d])
  );

/** User-visible languages (all languages that aren't "develop" maturity) */
export const VISIBLE_LANGUAGES: Record<Language, LanguageDefinition> =
  Object.fromEntries(
    Object.entries(ALL_LANGUAGES).filter(
      ([_id, definition]) => definition.maturity !== "develop"
    )
  );

/** All GA language identifiers */
export const GA_LANGUAGE_IDS: Language[] = Object.entries(ALL_LANGUAGES)
  .filter(([_, lang]) => lang.maturity === "ga")
  .map(([id, _]) => id);

/** Mapping of language keys to identifier
 *
 * E.g., both "py" and "python" map to "python"
 */
export const ID_BY_KEY: Record<string, Language> = Object.fromEntries(
  (doc as LanguageDefinition[]).flatMap((d) =>
    d.keys.map((k) => [k, d.id as Language])
  )
);

/** Getting all language keys from a specific language name
 *
 * E.g., get both "py" and "python" with "Python"
 */
export const SYNONYM_KEYS_BY_NAME: Record<string, Language[]> =
  Object.fromEntries(
    (doc as LanguageDefinition[]).map((d) => [d.name, d.keys as Language[]])
  );

/**  The valid language keys for rules */
export const ALL_VALID_LANGUAGE_KEYS: string[] = Object.entries(ALL_LANGUAGES)
  .map(([_, d]) => d.keys)
  .flat();

/**  The valid language names for rules */
export const ALL_VALID_LANGUAGE_NAMES: string[] = Object.entries(
  ALL_LANGUAGES
).map(([_, d]) => d.name);
