import { useContext, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { faGhost, faSignIn } from "@fortawesome/pro-duotone-svg-icons";
import {
  faPlusCircle,
  faSearch,
  faServer,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Box,
  Group,
  Menu,
  Modal,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { BetaLabel, Billboard } from "@shared/components";
import { useProductConfig, useRegistryRules } from "@shared/hooks";
import { Permission } from "@shared/types";

import { WorkbenchContext } from "../../providers";
import { TreeContainer } from "../../stores/Tree";
import {
  codeRuleExamples,
  secretRuleExamples,
  taintRuleExamples,
} from "../../utils/ruleExamples";
import { AiRuleForm } from "../AiRuleForm";
import { SignInButton } from "../SignInButton";

import { ExamplesMenuItem } from "./ExamplesMenuItem";

const Container = styled.div<{ isPlayground: boolean }>`
  gap: var(--mantine-spacing-xs);
  display: flex;
  flex-direction: column;
  height: 100%;
  /*
    by default min-width: auto prevents shrinking https://stackoverflow.com/a/36247448
    min-width is (effectively) set by snap offset of Split (see workbench/Screen.tsx)

    In playground, we want to have bigger min-width to avoid colliding with browser window resize.
    Otherwise there is a small gap to show user that it is possible to resize the panel.
  */
  min-width: ${({ isPlayground }) => (isPlayground ? "8px" : "2px")};
  overflow-y: auto;
  font-size: 13px;
`;

const NO_RESULTS_STATE = (
  <div style={{ margin: "var(--mantine-spacing-lg) 16px" }}>
    <Billboard icon={faGhost} title="No matches found">
      Try searching for different keywords, such as “django,” “java,” or
      “correctness.”
    </Billboard>
  </div>
);

const FileBrowserComponent = () => {
  const { workbench } = useContext(WorkbenchContext);
  const { fileRoot } = workbench;
  const { data: allRules } = useRegistryRules();
  const [aiRuleOpened, aiRuleHandlers] = useDisclosure(false);
  const [isFormDirty, setIsFormDirty] = useState(false); // State to track form dirty status
  const { data: productConfig } = useProductConfig();

  const hasSecretsEnabled = productConfig?.secrets.enabled ?? false;
  // not logged in users can create new rules
  const canCreateNewRule =
    workbench.permissions.includes(Permission.editor_create) ||
    workbench.user === undefined;

  const handleModalClose = () => {
    if (isFormDirty) {
      if (window.confirm("Are you sure you want to close?")) {
        aiRuleHandlers.close();
      }
    } else {
      aiRuleHandlers.close();
    }
  };

  useEffect(() => {
    // update file root's rules whenever the rules change
    fileRoot.updateRules(allRules ?? null);
  }, [allRules, fileRoot]);

  return (
    <Container isPlayground={workbench.isPlayground} data-testid="FileBrowser">
      <div>
        {/* height is taken from navbar orgpicker height */}
        <Group bg="dark.7" p="md" gap="xs" h={59}>
          {/* color matches the background of the file browser */}
          <Text c="var(--mantine-color-white)">
            <FontAwesomeIcon icon={faServer} />
          </Text>
          <Box
            style={{ flexGrow: 1 }}
            mr="auto"
            data-testid="FileBrowserHeader"
          >
            <Title order={3} c="white">
              Library
            </Title>
          </Box>
          <Menu
            transitionProps={{
              transition: "pop-top-right",
            }}
            position="top-start"
            width={280}
            withinPortal
            data-testid="FileBrowserCreateRuleDropdown"
          >
            <Menu.Target>
              <ActionIcon
                variant="outline"
                disabled={!canCreateNewRule}
                color="gray.0"
                aria-label="Create new rule"
              >
                <FontAwesomeIcon icon={faPlusCircle} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>Start from scratch</Menu.Label>
              <Menu.Item
                onClick={() => workbench.newBundle()}
                data-testid="FileBrowserNewRuleButton"
              >
                New rule
              </Menu.Item>
              <Menu.Label>Generate with AI</Menu.Label>
              <Menu.Item onClick={aiRuleHandlers.open}>
                Semgrep Assistant
                <BetaLabel />
              </Menu.Item>
              <Menu.Label>Rule syntax</Menu.Label>
              {codeRuleExamples.map((example, index) => {
                return (
                  <ExamplesMenuItem
                    example={example}
                    key={index}
                    workbench={workbench}
                  />
                );
              })}
              <Menu.Label>Taint mode</Menu.Label>
              {taintRuleExamples.map((example, index) => {
                return (
                  <ExamplesMenuItem
                    example={example}
                    key={index}
                    workbench={workbench}
                  />
                );
              })}
              {hasSecretsEnabled && (
                <>
                  <Menu.Label>Secrets</Menu.Label>
                  {secretRuleExamples.map((example, index) => (
                    <ExamplesMenuItem
                      example={example}
                      key={index}
                      workbench={workbench}
                    />
                  ))}
                </>
              )}
            </Menu.Dropdown>
          </Menu>
        </Group>
        <div
          style={{
            margin: "var(--mantine-spacing-sm)",
          }}
        >
          <TextInput
            radius="xl"
            leftSection={
              <FontAwesomeIcon
                style={{
                  color: "var(--mantine-color-gray-7)",
                  margin: "12px 8px",
                }}
                icon={faSearch}
              />
            }
            placeholder="e.g.: python.flask"
            disabled={fileRoot.workbench.user === undefined}
            onChange={debounce(
              (e) => fileRoot.updateSearchQuery(e.target.value),
              300
            )}
          />
        </div>
      </div>{" "}
      {fileRoot.isSearching && !fileRoot.matchesSearchQuery ? (
        NO_RESULTS_STATE
      ) : fileRoot.workbench.user === undefined ? (
        <div style={{ margin: "var(--mantine-spacing-lg) 16px" }}>
          <Billboard icon={faSignIn} title="Sign in required">
            <div>
              <div>
                Sign in to view and search the registry and your saved rules.
              </div>
              <div style={{ marginTop: "20px" }}>
                <SignInButton
                  returnPath={
                    workbench.hasUnsavedChanges ? "/playground/new" : undefined
                  }
                />
              </div>
            </div>
          </Billboard>
        </div>
      ) : (
        <TreeContainer contents={fileRoot.childrenNodes} />
      )}
      <Modal
        opened={aiRuleOpened}
        onClose={handleModalClose}
        title="Generate rule with Semgrep Assistant"
        size="xl"
      >
        <AiRuleForm
          onClose={aiRuleHandlers.close}
          onFormDirtyChange={setIsFormDirty}
        />
      </Modal>
    </Container>
  );
};

export const FileBrowser = observer(FileBrowserComponent);
