// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/deployment/v1/deployment.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { Role, roleFromJSON, roleToJSON, roleToNumber } from "../../auth/common/v1/common";
import { Group } from "../../permissions/common/v1/common";

export const protobufPackage = "protos.deployment.v1";

export enum OrgRole {
  UNSPECIFIED = "ORG_ROLE_UNSPECIFIED",
  ADMIN = "ORG_ROLE_ADMIN",
  MEMBER = "ORG_ROLE_MEMBER",
  IMPERSONATOR = "ORG_ROLE_IMPERSONATOR",
  READONLY = "ORG_ROLE_READONLY",
}

export function orgRoleFromJSON(object: any): OrgRole {
  switch (object) {
    case 0:
    case "ORG_ROLE_UNSPECIFIED":
      return OrgRole.UNSPECIFIED;
    case 1:
    case "ORG_ROLE_ADMIN":
      return OrgRole.ADMIN;
    case 2:
    case "ORG_ROLE_MEMBER":
      return OrgRole.MEMBER;
    case 3:
    case "ORG_ROLE_IMPERSONATOR":
      return OrgRole.IMPERSONATOR;
    case 4:
    case "ORG_ROLE_READONLY":
      return OrgRole.READONLY;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OrgRole");
  }
}

export function orgRoleToJSON(object: OrgRole): string {
  switch (object) {
    case OrgRole.UNSPECIFIED:
      return "ORG_ROLE_UNSPECIFIED";
    case OrgRole.ADMIN:
      return "ORG_ROLE_ADMIN";
    case OrgRole.MEMBER:
      return "ORG_ROLE_MEMBER";
    case OrgRole.IMPERSONATOR:
      return "ORG_ROLE_IMPERSONATOR";
    case OrgRole.READONLY:
      return "ORG_ROLE_READONLY";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OrgRole");
  }
}

export function orgRoleToNumber(object: OrgRole): number {
  switch (object) {
    case OrgRole.UNSPECIFIED:
      return 0;
    case OrgRole.ADMIN:
      return 1;
    case OrgRole.MEMBER:
      return 2;
    case OrgRole.IMPERSONATOR:
      return 3;
    case OrgRole.READONLY:
      return 4;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum OrgRole");
  }
}

export interface SendDeploymentToggleFeedbackRequest {
  deploymentId: number;
  featureAction: string;
  feedback?: string | undefined;
}

export interface SendDeploymentToggleFeedbackResponse {
}

export interface PatchDeploymentUserRequest {
  deploymentId: number;
  userId: number;
  newRole: OrgRole;
}

export interface PatchDeploymentUserResponse {
}

export interface GetDeploymentUsersRequest {
  deploymentId: number;
}

export interface GetDeploymentUsersResponse {
  users: DeploymentUser[];
}

/** @deprecated */
export interface GroupWithRole {
  group: Group | undefined;
  role: Role;
}

export interface DeploymentUser {
  id: number;
  orgRole: OrgRole;
  authentication: string[];
  since: Date | undefined;
  lastUpdatedAt:
    | Date
    | undefined;
  /** @deprecated */
  groups: GroupWithRole[];
  name: string;
  login: string;
  email: string;
  avatarUrl: string;
  active: boolean;
}

export interface FeatureFlag {
  name: string;
  expiresAt: Date | undefined;
}

export interface DeploymentFeatures {
  featureFlags: FeatureFlag[];
  hasAutofix: boolean;
  hasDeepsemgrep: boolean;
  hasTriageViaComment: boolean;
  hasDependencyQuery: boolean;
  defaultUserRole: OrgRole;
  editorSemgrepVersion: string;
}

export interface Deployment {
  id: number;
  name: string;
  displayName: string;
  slug: string;
  sourceType: string;
  features: DeploymentFeatures | undefined;
  organizationId?: number | undefined;
}

function createBaseSendDeploymentToggleFeedbackRequest(): SendDeploymentToggleFeedbackRequest {
  return { deploymentId: 0, featureAction: "", feedback: undefined };
}

export const SendDeploymentToggleFeedbackRequest = {
  encode(message: SendDeploymentToggleFeedbackRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    if (message.featureAction !== "") {
      writer.uint32(18).string(message.featureAction);
    }
    if (message.feedback !== undefined) {
      writer.uint32(26).string(message.feedback);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendDeploymentToggleFeedbackRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendDeploymentToggleFeedbackRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.featureAction = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.feedback = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendDeploymentToggleFeedbackRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      featureAction: isSet(object.featureAction) ? globalThis.String(object.featureAction) : "",
      feedback: isSet(object.feedback) ? globalThis.String(object.feedback) : undefined,
    };
  },

  toJSON(message: SendDeploymentToggleFeedbackRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.featureAction !== "") {
      obj.featureAction = message.featureAction;
    }
    if (message.feedback !== undefined) {
      obj.feedback = message.feedback;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendDeploymentToggleFeedbackRequest>, I>>(
    base?: I,
  ): SendDeploymentToggleFeedbackRequest {
    return SendDeploymentToggleFeedbackRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendDeploymentToggleFeedbackRequest>, I>>(
    object: I,
  ): SendDeploymentToggleFeedbackRequest {
    const message = createBaseSendDeploymentToggleFeedbackRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.featureAction = object.featureAction ?? "";
    message.feedback = object.feedback ?? undefined;
    return message;
  },
};

function createBaseSendDeploymentToggleFeedbackResponse(): SendDeploymentToggleFeedbackResponse {
  return {};
}

export const SendDeploymentToggleFeedbackResponse = {
  encode(_: SendDeploymentToggleFeedbackResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendDeploymentToggleFeedbackResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendDeploymentToggleFeedbackResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SendDeploymentToggleFeedbackResponse {
    return {};
  },

  toJSON(_: SendDeploymentToggleFeedbackResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SendDeploymentToggleFeedbackResponse>, I>>(
    base?: I,
  ): SendDeploymentToggleFeedbackResponse {
    return SendDeploymentToggleFeedbackResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendDeploymentToggleFeedbackResponse>, I>>(
    _: I,
  ): SendDeploymentToggleFeedbackResponse {
    const message = createBaseSendDeploymentToggleFeedbackResponse();
    return message;
  },
};

function createBasePatchDeploymentUserRequest(): PatchDeploymentUserRequest {
  return { deploymentId: 0, userId: 0, newRole: OrgRole.UNSPECIFIED };
}

export const PatchDeploymentUserRequest = {
  encode(message: PatchDeploymentUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    if (message.userId !== 0) {
      writer.uint32(16).int64(message.userId);
    }
    if (message.newRole !== OrgRole.UNSPECIFIED) {
      writer.uint32(24).int32(orgRoleToNumber(message.newRole));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatchDeploymentUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatchDeploymentUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userId = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.newRole = orgRoleFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PatchDeploymentUserRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      newRole: isSet(object.newRole) ? orgRoleFromJSON(object.newRole) : OrgRole.UNSPECIFIED,
    };
  },

  toJSON(message: PatchDeploymentUserRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.newRole !== OrgRole.UNSPECIFIED) {
      obj.newRole = orgRoleToJSON(message.newRole);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PatchDeploymentUserRequest>, I>>(base?: I): PatchDeploymentUserRequest {
    return PatchDeploymentUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PatchDeploymentUserRequest>, I>>(object: I): PatchDeploymentUserRequest {
    const message = createBasePatchDeploymentUserRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.userId = object.userId ?? 0;
    message.newRole = object.newRole ?? OrgRole.UNSPECIFIED;
    return message;
  },
};

function createBasePatchDeploymentUserResponse(): PatchDeploymentUserResponse {
  return {};
}

export const PatchDeploymentUserResponse = {
  encode(_: PatchDeploymentUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatchDeploymentUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatchDeploymentUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PatchDeploymentUserResponse {
    return {};
  },

  toJSON(_: PatchDeploymentUserResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<PatchDeploymentUserResponse>, I>>(base?: I): PatchDeploymentUserResponse {
    return PatchDeploymentUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PatchDeploymentUserResponse>, I>>(_: I): PatchDeploymentUserResponse {
    const message = createBasePatchDeploymentUserResponse();
    return message;
  },
};

function createBaseGetDeploymentUsersRequest(): GetDeploymentUsersRequest {
  return { deploymentId: 0 };
}

export const GetDeploymentUsersRequest = {
  encode(message: GetDeploymentUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDeploymentUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeploymentUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeploymentUsersRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0 };
  },

  toJSON(message: GetDeploymentUsersRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeploymentUsersRequest>, I>>(base?: I): GetDeploymentUsersRequest {
    return GetDeploymentUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeploymentUsersRequest>, I>>(object: I): GetDeploymentUsersRequest {
    const message = createBaseGetDeploymentUsersRequest();
    message.deploymentId = object.deploymentId ?? 0;
    return message;
  },
};

function createBaseGetDeploymentUsersResponse(): GetDeploymentUsersResponse {
  return { users: [] };
}

export const GetDeploymentUsersResponse = {
  encode(message: GetDeploymentUsersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      DeploymentUser.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDeploymentUsersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeploymentUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(DeploymentUser.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeploymentUsersResponse {
    return {
      users: globalThis.Array.isArray(object?.users) ? object.users.map((e: any) => DeploymentUser.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetDeploymentUsersResponse): unknown {
    const obj: any = {};
    if (message.users?.length) {
      obj.users = message.users.map((e) => DeploymentUser.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeploymentUsersResponse>, I>>(base?: I): GetDeploymentUsersResponse {
    return GetDeploymentUsersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeploymentUsersResponse>, I>>(object: I): GetDeploymentUsersResponse {
    const message = createBaseGetDeploymentUsersResponse();
    message.users = object.users?.map((e) => DeploymentUser.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGroupWithRole(): GroupWithRole {
  return { group: undefined, role: Role.UNSPECIFIED };
}

export const GroupWithRole = {
  encode(message: GroupWithRole, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      Group.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    if (message.role !== Role.UNSPECIFIED) {
      writer.uint32(16).int32(roleToNumber(message.role));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GroupWithRole {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroupWithRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = Group.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.role = roleFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GroupWithRole {
    return {
      group: isSet(object.group) ? Group.fromJSON(object.group) : undefined,
      role: isSet(object.role) ? roleFromJSON(object.role) : Role.UNSPECIFIED,
    };
  },

  toJSON(message: GroupWithRole): unknown {
    const obj: any = {};
    if (message.group !== undefined) {
      obj.group = Group.toJSON(message.group);
    }
    if (message.role !== Role.UNSPECIFIED) {
      obj.role = roleToJSON(message.role);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GroupWithRole>, I>>(base?: I): GroupWithRole {
    return GroupWithRole.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GroupWithRole>, I>>(object: I): GroupWithRole {
    const message = createBaseGroupWithRole();
    message.group = (object.group !== undefined && object.group !== null) ? Group.fromPartial(object.group) : undefined;
    message.role = object.role ?? Role.UNSPECIFIED;
    return message;
  },
};

function createBaseDeploymentUser(): DeploymentUser {
  return {
    id: 0,
    orgRole: OrgRole.UNSPECIFIED,
    authentication: [],
    since: undefined,
    lastUpdatedAt: undefined,
    groups: [],
    name: "",
    login: "",
    email: "",
    avatarUrl: "",
    active: false,
  };
}

export const DeploymentUser = {
  encode(message: DeploymentUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.orgRole !== OrgRole.UNSPECIFIED) {
      writer.uint32(16).int32(orgRoleToNumber(message.orgRole));
    }
    for (const v of message.authentication) {
      writer.uint32(26).string(v!);
    }
    if (message.since !== undefined) {
      Timestamp.encode(toTimestamp(message.since), writer.uint32(34).fork()).ldelim();
    }
    if (message.lastUpdatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdatedAt), writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.groups) {
      GroupWithRole.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(58).string(message.name);
    }
    if (message.login !== "") {
      writer.uint32(66).string(message.login);
    }
    if (message.email !== "") {
      writer.uint32(74).string(message.email);
    }
    if (message.avatarUrl !== "") {
      writer.uint32(82).string(message.avatarUrl);
    }
    if (message.active !== false) {
      writer.uint32(88).bool(message.active);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeploymentUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeploymentUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.orgRole = orgRoleFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.authentication.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.since = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastUpdatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.groups.push(GroupWithRole.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.name = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.login = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.email = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.avatarUrl = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.active = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeploymentUser {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      orgRole: isSet(object.orgRole) ? orgRoleFromJSON(object.orgRole) : OrgRole.UNSPECIFIED,
      authentication: globalThis.Array.isArray(object?.authentication)
        ? object.authentication.map((e: any) => globalThis.String(e))
        : [],
      since: isSet(object.since) ? fromJsonTimestamp(object.since) : undefined,
      lastUpdatedAt: isSet(object.lastUpdatedAt) ? fromJsonTimestamp(object.lastUpdatedAt) : undefined,
      groups: globalThis.Array.isArray(object?.groups) ? object.groups.map((e: any) => GroupWithRole.fromJSON(e)) : [],
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      login: isSet(object.login) ? globalThis.String(object.login) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      avatarUrl: isSet(object.avatarUrl) ? globalThis.String(object.avatarUrl) : "",
      active: isSet(object.active) ? globalThis.Boolean(object.active) : false,
    };
  },

  toJSON(message: DeploymentUser): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.orgRole !== OrgRole.UNSPECIFIED) {
      obj.orgRole = orgRoleToJSON(message.orgRole);
    }
    if (message.authentication?.length) {
      obj.authentication = message.authentication;
    }
    if (message.since !== undefined) {
      obj.since = message.since.toISOString();
    }
    if (message.lastUpdatedAt !== undefined) {
      obj.lastUpdatedAt = message.lastUpdatedAt.toISOString();
    }
    if (message.groups?.length) {
      obj.groups = message.groups.map((e) => GroupWithRole.toJSON(e));
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.login !== "") {
      obj.login = message.login;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.avatarUrl !== "") {
      obj.avatarUrl = message.avatarUrl;
    }
    if (message.active !== false) {
      obj.active = message.active;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeploymentUser>, I>>(base?: I): DeploymentUser {
    return DeploymentUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeploymentUser>, I>>(object: I): DeploymentUser {
    const message = createBaseDeploymentUser();
    message.id = object.id ?? 0;
    message.orgRole = object.orgRole ?? OrgRole.UNSPECIFIED;
    message.authentication = object.authentication?.map((e) => e) || [];
    message.since = object.since ?? undefined;
    message.lastUpdatedAt = object.lastUpdatedAt ?? undefined;
    message.groups = object.groups?.map((e) => GroupWithRole.fromPartial(e)) || [];
    message.name = object.name ?? "";
    message.login = object.login ?? "";
    message.email = object.email ?? "";
    message.avatarUrl = object.avatarUrl ?? "";
    message.active = object.active ?? false;
    return message;
  },
};

function createBaseFeatureFlag(): FeatureFlag {
  return { name: "", expiresAt: undefined };
}

export const FeatureFlag = {
  encode(message: FeatureFlag, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.expiresAt !== undefined) {
      Timestamp.encode(toTimestamp(message.expiresAt), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeatureFlag {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeatureFlag();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.expiresAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeatureFlag {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      expiresAt: isSet(object.expiresAt) ? fromJsonTimestamp(object.expiresAt) : undefined,
    };
  },

  toJSON(message: FeatureFlag): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.expiresAt !== undefined) {
      obj.expiresAt = message.expiresAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FeatureFlag>, I>>(base?: I): FeatureFlag {
    return FeatureFlag.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FeatureFlag>, I>>(object: I): FeatureFlag {
    const message = createBaseFeatureFlag();
    message.name = object.name ?? "";
    message.expiresAt = object.expiresAt ?? undefined;
    return message;
  },
};

function createBaseDeploymentFeatures(): DeploymentFeatures {
  return {
    featureFlags: [],
    hasAutofix: false,
    hasDeepsemgrep: false,
    hasTriageViaComment: false,
    hasDependencyQuery: false,
    defaultUserRole: OrgRole.UNSPECIFIED,
    editorSemgrepVersion: "",
  };
}

export const DeploymentFeatures = {
  encode(message: DeploymentFeatures, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.featureFlags) {
      FeatureFlag.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.hasAutofix !== false) {
      writer.uint32(16).bool(message.hasAutofix);
    }
    if (message.hasDeepsemgrep !== false) {
      writer.uint32(24).bool(message.hasDeepsemgrep);
    }
    if (message.hasTriageViaComment !== false) {
      writer.uint32(32).bool(message.hasTriageViaComment);
    }
    if (message.hasDependencyQuery !== false) {
      writer.uint32(40).bool(message.hasDependencyQuery);
    }
    if (message.defaultUserRole !== OrgRole.UNSPECIFIED) {
      writer.uint32(48).int32(orgRoleToNumber(message.defaultUserRole));
    }
    if (message.editorSemgrepVersion !== "") {
      writer.uint32(58).string(message.editorSemgrepVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeploymentFeatures {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeploymentFeatures();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.featureFlags.push(FeatureFlag.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasAutofix = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hasDeepsemgrep = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.hasTriageViaComment = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.hasDependencyQuery = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.defaultUserRole = orgRoleFromJSON(reader.int32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.editorSemgrepVersion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeploymentFeatures {
    return {
      featureFlags: globalThis.Array.isArray(object?.featureFlags)
        ? object.featureFlags.map((e: any) => FeatureFlag.fromJSON(e))
        : [],
      hasAutofix: isSet(object.hasAutofix) ? globalThis.Boolean(object.hasAutofix) : false,
      hasDeepsemgrep: isSet(object.hasDeepsemgrep) ? globalThis.Boolean(object.hasDeepsemgrep) : false,
      hasTriageViaComment: isSet(object.hasTriageViaComment) ? globalThis.Boolean(object.hasTriageViaComment) : false,
      hasDependencyQuery: isSet(object.hasDependencyQuery) ? globalThis.Boolean(object.hasDependencyQuery) : false,
      defaultUserRole: isSet(object.defaultUserRole) ? orgRoleFromJSON(object.defaultUserRole) : OrgRole.UNSPECIFIED,
      editorSemgrepVersion: isSet(object.editorSemgrepVersion) ? globalThis.String(object.editorSemgrepVersion) : "",
    };
  },

  toJSON(message: DeploymentFeatures): unknown {
    const obj: any = {};
    if (message.featureFlags?.length) {
      obj.featureFlags = message.featureFlags.map((e) => FeatureFlag.toJSON(e));
    }
    if (message.hasAutofix !== false) {
      obj.hasAutofix = message.hasAutofix;
    }
    if (message.hasDeepsemgrep !== false) {
      obj.hasDeepsemgrep = message.hasDeepsemgrep;
    }
    if (message.hasTriageViaComment !== false) {
      obj.hasTriageViaComment = message.hasTriageViaComment;
    }
    if (message.hasDependencyQuery !== false) {
      obj.hasDependencyQuery = message.hasDependencyQuery;
    }
    if (message.defaultUserRole !== OrgRole.UNSPECIFIED) {
      obj.defaultUserRole = orgRoleToJSON(message.defaultUserRole);
    }
    if (message.editorSemgrepVersion !== "") {
      obj.editorSemgrepVersion = message.editorSemgrepVersion;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeploymentFeatures>, I>>(base?: I): DeploymentFeatures {
    return DeploymentFeatures.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeploymentFeatures>, I>>(object: I): DeploymentFeatures {
    const message = createBaseDeploymentFeatures();
    message.featureFlags = object.featureFlags?.map((e) => FeatureFlag.fromPartial(e)) || [];
    message.hasAutofix = object.hasAutofix ?? false;
    message.hasDeepsemgrep = object.hasDeepsemgrep ?? false;
    message.hasTriageViaComment = object.hasTriageViaComment ?? false;
    message.hasDependencyQuery = object.hasDependencyQuery ?? false;
    message.defaultUserRole = object.defaultUserRole ?? OrgRole.UNSPECIFIED;
    message.editorSemgrepVersion = object.editorSemgrepVersion ?? "";
    return message;
  },
};

function createBaseDeployment(): Deployment {
  return { id: 0, name: "", displayName: "", slug: "", sourceType: "", features: undefined, organizationId: undefined };
}

export const Deployment = {
  encode(message: Deployment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.displayName !== "") {
      writer.uint32(26).string(message.displayName);
    }
    if (message.slug !== "") {
      writer.uint32(34).string(message.slug);
    }
    if (message.sourceType !== "") {
      writer.uint32(42).string(message.sourceType);
    }
    if (message.features !== undefined) {
      DeploymentFeatures.encode(message.features, writer.uint32(50).fork()).ldelim();
    }
    if (message.organizationId !== undefined) {
      writer.uint32(56).uint64(message.organizationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Deployment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeployment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.slug = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sourceType = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.features = DeploymentFeatures.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.organizationId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Deployment {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      displayName: isSet(object.displayName) ? globalThis.String(object.displayName) : "",
      slug: isSet(object.slug) ? globalThis.String(object.slug) : "",
      sourceType: isSet(object.sourceType) ? globalThis.String(object.sourceType) : "",
      features: isSet(object.features) ? DeploymentFeatures.fromJSON(object.features) : undefined,
      organizationId: isSet(object.organizationId) ? globalThis.Number(object.organizationId) : undefined,
    };
  },

  toJSON(message: Deployment): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    if (message.slug !== "") {
      obj.slug = message.slug;
    }
    if (message.sourceType !== "") {
      obj.sourceType = message.sourceType;
    }
    if (message.features !== undefined) {
      obj.features = DeploymentFeatures.toJSON(message.features);
    }
    if (message.organizationId !== undefined) {
      obj.organizationId = Math.round(message.organizationId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Deployment>, I>>(base?: I): Deployment {
    return Deployment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Deployment>, I>>(object: I): Deployment {
    const message = createBaseDeployment();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.displayName = object.displayName ?? "";
    message.slug = object.slug ?? "";
    message.sourceType = object.sourceType ?? "";
    message.features = (object.features !== undefined && object.features !== null)
      ? DeploymentFeatures.fromPartial(object.features)
      : undefined;
    message.organizationId = object.organizationId ?? undefined;
    return message;
  },
};

export interface DeploymentService {
  getDeploymentUsers(request: GetDeploymentUsersRequest): Promise<GetDeploymentUsersResponse>;
  patchDeploymentUser(request: PatchDeploymentUserRequest): Promise<PatchDeploymentUserResponse>;
  sendDeploymentToggleFeedback(
    request: SendDeploymentToggleFeedbackRequest,
  ): Promise<SendDeploymentToggleFeedbackResponse>;
}

export const DeploymentServiceServiceName = "protos.deployment.v1.DeploymentService";
export class DeploymentServiceClientImpl implements DeploymentService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || DeploymentServiceServiceName;
    this.rpc = rpc;
    this.getDeploymentUsers = this.getDeploymentUsers.bind(this);
    this.patchDeploymentUser = this.patchDeploymentUser.bind(this);
    this.sendDeploymentToggleFeedback = this.sendDeploymentToggleFeedback.bind(this);
  }
  getDeploymentUsers(request: GetDeploymentUsersRequest): Promise<GetDeploymentUsersResponse> {
    const data = GetDeploymentUsersRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetDeploymentUsers", data);
    return promise.then((data) => GetDeploymentUsersResponse.decode(_m0.Reader.create(data)));
  }

  patchDeploymentUser(request: PatchDeploymentUserRequest): Promise<PatchDeploymentUserResponse> {
    const data = PatchDeploymentUserRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "PatchDeploymentUser", data);
    return promise.then((data) => PatchDeploymentUserResponse.decode(_m0.Reader.create(data)));
  }

  sendDeploymentToggleFeedback(
    request: SendDeploymentToggleFeedbackRequest,
  ): Promise<SendDeploymentToggleFeedbackResponse> {
    const data = SendDeploymentToggleFeedbackRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "SendDeploymentToggleFeedback", data);
    return promise.then((data) => SendDeploymentToggleFeedbackResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
