// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/common/v1/common.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "protos.common.v1";

/** Severity level ("seriousness" of the finding) */
export enum Severity {
  /** UNSPECIFIED - Return results for all severities (if used as a parameter). */
  UNSPECIFIED = "SEVERITY_UNSPECIFIED",
  HIGH = "SEVERITY_HIGH",
  MEDIUM = "SEVERITY_MEDIUM",
  LOW = "SEVERITY_LOW",
  CRITICAL = "SEVERITY_CRITICAL",
}

export function severityFromJSON(object: any): Severity {
  switch (object) {
    case 0:
    case "SEVERITY_UNSPECIFIED":
      return Severity.UNSPECIFIED;
    case 1:
    case "SEVERITY_HIGH":
      return Severity.HIGH;
    case 2:
    case "SEVERITY_MEDIUM":
      return Severity.MEDIUM;
    case 3:
    case "SEVERITY_LOW":
      return Severity.LOW;
    case 4:
    case "SEVERITY_CRITICAL":
      return Severity.CRITICAL;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Severity");
  }
}

export function severityToJSON(object: Severity): string {
  switch (object) {
    case Severity.UNSPECIFIED:
      return "SEVERITY_UNSPECIFIED";
    case Severity.HIGH:
      return "SEVERITY_HIGH";
    case Severity.MEDIUM:
      return "SEVERITY_MEDIUM";
    case Severity.LOW:
      return "SEVERITY_LOW";
    case Severity.CRITICAL:
      return "SEVERITY_CRITICAL";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Severity");
  }
}

export function severityToNumber(object: Severity): number {
  switch (object) {
    case Severity.UNSPECIFIED:
      return 0;
    case Severity.HIGH:
      return 1;
    case Severity.MEDIUM:
      return 2;
    case Severity.LOW:
      return 3;
    case Severity.CRITICAL:
      return 4;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Severity");
  }
}

/** Mode behavior: Monitor / Comment / Block / Disabled */
export enum Mode {
  UNSPECIFIED = "MODE_UNSPECIFIED",
  /** MONITOR - Monitor mode, silently report findings */
  MONITOR = "MODE_MONITOR",
  /** COMMENT - Comment mode, leaves PR comments but does not block */
  COMMENT = "MODE_COMMENT",
  /** BLOCK - Block mode, leaves PR comments and blocks PR */
  BLOCK = "MODE_BLOCK",
  /** DISABLED - Disabled mode, not active */
  DISABLED = "MODE_DISABLED",
}

export function modeFromJSON(object: any): Mode {
  switch (object) {
    case 0:
    case "MODE_UNSPECIFIED":
      return Mode.UNSPECIFIED;
    case 1:
    case "MODE_MONITOR":
      return Mode.MONITOR;
    case 2:
    case "MODE_COMMENT":
      return Mode.COMMENT;
    case 3:
    case "MODE_BLOCK":
      return Mode.BLOCK;
    case 4:
    case "MODE_DISABLED":
      return Mode.DISABLED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Mode");
  }
}

export function modeToJSON(object: Mode): string {
  switch (object) {
    case Mode.UNSPECIFIED:
      return "MODE_UNSPECIFIED";
    case Mode.MONITOR:
      return "MODE_MONITOR";
    case Mode.COMMENT:
      return "MODE_COMMENT";
    case Mode.BLOCK:
      return "MODE_BLOCK";
    case Mode.DISABLED:
      return "MODE_DISABLED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Mode");
  }
}

export function modeToNumber(object: Mode): number {
  switch (object) {
    case Mode.UNSPECIFIED:
      return 0;
    case Mode.MONITOR:
      return 1;
    case Mode.COMMENT:
      return 2;
    case Mode.BLOCK:
      return 3;
    case Mode.DISABLED:
      return 4;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Mode");
  }
}

/** The current status of a finding */
export enum FindingStatus {
  /** UNSPECIFIED - Return results for all finding statuses (if used as a parameter). */
  UNSPECIFIED = "FINDING_STATUS_UNSPECIFIED",
  /** OPEN - Finding is open and needs to be triaged */
  OPEN = "FINDING_STATUS_OPEN",
  /** IGNORED - Finding has been triaged and is being ignored */
  IGNORED = "FINDING_STATUS_IGNORED",
  /** FIXED - Finding has been fixed */
  FIXED = "FINDING_STATUS_FIXED",
  /** REMOVED - Finding has been removed */
  REMOVED = "FINDING_STATUS_REMOVED",
  /** UNKNOWN - Finding status is unknown */
  UNKNOWN = "FINDING_STATUS_UNKNOWN",
}

export function findingStatusFromJSON(object: any): FindingStatus {
  switch (object) {
    case 0:
    case "FINDING_STATUS_UNSPECIFIED":
      return FindingStatus.UNSPECIFIED;
    case 1:
    case "FINDING_STATUS_OPEN":
      return FindingStatus.OPEN;
    case 2:
    case "FINDING_STATUS_IGNORED":
      return FindingStatus.IGNORED;
    case 3:
    case "FINDING_STATUS_FIXED":
      return FindingStatus.FIXED;
    case 4:
    case "FINDING_STATUS_REMOVED":
      return FindingStatus.REMOVED;
    case 5:
    case "FINDING_STATUS_UNKNOWN":
      return FindingStatus.UNKNOWN;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum FindingStatus");
  }
}

export function findingStatusToJSON(object: FindingStatus): string {
  switch (object) {
    case FindingStatus.UNSPECIFIED:
      return "FINDING_STATUS_UNSPECIFIED";
    case FindingStatus.OPEN:
      return "FINDING_STATUS_OPEN";
    case FindingStatus.IGNORED:
      return "FINDING_STATUS_IGNORED";
    case FindingStatus.FIXED:
      return "FINDING_STATUS_FIXED";
    case FindingStatus.REMOVED:
      return "FINDING_STATUS_REMOVED";
    case FindingStatus.UNKNOWN:
      return "FINDING_STATUS_UNKNOWN";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum FindingStatus");
  }
}

export function findingStatusToNumber(object: FindingStatus): number {
  switch (object) {
    case FindingStatus.UNSPECIFIED:
      return 0;
    case FindingStatus.OPEN:
      return 1;
    case FindingStatus.IGNORED:
      return 2;
    case FindingStatus.FIXED:
      return 3;
    case FindingStatus.REMOVED:
      return 4;
    case FindingStatus.UNKNOWN:
      return 5;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum FindingStatus");
  }
}

/** The current triage status of a finding */
export enum FindingTriageState {
  /** UNSPECIFIED - Return results for all triage states (if used as a parameter). */
  UNSPECIFIED = "FINDING_TRIAGE_STATE_UNSPECIFIED",
  /** UNTRIAGED - Finding is open and needs to be triaged */
  UNTRIAGED = "FINDING_TRIAGE_STATE_UNTRIAGED",
  /** IGNORED - Finding has been triaged and is being ignored */
  IGNORED = "FINDING_TRIAGE_STATE_IGNORED",
  /** REOPENED - Finding was triaged and is reopened */
  REOPENED = "FINDING_TRIAGE_STATE_REOPENED",
  /** UNKNOWN - Finding status is unknown */
  UNKNOWN = "FINDING_TRIAGE_STATE_UNKNOWN",
  /** REVIEWING - Finding was triaged and is being reviewed */
  REVIEWING = "FINDING_TRIAGE_STATE_REVIEWING",
  /** FIXING - Finding was triaged and is being fixed */
  FIXING = "FINDING_TRIAGE_STATE_FIXING",
}

export function findingTriageStateFromJSON(object: any): FindingTriageState {
  switch (object) {
    case 0:
    case "FINDING_TRIAGE_STATE_UNSPECIFIED":
      return FindingTriageState.UNSPECIFIED;
    case 1:
    case "FINDING_TRIAGE_STATE_UNTRIAGED":
      return FindingTriageState.UNTRIAGED;
    case 2:
    case "FINDING_TRIAGE_STATE_IGNORED":
      return FindingTriageState.IGNORED;
    case 3:
    case "FINDING_TRIAGE_STATE_REOPENED":
      return FindingTriageState.REOPENED;
    case 4:
    case "FINDING_TRIAGE_STATE_UNKNOWN":
      return FindingTriageState.UNKNOWN;
    case 5:
    case "FINDING_TRIAGE_STATE_REVIEWING":
      return FindingTriageState.REVIEWING;
    case 6:
    case "FINDING_TRIAGE_STATE_FIXING":
      return FindingTriageState.FIXING;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum FindingTriageState");
  }
}

export function findingTriageStateToJSON(object: FindingTriageState): string {
  switch (object) {
    case FindingTriageState.UNSPECIFIED:
      return "FINDING_TRIAGE_STATE_UNSPECIFIED";
    case FindingTriageState.UNTRIAGED:
      return "FINDING_TRIAGE_STATE_UNTRIAGED";
    case FindingTriageState.IGNORED:
      return "FINDING_TRIAGE_STATE_IGNORED";
    case FindingTriageState.REOPENED:
      return "FINDING_TRIAGE_STATE_REOPENED";
    case FindingTriageState.UNKNOWN:
      return "FINDING_TRIAGE_STATE_UNKNOWN";
    case FindingTriageState.REVIEWING:
      return "FINDING_TRIAGE_STATE_REVIEWING";
    case FindingTriageState.FIXING:
      return "FINDING_TRIAGE_STATE_FIXING";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum FindingTriageState");
  }
}

export function findingTriageStateToNumber(object: FindingTriageState): number {
  switch (object) {
    case FindingTriageState.UNSPECIFIED:
      return 0;
    case FindingTriageState.UNTRIAGED:
      return 1;
    case FindingTriageState.IGNORED:
      return 2;
    case FindingTriageState.REOPENED:
      return 3;
    case FindingTriageState.UNKNOWN:
      return 4;
    case FindingTriageState.REVIEWING:
      return 5;
    case FindingTriageState.FIXING:
      return 6;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum FindingTriageState");
  }
}

/** The reason a finding was triaged */
export enum FindingTriageReason {
  UNSPECIFIED = "FINDING_TRIAGE_REASON_UNSPECIFIED",
  /** FALSE_POSITIVE - Finding was triaged as false positive */
  FALSE_POSITIVE = "FINDING_TRIAGE_REASON_FALSE_POSITIVE",
  /** NO_TIME - Finding was triaged as won't fix */
  NO_TIME = "FINDING_TRIAGE_REASON_NO_TIME",
  /** ACCEPTABLE_RISK - Finding was triaged as risk accepted */
  ACCEPTABLE_RISK = "FINDING_TRIAGE_REASON_ACCEPTABLE_RISK",
  /** NO_TRIAGE_REASON - Finding does not have a triage reason */
  NO_TRIAGE_REASON = "FINDING_TRIAGE_REASON_NO_TRIAGE_REASON",
  /** UNKNOWN - Finding triage reason is unknown */
  UNKNOWN = "FINDING_TRIAGE_REASON_UNKNOWN",
}

export function findingTriageReasonFromJSON(object: any): FindingTriageReason {
  switch (object) {
    case 0:
    case "FINDING_TRIAGE_REASON_UNSPECIFIED":
      return FindingTriageReason.UNSPECIFIED;
    case 1:
    case "FINDING_TRIAGE_REASON_FALSE_POSITIVE":
      return FindingTriageReason.FALSE_POSITIVE;
    case 2:
    case "FINDING_TRIAGE_REASON_NO_TIME":
      return FindingTriageReason.NO_TIME;
    case 3:
    case "FINDING_TRIAGE_REASON_ACCEPTABLE_RISK":
      return FindingTriageReason.ACCEPTABLE_RISK;
    case 4:
    case "FINDING_TRIAGE_REASON_NO_TRIAGE_REASON":
      return FindingTriageReason.NO_TRIAGE_REASON;
    case 5:
    case "FINDING_TRIAGE_REASON_UNKNOWN":
      return FindingTriageReason.UNKNOWN;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum FindingTriageReason");
  }
}

export function findingTriageReasonToJSON(object: FindingTriageReason): string {
  switch (object) {
    case FindingTriageReason.UNSPECIFIED:
      return "FINDING_TRIAGE_REASON_UNSPECIFIED";
    case FindingTriageReason.FALSE_POSITIVE:
      return "FINDING_TRIAGE_REASON_FALSE_POSITIVE";
    case FindingTriageReason.NO_TIME:
      return "FINDING_TRIAGE_REASON_NO_TIME";
    case FindingTriageReason.ACCEPTABLE_RISK:
      return "FINDING_TRIAGE_REASON_ACCEPTABLE_RISK";
    case FindingTriageReason.NO_TRIAGE_REASON:
      return "FINDING_TRIAGE_REASON_NO_TRIAGE_REASON";
    case FindingTriageReason.UNKNOWN:
      return "FINDING_TRIAGE_REASON_UNKNOWN";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum FindingTriageReason");
  }
}

export function findingTriageReasonToNumber(object: FindingTriageReason): number {
  switch (object) {
    case FindingTriageReason.UNSPECIFIED:
      return 0;
    case FindingTriageReason.FALSE_POSITIVE:
      return 1;
    case FindingTriageReason.NO_TIME:
      return 2;
    case FindingTriageReason.ACCEPTABLE_RISK:
      return 3;
    case FindingTriageReason.NO_TRIAGE_REASON:
      return 4;
    case FindingTriageReason.UNKNOWN:
      return 5;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum FindingTriageReason");
  }
}

export enum IssueStatus {
  UNSPECIFIED = "ISSUE_STATUS_UNSPECIFIED",
  FIXED = "ISSUE_STATUS_FIXED",
  MUTED = "ISSUE_STATUS_MUTED",
  REMOVED = "ISSUE_STATUS_REMOVED",
  UNRESOLVED = "ISSUE_STATUS_UNRESOLVED",
}

export function issueStatusFromJSON(object: any): IssueStatus {
  switch (object) {
    case 0:
    case "ISSUE_STATUS_UNSPECIFIED":
      return IssueStatus.UNSPECIFIED;
    case 1:
    case "ISSUE_STATUS_FIXED":
      return IssueStatus.FIXED;
    case 2:
    case "ISSUE_STATUS_MUTED":
      return IssueStatus.MUTED;
    case 3:
    case "ISSUE_STATUS_REMOVED":
      return IssueStatus.REMOVED;
    case 4:
    case "ISSUE_STATUS_UNRESOLVED":
      return IssueStatus.UNRESOLVED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum IssueStatus");
  }
}

export function issueStatusToJSON(object: IssueStatus): string {
  switch (object) {
    case IssueStatus.UNSPECIFIED:
      return "ISSUE_STATUS_UNSPECIFIED";
    case IssueStatus.FIXED:
      return "ISSUE_STATUS_FIXED";
    case IssueStatus.MUTED:
      return "ISSUE_STATUS_MUTED";
    case IssueStatus.REMOVED:
      return "ISSUE_STATUS_REMOVED";
    case IssueStatus.UNRESOLVED:
      return "ISSUE_STATUS_UNRESOLVED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum IssueStatus");
  }
}

export function issueStatusToNumber(object: IssueStatus): number {
  switch (object) {
    case IssueStatus.UNSPECIFIED:
      return 0;
    case IssueStatus.FIXED:
      return 1;
    case IssueStatus.MUTED:
      return 2;
    case IssueStatus.REMOVED:
      return 3;
    case IssueStatus.UNRESOLVED:
      return 4;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum IssueStatus");
  }
}

/** Confidence in false-positive rate */
export enum Confidence {
  UNSPECIFIED = "CONFIDENCE_UNSPECIFIED",
  HIGH = "CONFIDENCE_HIGH",
  MEDIUM = "CONFIDENCE_MEDIUM",
  LOW = "CONFIDENCE_LOW",
}

export function confidenceFromJSON(object: any): Confidence {
  switch (object) {
    case 0:
    case "CONFIDENCE_UNSPECIFIED":
      return Confidence.UNSPECIFIED;
    case 1:
    case "CONFIDENCE_HIGH":
      return Confidence.HIGH;
    case 2:
    case "CONFIDENCE_MEDIUM":
      return Confidence.MEDIUM;
    case 3:
    case "CONFIDENCE_LOW":
      return Confidence.LOW;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Confidence");
  }
}

export function confidenceToJSON(object: Confidence): string {
  switch (object) {
    case Confidence.UNSPECIFIED:
      return "CONFIDENCE_UNSPECIFIED";
    case Confidence.HIGH:
      return "CONFIDENCE_HIGH";
    case Confidence.MEDIUM:
      return "CONFIDENCE_MEDIUM";
    case Confidence.LOW:
      return "CONFIDENCE_LOW";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Confidence");
  }
}

export function confidenceToNumber(object: Confidence): number {
  switch (object) {
    case Confidence.UNSPECIFIED:
      return 0;
    case Confidence.HIGH:
      return 1;
    case Confidence.MEDIUM:
      return 2;
    case Confidence.LOW:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Confidence");
  }
}

/** Source of the rule (pro rules, semgrep community, or a custom rule) */
export enum Source {
  UNSPECIFIED = "SOURCE_UNSPECIFIED",
  PRO = "SOURCE_PRO",
  COMMUNITY = "SOURCE_COMMUNITY",
  CUSTOM = "SOURCE_CUSTOM",
}

export function sourceFromJSON(object: any): Source {
  switch (object) {
    case 0:
    case "SOURCE_UNSPECIFIED":
      return Source.UNSPECIFIED;
    case 1:
    case "SOURCE_PRO":
      return Source.PRO;
    case 2:
    case "SOURCE_COMMUNITY":
      return Source.COMMUNITY;
    case 3:
    case "SOURCE_CUSTOM":
      return Source.CUSTOM;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Source");
  }
}

export function sourceToJSON(object: Source): string {
  switch (object) {
    case Source.UNSPECIFIED:
      return "SOURCE_UNSPECIFIED";
    case Source.PRO:
      return "SOURCE_PRO";
    case Source.COMMUNITY:
      return "SOURCE_COMMUNITY";
    case Source.CUSTOM:
      return "SOURCE_CUSTOM";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Source");
  }
}

export function sourceToNumber(object: Source): number {
  switch (object) {
    case Source.UNSPECIFIED:
      return 0;
    case Source.PRO:
      return 1;
    case Source.COMMUNITY:
      return 2;
    case Source.CUSTOM:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Source");
  }
}

export enum RepositoryVisibility {
  UNSPECIFIED = "REPOSITORY_VISIBILITY_UNSPECIFIED",
  PUBLIC = "REPOSITORY_VISIBILITY_PUBLIC",
  PRIVATE = "REPOSITORY_VISIBILITY_PRIVATE",
  UNKNOWN = "REPOSITORY_VISIBILITY_UNKNOWN",
}

export function repositoryVisibilityFromJSON(object: any): RepositoryVisibility {
  switch (object) {
    case 0:
    case "REPOSITORY_VISIBILITY_UNSPECIFIED":
      return RepositoryVisibility.UNSPECIFIED;
    case 1:
    case "REPOSITORY_VISIBILITY_PUBLIC":
      return RepositoryVisibility.PUBLIC;
    case 2:
    case "REPOSITORY_VISIBILITY_PRIVATE":
      return RepositoryVisibility.PRIVATE;
    case 3:
    case "REPOSITORY_VISIBILITY_UNKNOWN":
      return RepositoryVisibility.UNKNOWN;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RepositoryVisibility");
  }
}

export function repositoryVisibilityToJSON(object: RepositoryVisibility): string {
  switch (object) {
    case RepositoryVisibility.UNSPECIFIED:
      return "REPOSITORY_VISIBILITY_UNSPECIFIED";
    case RepositoryVisibility.PUBLIC:
      return "REPOSITORY_VISIBILITY_PUBLIC";
    case RepositoryVisibility.PRIVATE:
      return "REPOSITORY_VISIBILITY_PRIVATE";
    case RepositoryVisibility.UNKNOWN:
      return "REPOSITORY_VISIBILITY_UNKNOWN";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RepositoryVisibility");
  }
}

export function repositoryVisibilityToNumber(object: RepositoryVisibility): number {
  switch (object) {
    case RepositoryVisibility.UNSPECIFIED:
      return 0;
    case RepositoryVisibility.PUBLIC:
      return 1;
    case RepositoryVisibility.PRIVATE:
      return 2;
    case RepositoryVisibility.UNKNOWN:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RepositoryVisibility");
  }
}

/** Source code repository provider */
export enum ScmType {
  UNSPECIFIED = "SCM_TYPE_UNSPECIFIED",
  /** GITHUB - Github cloud */
  GITHUB = "SCM_TYPE_GITHUB",
  /** GITLAB - Gitlab cloud */
  GITLAB = "SCM_TYPE_GITLAB",
  GITHUB_ENTERPRISE = "SCM_TYPE_GITHUB_ENTERPRISE",
  GITLAB_SELFMANAGED = "SCM_TYPE_GITLAB_SELFMANAGED",
  BITBUCKET = "SCM_TYPE_BITBUCKET",
  AZURE_DEVOPS = "SCM_TYPE_AZURE_DEVOPS",
  UNKNOWN = "SCM_TYPE_UNKNOWN",
  BITBUCKET_DATACENTER = "SCM_TYPE_BITBUCKET_DATACENTER",
}

export function scmTypeFromJSON(object: any): ScmType {
  switch (object) {
    case 0:
    case "SCM_TYPE_UNSPECIFIED":
      return ScmType.UNSPECIFIED;
    case 1:
    case "SCM_TYPE_GITHUB":
      return ScmType.GITHUB;
    case 2:
    case "SCM_TYPE_GITLAB":
      return ScmType.GITLAB;
    case 3:
    case "SCM_TYPE_GITHUB_ENTERPRISE":
      return ScmType.GITHUB_ENTERPRISE;
    case 4:
    case "SCM_TYPE_GITLAB_SELFMANAGED":
      return ScmType.GITLAB_SELFMANAGED;
    case 5:
    case "SCM_TYPE_BITBUCKET":
      return ScmType.BITBUCKET;
    case 6:
    case "SCM_TYPE_AZURE_DEVOPS":
      return ScmType.AZURE_DEVOPS;
    case 7:
    case "SCM_TYPE_UNKNOWN":
      return ScmType.UNKNOWN;
    case 8:
    case "SCM_TYPE_BITBUCKET_DATACENTER":
      return ScmType.BITBUCKET_DATACENTER;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ScmType");
  }
}

export function scmTypeToJSON(object: ScmType): string {
  switch (object) {
    case ScmType.UNSPECIFIED:
      return "SCM_TYPE_UNSPECIFIED";
    case ScmType.GITHUB:
      return "SCM_TYPE_GITHUB";
    case ScmType.GITLAB:
      return "SCM_TYPE_GITLAB";
    case ScmType.GITHUB_ENTERPRISE:
      return "SCM_TYPE_GITHUB_ENTERPRISE";
    case ScmType.GITLAB_SELFMANAGED:
      return "SCM_TYPE_GITLAB_SELFMANAGED";
    case ScmType.BITBUCKET:
      return "SCM_TYPE_BITBUCKET";
    case ScmType.AZURE_DEVOPS:
      return "SCM_TYPE_AZURE_DEVOPS";
    case ScmType.UNKNOWN:
      return "SCM_TYPE_UNKNOWN";
    case ScmType.BITBUCKET_DATACENTER:
      return "SCM_TYPE_BITBUCKET_DATACENTER";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ScmType");
  }
}

export function scmTypeToNumber(object: ScmType): number {
  switch (object) {
    case ScmType.UNSPECIFIED:
      return 0;
    case ScmType.GITHUB:
      return 1;
    case ScmType.GITLAB:
      return 2;
    case ScmType.GITHUB_ENTERPRISE:
      return 3;
    case ScmType.GITLAB_SELFMANAGED:
      return 4;
    case ScmType.BITBUCKET:
      return 5;
    case ScmType.AZURE_DEVOPS:
      return 6;
    case ScmType.UNKNOWN:
      return 7;
    case ScmType.BITBUCKET_DATACENTER:
      return 8;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ScmType");
  }
}

/** The type of organization entity in Github */
export enum GithubEntityType {
  UNSPECIFIED = "GITHUB_ENTITY_TYPE_UNSPECIFIED",
  ORG = "GITHUB_ENTITY_TYPE_ORG",
  USER = "GITHUB_ENTITY_TYPE_USER",
}

export function githubEntityTypeFromJSON(object: any): GithubEntityType {
  switch (object) {
    case 0:
    case "GITHUB_ENTITY_TYPE_UNSPECIFIED":
      return GithubEntityType.UNSPECIFIED;
    case 1:
    case "GITHUB_ENTITY_TYPE_ORG":
      return GithubEntityType.ORG;
    case 2:
    case "GITHUB_ENTITY_TYPE_USER":
      return GithubEntityType.USER;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum GithubEntityType");
  }
}

export function githubEntityTypeToJSON(object: GithubEntityType): string {
  switch (object) {
    case GithubEntityType.UNSPECIFIED:
      return "GITHUB_ENTITY_TYPE_UNSPECIFIED";
    case GithubEntityType.ORG:
      return "GITHUB_ENTITY_TYPE_ORG";
    case GithubEntityType.USER:
      return "GITHUB_ENTITY_TYPE_USER";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum GithubEntityType");
  }
}

export function githubEntityTypeToNumber(object: GithubEntityType): number {
  switch (object) {
    case GithubEntityType.UNSPECIFIED:
      return 0;
    case GithubEntityType.ORG:
      return 1;
    case GithubEntityType.USER:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum GithubEntityType");
  }
}

/** The type of organization entity in Gitlab */
export enum GitlabEntityType {
  UNSPECIFIED = "GITLAB_ENTITY_TYPE_UNSPECIFIED",
  GROUP = "GITLAB_ENTITY_TYPE_GROUP",
  USER = "GITLAB_ENTITY_TYPE_USER",
}

export function gitlabEntityTypeFromJSON(object: any): GitlabEntityType {
  switch (object) {
    case 0:
    case "GITLAB_ENTITY_TYPE_UNSPECIFIED":
      return GitlabEntityType.UNSPECIFIED;
    case 1:
    case "GITLAB_ENTITY_TYPE_GROUP":
      return GitlabEntityType.GROUP;
    case 2:
    case "GITLAB_ENTITY_TYPE_USER":
      return GitlabEntityType.USER;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum GitlabEntityType");
  }
}

export function gitlabEntityTypeToJSON(object: GitlabEntityType): string {
  switch (object) {
    case GitlabEntityType.UNSPECIFIED:
      return "GITLAB_ENTITY_TYPE_UNSPECIFIED";
    case GitlabEntityType.GROUP:
      return "GITLAB_ENTITY_TYPE_GROUP";
    case GitlabEntityType.USER:
      return "GITLAB_ENTITY_TYPE_USER";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum GitlabEntityType");
  }
}

export function gitlabEntityTypeToNumber(object: GitlabEntityType): number {
  switch (object) {
    case GitlabEntityType.UNSPECIFIED:
      return 0;
    case GitlabEntityType.GROUP:
      return 1;
    case GitlabEntityType.USER:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum GitlabEntityType");
  }
}

/** The type of organization entity in Bitbucket Data Center */
export enum BitbucketDataCenterEntityType {
  UNSPECIFIED = "BITBUCKET_DATA_CENTER_ENTITY_TYPE_UNSPECIFIED",
  NORMAL = "BITBUCKET_DATA_CENTER_ENTITY_TYPE_NORMAL",
  PERSONAL = "BITBUCKET_DATA_CENTER_ENTITY_TYPE_PERSONAL",
}

export function bitbucketDataCenterEntityTypeFromJSON(object: any): BitbucketDataCenterEntityType {
  switch (object) {
    case 0:
    case "BITBUCKET_DATA_CENTER_ENTITY_TYPE_UNSPECIFIED":
      return BitbucketDataCenterEntityType.UNSPECIFIED;
    case 1:
    case "BITBUCKET_DATA_CENTER_ENTITY_TYPE_NORMAL":
      return BitbucketDataCenterEntityType.NORMAL;
    case 2:
    case "BITBUCKET_DATA_CENTER_ENTITY_TYPE_PERSONAL":
      return BitbucketDataCenterEntityType.PERSONAL;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum BitbucketDataCenterEntityType");
  }
}

export function bitbucketDataCenterEntityTypeToJSON(object: BitbucketDataCenterEntityType): string {
  switch (object) {
    case BitbucketDataCenterEntityType.UNSPECIFIED:
      return "BITBUCKET_DATA_CENTER_ENTITY_TYPE_UNSPECIFIED";
    case BitbucketDataCenterEntityType.NORMAL:
      return "BITBUCKET_DATA_CENTER_ENTITY_TYPE_NORMAL";
    case BitbucketDataCenterEntityType.PERSONAL:
      return "BITBUCKET_DATA_CENTER_ENTITY_TYPE_PERSONAL";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum BitbucketDataCenterEntityType");
  }
}

export function bitbucketDataCenterEntityTypeToNumber(object: BitbucketDataCenterEntityType): number {
  switch (object) {
    case BitbucketDataCenterEntityType.UNSPECIFIED:
      return 0;
    case BitbucketDataCenterEntityType.NORMAL:
      return 1;
    case BitbucketDataCenterEntityType.PERSONAL:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum BitbucketDataCenterEntityType");
  }
}

/** The type of organization entity in Bitbucket Cloud */
export enum BitbucketCloudEntityType {
  UNSPECIFIED = "BITBUCKET_CLOUD_ENTITY_TYPE_UNSPECIFIED",
  USER = "BITBUCKET_CLOUD_ENTITY_TYPE_USER",
  WORKSPACE = "BITBUCKET_CLOUD_ENTITY_TYPE_WORKSPACE",
}

export function bitbucketCloudEntityTypeFromJSON(object: any): BitbucketCloudEntityType {
  switch (object) {
    case 0:
    case "BITBUCKET_CLOUD_ENTITY_TYPE_UNSPECIFIED":
      return BitbucketCloudEntityType.UNSPECIFIED;
    case 1:
    case "BITBUCKET_CLOUD_ENTITY_TYPE_USER":
      return BitbucketCloudEntityType.USER;
    case 2:
    case "BITBUCKET_CLOUD_ENTITY_TYPE_WORKSPACE":
      return BitbucketCloudEntityType.WORKSPACE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum BitbucketCloudEntityType");
  }
}

export function bitbucketCloudEntityTypeToJSON(object: BitbucketCloudEntityType): string {
  switch (object) {
    case BitbucketCloudEntityType.UNSPECIFIED:
      return "BITBUCKET_CLOUD_ENTITY_TYPE_UNSPECIFIED";
    case BitbucketCloudEntityType.USER:
      return "BITBUCKET_CLOUD_ENTITY_TYPE_USER";
    case BitbucketCloudEntityType.WORKSPACE:
      return "BITBUCKET_CLOUD_ENTITY_TYPE_WORKSPACE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum BitbucketCloudEntityType");
  }
}

export function bitbucketCloudEntityTypeToNumber(object: BitbucketCloudEntityType): number {
  switch (object) {
    case BitbucketCloudEntityType.UNSPECIFIED:
      return 0;
    case BitbucketCloudEntityType.USER:
      return 1;
    case BitbucketCloudEntityType.WORKSPACE:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum BitbucketCloudEntityType");
  }
}

/** The type of organization entity in Azure Devops */
export enum AzureDevopsEntityType {
  UNSPECIFIED = "AZURE_DEVOPS_ENTITY_TYPE_UNSPECIFIED",
  NORMAL = "AZURE_DEVOPS_ENTITY_TYPE_NORMAL",
}

export function azureDevopsEntityTypeFromJSON(object: any): AzureDevopsEntityType {
  switch (object) {
    case 0:
    case "AZURE_DEVOPS_ENTITY_TYPE_UNSPECIFIED":
      return AzureDevopsEntityType.UNSPECIFIED;
    case 1:
    case "AZURE_DEVOPS_ENTITY_TYPE_NORMAL":
      return AzureDevopsEntityType.NORMAL;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AzureDevopsEntityType");
  }
}

export function azureDevopsEntityTypeToJSON(object: AzureDevopsEntityType): string {
  switch (object) {
    case AzureDevopsEntityType.UNSPECIFIED:
      return "AZURE_DEVOPS_ENTITY_TYPE_UNSPECIFIED";
    case AzureDevopsEntityType.NORMAL:
      return "AZURE_DEVOPS_ENTITY_TYPE_NORMAL";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AzureDevopsEntityType");
  }
}

export function azureDevopsEntityTypeToNumber(object: AzureDevopsEntityType): number {
  switch (object) {
    case AzureDevopsEntityType.UNSPECIFIED:
      return 0;
    case AzureDevopsEntityType.NORMAL:
      return 1;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AzureDevopsEntityType");
  }
}

export enum SsoType {
  UNSPECIFIED = "SSO_TYPE_UNSPECIFIED",
  GITHUB = "SSO_TYPE_GITHUB",
  OPENID = "SSO_TYPE_OPENID",
  SAML2 = "SSO_TYPE_SAML2",
  GITLAB = "SSO_TYPE_GITLAB",
  GOOGLE = "SSO_TYPE_GOOGLE",
  SIMPLE = "SSO_TYPE_SIMPLE",
}

export function ssoTypeFromJSON(object: any): SsoType {
  switch (object) {
    case 0:
    case "SSO_TYPE_UNSPECIFIED":
      return SsoType.UNSPECIFIED;
    case 1:
    case "SSO_TYPE_GITHUB":
      return SsoType.GITHUB;
    case 2:
    case "SSO_TYPE_OPENID":
      return SsoType.OPENID;
    case 3:
    case "SSO_TYPE_SAML2":
      return SsoType.SAML2;
    case 4:
    case "SSO_TYPE_GITLAB":
      return SsoType.GITLAB;
    case 5:
    case "SSO_TYPE_GOOGLE":
      return SsoType.GOOGLE;
    case 6:
    case "SSO_TYPE_SIMPLE":
      return SsoType.SIMPLE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SsoType");
  }
}

export function ssoTypeToJSON(object: SsoType): string {
  switch (object) {
    case SsoType.UNSPECIFIED:
      return "SSO_TYPE_UNSPECIFIED";
    case SsoType.GITHUB:
      return "SSO_TYPE_GITHUB";
    case SsoType.OPENID:
      return "SSO_TYPE_OPENID";
    case SsoType.SAML2:
      return "SSO_TYPE_SAML2";
    case SsoType.GITLAB:
      return "SSO_TYPE_GITLAB";
    case SsoType.GOOGLE:
      return "SSO_TYPE_GOOGLE";
    case SsoType.SIMPLE:
      return "SSO_TYPE_SIMPLE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SsoType");
  }
}

export function ssoTypeToNumber(object: SsoType): number {
  switch (object) {
    case SsoType.UNSPECIFIED:
      return 0;
    case SsoType.GITHUB:
      return 1;
    case SsoType.OPENID:
      return 2;
    case SsoType.SAML2:
      return 3;
    case SsoType.GITLAB:
      return 4;
    case SsoType.GOOGLE:
      return 5;
    case SsoType.SIMPLE:
      return 6;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SsoType");
  }
}

export enum SortDirection {
  UNSPECIFIED = "SORT_DIRECTION_UNSPECIFIED",
  ASC = "SORT_DIRECTION_ASC",
  DESC = "SORT_DIRECTION_DESC",
}

export function sortDirectionFromJSON(object: any): SortDirection {
  switch (object) {
    case 0:
    case "SORT_DIRECTION_UNSPECIFIED":
      return SortDirection.UNSPECIFIED;
    case 1:
    case "SORT_DIRECTION_ASC":
      return SortDirection.ASC;
    case 2:
    case "SORT_DIRECTION_DESC":
      return SortDirection.DESC;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SortDirection");
  }
}

export function sortDirectionToJSON(object: SortDirection): string {
  switch (object) {
    case SortDirection.UNSPECIFIED:
      return "SORT_DIRECTION_UNSPECIFIED";
    case SortDirection.ASC:
      return "SORT_DIRECTION_ASC";
    case SortDirection.DESC:
      return "SORT_DIRECTION_DESC";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SortDirection");
  }
}

export function sortDirectionToNumber(object: SortDirection): number {
  switch (object) {
    case SortDirection.UNSPECIFIED:
      return 0;
    case SortDirection.ASC:
      return 1;
    case SortDirection.DESC:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SortDirection");
  }
}

export enum TriageSource {
  UNSPECIFIED = "TRIAGE_SOURCE_UNSPECIFIED",
  /** SCM_COMMENT - any SCM comment ignore command */
  SCM_COMMENT = "TRIAGE_SOURCE_SCM_COMMENT",
  /** WEB - any triage feature on the AppSec platform */
  WEB = "TRIAGE_SOURCE_WEB",
  /** WEB_ASSISTANT_AGREE - an "Agree and ignore" action from autotriage results */
  WEB_ASSISTANT_AGREE = "TRIAGE_SOURCE_WEB_ASSISTANT_AGREE",
}

export function triageSourceFromJSON(object: any): TriageSource {
  switch (object) {
    case 0:
    case "TRIAGE_SOURCE_UNSPECIFIED":
      return TriageSource.UNSPECIFIED;
    case 1:
    case "TRIAGE_SOURCE_SCM_COMMENT":
      return TriageSource.SCM_COMMENT;
    case 2:
    case "TRIAGE_SOURCE_WEB":
      return TriageSource.WEB;
    case 3:
    case "TRIAGE_SOURCE_WEB_ASSISTANT_AGREE":
      return TriageSource.WEB_ASSISTANT_AGREE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TriageSource");
  }
}

export function triageSourceToJSON(object: TriageSource): string {
  switch (object) {
    case TriageSource.UNSPECIFIED:
      return "TRIAGE_SOURCE_UNSPECIFIED";
    case TriageSource.SCM_COMMENT:
      return "TRIAGE_SOURCE_SCM_COMMENT";
    case TriageSource.WEB:
      return "TRIAGE_SOURCE_WEB";
    case TriageSource.WEB_ASSISTANT_AGREE:
      return "TRIAGE_SOURCE_WEB_ASSISTANT_AGREE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TriageSource");
  }
}

export function triageSourceToNumber(object: TriageSource): number {
  switch (object) {
    case TriageSource.UNSPECIFIED:
      return 0;
    case TriageSource.SCM_COMMENT:
      return 1;
    case TriageSource.WEB:
      return 2;
    case TriageSource.WEB_ASSISTANT_AGREE:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TriageSource");
  }
}

export enum UpdateErrorCode {
  UNSPECIFIED = "UPDATE_ERROR_CODE_UNSPECIFIED",
  BAD_REQUEST = "UPDATE_ERROR_CODE_BAD_REQUEST",
  NOT_FOUND = "UPDATE_ERROR_CODE_NOT_FOUND",
  CONFLICT = "UPDATE_ERROR_CODE_CONFLICT",
}

export function updateErrorCodeFromJSON(object: any): UpdateErrorCode {
  switch (object) {
    case 0:
    case "UPDATE_ERROR_CODE_UNSPECIFIED":
      return UpdateErrorCode.UNSPECIFIED;
    case 1:
    case "UPDATE_ERROR_CODE_BAD_REQUEST":
      return UpdateErrorCode.BAD_REQUEST;
    case 2:
    case "UPDATE_ERROR_CODE_NOT_FOUND":
      return UpdateErrorCode.NOT_FOUND;
    case 3:
    case "UPDATE_ERROR_CODE_CONFLICT":
      return UpdateErrorCode.CONFLICT;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum UpdateErrorCode");
  }
}

export function updateErrorCodeToJSON(object: UpdateErrorCode): string {
  switch (object) {
    case UpdateErrorCode.UNSPECIFIED:
      return "UPDATE_ERROR_CODE_UNSPECIFIED";
    case UpdateErrorCode.BAD_REQUEST:
      return "UPDATE_ERROR_CODE_BAD_REQUEST";
    case UpdateErrorCode.NOT_FOUND:
      return "UPDATE_ERROR_CODE_NOT_FOUND";
    case UpdateErrorCode.CONFLICT:
      return "UPDATE_ERROR_CODE_CONFLICT";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum UpdateErrorCode");
  }
}

export function updateErrorCodeToNumber(object: UpdateErrorCode): number {
  switch (object) {
    case UpdateErrorCode.UNSPECIFIED:
      return 0;
    case UpdateErrorCode.BAD_REQUEST:
      return 1;
    case UpdateErrorCode.NOT_FOUND:
      return 2;
    case UpdateErrorCode.CONFLICT:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum UpdateErrorCode");
  }
}

export enum Product {
  UNSPECIFIED = "PRODUCT_UNSPECIFIED",
  SAST = "PRODUCT_SAST",
  SCA = "PRODUCT_SCA",
  SECRETS = "PRODUCT_SECRETS",
}

export function productFromJSON(object: any): Product {
  switch (object) {
    case 0:
    case "PRODUCT_UNSPECIFIED":
      return Product.UNSPECIFIED;
    case 1:
    case "PRODUCT_SAST":
      return Product.SAST;
    case 2:
    case "PRODUCT_SCA":
      return Product.SCA;
    case 3:
    case "PRODUCT_SECRETS":
      return Product.SECRETS;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Product");
  }
}

export function productToJSON(object: Product): string {
  switch (object) {
    case Product.UNSPECIFIED:
      return "PRODUCT_UNSPECIFIED";
    case Product.SAST:
      return "PRODUCT_SAST";
    case Product.SCA:
      return "PRODUCT_SCA";
    case Product.SECRETS:
      return "PRODUCT_SECRETS";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Product");
  }
}

export function productToNumber(object: Product): number {
  switch (object) {
    case Product.UNSPECIFIED:
      return 0;
    case Product.SAST:
      return 1;
    case Product.SCA:
      return 2;
    case Product.SECRETS:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Product");
  }
}

/** A generic filter item to return to populate filters for a page */
export interface FilterItem {
  /** The value of the filter */
  value: string;
  /** The count of the filter */
  count: number;
}

export interface Cursor {
  token: string;
}

export interface PaginateOptions {
  optionalLimit: number;
  cursor: Cursor | undefined;
}

export interface TaskResultResponse {
  ready: boolean;
  successful: boolean;
  failed: boolean;
  value: string;
}

export interface TaskGroupResultResponse {
  ready: boolean;
  completedCount: number;
  totalCount: number;
  successfulValues: string[];
  failedValues: string[];
}

export interface FloatRange {
  /** Start of the range */
  min?:
    | number
    | undefined;
  /** End of the range */
  max?: number | undefined;
}

export interface BulkEndpointError {
  /** Type of error, generic enough to be used across resources and endpoints */
  code: UpdateErrorCode;
  /** More detailed, human-readable message that may be specific to a resource or endpoint */
  message: string;
  /** Unique identifiers of the objects that triggered the error (e.g. repo_id, issue_id, scan_id, etc.) */
  ids: number[];
}

function createBaseFilterItem(): FilterItem {
  return { value: "", count: 0 };
}

export const FilterItem = {
  encode(message: FilterItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    if (message.count !== 0) {
      writer.uint32(16).uint32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FilterItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilterItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.count = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FilterItem {
    return {
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
    };
  },

  toJSON(message: FilterItem): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FilterItem>, I>>(base?: I): FilterItem {
    return FilterItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FilterItem>, I>>(object: I): FilterItem {
    const message = createBaseFilterItem();
    message.value = object.value ?? "";
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseCursor(): Cursor {
  return { token: "" };
}

export const Cursor = {
  encode(message: Cursor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Cursor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCursor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Cursor {
    return { token: isSet(object.token) ? globalThis.String(object.token) : "" };
  },

  toJSON(message: Cursor): unknown {
    const obj: any = {};
    if (message.token !== "") {
      obj.token = message.token;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Cursor>, I>>(base?: I): Cursor {
    return Cursor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Cursor>, I>>(object: I): Cursor {
    const message = createBaseCursor();
    message.token = object.token ?? "";
    return message;
  },
};

function createBasePaginateOptions(): PaginateOptions {
  return { optionalLimit: 0, cursor: undefined };
}

export const PaginateOptions = {
  encode(message: PaginateOptions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionalLimit !== 0) {
      writer.uint32(8).uint32(message.optionalLimit);
    }
    if (message.cursor !== undefined) {
      Cursor.encode(message.cursor, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaginateOptions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaginateOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.optionalLimit = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cursor = Cursor.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaginateOptions {
    return {
      optionalLimit: isSet(object.optionalLimit) ? globalThis.Number(object.optionalLimit) : 0,
      cursor: isSet(object.cursor) ? Cursor.fromJSON(object.cursor) : undefined,
    };
  },

  toJSON(message: PaginateOptions): unknown {
    const obj: any = {};
    if (message.optionalLimit !== 0) {
      obj.optionalLimit = Math.round(message.optionalLimit);
    }
    if (message.cursor !== undefined) {
      obj.cursor = Cursor.toJSON(message.cursor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PaginateOptions>, I>>(base?: I): PaginateOptions {
    return PaginateOptions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaginateOptions>, I>>(object: I): PaginateOptions {
    const message = createBasePaginateOptions();
    message.optionalLimit = object.optionalLimit ?? 0;
    message.cursor = (object.cursor !== undefined && object.cursor !== null)
      ? Cursor.fromPartial(object.cursor)
      : undefined;
    return message;
  },
};

function createBaseTaskResultResponse(): TaskResultResponse {
  return { ready: false, successful: false, failed: false, value: "" };
}

export const TaskResultResponse = {
  encode(message: TaskResultResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ready !== false) {
      writer.uint32(8).bool(message.ready);
    }
    if (message.successful !== false) {
      writer.uint32(16).bool(message.successful);
    }
    if (message.failed !== false) {
      writer.uint32(24).bool(message.failed);
    }
    if (message.value !== "") {
      writer.uint32(34).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskResultResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTaskResultResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ready = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.successful = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.failed = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TaskResultResponse {
    return {
      ready: isSet(object.ready) ? globalThis.Boolean(object.ready) : false,
      successful: isSet(object.successful) ? globalThis.Boolean(object.successful) : false,
      failed: isSet(object.failed) ? globalThis.Boolean(object.failed) : false,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: TaskResultResponse): unknown {
    const obj: any = {};
    if (message.ready !== false) {
      obj.ready = message.ready;
    }
    if (message.successful !== false) {
      obj.successful = message.successful;
    }
    if (message.failed !== false) {
      obj.failed = message.failed;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TaskResultResponse>, I>>(base?: I): TaskResultResponse {
    return TaskResultResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TaskResultResponse>, I>>(object: I): TaskResultResponse {
    const message = createBaseTaskResultResponse();
    message.ready = object.ready ?? false;
    message.successful = object.successful ?? false;
    message.failed = object.failed ?? false;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseTaskGroupResultResponse(): TaskGroupResultResponse {
  return { ready: false, completedCount: 0, totalCount: 0, successfulValues: [], failedValues: [] };
}

export const TaskGroupResultResponse = {
  encode(message: TaskGroupResultResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ready !== false) {
      writer.uint32(8).bool(message.ready);
    }
    if (message.completedCount !== 0) {
      writer.uint32(16).uint64(message.completedCount);
    }
    if (message.totalCount !== 0) {
      writer.uint32(24).uint64(message.totalCount);
    }
    for (const v of message.successfulValues) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.failedValues) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskGroupResultResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTaskGroupResultResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ready = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.completedCount = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.totalCount = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.successfulValues.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.failedValues.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TaskGroupResultResponse {
    return {
      ready: isSet(object.ready) ? globalThis.Boolean(object.ready) : false,
      completedCount: isSet(object.completedCount) ? globalThis.Number(object.completedCount) : 0,
      totalCount: isSet(object.totalCount) ? globalThis.Number(object.totalCount) : 0,
      successfulValues: globalThis.Array.isArray(object?.successfulValues)
        ? object.successfulValues.map((e: any) => globalThis.String(e))
        : [],
      failedValues: globalThis.Array.isArray(object?.failedValues)
        ? object.failedValues.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: TaskGroupResultResponse): unknown {
    const obj: any = {};
    if (message.ready !== false) {
      obj.ready = message.ready;
    }
    if (message.completedCount !== 0) {
      obj.completedCount = Math.round(message.completedCount);
    }
    if (message.totalCount !== 0) {
      obj.totalCount = Math.round(message.totalCount);
    }
    if (message.successfulValues?.length) {
      obj.successfulValues = message.successfulValues;
    }
    if (message.failedValues?.length) {
      obj.failedValues = message.failedValues;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TaskGroupResultResponse>, I>>(base?: I): TaskGroupResultResponse {
    return TaskGroupResultResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TaskGroupResultResponse>, I>>(object: I): TaskGroupResultResponse {
    const message = createBaseTaskGroupResultResponse();
    message.ready = object.ready ?? false;
    message.completedCount = object.completedCount ?? 0;
    message.totalCount = object.totalCount ?? 0;
    message.successfulValues = object.successfulValues?.map((e) => e) || [];
    message.failedValues = object.failedValues?.map((e) => e) || [];
    return message;
  },
};

function createBaseFloatRange(): FloatRange {
  return { min: undefined, max: undefined };
}

export const FloatRange = {
  encode(message: FloatRange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.min !== undefined) {
      writer.uint32(13).float(message.min);
    }
    if (message.max !== undefined) {
      writer.uint32(21).float(message.max);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FloatRange {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFloatRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.min = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.max = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FloatRange {
    return {
      min: isSet(object.min) ? globalThis.Number(object.min) : undefined,
      max: isSet(object.max) ? globalThis.Number(object.max) : undefined,
    };
  },

  toJSON(message: FloatRange): unknown {
    const obj: any = {};
    if (message.min !== undefined) {
      obj.min = message.min;
    }
    if (message.max !== undefined) {
      obj.max = message.max;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FloatRange>, I>>(base?: I): FloatRange {
    return FloatRange.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FloatRange>, I>>(object: I): FloatRange {
    const message = createBaseFloatRange();
    message.min = object.min ?? undefined;
    message.max = object.max ?? undefined;
    return message;
  },
};

function createBaseBulkEndpointError(): BulkEndpointError {
  return { code: UpdateErrorCode.UNSPECIFIED, message: "", ids: [] };
}

export const BulkEndpointError = {
  encode(message: BulkEndpointError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== UpdateErrorCode.UNSPECIFIED) {
      writer.uint32(8).int32(updateErrorCodeToNumber(message.code));
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    writer.uint32(26).fork();
    for (const v of message.ids) {
      writer.uint64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkEndpointError {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkEndpointError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = updateErrorCodeFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag === 24) {
            message.ids.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.ids.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkEndpointError {
    return {
      code: isSet(object.code) ? updateErrorCodeFromJSON(object.code) : UpdateErrorCode.UNSPECIFIED,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: BulkEndpointError): unknown {
    const obj: any = {};
    if (message.code !== UpdateErrorCode.UNSPECIFIED) {
      obj.code = updateErrorCodeToJSON(message.code);
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.ids?.length) {
      obj.ids = message.ids.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkEndpointError>, I>>(base?: I): BulkEndpointError {
    return BulkEndpointError.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkEndpointError>, I>>(object: I): BulkEndpointError {
    const message = createBaseBulkEndpointError();
    message.code = object.code ?? UpdateErrorCode.UNSPECIFIED;
    message.message = object.message ?? "";
    message.ids = object.ids?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
