import {
  faBitbucket,
  faGithub,
  faGitlab,
  faMicrosoft,
} from "@fortawesome/free-brands-svg-icons";
import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";

import { ScmType } from "@protos/common/v1/common";

export const scmEntityName = (
  scmTypeName: string | null | undefined | ScmType
) => {
  switch (scmTypeName) {
    case "github":
    case "github_enterprise":
    case ScmType.GITHUB:
    case ScmType.GITHUB_ENTERPRISE:
      return "GitHub Organization";
    case "gitlab":
    case "gitlab_selfmanaged":
    case ScmType.GITLAB:
    case ScmType.GITLAB_SELFMANAGED:
      return "GitLab Group";
    case "azure_devops":
    case ScmType.AZURE_DEVOPS:
      return "Azure DevOps Organization";
    case "bitbucket":
    case ScmType.BITBUCKET:
      return "Bitbucket Workspace";
    case "bitbucket_datacenter":
    case ScmType.BITBUCKET_DATACENTER:
      return "Bitbucket Project (Key)";
    case "openid":
    default:
      return "Organization";
  }
};

export const scmTitle = (scmTypeName: string | null | undefined | ScmType) => {
  switch (scmTypeName) {
    case "github":
    case ScmType.GITHUB:
      return "GitHub";
    case "github_enterprise":
    case ScmType.GITHUB_ENTERPRISE:
      return "GitHub Enterprise";
    case "gitlab":
    case ScmType.GITLAB:
      return "GitLab";
    case "gitlab_selfmanaged":
    case ScmType.GITLAB_SELFMANAGED:
      return "GitLab Self-Managed";
    case "azure_devops":
    case ScmType.AZURE_DEVOPS:
      return "Azure DevOps";
    case "bitbucket":
    case ScmType.BITBUCKET:
      return "Bitbucket Cloud";
    case "bitbucket_datacenter":
    case ScmType.BITBUCKET_DATACENTER:
      return "Bitbucket Data Center";
    default:
      return "";
  }
};

export const getScmIcon = (
  scmTypeName: string | null | undefined | ScmType
) => {
  switch (scmTypeName) {
    case "github":
    case "github_enterprise":
    case ScmType.GITHUB:
    case ScmType.GITHUB_ENTERPRISE:
      return faGithub;
    case "gitlab":
    case "gitlab_selfmanaged":
    case ScmType.GITLAB:
    case ScmType.GITLAB_SELFMANAGED:
      return faGitlab;
    case "bitbucket":
    case "bitbucket_datacenter":
    case ScmType.BITBUCKET:
    case ScmType.BITBUCKET_DATACENTER:
      return faBitbucket;
    case "azure_devops":
    case ScmType.AZURE_DEVOPS:
      return faMicrosoft;
    default:
      return faCircleQuestion;
  }
};
