export const DOCS_PATTERN_LOGIC =
  "https://semgrep.dev/docs/writing-rules/pattern-logic/";

export const DOCS_PATTERN_SYNTAX =
  "https://semgrep.dev/docs/writing-rules/pattern-syntax/";

export const DOCS_SCAN_NEVER_FINISHED =
  "https://semgrep.dev/docs/troubleshooting/semgrep-app/#if-a-project-reports-a-scan-never-finished";

export const DOCS_SCAN_NEVER_STARTED =
  "https://semgrep.dev/docs/troubleshooting/semgrep-app/#if-a-project-reports-the-last-scan-never-started";

export const DOCS_SMS_SCAN_NEVER_STARTED =
  "https://semgrep.dev/docs/deployment/managed-scanning#default-configuration";

export const DOCS_EXIT_CODES =
  "https://semgrep.dev/docs/cli-reference/#exit-codes";
