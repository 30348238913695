import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import ReactTimeAgo from "react-time-ago";
import { faMessageSlash } from "@fortawesome/pro-duotone-svg-icons";
import {
  faClockRotateLeft,
  faMessageCode,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex, FloatingPosition, Text, Tooltip } from "@mantine/core";

dayjs.extend(utc);

export interface SeverityCardTimestampProps {
  date: Date;
  hasComments: boolean;
  hasAiSilencedComments?: boolean;
  isBlocking: boolean;
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
  direction?: "row" | "column";
  color?: string;
  fontSize?: string;
  tooltipPosition?: FloatingPosition;
  tooltipWithArrow?: boolean;
}

export const SeverityCardTimestamp: FC<SeverityCardTimestampProps> = ({
  date,
  hasComments,
  hasAiSilencedComments = false,
  isBlocking,
  direction = "row",
  color = "var(--mantine-color-gray-7)",
  wrap = "nowrap",
  fontSize = "xs",
  tooltipPosition,
  tooltipWithArrow = true,
}) => {
  const [message, setMessage] = useState<string>("");
  const effectiveColor = hasAiSilencedComments
    ? "var(--mantine-color-gray-7)"
    : color;

  useEffect(() => {
    const dateStr = dayjs.utc(date).format("ddd, D MMM YYYY HH:mm:ss"); // this matches the old format exactly and is easy to change if necessary

    let firstPart = "";
    if (isBlocking) {
      firstPart = "Left blocking comment";
    } else if (hasAiSilencedComments) {
      firstPart = "Assistant filtered comment";
    } else if (hasComments && !isBlocking) {
      firstPart = "Left comment";
    } else {
      firstPart = "Found";
    }
    setMessage(`${firstPart} on ${dateStr} UTC`);
  }, [date, hasAiSilencedComments, hasComments, isBlocking]);

  return (
    <Tooltip
      label={message}
      position={tooltipPosition}
      withArrow={tooltipWithArrow}
      withinPortal
    >
      <Flex
        direction={direction}
        gap={4}
        fz={fontSize}
        align="center"
        wrap={wrap}
      >
        <FontAwesomeIcon
          icon={getIcon(hasComments, hasAiSilencedComments, isBlocking)}
          color={effectiveColor}
          fixedWidth
        />
        <Text span inherit c={effectiveColor} miw={30} ta="center">
          <ReactTimeAgo date={date} timeStyle="mini" locale="en-US" />
        </Text>
      </Flex>
    </Tooltip>
  );
};
function getIcon(
  hasComments: boolean,
  hasAiSilencedComments: boolean,
  isBlocking: boolean
) {
  if (isBlocking) return faMessageCode;
  if (hasAiSilencedComments) return faMessageSlash;
  if (hasComments) return faMessageCode;
  return faClockRotateLeft;
}
