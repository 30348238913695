import { useCallback, useEffect, useState } from "react";
import unionWith from "lodash/unionWith";

import {
  GetSearchRequest,
  GetSearchResponse,
} from "@protos/search/v1/search_service";
import { authPost } from "@shared/api";
import { ApiError } from "@shared/types";

const fetchSearchPost = async (
  request: GetSearchRequest
): Promise<GetSearchResponse> => {
  return authPost<GetSearchRequest, GetSearchResponse>(
    `/api/console/search/${request.searchId}`,
    request
  );
};

// Merges the respository fields and keeps the metadata from the latest response
const mergeResponses = (
  oldData: GetSearchResponse | null,
  newData: GetSearchResponse
) => {
  const mergedData: GetSearchResponse = {
    ...newData,
    repositories: unionWith(
      newData.repositories,
      oldData?.repositories ?? [],
      (a, b) => a.repository?.fullName === b.repository?.fullName
    ),
  };
  return mergedData;
};

/*
 * Gets the results for a query console search ID.
 * Results are paginated using the cursor field.
 * We aren't using react-query for this because the results need to be merged
 * rather than concatenated in an array.
 */
export const useQueryConsoleResult = (searchId: string | null) => {
  // Store data mapped to searchId for caching as well as
  // to avoid showing stale data when the id changes
  const [data, setData] = useState<Record<string, GetSearchResponse | null>>(
    {}
  );
  const currentData = searchId ? data[searchId] : null;

  // The usual
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [error, setError] = useState<ApiError | null>(null);

  // Fetch the next page of results based on the current cursor.
  // If the cursor doesn't change, we keep polling until it does
  const fetchPage = useCallback(async () => {
    if (!searchId) return;

    const request = GetSearchRequest.create({
      searchId: searchId ?? undefined,
      cursor: currentData?.nextCursor,
    });

    setIsFetching(true);
    try {
      const response = await fetchSearchPost(request);
      setData((prev) => ({
        ...prev,
        [searchId]: mergeResponses(prev[searchId], response),
      }));
    } catch (err) {
      setError(err as ApiError);
    }
  }, [searchId, currentData?.nextCursor]);

  // Main loop logic:
  // 1: If the data for the current search hasn't been fetched, do so.
  // 2: When the data updates, fetch again immediately if hasMore: true
  // 3: Fetch again in 2 seconds if hasMore: false
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (!currentData || currentData.hasMore || !currentData.terminated) {
      timeout = setTimeout(fetchPage, currentData?.hasMore ? 0 : 5000);
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId, currentData]);

  return {
    data: currentData,
    isFetching,
    error,
  };
};
