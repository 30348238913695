import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import Tree, { RenderItemParams } from "@atlaskit/tree";
import { TreeDraggableProvided } from "@atlaskit/tree/dist/types/components/TreeItem/TreeItem-types";
import {
  faChevronDown,
  faChevronRight,
  faGripDotsVertical,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Group, Stack } from "@mantine/core";
import { useHover } from "@mantine/hooks";

import { WorkbenchContext } from "../../providers";
import { Bundle } from "../../stores";
import { renderExplanationHighlights } from "../RuleInspector/OperationItem";

import {
  InArray,
  isConstraintNodeData,
  isMetavariableConstraintNodeData,
  isPatternNodeData,
  Pattern,
  PatternTreeItem,
  PatternWithKind,
} from "./types/rule";
import { FadedComponent } from "./utils/FadedComponent";
import { moveTreeItem, treeOfPattern } from "./utils/treeOfPattern";
import { CodePatternEditor } from "./CodePatternEditor";
import { MetavariableConstraintNode } from "./MetavariableConstraint";
import { ConstraintNode } from "./PatternConstraint";
import { PatternOperatorSelect } from "./PatternOperatorSelect";

import styles from "./StructureMode.module.css";

/******************************************************************************/
/* Core tree nodes */
/******************************************************************************/

interface CoreTreeNodeProps {
  item: PatternTreeItem;
  isFocused: boolean;
  topArray: InArray<Pattern> | null;
}
/** A CoreTreeNode is the entire tree node, not including the columns or side spacing.
 */
const CoreTreeNode: React.FC<CoreTreeNodeProps> = observer(
  ({ item, isFocused, topArray }) => {
    const kind = item.data.kind;
    let innerElem;

    switch (kind) {
      case "pattern":
      case "regex":
        innerElem = (
          <CodePatternEditor
            pattern={item.data.pattern as PatternWithKind<"pattern" | "regex">}
            inArray={item.data.inArray ?? topArray}
            explanation={item.data.explanation}
            isFocused={isFocused}
          />
        );
        break;
      case "any":
      case "all":
      case "not":
      case "inside": {
        innerElem = (
          <PatternOperatorSelect
            pattern={item.data.pattern}
            inArray={item.data.inArray ?? topArray}
            explanation={item.data.explanation}
            isFocused={isFocused}
          />
        );
        break;
      }
      case "ConstraintNode": {
        innerElem = (
          <ConstraintNode
            constraint={item.data.constraint}
            inArray={item.data.inArray}
            isFocused={isFocused}
          />
        );
        break;
      }
      case "MetavariableConstraintNode": {
        innerElem = (
          <MetavariableConstraintNode
            metavariableConstraint={item.data.metavariableConstraint}
            inArray={item.data.inArray}
            isFocused={isFocused}
          />
        );
        break;
      }
    }
    return (
      <div
        style={{
          paddingTop: "var(--pattern-spacing)",
          paddingBottom: "var(--pattern-spacing)",
        }}
      >
        {innerElem}
      </div>
    );
  }
);

/******************************************************************************/
/* Lined tree nodes and columns */
/******************************************************************************/

const onCollapse = (bundle: Bundle | null, item: PatternTreeItem) => {
  const data = item.data;

  if (isPatternNodeData(data)) {
    data.pattern.isExpanded = !data.pattern.isExpanded;
  } else if (isConstraintNodeData(data)) {
    data.constraint.isExpanded = !data.constraint.isExpanded;
  } else if (isMetavariableConstraintNodeData(data)) {
    data.metavariableConstraint.isExpanded =
      !data.metavariableConstraint.isExpanded;
  }
  bundle?.onStructureRuleChange();
};

const renderColumn = (
  bundle: Bundle | null,
  item: PatternTreeItem,
  isFocused: boolean
) => {
  let isExpanded;
  if (isPatternNodeData(item.data)) {
    isExpanded = item.data.pattern.isExpanded;
  } else if (isConstraintNodeData(item.data)) {
    isExpanded = item.data.constraint.isExpanded;
  } else if (isMetavariableConstraintNodeData(item.data)) {
    isExpanded = item.data.metavariableConstraint.isExpanded;
  } else {
    isExpanded = true;
  }

  return (
    <Stack
      style={{
        alignSelf: "stretch",
        marginTop: "7px",
      }}
      gap={0}
    >
      <ActionIcon
        size="sm"
        variant="transparent"
        color={
          isFocused
            ? "var(--mantine-color-blue-4)"
            : "var(--mantine-color-gray-5)"
        }
        onMouseDown={() => onCollapse(bundle, item)}
      >
        <FontAwesomeIcon
          icon={isExpanded ? faChevronDown : faChevronRight}
          size="sm"
        />
      </ActionIcon>
      {
        // Why is there a div with nothing in it here?
        // This div renders the vertical line below a chevorn, which
        // comprises of the columns denoting depth.
        <div
          style={{
            width: "2px",
            height: "100%",
            background: "var(--mantine-color-gray-2)",
            margin: "0 auto",
          }}
        />
      }
    </Stack>
  );
};

interface LinedTreeNodeProps {
  item: PatternTreeItem;
  depth: number;
  topArray: InArray<Pattern> | null;
  // We have to include this, so we can add the drag handle to this node.
  provided?: TreeDraggableProvided;
}
/** A LinedTreeNode is a CoreTreeNode, augmented with aligning columns.
 */
const LinedTreeNode: React.FC<LinedTreeNodeProps> = observer(
  ({ item, depth, topArray, provided }) => {
    const { workbench } = useContext(WorkbenchContext);
    const { bundle } = workbench;
    const { hovered, ref } = useHover();

    useEffect(() => {
      // It's safe to access the explanation directly, here, because we must be
      // holding a true pattern item.
      // If this were a constraint, we would crash.
      if (isPatternNodeData(item.data)) {
        renderExplanationHighlights(
          hovered,
          item.data.explanation,
          workbench.editors
        );
      }
    }, [hovered, item, workbench.editors]);

    function renderColumns(item: PatternTreeItem, depth: number) {
      if (depth <= 0) {
        // This thing looks kinda complicated, but all it is is:
        /*
        |------------------------------------------------|
        | chevron |             spacing                  |
        |   ||    |--------------------------------------|
        |   ||    |           core element               | <-- except this is tall
        |   ||    |--------------------------------------|
        |   ||    |             spacing                  |
        |------------------------------------------------|
        */
        return (
          <Group
            gap="4px"
            // Here, we apply a negative left margin to each instance
            // of the chevron + column
            // This means that we will synchronize with the below columns, without
            // creating excess horizontal space.
            style={{ marginLeft: "-10px" }}
          >
            {renderColumn(bundle, item, hovered)}
            <CoreTreeNode item={item} isFocused={hovered} topArray={null} />
          </Group>
        );
        // For each depth, recur and add a column.
        // The spacing needs to be precise such that each of these columns will
        // attach to the columns spawned by the nodes above and below this one.
      } else {
        return (
          <div
            style={{
              paddingLeft: "var(--mantine-spacing-lg)",
              borderLeft: "2px solid var(--mantine-color-gray-2)",
            }}
          >
            {renderColumns(item, depth - 1)}
          </div>
        );
      }
    }

    return (
      <FadedComponent
        isFocused={false}
        opacity={
          isPatternNodeData(item.data) && item.data.pattern.isDisabled ? 0.3 : 1
        }
      >
        <div
          ref={ref}
          style={{
            backgroundColor: hovered
              ? "var(--mantine-color-blue-0)"
              : undefined,
          }}
          className={styles.patternTreeNode}
        >
          <Group gap="xs">
            <div {...provided?.dragHandleProps}>
              <FadedComponent isFocused={hovered} opacity={0}>
                <FontAwesomeIcon
                  aria-label="StructureDragGrip"
                  icon={faGripDotsVertical}
                  color="var(--mantine-color-blue-4)"
                  size="lg"
                />
              </FadedComponent>
            </div>
            {depth === 0 ? (
              // This is literally the top-most component. We don't need to be able to
              // collapse it, since it will collapse the entire rule. So we just render the
              // core thing.
              <CoreTreeNode
                item={item}
                isFocused={hovered}
                topArray={topArray}
              />
            ) : (
              // Otherwise, we want to render one less column than the depth, since we special-cased
              // to not have the root's column.
              // This ends up causing a lack of space from the left to align with the root, so let's
              // add some space here, too.
              <div style={{ paddingLeft: "12px" }}>
                {renderColumns(item, depth - 1)}
              </div>
            )}
          </Group>
        </div>
      </FadedComponent>
    );
  }
);

/******************************************************************************/
/* Entry point */
/******************************************************************************/

interface PatternTreeProps {
  pattern: Pattern;
  // Most patterns shouldn't admit a plus or delete button.
  // This is not true in the case of sources and sinks in taint rules, which
  // want a plus and delete button to add more sources and sinks.
  // So in this case, topArray is not null, and eventually will replace the
  // inArray used in the case of nested patterns. The type signatures look the
  // same, however, so this can be a little confusing.
  topArray: InArray<Pattern> | null;
}

const PatternTreeComponent: React.FC<PatternTreeProps> = ({
  pattern,
  topArray,
}) => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;

  const tree = treeOfPattern(pattern);

  function renderItem({ item, depth, provided }: RenderItemParams) {
    return (
      <div
        aria-label="PatternTreeNode"
        {...provided.draggableProps}
        ref={provided.innerRef}
      >
        <LinedTreeNode
          item={item as PatternTreeItem}
          provided={provided}
          depth={
            // Because the top node is not rendered, we had to hard-code the depth
            // of it at 0.
            // Correspondingly, we increase the depth by 1 everywhere else.
            depth + 1
          }
          topArray={null}
        />
      </div>
    );
  }

  return (
    // This outer `div` makes it so that each node takes up maximum horizontal space!
    <div style={{ width: "100%" }}>
      <Stack gap={0} className={styles.patternTree}>
        {
          // The way that the Atlaskit tree library works is that it doesn't render
          // the top-most node, for some reason.
          // So, we need to render it manually ourselves here.
          <LinedTreeNode
            item={tree.items[tree.rootId]}
            depth={0}
            topArray={topArray}
          />
        }
        <Tree
          tree={tree}
          renderItem={renderItem}
          // TODO: prevent onDragStart when dragging invalidly
          onDragEnd={(source, destination) => {
            const source_parent = tree.items[source.parentId];
            if (!destination || source_parent.children.length <= 1) {
              return;
            }

            // Sometimes, the parent is clear but the index is NaN for some reason.
            // This happens commonly when trying to put something at the very bottom of the entire tree.
            // In this case, let's just assume it is to be moved last.
            if (Number.isNaN(destination.index)) {
              destination = {
                parentId: tree.rootId,
                index: tree.items[destination.parentId].children.length - 1,
              };
            }

            const source_parent_idx = source.index;
            const source_item =
              tree.items[
                tree.items[source.parentId].children[source_parent_idx]
              ];
            const dest_parent = tree.items[destination.parentId];
            const dest_parent_idx = destination.index;
            // This must be atomic, or we will end up in a half-moved state during a rerender.
            runInAction(() => {
              moveTreeItem(
                source_parent,
                source_parent_idx,
                source_item,
                dest_parent,
                dest_parent_idx
              );
            });
            bundle?.onStructureRuleChange();
          }}
          offsetPerLevel={0}
          isDragEnabled
        />
      </Stack>
    </div>
  );
};

export const PatternTree = observer(PatternTreeComponent);
