// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/encryption/v1/encryption.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "protos.encryption.v1";

export interface EncryptedValueDictMessage {
  /** see class EncryptedValueDict in server/semgrep_app/util/encryption.py */
  nonce: string;
  ciphertext: string;
  tag: string;
  keyVersion: string;
}

function createBaseEncryptedValueDictMessage(): EncryptedValueDictMessage {
  return { nonce: "", ciphertext: "", tag: "", keyVersion: "" };
}

export const EncryptedValueDictMessage = {
  encode(message: EncryptedValueDictMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nonce !== "") {
      writer.uint32(10).string(message.nonce);
    }
    if (message.ciphertext !== "") {
      writer.uint32(18).string(message.ciphertext);
    }
    if (message.tag !== "") {
      writer.uint32(26).string(message.tag);
    }
    if (message.keyVersion !== "") {
      writer.uint32(34).string(message.keyVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EncryptedValueDictMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEncryptedValueDictMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nonce = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ciphertext = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.keyVersion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EncryptedValueDictMessage {
    return {
      nonce: isSet(object.nonce) ? globalThis.String(object.nonce) : "",
      ciphertext: isSet(object.ciphertext) ? globalThis.String(object.ciphertext) : "",
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      keyVersion: isSet(object.keyVersion) ? globalThis.String(object.keyVersion) : "",
    };
  },

  toJSON(message: EncryptedValueDictMessage): unknown {
    const obj: any = {};
    if (message.nonce !== "") {
      obj.nonce = message.nonce;
    }
    if (message.ciphertext !== "") {
      obj.ciphertext = message.ciphertext;
    }
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.keyVersion !== "") {
      obj.keyVersion = message.keyVersion;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EncryptedValueDictMessage>, I>>(base?: I): EncryptedValueDictMessage {
    return EncryptedValueDictMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EncryptedValueDictMessage>, I>>(object: I): EncryptedValueDictMessage {
    const message = createBaseEncryptedValueDictMessage();
    message.nonce = object.nonce ?? "";
    message.ciphertext = object.ciphertext ?? "";
    message.tag = object.tag ?? "";
    message.keyVersion = object.keyVersion ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
