import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Select } from "@mantine/core";

import { techToImage } from "@shared/constants";
import { languageMaturityTag, VISIBLE_LANGUAGES } from "@shared/utils";

import { Rule } from "../types/rule";

import styles from "../StructureMode.module.css";

interface LanguageSelectProps {
  rule: Rule;
}
export const LanguageSelectComponent: React.FC<LanguageSelectProps> = observer(
  ({ rule }) => {
    let icon;
    if (techToImage[rule.language] !== undefined) {
      icon = (
        <img
          className={styles.languageIcon}
          src={techToImage[rule.language]}
          alt={""}
        />
      );
    }
    return (
      <Select
        searchable
        label="Language"
        data={Object.values(VISIBLE_LANGUAGES).map((def) => {
          return {
            value: def.id,
            label: `${def.name}${languageMaturityTag[def.maturity]}`,
          };
        })}
        value={rule.language}
        onChange={(lang) =>
          runInAction(() => {
            if (lang) {
              rule.language = lang;
            }
          })
        }
        leftSection={icon}
        data-fs-element="Structure language select"
      />
    );
  }
);
