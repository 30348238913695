import { useCallback, useContext, useEffect, useState } from "react";
import {
  faChevronLeft,
  faInfoCircle,
  faWarning,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Badge,
  Center,
  Checkbox,
  Group,
  Stack,
  Tabs,
  Text,
  Tooltip,
} from "@mantine/core";
import { usePrefab } from "@prefab-cloud/prefab-cloud-react";

import { TargetRepository } from "@protos/search/v1/search_service";
import { LinkButton, Spinner } from "@shared/components";
import {
  PrivateReposSelect,
  PublicReposSelect,
  RunButton,
} from "@shared/editorCore";
import {
  useProductLicenseInfo,
  useQueryConsole,
  useSupportsFeature,
  useUrlSearch,
  useUser,
} from "@shared/hooks";

import { WorkbenchContext } from "../../providers";

import { useReposFromQcSearch } from "./hooks/useReposFromQcSearch";
import { EditorQueryConsoleEmptyState } from "./EditorQueryConsoleEmptyState";
import { EditorQueryConsoleLoggedOut } from "./EditorQueryConsoleLoggedOut";
import { EditorQueryConsoleNotAvailable } from "./EditorQueryConsoleNotAvailable";
import { EditorQueryConsoleProgress } from "./EditorQueryConsoleProgress";
import { EditorQueryConsoleResults } from "./EditorQueryConsoleResults";
import { QueryConsoleBetaBanner } from "./QueryConsoleBetaBanner";

import styles from "./EditorQueryConsole.module.css";

export const EditorQueryConsole = () => {
  const [user] = useUser();
  const { get } = usePrefab();
  const { workbench } = useContext(WorkbenchContext);
  const rules = workbench.bundle?.ruleText;

  const sastLicenseData = useProductLicenseInfo("sast");
  const hasInfluence = useSupportsFeature("isQueryConsoleInfluencer");

  const [selectedPrivateRepos, setSelectedPrivateRepos] = useState<
    TargetRepository[]
  >([]);
  const [selectedPublicRepos, setSelectedPublicRepos] = useState<
    TargetRepository[]
  >([]);

  const [limitSearch, setLimitSearch] = useState(false);

  const maxRepos = (get("query-console.search.repos.max") ??
    Infinity) as number;
  const tooManyReposSelected =
    selectedPrivateRepos.length + selectedPublicRepos.length > maxRepos;

  // Source of truth for current search id
  // Set when a new search starts, or on page load from the URL
  const { search, setSearch } = useUrlSearch();
  const currentSearchId = search.get("search_id");
  const setCurrentSearchId = useCallback(
    (id?: string | null) => {
      if (id) {
        search.set("search_id", id);
      } else {
        search.delete("search_id");
      }
      setSearch(search);
    },
    [search, setSearch]
  );

  // Fill in selected repos when loading a previous search id
  const searchRepos = useReposFromQcSearch(currentSearchId);
  useEffect(() => {
    if (searchRepos) {
      setSelectedPrivateRepos(searchRepos.privateRepos);
      setSelectedPublicRepos(searchRepos.publicRepos);
    }
  }, [searchRepos]);

  const {
    startSearch,
    searchStarting,
    searchResults,
    cancel,
    isRunning,
    startSearchError,
  } = useQueryConsole({
    rules: rules ?? "",
    reposToSearch: [...selectedPrivateRepos, ...selectedPublicRepos],
    currentSearchId,
    setCurrentSearchId,
    limit: limitSearch ? 1 : undefined,
  });

  if (!user) return <EditorQueryConsoleLoggedOut />;
  if (!sastLicenseData)
    return (
      <Center h="100%">
        <Spinner size={40} />
      </Center>
    );

  const queryConsoleEnabled =
    (sastLicenseData &&
      (sastLicenseData.hasTrial || !!sastLicenseData.license?.active)) ||
    hasInfluence;

  if (!queryConsoleEnabled) return <EditorQueryConsoleNotAvailable />;

  let body = null;
  if ((!currentSearchId || !!startSearchError) && !isRunning) {
    body = (
      <Stack h="100%">
        <EditorQueryConsoleEmptyState>
          <QueryConsoleBetaBanner mx="md" />
          {startSearchError && (
            <Alert
              w="100%"
              variant="light"
              color="red"
              title="Search failed"
              icon={<FontAwesomeIcon icon={faWarning} />}
            >
              {startSearchError.message}
            </Alert>
          )}
        </EditorQueryConsoleEmptyState>
        <Tabs defaultValue="private">
          <Tabs.List className={styles.sticky}>
            <Tabs.Tab value="private">
              <Group align="center" gap="xs">
                My repositories{" "}
                {!!selectedPrivateRepos.length && (
                  <Badge size="xs">{selectedPrivateRepos.length}</Badge>
                )}
              </Group>
            </Tabs.Tab>
            <Tabs.Tab value="public">
              Public repositories{" "}
              {!!selectedPublicRepos.length && (
                <Badge size="xs">{selectedPublicRepos.length}</Badge>
              )}
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="private">
            <PrivateReposSelect
              value={selectedPrivateRepos}
              onChange={setSelectedPrivateRepos}
            />
          </Tabs.Panel>
          <Tabs.Panel value="public">
            <PublicReposSelect
              value={selectedPublicRepos}
              onChange={setSelectedPublicRepos}
            />
          </Tabs.Panel>
        </Tabs>
      </Stack>
    );
  } else if (searchResults) {
    body = <EditorQueryConsoleResults searchResult={searchResults} />;
  } else {
    body = (
      <Group h="100%" w="100%" justify="center" align="center">
        <Spinner size={40} />
      </Group>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.main}>{body}</div>
      <div className={styles.controls}>
        <div className={styles.controlsMain}>
          {isRunning ? (
            <EditorQueryConsoleProgress
              itemsProcessed={searchResults?.terminatedRepositories}
              itemsTotal={searchResults?.totalRepositories}
              onCancel={() => {
                setCurrentSearchId(null);
                cancel();
              }}
            />
          ) : currentSearchId ? (
            <Group h="100%" justify="space-between" align="center">
              <LinkButton onClick={() => setCurrentSearchId(null)}>
                <Group gap="xs">
                  <FontAwesomeIcon icon={faChevronLeft} /> New search
                </Group>
              </LinkButton>
            </Group>
          ) : (
            <Group h="100%" justify="end" align="center">
              {tooManyReposSelected && (
                <Text c="red" fz="sm">
                  Please select <strong>{maxRepos}</strong> or fewer
                  repositories
                </Text>
              )}
              <Checkbox
                label={
                  <span>
                    Limit results to one per repository{" "}
                    <Tooltip
                      label="This will limit your search to show the first result found for each repository. If you're just looking to understand if there are any results, this can speed up your search."
                      multiline={true}
                      w={300}
                    >
                      <FontAwesomeIcon size="sm" icon={faInfoCircle} />
                    </Tooltip>
                  </span>
                }
                size="sm"
                checked={limitSearch}
                onChange={(evt) => setLimitSearch(evt.target.checked)}
              />
            </Group>
          )}
        </div>
        <Group h="100%" justify="end" align="center">
          {searchResults && searchResults.rules !== rules && (
            <Tooltip label="Rule has been updated. Search again to see new results.">
              <Badge color="orange" variant="filled">
                Rule changed
              </Badge>
            </Tooltip>
          )}
          <RunButton
            customRunText="Search"
            onClick={startSearch}
            isRunning={searchStarting || isRunning}
            buttonProps={{ size: "compact-lg" }}
            disabled={
              !(selectedPrivateRepos.length + selectedPublicRepos.length) ||
              tooManyReposSelected
            }
          />
        </Group>
      </div>
    </div>
  );
};
