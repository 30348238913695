import { FC } from "react";
import {
  faHexagonXmark,
  faMinus,
  faTriangleExclamation,
  faUnlink,
} from "@fortawesome/pro-solid-svg-icons";
import { Box } from "@mantine/core";

import { ValidationState } from "@protos/secrets/v1/secrets";

import { CodeLocationIcon } from "./CodeLocationIcon";

type ValidationStateIconProps = {
  validationState: ValidationState;
};

export const ICON_MAP = {
  [ValidationState.CONFIRMED_VALID]: {
    icon: faTriangleExclamation,
    label: "Confirmed valid",
  },
  [ValidationState.CONFIRMED_INVALID]: {
    icon: faUnlink,
    label: "Confirmed invalid",
  },
  [ValidationState.NO_VALIDATOR]: { icon: faMinus, label: "No validator" },
  [ValidationState.VALIDATION_ERROR]: {
    icon: faHexagonXmark,
    label: "Validation error",
  },
  [ValidationState.UNSPECIFIED]: null,
};

export const ValidationStateIcon: FC<ValidationStateIconProps> = ({
  validationState,
}) => {
  const iconData = ICON_MAP[validationState];

  if (!iconData) {
    return null;
  }

  return (
    <Box>
      <CodeLocationIcon label={iconData.label} icon={iconData.icon} />
    </Box>
  );
};
