// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/path_to_transitivity/v1/path_to_transitivity.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Ecosystem, ecosystemFromJSON, ecosystemToJSON, ecosystemToNumber } from "../../sca/v1/sca";

export const protobufPackage = "protos.path_to_transitivity.v1";

/** A Dependency as recorded and processed in DGraph */
export interface DependencyGraphNode {
  /** Deployment ID as recorded in the Deployment table in the database */
  deploymentId: number;
  /** Repository ID as recorded in the Repository table in the database */
  repoId: number;
  /** Commit SHA of the latest SCA full scan that was ran on the repository in question */
  commitHash: string;
  /** File path from the root project where the manifest is located. Used to associate a node with a manifest's "graph" */
  manifestPath: string;
  /** Name of the dependency's package */
  packageName: string;
  /** Version of the dependency */
  packageVersion: string;
  /** Package ecosystem (enum defined in sca/vi/sca.proto) to which the dependency belongs */
  ecosystem: Ecosystem;
  /** A Dependency's unique identifier as recorded and processed in DGraph */
  dependencyId: string;
}

export interface GetDependencyPathsRequest {
  deploymentId: number;
  repoId: number;
  manifestPath: string;
  packageName: string;
  packageVersion: string;
  ecosystem: Ecosystem;
  commitHash?: string | undefined;
}

export interface GetDependencyPathsResponse {
  /** A list of paths that represents the various paths to transitivity by which the requested transitive dependency was brought in */
  paths: GetDependencyPathsResponse_Path[];
}

/** Represents an ordered path from the requested transitive dependency (first node) to the direct dependency that brought it in (last node) */
export interface GetDependencyPathsResponse_Path {
  nodes: DependencyGraphNode[];
}

function createBaseDependencyGraphNode(): DependencyGraphNode {
  return {
    deploymentId: 0,
    repoId: 0,
    commitHash: "",
    manifestPath: "",
    packageName: "",
    packageVersion: "",
    ecosystem: Ecosystem.no_package_manager,
    dependencyId: "",
  };
}

export const DependencyGraphNode = {
  encode(message: DependencyGraphNode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    if (message.repoId !== 0) {
      writer.uint32(16).int64(message.repoId);
    }
    if (message.commitHash !== "") {
      writer.uint32(26).string(message.commitHash);
    }
    if (message.manifestPath !== "") {
      writer.uint32(34).string(message.manifestPath);
    }
    if (message.packageName !== "") {
      writer.uint32(42).string(message.packageName);
    }
    if (message.packageVersion !== "") {
      writer.uint32(50).string(message.packageVersion);
    }
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      writer.uint32(56).int32(ecosystemToNumber(message.ecosystem));
    }
    if (message.dependencyId !== "") {
      writer.uint32(66).string(message.dependencyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DependencyGraphNode {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDependencyGraphNode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.repoId = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.commitHash = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.manifestPath = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.packageName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.packageVersion = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.ecosystem = ecosystemFromJSON(reader.int32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.dependencyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DependencyGraphNode {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      repoId: isSet(object.repoId) ? globalThis.Number(object.repoId) : 0,
      commitHash: isSet(object.commitHash) ? globalThis.String(object.commitHash) : "",
      manifestPath: isSet(object.manifestPath) ? globalThis.String(object.manifestPath) : "",
      packageName: isSet(object.packageName) ? globalThis.String(object.packageName) : "",
      packageVersion: isSet(object.packageVersion) ? globalThis.String(object.packageVersion) : "",
      ecosystem: isSet(object.ecosystem) ? ecosystemFromJSON(object.ecosystem) : Ecosystem.no_package_manager,
      dependencyId: isSet(object.dependencyId) ? globalThis.String(object.dependencyId) : "",
    };
  },

  toJSON(message: DependencyGraphNode): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.repoId !== 0) {
      obj.repoId = Math.round(message.repoId);
    }
    if (message.commitHash !== "") {
      obj.commitHash = message.commitHash;
    }
    if (message.manifestPath !== "") {
      obj.manifestPath = message.manifestPath;
    }
    if (message.packageName !== "") {
      obj.packageName = message.packageName;
    }
    if (message.packageVersion !== "") {
      obj.packageVersion = message.packageVersion;
    }
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      obj.ecosystem = ecosystemToJSON(message.ecosystem);
    }
    if (message.dependencyId !== "") {
      obj.dependencyId = message.dependencyId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DependencyGraphNode>, I>>(base?: I): DependencyGraphNode {
    return DependencyGraphNode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DependencyGraphNode>, I>>(object: I): DependencyGraphNode {
    const message = createBaseDependencyGraphNode();
    message.deploymentId = object.deploymentId ?? 0;
    message.repoId = object.repoId ?? 0;
    message.commitHash = object.commitHash ?? "";
    message.manifestPath = object.manifestPath ?? "";
    message.packageName = object.packageName ?? "";
    message.packageVersion = object.packageVersion ?? "";
    message.ecosystem = object.ecosystem ?? Ecosystem.no_package_manager;
    message.dependencyId = object.dependencyId ?? "";
    return message;
  },
};

function createBaseGetDependencyPathsRequest(): GetDependencyPathsRequest {
  return {
    deploymentId: 0,
    repoId: 0,
    manifestPath: "",
    packageName: "",
    packageVersion: "",
    ecosystem: Ecosystem.no_package_manager,
    commitHash: undefined,
  };
}

export const GetDependencyPathsRequest = {
  encode(message: GetDependencyPathsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).int64(message.deploymentId);
    }
    if (message.repoId !== 0) {
      writer.uint32(16).uint64(message.repoId);
    }
    if (message.manifestPath !== "") {
      writer.uint32(26).string(message.manifestPath);
    }
    if (message.packageName !== "") {
      writer.uint32(34).string(message.packageName);
    }
    if (message.packageVersion !== "") {
      writer.uint32(42).string(message.packageVersion);
    }
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      writer.uint32(48).int32(ecosystemToNumber(message.ecosystem));
    }
    if (message.commitHash !== undefined) {
      writer.uint32(58).string(message.commitHash);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDependencyPathsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDependencyPathsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.repoId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.manifestPath = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.packageName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.packageVersion = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.ecosystem = ecosystemFromJSON(reader.int32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.commitHash = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDependencyPathsRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      repoId: isSet(object.repoId) ? globalThis.Number(object.repoId) : 0,
      manifestPath: isSet(object.manifestPath) ? globalThis.String(object.manifestPath) : "",
      packageName: isSet(object.packageName) ? globalThis.String(object.packageName) : "",
      packageVersion: isSet(object.packageVersion) ? globalThis.String(object.packageVersion) : "",
      ecosystem: isSet(object.ecosystem) ? ecosystemFromJSON(object.ecosystem) : Ecosystem.no_package_manager,
      commitHash: isSet(object.commitHash) ? globalThis.String(object.commitHash) : undefined,
    };
  },

  toJSON(message: GetDependencyPathsRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.repoId !== 0) {
      obj.repoId = Math.round(message.repoId);
    }
    if (message.manifestPath !== "") {
      obj.manifestPath = message.manifestPath;
    }
    if (message.packageName !== "") {
      obj.packageName = message.packageName;
    }
    if (message.packageVersion !== "") {
      obj.packageVersion = message.packageVersion;
    }
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      obj.ecosystem = ecosystemToJSON(message.ecosystem);
    }
    if (message.commitHash !== undefined) {
      obj.commitHash = message.commitHash;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDependencyPathsRequest>, I>>(base?: I): GetDependencyPathsRequest {
    return GetDependencyPathsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDependencyPathsRequest>, I>>(object: I): GetDependencyPathsRequest {
    const message = createBaseGetDependencyPathsRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.repoId = object.repoId ?? 0;
    message.manifestPath = object.manifestPath ?? "";
    message.packageName = object.packageName ?? "";
    message.packageVersion = object.packageVersion ?? "";
    message.ecosystem = object.ecosystem ?? Ecosystem.no_package_manager;
    message.commitHash = object.commitHash ?? undefined;
    return message;
  },
};

function createBaseGetDependencyPathsResponse(): GetDependencyPathsResponse {
  return { paths: [] };
}

export const GetDependencyPathsResponse = {
  encode(message: GetDependencyPathsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.paths) {
      GetDependencyPathsResponse_Path.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDependencyPathsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDependencyPathsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.paths.push(GetDependencyPathsResponse_Path.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDependencyPathsResponse {
    return {
      paths: globalThis.Array.isArray(object?.paths)
        ? object.paths.map((e: any) => GetDependencyPathsResponse_Path.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetDependencyPathsResponse): unknown {
    const obj: any = {};
    if (message.paths?.length) {
      obj.paths = message.paths.map((e) => GetDependencyPathsResponse_Path.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDependencyPathsResponse>, I>>(base?: I): GetDependencyPathsResponse {
    return GetDependencyPathsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDependencyPathsResponse>, I>>(object: I): GetDependencyPathsResponse {
    const message = createBaseGetDependencyPathsResponse();
    message.paths = object.paths?.map((e) => GetDependencyPathsResponse_Path.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetDependencyPathsResponse_Path(): GetDependencyPathsResponse_Path {
  return { nodes: [] };
}

export const GetDependencyPathsResponse_Path = {
  encode(message: GetDependencyPathsResponse_Path, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.nodes) {
      DependencyGraphNode.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDependencyPathsResponse_Path {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDependencyPathsResponse_Path();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodes.push(DependencyGraphNode.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDependencyPathsResponse_Path {
    return {
      nodes: globalThis.Array.isArray(object?.nodes)
        ? object.nodes.map((e: any) => DependencyGraphNode.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetDependencyPathsResponse_Path): unknown {
    const obj: any = {};
    if (message.nodes?.length) {
      obj.nodes = message.nodes.map((e) => DependencyGraphNode.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDependencyPathsResponse_Path>, I>>(base?: I): GetDependencyPathsResponse_Path {
    return GetDependencyPathsResponse_Path.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDependencyPathsResponse_Path>, I>>(
    object: I,
  ): GetDependencyPathsResponse_Path {
    const message = createBaseGetDependencyPathsResponse_Path();
    message.nodes = object.nodes?.map((e) => DependencyGraphNode.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
