import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AISuggestion } from "@shared/types";
import { CliMatch } from "@semgrep_output_types";

import { WorkbenchContext } from "../../providers";
import { ErrorResult } from "../../stores";
import { applyFix } from "../../utils";

import { ErrorsSection } from "./ErrorsSection";
import { MatchesSection } from "./MatchesSection";
import { ResultSummary } from "./ResultSummary";
import { TestsSection } from "./TestsSection";

interface Dimmable {
  dim?: boolean;
  error?: boolean;
}

const Details = styled.div<Dimmable>`
  /* TODO this color was taken from the navbar, should be replaced with mantine color */
  border-top: 1px #404a52 solid;
  font-size: 13px;

  & > * {
    opacity: ${(props) => (props.dim ? "0.5" : "initial")};
    transition: opacity 0.2s ease;
  }
`;

const Container = styled.div``;

const SummaryBar = styled.div<Dimmable>`
  display: flex;
  align-items: center;
  gap: var(--mantine-spacing-sm);
  padding: var(--global-spacing-xxs) var(--mantine-spacing-sm);
  justify-content: space-between;
  cursor: pointer;
  /* TODO this color was taken from the navbar, should be replaced with mantine color */
  border-top: 1px #404a52 solid;

  ${(props) =>
    props.error &&
    `
    background-color: var(--mantine-color-red-6);
    color: white;
  `}

  :hover {
    background-color: var(--mantine-color-blue-0);
  }

  & > * {
    opacity: ${(props) => (props.dim ? "0.5" : "initial")};
    transition: opacity 0.2s ease;
  }
`;

const EmptySummaryBar = styled(SummaryBar)`
  justify-content: space-around;
  color: var(--mantine-color-gray-6);
  cursor: initial;

  :hover {
    background-color: initial;
  }
`;

const Panel = () => {
  const { workbench } = useContext(WorkbenchContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { result, bundle } = workbench;

  useEffect(() => {
    if (bundle && result && result.errorsCount > 0) setIsExpanded(true);
  }, [bundle, result]);

  const handleShowTrace = !workbench.turboModeEnabled
    ? (r: CliMatch) => () => {
        const result = workbench.result;
        if (!bundle || !result || result instanceof ErrorResult) {
          return;
        }
        if (r.extra.dataflow_trace) {
          result.showingTaintTraces = [r.extra.dataflow_trace];
        }
      }
    : undefined;

  const handleApplyMultilineFix = (fix: AISuggestion) => {
    const result = workbench.result;

    if (!bundle || !result || result instanceof ErrorResult) {
      return;
    }

    const { setShowingAutofixDiff } = bundle;
    // toggle
    setShowingAutofixDiff(bundle.showingAutofixDiff ? undefined : fix.fix_code);
  };

  const handleApplySemgrepAutofix = (r: CliMatch) => () => {
    const result = workbench.result;
    if (!bundle || !result || result instanceof ErrorResult) {
      return;
    }
    const { targetText, setShowingAutofixDiff } = bundle;
    const { newTarget } = applyFix(r, targetText);
    setShowingAutofixDiff(newTarget);
  };

  if (result === null) {
    if (bundle !== null && bundle.isRunning)
      return (
        <Container>
          <EmptySummaryBar>
            <span>
              Running <code>{bundle.rule?.id}</code>…
            </span>
          </EmptySummaryBar>
        </Container>
      );

    return (
      <Container>
        <EmptySummaryBar>Run your rule to see matches.</EmptySummaryBar>
      </Container>
    );
  }

  const expandIcon = (
    <FontAwesomeIcon
      icon={isExpanded ? faAngleDoubleDown : faAngleDoubleUp}
      size="xs"
    />
  );

  return (
    <Container>
      <Details
        dim={bundle?.isRunning}
        style={{
          display: isExpanded ? undefined : "none",
        }}
      >
        {result instanceof ErrorResult || result.errorsCount > 0 ? (
          <ErrorsSection result={result} />
        ) : (
          <>
            <MatchesSection
              result={result}
              onApplyFix={handleApplySemgrepAutofix}
              onApplyAIFix={handleApplyMultilineFix}
              onShowTrace={handleShowTrace}
              dirty={result.isDirty}
              aiAutofixes={result.response.ai_autofixes}
            />
            <TestsSection result={result} />
          </>
        )}
      </Details>
      <SummaryBar
        dim={bundle?.isRunning}
        error={!bundle?.isRunning && result.errorsCount > 0}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {expandIcon}
        <ResultSummary result={result} />
        {expandIcon}
      </SummaryBar>
    </Container>
  );
};

export const ResultsPanel = observer(Panel);
