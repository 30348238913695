// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/ai/v1/remediation.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "protos.ai.v1";

export interface Autofix {
  id: number;
  fixCode: string;
  fixDiff: string;
  explanation: string;
  url: string;
}

export interface Guidance {
  id: number;
  summary: string;
  guidanceText: string;
}

export interface Remediation {
  issueId: number;
  matchBasedId: string;
  autofix?: Autofix | undefined;
  guidance?: Guidance | undefined;
}

function createBaseAutofix(): Autofix {
  return { id: 0, fixCode: "", fixDiff: "", explanation: "", url: "" };
}

export const Autofix = {
  encode(message: Autofix, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.fixCode !== "") {
      writer.uint32(18).string(message.fixCode);
    }
    if (message.fixDiff !== "") {
      writer.uint32(26).string(message.fixDiff);
    }
    if (message.explanation !== "") {
      writer.uint32(34).string(message.explanation);
    }
    if (message.url !== "") {
      writer.uint32(42).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Autofix {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAutofix();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fixCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fixDiff = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.explanation = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Autofix {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      fixCode: isSet(object.fixCode) ? globalThis.String(object.fixCode) : "",
      fixDiff: isSet(object.fixDiff) ? globalThis.String(object.fixDiff) : "",
      explanation: isSet(object.explanation) ? globalThis.String(object.explanation) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: Autofix): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.fixCode !== "") {
      obj.fixCode = message.fixCode;
    }
    if (message.fixDiff !== "") {
      obj.fixDiff = message.fixDiff;
    }
    if (message.explanation !== "") {
      obj.explanation = message.explanation;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Autofix>, I>>(base?: I): Autofix {
    return Autofix.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Autofix>, I>>(object: I): Autofix {
    const message = createBaseAutofix();
    message.id = object.id ?? 0;
    message.fixCode = object.fixCode ?? "";
    message.fixDiff = object.fixDiff ?? "";
    message.explanation = object.explanation ?? "";
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseGuidance(): Guidance {
  return { id: 0, summary: "", guidanceText: "" };
}

export const Guidance = {
  encode(message: Guidance, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.summary !== "") {
      writer.uint32(18).string(message.summary);
    }
    if (message.guidanceText !== "") {
      writer.uint32(26).string(message.guidanceText);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Guidance {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGuidance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.summary = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.guidanceText = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Guidance {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      summary: isSet(object.summary) ? globalThis.String(object.summary) : "",
      guidanceText: isSet(object.guidanceText) ? globalThis.String(object.guidanceText) : "",
    };
  },

  toJSON(message: Guidance): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.summary !== "") {
      obj.summary = message.summary;
    }
    if (message.guidanceText !== "") {
      obj.guidanceText = message.guidanceText;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Guidance>, I>>(base?: I): Guidance {
    return Guidance.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Guidance>, I>>(object: I): Guidance {
    const message = createBaseGuidance();
    message.id = object.id ?? 0;
    message.summary = object.summary ?? "";
    message.guidanceText = object.guidanceText ?? "";
    return message;
  },
};

function createBaseRemediation(): Remediation {
  return { issueId: 0, matchBasedId: "", autofix: undefined, guidance: undefined };
}

export const Remediation = {
  encode(message: Remediation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.issueId !== 0) {
      writer.uint32(8).uint64(message.issueId);
    }
    if (message.matchBasedId !== "") {
      writer.uint32(18).string(message.matchBasedId);
    }
    if (message.autofix !== undefined) {
      Autofix.encode(message.autofix, writer.uint32(26).fork()).ldelim();
    }
    if (message.guidance !== undefined) {
      Guidance.encode(message.guidance, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Remediation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemediation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.issueId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.matchBasedId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.autofix = Autofix.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.guidance = Guidance.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Remediation {
    return {
      issueId: isSet(object.issueId) ? globalThis.Number(object.issueId) : 0,
      matchBasedId: isSet(object.matchBasedId) ? globalThis.String(object.matchBasedId) : "",
      autofix: isSet(object.autofix) ? Autofix.fromJSON(object.autofix) : undefined,
      guidance: isSet(object.guidance) ? Guidance.fromJSON(object.guidance) : undefined,
    };
  },

  toJSON(message: Remediation): unknown {
    const obj: any = {};
    if (message.issueId !== 0) {
      obj.issueId = Math.round(message.issueId);
    }
    if (message.matchBasedId !== "") {
      obj.matchBasedId = message.matchBasedId;
    }
    if (message.autofix !== undefined) {
      obj.autofix = Autofix.toJSON(message.autofix);
    }
    if (message.guidance !== undefined) {
      obj.guidance = Guidance.toJSON(message.guidance);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Remediation>, I>>(base?: I): Remediation {
    return Remediation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Remediation>, I>>(object: I): Remediation {
    const message = createBaseRemediation();
    message.issueId = object.issueId ?? 0;
    message.matchBasedId = object.matchBasedId ?? "";
    message.autofix = (object.autofix !== undefined && object.autofix !== null)
      ? Autofix.fromPartial(object.autofix)
      : undefined;
    message.guidance = (object.guidance !== undefined && object.guidance !== null)
      ? Guidance.fromPartial(object.guidance)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
