import { useCallback } from "react";
import jwt_decode from "jwt-decode";

import { authPut } from "@shared/api";
import {
  ACCESS_TOKEN_KEY,
  AUTH_URL,
  EXPIRES_AT_KEY,
  ORGANIZATION_KEY,
} from "@shared/constants";
import { recoverBrowserSecurityError } from "@shared/utils";

import { useUser } from "../useUser";

const refreshIdentity = (): Promise<{ token: string }> =>
  authPut<Record<string, never>, { token: string }>(`${AUTH_URL}/refresh`, {});

/**
 * @returns hook that returns a function to refresh the user's auth token
 */
export const useRefreshUserAuth = () => {
  const [, setUser] = useUser();

  const refreshUserAuth = useCallback(async () => {
    // post code to backend and do auth there;
    const { token } = await refreshIdentity();

    // save bearer token from backend to localStorage
    // nosemgrep
    const decoded = jwt_decode<any>(token);
    recoverBrowserSecurityError(() => {
      // make sure to invalidate cache first
      // nosemgrep
      localStorage.removeItem(ORGANIZATION_KEY);
      // then replace with new login token
      // nosemgrep
      localStorage.setItem(ACCESS_TOKEN_KEY, token);
      // nosemgrep
      localStorage.setItem(EXPIRES_AT_KEY, JSON.stringify(decoded.exp * 1000));

      // Update user in UserContext
      // setUser should be defined, but this was in the original code and auth
      // is fragile so we'll keep it. see PR #17306
      if (setUser) {
        // nosemgrep
        const { identity } = jwt_decode<any>(token);
        setUser(identity);
      }
    });
  }, [setUser]);

  return refreshUserAuth;
};
