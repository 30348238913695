import { atom } from "jotai";
import jwt_decode from "jwt-decode";

import { ACCESS_TOKEN_KEY, EXPIRES_AT_KEY } from "@shared/constants";
import { JwtV1, UserData } from "@shared/types";

// This is a re-implementation of the some of the auth and use utils from the @shared/utils package
// Importing from the shared/utils package would create a circular dependency, so as an immediate term fix we are re-implementing the required functions here
// This is not ideal and should be fixed in the future
const isAuthenticated = (): boolean => {
  let expiresAt = 0;
  try {
    expiresAt = JSON.parse(localStorage.getItem(EXPIRES_AT_KEY) || "0");
  } catch (e) {
    console.error("Error parsing expiresAt from localStorage", e);
  }
  return new Date().getTime() < expiresAt;
};

const getUserData = (): UserData => {
  let token = "";
  try {
    token = localStorage.getItem(ACCESS_TOKEN_KEY) || "";
  } catch (e) {
    console.error("Error parsing token from localStorage", e);
  }
  const decodedJwt = jwt_decode<JwtV1>(token);
  return decodedJwt.identity;
};

export const userAtom = atom(isAuthenticated() ? getUserData() : undefined);
export const userIdAtom = atom((get) => get(userAtom)?.user_id);
