import pluralize from "pluralize";
import { Anchor, Flex, Progress, Text } from "@mantine/core";

interface EditorQueryConsoleProgressProps {
  itemsProcessed?: number;
  itemsTotal?: number;
  onCancel?: () => void;
}

export const EditorQueryConsoleProgress = ({
  itemsProcessed,
  itemsTotal,
  onCancel,
}: EditorQueryConsoleProgressProps) => {
  let label = "";
  if (itemsProcessed === undefined || itemsTotal === undefined) {
    label = "Starting search...";
  } else if (itemsProcessed !== itemsTotal) {
    label = `${itemsProcessed} of ${itemsTotal} ${pluralize(
      "repo",
      itemsTotal
    )} searched`;
  }

  return (
    <div>
      <Progress
        value={((itemsProcessed ?? 0) / (itemsTotal ?? 1)) * 100}
        color="blue"
        w="100%"
        size="md"
        animated={true}
      />
      <small>
        <Flex justify="space-between">
          <Text truncate={true}>{label}</Text>
          {onCancel && (
            <Anchor component="button" c="red" onClick={onCancel}>
              Cancel
            </Anchor>
          )}
        </Flex>
      </small>
    </div>
  );
};
