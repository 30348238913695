export const dedent = (lines: string[]): string[] => {
  /* Dedent everything by what it takes to square the first line. */
  const firstLine: string = lines[0].replace(/^(\s|\t)+/, "");
  const nDedents: number = lines[0].length - firstLine.length;
  const dedentedLines = lines.map((line: string): string => {
    let dedentedLine = line;
    dedentedLine = dedentedLine.replace(
      // nosemgrep
      new RegExp(`^(\\s|\\t){${nDedents}}`),
      ""
    ); // /(\s|\t){4}/
    return dedentedLine;
  });
  return dedentedLines;
};
