import { useContext, useState } from "react";
import styled from "styled-components";
import { Button, Flex, Group, Modal, Radio, Text } from "@mantine/core";

import { Code } from "@shared/components";

import { WorkbenchContext } from "../providers";

interface Props {
  opened: boolean;
  closeDialog: () => void;
}

const OptionDescription = styled.div`
  font-size: var(--mantine-font-size-md);
`;

export const LegacyRuleSaveDialog = ({ opened, closeDialog }: Props) => {
  const { workbench } = useContext(WorkbenchContext);
  const [selectedValue, setSelectedValue] = useState("new");
  const { bundle } = workbench;

  if (
    !bundle?.rule?.id ||
    !workbench.address ||
    !workbench.doesRuleBelongToUser
  ) {
    // should never get here, since SaveButton checks if bundle is null
    // and address must be that of a snippet
    return null;
  }

  const newName = workbench.address.fullName.split(".").slice(-1)[0];

  const saveAndClose = () => {
    if (!bundle?.rule?.id || !workbench.doesRuleBelongToUser) {
      // Again, should never get here
      // This is just for type checking reasons
      closeDialog();
      return;
    }

    if (selectedValue === "overwrite") {
      bundle.setRuleId(newName);
    }
    workbench.saveBundle();
    closeDialog();
  };

  return (
    <Modal
      opened={opened}
      onClose={closeDialog}
      size="auto"
      title="This rule is currently in a legacy format. Would you like to..."
      withCloseButton={false}
      padding={24}
    >
      <Radio.Group value={selectedValue} onChange={setSelectedValue}>
        <Group mb={20} display="block">
          <Radio
            label={<span style={{ fontSize: "18px" }}>Create new</span>}
            description={
              <OptionDescription>
                This will create a new rule with id{" "}
                <Code inline light>
                  {bundle.rule.id}
                </Code>
                <Text size="xs" mt={4}>
                  Note: this newly created rule will not be added to any
                  policies
                </Text>
              </OptionDescription>
            }
            value="new"
          />
          <Radio
            label={<span style={{ fontSize: "18px" }}>Overwrite</span>}
            description={
              <OptionDescription>
                This will overwrite the current rule and change the id to{" "}
                <Code inline light>
                  {newName}
                </Code>
              </OptionDescription>
            }
            value="overwrite"
            mt={8}
          />
        </Group>
      </Radio.Group>
      <Flex justify="right" gap="md">
        <Button variant="default" onClick={closeDialog}>
          Cancel
        </Button>
        <Button onClick={saveAndClose}>Save</Button>
      </Flex>
    </Modal>
  );
};
