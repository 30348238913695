import { Range } from "monaco-editor";
import { Monaco } from "@monaco-editor/react";

import { Highlight } from "@shared/types";

export const makeHighlightDec = (highlighted: readonly Highlight[]) =>
  highlighted.map((hl) => ({
    range: hl.range,
    options: {
      inlineClassName: `highlight ${hl.type.toLowerCase()}`,
      zIndex: 1,
    },
  }));

export const makeHighlightLines = (
  highlighted: readonly Highlight[],
  _: Monaco
) => {
  return highlighted.map((hl) => {
    return {
      range: {
        startLineNumber: hl.startLine,
        startColumn: 1,
        endLineNumber: hl.endLine,
        endColumn: 1,
      } as Range,
      options: {
        isWholeLine: true,
        className: `highlight backdrop ${hl.type.toLowerCase()}`,
      },
    };
  });
};

export const firstDifferentLine = (
  targetText: string,
  newText: string
): number => {
  const targetLines = targetText.split("\n");
  const autofixLines = newText.split("\n");
  for (let i = 0; i < targetLines.length; i++) {
    if (targetLines[i] !== autofixLines[i]) {
      return i;
    }
  }
  return 0;
};
