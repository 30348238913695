import { useQuery } from "@tanstack/react-query";

import { fetchActiveFeatures } from "@shared/api";

const STALE_TIME = 300000; // 5 minutes

export const useActiveFeatures = () => {
  return useQuery({
    queryKey: ["Features"],
    queryFn: fetchActiveFeatures,
    staleTime: STALE_TIME,
  });
};
