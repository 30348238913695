import { Repository } from "@protos/projects/v1/projects";

import { useBasePath } from "../useBasePath";

/**
 * @param repo - optional, the repo to generate the URL for
 * @returns a link with default query params for the secrets page, which changes
 * based on if the user has the new secrets UI or not, and if the repo is
 * provided.
 */
export const useSecretsPageLink = (repo?: Repository) => {
  const basePath = useBasePath();
  const params = new URLSearchParams();

  if (repo) {
    params.set("repo", repo.name);
  }

  params.set(
    "validation_state",
    "confirmed_valid,validation_error,no_validator"
  );
  params.set("tab", "open");

  return `${basePath}/secrets?${params.toString()}`;
};
