import { faGlobeAmericas, faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mantine/core";

interface Props {
  visibility: string;
  allowPrivate: boolean;
  allowPublic: boolean;
  setAction: (action: "unlisted" | "org_private") => void;
}

export function VisibilityToggle({
  visibility,
  allowPrivate,
  allowPublic,
  setAction,
}: Props) {
  return (
    <Button.Group style={{ height: "40px" }} display="inline-block">
      <Button
        variant="default"
        style={{
          backgroundColor:
            visibility === "org_private" ? "lightgrey" : undefined,
        }}
        c={visibility === "org_private" ? undefined : "dimmed"}
        disabled={!allowPrivate}
        onClick={
          visibility !== "org_private"
            ? () => setAction("org_private")
            : undefined
        }
        title="Rule is visible and accessible only to your organization."
        leftSection={<FontAwesomeIcon icon={faLock} size="sm" />}
      >
        Private
      </Button>
      <Button
        variant="default"
        style={{
          backgroundColor:
            visibility === "unlisted" || !allowPublic ? "lightgrey" : undefined,
        }}
        c={visibility === "unlisted" ? undefined : "dimmed"}
        disabled={!allowPublic}
        onClick={
          visibility !== "unlisted" ? () => setAction("unlisted") : undefined
        }
        title="Rule is visible to anyone with the link."
        leftSection={<FontAwesomeIcon icon={faGlobeAmericas} size="sm" />}
      >
        {/* This is just label - "public" here means "unlisted" in data */}
        Public
      </Button>
    </Button.Group>
  );
}
