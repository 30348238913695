import mapValues from "lodash/mapValues";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  GetSearchRepoRequest,
  GetSearchRepoResponse,
} from "@protos/search/v1/search_service";
import { authGet } from "@shared/api";
import { ApiError } from "@shared/types";

const fetchSearchRepo = (options: GetSearchRepoRequest) => {
  const params = new URLSearchParams(mapValues(options, String));
  return authGet<GetSearchRepoResponse>(
    `/api/console/search/${options.searchId}/repo?${params.toString()}`
  );
};

type UseQueryConsoleRepoOptions = Omit<
  UseQueryOptions<GetSearchRepoResponse, ApiError>,
  "queryKey" | "queryFn" | "staleTime"
>;

export const useQueryConsoleRepo = (
  request: GetSearchRepoRequest,
  options: UseQueryConsoleRepoOptions = {}
) =>
  useQuery({
    queryKey: ["queryConsoleRepo", request],
    queryFn: () => fetchSearchRepo(request),
    staleTime: Infinity, // repo results are immutable once the search is complete
    ...options,
  });
