import { makeAutoObservable, runInAction } from "mobx";

import { Rule } from "@shared/types";

import { CustomRuleFile, SnippetFile } from "./FileRoot";

export class WorkbenchUI {
  fileToDelete?: SnippetFile | CustomRuleFile = undefined;
  isConfirmOverwriteAlertOpen: boolean = false;
  isRuleShareDialogOpen: boolean = false;
  isImportRulesDialogOpen: boolean = false;
  ruleImportDialogTarget: string | null = null;
  ruleShareDialogIsPublic?: boolean = undefined;
  multipleRuleDefinition?: Rule = undefined;
  isPlaygroundFeedbackOpen: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  /**
   * This, and the following two methods manage the dialog
   * open after a user would delete rules. Avoid 1-click
   * irreversible operations.
   */
  get isConfirmDeleteAlertOpen(): boolean {
    return this.fileToDelete !== undefined;
  }

  openConfirmDeleteAlert(fileToDelete: SnippetFile | CustomRuleFile) {
    this.fileToDelete = fileToDelete;
  }

  closeConfirmDeleteAlert() {
    this.fileToDelete = undefined;
  }

  /**
   * Manage whether the sharing dialog is open. Comes with
   * fitting close method right beneath.
   */
  openRuleShareDialog(isPublic?: boolean) {
    this.ruleShareDialogIsPublic = isPublic;
    this.isRuleShareDialogOpen = true;
  }

  closeRuleShareDialog() {
    this.ruleShareDialogIsPublic = undefined;
    this.isRuleShareDialogOpen = false;
  }

  /**
   * Manage whether the import dialog is open. Comes with
   * fitting close method right beneath.
   */
  openImportRulesDialog() {
    this.isImportRulesDialogOpen = true;
  }

  closeImportRulesDialog() {
    this.isImportRulesDialogOpen = false;
  }

  /**
   * These manage the dialog that opens after a user tries to save new/forked
   * rules over existing snippets. Warn the user and expect confirmation.
   */
  openConfirmOverwriteAlert() {
    this.isConfirmOverwriteAlertOpen = true;
  }

  closeConfirmOverwriteAlert() {
    this.isConfirmOverwriteAlertOpen = false;
  }

  /**
   * These methods manage the dialog that promps a user to take action after
   * opening a rule with multiple ids in it (a format that is no longer supported).
   * See MultipleRuleIdDialog.tsx
   */
  get isMultipleRuleIdAlertOpen(): boolean {
    return this.multipleRuleDefinition !== undefined;
  }

  openMultipleRuleIdAlert(definition: Rule) {
    runInAction(() => {
      this.multipleRuleDefinition = definition;
    });
  }

  closeMultipleRuleIdAlert() {
    runInAction(() => {
      this.multipleRuleDefinition = undefined;
    });
  }
}
