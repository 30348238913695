// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/permissions/v2/scopes.proto

/* eslint-disable */

export const protobufPackage = "protos.permissions.v2";

/** These scopes correspond to actions that a user might take on a certain team as a consumer of the Semgrep API */
export enum TeamScope {
  UNSPECIFIED = "TEAM_SCOPE_UNSPECIFIED",
  /** DIRECT_MEMBER - Indicates that the user is a direct member of the group */
  DIRECT_MEMBER = "TEAM_SCOPE_DIRECT_MEMBER",
  /** MANAGE - Indicates that the user can manage the team. */
  MANAGE = "TEAM_SCOPE_MANAGE",
}

export function teamScopeFromJSON(object: any): TeamScope {
  switch (object) {
    case 0:
    case "TEAM_SCOPE_UNSPECIFIED":
      return TeamScope.UNSPECIFIED;
    case 1:
    case "TEAM_SCOPE_DIRECT_MEMBER":
      return TeamScope.DIRECT_MEMBER;
    case 5:
    case "TEAM_SCOPE_MANAGE":
      return TeamScope.MANAGE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TeamScope");
  }
}

export function teamScopeToJSON(object: TeamScope): string {
  switch (object) {
    case TeamScope.UNSPECIFIED:
      return "TEAM_SCOPE_UNSPECIFIED";
    case TeamScope.DIRECT_MEMBER:
      return "TEAM_SCOPE_DIRECT_MEMBER";
    case TeamScope.MANAGE:
      return "TEAM_SCOPE_MANAGE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TeamScope");
  }
}

export function teamScopeToNumber(object: TeamScope): number {
  switch (object) {
    case TeamScope.UNSPECIFIED:
      return 0;
    case TeamScope.DIRECT_MEMBER:
      return 1;
    case TeamScope.MANAGE:
      return 5;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TeamScope");
  }
}

/** These scopes correspond to actions that a user might take on a certain repo as a consumer of the Semgrep API */
export enum RepoScope {
  UNSPECIFIED = "REPO_SCOPE_UNSPECIFIED",
  /** READ_FINDING - Can read findings from the repo */
  READ_FINDING = "REPO_SCOPE_READ_FINDING",
  /** TRIAGE_FINDING - Can triage findings from the repo */
  TRIAGE_FINDING = "REPO_SCOPE_TRIAGE_FINDING",
  /** MANAGE_PROJECT_SETTINGS - Can view and modify project settings */
  MANAGE_PROJECT_SETTINGS = "REPO_SCOPE_MANAGE_PROJECT_SETTINGS",
  /** AUTOINSTALL - Can tell semgrep to 'provision' the repo by committing a CI config file */
  AUTOINSTALL = "REPO_SCOPE_AUTOINSTALL",
  /** DELETE_PROJECT - Indicates that the user can delete the repo */
  DELETE_PROJECT = "REPO_SCOPE_DELETE_PROJECT",
  /** ASSIGN_TEAM - Indicates that the user can assign the project to an RBAC team */
  ASSIGN_TEAM = "REPO_SCOPE_ASSIGN_TEAM",
}

export function repoScopeFromJSON(object: any): RepoScope {
  switch (object) {
    case 0:
    case "REPO_SCOPE_UNSPECIFIED":
      return RepoScope.UNSPECIFIED;
    case 10:
    case "REPO_SCOPE_READ_FINDING":
      return RepoScope.READ_FINDING;
    case 12:
    case "REPO_SCOPE_TRIAGE_FINDING":
      return RepoScope.TRIAGE_FINDING;
    case 50:
    case "REPO_SCOPE_MANAGE_PROJECT_SETTINGS":
      return RepoScope.MANAGE_PROJECT_SETTINGS;
    case 51:
    case "REPO_SCOPE_AUTOINSTALL":
      return RepoScope.AUTOINSTALL;
    case 100:
    case "REPO_SCOPE_DELETE_PROJECT":
      return RepoScope.DELETE_PROJECT;
    case 101:
    case "REPO_SCOPE_ASSIGN_TEAM":
      return RepoScope.ASSIGN_TEAM;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RepoScope");
  }
}

export function repoScopeToJSON(object: RepoScope): string {
  switch (object) {
    case RepoScope.UNSPECIFIED:
      return "REPO_SCOPE_UNSPECIFIED";
    case RepoScope.READ_FINDING:
      return "REPO_SCOPE_READ_FINDING";
    case RepoScope.TRIAGE_FINDING:
      return "REPO_SCOPE_TRIAGE_FINDING";
    case RepoScope.MANAGE_PROJECT_SETTINGS:
      return "REPO_SCOPE_MANAGE_PROJECT_SETTINGS";
    case RepoScope.AUTOINSTALL:
      return "REPO_SCOPE_AUTOINSTALL";
    case RepoScope.DELETE_PROJECT:
      return "REPO_SCOPE_DELETE_PROJECT";
    case RepoScope.ASSIGN_TEAM:
      return "REPO_SCOPE_ASSIGN_TEAM";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RepoScope");
  }
}

export function repoScopeToNumber(object: RepoScope): number {
  switch (object) {
    case RepoScope.UNSPECIFIED:
      return 0;
    case RepoScope.READ_FINDING:
      return 10;
    case RepoScope.TRIAGE_FINDING:
      return 12;
    case RepoScope.MANAGE_PROJECT_SETTINGS:
      return 50;
    case RepoScope.AUTOINSTALL:
      return 51;
    case RepoScope.DELETE_PROJECT:
      return 100;
    case RepoScope.ASSIGN_TEAM:
      return 101;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RepoScope");
  }
}
