import { useContext } from "react";
import { observer } from "mobx-react-lite";

import { LanguageSelect } from "@shared/components";

import { WorkbenchContext } from "../../providers";

const LanguageSelectRowComponent = () => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;
  if (bundle === null) return null;
  return (
    <>
      <div style={{ gridColumnStart: 1 }}>Language is</div>
      <LanguageSelect
        // assuming language is valid because rule is validated before put in simple mode
        value={bundle.language!}
        onChange={bundle.setLanguage}
      />
    </>
  );
};

export const LanguageSelectRow = observer(LanguageSelectRowComponent);
