import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Helmet from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import Split from "react-split-grid";
import styled from "styled-components";
import { Modal, Text } from "@mantine/core";
import { useHotkeys } from "@mantine/hooks";
import { useAsyncEffect } from "@react-hook/async";

import { ErrorBoundary } from "@shared/components";
import { SplitGutter } from "@shared/editorCore";
import { useOrg, usePermissions, useUrlSearch, useUser } from "@shared/hooks";
import { withToaster } from "@shared/utils";

import ImportRulesDialog from "./components/FileBrowser/ImportRulesPanel";
import { RuleShareDialog } from "./components/RuleShareDialog";
import {
  ConfirmDeleteAlert,
  EditorsPanel,
  FileBrowser,
  SplashPanel,
} from "./components";
import { WorkbenchContext } from "./providers";
import { Workbench } from "./stores";
import { UrlParams } from "./types";

const Grid = styled.div<{ isPlayground: boolean }>`
  height: 100%;
  overflow-y: hidden;
  display: grid;
  grid-template-columns: 1fr 4px 4fr;
`;

const Screen = () => {
  const { bundleAddress } = useParams<UrlParams>();
  const history = useHistory();
  const [user] = useUser();
  const [org] = useOrg();
  const [permissions] = usePermissions();
  const { search } = useUrlSearch();

  const [workbench] = useState(
    () =>
      new Workbench(
        history,
        permissions,
        bundleAddress,
        user,
        org,
        history.location.pathname.startsWith("/playground"),
        // defaulting to pro engine on, turbo mode off
        search.get("turbo") === "1",
        search.get("pro") !== "0"
      )
  );

  useAsyncEffect(workbench.reloadAllData, []);
  useAsyncEffect(
    withToaster(workbench.initTurboMode, (err) => err.message),
    []
  );
  useEffect(() => {
    // Makes sure address string is always set to current url params
    if (bundleAddress !== workbench.addressString) {
      workbench.setAddressString(bundleAddress, search.get("pro") === "1");
    }
  }, [workbench, bundleAddress, search]);

  useEffect(() => {
    workbench.setContext(user, org);
  }, [workbench, user, org]);

  useEffect(() => {
    workbench.setPermissions(permissions);
  }, [workbench, permissions]);

  useHotkeys([
    [
      "mod+S",
      (e) => {
        e.preventDefault();
        if (workbench.hasUnsavedChanges) {
          workbench.saveOrFork();
        }
      },
    ],
    [
      "mod+Enter",
      () => {
        workbench.bundle?.run();
      },
    ],
  ]);

  const pageName = workbench.isPlayground ? "Playground" : "Editor";

  return (
    <>
      <Helmet>
        <title>{`${pageName} | Semgrep`}</title>
      </Helmet>
      <WorkbenchContext.Provider value={{ workbench }}>
        {workbench.ui.isRuleShareDialogOpen && <RuleShareDialog />}
        <Modal
          title={
            <Text inherit fw={700}>
              Import Rules
            </Text>
          }
          opened={workbench.ui.isImportRulesDialogOpen}
          onClose={workbench.ui.closeImportRulesDialog}
          size="xl"
          centered
          styles={{
            content: { backgroundColor: "#F8F9FA" },
            header: { borderBottom: "1px solid lightgray" },
          }}
        >
          <ImportRulesDialog name={workbench.bundle?.rule?.id || ""} />
        </Modal>
        <Split
          snapOffset={180}
          render={({ getGridProps, getGutterProps }: any) => (
            <Grid {...getGridProps()}>
              <ErrorBoundary>
                <FileBrowser />
              </ErrorBoundary>
              <SplitGutter
                between="left-right"
                {...getGutterProps("column", 1)}
              />
              <ConfirmDeleteAlert />
              <ErrorBoundary>
                {bundleAddress ? <EditorsPanel /> : <SplashPanel />}
              </ErrorBoundary>
            </Grid>
          )}
        />
      </WorkbenchContext.Provider>
    </>
  );
};

export const EditorScreen = observer(Screen);
