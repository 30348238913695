import { useEffect, useRef } from "react";
import uniq from "lodash/uniq";
import { editor, languages, Range } from "monaco-editor";
import { Monaco } from "@monaco-editor/react";

import { Highlight } from "@shared/types";
import { makeHighlightDec, makeHighlightLines } from "@shared/utils";

type HoverList = (languages.Hover | null)[];

const getDebugRanges = (
  offsetHighlight: [number, number],
  editor: editor.IStandaloneCodeEditor,
  monacoInstance: Monaco
): editor.IModelDeltaDecoration[] => {
  const [startH, endH] = offsetHighlight;
  const model = editor.getModel();
  if (model === null) {
    return [];
  }
  const startPos = model.getPositionAt(startH);
  const endPos = model.getPositionAt(endH);
  const range = monacoInstance.Range.fromPositions(startPos, endPos);
  return [
    {
      range: range,
      options: {
        className: `highlight debug-range`,
        inlineClassName: `highlight debug-range`,
        zIndex: 2,
      },
    },
  ];
};

const makeMarkers = (highlighted: readonly Highlight[]) =>
  highlighted.map((hl) => ({
    message: hl.message ?? "",
    severity: 8, // using the imported value MarkerSeverity.Error causes the build to break
    startLineNumber: hl.range.startLineNumber,
    startColumn: hl.range.startColumn,
    endLineNumber: hl.range.endLineNumber,
    endColumn: hl.range.endColumn,
  }));

const makeHovers = (highlighted: readonly Highlight[], ranges: Range[]) =>
  highlighted
    .filter((hl) => hl.message !== undefined && hl.isError)
    .map((hl, ix) => {
      const r = ranges[ix];
      const hover: languages.Hover = {
        range: r,
        contents: [
          {
            value: `**Match ${ix + 1} / ${ranges.length}**\n\n${hl.message}`,
          },
        ],
      };
      return hover;
    });

const makeHoverProvider = (hovers: HoverList, monaco: Monaco) => {
  const provider: languages.HoverProvider = {
    provideHover: (_: any, position) =>
      hovers.find(
        (h) =>
          h &&
          h.range &&
          new monaco.Range(
            h.range.startLineNumber,
            h.range.startColumn,
            h.range.endLineNumber,
            h.range.endColumn
          ).containsPosition(position)
      ),
  };
  return provider;
};

const makeGlyphLines = (highlighted: readonly Highlight[], monaco: Monaco) => {
  const uniqGroups = uniq(highlighted.map((hl) => hl.group));
  const glpyhLines = highlighted.map((hl) => ({
    range: new monaco.Range(hl.startLine, 0, hl.startLine, 0),
    options: {
      glyphMarginClassName: `highlight-glyph g${uniqGroups.indexOf(hl.group)}`,
    },
  }));
  return glpyhLines;
};

/**
 * Renders highlights on a Monaco editor.
 *
 * To use, you should call the returned method within the editor's `editorDidMount` function.
 *
 * @example
 *
 * ```
 *   const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor>();
 *   const [monaco, setMonaco] = useState<Monaco>();
 *   const registerHovers = useHighlights(monaco, editor, highlights, offset);
 *
 *   const editorDidMount = useCallback(
 *     async (
 *       mountedEditor: monaco.editor.IStandaloneCodeEditor,
 *       monaco: Monaco
 *     ) => {
 *       if (monaco === undefined) return;
 *       setEditor(mountedEditor);
 *       setMonaco(monaco);
 *       registerHovers(monaco);
 *     }, [registerHovers, setEditor, setMonaco]
 *   );
 * ```
 *
 * @param monaco The `Monaco` namespace returned by `editorDidMount`
 * @param editor The editor instance returned by `editorDidMount`
 * @param language Arbitrary language key to associate with highlights
 * @param highlighted The highlighted ranges
 * @param offsetHighlight If present, centers the editor on this (bytes) range
 * @returns The `registerHovers` callback
 */
export const useHighlights = (
  monaco: Monaco | undefined,
  editor: editor.IStandaloneCodeEditor | undefined,
  language: string | null,
  highlighted: readonly Highlight[],
  offsetHighlight: [number, number] | undefined
): ((monaco: Monaco) => void) => {
  const hovers = useRef<HoverList>([]);
  const decorationIds = useRef<string[]>([]);
  useEffect(() => {
    if (editor === undefined) return;
    if (monaco === undefined) return;
    const debugRanges = offsetHighlight
      ? getDebugRanges(offsetHighlight, editor, monaco)
      : [];
    if (debugRanges.length === 1) {
      editor.revealRangeInCenter(debugRanges[0].range);
    }

    const resultHighlights = highlighted.filter((hl) => !hl.isError);
    const errorHighlights = highlighted.filter((hl) => hl.isError);

    const highlightRanges = makeHighlightLines(resultHighlights, monaco);
    hovers.current = makeHovers(
      resultHighlights,
      highlightRanges.map((hr) => hr.range)
    );

    const markers = makeMarkers(errorHighlights);
    const model = editor.getModel();
    if (model) {
      monaco.editor.setModelMarkers(model, "semgrep_schema", markers);
    }

    const decorations = [
      ...debugRanges,
      ...highlightRanges,
      ...makeGlyphLines(resultHighlights, monaco),
      ...makeHighlightDec(resultHighlights),
    ];

    decorationIds.current = editor.deltaDecorations(
      decorationIds.current,
      decorations
    );
  }, [monaco, editor, highlighted, offsetHighlight]);

  const registerHovers = (monaco: Monaco) => {
    if (language === null) return;
    monaco.languages.registerHoverProvider(
      language,
      makeHoverProvider(hovers.current, monaco)
    );
  };
  return registerHovers;
};
