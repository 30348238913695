import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { authGet } from "@shared/api";
import { DeploymentProductConfig } from "@shared/types";

import { useOrg } from "../useOrg";

const fetchProductConfig = async (
  deploymentId: number
): Promise<DeploymentProductConfig> => {
  return authGet<{ products: DeploymentProductConfig }>(
    `/api/agent/deployments/${deploymentId}/products`
  ).then((result) => result.products);
};

export const useProductConfig = () => {
  // this hook may be called from a logged out state, so we need to handle the
  // case where org is undefined
  const [org] = useOrg();

  return useQuery({
    // If org is undefined, we set the key to `["productConfig", "logged_out"]`.
    // This is fine since the query does not run when org is undefined.
    // eslint-disable-next-line
    queryKey: ["productConfig", org?.id ?? "logged_out"],
    queryFn: () => fetchProductConfig(org!.id),
    enabled: !!org,
    placeholderData: keepPreviousData,
  });
};
