// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/issues/v1/issues.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Struct } from "../../../google/protobuf/struct";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { Codeowner } from "../../ai/v1/codeowners";
import { Remediation } from "../../ai/v1/remediation";
import { SensitivityTags } from "../../ai/v1/tags";
import {
  Autotriage,
  Autotriage_Verdict,
  autotriage_VerdictFromJSON,
  autotriage_VerdictToJSON,
  autotriage_VerdictToNumber,
} from "../../ai/v1/triage";
import {
  Confidence,
  confidenceFromJSON,
  confidenceToJSON,
  confidenceToNumber,
  FindingTriageReason,
  findingTriageReasonFromJSON,
  findingTriageReasonToJSON,
  findingTriageReasonToNumber,
  FindingTriageState,
  findingTriageStateFromJSON,
  findingTriageStateToJSON,
  findingTriageStateToNumber,
  IssueStatus,
  issueStatusFromJSON,
  issueStatusToJSON,
  issueStatusToNumber,
  Mode,
  modeFromJSON,
  modeToJSON,
  modeToNumber,
  RepositoryVisibility,
  repositoryVisibilityFromJSON,
  repositoryVisibilityToJSON,
  repositoryVisibilityToNumber,
  ScmType,
  scmTypeFromJSON,
  scmTypeToJSON,
  scmTypeToNumber,
  Severity,
  severityFromJSON,
  severityToJSON,
  severityToNumber,
  Source,
  sourceFromJSON,
  sourceToJSON,
  sourceToNumber,
  TriageSource,
  triageSourceFromJSON,
  triageSourceToJSON,
  triageSourceToNumber,
} from "../../common/v1/common";
import {
  Ecosystem,
  ecosystemFromJSON,
  ecosystemToJSON,
  ecosystemToNumber,
  EpssProbability,
  epssProbabilityFromJSON,
  epssProbabilityToJSON,
  epssProbabilityToNumber,
  EpssScore,
  Reachability,
  reachabilityFromJSON,
  reachabilityToJSON,
  reachabilityToNumber,
  ScaRuleKind,
  scaRuleKindFromJSON,
  scaRuleKindToJSON,
  scaRuleKindToNumber,
  Transitivity,
  transitivityFromJSON,
  transitivityToJSON,
  transitivityToNumber,
  Vuln_ExposureType,
  vuln_ExposureTypeFromJSON,
  vuln_ExposureTypeToJSON,
  vuln_ExposureTypeToNumber,
} from "../../sca/v1/sca";
import {
  HistoricalInfo,
  ValidationState,
  validationStateFromJSON,
  validationStateToJSON,
  validationStateToNumber,
} from "../../secrets/v1/secrets";
import { ExternalTicket, TicketAttempt } from "../../ticketing/v1/ticketing";

export const protobufPackage = "protos.issues.v1";

export enum TimeFilter {
  UNSPECIFIED = "TIME_FILTER_UNSPECIFIED",
  RELEVANT_SINCE = "TIME_FILTER_RELEVANT_SINCE",
  TRIAGED_AT = "TIME_FILTER_TRIAGED_AT",
  FIXED_AT = "TIME_FILTER_FIXED_AT",
}

export function timeFilterFromJSON(object: any): TimeFilter {
  switch (object) {
    case 0:
    case "TIME_FILTER_UNSPECIFIED":
      return TimeFilter.UNSPECIFIED;
    case 1:
    case "TIME_FILTER_RELEVANT_SINCE":
      return TimeFilter.RELEVANT_SINCE;
    case 2:
    case "TIME_FILTER_TRIAGED_AT":
      return TimeFilter.TRIAGED_AT;
    case 3:
    case "TIME_FILTER_FIXED_AT":
      return TimeFilter.FIXED_AT;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TimeFilter");
  }
}

export function timeFilterToJSON(object: TimeFilter): string {
  switch (object) {
    case TimeFilter.UNSPECIFIED:
      return "TIME_FILTER_UNSPECIFIED";
    case TimeFilter.RELEVANT_SINCE:
      return "TIME_FILTER_RELEVANT_SINCE";
    case TimeFilter.TRIAGED_AT:
      return "TIME_FILTER_TRIAGED_AT";
    case TimeFilter.FIXED_AT:
      return "TIME_FILTER_FIXED_AT";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TimeFilter");
  }
}

export function timeFilterToNumber(object: TimeFilter): number {
  switch (object) {
    case TimeFilter.UNSPECIFIED:
      return 0;
    case TimeFilter.RELEVANT_SINCE:
      return 1;
    case TimeFilter.TRIAGED_AT:
      return 2;
    case TimeFilter.FIXED_AT:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TimeFilter");
  }
}

export enum RuleOrigin {
  UNSPECIFIED = "RULE_ORIGIN_UNSPECIFIED",
  CUSTOM = "RULE_ORIGIN_CUSTOM",
  COMMUNITY = "RULE_ORIGIN_COMMUNITY",
  PRO_RULES = "RULE_ORIGIN_PRO_RULES",
}

export function ruleOriginFromJSON(object: any): RuleOrigin {
  switch (object) {
    case 0:
    case "RULE_ORIGIN_UNSPECIFIED":
      return RuleOrigin.UNSPECIFIED;
    case 1:
    case "RULE_ORIGIN_CUSTOM":
      return RuleOrigin.CUSTOM;
    case 2:
    case "RULE_ORIGIN_COMMUNITY":
      return RuleOrigin.COMMUNITY;
    case 3:
    case "RULE_ORIGIN_PRO_RULES":
      return RuleOrigin.PRO_RULES;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RuleOrigin");
  }
}

export function ruleOriginToJSON(object: RuleOrigin): string {
  switch (object) {
    case RuleOrigin.UNSPECIFIED:
      return "RULE_ORIGIN_UNSPECIFIED";
    case RuleOrigin.CUSTOM:
      return "RULE_ORIGIN_CUSTOM";
    case RuleOrigin.COMMUNITY:
      return "RULE_ORIGIN_COMMUNITY";
    case RuleOrigin.PRO_RULES:
      return "RULE_ORIGIN_PRO_RULES";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RuleOrigin");
  }
}

export function ruleOriginToNumber(object: RuleOrigin): number {
  switch (object) {
    case RuleOrigin.UNSPECIFIED:
      return 0;
    case RuleOrigin.CUSTOM:
      return 1;
    case RuleOrigin.COMMUNITY:
      return 2;
    case RuleOrigin.PRO_RULES:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RuleOrigin");
  }
}

export enum RuleVisibility {
  UNSPECIFIED = "RULE_VISIBILITY_UNSPECIFIED",
  /** UNLISTED - used for rules via the editor */
  UNLISTED = "RULE_VISIBILITY_UNLISTED",
  /** PUBLIC - used for rules via semgrep-rules + community registries */
  PUBLIC = "RULE_VISIBILITY_PUBLIC",
  /** ORG_PRIVATE - used for rules that are private to members of an org */
  ORG_PRIVATE = "RULE_VISIBILITY_ORG_PRIVATE",
  /** LOGGED_IN - used for rules that are only visible to logged in users */
  LOGGED_IN = "RULE_VISIBILITY_LOGGED_IN",
  /** TEAM_TIER - used for rules that are only visibile to users in team tier or above */
  TEAM_TIER = "RULE_VISIBILITY_TEAM_TIER",
}

export function ruleVisibilityFromJSON(object: any): RuleVisibility {
  switch (object) {
    case 0:
    case "RULE_VISIBILITY_UNSPECIFIED":
      return RuleVisibility.UNSPECIFIED;
    case 1:
    case "RULE_VISIBILITY_UNLISTED":
      return RuleVisibility.UNLISTED;
    case 2:
    case "RULE_VISIBILITY_PUBLIC":
      return RuleVisibility.PUBLIC;
    case 3:
    case "RULE_VISIBILITY_ORG_PRIVATE":
      return RuleVisibility.ORG_PRIVATE;
    case 4:
    case "RULE_VISIBILITY_LOGGED_IN":
      return RuleVisibility.LOGGED_IN;
    case 5:
    case "RULE_VISIBILITY_TEAM_TIER":
      return RuleVisibility.TEAM_TIER;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RuleVisibility");
  }
}

export function ruleVisibilityToJSON(object: RuleVisibility): string {
  switch (object) {
    case RuleVisibility.UNSPECIFIED:
      return "RULE_VISIBILITY_UNSPECIFIED";
    case RuleVisibility.UNLISTED:
      return "RULE_VISIBILITY_UNLISTED";
    case RuleVisibility.PUBLIC:
      return "RULE_VISIBILITY_PUBLIC";
    case RuleVisibility.ORG_PRIVATE:
      return "RULE_VISIBILITY_ORG_PRIVATE";
    case RuleVisibility.LOGGED_IN:
      return "RULE_VISIBILITY_LOGGED_IN";
    case RuleVisibility.TEAM_TIER:
      return "RULE_VISIBILITY_TEAM_TIER";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RuleVisibility");
  }
}

export function ruleVisibilityToNumber(object: RuleVisibility): number {
  switch (object) {
    case RuleVisibility.UNSPECIFIED:
      return 0;
    case RuleVisibility.UNLISTED:
      return 1;
    case RuleVisibility.PUBLIC:
      return 2;
    case RuleVisibility.ORG_PRIVATE:
      return 3;
    case RuleVisibility.LOGGED_IN:
      return 4;
    case RuleVisibility.TEAM_TIER:
      return 5;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RuleVisibility");
  }
}

export enum RuleType {
  UNSPECIFIED = "RULE_TYPE_UNSPECIFIED",
  SAST = "RULE_TYPE_SAST",
  SCA = "RULE_TYPE_SCA",
  SECRETS = "RULE_TYPE_SECRETS",
}

export function ruleTypeFromJSON(object: any): RuleType {
  switch (object) {
    case 0:
    case "RULE_TYPE_UNSPECIFIED":
      return RuleType.UNSPECIFIED;
    case 1:
    case "RULE_TYPE_SAST":
      return RuleType.SAST;
    case 2:
    case "RULE_TYPE_SCA":
      return RuleType.SCA;
    case 3:
    case "RULE_TYPE_SECRETS":
      return RuleType.SECRETS;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RuleType");
  }
}

export function ruleTypeToJSON(object: RuleType): string {
  switch (object) {
    case RuleType.UNSPECIFIED:
      return "RULE_TYPE_UNSPECIFIED";
    case RuleType.SAST:
      return "RULE_TYPE_SAST";
    case RuleType.SCA:
      return "RULE_TYPE_SCA";
    case RuleType.SECRETS:
      return "RULE_TYPE_SECRETS";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RuleType");
  }
}

export function ruleTypeToNumber(object: RuleType): number {
  switch (object) {
    case RuleType.UNSPECIFIED:
      return 0;
    case RuleType.SAST:
      return 1;
    case RuleType.SCA:
      return 2;
    case RuleType.SECRETS:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RuleType");
  }
}

export enum IssueType {
  UNSPECIFIED = "ISSUE_TYPE_UNSPECIFIED",
  SAST = "ISSUE_TYPE_SAST",
  SCA = "ISSUE_TYPE_SCA",
  SECRETS = "ISSUE_TYPE_SECRETS",
  RESEARCH = "ISSUE_TYPE_RESEARCH",
}

export function issueTypeFromJSON(object: any): IssueType {
  switch (object) {
    case 0:
    case "ISSUE_TYPE_UNSPECIFIED":
      return IssueType.UNSPECIFIED;
    case 1:
    case "ISSUE_TYPE_SAST":
      return IssueType.SAST;
    case 2:
    case "ISSUE_TYPE_SCA":
      return IssueType.SCA;
    case 3:
    case "ISSUE_TYPE_SECRETS":
      return IssueType.SECRETS;
    case 4:
    case "ISSUE_TYPE_RESEARCH":
      return IssueType.RESEARCH;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum IssueType");
  }
}

export function issueTypeToJSON(object: IssueType): string {
  switch (object) {
    case IssueType.UNSPECIFIED:
      return "ISSUE_TYPE_UNSPECIFIED";
    case IssueType.SAST:
      return "ISSUE_TYPE_SAST";
    case IssueType.SCA:
      return "ISSUE_TYPE_SCA";
    case IssueType.SECRETS:
      return "ISSUE_TYPE_SECRETS";
    case IssueType.RESEARCH:
      return "ISSUE_TYPE_RESEARCH";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum IssueType");
  }
}

export function issueTypeToNumber(object: IssueType): number {
  switch (object) {
    case IssueType.UNSPECIFIED:
      return 0;
    case IssueType.SAST:
      return 1;
    case IssueType.SCA:
      return 2;
    case IssueType.SECRETS:
      return 3;
    case IssueType.RESEARCH:
      return 4;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum IssueType");
  }
}

/** One state to rule them all. Covers scan status, triaging, external events such as PR closes, etc. */
export enum AggregateIssueState {
  UNSPECIFIED = "AGGREGATE_ISSUE_STATE_UNSPECIFIED",
  OPEN = "AGGREGATE_ISSUE_STATE_OPEN",
  FIXED = "AGGREGATE_ISSUE_STATE_FIXED",
  REMOVED = "AGGREGATE_ISSUE_STATE_REMOVED",
  IGNORED_APP = "AGGREGATE_ISSUE_STATE_IGNORED_APP",
  IGNORED_CODE = "AGGREGATE_ISSUE_STATE_IGNORED_CODE",
  UNKNOWN = "AGGREGATE_ISSUE_STATE_UNKNOWN",
  REVIEWING = "AGGREGATE_ISSUE_STATE_REVIEWING",
  FIXING = "AGGREGATE_ISSUE_STATE_FIXING",
}

export function aggregateIssueStateFromJSON(object: any): AggregateIssueState {
  switch (object) {
    case 0:
    case "AGGREGATE_ISSUE_STATE_UNSPECIFIED":
      return AggregateIssueState.UNSPECIFIED;
    case 1:
    case "AGGREGATE_ISSUE_STATE_OPEN":
      return AggregateIssueState.OPEN;
    case 2:
    case "AGGREGATE_ISSUE_STATE_FIXED":
      return AggregateIssueState.FIXED;
    case 3:
    case "AGGREGATE_ISSUE_STATE_REMOVED":
      return AggregateIssueState.REMOVED;
    case 4:
    case "AGGREGATE_ISSUE_STATE_IGNORED_APP":
      return AggregateIssueState.IGNORED_APP;
    case 5:
    case "AGGREGATE_ISSUE_STATE_IGNORED_CODE":
      return AggregateIssueState.IGNORED_CODE;
    case 6:
    case "AGGREGATE_ISSUE_STATE_UNKNOWN":
      return AggregateIssueState.UNKNOWN;
    case 7:
    case "AGGREGATE_ISSUE_STATE_REVIEWING":
      return AggregateIssueState.REVIEWING;
    case 8:
    case "AGGREGATE_ISSUE_STATE_FIXING":
      return AggregateIssueState.FIXING;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AggregateIssueState");
  }
}

export function aggregateIssueStateToJSON(object: AggregateIssueState): string {
  switch (object) {
    case AggregateIssueState.UNSPECIFIED:
      return "AGGREGATE_ISSUE_STATE_UNSPECIFIED";
    case AggregateIssueState.OPEN:
      return "AGGREGATE_ISSUE_STATE_OPEN";
    case AggregateIssueState.FIXED:
      return "AGGREGATE_ISSUE_STATE_FIXED";
    case AggregateIssueState.REMOVED:
      return "AGGREGATE_ISSUE_STATE_REMOVED";
    case AggregateIssueState.IGNORED_APP:
      return "AGGREGATE_ISSUE_STATE_IGNORED_APP";
    case AggregateIssueState.IGNORED_CODE:
      return "AGGREGATE_ISSUE_STATE_IGNORED_CODE";
    case AggregateIssueState.UNKNOWN:
      return "AGGREGATE_ISSUE_STATE_UNKNOWN";
    case AggregateIssueState.REVIEWING:
      return "AGGREGATE_ISSUE_STATE_REVIEWING";
    case AggregateIssueState.FIXING:
      return "AGGREGATE_ISSUE_STATE_FIXING";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AggregateIssueState");
  }
}

export function aggregateIssueStateToNumber(object: AggregateIssueState): number {
  switch (object) {
    case AggregateIssueState.UNSPECIFIED:
      return 0;
    case AggregateIssueState.OPEN:
      return 1;
    case AggregateIssueState.FIXED:
      return 2;
    case AggregateIssueState.REMOVED:
      return 3;
    case AggregateIssueState.IGNORED_APP:
      return 4;
    case AggregateIssueState.IGNORED_CODE:
      return 5;
    case AggregateIssueState.UNKNOWN:
      return 6;
    case AggregateIssueState.REVIEWING:
      return 7;
    case AggregateIssueState.FIXING:
      return 8;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AggregateIssueState");
  }
}

export enum IssueTab {
  UNSPECIFIED = "ISSUE_TAB_UNSPECIFIED",
  OPEN = "ISSUE_TAB_OPEN",
  IGNORED = "ISSUE_TAB_IGNORED",
  CLOSED = "ISSUE_TAB_CLOSED",
  REVIEWING = "ISSUE_TAB_REVIEWING",
  FIXING = "ISSUE_TAB_FIXING",
}

export function issueTabFromJSON(object: any): IssueTab {
  switch (object) {
    case 0:
    case "ISSUE_TAB_UNSPECIFIED":
      return IssueTab.UNSPECIFIED;
    case 1:
    case "ISSUE_TAB_OPEN":
      return IssueTab.OPEN;
    case 2:
    case "ISSUE_TAB_IGNORED":
      return IssueTab.IGNORED;
    case 3:
    case "ISSUE_TAB_CLOSED":
      return IssueTab.CLOSED;
    case 4:
    case "ISSUE_TAB_REVIEWING":
      return IssueTab.REVIEWING;
    case 5:
    case "ISSUE_TAB_FIXING":
      return IssueTab.FIXING;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum IssueTab");
  }
}

export function issueTabToJSON(object: IssueTab): string {
  switch (object) {
    case IssueTab.UNSPECIFIED:
      return "ISSUE_TAB_UNSPECIFIED";
    case IssueTab.OPEN:
      return "ISSUE_TAB_OPEN";
    case IssueTab.IGNORED:
      return "ISSUE_TAB_IGNORED";
    case IssueTab.CLOSED:
      return "ISSUE_TAB_CLOSED";
    case IssueTab.REVIEWING:
      return "ISSUE_TAB_REVIEWING";
    case IssueTab.FIXING:
      return "ISSUE_TAB_FIXING";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum IssueTab");
  }
}

export function issueTabToNumber(object: IssueTab): number {
  switch (object) {
    case IssueTab.UNSPECIFIED:
      return 0;
    case IssueTab.OPEN:
      return 1;
    case IssueTab.IGNORED:
      return 2;
    case IssueTab.CLOSED:
      return 3;
    case IssueTab.REVIEWING:
      return 4;
    case IssueTab.FIXING:
      return 5;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum IssueTab");
  }
}

export enum IssueBacklogType {
  UNSPECIFIED = "ISSUE_BACKLOG_TYPE_UNSPECIFIED",
  PROD = "ISSUE_BACKLOG_TYPE_PROD",
  PREPROD = "ISSUE_BACKLOG_TYPE_PREPROD",
}

export function issueBacklogTypeFromJSON(object: any): IssueBacklogType {
  switch (object) {
    case 0:
    case "ISSUE_BACKLOG_TYPE_UNSPECIFIED":
      return IssueBacklogType.UNSPECIFIED;
    case 1:
    case "ISSUE_BACKLOG_TYPE_PROD":
      return IssueBacklogType.PROD;
    case 2:
    case "ISSUE_BACKLOG_TYPE_PREPROD":
      return IssueBacklogType.PREPROD;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum IssueBacklogType");
  }
}

export function issueBacklogTypeToJSON(object: IssueBacklogType): string {
  switch (object) {
    case IssueBacklogType.UNSPECIFIED:
      return "ISSUE_BACKLOG_TYPE_UNSPECIFIED";
    case IssueBacklogType.PROD:
      return "ISSUE_BACKLOG_TYPE_PROD";
    case IssueBacklogType.PREPROD:
      return "ISSUE_BACKLOG_TYPE_PREPROD";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum IssueBacklogType");
  }
}

export function issueBacklogTypeToNumber(object: IssueBacklogType): number {
  switch (object) {
    case IssueBacklogType.UNSPECIFIED:
      return 0;
    case IssueBacklogType.PROD:
      return 1;
    case IssueBacklogType.PREPROD:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum IssueBacklogType");
  }
}

export interface GetIssueRequest {
  deploymentId: number;
  issueId: number;
}

export interface ListIssuesRequest {
  /** Specifies the deployment to which the issues belong. */
  deploymentId: number;
  /** Filters issues to those with the specified type (based on the product which produced these issues). Only SAST and SCA are currently supported. */
  issueType: IssueType;
  /** Specifies the field to sort the results by. */
  sortBy?:
    | ListIssuesRequest_SortBy
    | undefined;
  /** Specifies the direction to sort the results. */
  sortDirection?:
    | ListIssuesRequest_SortDirection
    | undefined;
  /** Sets the page size of the paginated list of issues. */
  limit?:
    | number
    | undefined;
  /** Cursor used for pagination -- passing no value or an empty string effectively requests the first page. */
  cursor?:
    | string
    | undefined;
  /** Filters the issues to list. */
  filter?: IssuesFilter | undefined;
}

export enum ListIssuesRequest_SortBy {
  UNSPECIFIED = "SORT_BY_UNSPECIFIED",
  SEVERITY = "SORT_BY_SEVERITY",
  REF = "SORT_BY_REF",
  REPO = "SORT_BY_REPO",
  RULE = "SORT_BY_RULE",
  RELEVANT_SINCE = "SORT_BY_RELEVANT_SINCE",
  STATE = "SORT_BY_STATE",
}

export function listIssuesRequest_SortByFromJSON(object: any): ListIssuesRequest_SortBy {
  switch (object) {
    case 0:
    case "SORT_BY_UNSPECIFIED":
      return ListIssuesRequest_SortBy.UNSPECIFIED;
    case 1:
    case "SORT_BY_SEVERITY":
      return ListIssuesRequest_SortBy.SEVERITY;
    case 2:
    case "SORT_BY_REF":
      return ListIssuesRequest_SortBy.REF;
    case 3:
    case "SORT_BY_REPO":
      return ListIssuesRequest_SortBy.REPO;
    case 4:
    case "SORT_BY_RULE":
      return ListIssuesRequest_SortBy.RULE;
    case 5:
    case "SORT_BY_RELEVANT_SINCE":
      return ListIssuesRequest_SortBy.RELEVANT_SINCE;
    case 6:
    case "SORT_BY_STATE":
      return ListIssuesRequest_SortBy.STATE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ListIssuesRequest_SortBy");
  }
}

export function listIssuesRequest_SortByToJSON(object: ListIssuesRequest_SortBy): string {
  switch (object) {
    case ListIssuesRequest_SortBy.UNSPECIFIED:
      return "SORT_BY_UNSPECIFIED";
    case ListIssuesRequest_SortBy.SEVERITY:
      return "SORT_BY_SEVERITY";
    case ListIssuesRequest_SortBy.REF:
      return "SORT_BY_REF";
    case ListIssuesRequest_SortBy.REPO:
      return "SORT_BY_REPO";
    case ListIssuesRequest_SortBy.RULE:
      return "SORT_BY_RULE";
    case ListIssuesRequest_SortBy.RELEVANT_SINCE:
      return "SORT_BY_RELEVANT_SINCE";
    case ListIssuesRequest_SortBy.STATE:
      return "SORT_BY_STATE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ListIssuesRequest_SortBy");
  }
}

export function listIssuesRequest_SortByToNumber(object: ListIssuesRequest_SortBy): number {
  switch (object) {
    case ListIssuesRequest_SortBy.UNSPECIFIED:
      return 0;
    case ListIssuesRequest_SortBy.SEVERITY:
      return 1;
    case ListIssuesRequest_SortBy.REF:
      return 2;
    case ListIssuesRequest_SortBy.REPO:
      return 3;
    case ListIssuesRequest_SortBy.RULE:
      return 4;
    case ListIssuesRequest_SortBy.RELEVANT_SINCE:
      return 5;
    case ListIssuesRequest_SortBy.STATE:
      return 6;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ListIssuesRequest_SortBy");
  }
}

export enum ListIssuesRequest_SortDirection {
  UNSPECIFIED = "SORT_DIRECTION_UNSPECIFIED",
  ASC = "SORT_DIRECTION_ASC",
  DESC = "SORT_DIRECTION_DESC",
}

export function listIssuesRequest_SortDirectionFromJSON(object: any): ListIssuesRequest_SortDirection {
  switch (object) {
    case 0:
    case "SORT_DIRECTION_UNSPECIFIED":
      return ListIssuesRequest_SortDirection.UNSPECIFIED;
    case 1:
    case "SORT_DIRECTION_ASC":
      return ListIssuesRequest_SortDirection.ASC;
    case 2:
    case "SORT_DIRECTION_DESC":
      return ListIssuesRequest_SortDirection.DESC;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ListIssuesRequest_SortDirection");
  }
}

export function listIssuesRequest_SortDirectionToJSON(object: ListIssuesRequest_SortDirection): string {
  switch (object) {
    case ListIssuesRequest_SortDirection.UNSPECIFIED:
      return "SORT_DIRECTION_UNSPECIFIED";
    case ListIssuesRequest_SortDirection.ASC:
      return "SORT_DIRECTION_ASC";
    case ListIssuesRequest_SortDirection.DESC:
      return "SORT_DIRECTION_DESC";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ListIssuesRequest_SortDirection");
  }
}

export function listIssuesRequest_SortDirectionToNumber(object: ListIssuesRequest_SortDirection): number {
  switch (object) {
    case ListIssuesRequest_SortDirection.UNSPECIFIED:
      return 0;
    case ListIssuesRequest_SortDirection.ASC:
      return 1;
    case ListIssuesRequest_SortDirection.DESC:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ListIssuesRequest_SortDirection");
  }
}

export interface ListIssuesResponse {
  issues: ListIssuesResponse_IssueSummary[];
  cursor?: string | undefined;
}

export interface ListIssuesResponse_ReviewComment {
  isInline: boolean;
  threadId: string;
}

export interface ListIssuesResponse_IssueSummary {
  issue: Issue | undefined;
  reviewCount: number;
  triageCount: number;
  allRefs: string[];
  aiSilencedCount: number;
  /** @deprecated */
  allReviewThreadIds: string[];
  allReviewComments: ListIssuesResponse_ReviewComment[];
}

export interface GetIssueCountsRequest {
  /** Specifies the deployment to which the issues belong. */
  deploymentId: number;
  /** Filters issues to those with the specified type (based on the product which produced these issues). Only SAST and SCA are currently supported. */
  issueType: IssueType;
  /** Filters the issues to count. */
  filter?: IssuesFilter | undefined;
}

export interface GetIssueCountsResponse {
  open: number;
  ignored: number;
  ignoredFalsePositive: number;
  ignoredAcceptableRisk: number;
  ignoredNoTime: number;
  ignoredNoTriageReason: number;
  closed: number;
  reviewing: number;
  fixing: number;
}

export interface GetIssueFiltersRequest {
  /** Specifies the deployment to which the issues belong. */
  deploymentId: number;
  /** Filters issues to those with the specified type (based on the product which produced these issues). If set to `ISSUE_TYPE_UNSPECIFIED`, oly generic filters will be returned */
  issueType: IssueType;
  /** Filters the issues to process. */
  filter?: IssuesFilter | undefined;
}

export interface RuleFilterItem {
  /** the numeric ID of the rule */
  id: number;
  /** a human-readable name for the rule */
  name: string;
  /** set when the rule is a SCA rule and it has an associated CVE */
  scaCve?:
    | string
    | undefined;
  /** set when the rule is a SCA rule and it has an associated GHSA */
  scaGhsa?: string | undefined;
}

export interface GetIssueFiltersResponse {
  /**
   * Applies to all issue types. Deprecated, migrate to repos
   *
   * @deprecated
   */
  repoNames: string[];
  /**
   * Applies to all issue types. Deprecated, migrate to tags
   *
   * @deprecated
   */
  repoTags: string[];
  /** Applies to all issue types */
  refs: string[];
  /** Applies to all issue types */
  repoRefGroups: GetIssueFiltersResponse_RepositoryRefFilterItemGroup[];
  /** Applies to SAST issues only. */
  rulesetNames: string[];
  /**
   * Applies to SAST issues only. Deprecated, migrate to `rules` instead.
   *
   * @deprecated
   */
  ruleNames: string[];
  /** Applies to SAST issues only. */
  categories: string[];
  /** Applies to all issue types */
  teamNames: string[];
  /** Applies to Secrets issues only */
  secretTypes: string[];
  /** Applies to all issue types. Corresponds to the rule_id filter, but provides extra information for display. */
  rules: RuleFilterItem[];
  repos: GetIssueFiltersResponse_AnnotatedRepo[];
  tags: GetIssueFiltersResponse_AnnotatedRepoTag[];
}

export interface GetIssueFiltersResponse_RepositoryRefFilterItemGroup {
  repoName: string;
  repoRefs: GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef[];
  hasPrimaryRef: boolean;
}

export interface GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef {
  id: number;
  refName: string;
  isPrimary: boolean;
}

export interface GetIssueFiltersResponse_AnnotatedRepo {
  id: number;
  name: string;
}

export interface GetIssueFiltersResponse_AnnotatedRepoTag {
  id: number;
  name: string;
}

export interface ListIssueGroupsRequest {
  /** Specifies the deployment to which the issues belong. */
  deploymentId: number;
  /** Filters issues to those with the specified type (based on the product which produced these issues). Only SAST and SCA are currently supported. */
  issueType: IssueType;
  /** Specifies how to group the issues in the response. */
  groupBy?:
    | ListIssueGroupsRequest_GroupBy
    | undefined;
  /** Sets the page size of the paginated list of issues. */
  limit?:
    | number
    | undefined;
  /** Cursor used for pagination -- passing no value or an empty string effectively requests the first page. */
  cursor?:
    | string
    | undefined;
  /** Filters the issues to search and group. */
  filter?:
    | IssuesFilter
    | undefined;
  /** Specifies how to sort the issue groups. */
  sortBy?:
    | ListIssueGroupsRequest_SortBy
    | undefined;
  /** Specifies the direction to sort the results. */
  sortDirection?: ListIssueGroupsRequest_SortDirection | undefined;
}

export enum ListIssueGroupsRequest_GroupBy {
  UNSPECIFIED = "GROUP_BY_UNSPECIFIED",
  RULE = "GROUP_BY_RULE",
}

export function listIssueGroupsRequest_GroupByFromJSON(object: any): ListIssueGroupsRequest_GroupBy {
  switch (object) {
    case 0:
    case "GROUP_BY_UNSPECIFIED":
      return ListIssueGroupsRequest_GroupBy.UNSPECIFIED;
    case 1:
    case "GROUP_BY_RULE":
      return ListIssueGroupsRequest_GroupBy.RULE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ListIssueGroupsRequest_GroupBy");
  }
}

export function listIssueGroupsRequest_GroupByToJSON(object: ListIssueGroupsRequest_GroupBy): string {
  switch (object) {
    case ListIssueGroupsRequest_GroupBy.UNSPECIFIED:
      return "GROUP_BY_UNSPECIFIED";
    case ListIssueGroupsRequest_GroupBy.RULE:
      return "GROUP_BY_RULE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ListIssueGroupsRequest_GroupBy");
  }
}

export function listIssueGroupsRequest_GroupByToNumber(object: ListIssueGroupsRequest_GroupBy): number {
  switch (object) {
    case ListIssueGroupsRequest_GroupBy.UNSPECIFIED:
      return 0;
    case ListIssueGroupsRequest_GroupBy.RULE:
      return 1;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ListIssueGroupsRequest_GroupBy");
  }
}

export enum ListIssueGroupsRequest_SortBy {
  UNSPECIFIED = "SORT_BY_UNSPECIFIED",
  ISSUE_COUNTS = "SORT_BY_ISSUE_COUNTS",
  SEVERITY = "SORT_BY_SEVERITY",
}

export function listIssueGroupsRequest_SortByFromJSON(object: any): ListIssueGroupsRequest_SortBy {
  switch (object) {
    case 0:
    case "SORT_BY_UNSPECIFIED":
      return ListIssueGroupsRequest_SortBy.UNSPECIFIED;
    case 1:
    case "SORT_BY_ISSUE_COUNTS":
      return ListIssueGroupsRequest_SortBy.ISSUE_COUNTS;
    case 2:
    case "SORT_BY_SEVERITY":
      return ListIssueGroupsRequest_SortBy.SEVERITY;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ListIssueGroupsRequest_SortBy");
  }
}

export function listIssueGroupsRequest_SortByToJSON(object: ListIssueGroupsRequest_SortBy): string {
  switch (object) {
    case ListIssueGroupsRequest_SortBy.UNSPECIFIED:
      return "SORT_BY_UNSPECIFIED";
    case ListIssueGroupsRequest_SortBy.ISSUE_COUNTS:
      return "SORT_BY_ISSUE_COUNTS";
    case ListIssueGroupsRequest_SortBy.SEVERITY:
      return "SORT_BY_SEVERITY";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ListIssueGroupsRequest_SortBy");
  }
}

export function listIssueGroupsRequest_SortByToNumber(object: ListIssueGroupsRequest_SortBy): number {
  switch (object) {
    case ListIssueGroupsRequest_SortBy.UNSPECIFIED:
      return 0;
    case ListIssueGroupsRequest_SortBy.ISSUE_COUNTS:
      return 1;
    case ListIssueGroupsRequest_SortBy.SEVERITY:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ListIssueGroupsRequest_SortBy");
  }
}

export enum ListIssueGroupsRequest_SortDirection {
  UNSPECIFIED = "SORT_DIRECTION_UNSPECIFIED",
  ASC = "SORT_DIRECTION_ASC",
  DESC = "SORT_DIRECTION_DESC",
}

export function listIssueGroupsRequest_SortDirectionFromJSON(object: any): ListIssueGroupsRequest_SortDirection {
  switch (object) {
    case 0:
    case "SORT_DIRECTION_UNSPECIFIED":
      return ListIssueGroupsRequest_SortDirection.UNSPECIFIED;
    case 1:
    case "SORT_DIRECTION_ASC":
      return ListIssueGroupsRequest_SortDirection.ASC;
    case 2:
    case "SORT_DIRECTION_DESC":
      return ListIssueGroupsRequest_SortDirection.DESC;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum ListIssueGroupsRequest_SortDirection",
      );
  }
}

export function listIssueGroupsRequest_SortDirectionToJSON(object: ListIssueGroupsRequest_SortDirection): string {
  switch (object) {
    case ListIssueGroupsRequest_SortDirection.UNSPECIFIED:
      return "SORT_DIRECTION_UNSPECIFIED";
    case ListIssueGroupsRequest_SortDirection.ASC:
      return "SORT_DIRECTION_ASC";
    case ListIssueGroupsRequest_SortDirection.DESC:
      return "SORT_DIRECTION_DESC";
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum ListIssueGroupsRequest_SortDirection",
      );
  }
}

export function listIssueGroupsRequest_SortDirectionToNumber(object: ListIssueGroupsRequest_SortDirection): number {
  switch (object) {
    case ListIssueGroupsRequest_SortDirection.UNSPECIFIED:
      return 0;
    case ListIssueGroupsRequest_SortDirection.ASC:
      return 1;
    case ListIssueGroupsRequest_SortDirection.DESC:
      return 2;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum ListIssueGroupsRequest_SortDirection",
      );
  }
}

export interface ListIssueParentGroupsKeySet {
  keySet?: { $case: "sourceKeySet"; sourceKeySet: ListIssueParentGroupsKeySet_SourceKeySet } | {
    $case: "issueCountsKeySet";
    issueCountsKeySet: ListIssueParentGroupsKeySet_IssueCountsKeySet;
  } | undefined;
}

export interface ListIssueParentGroupsKeySet_SourceKeySet {
  ruleSeverity: Severity;
  ruleVisibility: RuleVisibility;
  issueCounts: number;
  ruleId: number;
}

export interface ListIssueParentGroupsKeySet_IssueCountsKeySet {
  issueCounts: number;
  ruleId: number;
}

/** This represents the information of a rule which may supersede another. */
export interface SupersededRule {
  product: RuleType;
  rulePath?: string | undefined;
}

export interface ListIssueGroupsResponse {
  groups: ListIssueGroupsResponse_RuleGroup[];
  cursor?: string | undefined;
  total?: number | undefined;
  fetched?: number | undefined;
}

export interface ListIssueGroupsResponse_Rule {
  ruleId: string;
  ruleVersionId?: string | undefined;
  origin?: RuleOrigin | undefined;
}

export interface ListIssueGroupsResponse_RuleMeta {
  author?: string | undefined;
  authorPhotoUrl?: string | undefined;
  rule: ListIssueGroupsResponse_Rule | undefined;
}

export interface ListIssueGroupsResponse_RuleGroup {
  issuesCount: number;
  ruleId: number;
  ruleShaSum: string;
  ruleVisibility: RuleVisibility;
  ruleMeta: ListIssueGroupsResponse_RuleMeta | undefined;
  ruleLastChangeAt: Date | undefined;
  ruleLastChangeBy?: string | undefined;
  rulePath?: string | undefined;
  ruleSourceUri?: string | undefined;
  ruleTestCases: ListIssueGroupsResponse_RuleGroup_TestCase[];
  /** TODO: either flesh this out or remove this field to make room for a more concise, readable field (or reference) */
  ruleDefinition: { [key: string]: any } | undefined;
  ruleDeploymentName?:
    | string
    | undefined;
  /**
   * The information for what rules this rule may be "superseded by".
   * See https://www.notion.so/semgrep/Superseded-Rules-7d52c86d82ed466a9a1044d478e058d3?pvs=4
   */
  ruleSupersededBy: SupersededRule[];
  productAttributes?:
    | { $case: "sastAttributes"; sastAttributes: ListIssueGroupsResponse_RuleGroup_SastAttributes }
    | { $case: "scaAttributes"; scaAttributes: ListIssueGroupsResponse_RuleGroup_ScaAttributes }
    | { $case: "secretsAttributes"; secretsAttributes: ListIssueGroupsResponse_RuleGroup_SecretsAttributes }
    | undefined;
}

export interface ListIssueGroupsResponse_RuleGroup_TestCase {
  target: string;
  language?: string | undefined;
}

/** TODO: add any sast-specific fields here */
export interface ListIssueGroupsResponse_RuleGroup_SastAttributes {
}

export interface ListIssueGroupsResponse_RuleGroup_ScaAttributes {
  /** the exploit prediction score associated with the issue's corresponding CVE calculated by https://www.first.org/epss/ (only present if the issue is associated with a CVE) */
  epssScore?: EpssScore | undefined;
}

/** TODO: add any secrets-specific fields here */
export interface ListIssueGroupsResponse_RuleGroup_SecretsAttributes {
}

export interface UpdateFindingParams {
  /** New triage state for the finding. */
  triageState: FindingTriageState;
  /** Optional reason for why the finding was triaged. */
  triageReason?:
    | FindingTriageReason
    | undefined;
  /** Optional note for why the finding was triaged. */
  note?: string | undefined;
}

export interface UpdateFindingsRequest {
  /** Specifies the deployment to which the findings belong. */
  deploymentId: number;
  /** Filters findings to those with the specified type (based on the product which produced these issues). */
  issueType: IssueType;
  /** Filters the findings to match search logic. */
  filter:
    | IssuesFilter
    | undefined;
  /** Params used to update the list of findings. */
  params: UpdateFindingParams | undefined;
}

export interface UpdateFindingsResponse {
  /** Number of issue parents triaged. */
  issueParentCount: number;
  /** Number of issues triaged across parents. */
  issueCount: number;
  /** Number of issues updated successfully. */
  updatedCount: number;
}

export interface IssuesFilter {
  /** Filters issues to those whose ids match any of the specified values. Applies to all issue types. */
  ids: number[];
  /** Filters issues relevant since the specified timestamp. Applies to all issue types. */
  since?:
    | Date
    | undefined;
  /** Filters issues created before the specified timestamp. Applies to all issue types. */
  until?:
    | Date
    | undefined;
  /** Filters issues which belong to a repo with one of the specified names. Applies to all issue types. */
  repositoryNames: string[];
  /** Filters issues which belong to a repo with one of the specified tags. Applies to all issue types. */
  repositoryTags: string[];
  /** Filters issues which were found in a ref with one of the specified names. Applies to all issue types. */
  refs: string[];
  /** Filters issues which were found on the repo and ref associated with the repo ref id. Applies to all issue types. */
  repositoryRefIds: number[];
  /** Filters issues whose autotriage verdict matches one of the specified values. Applies to all issue types. */
  aiVerdicts: Autotriage_Verdict[];
  /** Filters issues whose autotriage tag matches one of the specified values. Applies to all issue types. */
  aiSensitivities: string[];
  /** Filters issues whose status matches one of the specified values. Applies to all issue types. */
  status: IssueStatus[];
  /** Filters issues whose general status (open, ignored, fixed) matches one of the specified values. Applies to all issue types. */
  tab?:
    | IssueTab
    | undefined;
  /** Filter issues which belong to a repo assigned to one of the specified teams. Applies to all issue types. */
  teamNames: string[];
  /** Filters SAST issues whose action mode matches one of the specified values. Only applies when the provided issue_type is SAST. */
  actions: Mode[];
  /** Filters SAST issues whose policy slug matches one of the specified values. Deprecated in favor of `action`. Only applies when the provided issue_type is SAST. */
  policySlugs: string[];
  /** Filters SAST issues whose rule paths match one of the specified values. Only applies when the provided issue_type is SAST. */
  rules: string[];
  /** Filters issues whose rule id matches one of the specified values. Applies to all issue types. */
  ruleIds: number[];
  /** Filters SAST issues whose ruleset name matches one of the specified values. Only applies when the provided issue_type is SAST. */
  rulesets: string[];
  /** Filters SAST issues whose category matches one of the specified values. Only applies when the provided issue_type is SAST. */
  categories: string[];
  /** Filters SAST issues whose confidence rating matches one of the specified values. Only applies when the provided issue_type is SAST. */
  confidences: Confidence[];
  /** Filters SAST issues whose severity matches one of the specified values. Only applies when the provided issue_type is SAST. */
  severities: Severity[];
  /** Filters supply chain issues whose transitivity matches one of the specified values. Only applies when the provided issue_type is SCA. */
  transitivities: Transitivity[];
  /** Filters supply chain issues whose exposure (a.k.a. "reachability") matches one of the specified values. Only applies when the provided issue_type is SCA. */
  exposures: Vuln_ExposureType[];
  /** Filters supply chain issues found in a dependency whose name matches any of the specified values. Only applies when the provided issue_type is SCA. */
  dependencies: string[];
  /** Filters supply chain issues belonging to a CVE whose name matches the specified value. Only applies when the provided issue_type is SCA. */
  cves: string[];
  /** Filters issues whose source matches one of the specified values. Applies to SAST now, and maybe Secrets in the future. */
  source: Source[];
  /** Filters supply chain issues whose reachability matches one of the specified values. Only applies when the provided issue_type is SCA. */
  reachabilities: Reachability[];
  /** Filters issues to those whose issue parent ids match any of the specified values. Applies to all types. */
  issueParentIds: number[];
  /** Filters secrets issues whose validation state matches one of the specified values. Only applies when the provided issue_type is SECRETS. */
  validationStates: ValidationState[];
  /** Filters secrets issues whose secret type matches one of the specified values. Only applies when the provided issue_type is SECRETS. */
  secretTypes: string[];
  /** Filters secrets issues whose status matches one of the specified values. Only applies when the provided issue_type is SECRETS. */
  repoVisibilities: RepositoryVisibility[];
  /** Filters out historical secrets issues if true. Only applies when the provided issue_type is SECRETS. */
  excludeHistorical?:
    | boolean
    | undefined;
  /** Filter down to only issues that are - or are not - found on the default branch of their project. */
  onPrimaryBranch?:
    | boolean
    | undefined;
  /** Filters supply chain issues whose corresponding EPSS scores fall in one of the specified buckets. Only applies when the provided issue_type is SCA. */
  epssProbabilities: EpssProbability[];
  /** Filters issues whose triage reason matches one of the specified values. Applies to all issue types. */
  triageReasons: FindingTriageReason[];
  /** Filters issues by date based on different attributes related to status changes or triaging */
  timeFilter?:
    | TimeFilter
    | undefined;
  /** Filters issues based on if they have a nango_external_ticket associated with them */
  includeTicketed?:
    | boolean
    | undefined;
  /**
   * Filters issues to either all (prod), or only those with review comments (pre-prod). Only applies when the provided issue_type is SAST.
   * TODO: once experiment is successful, prod should mean only issues in actual production (very similar to on_primary_branch)
   * TODO: verify if review_comment is correct for SCA/SECRETS and enable this filter for those issue types too
   */
  backlogType?:
    | IssueBacklogType
    | undefined;
  /** Filters supply chain issues by its kind (malicious dependency, reachable, upgrade-only, legacy). Only applies when the provided issue_type is SCA. */
  scaRuleKind: ScaRuleKind[];
}

export interface Issue {
  /** ID of the finding */
  id: number;
  /** Creation timestamp */
  createdAt:
    | Date
    | undefined;
  /** Branch where the finding was detected */
  ref?:
    | string
    | undefined;
  /** Semi-unique hash of a check consisting of: Line Numbers, File Path, Rule ID, Index of finding in file, and Matched code */
  syntacticId: string;
  /** Semi-unique hash of a check consisting of: File Path, Rule ID, Index of finding in file, and Rule Formula with metavariable bindings substituted in */
  matchBasedId: string;
  /** The ID of the associated semgrep rule */
  ruleId?:
    | number
    | undefined;
  /** TODO: remove state, use aggregate_state everywhere */
  status: IssueStatus;
  /** The repository in which this issue was found */
  repository:
    | Issue_Repository
    | undefined;
  /** The first scan in which this issue was found */
  firstSeenScan:
    | Issue_Scan
    | undefined;
  /** The issue's current triage state. */
  triageState: FindingTriageState;
  /** The reason the issue was triaged. */
  triageReason?:
    | FindingTriageReason
    | undefined;
  /** The timestamp from which this issue was relevant */
  relevantSince:
    | Date
    | undefined;
  /** The overall state of the issue, accounting for status (controlled by scans), triaging, external events like PR closes, etc. TODO: use the `AggregateState` enum instead of raw strings. */
  aggregateState: AggregateIssueState;
  /** Comment left when triaged */
  note?:
    | string
    | undefined;
  /** The external ticket reference */
  externalTicket?:
    | ExternalTicket
    | undefined;
  /** The key used to group supply chain vulns (deprecated) */
  vulnGroupKey?:
    | string
    | undefined;
  /** True if this issue is a blocking issue. */
  isBlocking?:
    | boolean
    | undefined;
  /** The autotriage info related to this issue. */
  autotriage?:
    | Autotriage
    | undefined;
  /** The autotriage tags associated with this issue */
  aiTags?:
    | SensitivityTags
    | undefined;
  /** The URL to the specific line of code causing this issue */
  lineOfCodeUrl?:
    | string
    | undefined;
  /** The code snippet causing this issue. Only available when fetching a single issue at a time via the GetIssue RPC. */
  codeSnippet?:
    | string
    | undefined;
  /** A call trace order of code locations leading to the site of the issue. Only available when fetching a single issue at a time via the GetIssue RPC. */
  dataflowTrace?:
    | Issue_DataflowTrace
    | undefined;
  /** The URL of the specific commit which introduced this issue. Only available when fetching a single issue at a time via the GetIssue RPC. */
  commitUrl?:
    | string
    | undefined;
  /** A chronologically-ordered list of events related to this issue (status change, ai tags, triage events). Only available when fetching a single issue at a time via the GetIssue RPC. */
  activityHistory: Issue_ActivityHistoryEvent[];
  /** A list of related issues (with the same match based ID). Only available when fetching a single issue at a time via the GetIssue RPC. */
  relatedIssues: Issue_RelatedIssue[];
  /** AI remediation info for this issue (guidance and autofix). Only available when fetching a single issue at a time via the GetIssue RPC. */
  remediation?:
    | Remediation
    | undefined;
  /** The last scan in which this issue was found */
  lastSeenScan:
    | Issue_Scan
    | undefined;
  /** The path to the file in which this issue was found. */
  filePath?:
    | string
    | undefined;
  /** The first line number in which this issue was found. */
  line?:
    | number
    | undefined;
  /** The end line number in which this issue was found (if the offending code spans several lines). */
  endLine?:
    | number
    | undefined;
  /** The start column number within the line in which this issue was found. */
  column?:
    | number
    | undefined;
  /** The end column number within the last line in which this issue was found (if applicable). */
  endColumn?:
    | number
    | undefined;
  /** The severity ("seriousness") of the issue. */
  severity: Severity;
  /** An optional message that helps describe the issue in more detail. */
  message?:
    | string
    | undefined;
  /** The full path of the rule used to generate this issue. */
  rulePath: string;
  /** The confidence in false-positive rate of an issue. */
  confidence?:
    | Confidence
    | undefined;
  /** The url of the rule used to generate this issue. */
  ruleUrl: string;
  /** A list of external references (URLs) which help describe or provide context for the rule used to generate this issue. */
  ruleReferences: string[];
  /** The origin of the rule (pro rules, semgrep community, or a custom rule) */
  ruleOrigin?:
    | RuleOrigin
    | undefined;
  /** The hash of the associated rule. */
  ruleHashId?:
    | string
    | undefined;
  /** The ruleset to which this issue's rule belongs (if applicable). */
  ruleset?:
    | string
    | undefined;
  /** A slugified version of the associated rule's policy. */
  policySlug?:
    | string
    | undefined;
  /** The issue's category (or "other" if none). */
  category: string;
  /** A list of rules which supersede this issue's rule. */
  ruleSupersededBy: SupersededRule[];
  /** The product which owns this issue, e.g. SAST (code), SCA (supply chain), etc. */
  issueType: IssueType;
  /** Parent ID of the finding. Present for all types. */
  issueParentId: number;
  /** The failed attempts at ticket creation */
  ticketAttempts: TicketAttempt[];
  productAttributes?:
    | { $case: "sastAttributes"; sastAttributes: Issue_SastAttributes }
    | { $case: "scaAttributes"; scaAttributes: Issue_ScaAttributes }
    | { $case: "secretsAttributes"; secretsAttributes: Issue_SecretsAttributes }
    | undefined;
  /** The issue's subcategories */
  subcategories: string[];
  /** The URL of the specific commit which introduced this issue. Only available when fetching a single issue at a time via the GetIssue RPC. */
  refUrl?: string | undefined;
  codeowners: Codeowner[];
}

export interface Issue_DataflowTrace {
  taintSource: Issue_DataflowTrace_Location[];
  intermediateVars: Issue_DataflowTrace_Location[];
  taintSink: Issue_DataflowTrace_Location[];
}

export interface Issue_DataflowTrace_Position {
  line: number;
  col: number;
  offset: number;
}

export interface Issue_DataflowTrace_Location {
  path: string;
  start: Issue_DataflowTrace_Position | undefined;
  end: Issue_DataflowTrace_Position | undefined;
  locationUrl?: string | undefined;
}

export interface Issue_ActivityHistoryEvent {
  date: Date | undefined;
  title: string;
  triageReason?: string | undefined;
  note: string;
  actor?: string | undefined;
  historyType: Issue_ActivityHistoryEvent_HistoryType;
}

export enum Issue_ActivityHistoryEvent_HistoryType {
  UNSPECIFIED = "HISTORY_TYPE_UNSPECIFIED",
  STATUS = "HISTORY_TYPE_STATUS",
  TRIAGE = "HISTORY_TYPE_TRIAGE",
  AUTOTRIAGE = "HISTORY_TYPE_AUTOTRIAGE",
  AUTOTRIAGE_FEEDBACK = "HISTORY_TYPE_AUTOTRIAGE_FEEDBACK",
  AI_TAG = "HISTORY_TYPE_AI_TAG",
  ANALYSIS_STARTED = "HISTORY_TYPE_ANALYSIS_STARTED",
}

export function issue_ActivityHistoryEvent_HistoryTypeFromJSON(object: any): Issue_ActivityHistoryEvent_HistoryType {
  switch (object) {
    case 0:
    case "HISTORY_TYPE_UNSPECIFIED":
      return Issue_ActivityHistoryEvent_HistoryType.UNSPECIFIED;
    case 1:
    case "HISTORY_TYPE_STATUS":
      return Issue_ActivityHistoryEvent_HistoryType.STATUS;
    case 2:
    case "HISTORY_TYPE_TRIAGE":
      return Issue_ActivityHistoryEvent_HistoryType.TRIAGE;
    case 3:
    case "HISTORY_TYPE_AUTOTRIAGE":
      return Issue_ActivityHistoryEvent_HistoryType.AUTOTRIAGE;
    case 6:
    case "HISTORY_TYPE_AUTOTRIAGE_FEEDBACK":
      return Issue_ActivityHistoryEvent_HistoryType.AUTOTRIAGE_FEEDBACK;
    case 4:
    case "HISTORY_TYPE_AI_TAG":
      return Issue_ActivityHistoryEvent_HistoryType.AI_TAG;
    case 5:
    case "HISTORY_TYPE_ANALYSIS_STARTED":
      return Issue_ActivityHistoryEvent_HistoryType.ANALYSIS_STARTED;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum Issue_ActivityHistoryEvent_HistoryType",
      );
  }
}

export function issue_ActivityHistoryEvent_HistoryTypeToJSON(object: Issue_ActivityHistoryEvent_HistoryType): string {
  switch (object) {
    case Issue_ActivityHistoryEvent_HistoryType.UNSPECIFIED:
      return "HISTORY_TYPE_UNSPECIFIED";
    case Issue_ActivityHistoryEvent_HistoryType.STATUS:
      return "HISTORY_TYPE_STATUS";
    case Issue_ActivityHistoryEvent_HistoryType.TRIAGE:
      return "HISTORY_TYPE_TRIAGE";
    case Issue_ActivityHistoryEvent_HistoryType.AUTOTRIAGE:
      return "HISTORY_TYPE_AUTOTRIAGE";
    case Issue_ActivityHistoryEvent_HistoryType.AUTOTRIAGE_FEEDBACK:
      return "HISTORY_TYPE_AUTOTRIAGE_FEEDBACK";
    case Issue_ActivityHistoryEvent_HistoryType.AI_TAG:
      return "HISTORY_TYPE_AI_TAG";
    case Issue_ActivityHistoryEvent_HistoryType.ANALYSIS_STARTED:
      return "HISTORY_TYPE_ANALYSIS_STARTED";
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum Issue_ActivityHistoryEvent_HistoryType",
      );
  }
}

export function issue_ActivityHistoryEvent_HistoryTypeToNumber(object: Issue_ActivityHistoryEvent_HistoryType): number {
  switch (object) {
    case Issue_ActivityHistoryEvent_HistoryType.UNSPECIFIED:
      return 0;
    case Issue_ActivityHistoryEvent_HistoryType.STATUS:
      return 1;
    case Issue_ActivityHistoryEvent_HistoryType.TRIAGE:
      return 2;
    case Issue_ActivityHistoryEvent_HistoryType.AUTOTRIAGE:
      return 3;
    case Issue_ActivityHistoryEvent_HistoryType.AUTOTRIAGE_FEEDBACK:
      return 6;
    case Issue_ActivityHistoryEvent_HistoryType.AI_TAG:
      return 4;
    case Issue_ActivityHistoryEvent_HistoryType.ANALYSIS_STARTED:
      return 5;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum Issue_ActivityHistoryEvent_HistoryType",
      );
  }
}

export interface Issue_RelatedIssue {
  id: number;
  ref: string;
  pullRequestId: string;
  aggregateState: AggregateIssueState;
}

export interface Issue_PrimaryRefItem {
  id: number;
  ref: string;
}

export interface Issue_Repository {
  /** Repository name */
  name: string;
  id: number;
  type: ScmType;
  primaryRef?: Issue_PrimaryRefItem | undefined;
}

export interface Issue_Scan {
  id: number;
  meta: { [key: string]: any } | undefined;
}

/** Groups the fields of Issue that are specific to the SAST product */
export interface Issue_SastAttributes {
}

/** Groups the fields of Issue that are specific to the SCA product */
export interface Issue_ScaAttributes {
  /** Severity of the SCA issue (TODO: reconcile this with the Issue.severity field, which *should* agree but doesn't necessarily agree yet) */
  severity: Severity;
  /** ID of the CVE or GHSA from upon which this SCA issue's rule is based */
  vulnDatabaseIdentifier: string;
  /** SCA issue's reachability (whether the issue is reachable from the client's code and, if so, how) */
  reachability: Reachability;
  /** For SCA issues which are conditionally reachable, this will store a human-readable description of the condition. */
  reachableCondition: string;
  /** Stands for "common weakness enumeration": a taxonomy for identifying the common sources of software flaws (e.g., buffer overflows, failure to check input data) */
  cwes: string[];
  /** The dependency in which this SCA issue was found. */
  foundDependency:
    | Issue_ScaAttributes_FoundDependency
    | undefined;
  /** A list of Semgrep's dependency upgrade recommendations which would addressing the SCA issue's underlying rule. */
  fixRecommendations: Issue_ScaAttributes_FixRecommendation[];
  /** One of the `fix_recommendations` that we have identified as best */
  bestFix?:
    | Issue_ScaAttributes_FixRecommendation
    | undefined;
  /** the exploit prediction score associated with the issue's corresponding CVE calculated by https://www.first.org/epss/ (only present if the issue is associated with a CVE) */
  epssScore?:
    | EpssScore
    | undefined;
  /** the date the associated rule was published */
  rulePublishDate?: Date | undefined;
}

export interface Issue_ScaAttributes_FoundDependency {
  /** Name of the dependency's package */
  package: string;
  /** The Version of the dependency */
  version: string;
  /** The transitivity (whether or not a dependency is directly imported into a project) of the dependency */
  transitivity: Transitivity;
  /** The URL (on the SCM) to the line in the lockfile at which the dependency is defined (might not exist if the dependency was surfaced in a non SCM context) */
  lockfileLineUrl?:
    | string
    | undefined;
  /** The relative path (from the project root) to the lockfile in which the dependency is defined */
  filePath: string;
  /** Package ecosystem (enum defined in sca/vi/sca.proto) to which the dependency belongs */
  ecosystem: Ecosystem;
  /** The relative path (from the project root) to the manifest file which defines the subproject which the dependency is part of */
  manifestFilePath?: string | undefined;
}

export interface Issue_ScaAttributes_FixRecommendation {
  package: string;
  version: string;
}

/** Groups the fields of Issue that are specific to the Secrets product */
export interface Issue_SecretsAttributes {
  validationState: ValidationState;
  secretType: string;
  historicalInfo?: HistoricalInfo | undefined;
}

export interface Issue_TriageAttributes {
  triagedBy: string;
  triagedAt: Date | undefined;
  source: TriageSource;
}

function createBaseGetIssueRequest(): GetIssueRequest {
  return { deploymentId: 0, issueId: 0 };
}

export const GetIssueRequest = {
  encode(message: GetIssueRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.issueId !== 0) {
      writer.uint32(16).uint64(message.issueId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetIssueRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetIssueRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.issueId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetIssueRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      issueId: isSet(object.issueId) ? globalThis.Number(object.issueId) : 0,
    };
  },

  toJSON(message: GetIssueRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.issueId !== 0) {
      obj.issueId = Math.round(message.issueId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetIssueRequest>, I>>(base?: I): GetIssueRequest {
    return GetIssueRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetIssueRequest>, I>>(object: I): GetIssueRequest {
    const message = createBaseGetIssueRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.issueId = object.issueId ?? 0;
    return message;
  },
};

function createBaseListIssuesRequest(): ListIssuesRequest {
  return {
    deploymentId: 0,
    issueType: IssueType.UNSPECIFIED,
    sortBy: undefined,
    sortDirection: undefined,
    limit: undefined,
    cursor: undefined,
    filter: undefined,
  };
}

export const ListIssuesRequest = {
  encode(message: ListIssuesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.issueType !== IssueType.UNSPECIFIED) {
      writer.uint32(16).int32(issueTypeToNumber(message.issueType));
    }
    if (message.sortBy !== undefined) {
      writer.uint32(24).int32(listIssuesRequest_SortByToNumber(message.sortBy));
    }
    if (message.sortDirection !== undefined) {
      writer.uint32(32).int32(listIssuesRequest_SortDirectionToNumber(message.sortDirection));
    }
    if (message.limit !== undefined) {
      writer.uint32(40).uint32(message.limit);
    }
    if (message.cursor !== undefined) {
      writer.uint32(50).string(message.cursor);
    }
    if (message.filter !== undefined) {
      IssuesFilter.encode(message.filter, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssuesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssuesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.issueType = issueTypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.sortBy = listIssuesRequest_SortByFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sortDirection = listIssuesRequest_SortDirectionFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.limit = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.cursor = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.filter = IssuesFilter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssuesRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      issueType: isSet(object.issueType) ? issueTypeFromJSON(object.issueType) : IssueType.UNSPECIFIED,
      sortBy: isSet(object.sortBy) ? listIssuesRequest_SortByFromJSON(object.sortBy) : undefined,
      sortDirection: isSet(object.sortDirection)
        ? listIssuesRequest_SortDirectionFromJSON(object.sortDirection)
        : undefined,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : undefined,
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
      filter: isSet(object.filter) ? IssuesFilter.fromJSON(object.filter) : undefined,
    };
  },

  toJSON(message: ListIssuesRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.issueType !== IssueType.UNSPECIFIED) {
      obj.issueType = issueTypeToJSON(message.issueType);
    }
    if (message.sortBy !== undefined) {
      obj.sortBy = listIssuesRequest_SortByToJSON(message.sortBy);
    }
    if (message.sortDirection !== undefined) {
      obj.sortDirection = listIssuesRequest_SortDirectionToJSON(message.sortDirection);
    }
    if (message.limit !== undefined) {
      obj.limit = Math.round(message.limit);
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    if (message.filter !== undefined) {
      obj.filter = IssuesFilter.toJSON(message.filter);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssuesRequest>, I>>(base?: I): ListIssuesRequest {
    return ListIssuesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssuesRequest>, I>>(object: I): ListIssuesRequest {
    const message = createBaseListIssuesRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.issueType = object.issueType ?? IssueType.UNSPECIFIED;
    message.sortBy = object.sortBy ?? undefined;
    message.sortDirection = object.sortDirection ?? undefined;
    message.limit = object.limit ?? undefined;
    message.cursor = object.cursor ?? undefined;
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? IssuesFilter.fromPartial(object.filter)
      : undefined;
    return message;
  },
};

function createBaseListIssuesResponse(): ListIssuesResponse {
  return { issues: [], cursor: undefined };
}

export const ListIssuesResponse = {
  encode(message: ListIssuesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.issues) {
      ListIssuesResponse_IssueSummary.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.cursor !== undefined) {
      writer.uint32(18).string(message.cursor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssuesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssuesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.issues.push(ListIssuesResponse_IssueSummary.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cursor = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssuesResponse {
    return {
      issues: globalThis.Array.isArray(object?.issues)
        ? object.issues.map((e: any) => ListIssuesResponse_IssueSummary.fromJSON(e))
        : [],
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
    };
  },

  toJSON(message: ListIssuesResponse): unknown {
    const obj: any = {};
    if (message.issues?.length) {
      obj.issues = message.issues.map((e) => ListIssuesResponse_IssueSummary.toJSON(e));
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssuesResponse>, I>>(base?: I): ListIssuesResponse {
    return ListIssuesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssuesResponse>, I>>(object: I): ListIssuesResponse {
    const message = createBaseListIssuesResponse();
    message.issues = object.issues?.map((e) => ListIssuesResponse_IssueSummary.fromPartial(e)) || [];
    message.cursor = object.cursor ?? undefined;
    return message;
  },
};

function createBaseListIssuesResponse_ReviewComment(): ListIssuesResponse_ReviewComment {
  return { isInline: false, threadId: "" };
}

export const ListIssuesResponse_ReviewComment = {
  encode(message: ListIssuesResponse_ReviewComment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isInline !== false) {
      writer.uint32(8).bool(message.isInline);
    }
    if (message.threadId !== "") {
      writer.uint32(18).string(message.threadId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssuesResponse_ReviewComment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssuesResponse_ReviewComment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isInline = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.threadId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssuesResponse_ReviewComment {
    return {
      isInline: isSet(object.isInline) ? globalThis.Boolean(object.isInline) : false,
      threadId: isSet(object.threadId) ? globalThis.String(object.threadId) : "",
    };
  },

  toJSON(message: ListIssuesResponse_ReviewComment): unknown {
    const obj: any = {};
    if (message.isInline !== false) {
      obj.isInline = message.isInline;
    }
    if (message.threadId !== "") {
      obj.threadId = message.threadId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssuesResponse_ReviewComment>, I>>(
    base?: I,
  ): ListIssuesResponse_ReviewComment {
    return ListIssuesResponse_ReviewComment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssuesResponse_ReviewComment>, I>>(
    object: I,
  ): ListIssuesResponse_ReviewComment {
    const message = createBaseListIssuesResponse_ReviewComment();
    message.isInline = object.isInline ?? false;
    message.threadId = object.threadId ?? "";
    return message;
  },
};

function createBaseListIssuesResponse_IssueSummary(): ListIssuesResponse_IssueSummary {
  return {
    issue: undefined,
    reviewCount: 0,
    triageCount: 0,
    allRefs: [],
    aiSilencedCount: 0,
    allReviewThreadIds: [],
    allReviewComments: [],
  };
}

export const ListIssuesResponse_IssueSummary = {
  encode(message: ListIssuesResponse_IssueSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.issue !== undefined) {
      Issue.encode(message.issue, writer.uint32(10).fork()).ldelim();
    }
    if (message.reviewCount !== 0) {
      writer.uint32(16).uint64(message.reviewCount);
    }
    if (message.triageCount !== 0) {
      writer.uint32(24).uint64(message.triageCount);
    }
    for (const v of message.allRefs) {
      writer.uint32(34).string(v!);
    }
    if (message.aiSilencedCount !== 0) {
      writer.uint32(40).uint64(message.aiSilencedCount);
    }
    for (const v of message.allReviewThreadIds) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.allReviewComments) {
      ListIssuesResponse_ReviewComment.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssuesResponse_IssueSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssuesResponse_IssueSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.issue = Issue.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.reviewCount = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.triageCount = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.allRefs.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.aiSilencedCount = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.allReviewThreadIds.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.allReviewComments.push(ListIssuesResponse_ReviewComment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssuesResponse_IssueSummary {
    return {
      issue: isSet(object.issue) ? Issue.fromJSON(object.issue) : undefined,
      reviewCount: isSet(object.reviewCount) ? globalThis.Number(object.reviewCount) : 0,
      triageCount: isSet(object.triageCount) ? globalThis.Number(object.triageCount) : 0,
      allRefs: globalThis.Array.isArray(object?.allRefs) ? object.allRefs.map((e: any) => globalThis.String(e)) : [],
      aiSilencedCount: isSet(object.aiSilencedCount) ? globalThis.Number(object.aiSilencedCount) : 0,
      allReviewThreadIds: globalThis.Array.isArray(object?.allReviewThreadIds)
        ? object.allReviewThreadIds.map((e: any) => globalThis.String(e))
        : [],
      allReviewComments: globalThis.Array.isArray(object?.allReviewComments)
        ? object.allReviewComments.map((e: any) => ListIssuesResponse_ReviewComment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListIssuesResponse_IssueSummary): unknown {
    const obj: any = {};
    if (message.issue !== undefined) {
      obj.issue = Issue.toJSON(message.issue);
    }
    if (message.reviewCount !== 0) {
      obj.reviewCount = Math.round(message.reviewCount);
    }
    if (message.triageCount !== 0) {
      obj.triageCount = Math.round(message.triageCount);
    }
    if (message.allRefs?.length) {
      obj.allRefs = message.allRefs;
    }
    if (message.aiSilencedCount !== 0) {
      obj.aiSilencedCount = Math.round(message.aiSilencedCount);
    }
    if (message.allReviewThreadIds?.length) {
      obj.allReviewThreadIds = message.allReviewThreadIds;
    }
    if (message.allReviewComments?.length) {
      obj.allReviewComments = message.allReviewComments.map((e) => ListIssuesResponse_ReviewComment.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssuesResponse_IssueSummary>, I>>(base?: I): ListIssuesResponse_IssueSummary {
    return ListIssuesResponse_IssueSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssuesResponse_IssueSummary>, I>>(
    object: I,
  ): ListIssuesResponse_IssueSummary {
    const message = createBaseListIssuesResponse_IssueSummary();
    message.issue = (object.issue !== undefined && object.issue !== null) ? Issue.fromPartial(object.issue) : undefined;
    message.reviewCount = object.reviewCount ?? 0;
    message.triageCount = object.triageCount ?? 0;
    message.allRefs = object.allRefs?.map((e) => e) || [];
    message.aiSilencedCount = object.aiSilencedCount ?? 0;
    message.allReviewThreadIds = object.allReviewThreadIds?.map((e) => e) || [];
    message.allReviewComments = object.allReviewComments?.map((e) => ListIssuesResponse_ReviewComment.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseGetIssueCountsRequest(): GetIssueCountsRequest {
  return { deploymentId: 0, issueType: IssueType.UNSPECIFIED, filter: undefined };
}

export const GetIssueCountsRequest = {
  encode(message: GetIssueCountsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.issueType !== IssueType.UNSPECIFIED) {
      writer.uint32(16).int32(issueTypeToNumber(message.issueType));
    }
    if (message.filter !== undefined) {
      IssuesFilter.encode(message.filter, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetIssueCountsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetIssueCountsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.issueType = issueTypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filter = IssuesFilter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetIssueCountsRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      issueType: isSet(object.issueType) ? issueTypeFromJSON(object.issueType) : IssueType.UNSPECIFIED,
      filter: isSet(object.filter) ? IssuesFilter.fromJSON(object.filter) : undefined,
    };
  },

  toJSON(message: GetIssueCountsRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.issueType !== IssueType.UNSPECIFIED) {
      obj.issueType = issueTypeToJSON(message.issueType);
    }
    if (message.filter !== undefined) {
      obj.filter = IssuesFilter.toJSON(message.filter);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetIssueCountsRequest>, I>>(base?: I): GetIssueCountsRequest {
    return GetIssueCountsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetIssueCountsRequest>, I>>(object: I): GetIssueCountsRequest {
    const message = createBaseGetIssueCountsRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.issueType = object.issueType ?? IssueType.UNSPECIFIED;
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? IssuesFilter.fromPartial(object.filter)
      : undefined;
    return message;
  },
};

function createBaseGetIssueCountsResponse(): GetIssueCountsResponse {
  return {
    open: 0,
    ignored: 0,
    ignoredFalsePositive: 0,
    ignoredAcceptableRisk: 0,
    ignoredNoTime: 0,
    ignoredNoTriageReason: 0,
    closed: 0,
    reviewing: 0,
    fixing: 0,
  };
}

export const GetIssueCountsResponse = {
  encode(message: GetIssueCountsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.open !== 0) {
      writer.uint32(8).uint64(message.open);
    }
    if (message.ignored !== 0) {
      writer.uint32(16).uint64(message.ignored);
    }
    if (message.ignoredFalsePositive !== 0) {
      writer.uint32(24).uint64(message.ignoredFalsePositive);
    }
    if (message.ignoredAcceptableRisk !== 0) {
      writer.uint32(32).uint64(message.ignoredAcceptableRisk);
    }
    if (message.ignoredNoTime !== 0) {
      writer.uint32(40).uint64(message.ignoredNoTime);
    }
    if (message.ignoredNoTriageReason !== 0) {
      writer.uint32(48).uint64(message.ignoredNoTriageReason);
    }
    if (message.closed !== 0) {
      writer.uint32(56).uint64(message.closed);
    }
    if (message.reviewing !== 0) {
      writer.uint32(64).uint64(message.reviewing);
    }
    if (message.fixing !== 0) {
      writer.uint32(72).uint64(message.fixing);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetIssueCountsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetIssueCountsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.open = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.ignored = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.ignoredFalsePositive = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.ignoredAcceptableRisk = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.ignoredNoTime = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.ignoredNoTriageReason = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.closed = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.reviewing = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.fixing = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetIssueCountsResponse {
    return {
      open: isSet(object.open) ? globalThis.Number(object.open) : 0,
      ignored: isSet(object.ignored) ? globalThis.Number(object.ignored) : 0,
      ignoredFalsePositive: isSet(object.ignoredFalsePositive) ? globalThis.Number(object.ignoredFalsePositive) : 0,
      ignoredAcceptableRisk: isSet(object.ignoredAcceptableRisk) ? globalThis.Number(object.ignoredAcceptableRisk) : 0,
      ignoredNoTime: isSet(object.ignoredNoTime) ? globalThis.Number(object.ignoredNoTime) : 0,
      ignoredNoTriageReason: isSet(object.ignoredNoTriageReason) ? globalThis.Number(object.ignoredNoTriageReason) : 0,
      closed: isSet(object.closed) ? globalThis.Number(object.closed) : 0,
      reviewing: isSet(object.reviewing) ? globalThis.Number(object.reviewing) : 0,
      fixing: isSet(object.fixing) ? globalThis.Number(object.fixing) : 0,
    };
  },

  toJSON(message: GetIssueCountsResponse): unknown {
    const obj: any = {};
    if (message.open !== 0) {
      obj.open = Math.round(message.open);
    }
    if (message.ignored !== 0) {
      obj.ignored = Math.round(message.ignored);
    }
    if (message.ignoredFalsePositive !== 0) {
      obj.ignoredFalsePositive = Math.round(message.ignoredFalsePositive);
    }
    if (message.ignoredAcceptableRisk !== 0) {
      obj.ignoredAcceptableRisk = Math.round(message.ignoredAcceptableRisk);
    }
    if (message.ignoredNoTime !== 0) {
      obj.ignoredNoTime = Math.round(message.ignoredNoTime);
    }
    if (message.ignoredNoTriageReason !== 0) {
      obj.ignoredNoTriageReason = Math.round(message.ignoredNoTriageReason);
    }
    if (message.closed !== 0) {
      obj.closed = Math.round(message.closed);
    }
    if (message.reviewing !== 0) {
      obj.reviewing = Math.round(message.reviewing);
    }
    if (message.fixing !== 0) {
      obj.fixing = Math.round(message.fixing);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetIssueCountsResponse>, I>>(base?: I): GetIssueCountsResponse {
    return GetIssueCountsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetIssueCountsResponse>, I>>(object: I): GetIssueCountsResponse {
    const message = createBaseGetIssueCountsResponse();
    message.open = object.open ?? 0;
    message.ignored = object.ignored ?? 0;
    message.ignoredFalsePositive = object.ignoredFalsePositive ?? 0;
    message.ignoredAcceptableRisk = object.ignoredAcceptableRisk ?? 0;
    message.ignoredNoTime = object.ignoredNoTime ?? 0;
    message.ignoredNoTriageReason = object.ignoredNoTriageReason ?? 0;
    message.closed = object.closed ?? 0;
    message.reviewing = object.reviewing ?? 0;
    message.fixing = object.fixing ?? 0;
    return message;
  },
};

function createBaseGetIssueFiltersRequest(): GetIssueFiltersRequest {
  return { deploymentId: 0, issueType: IssueType.UNSPECIFIED, filter: undefined };
}

export const GetIssueFiltersRequest = {
  encode(message: GetIssueFiltersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.issueType !== IssueType.UNSPECIFIED) {
      writer.uint32(16).int32(issueTypeToNumber(message.issueType));
    }
    if (message.filter !== undefined) {
      IssuesFilter.encode(message.filter, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetIssueFiltersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetIssueFiltersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.issueType = issueTypeFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.filter = IssuesFilter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetIssueFiltersRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      issueType: isSet(object.issueType) ? issueTypeFromJSON(object.issueType) : IssueType.UNSPECIFIED,
      filter: isSet(object.filter) ? IssuesFilter.fromJSON(object.filter) : undefined,
    };
  },

  toJSON(message: GetIssueFiltersRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.issueType !== IssueType.UNSPECIFIED) {
      obj.issueType = issueTypeToJSON(message.issueType);
    }
    if (message.filter !== undefined) {
      obj.filter = IssuesFilter.toJSON(message.filter);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetIssueFiltersRequest>, I>>(base?: I): GetIssueFiltersRequest {
    return GetIssueFiltersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetIssueFiltersRequest>, I>>(object: I): GetIssueFiltersRequest {
    const message = createBaseGetIssueFiltersRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.issueType = object.issueType ?? IssueType.UNSPECIFIED;
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? IssuesFilter.fromPartial(object.filter)
      : undefined;
    return message;
  },
};

function createBaseRuleFilterItem(): RuleFilterItem {
  return { id: 0, name: "", scaCve: undefined, scaGhsa: undefined };
}

export const RuleFilterItem = {
  encode(message: RuleFilterItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.scaCve !== undefined) {
      writer.uint32(82).string(message.scaCve);
    }
    if (message.scaGhsa !== undefined) {
      writer.uint32(90).string(message.scaGhsa);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleFilterItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleFilterItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.scaCve = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.scaGhsa = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RuleFilterItem {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      scaCve: isSet(object.scaCve) ? globalThis.String(object.scaCve) : undefined,
      scaGhsa: isSet(object.scaGhsa) ? globalThis.String(object.scaGhsa) : undefined,
    };
  },

  toJSON(message: RuleFilterItem): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.scaCve !== undefined) {
      obj.scaCve = message.scaCve;
    }
    if (message.scaGhsa !== undefined) {
      obj.scaGhsa = message.scaGhsa;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleFilterItem>, I>>(base?: I): RuleFilterItem {
    return RuleFilterItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RuleFilterItem>, I>>(object: I): RuleFilterItem {
    const message = createBaseRuleFilterItem();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.scaCve = object.scaCve ?? undefined;
    message.scaGhsa = object.scaGhsa ?? undefined;
    return message;
  },
};

function createBaseGetIssueFiltersResponse(): GetIssueFiltersResponse {
  return {
    repoNames: [],
    repoTags: [],
    refs: [],
    repoRefGroups: [],
    rulesetNames: [],
    ruleNames: [],
    categories: [],
    teamNames: [],
    secretTypes: [],
    rules: [],
    repos: [],
    tags: [],
  };
}

export const GetIssueFiltersResponse = {
  encode(message: GetIssueFiltersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.repoNames) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.repoTags) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.refs) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.repoRefGroups) {
      GetIssueFiltersResponse_RepositoryRefFilterItemGroup.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.rulesetNames) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.ruleNames) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.categories) {
      writer.uint32(58).string(v!);
    }
    for (const v of message.teamNames) {
      writer.uint32(66).string(v!);
    }
    for (const v of message.secretTypes) {
      writer.uint32(74).string(v!);
    }
    for (const v of message.rules) {
      RuleFilterItem.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.repos) {
      GetIssueFiltersResponse_AnnotatedRepo.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.tags) {
      GetIssueFiltersResponse_AnnotatedRepoTag.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetIssueFiltersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetIssueFiltersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.repoNames.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.repoTags.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.refs.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.repoRefGroups.push(
            GetIssueFiltersResponse_RepositoryRefFilterItemGroup.decode(reader, reader.uint32()),
          );
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.rulesetNames.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ruleNames.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.categories.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.teamNames.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.secretTypes.push(reader.string());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.rules.push(RuleFilterItem.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.repos.push(GetIssueFiltersResponse_AnnotatedRepo.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.tags.push(GetIssueFiltersResponse_AnnotatedRepoTag.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetIssueFiltersResponse {
    return {
      repoNames: globalThis.Array.isArray(object?.repoNames)
        ? object.repoNames.map((e: any) => globalThis.String(e))
        : [],
      repoTags: globalThis.Array.isArray(object?.repoTags) ? object.repoTags.map((e: any) => globalThis.String(e)) : [],
      refs: globalThis.Array.isArray(object?.refs) ? object.refs.map((e: any) => globalThis.String(e)) : [],
      repoRefGroups: globalThis.Array.isArray(object?.repoRefGroups)
        ? object.repoRefGroups.map((e: any) => GetIssueFiltersResponse_RepositoryRefFilterItemGroup.fromJSON(e))
        : [],
      rulesetNames: globalThis.Array.isArray(object?.rulesetNames)
        ? object.rulesetNames.map((e: any) => globalThis.String(e))
        : [],
      ruleNames: globalThis.Array.isArray(object?.ruleNames)
        ? object.ruleNames.map((e: any) => globalThis.String(e))
        : [],
      categories: globalThis.Array.isArray(object?.categories)
        ? object.categories.map((e: any) => globalThis.String(e))
        : [],
      teamNames: globalThis.Array.isArray(object?.teamNames)
        ? object.teamNames.map((e: any) => globalThis.String(e))
        : [],
      secretTypes: globalThis.Array.isArray(object?.secretTypes)
        ? object.secretTypes.map((e: any) => globalThis.String(e))
        : [],
      rules: globalThis.Array.isArray(object?.rules)
        ? object.rules.map((e: any) => RuleFilterItem.fromJSON(e))
        : [],
      repos: globalThis.Array.isArray(object?.repos)
        ? object.repos.map((e: any) => GetIssueFiltersResponse_AnnotatedRepo.fromJSON(e))
        : [],
      tags: globalThis.Array.isArray(object?.tags)
        ? object.tags.map((e: any) => GetIssueFiltersResponse_AnnotatedRepoTag.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetIssueFiltersResponse): unknown {
    const obj: any = {};
    if (message.repoNames?.length) {
      obj.repoNames = message.repoNames;
    }
    if (message.repoTags?.length) {
      obj.repoTags = message.repoTags;
    }
    if (message.refs?.length) {
      obj.refs = message.refs;
    }
    if (message.repoRefGroups?.length) {
      obj.repoRefGroups = message.repoRefGroups.map((e) =>
        GetIssueFiltersResponse_RepositoryRefFilterItemGroup.toJSON(e)
      );
    }
    if (message.rulesetNames?.length) {
      obj.rulesetNames = message.rulesetNames;
    }
    if (message.ruleNames?.length) {
      obj.ruleNames = message.ruleNames;
    }
    if (message.categories?.length) {
      obj.categories = message.categories;
    }
    if (message.teamNames?.length) {
      obj.teamNames = message.teamNames;
    }
    if (message.secretTypes?.length) {
      obj.secretTypes = message.secretTypes;
    }
    if (message.rules?.length) {
      obj.rules = message.rules.map((e) => RuleFilterItem.toJSON(e));
    }
    if (message.repos?.length) {
      obj.repos = message.repos.map((e) => GetIssueFiltersResponse_AnnotatedRepo.toJSON(e));
    }
    if (message.tags?.length) {
      obj.tags = message.tags.map((e) => GetIssueFiltersResponse_AnnotatedRepoTag.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetIssueFiltersResponse>, I>>(base?: I): GetIssueFiltersResponse {
    return GetIssueFiltersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetIssueFiltersResponse>, I>>(object: I): GetIssueFiltersResponse {
    const message = createBaseGetIssueFiltersResponse();
    message.repoNames = object.repoNames?.map((e) => e) || [];
    message.repoTags = object.repoTags?.map((e) => e) || [];
    message.refs = object.refs?.map((e) => e) || [];
    message.repoRefGroups =
      object.repoRefGroups?.map((e) => GetIssueFiltersResponse_RepositoryRefFilterItemGroup.fromPartial(e)) || [];
    message.rulesetNames = object.rulesetNames?.map((e) => e) || [];
    message.ruleNames = object.ruleNames?.map((e) => e) || [];
    message.categories = object.categories?.map((e) => e) || [];
    message.teamNames = object.teamNames?.map((e) => e) || [];
    message.secretTypes = object.secretTypes?.map((e) => e) || [];
    message.rules = object.rules?.map((e) => RuleFilterItem.fromPartial(e)) || [];
    message.repos = object.repos?.map((e) => GetIssueFiltersResponse_AnnotatedRepo.fromPartial(e)) || [];
    message.tags = object.tags?.map((e) => GetIssueFiltersResponse_AnnotatedRepoTag.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetIssueFiltersResponse_RepositoryRefFilterItemGroup(): GetIssueFiltersResponse_RepositoryRefFilterItemGroup {
  return { repoName: "", repoRefs: [], hasPrimaryRef: false };
}

export const GetIssueFiltersResponse_RepositoryRefFilterItemGroup = {
  encode(
    message: GetIssueFiltersResponse_RepositoryRefFilterItemGroup,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.repoName !== "") {
      writer.uint32(10).string(message.repoName);
    }
    for (const v of message.repoRefs) {
      GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.hasPrimaryRef !== false) {
      writer.uint32(24).bool(message.hasPrimaryRef);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetIssueFiltersResponse_RepositoryRefFilterItemGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetIssueFiltersResponse_RepositoryRefFilterItemGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.repoName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.repoRefs.push(
            GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef.decode(reader, reader.uint32()),
          );
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hasPrimaryRef = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetIssueFiltersResponse_RepositoryRefFilterItemGroup {
    return {
      repoName: isSet(object.repoName) ? globalThis.String(object.repoName) : "",
      repoRefs: globalThis.Array.isArray(object?.repoRefs)
        ? object.repoRefs.map((e: any) => GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef.fromJSON(e))
        : [],
      hasPrimaryRef: isSet(object.hasPrimaryRef) ? globalThis.Boolean(object.hasPrimaryRef) : false,
    };
  },

  toJSON(message: GetIssueFiltersResponse_RepositoryRefFilterItemGroup): unknown {
    const obj: any = {};
    if (message.repoName !== "") {
      obj.repoName = message.repoName;
    }
    if (message.repoRefs?.length) {
      obj.repoRefs = message.repoRefs.map((e) =>
        GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef.toJSON(e)
      );
    }
    if (message.hasPrimaryRef !== false) {
      obj.hasPrimaryRef = message.hasPrimaryRef;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetIssueFiltersResponse_RepositoryRefFilterItemGroup>, I>>(
    base?: I,
  ): GetIssueFiltersResponse_RepositoryRefFilterItemGroup {
    return GetIssueFiltersResponse_RepositoryRefFilterItemGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetIssueFiltersResponse_RepositoryRefFilterItemGroup>, I>>(
    object: I,
  ): GetIssueFiltersResponse_RepositoryRefFilterItemGroup {
    const message = createBaseGetIssueFiltersResponse_RepositoryRefFilterItemGroup();
    message.repoName = object.repoName ?? "";
    message.repoRefs =
      object.repoRefs?.map((e) => GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef.fromPartial(e)) || [];
    message.hasPrimaryRef = object.hasPrimaryRef ?? false;
    return message;
  },
};

function createBaseGetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef(): GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef {
  return { id: 0, refName: "", isPrimary: false };
}

export const GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef = {
  encode(
    message: GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.refName !== "") {
      writer.uint32(18).string(message.refName);
    }
    if (message.isPrimary !== false) {
      writer.uint32(24).bool(message.isPrimary);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.refName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isPrimary = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      refName: isSet(object.refName) ? globalThis.String(object.refName) : "",
      isPrimary: isSet(object.isPrimary) ? globalThis.Boolean(object.isPrimary) : false,
    };
  },

  toJSON(message: GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.refName !== "") {
      obj.refName = message.refName;
    }
    if (message.isPrimary !== false) {
      obj.isPrimary = message.isPrimary;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef>, I>>(
    base?: I,
  ): GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef {
    return GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef>, I>>(
    object: I,
  ): GetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef {
    const message = createBaseGetIssueFiltersResponse_RepositoryRefFilterItemGroup_RepoRef();
    message.id = object.id ?? 0;
    message.refName = object.refName ?? "";
    message.isPrimary = object.isPrimary ?? false;
    return message;
  },
};

function createBaseGetIssueFiltersResponse_AnnotatedRepo(): GetIssueFiltersResponse_AnnotatedRepo {
  return { id: 0, name: "" };
}

export const GetIssueFiltersResponse_AnnotatedRepo = {
  encode(message: GetIssueFiltersResponse_AnnotatedRepo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetIssueFiltersResponse_AnnotatedRepo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetIssueFiltersResponse_AnnotatedRepo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetIssueFiltersResponse_AnnotatedRepo {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: GetIssueFiltersResponse_AnnotatedRepo): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetIssueFiltersResponse_AnnotatedRepo>, I>>(
    base?: I,
  ): GetIssueFiltersResponse_AnnotatedRepo {
    return GetIssueFiltersResponse_AnnotatedRepo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetIssueFiltersResponse_AnnotatedRepo>, I>>(
    object: I,
  ): GetIssueFiltersResponse_AnnotatedRepo {
    const message = createBaseGetIssueFiltersResponse_AnnotatedRepo();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseGetIssueFiltersResponse_AnnotatedRepoTag(): GetIssueFiltersResponse_AnnotatedRepoTag {
  return { id: 0, name: "" };
}

export const GetIssueFiltersResponse_AnnotatedRepoTag = {
  encode(message: GetIssueFiltersResponse_AnnotatedRepoTag, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetIssueFiltersResponse_AnnotatedRepoTag {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetIssueFiltersResponse_AnnotatedRepoTag();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetIssueFiltersResponse_AnnotatedRepoTag {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: GetIssueFiltersResponse_AnnotatedRepoTag): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetIssueFiltersResponse_AnnotatedRepoTag>, I>>(
    base?: I,
  ): GetIssueFiltersResponse_AnnotatedRepoTag {
    return GetIssueFiltersResponse_AnnotatedRepoTag.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetIssueFiltersResponse_AnnotatedRepoTag>, I>>(
    object: I,
  ): GetIssueFiltersResponse_AnnotatedRepoTag {
    const message = createBaseGetIssueFiltersResponse_AnnotatedRepoTag();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListIssueGroupsRequest(): ListIssueGroupsRequest {
  return {
    deploymentId: 0,
    issueType: IssueType.UNSPECIFIED,
    groupBy: undefined,
    limit: undefined,
    cursor: undefined,
    filter: undefined,
    sortBy: undefined,
    sortDirection: undefined,
  };
}

export const ListIssueGroupsRequest = {
  encode(message: ListIssueGroupsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.issueType !== IssueType.UNSPECIFIED) {
      writer.uint32(16).int32(issueTypeToNumber(message.issueType));
    }
    if (message.groupBy !== undefined) {
      writer.uint32(24).int32(listIssueGroupsRequest_GroupByToNumber(message.groupBy));
    }
    if (message.limit !== undefined) {
      writer.uint32(32).uint32(message.limit);
    }
    if (message.cursor !== undefined) {
      writer.uint32(42).string(message.cursor);
    }
    if (message.filter !== undefined) {
      IssuesFilter.encode(message.filter, writer.uint32(50).fork()).ldelim();
    }
    if (message.sortBy !== undefined) {
      writer.uint32(56).int32(listIssueGroupsRequest_SortByToNumber(message.sortBy));
    }
    if (message.sortDirection !== undefined) {
      writer.uint32(64).int32(listIssueGroupsRequest_SortDirectionToNumber(message.sortDirection));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssueGroupsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssueGroupsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.issueType = issueTypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.groupBy = listIssueGroupsRequest_GroupByFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.limit = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.cursor = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.filter = IssuesFilter.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.sortBy = listIssueGroupsRequest_SortByFromJSON(reader.int32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.sortDirection = listIssueGroupsRequest_SortDirectionFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssueGroupsRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      issueType: isSet(object.issueType) ? issueTypeFromJSON(object.issueType) : IssueType.UNSPECIFIED,
      groupBy: isSet(object.groupBy) ? listIssueGroupsRequest_GroupByFromJSON(object.groupBy) : undefined,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : undefined,
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
      filter: isSet(object.filter) ? IssuesFilter.fromJSON(object.filter) : undefined,
      sortBy: isSet(object.sortBy) ? listIssueGroupsRequest_SortByFromJSON(object.sortBy) : undefined,
      sortDirection: isSet(object.sortDirection)
        ? listIssueGroupsRequest_SortDirectionFromJSON(object.sortDirection)
        : undefined,
    };
  },

  toJSON(message: ListIssueGroupsRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.issueType !== IssueType.UNSPECIFIED) {
      obj.issueType = issueTypeToJSON(message.issueType);
    }
    if (message.groupBy !== undefined) {
      obj.groupBy = listIssueGroupsRequest_GroupByToJSON(message.groupBy);
    }
    if (message.limit !== undefined) {
      obj.limit = Math.round(message.limit);
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    if (message.filter !== undefined) {
      obj.filter = IssuesFilter.toJSON(message.filter);
    }
    if (message.sortBy !== undefined) {
      obj.sortBy = listIssueGroupsRequest_SortByToJSON(message.sortBy);
    }
    if (message.sortDirection !== undefined) {
      obj.sortDirection = listIssueGroupsRequest_SortDirectionToJSON(message.sortDirection);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssueGroupsRequest>, I>>(base?: I): ListIssueGroupsRequest {
    return ListIssueGroupsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssueGroupsRequest>, I>>(object: I): ListIssueGroupsRequest {
    const message = createBaseListIssueGroupsRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.issueType = object.issueType ?? IssueType.UNSPECIFIED;
    message.groupBy = object.groupBy ?? undefined;
    message.limit = object.limit ?? undefined;
    message.cursor = object.cursor ?? undefined;
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? IssuesFilter.fromPartial(object.filter)
      : undefined;
    message.sortBy = object.sortBy ?? undefined;
    message.sortDirection = object.sortDirection ?? undefined;
    return message;
  },
};

function createBaseListIssueParentGroupsKeySet(): ListIssueParentGroupsKeySet {
  return { keySet: undefined };
}

export const ListIssueParentGroupsKeySet = {
  encode(message: ListIssueParentGroupsKeySet, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.keySet?.$case) {
      case "sourceKeySet":
        ListIssueParentGroupsKeySet_SourceKeySet.encode(message.keySet.sourceKeySet, writer.uint32(10).fork()).ldelim();
        break;
      case "issueCountsKeySet":
        ListIssueParentGroupsKeySet_IssueCountsKeySet.encode(message.keySet.issueCountsKeySet, writer.uint32(18).fork())
          .ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssueParentGroupsKeySet {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssueParentGroupsKeySet();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keySet = {
            $case: "sourceKeySet",
            sourceKeySet: ListIssueParentGroupsKeySet_SourceKeySet.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.keySet = {
            $case: "issueCountsKeySet",
            issueCountsKeySet: ListIssueParentGroupsKeySet_IssueCountsKeySet.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssueParentGroupsKeySet {
    return {
      keySet: isSet(object.sourceKeySet)
        ? {
          $case: "sourceKeySet",
          sourceKeySet: ListIssueParentGroupsKeySet_SourceKeySet.fromJSON(object.sourceKeySet),
        }
        : isSet(object.issueCountsKeySet)
        ? {
          $case: "issueCountsKeySet",
          issueCountsKeySet: ListIssueParentGroupsKeySet_IssueCountsKeySet.fromJSON(object.issueCountsKeySet),
        }
        : undefined,
    };
  },

  toJSON(message: ListIssueParentGroupsKeySet): unknown {
    const obj: any = {};
    if (message.keySet?.$case === "sourceKeySet") {
      obj.sourceKeySet = ListIssueParentGroupsKeySet_SourceKeySet.toJSON(message.keySet.sourceKeySet);
    }
    if (message.keySet?.$case === "issueCountsKeySet") {
      obj.issueCountsKeySet = ListIssueParentGroupsKeySet_IssueCountsKeySet.toJSON(message.keySet.issueCountsKeySet);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssueParentGroupsKeySet>, I>>(base?: I): ListIssueParentGroupsKeySet {
    return ListIssueParentGroupsKeySet.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssueParentGroupsKeySet>, I>>(object: I): ListIssueParentGroupsKeySet {
    const message = createBaseListIssueParentGroupsKeySet();
    if (
      object.keySet?.$case === "sourceKeySet" &&
      object.keySet?.sourceKeySet !== undefined &&
      object.keySet?.sourceKeySet !== null
    ) {
      message.keySet = {
        $case: "sourceKeySet",
        sourceKeySet: ListIssueParentGroupsKeySet_SourceKeySet.fromPartial(object.keySet.sourceKeySet),
      };
    }
    if (
      object.keySet?.$case === "issueCountsKeySet" &&
      object.keySet?.issueCountsKeySet !== undefined &&
      object.keySet?.issueCountsKeySet !== null
    ) {
      message.keySet = {
        $case: "issueCountsKeySet",
        issueCountsKeySet: ListIssueParentGroupsKeySet_IssueCountsKeySet.fromPartial(object.keySet.issueCountsKeySet),
      };
    }
    return message;
  },
};

function createBaseListIssueParentGroupsKeySet_SourceKeySet(): ListIssueParentGroupsKeySet_SourceKeySet {
  return { ruleSeverity: Severity.UNSPECIFIED, ruleVisibility: RuleVisibility.UNSPECIFIED, issueCounts: 0, ruleId: 0 };
}

export const ListIssueParentGroupsKeySet_SourceKeySet = {
  encode(message: ListIssueParentGroupsKeySet_SourceKeySet, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruleSeverity !== Severity.UNSPECIFIED) {
      writer.uint32(8).int32(severityToNumber(message.ruleSeverity));
    }
    if (message.ruleVisibility !== RuleVisibility.UNSPECIFIED) {
      writer.uint32(16).int32(ruleVisibilityToNumber(message.ruleVisibility));
    }
    if (message.issueCounts !== 0) {
      writer.uint32(24).uint64(message.issueCounts);
    }
    if (message.ruleId !== 0) {
      writer.uint32(32).uint64(message.ruleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssueParentGroupsKeySet_SourceKeySet {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssueParentGroupsKeySet_SourceKeySet();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ruleSeverity = severityFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.ruleVisibility = ruleVisibilityFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.issueCounts = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.ruleId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssueParentGroupsKeySet_SourceKeySet {
    return {
      ruleSeverity: isSet(object.ruleSeverity) ? severityFromJSON(object.ruleSeverity) : Severity.UNSPECIFIED,
      ruleVisibility: isSet(object.ruleVisibility)
        ? ruleVisibilityFromJSON(object.ruleVisibility)
        : RuleVisibility.UNSPECIFIED,
      issueCounts: isSet(object.issueCounts) ? globalThis.Number(object.issueCounts) : 0,
      ruleId: isSet(object.ruleId) ? globalThis.Number(object.ruleId) : 0,
    };
  },

  toJSON(message: ListIssueParentGroupsKeySet_SourceKeySet): unknown {
    const obj: any = {};
    if (message.ruleSeverity !== Severity.UNSPECIFIED) {
      obj.ruleSeverity = severityToJSON(message.ruleSeverity);
    }
    if (message.ruleVisibility !== RuleVisibility.UNSPECIFIED) {
      obj.ruleVisibility = ruleVisibilityToJSON(message.ruleVisibility);
    }
    if (message.issueCounts !== 0) {
      obj.issueCounts = Math.round(message.issueCounts);
    }
    if (message.ruleId !== 0) {
      obj.ruleId = Math.round(message.ruleId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssueParentGroupsKeySet_SourceKeySet>, I>>(
    base?: I,
  ): ListIssueParentGroupsKeySet_SourceKeySet {
    return ListIssueParentGroupsKeySet_SourceKeySet.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssueParentGroupsKeySet_SourceKeySet>, I>>(
    object: I,
  ): ListIssueParentGroupsKeySet_SourceKeySet {
    const message = createBaseListIssueParentGroupsKeySet_SourceKeySet();
    message.ruleSeverity = object.ruleSeverity ?? Severity.UNSPECIFIED;
    message.ruleVisibility = object.ruleVisibility ?? RuleVisibility.UNSPECIFIED;
    message.issueCounts = object.issueCounts ?? 0;
    message.ruleId = object.ruleId ?? 0;
    return message;
  },
};

function createBaseListIssueParentGroupsKeySet_IssueCountsKeySet(): ListIssueParentGroupsKeySet_IssueCountsKeySet {
  return { issueCounts: 0, ruleId: 0 };
}

export const ListIssueParentGroupsKeySet_IssueCountsKeySet = {
  encode(message: ListIssueParentGroupsKeySet_IssueCountsKeySet, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.issueCounts !== 0) {
      writer.uint32(8).uint64(message.issueCounts);
    }
    if (message.ruleId !== 0) {
      writer.uint32(16).uint64(message.ruleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssueParentGroupsKeySet_IssueCountsKeySet {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssueParentGroupsKeySet_IssueCountsKeySet();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.issueCounts = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.ruleId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssueParentGroupsKeySet_IssueCountsKeySet {
    return {
      issueCounts: isSet(object.issueCounts) ? globalThis.Number(object.issueCounts) : 0,
      ruleId: isSet(object.ruleId) ? globalThis.Number(object.ruleId) : 0,
    };
  },

  toJSON(message: ListIssueParentGroupsKeySet_IssueCountsKeySet): unknown {
    const obj: any = {};
    if (message.issueCounts !== 0) {
      obj.issueCounts = Math.round(message.issueCounts);
    }
    if (message.ruleId !== 0) {
      obj.ruleId = Math.round(message.ruleId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssueParentGroupsKeySet_IssueCountsKeySet>, I>>(
    base?: I,
  ): ListIssueParentGroupsKeySet_IssueCountsKeySet {
    return ListIssueParentGroupsKeySet_IssueCountsKeySet.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssueParentGroupsKeySet_IssueCountsKeySet>, I>>(
    object: I,
  ): ListIssueParentGroupsKeySet_IssueCountsKeySet {
    const message = createBaseListIssueParentGroupsKeySet_IssueCountsKeySet();
    message.issueCounts = object.issueCounts ?? 0;
    message.ruleId = object.ruleId ?? 0;
    return message;
  },
};

function createBaseSupersededRule(): SupersededRule {
  return { product: RuleType.UNSPECIFIED, rulePath: undefined };
}

export const SupersededRule = {
  encode(message: SupersededRule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.product !== RuleType.UNSPECIFIED) {
      writer.uint32(8).int32(ruleTypeToNumber(message.product));
    }
    if (message.rulePath !== undefined) {
      writer.uint32(18).string(message.rulePath);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SupersededRule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSupersededRule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.product = ruleTypeFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.rulePath = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SupersededRule {
    return {
      product: isSet(object.product) ? ruleTypeFromJSON(object.product) : RuleType.UNSPECIFIED,
      rulePath: isSet(object.rulePath) ? globalThis.String(object.rulePath) : undefined,
    };
  },

  toJSON(message: SupersededRule): unknown {
    const obj: any = {};
    if (message.product !== RuleType.UNSPECIFIED) {
      obj.product = ruleTypeToJSON(message.product);
    }
    if (message.rulePath !== undefined) {
      obj.rulePath = message.rulePath;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SupersededRule>, I>>(base?: I): SupersededRule {
    return SupersededRule.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SupersededRule>, I>>(object: I): SupersededRule {
    const message = createBaseSupersededRule();
    message.product = object.product ?? RuleType.UNSPECIFIED;
    message.rulePath = object.rulePath ?? undefined;
    return message;
  },
};

function createBaseListIssueGroupsResponse(): ListIssueGroupsResponse {
  return { groups: [], cursor: undefined, total: undefined, fetched: undefined };
}

export const ListIssueGroupsResponse = {
  encode(message: ListIssueGroupsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.groups) {
      ListIssueGroupsResponse_RuleGroup.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.cursor !== undefined) {
      writer.uint32(18).string(message.cursor);
    }
    if (message.total !== undefined) {
      writer.uint32(24).uint64(message.total);
    }
    if (message.fetched !== undefined) {
      writer.uint32(32).uint64(message.fetched);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssueGroupsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssueGroupsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groups.push(ListIssueGroupsResponse_RuleGroup.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cursor = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.total = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.fetched = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssueGroupsResponse {
    return {
      groups: globalThis.Array.isArray(object?.groups)
        ? object.groups.map((e: any) => ListIssueGroupsResponse_RuleGroup.fromJSON(e))
        : [],
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
      total: isSet(object.total) ? globalThis.Number(object.total) : undefined,
      fetched: isSet(object.fetched) ? globalThis.Number(object.fetched) : undefined,
    };
  },

  toJSON(message: ListIssueGroupsResponse): unknown {
    const obj: any = {};
    if (message.groups?.length) {
      obj.groups = message.groups.map((e) => ListIssueGroupsResponse_RuleGroup.toJSON(e));
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    if (message.total !== undefined) {
      obj.total = Math.round(message.total);
    }
    if (message.fetched !== undefined) {
      obj.fetched = Math.round(message.fetched);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssueGroupsResponse>, I>>(base?: I): ListIssueGroupsResponse {
    return ListIssueGroupsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssueGroupsResponse>, I>>(object: I): ListIssueGroupsResponse {
    const message = createBaseListIssueGroupsResponse();
    message.groups = object.groups?.map((e) => ListIssueGroupsResponse_RuleGroup.fromPartial(e)) || [];
    message.cursor = object.cursor ?? undefined;
    message.total = object.total ?? undefined;
    message.fetched = object.fetched ?? undefined;
    return message;
  },
};

function createBaseListIssueGroupsResponse_Rule(): ListIssueGroupsResponse_Rule {
  return { ruleId: "", ruleVersionId: undefined, origin: undefined };
}

export const ListIssueGroupsResponse_Rule = {
  encode(message: ListIssueGroupsResponse_Rule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruleId !== "") {
      writer.uint32(10).string(message.ruleId);
    }
    if (message.ruleVersionId !== undefined) {
      writer.uint32(18).string(message.ruleVersionId);
    }
    if (message.origin !== undefined) {
      writer.uint32(24).int32(ruleOriginToNumber(message.origin));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssueGroupsResponse_Rule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssueGroupsResponse_Rule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ruleId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ruleVersionId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.origin = ruleOriginFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssueGroupsResponse_Rule {
    return {
      ruleId: isSet(object.ruleId) ? globalThis.String(object.ruleId) : "",
      ruleVersionId: isSet(object.ruleVersionId) ? globalThis.String(object.ruleVersionId) : undefined,
      origin: isSet(object.origin) ? ruleOriginFromJSON(object.origin) : undefined,
    };
  },

  toJSON(message: ListIssueGroupsResponse_Rule): unknown {
    const obj: any = {};
    if (message.ruleId !== "") {
      obj.ruleId = message.ruleId;
    }
    if (message.ruleVersionId !== undefined) {
      obj.ruleVersionId = message.ruleVersionId;
    }
    if (message.origin !== undefined) {
      obj.origin = ruleOriginToJSON(message.origin);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssueGroupsResponse_Rule>, I>>(base?: I): ListIssueGroupsResponse_Rule {
    return ListIssueGroupsResponse_Rule.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssueGroupsResponse_Rule>, I>>(object: I): ListIssueGroupsResponse_Rule {
    const message = createBaseListIssueGroupsResponse_Rule();
    message.ruleId = object.ruleId ?? "";
    message.ruleVersionId = object.ruleVersionId ?? undefined;
    message.origin = object.origin ?? undefined;
    return message;
  },
};

function createBaseListIssueGroupsResponse_RuleMeta(): ListIssueGroupsResponse_RuleMeta {
  return { author: undefined, authorPhotoUrl: undefined, rule: undefined };
}

export const ListIssueGroupsResponse_RuleMeta = {
  encode(message: ListIssueGroupsResponse_RuleMeta, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.author !== undefined) {
      writer.uint32(10).string(message.author);
    }
    if (message.authorPhotoUrl !== undefined) {
      writer.uint32(18).string(message.authorPhotoUrl);
    }
    if (message.rule !== undefined) {
      ListIssueGroupsResponse_Rule.encode(message.rule, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssueGroupsResponse_RuleMeta {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssueGroupsResponse_RuleMeta();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.author = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authorPhotoUrl = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.rule = ListIssueGroupsResponse_Rule.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssueGroupsResponse_RuleMeta {
    return {
      author: isSet(object.author) ? globalThis.String(object.author) : undefined,
      authorPhotoUrl: isSet(object.authorPhotoUrl) ? globalThis.String(object.authorPhotoUrl) : undefined,
      rule: isSet(object.rule) ? ListIssueGroupsResponse_Rule.fromJSON(object.rule) : undefined,
    };
  },

  toJSON(message: ListIssueGroupsResponse_RuleMeta): unknown {
    const obj: any = {};
    if (message.author !== undefined) {
      obj.author = message.author;
    }
    if (message.authorPhotoUrl !== undefined) {
      obj.authorPhotoUrl = message.authorPhotoUrl;
    }
    if (message.rule !== undefined) {
      obj.rule = ListIssueGroupsResponse_Rule.toJSON(message.rule);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssueGroupsResponse_RuleMeta>, I>>(
    base?: I,
  ): ListIssueGroupsResponse_RuleMeta {
    return ListIssueGroupsResponse_RuleMeta.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssueGroupsResponse_RuleMeta>, I>>(
    object: I,
  ): ListIssueGroupsResponse_RuleMeta {
    const message = createBaseListIssueGroupsResponse_RuleMeta();
    message.author = object.author ?? undefined;
    message.authorPhotoUrl = object.authorPhotoUrl ?? undefined;
    message.rule = (object.rule !== undefined && object.rule !== null)
      ? ListIssueGroupsResponse_Rule.fromPartial(object.rule)
      : undefined;
    return message;
  },
};

function createBaseListIssueGroupsResponse_RuleGroup(): ListIssueGroupsResponse_RuleGroup {
  return {
    issuesCount: 0,
    ruleId: 0,
    ruleShaSum: "",
    ruleVisibility: RuleVisibility.UNSPECIFIED,
    ruleMeta: undefined,
    ruleLastChangeAt: undefined,
    ruleLastChangeBy: undefined,
    rulePath: undefined,
    ruleSourceUri: undefined,
    ruleTestCases: [],
    ruleDefinition: undefined,
    ruleDeploymentName: undefined,
    ruleSupersededBy: [],
    productAttributes: undefined,
  };
}

export const ListIssueGroupsResponse_RuleGroup = {
  encode(message: ListIssueGroupsResponse_RuleGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.issuesCount !== 0) {
      writer.uint32(8).uint64(message.issuesCount);
    }
    if (message.ruleId !== 0) {
      writer.uint32(16).uint64(message.ruleId);
    }
    if (message.ruleShaSum !== "") {
      writer.uint32(26).string(message.ruleShaSum);
    }
    if (message.ruleVisibility !== RuleVisibility.UNSPECIFIED) {
      writer.uint32(32).int32(ruleVisibilityToNumber(message.ruleVisibility));
    }
    if (message.ruleMeta !== undefined) {
      ListIssueGroupsResponse_RuleMeta.encode(message.ruleMeta, writer.uint32(42).fork()).ldelim();
    }
    if (message.ruleLastChangeAt !== undefined) {
      Timestamp.encode(toTimestamp(message.ruleLastChangeAt), writer.uint32(50).fork()).ldelim();
    }
    if (message.ruleLastChangeBy !== undefined) {
      writer.uint32(58).string(message.ruleLastChangeBy);
    }
    if (message.rulePath !== undefined) {
      writer.uint32(66).string(message.rulePath);
    }
    if (message.ruleSourceUri !== undefined) {
      writer.uint32(74).string(message.ruleSourceUri);
    }
    for (const v of message.ruleTestCases) {
      ListIssueGroupsResponse_RuleGroup_TestCase.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.ruleDefinition !== undefined) {
      Struct.encode(Struct.wrap(message.ruleDefinition), writer.uint32(90).fork()).ldelim();
    }
    if (message.ruleDeploymentName !== undefined) {
      writer.uint32(98).string(message.ruleDeploymentName);
    }
    for (const v of message.ruleSupersededBy) {
      SupersededRule.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    switch (message.productAttributes?.$case) {
      case "sastAttributes":
        ListIssueGroupsResponse_RuleGroup_SastAttributes.encode(
          message.productAttributes.sastAttributes,
          writer.uint32(114).fork(),
        ).ldelim();
        break;
      case "scaAttributes":
        ListIssueGroupsResponse_RuleGroup_ScaAttributes.encode(
          message.productAttributes.scaAttributes,
          writer.uint32(122).fork(),
        ).ldelim();
        break;
      case "secretsAttributes":
        ListIssueGroupsResponse_RuleGroup_SecretsAttributes.encode(
          message.productAttributes.secretsAttributes,
          writer.uint32(130).fork(),
        ).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssueGroupsResponse_RuleGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssueGroupsResponse_RuleGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.issuesCount = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.ruleId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ruleShaSum = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.ruleVisibility = ruleVisibilityFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ruleMeta = ListIssueGroupsResponse_RuleMeta.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ruleLastChangeAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ruleLastChangeBy = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.rulePath = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.ruleSourceUri = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.ruleTestCases.push(ListIssueGroupsResponse_RuleGroup_TestCase.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.ruleDefinition = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.ruleDeploymentName = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.ruleSupersededBy.push(SupersededRule.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.productAttributes = {
            $case: "sastAttributes",
            sastAttributes: ListIssueGroupsResponse_RuleGroup_SastAttributes.decode(reader, reader.uint32()),
          };
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.productAttributes = {
            $case: "scaAttributes",
            scaAttributes: ListIssueGroupsResponse_RuleGroup_ScaAttributes.decode(reader, reader.uint32()),
          };
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.productAttributes = {
            $case: "secretsAttributes",
            secretsAttributes: ListIssueGroupsResponse_RuleGroup_SecretsAttributes.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssueGroupsResponse_RuleGroup {
    return {
      issuesCount: isSet(object.issuesCount) ? globalThis.Number(object.issuesCount) : 0,
      ruleId: isSet(object.ruleId) ? globalThis.Number(object.ruleId) : 0,
      ruleShaSum: isSet(object.ruleShaSum) ? globalThis.String(object.ruleShaSum) : "",
      ruleVisibility: isSet(object.ruleVisibility)
        ? ruleVisibilityFromJSON(object.ruleVisibility)
        : RuleVisibility.UNSPECIFIED,
      ruleMeta: isSet(object.ruleMeta) ? ListIssueGroupsResponse_RuleMeta.fromJSON(object.ruleMeta) : undefined,
      ruleLastChangeAt: isSet(object.ruleLastChangeAt) ? fromJsonTimestamp(object.ruleLastChangeAt) : undefined,
      ruleLastChangeBy: isSet(object.ruleLastChangeBy) ? globalThis.String(object.ruleLastChangeBy) : undefined,
      rulePath: isSet(object.rulePath) ? globalThis.String(object.rulePath) : undefined,
      ruleSourceUri: isSet(object.ruleSourceUri) ? globalThis.String(object.ruleSourceUri) : undefined,
      ruleTestCases: globalThis.Array.isArray(object?.ruleTestCases)
        ? object.ruleTestCases.map((e: any) => ListIssueGroupsResponse_RuleGroup_TestCase.fromJSON(e))
        : [],
      ruleDefinition: isObject(object.ruleDefinition) ? object.ruleDefinition : undefined,
      ruleDeploymentName: isSet(object.ruleDeploymentName) ? globalThis.String(object.ruleDeploymentName) : undefined,
      ruleSupersededBy: globalThis.Array.isArray(object?.ruleSupersededBy)
        ? object.ruleSupersededBy.map((e: any) => SupersededRule.fromJSON(e))
        : [],
      productAttributes: isSet(object.sastAttributes)
        ? {
          $case: "sastAttributes",
          sastAttributes: ListIssueGroupsResponse_RuleGroup_SastAttributes.fromJSON(object.sastAttributes),
        }
        : isSet(object.scaAttributes)
        ? {
          $case: "scaAttributes",
          scaAttributes: ListIssueGroupsResponse_RuleGroup_ScaAttributes.fromJSON(object.scaAttributes),
        }
        : isSet(object.secretsAttributes)
        ? {
          $case: "secretsAttributes",
          secretsAttributes: ListIssueGroupsResponse_RuleGroup_SecretsAttributes.fromJSON(object.secretsAttributes),
        }
        : undefined,
    };
  },

  toJSON(message: ListIssueGroupsResponse_RuleGroup): unknown {
    const obj: any = {};
    if (message.issuesCount !== 0) {
      obj.issuesCount = Math.round(message.issuesCount);
    }
    if (message.ruleId !== 0) {
      obj.ruleId = Math.round(message.ruleId);
    }
    if (message.ruleShaSum !== "") {
      obj.ruleShaSum = message.ruleShaSum;
    }
    if (message.ruleVisibility !== RuleVisibility.UNSPECIFIED) {
      obj.ruleVisibility = ruleVisibilityToJSON(message.ruleVisibility);
    }
    if (message.ruleMeta !== undefined) {
      obj.ruleMeta = ListIssueGroupsResponse_RuleMeta.toJSON(message.ruleMeta);
    }
    if (message.ruleLastChangeAt !== undefined) {
      obj.ruleLastChangeAt = message.ruleLastChangeAt.toISOString();
    }
    if (message.ruleLastChangeBy !== undefined) {
      obj.ruleLastChangeBy = message.ruleLastChangeBy;
    }
    if (message.rulePath !== undefined) {
      obj.rulePath = message.rulePath;
    }
    if (message.ruleSourceUri !== undefined) {
      obj.ruleSourceUri = message.ruleSourceUri;
    }
    if (message.ruleTestCases?.length) {
      obj.ruleTestCases = message.ruleTestCases.map((e) => ListIssueGroupsResponse_RuleGroup_TestCase.toJSON(e));
    }
    if (message.ruleDefinition !== undefined) {
      obj.ruleDefinition = message.ruleDefinition;
    }
    if (message.ruleDeploymentName !== undefined) {
      obj.ruleDeploymentName = message.ruleDeploymentName;
    }
    if (message.ruleSupersededBy?.length) {
      obj.ruleSupersededBy = message.ruleSupersededBy.map((e) => SupersededRule.toJSON(e));
    }
    if (message.productAttributes?.$case === "sastAttributes") {
      obj.sastAttributes = ListIssueGroupsResponse_RuleGroup_SastAttributes.toJSON(
        message.productAttributes.sastAttributes,
      );
    }
    if (message.productAttributes?.$case === "scaAttributes") {
      obj.scaAttributes = ListIssueGroupsResponse_RuleGroup_ScaAttributes.toJSON(
        message.productAttributes.scaAttributes,
      );
    }
    if (message.productAttributes?.$case === "secretsAttributes") {
      obj.secretsAttributes = ListIssueGroupsResponse_RuleGroup_SecretsAttributes.toJSON(
        message.productAttributes.secretsAttributes,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssueGroupsResponse_RuleGroup>, I>>(
    base?: I,
  ): ListIssueGroupsResponse_RuleGroup {
    return ListIssueGroupsResponse_RuleGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssueGroupsResponse_RuleGroup>, I>>(
    object: I,
  ): ListIssueGroupsResponse_RuleGroup {
    const message = createBaseListIssueGroupsResponse_RuleGroup();
    message.issuesCount = object.issuesCount ?? 0;
    message.ruleId = object.ruleId ?? 0;
    message.ruleShaSum = object.ruleShaSum ?? "";
    message.ruleVisibility = object.ruleVisibility ?? RuleVisibility.UNSPECIFIED;
    message.ruleMeta = (object.ruleMeta !== undefined && object.ruleMeta !== null)
      ? ListIssueGroupsResponse_RuleMeta.fromPartial(object.ruleMeta)
      : undefined;
    message.ruleLastChangeAt = object.ruleLastChangeAt ?? undefined;
    message.ruleLastChangeBy = object.ruleLastChangeBy ?? undefined;
    message.rulePath = object.rulePath ?? undefined;
    message.ruleSourceUri = object.ruleSourceUri ?? undefined;
    message.ruleTestCases =
      object.ruleTestCases?.map((e) => ListIssueGroupsResponse_RuleGroup_TestCase.fromPartial(e)) || [];
    message.ruleDefinition = object.ruleDefinition ?? undefined;
    message.ruleDeploymentName = object.ruleDeploymentName ?? undefined;
    message.ruleSupersededBy = object.ruleSupersededBy?.map((e) => SupersededRule.fromPartial(e)) || [];
    if (
      object.productAttributes?.$case === "sastAttributes" &&
      object.productAttributes?.sastAttributes !== undefined &&
      object.productAttributes?.sastAttributes !== null
    ) {
      message.productAttributes = {
        $case: "sastAttributes",
        sastAttributes: ListIssueGroupsResponse_RuleGroup_SastAttributes.fromPartial(
          object.productAttributes.sastAttributes,
        ),
      };
    }
    if (
      object.productAttributes?.$case === "scaAttributes" &&
      object.productAttributes?.scaAttributes !== undefined &&
      object.productAttributes?.scaAttributes !== null
    ) {
      message.productAttributes = {
        $case: "scaAttributes",
        scaAttributes: ListIssueGroupsResponse_RuleGroup_ScaAttributes.fromPartial(
          object.productAttributes.scaAttributes,
        ),
      };
    }
    if (
      object.productAttributes?.$case === "secretsAttributes" &&
      object.productAttributes?.secretsAttributes !== undefined &&
      object.productAttributes?.secretsAttributes !== null
    ) {
      message.productAttributes = {
        $case: "secretsAttributes",
        secretsAttributes: ListIssueGroupsResponse_RuleGroup_SecretsAttributes.fromPartial(
          object.productAttributes.secretsAttributes,
        ),
      };
    }
    return message;
  },
};

function createBaseListIssueGroupsResponse_RuleGroup_TestCase(): ListIssueGroupsResponse_RuleGroup_TestCase {
  return { target: "", language: undefined };
}

export const ListIssueGroupsResponse_RuleGroup_TestCase = {
  encode(message: ListIssueGroupsResponse_RuleGroup_TestCase, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.target !== "") {
      writer.uint32(10).string(message.target);
    }
    if (message.language !== undefined) {
      writer.uint32(18).string(message.language);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssueGroupsResponse_RuleGroup_TestCase {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssueGroupsResponse_RuleGroup_TestCase();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.target = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.language = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssueGroupsResponse_RuleGroup_TestCase {
    return {
      target: isSet(object.target) ? globalThis.String(object.target) : "",
      language: isSet(object.language) ? globalThis.String(object.language) : undefined,
    };
  },

  toJSON(message: ListIssueGroupsResponse_RuleGroup_TestCase): unknown {
    const obj: any = {};
    if (message.target !== "") {
      obj.target = message.target;
    }
    if (message.language !== undefined) {
      obj.language = message.language;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssueGroupsResponse_RuleGroup_TestCase>, I>>(
    base?: I,
  ): ListIssueGroupsResponse_RuleGroup_TestCase {
    return ListIssueGroupsResponse_RuleGroup_TestCase.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssueGroupsResponse_RuleGroup_TestCase>, I>>(
    object: I,
  ): ListIssueGroupsResponse_RuleGroup_TestCase {
    const message = createBaseListIssueGroupsResponse_RuleGroup_TestCase();
    message.target = object.target ?? "";
    message.language = object.language ?? undefined;
    return message;
  },
};

function createBaseListIssueGroupsResponse_RuleGroup_SastAttributes(): ListIssueGroupsResponse_RuleGroup_SastAttributes {
  return {};
}

export const ListIssueGroupsResponse_RuleGroup_SastAttributes = {
  encode(_: ListIssueGroupsResponse_RuleGroup_SastAttributes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssueGroupsResponse_RuleGroup_SastAttributes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssueGroupsResponse_RuleGroup_SastAttributes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListIssueGroupsResponse_RuleGroup_SastAttributes {
    return {};
  },

  toJSON(_: ListIssueGroupsResponse_RuleGroup_SastAttributes): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssueGroupsResponse_RuleGroup_SastAttributes>, I>>(
    base?: I,
  ): ListIssueGroupsResponse_RuleGroup_SastAttributes {
    return ListIssueGroupsResponse_RuleGroup_SastAttributes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssueGroupsResponse_RuleGroup_SastAttributes>, I>>(
    _: I,
  ): ListIssueGroupsResponse_RuleGroup_SastAttributes {
    const message = createBaseListIssueGroupsResponse_RuleGroup_SastAttributes();
    return message;
  },
};

function createBaseListIssueGroupsResponse_RuleGroup_ScaAttributes(): ListIssueGroupsResponse_RuleGroup_ScaAttributes {
  return { epssScore: undefined };
}

export const ListIssueGroupsResponse_RuleGroup_ScaAttributes = {
  encode(
    message: ListIssueGroupsResponse_RuleGroup_ScaAttributes,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.epssScore !== undefined) {
      EpssScore.encode(message.epssScore, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssueGroupsResponse_RuleGroup_ScaAttributes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssueGroupsResponse_RuleGroup_ScaAttributes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 9:
          if (tag !== 74) {
            break;
          }

          message.epssScore = EpssScore.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIssueGroupsResponse_RuleGroup_ScaAttributes {
    return { epssScore: isSet(object.epssScore) ? EpssScore.fromJSON(object.epssScore) : undefined };
  },

  toJSON(message: ListIssueGroupsResponse_RuleGroup_ScaAttributes): unknown {
    const obj: any = {};
    if (message.epssScore !== undefined) {
      obj.epssScore = EpssScore.toJSON(message.epssScore);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssueGroupsResponse_RuleGroup_ScaAttributes>, I>>(
    base?: I,
  ): ListIssueGroupsResponse_RuleGroup_ScaAttributes {
    return ListIssueGroupsResponse_RuleGroup_ScaAttributes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssueGroupsResponse_RuleGroup_ScaAttributes>, I>>(
    object: I,
  ): ListIssueGroupsResponse_RuleGroup_ScaAttributes {
    const message = createBaseListIssueGroupsResponse_RuleGroup_ScaAttributes();
    message.epssScore = (object.epssScore !== undefined && object.epssScore !== null)
      ? EpssScore.fromPartial(object.epssScore)
      : undefined;
    return message;
  },
};

function createBaseListIssueGroupsResponse_RuleGroup_SecretsAttributes(): ListIssueGroupsResponse_RuleGroup_SecretsAttributes {
  return {};
}

export const ListIssueGroupsResponse_RuleGroup_SecretsAttributes = {
  encode(_: ListIssueGroupsResponse_RuleGroup_SecretsAttributes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListIssueGroupsResponse_RuleGroup_SecretsAttributes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIssueGroupsResponse_RuleGroup_SecretsAttributes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListIssueGroupsResponse_RuleGroup_SecretsAttributes {
    return {};
  },

  toJSON(_: ListIssueGroupsResponse_RuleGroup_SecretsAttributes): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListIssueGroupsResponse_RuleGroup_SecretsAttributes>, I>>(
    base?: I,
  ): ListIssueGroupsResponse_RuleGroup_SecretsAttributes {
    return ListIssueGroupsResponse_RuleGroup_SecretsAttributes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListIssueGroupsResponse_RuleGroup_SecretsAttributes>, I>>(
    _: I,
  ): ListIssueGroupsResponse_RuleGroup_SecretsAttributes {
    const message = createBaseListIssueGroupsResponse_RuleGroup_SecretsAttributes();
    return message;
  },
};

function createBaseUpdateFindingParams(): UpdateFindingParams {
  return { triageState: FindingTriageState.UNSPECIFIED, triageReason: undefined, note: undefined };
}

export const UpdateFindingParams = {
  encode(message: UpdateFindingParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.triageState !== FindingTriageState.UNSPECIFIED) {
      writer.uint32(8).int32(findingTriageStateToNumber(message.triageState));
    }
    if (message.triageReason !== undefined) {
      writer.uint32(16).int32(findingTriageReasonToNumber(message.triageReason));
    }
    if (message.note !== undefined) {
      writer.uint32(26).string(message.note);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFindingParams {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFindingParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.triageState = findingTriageStateFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.triageReason = findingTriageReasonFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.note = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateFindingParams {
    return {
      triageState: isSet(object.triageState)
        ? findingTriageStateFromJSON(object.triageState)
        : FindingTriageState.UNSPECIFIED,
      triageReason: isSet(object.triageReason) ? findingTriageReasonFromJSON(object.triageReason) : undefined,
      note: isSet(object.note) ? globalThis.String(object.note) : undefined,
    };
  },

  toJSON(message: UpdateFindingParams): unknown {
    const obj: any = {};
    if (message.triageState !== FindingTriageState.UNSPECIFIED) {
      obj.triageState = findingTriageStateToJSON(message.triageState);
    }
    if (message.triageReason !== undefined) {
      obj.triageReason = findingTriageReasonToJSON(message.triageReason);
    }
    if (message.note !== undefined) {
      obj.note = message.note;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateFindingParams>, I>>(base?: I): UpdateFindingParams {
    return UpdateFindingParams.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFindingParams>, I>>(object: I): UpdateFindingParams {
    const message = createBaseUpdateFindingParams();
    message.triageState = object.triageState ?? FindingTriageState.UNSPECIFIED;
    message.triageReason = object.triageReason ?? undefined;
    message.note = object.note ?? undefined;
    return message;
  },
};

function createBaseUpdateFindingsRequest(): UpdateFindingsRequest {
  return { deploymentId: 0, issueType: IssueType.UNSPECIFIED, filter: undefined, params: undefined };
}

export const UpdateFindingsRequest = {
  encode(message: UpdateFindingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.issueType !== IssueType.UNSPECIFIED) {
      writer.uint32(16).int32(issueTypeToNumber(message.issueType));
    }
    if (message.filter !== undefined) {
      IssuesFilter.encode(message.filter, writer.uint32(26).fork()).ldelim();
    }
    if (message.params !== undefined) {
      UpdateFindingParams.encode(message.params, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFindingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFindingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.issueType = issueTypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filter = IssuesFilter.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.params = UpdateFindingParams.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateFindingsRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      issueType: isSet(object.issueType) ? issueTypeFromJSON(object.issueType) : IssueType.UNSPECIFIED,
      filter: isSet(object.filter) ? IssuesFilter.fromJSON(object.filter) : undefined,
      params: isSet(object.params) ? UpdateFindingParams.fromJSON(object.params) : undefined,
    };
  },

  toJSON(message: UpdateFindingsRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.issueType !== IssueType.UNSPECIFIED) {
      obj.issueType = issueTypeToJSON(message.issueType);
    }
    if (message.filter !== undefined) {
      obj.filter = IssuesFilter.toJSON(message.filter);
    }
    if (message.params !== undefined) {
      obj.params = UpdateFindingParams.toJSON(message.params);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateFindingsRequest>, I>>(base?: I): UpdateFindingsRequest {
    return UpdateFindingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFindingsRequest>, I>>(object: I): UpdateFindingsRequest {
    const message = createBaseUpdateFindingsRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.issueType = object.issueType ?? IssueType.UNSPECIFIED;
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? IssuesFilter.fromPartial(object.filter)
      : undefined;
    message.params = (object.params !== undefined && object.params !== null)
      ? UpdateFindingParams.fromPartial(object.params)
      : undefined;
    return message;
  },
};

function createBaseUpdateFindingsResponse(): UpdateFindingsResponse {
  return { issueParentCount: 0, issueCount: 0, updatedCount: 0 };
}

export const UpdateFindingsResponse = {
  encode(message: UpdateFindingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.issueParentCount !== 0) {
      writer.uint32(8).uint64(message.issueParentCount);
    }
    if (message.issueCount !== 0) {
      writer.uint32(16).uint64(message.issueCount);
    }
    if (message.updatedCount !== 0) {
      writer.uint32(24).uint64(message.updatedCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFindingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFindingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.issueParentCount = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.issueCount = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.updatedCount = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateFindingsResponse {
    return {
      issueParentCount: isSet(object.issueParentCount) ? globalThis.Number(object.issueParentCount) : 0,
      issueCount: isSet(object.issueCount) ? globalThis.Number(object.issueCount) : 0,
      updatedCount: isSet(object.updatedCount) ? globalThis.Number(object.updatedCount) : 0,
    };
  },

  toJSON(message: UpdateFindingsResponse): unknown {
    const obj: any = {};
    if (message.issueParentCount !== 0) {
      obj.issueParentCount = Math.round(message.issueParentCount);
    }
    if (message.issueCount !== 0) {
      obj.issueCount = Math.round(message.issueCount);
    }
    if (message.updatedCount !== 0) {
      obj.updatedCount = Math.round(message.updatedCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateFindingsResponse>, I>>(base?: I): UpdateFindingsResponse {
    return UpdateFindingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFindingsResponse>, I>>(object: I): UpdateFindingsResponse {
    const message = createBaseUpdateFindingsResponse();
    message.issueParentCount = object.issueParentCount ?? 0;
    message.issueCount = object.issueCount ?? 0;
    message.updatedCount = object.updatedCount ?? 0;
    return message;
  },
};

function createBaseIssuesFilter(): IssuesFilter {
  return {
    ids: [],
    since: undefined,
    until: undefined,
    repositoryNames: [],
    repositoryTags: [],
    refs: [],
    repositoryRefIds: [],
    aiVerdicts: [],
    aiSensitivities: [],
    status: [],
    tab: undefined,
    teamNames: [],
    actions: [],
    policySlugs: [],
    rules: [],
    ruleIds: [],
    rulesets: [],
    categories: [],
    confidences: [],
    severities: [],
    transitivities: [],
    exposures: [],
    dependencies: [],
    cves: [],
    source: [],
    reachabilities: [],
    issueParentIds: [],
    validationStates: [],
    secretTypes: [],
    repoVisibilities: [],
    excludeHistorical: undefined,
    onPrimaryBranch: undefined,
    epssProbabilities: [],
    triageReasons: [],
    timeFilter: undefined,
    includeTicketed: undefined,
    backlogType: undefined,
    scaRuleKind: [],
  };
}

export const IssuesFilter = {
  encode(message: IssuesFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.ids) {
      writer.uint64(v);
    }
    writer.ldelim();
    if (message.since !== undefined) {
      Timestamp.encode(toTimestamp(message.since), writer.uint32(18).fork()).ldelim();
    }
    if (message.until !== undefined) {
      Timestamp.encode(toTimestamp(message.until), writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.repositoryNames) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.repositoryTags) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.refs) {
      writer.uint32(50).string(v!);
    }
    writer.uint32(58).fork();
    for (const v of message.repositoryRefIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    writer.uint32(66).fork();
    for (const v of message.aiVerdicts) {
      writer.int32(autotriage_VerdictToNumber(v));
    }
    writer.ldelim();
    for (const v of message.aiSensitivities) {
      writer.uint32(74).string(v!);
    }
    writer.uint32(82).fork();
    for (const v of message.status) {
      writer.int32(issueStatusToNumber(v));
    }
    writer.ldelim();
    if (message.tab !== undefined) {
      writer.uint32(88).int32(issueTabToNumber(message.tab));
    }
    for (const v of message.teamNames) {
      writer.uint32(98).string(v!);
    }
    writer.uint32(106).fork();
    for (const v of message.actions) {
      writer.int32(modeToNumber(v));
    }
    writer.ldelim();
    for (const v of message.policySlugs) {
      writer.uint32(114).string(v!);
    }
    for (const v of message.rules) {
      writer.uint32(122).string(v!);
    }
    writer.uint32(130).fork();
    for (const v of message.ruleIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    for (const v of message.rulesets) {
      writer.uint32(138).string(v!);
    }
    for (const v of message.categories) {
      writer.uint32(146).string(v!);
    }
    writer.uint32(154).fork();
    for (const v of message.confidences) {
      writer.int32(confidenceToNumber(v));
    }
    writer.ldelim();
    writer.uint32(162).fork();
    for (const v of message.severities) {
      writer.int32(severityToNumber(v));
    }
    writer.ldelim();
    writer.uint32(170).fork();
    for (const v of message.transitivities) {
      writer.int32(transitivityToNumber(v));
    }
    writer.ldelim();
    writer.uint32(178).fork();
    for (const v of message.exposures) {
      writer.int32(vuln_ExposureTypeToNumber(v));
    }
    writer.ldelim();
    for (const v of message.dependencies) {
      writer.uint32(186).string(v!);
    }
    for (const v of message.cves) {
      writer.uint32(194).string(v!);
    }
    writer.uint32(202).fork();
    for (const v of message.source) {
      writer.int32(sourceToNumber(v));
    }
    writer.ldelim();
    writer.uint32(210).fork();
    for (const v of message.reachabilities) {
      writer.int32(reachabilityToNumber(v));
    }
    writer.ldelim();
    writer.uint32(218).fork();
    for (const v of message.issueParentIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    writer.uint32(226).fork();
    for (const v of message.validationStates) {
      writer.int32(validationStateToNumber(v));
    }
    writer.ldelim();
    for (const v of message.secretTypes) {
      writer.uint32(234).string(v!);
    }
    writer.uint32(242).fork();
    for (const v of message.repoVisibilities) {
      writer.int32(repositoryVisibilityToNumber(v));
    }
    writer.ldelim();
    if (message.excludeHistorical !== undefined) {
      writer.uint32(248).bool(message.excludeHistorical);
    }
    if (message.onPrimaryBranch !== undefined) {
      writer.uint32(256).bool(message.onPrimaryBranch);
    }
    writer.uint32(266).fork();
    for (const v of message.epssProbabilities) {
      writer.int32(epssProbabilityToNumber(v));
    }
    writer.ldelim();
    writer.uint32(274).fork();
    for (const v of message.triageReasons) {
      writer.int32(findingTriageReasonToNumber(v));
    }
    writer.ldelim();
    if (message.timeFilter !== undefined) {
      writer.uint32(280).int32(timeFilterToNumber(message.timeFilter));
    }
    if (message.includeTicketed !== undefined) {
      writer.uint32(288).bool(message.includeTicketed);
    }
    if (message.backlogType !== undefined) {
      writer.uint32(296).int32(issueBacklogTypeToNumber(message.backlogType));
    }
    writer.uint32(306).fork();
    for (const v of message.scaRuleKind) {
      writer.int32(scaRuleKindToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssuesFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssuesFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.ids.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.ids.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.since = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.until = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.repositoryNames.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.repositoryTags.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.refs.push(reader.string());
          continue;
        case 7:
          if (tag === 56) {
            message.repositoryRefIds.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.repositoryRefIds.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 8:
          if (tag === 64) {
            message.aiVerdicts.push(autotriage_VerdictFromJSON(reader.int32()));

            continue;
          }

          if (tag === 66) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.aiVerdicts.push(autotriage_VerdictFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.aiSensitivities.push(reader.string());
          continue;
        case 10:
          if (tag === 80) {
            message.status.push(issueStatusFromJSON(reader.int32()));

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.status.push(issueStatusFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.tab = issueTabFromJSON(reader.int32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.teamNames.push(reader.string());
          continue;
        case 13:
          if (tag === 104) {
            message.actions.push(modeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 106) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.actions.push(modeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.policySlugs.push(reader.string());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.rules.push(reader.string());
          continue;
        case 16:
          if (tag === 128) {
            message.ruleIds.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 130) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.ruleIds.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.rulesets.push(reader.string());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.categories.push(reader.string());
          continue;
        case 19:
          if (tag === 152) {
            message.confidences.push(confidenceFromJSON(reader.int32()));

            continue;
          }

          if (tag === 154) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.confidences.push(confidenceFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 20:
          if (tag === 160) {
            message.severities.push(severityFromJSON(reader.int32()));

            continue;
          }

          if (tag === 162) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.severities.push(severityFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 21:
          if (tag === 168) {
            message.transitivities.push(transitivityFromJSON(reader.int32()));

            continue;
          }

          if (tag === 170) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.transitivities.push(transitivityFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 22:
          if (tag === 176) {
            message.exposures.push(vuln_ExposureTypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 178) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exposures.push(vuln_ExposureTypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.dependencies.push(reader.string());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.cves.push(reader.string());
          continue;
        case 25:
          if (tag === 200) {
            message.source.push(sourceFromJSON(reader.int32()));

            continue;
          }

          if (tag === 202) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.source.push(sourceFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 26:
          if (tag === 208) {
            message.reachabilities.push(reachabilityFromJSON(reader.int32()));

            continue;
          }

          if (tag === 210) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.reachabilities.push(reachabilityFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 27:
          if (tag === 216) {
            message.issueParentIds.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 218) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.issueParentIds.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 28:
          if (tag === 224) {
            message.validationStates.push(validationStateFromJSON(reader.int32()));

            continue;
          }

          if (tag === 226) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.validationStates.push(validationStateFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.secretTypes.push(reader.string());
          continue;
        case 30:
          if (tag === 240) {
            message.repoVisibilities.push(repositoryVisibilityFromJSON(reader.int32()));

            continue;
          }

          if (tag === 242) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.repoVisibilities.push(repositoryVisibilityFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.excludeHistorical = reader.bool();
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.onPrimaryBranch = reader.bool();
          continue;
        case 33:
          if (tag === 264) {
            message.epssProbabilities.push(epssProbabilityFromJSON(reader.int32()));

            continue;
          }

          if (tag === 266) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.epssProbabilities.push(epssProbabilityFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 34:
          if (tag === 272) {
            message.triageReasons.push(findingTriageReasonFromJSON(reader.int32()));

            continue;
          }

          if (tag === 274) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.triageReasons.push(findingTriageReasonFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.timeFilter = timeFilterFromJSON(reader.int32());
          continue;
        case 36:
          if (tag !== 288) {
            break;
          }

          message.includeTicketed = reader.bool();
          continue;
        case 37:
          if (tag !== 296) {
            break;
          }

          message.backlogType = issueBacklogTypeFromJSON(reader.int32());
          continue;
        case 38:
          if (tag === 304) {
            message.scaRuleKind.push(scaRuleKindFromJSON(reader.int32()));

            continue;
          }

          if (tag === 306) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.scaRuleKind.push(scaRuleKindFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssuesFilter {
    return {
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.Number(e)) : [],
      since: isSet(object.since) ? fromJsonTimestamp(object.since) : undefined,
      until: isSet(object.until) ? fromJsonTimestamp(object.until) : undefined,
      repositoryNames: globalThis.Array.isArray(object?.repositoryNames)
        ? object.repositoryNames.map((e: any) => globalThis.String(e))
        : [],
      repositoryTags: globalThis.Array.isArray(object?.repositoryTags)
        ? object.repositoryTags.map((e: any) => globalThis.String(e))
        : [],
      refs: globalThis.Array.isArray(object?.refs) ? object.refs.map((e: any) => globalThis.String(e)) : [],
      repositoryRefIds: globalThis.Array.isArray(object?.repositoryRefIds)
        ? object.repositoryRefIds.map((e: any) => globalThis.Number(e))
        : [],
      aiVerdicts: globalThis.Array.isArray(object?.aiVerdicts)
        ? object.aiVerdicts.map((e: any) => autotriage_VerdictFromJSON(e))
        : [],
      aiSensitivities: globalThis.Array.isArray(object?.aiSensitivities)
        ? object.aiSensitivities.map((e: any) => globalThis.String(e))
        : [],
      status: globalThis.Array.isArray(object?.status)
        ? object.status.map((e: any) => issueStatusFromJSON(e))
        : [],
      tab: isSet(object.tab) ? issueTabFromJSON(object.tab) : undefined,
      teamNames: globalThis.Array.isArray(object?.teamNames)
        ? object.teamNames.map((e: any) => globalThis.String(e))
        : [],
      actions: globalThis.Array.isArray(object?.actions)
        ? object.actions.map((e: any) => modeFromJSON(e))
        : [],
      policySlugs: globalThis.Array.isArray(object?.policySlugs)
        ? object.policySlugs.map((e: any) => globalThis.String(e))
        : [],
      rules: globalThis.Array.isArray(object?.rules)
        ? object.rules.map((e: any) => globalThis.String(e))
        : [],
      ruleIds: globalThis.Array.isArray(object?.ruleIds) ? object.ruleIds.map((e: any) => globalThis.Number(e)) : [],
      rulesets: globalThis.Array.isArray(object?.rulesets) ? object.rulesets.map((e: any) => globalThis.String(e)) : [],
      categories: globalThis.Array.isArray(object?.categories)
        ? object.categories.map((e: any) => globalThis.String(e))
        : [],
      confidences: globalThis.Array.isArray(object?.confidences)
        ? object.confidences.map((e: any) => confidenceFromJSON(e))
        : [],
      severities: globalThis.Array.isArray(object?.severities)
        ? object.severities.map((e: any) => severityFromJSON(e))
        : [],
      transitivities: globalThis.Array.isArray(object?.transitivities)
        ? object.transitivities.map((e: any) => transitivityFromJSON(e))
        : [],
      exposures: globalThis.Array.isArray(object?.exposures)
        ? object.exposures.map((e: any) => vuln_ExposureTypeFromJSON(e))
        : [],
      dependencies: globalThis.Array.isArray(object?.dependencies)
        ? object.dependencies.map((e: any) => globalThis.String(e))
        : [],
      cves: globalThis.Array.isArray(object?.cves)
        ? object.cves.map((e: any) => globalThis.String(e))
        : [],
      source: globalThis.Array.isArray(object?.source) ? object.source.map((e: any) => sourceFromJSON(e)) : [],
      reachabilities: globalThis.Array.isArray(object?.reachabilities)
        ? object.reachabilities.map((e: any) => reachabilityFromJSON(e))
        : [],
      issueParentIds: globalThis.Array.isArray(object?.issueParentIds)
        ? object.issueParentIds.map((e: any) => globalThis.Number(e))
        : [],
      validationStates: globalThis.Array.isArray(object?.validationStates)
        ? object.validationStates.map((e: any) => validationStateFromJSON(e))
        : [],
      secretTypes: globalThis.Array.isArray(object?.secretTypes)
        ? object.secretTypes.map((e: any) => globalThis.String(e))
        : [],
      repoVisibilities: globalThis.Array.isArray(object?.repoVisibilities)
        ? object.repoVisibilities.map((e: any) => repositoryVisibilityFromJSON(e))
        : [],
      excludeHistorical: isSet(object.excludeHistorical) ? globalThis.Boolean(object.excludeHistorical) : undefined,
      onPrimaryBranch: isSet(object.onPrimaryBranch) ? globalThis.Boolean(object.onPrimaryBranch) : undefined,
      epssProbabilities: globalThis.Array.isArray(object?.epssProbabilities)
        ? object.epssProbabilities.map((e: any) => epssProbabilityFromJSON(e))
        : [],
      triageReasons: globalThis.Array.isArray(object?.triageReasons)
        ? object.triageReasons.map((e: any) => findingTriageReasonFromJSON(e))
        : [],
      timeFilter: isSet(object.timeFilter) ? timeFilterFromJSON(object.timeFilter) : undefined,
      includeTicketed: isSet(object.includeTicketed) ? globalThis.Boolean(object.includeTicketed) : undefined,
      backlogType: isSet(object.backlogType) ? issueBacklogTypeFromJSON(object.backlogType) : undefined,
      scaRuleKind: globalThis.Array.isArray(object?.scaRuleKind)
        ? object.scaRuleKind.map((e: any) => scaRuleKindFromJSON(e))
        : [],
    };
  },

  toJSON(message: IssuesFilter): unknown {
    const obj: any = {};
    if (message.ids?.length) {
      obj.ids = message.ids.map((e) => Math.round(e));
    }
    if (message.since !== undefined) {
      obj.since = message.since.toISOString();
    }
    if (message.until !== undefined) {
      obj.until = message.until.toISOString();
    }
    if (message.repositoryNames?.length) {
      obj.repositoryNames = message.repositoryNames;
    }
    if (message.repositoryTags?.length) {
      obj.repositoryTags = message.repositoryTags;
    }
    if (message.refs?.length) {
      obj.refs = message.refs;
    }
    if (message.repositoryRefIds?.length) {
      obj.repositoryRefIds = message.repositoryRefIds.map((e) => Math.round(e));
    }
    if (message.aiVerdicts?.length) {
      obj.aiVerdicts = message.aiVerdicts.map((e) => autotriage_VerdictToJSON(e));
    }
    if (message.aiSensitivities?.length) {
      obj.aiSensitivities = message.aiSensitivities;
    }
    if (message.status?.length) {
      obj.status = message.status.map((e) => issueStatusToJSON(e));
    }
    if (message.tab !== undefined) {
      obj.tab = issueTabToJSON(message.tab);
    }
    if (message.teamNames?.length) {
      obj.teamNames = message.teamNames;
    }
    if (message.actions?.length) {
      obj.actions = message.actions.map((e) => modeToJSON(e));
    }
    if (message.policySlugs?.length) {
      obj.policySlugs = message.policySlugs;
    }
    if (message.rules?.length) {
      obj.rules = message.rules;
    }
    if (message.ruleIds?.length) {
      obj.ruleIds = message.ruleIds.map((e) => Math.round(e));
    }
    if (message.rulesets?.length) {
      obj.rulesets = message.rulesets;
    }
    if (message.categories?.length) {
      obj.categories = message.categories;
    }
    if (message.confidences?.length) {
      obj.confidences = message.confidences.map((e) => confidenceToJSON(e));
    }
    if (message.severities?.length) {
      obj.severities = message.severities.map((e) => severityToJSON(e));
    }
    if (message.transitivities?.length) {
      obj.transitivities = message.transitivities.map((e) => transitivityToJSON(e));
    }
    if (message.exposures?.length) {
      obj.exposures = message.exposures.map((e) => vuln_ExposureTypeToJSON(e));
    }
    if (message.dependencies?.length) {
      obj.dependencies = message.dependencies;
    }
    if (message.cves?.length) {
      obj.cves = message.cves;
    }
    if (message.source?.length) {
      obj.source = message.source.map((e) => sourceToJSON(e));
    }
    if (message.reachabilities?.length) {
      obj.reachabilities = message.reachabilities.map((e) => reachabilityToJSON(e));
    }
    if (message.issueParentIds?.length) {
      obj.issueParentIds = message.issueParentIds.map((e) => Math.round(e));
    }
    if (message.validationStates?.length) {
      obj.validationStates = message.validationStates.map((e) => validationStateToJSON(e));
    }
    if (message.secretTypes?.length) {
      obj.secretTypes = message.secretTypes;
    }
    if (message.repoVisibilities?.length) {
      obj.repoVisibilities = message.repoVisibilities.map((e) => repositoryVisibilityToJSON(e));
    }
    if (message.excludeHistorical !== undefined) {
      obj.excludeHistorical = message.excludeHistorical;
    }
    if (message.onPrimaryBranch !== undefined) {
      obj.onPrimaryBranch = message.onPrimaryBranch;
    }
    if (message.epssProbabilities?.length) {
      obj.epssProbabilities = message.epssProbabilities.map((e) => epssProbabilityToJSON(e));
    }
    if (message.triageReasons?.length) {
      obj.triageReasons = message.triageReasons.map((e) => findingTriageReasonToJSON(e));
    }
    if (message.timeFilter !== undefined) {
      obj.timeFilter = timeFilterToJSON(message.timeFilter);
    }
    if (message.includeTicketed !== undefined) {
      obj.includeTicketed = message.includeTicketed;
    }
    if (message.backlogType !== undefined) {
      obj.backlogType = issueBacklogTypeToJSON(message.backlogType);
    }
    if (message.scaRuleKind?.length) {
      obj.scaRuleKind = message.scaRuleKind.map((e) => scaRuleKindToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssuesFilter>, I>>(base?: I): IssuesFilter {
    return IssuesFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IssuesFilter>, I>>(object: I): IssuesFilter {
    const message = createBaseIssuesFilter();
    message.ids = object.ids?.map((e) => e) || [];
    message.since = object.since ?? undefined;
    message.until = object.until ?? undefined;
    message.repositoryNames = object.repositoryNames?.map((e) => e) || [];
    message.repositoryTags = object.repositoryTags?.map((e) => e) || [];
    message.refs = object.refs?.map((e) => e) || [];
    message.repositoryRefIds = object.repositoryRefIds?.map((e) => e) || [];
    message.aiVerdicts = object.aiVerdicts?.map((e) => e) || [];
    message.aiSensitivities = object.aiSensitivities?.map((e) => e) || [];
    message.status = object.status?.map((e) => e) || [];
    message.tab = object.tab ?? undefined;
    message.teamNames = object.teamNames?.map((e) => e) || [];
    message.actions = object.actions?.map((e) => e) || [];
    message.policySlugs = object.policySlugs?.map((e) => e) || [];
    message.rules = object.rules?.map((e) => e) || [];
    message.ruleIds = object.ruleIds?.map((e) => e) || [];
    message.rulesets = object.rulesets?.map((e) => e) || [];
    message.categories = object.categories?.map((e) => e) || [];
    message.confidences = object.confidences?.map((e) => e) || [];
    message.severities = object.severities?.map((e) => e) || [];
    message.transitivities = object.transitivities?.map((e) => e) || [];
    message.exposures = object.exposures?.map((e) => e) || [];
    message.dependencies = object.dependencies?.map((e) => e) || [];
    message.cves = object.cves?.map((e) => e) || [];
    message.source = object.source?.map((e) => e) || [];
    message.reachabilities = object.reachabilities?.map((e) => e) || [];
    message.issueParentIds = object.issueParentIds?.map((e) => e) || [];
    message.validationStates = object.validationStates?.map((e) => e) || [];
    message.secretTypes = object.secretTypes?.map((e) => e) || [];
    message.repoVisibilities = object.repoVisibilities?.map((e) => e) || [];
    message.excludeHistorical = object.excludeHistorical ?? undefined;
    message.onPrimaryBranch = object.onPrimaryBranch ?? undefined;
    message.epssProbabilities = object.epssProbabilities?.map((e) => e) || [];
    message.triageReasons = object.triageReasons?.map((e) => e) || [];
    message.timeFilter = object.timeFilter ?? undefined;
    message.includeTicketed = object.includeTicketed ?? undefined;
    message.backlogType = object.backlogType ?? undefined;
    message.scaRuleKind = object.scaRuleKind?.map((e) => e) || [];
    return message;
  },
};

function createBaseIssue(): Issue {
  return {
    id: 0,
    createdAt: undefined,
    ref: undefined,
    syntacticId: "",
    matchBasedId: "",
    ruleId: undefined,
    status: IssueStatus.UNSPECIFIED,
    repository: undefined,
    firstSeenScan: undefined,
    triageState: FindingTriageState.UNSPECIFIED,
    triageReason: undefined,
    relevantSince: undefined,
    aggregateState: AggregateIssueState.UNSPECIFIED,
    note: undefined,
    externalTicket: undefined,
    vulnGroupKey: undefined,
    isBlocking: undefined,
    autotriage: undefined,
    aiTags: undefined,
    lineOfCodeUrl: undefined,
    codeSnippet: undefined,
    dataflowTrace: undefined,
    commitUrl: undefined,
    activityHistory: [],
    relatedIssues: [],
    remediation: undefined,
    lastSeenScan: undefined,
    filePath: undefined,
    line: undefined,
    endLine: undefined,
    column: undefined,
    endColumn: undefined,
    severity: Severity.UNSPECIFIED,
    message: undefined,
    rulePath: "",
    confidence: undefined,
    ruleUrl: "",
    ruleReferences: [],
    ruleOrigin: undefined,
    ruleHashId: undefined,
    ruleset: undefined,
    policySlug: undefined,
    category: "",
    ruleSupersededBy: [],
    issueType: IssueType.UNSPECIFIED,
    issueParentId: 0,
    ticketAttempts: [],
    productAttributes: undefined,
    subcategories: [],
    refUrl: undefined,
    codeowners: [],
  };
}

export const Issue = {
  encode(message: Issue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.ref !== undefined) {
      writer.uint32(26).string(message.ref);
    }
    if (message.syntacticId !== "") {
      writer.uint32(34).string(message.syntacticId);
    }
    if (message.matchBasedId !== "") {
      writer.uint32(42).string(message.matchBasedId);
    }
    if (message.ruleId !== undefined) {
      writer.uint32(48).uint64(message.ruleId);
    }
    if (message.status !== IssueStatus.UNSPECIFIED) {
      writer.uint32(56).int32(issueStatusToNumber(message.status));
    }
    if (message.repository !== undefined) {
      Issue_Repository.encode(message.repository, writer.uint32(66).fork()).ldelim();
    }
    if (message.firstSeenScan !== undefined) {
      Issue_Scan.encode(message.firstSeenScan, writer.uint32(74).fork()).ldelim();
    }
    if (message.triageState !== FindingTriageState.UNSPECIFIED) {
      writer.uint32(80).int32(findingTriageStateToNumber(message.triageState));
    }
    if (message.triageReason !== undefined) {
      writer.uint32(88).int32(findingTriageReasonToNumber(message.triageReason));
    }
    if (message.relevantSince !== undefined) {
      Timestamp.encode(toTimestamp(message.relevantSince), writer.uint32(98).fork()).ldelim();
    }
    if (message.aggregateState !== AggregateIssueState.UNSPECIFIED) {
      writer.uint32(104).int32(aggregateIssueStateToNumber(message.aggregateState));
    }
    if (message.note !== undefined) {
      writer.uint32(114).string(message.note);
    }
    if (message.externalTicket !== undefined) {
      ExternalTicket.encode(message.externalTicket, writer.uint32(122).fork()).ldelim();
    }
    if (message.vulnGroupKey !== undefined) {
      writer.uint32(130).string(message.vulnGroupKey);
    }
    if (message.isBlocking !== undefined) {
      writer.uint32(136).bool(message.isBlocking);
    }
    if (message.autotriage !== undefined) {
      Autotriage.encode(message.autotriage, writer.uint32(146).fork()).ldelim();
    }
    if (message.aiTags !== undefined) {
      SensitivityTags.encode(message.aiTags, writer.uint32(154).fork()).ldelim();
    }
    if (message.lineOfCodeUrl !== undefined) {
      writer.uint32(162).string(message.lineOfCodeUrl);
    }
    if (message.codeSnippet !== undefined) {
      writer.uint32(170).string(message.codeSnippet);
    }
    if (message.dataflowTrace !== undefined) {
      Issue_DataflowTrace.encode(message.dataflowTrace, writer.uint32(178).fork()).ldelim();
    }
    if (message.commitUrl !== undefined) {
      writer.uint32(186).string(message.commitUrl);
    }
    for (const v of message.activityHistory) {
      Issue_ActivityHistoryEvent.encode(v!, writer.uint32(194).fork()).ldelim();
    }
    for (const v of message.relatedIssues) {
      Issue_RelatedIssue.encode(v!, writer.uint32(202).fork()).ldelim();
    }
    if (message.remediation !== undefined) {
      Remediation.encode(message.remediation, writer.uint32(210).fork()).ldelim();
    }
    if (message.lastSeenScan !== undefined) {
      Issue_Scan.encode(message.lastSeenScan, writer.uint32(218).fork()).ldelim();
    }
    if (message.filePath !== undefined) {
      writer.uint32(226).string(message.filePath);
    }
    if (message.line !== undefined) {
      writer.uint32(232).uint32(message.line);
    }
    if (message.endLine !== undefined) {
      writer.uint32(240).uint32(message.endLine);
    }
    if (message.column !== undefined) {
      writer.uint32(248).uint32(message.column);
    }
    if (message.endColumn !== undefined) {
      writer.uint32(256).uint32(message.endColumn);
    }
    if (message.severity !== Severity.UNSPECIFIED) {
      writer.uint32(264).int32(severityToNumber(message.severity));
    }
    if (message.message !== undefined) {
      writer.uint32(274).string(message.message);
    }
    if (message.rulePath !== "") {
      writer.uint32(282).string(message.rulePath);
    }
    if (message.confidence !== undefined) {
      writer.uint32(288).int32(confidenceToNumber(message.confidence));
    }
    if (message.ruleUrl !== "") {
      writer.uint32(298).string(message.ruleUrl);
    }
    for (const v of message.ruleReferences) {
      writer.uint32(306).string(v!);
    }
    if (message.ruleOrigin !== undefined) {
      writer.uint32(312).int32(ruleOriginToNumber(message.ruleOrigin));
    }
    if (message.ruleHashId !== undefined) {
      writer.uint32(322).string(message.ruleHashId);
    }
    if (message.ruleset !== undefined) {
      writer.uint32(330).string(message.ruleset);
    }
    if (message.policySlug !== undefined) {
      writer.uint32(338).string(message.policySlug);
    }
    if (message.category !== "") {
      writer.uint32(346).string(message.category);
    }
    for (const v of message.ruleSupersededBy) {
      SupersededRule.encode(v!, writer.uint32(354).fork()).ldelim();
    }
    if (message.issueType !== IssueType.UNSPECIFIED) {
      writer.uint32(360).int32(issueTypeToNumber(message.issueType));
    }
    if (message.issueParentId !== 0) {
      writer.uint32(376).uint64(message.issueParentId);
    }
    for (const v of message.ticketAttempts) {
      TicketAttempt.encode(v!, writer.uint32(386).fork()).ldelim();
    }
    switch (message.productAttributes?.$case) {
      case "sastAttributes":
        Issue_SastAttributes.encode(message.productAttributes.sastAttributes, writer.uint32(394).fork()).ldelim();
        break;
      case "scaAttributes":
        Issue_ScaAttributes.encode(message.productAttributes.scaAttributes, writer.uint32(402).fork()).ldelim();
        break;
      case "secretsAttributes":
        Issue_SecretsAttributes.encode(message.productAttributes.secretsAttributes, writer.uint32(410).fork()).ldelim();
        break;
    }
    for (const v of message.subcategories) {
      writer.uint32(418).string(v!);
    }
    if (message.refUrl !== undefined) {
      writer.uint32(426).string(message.refUrl);
    }
    for (const v of message.codeowners) {
      Codeowner.encode(v!, writer.uint32(434).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ref = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.syntacticId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.matchBasedId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.ruleId = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.status = issueStatusFromJSON(reader.int32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.repository = Issue_Repository.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.firstSeenScan = Issue_Scan.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.triageState = findingTriageStateFromJSON(reader.int32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.triageReason = findingTriageReasonFromJSON(reader.int32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.relevantSince = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.aggregateState = aggregateIssueStateFromJSON(reader.int32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.note = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.externalTicket = ExternalTicket.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.vulnGroupKey = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.isBlocking = reader.bool();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.autotriage = Autotriage.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.aiTags = SensitivityTags.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.lineOfCodeUrl = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.codeSnippet = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.dataflowTrace = Issue_DataflowTrace.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.commitUrl = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.activityHistory.push(Issue_ActivityHistoryEvent.decode(reader, reader.uint32()));
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.relatedIssues.push(Issue_RelatedIssue.decode(reader, reader.uint32()));
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.remediation = Remediation.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.lastSeenScan = Issue_Scan.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.filePath = reader.string();
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.line = reader.uint32();
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.endLine = reader.uint32();
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.column = reader.uint32();
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.endColumn = reader.uint32();
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.severity = severityFromJSON(reader.int32());
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.message = reader.string();
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.rulePath = reader.string();
          continue;
        case 36:
          if (tag !== 288) {
            break;
          }

          message.confidence = confidenceFromJSON(reader.int32());
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.ruleUrl = reader.string();
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.ruleReferences.push(reader.string());
          continue;
        case 39:
          if (tag !== 312) {
            break;
          }

          message.ruleOrigin = ruleOriginFromJSON(reader.int32());
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.ruleHashId = reader.string();
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.ruleset = reader.string();
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.policySlug = reader.string();
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.category = reader.string();
          continue;
        case 44:
          if (tag !== 354) {
            break;
          }

          message.ruleSupersededBy.push(SupersededRule.decode(reader, reader.uint32()));
          continue;
        case 45:
          if (tag !== 360) {
            break;
          }

          message.issueType = issueTypeFromJSON(reader.int32());
          continue;
        case 47:
          if (tag !== 376) {
            break;
          }

          message.issueParentId = longToNumber(reader.uint64() as Long);
          continue;
        case 48:
          if (tag !== 386) {
            break;
          }

          message.ticketAttempts.push(TicketAttempt.decode(reader, reader.uint32()));
          continue;
        case 49:
          if (tag !== 394) {
            break;
          }

          message.productAttributes = {
            $case: "sastAttributes",
            sastAttributes: Issue_SastAttributes.decode(reader, reader.uint32()),
          };
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.productAttributes = {
            $case: "scaAttributes",
            scaAttributes: Issue_ScaAttributes.decode(reader, reader.uint32()),
          };
          continue;
        case 51:
          if (tag !== 410) {
            break;
          }

          message.productAttributes = {
            $case: "secretsAttributes",
            secretsAttributes: Issue_SecretsAttributes.decode(reader, reader.uint32()),
          };
          continue;
        case 52:
          if (tag !== 418) {
            break;
          }

          message.subcategories.push(reader.string());
          continue;
        case 53:
          if (tag !== 426) {
            break;
          }

          message.refUrl = reader.string();
          continue;
        case 54:
          if (tag !== 434) {
            break;
          }

          message.codeowners.push(Codeowner.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      ref: isSet(object.ref) ? globalThis.String(object.ref) : undefined,
      syntacticId: isSet(object.syntacticId) ? globalThis.String(object.syntacticId) : "",
      matchBasedId: isSet(object.matchBasedId) ? globalThis.String(object.matchBasedId) : "",
      ruleId: isSet(object.ruleId) ? globalThis.Number(object.ruleId) : undefined,
      status: isSet(object.status) ? issueStatusFromJSON(object.status) : IssueStatus.UNSPECIFIED,
      repository: isSet(object.repository) ? Issue_Repository.fromJSON(object.repository) : undefined,
      firstSeenScan: isSet(object.firstSeenScan) ? Issue_Scan.fromJSON(object.firstSeenScan) : undefined,
      triageState: isSet(object.triageState)
        ? findingTriageStateFromJSON(object.triageState)
        : FindingTriageState.UNSPECIFIED,
      triageReason: isSet(object.triageReason) ? findingTriageReasonFromJSON(object.triageReason) : undefined,
      relevantSince: isSet(object.relevantSince) ? fromJsonTimestamp(object.relevantSince) : undefined,
      aggregateState: isSet(object.aggregateState)
        ? aggregateIssueStateFromJSON(object.aggregateState)
        : AggregateIssueState.UNSPECIFIED,
      note: isSet(object.note) ? globalThis.String(object.note) : undefined,
      externalTicket: isSet(object.externalTicket) ? ExternalTicket.fromJSON(object.externalTicket) : undefined,
      vulnGroupKey: isSet(object.vulnGroupKey) ? globalThis.String(object.vulnGroupKey) : undefined,
      isBlocking: isSet(object.isBlocking) ? globalThis.Boolean(object.isBlocking) : undefined,
      autotriage: isSet(object.autotriage) ? Autotriage.fromJSON(object.autotriage) : undefined,
      aiTags: isSet(object.aiTags) ? SensitivityTags.fromJSON(object.aiTags) : undefined,
      lineOfCodeUrl: isSet(object.lineOfCodeUrl) ? globalThis.String(object.lineOfCodeUrl) : undefined,
      codeSnippet: isSet(object.codeSnippet) ? globalThis.String(object.codeSnippet) : undefined,
      dataflowTrace: isSet(object.dataflowTrace) ? Issue_DataflowTrace.fromJSON(object.dataflowTrace) : undefined,
      commitUrl: isSet(object.commitUrl) ? globalThis.String(object.commitUrl) : undefined,
      activityHistory: globalThis.Array.isArray(object?.activityHistory)
        ? object.activityHistory.map((e: any) => Issue_ActivityHistoryEvent.fromJSON(e))
        : [],
      relatedIssues: globalThis.Array.isArray(object?.relatedIssues)
        ? object.relatedIssues.map((e: any) => Issue_RelatedIssue.fromJSON(e))
        : [],
      remediation: isSet(object.remediation) ? Remediation.fromJSON(object.remediation) : undefined,
      lastSeenScan: isSet(object.lastSeenScan) ? Issue_Scan.fromJSON(object.lastSeenScan) : undefined,
      filePath: isSet(object.filePath) ? globalThis.String(object.filePath) : undefined,
      line: isSet(object.line) ? globalThis.Number(object.line) : undefined,
      endLine: isSet(object.endLine) ? globalThis.Number(object.endLine) : undefined,
      column: isSet(object.column) ? globalThis.Number(object.column) : undefined,
      endColumn: isSet(object.endColumn) ? globalThis.Number(object.endColumn) : undefined,
      severity: isSet(object.severity) ? severityFromJSON(object.severity) : Severity.UNSPECIFIED,
      message: isSet(object.message) ? globalThis.String(object.message) : undefined,
      rulePath: isSet(object.rulePath) ? globalThis.String(object.rulePath) : "",
      confidence: isSet(object.confidence) ? confidenceFromJSON(object.confidence) : undefined,
      ruleUrl: isSet(object.ruleUrl) ? globalThis.String(object.ruleUrl) : "",
      ruleReferences: globalThis.Array.isArray(object?.ruleReferences)
        ? object.ruleReferences.map((e: any) => globalThis.String(e))
        : [],
      ruleOrigin: isSet(object.ruleOrigin) ? ruleOriginFromJSON(object.ruleOrigin) : undefined,
      ruleHashId: isSet(object.ruleHashId) ? globalThis.String(object.ruleHashId) : undefined,
      ruleset: isSet(object.ruleset) ? globalThis.String(object.ruleset) : undefined,
      policySlug: isSet(object.policySlug) ? globalThis.String(object.policySlug) : undefined,
      category: isSet(object.category) ? globalThis.String(object.category) : "",
      ruleSupersededBy: globalThis.Array.isArray(object?.ruleSupersededBy)
        ? object.ruleSupersededBy.map((e: any) => SupersededRule.fromJSON(e))
        : [],
      issueType: isSet(object.issueType) ? issueTypeFromJSON(object.issueType) : IssueType.UNSPECIFIED,
      issueParentId: isSet(object.issueParentId) ? globalThis.Number(object.issueParentId) : 0,
      ticketAttempts: globalThis.Array.isArray(object?.ticketAttempts)
        ? object.ticketAttempts.map((e: any) => TicketAttempt.fromJSON(e))
        : [],
      productAttributes: isSet(object.sastAttributes)
        ? { $case: "sastAttributes", sastAttributes: Issue_SastAttributes.fromJSON(object.sastAttributes) }
        : isSet(object.scaAttributes)
        ? { $case: "scaAttributes", scaAttributes: Issue_ScaAttributes.fromJSON(object.scaAttributes) }
        : isSet(object.secretsAttributes)
        ? { $case: "secretsAttributes", secretsAttributes: Issue_SecretsAttributes.fromJSON(object.secretsAttributes) }
        : undefined,
      subcategories: globalThis.Array.isArray(object?.subcategories)
        ? object.subcategories.map((e: any) => globalThis.String(e))
        : [],
      refUrl: isSet(object.refUrl) ? globalThis.String(object.refUrl) : undefined,
      codeowners: globalThis.Array.isArray(object?.codeowners)
        ? object.codeowners.map((e: any) => Codeowner.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Issue): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.ref !== undefined) {
      obj.ref = message.ref;
    }
    if (message.syntacticId !== "") {
      obj.syntacticId = message.syntacticId;
    }
    if (message.matchBasedId !== "") {
      obj.matchBasedId = message.matchBasedId;
    }
    if (message.ruleId !== undefined) {
      obj.ruleId = Math.round(message.ruleId);
    }
    if (message.status !== IssueStatus.UNSPECIFIED) {
      obj.status = issueStatusToJSON(message.status);
    }
    if (message.repository !== undefined) {
      obj.repository = Issue_Repository.toJSON(message.repository);
    }
    if (message.firstSeenScan !== undefined) {
      obj.firstSeenScan = Issue_Scan.toJSON(message.firstSeenScan);
    }
    if (message.triageState !== FindingTriageState.UNSPECIFIED) {
      obj.triageState = findingTriageStateToJSON(message.triageState);
    }
    if (message.triageReason !== undefined) {
      obj.triageReason = findingTriageReasonToJSON(message.triageReason);
    }
    if (message.relevantSince !== undefined) {
      obj.relevantSince = message.relevantSince.toISOString();
    }
    if (message.aggregateState !== AggregateIssueState.UNSPECIFIED) {
      obj.aggregateState = aggregateIssueStateToJSON(message.aggregateState);
    }
    if (message.note !== undefined) {
      obj.note = message.note;
    }
    if (message.externalTicket !== undefined) {
      obj.externalTicket = ExternalTicket.toJSON(message.externalTicket);
    }
    if (message.vulnGroupKey !== undefined) {
      obj.vulnGroupKey = message.vulnGroupKey;
    }
    if (message.isBlocking !== undefined) {
      obj.isBlocking = message.isBlocking;
    }
    if (message.autotriage !== undefined) {
      obj.autotriage = Autotriage.toJSON(message.autotriage);
    }
    if (message.aiTags !== undefined) {
      obj.aiTags = SensitivityTags.toJSON(message.aiTags);
    }
    if (message.lineOfCodeUrl !== undefined) {
      obj.lineOfCodeUrl = message.lineOfCodeUrl;
    }
    if (message.codeSnippet !== undefined) {
      obj.codeSnippet = message.codeSnippet;
    }
    if (message.dataflowTrace !== undefined) {
      obj.dataflowTrace = Issue_DataflowTrace.toJSON(message.dataflowTrace);
    }
    if (message.commitUrl !== undefined) {
      obj.commitUrl = message.commitUrl;
    }
    if (message.activityHistory?.length) {
      obj.activityHistory = message.activityHistory.map((e) => Issue_ActivityHistoryEvent.toJSON(e));
    }
    if (message.relatedIssues?.length) {
      obj.relatedIssues = message.relatedIssues.map((e) => Issue_RelatedIssue.toJSON(e));
    }
    if (message.remediation !== undefined) {
      obj.remediation = Remediation.toJSON(message.remediation);
    }
    if (message.lastSeenScan !== undefined) {
      obj.lastSeenScan = Issue_Scan.toJSON(message.lastSeenScan);
    }
    if (message.filePath !== undefined) {
      obj.filePath = message.filePath;
    }
    if (message.line !== undefined) {
      obj.line = Math.round(message.line);
    }
    if (message.endLine !== undefined) {
      obj.endLine = Math.round(message.endLine);
    }
    if (message.column !== undefined) {
      obj.column = Math.round(message.column);
    }
    if (message.endColumn !== undefined) {
      obj.endColumn = Math.round(message.endColumn);
    }
    if (message.severity !== Severity.UNSPECIFIED) {
      obj.severity = severityToJSON(message.severity);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    if (message.rulePath !== "") {
      obj.rulePath = message.rulePath;
    }
    if (message.confidence !== undefined) {
      obj.confidence = confidenceToJSON(message.confidence);
    }
    if (message.ruleUrl !== "") {
      obj.ruleUrl = message.ruleUrl;
    }
    if (message.ruleReferences?.length) {
      obj.ruleReferences = message.ruleReferences;
    }
    if (message.ruleOrigin !== undefined) {
      obj.ruleOrigin = ruleOriginToJSON(message.ruleOrigin);
    }
    if (message.ruleHashId !== undefined) {
      obj.ruleHashId = message.ruleHashId;
    }
    if (message.ruleset !== undefined) {
      obj.ruleset = message.ruleset;
    }
    if (message.policySlug !== undefined) {
      obj.policySlug = message.policySlug;
    }
    if (message.category !== "") {
      obj.category = message.category;
    }
    if (message.ruleSupersededBy?.length) {
      obj.ruleSupersededBy = message.ruleSupersededBy.map((e) => SupersededRule.toJSON(e));
    }
    if (message.issueType !== IssueType.UNSPECIFIED) {
      obj.issueType = issueTypeToJSON(message.issueType);
    }
    if (message.issueParentId !== 0) {
      obj.issueParentId = Math.round(message.issueParentId);
    }
    if (message.ticketAttempts?.length) {
      obj.ticketAttempts = message.ticketAttempts.map((e) => TicketAttempt.toJSON(e));
    }
    if (message.productAttributes?.$case === "sastAttributes") {
      obj.sastAttributes = Issue_SastAttributes.toJSON(message.productAttributes.sastAttributes);
    }
    if (message.productAttributes?.$case === "scaAttributes") {
      obj.scaAttributes = Issue_ScaAttributes.toJSON(message.productAttributes.scaAttributes);
    }
    if (message.productAttributes?.$case === "secretsAttributes") {
      obj.secretsAttributes = Issue_SecretsAttributes.toJSON(message.productAttributes.secretsAttributes);
    }
    if (message.subcategories?.length) {
      obj.subcategories = message.subcategories;
    }
    if (message.refUrl !== undefined) {
      obj.refUrl = message.refUrl;
    }
    if (message.codeowners?.length) {
      obj.codeowners = message.codeowners.map((e) => Codeowner.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue>, I>>(base?: I): Issue {
    return Issue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue>, I>>(object: I): Issue {
    const message = createBaseIssue();
    message.id = object.id ?? 0;
    message.createdAt = object.createdAt ?? undefined;
    message.ref = object.ref ?? undefined;
    message.syntacticId = object.syntacticId ?? "";
    message.matchBasedId = object.matchBasedId ?? "";
    message.ruleId = object.ruleId ?? undefined;
    message.status = object.status ?? IssueStatus.UNSPECIFIED;
    message.repository = (object.repository !== undefined && object.repository !== null)
      ? Issue_Repository.fromPartial(object.repository)
      : undefined;
    message.firstSeenScan = (object.firstSeenScan !== undefined && object.firstSeenScan !== null)
      ? Issue_Scan.fromPartial(object.firstSeenScan)
      : undefined;
    message.triageState = object.triageState ?? FindingTriageState.UNSPECIFIED;
    message.triageReason = object.triageReason ?? undefined;
    message.relevantSince = object.relevantSince ?? undefined;
    message.aggregateState = object.aggregateState ?? AggregateIssueState.UNSPECIFIED;
    message.note = object.note ?? undefined;
    message.externalTicket = (object.externalTicket !== undefined && object.externalTicket !== null)
      ? ExternalTicket.fromPartial(object.externalTicket)
      : undefined;
    message.vulnGroupKey = object.vulnGroupKey ?? undefined;
    message.isBlocking = object.isBlocking ?? undefined;
    message.autotriage = (object.autotriage !== undefined && object.autotriage !== null)
      ? Autotriage.fromPartial(object.autotriage)
      : undefined;
    message.aiTags = (object.aiTags !== undefined && object.aiTags !== null)
      ? SensitivityTags.fromPartial(object.aiTags)
      : undefined;
    message.lineOfCodeUrl = object.lineOfCodeUrl ?? undefined;
    message.codeSnippet = object.codeSnippet ?? undefined;
    message.dataflowTrace = (object.dataflowTrace !== undefined && object.dataflowTrace !== null)
      ? Issue_DataflowTrace.fromPartial(object.dataflowTrace)
      : undefined;
    message.commitUrl = object.commitUrl ?? undefined;
    message.activityHistory = object.activityHistory?.map((e) => Issue_ActivityHistoryEvent.fromPartial(e)) || [];
    message.relatedIssues = object.relatedIssues?.map((e) => Issue_RelatedIssue.fromPartial(e)) || [];
    message.remediation = (object.remediation !== undefined && object.remediation !== null)
      ? Remediation.fromPartial(object.remediation)
      : undefined;
    message.lastSeenScan = (object.lastSeenScan !== undefined && object.lastSeenScan !== null)
      ? Issue_Scan.fromPartial(object.lastSeenScan)
      : undefined;
    message.filePath = object.filePath ?? undefined;
    message.line = object.line ?? undefined;
    message.endLine = object.endLine ?? undefined;
    message.column = object.column ?? undefined;
    message.endColumn = object.endColumn ?? undefined;
    message.severity = object.severity ?? Severity.UNSPECIFIED;
    message.message = object.message ?? undefined;
    message.rulePath = object.rulePath ?? "";
    message.confidence = object.confidence ?? undefined;
    message.ruleUrl = object.ruleUrl ?? "";
    message.ruleReferences = object.ruleReferences?.map((e) => e) || [];
    message.ruleOrigin = object.ruleOrigin ?? undefined;
    message.ruleHashId = object.ruleHashId ?? undefined;
    message.ruleset = object.ruleset ?? undefined;
    message.policySlug = object.policySlug ?? undefined;
    message.category = object.category ?? "";
    message.ruleSupersededBy = object.ruleSupersededBy?.map((e) => SupersededRule.fromPartial(e)) || [];
    message.issueType = object.issueType ?? IssueType.UNSPECIFIED;
    message.issueParentId = object.issueParentId ?? 0;
    message.ticketAttempts = object.ticketAttempts?.map((e) => TicketAttempt.fromPartial(e)) || [];
    if (
      object.productAttributes?.$case === "sastAttributes" &&
      object.productAttributes?.sastAttributes !== undefined &&
      object.productAttributes?.sastAttributes !== null
    ) {
      message.productAttributes = {
        $case: "sastAttributes",
        sastAttributes: Issue_SastAttributes.fromPartial(object.productAttributes.sastAttributes),
      };
    }
    if (
      object.productAttributes?.$case === "scaAttributes" &&
      object.productAttributes?.scaAttributes !== undefined &&
      object.productAttributes?.scaAttributes !== null
    ) {
      message.productAttributes = {
        $case: "scaAttributes",
        scaAttributes: Issue_ScaAttributes.fromPartial(object.productAttributes.scaAttributes),
      };
    }
    if (
      object.productAttributes?.$case === "secretsAttributes" &&
      object.productAttributes?.secretsAttributes !== undefined &&
      object.productAttributes?.secretsAttributes !== null
    ) {
      message.productAttributes = {
        $case: "secretsAttributes",
        secretsAttributes: Issue_SecretsAttributes.fromPartial(object.productAttributes.secretsAttributes),
      };
    }
    message.subcategories = object.subcategories?.map((e) => e) || [];
    message.refUrl = object.refUrl ?? undefined;
    message.codeowners = object.codeowners?.map((e) => Codeowner.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIssue_DataflowTrace(): Issue_DataflowTrace {
  return { taintSource: [], intermediateVars: [], taintSink: [] };
}

export const Issue_DataflowTrace = {
  encode(message: Issue_DataflowTrace, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.taintSource) {
      Issue_DataflowTrace_Location.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.intermediateVars) {
      Issue_DataflowTrace_Location.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.taintSink) {
      Issue_DataflowTrace_Location.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_DataflowTrace {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_DataflowTrace();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.taintSource.push(Issue_DataflowTrace_Location.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.intermediateVars.push(Issue_DataflowTrace_Location.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.taintSink.push(Issue_DataflowTrace_Location.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue_DataflowTrace {
    return {
      taintSource: globalThis.Array.isArray(object?.taintSource)
        ? object.taintSource.map((e: any) => Issue_DataflowTrace_Location.fromJSON(e))
        : [],
      intermediateVars: globalThis.Array.isArray(object?.intermediateVars)
        ? object.intermediateVars.map((e: any) => Issue_DataflowTrace_Location.fromJSON(e))
        : [],
      taintSink: globalThis.Array.isArray(object?.taintSink)
        ? object.taintSink.map((e: any) => Issue_DataflowTrace_Location.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Issue_DataflowTrace): unknown {
    const obj: any = {};
    if (message.taintSource?.length) {
      obj.taintSource = message.taintSource.map((e) => Issue_DataflowTrace_Location.toJSON(e));
    }
    if (message.intermediateVars?.length) {
      obj.intermediateVars = message.intermediateVars.map((e) => Issue_DataflowTrace_Location.toJSON(e));
    }
    if (message.taintSink?.length) {
      obj.taintSink = message.taintSink.map((e) => Issue_DataflowTrace_Location.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_DataflowTrace>, I>>(base?: I): Issue_DataflowTrace {
    return Issue_DataflowTrace.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_DataflowTrace>, I>>(object: I): Issue_DataflowTrace {
    const message = createBaseIssue_DataflowTrace();
    message.taintSource = object.taintSource?.map((e) => Issue_DataflowTrace_Location.fromPartial(e)) || [];
    message.intermediateVars = object.intermediateVars?.map((e) => Issue_DataflowTrace_Location.fromPartial(e)) || [];
    message.taintSink = object.taintSink?.map((e) => Issue_DataflowTrace_Location.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIssue_DataflowTrace_Position(): Issue_DataflowTrace_Position {
  return { line: 0, col: 0, offset: 0 };
}

export const Issue_DataflowTrace_Position = {
  encode(message: Issue_DataflowTrace_Position, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.line !== 0) {
      writer.uint32(8).uint64(message.line);
    }
    if (message.col !== 0) {
      writer.uint32(16).uint64(message.col);
    }
    if (message.offset !== 0) {
      writer.uint32(24).uint64(message.offset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_DataflowTrace_Position {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_DataflowTrace_Position();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.line = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.col = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.offset = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue_DataflowTrace_Position {
    return {
      line: isSet(object.line) ? globalThis.Number(object.line) : 0,
      col: isSet(object.col) ? globalThis.Number(object.col) : 0,
      offset: isSet(object.offset) ? globalThis.Number(object.offset) : 0,
    };
  },

  toJSON(message: Issue_DataflowTrace_Position): unknown {
    const obj: any = {};
    if (message.line !== 0) {
      obj.line = Math.round(message.line);
    }
    if (message.col !== 0) {
      obj.col = Math.round(message.col);
    }
    if (message.offset !== 0) {
      obj.offset = Math.round(message.offset);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_DataflowTrace_Position>, I>>(base?: I): Issue_DataflowTrace_Position {
    return Issue_DataflowTrace_Position.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_DataflowTrace_Position>, I>>(object: I): Issue_DataflowTrace_Position {
    const message = createBaseIssue_DataflowTrace_Position();
    message.line = object.line ?? 0;
    message.col = object.col ?? 0;
    message.offset = object.offset ?? 0;
    return message;
  },
};

function createBaseIssue_DataflowTrace_Location(): Issue_DataflowTrace_Location {
  return { path: "", start: undefined, end: undefined, locationUrl: undefined };
}

export const Issue_DataflowTrace_Location = {
  encode(message: Issue_DataflowTrace_Location, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.path !== "") {
      writer.uint32(10).string(message.path);
    }
    if (message.start !== undefined) {
      Issue_DataflowTrace_Position.encode(message.start, writer.uint32(18).fork()).ldelim();
    }
    if (message.end !== undefined) {
      Issue_DataflowTrace_Position.encode(message.end, writer.uint32(26).fork()).ldelim();
    }
    if (message.locationUrl !== undefined) {
      writer.uint32(34).string(message.locationUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_DataflowTrace_Location {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_DataflowTrace_Location();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.path = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.start = Issue_DataflowTrace_Position.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.end = Issue_DataflowTrace_Position.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.locationUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue_DataflowTrace_Location {
    return {
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      start: isSet(object.start) ? Issue_DataflowTrace_Position.fromJSON(object.start) : undefined,
      end: isSet(object.end) ? Issue_DataflowTrace_Position.fromJSON(object.end) : undefined,
      locationUrl: isSet(object.locationUrl) ? globalThis.String(object.locationUrl) : undefined,
    };
  },

  toJSON(message: Issue_DataflowTrace_Location): unknown {
    const obj: any = {};
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.start !== undefined) {
      obj.start = Issue_DataflowTrace_Position.toJSON(message.start);
    }
    if (message.end !== undefined) {
      obj.end = Issue_DataflowTrace_Position.toJSON(message.end);
    }
    if (message.locationUrl !== undefined) {
      obj.locationUrl = message.locationUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_DataflowTrace_Location>, I>>(base?: I): Issue_DataflowTrace_Location {
    return Issue_DataflowTrace_Location.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_DataflowTrace_Location>, I>>(object: I): Issue_DataflowTrace_Location {
    const message = createBaseIssue_DataflowTrace_Location();
    message.path = object.path ?? "";
    message.start = (object.start !== undefined && object.start !== null)
      ? Issue_DataflowTrace_Position.fromPartial(object.start)
      : undefined;
    message.end = (object.end !== undefined && object.end !== null)
      ? Issue_DataflowTrace_Position.fromPartial(object.end)
      : undefined;
    message.locationUrl = object.locationUrl ?? undefined;
    return message;
  },
};

function createBaseIssue_ActivityHistoryEvent(): Issue_ActivityHistoryEvent {
  return {
    date: undefined,
    title: "",
    triageReason: undefined,
    note: "",
    actor: undefined,
    historyType: Issue_ActivityHistoryEvent_HistoryType.UNSPECIFIED,
  };
}

export const Issue_ActivityHistoryEvent = {
  encode(message: Issue_ActivityHistoryEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.date !== undefined) {
      Timestamp.encode(toTimestamp(message.date), writer.uint32(10).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.triageReason !== undefined) {
      writer.uint32(26).string(message.triageReason);
    }
    if (message.note !== "") {
      writer.uint32(34).string(message.note);
    }
    if (message.actor !== undefined) {
      writer.uint32(42).string(message.actor);
    }
    if (message.historyType !== Issue_ActivityHistoryEvent_HistoryType.UNSPECIFIED) {
      writer.uint32(48).int32(issue_ActivityHistoryEvent_HistoryTypeToNumber(message.historyType));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_ActivityHistoryEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_ActivityHistoryEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.date = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.triageReason = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.note = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.actor = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.historyType = issue_ActivityHistoryEvent_HistoryTypeFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue_ActivityHistoryEvent {
    return {
      date: isSet(object.date) ? fromJsonTimestamp(object.date) : undefined,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      triageReason: isSet(object.triageReason) ? globalThis.String(object.triageReason) : undefined,
      note: isSet(object.note) ? globalThis.String(object.note) : "",
      actor: isSet(object.actor) ? globalThis.String(object.actor) : undefined,
      historyType: isSet(object.historyType)
        ? issue_ActivityHistoryEvent_HistoryTypeFromJSON(object.historyType)
        : Issue_ActivityHistoryEvent_HistoryType.UNSPECIFIED,
    };
  },

  toJSON(message: Issue_ActivityHistoryEvent): unknown {
    const obj: any = {};
    if (message.date !== undefined) {
      obj.date = message.date.toISOString();
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.triageReason !== undefined) {
      obj.triageReason = message.triageReason;
    }
    if (message.note !== "") {
      obj.note = message.note;
    }
    if (message.actor !== undefined) {
      obj.actor = message.actor;
    }
    if (message.historyType !== Issue_ActivityHistoryEvent_HistoryType.UNSPECIFIED) {
      obj.historyType = issue_ActivityHistoryEvent_HistoryTypeToJSON(message.historyType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_ActivityHistoryEvent>, I>>(base?: I): Issue_ActivityHistoryEvent {
    return Issue_ActivityHistoryEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_ActivityHistoryEvent>, I>>(object: I): Issue_ActivityHistoryEvent {
    const message = createBaseIssue_ActivityHistoryEvent();
    message.date = object.date ?? undefined;
    message.title = object.title ?? "";
    message.triageReason = object.triageReason ?? undefined;
    message.note = object.note ?? "";
    message.actor = object.actor ?? undefined;
    message.historyType = object.historyType ?? Issue_ActivityHistoryEvent_HistoryType.UNSPECIFIED;
    return message;
  },
};

function createBaseIssue_RelatedIssue(): Issue_RelatedIssue {
  return { id: 0, ref: "", pullRequestId: "", aggregateState: AggregateIssueState.UNSPECIFIED };
}

export const Issue_RelatedIssue = {
  encode(message: Issue_RelatedIssue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.ref !== "") {
      writer.uint32(18).string(message.ref);
    }
    if (message.pullRequestId !== "") {
      writer.uint32(26).string(message.pullRequestId);
    }
    if (message.aggregateState !== AggregateIssueState.UNSPECIFIED) {
      writer.uint32(32).int32(aggregateIssueStateToNumber(message.aggregateState));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_RelatedIssue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_RelatedIssue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ref = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pullRequestId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.aggregateState = aggregateIssueStateFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue_RelatedIssue {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      ref: isSet(object.ref) ? globalThis.String(object.ref) : "",
      pullRequestId: isSet(object.pullRequestId) ? globalThis.String(object.pullRequestId) : "",
      aggregateState: isSet(object.aggregateState)
        ? aggregateIssueStateFromJSON(object.aggregateState)
        : AggregateIssueState.UNSPECIFIED,
    };
  },

  toJSON(message: Issue_RelatedIssue): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.ref !== "") {
      obj.ref = message.ref;
    }
    if (message.pullRequestId !== "") {
      obj.pullRequestId = message.pullRequestId;
    }
    if (message.aggregateState !== AggregateIssueState.UNSPECIFIED) {
      obj.aggregateState = aggregateIssueStateToJSON(message.aggregateState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_RelatedIssue>, I>>(base?: I): Issue_RelatedIssue {
    return Issue_RelatedIssue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_RelatedIssue>, I>>(object: I): Issue_RelatedIssue {
    const message = createBaseIssue_RelatedIssue();
    message.id = object.id ?? 0;
    message.ref = object.ref ?? "";
    message.pullRequestId = object.pullRequestId ?? "";
    message.aggregateState = object.aggregateState ?? AggregateIssueState.UNSPECIFIED;
    return message;
  },
};

function createBaseIssue_PrimaryRefItem(): Issue_PrimaryRefItem {
  return { id: 0, ref: "" };
}

export const Issue_PrimaryRefItem = {
  encode(message: Issue_PrimaryRefItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.ref !== "") {
      writer.uint32(18).string(message.ref);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_PrimaryRefItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_PrimaryRefItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ref = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue_PrimaryRefItem {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      ref: isSet(object.ref) ? globalThis.String(object.ref) : "",
    };
  },

  toJSON(message: Issue_PrimaryRefItem): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.ref !== "") {
      obj.ref = message.ref;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_PrimaryRefItem>, I>>(base?: I): Issue_PrimaryRefItem {
    return Issue_PrimaryRefItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_PrimaryRefItem>, I>>(object: I): Issue_PrimaryRefItem {
    const message = createBaseIssue_PrimaryRefItem();
    message.id = object.id ?? 0;
    message.ref = object.ref ?? "";
    return message;
  },
};

function createBaseIssue_Repository(): Issue_Repository {
  return { name: "", id: 0, type: ScmType.UNSPECIFIED, primaryRef: undefined };
}

export const Issue_Repository = {
  encode(message: Issue_Repository, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.id !== 0) {
      writer.uint32(16).uint64(message.id);
    }
    if (message.type !== ScmType.UNSPECIFIED) {
      writer.uint32(24).int32(scmTypeToNumber(message.type));
    }
    if (message.primaryRef !== undefined) {
      Issue_PrimaryRefItem.encode(message.primaryRef, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_Repository {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_Repository();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = scmTypeFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.primaryRef = Issue_PrimaryRefItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue_Repository {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      type: isSet(object.type) ? scmTypeFromJSON(object.type) : ScmType.UNSPECIFIED,
      primaryRef: isSet(object.primaryRef) ? Issue_PrimaryRefItem.fromJSON(object.primaryRef) : undefined,
    };
  },

  toJSON(message: Issue_Repository): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.type !== ScmType.UNSPECIFIED) {
      obj.type = scmTypeToJSON(message.type);
    }
    if (message.primaryRef !== undefined) {
      obj.primaryRef = Issue_PrimaryRefItem.toJSON(message.primaryRef);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_Repository>, I>>(base?: I): Issue_Repository {
    return Issue_Repository.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_Repository>, I>>(object: I): Issue_Repository {
    const message = createBaseIssue_Repository();
    message.name = object.name ?? "";
    message.id = object.id ?? 0;
    message.type = object.type ?? ScmType.UNSPECIFIED;
    message.primaryRef = (object.primaryRef !== undefined && object.primaryRef !== null)
      ? Issue_PrimaryRefItem.fromPartial(object.primaryRef)
      : undefined;
    return message;
  },
};

function createBaseIssue_Scan(): Issue_Scan {
  return { id: 0, meta: undefined };
}

export const Issue_Scan = {
  encode(message: Issue_Scan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.meta !== undefined) {
      Struct.encode(Struct.wrap(message.meta), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_Scan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_Scan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.meta = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue_Scan {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      meta: isObject(object.meta) ? object.meta : undefined,
    };
  },

  toJSON(message: Issue_Scan): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.meta !== undefined) {
      obj.meta = message.meta;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_Scan>, I>>(base?: I): Issue_Scan {
    return Issue_Scan.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_Scan>, I>>(object: I): Issue_Scan {
    const message = createBaseIssue_Scan();
    message.id = object.id ?? 0;
    message.meta = object.meta ?? undefined;
    return message;
  },
};

function createBaseIssue_SastAttributes(): Issue_SastAttributes {
  return {};
}

export const Issue_SastAttributes = {
  encode(_: Issue_SastAttributes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_SastAttributes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_SastAttributes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): Issue_SastAttributes {
    return {};
  },

  toJSON(_: Issue_SastAttributes): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_SastAttributes>, I>>(base?: I): Issue_SastAttributes {
    return Issue_SastAttributes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_SastAttributes>, I>>(_: I): Issue_SastAttributes {
    const message = createBaseIssue_SastAttributes();
    return message;
  },
};

function createBaseIssue_ScaAttributes(): Issue_ScaAttributes {
  return {
    severity: Severity.UNSPECIFIED,
    vulnDatabaseIdentifier: "",
    reachability: Reachability.UNSPECIFIED,
    reachableCondition: "",
    cwes: [],
    foundDependency: undefined,
    fixRecommendations: [],
    bestFix: undefined,
    epssScore: undefined,
    rulePublishDate: undefined,
  };
}

export const Issue_ScaAttributes = {
  encode(message: Issue_ScaAttributes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.severity !== Severity.UNSPECIFIED) {
      writer.uint32(8).int32(severityToNumber(message.severity));
    }
    if (message.vulnDatabaseIdentifier !== "") {
      writer.uint32(18).string(message.vulnDatabaseIdentifier);
    }
    if (message.reachability !== Reachability.UNSPECIFIED) {
      writer.uint32(24).int32(reachabilityToNumber(message.reachability));
    }
    if (message.reachableCondition !== "") {
      writer.uint32(34).string(message.reachableCondition);
    }
    for (const v of message.cwes) {
      writer.uint32(42).string(v!);
    }
    if (message.foundDependency !== undefined) {
      Issue_ScaAttributes_FoundDependency.encode(message.foundDependency, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.fixRecommendations) {
      Issue_ScaAttributes_FixRecommendation.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.bestFix !== undefined) {
      Issue_ScaAttributes_FixRecommendation.encode(message.bestFix, writer.uint32(66).fork()).ldelim();
    }
    if (message.epssScore !== undefined) {
      EpssScore.encode(message.epssScore, writer.uint32(74).fork()).ldelim();
    }
    if (message.rulePublishDate !== undefined) {
      Timestamp.encode(toTimestamp(message.rulePublishDate), writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_ScaAttributes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_ScaAttributes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.severity = severityFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vulnDatabaseIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.reachability = reachabilityFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.reachableCondition = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.cwes.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.foundDependency = Issue_ScaAttributes_FoundDependency.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fixRecommendations.push(Issue_ScaAttributes_FixRecommendation.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.bestFix = Issue_ScaAttributes_FixRecommendation.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.epssScore = EpssScore.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.rulePublishDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue_ScaAttributes {
    return {
      severity: isSet(object.severity) ? severityFromJSON(object.severity) : Severity.UNSPECIFIED,
      vulnDatabaseIdentifier: isSet(object.vulnDatabaseIdentifier)
        ? globalThis.String(object.vulnDatabaseIdentifier)
        : "",
      reachability: isSet(object.reachability) ? reachabilityFromJSON(object.reachability) : Reachability.UNSPECIFIED,
      reachableCondition: isSet(object.reachableCondition) ? globalThis.String(object.reachableCondition) : "",
      cwes: globalThis.Array.isArray(object?.cwes) ? object.cwes.map((e: any) => globalThis.String(e)) : [],
      foundDependency: isSet(object.foundDependency)
        ? Issue_ScaAttributes_FoundDependency.fromJSON(object.foundDependency)
        : undefined,
      fixRecommendations: globalThis.Array.isArray(object?.fixRecommendations)
        ? object.fixRecommendations.map((e: any) => Issue_ScaAttributes_FixRecommendation.fromJSON(e))
        : [],
      bestFix: isSet(object.bestFix) ? Issue_ScaAttributes_FixRecommendation.fromJSON(object.bestFix) : undefined,
      epssScore: isSet(object.epssScore) ? EpssScore.fromJSON(object.epssScore) : undefined,
      rulePublishDate: isSet(object.rulePublishDate) ? fromJsonTimestamp(object.rulePublishDate) : undefined,
    };
  },

  toJSON(message: Issue_ScaAttributes): unknown {
    const obj: any = {};
    if (message.severity !== Severity.UNSPECIFIED) {
      obj.severity = severityToJSON(message.severity);
    }
    if (message.vulnDatabaseIdentifier !== "") {
      obj.vulnDatabaseIdentifier = message.vulnDatabaseIdentifier;
    }
    if (message.reachability !== Reachability.UNSPECIFIED) {
      obj.reachability = reachabilityToJSON(message.reachability);
    }
    if (message.reachableCondition !== "") {
      obj.reachableCondition = message.reachableCondition;
    }
    if (message.cwes?.length) {
      obj.cwes = message.cwes;
    }
    if (message.foundDependency !== undefined) {
      obj.foundDependency = Issue_ScaAttributes_FoundDependency.toJSON(message.foundDependency);
    }
    if (message.fixRecommendations?.length) {
      obj.fixRecommendations = message.fixRecommendations.map((e) => Issue_ScaAttributes_FixRecommendation.toJSON(e));
    }
    if (message.bestFix !== undefined) {
      obj.bestFix = Issue_ScaAttributes_FixRecommendation.toJSON(message.bestFix);
    }
    if (message.epssScore !== undefined) {
      obj.epssScore = EpssScore.toJSON(message.epssScore);
    }
    if (message.rulePublishDate !== undefined) {
      obj.rulePublishDate = message.rulePublishDate.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_ScaAttributes>, I>>(base?: I): Issue_ScaAttributes {
    return Issue_ScaAttributes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_ScaAttributes>, I>>(object: I): Issue_ScaAttributes {
    const message = createBaseIssue_ScaAttributes();
    message.severity = object.severity ?? Severity.UNSPECIFIED;
    message.vulnDatabaseIdentifier = object.vulnDatabaseIdentifier ?? "";
    message.reachability = object.reachability ?? Reachability.UNSPECIFIED;
    message.reachableCondition = object.reachableCondition ?? "";
    message.cwes = object.cwes?.map((e) => e) || [];
    message.foundDependency = (object.foundDependency !== undefined && object.foundDependency !== null)
      ? Issue_ScaAttributes_FoundDependency.fromPartial(object.foundDependency)
      : undefined;
    message.fixRecommendations =
      object.fixRecommendations?.map((e) => Issue_ScaAttributes_FixRecommendation.fromPartial(e)) || [];
    message.bestFix = (object.bestFix !== undefined && object.bestFix !== null)
      ? Issue_ScaAttributes_FixRecommendation.fromPartial(object.bestFix)
      : undefined;
    message.epssScore = (object.epssScore !== undefined && object.epssScore !== null)
      ? EpssScore.fromPartial(object.epssScore)
      : undefined;
    message.rulePublishDate = object.rulePublishDate ?? undefined;
    return message;
  },
};

function createBaseIssue_ScaAttributes_FoundDependency(): Issue_ScaAttributes_FoundDependency {
  return {
    package: "",
    version: "",
    transitivity: Transitivity.UNKNOWN_TRANSITIVITY,
    lockfileLineUrl: undefined,
    filePath: "",
    ecosystem: Ecosystem.no_package_manager,
    manifestFilePath: undefined,
  };
}

export const Issue_ScaAttributes_FoundDependency = {
  encode(message: Issue_ScaAttributes_FoundDependency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.package !== "") {
      writer.uint32(10).string(message.package);
    }
    if (message.version !== "") {
      writer.uint32(18).string(message.version);
    }
    if (message.transitivity !== Transitivity.UNKNOWN_TRANSITIVITY) {
      writer.uint32(24).int32(transitivityToNumber(message.transitivity));
    }
    if (message.lockfileLineUrl !== undefined) {
      writer.uint32(34).string(message.lockfileLineUrl);
    }
    if (message.filePath !== "") {
      writer.uint32(42).string(message.filePath);
    }
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      writer.uint32(48).int32(ecosystemToNumber(message.ecosystem));
    }
    if (message.manifestFilePath !== undefined) {
      writer.uint32(58).string(message.manifestFilePath);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_ScaAttributes_FoundDependency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_ScaAttributes_FoundDependency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.package = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.version = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.transitivity = transitivityFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lockfileLineUrl = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.filePath = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.ecosystem = ecosystemFromJSON(reader.int32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.manifestFilePath = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue_ScaAttributes_FoundDependency {
    return {
      package: isSet(object.package) ? globalThis.String(object.package) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      transitivity: isSet(object.transitivity)
        ? transitivityFromJSON(object.transitivity)
        : Transitivity.UNKNOWN_TRANSITIVITY,
      lockfileLineUrl: isSet(object.lockfileLineUrl) ? globalThis.String(object.lockfileLineUrl) : undefined,
      filePath: isSet(object.filePath) ? globalThis.String(object.filePath) : "",
      ecosystem: isSet(object.ecosystem) ? ecosystemFromJSON(object.ecosystem) : Ecosystem.no_package_manager,
      manifestFilePath: isSet(object.manifestFilePath) ? globalThis.String(object.manifestFilePath) : undefined,
    };
  },

  toJSON(message: Issue_ScaAttributes_FoundDependency): unknown {
    const obj: any = {};
    if (message.package !== "") {
      obj.package = message.package;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.transitivity !== Transitivity.UNKNOWN_TRANSITIVITY) {
      obj.transitivity = transitivityToJSON(message.transitivity);
    }
    if (message.lockfileLineUrl !== undefined) {
      obj.lockfileLineUrl = message.lockfileLineUrl;
    }
    if (message.filePath !== "") {
      obj.filePath = message.filePath;
    }
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      obj.ecosystem = ecosystemToJSON(message.ecosystem);
    }
    if (message.manifestFilePath !== undefined) {
      obj.manifestFilePath = message.manifestFilePath;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_ScaAttributes_FoundDependency>, I>>(
    base?: I,
  ): Issue_ScaAttributes_FoundDependency {
    return Issue_ScaAttributes_FoundDependency.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_ScaAttributes_FoundDependency>, I>>(
    object: I,
  ): Issue_ScaAttributes_FoundDependency {
    const message = createBaseIssue_ScaAttributes_FoundDependency();
    message.package = object.package ?? "";
    message.version = object.version ?? "";
    message.transitivity = object.transitivity ?? Transitivity.UNKNOWN_TRANSITIVITY;
    message.lockfileLineUrl = object.lockfileLineUrl ?? undefined;
    message.filePath = object.filePath ?? "";
    message.ecosystem = object.ecosystem ?? Ecosystem.no_package_manager;
    message.manifestFilePath = object.manifestFilePath ?? undefined;
    return message;
  },
};

function createBaseIssue_ScaAttributes_FixRecommendation(): Issue_ScaAttributes_FixRecommendation {
  return { package: "", version: "" };
}

export const Issue_ScaAttributes_FixRecommendation = {
  encode(message: Issue_ScaAttributes_FixRecommendation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.package !== "") {
      writer.uint32(10).string(message.package);
    }
    if (message.version !== "") {
      writer.uint32(18).string(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_ScaAttributes_FixRecommendation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_ScaAttributes_FixRecommendation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.package = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.version = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue_ScaAttributes_FixRecommendation {
    return {
      package: isSet(object.package) ? globalThis.String(object.package) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
    };
  },

  toJSON(message: Issue_ScaAttributes_FixRecommendation): unknown {
    const obj: any = {};
    if (message.package !== "") {
      obj.package = message.package;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_ScaAttributes_FixRecommendation>, I>>(
    base?: I,
  ): Issue_ScaAttributes_FixRecommendation {
    return Issue_ScaAttributes_FixRecommendation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_ScaAttributes_FixRecommendation>, I>>(
    object: I,
  ): Issue_ScaAttributes_FixRecommendation {
    const message = createBaseIssue_ScaAttributes_FixRecommendation();
    message.package = object.package ?? "";
    message.version = object.version ?? "";
    return message;
  },
};

function createBaseIssue_SecretsAttributes(): Issue_SecretsAttributes {
  return { validationState: ValidationState.UNSPECIFIED, secretType: "", historicalInfo: undefined };
}

export const Issue_SecretsAttributes = {
  encode(message: Issue_SecretsAttributes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.validationState !== ValidationState.UNSPECIFIED) {
      writer.uint32(8).int32(validationStateToNumber(message.validationState));
    }
    if (message.secretType !== "") {
      writer.uint32(18).string(message.secretType);
    }
    if (message.historicalInfo !== undefined) {
      HistoricalInfo.encode(message.historicalInfo, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_SecretsAttributes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_SecretsAttributes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.validationState = validationStateFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.secretType = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.historicalInfo = HistoricalInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue_SecretsAttributes {
    return {
      validationState: isSet(object.validationState)
        ? validationStateFromJSON(object.validationState)
        : ValidationState.UNSPECIFIED,
      secretType: isSet(object.secretType) ? globalThis.String(object.secretType) : "",
      historicalInfo: isSet(object.historicalInfo) ? HistoricalInfo.fromJSON(object.historicalInfo) : undefined,
    };
  },

  toJSON(message: Issue_SecretsAttributes): unknown {
    const obj: any = {};
    if (message.validationState !== ValidationState.UNSPECIFIED) {
      obj.validationState = validationStateToJSON(message.validationState);
    }
    if (message.secretType !== "") {
      obj.secretType = message.secretType;
    }
    if (message.historicalInfo !== undefined) {
      obj.historicalInfo = HistoricalInfo.toJSON(message.historicalInfo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_SecretsAttributes>, I>>(base?: I): Issue_SecretsAttributes {
    return Issue_SecretsAttributes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_SecretsAttributes>, I>>(object: I): Issue_SecretsAttributes {
    const message = createBaseIssue_SecretsAttributes();
    message.validationState = object.validationState ?? ValidationState.UNSPECIFIED;
    message.secretType = object.secretType ?? "";
    message.historicalInfo = (object.historicalInfo !== undefined && object.historicalInfo !== null)
      ? HistoricalInfo.fromPartial(object.historicalInfo)
      : undefined;
    return message;
  },
};

function createBaseIssue_TriageAttributes(): Issue_TriageAttributes {
  return { triagedBy: "", triagedAt: undefined, source: TriageSource.UNSPECIFIED };
}

export const Issue_TriageAttributes = {
  encode(message: Issue_TriageAttributes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.triagedBy !== "") {
      writer.uint32(10).string(message.triagedBy);
    }
    if (message.triagedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.triagedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.source !== TriageSource.UNSPECIFIED) {
      writer.uint32(24).int32(triageSourceToNumber(message.source));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Issue_TriageAttributes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssue_TriageAttributes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.triagedBy = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.triagedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.source = triageSourceFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Issue_TriageAttributes {
    return {
      triagedBy: isSet(object.triagedBy) ? globalThis.String(object.triagedBy) : "",
      triagedAt: isSet(object.triagedAt) ? fromJsonTimestamp(object.triagedAt) : undefined,
      source: isSet(object.source) ? triageSourceFromJSON(object.source) : TriageSource.UNSPECIFIED,
    };
  },

  toJSON(message: Issue_TriageAttributes): unknown {
    const obj: any = {};
    if (message.triagedBy !== "") {
      obj.triagedBy = message.triagedBy;
    }
    if (message.triagedAt !== undefined) {
      obj.triagedAt = message.triagedAt.toISOString();
    }
    if (message.source !== TriageSource.UNSPECIFIED) {
      obj.source = triageSourceToJSON(message.source);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Issue_TriageAttributes>, I>>(base?: I): Issue_TriageAttributes {
    return Issue_TriageAttributes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Issue_TriageAttributes>, I>>(object: I): Issue_TriageAttributes {
    const message = createBaseIssue_TriageAttributes();
    message.triagedBy = object.triagedBy ?? "";
    message.triagedAt = object.triagedAt ?? undefined;
    message.source = object.source ?? TriageSource.UNSPECIFIED;
    return message;
  },
};

export interface IssuesService {
  /**
   * returns Issue instead of a GetIssueResponse since returning the resource directly (instead of wrapping it) is a standard practice for GET requests: https://google.aip.dev/131
   * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
   */
  getIssue(request: GetIssueRequest): Promise<Issue>;
  listIssues(request: ListIssuesRequest): Promise<ListIssuesResponse>;
  getIssueCounts(request: GetIssueCountsRequest): Promise<GetIssueCountsResponse>;
  getIssueFilters(request: GetIssueFiltersRequest): Promise<GetIssueFiltersResponse>;
  listIssueGroups(request: ListIssueGroupsRequest): Promise<ListIssueGroupsResponse>;
}

export const IssuesServiceServiceName = "protos.issues.v1.IssuesService";
export class IssuesServiceClientImpl implements IssuesService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || IssuesServiceServiceName;
    this.rpc = rpc;
    this.getIssue = this.getIssue.bind(this);
    this.listIssues = this.listIssues.bind(this);
    this.getIssueCounts = this.getIssueCounts.bind(this);
    this.getIssueFilters = this.getIssueFilters.bind(this);
    this.listIssueGroups = this.listIssueGroups.bind(this);
  }
  getIssue(request: GetIssueRequest): Promise<Issue> {
    const data = GetIssueRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetIssue", data);
    return promise.then((data) => Issue.decode(_m0.Reader.create(data)));
  }

  listIssues(request: ListIssuesRequest): Promise<ListIssuesResponse> {
    const data = ListIssuesRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListIssues", data);
    return promise.then((data) => ListIssuesResponse.decode(_m0.Reader.create(data)));
  }

  getIssueCounts(request: GetIssueCountsRequest): Promise<GetIssueCountsResponse> {
    const data = GetIssueCountsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetIssueCounts", data);
    return promise.then((data) => GetIssueCountsResponse.decode(_m0.Reader.create(data)));
  }

  getIssueFilters(request: GetIssueFiltersRequest): Promise<GetIssueFiltersResponse> {
    const data = GetIssueFiltersRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetIssueFilters", data);
    return promise.then((data) => GetIssueFiltersResponse.decode(_m0.Reader.create(data)));
  }

  listIssueGroups(request: ListIssueGroupsRequest): Promise<ListIssueGroupsResponse> {
    const data = ListIssueGroupsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListIssueGroups", data);
    return promise.then((data) => ListIssueGroupsResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
