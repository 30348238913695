import YAML from "yaml";

import {
  MetavariableConstraintPatternComponent,
  SearchPatternComponent,
  SimpleRule,
} from "@shared/types";

const DEFAULT_YAML_INDENT = 2;

const isYamlReservedWord = (value: string) => {
  const reservedYamlRegex =
    /^(y|Y|yes|Yes|YES|n|N|no|No|NO|true|True|TRUE|false|False|FALSE|on|On|ON|off|Off|OFF)$/g;
  return reservedYamlRegex.test(value);
};

const doesNotNeedPipeQuoting = (value: string) => {
  // see https://github.com/semgrep/semgrep-app/pull/3066 for discussion
  // rather than trying to handle all possible YAML spec quoting rules, we just parse the value
  // with and without quoting to see if we get the same result.

  // N.B.: Even if a value is already quoted with " or ', we want to make sure it is
  // pipe quoted. Because key: "hello" is different from key: |\n    "hello".
  const MAX_COLUMNS = 100;
  if (isYamlReservedWord(value)) {
    // these values will not be interpreted by yaml as strings, so we need to use pipe quoting
    return false;
  }
  if (value.split("\n").length > 1 || value.length > MAX_COLUMNS) {
    return false;
  }
  try {
    const with_injection = YAML.stringify(YAML.parse(`fakekey: ${value}`));
    const with_quoting = YAML.stringify({ fakekey: value });
    return with_injection === with_quoting;
  } catch (e) {
    console.warn(
      `expected to be able to parse a YAML pattern containing ${value} but exception: ${e}`
    );
    return false;
  }
};

const makeYamlKeyMultilineOrSingle = (
  keyname: string,
  value: string,
  prefixHyphen: boolean,
  depth: number,
  forceNoPipe?: boolean
) => {
  const prefix = prefixHyphen ? "- " : "";
  if (doesNotNeedPipeQuoting(value)) {
    return `${spaces(depth)}${prefix}${keyname}: ${value}`;
  }
  const optionalPipeChar = forceNoPipe ? "" : "|";

  const indentedValue = value
    .split("\n")
    .map((line) => `${spaces(depth + DEFAULT_YAML_INDENT * 2)}${line}`)
    .join("\n");
  return `${spaces(depth)}${prefix}${keyname}: ${optionalPipeChar}
${indentedValue}`;
};

const makeYamlKeyArray = (keyname: string, values: string[], depth: number) => {
  return `${spaces(depth)}${keyname}: [${values.join(", ")}]`;
};

const addOptionalYamlKey = (key: string, value: string, depth: number) => {
  if (value === "") return "";
  return makeYamlKeyMultilineOrSingle(key, value, false, depth) + "\n";
};

const hasValues = (obj: Object) =>
  Object.values(obj).some((v) => v !== null && typeof v !== "undefined");

const addOptionalYamlObject = (
  key: string,
  value: Object | any,
  depth: number
) => {
  if (value === undefined || !hasValues(value)) return "";
  const doc = new YAML.Document();
  doc.contents = value;
  const yamlStr = doc.toString();
  return makeYamlKeyMultilineOrSingle(key, yamlStr, false, depth, true);
};

export const yamlFromRule = (
  rule: SimpleRule,
  isSingleRule: boolean = false
) => {
  return isSingleRule ? YamlFromSinglerule(rule) : YamlFromMultirule(rule);
};

const spaces = (n: number) => {
  return " ".repeat(n);
};

const yamlFromRulePattern = (
  pattern: MetavariableConstraintPatternComponent | SearchPatternComponent,
  prefixHyphen: boolean,
  depth: number
): string => {
  if (pattern.type === "metavariable-regex") {
    const mpattern = pattern as MetavariableConstraintPatternComponent;
    const subkeyDepth = depth + DEFAULT_YAML_INDENT * 2;
    return `${spaces(depth)}- metavariable-regex:
${makeYamlKeyMultilineOrSingle(
  "metavariable",
  mpattern.metavariable,
  false,
  subkeyDepth
)}
${makeYamlKeyMultilineOrSingle(
  "regex",
  mpattern.constraint,
  false,
  subkeyDepth
)}`;
  } else {
    const spattern = pattern as SearchPatternComponent;
    return `${makeYamlKeyMultilineOrSingle(
      spattern.type,
      spattern.pattern,
      prefixHyphen,
      depth
    )}`;
  }
};

const makePatternEither = (
  pattern_keys: (
    | SearchPatternComponent
    | MetavariableConstraintPatternComponent
  )[]
) => {
  return `- pattern-either:
${pattern_keys
  .map((k) => yamlFromRulePattern(k, true, DEFAULT_YAML_INDENT * 3))
  .join("\n")}`;
};

function makeYamlPatterns(rule: SimpleRule, indent: number) {
  let orpatterns = "";

  // collect all the simple `pattern` patterns, they need to be or'd if there is more than one
  let allpatterns = "";
  const pattern_keys = rule.patterns.filter(
    (pattern) => pattern.type === "pattern"
  );
  if (rule.patterns.length === 1) {
    allpatterns = yamlFromRulePattern(rule.patterns[0], false, indent);
  } else if (pattern_keys.length > 1) {
    orpatterns = makePatternEither(pattern_keys);
    const non_pattern_keys = rule.patterns.filter(
      (pattern) => pattern.type !== "pattern"
    );
    const other_patterns = non_pattern_keys
      .map((k) => yamlFromRulePattern(k, true, indent ? indent * 2 : 2))
      .join("\n");
    allpatterns = `${" ".repeat(indent)}patterns:
    ${orpatterns}${other_patterns.length > 0 ? "\n" : ""}${other_patterns}`;
  } else {
    // in the simple cases, there's no OR key, so everything is flat. All other patterns are implicitly anded.
    allpatterns = `${" ".repeat(indent)}patterns:
${rule.patterns
  .map((k) => yamlFromRulePattern(k, true, indent ? indent * 2 : 2))
  .join("\n")}`;
  }
  return allpatterns;
}

const YamlFromMultirule = (rule: SimpleRule) => {
  const allpatterns = makeYamlPatterns(rule, DEFAULT_YAML_INDENT);

  let yamlStr = `rules:
- id: ${rule.id}
${allpatterns}
${makeYamlKeyMultilineOrSingle(
  "message",
  rule.message,
  false,
  DEFAULT_YAML_INDENT
)}
${makeYamlKeyArray("languages", rule.languages, DEFAULT_YAML_INDENT)}
${makeYamlKeyMultilineOrSingle(
  "severity",
  rule.severity,
  false,
  DEFAULT_YAML_INDENT
)}
`;
  yamlStr += addOptionalYamlKey("fix", rule.fix, DEFAULT_YAML_INDENT);
  yamlStr += addOptionalYamlObject(
    "metadata",
    rule.metadata,
    DEFAULT_YAML_INDENT
  );
  return yamlStr;
};

const YamlFromSinglerule = (rule: SimpleRule) => {
  const allpatterns = makeYamlPatterns(rule, 0);

  let yamlStr = `rules:
  - id: ${rule.id}
    ${allpatterns}
    ${makeYamlKeyMultilineOrSingle("message", rule.message, false, 0)}
    ${makeYamlKeyArray("languages", rule.languages, 0)}
    ${makeYamlKeyMultilineOrSingle("severity", rule.severity, false, 0)}
`;
  yamlStr += addOptionalYamlKey("fix", rule.fix, 0);
  yamlStr += addOptionalYamlObject("metadata", rule.metadata, 0);
  return yamlStr;
};
