import React from "react";
import styled from "styled-components";

import { Spinner } from "../LoadingScreen";

const Container = styled.section`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LoadingView: React.FC = () => (
  <Container>
    <Spinner size={60} />
    <div className="loading-text-m">Semgrep AppSec Platform is loading</div>
  </Container>
);
