import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Center, Stack, Text, Title } from "@mantine/core";

export const EditorQueryConsoleNotAvailable = () => {
  return (
    <Center mx="xl" h="100%">
      <Stack align="center">
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          size="6x"
          color="var(--mantine-color-blue-1)"
        />
        <Title order={2} ta="center">
          Search across your GitHub repositories
        </Title>
        <Text c="dimmed" mx="xl" ta="center">
          Take rule editing to the next level by viewing findings for a rule
          from any of your GitHub repositories. Upgrade your plan to get access.
        </Text>
        <Button component="a" href="https://semgrep.dev/contact-us">
          Contact sales
        </Button>
      </Stack>
    </Center>
  );
};
