import uniq from "lodash/uniq";

/*
The curated and exhaustive lists of technologies, categories, etc.
Each list has a curated version (i.e. OWASP) and an exhausive version
(i.e. ALL_OWASP) where every rule falls into exactly one category
in the exclusive version. This means that each ALL list must have an
"other" label like "--no-owasp--" so that rules that are not in our
enumerated categories are still searchable and discoverable
*/
export const OWASP_2017 = [
  "Injection",
  "Broken Authentication",
  "Sensitive Data Exposure",
  "XML External Entities (XXE)",
  "Broken Access Control",
  "Security Misconfiguration",
  "Cross-Site Scripting (XSS)",
  "Insecure Deserialization",
  "Using Components with Known Vulnerabilities",
  "Insufficient Logging & Monitoring",
];

export const OWASP_2021 = [
  "Broken Access Control",
  "Cryptographic Failures",
  "Injection",
  "Insecure Design",
  "Security Misconfiguration",
  "Vulnerable and Outdated Components",
  "Identification and Authentication Failures",
  "Software and Data Integrity Failures",
  "Security Logging and Monitoring Failures",
  "Server-Side Request Forgery (SSRF)",
];
export const NO_OWASP = "--no-owasp--";
export const ALL_OWASP = uniq([NO_OWASP, ...OWASP_2017, ...OWASP_2021]);
