import { useContext } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { faAlbumCollection } from "@fortawesome/pro-regular-svg-icons";

import { Code, ConfirmationModal } from "@shared/components";
import { getSnippetAliasName } from "@shared/utils";

import { WorkbenchContext } from "../providers";

const AlertBody = styled.div`
  max-width: 340px;
  padding-left: 12px;
  line-height: 1.6;
`;

const RuleName = styled.div`
  // without this color, the ellipses are black
  color: #f6f8fa;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MoreRules = styled.div`
  font-size: var(--mantine-font-size-xs);
`;

/**
 * Alert that shows up if the user is opening a rule with multiple ids
 * Only shows up if the user has a valid org
 * The user can either choose to
 * - view the first rule only (closing the dialog)
 * - split the rule into N rules (created and saved by the Workbench)
 *
 * Note that the dialog will only show the first 10 rules. There a few users
 * with over 100 ids in the rules, and we don't want this dialog to look ridiculous
 */
const MultipleRuleId = () => {
  const { workbench } = useContext(WorkbenchContext);
  const rule = workbench.ui.multipleRuleDefinition;
  const firstTenRules = rule?.definition.rules.slice(0, 9);

  const onConfirmSplitRule = () => {
    workbench.splitRules(rule!);
    workbench.ui.closeMultipleRuleIdAlert();
  };

  if (workbench.org === undefined) {
    // if there is no org to save to, don't show popup
    workbench.ui.closeMultipleRuleIdAlert();
    return null;
  }

  return (
    <ConfirmationModal
      opened={workbench.ui.isMultipleRuleIdAlertOpen}
      confirmButtonText="Split rule"
      onConfirm={onConfirmSplitRule}
      cancelButtonText="View first rule only"
      onCancel={workbench.ui.closeMultipleRuleIdAlert}
      icon={faAlbumCollection}
      size="auto"
    >
      <AlertBody>
        This snippet has multiple rules in it, which is a format that is no
        longer supported. You can either view the first rule, or split this rule
        into {rule?.definition.rules.length} rules, which create the following
        rules:
        {firstTenRules?.map((r, idx) => (
          <RuleName key={idx}>
            <Code inline>{getSnippetAliasName(workbench.org!, r.id)}</Code>
          </RuleName>
        ))}
        {rule && rule.definition.rules.length > 10 && (
          <MoreRules>And {rule.definition.rules.length - 10} more</MoreRules>
        )}
        <MoreRules>
          If you have any existing rules with these names, they will be
          overwritten.
        </MoreRules>
      </AlertBody>
    </ConfirmationModal>
  );
};

export const MultipleRuleIdAlert = observer(MultipleRuleId);
