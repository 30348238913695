import { Visibility } from "@shared/types";

import { authPut } from "../auth";

export const updateRuleVisibility = (id: string, visibility: Visibility) => {
  return authPut<{ visibility: Visibility }, { success: boolean }>(
    `/api/registry/rules/${id}/visibility`,
    { visibility: visibility }
  );
};
