import React, { useMemo } from "react";
import pluralize from "pluralize";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Center, Flex, Loader, ScrollArea } from "@mantine/core";

import { DOCS_PATTERN_LOGIC, DOCS_PATTERN_SYNTAX } from "@shared/constants";
import { RunResponseStructure } from "@shared/hooks";

import styles from "./WidgetResults.module.css";

interface Props {
  results: RunResponseStructure;
  errorMsg?: string;
  pattern: string;
  numTestFailures?: number | null;
  loading: boolean;
  onClose: () => void;
}

const invalidPatternHelp = (
  <div>
    The{" "}
    <a href={DOCS_PATTERN_SYNTAX} target="_blank" rel="noopener noreferrer">
      pattern documentation
    </a>{" "}
    has full details on pattern syntax.
  </div>
);

const noMatchesHelp = (
  <div>
    The{" "}
    <a href={DOCS_PATTERN_LOGIC} target="_blank" rel="noopener noreferrer">
      rule documentation
    </a>{" "}
    has full details on rule syntax and boolean logic.
  </div>
);

export const WidgetResults: React.FC<Props> = ({
  results,
  errorMsg,
  numTestFailures,
  loading,
  onClose,
}) => {
  const noMatches =
    results.findings.length === 0 && results.allErrors.length === 0;
  const spanErrors = useMemo(
    () => [...results.patternErrors, ...results.targetErrors],
    [results.patternErrors, results.targetErrors]
  );
  const internalErrors = useMemo(
    () => [
      ...results.fatalErrors,
      ...(results.runError ? [results.runError] : []),
    ],
    [results.fatalErrors, results.runError]
  );

  return (
    <div className={styles.console}>
      <Flex align="center" justify="space-between">
        <span
          style={{
            color: "#7a6bcb",
            fontSize: "var(--mantine-font-size-xs)",
          }}
        >
          OUTPUT
        </span>
        <Button
          size="compact-sm"
          onClick={onClose}
          className={styles.button}
          variant="subtle"
        >
          <FontAwesomeIcon icon={faXmark} />
        </Button>
      </Flex>
      <ScrollArea.Autosize
        mah={264}
        classNames={{
          scrollbar: styles.scrollbar,
        }}
      >
        {internalErrors.length > 0 && (
          <div>
            {internalErrors.map((error, ix) => (
              <div className={styles.error} key={ix}>
                {JSON.stringify(error).includes("MatchTimeoutError")
                  ? "Semgrep Timed Out"
                  : JSON.stringify(error).replace(/(^")|("$)/g, "")}
              </div>
            ))}
            {invalidPatternHelp}
          </div>
        )}

        {results === undefined ? (
          errorMsg ? (
            <div className={styles.error}>{errorMsg}</div>
          ) : (
            <div>Run to see results</div>
          )
        ) : noMatches ? (
          <>
            <div className={styles.error}>No matches</div>
            {noMatchesHelp}
          </>
        ) : loading ? (
          <Center>
            <Loader color="gray" size="xl" />
          </Center>
        ) : (
          <>
            {internalErrors.length === 0 && (
              <>
                {spanErrors.map((error, i) => (
                  <div key={i}>{error.message}</div>
                ))}

                {results.findings.map((r, ix) => (
                  <div key={ix}>
                    <span style={{ color: "var(--mantine-color-green-5)" }}>
                      L{r.start.line}:
                    </span>
                    {` ${r.extra.message}`}
                  </div>
                ))}
              </>
            )}
            {numTestFailures !== null &&
              numTestFailures !== undefined &&
              (numTestFailures === 0 ? (
                <div style={{ color: "var(--mantine-color-green-5)" }}>
                  <br />
                  All tests pass
                </div>
              ) : (
                <div className={styles.error}>
                  <br />
                  {numTestFailures} {pluralize("test", numTestFailures)} failed
                </div>
              ))}
          </>
        )}
      </ScrollArea.Autosize>
    </div>
  );
};
