import { CodeLoc, Highlight } from "@shared/types";
import { CliMatch, writeMatchSeverity } from "@semgrep_output_types";

import { makeHighlight } from "../makeHighlight";
import { makeResultKey } from "../makeResultKey";

export const makeMatchHighlight = (
  match: CliMatch,
  newEnd?: CodeLoc
): Highlight => {
  return {
    ...makeHighlight(match.start, match.end, newEnd),
    key: makeResultKey(match),
    isError: false,
    isDebug: false,
    type: newEnd ? "fix" : writeMatchSeverity(match.extra.severity),
    group: match.check_id,
    message: match.extra.message,
  };
};
