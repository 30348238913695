import { faFlask } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, AlertProps, Group } from "@mantine/core";

import { QueryConsoleFeedbackButton } from "../../QueryConsoleFeedbackButton";

export const QueryConsoleBetaBanner = (props: AlertProps) => {
  return (
    <Alert
      color="green"
      icon={<FontAwesomeIcon icon={faFlask} />}
      title="This feature is in Beta"
      styles={{ title: { width: "100%" }, label: { width: "100%" } }}
      {...props}
    >
      Searches are free during the beta period. Once this feature is Generally
      Available, there may be associated costs.
      <Group justify="end" mt="sm">
        <QueryConsoleFeedbackButton />
      </Group>
    </Alert>
  );
};
