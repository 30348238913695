import React from "react";
import omit from "lodash/omit";
import { faExternalLinkAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./ExternalLink.module.css";

const TRUSTED_EXTERNAL_HOSTNAMES = new Set(["r2c.dev", "github.com"]);

/**
 * Internal replacement for using plain anchor tags, with all the relevant
 * extensions to keep the app looking consistent. No icons.
 */
export const SimpleExternalLink: React.FC<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
> = (props) => {
  const hrefIsTrusted =
    props.href &&
    (props.href.startsWith("/") ||
      TRUSTED_EXTERNAL_HOSTNAMES.has(new URL(props.href).hostname));
  return (
    <span className={styles.container}>
      <a // nosemgrep: typescript.react.security.audit.react-missing-noreferrer.react-missing-noreferrer, nosemgrep: typescript.react.security.audit.react-missing-noopener.react-missing-noopener
        {...omit(props, ["children"])}
        style={{ display: "inline-flex", flexWrap: "nowrap", ...props.style }}
        target="_blank"
        rel={hrefIsTrusted ? "noopener" : "noopener noreferrer"}
      >
        {props.children}
      </a>
    </span>
  );
};

/**
 * Internal replacement for using plain anchor tags, with all the relevant
 * extensions to keep the app looking consistent. Shows an icon on hover.
 * @param stayVisible - Should the link icon stay visible without hover.
 */
export const ExternalLink: React.FC<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > & { stayVisible?: boolean }
> = (props) => {
  return (
    <SimpleExternalLink
      className={
        !props.stayVisible ? "ExternalLinkContainer__autohide" : undefined
      }
      {...omit(props, ["children", "stayVisible"])}
    >
      {props.children}&nbsp;
      <FontAwesomeIcon icon={faExternalLinkAlt} />
    </SimpleExternalLink>
  );
};
