import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Split from "react-split-grid";
import styled from "styled-components";
import yaml from "yaml";
import {
  faExpand,
  faExternalLinkAlt,
  faLock,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Group } from "@mantine/core";

import {
  fetchRule,
  fetchRules,
  fetchRulesetWithDefinitions,
  run,
} from "@shared/api";
import {
  DEFAULT_LANG,
  DEFAULT_PATTERN,
  EMPTY_RUN_RESPONSE,
} from "@shared/constants";
import { useRunResponse, useRunTestResults, useUser } from "@shared/hooks";
import { Highlight, Language, Rule, RunResponse, Snippet } from "@shared/types";
import { ALL_LANGUAGES, ID_BY_KEY, makeMatchHighlight } from "@shared/utils";
import { CliError, CliMatch } from "@semgrep_output_types";

import { makeErrorHighlight } from "../../utils/makeErrorHighlight";
import { SplitGutter } from "../SplitGutter";

import { WidgetCodeEditor } from "./components/WidgetCodeEditor";
import { WidgetLanguageSelect } from "./components/WidgetLanguageSelect";
import { WidgetPatternEditor } from "./components/WidgetPatternEditor";
import { WidgetResults } from "./components/WidgetResults";
import { WidgetRunButton } from "./components/WidgetRunButton";
import { OutputButton } from "./components";

const EMPTY_HIGHLIGHTS: Highlight[] = [];

interface NullableSnippet {
  pattern: string;
  language: string;
  target?: string;
}

type Props = {
  registryId?: string;
  snippetId?: string;
  private?: boolean;
  // replace open link with call to this function
  // meant to expand the playground in a modal for better viewing
  expand?: () => void;
};

const Container = styled.div`
  background-color: #171f43;
  border: 1px solid #0f1446;
  border-radius: 5px;
  position: relative;
  height: 100%;
  min-height: 432px;
  width: 100%;
  line-height: 1.625;
  display: flex;
  flex-direction: column;
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: 1fr 4px 1fr;
  flex-grow: 1;
`;

const SplitSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResizeEditorWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const RulePanel = styled.section`
  padding-top: 4px;
  display: block;
  flex-grow: 1;
  position: relative;
`;

const RuleHeader = styled.div`
  width: 100%;
  color: #7a6bcb;
  font-size: var(--mantine-font-size-xs);
  padding: 4px;
  border-bottom: 1px solid #0f1446;
  position: relative;
`;

const LeftAlign = styled.div`
  position: absolute;
  right: 4px;
  top: 2px;
`;

const OpenLink = styled.a`
  margin-right: 4px;
  color: var(--mantine-color-green-5);
  :hover {
    color: var(--mantine-color-green-5);
  }
`;

const ExpandText = styled.div`
  margin-right: 4px;
  color: var(--mantine-color-green-5);
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const CodeHeader = styled(RuleHeader)`
  border-top: 1px solid #0f1446;
`;

const CodePanel = styled.section`
  padding-top: 4px;
  display: block;
  margin-left: -20px;
  flex-grow: 1;
  position: relative;
`;

const ResultRow = styled(CodeHeader)`
  height: 32px;
  position: relative;
  border-bottom: none;
`;

export const EditorWidget: React.FC<Props> = (props) => {
  const history = useHistory();
  // User should only set registry or snippet ID, but we're not enforcing that
  const urlParams = new URLSearchParams(history.location.search);
  const registryId = props.registryId ?? urlParams.get("registry");
  const snippetId = props.snippetId ?? urlParams.get("snippet");
  const [pattern, setPattern] = useState<string>(DEFAULT_PATTERN);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [language, setLanguage] = useState<Language>(DEFAULT_LANG);
  const [target, setTarget] = useState<string | undefined>(undefined);
  const [runResponse, setRunResponse] =
    useState<RunResponse>(EMPTY_RUN_RESPONSE);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [highlighted, setHighlighted] = useState<Highlight[]>(EMPTY_HIGHLIGHTS);
  const [centeredLine, setCenteredLine] = useState<number>();
  const [showOutput, setShowOutput] = useState<boolean>(false);
  const [user] = useUser();

  const runResponseStructure = useRunResponse(runResponse);
  const { findings, patternErrors, targetErrors } = runResponseStructure;
  const { numTestFailures } = useRunTestResults(runResponse);

  const highlightResults = (
    results: CliMatch[],
    codeParseErrors: CliError[]
  ) => {
    if (codeParseErrors.length > 0) {
      const firstRes = codeParseErrors[0];
      // Make sure line is visible within editor (will scroll to it)
      setCenteredLine(firstRes.spans?.[0]?.start?.line);
      const highlighted = codeParseErrors.map((r) =>
        makeErrorHighlight(r, "parseError")
      );
      setHighlighted(highlighted);
    } else if (results.length > 0) {
      const firstRes = results[0];
      // Make sure line is visible within editor (will scroll to it)
      setCenteredLine(firstRes.start.line);
      const highlighted = results.map((r) => makeMatchHighlight(r));
      setHighlighted(highlighted);
    } else {
      setHighlighted(EMPTY_HIGHLIGHTS);
    }
  };

  useEffect(
    () => highlightResults(findings, targetErrors),
    [findings, targetErrors]
  );

  const editorRun = (callback?: () => void) => {
    const target_sanitized = target?.replace(/\t/g, "    ");
    const snippet: NullableSnippet = {
      pattern,
      language,
      target: target_sanitized,
    };
    setRunResponse(EMPTY_RUN_RESPONSE);
    setShowOutput(false);
    setIsRunning(true);
    setTarget(target_sanitized);

    if (pattern.trim().length === 0) {
      setErrorMessage("You can't run semgrep with an empty pattern");
      setIsRunning(false);
      setShowOutput(true);
    } else if (!snippet.target || snippet.target.trim().length === 0) {
      setErrorMessage("You can't run semgrep with an empty code block");
      setIsRunning(false);
      setShowOutput(true);
    } else {
      run(snippet as Snippet, true, {})
        .then((data) => {
          setRunResponse(data);
          setErrorMessage(undefined);
          setIsRunning(false);

          if (
            data.semgrep_result.output.results.length === 0 ||
            data.semgrep_result.output.errors.length !== 0 ||
            !!data.semgrep_result.run_error
          )
            setShowOutput(true);

          if (typeof callback === "function") {
            callback();
          }
        })
        .catch((err) => {
          setErrorMessage(`Error running semgrep: ${err.message}`);
          setIsRunning(false);
          setShowOutput(true);
        });
    }
  };

  const runAsync = async (): Promise<void> => {
    return await new Promise((resolve) => {
      editorRun(() => {
        resolve();
      });
    });
  };

  const onLangChange = (language: Language | null) => {
    if (language) {
      reset();
      setLanguage(language);
    }
  };

  const loadFetchedSnippet = (rule: Rule) => {
    const test_case = rule.test_cases
      ? rule.test_cases[0]
      : {
          target: undefined,
          language: DEFAULT_LANG,
        };
    const snippet: NullableSnippet = {
      ...test_case,
      pattern: yaml.stringify(rule.definition),
      language:
        ID_BY_KEY[rule.definition.rules[0].languages[0].toLowerCase()] ||
        DEFAULT_LANG,
    };

    setPattern(snippet.pattern);
    setLanguage(
      snippet.language in ALL_LANGUAGES
        ? (snippet.language as Language)
        : (DEFAULT_LANG as Language)
    );
    setTarget(snippet.target);
  };

  const reset = () => {
    setHighlighted(EMPTY_HIGHLIGHTS);
    setShowOutput(false);
    setRunResponse(EMPTY_RUN_RESPONSE);
    setErrorMessage(undefined);
  };

  useEffect(() => {
    if (snippetId) {
      const promise = snippetId.includes(":")
        ? fetchRulesetWithDefinitions(snippetId).then((r) =>
            loadFetchedSnippet(r.rules[0])
          )
        : fetchRule(snippetId).then(loadFetchedSnippet);
      promise.catch((err) => {
        setErrorMessage(`Error loading snippet '${snippetId}': ${err.message}`);
        setShowOutput(true);
      });
    }

    if (registryId) {
      // loadRegistrySnippet
      const promise = fetchRules(registryId).then((r) =>
        loadFetchedSnippet(r[0])
      );
      promise.catch((err) => {
        setErrorMessage(
          `Error loading example '${registryId}': ${err.message}`
        );
        setShowOutput(true);
      });
    }
  }, [registryId, snippetId]);

  const isPatternError = patternErrors.length > 0;

  let openRuleLink = "";
  if (user) {
    openRuleLink = registryId
      ? `/orgs/-/editor/r/${registryId}`
      : `/orgs/-/editor/s/${snippetId}`;
  } else {
    openRuleLink = registryId
      ? `/playground/r/${registryId}`
      : `/playground/s/${snippetId}`;
  }

  return (
    <Container>
      <Split
        render={({ getGridProps, getGutterProps }) => (
          <Grid {...getGridProps()}>
            <SplitSection>
              <RuleHeader>
                <div>RULE</div>
                <LeftAlign>
                  {props.private ? (
                    <FontAwesomeIcon
                      icon={faLock}
                      style={{
                        color: "var(--mantine-color-green-5)",
                        marginRight: "4px",
                      }}
                    />
                  ) : props.expand ? (
                    <ExpandText onClick={props.expand}>
                      Expand rule <FontAwesomeIcon icon={faExpand} />
                    </ExpandText>
                  ) : (
                    <OpenLink
                      href={openRuleLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open in {user ? "Editor " : "Playground "}
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </OpenLink>
                  )}
                </LeftAlign>
              </RuleHeader>
              <RulePanel className="rule-editor">
                <ResizeEditorWrapper>
                  <WidgetPatternEditor
                    pattern={pattern}
                    onPatternChange={(newPattern) => {
                      reset();
                      setPattern(newPattern);
                    }}
                    error={isPatternError}
                  />
                </ResizeEditorWrapper>
              </RulePanel>
            </SplitSection>
            <SplitGutter between="top-bottom" {...getGutterProps("row", 1)} />
            <SplitSection>
              <CodeHeader>
                <div>TEST CODE</div>
                <LeftAlign>
                  <WidgetLanguageSelect
                    onChange={onLangChange}
                    value={language}
                  />
                </LeftAlign>
              </CodeHeader>
              <CodePanel>
                <ResizeEditorWrapper>
                  <WidgetCodeEditor
                    // TODO: Make a real empty state
                    target={target ?? ""}
                    language={language}
                    highlighted={highlighted}
                    centeredLine={centeredLine}
                    onTargetChange={(newTarget) => {
                      reset();
                      setTarget(newTarget);
                    }}
                  />
                </ResizeEditorWrapper>
              </CodePanel>
            </SplitSection>
          </Grid>
        )}
      />
      {showOutput && (
        <WidgetResults
          results={runResponseStructure}
          errorMsg={errorMessage}
          pattern={pattern}
          numTestFailures={numTestFailures}
          loading={isRunning}
          onClose={() => setShowOutput(false)}
        />
      )}
      <ResultRow>
        <Group justify="flex-end">
          <OutputButton
            active={showOutput}
            onClick={() => setShowOutput(!showOutput)}
            showSuccess={findings.length > 0}
          />
          <WidgetRunButton onClick={runAsync} isRunning={isRunning} />
        </Group>
      </ResultRow>
    </Container>
  );
};
