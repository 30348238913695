import cloneDeep from "lodash/cloneDeep";
import reject from "lodash/reject";
import yaml from "yaml";

import {
  ALLOWED_VISUAL_PATTERN_KEYS,
  Language,
  PatternPath,
  SimpleRule,
} from "@shared/types";
import { ID_BY_KEY } from "@shared/utils";

export function ruleFromYAML(
  pattern: string,
  oldRule: SimpleRule,
  setLanguage?: Language
): [rule: SimpleRule | undefined, lossless: boolean] {
  /* Catch all parse errors and just circumvent the
    Simple Editor if yaml cannot be parsed
  */
  try {
    return protectedRuleFromYAML(pattern, oldRule, setLanguage);
  } catch (e) {
    console.warn(
      `while parsing: ${pattern}\nexception: unknown rule parse exception: ${e}`
    );
    return [undefined, false];
  }
}

function protectedRuleFromYAML(
  pattern: string,
  oldRule: SimpleRule,
  setLanguage?: Language
): [rule: SimpleRule | undefined, lossless: boolean] {
  /*
    Use automatic parser to convert YAML to dictionary
    but then restructure dict to fit our Rule object
  */
  const initPath = ["rules", 0];
  const parsedPattern = yaml.parse(pattern);
  removeEmptyKeys(parsedPattern);
  const rule = parsedPattern["rules"][0];
  let [lossLess, lossLessTemp] = [true, true];
  let yamlrule = assignNonPatternKeys(rule, oldRule);
  yamlrule.patterns = [];
  if (pattern.match(/^\s*?#/m)) {
    console.error(`not lossless: YAML string contains comments`);
    lossLess = false;
  }
  if (hasObjectValue(rule, "patterns")) {
    [yamlrule, lossLessTemp] = parsePatterns(yamlrule, rule, initPath);
    if (!lossLessTemp) {
      console.error(`not lossless: failed to parse "patterns" in ${yamlrule}`);
    }
    lossLess = lossLess && lossLessTemp;
  }
  if (hasObjectValue(rule, "pattern-either")) {
    [yamlrule, lossLessTemp] = parsePatternEither(yamlrule, rule, initPath);
    if (!lossLessTemp) {
      console.error(
        `not lossless: failed to parse "pattern-either" in ${yamlrule}`
      );
    }
    lossLess = lossLess && lossLessTemp;
  }
  if (hasStringValue(rule, "pattern")) {
    // This should not overwrite pattern-either patterns
    [yamlrule, lossLessTemp] = parsePattern(yamlrule, rule, initPath);
    if (!lossLessTemp) {
      console.error(`not lossless: failed to parse "pattern" in ${yamlrule}`);
    }
    lossLess = lossLess && lossLessTemp;
  }
  if (hasObjectOrStringValue(rule, "match")) {
    console.error(`TODO: currently not handling "match" in ${yamlrule}`);
  }
  if (yamlrule.patterns.length === 0) {
    yamlrule.patterns = [
      {
        pattern: "",
        path: [...initPath, "pattern"],
        type: "pattern" as ALLOWED_VISUAL_PATTERN_KEYS,
      },
    ];
  }

  if (parsedPattern["rules"].length > 1) {
    // We only parse the first rule
    console.error(
      `not lossless: only support 1 rule but had ${parsedPattern["rules"].length}`
    );
    lossLess = false;
  }
  if (!Object.keys(rule).every(isSupportedKey)) {
    // Warn user before dropping unsupported keys from representation
    console.error(
      `not lossless: unsupported keys: ${reject(
        Object.keys(rule),
        isSupportedKey
      ).join(", ")}`
    );
    lossLess = false;
  }
  yamlrule.patterns.sort((a: any, b: any) => (a.type < b.type ? -1 : 1));
  if (yamlrule.patterns[0].type !== "pattern") {
    // We assume that the first pattern in Simple editor has type "pattern"
    console.error(
      `not lossless: assumed root key would be of type pattern but found ${yamlrule.patterns[0].type}`
    );
    lossLess = false;
  }

  if (setLanguage !== undefined) {
    yamlrule.languages = [setLanguage];
  }
  return [yamlrule, lossLess];
}

function hasStringValue(dict: any, key: string) {
  return dict[key] && typeof dict[key] === "string";
}

function hasObjectValue(dict: any, key: string) {
  return dict[key] && typeof dict[key] === "object";
}

function hasObjectOrStringValue(dict: any, key: string) {
  return (
    dict[key] &&
    (typeof dict[key] === "object" || typeof dict[key] === "string")
  );
}

function removeEmptyKeys(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") {
      removeEmptyKeys(obj[key]); // recurse
    } else if (obj[key] == null) {
      obj[key] = " "; // delete
    } else if (typeof obj[key] === "string") {
      obj[key] = obj[key].trim();
    }
  });
}

function isSupportedKey(key: string) {
  return [
    "rules",
    "id",
    "message",
    "fix",
    "severity",
    "patterns",
    "pattern-either",
    "pattern",
    "language",
    "languages",
    "metadata",
  ].includes(key);
}

function assignNonPatternKeys(newRule: any, oldRule: SimpleRule) {
  const yamlrule = cloneDeep(oldRule);
  if (hasStringValue(newRule, "id")) {
    yamlrule.id = newRule["id"];
  }
  if (hasStringValue(newRule, "message")) {
    yamlrule.message = newRule["message"];
  } else {
    yamlrule.message = "";
  }
  if (hasStringValue(newRule, "fix")) {
    yamlrule.fix = newRule["fix"];
  } else {
    yamlrule.fix = "";
  }
  if (
    newRule["severity"] === "INFO" ||
    newRule["severity"] === "WARNING" ||
    newRule["severity"] === "ERROR"
  ) {
    yamlrule.severity = newRule["severity"];
  }
  if (hasObjectValue(newRule, "languages")) {
    // normalize for example 'typescript' into 'ts'
    yamlrule.languages = newRule["languages"].map((l: string) => ID_BY_KEY[l]);
  }
  if (hasObjectValue(newRule, "metadata")) {
    yamlrule.metadata = newRule.metadata;
  } else {
    yamlrule.metadata = {};
  }
  return yamlrule;
}

function parsePattern(
  yamlrule: SimpleRule,
  rule: any,
  path: PatternPath
): [SimpleRule, boolean] {
  yamlrule.patterns.push({
    pattern: rule["pattern"],
    path: [...path, "pattern"],
    type: "pattern" as ALLOWED_VISUAL_PATTERN_KEYS,
  });
  return [yamlrule, true];
}

function parsePatternEither(
  yamlrule: SimpleRule,
  rule: any,
  path: PatternPath
): [SimpleRule, boolean] {
  let lossLess = true;
  for (let j = 0; j < rule["pattern-either"].length; j++) {
    const innerdict = rule["pattern-either"][j];
    if (hasStringValue(innerdict, "pattern")) {
      pushPatternKey(yamlrule, innerdict, "pattern", [
        ...path,
        "pattern-either",
        j,
      ]);
    } else if (rule["pattern-either"] === " ") {
      yamlrule.patterns.push({
        pattern: "",
        path: [...path, "pattern-either", j],
        type: "pattern" as ALLOWED_VISUAL_PATTERN_KEYS,
      });
    } else {
      lossLess = false;
    }
  }
  return [yamlrule, lossLess];
}

function pushPatternKey(
  yamlrule: SimpleRule,
  dicto: any,
  key: string,
  path: PatternPath
) {
  yamlrule.patterns.push({
    pattern: dicto[key],
    path: [...path, key],
    type: key as ALLOWED_VISUAL_PATTERN_KEYS,
  });
}

function pushMetavarKey(yamlrule: SimpleRule, dicto: any, key: string) {
  yamlrule.patterns.push({
    type: key as ALLOWED_VISUAL_PATTERN_KEYS,
    metavariable: dicto[key]["metavariable"],
    constraint: dicto[key]["regex"],
  });
}

function parsePatterns(
  yamlrule: SimpleRule,
  rule: any,
  path: PatternPath
): [SimpleRule, boolean] {
  let patternAndCount = 0;
  let lossLess = true;
  for (let i = 0; i < rule["patterns"].length; i++) {
    const innerPath = [...path, "patterns", i];
    const dicto = rule["patterns"][i];
    if (hasObjectValue(dicto, "pattern-either")) {
      patternAndCount++;
      let lossLessTmp;
      [yamlrule, lossLessTmp] = parsePatternEither(yamlrule, dicto, innerPath);
      lossLess = lossLess && lossLessTmp;
    } else if (hasStringValue(dicto, "pattern")) {
      pushPatternKey(yamlrule, dicto, "pattern", innerPath);
      patternAndCount++;
    } else if (hasStringValue(dicto, "pattern-inside")) {
      pushPatternKey(yamlrule, dicto, "pattern-inside", innerPath);
    } else if (hasStringValue(dicto, "pattern-not")) {
      pushPatternKey(yamlrule, dicto, "pattern-not", innerPath);
    } else if (hasStringValue(dicto, "pattern-regex")) {
      pushPatternKey(yamlrule, dicto, "pattern-regex", innerPath);
    } else if (hasStringValue(dicto, "pattern-not-inside")) {
      pushPatternKey(yamlrule, dicto, "pattern-not-inside", innerPath);
    } else if (hasStringValue(dicto, "metavariable-regex")) {
      pushMetavarKey(yamlrule, dicto, "metavariable-regex");
    } else {
      lossLess = false;
    }
  }
  if (patternAndCount > 1) {
    lossLess = false;
  }
  return [yamlrule, lossLess];
}
