import { Redirect, Route, RouteProps } from "react-router-dom";

import { AuthStates } from "@shared/api";
// Nx doesn't like that these libraries are both statically imported and dynamically imported in the same file.
// It's probably correct and we should address this at some point, but for now we'll disable the rule for the offending imports.
// TODO: Remove this eslint disable once we've addressed the static import issue
// eslint-disable-next-line @nx/enforce-module-boundaries
import { RobotsNoindex } from "@shared/components";
import { getAuthenticationState } from "@shared/utils";

export const AuthedRoute: React.FC<RouteProps> = (props) => {
  switch (getAuthenticationState()) {
    case AuthStates.Unauthorized:
      return (
        <Redirect
          push
          to={{
            pathname: "/login",
            search: `?return_path=${window.location.pathname}`,
          }}
        />
      );
    case AuthStates.Authorized:
      return (
        <>
          <RobotsNoindex />
          <Route {...props} />
        </>
      );
    default:
      return <div>Error.</div>;
  }
};
