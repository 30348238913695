import React, { useState } from "react";
import { Tabs, Text } from "@mantine/core";

import { Code } from "@shared/components";

import { VisibilityToggle } from "../RuleShareDialog/VisibilityToggle";

import GitHubImportRulesCode, {
  CLIPublishCommand,
} from "./GitHubImportRulesCode";

interface Props {
  name: string;
}

type ImportMethod = "github" | "gitlab" | "cli";

const ImportRulesDialog: React.FC<Props> = ({ name }) => {
  const [ci, setCi] = useState<ImportMethod>("cli");
  const [visibility, setVisibility] = useState<
    "unlisted" | "org_private" | "public"
  >("unlisted");

  return (
    <Tabs
      value={ci}
      onChange={(val) => {
        setCi(val as ImportMethod);
      }}
    >
      <Tabs.List>
        <Tabs.Tab value="cli">CLI</Tabs.Tab>
        <Tabs.Tab value="github">GitHub Actions</Tabs.Tab>
      </Tabs.List>

      <Text my={12}>
        Import multiple rules to Semgrep AppSec Platform with the CLI.
      </Text>

      <div style={{ display: "flex", gap: "16px", marginBottom: "12px" }}>
        <VisibilityToggle
          visibility={visibility}
          allowPrivate={true}
          allowPublic={true}
          setAction={setVisibility}
        ></VisibilityToggle>
      </div>

      <Tabs.Panel value="cli">
        <>
          1. Log in to your account
          <Code copyable>semgrep login</Code>
          <br />
          2. Publish the folder with your rules
          <Code copyable>
            <CLIPublishCommand visibility={visibility} name={name} />
          </Code>
        </>
      </Tabs.Panel>
      <Tabs.Panel value="github">
        <GitHubImportRulesCode visibility={visibility} name={name} />
      </Tabs.Panel>
    </Tabs>
  );
};

export default ImportRulesDialog;
