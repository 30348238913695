import { createContext } from "react";

import { Workbench } from "../stores/Workbench";

interface WorkbenchContextProps {
  workbench: Workbench;
}

export const WorkbenchContext = createContext<WorkbenchContextProps>(
  undefined!
);
