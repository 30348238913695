interface Fullstory {
  identify(uid: string, userVars?: object): void;
  setUserVars(userVars: object): void;
  setVars(page: string, userVars: object): void;
  log(level: LogLevel, msg: string): void;
  event(eventName: string, eventProperties: object): void;
}

type LogLevel = "log" | "info" | "warn" | "error";

declare let FS: Fullstory;

export const fullstory = {
  identify: (uid: string, userVars?: object) => {
    try {
      FS.identify(uid, userVars);
    } catch {
      // noop
    }
  },
  setUserVars: (userVars: object) => {
    try {
      FS.setUserVars(userVars);
    } catch {
      // noop
    }
  },
  setVars: (page: string, pageVars: object) => {
    try {
      FS.setVars("page", {
        pageName: page,
        ...pageVars,
      });
    } catch {
      // noop
    }
  },
  log: (level: LogLevel, msg: string) => {
    try {
      FS.log(level, msg);
    } catch {
      // noop
    }
  },
  event: (eventName: string, eventProperties: object) => {
    try {
      FS.event(eventName, eventProperties);
    } catch {
      // noop
    }
  },
};
