import {
  CreateSearchRequest,
  CreateSearchResponse,
} from "@protos/search/v1/search_service";

import { authPost } from "../auth/auth";

export const createSearch = async (
  body: CreateSearchRequest
): Promise<CreateSearchResponse> => {
  return authPost<CreateSearchRequest, CreateSearchResponse>(
    "/api/console/search",
    body
  );
};
