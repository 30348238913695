import React, { ReactNode } from "react";
import styled from "styled-components";

const FloatingControls = styled.div`
  position: absolute;
  bottom: var(--mantine-spacing-lg);
  right: var(--mantine-spacing-lg);
  display: flex;
  gap: 8px;
`;

interface Props {
  controls?: ReactNode;
  id: string;
}

/**
 * We put this around <MonacoEditor> to have it react to resizes.
 *
 * The monaco-editor decides how many pixels high it wants to be on mount.
 * It would react to its parent shrinking, but the parent cannot shrink if its child has fixed height.
 * This wrapper makes the parent ignore the child editor's height when sizing itself.
 */
export const EditorWrapper = ({
  children,
  controls,
  id,
}: React.PropsWithChildren<Props>) => {
  return (
    <div style={{ position: "relative" }} id={id}>
      <div
        style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
      >
        {children}
      </div>
      <FloatingControls>{controls}</FloatingControls>
    </div>
  );
};
