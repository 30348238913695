// @ts-ignore
import { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { ExplainSupplyChain } from "./ExplainSupplyChain";
import { IntroSupplyChain } from "./IntroSupplyChain";
import { scBackground, Screen } from "./utils";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${scBackground};
`;

const DisappearingWrapper = styled.div<{
  show: boolean;
  direction: "top" | "bottom";
}>`
  width: 100%;
  position: absolute;
  transition: all 1s;
  transform: translate(
    0,
    ${({ direction }) => (direction === "top" ? "-" : "")}100px
  );
  ${({ show }) =>
    show
      ? `opacity: 1; transform: translate(0, 0);`
      : `opacity: 0; pointer-events: none;`}
`;

export function SupplyChainWidget() {
  const [selectedScreen, selectScreen] = useState<Screen | null>(null);

  const location = useLocation();
  const hash = location.hash;

  const params = new URLSearchParams(hash.replace("#", ""));
  const scrolled = params.get("scrolled");

  const screen = selectedScreen || (scrolled === "detail" ? "lodash" : "intro");

  return (
    <Wrapper>
      <DisappearingWrapper show={screen === "intro"} direction="top">
        <IntroSupplyChain goTo={selectScreen} />
      </DisappearingWrapper>

      <DisappearingWrapper show={screen !== "intro"} direction="bottom">
        <ExplainSupplyChain
          goTo={selectScreen}
          example={screen === "intro" ? "lodash" : screen}
        />
      </DisappearingWrapper>
    </Wrapper>
  );
}
