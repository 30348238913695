export const recoverBrowserSecurityError = <T>(f: () => T) => {
  // firefox in private browsing mode or anytime cookies are disabled,
  // removes access to localStorage and several other functions.
  // We use localstorage a ton so let's recover and log so at least the user knows they can fix it.
  // localStorage: Window.localStorage: read and write attempts throw a SecurityError exception
  // https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Privacy/Storage_access_policy
  // https://meyerweb.com/eric/thoughts/2012/04/25/firefox-failing-localstorage/
  try {
    return f();
  } catch (e) {
    console.error(
      "Unable to use a needed browser feature such as localStorage due to browser security / privacy settings.",
      e
    );
    return null;
  }
};
