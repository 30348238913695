import { appEnv } from "../appEnv";

// These are safe to keep in source control and distribute as they only have access to configs marked for client consumption
export const getPrefabClientKey = () => {
  let overrideEnv = null;
  try {
    overrideEnv = localStorage.getItem("prefab-override-env");
  } catch (e: unknown) {
    console.warn("Could not access localStorage");
  }

  if (overrideEnv !== null) {
    console.warn(
      `Using override env "${overrideEnv} for prefab.
      localStorage.removeItem("prefab-override-env") to clear.`
    );
  }

  switch (overrideEnv ?? appEnv) {
    case "prod":
      return "323-Production-P160-E510-CLIENT-60e8e662-fa53-4988-884d-170da2647940";
    case "staging":
      return "322-Staging-P160-E495-CLIENT-f2ba4a17-ffc6-45f7-8512-5baba3821954";
    case "dev":
    case "dev2":
    default:
      return "321-Development-P160-E220-CLIENT-18d29c3c-fc5b-46e9-a203-ec42b7bf2a7c";
  }
};
