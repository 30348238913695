import { showNotification } from "@mantine/notifications";
import { useQuery } from "@tanstack/react-query";

import { fetchRules } from "@shared/api/lib/registry";
import { ApiError } from "@shared/types";

/*
 * Returns query result with rules from registry
 * Shows notification if there is a failure to load the registry
 */
export const useRegistryRules = () => {
  return useQuery({
    queryKey: ["repos"],
    queryFn: async () => {
      try {
        return fetchRules();
      } catch (err) {
        showNotification({
          message: `Failed to load the registry: ${(err as ApiError).message}`,
          color: "red",
        });

        throw err;
      }
    },
  });
};
