import { FeatureNameType, orgHasFeature } from "@shared/utils";

import { useActiveFeatures } from "../useActiveFeatures";
import { useOrg } from "../useOrg";

export const useSupportsFeature = (name: FeatureNameType) => {
  const [org] = useOrg();
  const { data: activeFeatures } = useActiveFeatures();

  return (
    orgHasFeature(name, org) || !!activeFeatures?.find((f) => f.name === name)
  );
};
