import { faBookAlt, faBooks } from "@fortawesome/pro-duotone-svg-icons";
import { faEllipsisH, faMagic } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SimpleGrid, Text, UnstyledButton } from "@mantine/core";

const TutorialIcon = () => (
  <span className="fa-layers fa-4x">
    <FontAwesomeIcon icon={faBookAlt} />
    <FontAwesomeIcon icon={faEllipsisH} inverse transform={"shrink-10 up-2"} />
  </span>
);

const TaintModeIcon = () => (
  <span className="fa-layers fa-4x">
    <FontAwesomeIcon icon={faBookAlt} />
    <FontAwesomeIcon icon={faMagic} inverse transform={"shrink-10 up-2"} />
  </span>
);

export const SplashTutorials = () => {
  const tutorials = [
    {
      icon: <TutorialIcon />,
      text: "Tutorial",
      href: "/learn/basics/1",
    },
    {
      icon: <TaintModeIcon />,
      text: "Text mode",
      href: "https://semgrep.dev/docs/writing-rules/data-flow/taint-mode",
    },
    {
      icon: <FontAwesomeIcon icon={faBooks} size="4x" />,
      text: "Docs",
      href: "https://semgrep.dev/docs",
    },
  ];
  return (
    <SimpleGrid cols={3} maw={600}>
      {tutorials.map(({ icon, text, href }) => (
        <UnstyledButton
          component="a"
          href={href}
          variant="button-card"
          p="xl"
          key={href}
        >
          <Text c="blue.9">{icon}</Text>
          <Text>{text}</Text>
        </UnstyledButton>
      ))}
    </SimpleGrid>
  );
};
