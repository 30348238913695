// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/cli/semgrep_output.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Any } from "../../google/protobuf/any";
import { Struct } from "../../google/protobuf/struct";

export const protobufPackage = "cli";

export interface CliOutput {
  version: string;
  errors: CliError[];
  results: CliMatch[];
  paths: CliPaths | undefined;
  time: CliTiming | undefined;
}

export interface Position {
  line: number;
  col: number;
  offset: number;
}

export interface Location {
  path: string;
  start: Position | undefined;
  end: Position | undefined;
}

export interface CoreMatch {
  ruleId: string;
  location: Location | undefined;
  extra: CoreMatchExtra | undefined;
}

export interface CoreMatchExtra {
  message: string;
  metavars: { [key: string]: MetavarValue };
  dataflowTrace: DataflowTrace | undefined;
}

export interface CoreMatchExtra_MetavarsEntry {
  key: string;
  value: MetavarValue | undefined;
}

export interface DataflowTrace {
  taintSource: Location[];
  intermediateVars: Location[];
}

export interface MetavarValue {
  start: Position | undefined;
  end: Position | undefined;
  abstractContent: string;
  propagatedValue: SvalueValue | undefined;
  uniqueId: UniqueId | undefined;
}

export interface SvalueValue {
  svalueStart: Position | undefined;
  svalueEnd: Position | undefined;
  svalueAbstractContent: string;
}

export interface UniqueId {
  type: Any | undefined;
  md5sum: string;
  sid: number;
}

export interface CoreError {
  ruleId: string;
  errorType: Any | undefined;
  severity: Any | undefined;
  location: Location | undefined;
  message: string;
  details: string;
}

export interface CoreStats {
  okfiles: number;
  errorfiles: number;
}

export interface SkippedTarget {
  path: string;
  reason: Any | undefined;
  details: string;
  ruleId: string;
}

export interface SkippedRule {
  ruleId: string;
  details: string;
  position: Position | undefined;
}

export interface CoreTiming {
  targets: TargetTime[];
  rules: string[];
  rulesParseTime: number;
}

export interface TargetTime {
  path: string;
  ruleTimes: RuleTimes[];
  runTime: number;
}

export interface RuleTimes {
  ruleId: string;
  parseTime: number;
  matchTime: number;
}

export interface CveResult {
  url: string;
  filename: string;
  funcnames: string[];
}

export interface CoreMatchResults {
  matches: CoreMatch[];
  errors: CoreError[];
  skipped: SkippedTarget[];
  skippedRules: SkippedRule[];
  stats: CoreStats | undefined;
  time: CoreTiming | undefined;
}

export interface CliError {
  code: number;
  level: string;
  type: string;
  ruleId: string;
  message: string;
  path: string;
  longMsg: string;
  shortMsg: string;
  spans: ErrorSpan[];
  help: string;
}

export interface ErrorSpan {
  file: string;
  start: PositionBis | undefined;
  end: PositionBis | undefined;
  sourceHash: string;
  configStart: PositionBis | undefined;
  configEnd: PositionBis | undefined;
  configPath: string[];
  contextStart: PositionBis | undefined;
  contextEnd: PositionBis | undefined;
}

export interface PositionBis {
  line: number;
  col: number;
}

export interface CliMatch {
  checkId: string;
  path: string;
  start: Position | undefined;
  end: Position | undefined;
  extra: CliMatchExtra | undefined;
}

export interface CliMatchExtra {
  metavars: { [key: string]: MetavarValue };
  fingerprint: string;
  lines: string;
  message: string;
  metadata: Any | undefined;
  severity: string;
  fix: string;
  fixRegex: FixRegex | undefined;
  isIgnored: boolean;
  dependencyMatchOnly: boolean;
  dependencyMatches: Any | undefined;
  fixedLines: string[];
  dataflowTrace: DataflowTrace | undefined;
}

export interface CliMatchExtra_MetavarsEntry {
  key: string;
  value: MetavarValue | undefined;
}

export interface FixRegex {
  regex: string;
  replacement: string;
  count: number;
}

export interface CliOutputExtra {
  paths: CliPaths | undefined;
  time: CliTiming | undefined;
}

export interface CliPaths {
  scanned: string[];
  Comment: string;
  skipped: CliSkippedTarget[];
}

export interface CliSkippedTarget {
  path: string;
  reason: string;
}

export interface CliTiming {
  rules: RuleIdDict[];
  rulesParseTime: number;
  profilingTimes: { [key: string]: number };
  targets: CliTargetTimes[];
  totalBytes: number;
}

export interface CliTiming_ProfilingTimesEntry {
  key: string;
  value: number;
}

export interface RuleIdDict {
  id: string;
}

export interface CliTargetTimes {
  path: string;
  numBytes: number;
  matchTimes: number[];
  parseTimes: number[];
  runTime: number;
}

export interface ApiScansFindings {
  findings: Finding[];
  token: string;
  gitlabToken: string;
  searchedPaths: string[];
  ruleIds: string[];
  caiIds: string[];
}

export interface Finding {
  checkId: string;
  path: string;
  line: number;
  column: number;
  endLine: number;
  endColumn: number;
  message: string;
  severity: number;
  index: number;
  commitDate: string;
  syntacticId: string;
  matchBasedId: string;
  metadata: { [key: string]: any } | undefined;
  isBlocking: boolean;
  fixedLines: string[];
  scaInfo: ScaInfo | undefined;
  dataflowTrace: DataflowTrace | undefined;
}

export interface ScaInfo {
  dependencyMatchOnly: boolean;
  dependencyMatches: { [key: string]: any } | undefined;
}

function createBaseCliOutput(): CliOutput {
  return { version: "", errors: [], results: [], paths: undefined, time: undefined };
}

export const CliOutput = {
  encode(message: CliOutput, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.version !== "") {
      writer.uint32(4071110298).string(message.version);
    }
    for (const v of message.errors) {
      CliError.encode(v!, writer.uint32(1438626266).fork()).ldelim();
    }
    for (const v of message.results) {
      CliMatch.encode(v!, writer.uint32(2084366802).fork()).ldelim();
    }
    if (message.paths !== undefined) {
      CliPaths.encode(message.paths, writer.uint32(875342194).fork()).ldelim();
    }
    if (message.time !== undefined) {
      CliTiming.encode(message.time, writer.uint32(24936090).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliOutput {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliOutput();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 508888787:
          if (tag !== 4071110298) {
            break;
          }

          message.version = reader.string();
          continue;
        case 179828283:
          if (tag !== 1438626266) {
            break;
          }

          message.errors.push(CliError.decode(reader, reader.uint32()));
          continue;
        case 260545850:
          if (tag !== 2084366802) {
            break;
          }

          message.results.push(CliMatch.decode(reader, reader.uint32()));
          continue;
        case 109417774:
          if (tag !== 875342194) {
            break;
          }

          message.paths = CliPaths.decode(reader, reader.uint32());
          continue;
        case 3117011:
          if (tag !== 24936090) {
            break;
          }

          message.time = CliTiming.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliOutput {
    return {
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      errors: globalThis.Array.isArray(object?.errors) ? object.errors.map((e: any) => CliError.fromJSON(e)) : [],
      results: globalThis.Array.isArray(object?.results) ? object.results.map((e: any) => CliMatch.fromJSON(e)) : [],
      paths: isSet(object.paths) ? CliPaths.fromJSON(object.paths) : undefined,
      time: isSet(object.time) ? CliTiming.fromJSON(object.time) : undefined,
    };
  },

  toJSON(message: CliOutput): unknown {
    const obj: any = {};
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.errors?.length) {
      obj.errors = message.errors.map((e) => CliError.toJSON(e));
    }
    if (message.results?.length) {
      obj.results = message.results.map((e) => CliMatch.toJSON(e));
    }
    if (message.paths !== undefined) {
      obj.paths = CliPaths.toJSON(message.paths);
    }
    if (message.time !== undefined) {
      obj.time = CliTiming.toJSON(message.time);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliOutput>, I>>(base?: I): CliOutput {
    return CliOutput.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliOutput>, I>>(object: I): CliOutput {
    const message = createBaseCliOutput();
    message.version = object.version ?? "";
    message.errors = object.errors?.map((e) => CliError.fromPartial(e)) || [];
    message.results = object.results?.map((e) => CliMatch.fromPartial(e)) || [];
    message.paths = (object.paths !== undefined && object.paths !== null)
      ? CliPaths.fromPartial(object.paths)
      : undefined;
    message.time = (object.time !== undefined && object.time !== null) ? CliTiming.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBasePosition(): Position {
  return { line: 0, col: 0, offset: 0 };
}

export const Position = {
  encode(message: Position, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.line !== 0) {
      writer.uint32(24943712).int64(message.line);
    }
    if (message.col !== 0) {
      writer.uint32(858624).int64(message.col);
    }
    if (message.offset !== 0) {
      writer.uint32(1572471448).int64(message.offset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Position {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3117964:
          if (tag !== 24943712) {
            break;
          }

          message.line = longToNumber(reader.int64() as Long);
          continue;
        case 107328:
          if (tag !== 858624) {
            break;
          }

          message.col = longToNumber(reader.int64() as Long);
          continue;
        case 196558931:
          if (tag !== 1572471448) {
            break;
          }

          message.offset = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Position {
    return {
      line: isSet(object.line) ? globalThis.Number(object.line) : 0,
      col: isSet(object.col) ? globalThis.Number(object.col) : 0,
      offset: isSet(object.offset) ? globalThis.Number(object.offset) : 0,
    };
  },

  toJSON(message: Position): unknown {
    const obj: any = {};
    if (message.line !== 0) {
      obj.line = Math.round(message.line);
    }
    if (message.col !== 0) {
      obj.col = Math.round(message.col);
    }
    if (message.offset !== 0) {
      obj.offset = Math.round(message.offset);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Position>, I>>(base?: I): Position {
    return Position.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Position>, I>>(object: I): Position {
    const message = createBasePosition();
    message.line = object.line ?? 0;
    message.col = object.col ?? 0;
    message.offset = object.offset ?? 0;
    return message;
  },
};

function createBaseLocation(): Location {
  return { path: "", start: undefined, end: undefined };
}

export const Location = {
  encode(message: Location, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.path !== "") {
      writer.uint32(25702874).string(message.path);
    }
    if (message.start !== undefined) {
      Position.encode(message.start, writer.uint32(884972306).fork()).ldelim();
    }
    if (message.end !== undefined) {
      Position.encode(message.end, writer.uint32(796890).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Location {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3212859:
          if (tag !== 25702874) {
            break;
          }

          message.path = reader.string();
          continue;
        case 110621538:
          if (tag !== 884972306) {
            break;
          }

          message.start = Position.decode(reader, reader.uint32());
          continue;
        case 99611:
          if (tag !== 796890) {
            break;
          }

          message.end = Position.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Location {
    return {
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      start: isSet(object.start) ? Position.fromJSON(object.start) : undefined,
      end: isSet(object.end) ? Position.fromJSON(object.end) : undefined,
    };
  },

  toJSON(message: Location): unknown {
    const obj: any = {};
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.start !== undefined) {
      obj.start = Position.toJSON(message.start);
    }
    if (message.end !== undefined) {
      obj.end = Position.toJSON(message.end);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Location>, I>>(base?: I): Location {
    return Location.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Location>, I>>(object: I): Location {
    const message = createBaseLocation();
    message.path = object.path ?? "";
    message.start = (object.start !== undefined && object.start !== null)
      ? Position.fromPartial(object.start)
      : undefined;
    message.end = (object.end !== undefined && object.end !== null) ? Position.fromPartial(object.end) : undefined;
    return message;
  },
};

function createBaseCoreMatch(): CoreMatch {
  return { ruleId: "", location: undefined, extra: undefined };
}

export const CoreMatch = {
  encode(message: CoreMatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruleId !== "") {
      writer.uint32(338832714).string(message.ruleId);
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(1653688170).fork()).ldelim();
    }
    if (message.extra !== undefined) {
      CoreMatchExtra.encode(message.extra, writer.uint32(744744066).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CoreMatch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoreMatch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 42354089:
          if (tag !== 338832714) {
            break;
          }

          message.ruleId = reader.string();
          continue;
        case 206711021:
          if (tag !== 1653688170) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 93093008:
          if (tag !== 744744066) {
            break;
          }

          message.extra = CoreMatchExtra.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CoreMatch {
    return {
      ruleId: isSet(object.ruleId) ? globalThis.String(object.ruleId) : "",
      location: isSet(object.location) ? Location.fromJSON(object.location) : undefined,
      extra: isSet(object.extra) ? CoreMatchExtra.fromJSON(object.extra) : undefined,
    };
  },

  toJSON(message: CoreMatch): unknown {
    const obj: any = {};
    if (message.ruleId !== "") {
      obj.ruleId = message.ruleId;
    }
    if (message.location !== undefined) {
      obj.location = Location.toJSON(message.location);
    }
    if (message.extra !== undefined) {
      obj.extra = CoreMatchExtra.toJSON(message.extra);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CoreMatch>, I>>(base?: I): CoreMatch {
    return CoreMatch.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CoreMatch>, I>>(object: I): CoreMatch {
    const message = createBaseCoreMatch();
    message.ruleId = object.ruleId ?? "";
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.extra = (object.extra !== undefined && object.extra !== null)
      ? CoreMatchExtra.fromPartial(object.extra)
      : undefined;
    return message;
  },
};

function createBaseCoreMatchExtra(): CoreMatchExtra {
  return { message: "", metavars: {}, dataflowTrace: undefined };
}

export const CoreMatchExtra = {
  encode(message: CoreMatchExtra, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(2703991194).string(message.message);
    }
    Object.entries(message.metavars).forEach(([key, value]) => {
      CoreMatchExtra_MetavarsEntry.encode({ key: key as any, value }, writer.uint32(654517682).fork()).ldelim();
    });
    if (message.dataflowTrace !== undefined) {
      DataflowTrace.encode(message.dataflowTrace, writer.uint32(2874462522).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CoreMatchExtra {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoreMatchExtra();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 337998899:
          if (tag !== 2703991194) {
            break;
          }

          message.message = reader.string();
          continue;
        case 81814710:
          if (tag !== 654517682) {
            break;
          }

          const entry81814710 = CoreMatchExtra_MetavarsEntry.decode(reader, reader.uint32());
          if (entry81814710.value !== undefined) {
            message.metavars[entry81814710.key] = entry81814710.value;
          }
          continue;
        case 359307815:
          if (tag !== 2874462522) {
            break;
          }

          message.dataflowTrace = DataflowTrace.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CoreMatchExtra {
    return {
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      metavars: isObject(object.metavars)
        ? Object.entries(object.metavars).reduce<{ [key: string]: MetavarValue }>((acc, [key, value]) => {
          acc[key] = MetavarValue.fromJSON(value);
          return acc;
        }, {})
        : {},
      dataflowTrace: isSet(object.dataflowTrace) ? DataflowTrace.fromJSON(object.dataflowTrace) : undefined,
    };
  },

  toJSON(message: CoreMatchExtra): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.metavars) {
      const entries = Object.entries(message.metavars);
      if (entries.length > 0) {
        obj.metavars = {};
        entries.forEach(([k, v]) => {
          obj.metavars[k] = MetavarValue.toJSON(v);
        });
      }
    }
    if (message.dataflowTrace !== undefined) {
      obj.dataflowTrace = DataflowTrace.toJSON(message.dataflowTrace);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CoreMatchExtra>, I>>(base?: I): CoreMatchExtra {
    return CoreMatchExtra.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CoreMatchExtra>, I>>(object: I): CoreMatchExtra {
    const message = createBaseCoreMatchExtra();
    message.message = object.message ?? "";
    message.metavars = Object.entries(object.metavars ?? {}).reduce<{ [key: string]: MetavarValue }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = MetavarValue.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.dataflowTrace = (object.dataflowTrace !== undefined && object.dataflowTrace !== null)
      ? DataflowTrace.fromPartial(object.dataflowTrace)
      : undefined;
    return message;
  },
};

function createBaseCoreMatchExtra_MetavarsEntry(): CoreMatchExtra_MetavarsEntry {
  return { key: "", value: undefined };
}

export const CoreMatchExtra_MetavarsEntry = {
  encode(message: CoreMatchExtra_MetavarsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      MetavarValue.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CoreMatchExtra_MetavarsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoreMatchExtra_MetavarsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = MetavarValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CoreMatchExtra_MetavarsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? MetavarValue.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: CoreMatchExtra_MetavarsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = MetavarValue.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CoreMatchExtra_MetavarsEntry>, I>>(base?: I): CoreMatchExtra_MetavarsEntry {
    return CoreMatchExtra_MetavarsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CoreMatchExtra_MetavarsEntry>, I>>(object: I): CoreMatchExtra_MetavarsEntry {
    const message = createBaseCoreMatchExtra_MetavarsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? MetavarValue.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDataflowTrace(): DataflowTrace {
  return { taintSource: [], intermediateVars: [] };
}

export const DataflowTrace = {
  encode(message: DataflowTrace, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.taintSource) {
      Location.encode(v!, writer.uint32(876812594).fork()).ldelim();
    }
    for (const v of message.intermediateVars) {
      Location.encode(v!, writer.uint32(2469655834).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataflowTrace {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataflowTrace();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 109601574:
          if (tag !== 876812594) {
            break;
          }

          message.taintSource.push(Location.decode(reader, reader.uint32()));
          continue;
        case 308706979:
          if (tag !== 2469655834) {
            break;
          }

          message.intermediateVars.push(Location.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataflowTrace {
    return {
      taintSource: globalThis.Array.isArray(object?.taintSource)
        ? object.taintSource.map((e: any) => Location.fromJSON(e))
        : [],
      intermediateVars: globalThis.Array.isArray(object?.intermediateVars)
        ? object.intermediateVars.map((e: any) => Location.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DataflowTrace): unknown {
    const obj: any = {};
    if (message.taintSource?.length) {
      obj.taintSource = message.taintSource.map((e) => Location.toJSON(e));
    }
    if (message.intermediateVars?.length) {
      obj.intermediateVars = message.intermediateVars.map((e) => Location.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DataflowTrace>, I>>(base?: I): DataflowTrace {
    return DataflowTrace.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DataflowTrace>, I>>(object: I): DataflowTrace {
    const message = createBaseDataflowTrace();
    message.taintSource = object.taintSource?.map((e) => Location.fromPartial(e)) || [];
    message.intermediateVars = object.intermediateVars?.map((e) => Location.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMetavarValue(): MetavarValue {
  return { start: undefined, end: undefined, abstractContent: "", propagatedValue: undefined, uniqueId: undefined };
}

export const MetavarValue = {
  encode(message: MetavarValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.start !== undefined) {
      Position.encode(message.start, writer.uint32(884972306).fork()).ldelim();
    }
    if (message.end !== undefined) {
      Position.encode(message.end, writer.uint32(796890).fork()).ldelim();
    }
    if (message.abstractContent !== "") {
      writer.uint32(601859962).string(message.abstractContent);
    }
    if (message.propagatedValue !== undefined) {
      SvalueValue.encode(message.propagatedValue, writer.uint32(2159928738).fork()).ldelim();
    }
    if (message.uniqueId !== undefined) {
      UniqueId.encode(message.uniqueId, writer.uint32(3561188858).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MetavarValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMetavarValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 110621538:
          if (tag !== 884972306) {
            break;
          }

          message.start = Position.decode(reader, reader.uint32());
          continue;
        case 99611:
          if (tag !== 796890) {
            break;
          }

          message.end = Position.decode(reader, reader.uint32());
          continue;
        case 75232495:
          if (tag !== 601859962) {
            break;
          }

          message.abstractContent = reader.string();
          continue;
        case 269991092:
          if (tag !== 2159928738) {
            break;
          }

          message.propagatedValue = SvalueValue.decode(reader, reader.uint32());
          continue;
        case 445148607:
          if (tag !== 3561188858) {
            break;
          }

          message.uniqueId = UniqueId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MetavarValue {
    return {
      start: isSet(object.start) ? Position.fromJSON(object.start) : undefined,
      end: isSet(object.end) ? Position.fromJSON(object.end) : undefined,
      abstractContent: isSet(object.abstractContent) ? globalThis.String(object.abstractContent) : "",
      propagatedValue: isSet(object.propagatedValue) ? SvalueValue.fromJSON(object.propagatedValue) : undefined,
      uniqueId: isSet(object.uniqueId) ? UniqueId.fromJSON(object.uniqueId) : undefined,
    };
  },

  toJSON(message: MetavarValue): unknown {
    const obj: any = {};
    if (message.start !== undefined) {
      obj.start = Position.toJSON(message.start);
    }
    if (message.end !== undefined) {
      obj.end = Position.toJSON(message.end);
    }
    if (message.abstractContent !== "") {
      obj.abstractContent = message.abstractContent;
    }
    if (message.propagatedValue !== undefined) {
      obj.propagatedValue = SvalueValue.toJSON(message.propagatedValue);
    }
    if (message.uniqueId !== undefined) {
      obj.uniqueId = UniqueId.toJSON(message.uniqueId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MetavarValue>, I>>(base?: I): MetavarValue {
    return MetavarValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MetavarValue>, I>>(object: I): MetavarValue {
    const message = createBaseMetavarValue();
    message.start = (object.start !== undefined && object.start !== null)
      ? Position.fromPartial(object.start)
      : undefined;
    message.end = (object.end !== undefined && object.end !== null) ? Position.fromPartial(object.end) : undefined;
    message.abstractContent = object.abstractContent ?? "";
    message.propagatedValue = (object.propagatedValue !== undefined && object.propagatedValue !== null)
      ? SvalueValue.fromPartial(object.propagatedValue)
      : undefined;
    message.uniqueId = (object.uniqueId !== undefined && object.uniqueId !== null)
      ? UniqueId.fromPartial(object.uniqueId)
      : undefined;
    return message;
  },
};

function createBaseSvalueValue(): SvalueValue {
  return { svalueStart: undefined, svalueEnd: undefined, svalueAbstractContent: "" };
}

export const SvalueValue = {
  encode(message: SvalueValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.svalueStart !== undefined) {
      Position.encode(message.svalueStart, writer.uint32(3442342314).fork()).ldelim();
    }
    if (message.svalueEnd !== undefined) {
      Position.encode(message.svalueEnd, writer.uint32(2504655050).fork()).ldelim();
    }
    if (message.svalueAbstractContent !== "") {
      writer.uint32(4208813378).string(message.svalueAbstractContent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SvalueValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSvalueValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 430292789:
          if (tag !== 3442342314) {
            break;
          }

          message.svalueStart = Position.decode(reader, reader.uint32());
          continue;
        case 313081881:
          if (tag !== 2504655050) {
            break;
          }

          message.svalueEnd = Position.decode(reader, reader.uint32());
          continue;
        case 526101672:
          if (tag !== 4208813378) {
            break;
          }

          message.svalueAbstractContent = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SvalueValue {
    return {
      svalueStart: isSet(object.svalueStart) ? Position.fromJSON(object.svalueStart) : undefined,
      svalueEnd: isSet(object.svalueEnd) ? Position.fromJSON(object.svalueEnd) : undefined,
      svalueAbstractContent: isSet(object.svalueAbstractContent) ? globalThis.String(object.svalueAbstractContent) : "",
    };
  },

  toJSON(message: SvalueValue): unknown {
    const obj: any = {};
    if (message.svalueStart !== undefined) {
      obj.svalueStart = Position.toJSON(message.svalueStart);
    }
    if (message.svalueEnd !== undefined) {
      obj.svalueEnd = Position.toJSON(message.svalueEnd);
    }
    if (message.svalueAbstractContent !== "") {
      obj.svalueAbstractContent = message.svalueAbstractContent;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SvalueValue>, I>>(base?: I): SvalueValue {
    return SvalueValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SvalueValue>, I>>(object: I): SvalueValue {
    const message = createBaseSvalueValue();
    message.svalueStart = (object.svalueStart !== undefined && object.svalueStart !== null)
      ? Position.fromPartial(object.svalueStart)
      : undefined;
    message.svalueEnd = (object.svalueEnd !== undefined && object.svalueEnd !== null)
      ? Position.fromPartial(object.svalueEnd)
      : undefined;
    message.svalueAbstractContent = object.svalueAbstractContent ?? "";
    return message;
  },
};

function createBaseUniqueId(): UniqueId {
  return { type: undefined, md5sum: "", sid: 0 };
}

export const UniqueId = {
  encode(message: UniqueId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== undefined) {
      Any.encode(message.type, writer.uint32(24963122).fork()).ldelim();
    }
    if (message.md5sum !== "") {
      writer.uint32(87072458).string(message.md5sum);
    }
    if (message.sid !== 0) {
      writer.uint32(795760).int64(message.sid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UniqueId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUniqueId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3120390:
          if (tag !== 24963122) {
            break;
          }

          message.type = Any.decode(reader, reader.uint32());
          continue;
        case 10884057:
          if (tag !== 87072458) {
            break;
          }

          message.md5sum = reader.string();
          continue;
        case 99470:
          if (tag !== 795760) {
            break;
          }

          message.sid = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UniqueId {
    return {
      type: isSet(object.type) ? Any.fromJSON(object.type) : undefined,
      md5sum: isSet(object.md5sum) ? globalThis.String(object.md5sum) : "",
      sid: isSet(object.sid) ? globalThis.Number(object.sid) : 0,
    };
  },

  toJSON(message: UniqueId): unknown {
    const obj: any = {};
    if (message.type !== undefined) {
      obj.type = Any.toJSON(message.type);
    }
    if (message.md5sum !== "") {
      obj.md5sum = message.md5sum;
    }
    if (message.sid !== 0) {
      obj.sid = Math.round(message.sid);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UniqueId>, I>>(base?: I): UniqueId {
    return UniqueId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UniqueId>, I>>(object: I): UniqueId {
    const message = createBaseUniqueId();
    message.type = (object.type !== undefined && object.type !== null) ? Any.fromPartial(object.type) : undefined;
    message.md5sum = object.md5sum ?? "";
    message.sid = object.sid ?? 0;
    return message;
  },
};

function createBaseCoreError(): CoreError {
  return { ruleId: "", errorType: undefined, severity: undefined, location: undefined, message: "", details: "" };
}

export const CoreError = {
  encode(message: CoreError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruleId !== "") {
      writer.uint32(338832714).string(message.ruleId);
    }
    if (message.errorType !== undefined) {
      Any.encode(message.errorType, writer.uint32(3431465074).fork()).ldelim();
    }
    if (message.severity !== undefined) {
      Any.encode(message.severity, writer.uint32(1528909066).fork()).ldelim();
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(1653688170).fork()).ldelim();
    }
    if (message.message !== "") {
      writer.uint32(2703991194).string(message.message);
    }
    if (message.details !== "") {
      writer.uint32(225177650).string(message.details);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CoreError {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoreError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 42354089:
          if (tag !== 338832714) {
            break;
          }

          message.ruleId = reader.string();
          continue;
        case 428933134:
          if (tag !== 3431465074) {
            break;
          }

          message.errorType = Any.decode(reader, reader.uint32());
          continue;
        case 191113633:
          if (tag !== 1528909066) {
            break;
          }

          message.severity = Any.decode(reader, reader.uint32());
          continue;
        case 206711021:
          if (tag !== 1653688170) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 337998899:
          if (tag !== 2703991194) {
            break;
          }

          message.message = reader.string();
          continue;
        case 28147206:
          if (tag !== 225177650) {
            break;
          }

          message.details = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CoreError {
    return {
      ruleId: isSet(object.ruleId) ? globalThis.String(object.ruleId) : "",
      errorType: isSet(object.errorType) ? Any.fromJSON(object.errorType) : undefined,
      severity: isSet(object.severity) ? Any.fromJSON(object.severity) : undefined,
      location: isSet(object.location) ? Location.fromJSON(object.location) : undefined,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      details: isSet(object.details) ? globalThis.String(object.details) : "",
    };
  },

  toJSON(message: CoreError): unknown {
    const obj: any = {};
    if (message.ruleId !== "") {
      obj.ruleId = message.ruleId;
    }
    if (message.errorType !== undefined) {
      obj.errorType = Any.toJSON(message.errorType);
    }
    if (message.severity !== undefined) {
      obj.severity = Any.toJSON(message.severity);
    }
    if (message.location !== undefined) {
      obj.location = Location.toJSON(message.location);
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.details !== "") {
      obj.details = message.details;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CoreError>, I>>(base?: I): CoreError {
    return CoreError.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CoreError>, I>>(object: I): CoreError {
    const message = createBaseCoreError();
    message.ruleId = object.ruleId ?? "";
    message.errorType = (object.errorType !== undefined && object.errorType !== null)
      ? Any.fromPartial(object.errorType)
      : undefined;
    message.severity = (object.severity !== undefined && object.severity !== null)
      ? Any.fromPartial(object.severity)
      : undefined;
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.message = object.message ?? "";
    message.details = object.details ?? "";
    return message;
  },
};

function createBaseCoreStats(): CoreStats {
  return { okfiles: 0, errorfiles: 0 };
}

export const CoreStats = {
  encode(message: CoreStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.okfiles !== 0) {
      writer.uint32(2940877552).int64(message.okfiles);
    }
    if (message.errorfiles !== 0) {
      writer.uint32(2993033696).int64(message.errorfiles);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CoreStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoreStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 367609694:
          if (tag !== 2940877552) {
            break;
          }

          message.okfiles = longToNumber(reader.int64() as Long);
          continue;
        case 374129212:
          if (tag !== 2993033696) {
            break;
          }

          message.errorfiles = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CoreStats {
    return {
      okfiles: isSet(object.okfiles) ? globalThis.Number(object.okfiles) : 0,
      errorfiles: isSet(object.errorfiles) ? globalThis.Number(object.errorfiles) : 0,
    };
  },

  toJSON(message: CoreStats): unknown {
    const obj: any = {};
    if (message.okfiles !== 0) {
      obj.okfiles = Math.round(message.okfiles);
    }
    if (message.errorfiles !== 0) {
      obj.errorfiles = Math.round(message.errorfiles);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CoreStats>, I>>(base?: I): CoreStats {
    return CoreStats.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CoreStats>, I>>(object: I): CoreStats {
    const message = createBaseCoreStats();
    message.okfiles = object.okfiles ?? 0;
    message.errorfiles = object.errorfiles ?? 0;
    return message;
  },
};

function createBaseSkippedTarget(): SkippedTarget {
  return { path: "", reason: undefined, details: "", ruleId: "" };
}

export const SkippedTarget = {
  encode(message: SkippedTarget, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.path !== "") {
      writer.uint32(25702874).string(message.path);
    }
    if (message.reason !== undefined) {
      Any.encode(message.reason, writer.uint32(272115218).fork()).ldelim();
    }
    if (message.details !== "") {
      writer.uint32(225177650).string(message.details);
    }
    if (message.ruleId !== "") {
      writer.uint32(338832714).string(message.ruleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SkippedTarget {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSkippedTarget();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3212859:
          if (tag !== 25702874) {
            break;
          }

          message.path = reader.string();
          continue;
        case 34014402:
          if (tag !== 272115218) {
            break;
          }

          message.reason = Any.decode(reader, reader.uint32());
          continue;
        case 28147206:
          if (tag !== 225177650) {
            break;
          }

          message.details = reader.string();
          continue;
        case 42354089:
          if (tag !== 338832714) {
            break;
          }

          message.ruleId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SkippedTarget {
    return {
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      reason: isSet(object.reason) ? Any.fromJSON(object.reason) : undefined,
      details: isSet(object.details) ? globalThis.String(object.details) : "",
      ruleId: isSet(object.ruleId) ? globalThis.String(object.ruleId) : "",
    };
  },

  toJSON(message: SkippedTarget): unknown {
    const obj: any = {};
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.reason !== undefined) {
      obj.reason = Any.toJSON(message.reason);
    }
    if (message.details !== "") {
      obj.details = message.details;
    }
    if (message.ruleId !== "") {
      obj.ruleId = message.ruleId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SkippedTarget>, I>>(base?: I): SkippedTarget {
    return SkippedTarget.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SkippedTarget>, I>>(object: I): SkippedTarget {
    const message = createBaseSkippedTarget();
    message.path = object.path ?? "";
    message.reason = (object.reason !== undefined && object.reason !== null)
      ? Any.fromPartial(object.reason)
      : undefined;
    message.details = object.details ?? "";
    message.ruleId = object.ruleId ?? "";
    return message;
  },
};

function createBaseSkippedRule(): SkippedRule {
  return { ruleId: "", details: "", position: undefined };
}

export const SkippedRule = {
  encode(message: SkippedRule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruleId !== "") {
      writer.uint32(338832714).string(message.ruleId);
    }
    if (message.details !== "") {
      writer.uint32(225177650).string(message.details);
    }
    if (message.position !== undefined) {
      Position.encode(message.position, writer.uint32(1655717834).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SkippedRule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSkippedRule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 42354089:
          if (tag !== 338832714) {
            break;
          }

          message.ruleId = reader.string();
          continue;
        case 28147206:
          if (tag !== 225177650) {
            break;
          }

          message.details = reader.string();
          continue;
        case 206964729:
          if (tag !== 1655717834) {
            break;
          }

          message.position = Position.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SkippedRule {
    return {
      ruleId: isSet(object.ruleId) ? globalThis.String(object.ruleId) : "",
      details: isSet(object.details) ? globalThis.String(object.details) : "",
      position: isSet(object.position) ? Position.fromJSON(object.position) : undefined,
    };
  },

  toJSON(message: SkippedRule): unknown {
    const obj: any = {};
    if (message.ruleId !== "") {
      obj.ruleId = message.ruleId;
    }
    if (message.details !== "") {
      obj.details = message.details;
    }
    if (message.position !== undefined) {
      obj.position = Position.toJSON(message.position);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SkippedRule>, I>>(base?: I): SkippedRule {
    return SkippedRule.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SkippedRule>, I>>(object: I): SkippedRule {
    const message = createBaseSkippedRule();
    message.ruleId = object.ruleId ?? "";
    message.details = object.details ?? "";
    message.position = (object.position !== undefined && object.position !== null)
      ? Position.fromPartial(object.position)
      : undefined;
    return message;
  },
};

function createBaseCoreTiming(): CoreTiming {
  return { targets: [], rules: [], rulesParseTime: 0 };
}

export const CoreTiming = {
  encode(message: CoreTiming, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.targets) {
      TargetTime.encode(v!, writer.uint32(2029304370).fork()).ldelim();
    }
    for (const v of message.rules) {
      writer.uint32(874570682).string(v!);
    }
    if (message.rulesParseTime !== 0) {
      writer.uint32(2941513317).float(message.rulesParseTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CoreTiming {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoreTiming();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 253663046:
          if (tag !== 2029304370) {
            break;
          }

          message.targets.push(TargetTime.decode(reader, reader.uint32()));
          continue;
        case 109321335:
          if (tag !== 874570682) {
            break;
          }

          message.rules.push(reader.string());
          continue;
        case 367689164:
          if (tag !== 2941513317) {
            break;
          }

          message.rulesParseTime = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CoreTiming {
    return {
      targets: globalThis.Array.isArray(object?.targets) ? object.targets.map((e: any) => TargetTime.fromJSON(e)) : [],
      rules: globalThis.Array.isArray(object?.rules) ? object.rules.map((e: any) => globalThis.String(e)) : [],
      rulesParseTime: isSet(object.rulesParseTime) ? globalThis.Number(object.rulesParseTime) : 0,
    };
  },

  toJSON(message: CoreTiming): unknown {
    const obj: any = {};
    if (message.targets?.length) {
      obj.targets = message.targets.map((e) => TargetTime.toJSON(e));
    }
    if (message.rules?.length) {
      obj.rules = message.rules;
    }
    if (message.rulesParseTime !== 0) {
      obj.rulesParseTime = message.rulesParseTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CoreTiming>, I>>(base?: I): CoreTiming {
    return CoreTiming.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CoreTiming>, I>>(object: I): CoreTiming {
    const message = createBaseCoreTiming();
    message.targets = object.targets?.map((e) => TargetTime.fromPartial(e)) || [];
    message.rules = object.rules?.map((e) => e) || [];
    message.rulesParseTime = object.rulesParseTime ?? 0;
    return message;
  },
};

function createBaseTargetTime(): TargetTime {
  return { path: "", ruleTimes: [], runTime: 0 };
}

export const TargetTime = {
  encode(message: TargetTime, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.path !== "") {
      writer.uint32(25702874).string(message.path);
    }
    for (const v of message.ruleTimes) {
      RuleTimes.encode(v!, writer.uint32(2819308770).fork()).ldelim();
    }
    if (message.runTime !== 0) {
      writer.uint32(3704811653).float(message.runTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TargetTime {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTargetTime();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3212859:
          if (tag !== 25702874) {
            break;
          }

          message.path = reader.string();
          continue;
        case 352413596:
          if (tag !== 2819308770) {
            break;
          }

          message.ruleTimes.push(RuleTimes.decode(reader, reader.uint32()));
          continue;
        case 463101456:
          if (tag !== 3704811653) {
            break;
          }

          message.runTime = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TargetTime {
    return {
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      ruleTimes: globalThis.Array.isArray(object?.ruleTimes)
        ? object.ruleTimes.map((e: any) => RuleTimes.fromJSON(e))
        : [],
      runTime: isSet(object.runTime) ? globalThis.Number(object.runTime) : 0,
    };
  },

  toJSON(message: TargetTime): unknown {
    const obj: any = {};
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.ruleTimes?.length) {
      obj.ruleTimes = message.ruleTimes.map((e) => RuleTimes.toJSON(e));
    }
    if (message.runTime !== 0) {
      obj.runTime = message.runTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TargetTime>, I>>(base?: I): TargetTime {
    return TargetTime.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TargetTime>, I>>(object: I): TargetTime {
    const message = createBaseTargetTime();
    message.path = object.path ?? "";
    message.ruleTimes = object.ruleTimes?.map((e) => RuleTimes.fromPartial(e)) || [];
    message.runTime = object.runTime ?? 0;
    return message;
  },
};

function createBaseRuleTimes(): RuleTimes {
  return { ruleId: "", parseTime: 0, matchTime: 0 };
}

export const RuleTimes = {
  encode(message: RuleTimes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruleId !== "") {
      writer.uint32(338832714).string(message.ruleId);
    }
    if (message.parseTime !== 0) {
      writer.uint32(4024134053).float(message.parseTime);
    }
    if (message.matchTime !== 0) {
      writer.uint32(4042500661).float(message.matchTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleTimes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleTimes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 42354089:
          if (tag !== 338832714) {
            break;
          }

          message.ruleId = reader.string();
          continue;
        case 503016756:
          if (tag !== 4024134053) {
            break;
          }

          message.parseTime = reader.float();
          continue;
        case 505312582:
          if (tag !== 4042500661) {
            break;
          }

          message.matchTime = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RuleTimes {
    return {
      ruleId: isSet(object.ruleId) ? globalThis.String(object.ruleId) : "",
      parseTime: isSet(object.parseTime) ? globalThis.Number(object.parseTime) : 0,
      matchTime: isSet(object.matchTime) ? globalThis.Number(object.matchTime) : 0,
    };
  },

  toJSON(message: RuleTimes): unknown {
    const obj: any = {};
    if (message.ruleId !== "") {
      obj.ruleId = message.ruleId;
    }
    if (message.parseTime !== 0) {
      obj.parseTime = message.parseTime;
    }
    if (message.matchTime !== 0) {
      obj.matchTime = message.matchTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleTimes>, I>>(base?: I): RuleTimes {
    return RuleTimes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RuleTimes>, I>>(object: I): RuleTimes {
    const message = createBaseRuleTimes();
    message.ruleId = object.ruleId ?? "";
    message.parseTime = object.parseTime ?? 0;
    message.matchTime = object.matchTime ?? 0;
    return message;
  },
};

function createBaseCveResult(): CveResult {
  return { url: "", filename: "", funcnames: [] };
}

export const CveResult = {
  encode(message: CveResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(859514).string(message.url);
    }
    if (message.filename !== "") {
      writer.uint32(1829628498).string(message.filename);
    }
    for (const v of message.funcnames) {
      writer.uint32(1124869330).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CveResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCveResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 107439:
          if (tag !== 859514) {
            break;
          }

          message.url = reader.string();
          continue;
        case 228703562:
          if (tag !== 1829628498) {
            break;
          }

          message.filename = reader.string();
          continue;
        case 140608666:
          if (tag !== 1124869330) {
            break;
          }

          message.funcnames.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CveResult {
    return {
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      filename: isSet(object.filename) ? globalThis.String(object.filename) : "",
      funcnames: globalThis.Array.isArray(object?.funcnames)
        ? object.funcnames.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: CveResult): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    if (message.funcnames?.length) {
      obj.funcnames = message.funcnames;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CveResult>, I>>(base?: I): CveResult {
    return CveResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CveResult>, I>>(object: I): CveResult {
    const message = createBaseCveResult();
    message.url = object.url ?? "";
    message.filename = object.filename ?? "";
    message.funcnames = object.funcnames?.map((e) => e) || [];
    return message;
  },
};

function createBaseCoreMatchResults(): CoreMatchResults {
  return { matches: [], errors: [], skipped: [], skippedRules: [], stats: undefined, time: undefined };
}

export const CoreMatchResults = {
  encode(message: CoreMatchResults, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.matches) {
      CoreMatch.encode(v!, writer.uint32(2910000050).fork()).ldelim();
    }
    for (const v of message.errors) {
      CoreError.encode(v!, writer.uint32(1438626266).fork()).ldelim();
    }
    for (const v of message.skipped) {
      SkippedTarget.encode(v!, writer.uint32(3845862098).fork()).ldelim();
    }
    for (const v of message.skippedRules) {
      SkippedRule.encode(v!, writer.uint32(444551490).fork()).ldelim();
    }
    if (message.stats !== undefined) {
      CoreStats.encode(message.stats, writer.uint32(878060794).fork()).ldelim();
    }
    if (message.time !== undefined) {
      CoreTiming.encode(message.time, writer.uint32(24936090).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CoreMatchResults {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoreMatchResults();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 363750006:
          if (tag !== 2910000050) {
            break;
          }

          message.matches.push(CoreMatch.decode(reader, reader.uint32()));
          continue;
        case 179828283:
          if (tag !== 1438626266) {
            break;
          }

          message.errors.push(CoreError.decode(reader, reader.uint32()));
          continue;
        case 480732762:
          if (tag !== 3845862098) {
            break;
          }

          message.skipped.push(SkippedTarget.decode(reader, reader.uint32()));
          continue;
        case 55568936:
          if (tag !== 444551490) {
            break;
          }

          message.skippedRules.push(SkippedRule.decode(reader, reader.uint32()));
          continue;
        case 109757599:
          if (tag !== 878060794) {
            break;
          }

          message.stats = CoreStats.decode(reader, reader.uint32());
          continue;
        case 3117011:
          if (tag !== 24936090) {
            break;
          }

          message.time = CoreTiming.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CoreMatchResults {
    return {
      matches: globalThis.Array.isArray(object?.matches) ? object.matches.map((e: any) => CoreMatch.fromJSON(e)) : [],
      errors: globalThis.Array.isArray(object?.errors) ? object.errors.map((e: any) => CoreError.fromJSON(e)) : [],
      skipped: globalThis.Array.isArray(object?.skipped)
        ? object.skipped.map((e: any) => SkippedTarget.fromJSON(e))
        : [],
      skippedRules: globalThis.Array.isArray(object?.skippedRules)
        ? object.skippedRules.map((e: any) => SkippedRule.fromJSON(e))
        : [],
      stats: isSet(object.stats) ? CoreStats.fromJSON(object.stats) : undefined,
      time: isSet(object.time) ? CoreTiming.fromJSON(object.time) : undefined,
    };
  },

  toJSON(message: CoreMatchResults): unknown {
    const obj: any = {};
    if (message.matches?.length) {
      obj.matches = message.matches.map((e) => CoreMatch.toJSON(e));
    }
    if (message.errors?.length) {
      obj.errors = message.errors.map((e) => CoreError.toJSON(e));
    }
    if (message.skipped?.length) {
      obj.skipped = message.skipped.map((e) => SkippedTarget.toJSON(e));
    }
    if (message.skippedRules?.length) {
      obj.skippedRules = message.skippedRules.map((e) => SkippedRule.toJSON(e));
    }
    if (message.stats !== undefined) {
      obj.stats = CoreStats.toJSON(message.stats);
    }
    if (message.time !== undefined) {
      obj.time = CoreTiming.toJSON(message.time);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CoreMatchResults>, I>>(base?: I): CoreMatchResults {
    return CoreMatchResults.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CoreMatchResults>, I>>(object: I): CoreMatchResults {
    const message = createBaseCoreMatchResults();
    message.matches = object.matches?.map((e) => CoreMatch.fromPartial(e)) || [];
    message.errors = object.errors?.map((e) => CoreError.fromPartial(e)) || [];
    message.skipped = object.skipped?.map((e) => SkippedTarget.fromPartial(e)) || [];
    message.skippedRules = object.skippedRules?.map((e) => SkippedRule.fromPartial(e)) || [];
    message.stats = (object.stats !== undefined && object.stats !== null)
      ? CoreStats.fromPartial(object.stats)
      : undefined;
    message.time = (object.time !== undefined && object.time !== null)
      ? CoreTiming.fromPartial(object.time)
      : undefined;
    return message;
  },
};

function createBaseCliError(): CliError {
  return {
    code: 0,
    level: "",
    type: "",
    ruleId: "",
    message: "",
    path: "",
    longMsg: "",
    shortMsg: "",
    spans: [],
    help: "",
  };
}

export const CliError = {
  encode(message: CliError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(24868248).int64(message.code);
    }
    if (message.level !== "") {
      writer.uint32(822926370).string(message.level);
    }
    if (message.type !== "") {
      writer.uint32(24963122).string(message.type);
    }
    if (message.ruleId !== "") {
      writer.uint32(338832714).string(message.ruleId);
    }
    if (message.message !== "") {
      writer.uint32(2703991194).string(message.message);
    }
    if (message.path !== "") {
      writer.uint32(25702874).string(message.path);
    }
    if (message.longMsg !== "") {
      writer.uint32(1938396962).string(message.longMsg);
    }
    if (message.shortMsg !== "") {
      writer.uint32(57763154).string(message.shortMsg);
    }
    for (const v of message.spans) {
      ErrorSpan.encode(v!, writer.uint32(876629834).fork()).ldelim();
    }
    if (message.help !== "") {
      writer.uint32(27548922).string(message.help);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliError {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3108531:
          if (tag !== 24868248) {
            break;
          }

          message.code = longToNumber(reader.int64() as Long);
          continue;
        case 102865796:
          if (tag !== 822926370) {
            break;
          }

          message.level = reader.string();
          continue;
        case 3120390:
          if (tag !== 24963122) {
            break;
          }

          message.type = reader.string();
          continue;
        case 42354089:
          if (tag !== 338832714) {
            break;
          }

          message.ruleId = reader.string();
          continue;
        case 337998899:
          if (tag !== 2703991194) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3212859:
          if (tag !== 25702874) {
            break;
          }

          message.path = reader.string();
          continue;
        case 242299620:
          if (tag !== 1938396962) {
            break;
          }

          message.longMsg = reader.string();
          continue;
        case 7220394:
          if (tag !== 57763154) {
            break;
          }

          message.shortMsg = reader.string();
          continue;
        case 109578729:
          if (tag !== 876629834) {
            break;
          }

          message.spans.push(ErrorSpan.decode(reader, reader.uint32()));
          continue;
        case 3443615:
          if (tag !== 27548922) {
            break;
          }

          message.help = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliError {
    return {
      code: isSet(object.code) ? globalThis.Number(object.code) : 0,
      level: isSet(object.level) ? globalThis.String(object.level) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      ruleId: isSet(object.ruleId) ? globalThis.String(object.ruleId) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      longMsg: isSet(object.longMsg) ? globalThis.String(object.longMsg) : "",
      shortMsg: isSet(object.shortMsg) ? globalThis.String(object.shortMsg) : "",
      spans: globalThis.Array.isArray(object?.spans) ? object.spans.map((e: any) => ErrorSpan.fromJSON(e)) : [],
      help: isSet(object.help) ? globalThis.String(object.help) : "",
    };
  },

  toJSON(message: CliError): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = Math.round(message.code);
    }
    if (message.level !== "") {
      obj.level = message.level;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.ruleId !== "") {
      obj.ruleId = message.ruleId;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.longMsg !== "") {
      obj.longMsg = message.longMsg;
    }
    if (message.shortMsg !== "") {
      obj.shortMsg = message.shortMsg;
    }
    if (message.spans?.length) {
      obj.spans = message.spans.map((e) => ErrorSpan.toJSON(e));
    }
    if (message.help !== "") {
      obj.help = message.help;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliError>, I>>(base?: I): CliError {
    return CliError.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliError>, I>>(object: I): CliError {
    const message = createBaseCliError();
    message.code = object.code ?? 0;
    message.level = object.level ?? "";
    message.type = object.type ?? "";
    message.ruleId = object.ruleId ?? "";
    message.message = object.message ?? "";
    message.path = object.path ?? "";
    message.longMsg = object.longMsg ?? "";
    message.shortMsg = object.shortMsg ?? "";
    message.spans = object.spans?.map((e) => ErrorSpan.fromPartial(e)) || [];
    message.help = object.help ?? "";
    return message;
  },
};

function createBaseErrorSpan(): ErrorSpan {
  return {
    file: "",
    start: undefined,
    end: undefined,
    sourceHash: "",
    configStart: undefined,
    configEnd: undefined,
    configPath: [],
    contextStart: undefined,
    contextEnd: undefined,
  };
}

export const ErrorSpan = {
  encode(message: ErrorSpan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.file !== "") {
      writer.uint32(24928290).string(message.file);
    }
    if (message.start !== undefined) {
      PositionBis.encode(message.start, writer.uint32(884972306).fork()).ldelim();
    }
    if (message.end !== undefined) {
      PositionBis.encode(message.end, writer.uint32(796890).fork()).ldelim();
    }
    if (message.sourceHash !== "") {
      writer.uint32(3296940426).string(message.sourceHash);
    }
    if (message.configStart !== undefined) {
      PositionBis.encode(message.configStart, writer.uint32(3810418826).fork()).ldelim();
    }
    if (message.configEnd !== undefined) {
      PositionBis.encode(message.configEnd, writer.uint32(2872731562).fork()).ldelim();
    }
    for (const v of message.configPath) {
      writer.uint32(2095422938).string(v!);
    }
    if (message.contextStart !== undefined) {
      PositionBis.encode(message.contextStart, writer.uint32(3399505258).fork()).ldelim();
    }
    if (message.contextEnd !== undefined) {
      PositionBis.encode(message.contextEnd, writer.uint32(101003802).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ErrorSpan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseErrorSpan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3116036:
          if (tag !== 24928290) {
            break;
          }

          message.file = reader.string();
          continue;
        case 110621538:
          if (tag !== 884972306) {
            break;
          }

          message.start = PositionBis.decode(reader, reader.uint32());
          continue;
        case 99611:
          if (tag !== 796890) {
            break;
          }

          message.end = PositionBis.decode(reader, reader.uint32());
          continue;
        case 412117553:
          if (tag !== 3296940426) {
            break;
          }

          message.sourceHash = reader.string();
          continue;
        case 476302353:
          if (tag !== 3810418826) {
            break;
          }

          message.configStart = PositionBis.decode(reader, reader.uint32());
          continue;
        case 359091445:
          if (tag !== 2872731562) {
            break;
          }

          message.configEnd = PositionBis.decode(reader, reader.uint32());
          continue;
        case 261927867:
          if (tag !== 2095422938) {
            break;
          }

          message.configPath.push(reader.string());
          continue;
        case 424938157:
          if (tag !== 3399505258) {
            break;
          }

          message.contextStart = PositionBis.decode(reader, reader.uint32());
          continue;
        case 12625475:
          if (tag !== 101003802) {
            break;
          }

          message.contextEnd = PositionBis.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ErrorSpan {
    return {
      file: isSet(object.file) ? globalThis.String(object.file) : "",
      start: isSet(object.start) ? PositionBis.fromJSON(object.start) : undefined,
      end: isSet(object.end) ? PositionBis.fromJSON(object.end) : undefined,
      sourceHash: isSet(object.sourceHash) ? globalThis.String(object.sourceHash) : "",
      configStart: isSet(object.configStart) ? PositionBis.fromJSON(object.configStart) : undefined,
      configEnd: isSet(object.configEnd) ? PositionBis.fromJSON(object.configEnd) : undefined,
      configPath: globalThis.Array.isArray(object?.configPath)
        ? object.configPath.map((e: any) => globalThis.String(e))
        : [],
      contextStart: isSet(object.contextStart) ? PositionBis.fromJSON(object.contextStart) : undefined,
      contextEnd: isSet(object.contextEnd) ? PositionBis.fromJSON(object.contextEnd) : undefined,
    };
  },

  toJSON(message: ErrorSpan): unknown {
    const obj: any = {};
    if (message.file !== "") {
      obj.file = message.file;
    }
    if (message.start !== undefined) {
      obj.start = PositionBis.toJSON(message.start);
    }
    if (message.end !== undefined) {
      obj.end = PositionBis.toJSON(message.end);
    }
    if (message.sourceHash !== "") {
      obj.sourceHash = message.sourceHash;
    }
    if (message.configStart !== undefined) {
      obj.configStart = PositionBis.toJSON(message.configStart);
    }
    if (message.configEnd !== undefined) {
      obj.configEnd = PositionBis.toJSON(message.configEnd);
    }
    if (message.configPath?.length) {
      obj.configPath = message.configPath;
    }
    if (message.contextStart !== undefined) {
      obj.contextStart = PositionBis.toJSON(message.contextStart);
    }
    if (message.contextEnd !== undefined) {
      obj.contextEnd = PositionBis.toJSON(message.contextEnd);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ErrorSpan>, I>>(base?: I): ErrorSpan {
    return ErrorSpan.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ErrorSpan>, I>>(object: I): ErrorSpan {
    const message = createBaseErrorSpan();
    message.file = object.file ?? "";
    message.start = (object.start !== undefined && object.start !== null)
      ? PositionBis.fromPartial(object.start)
      : undefined;
    message.end = (object.end !== undefined && object.end !== null) ? PositionBis.fromPartial(object.end) : undefined;
    message.sourceHash = object.sourceHash ?? "";
    message.configStart = (object.configStart !== undefined && object.configStart !== null)
      ? PositionBis.fromPartial(object.configStart)
      : undefined;
    message.configEnd = (object.configEnd !== undefined && object.configEnd !== null)
      ? PositionBis.fromPartial(object.configEnd)
      : undefined;
    message.configPath = object.configPath?.map((e) => e) || [];
    message.contextStart = (object.contextStart !== undefined && object.contextStart !== null)
      ? PositionBis.fromPartial(object.contextStart)
      : undefined;
    message.contextEnd = (object.contextEnd !== undefined && object.contextEnd !== null)
      ? PositionBis.fromPartial(object.contextEnd)
      : undefined;
    return message;
  },
};

function createBasePositionBis(): PositionBis {
  return { line: 0, col: 0 };
}

export const PositionBis = {
  encode(message: PositionBis, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.line !== 0) {
      writer.uint32(24943712).int64(message.line);
    }
    if (message.col !== 0) {
      writer.uint32(858624).int64(message.col);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PositionBis {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePositionBis();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3117964:
          if (tag !== 24943712) {
            break;
          }

          message.line = longToNumber(reader.int64() as Long);
          continue;
        case 107328:
          if (tag !== 858624) {
            break;
          }

          message.col = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PositionBis {
    return {
      line: isSet(object.line) ? globalThis.Number(object.line) : 0,
      col: isSet(object.col) ? globalThis.Number(object.col) : 0,
    };
  },

  toJSON(message: PositionBis): unknown {
    const obj: any = {};
    if (message.line !== 0) {
      obj.line = Math.round(message.line);
    }
    if (message.col !== 0) {
      obj.col = Math.round(message.col);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PositionBis>, I>>(base?: I): PositionBis {
    return PositionBis.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PositionBis>, I>>(object: I): PositionBis {
    const message = createBasePositionBis();
    message.line = object.line ?? 0;
    message.col = object.col ?? 0;
    return message;
  },
};

function createBaseCliMatch(): CliMatch {
  return { checkId: "", path: "", start: undefined, end: undefined, extra: undefined };
}

export const CliMatch = {
  encode(message: CliMatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.checkId !== "") {
      writer.uint32(1955938858).string(message.checkId);
    }
    if (message.path !== "") {
      writer.uint32(25702874).string(message.path);
    }
    if (message.start !== undefined) {
      Position.encode(message.start, writer.uint32(884972306).fork()).ldelim();
    }
    if (message.end !== undefined) {
      Position.encode(message.end, writer.uint32(796890).fork()).ldelim();
    }
    if (message.extra !== undefined) {
      CliMatchExtra.encode(message.extra, writer.uint32(744744066).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliMatch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliMatch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 244492357:
          if (tag !== 1955938858) {
            break;
          }

          message.checkId = reader.string();
          continue;
        case 3212859:
          if (tag !== 25702874) {
            break;
          }

          message.path = reader.string();
          continue;
        case 110621538:
          if (tag !== 884972306) {
            break;
          }

          message.start = Position.decode(reader, reader.uint32());
          continue;
        case 99611:
          if (tag !== 796890) {
            break;
          }

          message.end = Position.decode(reader, reader.uint32());
          continue;
        case 93093008:
          if (tag !== 744744066) {
            break;
          }

          message.extra = CliMatchExtra.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliMatch {
    return {
      checkId: isSet(object.checkId) ? globalThis.String(object.checkId) : "",
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      start: isSet(object.start) ? Position.fromJSON(object.start) : undefined,
      end: isSet(object.end) ? Position.fromJSON(object.end) : undefined,
      extra: isSet(object.extra) ? CliMatchExtra.fromJSON(object.extra) : undefined,
    };
  },

  toJSON(message: CliMatch): unknown {
    const obj: any = {};
    if (message.checkId !== "") {
      obj.checkId = message.checkId;
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.start !== undefined) {
      obj.start = Position.toJSON(message.start);
    }
    if (message.end !== undefined) {
      obj.end = Position.toJSON(message.end);
    }
    if (message.extra !== undefined) {
      obj.extra = CliMatchExtra.toJSON(message.extra);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliMatch>, I>>(base?: I): CliMatch {
    return CliMatch.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliMatch>, I>>(object: I): CliMatch {
    const message = createBaseCliMatch();
    message.checkId = object.checkId ?? "";
    message.path = object.path ?? "";
    message.start = (object.start !== undefined && object.start !== null)
      ? Position.fromPartial(object.start)
      : undefined;
    message.end = (object.end !== undefined && object.end !== null) ? Position.fromPartial(object.end) : undefined;
    message.extra = (object.extra !== undefined && object.extra !== null)
      ? CliMatchExtra.fromPartial(object.extra)
      : undefined;
    return message;
  },
};

function createBaseCliMatchExtra(): CliMatchExtra {
  return {
    metavars: {},
    fingerprint: "",
    lines: "",
    message: "",
    metadata: undefined,
    severity: "",
    fix: "",
    fixRegex: undefined,
    isIgnored: false,
    dependencyMatchOnly: false,
    dependencyMatches: undefined,
    fixedLines: [],
    dataflowTrace: undefined,
  };
}

export const CliMatchExtra = {
  encode(message: CliMatchExtra, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.metavars).forEach(([key, value]) => {
      CliMatchExtra_MetavarsEntry.encode({ key: key as any, value }, writer.uint32(654517682).fork()).ldelim();
    });
    if (message.fingerprint !== "") {
      writer.uint32(3412489210).string(message.fingerprint);
    }
    if (message.lines !== "") {
      writer.uint32(874583034).string(message.lines);
    }
    if (message.message !== "") {
      writer.uint32(2703991194).string(message.message);
    }
    if (message.metadata !== undefined) {
      Any.encode(message.metadata, writer.uint32(4275062530).fork()).ldelim();
    }
    if (message.severity !== "") {
      writer.uint32(1528909066).string(message.severity);
    }
    if (message.fix !== "") {
      writer.uint32(949418).string(message.fix);
    }
    if (message.fixRegex !== undefined) {
      FixRegex.encode(message.fixRegex, writer.uint32(3135500674).fork()).ldelim();
    }
    if (message.isIgnored !== false) {
      writer.uint32(4249384584).bool(message.isIgnored);
    }
    if (message.dependencyMatchOnly !== false) {
      writer.uint32(3034766472).bool(message.dependencyMatchOnly);
    }
    if (message.dependencyMatches !== undefined) {
      Any.encode(message.dependencyMatches, writer.uint32(2309768258).fork()).ldelim();
    }
    for (const v of message.fixedLines) {
      writer.uint32(3244776578).string(v!);
    }
    if (message.dataflowTrace !== undefined) {
      DataflowTrace.encode(message.dataflowTrace, writer.uint32(2874462522).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliMatchExtra {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliMatchExtra();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 81814710:
          if (tag !== 654517682) {
            break;
          }

          const entry81814710 = CliMatchExtra_MetavarsEntry.decode(reader, reader.uint32());
          if (entry81814710.value !== undefined) {
            message.metavars[entry81814710.key] = entry81814710.value;
          }
          continue;
        case 426561151:
          if (tag !== 3412489210) {
            break;
          }

          message.fingerprint = reader.string();
          continue;
        case 109322879:
          if (tag !== 874583034) {
            break;
          }

          message.lines = reader.string();
          continue;
        case 337998899:
          if (tag !== 2703991194) {
            break;
          }

          message.message = reader.string();
          continue;
        case 534382816:
          if (tag !== 4275062530) {
            break;
          }

          message.metadata = Any.decode(reader, reader.uint32());
          continue;
        case 191113633:
          if (tag !== 1528909066) {
            break;
          }

          message.severity = reader.string();
          continue;
        case 118677:
          if (tag !== 949418) {
            break;
          }

          message.fix = reader.string();
          continue;
        case 391937584:
          if (tag !== 3135500674) {
            break;
          }

          message.fixRegex = FixRegex.decode(reader, reader.uint32());
          continue;
        case 531173073:
          if (tag !== 4249384584) {
            break;
          }

          message.isIgnored = reader.bool();
          continue;
        case 379345809:
          if (tag !== 3034766472) {
            break;
          }

          message.dependencyMatchOnly = reader.bool();
          continue;
        case 288721032:
          if (tag !== 2309768258) {
            break;
          }

          message.dependencyMatches = Any.decode(reader, reader.uint32());
          continue;
        case 405597072:
          if (tag !== 3244776578) {
            break;
          }

          message.fixedLines.push(reader.string());
          continue;
        case 359307815:
          if (tag !== 2874462522) {
            break;
          }

          message.dataflowTrace = DataflowTrace.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliMatchExtra {
    return {
      metavars: isObject(object.metavars)
        ? Object.entries(object.metavars).reduce<{ [key: string]: MetavarValue }>((acc, [key, value]) => {
          acc[key] = MetavarValue.fromJSON(value);
          return acc;
        }, {})
        : {},
      fingerprint: isSet(object.fingerprint) ? globalThis.String(object.fingerprint) : "",
      lines: isSet(object.lines) ? globalThis.String(object.lines) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      metadata: isSet(object.metadata) ? Any.fromJSON(object.metadata) : undefined,
      severity: isSet(object.severity) ? globalThis.String(object.severity) : "",
      fix: isSet(object.fix) ? globalThis.String(object.fix) : "",
      fixRegex: isSet(object.fixRegex) ? FixRegex.fromJSON(object.fixRegex) : undefined,
      isIgnored: isSet(object.isIgnored) ? globalThis.Boolean(object.isIgnored) : false,
      dependencyMatchOnly: isSet(object.dependencyMatchOnly) ? globalThis.Boolean(object.dependencyMatchOnly) : false,
      dependencyMatches: isSet(object.dependencyMatches) ? Any.fromJSON(object.dependencyMatches) : undefined,
      fixedLines: globalThis.Array.isArray(object?.fixedLines)
        ? object.fixedLines.map((e: any) => globalThis.String(e))
        : [],
      dataflowTrace: isSet(object.dataflowTrace) ? DataflowTrace.fromJSON(object.dataflowTrace) : undefined,
    };
  },

  toJSON(message: CliMatchExtra): unknown {
    const obj: any = {};
    if (message.metavars) {
      const entries = Object.entries(message.metavars);
      if (entries.length > 0) {
        obj.metavars = {};
        entries.forEach(([k, v]) => {
          obj.metavars[k] = MetavarValue.toJSON(v);
        });
      }
    }
    if (message.fingerprint !== "") {
      obj.fingerprint = message.fingerprint;
    }
    if (message.lines !== "") {
      obj.lines = message.lines;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Any.toJSON(message.metadata);
    }
    if (message.severity !== "") {
      obj.severity = message.severity;
    }
    if (message.fix !== "") {
      obj.fix = message.fix;
    }
    if (message.fixRegex !== undefined) {
      obj.fixRegex = FixRegex.toJSON(message.fixRegex);
    }
    if (message.isIgnored !== false) {
      obj.isIgnored = message.isIgnored;
    }
    if (message.dependencyMatchOnly !== false) {
      obj.dependencyMatchOnly = message.dependencyMatchOnly;
    }
    if (message.dependencyMatches !== undefined) {
      obj.dependencyMatches = Any.toJSON(message.dependencyMatches);
    }
    if (message.fixedLines?.length) {
      obj.fixedLines = message.fixedLines;
    }
    if (message.dataflowTrace !== undefined) {
      obj.dataflowTrace = DataflowTrace.toJSON(message.dataflowTrace);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliMatchExtra>, I>>(base?: I): CliMatchExtra {
    return CliMatchExtra.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliMatchExtra>, I>>(object: I): CliMatchExtra {
    const message = createBaseCliMatchExtra();
    message.metavars = Object.entries(object.metavars ?? {}).reduce<{ [key: string]: MetavarValue }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = MetavarValue.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.fingerprint = object.fingerprint ?? "";
    message.lines = object.lines ?? "";
    message.message = object.message ?? "";
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Any.fromPartial(object.metadata)
      : undefined;
    message.severity = object.severity ?? "";
    message.fix = object.fix ?? "";
    message.fixRegex = (object.fixRegex !== undefined && object.fixRegex !== null)
      ? FixRegex.fromPartial(object.fixRegex)
      : undefined;
    message.isIgnored = object.isIgnored ?? false;
    message.dependencyMatchOnly = object.dependencyMatchOnly ?? false;
    message.dependencyMatches = (object.dependencyMatches !== undefined && object.dependencyMatches !== null)
      ? Any.fromPartial(object.dependencyMatches)
      : undefined;
    message.fixedLines = object.fixedLines?.map((e) => e) || [];
    message.dataflowTrace = (object.dataflowTrace !== undefined && object.dataflowTrace !== null)
      ? DataflowTrace.fromPartial(object.dataflowTrace)
      : undefined;
    return message;
  },
};

function createBaseCliMatchExtra_MetavarsEntry(): CliMatchExtra_MetavarsEntry {
  return { key: "", value: undefined };
}

export const CliMatchExtra_MetavarsEntry = {
  encode(message: CliMatchExtra_MetavarsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      MetavarValue.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliMatchExtra_MetavarsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliMatchExtra_MetavarsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = MetavarValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliMatchExtra_MetavarsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? MetavarValue.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: CliMatchExtra_MetavarsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = MetavarValue.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliMatchExtra_MetavarsEntry>, I>>(base?: I): CliMatchExtra_MetavarsEntry {
    return CliMatchExtra_MetavarsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliMatchExtra_MetavarsEntry>, I>>(object: I): CliMatchExtra_MetavarsEntry {
    const message = createBaseCliMatchExtra_MetavarsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? MetavarValue.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFixRegex(): FixRegex {
  return { regex: "", replacement: "", count: 0 };
}

export const FixRegex = {
  encode(message: FixRegex, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.regex !== "") {
      writer.uint32(911469114).string(message.regex);
    }
    if (message.replacement !== "") {
      writer.uint32(168710210).string(message.replacement);
    }
    if (message.count !== 0) {
      writer.uint32(884171384).int64(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FixRegex {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFixRegex();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 113933639:
          if (tag !== 911469114) {
            break;
          }

          message.regex = reader.string();
          continue;
        case 21088776:
          if (tag !== 168710210) {
            break;
          }

          message.replacement = reader.string();
          continue;
        case 110521423:
          if (tag !== 884171384) {
            break;
          }

          message.count = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FixRegex {
    return {
      regex: isSet(object.regex) ? globalThis.String(object.regex) : "",
      replacement: isSet(object.replacement) ? globalThis.String(object.replacement) : "",
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
    };
  },

  toJSON(message: FixRegex): unknown {
    const obj: any = {};
    if (message.regex !== "") {
      obj.regex = message.regex;
    }
    if (message.replacement !== "") {
      obj.replacement = message.replacement;
    }
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FixRegex>, I>>(base?: I): FixRegex {
    return FixRegex.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FixRegex>, I>>(object: I): FixRegex {
    const message = createBaseFixRegex();
    message.regex = object.regex ?? "";
    message.replacement = object.replacement ?? "";
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseCliOutputExtra(): CliOutputExtra {
  return { paths: undefined, time: undefined };
}

export const CliOutputExtra = {
  encode(message: CliOutputExtra, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.paths !== undefined) {
      CliPaths.encode(message.paths, writer.uint32(875342194).fork()).ldelim();
    }
    if (message.time !== undefined) {
      CliTiming.encode(message.time, writer.uint32(24936090).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliOutputExtra {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliOutputExtra();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 109417774:
          if (tag !== 875342194) {
            break;
          }

          message.paths = CliPaths.decode(reader, reader.uint32());
          continue;
        case 3117011:
          if (tag !== 24936090) {
            break;
          }

          message.time = CliTiming.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliOutputExtra {
    return {
      paths: isSet(object.paths) ? CliPaths.fromJSON(object.paths) : undefined,
      time: isSet(object.time) ? CliTiming.fromJSON(object.time) : undefined,
    };
  },

  toJSON(message: CliOutputExtra): unknown {
    const obj: any = {};
    if (message.paths !== undefined) {
      obj.paths = CliPaths.toJSON(message.paths);
    }
    if (message.time !== undefined) {
      obj.time = CliTiming.toJSON(message.time);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliOutputExtra>, I>>(base?: I): CliOutputExtra {
    return CliOutputExtra.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliOutputExtra>, I>>(object: I): CliOutputExtra {
    const message = createBaseCliOutputExtra();
    message.paths = (object.paths !== undefined && object.paths !== null)
      ? CliPaths.fromPartial(object.paths)
      : undefined;
    message.time = (object.time !== undefined && object.time !== null) ? CliTiming.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseCliPaths(): CliPaths {
  return { scanned: [], Comment: "", skipped: [] };
}

export const CliPaths = {
  encode(message: CliPaths, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.scanned) {
      writer.uint32(3830545618).string(v!);
    }
    if (message.Comment !== "") {
      writer.uint32(4229212314).string(message.Comment);
    }
    for (const v of message.skipped) {
      CliSkippedTarget.encode(v!, writer.uint32(3845862098).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliPaths {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliPaths();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 478818202:
          if (tag !== 3830545618) {
            break;
          }

          message.scanned.push(reader.string());
          continue;
        case 528651539:
          if (tag !== 4229212314) {
            break;
          }

          message.Comment = reader.string();
          continue;
        case 480732762:
          if (tag !== 3845862098) {
            break;
          }

          message.skipped.push(CliSkippedTarget.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliPaths {
    return {
      scanned: globalThis.Array.isArray(object?.scanned) ? object.scanned.map((e: any) => globalThis.String(e)) : [],
      Comment: isSet(object.Comment) ? globalThis.String(object.Comment) : "",
      skipped: globalThis.Array.isArray(object?.skipped)
        ? object.skipped.map((e: any) => CliSkippedTarget.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CliPaths): unknown {
    const obj: any = {};
    if (message.scanned?.length) {
      obj.scanned = message.scanned;
    }
    if (message.Comment !== "") {
      obj.Comment = message.Comment;
    }
    if (message.skipped?.length) {
      obj.skipped = message.skipped.map((e) => CliSkippedTarget.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliPaths>, I>>(base?: I): CliPaths {
    return CliPaths.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliPaths>, I>>(object: I): CliPaths {
    const message = createBaseCliPaths();
    message.scanned = object.scanned?.map((e) => e) || [];
    message.Comment = object.Comment ?? "";
    message.skipped = object.skipped?.map((e) => CliSkippedTarget.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCliSkippedTarget(): CliSkippedTarget {
  return { path: "", reason: "" };
}

export const CliSkippedTarget = {
  encode(message: CliSkippedTarget, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.path !== "") {
      writer.uint32(25702874).string(message.path);
    }
    if (message.reason !== "") {
      writer.uint32(272115218).string(message.reason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliSkippedTarget {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliSkippedTarget();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3212859:
          if (tag !== 25702874) {
            break;
          }

          message.path = reader.string();
          continue;
        case 34014402:
          if (tag !== 272115218) {
            break;
          }

          message.reason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliSkippedTarget {
    return {
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      reason: isSet(object.reason) ? globalThis.String(object.reason) : "",
    };
  },

  toJSON(message: CliSkippedTarget): unknown {
    const obj: any = {};
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.reason !== "") {
      obj.reason = message.reason;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliSkippedTarget>, I>>(base?: I): CliSkippedTarget {
    return CliSkippedTarget.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliSkippedTarget>, I>>(object: I): CliSkippedTarget {
    const message = createBaseCliSkippedTarget();
    message.path = object.path ?? "";
    message.reason = object.reason ?? "";
    return message;
  },
};

function createBaseCliTiming(): CliTiming {
  return { rules: [], rulesParseTime: 0, profilingTimes: {}, targets: [], totalBytes: 0 };
}

export const CliTiming = {
  encode(message: CliTiming, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.rules) {
      RuleIdDict.encode(v!, writer.uint32(874570682).fork()).ldelim();
    }
    if (message.rulesParseTime !== 0) {
      writer.uint32(2941513317).float(message.rulesParseTime);
    }
    Object.entries(message.profilingTimes).forEach(([key, value]) => {
      CliTiming_ProfilingTimesEntry.encode({ key: key as any, value }, writer.uint32(1520872698).fork()).ldelim();
    });
    for (const v of message.targets) {
      CliTargetTimes.encode(v!, writer.uint32(2029304370).fork()).ldelim();
    }
    if (message.totalBytes !== 0) {
      writer.uint32(4200086872).int64(message.totalBytes);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliTiming {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliTiming();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 109321335:
          if (tag !== 874570682) {
            break;
          }

          message.rules.push(RuleIdDict.decode(reader, reader.uint32()));
          continue;
        case 367689164:
          if (tag !== 2941513317) {
            break;
          }

          message.rulesParseTime = reader.float();
          continue;
        case 190109087:
          if (tag !== 1520872698) {
            break;
          }

          const entry190109087 = CliTiming_ProfilingTimesEntry.decode(reader, reader.uint32());
          if (entry190109087.value !== undefined) {
            message.profilingTimes[entry190109087.key] = entry190109087.value;
          }
          continue;
        case 253663046:
          if (tag !== 2029304370) {
            break;
          }

          message.targets.push(CliTargetTimes.decode(reader, reader.uint32()));
          continue;
        case 525010859:
          if (tag !== 4200086872) {
            break;
          }

          message.totalBytes = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliTiming {
    return {
      rules: globalThis.Array.isArray(object?.rules) ? object.rules.map((e: any) => RuleIdDict.fromJSON(e)) : [],
      rulesParseTime: isSet(object.rulesParseTime) ? globalThis.Number(object.rulesParseTime) : 0,
      profilingTimes: isObject(object.profilingTimes)
        ? Object.entries(object.profilingTimes).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      targets: globalThis.Array.isArray(object?.targets)
        ? object.targets.map((e: any) => CliTargetTimes.fromJSON(e))
        : [],
      totalBytes: isSet(object.totalBytes) ? globalThis.Number(object.totalBytes) : 0,
    };
  },

  toJSON(message: CliTiming): unknown {
    const obj: any = {};
    if (message.rules?.length) {
      obj.rules = message.rules.map((e) => RuleIdDict.toJSON(e));
    }
    if (message.rulesParseTime !== 0) {
      obj.rulesParseTime = message.rulesParseTime;
    }
    if (message.profilingTimes) {
      const entries = Object.entries(message.profilingTimes);
      if (entries.length > 0) {
        obj.profilingTimes = {};
        entries.forEach(([k, v]) => {
          obj.profilingTimes[k] = v;
        });
      }
    }
    if (message.targets?.length) {
      obj.targets = message.targets.map((e) => CliTargetTimes.toJSON(e));
    }
    if (message.totalBytes !== 0) {
      obj.totalBytes = Math.round(message.totalBytes);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliTiming>, I>>(base?: I): CliTiming {
    return CliTiming.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliTiming>, I>>(object: I): CliTiming {
    const message = createBaseCliTiming();
    message.rules = object.rules?.map((e) => RuleIdDict.fromPartial(e)) || [];
    message.rulesParseTime = object.rulesParseTime ?? 0;
    message.profilingTimes = Object.entries(object.profilingTimes ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.targets = object.targets?.map((e) => CliTargetTimes.fromPartial(e)) || [];
    message.totalBytes = object.totalBytes ?? 0;
    return message;
  },
};

function createBaseCliTiming_ProfilingTimesEntry(): CliTiming_ProfilingTimesEntry {
  return { key: "", value: 0 };
}

export const CliTiming_ProfilingTimesEntry = {
  encode(message: CliTiming_ProfilingTimesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(21).float(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliTiming_ProfilingTimesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliTiming_ProfilingTimesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.value = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliTiming_ProfilingTimesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: CliTiming_ProfilingTimesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliTiming_ProfilingTimesEntry>, I>>(base?: I): CliTiming_ProfilingTimesEntry {
    return CliTiming_ProfilingTimesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliTiming_ProfilingTimesEntry>, I>>(
    object: I,
  ): CliTiming_ProfilingTimesEntry {
    const message = createBaseCliTiming_ProfilingTimesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseRuleIdDict(): RuleIdDict {
  return { id: "" };
}

export const RuleIdDict = {
  encode(message: RuleIdDict, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(25642).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleIdDict {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleIdDict();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3205:
          if (tag !== 25642) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RuleIdDict {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: RuleIdDict): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleIdDict>, I>>(base?: I): RuleIdDict {
    return RuleIdDict.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RuleIdDict>, I>>(object: I): RuleIdDict {
    const message = createBaseRuleIdDict();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseCliTargetTimes(): CliTargetTimes {
  return { path: "", numBytes: 0, matchTimes: [], parseTimes: [], runTime: 0 };
}

export const CliTargetTimes = {
  encode(message: CliTargetTimes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.path !== "") {
      writer.uint32(25702874).string(message.path);
    }
    if (message.numBytes !== 0) {
      writer.uint32(397678760).int64(message.numBytes);
    }
    writer.uint32(1519234570).fork();
    for (const v of message.matchTimes) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(1500867962).fork();
    for (const v of message.parseTimes) {
      writer.float(v);
    }
    writer.ldelim();
    if (message.runTime !== 0) {
      writer.uint32(3704811653).float(message.runTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CliTargetTimes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCliTargetTimes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3212859:
          if (tag !== 25702874) {
            break;
          }

          message.path = reader.string();
          continue;
        case 49709845:
          if (tag !== 397678760) {
            break;
          }

          message.numBytes = longToNumber(reader.int64() as Long);
          continue;
        case 189904321:
          if (tag === 1519234573) {
            message.matchTimes.push(reader.float());

            continue;
          }

          if (tag === 1519234570) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.matchTimes.push(reader.float());
            }

            continue;
          }

          break;
        case 187608495:
          if (tag === 1500867965) {
            message.parseTimes.push(reader.float());

            continue;
          }

          if (tag === 1500867962) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.parseTimes.push(reader.float());
            }

            continue;
          }

          break;
        case 463101456:
          if (tag !== 3704811653) {
            break;
          }

          message.runTime = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CliTargetTimes {
    return {
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      numBytes: isSet(object.numBytes) ? globalThis.Number(object.numBytes) : 0,
      matchTimes: globalThis.Array.isArray(object?.matchTimes)
        ? object.matchTimes.map((e: any) => globalThis.Number(e))
        : [],
      parseTimes: globalThis.Array.isArray(object?.parseTimes)
        ? object.parseTimes.map((e: any) => globalThis.Number(e))
        : [],
      runTime: isSet(object.runTime) ? globalThis.Number(object.runTime) : 0,
    };
  },

  toJSON(message: CliTargetTimes): unknown {
    const obj: any = {};
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.numBytes !== 0) {
      obj.numBytes = Math.round(message.numBytes);
    }
    if (message.matchTimes?.length) {
      obj.matchTimes = message.matchTimes;
    }
    if (message.parseTimes?.length) {
      obj.parseTimes = message.parseTimes;
    }
    if (message.runTime !== 0) {
      obj.runTime = message.runTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CliTargetTimes>, I>>(base?: I): CliTargetTimes {
    return CliTargetTimes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CliTargetTimes>, I>>(object: I): CliTargetTimes {
    const message = createBaseCliTargetTimes();
    message.path = object.path ?? "";
    message.numBytes = object.numBytes ?? 0;
    message.matchTimes = object.matchTimes?.map((e) => e) || [];
    message.parseTimes = object.parseTimes?.map((e) => e) || [];
    message.runTime = object.runTime ?? 0;
    return message;
  },
};

function createBaseApiScansFindings(): ApiScansFindings {
  return { findings: [], token: "", gitlabToken: "", searchedPaths: [], ruleIds: [], caiIds: [] };
}

export const ApiScansFindings = {
  encode(message: ApiScansFindings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.findings) {
      Finding.encode(v!, writer.uint32(2745660250).fork()).ldelim();
    }
    if (message.token !== "") {
      writer.uint32(837620682).string(message.token);
    }
    if (message.gitlabToken !== "") {
      writer.uint32(2279477978).string(message.gitlabToken);
    }
    for (const v of message.searchedPaths) {
      writer.uint32(2423629450).string(v!);
    }
    for (const v of message.ruleIds) {
      writer.uint32(1701586650).string(v!);
    }
    for (const v of message.caiIds) {
      writer.uint32(2687317050).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApiScansFindings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApiScansFindings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 343207531:
          if (tag !== 2745660250) {
            break;
          }

          message.findings.push(Finding.decode(reader, reader.uint32()));
          continue;
        case 104702585:
          if (tag !== 837620682) {
            break;
          }

          message.token = reader.string();
          continue;
        case 284934747:
          if (tag !== 2279477978) {
            break;
          }

          message.gitlabToken = reader.string();
          continue;
        case 302953681:
          if (tag !== 2423629450) {
            break;
          }

          message.searchedPaths.push(reader.string());
          continue;
        case 212698331:
          if (tag !== 1701586650) {
            break;
          }

          message.ruleIds.push(reader.string());
          continue;
        case 335914631:
          if (tag !== 2687317050) {
            break;
          }

          message.caiIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApiScansFindings {
    return {
      findings: globalThis.Array.isArray(object?.findings) ? object.findings.map((e: any) => Finding.fromJSON(e)) : [],
      token: isSet(object.token) ? globalThis.String(object.token) : "",
      gitlabToken: isSet(object.gitlabToken) ? globalThis.String(object.gitlabToken) : "",
      searchedPaths: globalThis.Array.isArray(object?.searchedPaths)
        ? object.searchedPaths.map((e: any) => globalThis.String(e))
        : [],
      ruleIds: globalThis.Array.isArray(object?.ruleIds) ? object.ruleIds.map((e: any) => globalThis.String(e)) : [],
      caiIds: globalThis.Array.isArray(object?.caiIds) ? object.caiIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ApiScansFindings): unknown {
    const obj: any = {};
    if (message.findings?.length) {
      obj.findings = message.findings.map((e) => Finding.toJSON(e));
    }
    if (message.token !== "") {
      obj.token = message.token;
    }
    if (message.gitlabToken !== "") {
      obj.gitlabToken = message.gitlabToken;
    }
    if (message.searchedPaths?.length) {
      obj.searchedPaths = message.searchedPaths;
    }
    if (message.ruleIds?.length) {
      obj.ruleIds = message.ruleIds;
    }
    if (message.caiIds?.length) {
      obj.caiIds = message.caiIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApiScansFindings>, I>>(base?: I): ApiScansFindings {
    return ApiScansFindings.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApiScansFindings>, I>>(object: I): ApiScansFindings {
    const message = createBaseApiScansFindings();
    message.findings = object.findings?.map((e) => Finding.fromPartial(e)) || [];
    message.token = object.token ?? "";
    message.gitlabToken = object.gitlabToken ?? "";
    message.searchedPaths = object.searchedPaths?.map((e) => e) || [];
    message.ruleIds = object.ruleIds?.map((e) => e) || [];
    message.caiIds = object.caiIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseFinding(): Finding {
  return {
    checkId: "",
    path: "",
    line: 0,
    column: 0,
    endLine: 0,
    endColumn: 0,
    message: "",
    severity: 0,
    index: 0,
    commitDate: "",
    syntacticId: "",
    matchBasedId: "",
    metadata: undefined,
    isBlocking: false,
    fixedLines: [],
    scaInfo: undefined,
    dataflowTrace: undefined,
  };
}

export const Finding = {
  encode(message: Finding, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.checkId !== "") {
      writer.uint32(1955938858).string(message.checkId);
    }
    if (message.path !== "") {
      writer.uint32(25702874).string(message.path);
    }
    if (message.line !== 0) {
      writer.uint32(24943712).int64(message.line);
    }
    if (message.column !== 0) {
      writer.uint32(257902464).int64(message.column);
    }
    if (message.endLine !== 0) {
      writer.uint32(2155722456).int64(message.endLine);
    }
    if (message.endColumn !== 0) {
      writer.uint32(953937752).int64(message.endColumn);
    }
    if (message.message !== "") {
      writer.uint32(2703991194).string(message.message);
    }
    if (message.severity !== 0) {
      writer.uint32(1528909064).int64(message.severity);
    }
    if (message.index !== 0) {
      writer.uint32(911448208).int64(message.index);
    }
    if (message.commitDate !== "") {
      writer.uint32(1113994842).string(message.commitDate);
    }
    if (message.syntacticId !== "") {
      writer.uint32(3089242634).string(message.syntacticId);
    }
    if (message.matchBasedId !== "") {
      writer.uint32(3153517890).string(message.matchBasedId);
    }
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(4275062530).fork()).ldelim();
    }
    if (message.isBlocking !== false) {
      writer.uint32(3897171936).bool(message.isBlocking);
    }
    for (const v of message.fixedLines) {
      writer.uint32(3244776578).string(v!);
    }
    if (message.scaInfo !== undefined) {
      ScaInfo.encode(message.scaInfo, writer.uint32(3947610).fork()).ldelim();
    }
    if (message.dataflowTrace !== undefined) {
      DataflowTrace.encode(message.dataflowTrace, writer.uint32(2874462522).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Finding {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFinding();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 244492357:
          if (tag !== 1955938858) {
            break;
          }

          message.checkId = reader.string();
          continue;
        case 3212859:
          if (tag !== 25702874) {
            break;
          }

          message.path = reader.string();
          continue;
        case 3117964:
          if (tag !== 24943712) {
            break;
          }

          message.line = longToNumber(reader.int64() as Long);
          continue;
        case 32237808:
          if (tag !== 257902464) {
            break;
          }

          message.column = longToNumber(reader.int64() as Long);
          continue;
        case 269465307:
          if (tag !== 2155722456) {
            break;
          }

          message.endLine = longToNumber(reader.int64() as Long);
          continue;
        case 119242219:
          if (tag !== 953937752) {
            break;
          }

          message.endColumn = longToNumber(reader.int64() as Long);
          continue;
        case 337998899:
          if (tag !== 2703991194) {
            break;
          }

          message.message = reader.string();
          continue;
        case 191113633:
          if (tag !== 1528909064) {
            break;
          }

          message.severity = longToNumber(reader.int64() as Long);
          continue;
        case 113931026:
          if (tag !== 911448208) {
            break;
          }

          message.index = longToNumber(reader.int64() as Long);
          continue;
        case 139249355:
          if (tag !== 1113994842) {
            break;
          }

          message.commitDate = reader.string();
          continue;
        case 386155329:
          if (tag !== 3089242634) {
            break;
          }

          message.syntacticId = reader.string();
          continue;
        case 394189736:
          if (tag !== 3153517890) {
            break;
          }

          message.matchBasedId = reader.string();
          continue;
        case 534382816:
          if (tag !== 4275062530) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 487146492:
          if (tag !== 3897171936) {
            break;
          }

          message.isBlocking = reader.bool();
          continue;
        case 405597072:
          if (tag !== 3244776578) {
            break;
          }

          message.fixedLines.push(reader.string());
          continue;
        case 493451:
          if (tag !== 3947610) {
            break;
          }

          message.scaInfo = ScaInfo.decode(reader, reader.uint32());
          continue;
        case 359307815:
          if (tag !== 2874462522) {
            break;
          }

          message.dataflowTrace = DataflowTrace.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Finding {
    return {
      checkId: isSet(object.checkId) ? globalThis.String(object.checkId) : "",
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      line: isSet(object.line) ? globalThis.Number(object.line) : 0,
      column: isSet(object.column) ? globalThis.Number(object.column) : 0,
      endLine: isSet(object.endLine) ? globalThis.Number(object.endLine) : 0,
      endColumn: isSet(object.endColumn) ? globalThis.Number(object.endColumn) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      severity: isSet(object.severity) ? globalThis.Number(object.severity) : 0,
      index: isSet(object.index) ? globalThis.Number(object.index) : 0,
      commitDate: isSet(object.commitDate) ? globalThis.String(object.commitDate) : "",
      syntacticId: isSet(object.syntacticId) ? globalThis.String(object.syntacticId) : "",
      matchBasedId: isSet(object.matchBasedId) ? globalThis.String(object.matchBasedId) : "",
      metadata: isObject(object.metadata) ? object.metadata : undefined,
      isBlocking: isSet(object.isBlocking) ? globalThis.Boolean(object.isBlocking) : false,
      fixedLines: globalThis.Array.isArray(object?.fixedLines)
        ? object.fixedLines.map((e: any) => globalThis.String(e))
        : [],
      scaInfo: isSet(object.scaInfo) ? ScaInfo.fromJSON(object.scaInfo) : undefined,
      dataflowTrace: isSet(object.dataflowTrace) ? DataflowTrace.fromJSON(object.dataflowTrace) : undefined,
    };
  },

  toJSON(message: Finding): unknown {
    const obj: any = {};
    if (message.checkId !== "") {
      obj.checkId = message.checkId;
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.line !== 0) {
      obj.line = Math.round(message.line);
    }
    if (message.column !== 0) {
      obj.column = Math.round(message.column);
    }
    if (message.endLine !== 0) {
      obj.endLine = Math.round(message.endLine);
    }
    if (message.endColumn !== 0) {
      obj.endColumn = Math.round(message.endColumn);
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.severity !== 0) {
      obj.severity = Math.round(message.severity);
    }
    if (message.index !== 0) {
      obj.index = Math.round(message.index);
    }
    if (message.commitDate !== "") {
      obj.commitDate = message.commitDate;
    }
    if (message.syntacticId !== "") {
      obj.syntacticId = message.syntacticId;
    }
    if (message.matchBasedId !== "") {
      obj.matchBasedId = message.matchBasedId;
    }
    if (message.metadata !== undefined) {
      obj.metadata = message.metadata;
    }
    if (message.isBlocking !== false) {
      obj.isBlocking = message.isBlocking;
    }
    if (message.fixedLines?.length) {
      obj.fixedLines = message.fixedLines;
    }
    if (message.scaInfo !== undefined) {
      obj.scaInfo = ScaInfo.toJSON(message.scaInfo);
    }
    if (message.dataflowTrace !== undefined) {
      obj.dataflowTrace = DataflowTrace.toJSON(message.dataflowTrace);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Finding>, I>>(base?: I): Finding {
    return Finding.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Finding>, I>>(object: I): Finding {
    const message = createBaseFinding();
    message.checkId = object.checkId ?? "";
    message.path = object.path ?? "";
    message.line = object.line ?? 0;
    message.column = object.column ?? 0;
    message.endLine = object.endLine ?? 0;
    message.endColumn = object.endColumn ?? 0;
    message.message = object.message ?? "";
    message.severity = object.severity ?? 0;
    message.index = object.index ?? 0;
    message.commitDate = object.commitDate ?? "";
    message.syntacticId = object.syntacticId ?? "";
    message.matchBasedId = object.matchBasedId ?? "";
    message.metadata = object.metadata ?? undefined;
    message.isBlocking = object.isBlocking ?? false;
    message.fixedLines = object.fixedLines?.map((e) => e) || [];
    message.scaInfo = (object.scaInfo !== undefined && object.scaInfo !== null)
      ? ScaInfo.fromPartial(object.scaInfo)
      : undefined;
    message.dataflowTrace = (object.dataflowTrace !== undefined && object.dataflowTrace !== null)
      ? DataflowTrace.fromPartial(object.dataflowTrace)
      : undefined;
    return message;
  },
};

function createBaseScaInfo(): ScaInfo {
  return { dependencyMatchOnly: false, dependencyMatches: undefined };
}

export const ScaInfo = {
  encode(message: ScaInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dependencyMatchOnly !== false) {
      writer.uint32(3034766472).bool(message.dependencyMatchOnly);
    }
    if (message.dependencyMatches !== undefined) {
      Struct.encode(Struct.wrap(message.dependencyMatches), writer.uint32(2309768258).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScaInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScaInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 379345809:
          if (tag !== 3034766472) {
            break;
          }

          message.dependencyMatchOnly = reader.bool();
          continue;
        case 288721032:
          if (tag !== 2309768258) {
            break;
          }

          message.dependencyMatches = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScaInfo {
    return {
      dependencyMatchOnly: isSet(object.dependencyMatchOnly) ? globalThis.Boolean(object.dependencyMatchOnly) : false,
      dependencyMatches: isObject(object.dependencyMatches) ? object.dependencyMatches : undefined,
    };
  },

  toJSON(message: ScaInfo): unknown {
    const obj: any = {};
    if (message.dependencyMatchOnly !== false) {
      obj.dependencyMatchOnly = message.dependencyMatchOnly;
    }
    if (message.dependencyMatches !== undefined) {
      obj.dependencyMatches = message.dependencyMatches;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScaInfo>, I>>(base?: I): ScaInfo {
    return ScaInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScaInfo>, I>>(object: I): ScaInfo {
    const message = createBaseScaInfo();
    message.dependencyMatchOnly = object.dependencyMatchOnly ?? false;
    message.dependencyMatches = object.dependencyMatches ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
