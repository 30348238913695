import { useContext } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box } from "@mantine/core";

import { SearchPatternComponent } from "@shared/types";

import { WorkbenchContext } from "../../providers";

import { FixRow } from "./FixRow";
import { LanguageSelectRow } from "./LanguageSelectRow";
import { PatternRow } from "./PatternRow";

const Grid = styled.div`
  display: grid;
  // label input +button -button
  grid-template-columns: max-content auto max-content max-content;
  align-items: center;
  gap: 16px;
  padding: 28px;
  max-width: 100%;
`;

const SimpleModeComponent = () => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;

  if (bundle?.simpleRule === undefined) {
    return (
      <Box p="md">
        <Alert
          c="red"
          icon={<FontAwesomeIcon icon={faCircleExclamation} />}
          title="Error converting rule"
        >
          Your rule can't be converted to simple mode because it's either
          invalid or too complex. Check that the rule is valid or start a new
          rule from scratch!
        </Alert>
      </Box>
    );
  }

  return (
    <Grid>
      <LanguageSelectRow />
      {bundle.simpleRule.patterns.map((pattern, idx) => (
        <PatternRow
          patternComponent={pattern as SearchPatternComponent}
          key={idx}
          index={idx}
        />
      ))}
      {bundle.simpleRule.fix !== "" && <FixRow />}
    </Grid>
  );
};

export const SimpleMode = observer(SimpleModeComponent);
