import { BASE_GITHUB_URL } from "@shared/constants";
import { UserData } from "@shared/types";

export const getUserPhotoUrl = (user?: UserData) => {
  if (user?.profile_photo) {
    return user.profile_photo;
  } else if (user?.username && user.auth_provider_type === "github") {
    return `${BASE_GITHUB_URL}${user.username}.png?size=400`;
  } else return undefined;
};
