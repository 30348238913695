import { FC } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor, Badge, Group, HoverCard, Text, Tooltip } from "@mantine/core";

import { Repository } from "@protos/projects/v1/projects";
import { useBasePath } from "@shared/hooks";
import { Repo } from "@shared/types";
import { scmTitle } from "@shared/utils";

dayjs.extend(relativeTime);

export interface ProjectNameCellProps {
  repo: Repository | Repo;
}

export const ProjectNameCell: FC<ProjectNameCellProps> = ({ repo }) => {
  const basePath = useBasePath();

  const repoScmType = scmTitle((repo as Repository).scmType);
  // TODO remove when Repo type is deleted
  const scmLastSyncedAt =
    "scmLastSyncedAt" in repo
      ? repo.scmLastSyncedAt
      : "scm_last_synced_at" in repo
      ? repo.scm_last_synced_at
      : null;
  const isSetup = "isSetup" in repo ? repo.isSetup : repo.is_setup;
  const isArchived = "isArchived" in repo ? repo.isArchived : repo.is_archived;
  const hasPrimaryRef =
    "primaryRef" in repo
      ? Boolean(repo.primaryRef)
      : "primary_ref" in repo
      ? Boolean(repo.primary_ref)
      : false;
  const hasPrimaryRefId =
    "primaryRef" in repo
      ? Boolean(repo.primaryRef?.id)
      : "primary_ref" in repo
      ? Boolean(repo.primary_ref?.id)
      : false;

  return (
    <Group wrap="nowrap">
      <Text inherit>
        <Tooltip
          label={
            <Text inherit pr={6}>
              Last synced project metadata {dayjs(scmLastSyncedAt).fromNow()}
            </Text>
          }
          disabled={scmLastSyncedAt === null}
          withinPortal
        >
          <Text inherit span pr={6}>
            {repo.name}
          </Text>
        </Tooltip>
        {/* We should not show the warning info if a project is not yet set up since default branch information is not yet ingested */}
        {isSetup && hasPrimaryRef && !hasPrimaryRefId && (
          <HoverCard withArrow>
            <HoverCard.Target>
              <FontAwesomeIcon icon={faCircleExclamation} color="orange" />
            </HoverCard.Target>
            <HoverCard.Dropdown maw={300}>
              <Text fz="sm">
                No primary branch is set for this project.{" "}
                <Anchor
                  href={`${basePath}/projects/${repo.id}/settings#primary-branch`}
                  target="_blank"
                  rel="noopener noreferrer"
                  inherit
                >
                  Set the primary branch
                </Anchor>{" "}
                so it appears in findings and reporting filters as expected.
              </Text>
            </HoverCard.Dropdown>
          </HoverCard>
        )}
      </Text>
      {isArchived && (
        <Tooltip
          label={`${repoScmType} has marked this repository as archived`}
        >
          <Badge ml="md" variant="outline" color="gray">
            Archived
          </Badge>
        </Tooltip>
      )}
    </Group>
  );
};
