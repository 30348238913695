import React, { ComponentType } from "react";

export type PreloadableComponent<T extends ComponentType<any>> = T & {
  preload: () => Promise<T>;
};

export const ReactLazyPreloadable = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): PreloadableComponent<T> => {
  const factoryWithRetries = async () => {
    try {
      return await factory();
    } catch (error) {
      // retry 5 times with linear backoff
      for (let i = 0; i < 5; i++) {
        await new Promise((resolve) => setTimeout(resolve, 1000 * i));
        try {
          return await factory();
        } catch (e) {
          console.warn(`Retrying dynamic import; attempt ${i + 1}`);
        }
      }
      throw error;
    }
  };

  const ReactLazyComponent = React.lazy(factoryWithRetries);
  let PreloadedComponent: T | undefined;
  let factoryPromise: Promise<T> | undefined;

  const Component = React.forwardRef((props, ref: React.Ref<unknown>) => {
    // Once one of these is chosen, we must ensure that it continues to be
    // used for all subsequent renders, otherwise it can cause the
    // underlying component to be unmounted and remounted.
    const ComponentToRender = React.useRef(
      PreloadedComponent ?? ReactLazyComponent
    );
    return React.createElement(
      ComponentToRender.current,
      Object.assign(ref ? { ref } : {}, props) as React.Attributes &
        React.ComponentPropsWithRef<T>
    );
  }) as unknown as PreloadableComponent<T>;

  Component.preload = () => {
    if (!factoryPromise) {
      factoryPromise = factoryWithRetries().then((module) => {
        PreloadedComponent = module.default;
        return PreloadedComponent;
      });
    }

    return factoryPromise;
  };
  return Component;
};
