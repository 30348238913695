import { Highlight } from "@shared/types";
import { makeHighlight } from "@shared/utils";
import { CliError } from "@semgrep_output_types";

export const makeErrorHighlight = (
  error: CliError,
  type?: string
): Highlight => {
  const span = error.spans?.[0];
  const start = span?.start ?? { line: 1, col: 0, offset: 0 };
  return {
    ...makeHighlight(start, span?.end),
    key: `${error.type_}-${error.rule_id}-${start.line}-${start.col}`,
    isError: true,
    isDebug: false,
    type: type ?? "unknown",
    group: error.type_.kind,
    message: error.message,
    configLocation: span,
  };
};
