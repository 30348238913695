import styled from "styled-components";
import { Menu, Skeleton } from "@mantine/core";

import { CreateOrgDialog } from "../../CreateOrgDialog";

import { LoginPrompt } from "./LoginPrompt";

export const LOADING_STATE = (
  <>
    <Menu.Item disabled>
      <Skeleton height={16} />
    </Menu.Item>
    <Menu.Item disabled>
      <Skeleton height={16} />
    </Menu.Item>
    <Menu.Item disabled>
      <Skeleton height={16} />
    </Menu.Item>
  </>
);

const PromptContainer = styled.div`
  padding: 12px;
  width: 300px;
`;

export const LOGGED_OUT_STATE = (
  <PromptContainer>
    <LoginPrompt returnPath={window.location.pathname} />
  </PromptContainer>
);

export const NO_ORG_STATE = (
  <PromptContainer>
    <CreateOrgDialog isOpen unCloseable={true} />
  </PromptContainer>
);
