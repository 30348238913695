import { CSSProperties } from "react";
import { Anchor, AnchorProps, Text, TextProps } from "@mantine/core";

import { isValidUrl } from "@shared/utils";

import { HoverCopyButton } from "../HoverCopyButton";

export type OptionalAnchorProps = {
  url?: string;
  copyableText?: string;
  copyTooltipText?: string;
  isCopyable?: boolean;
  style?: CSSProperties;
  textColor?: TextProps["c"];
  anchorColor?: AnchorProps["c"];
  openInSameTab?: boolean;
  children?: React.ReactNode;
};

export const OptionalAnchor: React.FC<OptionalAnchorProps> = ({
  url,
  textColor,
  copyableText,
  copyTooltipText,
  isCopyable,
  anchorColor,
  openInSameTab,
  children,
  style,
}) => {
  const displayText = (
    <Text
      component="span"
      inherit
      style={{ wordBreak: "break-word" }}
      c={textColor ?? "dark.7"}
    >
      {children}
    </Text>
  );
  if (url === undefined || url === "" || !isValidUrl(url)) {
    if (isCopyable) {
      return (
        <HoverCopyButton
          copyText={copyableText || ""}
          tooltipText={copyTooltipText || copyableText || ""}
        >
          {displayText}
        </HoverCopyButton>
      );
    }
    return displayText;
  }
  return (
    <Anchor
      href={url}
      target={openInSameTab ? undefined : "_blank"}
      rel={openInSameTab ? undefined : "noopener noreferrer"}
      inherit
      c={anchorColor}
      style={{ ...style, wordBreak: "break-word" }}
    >
      {children}
    </Anchor>
  );
};
