import { FC, HTMLAttributes } from "react";

import styles from "./SplitGutter.module.css";

interface SplitGutterProps extends HTMLAttributes<HTMLDivElement> {
  between: "top-bottom" | "left-right";
}

export const SplitGutter: FC<SplitGutterProps> = ({ between, ...props }) => {
  return (
    <div {...props} data-direction={between} className={styles.splitGutter} />
  );
};
