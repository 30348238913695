import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import React from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Group, Stack } from "@mantine/core";
import { useHover } from "@mantine/hooks";

import { WorkbenchContext } from "../../providers";

import { MenuDropdown, MenuDropdownItem } from "./components/MenuDropdown";
import {
  ConstraintWithKind,
  defaultConstraintOfKind,
  emptyConstraint,
  InArray,
  PatternConstraint,
} from "./types/rule";
import { StructureModeTextInput } from "./utils/StructureModeTextInput";
import { addIntoArray, removeFromInArray } from "./utils/utils";
import { PlusDeleteButtons } from "./Buttons";

const PATTERN_CONSTRAINT_OPTIONS: MenuDropdownItem[] = [
  {
    name: "focus",
    info: "Restrict finding to metavariable's range",
    enabled: true,
  },
  {
    name: "comparison",
    info: "Filter for findings for which a Python expression is true",
    enabled: true,
  },
  {
    name: "metavariable",
    info: "Filter for findings which fulfill metavariable constraint",
    enabled: true,
  },
];

/******************************************************************************/
/* Constraints */
/******************************************************************************/

interface ConstraintSelectProps {
  constraint: PatternConstraint;
}
const ConstraintSelectComponent: React.FC<ConstraintSelectProps> = ({
  constraint,
}) => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;

  const value = constraint.value.kind;

  const onChange = (newKind: string | null) => {
    // TODO(brandon): why is this here?
    if (newKind === value) {
      return;
    }
    runInAction(() => {
      switch (newKind) {
        case null:
          // TODO: delete this rule segment when null?
          // this will not run unless we remove `allowDeselect={false}` from the Select component
          break;
        case "focus":
        case "comparison":
        case "metavariable":
          constraint.value = defaultConstraintOfKind(newKind);
      }
      bundle?.onStructureRuleChange();
      return;
    });
  };

  return (
    <MenuDropdown
      items={PATTERN_CONSTRAINT_OPTIONS}
      onChange={onChange}
      value={value}
    />
  );
};
const ConstraintSelect = observer(ConstraintSelectComponent);

interface ComparisonEditorProps {
  constraint: ConstraintWithKind<"comparison">;
}
const ComparisonEditorComponent: React.FC<ComparisonEditorProps> = ({
  constraint,
}) => {
  const onChangeComparison = (s: string | undefined) => {
    if (s) {
      runInAction(() => {
        constraint.value.comparison = s;
        return;
      });
    }
  };

  return (
    <StructureModeTextInput
      value={constraint.value.comparison}
      onChange={onChangeComparison}
      placeholder={"$FOO == 2"}
      // it is, but Python, specifically. we would need to add extra logic
      // specifically to make it use the Python parser, and that's too much
      // effort, so let's just say it's not code for now
      isCode={false}
    />
  );
};

const ComparisonEditor = observer(ComparisonEditorComponent);

interface FocusEditorProps {
  constraint: ConstraintWithKind<"focus">;
  mvar: string;
  index: number;
}
const FocusEditorComponent: React.FC<FocusEditorProps> = ({
  constraint,
  mvar,
  index,
}) => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;

  const [isFocused, setIsFocused] = useState(false);
  const { hovered, ref } = useHover();

  const onChangeFocus = (s: string | undefined, index: number) => {
    if (s) {
      runInAction(() => {
        constraint.value.metavariables[index] = s;
      });
    }
  };

  useEffect(() => {
    setIsFocused(hovered);
  }, [hovered]);

  return (
    <Group ref={ref}>
      <StructureModeTextInput
        placeholder="$MVAR"
        value={mvar}
        isCode={false}
        onChange={(event) => onChangeFocus(event, index)}
        width={"var(--metavariable-input-width)"}
      />
      <PlusDeleteButtons
        onPlus={() => {
          constraint.value.metavariables.splice(index + 1, 0, "");
          bundle?.onStructureRuleChange();
        }}
        onDelete={() => {
          constraint.value.metavariables.splice(index, 1);
          bundle?.onStructureRuleChange();
        }}
        isFocused={isFocused}
        containingArray={constraint.value.metavariables}
        thingToDelete={"focus metavariable"}
      />
    </Group>
  );
};

const FocusEditor = observer(FocusEditorComponent);

interface FocusesEditorProps {
  constraint: ConstraintWithKind<"focus">;
}
const FocusesEditorComponent: React.FC<FocusesEditorProps> = ({
  constraint,
}) => {
  return (
    <Stack gap={"var(--pattern-spacing)"}>
      {constraint.value.metavariables.map((mvar, index) => (
        <FocusEditor
          key={constraint.uuid}
          constraint={constraint}
          mvar={mvar}
          index={index}
        />
      ))}
    </Stack>
  );
};

const FocusesEditor = observer(FocusesEditorComponent);

interface ConstraintNodeProps {
  inArray: InArray<PatternConstraint>;
  constraint: PatternConstraint;
  isFocused: boolean;
}
const ConstraintNodeComponent: React.FC<ConstraintNodeProps> = ({
  inArray,
  constraint,
  isFocused,
}) => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;

  const pc = constraint.value;

  const buttonsElem = (
    <PlusDeleteButtons
      onPlus={() => addIntoArray(bundle, inArray, emptyConstraint())}
      onDelete={() => removeFromInArray(bundle, inArray)}
      isFocused={isFocused}
      containingArray={inArray?.parentArray}
      thingToDelete={"pattern constraint"}
      allowZero={true}
    />
  );

  let innerElem;
  let metavariableElem;

  switch (pc.kind) {
    case "focus": {
      innerElem = (
        <FocusesEditor constraint={constraint as ConstraintWithKind<"focus">} />
      );
      break;
    }
    case "comparison": {
      innerElem = (
        <ComparisonEditor
          constraint={constraint as ConstraintWithKind<"comparison">}
        />
      );
      break;
    }
    case "metavariable": {
      // render nothing for innerElem, because the inner elements will be rendered
      // as children of the main tree

      const onChangeMetavariable = (s: string | undefined) => {
        if (s) {
          runInAction(() => {
            pc.metavariable = s;
          });
        }
      };

      metavariableElem = (
        <StructureModeTextInput
          placeholder={"$MVAR"}
          value={pc.metavariable}
          isCode={false}
          onChange={onChangeMetavariable}
          width={"var(--metavariable-input-width)"}
        />
      );
      break;
    }
  }
  return (
    <Stack
      style={{ flexWrap: "nowrap" }}
      gap={"var(--intra-node-vertical-spacing)"}
    >
      <Group gap={"var(--button-spacing)"}>
        <div style={{ fontSize: "12px", color: "var(--mantine-color-gray-6)" }}>
          where
        </div>
        <ConstraintSelect constraint={constraint} />
        {metavariableElem}
        {buttonsElem}
      </Group>
      {constraint.isExpanded && innerElem}
    </Stack>
  );
};

export const ConstraintNode = observer(ConstraintNodeComponent);
