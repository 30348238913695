import { FC, useMemo, useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Virtuoso } from "react-virtuoso";
import {
  Checkbox,
  Combobox,
  ComboboxProps,
  Group,
  Input,
  Loader,
  Pill,
  PillsInput,
  PillsInputProps,
  Stack,
  Text,
  Tooltip,
  TooltipProps,
  useCombobox,
} from "@mantine/core";
import { ComboboxClearButtonProps } from "@mantine/core/lib/components/Combobox/ComboboxClearButton/ComboboxClearButton";

const VIRTUALIZATION_LIMIT = 100;

export interface Data {
  label: string;
  value: string;
  count?: number;
  description?: string;
  leftSection?: React.ReactNode;
  disabled?: boolean;
  disabledMessage?: string;
}

interface FooterMessage {
  title: string;
  message: string;
}

interface OptionProps {
  item: Data;
  value: string[];
  withCheckboxes?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
}

const Option: FC<OptionProps> = ({
  item,
  value,
  withCheckboxes,
  disabled,
  disabledMessage,
}) => {
  // the tooltip should be attached with the text but to appear when the option is hovered over
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Combobox.Option
      value={item.value}
      key={item.value}
      active={value.includes(item.value)}
      disabled={disabled}
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
    >
      <Group gap="sm">
        {withCheckboxes && (
          <Checkbox
            checked={value.includes(item.value)}
            onChange={() => {}} //noop
            aria-hidden
            tabIndex={-1}
            style={{ pointerEvents: "none" }}
          />
        )}
        <Tooltip
          withArrow
          label={disabledMessage}
          opened={tooltipOpen}
          disabled={!(disabled && disabledMessage)}
        >
          <Stack gap={4}>
            <Text span c="dark.7" fz="sm">
              {item.label}
            </Text>
            {item.description && (
              <Text span c="dimmed" fz="xs">
                {item.description}
              </Text>
            )}
          </Stack>
        </Tooltip>
      </Group>
    </Combobox.Option>
  );
};

export interface FilterMultiselectProps {
  data: Data[];
  value: string[];
  onChange: (value: string[]) => void;
  onInputChange?: (input: string) => void;
  label?: string;
  searchable?: boolean;
  clearable?: boolean;
  placeholder?: string;
  disabled?: boolean;
  disabledMessage?: string;
  withCheckboxes?: boolean;
  withTooltips?: boolean;
  tooltipProps?: TooltipProps;
  comboboxProps?: ComboboxProps;
  inputProps?: PillsInputProps;
  nothingFoundMessage?: string;
  isLoading?: boolean;
  maxBadges?: number;
  loading?: boolean;
  creatable?: boolean;
  onCreate?: (value: string) => void;
  addOnCreate?: boolean;
  maxCreateLabelLength?: number;
  maxCreateLabelError?: string;
  defaultPillStyles?: boolean;
  error?: string | null;
  style?: React.CSSProperties;
  clearButtonProps?: ComboboxClearButtonProps;
  virtualized?: boolean;
  virtuosoItemHeight?: number;
  disableAutomaticVirtualization?: boolean;
  footerMessage?: FooterMessage;
  clearOnSelect?: boolean;
  searchDescriptions?: boolean;
  inline?: boolean;
}

/**
 * Custom Multiselect component built on top of Mantine Combobox and PillsInput components
 *
 * @param {FilterMultiselectProps} props
 * @param { Data[] } props.data  - array of objects with label and value keys
 * @param { string[] } props.value - array of selected values
 * @param { (value: string[]) => void } props.onChange - function to call when value changes
 * @param { string } [props.label] - label for input (optional)
 * @param { boolean } [props.searchable] - enable search (optional)
 * @param { boolean } [props.clearable] - enable clear button (optional)
 * @param { string } [props.placeholder] - placeholder for input, defaults to "Select a value" (optional)
 * @param { boolean } [props.disabled] - disable input (optional)
 * @param { boolean } [props.withCheckboxes] - show checkboxes in input dropdown (optional)
 * @param { boolean } [props.withTooltips] - show tooltips on Pill hover (optional)
 * @param { boolean } [props.withinPortal] - render combobox within portal (optional)
 * @param { number } [props.maxBadges] - maximum number of pills to show (optional)
 * @param { React.ReactNode } [props.nothingFoundMessage] - message to show when no options found (optional)
 * @param { boolean } [props.loading] - show loading indicator (optional)
 * @param { boolean } [props.creatable] - enable creating new values (optional)
 * @param { (value: string) => void } [props.onCreate] - function to call when new value is created (optional)
 * @param { boolean } [props.addOnCreate] - add new value to value array when created (optional)
 * @param { number } [props.maxCreateLabelLength] - maximum length of create label (optional)
 * @param { string } [props.maxCreateLabelError] - error message to show when maxCreateLabelLength is exceeded (optional)
 * @param { boolean } [props.defaultPillStyles] - use default pill styles (optional)
 * @param { string } [props.error] - error message to show (optional)
 * @param { React.CSSProperties } [props.style] - style object to pass to root element (optional)
 * @param { ComboboxClearButtonProps } [props.clearButtonProps] - props to pass to clear button (optional)
 * @param { boolean } [props.virtualized] - enable virtualization (optional)
 * @param { number } [ props.virtuosoItemHeight ] - height of virtualized item (optional)
 * @param { boolean } [ props.disableAutomaticVirtualization ] - disable automatic virtualization (optional)
 * @param { FooterMessage } [ props.footerMessage ] - message to show in the footer (optional)
 * @param { boolean } [ props.clearOnSelect ] - clear search on select (optional)
 * @param { boolean } [ props.searchDescriptions ] - search descriptions as well as labels (optional)
 * @param { boolean } [props.inline] - label and input should be inline rather than stacked
 *
 */
export const FilterMultiselect: FC<FilterMultiselectProps> = ({
  data,
  value,
  onChange,
  onInputChange,
  label,
  searchable = true,
  clearable,
  placeholder,
  disabled,
  disabledMessage,
  withCheckboxes,
  withTooltips = true,
  comboboxProps = {},
  tooltipProps = {},
  inputProps = {},
  nothingFoundMessage,
  isLoading,
  maxBadges,
  loading,
  creatable,
  onCreate,
  addOnCreate,
  maxCreateLabelLength,
  maxCreateLabelError,
  defaultPillStyles,
  error,
  style,
  clearButtonProps,
  virtualized,
  virtuosoItemHeight,
  disableAutomaticVirtualization,
  footerMessage,
  clearOnSelect = true,
  searchDescriptions = false,
  inline = false,
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      setSearch("");
    },
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  // Virtualize by default on a large dataset
  const shouldBeVirtualized =
    virtualized ||
    (data.length > VIRTUALIZATION_LIMIT && !disableAutomaticVirtualization);

  const [search, setSearch] = useState("");
  const labelId = useMemo(() => `filter-multiselect-label-${uniqueId()}`, []);

  const hasMaxCreateLengthError =
    creatable && maxCreateLabelLength && search.length > maxCreateLabelLength;
  const maxLengthErrorMessage =
    maxCreateLabelError ||
    `New item must be less than ${maxCreateLabelLength} characters`;

  const exactOptionMatch = data.some(
    (item) =>
      item.label &&
      search &&
      item.label.toLowerCase() === search.toLowerCase().trim()
  );

  const dataReference = useMemo(() => {
    const reference: Record<string, Data> = {};
    data.forEach((item) => (reference[item.value] = item));
    return reference;
  }, [data]);

  const handleValueSelect = (val: string) => {
    if (val === "$create") {
      if (!hasMaxCreateLengthError && search.trim().length > 0) {
        if (onCreate) {
          onCreate(search);
          if (addOnCreate) {
            onChange([...value, search]);
          }
        }
        setSearch("");
      }
    } else {
      const newVals = value.includes(val)
        ? value.filter((v) => v !== val)
        : [...value, val];
      onChange(newVals);

      if (clearOnSelect) {
        setSearch("");
      }
    }
  };

  const handleValueRemove = (val: string) => {
    const newVals = value.filter((v) => v !== val);
    onChange(newVals);
  };

  const valuePills = value.map((value) => (
    <Tooltip
      {...tooltipProps}
      label={dataReference[value]?.label || value}
      disabled={!withTooltips}
      key={value}
    >
      <Pill
        key={value}
        withRemoveButton
        onRemove={() => handleValueRemove(value)}
        variant={defaultPillStyles ? "default" : "select-pill"}
        data-testid="pill"
        disabled={dataReference[value]?.disabled}
      >
        {dataReference[value]?.label || value}
      </Pill>
    </Tooltip>
  ));

  const maxBadgesReached = maxBadges && value.length >= maxBadges;

  const MaxValuePill = () => {
    return (
      <Pill
        variant={defaultPillStyles ? "default" : "select-pill"}
        withRemoveButton
        onRemove={() =>
          onChange(value.filter((v) => dataReference[v]?.disabled))
        }
        data-testid="max-value-pill"
      >
        {value.length} selected
      </Pill>
    );
  };

  // if we have checkboxes, show all options, otherwise show only those that are not selected
  const filteredData = useMemo(() => {
    const normalizedSearch = search.trim().toLowerCase();
    return data
      .filter(
        (item) =>
          item.label.toLowerCase().includes(normalizedSearch) ||
          (searchDescriptions &&
            item.description?.toLowerCase().includes(normalizedSearch))
      )
      .filter((item) => (withCheckboxes ? true : !value.includes(item.value)));
  }, [data, search, value, withCheckboxes, searchDescriptions]);

  const options = shouldBeVirtualized ? (
    <Virtuoso
      style={{ height: "100%" }}
      data={filteredData}
      itemContent={(_, item) => (
        <Option
          item={item}
          value={value}
          withCheckboxes={withCheckboxes}
          disabled={item.disabled}
          disabledMessage={item.disabledMessage}
        />
      )}
    />
  ) : (
    filteredData.map((item) => (
      <Option
        key={item.value}
        item={item}
        value={value}
        withCheckboxes={withCheckboxes}
        disabled={item.disabled}
        disabledMessage={item.disabledMessage}
      />
    ))
  );

  const handleInputKeyDown = (event: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (
      (searchable || creatable) &&
      event.key === "Backspace" &&
      search.length === 0
    ) {
      event.preventDefault();
      const removableValue = value.filter(
        (value) => !dataReference[value]?.disabled
      );
      if (removableValue.length > 0) {
        handleValueRemove(removableValue[removableValue.length - 1]);
      }
    }
  };

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      position="bottom"
      {...comboboxProps}
    >
      <Combobox.DropdownTarget>
        <Group fz="sm" gap="xs" align="center">
          {inline && (
            <label htmlFor={labelId} style={{ fontWeight: 600 }}>
              {label}
            </label>
          )}
          <PillsInput
            id={labelId}
            flex={1}
            maw="100%"
            label={
              label &&
              !inline && (
                <Text mb={7} c="dark.4" size="md" fw={500}>
                  {label}
                </Text>
              )
            }
            onClick={() => combobox.openDropdown()}
            pointer={!searchable && !creatable}
            multiline
            error={hasMaxCreateLengthError ? maxLengthErrorMessage : error}
            rightSection={
              loading ? (
                <Loader size="1rem" />
              ) : clearable && value.length > 0 ? (
                <Combobox.ClearButton
                  size="sm"
                  onMouseDown={(event) => event.preventDefault()}
                  onClear={() =>
                    onChange(value.filter((v) => dataReference[v]?.disabled))
                  }
                  aria-label="Clear value"
                  {...clearButtonProps}
                />
              ) : (
                <Combobox.Chevron />
              )
            }
            fz="md"
            color="var(--mantine-color-dark-4)"
            fw={500}
            style={{ ...style }}
            data-testid="multiselect"
            {...inputProps}
          >
            <Pill.Group fw="initial">
              {!maxBadgesReached && Boolean(value.length > 0) && valuePills}
              {maxBadgesReached && <MaxValuePill />}

              {!value.length && !searchable && !creatable && (
                <Input.Placeholder>
                  {placeholder || "Select a value"}
                </Input.Placeholder>
              )}
              <Combobox.EventsTarget>
                <Tooltip
                  label={disabledMessage || "This filter is disabled."}
                  disabled={!disabled}
                  withinPortal
                >
                  <PillsInput.Field
                    type={searchable || creatable ? "visible" : "hidden"}
                    onFocus={() => combobox.openDropdown()}
                    onBlur={() => combobox.closeDropdown()}
                    value={search}
                    placeholder={
                      value.length > 0
                        ? undefined
                        : placeholder || "Search values"
                    }
                    onChange={
                      searchable || creatable
                        ? (event) => {
                            combobox.updateSelectedOptionIndex();
                            setSearch(event.currentTarget.value);
                            onInputChange?.(event.target.value);
                          }
                        : undefined
                    }
                    onKeyDown={handleInputKeyDown}
                    disabled={disabled}
                    data-testid="select-input"
                  />
                </Tooltip>
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Group>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown data-testid="select-dropdown">
        <Combobox.Options mah={300} style={{ overflowY: "auto" }}>
          {isLoading ? (
            <Combobox.Empty>Loading...</Combobox.Empty>
          ) : (
            <>
              <div
                style={{
                  height: shouldBeVirtualized
                    ? filteredData.length * (virtuosoItemHeight || 35)
                    : "auto",
                  maxHeight: 300,
                }}
              >
                {options}
              </div>
              {creatable && !exactOptionMatch && search.trim().length > 0 && (
                <Combobox.Option value="$create">
                  + Create {search}
                </Combobox.Option>
              )}

              {!creatable &&
                !exactOptionMatch &&
                search.trim().length > 0 &&
                filteredData.length === 0 && (
                  <Combobox.Empty>
                    {nothingFoundMessage || "Nothing found..."}
                  </Combobox.Empty>
                )}
            </>
          )}
        </Combobox.Options>
        {footerMessage && (
          <Combobox.Footer my={0}>
            <Text inherit fw={500}>
              {footerMessage?.title}
            </Text>
            <div>{footerMessage?.message}</div>
          </Combobox.Footer>
        )}
      </Combobox.Dropdown>
    </Combobox>
  );
};
