import * as monaco from "monaco-editor";

export const makeTestAssertionGlyphs = (
  expectedMatches: number[],
  expectedNotMatches: number[],
  actualMatches: number[],
  extraneousMatches: number[]
) => {
  const expectedGlyphs = expectedMatches.map((m) => {
    const res = actualMatches.includes(m);
    const className = res ? "success" : "failed";

    return {
      range: new monaco.Range(m - 1, 1, m - 1, 1),
      options: {
        glyphMarginClassName: `highlight ${className}`,
        className: `highlight backdrop ${className}`,
        isWholeLine: true,
        glyphMarginHoverMessage: {
          value: res ? "Test passed" : "Test failed, line must match",
        },
      },
    };
  });

  const expectedNotGlyphs = expectedNotMatches.map((m) => {
    const res = !actualMatches.includes(m);
    const className = res ? "success" : "failed";

    return {
      range: new monaco.Range(m - 1, 1, m - 1, 1),
      options: {
        glyphMarginClassName: `highlight ${className}`,
        className: `highlight backdrop ${className}`,
        isWholeLine: true,
        glyphMarginHoverMessage: {
          value: res ? "Test passed" : "Test failed, line must not match",
        },
      },
    };
  });

  const extraneousFailureGlyphs = extraneousMatches.map((m) => ({
    range: new monaco.Range(m, 1, m, 1),
    options: {
      glyphMarginClassName: "highlight error",
      className: "highlight backdrop error",
      isWholeLine: true,
      glyphMarginHoverMessage: {
        value: "No test assertion, line must not match",
      },
    },
  }));

  return [...expectedGlyphs, ...expectedNotGlyphs, ...extraneousFailureGlyphs];
};
