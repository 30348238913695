import React from "react";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps, Menu } from "@mantine/core";

import { KeyboardShortcutHint } from "@shared/components";
import { runShortcut } from "@shared/utils";

interface IRunButtonProps {
  onClick: () => any;
  isRunning: boolean;
  customRunText?: string;
  buttonProps?: ButtonProps;
  disabled?: boolean;
  menuItems?: React.ReactNode;
}

export const RunButton: React.FC<IRunButtonProps> = ({
  onClick,
  isRunning,
  customRunText,
  buttonProps,
  disabled,
  menuItems,
}) => {
  return (
    <Button.Group>
      <Button
        size="compact-md"
        {...buttonProps}
        onClick={onClick}
        loading={isRunning}
        disabled={disabled}
      >
        {customRunText || (
          <span>
            Run <KeyboardShortcutHint>{runShortcut}</KeyboardShortcutHint>
          </span>
        )}
      </Button>
      {menuItems && (
        <Menu position="top-end" withinPortal>
          <Menu.Target>
            <Button
              size="compact-md"
              {...buttonProps}
              disabled={isRunning || disabled}
            >
              <FontAwesomeIcon icon={faCaretDown} size="xs" />
            </Button>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      )}
    </Button.Group>
  );
};
