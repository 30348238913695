// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/sms/v2/package_manager_config.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { EncryptedValueDictMessage } from "../../encryption/v1/encryption";
import { Ecosystem, ecosystemFromJSON, ecosystemToJSON, ecosystemToNumber } from "../../sca/v1/sca";

export const protobufPackage = "protos.sms.v2";

export interface GetPackageManagerAuthConfigsRequest {
  deploymentId: number;
}

export interface GetPackageManagerAuthConfigsResponse {
  packageManagerConfigsWithCredentials: PackageManagerConfigWithCredentials[];
}

export interface PostPackageManagerAuthCredentialsRequest {
  deploymentId: number;
  packageManagerConfigForInsert: PackageManagerConfigForInsert | undefined;
}

export interface PostPackageManagerAuthCredentialsResponse {
  packageManagerConfig: PackageManagerConfig | undefined;
}

export interface UpdatePackageManagerAuthCredentialsRequest {
  deploymentId: number;
  packageManagerConfigId: number;
  packageManagerConfigForInsert: PackageManagerConfigForInsert | undefined;
}

export interface UpdatePackageManagerAuthCredentialsResponse {
  packageManagerConfig: PackageManagerConfig | undefined;
}

export interface DeletePackageManagerAuthConfigsRequest {
  deploymentId: number;
  packageManagerConfigId: number;
}

export interface DeletePackageManagerAuthConfigsResponse {
  packageManagerConfigId: number;
}

export interface MavenCredentials {
  username: string;
  password: string;
}

export interface PypiCredentials {
  username: string;
  password: string;
}

/**
 * Represents properties of the PackageManagerConfig model
 * without the credentials
 */
export interface PackageManagerConfig {
  id: number;
  name: string;
  url: string;
  requiresAuth: boolean;
  ecosystem: Ecosystem;
  deploymentId: number;
}

/** Should not be used except for when creating new configs */
export interface PackageManagerConfigForInsert {
  config: PackageManagerConfig | undefined;
  credentials?: { $case: "maven"; maven: MavenCredentials } | { $case: "pypi"; pypi: PypiCredentials } | undefined;
}

/** PackageManagerConfig properties with encrypted credentials */
export interface PackageManagerConfigWithCredentials {
  config: PackageManagerConfig | undefined;
  credentials: EncryptedValueDictMessage | undefined;
}

function createBaseGetPackageManagerAuthConfigsRequest(): GetPackageManagerAuthConfigsRequest {
  return { deploymentId: 0 };
}

export const GetPackageManagerAuthConfigsRequest = {
  encode(message: GetPackageManagerAuthConfigsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPackageManagerAuthConfigsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPackageManagerAuthConfigsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPackageManagerAuthConfigsRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0 };
  },

  toJSON(message: GetPackageManagerAuthConfigsRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPackageManagerAuthConfigsRequest>, I>>(
    base?: I,
  ): GetPackageManagerAuthConfigsRequest {
    return GetPackageManagerAuthConfigsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPackageManagerAuthConfigsRequest>, I>>(
    object: I,
  ): GetPackageManagerAuthConfigsRequest {
    const message = createBaseGetPackageManagerAuthConfigsRequest();
    message.deploymentId = object.deploymentId ?? 0;
    return message;
  },
};

function createBaseGetPackageManagerAuthConfigsResponse(): GetPackageManagerAuthConfigsResponse {
  return { packageManagerConfigsWithCredentials: [] };
}

export const GetPackageManagerAuthConfigsResponse = {
  encode(message: GetPackageManagerAuthConfigsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.packageManagerConfigsWithCredentials) {
      PackageManagerConfigWithCredentials.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPackageManagerAuthConfigsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPackageManagerAuthConfigsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.packageManagerConfigsWithCredentials.push(
            PackageManagerConfigWithCredentials.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPackageManagerAuthConfigsResponse {
    return {
      packageManagerConfigsWithCredentials: globalThis.Array.isArray(object?.packageManagerConfigsWithCredentials)
        ? object.packageManagerConfigsWithCredentials.map((e: any) => PackageManagerConfigWithCredentials.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetPackageManagerAuthConfigsResponse): unknown {
    const obj: any = {};
    if (message.packageManagerConfigsWithCredentials?.length) {
      obj.packageManagerConfigsWithCredentials = message.packageManagerConfigsWithCredentials.map((e) =>
        PackageManagerConfigWithCredentials.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPackageManagerAuthConfigsResponse>, I>>(
    base?: I,
  ): GetPackageManagerAuthConfigsResponse {
    return GetPackageManagerAuthConfigsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPackageManagerAuthConfigsResponse>, I>>(
    object: I,
  ): GetPackageManagerAuthConfigsResponse {
    const message = createBaseGetPackageManagerAuthConfigsResponse();
    message.packageManagerConfigsWithCredentials =
      object.packageManagerConfigsWithCredentials?.map((e) => PackageManagerConfigWithCredentials.fromPartial(e)) || [];
    return message;
  },
};

function createBasePostPackageManagerAuthCredentialsRequest(): PostPackageManagerAuthCredentialsRequest {
  return { deploymentId: 0, packageManagerConfigForInsert: undefined };
}

export const PostPackageManagerAuthCredentialsRequest = {
  encode(message: PostPackageManagerAuthCredentialsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.packageManagerConfigForInsert !== undefined) {
      PackageManagerConfigForInsert.encode(message.packageManagerConfigForInsert, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostPackageManagerAuthCredentialsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostPackageManagerAuthCredentialsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.packageManagerConfigForInsert = PackageManagerConfigForInsert.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostPackageManagerAuthCredentialsRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      packageManagerConfigForInsert: isSet(object.packageManagerConfigForInsert)
        ? PackageManagerConfigForInsert.fromJSON(object.packageManagerConfigForInsert)
        : undefined,
    };
  },

  toJSON(message: PostPackageManagerAuthCredentialsRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.packageManagerConfigForInsert !== undefined) {
      obj.packageManagerConfigForInsert = PackageManagerConfigForInsert.toJSON(message.packageManagerConfigForInsert);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PostPackageManagerAuthCredentialsRequest>, I>>(
    base?: I,
  ): PostPackageManagerAuthCredentialsRequest {
    return PostPackageManagerAuthCredentialsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostPackageManagerAuthCredentialsRequest>, I>>(
    object: I,
  ): PostPackageManagerAuthCredentialsRequest {
    const message = createBasePostPackageManagerAuthCredentialsRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.packageManagerConfigForInsert =
      (object.packageManagerConfigForInsert !== undefined && object.packageManagerConfigForInsert !== null)
        ? PackageManagerConfigForInsert.fromPartial(object.packageManagerConfigForInsert)
        : undefined;
    return message;
  },
};

function createBasePostPackageManagerAuthCredentialsResponse(): PostPackageManagerAuthCredentialsResponse {
  return { packageManagerConfig: undefined };
}

export const PostPackageManagerAuthCredentialsResponse = {
  encode(message: PostPackageManagerAuthCredentialsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.packageManagerConfig !== undefined) {
      PackageManagerConfig.encode(message.packageManagerConfig, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostPackageManagerAuthCredentialsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostPackageManagerAuthCredentialsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.packageManagerConfig = PackageManagerConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostPackageManagerAuthCredentialsResponse {
    return {
      packageManagerConfig: isSet(object.packageManagerConfig)
        ? PackageManagerConfig.fromJSON(object.packageManagerConfig)
        : undefined,
    };
  },

  toJSON(message: PostPackageManagerAuthCredentialsResponse): unknown {
    const obj: any = {};
    if (message.packageManagerConfig !== undefined) {
      obj.packageManagerConfig = PackageManagerConfig.toJSON(message.packageManagerConfig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PostPackageManagerAuthCredentialsResponse>, I>>(
    base?: I,
  ): PostPackageManagerAuthCredentialsResponse {
    return PostPackageManagerAuthCredentialsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostPackageManagerAuthCredentialsResponse>, I>>(
    object: I,
  ): PostPackageManagerAuthCredentialsResponse {
    const message = createBasePostPackageManagerAuthCredentialsResponse();
    message.packageManagerConfig = (object.packageManagerConfig !== undefined && object.packageManagerConfig !== null)
      ? PackageManagerConfig.fromPartial(object.packageManagerConfig)
      : undefined;
    return message;
  },
};

function createBaseUpdatePackageManagerAuthCredentialsRequest(): UpdatePackageManagerAuthCredentialsRequest {
  return { deploymentId: 0, packageManagerConfigId: 0, packageManagerConfigForInsert: undefined };
}

export const UpdatePackageManagerAuthCredentialsRequest = {
  encode(message: UpdatePackageManagerAuthCredentialsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.packageManagerConfigId !== 0) {
      writer.uint32(16).uint64(message.packageManagerConfigId);
    }
    if (message.packageManagerConfigForInsert !== undefined) {
      PackageManagerConfigForInsert.encode(message.packageManagerConfigForInsert, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdatePackageManagerAuthCredentialsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatePackageManagerAuthCredentialsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.packageManagerConfigId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.packageManagerConfigForInsert = PackageManagerConfigForInsert.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdatePackageManagerAuthCredentialsRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      packageManagerConfigId: isSet(object.packageManagerConfigId)
        ? globalThis.Number(object.packageManagerConfigId)
        : 0,
      packageManagerConfigForInsert: isSet(object.packageManagerConfigForInsert)
        ? PackageManagerConfigForInsert.fromJSON(object.packageManagerConfigForInsert)
        : undefined,
    };
  },

  toJSON(message: UpdatePackageManagerAuthCredentialsRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.packageManagerConfigId !== 0) {
      obj.packageManagerConfigId = Math.round(message.packageManagerConfigId);
    }
    if (message.packageManagerConfigForInsert !== undefined) {
      obj.packageManagerConfigForInsert = PackageManagerConfigForInsert.toJSON(message.packageManagerConfigForInsert);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdatePackageManagerAuthCredentialsRequest>, I>>(
    base?: I,
  ): UpdatePackageManagerAuthCredentialsRequest {
    return UpdatePackageManagerAuthCredentialsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdatePackageManagerAuthCredentialsRequest>, I>>(
    object: I,
  ): UpdatePackageManagerAuthCredentialsRequest {
    const message = createBaseUpdatePackageManagerAuthCredentialsRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.packageManagerConfigId = object.packageManagerConfigId ?? 0;
    message.packageManagerConfigForInsert =
      (object.packageManagerConfigForInsert !== undefined && object.packageManagerConfigForInsert !== null)
        ? PackageManagerConfigForInsert.fromPartial(object.packageManagerConfigForInsert)
        : undefined;
    return message;
  },
};

function createBaseUpdatePackageManagerAuthCredentialsResponse(): UpdatePackageManagerAuthCredentialsResponse {
  return { packageManagerConfig: undefined };
}

export const UpdatePackageManagerAuthCredentialsResponse = {
  encode(message: UpdatePackageManagerAuthCredentialsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.packageManagerConfig !== undefined) {
      PackageManagerConfig.encode(message.packageManagerConfig, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdatePackageManagerAuthCredentialsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatePackageManagerAuthCredentialsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.packageManagerConfig = PackageManagerConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdatePackageManagerAuthCredentialsResponse {
    return {
      packageManagerConfig: isSet(object.packageManagerConfig)
        ? PackageManagerConfig.fromJSON(object.packageManagerConfig)
        : undefined,
    };
  },

  toJSON(message: UpdatePackageManagerAuthCredentialsResponse): unknown {
    const obj: any = {};
    if (message.packageManagerConfig !== undefined) {
      obj.packageManagerConfig = PackageManagerConfig.toJSON(message.packageManagerConfig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdatePackageManagerAuthCredentialsResponse>, I>>(
    base?: I,
  ): UpdatePackageManagerAuthCredentialsResponse {
    return UpdatePackageManagerAuthCredentialsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdatePackageManagerAuthCredentialsResponse>, I>>(
    object: I,
  ): UpdatePackageManagerAuthCredentialsResponse {
    const message = createBaseUpdatePackageManagerAuthCredentialsResponse();
    message.packageManagerConfig = (object.packageManagerConfig !== undefined && object.packageManagerConfig !== null)
      ? PackageManagerConfig.fromPartial(object.packageManagerConfig)
      : undefined;
    return message;
  },
};

function createBaseDeletePackageManagerAuthConfigsRequest(): DeletePackageManagerAuthConfigsRequest {
  return { deploymentId: 0, packageManagerConfigId: 0 };
}

export const DeletePackageManagerAuthConfigsRequest = {
  encode(message: DeletePackageManagerAuthConfigsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.packageManagerConfigId !== 0) {
      writer.uint32(16).uint64(message.packageManagerConfigId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeletePackageManagerAuthConfigsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeletePackageManagerAuthConfigsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.packageManagerConfigId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeletePackageManagerAuthConfigsRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      packageManagerConfigId: isSet(object.packageManagerConfigId)
        ? globalThis.Number(object.packageManagerConfigId)
        : 0,
    };
  },

  toJSON(message: DeletePackageManagerAuthConfigsRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.packageManagerConfigId !== 0) {
      obj.packageManagerConfigId = Math.round(message.packageManagerConfigId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeletePackageManagerAuthConfigsRequest>, I>>(
    base?: I,
  ): DeletePackageManagerAuthConfigsRequest {
    return DeletePackageManagerAuthConfigsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeletePackageManagerAuthConfigsRequest>, I>>(
    object: I,
  ): DeletePackageManagerAuthConfigsRequest {
    const message = createBaseDeletePackageManagerAuthConfigsRequest();
    message.deploymentId = object.deploymentId ?? 0;
    message.packageManagerConfigId = object.packageManagerConfigId ?? 0;
    return message;
  },
};

function createBaseDeletePackageManagerAuthConfigsResponse(): DeletePackageManagerAuthConfigsResponse {
  return { packageManagerConfigId: 0 };
}

export const DeletePackageManagerAuthConfigsResponse = {
  encode(message: DeletePackageManagerAuthConfigsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.packageManagerConfigId !== 0) {
      writer.uint32(8).uint64(message.packageManagerConfigId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeletePackageManagerAuthConfigsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeletePackageManagerAuthConfigsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.packageManagerConfigId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeletePackageManagerAuthConfigsResponse {
    return {
      packageManagerConfigId: isSet(object.packageManagerConfigId)
        ? globalThis.Number(object.packageManagerConfigId)
        : 0,
    };
  },

  toJSON(message: DeletePackageManagerAuthConfigsResponse): unknown {
    const obj: any = {};
    if (message.packageManagerConfigId !== 0) {
      obj.packageManagerConfigId = Math.round(message.packageManagerConfigId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeletePackageManagerAuthConfigsResponse>, I>>(
    base?: I,
  ): DeletePackageManagerAuthConfigsResponse {
    return DeletePackageManagerAuthConfigsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeletePackageManagerAuthConfigsResponse>, I>>(
    object: I,
  ): DeletePackageManagerAuthConfigsResponse {
    const message = createBaseDeletePackageManagerAuthConfigsResponse();
    message.packageManagerConfigId = object.packageManagerConfigId ?? 0;
    return message;
  },
};

function createBaseMavenCredentials(): MavenCredentials {
  return { username: "", password: "" };
}

export const MavenCredentials = {
  encode(message: MavenCredentials, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    if (message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MavenCredentials {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMavenCredentials();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MavenCredentials {
    return {
      username: isSet(object.username) ? globalThis.String(object.username) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
    };
  },

  toJSON(message: MavenCredentials): unknown {
    const obj: any = {};
    if (message.username !== "") {
      obj.username = message.username;
    }
    if (message.password !== "") {
      obj.password = message.password;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MavenCredentials>, I>>(base?: I): MavenCredentials {
    return MavenCredentials.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MavenCredentials>, I>>(object: I): MavenCredentials {
    const message = createBaseMavenCredentials();
    message.username = object.username ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBasePypiCredentials(): PypiCredentials {
  return { username: "", password: "" };
}

export const PypiCredentials = {
  encode(message: PypiCredentials, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    if (message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PypiCredentials {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePypiCredentials();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PypiCredentials {
    return {
      username: isSet(object.username) ? globalThis.String(object.username) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
    };
  },

  toJSON(message: PypiCredentials): unknown {
    const obj: any = {};
    if (message.username !== "") {
      obj.username = message.username;
    }
    if (message.password !== "") {
      obj.password = message.password;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PypiCredentials>, I>>(base?: I): PypiCredentials {
    return PypiCredentials.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PypiCredentials>, I>>(object: I): PypiCredentials {
    const message = createBasePypiCredentials();
    message.username = object.username ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBasePackageManagerConfig(): PackageManagerConfig {
  return { id: 0, name: "", url: "", requiresAuth: false, ecosystem: Ecosystem.no_package_manager, deploymentId: 0 };
}

export const PackageManagerConfig = {
  encode(message: PackageManagerConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.url !== "") {
      writer.uint32(26).string(message.url);
    }
    if (message.requiresAuth !== false) {
      writer.uint32(32).bool(message.requiresAuth);
    }
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      writer.uint32(40).int32(ecosystemToNumber(message.ecosystem));
    }
    if (message.deploymentId !== 0) {
      writer.uint32(48).uint64(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PackageManagerConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePackageManagerConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.url = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.requiresAuth = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.ecosystem = ecosystemFromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PackageManagerConfig {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      requiresAuth: isSet(object.requiresAuth) ? globalThis.Boolean(object.requiresAuth) : false,
      ecosystem: isSet(object.ecosystem) ? ecosystemFromJSON(object.ecosystem) : Ecosystem.no_package_manager,
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
    };
  },

  toJSON(message: PackageManagerConfig): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.requiresAuth !== false) {
      obj.requiresAuth = message.requiresAuth;
    }
    if (message.ecosystem !== Ecosystem.no_package_manager) {
      obj.ecosystem = ecosystemToJSON(message.ecosystem);
    }
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PackageManagerConfig>, I>>(base?: I): PackageManagerConfig {
    return PackageManagerConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PackageManagerConfig>, I>>(object: I): PackageManagerConfig {
    const message = createBasePackageManagerConfig();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.url = object.url ?? "";
    message.requiresAuth = object.requiresAuth ?? false;
    message.ecosystem = object.ecosystem ?? Ecosystem.no_package_manager;
    message.deploymentId = object.deploymentId ?? 0;
    return message;
  },
};

function createBasePackageManagerConfigForInsert(): PackageManagerConfigForInsert {
  return { config: undefined, credentials: undefined };
}

export const PackageManagerConfigForInsert = {
  encode(message: PackageManagerConfigForInsert, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.config !== undefined) {
      PackageManagerConfig.encode(message.config, writer.uint32(10).fork()).ldelim();
    }
    switch (message.credentials?.$case) {
      case "maven":
        MavenCredentials.encode(message.credentials.maven, writer.uint32(18).fork()).ldelim();
        break;
      case "pypi":
        PypiCredentials.encode(message.credentials.pypi, writer.uint32(26).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PackageManagerConfigForInsert {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePackageManagerConfigForInsert();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.config = PackageManagerConfig.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.credentials = { $case: "maven", maven: MavenCredentials.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.credentials = { $case: "pypi", pypi: PypiCredentials.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PackageManagerConfigForInsert {
    return {
      config: isSet(object.config) ? PackageManagerConfig.fromJSON(object.config) : undefined,
      credentials: isSet(object.maven)
        ? { $case: "maven", maven: MavenCredentials.fromJSON(object.maven) }
        : isSet(object.pypi)
        ? { $case: "pypi", pypi: PypiCredentials.fromJSON(object.pypi) }
        : undefined,
    };
  },

  toJSON(message: PackageManagerConfigForInsert): unknown {
    const obj: any = {};
    if (message.config !== undefined) {
      obj.config = PackageManagerConfig.toJSON(message.config);
    }
    if (message.credentials?.$case === "maven") {
      obj.maven = MavenCredentials.toJSON(message.credentials.maven);
    }
    if (message.credentials?.$case === "pypi") {
      obj.pypi = PypiCredentials.toJSON(message.credentials.pypi);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PackageManagerConfigForInsert>, I>>(base?: I): PackageManagerConfigForInsert {
    return PackageManagerConfigForInsert.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PackageManagerConfigForInsert>, I>>(
    object: I,
  ): PackageManagerConfigForInsert {
    const message = createBasePackageManagerConfigForInsert();
    message.config = (object.config !== undefined && object.config !== null)
      ? PackageManagerConfig.fromPartial(object.config)
      : undefined;
    if (
      object.credentials?.$case === "maven" &&
      object.credentials?.maven !== undefined &&
      object.credentials?.maven !== null
    ) {
      message.credentials = { $case: "maven", maven: MavenCredentials.fromPartial(object.credentials.maven) };
    }
    if (
      object.credentials?.$case === "pypi" &&
      object.credentials?.pypi !== undefined &&
      object.credentials?.pypi !== null
    ) {
      message.credentials = { $case: "pypi", pypi: PypiCredentials.fromPartial(object.credentials.pypi) };
    }
    return message;
  },
};

function createBasePackageManagerConfigWithCredentials(): PackageManagerConfigWithCredentials {
  return { config: undefined, credentials: undefined };
}

export const PackageManagerConfigWithCredentials = {
  encode(message: PackageManagerConfigWithCredentials, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.config !== undefined) {
      PackageManagerConfig.encode(message.config, writer.uint32(10).fork()).ldelim();
    }
    if (message.credentials !== undefined) {
      EncryptedValueDictMessage.encode(message.credentials, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PackageManagerConfigWithCredentials {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePackageManagerConfigWithCredentials();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.config = PackageManagerConfig.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.credentials = EncryptedValueDictMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PackageManagerConfigWithCredentials {
    return {
      config: isSet(object.config) ? PackageManagerConfig.fromJSON(object.config) : undefined,
      credentials: isSet(object.credentials) ? EncryptedValueDictMessage.fromJSON(object.credentials) : undefined,
    };
  },

  toJSON(message: PackageManagerConfigWithCredentials): unknown {
    const obj: any = {};
    if (message.config !== undefined) {
      obj.config = PackageManagerConfig.toJSON(message.config);
    }
    if (message.credentials !== undefined) {
      obj.credentials = EncryptedValueDictMessage.toJSON(message.credentials);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PackageManagerConfigWithCredentials>, I>>(
    base?: I,
  ): PackageManagerConfigWithCredentials {
    return PackageManagerConfigWithCredentials.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PackageManagerConfigWithCredentials>, I>>(
    object: I,
  ): PackageManagerConfigWithCredentials {
    const message = createBasePackageManagerConfigWithCredentials();
    message.config = (object.config !== undefined && object.config !== null)
      ? PackageManagerConfig.fromPartial(object.config)
      : undefined;
    message.credentials = (object.credentials !== undefined && object.credentials !== null)
      ? EncryptedValueDictMessage.fromPartial(object.credentials)
      : undefined;
    return message;
  },
};

export interface SmsPackageManagerConfigService {
  postPackageManagerAuthCredentials(
    request: PostPackageManagerAuthCredentialsRequest,
  ): Promise<PostPackageManagerAuthCredentialsResponse>;
  getPackageManagerAuthConfigs(
    request: GetPackageManagerAuthConfigsRequest,
  ): Promise<GetPackageManagerAuthConfigsResponse>;
  updatePackageManagerAuthCredentials(
    request: UpdatePackageManagerAuthCredentialsRequest,
  ): Promise<UpdatePackageManagerAuthCredentialsResponse>;
  deletePackageManagerAuthConfigs(
    request: DeletePackageManagerAuthConfigsRequest,
  ): Promise<DeletePackageManagerAuthConfigsResponse>;
}

export const SmsPackageManagerConfigServiceServiceName = "protos.sms.v2.SmsPackageManagerConfigService";
export class SmsPackageManagerConfigServiceClientImpl implements SmsPackageManagerConfigService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || SmsPackageManagerConfigServiceServiceName;
    this.rpc = rpc;
    this.postPackageManagerAuthCredentials = this.postPackageManagerAuthCredentials.bind(this);
    this.getPackageManagerAuthConfigs = this.getPackageManagerAuthConfigs.bind(this);
    this.updatePackageManagerAuthCredentials = this.updatePackageManagerAuthCredentials.bind(this);
    this.deletePackageManagerAuthConfigs = this.deletePackageManagerAuthConfigs.bind(this);
  }
  postPackageManagerAuthCredentials(
    request: PostPackageManagerAuthCredentialsRequest,
  ): Promise<PostPackageManagerAuthCredentialsResponse> {
    const data = PostPackageManagerAuthCredentialsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "PostPackageManagerAuthCredentials", data);
    return promise.then((data) => PostPackageManagerAuthCredentialsResponse.decode(_m0.Reader.create(data)));
  }

  getPackageManagerAuthConfigs(
    request: GetPackageManagerAuthConfigsRequest,
  ): Promise<GetPackageManagerAuthConfigsResponse> {
    const data = GetPackageManagerAuthConfigsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetPackageManagerAuthConfigs", data);
    return promise.then((data) => GetPackageManagerAuthConfigsResponse.decode(_m0.Reader.create(data)));
  }

  updatePackageManagerAuthCredentials(
    request: UpdatePackageManagerAuthCredentialsRequest,
  ): Promise<UpdatePackageManagerAuthCredentialsResponse> {
    const data = UpdatePackageManagerAuthCredentialsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "UpdatePackageManagerAuthCredentials", data);
    return promise.then((data) => UpdatePackageManagerAuthCredentialsResponse.decode(_m0.Reader.create(data)));
  }

  deletePackageManagerAuthConfigs(
    request: DeletePackageManagerAuthConfigsRequest,
  ): Promise<DeletePackageManagerAuthConfigsResponse> {
    const data = DeletePackageManagerAuthConfigsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "DeletePackageManagerAuthConfigs", data);
    return promise.then((data) => DeletePackageManagerAuthConfigsResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
