import { useMemo } from "react";

import { RunResponse } from "@shared/types";

export const useRunTestResults = (runResponse: RunResponse) =>
  useMemo(
    () => ({
      numTestFailures: runResponse.test_result.reduce(
        (memo, tr) => memo + (tr.status === "FAILURE" ? 1 : 0),
        0
      ),
    }),
    [runResponse.test_result]
  );
