import { JsonValue } from "@bufbuild/protobuf";
import {
  InfiniteData,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";

import {
  ListScmConfigRequest,
  ListScmConfigResponse,
} from "@protos/scm/v1/scm";
import { authGet } from "@shared/api";

import { useOrg } from "../useOrg";
import { useOrgAssertDefined } from "../useOrgAssertDefined";

const LIMIT = 30;

const getDeploymentScmConfigs = async (
  request: ListScmConfigRequest
): Promise<ListScmConfigResponse> => {
  const params = new URLSearchParams();
  if (request.limit) {
    params.set("limit", request.limit.toString());
  }
  if (request.cursor) {
    params.set("cursor", request.cursor);
  }
  const url = `/api/scm/deployments/${
    request.deploymentId
  }/configs?${params.toString()}`;
  const response = await authGet<JsonValue>(url).then((jsonResp) => {
    return ListScmConfigResponse.fromJSON(jsonResp);
  });
  return response;
};

interface UseScmConfigsParams
  extends Omit<ListScmConfigRequest, "deploymentId" | "cursor"> {}

export const useScmConfigsPaginated = (fetchArgs: UseScmConfigsParams = {}) => {
  // set default limit to 100
  const org = useOrgAssertDefined();
  const orgId = org.id;

  return useInfiniteQuery({
    queryKey: ["deploymentScmConfigs", orgId, { ...fetchArgs }],
    select: (data: InfiniteData<ListScmConfigResponse>) => ({
      pages: data.pages.map((page) => page.configs).flatMap((x) => x),
      pageParams: data.pageParams,
    }),
    queryFn: ({ pageParam: cursor = "" }) =>
      getDeploymentScmConfigs({
        deploymentId: orgId,
        cursor,
        limit: fetchArgs.limit ?? LIMIT,
        ...fetchArgs,
      }),
    staleTime: 60000, // 60s until stale (defaults to 0 otherwise)
    getNextPageParam: (lastPage) => {
      return lastPage.cursor;
    },
    initialPageParam: "",
  });
};

/**
 * Get the current deployments SCM configs.
 *
 * @returns a query that returns the current deployments SCM configs
 */
export const useScmConfigList = () => {
  const [org] = useOrg();
  const deploymentId = org?.id;

  return useQuery({
    queryKey: ["deploymentScmConfigs", deploymentId],

    queryFn: async () => {
      const response = await getDeploymentScmConfigs({
        deploymentId: deploymentId!,
      });
      return response.configs.sort((a, b) => a.id - b.id);
    },
    // Only enable the query if we have an org as this hook can be called before
    // the org is fully loaded following a redirect from GitHub on the SetupGitHubInstall
    // component of onboarding at /onboarding/scm/github
    enabled: !!deploymentId,
    // don't refetch if we loaded the cache before rendering (e.g. in useRootGroupIds)
    staleTime: 1000,
  });
};
