import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import deburr from "lodash/deburr";

import { LAST_SELECTED_ORG_KEY, ORGANIZATION_KEY } from "@shared/constants";
import { OrgData } from "@shared/types";

import { orgLoadingAtom } from "./loading";
import { tlrAtom } from "./timeLookbackRage";
import { userAtom } from "./user";

/**
 * Org Atom - stores the org data
 * If you need to access the org data or set a property on the org object, use this atom
 */
export const orgAtom = atomWithStorage<OrgData | undefined>(
  ORGANIZATION_KEY,
  undefined,
  {
    getItem(key, initialValue) {
      const storedValue = localStorage.getItem(key);
      try {
        return JSON.parse(storedValue ?? "");
      } catch {
        return initialValue;
      }
    },
    setItem(key, value) {
      return Promise.resolve(localStorage.setItem(key, JSON.stringify(value)));
    },
    removeItem(key) {
      return Promise.resolve(localStorage.removeItem(key));
    },
  },
  {
    getOnInit: true,
  }
);

/**
 * Change Org Atom (Write Only)
 * If you need to change the org, use this atom
 */
// For write only atoms, the convention is to pass null in as the first value
export const changeOrgAtom = atom(null, async (get, set, newOrg: OrgData) => {
  set(orgLoadingAtom, true);
  // Get the user from the store
  const user = get(userAtom);
  if (!user) return;
  // set the most recent org to the new org
  try {
    localStorage.setItem(LAST_SELECTED_ORG_KEY, getOrgSlug(newOrg));
  } catch (e) {
    console.error(
      "Unable to use a needed browser feature such as localStorage due to browser security / privacy settings.",
      e
    );
  }
  // This is the most important part of the function – set the new org data
  set(orgAtom, newOrg);

  // Set the Time Lookback Range to "All time"
  set(tlrAtom, "All time");
  set(orgLoadingAtom, false);
});

/**
 * TODO this is a temporary fix since we cannot import these functions from the
 * utils library without creating a circular dependency.
 *
 * This will be fixed when we refactor how we handle changing org. See SMS-419
 */
const slugifyNameForOrg = (name: string): string =>
  deburr(name.toLowerCase()).trim().replace(/\W+/g, "-");

const getOrgSlug = (org: OrgData): string => {
  return org.slug ?? slugifyNameForOrg(org.name);
};
