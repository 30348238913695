import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { FeedbackRequest } from "@protos/search/v1/search_service";
import { authPost } from "@shared/api";
import { ApiError } from "@shared/types";

const submitFeedback = async (request: FeedbackRequest) => {
  return authPost<FeedbackRequest, void>("/api/console/feedback", request);
};

export const useQueryConsoleFeedback = (
  options?: Omit<
    UseMutationOptions<void, ApiError, FeedbackRequest>,
    "mutationFn"
  >
) => {
  return useMutation({ mutationFn: submitFeedback, ...options });
};
