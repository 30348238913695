/*
  Generated by atdts from type definitions in 'semgrep_output_v1.atd'.

  Type-safe translations from/to JSON

  For each type 'Foo', there is a pair of functions:
  - 'writeFoo': convert a 'Foo' value into a JSON-compatible value.
  - 'readFoo': convert a JSON-compatible value into a TypeScript value
    of type 'Foo'.
*/

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */

export type RawJson = any

export type Fpath = string

export type Uri = string

export type Sha1 = string

export type Uuid = string

export type Datetime = string

export type Glob = string

export type Version = string

export type Position = {
  line: number /*int*/;
  col: number /*int*/;
  offset: number /*int*/;
}

export type Location = {
  path: Fpath;
  start: Position;
  end: Position;
}

export type RuleId = string

export type MatchSeverity =
| { kind: 'Error' /* JSON: "ERROR" */ }
| { kind: 'Warning' /* JSON: "WARNING" */ }
| { kind: 'Experiment' /* JSON: "EXPERIMENT" */ }
| { kind: 'Inventory' /* JSON: "INVENTORY" */ }
| { kind: 'Critical' /* JSON: "CRITICAL" */ }
| { kind: 'High' /* JSON: "HIGH" */ }
| { kind: 'Medium' /* JSON: "MEDIUM" */ }
| { kind: 'Low' /* JSON: "LOW" */ }
| { kind: 'Info' /* JSON: "INFO" */ }

export type ErrorSeverity =
| { kind: 'Error' /* JSON: "error" */ }
| { kind: 'Warning' /* JSON: "warn" */ }
| { kind: 'Info' /* JSON: "info" */ }

export type ProFeature = {
  interproc_taint: boolean;
  interfile_taint: boolean;
  proprietary_language: boolean;
}

export type EngineOfFinding =
| { kind: 'OSS' }
| { kind: 'PRO' }
| { kind: 'PRO_REQUIRED'; value: ProFeature }

export type EngineKind =
| { kind: 'OSS' }
| { kind: 'PRO' }

export type RuleIdAndEngineKind = [RuleId, EngineKind]

export type Product =
| { kind: 'SAST' /* JSON: "sast" */ }
| { kind: 'SCA' /* JSON: "sca" */ }
| { kind: 'Secrets' /* JSON: "secrets" */ }

export type MatchBasedId = string

export type CliMatch = {
  check_id: RuleId;
  path: Fpath;
  start: Position;
  end: Position;
  extra: CliMatchExtra;
}

export type CliMatchExtra = {
  metavars?: Metavars;
  message: string;
  fix?: string;
  fixed_lines?: string[];
  metadata: RawJson;
  severity: MatchSeverity;
  fingerprint: string;
  lines: string;
  is_ignored?: boolean;
  sca_info?: ScaMatch;
  validation_state?: ValidationState;
  historical_info?: HistoricalInfo;
  dataflow_trace?: MatchDataflowTrace;
  engine_kind?: EngineOfFinding;
  extra_extra?: RawJson;
}

export type Metavars = Map<string, MetavarValue>

export type MetavarValue = {
  start: Position;
  end: Position;
  abstract_content: string;
  propagated_value?: SvalueValue;
}

export type SvalueValue = {
  svalue_start?: Position;
  svalue_end?: Position;
  svalue_abstract_content: string;
}

export type MatchingExplanation = {
  op: MatchingOperation;
  children: MatchingExplanation[];
  matches: CoreMatch[];
  loc: Location;
  extra?: MatchingExplanationExtra;
}

export type MatchingExplanationExtra = {
  before_negation_matches: Option<CoreMatch[]>;
  before_filter_matches: Option<CoreMatch[]>;
}

export type MatchingOperation =
| { kind: 'And' }
| { kind: 'Or' }
| { kind: 'Inside' }
| { kind: 'Anywhere' }
| { kind: 'XPat'; value: string }
| { kind: 'Negation' }
| { kind: 'Filter'; value: string }
| { kind: 'Taint' }
| { kind: 'TaintSource' }
| { kind: 'TaintSink' }
| { kind: 'TaintSanitizer' }
| { kind: 'EllipsisAndStmts' }
| { kind: 'ClassHeaderAndElems' }

export type MatchDataflowTrace = {
  taint_source?: MatchCallTrace;
  intermediate_vars?: MatchIntermediateVar[];
  taint_sink?: MatchCallTrace;
}

export type LocAndContent = [Location, string]

export type MatchCallTrace =
| { kind: 'CliLoc'; value: LocAndContent }
| { kind: 'CliCall'; value: [LocAndContent, MatchIntermediateVar[], MatchCallTrace] }

export type MatchIntermediateVar = {
  location: Location;
  content: string;
}

export type Ecosystem =
| { kind: 'Npm' /* JSON: "npm" */ }
| { kind: 'Pypi' /* JSON: "pypi" */ }
| { kind: 'Gem' /* JSON: "gem" */ }
| { kind: 'Gomod' /* JSON: "gomod" */ }
| { kind: 'Cargo' /* JSON: "cargo" */ }
| { kind: 'Maven' /* JSON: "maven" */ }
| { kind: 'Composer' /* JSON: "composer" */ }
| { kind: 'Nuget' /* JSON: "nuget" */ }
| { kind: 'Pub' /* JSON: "pub" */ }
| { kind: 'SwiftPM' /* JSON: "swiftpm" */ }
| { kind: 'Cocoapods' /* JSON: "cocoapods" */ }
| { kind: 'Mix' /* JSON: "mix" */ }
| { kind: 'Hex' /* JSON: "hex" */ }

export type Transitivity =
| { kind: 'Direct' /* JSON: "direct" */ }
| { kind: 'Transitive' /* JSON: "transitive" */ }
| { kind: 'Unknown' /* JSON: "unknown" */ }

export type ScaMatch = {
  reachable: boolean;
  reachability_rule: boolean;
  sca_finding_schema: number /*int*/;
  dependency_match: DependencyMatch;
}

export type DependencyMatch = {
  dependency_pattern: ScaPattern;
  found_dependency: FoundDependency;
  lockfile: Fpath;
}

export type ScaPattern = {
  ecosystem: Ecosystem;
  package_: string;
  semver_range: string;
}

export type FoundDependency = {
  package_: string;
  version: string;
  ecosystem: Ecosystem;
  allowed_hashes: Map<string, string[]>;
  resolved_url?: string;
  transitivity: Transitivity;
  manifest_path?: Fpath;
  lockfile_path?: Fpath;
  line_number?: number /*int*/;
  children?: DependencyChild[];
  git_ref?: string;
}

export type DependencyChild = {
  package_: string;
  version: string;
}

export type ValidationState =
| { kind: 'Confirmed_valid' /* JSON: "CONFIRMED_VALID" */ }
| { kind: 'Confirmed_invalid' /* JSON: "CONFIRMED_INVALID" */ }
| { kind: 'Validation_error' /* JSON: "VALIDATION_ERROR" */ }
| { kind: 'No_validator' /* JSON: "NO_VALIDATOR" */ }

export type HistoricalInfo = {
  git_commit: Sha1;
  git_blob?: Sha1;
  git_commit_timestamp: Datetime;
}

export type ErrorType =
| { kind: 'LexicalError' /* JSON: "Lexical error" */ }
| { kind: 'ParseError' /* JSON: "Syntax error" */ }
| { kind: 'OtherParseError' /* JSON: "Other syntax error" */ }
| { kind: 'AstBuilderError' /* JSON: "AST builder error" */ }
| { kind: 'RuleParseError' /* JSON: "Rule parse error" */ }
| { kind: 'SemgrepWarning' }
| { kind: 'SemgrepError' }
| { kind: 'InvalidRuleSchemaError' }
| { kind: 'UnknownLanguageError' }
| { kind: 'InvalidYaml' /* JSON: "Invalid YAML" */ }
| { kind: 'MatchingError' /* JSON: "Internal matching error" */ }
| { kind: 'SemgrepMatchFound' /* JSON: "Semgrep match found" */ }
| { kind: 'TooManyMatches' /* JSON: "Too many matches" */ }
| { kind: 'FatalError' /* JSON: "Fatal error" */ }
| { kind: 'Timeout' }
| { kind: 'OutOfMemory' /* JSON: "Out of memory" */ }
| { kind: 'StackOverflow' /* JSON: "Stack overflow" */ }
| { kind: 'TimeoutDuringInterfile' /* JSON: "Timeout during interfile analysis" */ }
| { kind: 'OutOfMemoryDuringInterfile' /* JSON: "OOM during interfile analysis" */ }
| { kind: 'MissingPlugin' /* JSON: "Missing plugin" */ }
| { kind: 'PatternParseError'; value: string[] }
| { kind: 'PartialParsing'; value: Location[] }
| { kind: 'IncompatibleRule'; value: IncompatibleRule }
| { kind: 'PatternParseError0' /* JSON: "Pattern parse error" */ }
| { kind: 'IncompatibleRule0' /* JSON: "Incompatible rule" */ }
| { kind: 'DependencyResolutionError'; value: ResolutionError }

export type IncompatibleRule = {
  rule_id: RuleId;
  this_version: Version;
  min_version?: Version;
  max_version?: Version;
}

export type CliError = {
  code: number /*int*/;
  level: ErrorSeverity;
  type_: ErrorType;
  rule_id?: RuleId;
  message?: string;
  path?: Fpath;
  long_msg?: string;
  short_msg?: string;
  spans?: ErrorSpan[];
  help?: string;
}

export type ErrorSpan = {
  file: Fpath;
  start: Position;
  end: Position;
  source_hash?: string;
  config_start?: (Position | null);
  config_end?: (Position | null);
  config_path?: (string[] | null);
  context_start?: (Position | null);
  context_end?: (Position | null);
}

export type SkipReason =
| { kind: 'Always_skipped' /* JSON: "always_skipped" */ }
| { kind: 'Semgrepignore_patterns_match' /* JSON: "semgrepignore_patterns_match" */ }
| { kind: 'Cli_include_flags_do_not_match' /* JSON: "cli_include_flags_do_not_match" */ }
| { kind: 'Cli_exclude_flags_match' /* JSON: "cli_exclude_flags_match" */ }
| { kind: 'Exceeded_size_limit' /* JSON: "exceeded_size_limit" */ }
| { kind: 'Analysis_failed_parser_or_internal_error' /* JSON: "analysis_failed_parser_or_internal_error" */ }
| { kind: 'Excluded_by_config' /* JSON: "excluded_by_config" */ }
| { kind: 'Wrong_language' /* JSON: "wrong_language" */ }
| { kind: 'Too_big' /* JSON: "too_big" */ }
| { kind: 'Minified' /* JSON: "minified" */ }
| { kind: 'Binary' /* JSON: "binary" */ }
| { kind: 'Irrelevant_rule' /* JSON: "irrelevant_rule" */ }
| { kind: 'Too_many_matches' /* JSON: "too_many_matches" */ }
| { kind: 'Gitignore_patterns_match' }
| { kind: 'Dotfile' }
| { kind: 'Nonexistent_file' }
| { kind: 'Insufficient_permissions' /* JSON: "insufficient_permissions" */ }

export type SkippedTarget = {
  path: Fpath;
  reason: SkipReason;
  details?: string;
  rule_id?: RuleId;
}

export type ScannedAndSkipped = {
  scanned: Fpath[];
  skipped?: SkippedTarget[];
}

export type SkippedRule = {
  rule_id: RuleId;
  details: string;
  position: Position;
}

export type Profile = {
  rules: RuleId[];
  rules_parse_time: number;
  profiling_times: Map<string, number>;
  targets: TargetTimes[];
  total_bytes: number /*int*/;
  max_memory_bytes?: number /*int*/;
}

export type TargetTimes = {
  path: Fpath;
  num_bytes: number /*int*/;
  match_times: number[];
  parse_times: number[];
  run_time: number;
}

export type CliOutput = {
  version?: Version;
  results: CliMatch[];
  errors: CliError[];
  paths: ScannedAndSkipped;
  time?: Profile;
  explanations?: MatchingExplanation[];
  rules_by_engine?: RuleIdAndEngineKind[];
  engine_requested?: EngineKind;
  interfile_languages_used?: string[];
  skipped_rules: SkippedRule[];
}

export type CliOutputExtra = {
  paths: ScannedAndSkipped;
  time?: Profile;
  explanations?: MatchingExplanation[];
  rules_by_engine?: RuleIdAndEngineKind[];
  engine_requested?: EngineKind;
  interfile_languages_used?: string[];
  skipped_rules: SkippedRule[];
}

export type ConfigErrorReason =
| { kind: 'UnparsableRule' /* JSON: "unparsable_rule" */ }

export type ConfigError = {
  file: Fpath;
  reason: ConfigErrorReason;
}

export type TestsResult = {
  results: [string, Checks][];
  fixtest_results: [string, FixtestResult][];
  config_missing_tests: Fpath[];
  config_missing_fixtests: Fpath[];
  config_with_errors: ConfigError[];
}

export type Checks = {
  checks: [string, RuleResult][];
}

export type RuleResult = {
  passed: boolean;
  matches: [string, ExpectedReported][];
  errors: Todo[];
  diagnosis?: MatchingDiagnosis;
}

export type ExpectedReported = {
  expected_lines: number /*int*/[];
  reported_lines: number /*int*/[];
}

export type FixtestResult = {
  passed: boolean;
}

export type Todo = number /*int*/

export type MatchingDiagnosis = {
  target: Fpath;
  unexpected_match_diagnoses: UnexpectedMatchDiagnosis[];
  unexpected_no_match_diagnoses: UnexpectedNoMatchDiagnosis[];
}

export type UnexpectedMatchDiagnosis = {
  matched_text: Snippet;
  originating_kind: OriginatingNodeKind;
  originating_text: Snippet;
  killing_parents: KillingParent[];
}

export type UnexpectedNoMatchDiagnosis = {
  line: number /*int*/;
  kind: UnexpectedNoMatchDiagnosisKind;
}

export type UnexpectedNoMatchDiagnosisKind =
| { kind: 'Never_matched' }
| { kind: 'Killed_by_nodes'; value: KillingParent[] }

export type OriginatingNodeKind =
| { kind: 'Focus' }
| { kind: 'Xpattern' }

export type KillingParentKind =
| { kind: 'And' }
| { kind: 'Inside' }
| { kind: 'Negation' }
| { kind: 'Filter'; value: string }

export type Snippet = {
  line: number /*int*/;
  text: string;
}

export type KillingParent = {
  killing_parent_kind: KillingParentKind;
  snippet: Snippet;
}

export type Features = {
  autofix: boolean;
  deepsemgrep: boolean;
  dependency_query: boolean;
  path_to_transitivity: boolean;
  scan_all_deps_in_diff_scan: boolean;
}

export type TriageIgnored = {
  triage_ignored_syntactic_ids: string[];
  triage_ignored_match_based_ids: string[];
}

export type Action =
| { kind: 'Message'; value: string }
| { kind: 'Delay'; value: number }
| { kind: 'Exit'; value: number /*int*/ }

export type ScanResponse = {
  info: ScanInfo;
  config: ScanConfiguration;
  engine_params: EngineConfiguration;
}

export type ScanInfo = {
  id?: number /*int*/;
  enabled_products: Product[];
  deployment_id: number /*int*/;
  deployment_name: string;
}

export type ScanConfiguration = {
  rules: RawJson;
  triage_ignored_syntactic_ids: string[];
  triage_ignored_match_based_ids: string[];
}

export type EngineConfiguration = {
  autofix: boolean;
  deepsemgrep: boolean;
  dependency_query: boolean;
  path_to_transitivity: boolean;
  scan_all_deps_in_diff_scan: boolean;
  ignored_files: string[];
  product_ignored_files?: ProductIgnoredFiles;
  generic_slow_rollout: boolean;
  historical_config?: HistoricalConfiguration;
  always_suppress_errors: boolean;
}

export type ProductIgnoredFiles = Map<Product, Glob[]>

export type HistoricalConfiguration = {
  enabled: boolean;
  lookback_days?: number /*int*/;
}

export type ScanRequest = {
  project_metadata: ProjectMetadata;
  scan_metadata: ScanMetadata;
  project_config?: CiConfigFromRepo;
}

export type ProjectMetadata = {
  scan_environment: string;
  repository: string;
  repo_url: (Uri | null);
  repo_id?: string;
  org_id?: string;
  repo_display_name?: string;
  branch: (string | null);
  commit: (Sha1 | null);
  commit_title: (string | null);
  commit_timestamp?: Datetime;
  commit_author_email: (string | null);
  commit_author_name: (string | null);
  commit_author_username: (string | null);
  commit_author_image_url: (Uri | null);
  ci_job_url: (Uri | null);
  on: string;
  pull_request_author_username: (string | null);
  pull_request_author_image_url: (Uri | null);
  pull_request_id: (string | null);
  pull_request_title: (string | null);
  base_sha?: Sha1;
  start_sha?: Sha1;
  is_full_scan: boolean;
  is_sca_scan?: boolean;
  is_code_scan?: boolean;
  is_secrets_scan?: boolean;
}

export type ScanMetadata = {
  cli_version: Version;
  unique_id: Uuid;
  requested_products: Product[];
  dry_run: boolean;
  sms_scan_id?: string;
}

export type CiConfigFromRepo = {
  version: Version;
  tags?: Tag[];
}

export type Tag = string

export type Finding = {
  check_id: RuleId;
  path: Fpath;
  line: number /*int*/;
  column: number /*int*/;
  end_line: number /*int*/;
  end_column: number /*int*/;
  message: string;
  severity: any;
  index: number /*int*/;
  commit_date: string;
  syntactic_id: string;
  match_based_id?: string;
  hashes?: FindingHashes;
  metadata: RawJson;
  is_blocking: boolean;
  fixed_lines?: string[];
  sca_info?: ScaMatch;
  dataflow_trace?: MatchDataflowTrace;
  validation_state?: ValidationState;
  historical_info?: HistoricalInfo;
  engine_kind?: EngineOfFinding;
}

export type FindingHashes = {
  start_line_hash: string;
  end_line_hash: string;
  code_hash: string;
  pattern_hash: string;
}

export type CiScanResults = {
  findings: Finding[];
  ignores: Finding[];
  token: (string | null);
  searched_paths: Fpath[];
  renamed_paths: Fpath[];
  rule_ids: RuleId[];
  contributions?: Contributions;
  dependencies?: CiScanDependencies;
}

export type Contributor = {
  commit_author_name: string;
  commit_author_email: string;
}

export type Contribution = {
  commit_hash: string;
  commit_timestamp: Datetime;
  contributor: Contributor;
}

export type Contributions = Contribution[]

export type CiScanResultsResponse = {
  errors: CiScanResultsResponseError[];
  task_id?: string;
}

export type CiScanResultsResponseError = {
  message: string;
}

export type CiScanComplete = {
  exit_code: number /*int*/;
  stats: CiScanCompleteStats;
  dependencies?: CiScanDependencies;
  dependency_parser_errors?: DependencyParserError[];
  task_id?: string;
  final_attempt?: boolean;
}

export type CiScanCompleteStats = {
  findings: number /*int*/;
  errors: CliError[];
  total_time: number;
  unsupported_exts: Map<string, number /*int*/>;
  lockfile_scan_info: Map<string, number /*int*/>;
  parse_rate: Map<string, ParsingStats>;
  engine_requested?: string;
  findings_by_product?: Map<string, number /*int*/>;
  supply_chain_stats?: SupplyChainStats;
}

export type ParsingStats = {
  targets_parsed: number /*int*/;
  num_targets: number /*int*/;
  bytes_parsed: number /*int*/;
  num_bytes: number /*int*/;
}

export type CiScanCompleteResponse = {
  success: boolean;
  app_block_override: boolean;
  app_block_reason: string;
  app_blocking_match_based_ids: MatchBasedId[];
}

export type CiScanDependencies = Map<string, FoundDependency[]>

export type DependencyParserError = {
  path: Fpath;
  parser: ScaParserName;
  reason: string;
  line?: number /*int*/;
  col?: number /*int*/;
  text?: string;
}

export type ScaParserName =
| { kind: 'Gemfile_lock' /* JSON: "gemfile_lock" */ }
| { kind: 'Go_mod' /* JSON: "go_mod" */ }
| { kind: 'Go_sum' /* JSON: "go_sum" */ }
| { kind: 'Gradle_lockfile' /* JSON: "gradle_lockfile" */ }
| { kind: 'Gradle_build' /* JSON: "gradle_build" */ }
| { kind: 'Jsondoc' /* JSON: "jsondoc" */ }
| { kind: 'Pipfile' /* JSON: "pipfile" */ }
| { kind: 'Pnpm_lock' /* JSON: "pnpm_lock" */ }
| { kind: 'Poetry_lock' /* JSON: "poetry_lock" */ }
| { kind: 'Pyproject_toml' /* JSON: "pyproject_toml" */ }
| { kind: 'Requirements' /* JSON: "requirements" */ }
| { kind: 'Yarn_1' /* JSON: "yarn_1" */ }
| { kind: 'Yarn_2' /* JSON: "yarn_2" */ }
| { kind: 'Pomtree' /* JSON: "pomtree" */ }
| { kind: 'Cargo_parser' /* JSON: "cargo" */ }
| { kind: 'Composer_lock' /* JSON: "composer_lock" */ }
| { kind: 'Pubspec_lock' /* JSON: "pubspec_lock" */ }
| { kind: 'Package_swift' /* JSON: "package_swift" */ }
| { kind: 'Podfile_lock' /* JSON: "podfile_lock" */ }
| { kind: 'Package_resolved' /* JSON: "package_resolved" */ }
| { kind: 'Mix_lock' /* JSON: "mix_lock" */ }

export type SupplyChainStats = {
  subprojects_stats: SubprojectStats[];
}

export type SubprojectStats = {
  subproject_id: string;
  dependency_sources: DependencySourceFile[];
  resolved_stats?: DependencyResolutionStats;
}

export type DependencySourceFile = {
  kind: DependencySourceFileKind;
  path: Fpath;
}

export type DependencySourceFileKind =
| { kind: 'Lockfile'; value: LockfileKind }
| { kind: 'Manifest'; value: ManifestKind }

export type DependencyResolutionStats = {
  resolution_method: ResolutionMethod;
  dependency_count: number /*int*/;
  ecosystem: Ecosystem;
}

export type ResolutionMethod =
| { kind: 'LockfileParsing' }
| { kind: 'DynamicResolution' }

export type CiScanFailure = {
  exit_code: number /*int*/;
  stderr: string;
}

export type DeploymentConfig = {
  id: number /*int*/;
  name: string;
  organization_id: number /*int*/;
  display_name: string;
  scm_name: string;
  slug: string;
  source_type: string;
  default_user_role: string;
  has_autofix: boolean;
  has_deepsemgrep: boolean;
  has_triage_via_comment: boolean;
  has_dependency_query: boolean;
}

export type HasFeatures = {
  has_autofix: boolean;
  has_deepsemgrep: boolean;
  has_triage_via_comment: boolean;
  has_dependency_query: boolean;
}

export type DeploymentResponse = {
  deployment: DeploymentConfig;
}

export type ScanConfig = {
  deployment_id: number /*int*/;
  deployment_name: string;
  policy_names: string[];
  rule_config: string;
  autofix: boolean;
  deepsemgrep: boolean;
  dependency_query: boolean;
  path_to_transitivity: boolean;
  scan_all_deps_in_diff_scan: boolean;
  triage_ignored_syntactic_ids: string[];
  triage_ignored_match_based_ids: string[];
  ignored_files: string[];
  enabled_products?: Product[];
  actions: Action[];
  ci_config_from_cloud?: CiConfigFromCloud;
}

export type CiConfigFromCloud = {
  repo_config: CiConfig;
  org_config?: CiConfig;
  dirs_config?: [Fpath, CiConfig][];
  actions: Action[];
}

export type CiConfig = {
  env: CiEnv;
  enabled_products: Product[];
  ignored_files: string[];
  autofix: boolean;
  deepsemgrep: boolean;
  dependency_query: boolean;
  path_to_transitivity: boolean;
  scan_all_deps_in_diff_scan: boolean;
}

export type CiEnv = Map<string, string>

export type CoreOutput = {
  version: Version;
  results: CoreMatch[];
  errors: CoreError[];
  paths: ScannedAndSkipped;
  time?: Profile;
  explanations?: MatchingExplanation[];
  rules_by_engine?: RuleIdAndEngineKind[];
  engine_requested?: EngineKind;
  interfile_languages_used?: string[];
  skipped_rules: SkippedRule[];
}

export type CoreMatch = {
  check_id: RuleId;
  path: Fpath;
  start: Position;
  end: Position;
  extra: CoreMatchExtra;
}

export type CoreMatchExtra = {
  metavars: Metavars;
  engine_kind: EngineOfFinding;
  is_ignored: boolean;
  message?: string;
  metadata?: RawJson;
  severity?: MatchSeverity;
  fix?: string;
  dataflow_trace?: MatchDataflowTrace;
  sca_match?: ScaMatch;
  validation_state?: ValidationState;
  historical_info?: HistoricalInfo;
  extra_extra?: RawJson;
}

export type CoreError = {
  error_type: ErrorType;
  severity: ErrorSeverity;
  message: string;
  details?: string;
  location?: Location;
  rule_id?: RuleId;
}

export type Analyzer = string

export type Target =
| { kind: 'CodeTarget'; value: CodeTarget }
| { kind: 'LockfileTarget'; value: Lockfile }

export type CodeTarget = {
  path: Fpath;
  analyzer: Analyzer;
  products: Product[];
  lockfile_target?: Lockfile;
}

export type Targets = Target[]

export type Edit = {
  path: Fpath;
  start_offset: number /*int*/;
  end_offset: number /*int*/;
  replacement_text: string;
}

export type ApplyFixesParams = {
  dryrun: boolean;
  edits: Edit[];
}

export type ApplyFixesReturn = {
  modified_file_count: number /*int*/;
  fixed_lines: [number /*int*/, string[]][];
}

export type SarifFormat = {
  rules: Fpath;
  is_pro: boolean;
  show_dataflow_traces: boolean;
}

export type OutputFormat =
| { kind: 'Text' }
| { kind: 'Json' }
| { kind: 'Emacs' }
| { kind: 'Vim' }
| { kind: 'Sarif' }
| { kind: 'Gitlab_sast' }
| { kind: 'Gitlab_secrets' }
| { kind: 'Junit_xml' }
| { kind: 'Files_with_matches' }
| { kind: 'Incremental' }

export type FormatContext = {
  is_ci_invocation: boolean;
  is_logged_in: boolean;
  is_using_registry: boolean;
}

export type DumpRulePartitionsParams = {
  rules: RawJson;
  n_partitions: number /*int*/;
  output_dir: Fpath;
}

export type LockfileKind =
| { kind: 'PipRequirementsTxt' }
| { kind: 'PoetryLock' }
| { kind: 'PipfileLock' }
| { kind: 'UvLock' }
| { kind: 'NpmPackageLockJson' }
| { kind: 'YarnLock' }
| { kind: 'PnpmLock' }
| { kind: 'GemfileLock' }
| { kind: 'GoMod' }
| { kind: 'CargoLock' }
| { kind: 'MavenDepTree' }
| { kind: 'GradleLockfile' }
| { kind: 'ComposerLock' }
| { kind: 'NugetPackagesLockJson' }
| { kind: 'PubspecLock' }
| { kind: 'SwiftPackageResolved' }
| { kind: 'PodfileLock' }
| { kind: 'MixLock' }
| { kind: 'ConanLock' }

export type ManifestKind =
| { kind: 'RequirementsIn' }
| { kind: 'PackageJson' }
| { kind: 'Gemfile' }
| { kind: 'GoMod' }
| { kind: 'CargoToml' }
| { kind: 'PomXml' }
| { kind: 'BuildGradle' }
| { kind: 'SettingsGradle' }
| { kind: 'ComposerJson' }
| { kind: 'NugetManifestJson' }
| { kind: 'PubspecYaml' }
| { kind: 'PackageSwift' }
| { kind: 'Podfile' }
| { kind: 'MixExs' }
| { kind: 'Pipfile' }
| { kind: 'PyprojectToml' }
| { kind: 'ConanFileTxt' }
| { kind: 'ConanFilePy' }
| { kind: 'Csproj' }

export type Manifest = {
  kind: ManifestKind;
  path: Fpath;
}

export type Lockfile = {
  kind: LockfileKind;
  path: Fpath;
}

export type DependencySource =
| { kind: 'ManifestOnlyDependencySource'; value: Manifest }
| { kind: 'LockfileOnlyDependencySource'; value: Lockfile }
| { kind: 'ManifestLockfileDependencySource'; value: [Manifest, Lockfile] }

export type ResolutionError =
| { kind: 'UnsupportedManifest' }
| { kind: 'MissingRequirement'; value: string }
| { kind: 'ResolutionCmdFailed'; value: ResolutionCmdFailed }
| { kind: 'ParseDependenciesFailed'; value: string }

export type ResolutionCmdFailed = {
  command: string;
  message: string;
}

export type ResolutionResult =
| { kind: 'ResolutionOk'; value: [FoundDependency[], ResolutionError[]] }
| { kind: 'ResolutionError'; value: ResolutionError[] }

export type FunctionCall =
| { kind: 'CallContributions' }
| { kind: 'CallApplyFixes'; value: ApplyFixesParams }
| { kind: 'CallFormatter'; value: [OutputFormat, FormatContext, CliOutput] }
| { kind: 'CallSarifFormat'; value: [SarifFormat, FormatContext, CliOutput] }
| { kind: 'CallValidate'; value: Fpath }
| { kind: 'CallResolveDependencies'; value: DependencySource[] }
| { kind: 'CallDumpRulePartitions'; value: DumpRulePartitionsParams }

export type FunctionReturn =
| { kind: 'RetError'; value: string }
| { kind: 'RetApplyFixes'; value: ApplyFixesReturn }
| { kind: 'RetContributions'; value: Contributions }
| { kind: 'RetFormatter'; value: string }
| { kind: 'RetSarifFormat'; value: string }
| { kind: 'RetValidate'; value: boolean }
| { kind: 'RetResolveDependencies'; value: [DependencySource, ResolutionResult][] }
| { kind: 'RetDumpRulePartitions'; value: boolean }

export type PartialScanResult =
| { kind: 'PartialScanOk'; value: [CiScanResults, CiScanComplete] }
| { kind: 'PartialScanError'; value: CiScanFailure }

export type DiffFile = {
  filename: Fpath;
  diffs: string[];
  url: string;
}

export type DiffFiles = {
  cve_diffs: DiffFile[];
}

export function writeRawJson(x: RawJson, context: any = x): any {
  return ((x: any, context): any => x)(x, context);
}

export function readRawJson(x: any, context: any = x): RawJson {
  return ((x: any, context): any => x)(x, context);
}

export function writeFpath(x: Fpath, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readFpath(x: any, context: any = x): Fpath {
  return _atd_read_string(x, context);
}

export function writeUri(x: Uri, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readUri(x: any, context: any = x): Uri {
  return _atd_read_string(x, context);
}

export function writeSha1(x: Sha1, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readSha1(x: any, context: any = x): Sha1 {
  return _atd_read_string(x, context);
}

export function writeUuid(x: Uuid, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readUuid(x: any, context: any = x): Uuid {
  return _atd_read_string(x, context);
}

export function writeDatetime(x: Datetime, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readDatetime(x: any, context: any = x): Datetime {
  return _atd_read_string(x, context);
}

export function writeGlob(x: Glob, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readGlob(x: any, context: any = x): Glob {
  return _atd_read_string(x, context);
}

export function writeVersion(x: Version, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readVersion(x: any, context: any = x): Version {
  return _atd_read_string(x, context);
}

export function writePosition(x: Position, context: any = x): any {
  return {
    'line': _atd_write_required_field('Position', 'line', _atd_write_int, x.line, x),
    'col': _atd_write_required_field('Position', 'col', _atd_write_int, x.col, x),
    'offset': _atd_write_field_with_default(_atd_write_int, 0, x.offset, x),
  };
}

export function readPosition(x: any, context: any = x): Position {
  return {
    line: _atd_read_required_field('Position', 'line', _atd_read_int, x['line'], x),
    col: _atd_read_required_field('Position', 'col', _atd_read_int, x['col'], x),
    offset: _atd_read_field_with_default(_atd_read_int, 0, x['offset'], x),
  };
}

export function writeLocation(x: Location, context: any = x): any {
  return {
    'path': _atd_write_required_field('Location', 'path', writeFpath, x.path, x),
    'start': _atd_write_required_field('Location', 'start', writePosition, x.start, x),
    'end': _atd_write_required_field('Location', 'end', writePosition, x.end, x),
  };
}

export function readLocation(x: any, context: any = x): Location {
  return {
    path: _atd_read_required_field('Location', 'path', readFpath, x['path'], x),
    start: _atd_read_required_field('Location', 'start', readPosition, x['start'], x),
    end: _atd_read_required_field('Location', 'end', readPosition, x['end'], x),
  };
}

export function writeRuleId(x: RuleId, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readRuleId(x: any, context: any = x): RuleId {
  return _atd_read_string(x, context);
}

export function writeMatchSeverity(x: MatchSeverity, context: any = x): any {
  switch (x.kind) {
    case 'Error':
      return 'ERROR'
    case 'Warning':
      return 'WARNING'
    case 'Experiment':
      return 'EXPERIMENT'
    case 'Inventory':
      return 'INVENTORY'
    case 'Critical':
      return 'CRITICAL'
    case 'High':
      return 'HIGH'
    case 'Medium':
      return 'MEDIUM'
    case 'Low':
      return 'LOW'
    case 'Info':
      return 'INFO'
  }
}

export function readMatchSeverity(x: any, context: any = x): MatchSeverity {
  switch (x) {
    case 'ERROR':
      return { kind: 'Error' }
    case 'WARNING':
      return { kind: 'Warning' }
    case 'EXPERIMENT':
      return { kind: 'Experiment' }
    case 'INVENTORY':
      return { kind: 'Inventory' }
    case 'CRITICAL':
      return { kind: 'Critical' }
    case 'HIGH':
      return { kind: 'High' }
    case 'MEDIUM':
      return { kind: 'Medium' }
    case 'LOW':
      return { kind: 'Low' }
    case 'INFO':
      return { kind: 'Info' }
    default:
      _atd_bad_json('MatchSeverity', x, context)
      throw new Error('impossible')
  }
}

export function writeErrorSeverity(x: ErrorSeverity, context: any = x): any {
  switch (x.kind) {
    case 'Error':
      return 'error'
    case 'Warning':
      return 'warn'
    case 'Info':
      return 'info'
  }
}

export function readErrorSeverity(x: any, context: any = x): ErrorSeverity {
  switch (x) {
    case 'error':
      return { kind: 'Error' }
    case 'warn':
      return { kind: 'Warning' }
    case 'info':
      return { kind: 'Info' }
    default:
      _atd_bad_json('ErrorSeverity', x, context)
      throw new Error('impossible')
  }
}

export function writeProFeature(x: ProFeature, context: any = x): any {
  return {
    'interproc_taint': _atd_write_required_field('ProFeature', 'interproc_taint', _atd_write_bool, x.interproc_taint, x),
    'interfile_taint': _atd_write_required_field('ProFeature', 'interfile_taint', _atd_write_bool, x.interfile_taint, x),
    'proprietary_language': _atd_write_required_field('ProFeature', 'proprietary_language', _atd_write_bool, x.proprietary_language, x),
  };
}

export function readProFeature(x: any, context: any = x): ProFeature {
  return {
    interproc_taint: _atd_read_required_field('ProFeature', 'interproc_taint', _atd_read_bool, x['interproc_taint'], x),
    interfile_taint: _atd_read_required_field('ProFeature', 'interfile_taint', _atd_read_bool, x['interfile_taint'], x),
    proprietary_language: _atd_read_required_field('ProFeature', 'proprietary_language', _atd_read_bool, x['proprietary_language'], x),
  };
}

export function writeEngineOfFinding(x: EngineOfFinding, context: any = x): any {
  switch (x.kind) {
    case 'OSS':
      return 'OSS'
    case 'PRO':
      return 'PRO'
    case 'PRO_REQUIRED':
      return ['PRO_REQUIRED', writeProFeature(x.value, x)]
  }
}

export function readEngineOfFinding(x: any, context: any = x): EngineOfFinding {
  if (typeof x === 'string') {
    switch (x) {
      case 'OSS':
        return { kind: 'OSS' }
      case 'PRO':
        return { kind: 'PRO' }
      default:
        _atd_bad_json('EngineOfFinding', x, context)
        throw new Error('impossible')
    }
  }
  else {
    _atd_check_json_tuple(2, x, context)
    switch (x[0]) {
      case 'PRO_REQUIRED':
        return { kind: 'PRO_REQUIRED', value: readProFeature(x[1], x) }
      default:
        _atd_bad_json('EngineOfFinding', x, context)
        throw new Error('impossible')
    }
  }
}

export function writeEngineKind(x: EngineKind, context: any = x): any {
  switch (x.kind) {
    case 'OSS':
      return 'OSS'
    case 'PRO':
      return 'PRO'
  }
}

export function readEngineKind(x: any, context: any = x): EngineKind {
  switch (x) {
    case 'OSS':
      return { kind: 'OSS' }
    case 'PRO':
      return { kind: 'PRO' }
    default:
      _atd_bad_json('EngineKind', x, context)
      throw new Error('impossible')
  }
}

export function writeRuleIdAndEngineKind(x: RuleIdAndEngineKind, context: any = x): any {
  return ((x, context) => [writeRuleId(x[0], x), writeEngineKind(x[1], x)])(x, context);
}

export function readRuleIdAndEngineKind(x: any, context: any = x): RuleIdAndEngineKind {
  return ((x, context): [RuleId, EngineKind] => { _atd_check_json_tuple(2, x, context); return [readRuleId(x[0], x), readEngineKind(x[1], x)] })(x, context);
}

export function writeProduct(x: Product, context: any = x): any {
  switch (x.kind) {
    case 'SAST':
      return 'sast'
    case 'SCA':
      return 'sca'
    case 'Secrets':
      return 'secrets'
  }
}

export function readProduct(x: any, context: any = x): Product {
  switch (x) {
    case 'sast':
      return { kind: 'SAST' }
    case 'sca':
      return { kind: 'SCA' }
    case 'secrets':
      return { kind: 'Secrets' }
    default:
      _atd_bad_json('Product', x, context)
      throw new Error('impossible')
  }
}

export function writeMatchBasedId(x: MatchBasedId, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readMatchBasedId(x: any, context: any = x): MatchBasedId {
  return _atd_read_string(x, context);
}

export function writeCliMatch(x: CliMatch, context: any = x): any {
  return {
    'check_id': _atd_write_required_field('CliMatch', 'check_id', writeRuleId, x.check_id, x),
    'path': _atd_write_required_field('CliMatch', 'path', writeFpath, x.path, x),
    'start': _atd_write_required_field('CliMatch', 'start', writePosition, x.start, x),
    'end': _atd_write_required_field('CliMatch', 'end', writePosition, x.end, x),
    'extra': _atd_write_required_field('CliMatch', 'extra', writeCliMatchExtra, x.extra, x),
  };
}

export function readCliMatch(x: any, context: any = x): CliMatch {
  return {
    check_id: _atd_read_required_field('CliMatch', 'check_id', readRuleId, x['check_id'], x),
    path: _atd_read_required_field('CliMatch', 'path', readFpath, x['path'], x),
    start: _atd_read_required_field('CliMatch', 'start', readPosition, x['start'], x),
    end: _atd_read_required_field('CliMatch', 'end', readPosition, x['end'], x),
    extra: _atd_read_required_field('CliMatch', 'extra', readCliMatchExtra, x['extra'], x),
  };
}

export function writeCliMatchExtra(x: CliMatchExtra, context: any = x): any {
  return {
    'metavars': _atd_write_optional_field(writeMetavars, x.metavars, x),
    'message': _atd_write_required_field('CliMatchExtra', 'message', _atd_write_string, x.message, x),
    'fix': _atd_write_optional_field(_atd_write_string, x.fix, x),
    'fixed_lines': _atd_write_optional_field(_atd_write_array(_atd_write_string), x.fixed_lines, x),
    'metadata': _atd_write_required_field('CliMatchExtra', 'metadata', writeRawJson, x.metadata, x),
    'severity': _atd_write_required_field('CliMatchExtra', 'severity', writeMatchSeverity, x.severity, x),
    'fingerprint': _atd_write_required_field('CliMatchExtra', 'fingerprint', _atd_write_string, x.fingerprint, x),
    'lines': _atd_write_required_field('CliMatchExtra', 'lines', _atd_write_string, x.lines, x),
    'is_ignored': _atd_write_optional_field(_atd_write_bool, x.is_ignored, x),
    'sca_info': _atd_write_optional_field(writeScaMatch, x.sca_info, x),
    'validation_state': _atd_write_optional_field(writeValidationState, x.validation_state, x),
    'historical_info': _atd_write_optional_field(writeHistoricalInfo, x.historical_info, x),
    'dataflow_trace': _atd_write_optional_field(writeMatchDataflowTrace, x.dataflow_trace, x),
    'engine_kind': _atd_write_optional_field(writeEngineOfFinding, x.engine_kind, x),
    'extra_extra': _atd_write_optional_field(writeRawJson, x.extra_extra, x),
  };
}

export function readCliMatchExtra(x: any, context: any = x): CliMatchExtra {
  return {
    metavars: _atd_read_optional_field(readMetavars, x['metavars'], x),
    message: _atd_read_required_field('CliMatchExtra', 'message', _atd_read_string, x['message'], x),
    fix: _atd_read_optional_field(_atd_read_string, x['fix'], x),
    fixed_lines: _atd_read_optional_field(_atd_read_array(_atd_read_string), x['fixed_lines'], x),
    metadata: _atd_read_required_field('CliMatchExtra', 'metadata', readRawJson, x['metadata'], x),
    severity: _atd_read_required_field('CliMatchExtra', 'severity', readMatchSeverity, x['severity'], x),
    fingerprint: _atd_read_required_field('CliMatchExtra', 'fingerprint', _atd_read_string, x['fingerprint'], x),
    lines: _atd_read_required_field('CliMatchExtra', 'lines', _atd_read_string, x['lines'], x),
    is_ignored: _atd_read_optional_field(_atd_read_bool, x['is_ignored'], x),
    sca_info: _atd_read_optional_field(readScaMatch, x['sca_info'], x),
    validation_state: _atd_read_optional_field(readValidationState, x['validation_state'], x),
    historical_info: _atd_read_optional_field(readHistoricalInfo, x['historical_info'], x),
    dataflow_trace: _atd_read_optional_field(readMatchDataflowTrace, x['dataflow_trace'], x),
    engine_kind: _atd_read_optional_field(readEngineOfFinding, x['engine_kind'], x),
    extra_extra: _atd_read_optional_field(readRawJson, x['extra_extra'], x),
  };
}

export function writeMetavars(x: Metavars, context: any = x): any {
  return _atd_write_assoc_map_to_object(writeMetavarValue)(x, context);
}

export function readMetavars(x: any, context: any = x): Metavars {
  return _atd_read_assoc_object_into_map(readMetavarValue)(x, context);
}

export function writeMetavarValue(x: MetavarValue, context: any = x): any {
  return {
    'start': _atd_write_required_field('MetavarValue', 'start', writePosition, x.start, x),
    'end': _atd_write_required_field('MetavarValue', 'end', writePosition, x.end, x),
    'abstract_content': _atd_write_required_field('MetavarValue', 'abstract_content', _atd_write_string, x.abstract_content, x),
    'propagated_value': _atd_write_optional_field(writeSvalueValue, x.propagated_value, x),
  };
}

export function readMetavarValue(x: any, context: any = x): MetavarValue {
  return {
    start: _atd_read_required_field('MetavarValue', 'start', readPosition, x['start'], x),
    end: _atd_read_required_field('MetavarValue', 'end', readPosition, x['end'], x),
    abstract_content: _atd_read_required_field('MetavarValue', 'abstract_content', _atd_read_string, x['abstract_content'], x),
    propagated_value: _atd_read_optional_field(readSvalueValue, x['propagated_value'], x),
  };
}

export function writeSvalueValue(x: SvalueValue, context: any = x): any {
  return {
    'svalue_start': _atd_write_optional_field(writePosition, x.svalue_start, x),
    'svalue_end': _atd_write_optional_field(writePosition, x.svalue_end, x),
    'svalue_abstract_content': _atd_write_required_field('SvalueValue', 'svalue_abstract_content', _atd_write_string, x.svalue_abstract_content, x),
  };
}

export function readSvalueValue(x: any, context: any = x): SvalueValue {
  return {
    svalue_start: _atd_read_optional_field(readPosition, x['svalue_start'], x),
    svalue_end: _atd_read_optional_field(readPosition, x['svalue_end'], x),
    svalue_abstract_content: _atd_read_required_field('SvalueValue', 'svalue_abstract_content', _atd_read_string, x['svalue_abstract_content'], x),
  };
}

export function writeMatchingExplanation(x: MatchingExplanation, context: any = x): any {
  return {
    'op': _atd_write_required_field('MatchingExplanation', 'op', writeMatchingOperation, x.op, x),
    'children': _atd_write_required_field('MatchingExplanation', 'children', _atd_write_array(writeMatchingExplanation), x.children, x),
    'matches': _atd_write_required_field('MatchingExplanation', 'matches', _atd_write_array(writeCoreMatch), x.matches, x),
    'loc': _atd_write_required_field('MatchingExplanation', 'loc', writeLocation, x.loc, x),
    'extra': _atd_write_optional_field(writeMatchingExplanationExtra, x.extra, x),
  };
}

export function readMatchingExplanation(x: any, context: any = x): MatchingExplanation {
  return {
    op: _atd_read_required_field('MatchingExplanation', 'op', readMatchingOperation, x['op'], x),
    children: _atd_read_required_field('MatchingExplanation', 'children', _atd_read_array(readMatchingExplanation), x['children'], x),
    matches: _atd_read_required_field('MatchingExplanation', 'matches', _atd_read_array(readCoreMatch), x['matches'], x),
    loc: _atd_read_required_field('MatchingExplanation', 'loc', readLocation, x['loc'], x),
    extra: _atd_read_optional_field(readMatchingExplanationExtra, x['extra'], x),
  };
}

export function writeMatchingExplanationExtra(x: MatchingExplanationExtra, context: any = x): any {
  return {
    'before_negation_matches': _atd_write_required_field('MatchingExplanationExtra', 'before_negation_matches', _atd_write_option(_atd_write_array(writeCoreMatch)), x.before_negation_matches, x),
    'before_filter_matches': _atd_write_required_field('MatchingExplanationExtra', 'before_filter_matches', _atd_write_option(_atd_write_array(writeCoreMatch)), x.before_filter_matches, x),
  };
}

export function readMatchingExplanationExtra(x: any, context: any = x): MatchingExplanationExtra {
  return {
    before_negation_matches: _atd_read_required_field('MatchingExplanationExtra', 'before_negation_matches', _atd_read_option(_atd_read_array(readCoreMatch)), x['before_negation_matches'], x),
    before_filter_matches: _atd_read_required_field('MatchingExplanationExtra', 'before_filter_matches', _atd_read_option(_atd_read_array(readCoreMatch)), x['before_filter_matches'], x),
  };
}

export function writeMatchingOperation(x: MatchingOperation, context: any = x): any {
  switch (x.kind) {
    case 'And':
      return 'And'
    case 'Or':
      return 'Or'
    case 'Inside':
      return 'Inside'
    case 'Anywhere':
      return 'Anywhere'
    case 'XPat':
      return ['XPat', _atd_write_string(x.value, x)]
    case 'Negation':
      return 'Negation'
    case 'Filter':
      return ['Filter', _atd_write_string(x.value, x)]
    case 'Taint':
      return 'Taint'
    case 'TaintSource':
      return 'TaintSource'
    case 'TaintSink':
      return 'TaintSink'
    case 'TaintSanitizer':
      return 'TaintSanitizer'
    case 'EllipsisAndStmts':
      return 'EllipsisAndStmts'
    case 'ClassHeaderAndElems':
      return 'ClassHeaderAndElems'
  }
}

export function readMatchingOperation(x: any, context: any = x): MatchingOperation {
  if (typeof x === 'string') {
    switch (x) {
      case 'And':
        return { kind: 'And' }
      case 'Or':
        return { kind: 'Or' }
      case 'Inside':
        return { kind: 'Inside' }
      case 'Anywhere':
        return { kind: 'Anywhere' }
      case 'Negation':
        return { kind: 'Negation' }
      case 'Taint':
        return { kind: 'Taint' }
      case 'TaintSource':
        return { kind: 'TaintSource' }
      case 'TaintSink':
        return { kind: 'TaintSink' }
      case 'TaintSanitizer':
        return { kind: 'TaintSanitizer' }
      case 'EllipsisAndStmts':
        return { kind: 'EllipsisAndStmts' }
      case 'ClassHeaderAndElems':
        return { kind: 'ClassHeaderAndElems' }
      default:
        _atd_bad_json('MatchingOperation', x, context)
        throw new Error('impossible')
    }
  }
  else {
    _atd_check_json_tuple(2, x, context)
    switch (x[0]) {
      case 'XPat':
        return { kind: 'XPat', value: _atd_read_string(x[1], x) }
      case 'Filter':
        return { kind: 'Filter', value: _atd_read_string(x[1], x) }
      default:
        _atd_bad_json('MatchingOperation', x, context)
        throw new Error('impossible')
    }
  }
}

export function writeMatchDataflowTrace(x: MatchDataflowTrace, context: any = x): any {
  return {
    'taint_source': _atd_write_optional_field(writeMatchCallTrace, x.taint_source, x),
    'intermediate_vars': _atd_write_optional_field(_atd_write_array(writeMatchIntermediateVar), x.intermediate_vars, x),
    'taint_sink': _atd_write_optional_field(writeMatchCallTrace, x.taint_sink, x),
  };
}

export function readMatchDataflowTrace(x: any, context: any = x): MatchDataflowTrace {
  return {
    taint_source: _atd_read_optional_field(readMatchCallTrace, x['taint_source'], x),
    intermediate_vars: _atd_read_optional_field(_atd_read_array(readMatchIntermediateVar), x['intermediate_vars'], x),
    taint_sink: _atd_read_optional_field(readMatchCallTrace, x['taint_sink'], x),
  };
}

export function writeLocAndContent(x: LocAndContent, context: any = x): any {
  return ((x, context) => [writeLocation(x[0], x), _atd_write_string(x[1], x)])(x, context);
}

export function readLocAndContent(x: any, context: any = x): LocAndContent {
  return ((x, context): [Location, string] => { _atd_check_json_tuple(2, x, context); return [readLocation(x[0], x), _atd_read_string(x[1], x)] })(x, context);
}

export function writeMatchCallTrace(x: MatchCallTrace, context: any = x): any {
  switch (x.kind) {
    case 'CliLoc':
      return ['CliLoc', writeLocAndContent(x.value, x)]
    case 'CliCall':
      return ['CliCall', ((x, context) => [writeLocAndContent(x[0], x), _atd_write_array(writeMatchIntermediateVar)(x[1], x), writeMatchCallTrace(x[2], x)])(x.value, x)]
  }
}

export function readMatchCallTrace(x: any, context: any = x): MatchCallTrace {
  _atd_check_json_tuple(2, x, context)
  switch (x[0]) {
    case 'CliLoc':
      return { kind: 'CliLoc', value: readLocAndContent(x[1], x) }
    case 'CliCall':
      return { kind: 'CliCall', value: ((x, context): [LocAndContent, MatchIntermediateVar[], MatchCallTrace] => { _atd_check_json_tuple(3, x, context); return [readLocAndContent(x[0], x), _atd_read_array(readMatchIntermediateVar)(x[1], x), readMatchCallTrace(x[2], x)] })(x[1], x) }
    default:
      _atd_bad_json('MatchCallTrace', x, context)
      throw new Error('impossible')
  }
}

export function writeMatchIntermediateVar(x: MatchIntermediateVar, context: any = x): any {
  return {
    'location': _atd_write_required_field('MatchIntermediateVar', 'location', writeLocation, x.location, x),
    'content': _atd_write_required_field('MatchIntermediateVar', 'content', _atd_write_string, x.content, x),
  };
}

export function readMatchIntermediateVar(x: any, context: any = x): MatchIntermediateVar {
  return {
    location: _atd_read_required_field('MatchIntermediateVar', 'location', readLocation, x['location'], x),
    content: _atd_read_required_field('MatchIntermediateVar', 'content', _atd_read_string, x['content'], x),
  };
}

export function writeEcosystem(x: Ecosystem, context: any = x): any {
  switch (x.kind) {
    case 'Npm':
      return 'npm'
    case 'Pypi':
      return 'pypi'
    case 'Gem':
      return 'gem'
    case 'Gomod':
      return 'gomod'
    case 'Cargo':
      return 'cargo'
    case 'Maven':
      return 'maven'
    case 'Composer':
      return 'composer'
    case 'Nuget':
      return 'nuget'
    case 'Pub':
      return 'pub'
    case 'SwiftPM':
      return 'swiftpm'
    case 'Cocoapods':
      return 'cocoapods'
    case 'Mix':
      return 'mix'
    case 'Hex':
      return 'hex'
  }
}

export function readEcosystem(x: any, context: any = x): Ecosystem {
  switch (x) {
    case 'npm':
      return { kind: 'Npm' }
    case 'pypi':
      return { kind: 'Pypi' }
    case 'gem':
      return { kind: 'Gem' }
    case 'gomod':
      return { kind: 'Gomod' }
    case 'cargo':
      return { kind: 'Cargo' }
    case 'maven':
      return { kind: 'Maven' }
    case 'composer':
      return { kind: 'Composer' }
    case 'nuget':
      return { kind: 'Nuget' }
    case 'pub':
      return { kind: 'Pub' }
    case 'swiftpm':
      return { kind: 'SwiftPM' }
    case 'cocoapods':
      return { kind: 'Cocoapods' }
    case 'mix':
      return { kind: 'Mix' }
    case 'hex':
      return { kind: 'Hex' }
    default:
      _atd_bad_json('Ecosystem', x, context)
      throw new Error('impossible')
  }
}

export function writeTransitivity(x: Transitivity, context: any = x): any {
  switch (x.kind) {
    case 'Direct':
      return 'direct'
    case 'Transitive':
      return 'transitive'
    case 'Unknown':
      return 'unknown'
  }
}

export function readTransitivity(x: any, context: any = x): Transitivity {
  switch (x) {
    case 'direct':
      return { kind: 'Direct' }
    case 'transitive':
      return { kind: 'Transitive' }
    case 'unknown':
      return { kind: 'Unknown' }
    default:
      _atd_bad_json('Transitivity', x, context)
      throw new Error('impossible')
  }
}

export function writeScaMatch(x: ScaMatch, context: any = x): any {
  return {
    'reachable': _atd_write_required_field('ScaMatch', 'reachable', _atd_write_bool, x.reachable, x),
    'reachability_rule': _atd_write_required_field('ScaMatch', 'reachability_rule', _atd_write_bool, x.reachability_rule, x),
    'sca_finding_schema': _atd_write_required_field('ScaMatch', 'sca_finding_schema', _atd_write_int, x.sca_finding_schema, x),
    'dependency_match': _atd_write_required_field('ScaMatch', 'dependency_match', writeDependencyMatch, x.dependency_match, x),
  };
}

export function readScaMatch(x: any, context: any = x): ScaMatch {
  return {
    reachable: _atd_read_required_field('ScaMatch', 'reachable', _atd_read_bool, x['reachable'], x),
    reachability_rule: _atd_read_required_field('ScaMatch', 'reachability_rule', _atd_read_bool, x['reachability_rule'], x),
    sca_finding_schema: _atd_read_required_field('ScaMatch', 'sca_finding_schema', _atd_read_int, x['sca_finding_schema'], x),
    dependency_match: _atd_read_required_field('ScaMatch', 'dependency_match', readDependencyMatch, x['dependency_match'], x),
  };
}

export function writeDependencyMatch(x: DependencyMatch, context: any = x): any {
  return {
    'dependency_pattern': _atd_write_required_field('DependencyMatch', 'dependency_pattern', writeScaPattern, x.dependency_pattern, x),
    'found_dependency': _atd_write_required_field('DependencyMatch', 'found_dependency', writeFoundDependency, x.found_dependency, x),
    'lockfile': _atd_write_required_field('DependencyMatch', 'lockfile', writeFpath, x.lockfile, x),
  };
}

export function readDependencyMatch(x: any, context: any = x): DependencyMatch {
  return {
    dependency_pattern: _atd_read_required_field('DependencyMatch', 'dependency_pattern', readScaPattern, x['dependency_pattern'], x),
    found_dependency: _atd_read_required_field('DependencyMatch', 'found_dependency', readFoundDependency, x['found_dependency'], x),
    lockfile: _atd_read_required_field('DependencyMatch', 'lockfile', readFpath, x['lockfile'], x),
  };
}

export function writeScaPattern(x: ScaPattern, context: any = x): any {
  return {
    'ecosystem': _atd_write_required_field('ScaPattern', 'ecosystem', writeEcosystem, x.ecosystem, x),
    'package': _atd_write_required_field('ScaPattern', 'package', _atd_write_string, x.package_, x),
    'semver_range': _atd_write_required_field('ScaPattern', 'semver_range', _atd_write_string, x.semver_range, x),
  };
}

export function readScaPattern(x: any, context: any = x): ScaPattern {
  return {
    ecosystem: _atd_read_required_field('ScaPattern', 'ecosystem', readEcosystem, x['ecosystem'], x),
    package_: _atd_read_required_field('ScaPattern', 'package', _atd_read_string, x['package'], x),
    semver_range: _atd_read_required_field('ScaPattern', 'semver_range', _atd_read_string, x['semver_range'], x),
  };
}

export function writeFoundDependency(x: FoundDependency, context: any = x): any {
  return {
    'package': _atd_write_required_field('FoundDependency', 'package', _atd_write_string, x.package_, x),
    'version': _atd_write_required_field('FoundDependency', 'version', _atd_write_string, x.version, x),
    'ecosystem': _atd_write_required_field('FoundDependency', 'ecosystem', writeEcosystem, x.ecosystem, x),
    'allowed_hashes': _atd_write_required_field('FoundDependency', 'allowed_hashes', _atd_write_assoc_map_to_object(_atd_write_array(_atd_write_string)), x.allowed_hashes, x),
    'resolved_url': _atd_write_optional_field(_atd_write_string, x.resolved_url, x),
    'transitivity': _atd_write_required_field('FoundDependency', 'transitivity', writeTransitivity, x.transitivity, x),
    'manifest_path': _atd_write_optional_field(writeFpath, x.manifest_path, x),
    'lockfile_path': _atd_write_optional_field(writeFpath, x.lockfile_path, x),
    'line_number': _atd_write_optional_field(_atd_write_int, x.line_number, x),
    'children': _atd_write_optional_field(_atd_write_array(writeDependencyChild), x.children, x),
    'git_ref': _atd_write_optional_field(_atd_write_string, x.git_ref, x),
  };
}

export function readFoundDependency(x: any, context: any = x): FoundDependency {
  return {
    package_: _atd_read_required_field('FoundDependency', 'package', _atd_read_string, x['package'], x),
    version: _atd_read_required_field('FoundDependency', 'version', _atd_read_string, x['version'], x),
    ecosystem: _atd_read_required_field('FoundDependency', 'ecosystem', readEcosystem, x['ecosystem'], x),
    allowed_hashes: _atd_read_required_field('FoundDependency', 'allowed_hashes', _atd_read_assoc_object_into_map(_atd_read_array(_atd_read_string)), x['allowed_hashes'], x),
    resolved_url: _atd_read_optional_field(_atd_read_string, x['resolved_url'], x),
    transitivity: _atd_read_required_field('FoundDependency', 'transitivity', readTransitivity, x['transitivity'], x),
    manifest_path: _atd_read_optional_field(readFpath, x['manifest_path'], x),
    lockfile_path: _atd_read_optional_field(readFpath, x['lockfile_path'], x),
    line_number: _atd_read_optional_field(_atd_read_int, x['line_number'], x),
    children: _atd_read_optional_field(_atd_read_array(readDependencyChild), x['children'], x),
    git_ref: _atd_read_optional_field(_atd_read_string, x['git_ref'], x),
  };
}

export function writeDependencyChild(x: DependencyChild, context: any = x): any {
  return {
    'package': _atd_write_required_field('DependencyChild', 'package', _atd_write_string, x.package_, x),
    'version': _atd_write_required_field('DependencyChild', 'version', _atd_write_string, x.version, x),
  };
}

export function readDependencyChild(x: any, context: any = x): DependencyChild {
  return {
    package_: _atd_read_required_field('DependencyChild', 'package', _atd_read_string, x['package'], x),
    version: _atd_read_required_field('DependencyChild', 'version', _atd_read_string, x['version'], x),
  };
}

export function writeValidationState(x: ValidationState, context: any = x): any {
  switch (x.kind) {
    case 'Confirmed_valid':
      return 'CONFIRMED_VALID'
    case 'Confirmed_invalid':
      return 'CONFIRMED_INVALID'
    case 'Validation_error':
      return 'VALIDATION_ERROR'
    case 'No_validator':
      return 'NO_VALIDATOR'
  }
}

export function readValidationState(x: any, context: any = x): ValidationState {
  switch (x) {
    case 'CONFIRMED_VALID':
      return { kind: 'Confirmed_valid' }
    case 'CONFIRMED_INVALID':
      return { kind: 'Confirmed_invalid' }
    case 'VALIDATION_ERROR':
      return { kind: 'Validation_error' }
    case 'NO_VALIDATOR':
      return { kind: 'No_validator' }
    default:
      _atd_bad_json('ValidationState', x, context)
      throw new Error('impossible')
  }
}

export function writeHistoricalInfo(x: HistoricalInfo, context: any = x): any {
  return {
    'git_commit': _atd_write_required_field('HistoricalInfo', 'git_commit', writeSha1, x.git_commit, x),
    'git_blob': _atd_write_optional_field(writeSha1, x.git_blob, x),
    'git_commit_timestamp': _atd_write_required_field('HistoricalInfo', 'git_commit_timestamp', writeDatetime, x.git_commit_timestamp, x),
  };
}

export function readHistoricalInfo(x: any, context: any = x): HistoricalInfo {
  return {
    git_commit: _atd_read_required_field('HistoricalInfo', 'git_commit', readSha1, x['git_commit'], x),
    git_blob: _atd_read_optional_field(readSha1, x['git_blob'], x),
    git_commit_timestamp: _atd_read_required_field('HistoricalInfo', 'git_commit_timestamp', readDatetime, x['git_commit_timestamp'], x),
  };
}

export function writeErrorType(x: ErrorType, context: any = x): any {
  switch (x.kind) {
    case 'LexicalError':
      return 'Lexical error'
    case 'ParseError':
      return 'Syntax error'
    case 'OtherParseError':
      return 'Other syntax error'
    case 'AstBuilderError':
      return 'AST builder error'
    case 'RuleParseError':
      return 'Rule parse error'
    case 'SemgrepWarning':
      return 'SemgrepWarning'
    case 'SemgrepError':
      return 'SemgrepError'
    case 'InvalidRuleSchemaError':
      return 'InvalidRuleSchemaError'
    case 'UnknownLanguageError':
      return 'UnknownLanguageError'
    case 'InvalidYaml':
      return 'Invalid YAML'
    case 'MatchingError':
      return 'Internal matching error'
    case 'SemgrepMatchFound':
      return 'Semgrep match found'
    case 'TooManyMatches':
      return 'Too many matches'
    case 'FatalError':
      return 'Fatal error'
    case 'Timeout':
      return 'Timeout'
    case 'OutOfMemory':
      return 'Out of memory'
    case 'StackOverflow':
      return 'Stack overflow'
    case 'TimeoutDuringInterfile':
      return 'Timeout during interfile analysis'
    case 'OutOfMemoryDuringInterfile':
      return 'OOM during interfile analysis'
    case 'MissingPlugin':
      return 'Missing plugin'
    case 'PatternParseError':
      return ['PatternParseError', _atd_write_array(_atd_write_string)(x.value, x)]
    case 'PartialParsing':
      return ['PartialParsing', _atd_write_array(writeLocation)(x.value, x)]
    case 'IncompatibleRule':
      return ['IncompatibleRule', writeIncompatibleRule(x.value, x)]
    case 'PatternParseError0':
      return 'Pattern parse error'
    case 'IncompatibleRule0':
      return 'Incompatible rule'
    case 'DependencyResolutionError':
      return ['DependencyResolutionError', writeResolutionError(x.value, x)]
  }
}

export function readErrorType(x: any, context: any = x): ErrorType {
  if (typeof x === 'string') {
    switch (x) {
      case 'Lexical error':
        return { kind: 'LexicalError' }
      case 'Syntax error':
        return { kind: 'ParseError' }
      case 'Other syntax error':
        return { kind: 'OtherParseError' }
      case 'AST builder error':
        return { kind: 'AstBuilderError' }
      case 'Rule parse error':
        return { kind: 'RuleParseError' }
      case 'SemgrepWarning':
        return { kind: 'SemgrepWarning' }
      case 'SemgrepError':
        return { kind: 'SemgrepError' }
      case 'InvalidRuleSchemaError':
        return { kind: 'InvalidRuleSchemaError' }
      case 'UnknownLanguageError':
        return { kind: 'UnknownLanguageError' }
      case 'Invalid YAML':
        return { kind: 'InvalidYaml' }
      case 'Internal matching error':
        return { kind: 'MatchingError' }
      case 'Semgrep match found':
        return { kind: 'SemgrepMatchFound' }
      case 'Too many matches':
        return { kind: 'TooManyMatches' }
      case 'Fatal error':
        return { kind: 'FatalError' }
      case 'Timeout':
        return { kind: 'Timeout' }
      case 'Out of memory':
        return { kind: 'OutOfMemory' }
      case 'Stack overflow':
        return { kind: 'StackOverflow' }
      case 'Timeout during interfile analysis':
        return { kind: 'TimeoutDuringInterfile' }
      case 'OOM during interfile analysis':
        return { kind: 'OutOfMemoryDuringInterfile' }
      case 'Missing plugin':
        return { kind: 'MissingPlugin' }
      case 'Pattern parse error':
        return { kind: 'PatternParseError0' }
      case 'Incompatible rule':
        return { kind: 'IncompatibleRule0' }
      default:
        _atd_bad_json('ErrorType', x, context)
        throw new Error('impossible')
    }
  }
  else {
    _atd_check_json_tuple(2, x, context)
    switch (x[0]) {
      case 'PatternParseError':
        return { kind: 'PatternParseError', value: _atd_read_array(_atd_read_string)(x[1], x) }
      case 'PartialParsing':
        return { kind: 'PartialParsing', value: _atd_read_array(readLocation)(x[1], x) }
      case 'IncompatibleRule':
        return { kind: 'IncompatibleRule', value: readIncompatibleRule(x[1], x) }
      case 'DependencyResolutionError':
        return { kind: 'DependencyResolutionError', value: readResolutionError(x[1], x) }
      default:
        _atd_bad_json('ErrorType', x, context)
        throw new Error('impossible')
    }
  }
}

export function writeIncompatibleRule(x: IncompatibleRule, context: any = x): any {
  return {
    'rule_id': _atd_write_required_field('IncompatibleRule', 'rule_id', writeRuleId, x.rule_id, x),
    'this_version': _atd_write_required_field('IncompatibleRule', 'this_version', writeVersion, x.this_version, x),
    'min_version': _atd_write_optional_field(writeVersion, x.min_version, x),
    'max_version': _atd_write_optional_field(writeVersion, x.max_version, x),
  };
}

export function readIncompatibleRule(x: any, context: any = x): IncompatibleRule {
  return {
    rule_id: _atd_read_required_field('IncompatibleRule', 'rule_id', readRuleId, x['rule_id'], x),
    this_version: _atd_read_required_field('IncompatibleRule', 'this_version', readVersion, x['this_version'], x),
    min_version: _atd_read_optional_field(readVersion, x['min_version'], x),
    max_version: _atd_read_optional_field(readVersion, x['max_version'], x),
  };
}

export function writeCliError(x: CliError, context: any = x): any {
  return {
    'code': _atd_write_required_field('CliError', 'code', _atd_write_int, x.code, x),
    'level': _atd_write_required_field('CliError', 'level', writeErrorSeverity, x.level, x),
    'type': _atd_write_required_field('CliError', 'type_', writeErrorType, x.type_, x),
    'rule_id': _atd_write_optional_field(writeRuleId, x.rule_id, x),
    'message': _atd_write_optional_field(_atd_write_string, x.message, x),
    'path': _atd_write_optional_field(writeFpath, x.path, x),
    'long_msg': _atd_write_optional_field(_atd_write_string, x.long_msg, x),
    'short_msg': _atd_write_optional_field(_atd_write_string, x.short_msg, x),
    'spans': _atd_write_optional_field(_atd_write_array(writeErrorSpan), x.spans, x),
    'help': _atd_write_optional_field(_atd_write_string, x.help, x),
  };
}

export function readCliError(x: any, context: any = x): CliError {
  return {
    code: _atd_read_required_field('CliError', 'code', _atd_read_int, x['code'], x),
    level: _atd_read_required_field('CliError', 'level', readErrorSeverity, x['level'], x),
    type_: _atd_read_required_field('CliError', 'type', readErrorType, x['type'], x),
    rule_id: _atd_read_optional_field(readRuleId, x['rule_id'], x),
    message: _atd_read_optional_field(_atd_read_string, x['message'], x),
    path: _atd_read_optional_field(readFpath, x['path'], x),
    long_msg: _atd_read_optional_field(_atd_read_string, x['long_msg'], x),
    short_msg: _atd_read_optional_field(_atd_read_string, x['short_msg'], x),
    spans: _atd_read_optional_field(_atd_read_array(readErrorSpan), x['spans'], x),
    help: _atd_read_optional_field(_atd_read_string, x['help'], x),
  };
}

export function writeErrorSpan(x: ErrorSpan, context: any = x): any {
  return {
    'file': _atd_write_required_field('ErrorSpan', 'file', writeFpath, x.file, x),
    'start': _atd_write_required_field('ErrorSpan', 'start', writePosition, x.start, x),
    'end': _atd_write_required_field('ErrorSpan', 'end', writePosition, x.end, x),
    'source_hash': _atd_write_optional_field(_atd_write_string, x.source_hash, x),
    'config_start': _atd_write_optional_field(_atd_write_nullable(writePosition), x.config_start, x),
    'config_end': _atd_write_optional_field(_atd_write_nullable(writePosition), x.config_end, x),
    'config_path': _atd_write_optional_field(_atd_write_nullable(_atd_write_array(_atd_write_string)), x.config_path, x),
    'context_start': _atd_write_optional_field(_atd_write_nullable(writePosition), x.context_start, x),
    'context_end': _atd_write_optional_field(_atd_write_nullable(writePosition), x.context_end, x),
  };
}

export function readErrorSpan(x: any, context: any = x): ErrorSpan {
  return {
    file: _atd_read_required_field('ErrorSpan', 'file', readFpath, x['file'], x),
    start: _atd_read_required_field('ErrorSpan', 'start', readPosition, x['start'], x),
    end: _atd_read_required_field('ErrorSpan', 'end', readPosition, x['end'], x),
    source_hash: _atd_read_optional_field(_atd_read_string, x['source_hash'], x),
    config_start: _atd_read_optional_field(_atd_read_nullable(readPosition), x['config_start'], x),
    config_end: _atd_read_optional_field(_atd_read_nullable(readPosition), x['config_end'], x),
    config_path: _atd_read_optional_field(_atd_read_nullable(_atd_read_array(_atd_read_string)), x['config_path'], x),
    context_start: _atd_read_optional_field(_atd_read_nullable(readPosition), x['context_start'], x),
    context_end: _atd_read_optional_field(_atd_read_nullable(readPosition), x['context_end'], x),
  };
}

export function writeSkipReason(x: SkipReason, context: any = x): any {
  switch (x.kind) {
    case 'Always_skipped':
      return 'always_skipped'
    case 'Semgrepignore_patterns_match':
      return 'semgrepignore_patterns_match'
    case 'Cli_include_flags_do_not_match':
      return 'cli_include_flags_do_not_match'
    case 'Cli_exclude_flags_match':
      return 'cli_exclude_flags_match'
    case 'Exceeded_size_limit':
      return 'exceeded_size_limit'
    case 'Analysis_failed_parser_or_internal_error':
      return 'analysis_failed_parser_or_internal_error'
    case 'Excluded_by_config':
      return 'excluded_by_config'
    case 'Wrong_language':
      return 'wrong_language'
    case 'Too_big':
      return 'too_big'
    case 'Minified':
      return 'minified'
    case 'Binary':
      return 'binary'
    case 'Irrelevant_rule':
      return 'irrelevant_rule'
    case 'Too_many_matches':
      return 'too_many_matches'
    case 'Gitignore_patterns_match':
      return 'Gitignore_patterns_match'
    case 'Dotfile':
      return 'Dotfile'
    case 'Nonexistent_file':
      return 'Nonexistent_file'
    case 'Insufficient_permissions':
      return 'insufficient_permissions'
  }
}

export function readSkipReason(x: any, context: any = x): SkipReason {
  switch (x) {
    case 'always_skipped':
      return { kind: 'Always_skipped' }
    case 'semgrepignore_patterns_match':
      return { kind: 'Semgrepignore_patterns_match' }
    case 'cli_include_flags_do_not_match':
      return { kind: 'Cli_include_flags_do_not_match' }
    case 'cli_exclude_flags_match':
      return { kind: 'Cli_exclude_flags_match' }
    case 'exceeded_size_limit':
      return { kind: 'Exceeded_size_limit' }
    case 'analysis_failed_parser_or_internal_error':
      return { kind: 'Analysis_failed_parser_or_internal_error' }
    case 'excluded_by_config':
      return { kind: 'Excluded_by_config' }
    case 'wrong_language':
      return { kind: 'Wrong_language' }
    case 'too_big':
      return { kind: 'Too_big' }
    case 'minified':
      return { kind: 'Minified' }
    case 'binary':
      return { kind: 'Binary' }
    case 'irrelevant_rule':
      return { kind: 'Irrelevant_rule' }
    case 'too_many_matches':
      return { kind: 'Too_many_matches' }
    case 'Gitignore_patterns_match':
      return { kind: 'Gitignore_patterns_match' }
    case 'Dotfile':
      return { kind: 'Dotfile' }
    case 'Nonexistent_file':
      return { kind: 'Nonexistent_file' }
    case 'insufficient_permissions':
      return { kind: 'Insufficient_permissions' }
    default:
      _atd_bad_json('SkipReason', x, context)
      throw new Error('impossible')
  }
}

export function writeSkippedTarget(x: SkippedTarget, context: any = x): any {
  return {
    'path': _atd_write_required_field('SkippedTarget', 'path', writeFpath, x.path, x),
    'reason': _atd_write_required_field('SkippedTarget', 'reason', writeSkipReason, x.reason, x),
    'details': _atd_write_optional_field(_atd_write_string, x.details, x),
    'rule_id': _atd_write_optional_field(writeRuleId, x.rule_id, x),
  };
}

export function readSkippedTarget(x: any, context: any = x): SkippedTarget {
  return {
    path: _atd_read_required_field('SkippedTarget', 'path', readFpath, x['path'], x),
    reason: _atd_read_required_field('SkippedTarget', 'reason', readSkipReason, x['reason'], x),
    details: _atd_read_optional_field(_atd_read_string, x['details'], x),
    rule_id: _atd_read_optional_field(readRuleId, x['rule_id'], x),
  };
}

export function writeScannedAndSkipped(x: ScannedAndSkipped, context: any = x): any {
  return {
    'scanned': _atd_write_required_field('ScannedAndSkipped', 'scanned', _atd_write_array(writeFpath), x.scanned, x),
    'skipped': _atd_write_optional_field(_atd_write_array(writeSkippedTarget), x.skipped, x),
  };
}

export function readScannedAndSkipped(x: any, context: any = x): ScannedAndSkipped {
  return {
    scanned: _atd_read_required_field('ScannedAndSkipped', 'scanned', _atd_read_array(readFpath), x['scanned'], x),
    skipped: _atd_read_optional_field(_atd_read_array(readSkippedTarget), x['skipped'], x),
  };
}

export function writeSkippedRule(x: SkippedRule, context: any = x): any {
  return {
    'rule_id': _atd_write_required_field('SkippedRule', 'rule_id', writeRuleId, x.rule_id, x),
    'details': _atd_write_required_field('SkippedRule', 'details', _atd_write_string, x.details, x),
    'position': _atd_write_required_field('SkippedRule', 'position', writePosition, x.position, x),
  };
}

export function readSkippedRule(x: any, context: any = x): SkippedRule {
  return {
    rule_id: _atd_read_required_field('SkippedRule', 'rule_id', readRuleId, x['rule_id'], x),
    details: _atd_read_required_field('SkippedRule', 'details', _atd_read_string, x['details'], x),
    position: _atd_read_required_field('SkippedRule', 'position', readPosition, x['position'], x),
  };
}

export function writeProfile(x: Profile, context: any = x): any {
  return {
    'rules': _atd_write_required_field('Profile', 'rules', _atd_write_array(writeRuleId), x.rules, x),
    'rules_parse_time': _atd_write_required_field('Profile', 'rules_parse_time', _atd_write_float, x.rules_parse_time, x),
    'profiling_times': _atd_write_required_field('Profile', 'profiling_times', _atd_write_assoc_map_to_object(_atd_write_float), x.profiling_times, x),
    'targets': _atd_write_required_field('Profile', 'targets', _atd_write_array(writeTargetTimes), x.targets, x),
    'total_bytes': _atd_write_required_field('Profile', 'total_bytes', _atd_write_int, x.total_bytes, x),
    'max_memory_bytes': _atd_write_optional_field(_atd_write_int, x.max_memory_bytes, x),
  };
}

export function readProfile(x: any, context: any = x): Profile {
  return {
    rules: _atd_read_required_field('Profile', 'rules', _atd_read_array(readRuleId), x['rules'], x),
    rules_parse_time: _atd_read_required_field('Profile', 'rules_parse_time', _atd_read_float, x['rules_parse_time'], x),
    profiling_times: _atd_read_required_field('Profile', 'profiling_times', _atd_read_assoc_object_into_map(_atd_read_float), x['profiling_times'], x),
    targets: _atd_read_required_field('Profile', 'targets', _atd_read_array(readTargetTimes), x['targets'], x),
    total_bytes: _atd_read_required_field('Profile', 'total_bytes', _atd_read_int, x['total_bytes'], x),
    max_memory_bytes: _atd_read_optional_field(_atd_read_int, x['max_memory_bytes'], x),
  };
}

export function writeTargetTimes(x: TargetTimes, context: any = x): any {
  return {
    'path': _atd_write_required_field('TargetTimes', 'path', writeFpath, x.path, x),
    'num_bytes': _atd_write_required_field('TargetTimes', 'num_bytes', _atd_write_int, x.num_bytes, x),
    'match_times': _atd_write_required_field('TargetTimes', 'match_times', _atd_write_array(_atd_write_float), x.match_times, x),
    'parse_times': _atd_write_required_field('TargetTimes', 'parse_times', _atd_write_array(_atd_write_float), x.parse_times, x),
    'run_time': _atd_write_required_field('TargetTimes', 'run_time', _atd_write_float, x.run_time, x),
  };
}

export function readTargetTimes(x: any, context: any = x): TargetTimes {
  return {
    path: _atd_read_required_field('TargetTimes', 'path', readFpath, x['path'], x),
    num_bytes: _atd_read_required_field('TargetTimes', 'num_bytes', _atd_read_int, x['num_bytes'], x),
    match_times: _atd_read_required_field('TargetTimes', 'match_times', _atd_read_array(_atd_read_float), x['match_times'], x),
    parse_times: _atd_read_required_field('TargetTimes', 'parse_times', _atd_read_array(_atd_read_float), x['parse_times'], x),
    run_time: _atd_read_required_field('TargetTimes', 'run_time', _atd_read_float, x['run_time'], x),
  };
}

export function writeCliOutput(x: CliOutput, context: any = x): any {
  return {
    'version': _atd_write_optional_field(writeVersion, x.version, x),
    'results': _atd_write_required_field('CliOutput', 'results', _atd_write_array(writeCliMatch), x.results, x),
    'errors': _atd_write_required_field('CliOutput', 'errors', _atd_write_array(writeCliError), x.errors, x),
    'paths': _atd_write_required_field('CliOutput', 'paths', writeScannedAndSkipped, x.paths, x),
    'time': _atd_write_optional_field(writeProfile, x.time, x),
    'explanations': _atd_write_optional_field(_atd_write_array(writeMatchingExplanation), x.explanations, x),
    'rules_by_engine': _atd_write_optional_field(_atd_write_array(writeRuleIdAndEngineKind), x.rules_by_engine, x),
    'engine_requested': _atd_write_optional_field(writeEngineKind, x.engine_requested, x),
    'interfile_languages_used': _atd_write_optional_field(_atd_write_array(_atd_write_string), x.interfile_languages_used, x),
    'skipped_rules': _atd_write_field_with_default(_atd_write_array(writeSkippedRule), [], x.skipped_rules, x),
  };
}

export function readCliOutput(x: any, context: any = x): CliOutput {
  return {
    version: _atd_read_optional_field(readVersion, x['version'], x),
    results: _atd_read_required_field('CliOutput', 'results', _atd_read_array(readCliMatch), x['results'], x),
    errors: _atd_read_required_field('CliOutput', 'errors', _atd_read_array(readCliError), x['errors'], x),
    paths: _atd_read_required_field('CliOutput', 'paths', readScannedAndSkipped, x['paths'], x),
    time: _atd_read_optional_field(readProfile, x['time'], x),
    explanations: _atd_read_optional_field(_atd_read_array(readMatchingExplanation), x['explanations'], x),
    rules_by_engine: _atd_read_optional_field(_atd_read_array(readRuleIdAndEngineKind), x['rules_by_engine'], x),
    engine_requested: _atd_read_optional_field(readEngineKind, x['engine_requested'], x),
    interfile_languages_used: _atd_read_optional_field(_atd_read_array(_atd_read_string), x['interfile_languages_used'], x),
    skipped_rules: _atd_read_field_with_default(_atd_read_array(readSkippedRule), [], x['skipped_rules'], x),
  };
}

export function writeCliOutputExtra(x: CliOutputExtra, context: any = x): any {
  return {
    'paths': _atd_write_required_field('CliOutputExtra', 'paths', writeScannedAndSkipped, x.paths, x),
    'time': _atd_write_optional_field(writeProfile, x.time, x),
    'explanations': _atd_write_optional_field(_atd_write_array(writeMatchingExplanation), x.explanations, x),
    'rules_by_engine': _atd_write_optional_field(_atd_write_array(writeRuleIdAndEngineKind), x.rules_by_engine, x),
    'engine_requested': _atd_write_optional_field(writeEngineKind, x.engine_requested, x),
    'interfile_languages_used': _atd_write_optional_field(_atd_write_array(_atd_write_string), x.interfile_languages_used, x),
    'skipped_rules': _atd_write_field_with_default(_atd_write_array(writeSkippedRule), [], x.skipped_rules, x),
  };
}

export function readCliOutputExtra(x: any, context: any = x): CliOutputExtra {
  return {
    paths: _atd_read_required_field('CliOutputExtra', 'paths', readScannedAndSkipped, x['paths'], x),
    time: _atd_read_optional_field(readProfile, x['time'], x),
    explanations: _atd_read_optional_field(_atd_read_array(readMatchingExplanation), x['explanations'], x),
    rules_by_engine: _atd_read_optional_field(_atd_read_array(readRuleIdAndEngineKind), x['rules_by_engine'], x),
    engine_requested: _atd_read_optional_field(readEngineKind, x['engine_requested'], x),
    interfile_languages_used: _atd_read_optional_field(_atd_read_array(_atd_read_string), x['interfile_languages_used'], x),
    skipped_rules: _atd_read_field_with_default(_atd_read_array(readSkippedRule), [], x['skipped_rules'], x),
  };
}

export function writeConfigErrorReason(x: ConfigErrorReason, context: any = x): any {
  switch (x.kind) {
    case 'UnparsableRule':
      return 'unparsable_rule'
  }
}

export function readConfigErrorReason(x: any, context: any = x): ConfigErrorReason {
  switch (x) {
    case 'unparsable_rule':
      return { kind: 'UnparsableRule' }
    default:
      _atd_bad_json('ConfigErrorReason', x, context)
      throw new Error('impossible')
  }
}

export function writeConfigError(x: ConfigError, context: any = x): any {
  return {
    'file': _atd_write_required_field('ConfigError', 'file', writeFpath, x.file, x),
    'reason': _atd_write_required_field('ConfigError', 'reason', writeConfigErrorReason, x.reason, x),
  };
}

export function readConfigError(x: any, context: any = x): ConfigError {
  return {
    file: _atd_read_required_field('ConfigError', 'file', readFpath, x['file'], x),
    reason: _atd_read_required_field('ConfigError', 'reason', readConfigErrorReason, x['reason'], x),
  };
}

export function writeTestsResult(x: TestsResult, context: any = x): any {
  return {
    'results': _atd_write_required_field('TestsResult', 'results', _atd_write_assoc_array_to_object(writeChecks), x.results, x),
    'fixtest_results': _atd_write_required_field('TestsResult', 'fixtest_results', _atd_write_assoc_array_to_object(writeFixtestResult), x.fixtest_results, x),
    'config_missing_tests': _atd_write_required_field('TestsResult', 'config_missing_tests', _atd_write_array(writeFpath), x.config_missing_tests, x),
    'config_missing_fixtests': _atd_write_required_field('TestsResult', 'config_missing_fixtests', _atd_write_array(writeFpath), x.config_missing_fixtests, x),
    'config_with_errors': _atd_write_required_field('TestsResult', 'config_with_errors', _atd_write_array(writeConfigError), x.config_with_errors, x),
  };
}

export function readTestsResult(x: any, context: any = x): TestsResult {
  return {
    results: _atd_read_required_field('TestsResult', 'results', _atd_read_assoc_object_into_array(readChecks), x['results'], x),
    fixtest_results: _atd_read_required_field('TestsResult', 'fixtest_results', _atd_read_assoc_object_into_array(readFixtestResult), x['fixtest_results'], x),
    config_missing_tests: _atd_read_required_field('TestsResult', 'config_missing_tests', _atd_read_array(readFpath), x['config_missing_tests'], x),
    config_missing_fixtests: _atd_read_required_field('TestsResult', 'config_missing_fixtests', _atd_read_array(readFpath), x['config_missing_fixtests'], x),
    config_with_errors: _atd_read_required_field('TestsResult', 'config_with_errors', _atd_read_array(readConfigError), x['config_with_errors'], x),
  };
}

export function writeChecks(x: Checks, context: any = x): any {
  return {
    'checks': _atd_write_required_field('Checks', 'checks', _atd_write_assoc_array_to_object(writeRuleResult), x.checks, x),
  };
}

export function readChecks(x: any, context: any = x): Checks {
  return {
    checks: _atd_read_required_field('Checks', 'checks', _atd_read_assoc_object_into_array(readRuleResult), x['checks'], x),
  };
}

export function writeRuleResult(x: RuleResult, context: any = x): any {
  return {
    'passed': _atd_write_required_field('RuleResult', 'passed', _atd_write_bool, x.passed, x),
    'matches': _atd_write_required_field('RuleResult', 'matches', _atd_write_assoc_array_to_object(writeExpectedReported), x.matches, x),
    'errors': _atd_write_required_field('RuleResult', 'errors', _atd_write_array(writeTodo), x.errors, x),
    'diagnosis': _atd_write_optional_field(writeMatchingDiagnosis, x.diagnosis, x),
  };
}

export function readRuleResult(x: any, context: any = x): RuleResult {
  return {
    passed: _atd_read_required_field('RuleResult', 'passed', _atd_read_bool, x['passed'], x),
    matches: _atd_read_required_field('RuleResult', 'matches', _atd_read_assoc_object_into_array(readExpectedReported), x['matches'], x),
    errors: _atd_read_required_field('RuleResult', 'errors', _atd_read_array(readTodo), x['errors'], x),
    diagnosis: _atd_read_optional_field(readMatchingDiagnosis, x['diagnosis'], x),
  };
}

export function writeExpectedReported(x: ExpectedReported, context: any = x): any {
  return {
    'expected_lines': _atd_write_required_field('ExpectedReported', 'expected_lines', _atd_write_array(_atd_write_int), x.expected_lines, x),
    'reported_lines': _atd_write_required_field('ExpectedReported', 'reported_lines', _atd_write_array(_atd_write_int), x.reported_lines, x),
  };
}

export function readExpectedReported(x: any, context: any = x): ExpectedReported {
  return {
    expected_lines: _atd_read_required_field('ExpectedReported', 'expected_lines', _atd_read_array(_atd_read_int), x['expected_lines'], x),
    reported_lines: _atd_read_required_field('ExpectedReported', 'reported_lines', _atd_read_array(_atd_read_int), x['reported_lines'], x),
  };
}

export function writeFixtestResult(x: FixtestResult, context: any = x): any {
  return {
    'passed': _atd_write_required_field('FixtestResult', 'passed', _atd_write_bool, x.passed, x),
  };
}

export function readFixtestResult(x: any, context: any = x): FixtestResult {
  return {
    passed: _atd_read_required_field('FixtestResult', 'passed', _atd_read_bool, x['passed'], x),
  };
}

export function writeTodo(x: Todo, context: any = x): any {
  return _atd_write_int(x, context);
}

export function readTodo(x: any, context: any = x): Todo {
  return _atd_read_int(x, context);
}

export function writeMatchingDiagnosis(x: MatchingDiagnosis, context: any = x): any {
  return {
    'target': _atd_write_required_field('MatchingDiagnosis', 'target', writeFpath, x.target, x),
    'unexpected_match_diagnoses': _atd_write_required_field('MatchingDiagnosis', 'unexpected_match_diagnoses', _atd_write_array(writeUnexpectedMatchDiagnosis), x.unexpected_match_diagnoses, x),
    'unexpected_no_match_diagnoses': _atd_write_required_field('MatchingDiagnosis', 'unexpected_no_match_diagnoses', _atd_write_array(writeUnexpectedNoMatchDiagnosis), x.unexpected_no_match_diagnoses, x),
  };
}

export function readMatchingDiagnosis(x: any, context: any = x): MatchingDiagnosis {
  return {
    target: _atd_read_required_field('MatchingDiagnosis', 'target', readFpath, x['target'], x),
    unexpected_match_diagnoses: _atd_read_required_field('MatchingDiagnosis', 'unexpected_match_diagnoses', _atd_read_array(readUnexpectedMatchDiagnosis), x['unexpected_match_diagnoses'], x),
    unexpected_no_match_diagnoses: _atd_read_required_field('MatchingDiagnosis', 'unexpected_no_match_diagnoses', _atd_read_array(readUnexpectedNoMatchDiagnosis), x['unexpected_no_match_diagnoses'], x),
  };
}

export function writeUnexpectedMatchDiagnosis(x: UnexpectedMatchDiagnosis, context: any = x): any {
  return {
    'matched_text': _atd_write_required_field('UnexpectedMatchDiagnosis', 'matched_text', writeSnippet, x.matched_text, x),
    'originating_kind': _atd_write_required_field('UnexpectedMatchDiagnosis', 'originating_kind', writeOriginatingNodeKind, x.originating_kind, x),
    'originating_text': _atd_write_required_field('UnexpectedMatchDiagnosis', 'originating_text', writeSnippet, x.originating_text, x),
    'killing_parents': _atd_write_required_field('UnexpectedMatchDiagnosis', 'killing_parents', _atd_write_array(writeKillingParent), x.killing_parents, x),
  };
}

export function readUnexpectedMatchDiagnosis(x: any, context: any = x): UnexpectedMatchDiagnosis {
  return {
    matched_text: _atd_read_required_field('UnexpectedMatchDiagnosis', 'matched_text', readSnippet, x['matched_text'], x),
    originating_kind: _atd_read_required_field('UnexpectedMatchDiagnosis', 'originating_kind', readOriginatingNodeKind, x['originating_kind'], x),
    originating_text: _atd_read_required_field('UnexpectedMatchDiagnosis', 'originating_text', readSnippet, x['originating_text'], x),
    killing_parents: _atd_read_required_field('UnexpectedMatchDiagnosis', 'killing_parents', _atd_read_array(readKillingParent), x['killing_parents'], x),
  };
}

export function writeUnexpectedNoMatchDiagnosis(x: UnexpectedNoMatchDiagnosis, context: any = x): any {
  return {
    'line': _atd_write_required_field('UnexpectedNoMatchDiagnosis', 'line', _atd_write_int, x.line, x),
    'kind': _atd_write_required_field('UnexpectedNoMatchDiagnosis', 'kind', writeUnexpectedNoMatchDiagnosisKind, x.kind, x),
  };
}

export function readUnexpectedNoMatchDiagnosis(x: any, context: any = x): UnexpectedNoMatchDiagnosis {
  return {
    line: _atd_read_required_field('UnexpectedNoMatchDiagnosis', 'line', _atd_read_int, x['line'], x),
    kind: _atd_read_required_field('UnexpectedNoMatchDiagnosis', 'kind', readUnexpectedNoMatchDiagnosisKind, x['kind'], x),
  };
}

export function writeUnexpectedNoMatchDiagnosisKind(x: UnexpectedNoMatchDiagnosisKind, context: any = x): any {
  switch (x.kind) {
    case 'Never_matched':
      return 'Never_matched'
    case 'Killed_by_nodes':
      return ['Killed_by_nodes', _atd_write_array(writeKillingParent)(x.value, x)]
  }
}

export function readUnexpectedNoMatchDiagnosisKind(x: any, context: any = x): UnexpectedNoMatchDiagnosisKind {
  if (typeof x === 'string') {
    switch (x) {
      case 'Never_matched':
        return { kind: 'Never_matched' }
      default:
        _atd_bad_json('UnexpectedNoMatchDiagnosisKind', x, context)
        throw new Error('impossible')
    }
  }
  else {
    _atd_check_json_tuple(2, x, context)
    switch (x[0]) {
      case 'Killed_by_nodes':
        return { kind: 'Killed_by_nodes', value: _atd_read_array(readKillingParent)(x[1], x) }
      default:
        _atd_bad_json('UnexpectedNoMatchDiagnosisKind', x, context)
        throw new Error('impossible')
    }
  }
}

export function writeOriginatingNodeKind(x: OriginatingNodeKind, context: any = x): any {
  switch (x.kind) {
    case 'Focus':
      return 'Focus'
    case 'Xpattern':
      return 'Xpattern'
  }
}

export function readOriginatingNodeKind(x: any, context: any = x): OriginatingNodeKind {
  switch (x) {
    case 'Focus':
      return { kind: 'Focus' }
    case 'Xpattern':
      return { kind: 'Xpattern' }
    default:
      _atd_bad_json('OriginatingNodeKind', x, context)
      throw new Error('impossible')
  }
}

export function writeKillingParentKind(x: KillingParentKind, context: any = x): any {
  switch (x.kind) {
    case 'And':
      return 'And'
    case 'Inside':
      return 'Inside'
    case 'Negation':
      return 'Negation'
    case 'Filter':
      return ['Filter', _atd_write_string(x.value, x)]
  }
}

export function readKillingParentKind(x: any, context: any = x): KillingParentKind {
  if (typeof x === 'string') {
    switch (x) {
      case 'And':
        return { kind: 'And' }
      case 'Inside':
        return { kind: 'Inside' }
      case 'Negation':
        return { kind: 'Negation' }
      default:
        _atd_bad_json('KillingParentKind', x, context)
        throw new Error('impossible')
    }
  }
  else {
    _atd_check_json_tuple(2, x, context)
    switch (x[0]) {
      case 'Filter':
        return { kind: 'Filter', value: _atd_read_string(x[1], x) }
      default:
        _atd_bad_json('KillingParentKind', x, context)
        throw new Error('impossible')
    }
  }
}

export function writeSnippet(x: Snippet, context: any = x): any {
  return {
    'line': _atd_write_required_field('Snippet', 'line', _atd_write_int, x.line, x),
    'text': _atd_write_required_field('Snippet', 'text', _atd_write_string, x.text, x),
  };
}

export function readSnippet(x: any, context: any = x): Snippet {
  return {
    line: _atd_read_required_field('Snippet', 'line', _atd_read_int, x['line'], x),
    text: _atd_read_required_field('Snippet', 'text', _atd_read_string, x['text'], x),
  };
}

export function writeKillingParent(x: KillingParent, context: any = x): any {
  return {
    'killing_parent_kind': _atd_write_required_field('KillingParent', 'killing_parent_kind', writeKillingParentKind, x.killing_parent_kind, x),
    'snippet': _atd_write_required_field('KillingParent', 'snippet', writeSnippet, x.snippet, x),
  };
}

export function readKillingParent(x: any, context: any = x): KillingParent {
  return {
    killing_parent_kind: _atd_read_required_field('KillingParent', 'killing_parent_kind', readKillingParentKind, x['killing_parent_kind'], x),
    snippet: _atd_read_required_field('KillingParent', 'snippet', readSnippet, x['snippet'], x),
  };
}

export function writeFeatures(x: Features, context: any = x): any {
  return {
    'autofix': _atd_write_field_with_default(_atd_write_bool, false, x.autofix, x),
    'deepsemgrep': _atd_write_field_with_default(_atd_write_bool, false, x.deepsemgrep, x),
    'dependency_query': _atd_write_field_with_default(_atd_write_bool, false, x.dependency_query, x),
    'path_to_transitivity': _atd_write_field_with_default(_atd_write_bool, false, x.path_to_transitivity, x),
    'scan_all_deps_in_diff_scan': _atd_write_field_with_default(_atd_write_bool, false, x.scan_all_deps_in_diff_scan, x),
  };
}

export function readFeatures(x: any, context: any = x): Features {
  return {
    autofix: _atd_read_field_with_default(_atd_read_bool, false, x['autofix'], x),
    deepsemgrep: _atd_read_field_with_default(_atd_read_bool, false, x['deepsemgrep'], x),
    dependency_query: _atd_read_field_with_default(_atd_read_bool, false, x['dependency_query'], x),
    path_to_transitivity: _atd_read_field_with_default(_atd_read_bool, false, x['path_to_transitivity'], x),
    scan_all_deps_in_diff_scan: _atd_read_field_with_default(_atd_read_bool, false, x['scan_all_deps_in_diff_scan'], x),
  };
}

export function writeTriageIgnored(x: TriageIgnored, context: any = x): any {
  return {
    'triage_ignored_syntactic_ids': _atd_write_field_with_default(_atd_write_array(_atd_write_string), [], x.triage_ignored_syntactic_ids, x),
    'triage_ignored_match_based_ids': _atd_write_field_with_default(_atd_write_array(_atd_write_string), [], x.triage_ignored_match_based_ids, x),
  };
}

export function readTriageIgnored(x: any, context: any = x): TriageIgnored {
  return {
    triage_ignored_syntactic_ids: _atd_read_field_with_default(_atd_read_array(_atd_read_string), [], x['triage_ignored_syntactic_ids'], x),
    triage_ignored_match_based_ids: _atd_read_field_with_default(_atd_read_array(_atd_read_string), [], x['triage_ignored_match_based_ids'], x),
  };
}

export function writeAction(x: Action, context: any = x): any {
  switch (x.kind) {
    case 'Message':
      return ['Message', _atd_write_string(x.value, x)]
    case 'Delay':
      return ['Delay', _atd_write_float(x.value, x)]
    case 'Exit':
      return ['Exit', _atd_write_int(x.value, x)]
  }
}

export function readAction(x: any, context: any = x): Action {
  _atd_check_json_tuple(2, x, context)
  switch (x[0]) {
    case 'Message':
      return { kind: 'Message', value: _atd_read_string(x[1], x) }
    case 'Delay':
      return { kind: 'Delay', value: _atd_read_float(x[1], x) }
    case 'Exit':
      return { kind: 'Exit', value: _atd_read_int(x[1], x) }
    default:
      _atd_bad_json('Action', x, context)
      throw new Error('impossible')
  }
}

export function writeScanResponse(x: ScanResponse, context: any = x): any {
  return {
    'info': _atd_write_required_field('ScanResponse', 'info', writeScanInfo, x.info, x),
    'config': _atd_write_required_field('ScanResponse', 'config', writeScanConfiguration, x.config, x),
    'engine_params': _atd_write_required_field('ScanResponse', 'engine_params', writeEngineConfiguration, x.engine_params, x),
  };
}

export function readScanResponse(x: any, context: any = x): ScanResponse {
  return {
    info: _atd_read_required_field('ScanResponse', 'info', readScanInfo, x['info'], x),
    config: _atd_read_required_field('ScanResponse', 'config', readScanConfiguration, x['config'], x),
    engine_params: _atd_read_required_field('ScanResponse', 'engine_params', readEngineConfiguration, x['engine_params'], x),
  };
}

export function writeScanInfo(x: ScanInfo, context: any = x): any {
  return {
    'id': _atd_write_optional_field(_atd_write_int, x.id, x),
    'enabled_products': _atd_write_required_field('ScanInfo', 'enabled_products', _atd_write_array(writeProduct), x.enabled_products, x),
    'deployment_id': _atd_write_required_field('ScanInfo', 'deployment_id', _atd_write_int, x.deployment_id, x),
    'deployment_name': _atd_write_required_field('ScanInfo', 'deployment_name', _atd_write_string, x.deployment_name, x),
  };
}

export function readScanInfo(x: any, context: any = x): ScanInfo {
  return {
    id: _atd_read_optional_field(_atd_read_int, x['id'], x),
    enabled_products: _atd_read_required_field('ScanInfo', 'enabled_products', _atd_read_array(readProduct), x['enabled_products'], x),
    deployment_id: _atd_read_required_field('ScanInfo', 'deployment_id', _atd_read_int, x['deployment_id'], x),
    deployment_name: _atd_read_required_field('ScanInfo', 'deployment_name', _atd_read_string, x['deployment_name'], x),
  };
}

export function writeScanConfiguration(x: ScanConfiguration, context: any = x): any {
  return {
    'rules': _atd_write_required_field('ScanConfiguration', 'rules', writeRawJson, x.rules, x),
    'triage_ignored_syntactic_ids': _atd_write_field_with_default(_atd_write_array(_atd_write_string), [], x.triage_ignored_syntactic_ids, x),
    'triage_ignored_match_based_ids': _atd_write_field_with_default(_atd_write_array(_atd_write_string), [], x.triage_ignored_match_based_ids, x),
  };
}

export function readScanConfiguration(x: any, context: any = x): ScanConfiguration {
  return {
    rules: _atd_read_required_field('ScanConfiguration', 'rules', readRawJson, x['rules'], x),
    triage_ignored_syntactic_ids: _atd_read_field_with_default(_atd_read_array(_atd_read_string), [], x['triage_ignored_syntactic_ids'], x),
    triage_ignored_match_based_ids: _atd_read_field_with_default(_atd_read_array(_atd_read_string), [], x['triage_ignored_match_based_ids'], x),
  };
}

export function writeEngineConfiguration(x: EngineConfiguration, context: any = x): any {
  return {
    'autofix': _atd_write_field_with_default(_atd_write_bool, false, x.autofix, x),
    'deepsemgrep': _atd_write_field_with_default(_atd_write_bool, false, x.deepsemgrep, x),
    'dependency_query': _atd_write_field_with_default(_atd_write_bool, false, x.dependency_query, x),
    'path_to_transitivity': _atd_write_field_with_default(_atd_write_bool, false, x.path_to_transitivity, x),
    'scan_all_deps_in_diff_scan': _atd_write_field_with_default(_atd_write_bool, false, x.scan_all_deps_in_diff_scan, x),
    'ignored_files': _atd_write_field_with_default(_atd_write_array(_atd_write_string), [], x.ignored_files, x),
    'product_ignored_files': _atd_write_optional_field(writeProductIgnoredFiles, x.product_ignored_files, x),
    'generic_slow_rollout': _atd_write_field_with_default(_atd_write_bool, false, x.generic_slow_rollout, x),
    'historical_config': _atd_write_optional_field(writeHistoricalConfiguration, x.historical_config, x),
    'always_suppress_errors': _atd_write_field_with_default(_atd_write_bool, false, x.always_suppress_errors, x),
  };
}

export function readEngineConfiguration(x: any, context: any = x): EngineConfiguration {
  return {
    autofix: _atd_read_field_with_default(_atd_read_bool, false, x['autofix'], x),
    deepsemgrep: _atd_read_field_with_default(_atd_read_bool, false, x['deepsemgrep'], x),
    dependency_query: _atd_read_field_with_default(_atd_read_bool, false, x['dependency_query'], x),
    path_to_transitivity: _atd_read_field_with_default(_atd_read_bool, false, x['path_to_transitivity'], x),
    scan_all_deps_in_diff_scan: _atd_read_field_with_default(_atd_read_bool, false, x['scan_all_deps_in_diff_scan'], x),
    ignored_files: _atd_read_field_with_default(_atd_read_array(_atd_read_string), [], x['ignored_files'], x),
    product_ignored_files: _atd_read_optional_field(readProductIgnoredFiles, x['product_ignored_files'], x),
    generic_slow_rollout: _atd_read_field_with_default(_atd_read_bool, false, x['generic_slow_rollout'], x),
    historical_config: _atd_read_optional_field(readHistoricalConfiguration, x['historical_config'], x),
    always_suppress_errors: _atd_read_field_with_default(_atd_read_bool, false, x['always_suppress_errors'], x),
  };
}

export function writeProductIgnoredFiles(x: ProductIgnoredFiles, context: any = x): any {
  return _atd_write_assoc_map_to_array(writeProduct, _atd_write_array(writeGlob))(x, context);
}

export function readProductIgnoredFiles(x: any, context: any = x): ProductIgnoredFiles {
  return _atd_read_assoc_array_into_map(readProduct, _atd_read_array(readGlob))(x, context);
}

export function writeHistoricalConfiguration(x: HistoricalConfiguration, context: any = x): any {
  return {
    'enabled': _atd_write_required_field('HistoricalConfiguration', 'enabled', _atd_write_bool, x.enabled, x),
    'lookback_days': _atd_write_optional_field(_atd_write_int, x.lookback_days, x),
  };
}

export function readHistoricalConfiguration(x: any, context: any = x): HistoricalConfiguration {
  return {
    enabled: _atd_read_required_field('HistoricalConfiguration', 'enabled', _atd_read_bool, x['enabled'], x),
    lookback_days: _atd_read_optional_field(_atd_read_int, x['lookback_days'], x),
  };
}

export function writeScanRequest(x: ScanRequest, context: any = x): any {
  return {
    'project_metadata': _atd_write_required_field('ScanRequest', 'project_metadata', writeProjectMetadata, x.project_metadata, x),
    'scan_metadata': _atd_write_required_field('ScanRequest', 'scan_metadata', writeScanMetadata, x.scan_metadata, x),
    'project_config': _atd_write_optional_field(writeCiConfigFromRepo, x.project_config, x),
  };
}

export function readScanRequest(x: any, context: any = x): ScanRequest {
  return {
    project_metadata: _atd_read_required_field('ScanRequest', 'project_metadata', readProjectMetadata, x['project_metadata'], x),
    scan_metadata: _atd_read_required_field('ScanRequest', 'scan_metadata', readScanMetadata, x['scan_metadata'], x),
    project_config: _atd_read_optional_field(readCiConfigFromRepo, x['project_config'], x),
  };
}

export function writeProjectMetadata(x: ProjectMetadata, context: any = x): any {
  return {
    'scan_environment': _atd_write_required_field('ProjectMetadata', 'scan_environment', _atd_write_string, x.scan_environment, x),
    'repository': _atd_write_required_field('ProjectMetadata', 'repository', _atd_write_string, x.repository, x),
    'repo_url': _atd_write_required_field('ProjectMetadata', 'repo_url', _atd_write_nullable(writeUri), x.repo_url, x),
    'repo_id': _atd_write_optional_field(_atd_write_string, x.repo_id, x),
    'org_id': _atd_write_optional_field(_atd_write_string, x.org_id, x),
    'repo_display_name': _atd_write_optional_field(_atd_write_string, x.repo_display_name, x),
    'branch': _atd_write_required_field('ProjectMetadata', 'branch', _atd_write_nullable(_atd_write_string), x.branch, x),
    'commit': _atd_write_required_field('ProjectMetadata', 'commit', _atd_write_nullable(writeSha1), x.commit, x),
    'commit_title': _atd_write_required_field('ProjectMetadata', 'commit_title', _atd_write_nullable(_atd_write_string), x.commit_title, x),
    'commit_timestamp': _atd_write_optional_field(writeDatetime, x.commit_timestamp, x),
    'commit_author_email': _atd_write_required_field('ProjectMetadata', 'commit_author_email', _atd_write_nullable(_atd_write_string), x.commit_author_email, x),
    'commit_author_name': _atd_write_required_field('ProjectMetadata', 'commit_author_name', _atd_write_nullable(_atd_write_string), x.commit_author_name, x),
    'commit_author_username': _atd_write_required_field('ProjectMetadata', 'commit_author_username', _atd_write_nullable(_atd_write_string), x.commit_author_username, x),
    'commit_author_image_url': _atd_write_required_field('ProjectMetadata', 'commit_author_image_url', _atd_write_nullable(writeUri), x.commit_author_image_url, x),
    'ci_job_url': _atd_write_required_field('ProjectMetadata', 'ci_job_url', _atd_write_nullable(writeUri), x.ci_job_url, x),
    'on': _atd_write_required_field('ProjectMetadata', 'on', _atd_write_string, x.on, x),
    'pull_request_author_username': _atd_write_required_field('ProjectMetadata', 'pull_request_author_username', _atd_write_nullable(_atd_write_string), x.pull_request_author_username, x),
    'pull_request_author_image_url': _atd_write_required_field('ProjectMetadata', 'pull_request_author_image_url', _atd_write_nullable(writeUri), x.pull_request_author_image_url, x),
    'pull_request_id': _atd_write_required_field('ProjectMetadata', 'pull_request_id', _atd_write_nullable(_atd_write_string), x.pull_request_id, x),
    'pull_request_title': _atd_write_required_field('ProjectMetadata', 'pull_request_title', _atd_write_nullable(_atd_write_string), x.pull_request_title, x),
    'base_sha': _atd_write_optional_field(writeSha1, x.base_sha, x),
    'start_sha': _atd_write_optional_field(writeSha1, x.start_sha, x),
    'is_full_scan': _atd_write_required_field('ProjectMetadata', 'is_full_scan', _atd_write_bool, x.is_full_scan, x),
    'is_sca_scan': _atd_write_optional_field(_atd_write_bool, x.is_sca_scan, x),
    'is_code_scan': _atd_write_optional_field(_atd_write_bool, x.is_code_scan, x),
    'is_secrets_scan': _atd_write_optional_field(_atd_write_bool, x.is_secrets_scan, x),
  };
}

export function readProjectMetadata(x: any, context: any = x): ProjectMetadata {
  return {
    scan_environment: _atd_read_required_field('ProjectMetadata', 'scan_environment', _atd_read_string, x['scan_environment'], x),
    repository: _atd_read_required_field('ProjectMetadata', 'repository', _atd_read_string, x['repository'], x),
    repo_url: _atd_read_required_field('ProjectMetadata', 'repo_url', _atd_read_nullable(readUri), x['repo_url'], x),
    repo_id: _atd_read_optional_field(_atd_read_string, x['repo_id'], x),
    org_id: _atd_read_optional_field(_atd_read_string, x['org_id'], x),
    repo_display_name: _atd_read_optional_field(_atd_read_string, x['repo_display_name'], x),
    branch: _atd_read_required_field('ProjectMetadata', 'branch', _atd_read_nullable(_atd_read_string), x['branch'], x),
    commit: _atd_read_required_field('ProjectMetadata', 'commit', _atd_read_nullable(readSha1), x['commit'], x),
    commit_title: _atd_read_required_field('ProjectMetadata', 'commit_title', _atd_read_nullable(_atd_read_string), x['commit_title'], x),
    commit_timestamp: _atd_read_optional_field(readDatetime, x['commit_timestamp'], x),
    commit_author_email: _atd_read_required_field('ProjectMetadata', 'commit_author_email', _atd_read_nullable(_atd_read_string), x['commit_author_email'], x),
    commit_author_name: _atd_read_required_field('ProjectMetadata', 'commit_author_name', _atd_read_nullable(_atd_read_string), x['commit_author_name'], x),
    commit_author_username: _atd_read_required_field('ProjectMetadata', 'commit_author_username', _atd_read_nullable(_atd_read_string), x['commit_author_username'], x),
    commit_author_image_url: _atd_read_required_field('ProjectMetadata', 'commit_author_image_url', _atd_read_nullable(readUri), x['commit_author_image_url'], x),
    ci_job_url: _atd_read_required_field('ProjectMetadata', 'ci_job_url', _atd_read_nullable(readUri), x['ci_job_url'], x),
    on: _atd_read_required_field('ProjectMetadata', 'on', _atd_read_string, x['on'], x),
    pull_request_author_username: _atd_read_required_field('ProjectMetadata', 'pull_request_author_username', _atd_read_nullable(_atd_read_string), x['pull_request_author_username'], x),
    pull_request_author_image_url: _atd_read_required_field('ProjectMetadata', 'pull_request_author_image_url', _atd_read_nullable(readUri), x['pull_request_author_image_url'], x),
    pull_request_id: _atd_read_required_field('ProjectMetadata', 'pull_request_id', _atd_read_nullable(_atd_read_string), x['pull_request_id'], x),
    pull_request_title: _atd_read_required_field('ProjectMetadata', 'pull_request_title', _atd_read_nullable(_atd_read_string), x['pull_request_title'], x),
    base_sha: _atd_read_optional_field(readSha1, x['base_sha'], x),
    start_sha: _atd_read_optional_field(readSha1, x['start_sha'], x),
    is_full_scan: _atd_read_required_field('ProjectMetadata', 'is_full_scan', _atd_read_bool, x['is_full_scan'], x),
    is_sca_scan: _atd_read_optional_field(_atd_read_bool, x['is_sca_scan'], x),
    is_code_scan: _atd_read_optional_field(_atd_read_bool, x['is_code_scan'], x),
    is_secrets_scan: _atd_read_optional_field(_atd_read_bool, x['is_secrets_scan'], x),
  };
}

export function writeScanMetadata(x: ScanMetadata, context: any = x): any {
  return {
    'cli_version': _atd_write_required_field('ScanMetadata', 'cli_version', writeVersion, x.cli_version, x),
    'unique_id': _atd_write_required_field('ScanMetadata', 'unique_id', writeUuid, x.unique_id, x),
    'requested_products': _atd_write_required_field('ScanMetadata', 'requested_products', _atd_write_array(writeProduct), x.requested_products, x),
    'dry_run': _atd_write_field_with_default(_atd_write_bool, false, x.dry_run, x),
    'sms_scan_id': _atd_write_optional_field(_atd_write_string, x.sms_scan_id, x),
  };
}

export function readScanMetadata(x: any, context: any = x): ScanMetadata {
  return {
    cli_version: _atd_read_required_field('ScanMetadata', 'cli_version', readVersion, x['cli_version'], x),
    unique_id: _atd_read_required_field('ScanMetadata', 'unique_id', readUuid, x['unique_id'], x),
    requested_products: _atd_read_required_field('ScanMetadata', 'requested_products', _atd_read_array(readProduct), x['requested_products'], x),
    dry_run: _atd_read_field_with_default(_atd_read_bool, false, x['dry_run'], x),
    sms_scan_id: _atd_read_optional_field(_atd_read_string, x['sms_scan_id'], x),
  };
}

export function writeCiConfigFromRepo(x: CiConfigFromRepo, context: any = x): any {
  return {
    'version': _atd_write_field_with_default(writeVersion, 'v1', x.version, x),
    'tags': _atd_write_optional_field(_atd_write_array(writeTag), x.tags, x),
  };
}

export function readCiConfigFromRepo(x: any, context: any = x): CiConfigFromRepo {
  return {
    version: _atd_read_field_with_default(readVersion, 'v1', x['version'], x),
    tags: _atd_read_optional_field(_atd_read_array(readTag), x['tags'], x),
  };
}

export function writeTag(x: Tag, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readTag(x: any, context: any = x): Tag {
  return _atd_read_string(x, context);
}

export function writeFinding(x: Finding, context: any = x): any {
  return {
    'check_id': _atd_write_required_field('Finding', 'check_id', writeRuleId, x.check_id, x),
    'path': _atd_write_required_field('Finding', 'path', writeFpath, x.path, x),
    'line': _atd_write_required_field('Finding', 'line', _atd_write_int, x.line, x),
    'column': _atd_write_required_field('Finding', 'column', _atd_write_int, x.column, x),
    'end_line': _atd_write_required_field('Finding', 'end_line', _atd_write_int, x.end_line, x),
    'end_column': _atd_write_required_field('Finding', 'end_column', _atd_write_int, x.end_column, x),
    'message': _atd_write_required_field('Finding', 'message', _atd_write_string, x.message, x),
    'severity': _atd_write_required_field('Finding', 'severity', ((x: any, context): any => x), x.severity, x),
    'index': _atd_write_required_field('Finding', 'index', _atd_write_int, x.index, x),
    'commit_date': _atd_write_required_field('Finding', 'commit_date', _atd_write_string, x.commit_date, x),
    'syntactic_id': _atd_write_required_field('Finding', 'syntactic_id', _atd_write_string, x.syntactic_id, x),
    'match_based_id': _atd_write_optional_field(_atd_write_string, x.match_based_id, x),
    'hashes': _atd_write_optional_field(writeFindingHashes, x.hashes, x),
    'metadata': _atd_write_required_field('Finding', 'metadata', writeRawJson, x.metadata, x),
    'is_blocking': _atd_write_required_field('Finding', 'is_blocking', _atd_write_bool, x.is_blocking, x),
    'fixed_lines': _atd_write_optional_field(_atd_write_array(_atd_write_string), x.fixed_lines, x),
    'sca_info': _atd_write_optional_field(writeScaMatch, x.sca_info, x),
    'dataflow_trace': _atd_write_optional_field(writeMatchDataflowTrace, x.dataflow_trace, x),
    'validation_state': _atd_write_optional_field(writeValidationState, x.validation_state, x),
    'historical_info': _atd_write_optional_field(writeHistoricalInfo, x.historical_info, x),
    'engine_kind': _atd_write_optional_field(writeEngineOfFinding, x.engine_kind, x),
  };
}

export function readFinding(x: any, context: any = x): Finding {
  return {
    check_id: _atd_read_required_field('Finding', 'check_id', readRuleId, x['check_id'], x),
    path: _atd_read_required_field('Finding', 'path', readFpath, x['path'], x),
    line: _atd_read_required_field('Finding', 'line', _atd_read_int, x['line'], x),
    column: _atd_read_required_field('Finding', 'column', _atd_read_int, x['column'], x),
    end_line: _atd_read_required_field('Finding', 'end_line', _atd_read_int, x['end_line'], x),
    end_column: _atd_read_required_field('Finding', 'end_column', _atd_read_int, x['end_column'], x),
    message: _atd_read_required_field('Finding', 'message', _atd_read_string, x['message'], x),
    severity: _atd_read_required_field('Finding', 'severity', ((x: any, context): any => x), x['severity'], x),
    index: _atd_read_required_field('Finding', 'index', _atd_read_int, x['index'], x),
    commit_date: _atd_read_required_field('Finding', 'commit_date', _atd_read_string, x['commit_date'], x),
    syntactic_id: _atd_read_required_field('Finding', 'syntactic_id', _atd_read_string, x['syntactic_id'], x),
    match_based_id: _atd_read_optional_field(_atd_read_string, x['match_based_id'], x),
    hashes: _atd_read_optional_field(readFindingHashes, x['hashes'], x),
    metadata: _atd_read_required_field('Finding', 'metadata', readRawJson, x['metadata'], x),
    is_blocking: _atd_read_required_field('Finding', 'is_blocking', _atd_read_bool, x['is_blocking'], x),
    fixed_lines: _atd_read_optional_field(_atd_read_array(_atd_read_string), x['fixed_lines'], x),
    sca_info: _atd_read_optional_field(readScaMatch, x['sca_info'], x),
    dataflow_trace: _atd_read_optional_field(readMatchDataflowTrace, x['dataflow_trace'], x),
    validation_state: _atd_read_optional_field(readValidationState, x['validation_state'], x),
    historical_info: _atd_read_optional_field(readHistoricalInfo, x['historical_info'], x),
    engine_kind: _atd_read_optional_field(readEngineOfFinding, x['engine_kind'], x),
  };
}

export function writeFindingHashes(x: FindingHashes, context: any = x): any {
  return {
    'start_line_hash': _atd_write_required_field('FindingHashes', 'start_line_hash', _atd_write_string, x.start_line_hash, x),
    'end_line_hash': _atd_write_required_field('FindingHashes', 'end_line_hash', _atd_write_string, x.end_line_hash, x),
    'code_hash': _atd_write_required_field('FindingHashes', 'code_hash', _atd_write_string, x.code_hash, x),
    'pattern_hash': _atd_write_required_field('FindingHashes', 'pattern_hash', _atd_write_string, x.pattern_hash, x),
  };
}

export function readFindingHashes(x: any, context: any = x): FindingHashes {
  return {
    start_line_hash: _atd_read_required_field('FindingHashes', 'start_line_hash', _atd_read_string, x['start_line_hash'], x),
    end_line_hash: _atd_read_required_field('FindingHashes', 'end_line_hash', _atd_read_string, x['end_line_hash'], x),
    code_hash: _atd_read_required_field('FindingHashes', 'code_hash', _atd_read_string, x['code_hash'], x),
    pattern_hash: _atd_read_required_field('FindingHashes', 'pattern_hash', _atd_read_string, x['pattern_hash'], x),
  };
}

export function writeCiScanResults(x: CiScanResults, context: any = x): any {
  return {
    'findings': _atd_write_required_field('CiScanResults', 'findings', _atd_write_array(writeFinding), x.findings, x),
    'ignores': _atd_write_required_field('CiScanResults', 'ignores', _atd_write_array(writeFinding), x.ignores, x),
    'token': _atd_write_required_field('CiScanResults', 'token', _atd_write_nullable(_atd_write_string), x.token, x),
    'searched_paths': _atd_write_required_field('CiScanResults', 'searched_paths', _atd_write_array(writeFpath), x.searched_paths, x),
    'renamed_paths': _atd_write_required_field('CiScanResults', 'renamed_paths', _atd_write_array(writeFpath), x.renamed_paths, x),
    'rule_ids': _atd_write_required_field('CiScanResults', 'rule_ids', _atd_write_array(writeRuleId), x.rule_ids, x),
    'contributions': _atd_write_optional_field(writeContributions, x.contributions, x),
    'dependencies': _atd_write_optional_field(writeCiScanDependencies, x.dependencies, x),
  };
}

export function readCiScanResults(x: any, context: any = x): CiScanResults {
  return {
    findings: _atd_read_required_field('CiScanResults', 'findings', _atd_read_array(readFinding), x['findings'], x),
    ignores: _atd_read_required_field('CiScanResults', 'ignores', _atd_read_array(readFinding), x['ignores'], x),
    token: _atd_read_required_field('CiScanResults', 'token', _atd_read_nullable(_atd_read_string), x['token'], x),
    searched_paths: _atd_read_required_field('CiScanResults', 'searched_paths', _atd_read_array(readFpath), x['searched_paths'], x),
    renamed_paths: _atd_read_required_field('CiScanResults', 'renamed_paths', _atd_read_array(readFpath), x['renamed_paths'], x),
    rule_ids: _atd_read_required_field('CiScanResults', 'rule_ids', _atd_read_array(readRuleId), x['rule_ids'], x),
    contributions: _atd_read_optional_field(readContributions, x['contributions'], x),
    dependencies: _atd_read_optional_field(readCiScanDependencies, x['dependencies'], x),
  };
}

export function writeContributor(x: Contributor, context: any = x): any {
  return {
    'commit_author_name': _atd_write_required_field('Contributor', 'commit_author_name', _atd_write_string, x.commit_author_name, x),
    'commit_author_email': _atd_write_required_field('Contributor', 'commit_author_email', _atd_write_string, x.commit_author_email, x),
  };
}

export function readContributor(x: any, context: any = x): Contributor {
  return {
    commit_author_name: _atd_read_required_field('Contributor', 'commit_author_name', _atd_read_string, x['commit_author_name'], x),
    commit_author_email: _atd_read_required_field('Contributor', 'commit_author_email', _atd_read_string, x['commit_author_email'], x),
  };
}

export function writeContribution(x: Contribution, context: any = x): any {
  return {
    'commit_hash': _atd_write_required_field('Contribution', 'commit_hash', _atd_write_string, x.commit_hash, x),
    'commit_timestamp': _atd_write_required_field('Contribution', 'commit_timestamp', writeDatetime, x.commit_timestamp, x),
    'contributor': _atd_write_required_field('Contribution', 'contributor', writeContributor, x.contributor, x),
  };
}

export function readContribution(x: any, context: any = x): Contribution {
  return {
    commit_hash: _atd_read_required_field('Contribution', 'commit_hash', _atd_read_string, x['commit_hash'], x),
    commit_timestamp: _atd_read_required_field('Contribution', 'commit_timestamp', readDatetime, x['commit_timestamp'], x),
    contributor: _atd_read_required_field('Contribution', 'contributor', readContributor, x['contributor'], x),
  };
}

export function writeContributions(x: Contributions, context: any = x): any {
  return _atd_write_array(writeContribution)(x, context);
}

export function readContributions(x: any, context: any = x): Contributions {
  return _atd_read_array(readContribution)(x, context);
}

export function writeCiScanResultsResponse(x: CiScanResultsResponse, context: any = x): any {
  return {
    'errors': _atd_write_required_field('CiScanResultsResponse', 'errors', _atd_write_array(writeCiScanResultsResponseError), x.errors, x),
    'task_id': _atd_write_optional_field(_atd_write_string, x.task_id, x),
  };
}

export function readCiScanResultsResponse(x: any, context: any = x): CiScanResultsResponse {
  return {
    errors: _atd_read_required_field('CiScanResultsResponse', 'errors', _atd_read_array(readCiScanResultsResponseError), x['errors'], x),
    task_id: _atd_read_optional_field(_atd_read_string, x['task_id'], x),
  };
}

export function writeCiScanResultsResponseError(x: CiScanResultsResponseError, context: any = x): any {
  return {
    'message': _atd_write_required_field('CiScanResultsResponseError', 'message', _atd_write_string, x.message, x),
  };
}

export function readCiScanResultsResponseError(x: any, context: any = x): CiScanResultsResponseError {
  return {
    message: _atd_read_required_field('CiScanResultsResponseError', 'message', _atd_read_string, x['message'], x),
  };
}

export function writeCiScanComplete(x: CiScanComplete, context: any = x): any {
  return {
    'exit_code': _atd_write_required_field('CiScanComplete', 'exit_code', _atd_write_int, x.exit_code, x),
    'stats': _atd_write_required_field('CiScanComplete', 'stats', writeCiScanCompleteStats, x.stats, x),
    'dependencies': _atd_write_optional_field(writeCiScanDependencies, x.dependencies, x),
    'dependency_parser_errors': _atd_write_optional_field(_atd_write_array(writeDependencyParserError), x.dependency_parser_errors, x),
    'task_id': _atd_write_optional_field(_atd_write_string, x.task_id, x),
    'final_attempt': _atd_write_optional_field(_atd_write_bool, x.final_attempt, x),
  };
}

export function readCiScanComplete(x: any, context: any = x): CiScanComplete {
  return {
    exit_code: _atd_read_required_field('CiScanComplete', 'exit_code', _atd_read_int, x['exit_code'], x),
    stats: _atd_read_required_field('CiScanComplete', 'stats', readCiScanCompleteStats, x['stats'], x),
    dependencies: _atd_read_optional_field(readCiScanDependencies, x['dependencies'], x),
    dependency_parser_errors: _atd_read_optional_field(_atd_read_array(readDependencyParserError), x['dependency_parser_errors'], x),
    task_id: _atd_read_optional_field(_atd_read_string, x['task_id'], x),
    final_attempt: _atd_read_optional_field(_atd_read_bool, x['final_attempt'], x),
  };
}

export function writeCiScanCompleteStats(x: CiScanCompleteStats, context: any = x): any {
  return {
    'findings': _atd_write_required_field('CiScanCompleteStats', 'findings', _atd_write_int, x.findings, x),
    'errors': _atd_write_required_field('CiScanCompleteStats', 'errors', _atd_write_array(writeCliError), x.errors, x),
    'total_time': _atd_write_required_field('CiScanCompleteStats', 'total_time', _atd_write_float, x.total_time, x),
    'unsupported_exts': _atd_write_required_field('CiScanCompleteStats', 'unsupported_exts', _atd_write_assoc_map_to_object(_atd_write_int), x.unsupported_exts, x),
    'lockfile_scan_info': _atd_write_required_field('CiScanCompleteStats', 'lockfile_scan_info', _atd_write_assoc_map_to_object(_atd_write_int), x.lockfile_scan_info, x),
    'parse_rate': _atd_write_required_field('CiScanCompleteStats', 'parse_rate', _atd_write_assoc_map_to_object(writeParsingStats), x.parse_rate, x),
    'engine_requested': _atd_write_optional_field(_atd_write_string, x.engine_requested, x),
    'findings_by_product': _atd_write_optional_field(_atd_write_assoc_map_to_object(_atd_write_int), x.findings_by_product, x),
    'supply_chain_stats': _atd_write_optional_field(writeSupplyChainStats, x.supply_chain_stats, x),
  };
}

export function readCiScanCompleteStats(x: any, context: any = x): CiScanCompleteStats {
  return {
    findings: _atd_read_required_field('CiScanCompleteStats', 'findings', _atd_read_int, x['findings'], x),
    errors: _atd_read_required_field('CiScanCompleteStats', 'errors', _atd_read_array(readCliError), x['errors'], x),
    total_time: _atd_read_required_field('CiScanCompleteStats', 'total_time', _atd_read_float, x['total_time'], x),
    unsupported_exts: _atd_read_required_field('CiScanCompleteStats', 'unsupported_exts', _atd_read_assoc_object_into_map(_atd_read_int), x['unsupported_exts'], x),
    lockfile_scan_info: _atd_read_required_field('CiScanCompleteStats', 'lockfile_scan_info', _atd_read_assoc_object_into_map(_atd_read_int), x['lockfile_scan_info'], x),
    parse_rate: _atd_read_required_field('CiScanCompleteStats', 'parse_rate', _atd_read_assoc_object_into_map(readParsingStats), x['parse_rate'], x),
    engine_requested: _atd_read_optional_field(_atd_read_string, x['engine_requested'], x),
    findings_by_product: _atd_read_optional_field(_atd_read_assoc_object_into_map(_atd_read_int), x['findings_by_product'], x),
    supply_chain_stats: _atd_read_optional_field(readSupplyChainStats, x['supply_chain_stats'], x),
  };
}

export function writeParsingStats(x: ParsingStats, context: any = x): any {
  return {
    'targets_parsed': _atd_write_required_field('ParsingStats', 'targets_parsed', _atd_write_int, x.targets_parsed, x),
    'num_targets': _atd_write_required_field('ParsingStats', 'num_targets', _atd_write_int, x.num_targets, x),
    'bytes_parsed': _atd_write_required_field('ParsingStats', 'bytes_parsed', _atd_write_int, x.bytes_parsed, x),
    'num_bytes': _atd_write_required_field('ParsingStats', 'num_bytes', _atd_write_int, x.num_bytes, x),
  };
}

export function readParsingStats(x: any, context: any = x): ParsingStats {
  return {
    targets_parsed: _atd_read_required_field('ParsingStats', 'targets_parsed', _atd_read_int, x['targets_parsed'], x),
    num_targets: _atd_read_required_field('ParsingStats', 'num_targets', _atd_read_int, x['num_targets'], x),
    bytes_parsed: _atd_read_required_field('ParsingStats', 'bytes_parsed', _atd_read_int, x['bytes_parsed'], x),
    num_bytes: _atd_read_required_field('ParsingStats', 'num_bytes', _atd_read_int, x['num_bytes'], x),
  };
}

export function writeCiScanCompleteResponse(x: CiScanCompleteResponse, context: any = x): any {
  return {
    'success': _atd_write_required_field('CiScanCompleteResponse', 'success', _atd_write_bool, x.success, x),
    'app_block_override': _atd_write_field_with_default(_atd_write_bool, false, x.app_block_override, x),
    'app_block_reason': _atd_write_field_with_default(_atd_write_string, "", x.app_block_reason, x),
    'app_blocking_match_based_ids': _atd_write_field_with_default(_atd_write_array(writeMatchBasedId), [], x.app_blocking_match_based_ids, x),
  };
}

export function readCiScanCompleteResponse(x: any, context: any = x): CiScanCompleteResponse {
  return {
    success: _atd_read_required_field('CiScanCompleteResponse', 'success', _atd_read_bool, x['success'], x),
    app_block_override: _atd_read_field_with_default(_atd_read_bool, false, x['app_block_override'], x),
    app_block_reason: _atd_read_field_with_default(_atd_read_string, "", x['app_block_reason'], x),
    app_blocking_match_based_ids: _atd_read_field_with_default(_atd_read_array(readMatchBasedId), [], x['app_blocking_match_based_ids'], x),
  };
}

export function writeCiScanDependencies(x: CiScanDependencies, context: any = x): any {
  return _atd_write_assoc_map_to_object(_atd_write_array(writeFoundDependency))(x, context);
}

export function readCiScanDependencies(x: any, context: any = x): CiScanDependencies {
  return _atd_read_assoc_object_into_map(_atd_read_array(readFoundDependency))(x, context);
}

export function writeDependencyParserError(x: DependencyParserError, context: any = x): any {
  return {
    'path': _atd_write_required_field('DependencyParserError', 'path', writeFpath, x.path, x),
    'parser': _atd_write_required_field('DependencyParserError', 'parser', writeScaParserName, x.parser, x),
    'reason': _atd_write_required_field('DependencyParserError', 'reason', _atd_write_string, x.reason, x),
    'line': _atd_write_optional_field(_atd_write_int, x.line, x),
    'col': _atd_write_optional_field(_atd_write_int, x.col, x),
    'text': _atd_write_optional_field(_atd_write_string, x.text, x),
  };
}

export function readDependencyParserError(x: any, context: any = x): DependencyParserError {
  return {
    path: _atd_read_required_field('DependencyParserError', 'path', readFpath, x['path'], x),
    parser: _atd_read_required_field('DependencyParserError', 'parser', readScaParserName, x['parser'], x),
    reason: _atd_read_required_field('DependencyParserError', 'reason', _atd_read_string, x['reason'], x),
    line: _atd_read_optional_field(_atd_read_int, x['line'], x),
    col: _atd_read_optional_field(_atd_read_int, x['col'], x),
    text: _atd_read_optional_field(_atd_read_string, x['text'], x),
  };
}

export function writeScaParserName(x: ScaParserName, context: any = x): any {
  switch (x.kind) {
    case 'Gemfile_lock':
      return 'gemfile_lock'
    case 'Go_mod':
      return 'go_mod'
    case 'Go_sum':
      return 'go_sum'
    case 'Gradle_lockfile':
      return 'gradle_lockfile'
    case 'Gradle_build':
      return 'gradle_build'
    case 'Jsondoc':
      return 'jsondoc'
    case 'Pipfile':
      return 'pipfile'
    case 'Pnpm_lock':
      return 'pnpm_lock'
    case 'Poetry_lock':
      return 'poetry_lock'
    case 'Pyproject_toml':
      return 'pyproject_toml'
    case 'Requirements':
      return 'requirements'
    case 'Yarn_1':
      return 'yarn_1'
    case 'Yarn_2':
      return 'yarn_2'
    case 'Pomtree':
      return 'pomtree'
    case 'Cargo_parser':
      return 'cargo'
    case 'Composer_lock':
      return 'composer_lock'
    case 'Pubspec_lock':
      return 'pubspec_lock'
    case 'Package_swift':
      return 'package_swift'
    case 'Podfile_lock':
      return 'podfile_lock'
    case 'Package_resolved':
      return 'package_resolved'
    case 'Mix_lock':
      return 'mix_lock'
  }
}

export function readScaParserName(x: any, context: any = x): ScaParserName {
  switch (x) {
    case 'gemfile_lock':
      return { kind: 'Gemfile_lock' }
    case 'go_mod':
      return { kind: 'Go_mod' }
    case 'go_sum':
      return { kind: 'Go_sum' }
    case 'gradle_lockfile':
      return { kind: 'Gradle_lockfile' }
    case 'gradle_build':
      return { kind: 'Gradle_build' }
    case 'jsondoc':
      return { kind: 'Jsondoc' }
    case 'pipfile':
      return { kind: 'Pipfile' }
    case 'pnpm_lock':
      return { kind: 'Pnpm_lock' }
    case 'poetry_lock':
      return { kind: 'Poetry_lock' }
    case 'pyproject_toml':
      return { kind: 'Pyproject_toml' }
    case 'requirements':
      return { kind: 'Requirements' }
    case 'yarn_1':
      return { kind: 'Yarn_1' }
    case 'yarn_2':
      return { kind: 'Yarn_2' }
    case 'pomtree':
      return { kind: 'Pomtree' }
    case 'cargo':
      return { kind: 'Cargo_parser' }
    case 'composer_lock':
      return { kind: 'Composer_lock' }
    case 'pubspec_lock':
      return { kind: 'Pubspec_lock' }
    case 'package_swift':
      return { kind: 'Package_swift' }
    case 'podfile_lock':
      return { kind: 'Podfile_lock' }
    case 'package_resolved':
      return { kind: 'Package_resolved' }
    case 'mix_lock':
      return { kind: 'Mix_lock' }
    default:
      _atd_bad_json('ScaParserName', x, context)
      throw new Error('impossible')
  }
}

export function writeSupplyChainStats(x: SupplyChainStats, context: any = x): any {
  return {
    'subprojects_stats': _atd_write_required_field('SupplyChainStats', 'subprojects_stats', _atd_write_array(writeSubprojectStats), x.subprojects_stats, x),
  };
}

export function readSupplyChainStats(x: any, context: any = x): SupplyChainStats {
  return {
    subprojects_stats: _atd_read_required_field('SupplyChainStats', 'subprojects_stats', _atd_read_array(readSubprojectStats), x['subprojects_stats'], x),
  };
}

export function writeSubprojectStats(x: SubprojectStats, context: any = x): any {
  return {
    'subproject_id': _atd_write_required_field('SubprojectStats', 'subproject_id', _atd_write_string, x.subproject_id, x),
    'dependency_sources': _atd_write_required_field('SubprojectStats', 'dependency_sources', _atd_write_array(writeDependencySourceFile), x.dependency_sources, x),
    'resolved_stats': _atd_write_optional_field(writeDependencyResolutionStats, x.resolved_stats, x),
  };
}

export function readSubprojectStats(x: any, context: any = x): SubprojectStats {
  return {
    subproject_id: _atd_read_required_field('SubprojectStats', 'subproject_id', _atd_read_string, x['subproject_id'], x),
    dependency_sources: _atd_read_required_field('SubprojectStats', 'dependency_sources', _atd_read_array(readDependencySourceFile), x['dependency_sources'], x),
    resolved_stats: _atd_read_optional_field(readDependencyResolutionStats, x['resolved_stats'], x),
  };
}

export function writeDependencySourceFile(x: DependencySourceFile, context: any = x): any {
  return {
    'kind': _atd_write_required_field('DependencySourceFile', 'kind', writeDependencySourceFileKind, x.kind, x),
    'path': _atd_write_required_field('DependencySourceFile', 'path', writeFpath, x.path, x),
  };
}

export function readDependencySourceFile(x: any, context: any = x): DependencySourceFile {
  return {
    kind: _atd_read_required_field('DependencySourceFile', 'kind', readDependencySourceFileKind, x['kind'], x),
    path: _atd_read_required_field('DependencySourceFile', 'path', readFpath, x['path'], x),
  };
}

export function writeDependencySourceFileKind(x: DependencySourceFileKind, context: any = x): any {
  switch (x.kind) {
    case 'Lockfile':
      return ['Lockfile', writeLockfileKind(x.value, x)]
    case 'Manifest':
      return ['Manifest', writeManifestKind(x.value, x)]
  }
}

export function readDependencySourceFileKind(x: any, context: any = x): DependencySourceFileKind {
  _atd_check_json_tuple(2, x, context)
  switch (x[0]) {
    case 'Lockfile':
      return { kind: 'Lockfile', value: readLockfileKind(x[1], x) }
    case 'Manifest':
      return { kind: 'Manifest', value: readManifestKind(x[1], x) }
    default:
      _atd_bad_json('DependencySourceFileKind', x, context)
      throw new Error('impossible')
  }
}

export function writeDependencyResolutionStats(x: DependencyResolutionStats, context: any = x): any {
  return {
    'resolution_method': _atd_write_required_field('DependencyResolutionStats', 'resolution_method', writeResolutionMethod, x.resolution_method, x),
    'dependency_count': _atd_write_required_field('DependencyResolutionStats', 'dependency_count', _atd_write_int, x.dependency_count, x),
    'ecosystem': _atd_write_required_field('DependencyResolutionStats', 'ecosystem', writeEcosystem, x.ecosystem, x),
  };
}

export function readDependencyResolutionStats(x: any, context: any = x): DependencyResolutionStats {
  return {
    resolution_method: _atd_read_required_field('DependencyResolutionStats', 'resolution_method', readResolutionMethod, x['resolution_method'], x),
    dependency_count: _atd_read_required_field('DependencyResolutionStats', 'dependency_count', _atd_read_int, x['dependency_count'], x),
    ecosystem: _atd_read_required_field('DependencyResolutionStats', 'ecosystem', readEcosystem, x['ecosystem'], x),
  };
}

export function writeResolutionMethod(x: ResolutionMethod, context: any = x): any {
  switch (x.kind) {
    case 'LockfileParsing':
      return 'LockfileParsing'
    case 'DynamicResolution':
      return 'DynamicResolution'
  }
}

export function readResolutionMethod(x: any, context: any = x): ResolutionMethod {
  switch (x) {
    case 'LockfileParsing':
      return { kind: 'LockfileParsing' }
    case 'DynamicResolution':
      return { kind: 'DynamicResolution' }
    default:
      _atd_bad_json('ResolutionMethod', x, context)
      throw new Error('impossible')
  }
}

export function writeCiScanFailure(x: CiScanFailure, context: any = x): any {
  return {
    'exit_code': _atd_write_required_field('CiScanFailure', 'exit_code', _atd_write_int, x.exit_code, x),
    'stderr': _atd_write_required_field('CiScanFailure', 'stderr', _atd_write_string, x.stderr, x),
  };
}

export function readCiScanFailure(x: any, context: any = x): CiScanFailure {
  return {
    exit_code: _atd_read_required_field('CiScanFailure', 'exit_code', _atd_read_int, x['exit_code'], x),
    stderr: _atd_read_required_field('CiScanFailure', 'stderr', _atd_read_string, x['stderr'], x),
  };
}

export function writeDeploymentConfig(x: DeploymentConfig, context: any = x): any {
  return {
    'id': _atd_write_required_field('DeploymentConfig', 'id', _atd_write_int, x.id, x),
    'name': _atd_write_required_field('DeploymentConfig', 'name', _atd_write_string, x.name, x),
    'organization_id': _atd_write_field_with_default(_atd_write_int, 0, x.organization_id, x),
    'display_name': _atd_write_field_with_default(_atd_write_string, "", x.display_name, x),
    'scm_name': _atd_write_field_with_default(_atd_write_string, "", x.scm_name, x),
    'slug': _atd_write_field_with_default(_atd_write_string, "", x.slug, x),
    'source_type': _atd_write_field_with_default(_atd_write_string, "", x.source_type, x),
    'default_user_role': _atd_write_field_with_default(_atd_write_string, "", x.default_user_role, x),
    'has_autofix': _atd_write_field_with_default(_atd_write_bool, false, x.has_autofix, x),
    'has_deepsemgrep': _atd_write_field_with_default(_atd_write_bool, false, x.has_deepsemgrep, x),
    'has_triage_via_comment': _atd_write_field_with_default(_atd_write_bool, false, x.has_triage_via_comment, x),
    'has_dependency_query': _atd_write_field_with_default(_atd_write_bool, false, x.has_dependency_query, x),
  };
}

export function readDeploymentConfig(x: any, context: any = x): DeploymentConfig {
  return {
    id: _atd_read_required_field('DeploymentConfig', 'id', _atd_read_int, x['id'], x),
    name: _atd_read_required_field('DeploymentConfig', 'name', _atd_read_string, x['name'], x),
    organization_id: _atd_read_field_with_default(_atd_read_int, 0, x['organization_id'], x),
    display_name: _atd_read_field_with_default(_atd_read_string, "", x['display_name'], x),
    scm_name: _atd_read_field_with_default(_atd_read_string, "", x['scm_name'], x),
    slug: _atd_read_field_with_default(_atd_read_string, "", x['slug'], x),
    source_type: _atd_read_field_with_default(_atd_read_string, "", x['source_type'], x),
    default_user_role: _atd_read_field_with_default(_atd_read_string, "", x['default_user_role'], x),
    has_autofix: _atd_read_field_with_default(_atd_read_bool, false, x['has_autofix'], x),
    has_deepsemgrep: _atd_read_field_with_default(_atd_read_bool, false, x['has_deepsemgrep'], x),
    has_triage_via_comment: _atd_read_field_with_default(_atd_read_bool, false, x['has_triage_via_comment'], x),
    has_dependency_query: _atd_read_field_with_default(_atd_read_bool, false, x['has_dependency_query'], x),
  };
}

export function writeHasFeatures(x: HasFeatures, context: any = x): any {
  return {
    'has_autofix': _atd_write_field_with_default(_atd_write_bool, false, x.has_autofix, x),
    'has_deepsemgrep': _atd_write_field_with_default(_atd_write_bool, false, x.has_deepsemgrep, x),
    'has_triage_via_comment': _atd_write_field_with_default(_atd_write_bool, false, x.has_triage_via_comment, x),
    'has_dependency_query': _atd_write_field_with_default(_atd_write_bool, false, x.has_dependency_query, x),
  };
}

export function readHasFeatures(x: any, context: any = x): HasFeatures {
  return {
    has_autofix: _atd_read_field_with_default(_atd_read_bool, false, x['has_autofix'], x),
    has_deepsemgrep: _atd_read_field_with_default(_atd_read_bool, false, x['has_deepsemgrep'], x),
    has_triage_via_comment: _atd_read_field_with_default(_atd_read_bool, false, x['has_triage_via_comment'], x),
    has_dependency_query: _atd_read_field_with_default(_atd_read_bool, false, x['has_dependency_query'], x),
  };
}

export function writeDeploymentResponse(x: DeploymentResponse, context: any = x): any {
  return {
    'deployment': _atd_write_required_field('DeploymentResponse', 'deployment', writeDeploymentConfig, x.deployment, x),
  };
}

export function readDeploymentResponse(x: any, context: any = x): DeploymentResponse {
  return {
    deployment: _atd_read_required_field('DeploymentResponse', 'deployment', readDeploymentConfig, x['deployment'], x),
  };
}

export function writeScanConfig(x: ScanConfig, context: any = x): any {
  return {
    'deployment_id': _atd_write_required_field('ScanConfig', 'deployment_id', _atd_write_int, x.deployment_id, x),
    'deployment_name': _atd_write_required_field('ScanConfig', 'deployment_name', _atd_write_string, x.deployment_name, x),
    'policy_names': _atd_write_required_field('ScanConfig', 'policy_names', _atd_write_array(_atd_write_string), x.policy_names, x),
    'rule_config': _atd_write_required_field('ScanConfig', 'rule_config', _atd_write_string, x.rule_config, x),
    'autofix': _atd_write_field_with_default(_atd_write_bool, false, x.autofix, x),
    'deepsemgrep': _atd_write_field_with_default(_atd_write_bool, false, x.deepsemgrep, x),
    'dependency_query': _atd_write_field_with_default(_atd_write_bool, false, x.dependency_query, x),
    'path_to_transitivity': _atd_write_field_with_default(_atd_write_bool, false, x.path_to_transitivity, x),
    'scan_all_deps_in_diff_scan': _atd_write_field_with_default(_atd_write_bool, false, x.scan_all_deps_in_diff_scan, x),
    'triage_ignored_syntactic_ids': _atd_write_field_with_default(_atd_write_array(_atd_write_string), [], x.triage_ignored_syntactic_ids, x),
    'triage_ignored_match_based_ids': _atd_write_field_with_default(_atd_write_array(_atd_write_string), [], x.triage_ignored_match_based_ids, x),
    'ignored_files': _atd_write_field_with_default(_atd_write_array(_atd_write_string), [], x.ignored_files, x),
    'enabled_products': _atd_write_optional_field(_atd_write_array(writeProduct), x.enabled_products, x),
    'actions': _atd_write_field_with_default(_atd_write_array(writeAction), [], x.actions, x),
    'ci_config_from_cloud': _atd_write_optional_field(writeCiConfigFromCloud, x.ci_config_from_cloud, x),
  };
}

export function readScanConfig(x: any, context: any = x): ScanConfig {
  return {
    deployment_id: _atd_read_required_field('ScanConfig', 'deployment_id', _atd_read_int, x['deployment_id'], x),
    deployment_name: _atd_read_required_field('ScanConfig', 'deployment_name', _atd_read_string, x['deployment_name'], x),
    policy_names: _atd_read_required_field('ScanConfig', 'policy_names', _atd_read_array(_atd_read_string), x['policy_names'], x),
    rule_config: _atd_read_required_field('ScanConfig', 'rule_config', _atd_read_string, x['rule_config'], x),
    autofix: _atd_read_field_with_default(_atd_read_bool, false, x['autofix'], x),
    deepsemgrep: _atd_read_field_with_default(_atd_read_bool, false, x['deepsemgrep'], x),
    dependency_query: _atd_read_field_with_default(_atd_read_bool, false, x['dependency_query'], x),
    path_to_transitivity: _atd_read_field_with_default(_atd_read_bool, false, x['path_to_transitivity'], x),
    scan_all_deps_in_diff_scan: _atd_read_field_with_default(_atd_read_bool, false, x['scan_all_deps_in_diff_scan'], x),
    triage_ignored_syntactic_ids: _atd_read_field_with_default(_atd_read_array(_atd_read_string), [], x['triage_ignored_syntactic_ids'], x),
    triage_ignored_match_based_ids: _atd_read_field_with_default(_atd_read_array(_atd_read_string), [], x['triage_ignored_match_based_ids'], x),
    ignored_files: _atd_read_field_with_default(_atd_read_array(_atd_read_string), [], x['ignored_files'], x),
    enabled_products: _atd_read_optional_field(_atd_read_array(readProduct), x['enabled_products'], x),
    actions: _atd_read_field_with_default(_atd_read_array(readAction), [], x['actions'], x),
    ci_config_from_cloud: _atd_read_optional_field(readCiConfigFromCloud, x['ci_config_from_cloud'], x),
  };
}

export function writeCiConfigFromCloud(x: CiConfigFromCloud, context: any = x): any {
  return {
    'repo_config': _atd_write_required_field('CiConfigFromCloud', 'repo_config', writeCiConfig, x.repo_config, x),
    'org_config': _atd_write_optional_field(writeCiConfig, x.org_config, x),
    'dirs_config': _atd_write_optional_field(_atd_write_array(((x, context) => [writeFpath(x[0], x), writeCiConfig(x[1], x)])), x.dirs_config, x),
    'actions': _atd_write_field_with_default(_atd_write_array(writeAction), [], x.actions, x),
  };
}

export function readCiConfigFromCloud(x: any, context: any = x): CiConfigFromCloud {
  return {
    repo_config: _atd_read_required_field('CiConfigFromCloud', 'repo_config', readCiConfig, x['repo_config'], x),
    org_config: _atd_read_optional_field(readCiConfig, x['org_config'], x),
    dirs_config: _atd_read_optional_field(_atd_read_array(((x, context): [Fpath, CiConfig] => { _atd_check_json_tuple(2, x, context); return [readFpath(x[0], x), readCiConfig(x[1], x)] })), x['dirs_config'], x),
    actions: _atd_read_field_with_default(_atd_read_array(readAction), [], x['actions'], x),
  };
}

export function writeCiConfig(x: CiConfig, context: any = x): any {
  return {
    'env': _atd_write_required_field('CiConfig', 'env', writeCiEnv, x.env, x),
    'enabled_products': _atd_write_required_field('CiConfig', 'enabled_products', _atd_write_array(writeProduct), x.enabled_products, x),
    'ignored_files': _atd_write_required_field('CiConfig', 'ignored_files', _atd_write_array(_atd_write_string), x.ignored_files, x),
    'autofix': _atd_write_field_with_default(_atd_write_bool, false, x.autofix, x),
    'deepsemgrep': _atd_write_field_with_default(_atd_write_bool, false, x.deepsemgrep, x),
    'dependency_query': _atd_write_field_with_default(_atd_write_bool, false, x.dependency_query, x),
    'path_to_transitivity': _atd_write_field_with_default(_atd_write_bool, false, x.path_to_transitivity, x),
    'scan_all_deps_in_diff_scan': _atd_write_field_with_default(_atd_write_bool, false, x.scan_all_deps_in_diff_scan, x),
  };
}

export function readCiConfig(x: any, context: any = x): CiConfig {
  return {
    env: _atd_read_required_field('CiConfig', 'env', readCiEnv, x['env'], x),
    enabled_products: _atd_read_required_field('CiConfig', 'enabled_products', _atd_read_array(readProduct), x['enabled_products'], x),
    ignored_files: _atd_read_required_field('CiConfig', 'ignored_files', _atd_read_array(_atd_read_string), x['ignored_files'], x),
    autofix: _atd_read_field_with_default(_atd_read_bool, false, x['autofix'], x),
    deepsemgrep: _atd_read_field_with_default(_atd_read_bool, false, x['deepsemgrep'], x),
    dependency_query: _atd_read_field_with_default(_atd_read_bool, false, x['dependency_query'], x),
    path_to_transitivity: _atd_read_field_with_default(_atd_read_bool, false, x['path_to_transitivity'], x),
    scan_all_deps_in_diff_scan: _atd_read_field_with_default(_atd_read_bool, false, x['scan_all_deps_in_diff_scan'], x),
  };
}

export function writeCiEnv(x: CiEnv, context: any = x): any {
  return _atd_write_assoc_map_to_object(_atd_write_string)(x, context);
}

export function readCiEnv(x: any, context: any = x): CiEnv {
  return _atd_read_assoc_object_into_map(_atd_read_string)(x, context);
}

export function writeCoreOutput(x: CoreOutput, context: any = x): any {
  return {
    'version': _atd_write_required_field('CoreOutput', 'version', writeVersion, x.version, x),
    'results': _atd_write_required_field('CoreOutput', 'results', _atd_write_array(writeCoreMatch), x.results, x),
    'errors': _atd_write_required_field('CoreOutput', 'errors', _atd_write_array(writeCoreError), x.errors, x),
    'paths': _atd_write_required_field('CoreOutput', 'paths', writeScannedAndSkipped, x.paths, x),
    'time': _atd_write_optional_field(writeProfile, x.time, x),
    'explanations': _atd_write_optional_field(_atd_write_array(writeMatchingExplanation), x.explanations, x),
    'rules_by_engine': _atd_write_optional_field(_atd_write_array(writeRuleIdAndEngineKind), x.rules_by_engine, x),
    'engine_requested': _atd_write_optional_field(writeEngineKind, x.engine_requested, x),
    'interfile_languages_used': _atd_write_optional_field(_atd_write_array(_atd_write_string), x.interfile_languages_used, x),
    'skipped_rules': _atd_write_field_with_default(_atd_write_array(writeSkippedRule), [], x.skipped_rules, x),
  };
}

export function readCoreOutput(x: any, context: any = x): CoreOutput {
  return {
    version: _atd_read_required_field('CoreOutput', 'version', readVersion, x['version'], x),
    results: _atd_read_required_field('CoreOutput', 'results', _atd_read_array(readCoreMatch), x['results'], x),
    errors: _atd_read_required_field('CoreOutput', 'errors', _atd_read_array(readCoreError), x['errors'], x),
    paths: _atd_read_required_field('CoreOutput', 'paths', readScannedAndSkipped, x['paths'], x),
    time: _atd_read_optional_field(readProfile, x['time'], x),
    explanations: _atd_read_optional_field(_atd_read_array(readMatchingExplanation), x['explanations'], x),
    rules_by_engine: _atd_read_optional_field(_atd_read_array(readRuleIdAndEngineKind), x['rules_by_engine'], x),
    engine_requested: _atd_read_optional_field(readEngineKind, x['engine_requested'], x),
    interfile_languages_used: _atd_read_optional_field(_atd_read_array(_atd_read_string), x['interfile_languages_used'], x),
    skipped_rules: _atd_read_field_with_default(_atd_read_array(readSkippedRule), [], x['skipped_rules'], x),
  };
}

export function writeCoreMatch(x: CoreMatch, context: any = x): any {
  return {
    'check_id': _atd_write_required_field('CoreMatch', 'check_id', writeRuleId, x.check_id, x),
    'path': _atd_write_required_field('CoreMatch', 'path', writeFpath, x.path, x),
    'start': _atd_write_required_field('CoreMatch', 'start', writePosition, x.start, x),
    'end': _atd_write_required_field('CoreMatch', 'end', writePosition, x.end, x),
    'extra': _atd_write_required_field('CoreMatch', 'extra', writeCoreMatchExtra, x.extra, x),
  };
}

export function readCoreMatch(x: any, context: any = x): CoreMatch {
  return {
    check_id: _atd_read_required_field('CoreMatch', 'check_id', readRuleId, x['check_id'], x),
    path: _atd_read_required_field('CoreMatch', 'path', readFpath, x['path'], x),
    start: _atd_read_required_field('CoreMatch', 'start', readPosition, x['start'], x),
    end: _atd_read_required_field('CoreMatch', 'end', readPosition, x['end'], x),
    extra: _atd_read_required_field('CoreMatch', 'extra', readCoreMatchExtra, x['extra'], x),
  };
}

export function writeCoreMatchExtra(x: CoreMatchExtra, context: any = x): any {
  return {
    'metavars': _atd_write_required_field('CoreMatchExtra', 'metavars', writeMetavars, x.metavars, x),
    'engine_kind': _atd_write_required_field('CoreMatchExtra', 'engine_kind', writeEngineOfFinding, x.engine_kind, x),
    'is_ignored': _atd_write_required_field('CoreMatchExtra', 'is_ignored', _atd_write_bool, x.is_ignored, x),
    'message': _atd_write_optional_field(_atd_write_string, x.message, x),
    'metadata': _atd_write_optional_field(writeRawJson, x.metadata, x),
    'severity': _atd_write_optional_field(writeMatchSeverity, x.severity, x),
    'fix': _atd_write_optional_field(_atd_write_string, x.fix, x),
    'dataflow_trace': _atd_write_optional_field(writeMatchDataflowTrace, x.dataflow_trace, x),
    'sca_match': _atd_write_optional_field(writeScaMatch, x.sca_match, x),
    'validation_state': _atd_write_optional_field(writeValidationState, x.validation_state, x),
    'historical_info': _atd_write_optional_field(writeHistoricalInfo, x.historical_info, x),
    'extra_extra': _atd_write_optional_field(writeRawJson, x.extra_extra, x),
  };
}

export function readCoreMatchExtra(x: any, context: any = x): CoreMatchExtra {
  return {
    metavars: _atd_read_required_field('CoreMatchExtra', 'metavars', readMetavars, x['metavars'], x),
    engine_kind: _atd_read_required_field('CoreMatchExtra', 'engine_kind', readEngineOfFinding, x['engine_kind'], x),
    is_ignored: _atd_read_required_field('CoreMatchExtra', 'is_ignored', _atd_read_bool, x['is_ignored'], x),
    message: _atd_read_optional_field(_atd_read_string, x['message'], x),
    metadata: _atd_read_optional_field(readRawJson, x['metadata'], x),
    severity: _atd_read_optional_field(readMatchSeverity, x['severity'], x),
    fix: _atd_read_optional_field(_atd_read_string, x['fix'], x),
    dataflow_trace: _atd_read_optional_field(readMatchDataflowTrace, x['dataflow_trace'], x),
    sca_match: _atd_read_optional_field(readScaMatch, x['sca_match'], x),
    validation_state: _atd_read_optional_field(readValidationState, x['validation_state'], x),
    historical_info: _atd_read_optional_field(readHistoricalInfo, x['historical_info'], x),
    extra_extra: _atd_read_optional_field(readRawJson, x['extra_extra'], x),
  };
}

export function writeCoreError(x: CoreError, context: any = x): any {
  return {
    'error_type': _atd_write_required_field('CoreError', 'error_type', writeErrorType, x.error_type, x),
    'severity': _atd_write_required_field('CoreError', 'severity', writeErrorSeverity, x.severity, x),
    'message': _atd_write_required_field('CoreError', 'message', _atd_write_string, x.message, x),
    'details': _atd_write_optional_field(_atd_write_string, x.details, x),
    'location': _atd_write_optional_field(writeLocation, x.location, x),
    'rule_id': _atd_write_optional_field(writeRuleId, x.rule_id, x),
  };
}

export function readCoreError(x: any, context: any = x): CoreError {
  return {
    error_type: _atd_read_required_field('CoreError', 'error_type', readErrorType, x['error_type'], x),
    severity: _atd_read_required_field('CoreError', 'severity', readErrorSeverity, x['severity'], x),
    message: _atd_read_required_field('CoreError', 'message', _atd_read_string, x['message'], x),
    details: _atd_read_optional_field(_atd_read_string, x['details'], x),
    location: _atd_read_optional_field(readLocation, x['location'], x),
    rule_id: _atd_read_optional_field(readRuleId, x['rule_id'], x),
  };
}

export function writeAnalyzer(x: Analyzer, context: any = x): any {
  return _atd_write_string(x, context);
}

export function readAnalyzer(x: any, context: any = x): Analyzer {
  return _atd_read_string(x, context);
}

export function writeTarget(x: Target, context: any = x): any {
  switch (x.kind) {
    case 'CodeTarget':
      return ['CodeTarget', writeCodeTarget(x.value, x)]
    case 'LockfileTarget':
      return ['LockfileTarget', writeLockfile(x.value, x)]
  }
}

export function readTarget(x: any, context: any = x): Target {
  _atd_check_json_tuple(2, x, context)
  switch (x[0]) {
    case 'CodeTarget':
      return { kind: 'CodeTarget', value: readCodeTarget(x[1], x) }
    case 'LockfileTarget':
      return { kind: 'LockfileTarget', value: readLockfile(x[1], x) }
    default:
      _atd_bad_json('Target', x, context)
      throw new Error('impossible')
  }
}

export function writeCodeTarget(x: CodeTarget, context: any = x): any {
  return {
    'path': _atd_write_required_field('CodeTarget', 'path', writeFpath, x.path, x),
    'analyzer': _atd_write_required_field('CodeTarget', 'analyzer', writeAnalyzer, x.analyzer, x),
    'products': _atd_write_required_field('CodeTarget', 'products', _atd_write_array(writeProduct), x.products, x),
    'lockfile_target': _atd_write_optional_field(writeLockfile, x.lockfile_target, x),
  };
}

export function readCodeTarget(x: any, context: any = x): CodeTarget {
  return {
    path: _atd_read_required_field('CodeTarget', 'path', readFpath, x['path'], x),
    analyzer: _atd_read_required_field('CodeTarget', 'analyzer', readAnalyzer, x['analyzer'], x),
    products: _atd_read_required_field('CodeTarget', 'products', _atd_read_array(readProduct), x['products'], x),
    lockfile_target: _atd_read_optional_field(readLockfile, x['lockfile_target'], x),
  };
}

export function writeTargets(x: Targets, context: any = x): any {
  return _atd_write_array(writeTarget)(x, context);
}

export function readTargets(x: any, context: any = x): Targets {
  return _atd_read_array(readTarget)(x, context);
}

export function writeEdit(x: Edit, context: any = x): any {
  return {
    'path': _atd_write_required_field('Edit', 'path', writeFpath, x.path, x),
    'start_offset': _atd_write_required_field('Edit', 'start_offset', _atd_write_int, x.start_offset, x),
    'end_offset': _atd_write_required_field('Edit', 'end_offset', _atd_write_int, x.end_offset, x),
    'replacement_text': _atd_write_required_field('Edit', 'replacement_text', _atd_write_string, x.replacement_text, x),
  };
}

export function readEdit(x: any, context: any = x): Edit {
  return {
    path: _atd_read_required_field('Edit', 'path', readFpath, x['path'], x),
    start_offset: _atd_read_required_field('Edit', 'start_offset', _atd_read_int, x['start_offset'], x),
    end_offset: _atd_read_required_field('Edit', 'end_offset', _atd_read_int, x['end_offset'], x),
    replacement_text: _atd_read_required_field('Edit', 'replacement_text', _atd_read_string, x['replacement_text'], x),
  };
}

export function writeApplyFixesParams(x: ApplyFixesParams, context: any = x): any {
  return {
    'dryrun': _atd_write_required_field('ApplyFixesParams', 'dryrun', _atd_write_bool, x.dryrun, x),
    'edits': _atd_write_required_field('ApplyFixesParams', 'edits', _atd_write_array(writeEdit), x.edits, x),
  };
}

export function readApplyFixesParams(x: any, context: any = x): ApplyFixesParams {
  return {
    dryrun: _atd_read_required_field('ApplyFixesParams', 'dryrun', _atd_read_bool, x['dryrun'], x),
    edits: _atd_read_required_field('ApplyFixesParams', 'edits', _atd_read_array(readEdit), x['edits'], x),
  };
}

export function writeApplyFixesReturn(x: ApplyFixesReturn, context: any = x): any {
  return {
    'modified_file_count': _atd_write_required_field('ApplyFixesReturn', 'modified_file_count', _atd_write_int, x.modified_file_count, x),
    'fixed_lines': _atd_write_required_field('ApplyFixesReturn', 'fixed_lines', _atd_write_array(((x, context) => [_atd_write_int(x[0], x), _atd_write_array(_atd_write_string)(x[1], x)])), x.fixed_lines, x),
  };
}

export function readApplyFixesReturn(x: any, context: any = x): ApplyFixesReturn {
  return {
    modified_file_count: _atd_read_required_field('ApplyFixesReturn', 'modified_file_count', _atd_read_int, x['modified_file_count'], x),
    fixed_lines: _atd_read_required_field('ApplyFixesReturn', 'fixed_lines', _atd_read_array(((x, context): [number /*int*/, string[]] => { _atd_check_json_tuple(2, x, context); return [_atd_read_int(x[0], x), _atd_read_array(_atd_read_string)(x[1], x)] })), x['fixed_lines'], x),
  };
}

export function writeSarifFormat(x: SarifFormat, context: any = x): any {
  return {
    'rules': _atd_write_required_field('SarifFormat', 'rules', writeFpath, x.rules, x),
    'is_pro': _atd_write_required_field('SarifFormat', 'is_pro', _atd_write_bool, x.is_pro, x),
    'show_dataflow_traces': _atd_write_required_field('SarifFormat', 'show_dataflow_traces', _atd_write_bool, x.show_dataflow_traces, x),
  };
}

export function readSarifFormat(x: any, context: any = x): SarifFormat {
  return {
    rules: _atd_read_required_field('SarifFormat', 'rules', readFpath, x['rules'], x),
    is_pro: _atd_read_required_field('SarifFormat', 'is_pro', _atd_read_bool, x['is_pro'], x),
    show_dataflow_traces: _atd_read_required_field('SarifFormat', 'show_dataflow_traces', _atd_read_bool, x['show_dataflow_traces'], x),
  };
}

export function writeOutputFormat(x: OutputFormat, context: any = x): any {
  switch (x.kind) {
    case 'Text':
      return 'Text'
    case 'Json':
      return 'Json'
    case 'Emacs':
      return 'Emacs'
    case 'Vim':
      return 'Vim'
    case 'Sarif':
      return 'Sarif'
    case 'Gitlab_sast':
      return 'Gitlab_sast'
    case 'Gitlab_secrets':
      return 'Gitlab_secrets'
    case 'Junit_xml':
      return 'Junit_xml'
    case 'Files_with_matches':
      return 'Files_with_matches'
    case 'Incremental':
      return 'Incremental'
  }
}

export function readOutputFormat(x: any, context: any = x): OutputFormat {
  switch (x) {
    case 'Text':
      return { kind: 'Text' }
    case 'Json':
      return { kind: 'Json' }
    case 'Emacs':
      return { kind: 'Emacs' }
    case 'Vim':
      return { kind: 'Vim' }
    case 'Sarif':
      return { kind: 'Sarif' }
    case 'Gitlab_sast':
      return { kind: 'Gitlab_sast' }
    case 'Gitlab_secrets':
      return { kind: 'Gitlab_secrets' }
    case 'Junit_xml':
      return { kind: 'Junit_xml' }
    case 'Files_with_matches':
      return { kind: 'Files_with_matches' }
    case 'Incremental':
      return { kind: 'Incremental' }
    default:
      _atd_bad_json('OutputFormat', x, context)
      throw new Error('impossible')
  }
}

export function writeFormatContext(x: FormatContext, context: any = x): any {
  return {
    'is_ci_invocation': _atd_write_required_field('FormatContext', 'is_ci_invocation', _atd_write_bool, x.is_ci_invocation, x),
    'is_logged_in': _atd_write_required_field('FormatContext', 'is_logged_in', _atd_write_bool, x.is_logged_in, x),
    'is_using_registry': _atd_write_required_field('FormatContext', 'is_using_registry', _atd_write_bool, x.is_using_registry, x),
  };
}

export function readFormatContext(x: any, context: any = x): FormatContext {
  return {
    is_ci_invocation: _atd_read_required_field('FormatContext', 'is_ci_invocation', _atd_read_bool, x['is_ci_invocation'], x),
    is_logged_in: _atd_read_required_field('FormatContext', 'is_logged_in', _atd_read_bool, x['is_logged_in'], x),
    is_using_registry: _atd_read_required_field('FormatContext', 'is_using_registry', _atd_read_bool, x['is_using_registry'], x),
  };
}

export function writeDumpRulePartitionsParams(x: DumpRulePartitionsParams, context: any = x): any {
  return {
    'rules': _atd_write_required_field('DumpRulePartitionsParams', 'rules', writeRawJson, x.rules, x),
    'n_partitions': _atd_write_required_field('DumpRulePartitionsParams', 'n_partitions', _atd_write_int, x.n_partitions, x),
    'output_dir': _atd_write_required_field('DumpRulePartitionsParams', 'output_dir', writeFpath, x.output_dir, x),
  };
}

export function readDumpRulePartitionsParams(x: any, context: any = x): DumpRulePartitionsParams {
  return {
    rules: _atd_read_required_field('DumpRulePartitionsParams', 'rules', readRawJson, x['rules'], x),
    n_partitions: _atd_read_required_field('DumpRulePartitionsParams', 'n_partitions', _atd_read_int, x['n_partitions'], x),
    output_dir: _atd_read_required_field('DumpRulePartitionsParams', 'output_dir', readFpath, x['output_dir'], x),
  };
}

export function writeLockfileKind(x: LockfileKind, context: any = x): any {
  switch (x.kind) {
    case 'PipRequirementsTxt':
      return 'PipRequirementsTxt'
    case 'PoetryLock':
      return 'PoetryLock'
    case 'PipfileLock':
      return 'PipfileLock'
    case 'UvLock':
      return 'UvLock'
    case 'NpmPackageLockJson':
      return 'NpmPackageLockJson'
    case 'YarnLock':
      return 'YarnLock'
    case 'PnpmLock':
      return 'PnpmLock'
    case 'GemfileLock':
      return 'GemfileLock'
    case 'GoMod':
      return 'GoMod'
    case 'CargoLock':
      return 'CargoLock'
    case 'MavenDepTree':
      return 'MavenDepTree'
    case 'GradleLockfile':
      return 'GradleLockfile'
    case 'ComposerLock':
      return 'ComposerLock'
    case 'NugetPackagesLockJson':
      return 'NugetPackagesLockJson'
    case 'PubspecLock':
      return 'PubspecLock'
    case 'SwiftPackageResolved':
      return 'SwiftPackageResolved'
    case 'PodfileLock':
      return 'PodfileLock'
    case 'MixLock':
      return 'MixLock'
    case 'ConanLock':
      return 'ConanLock'
  }
}

export function readLockfileKind(x: any, context: any = x): LockfileKind {
  switch (x) {
    case 'PipRequirementsTxt':
      return { kind: 'PipRequirementsTxt' }
    case 'PoetryLock':
      return { kind: 'PoetryLock' }
    case 'PipfileLock':
      return { kind: 'PipfileLock' }
    case 'UvLock':
      return { kind: 'UvLock' }
    case 'NpmPackageLockJson':
      return { kind: 'NpmPackageLockJson' }
    case 'YarnLock':
      return { kind: 'YarnLock' }
    case 'PnpmLock':
      return { kind: 'PnpmLock' }
    case 'GemfileLock':
      return { kind: 'GemfileLock' }
    case 'GoMod':
      return { kind: 'GoMod' }
    case 'CargoLock':
      return { kind: 'CargoLock' }
    case 'MavenDepTree':
      return { kind: 'MavenDepTree' }
    case 'GradleLockfile':
      return { kind: 'GradleLockfile' }
    case 'ComposerLock':
      return { kind: 'ComposerLock' }
    case 'NugetPackagesLockJson':
      return { kind: 'NugetPackagesLockJson' }
    case 'PubspecLock':
      return { kind: 'PubspecLock' }
    case 'SwiftPackageResolved':
      return { kind: 'SwiftPackageResolved' }
    case 'PodfileLock':
      return { kind: 'PodfileLock' }
    case 'MixLock':
      return { kind: 'MixLock' }
    case 'ConanLock':
      return { kind: 'ConanLock' }
    default:
      _atd_bad_json('LockfileKind', x, context)
      throw new Error('impossible')
  }
}

export function writeManifestKind(x: ManifestKind, context: any = x): any {
  switch (x.kind) {
    case 'RequirementsIn':
      return 'RequirementsIn'
    case 'PackageJson':
      return 'PackageJson'
    case 'Gemfile':
      return 'Gemfile'
    case 'GoMod':
      return 'GoMod'
    case 'CargoToml':
      return 'CargoToml'
    case 'PomXml':
      return 'PomXml'
    case 'BuildGradle':
      return 'BuildGradle'
    case 'SettingsGradle':
      return 'SettingsGradle'
    case 'ComposerJson':
      return 'ComposerJson'
    case 'NugetManifestJson':
      return 'NugetManifestJson'
    case 'PubspecYaml':
      return 'PubspecYaml'
    case 'PackageSwift':
      return 'PackageSwift'
    case 'Podfile':
      return 'Podfile'
    case 'MixExs':
      return 'MixExs'
    case 'Pipfile':
      return 'Pipfile'
    case 'PyprojectToml':
      return 'PyprojectToml'
    case 'ConanFileTxt':
      return 'ConanFileTxt'
    case 'ConanFilePy':
      return 'ConanFilePy'
    case 'Csproj':
      return 'Csproj'
  }
}

export function readManifestKind(x: any, context: any = x): ManifestKind {
  switch (x) {
    case 'RequirementsIn':
      return { kind: 'RequirementsIn' }
    case 'PackageJson':
      return { kind: 'PackageJson' }
    case 'Gemfile':
      return { kind: 'Gemfile' }
    case 'GoMod':
      return { kind: 'GoMod' }
    case 'CargoToml':
      return { kind: 'CargoToml' }
    case 'PomXml':
      return { kind: 'PomXml' }
    case 'BuildGradle':
      return { kind: 'BuildGradle' }
    case 'SettingsGradle':
      return { kind: 'SettingsGradle' }
    case 'ComposerJson':
      return { kind: 'ComposerJson' }
    case 'NugetManifestJson':
      return { kind: 'NugetManifestJson' }
    case 'PubspecYaml':
      return { kind: 'PubspecYaml' }
    case 'PackageSwift':
      return { kind: 'PackageSwift' }
    case 'Podfile':
      return { kind: 'Podfile' }
    case 'MixExs':
      return { kind: 'MixExs' }
    case 'Pipfile':
      return { kind: 'Pipfile' }
    case 'PyprojectToml':
      return { kind: 'PyprojectToml' }
    case 'ConanFileTxt':
      return { kind: 'ConanFileTxt' }
    case 'ConanFilePy':
      return { kind: 'ConanFilePy' }
    case 'Csproj':
      return { kind: 'Csproj' }
    default:
      _atd_bad_json('ManifestKind', x, context)
      throw new Error('impossible')
  }
}

export function writeManifest(x: Manifest, context: any = x): any {
  return {
    'kind': _atd_write_required_field('Manifest', 'kind', writeManifestKind, x.kind, x),
    'path': _atd_write_required_field('Manifest', 'path', writeFpath, x.path, x),
  };
}

export function readManifest(x: any, context: any = x): Manifest {
  return {
    kind: _atd_read_required_field('Manifest', 'kind', readManifestKind, x['kind'], x),
    path: _atd_read_required_field('Manifest', 'path', readFpath, x['path'], x),
  };
}

export function writeLockfile(x: Lockfile, context: any = x): any {
  return {
    'kind': _atd_write_required_field('Lockfile', 'kind', writeLockfileKind, x.kind, x),
    'path': _atd_write_required_field('Lockfile', 'path', writeFpath, x.path, x),
  };
}

export function readLockfile(x: any, context: any = x): Lockfile {
  return {
    kind: _atd_read_required_field('Lockfile', 'kind', readLockfileKind, x['kind'], x),
    path: _atd_read_required_field('Lockfile', 'path', readFpath, x['path'], x),
  };
}

export function writeDependencySource(x: DependencySource, context: any = x): any {
  switch (x.kind) {
    case 'ManifestOnlyDependencySource':
      return ['ManifestOnlyDependencySource', writeManifest(x.value, x)]
    case 'LockfileOnlyDependencySource':
      return ['LockfileOnlyDependencySource', writeLockfile(x.value, x)]
    case 'ManifestLockfileDependencySource':
      return ['ManifestLockfileDependencySource', ((x, context) => [writeManifest(x[0], x), writeLockfile(x[1], x)])(x.value, x)]
  }
}

export function readDependencySource(x: any, context: any = x): DependencySource {
  _atd_check_json_tuple(2, x, context)
  switch (x[0]) {
    case 'ManifestOnlyDependencySource':
      return { kind: 'ManifestOnlyDependencySource', value: readManifest(x[1], x) }
    case 'LockfileOnlyDependencySource':
      return { kind: 'LockfileOnlyDependencySource', value: readLockfile(x[1], x) }
    case 'ManifestLockfileDependencySource':
      return { kind: 'ManifestLockfileDependencySource', value: ((x, context): [Manifest, Lockfile] => { _atd_check_json_tuple(2, x, context); return [readManifest(x[0], x), readLockfile(x[1], x)] })(x[1], x) }
    default:
      _atd_bad_json('DependencySource', x, context)
      throw new Error('impossible')
  }
}

export function writeResolutionError(x: ResolutionError, context: any = x): any {
  switch (x.kind) {
    case 'UnsupportedManifest':
      return 'UnsupportedManifest'
    case 'MissingRequirement':
      return ['MissingRequirement', _atd_write_string(x.value, x)]
    case 'ResolutionCmdFailed':
      return ['ResolutionCmdFailed', writeResolutionCmdFailed(x.value, x)]
    case 'ParseDependenciesFailed':
      return ['ParseDependenciesFailed', _atd_write_string(x.value, x)]
  }
}

export function readResolutionError(x: any, context: any = x): ResolutionError {
  if (typeof x === 'string') {
    switch (x) {
      case 'UnsupportedManifest':
        return { kind: 'UnsupportedManifest' }
      default:
        _atd_bad_json('ResolutionError', x, context)
        throw new Error('impossible')
    }
  }
  else {
    _atd_check_json_tuple(2, x, context)
    switch (x[0]) {
      case 'MissingRequirement':
        return { kind: 'MissingRequirement', value: _atd_read_string(x[1], x) }
      case 'ResolutionCmdFailed':
        return { kind: 'ResolutionCmdFailed', value: readResolutionCmdFailed(x[1], x) }
      case 'ParseDependenciesFailed':
        return { kind: 'ParseDependenciesFailed', value: _atd_read_string(x[1], x) }
      default:
        _atd_bad_json('ResolutionError', x, context)
        throw new Error('impossible')
    }
  }
}

export function writeResolutionCmdFailed(x: ResolutionCmdFailed, context: any = x): any {
  return {
    'command': _atd_write_required_field('ResolutionCmdFailed', 'command', _atd_write_string, x.command, x),
    'message': _atd_write_required_field('ResolutionCmdFailed', 'message', _atd_write_string, x.message, x),
  };
}

export function readResolutionCmdFailed(x: any, context: any = x): ResolutionCmdFailed {
  return {
    command: _atd_read_required_field('ResolutionCmdFailed', 'command', _atd_read_string, x['command'], x),
    message: _atd_read_required_field('ResolutionCmdFailed', 'message', _atd_read_string, x['message'], x),
  };
}

export function writeResolutionResult(x: ResolutionResult, context: any = x): any {
  switch (x.kind) {
    case 'ResolutionOk':
      return ['ResolutionOk', ((x, context) => [_atd_write_array(writeFoundDependency)(x[0], x), _atd_write_array(writeResolutionError)(x[1], x)])(x.value, x)]
    case 'ResolutionError':
      return ['ResolutionError', _atd_write_array(writeResolutionError)(x.value, x)]
  }
}

export function readResolutionResult(x: any, context: any = x): ResolutionResult {
  _atd_check_json_tuple(2, x, context)
  switch (x[0]) {
    case 'ResolutionOk':
      return { kind: 'ResolutionOk', value: ((x, context): [FoundDependency[], ResolutionError[]] => { _atd_check_json_tuple(2, x, context); return [_atd_read_array(readFoundDependency)(x[0], x), _atd_read_array(readResolutionError)(x[1], x)] })(x[1], x) }
    case 'ResolutionError':
      return { kind: 'ResolutionError', value: _atd_read_array(readResolutionError)(x[1], x) }
    default:
      _atd_bad_json('ResolutionResult', x, context)
      throw new Error('impossible')
  }
}

export function writeFunctionCall(x: FunctionCall, context: any = x): any {
  switch (x.kind) {
    case 'CallContributions':
      return 'CallContributions'
    case 'CallApplyFixes':
      return ['CallApplyFixes', writeApplyFixesParams(x.value, x)]
    case 'CallFormatter':
      return ['CallFormatter', ((x, context) => [writeOutputFormat(x[0], x), writeFormatContext(x[1], x), writeCliOutput(x[2], x)])(x.value, x)]
    case 'CallSarifFormat':
      return ['CallSarifFormat', ((x, context) => [writeSarifFormat(x[0], x), writeFormatContext(x[1], x), writeCliOutput(x[2], x)])(x.value, x)]
    case 'CallValidate':
      return ['CallValidate', writeFpath(x.value, x)]
    case 'CallResolveDependencies':
      return ['CallResolveDependencies', _atd_write_array(writeDependencySource)(x.value, x)]
    case 'CallDumpRulePartitions':
      return ['CallDumpRulePartitions', writeDumpRulePartitionsParams(x.value, x)]
  }
}

export function readFunctionCall(x: any, context: any = x): FunctionCall {
  if (typeof x === 'string') {
    switch (x) {
      case 'CallContributions':
        return { kind: 'CallContributions' }
      default:
        _atd_bad_json('FunctionCall', x, context)
        throw new Error('impossible')
    }
  }
  else {
    _atd_check_json_tuple(2, x, context)
    switch (x[0]) {
      case 'CallApplyFixes':
        return { kind: 'CallApplyFixes', value: readApplyFixesParams(x[1], x) }
      case 'CallFormatter':
        return { kind: 'CallFormatter', value: ((x, context): [OutputFormat, FormatContext, CliOutput] => { _atd_check_json_tuple(3, x, context); return [readOutputFormat(x[0], x), readFormatContext(x[1], x), readCliOutput(x[2], x)] })(x[1], x) }
      case 'CallSarifFormat':
        return { kind: 'CallSarifFormat', value: ((x, context): [SarifFormat, FormatContext, CliOutput] => { _atd_check_json_tuple(3, x, context); return [readSarifFormat(x[0], x), readFormatContext(x[1], x), readCliOutput(x[2], x)] })(x[1], x) }
      case 'CallValidate':
        return { kind: 'CallValidate', value: readFpath(x[1], x) }
      case 'CallResolveDependencies':
        return { kind: 'CallResolveDependencies', value: _atd_read_array(readDependencySource)(x[1], x) }
      case 'CallDumpRulePartitions':
        return { kind: 'CallDumpRulePartitions', value: readDumpRulePartitionsParams(x[1], x) }
      default:
        _atd_bad_json('FunctionCall', x, context)
        throw new Error('impossible')
    }
  }
}

export function writeFunctionReturn(x: FunctionReturn, context: any = x): any {
  switch (x.kind) {
    case 'RetError':
      return ['RetError', _atd_write_string(x.value, x)]
    case 'RetApplyFixes':
      return ['RetApplyFixes', writeApplyFixesReturn(x.value, x)]
    case 'RetContributions':
      return ['RetContributions', writeContributions(x.value, x)]
    case 'RetFormatter':
      return ['RetFormatter', _atd_write_string(x.value, x)]
    case 'RetSarifFormat':
      return ['RetSarifFormat', _atd_write_string(x.value, x)]
    case 'RetValidate':
      return ['RetValidate', _atd_write_bool(x.value, x)]
    case 'RetResolveDependencies':
      return ['RetResolveDependencies', _atd_write_array(((x, context) => [writeDependencySource(x[0], x), writeResolutionResult(x[1], x)]))(x.value, x)]
    case 'RetDumpRulePartitions':
      return ['RetDumpRulePartitions', _atd_write_bool(x.value, x)]
  }
}

export function readFunctionReturn(x: any, context: any = x): FunctionReturn {
  _atd_check_json_tuple(2, x, context)
  switch (x[0]) {
    case 'RetError':
      return { kind: 'RetError', value: _atd_read_string(x[1], x) }
    case 'RetApplyFixes':
      return { kind: 'RetApplyFixes', value: readApplyFixesReturn(x[1], x) }
    case 'RetContributions':
      return { kind: 'RetContributions', value: readContributions(x[1], x) }
    case 'RetFormatter':
      return { kind: 'RetFormatter', value: _atd_read_string(x[1], x) }
    case 'RetSarifFormat':
      return { kind: 'RetSarifFormat', value: _atd_read_string(x[1], x) }
    case 'RetValidate':
      return { kind: 'RetValidate', value: _atd_read_bool(x[1], x) }
    case 'RetResolveDependencies':
      return { kind: 'RetResolveDependencies', value: _atd_read_array(((x, context): [DependencySource, ResolutionResult] => { _atd_check_json_tuple(2, x, context); return [readDependencySource(x[0], x), readResolutionResult(x[1], x)] }))(x[1], x) }
    case 'RetDumpRulePartitions':
      return { kind: 'RetDumpRulePartitions', value: _atd_read_bool(x[1], x) }
    default:
      _atd_bad_json('FunctionReturn', x, context)
      throw new Error('impossible')
  }
}

export function writePartialScanResult(x: PartialScanResult, context: any = x): any {
  switch (x.kind) {
    case 'PartialScanOk':
      return ['PartialScanOk', ((x, context) => [writeCiScanResults(x[0], x), writeCiScanComplete(x[1], x)])(x.value, x)]
    case 'PartialScanError':
      return ['PartialScanError', writeCiScanFailure(x.value, x)]
  }
}

export function readPartialScanResult(x: any, context: any = x): PartialScanResult {
  _atd_check_json_tuple(2, x, context)
  switch (x[0]) {
    case 'PartialScanOk':
      return { kind: 'PartialScanOk', value: ((x, context): [CiScanResults, CiScanComplete] => { _atd_check_json_tuple(2, x, context); return [readCiScanResults(x[0], x), readCiScanComplete(x[1], x)] })(x[1], x) }
    case 'PartialScanError':
      return { kind: 'PartialScanError', value: readCiScanFailure(x[1], x) }
    default:
      _atd_bad_json('PartialScanResult', x, context)
      throw new Error('impossible')
  }
}

export function writeDiffFile(x: DiffFile, context: any = x): any {
  return {
    'filename': _atd_write_required_field('DiffFile', 'filename', writeFpath, x.filename, x),
    'diffs': _atd_write_required_field('DiffFile', 'diffs', _atd_write_array(_atd_write_string), x.diffs, x),
    'url': _atd_write_required_field('DiffFile', 'url', _atd_write_string, x.url, x),
  };
}

export function readDiffFile(x: any, context: any = x): DiffFile {
  return {
    filename: _atd_read_required_field('DiffFile', 'filename', readFpath, x['filename'], x),
    diffs: _atd_read_required_field('DiffFile', 'diffs', _atd_read_array(_atd_read_string), x['diffs'], x),
    url: _atd_read_required_field('DiffFile', 'url', _atd_read_string, x['url'], x),
  };
}

export function writeDiffFiles(x: DiffFiles, context: any = x): any {
  return {
    'cve_diffs': _atd_write_required_field('DiffFiles', 'cve_diffs', _atd_write_array(writeDiffFile), x.cve_diffs, x),
  };
}

export function readDiffFiles(x: any, context: any = x): DiffFiles {
  return {
    cve_diffs: _atd_read_required_field('DiffFiles', 'cve_diffs', _atd_read_array(readDiffFile), x['cve_diffs'], x),
  };
}


/////////////////////////////////////////////////////////////////////
// Runtime library
/////////////////////////////////////////////////////////////////////

export type Option<T> = null | { value: T }

function _atd_missing_json_field(type_name: string, json_field_name: string) {
    throw new Error(`missing field '${json_field_name}'` +
                    ` in JSON object of type '${type_name}'`)
}

function _atd_missing_ts_field(type_name: string, ts_field_name: string) {
    throw new Error(`missing field '${ts_field_name}'` +
                    ` in TypeScript object of type '${type_name}'`)
}

function _atd_bad_json(expected_type: string, json_value: any, context: any) {
  let value_str = JSON.stringify(json_value)
  if (value_str.length > 200)
    value_str = value_str.substring(0, 200) + '…';

  throw new Error(`incompatible JSON value where` +
                  ` type '${expected_type}' was expected: '${value_str}'.` +
                  ` Occurs in '${JSON.stringify(context)}'.`)
}

function _atd_bad_ts(expected_type: string, ts_value: any, context: any) {
  let value_str = JSON.stringify(ts_value)
  if (value_str.length > 200)
    value_str = value_str.substring(0, 200) + '…';

  throw new Error(`incompatible TypeScript value where` +
                  ` type '${expected_type}' was expected: '${value_str}'.` +
                  ` Occurs in '${JSON.stringify(context)}'.`)
}

function _atd_check_json_tuple(len: number /*int*/, x: any, context: any) {
  if (! Array.isArray(x) || x.length !== len)
    _atd_bad_json('tuple of length ' + len, x, context);
}

function _atd_read_unit(x: any, context: any): null {
  if (x === null)
    return null
  else {
    _atd_bad_json('null', x, context)
    throw new Error('impossible')
  }
}

function _atd_read_bool(x: any, context: any): boolean {
  if (typeof x === 'boolean')
    return x
  else {
    _atd_bad_json('boolean', x, context)
    throw new Error('impossible')
  }
}

function _atd_read_int(x: any, context: any): number /*int*/ {
  if (Number.isInteger(x))
    return x
  else {
    _atd_bad_json('integer', x, context)
    throw new Error('impossible')
  }
}

function _atd_read_float(x: any, context: any): number {
  if (isFinite(x))
    return x
  else {
    _atd_bad_json('number', x, context)
    throw new Error('impossible')
  }
}

function _atd_read_string(x: any, context: any): string {
  if (typeof x === 'string')
    return x
  else {
    _atd_bad_json('string', x, context)
    throw new Error('impossible')
  }
}

function _atd_read_required_field<T>(type_name: string,
                                     field_name: string,
                                     read_elt: (x: any, context: any) => T,
                                     x: any,
                                     context: any): T {
  if (x === undefined) {
    _atd_missing_json_field(type_name, field_name)
    throw new Error('impossible')
  }
  else
    return read_elt(x, context)
}

function _atd_read_optional_field<T>(read_elt: (x: any, context: any) => T,
                                     x: any,
                                     context: any): T {
  if (x === undefined || x === null)
    return x
  else
    return read_elt(x, context)
}

function _atd_read_field_with_default<T>(read_elt: (x: any, context: any) => T,
                                         default_: T,
                                         x: any,
                                         context: any): T {
  if (x === undefined || x === null)
    return default_
  else
    return read_elt(x, context)
}

function _atd_read_option<T>(read_elt: (x: any, context: any) => T):
  (x: any, context: any) => Option<T> {
  function read_option(x: any, context: any): Option<T> {
    if (x === 'None')
      return null
    else {
      _atd_check_json_tuple(2, x, context);
      switch (x[0]) {
        case 'Some':
          return { value: read_elt(x[1], context) }
        default:
          _atd_bad_json('option', x, context)
          throw new Error('impossible')
      }
    }
  }
  return read_option
}

function _atd_read_nullable<T>(read_elt: (x: any, context: any) => T):
  (x: any, context: any) => T | null {
  function read_nullable(x: any, context: any): T | null {
    if (x === null)
      return null
    else
      return read_elt(x, context)
  }
  return read_nullable
}

function _atd_read_array<T>(read_elt: (x: any, context: any) => T):
  (elts: any, context: any) => T[] {
  function read_array(elts: any, context: any): T[] {
    if (Array.isArray(elts))
      return elts.map((x) => read_elt(x, elts))
    else {
      _atd_bad_json('array', elts, context)
      throw new Error('impossible')
    }
  }
  return read_array
}

function _atd_read_assoc_array_into_map<K, V>(
    read_key: (key: any, context: any) => K,
    read_value: (value: any, context: any) => V
  ): (x: any, context: any) => Map<K, V> {
  function read_assoc(elts: any, context: any): Map<K, V> {
    if (Array.isArray(elts)) {
      const res = new Map<K, V>([])
      for (const x of elts) {
        if (Array.isArray(x) && x.length === 2)
          res.set(read_key(x[0], x), read_value(x[1], x))
        else {
          _atd_bad_json('pair', x, elts)
          throw new Error('impossible')
        }
      }
      return res
    }
    else {
      _atd_bad_json('array', elts, context)
      throw new Error('impossible')
    }
  }
  return read_assoc
}

function _atd_read_assoc_object_into_map<T>(
    read_value: (value: any, context: any) => T
  ): (x: any, context: any) => Map<string, T> {
  function read_assoc(elts: any, context: any): Map<string, T> {
    if (typeof elts === 'object') {
      const res = new Map<string, T>([])
      for (const [key, value] of Object.entries(elts))
        res.set(key, read_value(value, elts))
      return res
    }
    else {
      _atd_bad_json('object', elts, context)
      throw new Error('impossible')
    }
  }
  return read_assoc
}

function _atd_read_assoc_object_into_array<T>(
    read_value: (value: any, context: any) => T
  ): (x: any, context: any) => [string, T][] {
  function read_assoc(elts: any, context: any): [string, T][] {
    if (typeof elts === 'object') {
      const res: [string, T][] = []
      for (const [key, value] of Object.entries(elts))
        res.push([key, read_value(value, elts)])
      return res
    }
    else {
      _atd_bad_json('object', elts, context)
      throw new Error('impossible')
    }
  }
  return read_assoc
}

function _atd_write_unit(x: any, context: any) {
  if (x === null)
    return x
  else {
    _atd_bad_ts('null', x, context)
    throw new Error('impossible')
  }
}

function _atd_write_bool(x: any, context: any): boolean {
  if (typeof x === 'boolean')
    return x
  else {
    _atd_bad_ts('boolean', x, context)
    throw new Error('impossible')
  }
}

function _atd_write_int(x: any, context: any): number /*int*/ {
  if (Number.isInteger(x))
    return x
  else {
    _atd_bad_ts('integer', x, context)
    throw new Error('impossible')
  }
}

function _atd_write_float(x: any, context: any): number {
  if (isFinite(x))
    return x
  else {
    _atd_bad_ts('number', x, context)
    throw new Error('impossible')
  }
}

function _atd_write_string(x: any, context: any): string {
  if (typeof x === 'string')
    return x
  else {
    _atd_bad_ts('string', x, context)
    throw new Error('impossible')
  }
}

function _atd_write_option<T>(write_elt: (x: T, context: any) => any):
   (elts: Option<T>, context: any) => any {
  function write_option(x: Option<T>, context: any): any {
    if (x === null)
      return 'None'
    else
      return ['Some', write_elt(x.value, context)]
  }
  return write_option
}

function _atd_write_nullable<T>(write_elt: (x: T, context: any) => any):
  (x: T | null, context: any) => any {
  function write_option(x: T | null, context: any): any {
    if (x === null)
      return null
    else
      return write_elt(x, context)
  }
  return write_option
}

function _atd_write_array<T>(write_elt: (elt: T, context: any) => any):
  (elts: T[], context: any) => any {
  return ((elts: T[], context: any): any =>
    elts.map((x) => write_elt(x, elts))
  )
}

function _atd_write_assoc_map_to_array<K, V>(
    write_key: (key: K, context: any) => any,
    write_value: (value: V, context: any) => any
  ): (elts: Map<K, V>, context: any) => any {
  function write_assoc(elts: Map<K, V>, context: any): any {
    const res: any = []
    elts.forEach((value: V, key: K) =>
      res.push([write_key(key, elts), write_value(value, elts)])
    )
    return res
  }
  return write_assoc
}

function _atd_write_assoc_map_to_object<T>(
    write_value: (value: T, context: any) => any
  ): (elts: Map<string, T>, context: any) => any {
  function write_assoc(elts: Map<string, T>, context: any): any {
    const res: any = {}
    elts.forEach((value: T, key: string) =>
      res[key] = write_value(value, elts)
    )
    return res
  }
  return write_assoc
}

function _atd_write_assoc_array_to_object<T>(
    write_value: (value: T, context: any) => any
  ): (elts: [string, T][], context: any) => any {
  function write_assoc(elts: [string, T][], context: any): any {
    const res: any = {}
    for (const [key, value] of elts)
      res[key] = write_value(value, elts)
    return res
  }
  return write_assoc
}

function _atd_write_required_field<T>(type_name: string,
                                      field_name: string,
                                      write_elt: (x: T, context: any) => any,
                                      x: T,
                                      context: any): any {
  if (x === undefined) {
    _atd_missing_ts_field(type_name, field_name)
    throw new Error('impossible')
  }
  else
    return write_elt(x, context)
}

function _atd_write_optional_field<T>(write_elt: (x: T, context: any) => any,
                                      x: T | undefined,
                                      context: any): any {
  if (x === undefined || x === null)
    return x
  else
    return write_elt(x, context)
}

function _atd_write_field_with_default<T>(
  write_elt: (x: T, context: any) => any,
  default_: T,
  x: T,
  context: any
): T {
  const value = (x === undefined || x === null) ? default_ : x
  return write_elt(value, context)
}

