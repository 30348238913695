import React, { useEffect } from "react";
import { useMemo, useRef, useState } from "react";
import uniq from "lodash/uniq";
import * as monaco from "monaco-editor";
import { Monaco } from "@monaco-editor/react";

import { MonacoEditor } from "@vendors/monaco";

import { LANG_MONACO_MAP } from "@shared/constants";
import { useDebouncedResize } from "@shared/hooks";
import { Highlight } from "@shared/types";

import "./CodeEditor.scss";

interface Props {
  target: string;
  language: string;
  hideLineNumbers?: boolean;
  onTargetChange: (target: string) => void;
  highlighted: Highlight[];
  centeredLine: number | undefined;
  readOnly?: boolean;
}

type HoverList = (monaco.languages.Hover | null)[];

const makeHighlightDec = (highlighted: Highlight[]) =>
  highlighted.map((hl) => ({
    range: hl.range,
    options: {
      inlineClassName: `widget-highlight ${hl.type.toLowerCase()}`,
      zIndex: 1,
    },
  }));

const makeHighlightLines = (highlighted: Highlight[], monacoInstance: Monaco) =>
  highlighted.map((hl) => ({
    range: new monacoInstance.Range(hl.startLine, 0, hl.endLine, Infinity),
    options: {
      isWholeLine: true,
      className: `widget-highlight backdrop ${hl.type.toLowerCase()}`,
    },
  }));

const makeHovers = (highlighted: Highlight[], ranges: monaco.Range[]) =>
  highlighted
    .filter((hl) => hl.message !== undefined)
    .map((hl, ix) => {
      const r = ranges[ix];
      const hover: monaco.languages.Hover = {
        range: r,
        contents: [
          {
            value: `**Match ${ix + 1} / ${ranges.length}**\n\n${hl.message}`,
          },
        ],
      };
      return hover;
    });

const makeHoverProvider = (
  hovers: React.MutableRefObject<HoverList>,
  monacoInstance: Monaco
) => {
  const provider: monaco.languages.HoverProvider = {
    provideHover: (_: any, position) =>
      hovers.current.find(
        (h) =>
          h &&
          h.range &&
          new monacoInstance.Range(
            h.range.startLineNumber,
            h.range.startColumn,
            h.range.endLineNumber,
            h.range.endColumn
          ).containsPosition(position)
      ),
  };
  return provider;
};

const makeGlyphLines = (highlighted: Highlight[], monacoInstance: Monaco) => {
  const uniqGroups = uniq(highlighted.map((hl) => hl.group));
  const glpyhLines = highlighted.map((hl) => ({
    range: new monacoInstance.Range(hl.startLine, 0, hl.startLine, 0),
    options: {
      glyphMarginClassName: `highlight-glyph g${uniqGroups.indexOf(hl.group)}`,
    },
  }));
  return glpyhLines;
};

const CodeEditor: React.FC<Props> = ({
  target,
  onTargetChange,
  readOnly,
  language,
  hideLineNumbers,
  centeredLine,
  highlighted,
}) => {
  const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor>();
  const [monacoInstance, setMonacoInstance] = useState<Monaco>();
  const decorationIds = useRef<string[]>([]);
  const hovers = useRef<HoverList>([]);

  useEffect(() => {
    if (editor === undefined || centeredLine === undefined) {
      return;
    }
    editor.revealLineInCenter(centeredLine);
  }, [editor, centeredLine]);

  useEffect(() => {
    if (editor === undefined) return;
    if (monacoInstance === undefined) return;

    const highlightRanges = makeHighlightLines(highlighted, monacoInstance);
    hovers.current = makeHovers(
      highlighted,
      highlightRanges.map((hr) => hr.range)
    );

    const decorations = [
      ...highlightRanges,
      ...makeGlyphLines(highlighted, monacoInstance),
      ...makeHighlightDec(highlighted),
    ];

    decorationIds.current = editor.deltaDecorations(
      decorationIds.current,
      decorations
    );
  }, [monacoInstance, editor, highlighted]);

  const editorEl = useRef<HTMLDivElement>(null!);
  useDebouncedResize(editorEl, () => editor?.layout());

  const editorDidMount = useMemo(
    () =>
      async (
        mountedEditor: monaco.editor.IStandaloneCodeEditor,
        monacoInstance: Monaco
      ) => {
        if (monacoInstance === undefined) return;

        monacoInstance.editor.defineTheme("myTheme", {
          base: "vs-dark",
          inherit: true,
          rules: [
            { token: "comment", foreground: "#7b8daf", fontStyle: "italic" },
            { token: "string", foreground: "#f9f1ab" },
            { token: "operator", foreground: "#f3f8fe" },
            { token: "keyword", foreground: "#beb2f6" },
            { token: "identifier", foreground: "#89b6d9" },
            { token: "type.yaml", foreground: "#beb2f6" },
            { token: "string.yaml", foreground: "#f1f6fc" },
          ],
          colors: {
            "editor.background": "#171f43",
            "editorCursor.foreground": "#F2F8FF",
            "editorLineNumber.foreground": "#515774",
          },
        });
        monacoInstance.editor.setTheme("myTheme");
        setEditor(mountedEditor);
        setMonacoInstance(monacoInstance);
        monacoInstance.languages.registerHoverProvider(
          language,
          makeHoverProvider(hovers, monacoInstance)
        );
      },
    [setEditor, language]
  );
  const lang = language.toLowerCase();
  const editorLang = LANG_MONACO_MAP[language] || lang;

  return (
    <div ref={editorEl} style={{ height: "100%" }}>
      <MonacoEditor
        value={target}
        onChange={(value: string | undefined) => {
          value !== undefined && onTargetChange(value);
        }}
        onMount={editorDidMount}
        language={editorLang}
        options={{
          minimap: { enabled: false },
          readOnly: readOnly || false,
          wordWrap: "on",
          glyphMargin: true,
          fontFamily:
            '"Jetbrains MonoVariable", "Jetbrains Mono", "Menlo", "Courier New", monospace',
          lineNumbers: hideLineNumbers ? "off" : "on",
          contextmenu: true,
          hideCursorInOverviewRuler: true,
          scrollBeyondLastLine: false,
          overviewRulerBorder: false,
          overviewRulerLanes: 1,
          lineNumbersMinChars: 3,
          scrollbar: {
            horizontal: "hidden",
            verticalScrollbarSize: 5,
          },
          hover: { delay: 200 },
          wrappingIndent: "same",
          lightbulb: { enabled: false },
          showUnused: false,
        }}
      />
    </div>
  );
};
export const WidgetCodeEditor = React.memo(CodeEditor);
