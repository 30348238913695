import { OrgData } from "@shared/types";

import { getOrgSlug, slugifyNameForOrg } from "../getOrgSlug";

/**
 * @param slug the slug to match on
 * @param orgs the list from which to find the org (all user orgs)
 * @returns the org that matches the slug, or undefined if no org matches
 */
export const findOrgBySlug = (
  slug: string,
  orgs: OrgData[]
): OrgData | undefined => {
  let org = orgs.find((org) => getOrgSlug(org) === slug);

  if (org === undefined) {
    // this is for backwards compatibility
    // try the old way of creating the org slug, which slugifies the org name
    // instead of directly using the org slug
    org = orgs.find((org) => slugifyNameForOrg(org.name) === slug);
  }

  return org;
};
