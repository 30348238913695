import { MantineThemeOverride } from "@mantine/core";

import { Badge } from "./Badge";
import { Button } from "./Button";
import { HoverCard } from "./HoverCard";
import { Modal } from "./Modal";
import { Pill } from "./Pill";
import { Text } from "./Text";
import { Tooltip } from "./Tooltip";
import { UnstyledButton } from "./UnstyledButton";

import "@fontsource/inter/variable-full.css";
import "@fontsource/jetbrains-mono/variable-full.css";

import "@fontsource/inter";
import "@fontsource/jetbrains-mono";

export const theme: MantineThemeOverride = {
  components: {
    Badge,
    Button,
    Modal,
    Pill,
    Text,
    Tooltip,
    UnstyledButton,
    HoverCard,
  },

  fontFamily: '"InterVariable", "Inter", "Icons16", sans-serif',
  fontFamilyMonospace:
    '"Jetbrains MonoVariable", "Jetbrains Mono", "Menlo", "Courier New", monospace',
  /* changing monospace in the monaco-editors is only possible within the component */

  headings: {
    sizes: {
      h1: { fontWeight: "600" },
      h2: { fontWeight: "600" },
      h3: { fontWeight: "600" },
      h4: { fontWeight: "600" },
      h5: { fontWeight: "600" },
      h6: { fontWeight: "600" },
    },
  },
  primaryShade: { light: 7, dark: 8 },
  colors: {
    dark: [
      "#C1C2C5",
      "#A6A7AB",
      "#909296",
      "#5c5f66",
      "#373A40",
      "#2C2E33",
      "#25262b",
      "#1A1B1E",
      "#141517",
      "#101113",
    ],
  },
};
