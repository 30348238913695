import { useMemo } from "react";
import { useLocalStorage } from "@mantine/hooks";

import { DEFAULT_TENANT_NAME, Tenant } from "@shared/types";

export function parseTenantFromHostname(
  hostname: string,
  tenantNameOverride: string | null = null
): Tenant {
  const parts = hostname.split(".").reverse();

  // Not *.semgrep.dev -> dev env, default tenant
  if (parts[0] !== "dev" && parts[1] !== "semgrep") {
    return {
      environment: "dev",
      tenantName: tenantNameOverride || DEFAULT_TENANT_NAME,
    };
  }

  // dev.semgrep.dev, *.dev.semgrep.dev -> dev env, default tenant
  if (parts[2] === "dev") {
    return {
      environment: "dev",
      tenantName: tenantNameOverride || DEFAULT_TENANT_NAME,
    };
  }

  if (parts[2] === "dev2") {
    return {
      environment: "dev2",
      tenantName: tenantNameOverride || DEFAULT_TENANT_NAME,
    };
  }

  // staging.semgrep.dev, *.staging.semgrep.dev -> staging env, subdomain sets tenant
  if (parts[2] === "staging") {
    return {
      environment: "staging",
      tenantName: tenantNameOverride || parts[3] || DEFAULT_TENANT_NAME,
    };
  }

  // semgrep.dev, *.semgrep.dev -> prod env, subdomain sets tenant
  const tenantName = parts[2] !== "www" && parts[2]; // www is not a tenant
  return {
    environment: "prod",
    tenantName: tenantNameOverride || tenantName || DEFAULT_TENANT_NAME,
  };
}

export const useTenant = (): Tenant => {
  const [tenantNameOverride] = useLocalStorage<string | null>({
    key: "tenantNameOverride",
    defaultValue: null,
  });
  return useMemo(
    () => parseTenantFromHostname(window.location.host, tenantNameOverride),
    [tenantNameOverride]
  );
};
