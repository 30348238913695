import React, { useState } from "react";
import { Link } from "react-router-dom";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps, Menu, Stack, Tooltip } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";

import { useBasePath, useOrg, usePermissions, useUser } from "@shared/hooks";
import { Permission, ProductRuleItem } from "@shared/types";
import { Feature } from "@shared/utils";

import { PolicyModesMenu } from "./components/PolicyModesMenu";
import { SinglePolicyModesMenu } from "./components/SinglePolicyModesMenu";

export interface AddToPolicyButtonProps {
  ruleItemsToAdd: ProductRuleItem[];
  buttonText?: string;
  buttonProps?: ButtonProps;
  showOpenInAppCTA?: boolean;
}

/**
 * @ruleItemsToAdd rule items to add to the rule board's policies. Must be formatted as `ProductRuleItem`s.
 * @buttonText Text on the button. If undefined, will say "Add to Policy"
 * @buttonProps Mantine button props for if you want to change the buttons styling
 * @returns Button with a drop down menu that allows user to select or create new policy to add rules to
 */
export const AddToPolicyButton: React.FC<AddToPolicyButtonProps> = ({
  ruleItemsToAdd,
  buttonProps,
  showOpenInAppCTA = false,
}) => {
  const [org] = useOrg();
  const [user] = useUser();
  const [permissions] = usePermissions();
  const basePath = useBasePath();

  const canAccessPolicies = permissions.includes(Permission.panel_policy);
  const hasMultipleRuleboards = Feature.hasMultipleRuleboards({ org });

  const isPolicyButtonDisabled = user && org && !canAccessPolicies;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdown, setDropdown] = useState<HTMLDivElement | null>(null);
  const [control, setControl] = useState<HTMLDivElement | null>(null);
  useClickOutside(() => setIsMenuOpen(false), null, [dropdown, control]);

  return (
    <Menu
      position="bottom"
      width={300}
      shadow="sm"
      opened={isMenuOpen}
      styles={() => ({
        itemLabel: { overflow: "hidden", textOverflow: "ellipsis" },
      })}
    >
      <Menu.Target>
        <div ref={setControl}>
          <Tooltip
            multiline
            w={220}
            withArrow
            transitionProps={{ duration: 200 }}
            label="Future additions to this ruleset will automatically be included in your policy in the same mode you select."
          >
            <Button
              {...buttonProps}
              disabled={isPolicyButtonDisabled || buttonProps?.disabled}
              rightSection={<FontAwesomeIcon icon={faCaretDown} />}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {user === undefined ? "Use in CI" : "Add to Policy"}&nbsp;
            </Button>
          </Tooltip>
        </div>
      </Menu.Target>
      {!isPolicyButtonDisabled && (
        <Menu.Dropdown>
          <div ref={setDropdown}>
            {hasMultipleRuleboards ? (
              <PolicyModesMenu
                ruleItemsToAdd={ruleItemsToAdd}
                handleClose={() => setIsMenuOpen(false)}
              />
            ) : (
              <SinglePolicyModesMenu ruleItemsToAdd={ruleItemsToAdd} />
            )}
          </div>
          {showOpenInAppCTA && (
            <Stack
              p="sm"
              styles={{ root: { color: "#000", whiteSpace: "break-spaces" } }}
            >
              Want to see your findings? Open in the App!
              <Button component={Link} to={`${basePath}/findings?primary=true`}>
                See in App
              </Button>
            </Stack>
          )}
        </Menu.Dropdown>
      )}
    </Menu>
  );
};
