import { Button, Stack, Text } from "@mantine/core";

export const SaveRuleUpsellPopover: React.FC<{ returnPath?: string }> = ({
  returnPath,
}) => {
  return (
    <Stack style={{ whiteSpace: "break-spaces" }} w={200}>
      <Text c="dark">Create an account to save your rule!</Text>
      <Button
        component="a"
        href={`/login?return_path=${returnPath ?? window.location.pathname}`}
      >
        Sign up
      </Button>
    </Stack>
  );
};
