import React from "react";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "@mantine/core";

import { Language } from "@shared/types";
import { ALL_LANGUAGES } from "@shared/utils";

import styles from "./WidgetLanguageSelect.module.css";

interface Props {
  onChange: (language: Language | null) => void;
  value: Language;
  disabled?: boolean;
}

export const WidgetLanguageSelect: React.FC<Props> = ({
  onChange,
  value,
  disabled = false,
}) => {
  const langs = Object.values(ALL_LANGUAGES).map((l) => {
    return { value: l.id, label: l.name };
  });

  return (
    <Select
      data={langs}
      allowDeselect={false}
      disabled={disabled}
      onChange={onChange}
      rightSection={<FontAwesomeIcon icon={faChevronDown} />}
      rightSectionWidth={30}
      size="xs"
      classNames={{
        input: styles.input,
        option: styles.option,
        section: styles.section,
      }}
      styles={(theme) => ({
        input: {
          color: "#7a6bcb",
          fontWeight: "bold",
          height: 22,
          lineHeight: 20,
          minHeight: 22,
          paddingRight: 30,
          textAlign: "right",
          width: 100,
        },
        item: {
          // applies styles to selected item
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: theme.colors["grape"][9],
              color: theme.colors["grape"][0],
            },
          },
          paddingBottom: 2,
          paddingTop: 2,
        },
        rightSection: { pointerEvents: "none" },
      })}
      value={value}
      variant="unstyled"
      data-fs-element="Widget language select"
    />
  );
};
