import { Anchor, Stack, Text } from "@mantine/core";

export const DataflowInfoTooltipContent = () => (
  <Stack w={250} c="#fff" fz="sm" style={{ whiteSpace: "break-spaces" }}>
    <Text inherit>
      Data flow tags untrusted data as it enters your code, then tracks it,
      helping you identify potential security vulnerabilities like SQL injection
      and data leakage.
    </Text>
    <Text inherit>
      <b>Source</b>: where untrusted data enters the program.
      <br />
      <b>Traces</b>: how data is used and propagated throughout the code.
      <br />
      <b>Sink</b>: where data is used in a potentially dangerous way.
      <br />
    </Text>
    <Text inherit>
      See more about{" "}
      <Anchor
        inherit
        href="https://semgrep.dev/docs/writing-rules/data-flow/data-flow-overview/"
        rel="noopener noreferrer"
      >
        data flow
      </Anchor>{" "}
      in the docs.
    </Text>
  </Stack>
);
