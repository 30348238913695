import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useCookie from "react-use-cookie";
import { datadogRum } from "@datadog/browser-rum";
import { useAsyncEffect } from "@react-hook/async";
import * as Sentry from "@sentry/react";

import {
  useLogEventMutation,
  useOrg,
  useProductLicenseInfo,
  useUrlSearch,
  useUser,
  useUserOrgs,
} from "@shared/hooks";
import {
  daysSinceUserCreated,
  extractUserCreatedDate,
  extractUserCreatedQuarter,
} from "@shared/utils";

declare let FS: any;

interface UrlParamObj {
  key: string;
  value: string;
}

export const Tracking: React.FC = () => {
  const [user] = useUser();
  const { data: userOrgs, isLoading: isUserOrgsLoading } = useUserOrgs();
  const [org] = useOrg();
  const { search } = useUrlSearch();
  const location = useLocation();
  const [utmCookie] = useCookie("dpi_utmVals");
  const sastLicenseInfo = useProductLicenseInfo("sast");
  const sscLicenseInfo = useProductLicenseInfo("ssc");
  const secretsLicenseInfo = useProductLicenseInfo("secrets");
  const { mutateAsync: logEvent } = useLogEventMutation();

  const pathName = location.pathname;

  // grab utm values anytime the search params change or the utm cookie changes
  // we will deduplicate them together to try to get as much utm data as possible
  useAsyncEffect(async () => {
    try {
      const utmParams: UrlParamObj[] = [];

      const cookieObj: { [key: string]: string } = JSON.parse(
        utmCookie || "{}" // "" throws an error
      );

      // Filter the entries to keep only those that start with "utm_" (case-insensitive)
      for (const [key, value] of Object.entries(cookieObj)) {
        if (key.toLowerCase().startsWith("utm_")) {
          utmParams.push({ key, value });
        }
      }

      // Grab any additional utms from the params but we will prefer
      // those in the cookie over those in the params
      for (const [key, value] of search.entries()) {
        if (key.toLowerCase().startsWith("utm_")) {
          if (!utmParams.find((x) => x.key === key)) {
            utmParams.push({ key, value });
          }
        }
      }

      if (utmParams.length > 0) {
        await logEvent({
          event: "utmParams",
          metadata: { utmParams, pathName },
        });
      }
    } catch (e) {
      console.error("unable to parse UTM from cookie or params", e, utmCookie);
    }
  }, [search, pathName, utmCookie]);

  useEffect(() => {
    const orgProducts = [];
    if (sastLicenseInfo?.license) orgProducts.push("sast");
    if (sastLicenseInfo?.hasTrial) orgProducts.push("sastTrial");
    if (sscLicenseInfo?.license) orgProducts.push("sca");
    if (sscLicenseInfo?.hasTrial) orgProducts.push("scaTrial");
    if (secretsLicenseInfo?.license) orgProducts.push("secrets");
    if (secretsLicenseInfo?.hasTrial) orgProducts.push("secretsTrial");

    if (!user || isUserOrgsLoading) return;
    try {
      FS.identify(user.username, {
        displayName: user.username,
        email: user.email,
        // Custom user properties (see https://developer.fullstory.com/browser/v1/identification/set-user-properties/)
        userId_int: user.user_id,
        // `daysSinceUserCreated` will allow us to filter user sessions across different absolute time periods
        // while using the same relative age of the account (e.g. 30 days after account creation).
        userAccountAgeDays_int: daysSinceUserCreated(user),
        userAuthProvider_str: user.auth_provider_type, // e.g. "github"
        // `extractUserCreatedDate` will allow us to filter user sessions by the exact date they created their account
        userCreatedAt_str: extractUserCreatedDate(user),
        // `extractUserCreatedQuarter` will allow us to group users by the quarter they created their account
        userCreatedQuarter_str: extractUserCreatedQuarter(user),
        deploymentId_int: org?.id,
        deploymentName_str: org?.name,
        featureFlags_strs: org?.features?.map((f) => f.name) ?? [],
        isSemgrepStaff_bool:
          (userOrgs || []).find(
            (org) => org.name === "returntocorp" || org.name === "semgrep"
          ) !== undefined,
        tier_str:
          sastLicenseInfo?.license || sscLicenseInfo?.license ? "paid" : "free",
        orgProducts_strs: orgProducts,
      });
    } catch (err) {
      console.warn(`caught error in tracking script`);
    }
  }, [
    user,
    org,
    sastLicenseInfo,
    sscLicenseInfo,
    secretsLicenseInfo,
    isUserOrgsLoading,
    userOrgs,
  ]);

  useEffect(() => {
    if (!user) return;
    try {
      datadogRum.setUser({
        name: user.username,
        email: user.email,
      });
    } catch (err) {
      console.warn(`caught error in tracking script`);
    }
  }, [user]);

  useEffect(() => {
    if (!org) return;
    try {
      datadogRum.addRumGlobalContext("org", {
        name: org.name,
        id: org.id,
      });
    } catch (err) {
      console.warn(`caught error in tracking script`);
    }
  }, [org]);

  useEffect(() => {
    if (!user) return;
    try {
      Sentry.setUser({
        ...(user.user_id && { id: `user-${user.user_id}` }),
        username: user.username,
        email: user.email,
      });
    } catch (err) {
      console.warn(`caught error in tracking script`);
    }
  }, [user]);

  useEffect(() => {
    if (!org) return;
    try {
      Sentry.setContext("organization", { id: org.id, name: org.name });
    } catch (err) {
      console.warn(`caught error in tracking script`);
    }
  }, [org]);

  return null;
};
