import { FC } from "react";
import { Link } from "react-router-dom";
import { Menu } from "@mantine/core";
import { showNotification } from "@mantine/notifications";

import { updateRuleBoard } from "@shared/api";
import { POLICY_TABS_TO_DISPLAY_NAME_MAPPING } from "@shared/constants";
import { useBasePath, useOrg, useUser } from "@shared/hooks";
import {
  PolicyProduct,
  ProductRuleItem,
  RuleBoardUpdate,
  RuleItem,
} from "@shared/types";

import { productToSlug, productToUrl } from "../../utils";
import { LOGGED_OUT_STATE, NO_ORG_STATE } from "../MenuStates";

export interface SinglePolicyModesMenuProps {
  ruleItemsToAdd: ProductRuleItem[];
}

export const SinglePolicyModesMenu: FC<SinglePolicyModesMenuProps> = ({
  ruleItemsToAdd,
}) => {
  const [user] = useUser();
  const [org] = useOrg();
  const basePath = useBasePath();

  const successMessage = (
    addedRules: RuleItem[],
    policy: string,
    product: PolicyProduct
  ) => {
    const rulesAdded =
      addedRules.length === 1
        ? addedRules[0].item.id
        : addedRules.length + " rules";
    return (
      <>
        {`Added ${rulesAdded} to `}
        <Link to={`${basePath}/${productToUrl(product)}?mode=${policy}`}>
          {POLICY_TABS_TO_DISPLAY_NAME_MAPPING[policy]}
        </Link>
      </>
    );
  };

  const updateRuleboardForProduct = async (
    ruleItemsToAdd: ProductRuleItem[],
    product: PolicyProduct,
    policy: string
  ) => {
    const ruleItems = ruleItemsToAdd
      .filter((item) => item.product === product)
      .map(({ ruleItem }) => ruleItem);

    if (ruleItems.length === 0) return;

    const ruleboardUpdates = ruleItems.map((ruleItem) => ({
      ...ruleItem,
      policy,
      update_type: "add",
    })) as RuleBoardUpdate[];

    try {
      await updateRuleBoard(productToSlug(product), ruleboardUpdates, org!.id);
      showNotification({
        title: "Success!",
        message: successMessage(ruleItems, policy, product),
        color: "green",
      });
    } catch (error) {
      showNotification({
        title: "Error",
        message: `Something went wrong: could not update ${product}'s ${POLICY_TABS_TO_DISPLAY_NAME_MAPPING[policy]}. Please try again.`,
        color: "red",
      });
    }
  };

  const handleSubmit = async (policy: string) => {
    const supportedProducts: PolicyProduct[] = ["sast", "secrets"];

    const updatePromises = supportedProducts.map((product) =>
      updateRuleboardForProduct(ruleItemsToAdd, product, policy)
    );

    try {
      await Promise.all(updatePromises);
    } catch (error) {
      showNotification({
        title: "Error",
        message:
          "Something went wrong: could not update policies. Please try again.",
        color: "red",
      });
    }
  };

  // Zero states and error states
  if (user === undefined) return LOGGED_OUT_STATE;
  if (org === undefined) return NO_ORG_STATE;

  return (
    <>
      {Object.keys(POLICY_TABS_TO_DISPLAY_NAME_MAPPING).map((policy) => (
        <Menu.Item
          onClick={() => handleSubmit(policy)}
          key={policy}
          data-testid={policy}
        >
          {POLICY_TABS_TO_DISPLAY_NAME_MAPPING[policy]}
        </Menu.Item>
      ))}
    </>
  );
};
