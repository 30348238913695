import { FC, useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Group,
  Loader,
  LoadingOverlay,
  Stack,
  Text,
  Textarea,
  Transition,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";

import { postRuleGeneration } from "@shared/ai";
import { LanguageSelect } from "@shared/components";
import { useOrg, useTaskResult } from "@shared/hooks";

import { WorkbenchContext } from "../../providers";

const BAD_CODE_PLACEHOLDER = `class Post(models.Model):
    def save(self, *args, **kwargs):
        if not self.slug:
            self.slug = slugify(self.title)
`;

const GOOD_CODE_PLACEHOLDER = `class Post(models.Model):
    def save(self, *args, **kwargs):
        if not self.slug:
            self.slug = slugify(self.title)
        super(Post, self).save(*args, **kwargs)
`;

const actions = [
  "Searching the web for library specific information",
  "Finding docs to understand the code",
  "Learning how to use Semgrep patterns",
  "Generating the rule",
  "Validating the rule against the Semgrep Engine",
];

interface Props {
  onClose: () => void;
  onFormDirtyChange?: (isDirty: boolean) => void;
}

export const AiRuleForm: FC<Props> = ({ onClose, onFormDirtyChange }) => {
  const [org] = useOrg();
  const { workbench } = useContext(WorkbenchContext);
  const [loading, setLoading] = useState(false);
  const [currentAction, setCurrentAction] = useState(actions[0]);
  const [actionIndex, setActionIndex] = useState(0);
  const [active, setActive] = useState(true);

  const taskResult = useTaskResult({
    startTaskFn: async () => {
      if (!org) throw new Error("Organization not found");
      setLoading(true);
      const { task_token_jwt } = await postRuleGeneration({
        orgId: org.id,
        request: form.values,
      });
      if (!task_token_jwt) throw new Error("Task token not found");
      return task_token_jwt;
    },
    options: {
      onSuccess: (data) => {
        workbench.newBundle({
          newPattern: data.value as string,
          newTarget: `# ruleid: generated-rule-${form.values.language}\n${form.values.bad_code}\n# ok: generated-rule-${form.values.language}\n${form.values.good_code}`,
        });
        workbench.bundle?.run();
        onClose();
      },
      onTaskResultErrorFromCelery: () => {
        showNotification({
          title: "Rule generation failed",
          message: "Please try again later.",
          color: "red",
          autoClose: true,
        });
        setLoading(false);
      },
      onTaskResultErrorFromPolling: () => {
        showNotification({
          title: "Rule generation failed",
          message: "Please try again later.",
          color: "red",
          autoClose: true,
        });
        setLoading(false);
      },
    },
  });

  useEffect(() => {
    let interval: NodeJS.Timeout;
    let mountedInterval: NodeJS.Timeout;
    if (loading) {
      mountedInterval = setInterval(() => setActive(false), 3500);
      interval = setInterval(() => {
        setActionIndex((prevIndex) => (prevIndex + 1) % actions.length);
        setActive(true);
      }, 5000);
    }

    setCurrentAction(actions[actionIndex]);

    return () => {
      clearInterval(interval);
      clearInterval(mountedInterval);
    };
  }, [loading, actionIndex]);
  const form = useForm({
    initialValues: {
      description: "",
      language: "python",
      good_code: "",
      bad_code: "",
    },
    validate: {
      description: isNotEmpty(),
      good_code: isNotEmpty(),
      bad_code: isNotEmpty(),
    },
  });

  useEffect(() => {
    if (onFormDirtyChange) {
      onFormDirtyChange(form.isDirty());
    }
  }, [form, onFormDirtyChange]);

  if (!org)
    return (
      <Alert title="Login required" color="red">
        Please sign in to generate a rule with Semgrep Assistant.
      </Alert>
    );

  return (
    <>
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
        loaderProps={{
          children: (
            <Stack align="center" m={"1em"} justify="center" gap="xl">
              <Group>
                <Loader size="xl" />
              </Group>
              <Transition
                transition="slide-down"
                mounted={loading && active}
                duration={3000}
                timingFunction="ease"
              >
                {(styles) => (
                  <Text style={styles}>
                    {currentAction}
                    {"..."}
                  </Text>
                )}
              </Transition>
              <Text>Rule generation should finish in 1-2 minutes</Text>
            </Stack>
          ),
        }}
      />
      <Stack>
        <Alert color="pink" title="This feature is currently in beta">
          Assistant may output incorrect rules for complex requests and major
          improvements to its performance are coming soon. Reach out to the team
          via{" "}
          <a
            href="https://go.semgrep.dev/slack"
            target="_blank"
            rel="noopener noreferrer"
          >
            our community Slack
          </a>{" "}
          if you have any questions!
        </Alert>
        <LanguageSelect
          onChange={form.getInputProps("language").onChange}
          value={form.getInputProps("language").value}
        />
        <Textarea
          label="Prompt"
          description="Instructions for the rule generation model. Describe what you want the rule to do as clearly as possible."
          withAsterisk
          placeholder="The save method of Django models must always call super().save()"
          {...form.getInputProps("description")}
        />
        <Textarea
          label="Example bad code"
          description="Code that should trigger the rule"
          placeholder={BAD_CODE_PLACEHOLDER}
          styles={(theme) => ({
            input: { fontFamily: theme.fontFamilyMonospace },
          })}
          autosize
          minRows={5}
          maxRows={10}
          {...form.getInputProps("bad_code")}
        />
        <Textarea
          label="Example good code"
          description="Code that should not trigger the rule"
          placeholder={GOOD_CODE_PLACEHOLDER}
          styles={(theme) => ({
            input: { fontFamily: theme.fontFamilyMonospace },
          })}
          autosize
          minRows={5}
          maxRows={10}
          {...form.getInputProps("good_code")}
        />
        <Button onClick={taskResult.startTask} disabled={loading}>
          Generate
        </Button>
      </Stack>
    </>
  );
};
