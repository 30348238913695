import { ReactNode } from "react";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import {
  faDroplet,
  faFlask,
  faMemo,
  faMemoCircleCheck,
  faSquareEllipsis,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Center, HoverCard, Tooltip } from "@mantine/core";

import { DataflowInfoTooltipContent } from "../components/DataflowInfoTooltipContent";
import { RulePreviewTabs } from "../types";
const findingTab = (hasFix: boolean) => {
  return (
    <Center>
      <FontAwesomeIcon icon={faGithub} />
      <Box ml={8} mr={6}>
        {hasFix ? "Your code & fix" : "Your code"}
      </Box>
      <Tooltip
        withinPortal
        w={300}
        multiline
        position="bottom"
        label="This is the source file in which a match was found."
      >
        <div>
          <FontAwesomeIcon icon={faInfoCircle} />
        </div>
      </Tooltip>
    </Center>
  );
};
/**
 * labels for the segmented control, pulled into a constant because
 * we'll use this in both the regular and small screen views
 */
export const RULE_PREVIEW_TABS_LABELS: { [key in RulePreviewTabs]: ReactNode } =
  {
    pattern: (
      <Center>
        <FontAwesomeIcon icon={faSquareEllipsis} />
        <Box ml={8}>Pattern</Box>
      </Center>
    ),
    metadata: (
      <Center>
        <FontAwesomeIcon icon={faMemo} />
        <Box ml={8}>Metadata</Box>
      </Center>
    ),
    validators: (
      <Center>
        <FontAwesomeIcon icon={faMemoCircleCheck} />
        <Box ml={8}>Validators</Box>
      </Center>
    ),
    "example-code": (
      <Center>
        <FontAwesomeIcon icon={faFlask} />
        <Box ml={8} mr={6}>
          Example code
        </Box>
        <Tooltip
          withinPortal
          w={300}
          multiline
          position="bottom"
          label="This is example test code taken from the rule's documentation. Your source code is not sent to Semgrep AppSec Platform."
        >
          <Box>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Box>
        </Tooltip>
      </Center>
    ),
    "dataflow-trace": (
      <Center>
        <FontAwesomeIcon icon={faDroplet} />
        <Box ml={8} mr={6}>
          Data flow
        </Box>
        <HoverCard withinPortal>
          <HoverCard.Target>
            <FontAwesomeIcon icon={faInfoCircle} />
          </HoverCard.Target>
          <HoverCard.Dropdown bg="dark.7">
            <DataflowInfoTooltipContent />
          </HoverCard.Dropdown>
        </HoverCard>
      </Center>
    ),
    "finding-code": findingTab(false),
    "finding-code-with-fix": findingTab(true),
    rule: (
      <Center>
        <FontAwesomeIcon icon={faMemo} />
        <Box ml={8}>Rule</Box>
      </Center>
    ),
    "path-to-transitivity": (
      <Center>
        <Box ml={8}>Dependency Path</Box>
      </Center>
    ),
  };
