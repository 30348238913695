import { Text } from "@mantine/core";

/* beta label that is just formatted superscript text
  put this label within a <Text> component to get the correct font size! */
export const BetaLabel = () => {
  return (
    <Text
      inherit
      component="sup"
      span
      fz="max(10px, 0.65em)"
      pl={4}
      tt="uppercase"
      lts="0.02em"
    >
      beta
    </Text>
  );
};
