import { Ruleset } from "@shared/types";

export const SECRETS_AI_RULESET = "semgrep-secrets-ai";

const SECRETS_RULESETS = [
  "secrets-default",
  "semgrep-secrets",
  SECRETS_AI_RULESET,
];

export function isSecretsRuleset(ruleset: Ruleset): boolean {
  // TODO: Switch to using ruleset.type once available
  return SECRETS_RULESETS.includes(ruleset.name);
}
