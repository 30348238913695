import { authPost } from "@shared/api";

import {
  RuleGenerationRequest,
  RuleGenerationTaskStartResponse,
} from "../types";

export interface PostRuleGenerationParams {
  orgId: number;
  request: RuleGenerationRequest;
}

export const postRuleGeneration = async ({
  orgId,
  request,
}: PostRuleGenerationParams): Promise<RuleGenerationTaskStartResponse> => {
  const response = await authPost<
    RuleGenerationRequest,
    RuleGenerationTaskStartResponse
  >(`/api/ai/deployments/${orgId}/rule_generations`, request);
  return response;
};
