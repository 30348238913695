import React from "react";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Menu } from "@mantine/core";

import { ALLOWED_VISUAL_PATTERN_KEYS } from "@shared/types";

interface Props {
  onChange: (pattern: ALLOWED_VISUAL_PATTERN_KEYS) => void;
  value: ALLOWED_VISUAL_PATTERN_KEYS;
  disabled: boolean;
}

const typeFromText = (selection: string) => {
  let newType: ALLOWED_VISUAL_PATTERN_KEYS = "pattern";
  switch (selection) {
    case "and is inside":
      newType = "pattern-inside";
      break;
    case "and is not":
      newType = "pattern-not";
      break;
    case "and is not inside":
      newType = "pattern-not-inside";
      break;
    case "and autofix is":
      newType = "pattern-fix";
      break;
    case "and matches regex":
      newType = "pattern-regex";
      break;
  }
  return newType;
};

const textFromType = (
  isFirst: boolean,
  selection: ALLOWED_VISUAL_PATTERN_KEYS
) => {
  if (isFirst && selection !== "pattern-fix") return "code is";
  let newString: string = "or is";
  switch (selection) {
    case "pattern":
      newString = "or is";
      break;
    case "pattern-inside":
      newString = "and is inside";
      break;
    case "pattern-not":
      newString = "and is not";
      break;
    case "pattern-not-inside":
      newString = "and is not inside";
      break;
    case "pattern-fix":
      newString = "and autofix is";
      break;
    case "pattern-regex":
      newString = "and matches regex";
      break;
  }
  return newString;
};

const ITEMS = [
  "code is",
  "or is",
  "and is inside",
  "and is not",
  "and is not inside",
  "and matches regex",
  "and autofix is",
];

export const PatternSelect: React.FC<Props> = ({
  onChange,
  value,
  disabled,
}) => {
  return (
    <Menu position="bottom-start">
      <Menu.Dropdown>
        {ITEMS.map((item, idx) => (
          <Menu.Item key={idx} onClick={() => onChange(typeFromText(item))}>
            {item}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
      <Menu.Target>
        <Button
          leftSection={
            disabled ? undefined : <FontAwesomeIcon icon={faCaretDown} />
          }
          disabled={disabled}
          variant="default"
          w={180}
          style={{
            flexBasis: 160,
          }}
        >
          {textFromType(disabled, value)}
        </Button>
      </Menu.Target>
    </Menu>
  );
};
