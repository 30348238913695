export const TIME_LOOKBACK_RANGES_TO_DAYS_AGO = {
  "1 day": 1,
  "7 days": 7,
  "1 month": 30,
  "3 months": 90,
  "6 months": 180,
  "1 year": 365,
  "All time": Infinity,
} as const;

export type TimeLookbackRange = keyof typeof TIME_LOOKBACK_RANGES_TO_DAYS_AGO;

export const TIME_LOOKBACK_RANGES = Object.keys(
  TIME_LOOKBACK_RANGES_TO_DAYS_AGO
) as TimeLookbackRange[];
