import { useContext } from "react";
import dayjs from "dayjs";
import { Paper, Table, Text } from "@mantine/core";

import { useOrg } from "@shared/hooks";

import { WorkbenchContext } from "../../providers";
import { getRecentlyViewedRules } from "../../utils";

import styles from "./SplashRecents.module.css";

export const SplashRecents = () => {
  const [org] = useOrg();
  const { workbench } = useContext(WorkbenchContext);
  const recentlyVisitedRules = org ? getRecentlyViewedRules(org.name) : [];

  if (recentlyVisitedRules.length === 0) {
    return <Paper p="md">No recently viewed rules</Paper>;
  }

  return (
    <Paper withBorder radius="sm" style={{ overflow: "hidden" }}>
      <Table striped highlightOnHover style={{ cursor: "pointer" }}>
        <Table.Tbody>
          {recentlyVisitedRules.map((rule, idx) => (
            <Table.Tr
              key={idx}
              onClick={() => workbench.fetchBundle(rule.file_path)}
            >
              <Table.Td className={styles.ruleName}>{rule.name}</Table.Td>
              <Table.Td>
                <Text c="gray.3">|</Text>
              </Table.Td>
              <Table.Td style={{ whiteSpace: "nowrap" }}>
                {dayjs(new Date(rule.last_viewed)).fromNow()}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Paper>
  );
};
