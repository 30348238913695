import styled from "styled-components";

interface Props {
  dark?: boolean;
}

export const KeyboardShortcutHint = styled.span<Props>`
  padding: 2px;
  font-size: var(--mantine-font-size-xs);
  vertical-align: middle;
  background: ${({ dark = false }) =>
    dark ? "rgba(0, 0, 0, 0.1)" : "rgba(255, 255, 255, 0.1)"};
  border-radius: 3px;
`;
