import { useEffect, useMemo, useState } from "react";
import { usePrefab } from "@prefab-cloud/prefab-cloud-react";

type PrefabValueType = "number" | "boolean" | "string" | "string[]";

type ConvertedType<T extends PrefabValueType> = T extends "number"
  ? number | undefined
  : T extends "boolean"
  ? boolean | undefined
  : T extends "string[]"
  ? string[]
  : T extends "string"
  ? string
  : never;

/**
 * Hook to get the value of a prefab key. You can type the return value with the generic type.
 * @param key Key of the prefab value
 * @param type Type we want to cast the value to
 * @returns Object with the data, loading and isEnabled properties
 */
export const usePrefabValue = <T extends PrefabValueType>(
  key: string,
  type: T
) => {
  const { loading, get, keys, isEnabled } = usePrefab();
  const [fetchedKeys, setFetchedKeys] = useState<string[]>([]);
  const [rawValue, setRawValue] = useState<unknown>(undefined);

  useEffect(() => {
    if (keys.length > 0) {
      if (!keys.includes(key)) {
        console.error(`Key ${key} not found in prefab`);
      } else {
        setFetchedKeys(keys);
      }
    }
  }, [keys, loading, key]);

  useEffect(() => {
    if (!loading && fetchedKeys.length > 0) {
      setRawValue(get(key));
    }
  }, [keys, loading, get, key, fetchedKeys]);

  return useMemo(() => {
    const convertRawData = (value: unknown): ConvertedType<T> => {
      switch (type) {
        case "number": {
          const num = Number(value);
          if (isNaN(num)) {
            console.error(`Could not convert "${value}" to a number`);
            return undefined as ConvertedType<T>;
          } else {
            return num as ConvertedType<T>;
          }
        }

        case "boolean": {
          if (typeof value === "boolean") return value as ConvertedType<T>;
          if (value === "true") return true as ConvertedType<T>;
          if (value === "false") return false as ConvertedType<T>;
          return undefined as ConvertedType<T>;
        }

        case "string[]": {
          if (Array.isArray(value)) {
            return value.map(String) as ConvertedType<T>;
          }
          if (typeof value === "string") {
            try {
              const parsed = JSON.parse(value);
              if (Array.isArray(parsed)) {
                return parsed.map(String) as ConvertedType<T>;
              }
            } catch {
              return [] as unknown as ConvertedType<T>;
            }
          }
          return [] as unknown as ConvertedType<T>;
        }

        case "string":
        default:
          return String(value) as ConvertedType<T>;
      }
    };

    return {
      data: !rawValue ? undefined : convertRawData(rawValue),
      isLoading: loading,
      isEnabled: isEnabled(key),
    };
  }, [rawValue, loading, isEnabled, key, type]);
};
