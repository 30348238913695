// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/notifications/v1/notifications.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "protos.notifications.v1";

export enum Severity {
  UNSPECIFIED = "SEVERITY_UNSPECIFIED",
  INFO = "SEVERITY_INFO",
  WARNING = "SEVERITY_WARNING",
  ERROR = "SEVERITY_ERROR",
}

export function severityFromJSON(object: any): Severity {
  switch (object) {
    case 0:
    case "SEVERITY_UNSPECIFIED":
      return Severity.UNSPECIFIED;
    case 1:
    case "SEVERITY_INFO":
      return Severity.INFO;
    case 2:
    case "SEVERITY_WARNING":
      return Severity.WARNING;
    case 3:
    case "SEVERITY_ERROR":
      return Severity.ERROR;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Severity");
  }
}

export function severityToJSON(object: Severity): string {
  switch (object) {
    case Severity.UNSPECIFIED:
      return "SEVERITY_UNSPECIFIED";
    case Severity.INFO:
      return "SEVERITY_INFO";
    case Severity.WARNING:
      return "SEVERITY_WARNING";
    case Severity.ERROR:
      return "SEVERITY_ERROR";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Severity");
  }
}

export function severityToNumber(object: Severity): number {
  switch (object) {
    case Severity.UNSPECIFIED:
      return 0;
    case Severity.INFO:
      return 1;
    case Severity.WARNING:
      return 2;
    case Severity.ERROR:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Severity");
  }
}

export enum Status {
  UNSPECIFIED = "STATUS_UNSPECIFIED",
  DELIVERED = "STATUS_DELIVERED",
  SEEN = "STATUS_SEEN",
  ARCHIVED = "STATUS_ARCHIVED",
}

export function statusFromJSON(object: any): Status {
  switch (object) {
    case 0:
    case "STATUS_UNSPECIFIED":
      return Status.UNSPECIFIED;
    case 1:
    case "STATUS_DELIVERED":
      return Status.DELIVERED;
    case 2:
    case "STATUS_SEEN":
      return Status.SEEN;
    case 3:
    case "STATUS_ARCHIVED":
      return Status.ARCHIVED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Status");
  }
}

export function statusToJSON(object: Status): string {
  switch (object) {
    case Status.UNSPECIFIED:
      return "STATUS_UNSPECIFIED";
    case Status.DELIVERED:
      return "STATUS_DELIVERED";
    case Status.SEEN:
      return "STATUS_SEEN";
    case Status.ARCHIVED:
      return "STATUS_ARCHIVED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Status");
  }
}

export function statusToNumber(object: Status): number {
  switch (object) {
    case Status.UNSPECIFIED:
      return 0;
    case Status.DELIVERED:
      return 1;
    case Status.SEEN:
      return 2;
    case Status.ARCHIVED:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Status");
  }
}

export interface CreateNotificationRequest {
  title: string;
  description: string;
  severity: Severity;
  adminOnly: boolean;
  link?: string | undefined;
  isExternalLink?: boolean | undefined;
}

export interface CreateNotificationResponse {
  notificationId: string;
}

export interface Notification {
  id: number;
  title: string;
  description: string;
  severity: Severity;
  status: Status;
  adminOnly: boolean;
  createdAt: string;
  link: string;
  isExternalLink: boolean;
}

export interface GetNotificationsResponse {
  notifications: Notification[];
  cursor?: string | undefined;
}

export interface MarkNotificationsAsSeenRequest {
  notificationIds: string[];
}

export interface MarkNotificationsAsSeenResponse {
  notifications: Notification[];
}

export interface GetNewNotificationsCountResponse {
  count: number;
}

function createBaseCreateNotificationRequest(): CreateNotificationRequest {
  return {
    title: "",
    description: "",
    severity: Severity.UNSPECIFIED,
    adminOnly: false,
    link: undefined,
    isExternalLink: undefined,
  };
}

export const CreateNotificationRequest = {
  encode(message: CreateNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.severity !== Severity.UNSPECIFIED) {
      writer.uint32(24).int32(severityToNumber(message.severity));
    }
    if (message.adminOnly !== false) {
      writer.uint32(32).bool(message.adminOnly);
    }
    if (message.link !== undefined) {
      writer.uint32(42).string(message.link);
    }
    if (message.isExternalLink !== undefined) {
      writer.uint32(48).bool(message.isExternalLink);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.severity = severityFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.adminOnly = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.link = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isExternalLink = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateNotificationRequest {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      severity: isSet(object.severity) ? severityFromJSON(object.severity) : Severity.UNSPECIFIED,
      adminOnly: isSet(object.adminOnly) ? globalThis.Boolean(object.adminOnly) : false,
      link: isSet(object.link) ? globalThis.String(object.link) : undefined,
      isExternalLink: isSet(object.isExternalLink) ? globalThis.Boolean(object.isExternalLink) : undefined,
    };
  },

  toJSON(message: CreateNotificationRequest): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.severity !== Severity.UNSPECIFIED) {
      obj.severity = severityToJSON(message.severity);
    }
    if (message.adminOnly !== false) {
      obj.adminOnly = message.adminOnly;
    }
    if (message.link !== undefined) {
      obj.link = message.link;
    }
    if (message.isExternalLink !== undefined) {
      obj.isExternalLink = message.isExternalLink;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateNotificationRequest>, I>>(base?: I): CreateNotificationRequest {
    return CreateNotificationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateNotificationRequest>, I>>(object: I): CreateNotificationRequest {
    const message = createBaseCreateNotificationRequest();
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.severity = object.severity ?? Severity.UNSPECIFIED;
    message.adminOnly = object.adminOnly ?? false;
    message.link = object.link ?? undefined;
    message.isExternalLink = object.isExternalLink ?? undefined;
    return message;
  },
};

function createBaseCreateNotificationResponse(): CreateNotificationResponse {
  return { notificationId: "" };
}

export const CreateNotificationResponse = {
  encode(message: CreateNotificationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.notificationId !== "") {
      writer.uint32(10).string(message.notificationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateNotificationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateNotificationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notificationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateNotificationResponse {
    return { notificationId: isSet(object.notificationId) ? globalThis.String(object.notificationId) : "" };
  },

  toJSON(message: CreateNotificationResponse): unknown {
    const obj: any = {};
    if (message.notificationId !== "") {
      obj.notificationId = message.notificationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateNotificationResponse>, I>>(base?: I): CreateNotificationResponse {
    return CreateNotificationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateNotificationResponse>, I>>(object: I): CreateNotificationResponse {
    const message = createBaseCreateNotificationResponse();
    message.notificationId = object.notificationId ?? "";
    return message;
  },
};

function createBaseNotification(): Notification {
  return {
    id: 0,
    title: "",
    description: "",
    severity: Severity.UNSPECIFIED,
    status: Status.UNSPECIFIED,
    adminOnly: false,
    createdAt: "",
    link: "",
    isExternalLink: false,
  };
}

export const Notification = {
  encode(message: Notification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.severity !== Severity.UNSPECIFIED) {
      writer.uint32(32).int32(severityToNumber(message.severity));
    }
    if (message.status !== Status.UNSPECIFIED) {
      writer.uint32(40).int32(statusToNumber(message.status));
    }
    if (message.adminOnly !== false) {
      writer.uint32(48).bool(message.adminOnly);
    }
    if (message.createdAt !== "") {
      writer.uint32(58).string(message.createdAt);
    }
    if (message.link !== "") {
      writer.uint32(66).string(message.link);
    }
    if (message.isExternalLink !== false) {
      writer.uint32(72).bool(message.isExternalLink);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Notification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.severity = severityFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = statusFromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.adminOnly = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.link = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isExternalLink = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Notification {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      severity: isSet(object.severity) ? severityFromJSON(object.severity) : Severity.UNSPECIFIED,
      status: isSet(object.status) ? statusFromJSON(object.status) : Status.UNSPECIFIED,
      adminOnly: isSet(object.adminOnly) ? globalThis.Boolean(object.adminOnly) : false,
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
      link: isSet(object.link) ? globalThis.String(object.link) : "",
      isExternalLink: isSet(object.isExternalLink) ? globalThis.Boolean(object.isExternalLink) : false,
    };
  },

  toJSON(message: Notification): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.severity !== Severity.UNSPECIFIED) {
      obj.severity = severityToJSON(message.severity);
    }
    if (message.status !== Status.UNSPECIFIED) {
      obj.status = statusToJSON(message.status);
    }
    if (message.adminOnly !== false) {
      obj.adminOnly = message.adminOnly;
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    if (message.link !== "") {
      obj.link = message.link;
    }
    if (message.isExternalLink !== false) {
      obj.isExternalLink = message.isExternalLink;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Notification>, I>>(base?: I): Notification {
    return Notification.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Notification>, I>>(object: I): Notification {
    const message = createBaseNotification();
    message.id = object.id ?? 0;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.severity = object.severity ?? Severity.UNSPECIFIED;
    message.status = object.status ?? Status.UNSPECIFIED;
    message.adminOnly = object.adminOnly ?? false;
    message.createdAt = object.createdAt ?? "";
    message.link = object.link ?? "";
    message.isExternalLink = object.isExternalLink ?? false;
    return message;
  },
};

function createBaseGetNotificationsResponse(): GetNotificationsResponse {
  return { notifications: [], cursor: undefined };
}

export const GetNotificationsResponse = {
  encode(message: GetNotificationsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.notifications) {
      Notification.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.cursor !== undefined) {
      writer.uint32(18).string(message.cursor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNotificationsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNotificationsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notifications.push(Notification.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cursor = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNotificationsResponse {
    return {
      notifications: globalThis.Array.isArray(object?.notifications)
        ? object.notifications.map((e: any) => Notification.fromJSON(e))
        : [],
      cursor: isSet(object.cursor) ? globalThis.String(object.cursor) : undefined,
    };
  },

  toJSON(message: GetNotificationsResponse): unknown {
    const obj: any = {};
    if (message.notifications?.length) {
      obj.notifications = message.notifications.map((e) => Notification.toJSON(e));
    }
    if (message.cursor !== undefined) {
      obj.cursor = message.cursor;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNotificationsResponse>, I>>(base?: I): GetNotificationsResponse {
    return GetNotificationsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNotificationsResponse>, I>>(object: I): GetNotificationsResponse {
    const message = createBaseGetNotificationsResponse();
    message.notifications = object.notifications?.map((e) => Notification.fromPartial(e)) || [];
    message.cursor = object.cursor ?? undefined;
    return message;
  },
};

function createBaseMarkNotificationsAsSeenRequest(): MarkNotificationsAsSeenRequest {
  return { notificationIds: [] };
}

export const MarkNotificationsAsSeenRequest = {
  encode(message: MarkNotificationsAsSeenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.notificationIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarkNotificationsAsSeenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarkNotificationsAsSeenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notificationIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MarkNotificationsAsSeenRequest {
    return {
      notificationIds: globalThis.Array.isArray(object?.notificationIds)
        ? object.notificationIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: MarkNotificationsAsSeenRequest): unknown {
    const obj: any = {};
    if (message.notificationIds?.length) {
      obj.notificationIds = message.notificationIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarkNotificationsAsSeenRequest>, I>>(base?: I): MarkNotificationsAsSeenRequest {
    return MarkNotificationsAsSeenRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarkNotificationsAsSeenRequest>, I>>(
    object: I,
  ): MarkNotificationsAsSeenRequest {
    const message = createBaseMarkNotificationsAsSeenRequest();
    message.notificationIds = object.notificationIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseMarkNotificationsAsSeenResponse(): MarkNotificationsAsSeenResponse {
  return { notifications: [] };
}

export const MarkNotificationsAsSeenResponse = {
  encode(message: MarkNotificationsAsSeenResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.notifications) {
      Notification.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarkNotificationsAsSeenResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarkNotificationsAsSeenResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notifications.push(Notification.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MarkNotificationsAsSeenResponse {
    return {
      notifications: globalThis.Array.isArray(object?.notifications)
        ? object.notifications.map((e: any) => Notification.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MarkNotificationsAsSeenResponse): unknown {
    const obj: any = {};
    if (message.notifications?.length) {
      obj.notifications = message.notifications.map((e) => Notification.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarkNotificationsAsSeenResponse>, I>>(base?: I): MarkNotificationsAsSeenResponse {
    return MarkNotificationsAsSeenResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarkNotificationsAsSeenResponse>, I>>(
    object: I,
  ): MarkNotificationsAsSeenResponse {
    const message = createBaseMarkNotificationsAsSeenResponse();
    message.notifications = object.notifications?.map((e) => Notification.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetNewNotificationsCountResponse(): GetNewNotificationsCountResponse {
  return { count: 0 };
}

export const GetNewNotificationsCountResponse = {
  encode(message: GetNewNotificationsCountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.count !== 0) {
      writer.uint32(8).int64(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNewNotificationsCountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNewNotificationsCountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.count = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNewNotificationsCountResponse {
    return { count: isSet(object.count) ? globalThis.Number(object.count) : 0 };
  },

  toJSON(message: GetNewNotificationsCountResponse): unknown {
    const obj: any = {};
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNewNotificationsCountResponse>, I>>(
    base?: I,
  ): GetNewNotificationsCountResponse {
    return GetNewNotificationsCountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNewNotificationsCountResponse>, I>>(
    object: I,
  ): GetNewNotificationsCountResponse {
    const message = createBaseGetNewNotificationsCountResponse();
    message.count = object.count ?? 0;
    return message;
  },
};

export interface NotificationsService {
  createNotification(request: CreateNotificationRequest): Promise<CreateNotificationResponse>;
  markNotificationsAsSeen(request: MarkNotificationsAsSeenRequest): Promise<MarkNotificationsAsSeenResponse>;
}

export const NotificationsServiceServiceName = "protos.notifications.v1.NotificationsService";
export class NotificationsServiceClientImpl implements NotificationsService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || NotificationsServiceServiceName;
    this.rpc = rpc;
    this.createNotification = this.createNotification.bind(this);
    this.markNotificationsAsSeen = this.markNotificationsAsSeen.bind(this);
  }
  createNotification(request: CreateNotificationRequest): Promise<CreateNotificationResponse> {
    const data = CreateNotificationRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateNotification", data);
    return promise.then((data) => CreateNotificationResponse.decode(_m0.Reader.create(data)));
  }

  markNotificationsAsSeen(request: MarkNotificationsAsSeenRequest): Promise<MarkNotificationsAsSeenResponse> {
    const data = MarkNotificationsAsSeenRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "MarkNotificationsAsSeen", data);
    return promise.then((data) => MarkNotificationsAsSeenResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
