export const SEMGREP_DEV_PRO_ENGINE_INFO_URL =
  "https://semgrep.dev/products/pro-engine/";

export const SEMGREP_DEV_SECRETS_INFO_URL =
  "https://semgrep.dev/products/semgrep-secrets/";

export const SEMGREP_DEMO_REQUEST_URL = "https://semgrep.dev/contact/demo/";

export const SEMGREP_REPO = "https://github.com/semgrep/semgrep";

export const SEMGREP_ACTION_REPO = "https://github.com/semgrep/semgrep-action";

export const SLACK_INVITE_URL =
  "https://join.slack.com/t/r2c-community/shared_invite/enQtNjU0NDYzMjAwODY4LWE3NTg1MGNhYTAwMzk5ZGRhMjQ2MzVhNGJiZjI1ZWQ0NjQ2YWI4ZGY3OGViMGJjNzA4ODQ3MjEzOWExNjZlNTA";

export const TWITTER_URL = "https://twitter.com/semgrep";

export const BASE_GITHUB_URL = "https://github.com/";
