import { OrgData, UserData } from "@shared/types";

type FeatureGate = (data: { user?: UserData; org?: OrgData }) => boolean;

const FEATURE_FLAGS = {
  hasS3Integration: "Adds S3 as an integration option",
  hasTeamsTierTrial:
    "Has the upgraded version of the Semgrep AppSec Platform, but on a trial basis.",
  hasSCATrial:
    "Has the upgraded version of Semgrep Supply Chain, but on a trial basis.",
  forceScaScans:
    "All scans include supply chain scanning, even if the user has not enabled it",
  generateAiPatches:
    "Automatically generate potential patches for AI findings (if available)",
  hasNonStandardTOC:
    "Does not display 'terms' on the app footer or when creating an account (for legal reasons)",
  disableDeploymentAttributeSync:
    "Deployment will not synchronize internal attribute changes with external provider state.",
  useNetworkBroker: "Use semgrep network broker for SCM comments",
  hasProjectPoliciesPanel: "Can view policies panel on project page",
  forcePolicyPinning: "Rule + Ruleset versions are pinned upon save.",
  hasMemberActivePruning: "Members panel will attempt to filter active members",
  hasDisableGithubActivePruning:
    "Disable pruning of active members from Github",
  hasMultipleRuleboards: "Can create multiple rule boards",
  // Used with feature rollouts
  hasProjectLevelRuleExclusion: "Can have rule exclusions on the project level",
  hasAsyncScanResults:
    "Findings are uploaded and processed asynchronously via Celery",
  hasSecretsProduct:
    "Has access to Secrets rules in scans and Secrets product page",
  disableUsageLimitNotifications:
    "Disable notifications for those who have gone over their usage limits",
  hasAiOnFullScans: "Run auto-triage / autofix on new issues of full scans",
  hasTriageForGitlab: "Enable triaging via MR comments on GitLab",
  hasProjectLevelRBACSelfServe:
    "Org admins can enable project-level RBAC for the deployment without assistance",
  hasProjectLevelRBAC:
    "Has project level RBAC (can assign roles to users on a per-project or group basis)",
  hasProjectLevelRBACEnforced:
    "Project-level RBAC is enforced for supported endpoints",
  hasCodeAsSecretsFindings: "Enable code secrets as secrets findings.",
  hasSsoLoginOnly: "Only SSO users can login to this deployment",
  isAssistantPaidDeployment:
    "Overrides the license check for determining whether someone is a paid user for Assistant",
  sendAssistantEmails: "Generate and send Assistant emails for this deployment",
  hasConnectMoreSCMs:
    "Has option to connect more SCMs in Source code managers panel",
  hasProductIgnoreLists:
    "Users have the ability to configure the repository ignore list for each product",
  hasQueryConsoleSavedSearch:
    "Users have the new Query Console Saved Search interface",
  isQueryConsoleInfluencer:
    "Users have Query Console regardless of their active licenses",
  hasMultiIssueJiraTickets:
    "Allows users to create Jira tickets that represent more than one Semgrep issue",
  hasGenericSecrets:
    "Users can triage their Secrets findings with AI-powered generic secrets detection",
  hasFakeReportingData: "Reporting page shows fake data",
  forceFailOpenOnScans:
    "Forces fail open on scans regardless of CLI flags. Sends to the CLI always_suppress_errors=true",
  hasUpdatedSCMOnboarding: "Has the updated SCM agnostic onboarding flow",
  hasPathToTransitivityInScans:
    "Enables scanning paths to transitivity for transitive dependencies in SCA scans",
  hasPrivateRegistrySettings:
    "Has the option to configure private registries on the settings page",
  usesFindingsForSbomExport:
    "Uses the findings data model to populate SBOM vulnerabilities (instead of the old vulns data model)",
  hasSSCPolicyAutomations:
    "Has the option to configure automations for SSC Policies",
} as const;

export type FeatureNameType = keyof typeof FEATURE_FLAGS;

export const FEATURE_FLAG_NAMES = Object.getOwnPropertyNames(
  FEATURE_FLAGS
).sort() as FeatureNameType[];

export const orgHasFeature = (feature: string, org?: OrgData) => {
  const currentTime = new Date().getTime();
  return !!org?.features?.find(
    (f) =>
      f.name === feature &&
      // If expires_at is not set, the feature is always enabled
      // If expires_at is set, the feature is enabled if the expiration date is in the future
      (f.expires_at === "" || Date.parse(f.expires_at) >= currentTime)
  );
};

// Value of each gate checks if a corresponding feature flag is set on the organization
const generate = () => {
  const o = {} as Record<FeatureNameType, FeatureGate>;
  for (const fn of FEATURE_FLAG_NAMES) {
    o[fn as FeatureNameType] = ({ org }) => orgHasFeature(fn, org);
  }
  return o;
};

// Feature's type only has properties with names in FEATURE_FLAG_NAMES
export const Feature = generate();
