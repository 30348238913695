import { DEFAULT_PATTERN_COMPONENT } from "@shared/constants";
import { Language, SimpleRule } from "@shared/types";

export const DEFAULT_RULE = (language: Language): SimpleRule => {
  // TODO: import these from semgrep_app/constants.py
  return {
    id: "untitled_rule",
    patterns: [DEFAULT_PATTERN_COMPONENT],
    languages: [language],
    message: "Semgrep found a match",
    severity: "WARNING",
    fix: "",
  };
};
