import { useCallback, useRef } from "react";
import { useContext } from "react";
import React from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Select } from "@mantine/core";

import { WorkbenchContext } from "../../../providers";
import { MetavariableConstraintWithKind } from "../types/rule";

interface AnalyzerSelectProps {
  value: string;
  metavariableConstraint: MetavariableConstraintWithKind<"analyzer">;
}

const AnalyzerSelectComponent: React.FC<AnalyzerSelectProps> = ({
  value,
  metavariableConstraint,
}) => {
  const {
    workbench: { bundle },
  } = useContext(WorkbenchContext);

  const onChange = useCallback(
    (newKind: string | null) => {
      if (newKind === value || newKind === null) {
        return;
      }
      runInAction(() => {
        metavariableConstraint.value.analyzer = newKind;
        bundle?.onStructureRuleChange();
        return;
      });
    },
    [value, bundle, metavariableConstraint]
  );

  return (
    <Select
      ref={useRef<HTMLInputElement>(null)}
      onChange={onChange}
      allowDeselect={false}
      data={["redos", "entropy"]}
      value={value}
      searchable
      size="xs"
      data-fs-element="Structure analyzer select"
    />
  );
};
export const AnalyzerSelect = observer(AnalyzerSelectComponent);
