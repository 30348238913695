import styled from "styled-components";

interface Props {
  fullText: string;
  highlightedText: string;
}

const Highlight = styled.span`
  font-weight: 600;
  background-color: var(--mantine-color-green-3);
  border-radius: 2px;
  padding: 0 var(--global-spacing-xxs);
`;

export const HighlightedText = ({ fullText, highlightedText }: Props) => {
  if (highlightedText === "") return <span>{fullText}</span>;

  return (
    <span>
      {fullText.split(highlightedText).map((nonMatch, index) => (
        <span key={`${fullText}-fragment-${index}`}>
          {index !== 0 && (
            <Highlight data-testid="highlighted-fragment">
              {highlightedText}
            </Highlight>
          )}
          <span>{nonMatch}</span>
        </span>
      ))}
    </span>
  );
};
