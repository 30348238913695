import React from "react";
import { Text } from "@mantine/core";

import { ConfirmationModal } from "@shared/components";

interface ModeToggleAlertProps {
  opened: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ModeToggleAlert: React.FC<ModeToggleAlertProps> = ({
  onConfirm,
  onCancel,
  opened,
}) => {
  return (
    <ConfirmationModal
      onConfirm={onConfirm}
      onCancel={onCancel}
      opened={opened}
      confirmButtonText={"Overwrite"}
      confirmButtonColor={"yellow"}
    >
      <Text display="inline-block" ml={16}>
        <p>If you switch modes, your draft rule will be deleted.</p>
        <p>Are you sure you want to proceed?</p>
      </Text>
    </ConfirmationModal>
  );
};
