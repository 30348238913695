import { useQuery } from "@tanstack/react-query";

import {
  SearchPublicReposRequest,
  SearchPublicReposResponse,
} from "@protos/search/v1/search_service";
import { authGet } from "@shared/api";

import { useOrg } from "../useOrg";

export const usePublicGithubRepos = (
  options: Omit<SearchPublicReposRequest, "deploymentId">
) => {
  const [org] = useOrg();
  const params = new URLSearchParams({
    ...options,
    deploymentId: org?.id.toString() || "",
  });
  return useQuery({
    queryKey: ["publicGithubRepos", options],

    queryFn: () =>
      authGet<SearchPublicReposResponse>(
        `/api/console/repos/public?${params.toString()}`
      ),

    enabled: !!options.query && !!org?.id,
  });
};
