interface ErrorBody {
  error?: string;
}
export class ApiError extends Error {
  statusCode: number;
  body?: ErrorBody;

  constructor(message: string, statusCode: number, body?: ErrorBody) {
    super(message);
    this.statusCode = statusCode;
    this.name = "ApiError";
    this.body = body;
    // Fix for ensuring `instanceof` works correctly for custom errors
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
