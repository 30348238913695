// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/ai/v1/tasks.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Memory } from "./memories";

export const protobufPackage = "protos.ai.v1";

export enum Chain {
  UNSPECIFIED = "CHAIN_UNSPECIFIED",
  AUTOTRIAGE = "CHAIN_AUTOTRIAGE",
  AUTOTRIAGE_WITH_GUIDANCE_AND_AUTOFIX = "CHAIN_AUTOTRIAGE_WITH_GUIDANCE_AND_AUTOFIX",
  ISSUE_TAGS = "CHAIN_ISSUE_TAGS",
}

export function chainFromJSON(object: any): Chain {
  switch (object) {
    case 0:
    case "CHAIN_UNSPECIFIED":
      return Chain.UNSPECIFIED;
    case 1:
    case "CHAIN_AUTOTRIAGE":
      return Chain.AUTOTRIAGE;
    case 2:
    case "CHAIN_AUTOTRIAGE_WITH_GUIDANCE_AND_AUTOFIX":
      return Chain.AUTOTRIAGE_WITH_GUIDANCE_AND_AUTOFIX;
    case 3:
    case "CHAIN_ISSUE_TAGS":
      return Chain.ISSUE_TAGS;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Chain");
  }
}

export function chainToJSON(object: Chain): string {
  switch (object) {
    case Chain.UNSPECIFIED:
      return "CHAIN_UNSPECIFIED";
    case Chain.AUTOTRIAGE:
      return "CHAIN_AUTOTRIAGE";
    case Chain.AUTOTRIAGE_WITH_GUIDANCE_AND_AUTOFIX:
      return "CHAIN_AUTOTRIAGE_WITH_GUIDANCE_AND_AUTOFIX";
    case Chain.ISSUE_TAGS:
      return "CHAIN_ISSUE_TAGS";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Chain");
  }
}

export function chainToNumber(object: Chain): number {
  switch (object) {
    case Chain.UNSPECIFIED:
      return 0;
    case Chain.AUTOTRIAGE:
      return 1;
    case Chain.AUTOTRIAGE_WITH_GUIDANCE_AND_AUTOFIX:
      return 2;
    case Chain.ISSUE_TAGS:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Chain");
  }
}

export interface AnalysisUsageInfo {
  deploymentId: number;
  deploymentName: string;
  used: number;
  maxAllowed: number;
}

export interface ListAllAnalyzeUsageInfo {
  allUsages: AnalysisUsageInfo[];
}

export interface Task {
  deploymentId: number;
  issueIds: number[];
  chains: Chain[];
  regenerate: boolean;
  memory?: Memory | undefined;
}

export interface PostTaskRequest {
  task: Task | undefined;
  queryParams: string;
}

export interface BackfillStatus {
  waitTime: number;
  runsRemaining: number;
}

function createBaseAnalysisUsageInfo(): AnalysisUsageInfo {
  return { deploymentId: 0, deploymentName: "", used: 0, maxAllowed: 0 };
}

export const AnalysisUsageInfo = {
  encode(message: AnalysisUsageInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    if (message.deploymentName !== "") {
      writer.uint32(18).string(message.deploymentName);
    }
    if (message.used !== 0) {
      writer.uint32(24).uint64(message.used);
    }
    if (message.maxAllowed !== 0) {
      writer.uint32(32).uint64(message.maxAllowed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnalysisUsageInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnalysisUsageInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deploymentName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.used = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.maxAllowed = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AnalysisUsageInfo {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      deploymentName: isSet(object.deploymentName) ? globalThis.String(object.deploymentName) : "",
      used: isSet(object.used) ? globalThis.Number(object.used) : 0,
      maxAllowed: isSet(object.maxAllowed) ? globalThis.Number(object.maxAllowed) : 0,
    };
  },

  toJSON(message: AnalysisUsageInfo): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.deploymentName !== "") {
      obj.deploymentName = message.deploymentName;
    }
    if (message.used !== 0) {
      obj.used = Math.round(message.used);
    }
    if (message.maxAllowed !== 0) {
      obj.maxAllowed = Math.round(message.maxAllowed);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AnalysisUsageInfo>, I>>(base?: I): AnalysisUsageInfo {
    return AnalysisUsageInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AnalysisUsageInfo>, I>>(object: I): AnalysisUsageInfo {
    const message = createBaseAnalysisUsageInfo();
    message.deploymentId = object.deploymentId ?? 0;
    message.deploymentName = object.deploymentName ?? "";
    message.used = object.used ?? 0;
    message.maxAllowed = object.maxAllowed ?? 0;
    return message;
  },
};

function createBaseListAllAnalyzeUsageInfo(): ListAllAnalyzeUsageInfo {
  return { allUsages: [] };
}

export const ListAllAnalyzeUsageInfo = {
  encode(message: ListAllAnalyzeUsageInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.allUsages) {
      AnalysisUsageInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAllAnalyzeUsageInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAllAnalyzeUsageInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.allUsages.push(AnalysisUsageInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAllAnalyzeUsageInfo {
    return {
      allUsages: globalThis.Array.isArray(object?.allUsages)
        ? object.allUsages.map((e: any) => AnalysisUsageInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListAllAnalyzeUsageInfo): unknown {
    const obj: any = {};
    if (message.allUsages?.length) {
      obj.allUsages = message.allUsages.map((e) => AnalysisUsageInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAllAnalyzeUsageInfo>, I>>(base?: I): ListAllAnalyzeUsageInfo {
    return ListAllAnalyzeUsageInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAllAnalyzeUsageInfo>, I>>(object: I): ListAllAnalyzeUsageInfo {
    const message = createBaseListAllAnalyzeUsageInfo();
    message.allUsages = object.allUsages?.map((e) => AnalysisUsageInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTask(): Task {
  return { deploymentId: 0, issueIds: [], chains: [], regenerate: false, memory: undefined };
}

export const Task = {
  encode(message: Task, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== 0) {
      writer.uint32(8).uint64(message.deploymentId);
    }
    writer.uint32(18).fork();
    for (const v of message.issueIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.chains) {
      writer.int32(chainToNumber(v));
    }
    writer.ldelim();
    if (message.regenerate !== false) {
      writer.uint32(32).bool(message.regenerate);
    }
    if (message.memory !== undefined) {
      Memory.encode(message.memory, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Task {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deploymentId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag === 16) {
            message.issueIds.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.issueIds.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag === 24) {
            message.chains.push(chainFromJSON(reader.int32()));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.chains.push(chainFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.regenerate = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.memory = Memory.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Task {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.Number(object.deploymentId) : 0,
      issueIds: globalThis.Array.isArray(object?.issueIds) ? object.issueIds.map((e: any) => globalThis.Number(e)) : [],
      chains: globalThis.Array.isArray(object?.chains) ? object.chains.map((e: any) => chainFromJSON(e)) : [],
      regenerate: isSet(object.regenerate) ? globalThis.Boolean(object.regenerate) : false,
      memory: isSet(object.memory) ? Memory.fromJSON(object.memory) : undefined,
    };
  },

  toJSON(message: Task): unknown {
    const obj: any = {};
    if (message.deploymentId !== 0) {
      obj.deploymentId = Math.round(message.deploymentId);
    }
    if (message.issueIds?.length) {
      obj.issueIds = message.issueIds.map((e) => Math.round(e));
    }
    if (message.chains?.length) {
      obj.chains = message.chains.map((e) => chainToJSON(e));
    }
    if (message.regenerate !== false) {
      obj.regenerate = message.regenerate;
    }
    if (message.memory !== undefined) {
      obj.memory = Memory.toJSON(message.memory);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Task>, I>>(base?: I): Task {
    return Task.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Task>, I>>(object: I): Task {
    const message = createBaseTask();
    message.deploymentId = object.deploymentId ?? 0;
    message.issueIds = object.issueIds?.map((e) => e) || [];
    message.chains = object.chains?.map((e) => e) || [];
    message.regenerate = object.regenerate ?? false;
    message.memory = (object.memory !== undefined && object.memory !== null)
      ? Memory.fromPartial(object.memory)
      : undefined;
    return message;
  },
};

function createBasePostTaskRequest(): PostTaskRequest {
  return { task: undefined, queryParams: "" };
}

export const PostTaskRequest = {
  encode(message: PostTaskRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.task !== undefined) {
      Task.encode(message.task, writer.uint32(10).fork()).ldelim();
    }
    if (message.queryParams !== "") {
      writer.uint32(18).string(message.queryParams);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostTaskRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostTaskRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.task = Task.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.queryParams = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostTaskRequest {
    return {
      task: isSet(object.task) ? Task.fromJSON(object.task) : undefined,
      queryParams: isSet(object.queryParams) ? globalThis.String(object.queryParams) : "",
    };
  },

  toJSON(message: PostTaskRequest): unknown {
    const obj: any = {};
    if (message.task !== undefined) {
      obj.task = Task.toJSON(message.task);
    }
    if (message.queryParams !== "") {
      obj.queryParams = message.queryParams;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PostTaskRequest>, I>>(base?: I): PostTaskRequest {
    return PostTaskRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostTaskRequest>, I>>(object: I): PostTaskRequest {
    const message = createBasePostTaskRequest();
    message.task = (object.task !== undefined && object.task !== null) ? Task.fromPartial(object.task) : undefined;
    message.queryParams = object.queryParams ?? "";
    return message;
  },
};

function createBaseBackfillStatus(): BackfillStatus {
  return { waitTime: 0, runsRemaining: 0 };
}

export const BackfillStatus = {
  encode(message: BackfillStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.waitTime !== 0) {
      writer.uint32(8).uint64(message.waitTime);
    }
    if (message.runsRemaining !== 0) {
      writer.uint32(16).uint64(message.runsRemaining);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BackfillStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBackfillStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.waitTime = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.runsRemaining = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BackfillStatus {
    return {
      waitTime: isSet(object.waitTime) ? globalThis.Number(object.waitTime) : 0,
      runsRemaining: isSet(object.runsRemaining) ? globalThis.Number(object.runsRemaining) : 0,
    };
  },

  toJSON(message: BackfillStatus): unknown {
    const obj: any = {};
    if (message.waitTime !== 0) {
      obj.waitTime = Math.round(message.waitTime);
    }
    if (message.runsRemaining !== 0) {
      obj.runsRemaining = Math.round(message.runsRemaining);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BackfillStatus>, I>>(base?: I): BackfillStatus {
    return BackfillStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BackfillStatus>, I>>(object: I): BackfillStatus {
    const message = createBaseBackfillStatus();
    message.waitTime = object.waitTime ?? 0;
    message.runsRemaining = object.runsRemaining ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
