import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { createRoot } from "react-dom/client";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";

import { sentryIgnoreErrors } from "@shared/constants";
import { parseTenantFromHostname } from "@shared/hooks";
import { DEFAULT_TENANT_NAME } from "@shared/types";
import { appEnv } from "@shared/utils";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./globalStyles.module.css";

import "./lib/i18n";
import "react-app-polyfill/stable";
TimeAgo.addDefaultLocale(en);

const { environment, tenantName } = parseTenantFromHostname(
  window.location.hostname
);

if (
  ["prod", "staging"].includes(environment) ||
  (environment === "dev2" && tenantName === DEFAULT_TENANT_NAME)
) {
  Sentry.init({
    dsn: "https://d71a25a2a71b4d27a6cc45da98ca30ad@o77510.ingest.sentry.io/5770304",
    environment: environment,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: sentryIgnoreErrors,
    integrations: [
      // NOTE: disabling this as we suspect that it's causing an infinite loop on the Findings page.
      // Context on FIND-1244: https://linear.app/semgrep/issue/FIND-1244/figma-findings-page-not-loading-at-1-yearall-time
      // new Replay({
      //   maskAllText: false,
      //   maskAllInputs: false,
      //   maskInputOptions: {
      //     password: true,
      //   },
      // }),
      new Sentry.BrowserTracing(),
    ],
    tracesSampleRate: 1,
  });
  Sentry.setContext("tenant", { tenantName });

  datadogRum.init({
    applicationId: "df1b64eb-4e47-4f5d-94af-95405cea8774",
    clientToken: "pub92e7a5b93ab691331e1fe1b700aa1004",
    site: "datadoghq.com",
    service: "frontend",
    env: appEnv,
    sampleRate: 100,
    replaySampleRate: 100,
    defaultPrivacyLevel: "allow",
    trackInteractions: true,
    allowedTracingUrls: [
      {
        match: "/https:[/][/].*?[.]?semgrep[.]dev/",
        propagatorTypes: ["tracecontext"],
      },
    ],
    beforeSend: (event) => {
      if (/https:[/][/].*?[.]?semgrep[.]dev[/]embed/.test(event.view.url)) {
        // discard events from embedded widgets
        return false;
      }
      if (
        event.type === "error" &&
        event.error.message.includes("ResizeObserver loop limit exceeded")
      ) {
        // discard ResizeObserver loop limit exceeded errors
        return false;
      }
      return;
    },
  });
  datadogRum.startSessionReplayRecording();
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
