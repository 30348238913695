import { useAtom, useSetAtom } from "jotai";

import { permissionsAtom } from "@shared/state";

export const usePermissions = () => {
  return useAtom(permissionsAtom);
};

export const useSetPermissions = () => {
  return useSetAtom(permissionsAtom);
};
