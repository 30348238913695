/* eslint no-template-curly-in-string: 0 */
import * as monaco from "monaco-editor";

// I do not like monaco types! reason #234 monaco is packaged horribly

export const codeSnippetsProvider = (
  model: monaco.editor.ITextModel,
  position: monaco.Position,
  _context: monaco.languages.CompletionContext,
  _token: monaco.CancellationToken
): monaco.languages.ProviderResult<monaco.languages.CompletionList> => {
  const word = model.getWordUntilPosition(position);
  let parentIsArrayItem = false;
  if (position.lineNumber > 1) {
    const parentLine = model.getLinesContent()[position.lineNumber - 1];
    const firstChar = parentLine.trim().charAt(0);
    parentIsArrayItem = firstChar === "-";
  }
  const currWordRange = {
    startLineNumber: position.lineNumber,
    endLineNumber: position.lineNumber,
    startColumn: word.startColumn,
    endColumn: word.endColumn,
  };
  const spacing = parentIsArrayItem ? "    " : "  ";
  const command: monaco.languages.Command = {
    id: "fullstoryLog",
    title: "FullStory Log",
  };
  const snippets = [
    {
      label: "id",
      kind: monaco.languages.CompletionItemKind.Field,
      documentation:
        "Unique, descriptive identifier, for example: no-unused-variable",
      detail: "string",
      insertText: "id: ${0:untitled_rule}",
    },
    {
      label: "message",
      kind: monaco.languages.CompletionItemKind.Field,
      documentation:
        "Message that includes why Semgrep matched this pattern and how to remediate it",
      detail: "string",
      insertText: "message: ${0:Semgrep found a match}",
    },
    {
      label: "severity",
      kind: monaco.languages.CompletionItemKind.Field,
      documentation:
        "One of the following values: INFO (Low severity), WARNING (Medium severity), or ERROR (High severity). The severity key specifies how critical are the issues that a rule potentially detects. Note: Semgrep Supply Chain differs, as its rules use CVE assignments for severity. ",
      detail: "INFO | WARNING | ERROR",
      insertText: "severity: ${0:INFO}",
    },
    {
      label: "languages",
      kind: monaco.languages.CompletionItemKind.Field,
      documentation: "See language extensions and tags",
      detail: "array",
      insertText: "languages:\n  - ${1:python}\n${0}",
    },
    {
      label: "fix",
      kind: monaco.languages.CompletionItemKind.Field,
      documentation:
        "The fix top-level key allows for simple autofixing of a pattern by suggesting an autofix for each match",
      detail: "object",
      insertText: "fix: $0:",
    },
    {
      label: "mode",
      kind: monaco.languages.CompletionItemKind.Field,
      documentation: "The matching mode of the rule",
      detail: "search | taint",
      insertText: "mode: $0",
    },
    {
      label: "mode: taint",
      kind: monaco.languages.CompletionItemKind.Text,
      documentation:
        "This data-flow analysis mode tracks the flow of untrusted (tainted) data throughout the body of a function or method.",
      detail: "search | taint",
      insertText:
        "mode: taint\npattern-sources:\n  - $1\npattern-sinks:\n  - $2",
    },
    {
      label: "metadata",
      kind: monaco.languages.CompletionItemKind.Field,
      documentation:
        "Arbitrary user-provided data; attach data to rules without affecting Semgrep behavior",
      detail: "object",
      insertText:
        "metadata:\n  category: $1 \n  technology:\n    - $2\n  references:\n    - $3",
    },
    {
      label: "pattern",
      kind: monaco.languages.CompletionItemKind.Field,
      documentation: "Find code matching this expression",
      detail: "string",
      insertText: "pattern: $0",
    },
    {
      label: "patterns",
      kind: monaco.languages.CompletionItemKind.Operator,
      documentation:
        "The patterns operator performs a logical AND operation on one or more child patterns. This is useful for chaining multiple patterns together that all must be true.",
      detail: "array",
      insertText: `patterns:\n${spacing}- $\{1:pattern:}\n${spacing}- $\{2:pattern:}\n${spacing}$0`,
    },
    {
      label: "pattern-either",
      kind: monaco.languages.CompletionItemKind.Operator,
      documentation:
        "The pattern-either operator performs a logical OR operation on one or more child patterns. This is useful for chaining multiple patterns together where any may be true.",
      detail: "array",
      insertText: `pattern-either:\n${spacing}- $\{1:pattern:}\n${spacing}- $\{2:pattern:}\n${spacing}$0`,
    },
    {
      label: "pattern-regex",
      kind: monaco.languages.CompletionItemKind.Field,
      documentation:
        "The pattern-regex operator searches files for substrings matching the given PCRE pattern. This is useful for migrating existing regular expression code search functionality to Semgrep.",
      detail: "string",
      insertText: "pattern-regex: $0",
    },
    {
      label: "focus-metavariable",
      kind: monaco.languages.CompletionItemKind.Operator,
      documentation:
        "The focus-metavariable operator puts the focus, or zooms in, on the code region matched by a single metavariable or a list of metavariables.",
      detail: "string",
      insertText: "focus-metavariable: ${0:\\$X}",
    },
    {
      label: "metavariable-regex",
      kind: monaco.languages.CompletionItemKind.Interface,
      documentation:
        "The metavariable-regex operator searches metavariables for a PCRE regular expression. This is useful for filtering results based on a metavariable’s value.",
      detail: "map",
      insertText: `metavariable-regex:\n${spacing}metavariable: $\{1:\\$X}\n${spacing}regex: $0`,
    },
    {
      label: "metavariable-pattern",
      kind: monaco.languages.CompletionItemKind.Interface,
      documentation:
        "The metavariable-pattern operator matches metavariables with a pattern formula. This is useful for filtering results based on a metavariable’s value.",
      detail: "map",
      insertText: `metavariable-pattern:\n${spacing}metavariable: $\{1:\\$X}\n${spacing}$\{2:pattern:}`,
    },
    {
      label: "metavariable-comparison",
      kind: monaco.languages.CompletionItemKind.Interface,
      documentation:
        "The metavariable-comparison operator compares metavariables against a basic Python comparison expression. This is useful for filtering results based on a metavariable's numeric value.",
      detail: "map",
      insertText: `metavariable-comparison:\n${spacing}comparison: $1\n${spacing}metavariable: $\{2:\\$X}`,
    },
    {
      label: "metavariable-type",
      kind: monaco.languages.CompletionItemKind.Interface,
      documentation:
        "The metavariable-type operator is used to compare metavariables against their types.",
      detail: "map",
      insertText: `metavariable-type:\n${spacing}type: $0`,
    },
    {
      label: "metavariable-analysis",
      kind: monaco.languages.CompletionItemKind.Interface,
      documentation:
        "The metavariable-analysis operator is used to inspect metavariables with predefined analyzers.",
      detail: "map",
      insertText: `metavariable-analysis:\n${spacing}analyzer: $\{1:entropy}\n${spacing}$\{2:metavariable:}`,
    },
    {
      label: "pattern-not",
      kind: monaco.languages.CompletionItemKind.Field,
      documentation:
        "The pattern-not operator is the opposite of the pattern operator. It finds code that does not match its expression.",
      detail: "string",
      insertText: "pattern-not: $0",
    },
    {
      label: "pattern-inside",
      kind: monaco.languages.CompletionItemKind.Field,
      documentation:
        "The pattern-inside operator keeps matched findings that reside within its expression. This is useful for finding code inside other pieces of code like functions or if blocks.",
      detail: "string",
      insertText: `pattern-inside: |\n${spacing}$1\n${spacing}...\n$0`,
    },
    {
      label: "pattern-not-inside",
      kind: monaco.languages.CompletionItemKind.Field,
      documentation:
        "The pattern-not-inside operator keeps matched findings that do not reside within its expression. It is the opposite of pattern-inside.",
      detail: "string",
      insertText: `pattern-not-inside: |\n${spacing}$1\n${spacing}...\n$0`,
    },
    {
      label: "pattern-sources",
      kind: monaco.languages.CompletionItemKind.Operator,
      documentation:
        "Any subexpression that this pattern matches is regarded as a source of taint.",
      detail: "array",
      insertText: "pattern-sources:\n  - $1",
    },
    {
      label: "pattern-propagators",
      kind: monaco.languages.CompletionItemKind.Operator,
      documentation:
        "Any subexpression will propagate taint if it matches this pattern.",
      detail: "array",
      insertText:
        "pattern-propagators:\n  - pattern: $1\n    from: ${2:$X}\n    to: ${3:$Y}",
    },
    {
      label: "pattern-sanitizers",
      kind: monaco.languages.CompletionItemKind.Operator,
      documentation:
        "A sanitizer is specified by a pattern, and any tainted subexpression that is matched by this pattern is regarded as sanitized.",
      detail: "array",
      insertText: "pattern-sanitizers:\n  - $1",
    },
    {
      label: "pattern-sinks",
      kind: monaco.languages.CompletionItemKind.Operator,
      documentation:
        "Any subexpression that matches this pattern is regarded as a sink.",
      detail: "array",
      insertText: "pattern-sinks:\n  - $1",
    },
  ];
  const suggestions = snippets.map((snippet) => {
    return {
      range: currWordRange,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      command: { arguments: [snippet.label], ...command },
      ...snippet,
    };
  });
  return { suggestions: suggestions };
};
