import { useCallback, useState } from "react";
import { MutationFunction, useMutation, useQuery } from "@tanstack/react-query";

import { TaskResultResponse } from "@protos/common/v1/common";
import { authGet } from "@shared/api/lib/auth/auth";

const fetchTaskResult = (
  taskGroupTokenJwt: string
): Promise<TaskResultResponse> => {
  return authGet<TaskResultResponse>(`/api/tasks/${taskGroupTokenJwt}`);
};

interface TaskResultProps<TParameters, TError> {
  startTaskFn: MutationFunction<string, TParameters>;
  options?: {
    onSuccess?: (data: TaskResultResponse) => void;
    onTaskStartError?: (error: TError) => void;
    onTaskResultErrorFromCelery?: (error: string) => void;
    onTaskResultErrorFromPolling?: (error: TError) => void;
  };
}

/**
 * A hook that starts a task with `startTaskFn` and then polls for the result
 * every 1 second until the task is finished.
 * @param props.startTaskFn the api function that is called to start the task.
 * must return a taskTokenJwt, which is used to identify the task and poll for
 * its completion.
 * @param props.options.onSuccess a callback that is called when the task is
 * done (`result.ready` is true).
 * @param props.options.onTaskStartError a callback that is called when
 * startTaskFn returns an error, so the task fails to start.
 * @param props.options.onTaskResultErrorFromCelery a callback that is called when we can
 * successfuly fetch the result from the polling endpoint, but the task has failed.
 * @param props.options.onTaskResultErrorFromPolling a callback that is called when there
 * is a failure in polling from the task result, i.e. there is an error in fetching the result
 * from the polling endpoint.
 * @returns
 * - `result` the result of the task polling react-query hook. This is
 *   of type `TaskResultResponse`.
 * - `startTask` the `mutate` function from `useMutation` that starts the task.
 *   You can pass other arguments to this function like `onSuccess`.
 * - `isWorking` a boolean that is true while the task is running.
 * - `cancel` a function that cancels the task. This stops polling but doesn't
 *    cancel the task on the server.
 */
export function useTaskResult<TParameters = any, TError = unknown>({
  startTaskFn,
  options,
}: TaskResultProps<TParameters, TError>) {
  const [isWorking, setIsWorking] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const taskTokenJwtMutation = useMutation({
    mutationFn: startTaskFn,
    mutationKey: ["taskTokenJwtMutation"],
    onMutate: () => {
      setIsWorking(true);
      setIsCancelled(false);
    },
    onError: (err: TError) => {
      if (options?.onTaskStartError) options.onTaskStartError(err);

      setIsWorking(false);
    },
  });

  const taskTokenJwt = taskTokenJwtMutation.data;

  const taskResultQuery = useQuery({
    queryKey: ["taskResult", taskTokenJwt],
    queryFn: async () => {
      try {
        const result = await fetchTaskResult(taskTokenJwt!);

        if (result.ready) setIsWorking(false);
        if (result.successful && options?.onSuccess) options.onSuccess(result);
        if (result.failed && options?.onTaskResultErrorFromCelery)
          options.onTaskResultErrorFromCelery(result.value);

        return result;
      } catch (error) {
        const err = error as TError;

        if (options?.onTaskResultErrorFromPolling)
          options.onTaskResultErrorFromPolling(err);
        setIsWorking(false);

        throw error;
      }
    },
    enabled: taskTokenJwt !== undefined && !isCancelled,
    refetchInterval: (data) =>
      taskTokenJwt && !data.state.data?.ready ? 1000 : false,
  });

  const cancel = useCallback(() => {
    setIsWorking(false);
    setIsCancelled(true);
  }, []);

  return {
    result: taskResultQuery.data,
    startTask: taskTokenJwtMutation.mutate,
    isWorking,
    cancel,
  };
}
