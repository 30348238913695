import styled from "styled-components";

import { Advisory_Severity } from "@protos/sca/v1/sca";

import { SeverityBadge } from "../SeverityBadge";

import { GradientTransparencyWrapper } from "./GradientTransparencyWrapper";
import { SupplyChainCard } from "./SupplyChainCard";
import { Example } from "./utils";

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.div`
  display: flex;
  font-weight: 500;
  line-height: 1.2em;
`;

export function FindingCard({
  name,
  version,
  upgradeTo,
  severity,
  faded,
  lib,
  goTo,
  activeExample,
}: {
  name: string;
  version: string;
  upgradeTo: string;
  severity: Advisory_Severity;
  faded?: boolean;
  lib: Example;
  goTo?: (name: Example) => void;
  activeExample: Example;
}) {
  return (
    <GradientTransparencyWrapper
      id={"finding-" + lib}
      direction={faded ? "bottom" : undefined}
      onClick={() => goTo && goTo(lib)}
    >
      <SupplyChainCard
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          minWidth: "270px",
          textAlign: "left",
          height: "84px",
        }}
        clickable={!!goTo && activeExample !== lib}
      >
        <Name>{name}</Name>
        <Bottom>
          <SeverityBadge severity={severity} />
          <div style={{ color: "#546075" }}>
            {version} {">"} {upgradeTo}
          </div>
        </Bottom>
      </SupplyChainCard>
    </GradientTransparencyWrapper>
  );
}
