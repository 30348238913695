import { JsonValue } from "@bufbuild/protobuf";
import { datadogRum } from "@datadog/browser-rum";

import { AUTH_URL } from "@shared/constants";
import { ApiError } from "@shared/types";
import { GenericProto } from "@shared/utils";
import {
  clearTokens,
  getAccessToken,
  isAuthenticated,
  setAccessToken,
} from "@shared/utils/lib/authUtils";

import { checkStatus } from "../fetch";

interface IAuthenticateRequest {
  redirectUri: string;
  code: string;
  state: string | null;
}

interface AuthHeaders {
  Authorization: string;
}

export enum AuthStates {
  Unauthorized = "UNAUTHORIZED",
  Authorized = "AUTHORIZED",
}

const CONTENT_TYPE_JSON = { "Content-Type": "application/json" };

export async function checkAuthorized(response: Response): Promise<Response> {
  if (response.status === 401) {
    clearTokens();
    const err = new ApiError(
      `Authorization failed: ${response.statusText}`,
      response.status
    );
    datadogRum.addError(err);
    window.location.reload();
    throw err;
  } else if (response.status === 403) {
    // Session is valid, but not authorized for this action.
    let message = `Authorization failed: ${response.statusText}`;
    const body = await response.clone().json();
    try {
      if (body?.error) {
        message = `${response.statusText} ${body?.error}`;
      }
    } catch {
      // not valid json in the servers response, move on
    }
    throw new ApiError(message, response.status);
  } else if (
    response.status === 422 &&
    (await response.clone().json()).msg === "Unrecognized JWT key ID"
  ) {
    // Session is valid, but not for this server
    clearTokens();
    throw new ApiError("Unrecognized JWT key ID", response.status);
  } else {
    // Response is authorized
    return response;
  }
}

const codeToBearer = async (
  loginInfo: IAuthenticateRequest,
  authProviderName: string = "github"
): Promise<string> => {
  const code = loginInfo.code;
  const state = loginInfo.state;
  const redirectUri = loginInfo.redirectUri;
  let data: {
    code: string;
    redirectUri: string;
    authProviderName: string;
    state?: string;
  } = {
    code,
    redirectUri,
    authProviderName,
  };
  if (state) {
    data = { ...data, state };
  }
  return fetch(`${AUTH_URL}/authenticate`, {
    method: "post",
    headers: {
      Accept: "application/json, */*",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    credentials: "same-origin",
    body: new URLSearchParams(data),
  })
    .then(checkAuthorized)
    .then((res) => res.json())
    .then((data) => data.token);
};

export function buildAuthorizationHeader(): AuthHeaders {
  return {
    Authorization: `Bearer ${getAccessToken()}`,
  };
}

export async function authRequestRaw<B>(
  method: string,
  path: string,
  body?: B,
  headers?: HeadersInit
) {
  const hasBody = body !== undefined;
  const sendHeaders: HeadersInit = {
    Accept: "application/json",
    ...headers,
    ...(isAuthenticated() ? buildAuthorizationHeader() : {}),
    ...(hasBody ? CONTENT_TYPE_JSON : {}),
  };

  let pathPrefix = "";
  // TODO: remove this once we have a better way to test the frontend
  // Node doesn't have the concept of a location origin, so we need to explicitly set localhost:3000 on every api path
  if (
    import.meta.env.SG_TEST_ENV === "true" ||
    process.env.SG_TEST_ENV === "true" // process is undefined with Vite
  ) {
    pathPrefix = "http://localhost:3000";
  }
  const fullPath = pathPrefix + path;
  try {
    const response = await fetch(fullPath, {
      method: method,
      headers: sendHeaders,
      credentials: "same-origin",
      ...(hasBody ? { body: JSON.stringify(body) } : {}),
    });
    return await checkAuthorized(response);
  } catch (e) {
    if (e instanceof ApiError) {
      // e.g. `ApiError: Authorization failed: 401`
      throw e;
    } else if (e instanceof TypeError) {
      // e.g. `TypeError: Failed to fetch`
      throw e;
    }
    // Fallback case for unknown errors
    throw e;
  }
}

/**
 * Returns a promise that resolves to a {@link Response} object without explicitly converting it to JSON.
 * @param method The HTTP method to use
 * @param path The path to the resource
 * @param body The body of the request
 * @param headers overrides to the {@link HeadersInit} object that is sent with the request
 * @returns
 */
export function authWithResponse<B>(
  method: string,
  path: string,
  body: B,
  headers?: HeadersInit
): Promise<Response> {
  return authRequestRaw<B>(method, path, body, headers)
    .then(checkStatus)
    .then((res) => res);
}

export function authGet<R>(path: string): Promise<R> {
  return authRequestRaw("get", path)
    .then(checkStatus)
    .then((res) => res.json());
}

// Allows an authenticated GET request to be made without throwing an error
// if the request does not return a 2xx status code (e.g. 404 response)
export async function authGetUnchecked<R>(path: string): Promise<R | null> {
  const res = await authRequestRaw("get", path);
  if (res.status >= 200 && res.status < 300) {
    return res.json();
  }
  return Promise.resolve(null);
}

export function authGetText(path: string): Promise<string> {
  return authRequestRaw("get", path)
    .then(checkStatus)
    .then((res) => res.text());
}

export function authPatch<B, R>(path: string, body: B): Promise<R> {
  return authRequestRaw<B>("PATCH", path, body)
    .then(checkStatus)
    .then((res) => res.json());
}

export function authPost<B, R>(path: string, body: B): Promise<R> {
  return authRequestRaw<B>("post", path, body)
    .then(checkStatus)
    .then((res) => res.json());
}

export function authPut<B, R>(path: string, body: B): Promise<R> {
  return authRequestRaw<B>("put", path, body)
    .then(checkStatus)
    .then((res) => res.json());
}

export function authDelete<R>(path: string): Promise<R> {
  return authRequestRaw("delete", path)
    .then(checkStatus)
    .then((res) => res.json());
}

export function authDeleteWithBody<B, R>(path: string, body: B): Promise<R> {
  return authRequestRaw<B>("delete", path, body)
    .then(checkStatus)
    .then((res) => res.json());
}

/**
 * Returns a promise that resolves to a response message.
 * Use this if your request body and response are defined by protobufs. It will automatically serialize and deserialize them correctly.
 * @param method The HTTP method to use
 * @param path The path to the resource
 * @param body The body of the request, should be a protobuf message
 * @param ResponseMessageClass The message class of the response, used for deserializing
 * @returns
 */
export function authPostProto<TBody, TResponse>(
  path: string,
  body: TBody,
  ResponseMessageClass: GenericProto<TResponse>
): Promise<TResponse> {
  return authPost<TBody, JsonValue>(path, body).then((response) =>
    ResponseMessageClass.fromJSON(response)
  );
}

export const logoutBack = () => {
  clearTokens();
  window.history.back();
};

export const finishUserAuth = async (
  redirectUri: string,
  code: string,
  state: string | null,
  authProviderName: string = "github",
  useExistingAuth: boolean = false
): Promise<void> => {
  // post code to backend and do auth there;
  const token = useExistingAuth
    ? await authPost<any, { token: string }>(
        `${AUTH_URL}/authenticate_extension`,
        {
          code,
          state,
          redirectUri,
          authProviderName,
        }
      ).then((res) => res.token)
    : await codeToBearer({ redirectUri, code, state }, authProviderName);

  // save bearer token from backend to localStorage
  setAccessToken(token);
};

export const finishSamlAuth = async (token: string): Promise<void> => {
  // TODO drew switch to single-use code in the url param

  // save bearer token from backend to localStorage
  setAccessToken(token);
};
