import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { editor as monacoEditor } from "monaco-editor";
import styled from "styled-components";
import { Monaco } from "@monaco-editor/react";

import { DEFAULT_MONACO_OPTIONS, MonacoEditor } from "@vendors/monaco";

import {
  useDebouncedEffect,
  useDebouncedResize,
  useHighlights,
} from "@shared/hooks";

import { WorkbenchContext } from "../../providers";

interface Props {
  pattern: string;
  onPatternChange: (pattern: string) => void;
  index: number;
}

const MAX_EDITOR_LINES = 10;
const PLACEHOLDER_TEXT = "Your pattern here...";

const Container = styled.div`
  min-height: 30px;
  max-height: 200px;
`;

// floats placeholder text above monaco editor
// since monaco editor has no placeholder option
const Placeholder = styled.div`
  position: absolute;
  z-index: 3;
  pointer-events: none;
  color: #c7c7c7;
  /* Copied from .monaco-editor .view-lines */
  font-family: var(--mantine-font-family-monospace);
  font-weight: 400;
  font-size: var(--mantine-font-size-xs);
  font-feature-settings: "liga" 0, "calt" 0;
  line-height: 18px;
  letter-spacing: 0px;
  margin-left: 18px;
  margin-top: 4px;
`;

const getEditorHeight = (
  monacoInstance: Monaco,
  mountedEditor: monacoEditor.IStandaloneCodeEditor
) => {
  const editorElement = mountedEditor.getDomNode();

  if (!editorElement) {
    return;
  }

  const lineHeight = mountedEditor.getOption(
    monacoInstance.editor.EditorOption.lineHeight
  );
  let lineCount = mountedEditor.getModel()?.getLineCount() || 1;
  lineCount = Math.min(lineCount, MAX_EDITOR_LINES);
  const newHeight = lineCount * lineHeight + 7;
  return newHeight;
};

const PatternEditorComponent: React.FC<Props> = ({
  pattern,
  onPatternChange,
  index,
}) => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle } = workbench;

  const [editor, setEditor] = useState<monacoEditor.IStandaloneCodeEditor>();
  const [monacoInstance, setMonacoInstance] = useState<Monaco>();
  const [model, setModel] = useState<monacoEditor.ITextModel>();
  const [height, setHeight] = useState<number>(0);

  const registerHovers = useHighlights(
    monacoInstance,
    editor,
    bundle!.language,
    [],
    undefined
  );

  const editorDidMount = useMemo(
    () =>
      (
        mountedEditor: monacoEditor.IStandaloneCodeEditor,
        monacoInstance: Monaco
      ) => {
        setEditor(mountedEditor);
        setMonacoInstance(monacoInstance);
        // from https://github.com/microsoft/monaco-editor/issues/794#issuecomment-583367666
        const updateEditorHeight = () => {
          const newHeight = getEditorHeight(monacoInstance, mountedEditor);
          if (newHeight) {
            setHeight(newHeight);
          }
        };
        mountedEditor.onDidChangeModelDecorations(updateEditorHeight);

        const model = mountedEditor.getModel()!;
        setModel(model);
        model.updateOptions({ tabSize: 2 });
        updateEditorHeight();

        registerHovers(monacoInstance);
      },
    [registerHovers, setEditor, setHeight, setModel]
  );

  const container = useRef<HTMLDivElement>(null!);
  useDebouncedResize(container, () => {
    if (editor === undefined) return;
    if (monacoInstance === undefined) return;
    // recalculate the height here as well
    editor.layout();
    const newHeight = getEditorHeight(monacoInstance, editor);
    if (newHeight) {
      setHeight(newHeight);
    }
  });

  useEffect(() => {
    editor?.layout();
  }, [editor, height]);

  useEffect(() => {
    if (monacoInstance === undefined) return;
    editor?.addCommand(
      monacoInstance.KeyMod.CtrlCmd | monacoInstance.KeyCode.Enter,
      bundle!.run
    );
    editor?.addCommand(
      monacoInstance.KeyMod.CtrlCmd | monacoInstance.KeyCode.KeyS,
      workbench.saveOrFork
    );
    // disabling next line because we don't want to depend on the entire bundle
    // eslint-disable-next-line
  }, [monacoInstance, editor, bundle!.run]);

  const updateMarkers = useMemo(
    () => (_?: string) => {
      if (!monacoInstance) return;
      monacoInstance?.editor.setModelMarkers(model!, "yaml", []);
    },
    [model, monacoInstance]
  );

  useDebouncedEffect(
    () => {
      updateMarkers(pattern);
    },
    100,
    [pattern, updateMarkers]
  );

  // we have to put the monaco editor in an absolutely-positioned div so that it will dynamically resize
  // that means its relatively-positioned container will not resize to it
  // so we have to check the size and then manually set it
  const containerClassName = `pattern-editor-container-${index}`;
  const parentContainerClassName = `pattern-editor-container-parent-${index}`;
  const codeTextAreaContainer = document.querySelector(
    "." + containerClassName
  );
  if (codeTextAreaContainer) {
    const parent = document.querySelector(
      "." + parentContainerClassName
    ) as HTMLElement;
    const newParentHeight =
      parseInt(window.getComputedStyle(codeTextAreaContainer).height) + "px";
    if (parent) parent.style.height = newParentHeight;
  }

  return (
    <div style={{ position: "relative" }} className={parentContainerClassName}>
      <div
        style={{ position: "absolute", top: 0, right: 0, left: 0 }}
        className={containerClassName}
      >
        <Container
          ref={container}
          style={{ height: height }}
          className={"pattern-editor"}
        >
          {pattern === "" ? (
            <Placeholder>{PLACEHOLDER_TEXT}</Placeholder>
          ) : undefined}
          <MonacoEditor
            value={pattern}
            onChange={(value: string | undefined) => {
              value !== undefined && onPatternChange(value);
            }}
            onMount={editorDidMount}
            language={bundle?.language ?? "yaml"}
            options={{
              ...DEFAULT_MONACO_OPTIONS,
              automaticLayout: true,
              lineNumbers: "off",
              padding: { top: 4 },
            }}
          />
        </Container>
      </div>
    </div>
  );
};
export const PatternEditor = React.memo(PatternEditorComponent);
