import { useAtom } from "jotai";

import { orgAtom } from "@shared/state";

/**
 * useOrg hook. Used for getting and setting the org using jotai.
 * If you need to update the org object, use the setOrg function returned from this hook.
 * If you need to switch to another org, use the useChangeOrg hook.
 */
export const useOrg = () => {
  return useAtom(orgAtom);
};
