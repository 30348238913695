import { ComponentType, forwardRef } from "react";
import { Tooltip } from "@mantine/core";

// only exported for the purpose of testing
export type FallbackOptions =
  | {
      tooltipText: string;
    }
  | {
      Fallback: React.ReactNode;
    };

interface AccessControlledElementProps {
  TargetComponent: ComponentType<{ disabled?: boolean }>;
  hasAccess: boolean;
  fallbackOptions: FallbackOptions;
}

// minimal wrapper for tooltip child element, which requires a forwardRef
const TooltipChildWrapper = forwardRef<
  HTMLDivElement,
  { children?: React.ReactNode }
>(({ children }, ref) => <div ref={ref}>{children}</div>);

/**
 * An element that is conditionally rendered based on the user's permissions.
 * @param props.TargetComponent - The component to render, should be able to be
 * disabled using the `disabled` prop. For relevant Mantine components, be sure
 * to use the `data-disabled` instead of the `disabled` prop so the tooltip is
 * still displayed, and set the onClick to do nothing if disabled (see example).
 * @param props.hasAccess - Whether the user has permission to view the
 * `TargetComponent`.
 * @param props.fallbackOptions - Determines what to render when the user does
 * not have access to the `TargetComponent`. Either a tooltip or a fallback
 * component.
 * @param props.fallbackOptions.tooltipText: Text to display in a tooltip if the
 * user does not have access. Helpful tooltip text gives the user an action to
 * do to gain access.
 * @param props.fallbackOptions.Fallback: A component to render instead of
 * `TargetComponent` if the user does not have access.
 * @returns `TargetComponent` if the user has access. Else, uses the
 * `fallbackOptions` to determine if the `TargetComponent` is disabled with a
 * tooltip (`props.fallbackOptions.tooltipText`) or if a fallback element is
 * rendered instead (`props.fallbackOptions.Fallback`).
 * @example
 * ```tsx
 * <AccessControlledElement
 *   TargetComponent={({disabled}) => (
 *     <Button
 *       data-disabled={disabled}
 *       onClick={disabled ? () => {} : doSomething()}
 *     />
 *   )}
 *   hasAccess={Feature.hasSomeFeature({ org })}
 *   fallbackOptions={{
 *     tooltipText: "Ask your admin for access to this feature.",
 *   }}
 * />
 * ```
 */
export const AccessControlledElement: React.FC<
  AccessControlledElementProps
> = ({ TargetComponent, hasAccess, fallbackOptions }) => {
  if (hasAccess) {
    return <TargetComponent />;
  }

  // show tooltip
  if ("tooltipText" in fallbackOptions) {
    return (
      // web a11y guidelines say that lines should not be longer than 80 chars
      <Tooltip maw={"80ch"} multiline label={fallbackOptions.tooltipText}>
        <TooltipChildWrapper>
          <TargetComponent disabled />
        </TooltipChildWrapper>
      </Tooltip>
    );
  } else {
    return <>{fallbackOptions.Fallback}</>;
  }
};
