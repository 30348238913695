import { useContext } from "react";
import { observer } from "mobx-react-lite";

import { Code, DeleteModal } from "@shared/components";

import { WorkbenchContext } from "../providers";

const ConfirmDelete = () => {
  const { workbench } = useContext(WorkbenchContext);

  const deleteAndClose = () => {
    workbench.ui.fileToDelete?.delete();
    workbench.ui.closeConfirmDeleteAlert();
  };

  return (
    <DeleteModal
      opened={workbench.ui.isConfirmDeleteAlertOpen}
      onDelete={deleteAndClose}
      onCancel={workbench.ui.closeConfirmDeleteAlert}
      size="auto"
      text={
        <>
          Are you sure you want to delete{" "}
          <Code inline light>
            {workbench.ui.fileToDelete?.title}
          </Code>{" "}
          ?
        </>
      }
    />
  );
};

export const ConfirmDeleteAlert = observer(ConfirmDelete);
