import { useCallback, useEffect, useRef } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import { Button, Group, Modal } from "@mantine/core";

export function PrCreatedDialog({
  onClose,
  prURL,
}: {
  onClose: () => void;
  prURL: string;
}) {
  const refAnimationInstance = useRef<any>(null);

  const getInstance = useCallback((instance: confetti.CreateTypes | null) => {
    refAnimationInstance.current = instance;
  }, []);

  useEffect(() => {
    // Wait for modal to open
    setTimeout(() => {
      // Confeti logic is taken from examples in https://ulitcos.github.io/react-canvas-confetti/
      const makeShot = (particleRatio: any, opts: any) => {
        const instance = refAnimationInstance.current;
        instance &&
          instance({
            ...opts,
            origin: { y: 0.7 },
            particleCount: Math.floor(200 * particleRatio),
          });
      };

      makeShot(0.25, {
        spread: 26,
        startVelocity: 55,
      });

      makeShot(0.2, {
        spread: 60,
      });

      makeShot(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });

      makeShot(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });

      makeShot(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    }, 500);
  }, []);

  return (
    <Modal
      opened={true}
      size="auto"
      onClose={onClose}
      title={`Pull Request Created`}
    >
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={{
          position: "fixed",
          pointerEvents: "none",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        }}
      />
      <div>
        <strong>Upload complete, you rule!</strong>
        <br />
        We're so glad to have you as part of the Semgrep community.
        <br /> Once a maintainer approves the rule it will be published on the
        Semgrep Registry.
      </div>
      <Group justify="right" mt={8}>
        <Button
          component="a"
          href={prURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Check your Pull Request
        </Button>
      </Group>
    </Modal>
  );
}
