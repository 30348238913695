import { useContext } from "react";
import { observer } from "mobx-react-lite";
import * as monaco from "monaco-editor";
import { faTerminal } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Modal, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Monaco } from "@monaco-editor/react";

import {
  DEFAULT_MONACO_OPTIONS,
  DiffEditor,
  MonacoEditor,
} from "@vendors/monaco";

import { AcceptFixButton, Code, Subheading } from "@shared/components";
import { RunButton } from "@shared/editorCore";

import { WorkbenchContext } from "../../providers";
import { EditorWrapper } from "../EditorWrapper";

const Editor = () => {
  const { workbench } = useContext(WorkbenchContext);
  const { bundle, editors } = workbench;

  const [runInCliModalOpen, runInCliModal] = useDisclosure(false);
  const onMount = (
    editor: monaco.editor.IStandaloneCodeEditor,
    monaco: Monaco
  ) => {
    editors.onTargetEditorMount(editor, monaco);
  };

  const onMountDiffEditor = (
    editor: monaco.editor.IStandaloneDiffEditor,
    monaco: Monaco
  ) => {
    editors.onTargetDiffEditorMount(editor, monaco);
  };

  if (bundle === null) return null;

  const { language, targetText, setTargetText, showingAutofixDiff } = bundle;

  // In general, the language that we want is our normalized language from the keys. However, the monaco
  // editor refers to ts as typescript and js as javascript so we have to switch those.
  // this is a really ugly hack and if someone has a better idea, please replace this
  const editorLang =
    language === "ts"
      ? "typescript"
      : language === "js"
      ? "javascript"
      : language;

  return (
    <EditorWrapper
      id="target-editor"
      controls={
        bundle.showingAutofixDiff ? (
          <>
            <AcceptFixButton handleAccept={workbench.handleAcceptFix} />
            <AcceptFixButton reject handleAccept={workbench.handleAcceptFix} />
          </>
        ) : workbench.shouldShowRunButton ? (
          <>
            <RunButton
              isRunning={bundle.isRunning}
              onClick={bundle.run}
              // disable when bundle.rule is missing, it can happen for example when the rule is not valid
              disabled={!bundle.rule}
              menuItems={
                <Menu.Item
                  disabled={
                    workbench.addressString === "new" ||
                    workbench.hasUnsavedChanges
                  }
                  onClick={runInCliModal.open}
                  leftSection={<FontAwesomeIcon icon={faTerminal} />}
                >
                  Run locally in CLI
                </Menu.Item>
              }
            />
            <Modal
              title={
                <Text inherit fw={700}>
                  Run in CLI
                </Text>
              }
              onClose={runInCliModal.close}
              opened={runInCliModalOpen}
              size="lg"
              centered
              styles={{
                content: { backgroundColor: "#F8F9FA" },
                header: { borderBottom: "1px solid lightgray" },
              }}
            >
              <Subheading>To run this rule locally:</Subheading>
              <p>Step 1: install the CLI tool</p>
              <Code copyable>pip install semgrep</Code>
              <p>Step 2: login to the CLI tool</p>
              <Code copyable>semgrep login</Code>
              <p style={{ marginTop: "12px" }}>Step 3: Scan a code directory</p>
              <Code
                copyable
              >{`semgrep --config ${workbench.addressString}`}</Code>
            </Modal>
          </>
        ) : null
      }
    >
      {showingAutofixDiff ? (
        <DiffEditor
          original={targetText}
          modified={showingAutofixDiff || targetText}
          onMount={onMountDiffEditor}
          language={editorLang ?? undefined}
          options={{
            ...DEFAULT_MONACO_OPTIONS,
            readOnly: true, // don't let user modify the diff
            renderSideBySide: false,
          }}
        />
      ) : (
        <MonacoEditor
          value={targetText}
          onChange={(value) => {
            if (value !== undefined) setTargetText(value);
          }}
          onMount={onMount}
          language={editorLang ?? undefined}
          options={{
            ...DEFAULT_MONACO_OPTIONS,
            readOnly: !workbench.doesUserHaveEditPermission,
            scrollBeyondLastLine: true,
          }}
        />
      )}
    </EditorWrapper>
  );
};
export const TargetEditor = observer(Editor);
