import { Ecosystem } from "@protos/sca/v1/sca";

const pttSupportedEcosystems: Array<Ecosystem> = [
  Ecosystem.pypi,
  Ecosystem.swiftpm,
  Ecosystem.npm,
  Ecosystem.maven,
];

const scanBasedPttSupportedManifestLockfiles: Array<string> = [
  "build.gradle",
  "settings.gradle",
  "pom.xml",
  "build.gradle.kts",
  "settings.gradle.kts",
  "pnpm-lock.yaml",
  "poetry.lock",
  "package-lock.json",
  "yarn.lock",
];

/**
 * Check if the file path corresponds to a lockfile or manifest file that is supported for scan based path to transitivity
 * TODO: Make this logic more robust as we onboard more ecosystems and lockfile/manifest file types
 *
 * @param filePath The path to the lockfile or manifest file
 * @returns True if the file path corresponds to a lockfile or manifest file that is supported for scan based path to transitivity
 */
const isFilePathSupportedForScanBasedPtt = (filePath?: string) => {
  return filePath
    ? scanBasedPttSupportedManifestLockfiles.some((f) => filePath.endsWith(f))
    : false;
};

/**
 * Check if the dependency paths should be shown for the given ecosystem, based on the feature flags.
 * Rationale for the order of checking and return is that SBOM-based path to transitivity will be removed in the future,
 * and in the real world, only one of the two flags is expected to be enabled on a deployment.
 * @param ecosystem
 * @param enableScanBasedPathToTransitivity
 * @param enableSbomBasedPathToTransitivity
 * @param definedAtPath Filepath to where dependency is dependency is defined, i.e. the lockfile or manifest file
 * @returns true if the dependency paths should be shown for the given ecosystem, else false
 */
export const showDependencyPaths = (
  ecosystem: Ecosystem,
  enableScanBasedPathToTransitivity: boolean,
  enableSbomBasedPathToTransitivity: boolean,
  definedAtPath?: string
) => {
  if (enableScanBasedPathToTransitivity) {
    return isFilePathSupportedForScanBasedPtt(definedAtPath);
  }

  if (enableSbomBasedPathToTransitivity) {
    return pttSupportedEcosystems.includes(ecosystem);
  }

  return false;
};
