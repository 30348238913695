import { useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { GetChecklistStatusResponse } from "@protos/onboarding/v1/checklist";
import { authGet } from "@shared/api/lib/auth/auth";

import { useOrgAssertDefined } from "../useOrgAssertDefined";

const fetchChecklistStatus = (
  deploymentId: number
): Promise<GetChecklistStatusResponse> => {
  return authGet<GetChecklistStatusResponse>(
    `/api/onboarding/deployments/${deploymentId}/checklist`
  );
};

/**
 * @returns current checklist items
 */
export const useChecklistProgress = () => {
  const org = useOrgAssertDefined();
  return useQuery({
    queryKey: ["checklistProgress", { org }, org.id],
    queryFn: async () => {
      return await fetchChecklistStatus(org.id).then((response) => {
        return response.taskStatus;
      });
    },
  });
};

export const useInvalidateChecklistProgress = () => {
  const queryClient = useQueryClient();

  const invalidateChecklistProgress = useCallback(() => {
    return queryClient.invalidateQueries({ queryKey: ["checklistProgress"] });
  }, [queryClient]);

  return { invalidateChecklistProgress };
};
