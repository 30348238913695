import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";

export const Link = styled(ReactRouterLink)`
  &,
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: inherit;
    text-decoration: inherit;
  }
`;
