import dayjs from "dayjs";

import {
  TIME_LOOKBACK_RANGES_TO_DAYS_AGO,
  TimeLookbackRange,
} from "@shared/types";

export const calculateLookbackSeconds = (
  tlr: TimeLookbackRange,
  until?: Date
) => {
  const daysAgo = TIME_LOOKBACK_RANGES_TO_DAYS_AGO[tlr];
  const day = until !== undefined ? dayjs(until) : dayjs();
  return daysAgo === Infinity ? 0 : day.subtract(daysAgo, "days").unix();
};
