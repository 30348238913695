import React from "react";
import cx from "clsx";
import { faRectangleList } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Group, Indicator } from "@mantine/core";

import styles from "./OutputButton.module.css";
interface Props {
  active: boolean;
  onClick: () => void;
  showSuccess: boolean;
}

export const OutputButton: React.FC<Props> = ({
  active,
  onClick,
  showSuccess,
}) => {
  return (
    <Group>
      <Indicator color="green" disabled={!showSuccess}>
        <Button
          color="violet"
          size="compact-xs"
          onClick={onClick}
          className={cx(styles.root, { active: active })}
        >
          <FontAwesomeIcon icon={faRectangleList} />
        </Button>
      </Indicator>
    </Group>
  );
};
