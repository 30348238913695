import { useMutation } from "@tanstack/react-query";

import { authPost } from "@shared/api";

import { useOrg } from "../useOrg";

type Params = {
  event: string;
  metadata: object;
};

export const useLogEventMutation = () => {
  const [org] = useOrg();

  return useMutation({
    mutationFn: async ({ event, metadata }: Params) => {
      return authPost("/api/events", {
        event,
        metadata: { groupId: org?.id, ...metadata },
      });
    },
  });
};
