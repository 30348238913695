import { observer } from "mobx-react-lite";
import { faGem } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Group } from "@mantine/core";

import { SuccessResult } from "../../stores";

import styles from "./ResultsPanel.module.css";

interface Props {
  result: SuccessResult;
}
const Results = ({ result }: Props) => {
  return (
    result.differentialResults && (
      <Group gap="5px">
        <FontAwesomeIcon icon={faGem} />
        <b>
          <span className={styles.proOnlyFindingText}>
            +{result.differentialResults.proOnlyFindings.length}
          </span>
          /
          <span className={styles.proRemovedFindingText}>
            -{result.differentialResults.proRemovedFindings.length}
          </span>{" "}
          Pro
        </b>
      </Group>
    )
  );
};

export const ProResults = observer(Results);
