import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import styled from "styled-components";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "@mantine/core";

import { Result, SuccessResult } from "../../stores";

import { ProResults } from "./ProResults";

interface Props {
  result: Result;
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

const ResultFragment = styled.span`
  :not(:first-child)::before {
    content: " · ";
  }
`;

const Summary = ({ result }: Props) => {
  const { matchesCount, errorsCount, testFailuresCount } = result;
  return errorsCount > 0 ? (
    <Container>
      <b>
        {errorsCount} {pluralize("error", errorsCount)}
      </b>
      <span>
        <FontAwesomeIcon icon={faTimesCircle} /> run failed
      </span>
    </Container>
  ) : (
    <Container>
      <b>
        {matchesCount} {pluralize("match", matchesCount)}
      </b>
      {result instanceof SuccessResult &&
        result.differentialResults &&
        (result.differentialResults.proOnlyFindings.length !== 0 ||
          result.differentialResults.proRemovedFindings.length !== 0) && (
          <>
            <Divider orientation="vertical" />
            <ProResults result={result} />
            <Divider orientation="vertical" />
          </>
        )}
      {result instanceof SuccessResult && (
        <div>
          <ResultFragment>Semgrep v{result.semgrepVersion}</ResultFragment>
          <ResultFragment>
            in {result.runtimeSeconds.toFixed(1)}s
          </ResultFragment>
          <ResultFragment>
            {testFailuresCount === 0 ? (
              <span style={{ color: "var(--mantine-color-green-9)" }}>
                <FontAwesomeIcon icon={faCheckCircle} /> tests passed
              </span>
            ) : (
              <span style={{ color: "var(--mantine-color-red-8)" }}>
                <FontAwesomeIcon icon={faTimesCircle} /> {testFailuresCount}{" "}
                {pluralize("test", testFailuresCount)} failed
              </span>
            )}
          </ResultFragment>
        </div>
      )}
    </Container>
  );
};
export const ResultSummary = observer(Summary);
