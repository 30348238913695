import { Button } from "@mantine/core";

export const SignInButton: React.FC<{ returnPath?: string }> = ({
  returnPath,
}) => (
  <Button
    color="blue"
    component="a"
    href={`/login?return_path=${returnPath ?? window.location.pathname}`}
  >
    Sign in
  </Button>
);
