// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/auth/v1/user.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Deployment } from "../../deployment/v1/deployment";
import { TourState, tourStateFromJSON, tourStateToJSON, tourStateToNumber, User, UserSettings } from "./common";

export const protobufPackage = "protos.auth.v1";

export interface GetUserRequest {
}

export interface GetUserResponse {
  user: User | undefined;
}

export interface GetUserSettingsRequest {
}

export interface GetUserSettingsResponse {
  settings: UserSettings | undefined;
}

export interface PatchUserSettingsRequest {
  /** the user's currently selected email address */
  primaryEmail?:
    | string
    | undefined;
  /** if the user has accepted the terms of service */
  hasAcceptedToc?:
    | boolean
    | undefined;
  /** if the user is subscribed to emails */
  subscribe?:
    | boolean
    | undefined;
  /** if the user would like to be contacted about product experiments */
  contactAboutExperiments?:
    | boolean
    | undefined;
  /** if the user has used the CLI to login */
  usedCliLogin?:
    | boolean
    | undefined;
  /**
   * if the user has completed the findings product tour, deprecated (see findings_tour_state)
   *
   * @deprecated
   */
  hasCompletedFindingsTour?:
    | boolean
    | undefined;
  /**
   * if the user has completed the supply chain product tour, deprecated (see supply_chain_tour_state)
   *
   * @deprecated
   */
  hasCompletedSupplyChainTour?:
    | boolean
    | undefined;
  /** the completion state of the findings product tour */
  findingsTourState?:
    | TourState
    | undefined;
  /** the completion state of the supply chain product tour */
  supplyChainTourState?:
    | TourState
    | undefined;
  /** the completion state of the supply chain product tour */
  supplyChainFindingsTourState?: TourState | undefined;
}

export interface PatchUserSettingsResponse {
  user: User | undefined;
}

export interface ListUserOrgsRequest {
  userId: number;
}

export interface ListUserOrgsResponse {
  userId: number;
  orgs: Deployment[];
}

export interface ListUserJoinableOrgsRequest {
  userId: number;
}

export interface ListUserJoinableOrgsResponse {
  userId: number;
  orgs: Deployment[];
}

function createBaseGetUserRequest(): GetUserRequest {
  return {};
}

export const GetUserRequest = {
  encode(_: GetUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetUserRequest {
    return {};
  },

  toJSON(_: GetUserRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserRequest>, I>>(base?: I): GetUserRequest {
    return GetUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserRequest>, I>>(_: I): GetUserRequest {
    const message = createBaseGetUserRequest();
    return message;
  },
};

function createBaseGetUserResponse(): GetUserResponse {
  return { user: undefined };
}

export const GetUserResponse = {
  encode(message: GetUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserResponse {
    return { user: isSet(object.user) ? User.fromJSON(object.user) : undefined };
  },

  toJSON(message: GetUserResponse): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserResponse>, I>>(base?: I): GetUserResponse {
    return GetUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserResponse>, I>>(object: I): GetUserResponse {
    const message = createBaseGetUserResponse();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBaseGetUserSettingsRequest(): GetUserSettingsRequest {
  return {};
}

export const GetUserSettingsRequest = {
  encode(_: GetUserSettingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserSettingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserSettingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetUserSettingsRequest {
    return {};
  },

  toJSON(_: GetUserSettingsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserSettingsRequest>, I>>(base?: I): GetUserSettingsRequest {
    return GetUserSettingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserSettingsRequest>, I>>(_: I): GetUserSettingsRequest {
    const message = createBaseGetUserSettingsRequest();
    return message;
  },
};

function createBaseGetUserSettingsResponse(): GetUserSettingsResponse {
  return { settings: undefined };
}

export const GetUserSettingsResponse = {
  encode(message: GetUserSettingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.settings !== undefined) {
      UserSettings.encode(message.settings, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserSettingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserSettingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.settings = UserSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserSettingsResponse {
    return { settings: isSet(object.settings) ? UserSettings.fromJSON(object.settings) : undefined };
  },

  toJSON(message: GetUserSettingsResponse): unknown {
    const obj: any = {};
    if (message.settings !== undefined) {
      obj.settings = UserSettings.toJSON(message.settings);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserSettingsResponse>, I>>(base?: I): GetUserSettingsResponse {
    return GetUserSettingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserSettingsResponse>, I>>(object: I): GetUserSettingsResponse {
    const message = createBaseGetUserSettingsResponse();
    message.settings = (object.settings !== undefined && object.settings !== null)
      ? UserSettings.fromPartial(object.settings)
      : undefined;
    return message;
  },
};

function createBasePatchUserSettingsRequest(): PatchUserSettingsRequest {
  return {
    primaryEmail: undefined,
    hasAcceptedToc: undefined,
    subscribe: undefined,
    contactAboutExperiments: undefined,
    usedCliLogin: undefined,
    hasCompletedFindingsTour: undefined,
    hasCompletedSupplyChainTour: undefined,
    findingsTourState: undefined,
    supplyChainTourState: undefined,
    supplyChainFindingsTourState: undefined,
  };
}

export const PatchUserSettingsRequest = {
  encode(message: PatchUserSettingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.primaryEmail !== undefined) {
      writer.uint32(10).string(message.primaryEmail);
    }
    if (message.hasAcceptedToc !== undefined) {
      writer.uint32(16).bool(message.hasAcceptedToc);
    }
    if (message.subscribe !== undefined) {
      writer.uint32(24).bool(message.subscribe);
    }
    if (message.contactAboutExperiments !== undefined) {
      writer.uint32(32).bool(message.contactAboutExperiments);
    }
    if (message.usedCliLogin !== undefined) {
      writer.uint32(40).bool(message.usedCliLogin);
    }
    if (message.hasCompletedFindingsTour !== undefined) {
      writer.uint32(48).bool(message.hasCompletedFindingsTour);
    }
    if (message.hasCompletedSupplyChainTour !== undefined) {
      writer.uint32(56).bool(message.hasCompletedSupplyChainTour);
    }
    if (message.findingsTourState !== undefined) {
      writer.uint32(64).int32(tourStateToNumber(message.findingsTourState));
    }
    if (message.supplyChainTourState !== undefined) {
      writer.uint32(72).int32(tourStateToNumber(message.supplyChainTourState));
    }
    if (message.supplyChainFindingsTourState !== undefined) {
      writer.uint32(80).int32(tourStateToNumber(message.supplyChainFindingsTourState));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatchUserSettingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatchUserSettingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.primaryEmail = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasAcceptedToc = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.subscribe = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.contactAboutExperiments = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.usedCliLogin = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.hasCompletedFindingsTour = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.hasCompletedSupplyChainTour = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.findingsTourState = tourStateFromJSON(reader.int32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.supplyChainTourState = tourStateFromJSON(reader.int32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.supplyChainFindingsTourState = tourStateFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PatchUserSettingsRequest {
    return {
      primaryEmail: isSet(object.primaryEmail) ? globalThis.String(object.primaryEmail) : undefined,
      hasAcceptedToc: isSet(object.hasAcceptedToc) ? globalThis.Boolean(object.hasAcceptedToc) : undefined,
      subscribe: isSet(object.subscribe) ? globalThis.Boolean(object.subscribe) : undefined,
      contactAboutExperiments: isSet(object.contactAboutExperiments)
        ? globalThis.Boolean(object.contactAboutExperiments)
        : undefined,
      usedCliLogin: isSet(object.usedCliLogin) ? globalThis.Boolean(object.usedCliLogin) : undefined,
      hasCompletedFindingsTour: isSet(object.hasCompletedFindingsTour)
        ? globalThis.Boolean(object.hasCompletedFindingsTour)
        : undefined,
      hasCompletedSupplyChainTour: isSet(object.hasCompletedSupplyChainTour)
        ? globalThis.Boolean(object.hasCompletedSupplyChainTour)
        : undefined,
      findingsTourState: isSet(object.findingsTourState) ? tourStateFromJSON(object.findingsTourState) : undefined,
      supplyChainTourState: isSet(object.supplyChainTourState)
        ? tourStateFromJSON(object.supplyChainTourState)
        : undefined,
      supplyChainFindingsTourState: isSet(object.supplyChainFindingsTourState)
        ? tourStateFromJSON(object.supplyChainFindingsTourState)
        : undefined,
    };
  },

  toJSON(message: PatchUserSettingsRequest): unknown {
    const obj: any = {};
    if (message.primaryEmail !== undefined) {
      obj.primaryEmail = message.primaryEmail;
    }
    if (message.hasAcceptedToc !== undefined) {
      obj.hasAcceptedToc = message.hasAcceptedToc;
    }
    if (message.subscribe !== undefined) {
      obj.subscribe = message.subscribe;
    }
    if (message.contactAboutExperiments !== undefined) {
      obj.contactAboutExperiments = message.contactAboutExperiments;
    }
    if (message.usedCliLogin !== undefined) {
      obj.usedCliLogin = message.usedCliLogin;
    }
    if (message.hasCompletedFindingsTour !== undefined) {
      obj.hasCompletedFindingsTour = message.hasCompletedFindingsTour;
    }
    if (message.hasCompletedSupplyChainTour !== undefined) {
      obj.hasCompletedSupplyChainTour = message.hasCompletedSupplyChainTour;
    }
    if (message.findingsTourState !== undefined) {
      obj.findingsTourState = tourStateToJSON(message.findingsTourState);
    }
    if (message.supplyChainTourState !== undefined) {
      obj.supplyChainTourState = tourStateToJSON(message.supplyChainTourState);
    }
    if (message.supplyChainFindingsTourState !== undefined) {
      obj.supplyChainFindingsTourState = tourStateToJSON(message.supplyChainFindingsTourState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PatchUserSettingsRequest>, I>>(base?: I): PatchUserSettingsRequest {
    return PatchUserSettingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PatchUserSettingsRequest>, I>>(object: I): PatchUserSettingsRequest {
    const message = createBasePatchUserSettingsRequest();
    message.primaryEmail = object.primaryEmail ?? undefined;
    message.hasAcceptedToc = object.hasAcceptedToc ?? undefined;
    message.subscribe = object.subscribe ?? undefined;
    message.contactAboutExperiments = object.contactAboutExperiments ?? undefined;
    message.usedCliLogin = object.usedCliLogin ?? undefined;
    message.hasCompletedFindingsTour = object.hasCompletedFindingsTour ?? undefined;
    message.hasCompletedSupplyChainTour = object.hasCompletedSupplyChainTour ?? undefined;
    message.findingsTourState = object.findingsTourState ?? undefined;
    message.supplyChainTourState = object.supplyChainTourState ?? undefined;
    message.supplyChainFindingsTourState = object.supplyChainFindingsTourState ?? undefined;
    return message;
  },
};

function createBasePatchUserSettingsResponse(): PatchUserSettingsResponse {
  return { user: undefined };
}

export const PatchUserSettingsResponse = {
  encode(message: PatchUserSettingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatchUserSettingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatchUserSettingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PatchUserSettingsResponse {
    return { user: isSet(object.user) ? User.fromJSON(object.user) : undefined };
  },

  toJSON(message: PatchUserSettingsResponse): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PatchUserSettingsResponse>, I>>(base?: I): PatchUserSettingsResponse {
    return PatchUserSettingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PatchUserSettingsResponse>, I>>(object: I): PatchUserSettingsResponse {
    const message = createBasePatchUserSettingsResponse();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBaseListUserOrgsRequest(): ListUserOrgsRequest {
  return { userId: 0 };
}

export const ListUserOrgsRequest = {
  encode(message: ListUserOrgsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).uint64(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUserOrgsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUserOrgsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListUserOrgsRequest {
    return { userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0 };
  },

  toJSON(message: ListUserOrgsRequest): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListUserOrgsRequest>, I>>(base?: I): ListUserOrgsRequest {
    return ListUserOrgsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListUserOrgsRequest>, I>>(object: I): ListUserOrgsRequest {
    const message = createBaseListUserOrgsRequest();
    message.userId = object.userId ?? 0;
    return message;
  },
};

function createBaseListUserOrgsResponse(): ListUserOrgsResponse {
  return { userId: 0, orgs: [] };
}

export const ListUserOrgsResponse = {
  encode(message: ListUserOrgsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).uint64(message.userId);
    }
    for (const v of message.orgs) {
      Deployment.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUserOrgsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUserOrgsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgs.push(Deployment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListUserOrgsResponse {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      orgs: globalThis.Array.isArray(object?.orgs) ? object.orgs.map((e: any) => Deployment.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListUserOrgsResponse): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.orgs?.length) {
      obj.orgs = message.orgs.map((e) => Deployment.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListUserOrgsResponse>, I>>(base?: I): ListUserOrgsResponse {
    return ListUserOrgsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListUserOrgsResponse>, I>>(object: I): ListUserOrgsResponse {
    const message = createBaseListUserOrgsResponse();
    message.userId = object.userId ?? 0;
    message.orgs = object.orgs?.map((e) => Deployment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListUserJoinableOrgsRequest(): ListUserJoinableOrgsRequest {
  return { userId: 0 };
}

export const ListUserJoinableOrgsRequest = {
  encode(message: ListUserJoinableOrgsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).uint64(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUserJoinableOrgsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUserJoinableOrgsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListUserJoinableOrgsRequest {
    return { userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0 };
  },

  toJSON(message: ListUserJoinableOrgsRequest): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListUserJoinableOrgsRequest>, I>>(base?: I): ListUserJoinableOrgsRequest {
    return ListUserJoinableOrgsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListUserJoinableOrgsRequest>, I>>(object: I): ListUserJoinableOrgsRequest {
    const message = createBaseListUserJoinableOrgsRequest();
    message.userId = object.userId ?? 0;
    return message;
  },
};

function createBaseListUserJoinableOrgsResponse(): ListUserJoinableOrgsResponse {
  return { userId: 0, orgs: [] };
}

export const ListUserJoinableOrgsResponse = {
  encode(message: ListUserJoinableOrgsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).uint64(message.userId);
    }
    for (const v of message.orgs) {
      Deployment.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUserJoinableOrgsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUserJoinableOrgsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgs.push(Deployment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListUserJoinableOrgsResponse {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      orgs: globalThis.Array.isArray(object?.orgs) ? object.orgs.map((e: any) => Deployment.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListUserJoinableOrgsResponse): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.orgs?.length) {
      obj.orgs = message.orgs.map((e) => Deployment.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListUserJoinableOrgsResponse>, I>>(base?: I): ListUserJoinableOrgsResponse {
    return ListUserJoinableOrgsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListUserJoinableOrgsResponse>, I>>(object: I): ListUserJoinableOrgsResponse {
    const message = createBaseListUserJoinableOrgsResponse();
    message.userId = object.userId ?? 0;
    message.orgs = object.orgs?.map((e) => Deployment.fromPartial(e)) || [];
    return message;
  },
};

export interface UserService {
  listUserOrgs(request: ListUserOrgsRequest): Promise<ListUserOrgsResponse>;
  listUserJoinableOrgs(request: ListUserJoinableOrgsRequest): Promise<ListUserJoinableOrgsResponse>;
}

export const UserServiceServiceName = "protos.auth.v1.UserService";
export class UserServiceClientImpl implements UserService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || UserServiceServiceName;
    this.rpc = rpc;
    this.listUserOrgs = this.listUserOrgs.bind(this);
    this.listUserJoinableOrgs = this.listUserJoinableOrgs.bind(this);
  }
  listUserOrgs(request: ListUserOrgsRequest): Promise<ListUserOrgsResponse> {
    const data = ListUserOrgsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListUserOrgs", data);
    return promise.then((data) => ListUserOrgsResponse.decode(_m0.Reader.create(data)));
  }

  listUserJoinableOrgs(request: ListUserJoinableOrgsRequest): Promise<ListUserJoinableOrgsResponse> {
    const data = ListUserJoinableOrgsRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "ListUserJoinableOrgs", data);
    return promise.then((data) => ListUserJoinableOrgsResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
