import doc from "../lang.json";

export type LanguageMaturity = "ga" | "beta" | "alpha" | "develop";

/** Each element of lang.json follows this interface */
export interface LanguageDefinition {
  /** Unique language identifier */
  id: string;
  /** Possible language keys (e.g. in rule definitions) */
  keys: string[];
  /** Human-readable language name */
  name: string;
  /** Language maturity value */
  maturity: LanguageMaturity;
}

const language_ids = doc.map((d) => d.id);

/** A unique language identifier (e.g. "python") */
export type Language = typeof language_ids[number];
