import React from "react";
import { useHistory } from "react-router";
import { NavLink, Paper } from "@mantine/core";

import { EDITOR_EXAMPLES } from "@shared/constants";
import { useBasePath } from "@shared/hooks";

export const SplashExamples: React.FC = () => {
  const displayedExamples = EDITOR_EXAMPLES.slice(0, 3);
  const basePath = useBasePath();
  const history = useHistory();

  return (
    <Paper radius="sm">
      {displayedExamples.map((e, idx) => (
        <NavLink
          key={idx}
          label={e.title}
          description={e.description}
          onClick={() => history.push(`${basePath}/editor/s/${e.hash_id}`)}
        />
      ))}
    </Paper>
  );
};
