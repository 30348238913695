// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.178.0
//   protoc               unknown
// source: protos/ai/v1/codeowners.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "protos.ai.v1";

export interface Codeowner {
  id: number;
  name: string;
  isTeam: boolean;
  members: Codeowner[];
}

function createBaseCodeowner(): Codeowner {
  return { id: 0, name: "", isTeam: false, members: [] };
}

export const Codeowner = {
  encode(message: Codeowner, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.isTeam !== false) {
      writer.uint32(24).bool(message.isTeam);
    }
    for (const v of message.members) {
      Codeowner.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Codeowner {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCodeowner();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isTeam = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.members.push(Codeowner.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Codeowner {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      isTeam: isSet(object.isTeam) ? globalThis.Boolean(object.isTeam) : false,
      members: globalThis.Array.isArray(object?.members) ? object.members.map((e: any) => Codeowner.fromJSON(e)) : [],
    };
  },

  toJSON(message: Codeowner): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.isTeam !== false) {
      obj.isTeam = message.isTeam;
    }
    if (message.members?.length) {
      obj.members = message.members.map((e) => Codeowner.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Codeowner>, I>>(base?: I): Codeowner {
    return Codeowner.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Codeowner>, I>>(object: I): Codeowner {
    const message = createBaseCodeowner();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.isTeam = object.isTeam ?? false;
    message.members = object.members?.map((e) => Codeowner.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
