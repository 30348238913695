import React, { FC, useEffect, useMemo } from "react";
import styled from "styled-components";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface InputValidator {
  description: string;
  isValid: (inputString: string) => boolean;
}

const InputValidatorDescription = styled.span`
  color: #777777;
  font-weight: 200;
  font-size: var(--mantine-font-size-xs);
  margin-bottom: -0.2rem;
`;

const InputValidatorRow: FC<{ description: string }> = ({ description }) => {
  return (
    <div>
      <FontAwesomeIcon
        data-testid="InputValidatorIcon"
        icon={faCircle}
        style={{ color: "red" }}
      />{" "}
      <InputValidatorDescription data-testid="InputValidatorDescription">
        {description}
      </InputValidatorDescription>
    </div>
  );
};

const InputValidatorStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: left;
`;

interface Props {
  value: string;
  validators: InputValidator[];
  setValidInput?: (value: boolean) => void;
}

export const InputValidation: FC<Props> = ({
  value,
  validators,
  setValidInput,
}) => {
  const failedValidators = useMemo(() => {
    return validators.filter((validator) => !validator.isValid(value));
  }, [value, validators]);

  useEffect(() => {
    const allValid = failedValidators.length === 0;
    if (setValidInput && allValid) {
      setValidInput(allValid);
    }
  }, [failedValidators, setValidInput]);

  return (
    <InputValidatorStatusContainer>
      {failedValidators &&
        failedValidators.map((validator, i) => (
          <InputValidatorRow description={validator.description} key={i} />
        ))}
    </InputValidatorStatusContainer>
  );
};
