import styled from "styled-components";

export const SupplyChainCard = styled.div<{ clickable?: boolean }>`
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  color: white;
  border: 1px solid #546075;
  min-width: 150px;
  border-radius: 8px;
  padding: 8px 10px;
  ${({ clickable }) =>
    clickable
      ? `
    cursor: pointer;
    :hover {
      background-color: #20283e;
    }
  `
      : ""}
`;
