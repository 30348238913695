import { JsonValue } from "@bufbuild/protobuf";
import { usePrefab } from "@prefab-cloud/prefab-cloud-react";
import { useQuery } from "@tanstack/react-query";

import { ListUserJoinableOrgsResponse } from "@protos/auth/v1/user";
import { Deployment } from "@protos/deployment/v1/deployment";
import { authGet } from "@shared/api/lib/auth/auth";

import { useUser } from "../useUser/useUser";

const fetchJoinableOrgs = async (
  userId: number | string
): Promise<Deployment[]> => {
  const result = await authGet<JsonValue>(
    `/api/auth/users/${userId}/deployments/joinable`
  );
  return ListUserJoinableOrgsResponse.fromJSON(result).orgs;
};

/**
 * Get the list of joinable deployments for the user.
 *
 * @returns a query list of joinable deployments for the user
 */
export const useJoinableOrgs = () => {
  const [user] = useUser();
  const userId = user?.user_id;
  const { get, loading: prefabLoading } = usePrefab();
  // NOTE: If prefab has not yet loaded, the denylist will be undefined (see GROW-305).
  // In addition, prefab failing to resolve the config value will also result in undefined.
  // This can be reproduced by blocking `api-prefab-cloud.global.ssl.fastly.net/api/v1/`
  // and `api.prefab.cloud/api/v1/` in the network request blocking tab in devtools.
  // The denylist comes from #incident-2024-05-31-unwanted-users-in-the-org and is
  // a temporary measure to prevent unwanted users from joining unsafe deployments.
  const deploymentJoinDenylist = get(
    "login.deployment.denylisted_deployments_to_join"
  ) as string[] | undefined;
  return useQuery({
    queryKey: ["userJoinableDeployments", userId, deploymentJoinDenylist],
    queryFn: async () => {
      const candidateDeployments = await fetchJoinableOrgs(userId!);
      const denylist = deploymentJoinDenylist ?? [];
      return candidateDeployments.filter(
        (deployment) => !denylist.includes(deployment.id.toString())
      );
    },
    enabled: !!userId && !prefabLoading,
    staleTime: 60000, // 60s until stale (defaults to 0 otherwise)
  });
};
