import { faGem } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "@mantine/core";

import { isPro } from "../../utils";

interface Props {
  label: "pro-only" | "pro-removed" | "normal";
}

export const ProBadge = ({ label }: Props) => {
  return (
    isPro(label) && (
      <Badge size="xs" color="var(--mantine-color-violet-5)">
        <FontAwesomeIcon icon={faGem} />{" "}
        {label === "pro-removed" ? "REMOVED IN PRO" : "ONLY IN PRO"}
      </Badge>
    )
  );
};
