import { FC } from "react";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { Group, Tooltip } from "@mantine/core";

import { OptionalAnchor } from "../OptionalAnchor";

import styles from "./CodeLocationIconList.module.css";

type CodeLocationIconProps = {
  icon: IconDefinition;
  label: string;
  href?: string;
  color?: string;
  iconProps?: Omit<FontAwesomeIconProps, "icon" | "color">;
  onHoverText?: string;
};

export const CodeLocationIcon: FC<CodeLocationIconProps> = (props) => (
  <Tooltip disabled={!props.onHoverText} label={props.onHoverText}>
    <Group gap={6} wrap="nowrap" className={styles.group} c={props.color}>
      <FontAwesomeIcon
        icon={props.icon}
        size="sm"
        flip={props.iconProps?.flip}
      />
      <Group gap={2} maw={200}>
        <OptionalAnchor
          url={props.href}
          anchorColor="dark.3"
          textColor="dark.3"
        >
          {props.label}
        </OptionalAnchor>
      </Group>
    </Group>
  </Tooltip>
);
