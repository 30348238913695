import { FC } from "react";
import React from "react";
import cx from "clsx";
import { Link } from "react-router-dom";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Center,
  Group,
  MantineColor,
  Stack,
  Title,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";

import styles from "./Billboard.module.css";

interface BillboardActionProps {
  priority?: "primary" | "secondary" | "tertiary";
  text: string;
  linkTo: string;
}

const BillboardActionButton = ({
  priority,
  text,
  linkTo,
}: BillboardActionProps) => {
  const buttonVariant =
    priority === "primary"
      ? "filled"
      : priority === "secondary"
      ? "outline"
      : "subtle";
  return (
    <Link to={linkTo}>
      <Button variant={buttonVariant}>{text}</Button>
    </Link>
  );
};

type ActionDef = React.ReactNode | Omit<BillboardActionProps, "priority">;

export interface BillboardProps {
  title?: string;
  color?: MantineColor;
  opacity?: number;
  icon?: IconDefinition;
  bordered?: boolean;
  size?: SizeProp;
  primaryAction?: ActionDef;
  secondaryAction?: ActionDef;
  tertiaryAction?: ActionDef;
  isSmall?: boolean;
  children?: React.ReactNode;
}

const isButtonProps = (
  action: ActionDef
): action is Omit<BillboardActionProps, "priority"> =>
  !!action && !React.isValidElement(action);

export const Billboard: FC<BillboardProps> = ({
  title,
  color,
  opacity,
  icon,
  bordered = false,
  size,
  primaryAction,
  secondaryAction,
  tertiaryAction,
  isSmall,
  children,
}) => {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  return (
    <Center
      className={cx(styles.root, {
        bordered: bordered,
        dark: colorScheme === "dark",
      })}
    >
      <Stack gap={isSmall ? "md" : "xl"}>
        {icon !== undefined && (
          <FontAwesomeIcon
            size={size ?? "6x"}
            icon={icon}
            color={color ?? colors["blue"][8]}
            opacity={opacity}
          />
        )}
        <Stack gap={12} pt={12}>
          {title !== undefined && (
            <Title order={isSmall ? 5 : 3}>{title}</Title>
          )}
          {children}
        </Stack>
        <Group gap="xs" m="auto">
          {isButtonProps(primaryAction) ? (
            <BillboardActionButton priority="primary" {...primaryAction} />
          ) : (
            primaryAction
          )}
          {isButtonProps(secondaryAction) ? (
            <BillboardActionButton priority="secondary" {...secondaryAction} />
          ) : (
            secondaryAction
          )}
          {isButtonProps(tertiaryAction) ? (
            <BillboardActionButton priority="tertiary" {...tertiaryAction} />
          ) : tertiaryAction === "object" ? (
            tertiaryAction
          ) : null}
        </Group>
      </Stack>
    </Center>
  );
};
