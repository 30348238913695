import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { RegistryRuleMetadata } from "@shared/types";

import { Workbench } from "../../stores";
import { RuleMetadataEditor } from "../RuleMetadataEditor/RuleMetadataEditor";

const Container = styled.div``;

function PublishMetadataComponent({ workbench }: { workbench: Workbench }) {
  const { bundle } = workbench;
  if (bundle === null) return null;
  const { rule } = bundle;
  if (rule === null) return null;

  return (
    <Container>
      <RuleMetadataEditor
        metadata={(rule.metadata ?? {}) as RegistryRuleMetadata}
        setMetadata={(m) => bundle.setRuleMetadata(m)}
        message={rule.message}
        setMessage={(m) => bundle.setRuleMessage(m)}
        ruleId={rule.id}
        setRuleId={(m) => bundle.setRuleId(m)}
        languages={rule.languages}
        setLanguages={(m) => bundle.setRuleLanguages(m)}
        showOptional={false}
      />
    </Container>
  );
}

export const PublishMetadata = observer(PublishMetadataComponent);
