import {
  Confidence,
  FindingStatus,
  Mode,
  RepositoryVisibility,
  Severity,
} from "@protos/common/v1/common";
import { ValidationState } from "@protos/secrets/v1/secrets";

export const protos = {
  // Severities
  [Severity.CRITICAL]: "Critical",
  [Severity.HIGH]: "High",
  [Severity.MEDIUM]: "Medium",
  [Severity.LOW]: "Low",

  // Modes
  [Mode.MONITOR]: "Monitor",
  [Mode.COMMENT]: "Comment",
  [Mode.BLOCK]: "Block",

  // Finding Statuses
  [FindingStatus.OPEN]: "Open",
  [FindingStatus.IGNORED]: "Ignored",
  [FindingStatus.FIXED]: "Fixed",
  [FindingStatus.REMOVED]: "Removed",

  // Confidence
  [Confidence.HIGH]: "High",
  [Confidence.MEDIUM]: "Medium",
  [Confidence.LOW]: "Low",

  // Secrets
  [ValidationState.CONFIRMED_VALID]: "Confirmed valid",
  [ValidationState.CONFIRMED_INVALID]: "Confirmed invalid",
  [ValidationState.VALIDATION_ERROR]: "Validation error",
  [ValidationState.NO_VALIDATOR]: "No validator",

  // Repository Visibility
  [RepositoryVisibility.PRIVATE]: "Private",
  [RepositoryVisibility.PUBLIC]: "Public",
  [RepositoryVisibility.UNKNOWN]: "Unknown",
};
