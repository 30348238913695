import { recoverBrowserSecurityError } from "@shared/utils";

import {
  BundleAddressString,
  RecentlyVisitedOrgData,
  RecentlyVisitedRuleData,
} from "../types";

import {
  parseRecentlyVisitedDataFromLocalStorage,
  RECENTLY_VIEWED_RULES_EDITOR,
} from "./parseRecentlyVisitedDataFromLocalStorage";

// only exported for testing
export const NUM_RECENTLY_SAVED_RULES_PER_ORG = 5;

export const addRuleToRecentlyViewed = (
  orgName: string,
  ruleName: string,
  filePath: BundleAddressString
) => {
  const recentlyViewed = parseRecentlyVisitedDataFromLocalStorage();
  const indexOfCurrentOrgData = recentlyViewed.findIndex(
    (orgDatum) => orgDatum.orgName === orgName
  );
  const currentRuleVisitData: RecentlyVisitedRuleData = {
    name: ruleName,
    file_path: filePath,
    last_viewed: new Date().toISOString(),
  };

  if (indexOfCurrentOrgData === -1) {
    // recently viewed data for this org hasn't been saved yet
    // add to localstorage data
    const dataToAdd: RecentlyVisitedOrgData = {
      orgName: orgName,
      rules: [currentRuleVisitData],
    };
    recentlyViewed.push(dataToAdd);
  } else {
    // org data already exists, search for current rule
    const indexOfCurrentRuleData = recentlyViewed[
      indexOfCurrentOrgData
    ].rules.findIndex((ruleDatum) => ruleDatum.file_path === filePath);
    if (indexOfCurrentRuleData === -1) {
      // adding new rule data
      if (
        recentlyViewed[indexOfCurrentOrgData].rules.length >=
        NUM_RECENTLY_SAVED_RULES_PER_ORG
      ) {
        // only keep 5 rules at a time so remove the one at the end (since it's already sorted)
        recentlyViewed[indexOfCurrentOrgData].rules.splice(
          NUM_RECENTLY_SAVED_RULES_PER_ORG - 1
        );
      }
      // This should be unshfit so we add it to the front (as it is the most recent one)
      recentlyViewed[indexOfCurrentOrgData].rules.unshift(currentRuleVisitData);
    } else {
      // modify original
      recentlyViewed[indexOfCurrentOrgData].rules[
        indexOfCurrentRuleData
      ].last_viewed = new Date().toISOString();
    }
    // resort the original list as the last visited time has changed
    recentlyViewed[indexOfCurrentOrgData].rules.sort(
      (a, b) =>
        new Date(b.last_viewed).valueOf() - new Date(a.last_viewed).valueOf()
    );
  }

  // put modified object back in local storage
  recoverBrowserSecurityError(() => {
    localStorage.setItem(
      RECENTLY_VIEWED_RULES_EDITOR,
      JSON.stringify(recentlyViewed)
    );
  });
};
