import { ALLOWED_VISUAL_PATTERN_KEYS } from "@shared/types";

export const DEFAULT_LANG = "python";

export const DEFAULT_PATTERN_COMPONENT = {
  pattern: `print("...")`,
  type: "pattern" as ALLOWED_VISUAL_PATTERN_KEYS,
};

export const DEFAULT_WORKBENCH_LANGUAGE = "python";

export const DEFAULT_TARGET = `print("Welcome to Semgrep!" + "Use our Run button to start experimenting -->")


print("...")

# To detect ALL calls to the print() function, change the Semgrep Rule from print("...") to print(...)

print(not_a_string)

print(first_var, second_var)

print()

# print("This is commented out so it will never be found")
`;

export const EMPTY_TARGET = "";

// coupled to semgrep codebase
export const ERROR_TYPE_PATTERN = "PatternParseError";
export const ERROR_TYPE_CODE = "ParseError";

export const LANG_MONACO_MAP: { [key: string]: string } = {
  js: "javascript",
  ts: "typescript",
};

// default is `//` for most languages we support
export const LANG_COMMENT_SYNTAX_MAP: { [key: string]: string } = {
  python: "#",
  ruby: "#",
  lua: "--",
  r: "#",
};

export const DEFAULT_PATTERN = `
rules:
- id: untitled_rule
  pattern: print("...")
  message: |
    Semgrep found a match
  severity: WARNING
  languages: [${DEFAULT_LANG}]
`;
