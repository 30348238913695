import { useMemo } from "react";
import partition from "lodash/partition";

import { LicenseData } from "@shared/types";

import { useLicenses } from "../useLicenses";
import { useOrg } from "../useOrg";

export interface ProductLicenseInfo {
  productCode: "sast" | "ssc" | "secrets";
  license?: LicenseData;
  hasTrial: boolean;
  trialExpirationDate?: string;
}

const PRODUCT_TO_TRIAL_FLAG = {
  sast: "hasTeamsTierTrial",
  ssc: "hasSCATrial",
  secrets: "hasSecretsTrial",
};

/**
 *
 * @param product the product to check for a license
 * @returns
 *  productCode: the product to which this license info pertains.
 *  license: the license for the product, if available. This does not include trial licenses, see hasTrial.
 *  hasTrial: true if the org has a trial for the product, through a license or feature flag.
 *  trialExpirationDate: the expiration date of the current trial if there is one.
 */
export const useProductLicenseInfo = (
  product: "sast" | "ssc" | "secrets"
): ProductLicenseInfo | undefined => {
  const [org] = useOrg();
  const { data: licenses } = useLicenses();

  return useMemo(() => {
    if (org === undefined || licenses === undefined) {
      // still loading
      return undefined;
    }

    // check for trial feature flag
    const currentTime = new Date();
    const trialExpirationDate = org?.features?.find(
      (f) => f.name === PRODUCT_TO_TRIAL_FLAG[product]
    )?.expires_at;
    const hasTrial = trialExpirationDate
      ? new Date(trialExpirationDate) >= currentTime
      : false;

    if (licenses.length === 0) {
      return {
        productCode: product,
        license: undefined,
        hasTrial,
        trialExpirationDate: hasTrial ? trialExpirationDate : undefined,
      };
    }

    const activeLicenses = licenses.filter(
      (l) => l.active && l.product_code === product.toUpperCase()
    );

    // no active licenses for this product
    if (activeLicenses.length === 0) {
      return {
        productCode: product,
        license: undefined,
        hasTrial,
      };
    }

    const [paidLicenses, trialLicenses] = partition(
      activeLicenses,
      (l) => !l.is_trial
    );

    if (paidLicenses.length > 0) {
      // on the off chance that the org has a subscription and license from an AE, get the AE one
      const licenseFromAE = paidLicenses.find((l) => !l.subscription_id);
      return {
        productCode: product,
        license: licenseFromAE ?? paidLicenses[0],
        hasTrial: false,
      };
    } else if (trialLicenses.length > 0) {
      // we're treating trial licenses the same as feature flags, so the license is undefined
      return {
        productCode: product,
        license: undefined,
        hasTrial: true,
        trialExpirationDate: trialLicenses[0].expiration_date,
      };
    } else {
      // should never get here
      return {
        productCode: product,
        license: undefined,
        hasTrial: false,
      };
    }
  }, [org, licenses, product]);
};
