import React from "react";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mantine/core";

interface IRunButtonProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => Promise<void>;
  isRunning: boolean;
}

export const WidgetRunButton: React.FC<IRunButtonProps> = ({
  onClick,
  isRunning,
}) => {
  return (
    <Button
      color="green"
      onClick={onClick}
      leftSection={<FontAwesomeIcon icon={faPlay} />}
      loading={isRunning}
      size="compact-xs"
    >
      Run
    </Button>
  );
};
