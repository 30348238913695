import React, { useState } from "react";
import { Box, Modal, Stack, Title } from "@mantine/core";

import { useOrg } from "@shared/hooks";
import { DeploymentAction } from "@shared/types";

import { JoinableOrgsForm } from "./JoinableOrgsForm";
import { NewOrgForm } from "./NewOrgForm";
import { OrgActionSelector } from "./OrgActionSelector";

interface CreateOrgDialogProps {
  isOpen: boolean;
  onClose?: () => void;
  unCloseable?: boolean;
}

export const CreateOrgDialog: React.FC<CreateOrgDialogProps> = ({
  isOpen,
  onClose,
  unCloseable,
}) => {
  const [org] = useOrg();
  const [action, setAction] = useState<DeploymentAction>();

  return (
    <Modal
      opened={isOpen}
      size="auto"
      closeOnClickOutside={!unCloseable}
      withCloseButton={!unCloseable}
      closeOnEscape={!unCloseable}
      title={org ? "Add an organization" : "Welcome to Semgrep!"}
      onClose={() => {
        setAction(undefined);
        if (onClose) onClose();
      }}
    >
      <Box w="50vw" miw={400}>
        <Stack align="center" p="md" pt={24}>
          {!org && (
            <Title order={3} fw="normal">
              Let's get you started
            </Title>
          )}
          {action === "create" ? (
            <NewOrgForm onBack={() => setAction(undefined)} />
          ) : action === "join" ? (
            <JoinableOrgsForm onBack={() => setAction(undefined)} />
          ) : (
            <OrgActionSelector setAction={setAction} />
          )}
        </Stack>
      </Box>
    </Modal>
  );
};
