export const makeGhIssueUrl = (
  github_repo: string,
  title: string,
  body: string
): string => {
  return (
    github_repo +
    "/issues/new?title=" +
    encodeURIComponent(title) +
    "&body=" +
    encodeURIComponent(body)
  );
};
