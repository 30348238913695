import DOMPurify from "dompurify";
import { editor } from "monaco-editor";

import { Location, MatchDataflowTrace } from "@semgrep_output_types";

import { dataflow_trace_to_lines, TaintVisualizerRow } from "./taintStack";

function getDictionary(): string {
  return "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
}

// https://stackoverflow.com/questions/36129721/convert-number-to-alphabet-letter
function numberToEncodedLetter(number: number): string {
  function numToLetter(num: number) {
    const oneIndexed = num + 1;
    //Takes a letter between 0 and max letter length and returns the corresponding letter
    if (oneIndexed > dictionary.length || oneIndexed < 0) {
      return "";
    }
    if (oneIndexed === 0) {
      return "";
    } else {
      return dictionary.slice(oneIndexed - 1, oneIndexed);
    }
  }

  //Takes any number and converts it into a base (dictionary length) letter combo. 0 corresponds to an empty string.
  //It converts any numerical entry into a positive integer.
  if (isNaN(number)) {
    return "";
  }
  number = Math.abs(Math.floor(number));

  const dictionary = getDictionary();
  let index = number % dictionary.length;
  let quotient = number / dictionary.length;
  let result = "";

  if (number <= dictionary.length) {
    return numToLetter(number);
  } //Number is within single digit bounds of our encoding letter alphabet

  if (quotient >= 1) {
    //This number was bigger than our dictionary, recursively perform this function until we're done
    if (index === 0) {
      quotient--;
    } //Accounts for the edge case of the last letter in the dictionary string
    result = numberToEncodedLetter(quotient);
  }

  if (index === 0) {
    index = dictionary.length;
  } //Accounts for the edge case of the final letter; avoids getting an empty string

  return result + numToLetter(index);
}

const TAINT_DESCRIPTION_CSS_CLASS_NAME = "taint-inline-description";

const makeViewZoneFromContentAndLocation = (
  location: Location,
  description: string,
  content: string,
  index: string
): editor.IViewZone => {
  const domNode = document.createElement("div");
  domNode.className = TAINT_DESCRIPTION_CSS_CLASS_NAME;
  const dangerousHtmlString = `<span class="number">${index}</span><span>${description}: </span><span class="code">${content}<span>`;
  // This is fine, we're using DOMPurify to sanitize the HTML string
  // nosemgrep: javascript.browser.security.insecure-document-method.insecure-document-method
  domNode.innerHTML = DOMPurify.sanitize(dangerousHtmlString);

  return {
    afterLineNumber: location.start.line,
    heightInLines: 1,
    domNode: domNode,
  };
};

export const makeTaintViewZones = (
  traces: MatchDataflowTrace[]
): editor.IViewZone[] => {
  const getLinesDummy = (
    _path: string,
    _start_line: number,
    _end_line: number
  ) => {
    return [];
  };

  const allMatchTraces = traces.flatMap((trace, match_index) => {
    const visualizerRows = dataflow_trace_to_lines(trace, getLinesDummy);
    const zones: editor.IViewZone[] = visualizerRows.flatMap(
      (row: TaintVisualizerRow, i: number) => {
        if (row.location) {
          return makeViewZoneFromContentAndLocation(
            row.location,
            row.label,
            row.content?.join("\n") || "",
            numberToEncodedLetter(match_index) + (i + 1).toString()
          );
        } else {
          return [];
        }
      }
    );
    return zones;
  });
  return allMatchTraces;
};
